import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { ProjectInterface } from '../../../../../models/Project';
import { CurrentProjectReport, ProjectDashboardService } from '../../../../../services/project/project.service';
import { RestService } from '../../../../../services/common/rest.service';
import { ExcelExportEvent, GridComponent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query/dist/npm/sort-descriptor';
import { ScheduleAnalysisTask } from '../../../../../models/Update/Task';
import { KendoColumn } from '../../../../../models/ChartSettings';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';
import { formatExcelExport } from '../../../../../util/excel';
import { AnalyticsDashboardService } from '../../../../../services/analytics/analytics.service';
import { cleanDateUTC } from '../../../../../util/pipes/date.pipe';
import { searchIcon, SVGIcon } from '@progress/kendo-svg-icons';
import { TotalFloatIndexArgs } from '@rhinoworks/analytics-calculations';
import { Activity, XerActivity, XerActivityCode } from '@rhinoworks/xer-parse';
export enum FloatConsumptionView {
	averageFloat = 'Average Float',
	negative = 'Negative Float',
	criticalPathFloat = 'Zero Float',
	nearCriticalFloat = '1-7 days',
	monthFloat = '8-30 days',
	largeFloat = '30+ days',
}
export type FloatConsumptionActv = ScheduleAnalysisTask & {
	latest_status?: 'Not Started' | 'Completed' | 'Incomplete' | 'Deleted';
	latest_end_date?: Date;
	latest_start_date?: Date;
	total_float_days?: number;
	prev_total_float_days?: number;
	category?: FloatConsumptionView;
};
@Component({
	selector: 'app-float-consumption-table-card',
	templateUrl: './float-consumption-table-card.component.html',
	styleUrls: ['./float-consumption-table-card.component.scss'],
})
export class FloatConsumptionTableCardComponent implements OnInit, OnChanges {
	FloatConsumptionView = FloatConsumptionView;
	public svgSearch: SVGIcon = searchIcon;
	@Input() visualizer: boolean = false;
	@Input() tableView: FloatConsumptionView = FloatConsumptionView.averageFloat;
	@Input() exportExcel: Observable<void>;
	@Input() focusTabFloatHistoricalVals: TotalFloatIndexArgs[];
	@Input() focusTabActvCode: XerActivityCode[];
	@Input() focusTabTasks: XerActivity[];
	@Input() doSearchFilter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	@Input() isFocus: boolean = false;
	@Input() tasks: Map<string, Activity>;
	@Input() prevTasks: Map<string, Activity>;
	@Output() selectedFloatConsumptionViewChange = new EventEmitter<FloatConsumptionView>();

	constructor(
		public project: ProjectDashboardService,
		private restService: RestService,
		public analyticsService: AnalyticsDashboardService
	) {
		this.allData = this.allData.bind(this);
	}

	searchTerm = '';
	previousActivities;
	tableSearch = '';
	@ViewChild(GridComponent)
	public grid: GridComponent;
	public gridView: GridDataResult;
	gridData: Array<any> = [];
	public sort: SortDescriptor[] = [
		{
			dir: 'asc',
			field: 'early_end_date',
		},
	];
	public pageSize = 100;
	public skip = 0;
	currentDisplaySet: ScheduleAnalysisTask[] = [];
	unfilteredDisplaySet: {
		[key in FloatConsumptionView]?: ScheduleAnalysisTask[];
	} = {
		[FloatConsumptionView.averageFloat]: [],
		[FloatConsumptionView.negative]: [],
		[FloatConsumptionView.criticalPathFloat]: [],
		[FloatConsumptionView.nearCriticalFloat]: [],
		[FloatConsumptionView.monthFloat]: [],
		[FloatConsumptionView.largeFloat]: [],
	};
	columns: KendoColumn[] = [
		{
			field: 'category',
			title: 'Category',
			width: 100,
			excelOrder: 6,
			type: 'center-string',
		},
		{
			field: 'total_float_days',
			title: 'Curr. TF',
			width: 100,
			excelOrder: 5,
			type: 'number',
		},
		{
			field: 'prev_total_float_days',
			title: 'Prev. TF',
			width: 100,
			excelOrder: 4,
			type: 'number',
		},
		{
			field: 'task_code',
			title: 'Activity ID',
			width: 110,
			excelOrder: 1,
		},
		{
			field: 'task_name',
			title: 'Activity Name',
			width: 250,
			excelOrder: 2,
		},
		{
			field: 'early_end_date',
			title: 'Planned Finish',
			width: 120,
			format: '{0:MM/dd/yyyy}',
			excelOrder: 3,
			type: 'date',
		},
		{
			field: 'latest_status',
			title: 'Status',
			width: 90,
			excelOrder: 0,
		},
	];
	readonly EXCEL_COLUMNS = this.columns.sort((a, b) => a.excelOrder - b.excelOrder);
	allActivities: FloatConsumptionActv[] = [];
	private _unsubscribeAll: Subject<void> = new Subject<void>();

	ngOnInit(): void {
		combineLatest([
			// Debounce the project report if needed
			this.project.$currentProjectReport.pipe(debounceTime(100)),
		])
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(([report]) => {
				// Ensure both have valid values, if you need an extra guard:
				if (!report || !this.tasks?.size) {
					return;
				}
				this.setFloatConsumptionTables(
					report,
					Array.from(this.tasks.values()).map((t) => t.raw_entry)
				);
			});
		this.exportExcel.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
			this.exportToExcel(this.grid);
		});
		this.doSearchFilter.subscribe((val: boolean) => {
			if (val && this.tableSearch !== '') {
				setTimeout(() => {
					this.updateFilter({ searchTerm: this.tableSearch });
				}, 250);
			}
		});
	}

	OnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	/**
	 * sets table view to a filtered scope
	 * @param view
	 */
	setTableView(view: FloatConsumptionView) {
		this.selectedFloatConsumptionViewChange.next(view);
		this.tableView = view;
		this.currentDisplaySet = this.unfilteredDisplaySet[view];
		this.loadActivities();
		this.reset();
	}

	updateFilter(args: { event?: any; searchTerm?: string }) {
		const searchTerm: string = args?.event?.target?.value?.toLowerCase() || args.searchTerm;
		this.tableSearch = searchTerm;
		let filteredDisplaySet: FloatConsumptionActv[] = [];
		const unfilteredDisplaySet = this.unfilteredDisplaySet[this.tableView] || [];
		if (!searchTerm) {
			filteredDisplaySet = unfilteredDisplaySet;
		} else {
			for (const task of unfilteredDisplaySet) {
				if (
					task.task_code?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
					task.task_name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
				) {
					filteredDisplaySet.push(task);
				}
			}
		}

		// update the rows
		this.currentDisplaySet = filteredDisplaySet;
		this.loadActivities();
	}

	/**
	 * updates table data with latest displayset
	 * @param projectReport
	 * @param tasks
	 * @param prevTasks
	 */
	setFloatConsumptionTables(projectReport: CurrentProjectReport, tasks: Array<FloatConsumptionActv>) {
		const filteredTasks: FloatConsumptionActv[] = this.focusTabTasks || tasks;

		if (!projectReport || !filteredTasks.length) {
			return;
		}
		if (filteredTasks.length > 0) {
			this.allActivities = tasks;
			this.analyticsService.floatConsumptionLoading = true;
			this.unfilteredDisplaySet = {
				[FloatConsumptionView.averageFloat]: [],
				[FloatConsumptionView.negative]: [],
				[FloatConsumptionView.criticalPathFloat]: [],
				[FloatConsumptionView.nearCriticalFloat]: [],
				[FloatConsumptionView.monthFloat]: [],
				[FloatConsumptionView.largeFloat]: [],
			};
			for (const task of filteredTasks) {
				if (task.act_end_date || task.task_type === 'TT_LOE') {
					continue;
				}

				if (task.act_end_date) {
					task.latest_status = 'Completed';
				} else if (task.act_start_date) {
					task.latest_status = 'Incomplete';
				} else {
					task.latest_status = 'Not Started';
				}
				task.latest_start_date = task.act_start_date || task.act_start_date ? new Date(task.act_start_date) : undefined;
				task.target_end_date = task.target_end_date ? new Date(task.target_end_date) : undefined;
				task.target_start_date = task.target_start_date ? new Date(task.target_start_date) : undefined;
				task.early_start_date =
					(task.early_start_date ? new Date(task.early_start_date) : undefined) || task.target_start_date;
				task.early_end_date = (task.early_end_date ? new Date(task.early_end_date) : undefined) || task.target_end_date;
				task.act_start_date = task.act_start_date ? new Date(task.act_start_date) : undefined;
				const totalFloat = task.total_float_hr_cnt;
				const prevTask: Activity = this.prevTasks.get(task.task_code);
				const prevTotalFloat: number = prevTask ? prevTask.raw_entry.total_float_hr_cnt : null;
				if (totalFloat === undefined) {
					continue;
				}
				const taskCalendarHrs: number =
					(
						this.project.calendars.get(task.clndr_id) ||
						Array.from(this.project.calendars.values()).find((cal) => cal.default_flag === 'Y')
					)?.day_hr_cnt || 8;
				task.total_float_days = Math.round(task.total_float_hr_cnt / taskCalendarHrs);
				task.prev_total_float_days = prevTotalFloat === null ? null : Math.round(prevTotalFloat / taskCalendarHrs);
				if (task.total_float_days < 0) {
					this.unfilteredDisplaySet[FloatConsumptionView.negative].push(task);
					task.category = FloatConsumptionView.negative;
				} else if (task.total_float_days === 0) {
					this.unfilteredDisplaySet[FloatConsumptionView.criticalPathFloat].push(task);
					task.category = FloatConsumptionView.criticalPathFloat;
				} else if (task.total_float_days <= 7) {
					this.unfilteredDisplaySet[FloatConsumptionView.nearCriticalFloat].push(task);
					task.category = FloatConsumptionView.nearCriticalFloat;
				} else if (task.total_float_days <= 30) {
					this.unfilteredDisplaySet[FloatConsumptionView.monthFloat].push(task);
					task.category = FloatConsumptionView.monthFloat;
				} else {
					this.unfilteredDisplaySet[FloatConsumptionView.largeFloat].push(task);
					task.category = FloatConsumptionView.largeFloat;
				}
				this.unfilteredDisplaySet[FloatConsumptionView.averageFloat].push(task);
			}
			this.currentDisplaySet = this.unfilteredDisplaySet[this.tableView] || [];
			const floatHistorical =
				this.focusTabFloatHistoricalVals?.length > 0
					? this.focusTabFloatHistoricalVals
					: projectReport.floatHistorical || [];
			if (floatHistorical.length > 0) {
				this.loadActivities();
				this.analyticsService.floatConsumptionLoading = false;
			}
		}
	}

	/**
	 * sets activities grid data to be the latest data at the current scroll/page spot
	 */
	public loadActivities(): void {
		this.gridView = {
			data: this.currentDisplaySet?.slice(this.skip, this.skip + this.pageSize),
			total: this.currentDisplaySet?.length,
		};
		this.gridData = this.currentDisplaySet;
	}

	public allData(): ExcelExportData {
		const result: ExcelExportData = {
			data: process(this.unfilteredDisplaySet['Average Float'], {
				sort: [{ field: 'total_float_days', dir: 'asc' }],
			}).data,
		};
		return result;
	}

	reset() {
		this.skip = 0;
		this?.grid?.scrollTo({ row: 0, column: 0 });
	}

	public sortChange(sort: SortDescriptor[]): void {
		this.sort = sort;
		this.loadActivities();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.tableView?.currentValue) {
			this.setTableView(changes.tableView.currentValue);
		}
		if (changes.tasks?.currentValue) {
			const tasks = changes.tasks.currentValue as Map<string, Activity>;
			this.setFloatConsumptionTables(
				this.project.$currentProjectReport.value,
				Array.from(tasks.values()).map((t) => t.raw_entry)
			);
		}
	}

	public onExcelExport(e: ExcelExportEvent): void {
		e.preventDefault();
		formatExcelExport(
			e,
			'Float_' +
				this.project.$currentProjectReport.value.project.name +
				'_' +
				this.analyticsService.yyyymmdd() +
				'.xlsx',
			this.project.$currentProjectReport.value.project.name,
			'Float Consumption Data Export',
			false,
			this.project.$currentProjectReport.value.project.name +
				'\nUpdate ' +
				(this.project.$currentProjectReport.value.updateIds.length - 1) +
				' - ' +
				cleanDateUTC(new Date(), 'MMMM d, yyyy'),
			'F1'
		);
		console.log(e.workbook);
	}
	public exportToExcel(grid: GridComponent): void {
		grid.saveAsExcel();
	}
	public pageChange(event: PageChangeEvent): void {
		this.skip = event.skip;
		this.loadActivities();
	}
}
