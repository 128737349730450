import { Component, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'mail-confirm',
	templateUrl: './mail-confirm.component.html',
	styleUrls: ['./mail-confirm.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class MailConfirmComponent {
	/**
	 * Constructor
	 *
	 */
	constructor() {}
}
