import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QcPageComponent } from './qc-page.component';
import { TooltipMenuModule } from '../../portfolio/tooltip-menu/tooltip-menu.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedIonModule } from 'components/shared/shared-ion.module';
import { MatIconModule } from '@angular/material/icon';
import { PipeModule } from '../../../util/pipes/pipe.module';
import { DcmaAssessmentComponent } from './dcma-assessment/dcma-assessment.component';
import { DcmaRatingComponent } from './dcma-assessment/dcma-rating/dcma-rating.component';
import { DcmaAssessmentIconComponent } from './dcma-assessment/dcma-rating/icons/dcma-rating-icon.component';
import { ScheduleAnalysisComponent } from './schedule-analysis/schedule-analysis.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { GaugeModule } from '../../shared/gauge/gauge.module';
import { GridLayoutModule } from '@progress/kendo-angular-layout';
import { AegisChartModule } from '../../shared/chart/chart.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { LabelModule } from '@progress/kendo-angular-label';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { QcTrendComponent } from './qc-trend/qc-trend.component';
import { OverviewNotesModule } from '../../shared/overview-notes/overview-notes.module';

@NgModule({
	declarations: [
		QcPageComponent,
		DcmaAssessmentComponent,
		DcmaRatingComponent,
		DcmaAssessmentIconComponent,
		ScheduleAnalysisComponent,
		QcTrendComponent,
	],
	imports: [
		CommonModule,
		SharedIonModule,
		TooltipMenuModule,
		MatExpansionModule,
		NgxDatatableModule,
		MatIconModule,
		PipeModule,
		ButtonsModule,
		TooltipsModule,
		GaugeModule,
		GridLayoutModule,
		AegisChartModule,
		GridModule,
		LabelModule,
		TextBoxModule,
		ProgressBarModule,
		SVGIconModule,
		OverviewNotesModule,
	],
	exports: [QcPageComponent, ScheduleAnalysisComponent, DcmaAssessmentComponent, QcTrendComponent],
})
export class QcPageModule {}
