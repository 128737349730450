import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChartModule } from '@progress/kendo-angular-charts';
import { GaugeComponent } from './gauge.component';
import { ArcGaugeModule } from '@progress/kendo-angular-gauges';

@NgModule({
	imports: [CommonModule, ChartModule, ArcGaugeModule],
	declarations: [GaugeComponent],
	exports: [GaugeComponent],
})
export class GaugeModule {}
