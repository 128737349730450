 <div class="bg-content" style="padding-bottom: 5%; padding-left: 1%">
      <div style="font-weight: bold">{{subtitle1}}</div>
		<div *ngIf='ul1'>
			{{sub0}}<b>{{sub01}}</b>{{sub02}}
			<ul>
				<li>{{ul1}}</li>
				<li>{{ul2}}</li>
			</ul>
		</div>
      <div class="indent" *ngIf="highlight">
        {{pre}}<span>{{highlight}}</span>{{post}}
        <div *ngIf="firstParagraph2">
          <div><br /></div>
          {{firstParagraph2}}
        </div>
        <div *ngIf="firstParagraph3 && !isProblematicActivities">
          <div><br /></div>
          {{firstParagraph3}}
        </div>
				<div *ngIf="firstParagraph33 && !isProblematicActivities">
					<div><br /></div>
					{{firstParagraph33}}
				</div>
      </div>
      <div *ngIf="firstParagraph3 && isProblematicActivities">
        <div><br /></div>
        {{firstParagraph3}}
      </div>
      <div class="indent" *ngIf="!highlight && !needsBR">
        {{firstParagraph}}
        <div *ngIf="firstParagraph2">{{firstParagraph2}}</div>
        <div *ngIf="firstParagraph3">{{firstParagraph3}}</div>
      </div>
      <div class="indent" *ngIf="!highlight && needsBR">
        {{firstParagraph}}
        <div *ngIf="firstParagraph2">{{firstParagraph2}}</div>
        <div *ngIf="firstParagraph3">
          <div><br /></div>
          {{firstParagraph3}}
        </div>
      </div>
      <div class="indent" *ngIf="strongRange">
        <div><br /></div>
        <span class="greenColor;">{{strongText}}</span>
        {{strongRange}}
      </div>
      <div class="indent">
        <span class="orangeColor;">{{moderateText}}</span>
        {{moderateRange}}
      </div>
      <div class="indent">
        <span class="redColor;">{{needsImprovementText}}</span>
        {{needsImprovementRange}}
      </div>
      <div style="font-weight: bold" *ngIf="subtitle2">
        <div><br /></div>
        {{subtitle2}}
      </div>
      <div *ngIf="isProblematicActivities" class="indent">
        <div><br /></div>
        {{secondParagraph}}
      </div>
      <div *ngIf="!isProblematicActivities" class="indent">{{secondParagraph}}</div>
      <div class="indent" *ngIf="notes1">
        <div><br /></div>
        {{notes1}}
      </div>
      <div style="font-weight: bold" *ngIf="subtitle3">
        <div><br /></div>
        {{subtitle3}}
      </div>
      <div *ngIf="!subtitle3 && third1"><br /></div>
      <span *ngIf="!isProblematicActivities">
        <div class="indent">{{third1}}</div>
        <div class="indent">{{third2}}</div>
        <div class="indent">{{third3}}</div>
        <div class="indent">{{third4}}</div>
      </span>
      <span *ngIf="isProblematicActivities">
        <div><br /></div>
        <div class="indent">{{third1}}</div>
        <div class="indent">{{third2}}</div>
        <div><br /></div>
        <div class="indent">{{third3}}</div>
        <div><br /></div>
        <div class="indent">{{third4}}</div>
        <div><br /></div><div><br /></div>
        <div>
          {{firstParagraph4}}
        </div>
        <div><br /></div>
        <div class="indent">{{third5}}</div>
        <div class="indent">{{third6}}</div>
        <div><br /></div>
        <div class="indent">{{third7}}</div>
        <div><br /></div>
        <div class="indent">{{third8}}</div>
      </span>
      <div class="indent" *ngIf="subtitle4">
        <div><br /></div>
        <b>{{subtitle4}}</b> - {{p4}}
      </div>
      <div class="indent" *ngIf="subtitle5">
        <div><br /></div>
        <b>{{subtitle5}}</b> - {{p5}}
      </div>
	 <div *ngIf='isSaas'>
		 <div>{{saas1}}</div>
		 <div class="indent">{{saas2}}</div>
		 <div><br /></div>
		 <div>{{saas3}}</div>
		 <div class="indent">{{saas4}}</div>
		 <div><br /></div>
		 <div>{{saas5}}</div>
		 <div><br /></div>
	 </div>
		<span *ngIf='sub11'>
			<b>{{pre11}}</b>{{pre12}}
			<ul>
				<li><b>{{sub11}}</b> - {{p11}}</li>
				<li><b>{{sub12}}</b> - {{p12}}</li>
			</ul>
		</span>
      <div class="indent" *ngIf="subtitle6">
        <div><br /></div>
        <b>{{subtitle6}}</b> -
        <ol>
          <li>{{oItem1}}</li>
          <li>{{oItem2}}</li>
          <li>{{oItem3}}</li>
          <li>{{oItem4}}</li>
        </ol>
      </div>
      <ul *ngIf="item1">
        <li>{{item1}}<b><u>{{bold1}}</u></b>{{restOf1}}</li>
        <li>{{item2}}<b><u>{{bold2}}</u></b>{{restOf2}}</li>
        <li>{{item3}}<b><u>{{bold3}}</u></b>{{restOf3}}</li>
        <li *ngIf="item4">{{item4}}</li>
        <li *ngIf="item5">{{item5}}</li>
      </ul>
      <div *ngIf="rpText" class="indent">
        {{rpText}}
        <div class="indent">
          <div><br /></div>
          <span>{{rp1}}</span><br />
          <span>{{rp2}}</span><br />
          <span>{{rp3}}</span>
        </div>
      </div>
      <div *ngIf="isMilestones">
        <div class="indent">- Activity ID = Unique milestone code in the schedule file</div>
        <div class="indent">- Activity Name = Name of milestone in the schedule file</div>
        <div class="indent">- Current = The projected completion date from the latest update upload</div>
        <div class="indent">- Baseline = The projected completion date from the baseline schedule upload</div>
        <div class="indent">
          - Variance (Baseline) = The difference between the Baseline date and the current date, in calendar days
          <div class="indent">If variance > 0, then current date is ahead of baseline</div>
          <div class="indent">If variance = 0, then current date is equal to baseline</div>
          <div class="indent">If variance < 0, then current date is behind baseline</div>
        </div>
        <div class="indent">- Previous = The project completion date from the previous update upload</div>
        <div class="indent">
          - Variance (Previous) = The difference between the previous date and the current date, in calendar days
          <div class="indent">If variance > 0, then current date is ahead of previous</div>
          <div class="indent">If variance = 0, then current date is equal to previous</div>
          <div class="indent">If variance < 0, then current date is behind previous</div>
        </div>
        <div class="indent">- Chart = Toggles line visibility in the above milestone trending chart</div><br>
        <div style="font-weight: bold">What does this graph represent?</div>
        <div class="indent">
          Similar to the Project Completion Trending graph this represents the selected milestone’s progress over time, the y-axis shows the project gain or slippage in calendar days, and the x-axis represents the schedule updates. Select the milestone in the upper right corner and see a trend for that particular milestone.
        </div>
      </div>
		<div *ngIf='disclaimer' style='font-style: italic'>
			<div><br /></div>{{disclaimer}}
		</div>
 </div>

