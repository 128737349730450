import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
	CheckBoxModule,
	FormFieldModule,
	NumericTextBoxModule,
	RadioButtonModule,
	SliderModule,
} from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ProjectInterface } from '../../../../models/Project';
import { AsyncPipe, NgIf } from '@angular/common';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { RestService } from '../../../../services/common/rest.service';
import { Observable } from 'rxjs';
import { QualityPreferences } from '@rhinoworks/analytics-calculations';
import {
	ActvCodeFilterItem,
	CodeFilterItem,
	SubCodeFilterItem,
} from '../../overview/activity-completion/activity-completion.component';
import {
	DropDownTreesModule,
	MultiSelectComponent,
	MultiSelectModule,
	SharedDirectivesModule,
} from '@progress/kendo-angular-dropdowns';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { ProjectDashboardService } from '../../../../services/project/project.service';
import { caretAltDownIcon } from '@progress/kendo-svg-icons';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { Xer } from '@rhinoworks/xer-parse';
import { LicenseTier } from '../../../../models/auth/account-user';
import { AuthorizationLevel } from '../../../../services/common/navigation-bar-storage.service';

const DEFAULT_QUALITY_PREFERENCES: QualityPreferences = {
	useDCMA: false,
	beiMin: 95,
	useBei: true,
	cpliMin: 95,
	useCpli: true,
	missedTaskMax: 5,
	useMissedTask: true,
	negativeFloatMax: 0,
	useNegativeFloat: true,
	nonFSMax: 10,
	useNonFS: true,
	missingPredSuccMax: 5,
	useMissingPredSucc: true,
	negativeLagMax: 0,
	useNegativeLag: true,
	positiveLagMax: 5,
	usePositiveLag: true,
	hardConstraintsMax: 5,
	useHardConstraints: true,
	highFloatMax: 5,
	useHighFloat: true,
	highFloatDays: 44,
	highDurationMax: 5,
	useHighDuration: true,
	highDurationDays: 44,
	invalidDatesMax: 0,
	useInvalidDates: true,
	unassignedResourceMax: 0,
	useUnassignedResource: false,
	requireCritPath: true,
	excludeActvCodes: [],
	excludeForDuration: true,
	excludeForFloat: true,
	highDurationUseRD: true,
	lagRelationTypes: [],
};

@Component({
	selector: 'app-quality-settings',
	standalone: true,
	imports: [
		CheckBoxModule,
		LabelModule,
		SliderModule,
		FormsModule,
		NumericTextBoxModule,
		NgIf,
		FormFieldModule,
		RadioButtonModule,
		ReactiveFormsModule,
		ButtonModule,
		AsyncPipe,
		DropDownTreesModule,
		SVGIconModule,
		SharedDirectivesModule,
		MultiSelectModule,
	],
	templateUrl: './quality-settings.component.html',
	styleUrl: './quality-settings.component.scss',
})
export class QualitySettingsComponent implements OnInit {
	allActivityCodes: ActvCodeFilterItem[] = [];
	selectedActivityCodes: SubCodeFilterItem[] = [];
	public qualityFormGroup: FormGroup = new FormGroup({
		useDCMA: new FormControl(false, Validators.required),
		beiMin: new FormControl(95, [Validators.required, Validators.min(0), Validators.max(100)]),
		useBei: new FormControl(true, Validators.required),
		cpliMin: new FormControl(95, [Validators.required, Validators.min(0), Validators.max(100)]),
		useCpli: new FormControl(true, Validators.required),
		missedTaskMax: new FormControl(5, [Validators.required, Validators.min(0), Validators.max(100)]),
		useMissedTask: new FormControl(true, Validators.required),
		negativeFloatMax: new FormControl(0, [Validators.required, Validators.min(0), Validators.max(100)]),
		useNegativeFloat: new FormControl(true, Validators.required),
		nonFSMax: new FormControl(10, [Validators.required, Validators.min(0), Validators.max(100)]),
		useNonFS: new FormControl(true, Validators.required),
		missingPredSuccMax: new FormControl(5, [Validators.required, Validators.min(0), Validators.max(100)]),
		useMissingPredSucc: new FormControl(true, Validators.required),
		negativeLagMax: new FormControl(0, [Validators.required, Validators.min(0), Validators.max(100)]),
		useNegativeLag: new FormControl(true, Validators.required),
		positiveLagMax: new FormControl(5, [Validators.required, Validators.min(0), Validators.max(100)]),
		usePositiveLag: new FormControl(true, Validators.required),
		hardConstraintsMax: new FormControl(5, [Validators.required, Validators.min(0), Validators.max(100)]),
		useHardConstraints: new FormControl(true, Validators.required),
		highFloatMax: new FormControl(5, [Validators.required, Validators.min(0), Validators.max(100)]),
		highFloatDays: new FormControl(44, [Validators.required, Validators.min(0)]),
		useHighFloat: new FormControl(true, Validators.required),
		highDurationMax: new FormControl(5, [Validators.required, Validators.min(0), Validators.max(100)]),
		highDurationDays: new FormControl(44, [Validators.required, Validators.min(0)]),
		useHighDuration: new FormControl(true, Validators.required),
		invalidDatesMax: new FormControl(0, [Validators.required, Validators.min(0), Validators.max(100)]),
		useInvalidDates: new FormControl(true, Validators.required),
		unassignedResourceMax: new FormControl(0, [Validators.required, Validators.min(0), Validators.max(100)]),
		useUnassignedResource: new FormControl(false, Validators.required),
		requireCritPath: new FormControl(true, Validators.required),
		excludeActvCodes: new FormControl([], Validators.required),
		excludeForDuration: new FormControl(true, Validators.required),
		excludeForFloat: new FormControl(true, Validators.required),
		highDurationUseRD: new FormControl(true, Validators.required),
		lagRelationTypes: new FormControl([], Validators.required),
	});
	@Input() projectData: ProjectInterface;
	@Output() hasUnsavedChanges = new EventEmitter<boolean>();
	@Output() changes = new EventEmitter<QualityPreferences>();
	@Output() closeWindow = new EventEmitter<void>();
	@Input() saveChangesEvent: Observable<boolean>;
	hasChange = false;
	hasDCMAChange = false;
	caretDown = caretAltDownIcon;
	codesTag: string = '';
	relTypes: Array<{ text: string; value: string }> = [
		{
			text: 'Finish → Start',
			value: 'PR_FS',
		},
		{
			text: 'Finish → Finish',
			value: 'PR_FF',
		},
		{
			text: 'Start → Start',
			value: 'PR_SS1',
		},
		{
			text: 'Start → Finish',
			value: 'PR_SF',
		},
	];
	@ViewChild('lagTypeMultiselect') lagTypeMultiselect: MultiSelectComponent;
	@Input() licenseTier: LicenseTier = 'ANALYTICS-BASIC-APK';
	@Input() authLevel: AuthorizationLevel = 'standard';
	viewOnly: boolean = false;

	constructor(
		private restService: RestService,
		public projectService: ProjectDashboardService,
		public scheduleService: ScheduleStorageService
	) {}

	ngOnInit(): void {
		this.viewOnly =
			this.authLevel === 'viewer'
				? true
				: !this.projectService.companyAccountPermissionSettings.projSettingsQuality[this.authLevel];
		this.qualityFormGroup.patchValue({
			useDCMA: false,
			beiMin: 95,
			useBei: true,
			cpliMin: 95,
			useCpli: true,
			missedTaskMax: 5,
			useMissedTask: true,
			negativeFloatMax: 0,
			useNegativeFloat: true,
			nonFSMax: 10,
			useNonFS: true,
			missingPredSuccMax: 5,
			useMissingPredSucc: true,
			negativeLagMax: 0,
			useNegativeLag: true,
			positiveLagMax: 5,
			usePositiveLag: true,
			hardConstraintsMax: 5,
			useHardConstraints: true,
			highFloatMax: 5,
			useHighFloat: true,
			highFloatDays: 44,
			highDurationMax: 5,
			useHighDuration: true,
			highDurationDays: 44,
			invalidDatesMax: 0,
			useInvalidDates: true,
			unassignedResourceMax: 0,
			useUnassignedResource: false,
			requireCritPath: true,
			excludeActvCodes: [],
			excludeForDuration: true,
			excludeForFloat: true,
			highDurationUseRD: true,
			lagRelationTypes: [],
			...(this.projectService.companyDefaults.quality || {}),
			...(this.projectData.qualityPreferences || {}),
		});
		if (!this.projectData.qualityPreferences?.useDCMA) {
			for (const key in this.qualityFormGroup.controls) {
				if (!['useDCMA', 'excludeActvCodes', 'excludeForDuration', 'excludeForFloat'].includes(key)) {
					this.qualityFormGroup.get(key)?.disable();
				}
			}
		}
		this.hasDCMAChange = Object.keys(DEFAULT_QUALITY_PREFERENCES).some(
			(key) =>
				key !== 'useDCMA' &&
				key !== 'excludeActvCodes' &&
				key !== 'excludeForDuration' &&
				key !== 'excludeForFloat' &&
				this.qualityFormGroup.value[key]?.toString() != DEFAULT_QUALITY_PREFERENCES[key]?.toString()
		);
		this.onCreateGroupFormValueChange();
		this.saveChangesEvent?.subscribe((val) => val && this.saveChanges());
		this.scheduleService
			.grabUpdateXerData(this.projectData.updateIds[this.projectData.updateIds.length - 1])
			.then(async (xerData) => {
				const selectedActivityCodes: SubCodeFilterItem[] = [];
				const savedActCodes: string[] =
					this.projectService.$currentProjectData.value?.qualityPreferences?.excludeActvCodes || [];
				this.allActivityCodes = this.scheduleService.generateActivityCodeFilter(
					new Xer(xerData),
					this.selectedActivityCodes
				);
				const codes: CodeFilterItem[] = [];
				this.allActivityCodes.forEach((type: ActvCodeFilterItem) => {
					type.subCodes.forEach((code: SubCodeFilterItem) => {
						if (
							codes.findIndex(
								(c: SubCodeFilterItem) =>
									c.codeName + '%--//--%' + c.shortName === code.codeName + '%--//--%' + code.shortName
							) === -1
						) {
							codes.push(code);
						}
					});
					const copyOfType = structuredClone(type);
					codes.push(copyOfType as unknown as SubCodeFilterItem);
				});
				savedActCodes.forEach((code: string) => {
					const matchingCode = codes.find((c: SubCodeFilterItem) => c.codeName + '%--//--%' + c.shortName === code);
					if (matchingCode) {
						selectedActivityCodes.push(<CodeFilterItem & { parentName: string; alwaysDisabled: boolean }>matchingCode);
					}
				});
				this.selectedActivityCodes = selectedActivityCodes;
				this.updateTagText(this.selectedActivityCodes);
			});
	}

	onCreateGroupFormValueChange() {
		const initialValue = this.qualityFormGroup.value;
		this.qualityFormGroup.valueChanges.subscribe((value) => {
			this.hasDCMAChange = Object.keys(this.qualityFormGroup.value).some(
				(key) =>
					key !== 'useDCMA' &&
					key !== 'excludeActvCodes' &&
					key !== 'excludeForDuration' &&
					key !== 'excludeForFloat' &&
					this.qualityFormGroup.value[key]?.toString() != DEFAULT_QUALITY_PREFERENCES[key]?.toString()
			);
			this.hasChange = Object.keys(value).some((key) =>
				key === 'excludeActvCodes' || key === 'lagRelationTypes'
					? this.qualityFormGroup.value[key].sort().join(',') != initialValue[key].sort().join(',')
					: this.qualityFormGroup.value[key] != initialValue[key]
			);
			this.hasUnsavedChanges.emit(this.hasChange);
			this.changes.emit(this.hasChange ? this.qualityFormGroup.value : undefined);
			const useDCMA = value.useDCMA;
			for (const key in this.qualityFormGroup.controls) {
				if (
					!['useDCMA', 'excludeActvCodes', 'excludeForDuration', 'excludeForFloat'].includes(key) &&
					useDCMA !== this.qualityFormGroup.get(key)?.enabled
				) {
					if (useDCMA) {
						this.qualityFormGroup.get(key)?.enable();
					} else {
						this.qualityFormGroup.get(key)?.disable();
					}
				}
			}
		});
	}

	resetDCMA() {
		this.qualityFormGroup.patchValue({
			...DEFAULT_QUALITY_PREFERENCES,
			useDCMA: true,
			excludeForDuration: true,
			excludeForFloat: true,
			highDurationUseRD: true,
			lagRelationTypes: [],
		});
	}

	saveChanges() {
		this.closeWindow.emit();
		this.restService
			.post(`project/preferences/quality/${this.projectData._id}`, this.qualityFormGroup.value)
			.subscribe((val) => {
				this.projectData = val.proj;
				this.restService.post(`report/calculate/${this.projectData._id}`, {}).subscribe(
					() => {},
					(response) => {
						console.log('POST call in error', response);
					},
					() => {}
				);
			});
	}

	public itemDisabled(dataItem: { alwaysDisabled: boolean; disabled: boolean }) {
		return dataItem.alwaysDisabled;
	}

	filterChanged(ev: ActvCodeFilterItem[] = []): void {
		const excluded = ev.map((item) => item.codeName + '%--//--%' + item.shortName);
		this.qualityFormGroup.controls.excludeActvCodes.setValue(excluded);
		this.updateTagText(ev);
	}

	updateTagText(ev?: Array<CodeFilterItem & { subCodes?: SubCodeFilterItem[] }>): void {
		if (ev?.length === 0) {
			this.codesTag = '';
		} else {
			const topLevelCode = ev.find((item) => item?.subCodes?.length > 0);
			if (topLevelCode !== undefined) {
				this.codesTag = topLevelCode.name;
			} else {
				this.codesTag = ev?.length === 1 ? ev[0].name : ev?.length + ' codes selected';
			}
		}
	}

	toggleMultiselect(type: string): void {
		this[type].toggle(!this[type].isOpen);
	}

	protected readonly caretAltDownIcon = caretAltDownIcon;
}
