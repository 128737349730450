import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'absolute',
})
export class AbsolutePipe implements PipeTransform {
	transform(value: number): number | undefined {
		return Math.abs(value);
	}
}
