<div style='page-break-inside: avoid; border-radius: 4px; height: 100%;' id="summary">
	<kendo-gridlayout
		[rows]="['42.4px', '127.6px']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='grid'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container overviewHeader'>
			<div class="card-header">
				Summary
			</div>
			<tooltip-open type='summary' [fillColor]="'overview'"></tooltip-open>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1' class="gridlayout-container">
			<kendo-gridlayout
				[rows]="['calc(100% / 3 - (10px / 3))', 'calc(100% / 3 - (10px / 3))', 'calc(100% / 3 - (10px / 3))']"
				[cols]="['calc(25% - 15px / 4)', 'calc(25% - 15px / 4)', 'calc(25% - 15px / 4)', 'calc(25% - 15px / 4)']"
				[gap]="{ rows: 5, cols: 5 }"
				class='grid full-height'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' [rowSpan]="3" class='gridlayout-item details-container shadow'>
					<div class="details-item-row">
						<div class="details-label">
							Client:
						</div>
						<div class="details-val">
							{{$clientName | async}}
						</div>
					</div>
					<div class="details-item-row">
						<div class="details-label">
							Market Segment:
						</div>
						<div class="details-val">
							{{_projectDashboardService.$currentProjectData.value?.projectType}}
						</div>
					</div>
					<div class="details-item-row">
						<div class="details-label">
							Company:
						</div>
						<div class="details-val">
							{{$companyName | async}}
						</div>
					</div>
					<div class="details-item-row">
						<div class="details-label">
							Point of Contact:
						</div>
						<div class="details-val">
							{{$pocName | async}}
						</div>
					</div>
					<div class="details-item-row">
						<div class="details-label">
							Project Type:
						</div>
						<div class="details-val">
							{{$scheduleType | async}}
						</div>
					</div>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2' [rowSpan]="3" class='gridlayout-item details-container shadow'>
					<div class="details-item-row">
						<div class="details-label" style="min-width: 160px;">
							Project Code:
						</div>
						<div class="details-val">
							{{$projectCode | async}}
						</div>
					</div>
					<div class="details-item-row">
						<div class="details-label" style="min-width: 160px;">
							Project Location:
						</div>
						<div class="details-val">
							{{$projectLocation | async}}
						</div>
					</div>
					<div class="details-item-row">
						<div class="details-label" style="min-width: 160px;">
							Finish Milestone:
						</div>
						<div class="details-val">
							{{$finishMilestone | async}}
						</div>
					</div>
					<div class="details-item-row">
						<div class="details-label" style="min-width: 160px;">
							Risk Calculation Method:
						</div>
						<div class="details-val">
							{{$riskCalcMethod | async}}
						</div>
					</div>
					<div class="details-item-row">
						<div class="details-label" style="min-width: 160px;">
							Total Activities:
						</div>
						<div class="details-val">
							{{$totalActivities | async | number: '1.0-0'}}
						</div>
					</div>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='3' class="gridlayout-container summary-data shadow" style="border-radius: 0 0 4px 4px;">
					<div class="centerBoth" [style.color]="($completionVarianceValue | async) >= 0 ? '#4fc931' : '#DF5353'">{{ ($completionVariance | async) || '-' }}</div>
					<div class="filterBoxLabel">{{ $completionVarianceLabel | async }}</div>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='4' class="gridlayout-container summary-data shadow" style="border-radius: 0 0 4px 4px;">
					<div class="centerBoth" [style.color]="($floatIsLarger | async) ? '#4fc931' : '#DF5353'">{{ ($floatConsumption | async) || '-' }}</div>
					<div class="filterBoxLabel">Float Consumption</div>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='2' [col]='3' class="gridlayout-container summary-data shadow">
					<div class="centerBoth" style="color: #DF5353">{{ ($drivingCriticalActivity | async) === 'undefined - undefined' ? 'N/A' : ($drivingCriticalActivity | async) || '-' }}</div>
					<div class="filterBoxLabel">Driving Critical Activity </div>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='2' [col]='4' class="gridlayout-container summary-data shadow">
					<div class="centerBoth" [style.color]="($progressPerformanceValue | async) >= 0 ? '#4fc931' : '#DF5353'">{{ ($progressPerformance | async) || '-' }}</div>
					<div class="filterBoxLabel">Progress Performance</div>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='3' [col]='3' class="gridlayout-container summary-data shadow">
					<div class="centerBoth" style="color: #DF5353">{{ ($qcIssue | async) || '-' }}</div>
					<div class="filterBoxLabel">QC Issue</div>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='3' [col]='4' class="gridlayout-container summary-data shadow">
					<div class="centerBoth" [style.color]="($criticalPathReliability | async) >= 70 ? '#4fc931' : '#DF5353'">
						{{ ($criticalPathReliability | async | number: '1.0-0') }}%
					</div>
					<div class="filterBoxLabel">Critical Path Reliability</div>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
</div>
