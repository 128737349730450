import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SharedMatModule } from 'components/shared/shared-mat.module';
import { RiskSettingsButtonComponent } from './risk-settings-button/risk-settings-button.component';
import { RiskSettingsComponent } from './risk-settings/risk-settings.component';
import { DialogModule, WindowModule } from '@progress/kendo-angular-dialog';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { FormFieldModule, InputsModule, TextAreaModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LabelModule } from '@progress/kendo-angular-label';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { RiskSettingsService } from '../../../../services/project/risk-settings.service';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { RiskDataFieldsComponent } from './risk-settings/risk-data-fields/risk-data-fields.component';

@NgModule({
	declarations: [RiskSettingsButtonComponent, RiskSettingsComponent, RiskDataFieldsComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		IonicModule,
		SharedMatModule,
		DialogModule,
		LayoutModule,
		FormFieldModule,
		TextBoxModule,
		DropDownsModule,
		TextAreaModule,
		LabelModule,
		TreeListModule,
		InputsModule,
		ButtonModule,
		WindowModule,
	],
	exports: [RiskSettingsButtonComponent, RiskSettingsComponent, RiskDataFieldsComponent],
	providers: [RiskSettingsService],
})
export class RiskSettingsModule {}
