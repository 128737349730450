import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ScheduleStorageService } from '../../../services/project/schedule-storage.service';
import { CurrentProjectReport, ProjectDashboardService } from '../../../services/project/project.service';
import {
	Activity,
	ActivityInfo,
	Xer,
	XerActivity,
	XerActivityCode,
	XerActivityType,
	XerData,
	XerTaskActivity,
	XerTaskPredecessor,
} from '@rhinoworks/xer-parse';
import { DropDownTreeComponent, DropDownTreesModule } from '@progress/kendo-angular-dropdowns';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { ActvCodeFilterItem, SubCodeFilterItem } from '../overview/activity-completion/activity-completion.component';
import { AsyncPipe, DecimalPipe, NgIf } from '@angular/common';
import { caretAltDownIcon } from '@progress/kendo-svg-icons';
import {
	ExpandedMetrics,
	findNLeastFloatPaths,
	getSingleUpdateStats,
	ProjectReportInterface,
	ReportGenerator,
	TotalFloatIndexArgs,
} from '@rhinoworks/analytics-calculations';
import { ActivityCompletionModule } from '../overview/activity-completion/activity.completion.module';
import { ProjectOverviewModule } from '../overview/project-overview.module';
import { DrivingPathComponent, MilestoneTreeItem } from '../schedule-updates-list/driving-path/driving-path.component';
import { haveCommonItem } from '../../../util/strings';
import { ExpansionPanelComponent, ExpansionPanelModule, GridLayoutModule } from '@progress/kendo-angular-layout';
import { TooltipMenuModule } from '../../portfolio/tooltip-menu/tooltip-menu.module';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { RestService } from '../../../services/common/rest.service';
import { hasObjChanged } from '../../../util/projects';
import { dataFromXer } from '../../../util/tasks';
import { UpdateInterface } from '../../../models/Update/Task';
import { QcPageModule } from '../qc/qc-page.module';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { periodicFloat, TotalFloatIndex } from '../../../pkg-test/models/scores';

@Component({
	selector: 'app-focus',
	standalone: true,
	imports: [
		DropDownTreesModule,
		SVGIconModule,
		AsyncPipe,
		ActivityCompletionModule,
		ProjectOverviewModule,
		DrivingPathComponent,
		GridLayoutModule,
		TooltipMenuModule,
		TooltipModule,
		ButtonModule,
		NgIf,
		ExpansionPanelModule,
		DecimalPipe,
		QcPageModule,
	],
	templateUrl: './focus.component.html',
	styleUrl: './focus.component.scss',
})
export class FocusComponent implements OnInit {
	icons = {
		caretDown: caretAltDownIcon,
	};
	allActivityCodes: ActvCodeFilterItem[] = [];
	selectedActivityCodes: SubCodeFilterItem[] = [];
	latestSelection: ActvCodeFilterItem[] = [];
	selectedActivityCodesForPanel: Array<
		SubCodeFilterItem & {
			expanded: boolean;
			progressScore: number;
			qcScore: number;
			riskScore: number;
			actvCompletion: number;
			floatConsumption: string;
			floatConsumptionIsLarger: boolean;
		}
	> = [];
	openPanel: SubCodeFilterItem;
	codesTag: string = '';
	progressScore: number = 0;
	qcScore = 0;
	riskScore = 0;
	latestXer: Xer;
	lastActivityCode: string;
	loading: boolean = true;
	hasChanges: boolean = false;
	@ViewChildren('expansionPanels') panels: QueryList<ExpansionPanelComponent>;
	reportByCode = new Map<string, [ProjectReportInterface, ExpandedMetrics]>([]);
	isScreenshotMode: boolean = false;
	allCodeKeys = new Set<string>();
	public groupedActivities: Record<string, MilestoneTreeItem[]> = {};
	public selectedFinishMilestones: Record<string, XerActivity & { entry?: { name: string; id: number } }> = {};
	constructor(
		public scheduleService: ScheduleStorageService,
		public projectService: ProjectDashboardService,
		public restService: RestService
	) {}

	ngOnInit() {
		const updateIds = this.projectService.$currentProjectData.value.updateIds;
		this.scheduleService.grabUpdateXer(updateIds[updateIds.length - 1]).then(async (xerData) => {
			const xer: Xer = new Xer(xerData);
			this.latestXer = xer;
			const selectedActivityCodes: SubCodeFilterItem[] = [];
			const savedActCodes: string[] = this.projectService.$currentProjectData.value?.focusTabSelectedCodes || [];
			this.allActivityCodes = this.scheduleService.generateActivityCodeFilter(xerData, this.selectedActivityCodes);
			const codes: SubCodeFilterItem[] = [];
			this.allActivityCodes.forEach((type: ActvCodeFilterItem) => {
				type.subCodes.forEach((code: SubCodeFilterItem) => {
					if (
						codes.findIndex(
							(c: SubCodeFilterItem) => c.parentId === code.parentId && c.shortName === code.shortName
						) === -1
					) {
						codes.push(code);
					}
				});
				const copyOfType: any = structuredClone(type);
				copyOfType.parentName = undefined;
				codes.push(copyOfType);
			});
			const availableActivitiesByCode = new Map<string, Array<XerActivity & { display: string; taskType: string }>>([]);
			for (const [codeId, activities] of xer.tasksByActivityCodes) {
				const code = xer.activityCodes.get(codeId);
				const actvOptions = Array.from(activities).map((task) => ({
					...task.raw_entry,
					display: `${task.code} - ${task._activity.activityName}`,
					taskType: task._activity.taskType === 'TT_FinMile' ? 'Finish Milestones' : 'All Activities',
					entry: {
						id: task.id,
						name: task.code + ' - ' + task.raw_entry.task_name,
					},
				}));
				availableActivitiesByCode.set(code.codeName + '--//--' + code.shortName, actvOptions);
				const groupedActivities: GroupResult[] = groupBy(actvOptions, [
					{ field: 'taskType' },
				]).reverse() as GroupResult[];
				this.groupedActivities[code.codeName + '%--//--%' + code.shortName] = [
					{
						entry: {
							id: 0,
							name: 'Finish Milestones',
						},
						children: (groupedActivities.find((i) => i.value === 'Finish Milestones')?.items || []) as XerActivity[],
					},
					{
						entry: {
							id: 1,
							name: 'All Activities',
						},
						children: (groupedActivities.find((i) => i.value === 'All Activities')?.items || []) as XerActivity[],
					},
				];
				let latestActv: XerActivity & { display: string; taskType: string };
				let latestFin: Date;
				for (const task of actvOptions) {
					if (
						this.projectService.$currentProjectData.value.preferences?.focusMilestones?.[
							code.codeName + '%--//--%' + code.shortName
						] === task.task_code
					) {
						latestActv = task;
						break;
					}
					const earlyFin = task.early_end_date || task.reend_date;
					if (earlyFin && (!latestFin || new Date(earlyFin).getTime() < latestFin?.getTime())) {
						latestActv = task;
						latestFin = new Date(earlyFin);
					}
				}
				console.log('setting the thing');
				this.selectedFinishMilestones[code.codeName + '%--//--%' + code.shortName] = latestActv;
			}
			for (const code of savedActCodes) {
				await this.getMatchingCode(code, codes).then((val: SubCodeFilterItem) => {
					if (val) {
						selectedActivityCodes.push(val);
					}
				});
			}
			this.allCodeKeys = new Set(codes.map((ac) => ac.codeName + '%--//--%' + ac.shortName));
			this.selectedActivityCodes = selectedActivityCodes;
			const projectData = this.projectService.$currentProjectData.value;
			const xers: Xer[] = [];
			const updates = this.scheduleService.$allUpdates.value || [];
			for (let i = Math.max(0, updateIds.length - 3); i < updateIds.length; i++) {
				await this.scheduleService.grabCalendar(updateIds[i]);
				xers[i] = new Xer(this.scheduleService.cachedXers.get(updateIds[i]));
			}
			this.selectedActivityCodesForPanel = this.selectedActivityCodes
				.filter((code: SubCodeFilterItem) => code?.parentName !== undefined)
				.map((c: SubCodeFilterItem) => {
					const rg = new ReportGenerator(projectData, projectData.preferences.scores, projectData.qualityPreferences);
					rg.selectedActivityCodes = [{ shortName: c.shortName, codeName: c.name }];
					for (let i = 0; i < updates.length; i++) {
						const xer = xers[i];
						const update = updates[i];
						if (!xer) {
							continue;
						}
						rg.nextUpdate(
							{ _id: update._id, ...update },
							xer,
							undefined,
							undefined,
							this.projectService.$currentProjectReport.value?.riskPage.performanceFactor
						);
					}
					return {
						...c,
						expanded: false,
						progressScore: rg.report.progressScore,
						qcScore: rg.report.qualityControl.qcScore,
						riskScore: rg.report.riskScore,
						actvCompletion: null,
						floatConsumption: '',
						floatConsumptionIsLarger: false,
					};
				});
			const tagCodes: ActvCodeFilterItem[] = selectedActivityCodes.map((c: SubCodeFilterItem) => ({
				...c,
				subCodes: c.parentName === undefined ? [null, null] : [],
			}));
			this.latestSelection = tagCodes;
			this.updateTagText(tagCodes);
			this.updateScoresBuffer();
			this.loading = false;
			this.checkForChanges();
		});
		this.scheduleService.$allUpdates.subscribe((updates) => {
			if (updates?.length > 0) {
				this.updateScoresBuffer();
			}
		});
	}

	/**
	 * converts saved code to current update code if available.
	 * codeString stored in format activityTypeId + /%%/ + updateId + /%%/ + activityCode's shortName
	 * @param codeString
	 * @param codes
	 */
	async getMatchingCode(codeString: string, codes: SubCodeFilterItem[]): Promise<SubCodeFilterItem> {
		const split: string[] = codeString.split('/%%/');
		if (split?.length === 1) {
			return this.getCode(split[0], codes);
		} else if (split?.length === 3) {
			const activityTypeId: number = +split[0];
			const savedUpdateId: string = split[1];
			const shortCode: string = split[2];
			const updateIds: string[] = this.projectService.$currentProjectData.value.updateIds;
			const currentUpdateId: string = updateIds[updateIds.length - 1];
			if (savedUpdateId !== currentUpdateId) {
				const currentActvTypes: XerActivityType[] = await this.scheduleService.grabUpdateTable<XerActivityType>(
					currentUpdateId,
					'ACTVTYPE'
				);
				const savedActvTypes: XerActivityType[] = await this.scheduleService.grabUpdateTable<XerActivityType>(
					savedUpdateId,
					'ACTVTYPE'
				);
				const savedActvType: XerActivityType = savedActvTypes.find(
					(t: XerActivityType) => t.actv_code_type_id === activityTypeId
				);
				if (savedActvType) {
					const currentActvType: XerActivityType = currentActvTypes.find(
						(t: XerActivityType) =>
							t.actv_code_type === savedActvType.actv_code_type &&
							t.actv_code_type_scope === savedActvType.actv_code_type_scope
					);
					if (currentActvType) {
						const codesForType: SubCodeFilterItem[] = codes.filter(
							(c: SubCodeFilterItem) => c.parentId === currentActvType.actv_code_type_id
						);
						return codesForType.find((c: SubCodeFilterItem) => c.shortName === shortCode);
					}
				}
			} else {
				const codesForType: SubCodeFilterItem[] = codes.filter((c: SubCodeFilterItem) => c.parentId === activityTypeId);
				return codesForType.find((c: SubCodeFilterItem) => c.shortName === shortCode);
			}
		}
		return undefined;
	}

	getCode(code: string, codes: SubCodeFilterItem[]): SubCodeFilterItem {
		return codes.find((c: SubCodeFilterItem) => c.shortName === code);
	}

	async updateScores() {
		const report: CurrentProjectReport = this.projectService.$currentProjectReport.value;
		const updates: UpdateInterface[] = this.scheduleService.$allUpdates.value;
		const data: XerData = await this.scheduleService.grabUpdateXer(updates[updates.length - 1]?._id);
		const prevData: XerData =
			updates?.length > 1 ? await this.scheduleService.grabUpdateXer(updates[updates.length - 2]?._id) : null;
		const tasks: XerActivity[] = dataFromXer<XerActivity>(data, 'TASK');
		const prevTasks: XerActivity[] = dataFromXer<XerActivity>(prevData, 'TASK');
		const prevActvCodes: XerActivityCode[] = dataFromXer<XerActivityCode>(prevData, 'ACTVCODE');
		const taskActv: XerTaskActivity[] = dataFromXer<XerTaskActivity>(data, 'TASKACTV');
		const prevTaskActv: XerTaskActivity[] = dataFromXer<XerTaskActivity>(prevData, 'TASKACTV');
		await this.scheduleService.grabCalendar(updates[0]._id);
		const xers: Xer[] = [new Xer(this.scheduleService.cachedXers.get(updates[0]._id))];
		for (let i = Math.max(0, updates.length - 3); i < updates.length; i++) {
			await this.scheduleService.grabCalendar(updates[i]._id);
			xers[i] = new Xer(this.scheduleService.cachedXers.get(updates[i]._id));
		}
		this.selectedActivityCodesForPanel.forEach(
			(
				code: SubCodeFilterItem & {
					expanded: boolean;
					progressScore: number;
					qcScore: number;
					riskScore: number;
					actvCompletion: number;
					floatConsumption: string;
					floatConsumptionIsLarger: boolean;
				}
			) => {
				const updateFloatHistoricalByCode: Record<string, TotalFloatIndexArgs> = report?.actvCodeFloatHistorical?.length
					? report?.actvCodeFloatHistorical[report?.actvCodeFloatHistorical?.length - 1]
					: null;
				const prevUpdateFloatHistoricalByCode: Record<string, TotalFloatIndexArgs> =
					report?.actvCodeFloatHistorical?.length > 1
						? report?.actvCodeFloatHistorical[report?.actvCodeFloatHistorical?.length - 2]
						: undefined;
				const recordKey: string = code.codeName + '%--//--%' + code.shortName;
				const updateVal: TotalFloatIndexArgs = updateFloatHistoricalByCode?.[recordKey];
				let totalFloatAllActivities: number = 0;
				let prevTotalFloat: number = 0;
				let prevTotalIncomplete: number = 0;
				let totalIncompleteActivities: number = 0;
				const currentUpdateTaskIds: number[] = taskActv
					.filter((t: XerTaskActivity) => t.actv_code_id === code.id)
					.map((a) => a.task_id);
				const codeInPrevUpdate: XerActivityCode = prevActvCodes.find(
					(c: XerActivityCode) => c.short_name === code.shortName
				);
				const prevUpdateTaskIds: number[] = prevTaskActv
					.filter((t: XerTaskActivity) => t.actv_code_id === codeInPrevUpdate?.actv_code_id)
					.map((a) => a.task_id);
				const filteredTasks: XerActivity[] = tasks.filter((t: XerActivity) => currentUpdateTaskIds.includes(t.task_id));
				const filteredPrevTasks: XerActivity[] = prevTasks.filter((t: XerActivity) =>
					prevUpdateTaskIds.includes(t.task_id)
				);
				if (filteredTasks.length > 0) {
					for (const task of filteredTasks) {
						const totalFloat: number = task.total_float_hr_cnt;
						if (task.act_end_date || totalFloat === undefined) {
							continue;
						}
						totalFloatAllActivities += Math.round(task.total_float_hr_cnt / 8);
						totalIncompleteActivities += 1;
					}
					for (const task of filteredPrevTasks) {
						if (task.act_end_date || task.total_float_hr_cnt === undefined) {
							continue;
						}
						prevTotalFloat += Math.round(task.total_float_hr_cnt / 8);
						prevTotalIncomplete += 1;
					}
				}
				const currentFloat: number = Math.round(totalFloatAllActivities / (totalIncompleteActivities || 1));
				const prevFloat: number =
					this.projectService.$currentProjectData.value?.updateIds?.length > 1
						? Math.round(prevTotalFloat / (prevTotalIncomplete || 1))
						: null;
				const floatDifference: number =
					prevFloat === null ? null : Math.abs(Math.round((currentFloat / prevFloat - 1) * 100));
				code.floatConsumption = isNaN(floatDifference)
					? currentFloat < prevFloat
						? '-100%'
						: '100%'
					: floatDifference === null || floatDifference === Infinity || floatDifference === -Infinity
						? null
						: currentFloat > prevFloat
							? '+' + floatDifference + '%'
							: (currentFloat < prevFloat ? '-' : '') + floatDifference + '%';
				code.floatConsumptionIsLarger = currentFloat > prevFloat;
				const nonCompletedFilteredTasks: XerActivity[] = filteredTasks.filter(
					(t: XerActivity) => t.status_code !== 'TK_Complete'
				);
				code.alwaysDisabled = filteredTasks?.length === 0;
				code.disabled = nonCompletedFilteredTasks?.length === 0;
				if (this.reportByCode.has(code.codeName + '%--//--%' + code.shortName)) {
					const report = this.reportByCode.get(code.codeName + '%--//--%' + code.shortName)[0];
					code.progressScore = report.progressScore;
					code.qcScore = report.qualityControl.qcScore;
					code.riskScore = report.riskScore;
					const actvC = report.activityCompletionGraph.projectTrendGraph.totalCompletionPercentageArray;
					code.actvCompletion = actvC[actvC.length - 1];
				} else if (!code.disabled) {
					const projectData = this.projectService.$currentProjectData.value;
					const rg = new ReportGenerator(projectData, projectData.preferences.scores, projectData.qualityPreferences);
					rg.selectedActivityCodes = [code];

					const drivingSwitch = this.selectedFinishMilestones[code.codeName + '%--//--%' + code.shortName];

					for (let i = 0; i < updates.length; i++) {
						const xer = xers[i];
						const update = updates[i];
						if (!xer) {
							continue;
						}
						let customCritPath: number[];
						if (drivingSwitch) {
							customCritPath = [];
							const longestPathSwitchPaths = findNLeastFloatPaths(xer.activitiesByCode.get(drivingSwitch.task_code), 3);
							let visitedActvs = new Set<Activity>();
							for (const path of longestPathSwitchPaths) {
								customCritPath.push(path.path.find((a) => !visitedActvs.has(a))?._activity.totalFloatHrs || 0);
								visitedActvs = new Set([...path.path, ...visitedActvs]);
							}
						}

						rg.nextUpdate(
							{ _id: update._id, ...update },
							xer,
							undefined,
							undefined,
							this.projectService.$currentProjectReport.value?.riskPage.performanceFactor,
							customCritPath
						);
					}
					const actvC = rg.report.activityCompletionGraph.projectTrendGraph.totalCompletionPercentageArray;
					code.progressScore = rg.report.progressScore;
					code.qcScore = rg.report.qualityControl.qcScore;
					code.riskScore = rg.report.riskScore;
					code.actvCompletion = actvC[actvC.length - 1];
					this.reportByCode.set(code.codeName + '%--//--%' + code.shortName, [rg.report, rg.expandedMetrics]);
				}
			}
		);
		this.allActivityCodes.forEach((type: ActvCodeFilterItem) => {
			let hasAtLeastOneCodeWithTasks: boolean = false;
			let hasAtLeastOneCodeWithNonCompletedTasks: boolean = false;
			type.subCodes.forEach((code: SubCodeFilterItem) => {
				const currentUpdateTaskIds: number[] = taskActv
					.filter((t: XerTaskActivity) => t.actv_code_id === code.id)
					.map((a) => a.task_id);
				const filteredTasks: XerActivity[] = tasks.filter((t: XerActivity) => currentUpdateTaskIds.includes(t.task_id));
				const nonCompletedFilteredTasks: XerActivity[] = filteredTasks.filter(
					(t: XerActivity) => t.status_code !== 'TK_Complete'
				);
				code.alwaysDisabled = filteredTasks?.length === 0;
				code.disabled = nonCompletedFilteredTasks?.length === 0;
				if (filteredTasks?.length > 0) {
					hasAtLeastOneCodeWithTasks = true;
				}
				if (nonCompletedFilteredTasks?.length > 0) {
					hasAtLeastOneCodeWithNonCompletedTasks = true;
				}
			});
			type.alwaysDisabled = !hasAtLeastOneCodeWithTasks;
			type.disabled = !hasAtLeastOneCodeWithNonCompletedTasks;
		});
		this.isScreenshotMode = localStorage.getItem('isScreenshotMode') === 'true';
	}

	filterChanged(ev?: ActvCodeFilterItem[]): void {
		this.latestSelection = ev;
		this.selectedActivityCodesForPanel = this.selectedActivityCodes
			.filter((code: SubCodeFilterItem) => code?.parentName !== undefined)
			.map((c: SubCodeFilterItem) => ({
				...c,
				expanded: false,
				progressScore: null,
				qcScore: null,
				riskScore: null,
				actvCompletion: null,
				floatConsumption: '',
				floatConsumptionIsLarger: false,
			}));
		this.updateTagText(ev);
		this.updateScoresBuffer();
		this.checkForChanges();
	}

	public itemDisabled(dataItem: { alwaysDisabled: boolean; disabled: boolean }) {
		return dataItem.alwaysDisabled;
	}

	updateTagText(ev?: ActvCodeFilterItem[]): void {
		if (ev?.length === 0) {
			this.codesTag = '';
		} else {
			const topLevelCode = ev.find((item) => item?.subCodes?.length > 0);
			if (topLevelCode !== undefined) {
				this.codesTag = topLevelCode.name;
			} else {
				this.codesTag = ev?.length === 1 ? ev[0].name : ev?.length + ' codes selected';
			}
		}
	}

	saveChanges(): void {
		this.loading = true;
		const updateId: string =
			this.projectService.$currentProjectData.value.updateIds[
				this.projectService.$currentProjectData.value.updateIds.length - 1
			];
		const newSelectedCodes: string[] = Array.from(
			this.selectedActivityCodes.map((c: SubCodeFilterItem) => c.parentId + '/%%/' + updateId + '/%%/' + c.shortName)
		);
		this.restService
			.patch(`project/${this.projectService.$currentProjectData.value._id}`, {
				focusTabSelectedCodes: newSelectedCodes,
			})
			.subscribe((val) => {
				console.log('patch res', val);
				this.loading = false;
				this.hasChanges = false;
			});
	}

	public onAction(code: SubCodeFilterItem, index: number): void {
		this.panels.forEach((panel: ExpansionPanelComponent, idx: number) => {
			if (idx !== index && panel.expanded) {
				panel.toggle();
			}
		});
		this.openPanel = structuredClone(code);
		const latest: ActvCodeFilterItem = this.latestSelection.find(
			(c: ActvCodeFilterItem) => c.shortName === code.shortName
		);
		this.doLatest([latest]);
	}

	checkForChanges(): void {
		const lastSaved: string[] = this.projectService.$currentProjectData.value?.focusTabSelectedCodes || [];
		const currentCodes: string[] = Array.from(this.selectedActivityCodes.map((c: SubCodeFilterItem) => c.shortName));
		this.hasChanges = this.selectedActivityCodes?.length && hasObjChanged(lastSaved, currentCodes);
	}

	doLatest(ev: ActvCodeFilterItem[]): void {
		let latestActv: Activity;
		for (const actv of this.latestXer.sortedActivities) {
			if (latestActv && latestActv.targetFinish > actv.targetFinish) {
				continue;
			}
			if (!ev?.length || haveCommonItem(actv.activityCodeIds, new Set(ev.map((item) => item.id)))) {
				latestActv = actv;
			}
		}
		this.lastActivityCode = latestActv?.code;
	}

	updateScoresBuffer(): void {
		let i: number = 0;
		const waitingInterval = setInterval(() => {
			if (
				(this.projectService.$currentProjectReport.value !== undefined &&
					this.scheduleService.$allUpdates.value !== undefined) ||
				i > 500
			) {
				clearInterval(waitingInterval);
				this.updateScores();
			}
			i++;
		}, 200);
	}

	isAllCaps(str: string): boolean {
		return str === str.toUpperCase();
	}

	test(e) {
		e.stopPropagation();
	}

	treeValChange(
		ev: XerActivity & { entry?: { name: string; id: number } },
		dropDownTree: DropDownTreeComponent,
		codeKey: string
	): void {
		if (ev.task_id >= 0) {
			const pref = this.projectService.$currentProjectData.value.preferences;
			pref.focusMilestones = pref.focusMilestones || {};
			pref.focusMilestones[codeKey] = ev.task_code;
			this.restService
				.post('project/preferences/' + this.projectService.$currentProjectData.value._id, {
					data: pref,
				})
				.subscribe((r) => {
					console.log('preferences saved!', r);
				});
			this.selectedFinishMilestones[codeKey] = ev;
			this.reportByCode.delete(codeKey);
			this.updateScoresBuffer();
		}

		/*if (ev.entry.id === 0 || ev.entry.id === 1) {
			this.selectedFinishMilestone = structuredClone(this.prevSelectedFinishMilestone);
		} else {
			this.selectedFinishMilestone = ev;
			if (ev?.entry?.id !== this.prevSelectedFinishMilestone?.entry?.id) {
				this.prevSelectedFinishMilestone = structuredClone(this.selectedFinishMilestone);
				this.prevSelectedFinishMilestone.entry = {
					name: this.prevSelectedFinishMilestone.task_code + ' - ' + this.prevSelectedFinishMilestone.task_name,
					id: this.prevSelectedFinishMilestone.task_id,
				};
				this.loadDrivingPath();
				dropDownTree.toggle(false);
				this.dropdownIsOpen = false;
			}
		}*/
	}
}
