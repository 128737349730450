<div>
	<fieldset style="margin-right: 0;display: flex; gap: 10px">
		<legend>Exclude Activity Codes</legend>
		<kendo-multiselecttree
			kendoMultiSelectTreeSummaryTag
			kendoMultiSelectTreeExpandable
			[kendoMultiSelectTreeHierarchyBinding]="allActivityCodes"
			[(value)]="selectedActivityCodes"
			textField="name"
			valueField="id"
			childrenField='subCodes'
			[valuePrimitive]='false'
			[filterable]='true'
			(valueChange)='filterChanged($event);'
			[clearButton]='false'
			[expandOnFilter]="{expandMatches: true}"
			[itemDisabled]="itemDisabled"
			[placeholder]='"Activity Code Filter"'
			style='width: 300px; margin: auto 0; cursor: pointer;'
			[disabled]='((projectService.$currentProjectData | async)?.updateIds.length || 0) <= 1'
			class="focusActvFilter"
		>
			<ng-template kendoMultiSelectTreeGroupTagTemplate let-dataItems>
									<span>
										<kendo-svg-icon [icon]='caretDown'></kendo-svg-icon>
										{{ codesTag }}
									</span>
			</ng-template>
		</kendo-multiselecttree>

			<ul class="k-radio-list" style="display: flex; flex-direction: row; gap: 10px;">
				<form [formGroup]='qualityFormGroup' style="display: flex; gap: 10px">

					<kendo-formfield showHints="initial" [orientation]="'horizontal'">
				<li class="k-radio-item">
					<input
						type="checkbox"
						#excludeD
						[value]="false"
						kendoRadioButton
						formControlName="excludeForDuration"
					/>
					<kendo-label
						class="k-radio-label"
						[for]="excludeD"
						text="High Duration"
					></kendo-label>
				</li>

					</kendo-formfield>

					<kendo-formfield showHints="initial" [orientation]="'horizontal'">
				<li class="k-radio-item">
					<input
						type="checkbox"
						#excludeF
						[value]="true"
						kendoRadioButton
						formControlName="excludeForFloat"
					/>
					<kendo-label
						class="k-radio-label"
						[for]="excludeF"
						text="High Float"
					></kendo-label>
				</li>

					</kendo-formfield>
				</form>
			</ul>
	</fieldset>
	<fieldset style="margin-right: 0;">
		<legend>Quality Calculation Method</legend>
		<form [formGroup]='qualityFormGroup'>
			<kendo-formfield showHints="initial" [orientation]="'horizontal'">
				<ul class="k-radio-list" style="display: flex; flex-direction: row; gap: 10px;">
					<li class="k-radio-item">
						<input
							type="radio"
							#useDcmaF
							[value]="false"
							kendoRadioButton
							formControlName="useDCMA"
						/>
						<kendo-label
							class="k-radio-label"
							[for]="useDcmaF"
							text="Default"
						></kendo-label>
					</li>
					<li class="k-radio-item">
						<input
							type="radio"
							#useDcmaT
							[value]="true"
							kendoRadioButton
							formControlName="useDCMA"
						/>
						<kendo-label
							class="k-radio-label"
							[for]="useDcmaT"
							text="DCMA"
						></kendo-label>
					</li>
				</ul>
				<kendo-formerror [align]="'end'">Error: This field is required</kendo-formerror>
			</kendo-formfield>
		</form>
	</fieldset>
	<fieldset style="margin-right: 0;">
		<legend>DCMA Options</legend>
		<form [formGroup]="qualityFormGroup" style="max-height: 322px; overflow: auto">
			<button kendoButton [disabled]="!qualityFormGroup.get('useDCMA').value || !hasDCMAChange" (click)="resetDCMA()"
							style="position: sticky; top: 0; float: right; margin-right: 5px; z-index: 30" size="small">Reset</button>
			<div class="dcmaGrid">
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #beicheck formControlName="useBei" ></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="beicheck"
							text="BEI"
						></kendo-label>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 50px auto">
						<span>Min %:</span>
						<kendo-numerictextbox
							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							[max]="100"
							formControlName="beiMin"
						></kendo-numerictextbox>
					</div>
				</div>
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #cplicheck formControlName="useCpli" ></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="cplicheck"
							text="CPLI"
						></kendo-label>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 50px auto">
						<span>Min %:</span>
						<kendo-numerictextbox

							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							[max]="100"
							formControlName="cpliMin"
						></kendo-numerictextbox>
					</div>
				</div>
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #critcheck formControlName="requireCritPath" ></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="critcheck"
							text="Critical Path Test"
						></kendo-label>
					</div>
				</div>
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #hardcheck formControlName="useHardConstraints" ></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="hardcheck"
							text="Hard Constraints"
						></kendo-label>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 50px auto">
						<span>Max %:</span>
						<kendo-numerictextbox

							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							[max]="100"
							formControlName="hardConstraintsMax"
						></kendo-numerictextbox>
					</div>
				</div>
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #durationcheck formControlName="useHighDuration" ></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="durationcheck"
							text="High Duration"
						></kendo-label>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 50px auto">
						<span>Max %:</span>
						<kendo-numerictextbox

							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							[max]="100"
							formControlName="highDurationMax"
						></kendo-numerictextbox>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 75px auto">
						<span>Threshold:</span>
						<kendo-numerictextbox

							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							formControlName="highDurationDays"
						></kendo-numerictextbox>
					</div>
				</div>
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #floatcheck formControlName="useHighFloat" ></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="floatcheck"
							text="High Float"
						></kendo-label>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 50px auto">
						<span>Max %:</span>
						<kendo-numerictextbox

							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							[max]="100"
							formControlName="highFloatMax"
						></kendo-numerictextbox>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 75px auto">
						<span>Threshold:</span>
						<kendo-numerictextbox

							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							formControlName="highFloatDays"
						></kendo-numerictextbox>
					</div>
				</div>
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #invalidcheck formControlName="useInvalidDates" ></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="invalidcheck"
							text="Invalid Dates"
						></kendo-label>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 50px auto">
						<span>Max %:</span>
						<kendo-numerictextbox

							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							[max]="100"
							formControlName="invalidDatesMax"
						></kendo-numerictextbox>
					</div>
				</div>
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #positivecheck formControlName="usePositiveLag" ></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="positivecheck"
							text="Lags"
						></kendo-label>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 50px auto">
						<span>Max %:</span>
						<kendo-numerictextbox

							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							[max]="100"
							formControlName="positiveLagMax"
						></kendo-numerictextbox>
					</div>
				</div>
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #negativelagcheck formControlName="useNegativeLag" ></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="negativelagcheck"
							text="Leads"
						></kendo-label>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 50px auto">
						<span>Max %:</span>
						<kendo-numerictextbox

							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							[max]="100"
							formControlName="negativeLagMax"
						></kendo-numerictextbox>
					</div>
				</div>
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #missingcheck formControlName="useMissingPredSucc" ></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="missingcheck"
							text="Logic"
						></kendo-label>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 50px auto">
						<span>Max %:</span>
						<kendo-numerictextbox

							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							[max]="100"
							formControlName="missingPredSuccMax"
						></kendo-numerictextbox>
					</div>
				</div>
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #missedcheck formControlName="useMissedTask" ></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="missedcheck"
							text="Missed Tasks"
						></kendo-label>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 50px auto">
						<span>Max %:</span>
						<kendo-numerictextbox

							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							[max]="100"
							formControlName="missedTaskMax"
						></kendo-numerictextbox>
					</div>
				</div>
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #negativecheck formControlName="useNegativeFloat" ></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="negativecheck"
							text="Negative Float"
						></kendo-label>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 50px auto">
						<span>Max %:</span>
						<kendo-numerictextbox

							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							[max]="100"
							formControlName="negativeFloatMax"
						></kendo-numerictextbox>
					</div>
				</div>
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #relTypeCheck formControlName="useNonFS" ></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="relTypeCheck"
							text="Relationship Types"
						></kendo-label>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 50px auto">
						<span>Max %:</span>
						<kendo-numerictextbox

							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							[max]="100"
							formControlName="nonFSMax"
						></kendo-numerictextbox>
					</div>
				</div>
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #rsrccheck formControlName="useUnassignedResource" ></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="rsrccheck"
							text="Resources"
						></kendo-label>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 50px auto">
						<span>Max %:</span>
						<kendo-numerictextbox

							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							[max]="100"
							formControlName="unassignedResourceMax"
						></kendo-numerictextbox>
					</div>
				</div>
			</div>
		</form>
	</fieldset>
</div>
<button kendoButton themeColor='success' style='float: right; margin-top: 10px' [disabled]="!hasChange" (click)="saveChanges()">Save</button>
