import { ExcelExportEvent } from '@progress/kendo-angular-grid';
import { loadBinary } from './images';
import { WorkbookSheetRow } from '@progress/kendo-ooxml/dist/npm/ooxml';
import { saveAs, encodeBase64 } from '@progress/kendo-file-saver';
import { Workbook, WorkbookSheetColumn } from '@progress/kendo-angular-excel-export';
import { differenceInDays, isAfter } from 'date-fns';

export const formatExcelExport = (
	e: ExcelExportEvent,
	fileName: string,
	projectName?: string,
	exportTitle?: string,
	roundScores?: boolean,
	titleValue?: string,
	topLeftCellString?: string,
	riskGoldRow?: string,
	isPortfolio?: boolean
) => {
	loadBinary('https://account.rhino.works/static/assets/img/AnalyticsLogo2023_Color.png', async (data, contentType) => {
		console.log(e);
		const rows = e.workbook.sheets[0].rows as WorkbookSheetRow[];
		let rowIndex = 2;
		if (riskGoldRow) {
			rowIndex = 3;
			rows[0].cells.forEach((cell, index) => {
				cell.color = '#000000';
				cell.textAlign = 'center';
			});
			rows[0].cells[0].background = 'rgb(255, 225, 97)';
			rows[0].cells[1].background = 'rgb(245, 194, 194)';
			rows[0].cells[2].background = 'rgb(197, 229, 197)';
			rows[0].cells[3].background = 'rgb(192, 214, 236)';
		} else {
			rows[0].cells.forEach((cell) => {
				cell.background = '#fcb404';
				cell.color = '#000000';
			});
		}
		let maxRowWidth = 4;
		let total_date = new Date(0);
		let total_curr = new Date(0);
		let total_contr = new Date(0);
		let total_contr_change = 0;
		let total_update = 0;
		let total_progress = 0;
		let total_logic = 0;

		rows.forEach((row) => {
			row.index = rowIndex++;
			row.cells.forEach((cell) => {
				cell.wrap = true; //wraps each cell no matter if roundScores is true or false
				if (roundScores) {
					const asNum = +cell.value;
					if (!Number.isNaN(asNum) && asNum > 0 && asNum <= 100) {
						cell.value = Math.round(asNum);
					}
				}
			});
			/*			if (row.index === 5) {
				row.cells.forEach((cell) => {
					cell.background = undefined;
					cell.color = '#000000';
				});
			}*/
			if (row.cells.length > maxRowWidth) {
				maxRowWidth = row.cells.length;
			}

			if (exportTitle === 'Schedule List Data Export') {
				total_date = isAfter(row.cells[2].value as Date, total_date) ? (row.cells[2].value as Date) : total_date;
				total_curr = isAfter(row.cells[3].value as Date, total_curr) ? (row.cells[3].value as Date) : total_curr;
				total_contr = isAfter(row.cells[4].value as Date, total_contr) ? (row.cells[4].value as Date) : total_contr;
				total_contr_change = differenceInDays(total_contr, total_curr);
				total_update = !Number.isNaN(+row.cells[6].value) ? total_update + +row.cells[6].value : total_update;
				total_progress = !Number.isNaN(+row.cells[7].value) ? total_progress + +row.cells[7].value : total_progress;
				total_logic = !Number.isNaN(+row.cells[8].value) ? total_logic + +row.cells[8].value : total_logic;
			}
		});
		const aegisLogoRow: WorkbookSheetRow = {
			index: 0,
			height: 100,
			cells: [
				{
					index: 0,
					value: titleValue,
					color: '#000000',
					fontSize: 29,
					textAlign: 'left',
					colSpan: maxRowWidth,
					wrap: true,
					bold: true,
				},
			],
		};

		const blueRow: WorkbookSheetRow = {
			index: 1,
			height: 25,
			cells: [
				{
					index: 0,
					background: '#011689',
					colSpan: maxRowWidth,
					value: exportTitle,
					textAlign: riskGoldRow ? 'left' : 'center',
					color: '#FFFFFF',
					fontSize: 16,
					bold: true,
				},
			],
		};
		const goldRow: WorkbookSheetRow = {
			index: 2,
			height: 25,
			cells: [
				{
					index: 0,
					background: '#fcb404',
					colSpan: maxRowWidth,
					value: riskGoldRow,
					fontSize: 16,
					bold: true,
					color: '#000000',
					textAlign: 'left',
				},
			],
		};
		const totalRow: WorkbookSheetRow = {
			index: rowIndex,
			height: 25,
			cells: [
				{
					index: 0,
					colSpan: 2,
					value: 'Total',
					wrap: true,
					borderTop: { color: '#000000', size: 2 },
					textAlign: 'center',
				},
				{
					index: 2,
					value: total_date,
					wrap: true,
					borderTop: { color: '#000000', size: 2 },
				},
				{
					index: 3,
					value: total_curr,
					wrap: true,
					borderTop: { color: '#000000', size: 2 },
				},
				{
					index: 4,
					value: total_contr,
					wrap: true,
					borderTop: { color: '#000000', size: 2 },
				},
				{
					index: 5,
					value: total_contr_change,
					wrap: true,
					borderTop: { color: '#000000', size: 2 },
				},
				{
					index: 6,
					value: total_update,
					wrap: true,
					borderTop: { color: '#000000', size: 2 },
				},
				{
					index: 7,
					value: total_progress,
					wrap: true,
					borderTop: { color: '#000000', size: 2 },
				},
				{
					index: 8,
					value: total_logic,
					wrap: true,
					borderTop: { color: '#000000', size: 2 },
				},
				{
					index: 9,
					borderTop: { color: '#000000', size: 2 },
				},
				{
					index: 10,
					borderTop: { color: '#000000', size: 2 },
				},
				{
					index: 11,
					borderTop: { color: '#000000', size: 2 },
				},
			],
		};
		if (exportTitle === 'Schedule List Data Export') {
			rows.push(totalRow);
		}
		if (riskGoldRow) {
			rows.push(aegisLogoRow, blueRow, goldRow);
		} else {
			rows.push(aegisLogoRow, blueRow);
		}
		const images = {
			logo: { data, type: contentType },
		};
		const work = new Workbook({
			// @ts-ignore
			images,
			sheets: [
				{
					...e.workbook.sheets[0],
					drawings: [
						{
							topLeftCell: topLeftCellString,
							width: isPortfolio ? 207 : 222,
							height: 96,
							image: 'logo',
							offsetX: 145,
							offsetY: 2,
						},
					],
				},
			],
		});
		const columns = e.workbook.sheets[0].columns as WorkbookSheetColumn;
		if (exportTitle === 'Risk Register Data Export') {
			columns[0].width = 3;
		}

		const uri = await work.toDataURL();
		saveAs(uri, fileName, { proxyURL: '//demos.telerik.com/kendo-ui/service/export' });
	});
};
