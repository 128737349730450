<div class='card' id="performanceTrendingComponent">
	<kendo-gridlayout
		[rows]="['42.4px', hideComponent ? '150px' : '403px']"
		[cols]="['25%', 'auto']"
		[gap]="{ rows: 0, cols: 0 }"
		class='full-height'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' [colSpan]="2" class='card-header-container' [class.overviewHeader]="isOverview">
			<kendo-gridlayout
				[cols]="['calc(300px)', 'calc(100% - 305px)',]"
				[gap]="{ rows: 0, cols: 5 }"
				class='full-width'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
					<div class="card-header">
						Performance Trending
					</div>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2' class='row-start-aligned-container' style='justify-content: flex-end'>
					<div style='position: relative; display: flex; flex-direction: row; justify-content: flex-end; gap: 10px; padding-right: 10px;'>
						<ion-button
							style="font-size: 13px; --background: #2ebb2e; margin: auto 0;"
							(click)="submit()"
							size='small'
							[disabled]="!saveButtonEnabled"
						>
							<span>
								{{ 'Save' }}
							</span>
						</ion-button>
					</div>
					<span style='display: inline-flex; align-items: center;float: right; gap: 10px;' kendoTooltip>
						@if (isOverview) {
							<button kendoButton themeColor="base" title="Go to source" [imageUrl]="'assets/icons/newIcons/noun-arrow-5544651.svg'" class='header-btn' (click)='projectService.goTo(6)' style="background-color: #ffb500;"></button>
						}
						<button kendoButton themeColor="base" title="Add/Edit Notes" [imageUrl]="'assets/icons/newIcons/noun-notepad-6607257.svg'" class='header-btn overview-notes-icon' (click)='projectService.openNotesWindow({id: 6, name: "Performance Trending"})' [style.background-color]="isOverview ? '#ffb500' : 'white'" style="margin-right: 10px;"></button>
					</span>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>

		@if (hideComponent) {
			<kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="2">
				<div class="warning-div">
					Add Activity Codes to your schedule to enable this component
				</div>
			</kendo-gridlayout-item>
		} @else {
			<kendo-gridlayout-item [row]="2" [col]="1" style='margin: 5px 0 5px 5px; border: 1px solid #ededed; border-radius: 4px;'>
				<app-pf-table-dropdown [fromPerformanceTrendingComponent]="true" (selectedActivityCodesEmitter)="updateSelectedCodes($event)" (activityCodeDropDownNameChange)="activityCodeDropDownNameChange($event)"
															 (cancelEnabledChange)='cancelChange($event)' [saveClicked]='saveClicked'
															 (parentFromActivityCode)="initializeParentMap($event)" (noActivityCodeTypes)="minimizeComponentIfNoOptions($event)" (noSelections)="showNoSelectionText($event)"></app-pf-table-dropdown>
			</kendo-gridlayout-item>
			<kendo-gridlayout-item [row]='2' [col]='2'>
				<div style='height: 400px; margin-bottom: 3px'>
					@if (showNoSelection) {
						<div class="warning-div" style="height: 150px;">
							Select an Activity Code Type from the dropdown to view chart
						</div>
					} @else {
						<app-chart
							[categories]='categories'
							[seriesData]='seriesData'
							[legendVisible]='true'
							[subtitle]="selectedActivityTypeName"
							[valueAxis]='valueAxisItemSettings'
							[isPerformanceTrendingChart]="true"
							[legendClickable]="true"
						></app-chart>
					}
				</div>
			</kendo-gridlayout-item>
		}
		@if (isOverview && hasNotes) {
			<div style="width: calc(100vw - 20px);">
				<app-overview-notes [componentId]="6"></app-overview-notes>
			</div>
		}
	</kendo-gridlayout>
</div>
