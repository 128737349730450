<div>
	<kendo-treeview
		kendoTreeViewExpandable
		[nodes]="ganttService.wbsTreeData"
		textField="wbs_name"
		[children]="fetchChildren"
		[hasChildren]="hasChildren"
		[isExpanded]="isExpanded"
		(collapse)="handleCollapse($event)"
		(expand)="handleExpand($event)"
	>
		<ng-template kendoTreeViewNodeTemplate let-dataItem>
			<span style="padding: 0 2px; border-radius: 4px;" [class.not-included]="ganttService.selectedWbsLevel?.value !== 0 && ganttService.selectedWbsLevel?.value !== null && dataItem?.level > ganttService.selectedWbsLevel?.value">
				{{ dataItem.level + ' - ' + dataItem.wbs_name }}
			</span>
		</ng-template>
	</kendo-treeview>
</div>
