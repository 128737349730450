<div class="group-by-content-container">
	<div class="rows">
		<div class="content-container" [style.height]="fromPresetWindow ? 'calc(100% - 4px)' : 'calc(100% - 55px)'">
			<div class="desc" style="padding-bottom: 10px;">
				You can group activities by WBS or by Activity Codes
			</div>
			<div class="radio">
				<kendo-radiobutton
					#wbs
					value="wbs"
					[(ngModel)]="ganttService.radioSelection"
					(checkedChange)="radioChoiceChanged($event, 'wbs')"
				></kendo-radiobutton>
				<kendo-label
					class="k-radio-label grouping-option-label"
					[for]="wbs"
					text="WBS"
					style="margin-right: 25px;"
				></kendo-label>
				<kendo-radiobutton
					#actvCodes
					value="actvCodes"
					[(ngModel)]="ganttService.radioSelection"
					(checkedChange)="radioChoiceChanged($event, 'actvCodes')"
				></kendo-radiobutton>
				<kendo-label
					class="k-radio-label grouping-option-label"
					[for]="actvCodes"
					text="Activity Codes"
				></kendo-label>
				@if (fromPresetWindow && ganttService.radioSelection !== null) {
					<button size="small" kendoButton [svgIcon]="icons.x" (click)="resetGroupBy()" style="margin-left: 20px; margin-top: 0px; padding: 0px; border-color: rgba(0, 0, 0, 0.24); background-color: rgba(133, 133, 133, 0.06);"></button>
				}
			</div>
			@if (ganttService.radioSelection !== null) {
				<div class="checkbox-row">
					<kendo-checkbox #summaryBarCheckbox [checkedState]="ganttService.hideSummaryBars" (checkedStateChange)="ganttService.checkedStateChange($event)"></kendo-checkbox>
					<kendo-label
						class="k-checkbox-label"
						[for]="summaryBarCheckbox"
						text="Hide Summary Bars"
					></kendo-label>
				</div>
			}
			@if (ganttService.radioSelection === 'wbs') {
				<div class="desc" style="font-size: 12px;">
					Click the arrow buttons to add Levels to the Group By Panel. Click the X button to remove codes from the selection. Selected Wbs Levels must be in order.
				</div>
				<div class="adjustment-row">
					<kendo-gridlayout
						[rows]="['100%']"
						[cols]="['calc(50% - 5px)', 'calc(50% - 5px)']"
						[gap]="{ rows: 0, cols: 10 }"
						class='grid'
						style="height: 100%; width: 100%;"
						[style.padding-bottom]="fromPresetWindow ? '16px' : '0'"
						id="wbsOptionContainer"
					>
						<kendo-gridlayout-item [row]='1' [col]='1' class="gridlayout-container" style="justify-content: flex-start;">
							<div class="panel-title" [style.margin-bottom]="fromPresetWindow ? '0' : '3px'">
								<button kendoButton themeColor="base" title="View Structure" [imageUrl]='"/assets/icons/newIcons/noun-structure-7102606.svg"' (click)='viewStructure()' class="structureBtn"></button>
								Options
								<button kendoButton themeColor="base" title="Move All" [svgIcon]="icons.arrowRight" [disabled]="!currentWbsOptions?.length" (click)='moveAll("wbs")' class="allBtn">All</button>
							</div>
							<div class="options-list">
								@for (level of currentWbsOptions; track level) {
									<div class="option">
										<div class="label" style="width: calc(100% - 22px)">
											{{level.text}}
										</div>
										<span class="btn-row">
											<button kendoButton themeColor="base" title="Move to Group By" [svgIcon]="icons.arrowRight" (click)='moveItem(null, "in", true, level.value)' class="itemBtn"></button>
										</span>
									</div>
								}
							</div>
						</kendo-gridlayout-item>
						<kendo-gridlayout-item [row]='1' [col]='2' class="gridlayout-container" style="justify-content: flex-start;">
							<div class="panel-title" [style.margin-bottom]="fromPresetWindow ? '0' : '3px'">
								Group By
								<button kendoButton themeColor="base" title="Remove All" [svgIcon]="icons.close" [disabled]="!ganttService.selectedWbsLevels?.length" (click)='removeAll("wbs")' class="allBtn">All</button>
							</div>
							<div class="options-list">
								@for (item of ganttService.selectedWbsLevels; track item) {
									<div class="rowItem">
										<kendo-colorpicker
											[value]="ganttService.tempWbsColorStrings[$index]"
											(valueChange)="colorChange($event, $index, colorPicker)"
											[views]="['palette', 'gradient']"
											[clearButton]="false"
											[format]="'hex'"
											class="actvCodesColorPicker"
											#colorPicker
										>
										</kendo-colorpicker>
										<div class="option"style="width: calc(100% - 28px);">
											<span
												style="width: calc(100% - 66px); display: flex; align-items: center;"
											>
												{{item.text}}
											</span>
											<span class="btn-row">
												<button kendoButton themeColor="base" title="Deselect" [svgIcon]="icons.close" (click)='deselectItem(null, true, item.value)' class="itemBtn"></button>
											</span>
										</div>
									</div>
								}
							</div>
						</kendo-gridlayout-item>
					</kendo-gridlayout>
				</div>
			} @else if (ganttService.radioSelection === 'actvCodes') {
				<div class="desc" style="font-size: 12px;">
					Click the arrow buttons to add Activity Codes to the Group By Panel or reorder them within the panel. Click the X button to remove codes from the selection.
				</div>
				<div class="adjustment-row">
					<kendo-gridlayout
						[rows]="['100%']"
						[cols]="['calc(50% - 5px)', 'calc(50% - 5px)']"
						[gap]="{ rows: 0, cols: 10 }"
						class='grid'
						style="height: 100%; width: 100%;"
						[style.padding-bottom]="fromPresetWindow ? '16px' : '0'"
						id="optionContainer"
						#optionContainer
					>
						<kendo-gridlayout-item [row]='1' [col]='1' class="gridlayout-container" style="justify-content: flex-start;">
							<div class="panel-title" [style.margin-bottom]="fromPresetWindow ? '0' : '3px'">
								Options
								<button kendoButton themeColor="base" title="Move All" [svgIcon]="icons.arrowRight" [disabled]="!currentGroupByOptions?.length" (click)='moveAll("actvCodes")' class="allBtn">All</button>
							</div>
							<div class="options-list">
								@for (type of currentGroupByOptions; track type) {
									@if(type.activitiesUnderCode !== undefined && type?.activitiesUnderCode > 0) {
										<div class="option">
											<div class="label" style="width: calc(100% - 22px)">
												{{type?.actv_code_type}} {{type?.scope !== undefined ? '(' + type?.scope + ')' : ''}}
											</div>
											<span class="btn-row">
												<button kendoButton themeColor="base" title="Move to Group By" [svgIcon]="icons.arrowRight" (click)='moveItem(type, "in")' class="itemBtn"></button>
											</span>
										</div>
									} @else {
										<span style='display: inline-flex; align-items: center;float: right' kendoTooltip>
											<div class="option disabled-type" title="No Activities Assigned">
												<div class="label" style="width: calc(100% - 22px)">
													{{type?.actv_code_type}}
												</div>
												<span class="btn-row">
													<button kendoButton themeColor="base" title="Move to Group By" [svgIcon]="icons.arrowRight" [disabled]="true" class="itemBtn"></button>
												</span>
											</div>
										</span>
									}
								}
							</div>
						</kendo-gridlayout-item>
						<kendo-gridlayout-item [row]='1' [col]='2' class="gridlayout-container" style="justify-content: flex-start;">
							<div class="panel-title" [style.margin-bottom]="fromPresetWindow ? '0' : '3px'">
								Group By
								<button kendoButton themeColor="base" title="Remove All" [svgIcon]="icons.close" [disabled]="!ganttService.selectedGroupBy?.length" (click)='removeAll("actvCodes")' class="allBtn">All</button>
							</div>
							<div class="options-list">
								@for (item of ganttService.selectedGroupBy; track item) {
									<div class="rowItem">
										<kendo-colorpicker
											[value]="ganttService.tempWbsColorStrings[$index]"
											(valueChange)="colorChange($event, $index, colorPicker)"
											[views]="['palette', 'gradient']"
											[clearButton]="false"
											[format]="'hex'"
											class="actvCodesColorPicker"
											#colorPicker
										>
										</kendo-colorpicker>
										<div class="option"style="width: calc(100% - 28px);">
											<span
												style="width: calc(100% - 66px); align-items: center; overflow: hidden; position: relative; display: inline-block; text-overflow: ellipsis;"
											>
												{{item?.actv_code_type}}
											</span>
											<span class="btn-row">
												<button kendoButton themeColor="base" title="Move Up" [svgIcon]="icons.arrowUp" (click)='moveItem(item, "up")' class="itemBtn" [disabled]="ganttService.selectedGroupBy[0]?.actv_code_type_id === item?.actv_code_type_id"></button>
												<button kendoButton themeColor="base" title="Move Down" [svgIcon]="icons.arrowDown" (click)='moveItem(item, "down")' class="itemBtn" [disabled]="ganttService.selectedGroupBy[ganttService.selectedGroupBy?.length - 1]?.actv_code_type_id === item?.actv_code_type_id"></button>
												<button kendoButton themeColor="base" title="Deselect" [svgIcon]="icons.close" (click)='deselectItem(item)' class="itemBtn"></button>
											</span>
										</div>
									</div>
								}
							</div>
						</kendo-gridlayout-item>
					</kendo-gridlayout>
				</div>
			}
		</div>
		@if (!fromPresetWindow) {
			<div style='padding: 5px 5px 0 5px; display: flex; justify-content: center; background-color: #c8cad6;'>
				<button style='margin-right: 8px;' kendoButton themeColor="error" title="Reset" [disabled]='ganttService.selectedGroupBy?.length === 0 && ganttService.selectedWbsLevel?.value === 0' (click)='resetGroupBy()'>Reset</button>
				<button kendoButton themeColor="success" title="Apply" [disabled]='isDiffThanCurrent()' (click)="applyGrouping()">Apply</button>
			</div>
		}
	</div>
</div>
