<div class='card' id="riskRegisterComponent">
	<kendo-gridlayout
		[rows]="['42.4px', 'calc(100% - 42.4px)']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='full-width'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' [class.overviewHeader]="isOverview">
			<kendo-gridlayout
				[rows]="['42.4px']"
				[cols]="['200px', 'calc(100% - 205px)']"
				[gap]="{ rows: 0, cols: 5 }"
				class='full-width'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
					<div class="card-header">
						Risk Register	<span class='across-the-screen' *ngIf='isEEVisible'><img class='rhino-spin' src='/assets/icons/aegis/rhino-tooltip-white.svg' (click)='launchEE()'></span>
					</div>
					<tooltip-open type='{{isOverview ? "overview" : "risk-register"}}' [fillColor]="isOverview ? 'overview' : ''"></tooltip-open>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2' class='toolbar-item-container'>
					<span style='display: inline-flex; align-items: center;float: right; gap: 10px;' kendoTooltip>
						<button kendoButton themeColor="base" *ngIf='!$projectData.value?.isArchived' title="Add New Risk" class='header-btn new-img-18' (click)='openNew()' [disabled]='navBarStorage.monteCarloCalculating' [imageUrl]='"/assets/icons/newIcons/noun-library-add-3643876.svg"' [style.background-color]="isOverview ? '#ffb500' : 'white'">
						</button>
						<button kendoButton themeColor="base" *ngIf='!$projectData.value?.isArchived' title="Import" class='header-btn new-img-16' (click)='openImportDialog()' [imageUrl]='"/assets/icons/newIcons/importRisk.svg"' [style.background-color]="isOverview ? '#ffb500' : 'white'"></button>
						<button kendoButton themeColor="base" title="Export Report (XLSX)" class='header-btn' (click)='exportToExcel()' [imageUrl]='"/assets/icons/newIcons/excelExport.svg"' [style.background-color]="isOverview ? '#ffb500' : 'white'">
						</button>
						<button kendoButton [imageUrl]='"/assets/icons/newIcons/filter.svg"' [class.kendo-inline-header-btn]='isFullscreenOpen' title="Quick Filters" themeColor='base' class="header-btn new-filter-icon" [class.active-quickfilter]='tableFilter?.filters.length > 0' #anchor (click)='toggleFilterMenu()' [class.active-col-select]='showFilterToggle' [style.background-color]="isOverview ? '#ffb500' : 'white'">
						</button>
						<kendo-popup
							[anchor]="anchor.element"
							(anchorViewportLeave)="showFilterToggle = false"
							[popupAlign]='{horizontal: "center", vertical: "top"}'
							[popupClass]='"filter-menu"'
							*ngIf="showFilterToggle"
							[class.production-kendo-popup]='isProduction'
						>
							<div>
								<div style='display: flex; justify-content: space-between; padding-bottom: 10px;'>
									<button
										kendoButton
										[size]="'small'"
										fillMode="outline"
										[rounded]="'medium'"
										[themeColor]="'info'"
										(click)='resetFilters()'
										style='display: block; width: 84%;'
									>
										Reset
									</button>
									<button
										kendoButton
										[size]="'small'"
										[rounded]="'medium'"
										fillMode="outline"
										[themeColor]="'primary'"
										style='display: block; width: 15%;'
										(click)="toggleFilterMenu()"
									>
										X
									</button>
								</div>
								<div>
									<div *ngFor='let filter of filterList' class='quick-filter-container'>
										<input
											type="radio"
											name='radio'
											[id]='filter.name'
											[value]='filter.name'
											[(ngModel)]="selectedQuickFilter"
											(ngModelChange)='quickFilterChange(filter)'
											class='radio-button'
											kendoRadioButton />
										<kendo-label [for]="filter.name" class='radio-label'> {{filter.displayLabel}}</kendo-label>
									</div>
								</div>
							</div>
						</kendo-popup>
						<button kendoButton [imageUrl]='"/assets/icons/newIcons/noun-add-column2 1.svg"' [class.kendo-inline-header-btn]='isFullscreenOpen' title="Add/Remove Columns" themeColor='base' class="header-btn new-img-22" #anchor (click)='toggleColumnMenu()' [class.active-col-select]='showColumnToggle' [style.background-color]="isOverview ? '#ffb500' : 'white'">
						</button>
						<kendo-popup
							[anchor]="anchor.element"
							(anchorViewportLeave)="showColumnToggle = false"
							[popupAlign]='{horizontal: "center", vertical: "top"}'
							[popupClass]='"column-toggle-menu"'
							*ngIf="showColumnToggle"
							[class.production-kendo-popup]='isProduction'
						>
							<div>
								<div style='display: flex; justify-content: space-between; padding-bottom: 10px;'>
									<button
										kendoButton
										[size]="'small'"
										fillMode="outline"
										[rounded]="'medium'"
										[themeColor]="'info'"
										(click)='resetColumns()'
										style='display: block; width: 84%;'
									>
										Reset
									</button>
									<button
										kendoButton
										[size]="'small'"
										[rounded]="'medium'"
										fillMode="outline"
										[themeColor]="'primary'"
										style='display: block; width: 15%;'
										(click)="toggleColumnMenu()"
									>
										X
									</button>
								</div>
								<kendo-treeview
									[nodes]="allGroups.groups"
									[children]="children"
									[hasChildren]="hasChildren"
									[isDisabled]='isDisabled'
									textField="title"
									[kendoTreeViewCheckable]="{checkParents: true, checkParents: true}"
									[(checkedKeys)]="visibleRiskPageColumns"
								>
								</kendo-treeview>
							</div>
						</kendo-popup>
						<button kendoButton themeColor="base" [title]=" isFullscreenOpen ? 'Minimize' : 'Expand Risk Register to Full Screen'" class='header-btn' (click)='toggleView()'
										[imageUrl]='isFullscreenOpen ? "/assets/icons/newIcons/minScreen.svg" : "/assets/icons/newIcons/fullScreen2.svg"' [style.background-color]="isOverview ? '#ffb500' : 'white'">
						</button>
						@if (isOverview) {
							<button kendoButton themeColor="base" title="Go to source" [imageUrl]="'assets/icons/newIcons/noun-arrow-5544651.svg'" class='header-btn' (click)='projectDashboardService.goTo(17)' style="background-color: #ffb500; margin: auto 0;"></button>
						}
						<button kendoButton themeColor="base" title="Add/Edit Notes" [imageUrl]="'assets/icons/newIcons/noun-notepad-6607257.svg'" class='header-btn overview-notes-icon' (click)='projectDashboardService.openNotesWindow({id: 17, name: "Risk Register"})' [style.background-color]="isOverview ? '#ffb500' : 'white'" style="margin: auto 10px auto 0;"></button>
					</span>
				</kendo-gridlayout-item>
			</kendo-gridlayout>

		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1'>
			<app-risk-register-table [riskRegisters]='riskRegisters'
															 [tableFilter]='tableFilter'
															 (overrideQuickFilter)='onQuickFilterOverride()'
															 [allHiddenColumns]='hiddenColumns'
															 [isFullscreen]='false'
															 [hiddenColumns]='hiddenColumns.riskPage'
															 [closeClicked]='closeClicked'
															 [disableEdit]='disableEdit'
															 (addRegister)='openNew()'
															 (editRegister)='editRegister($event)'
															 (saveRegisterEmit)='saveRegister($event)'
															 (constructRegisters)='createRegisterClasses()'
															 (deleteRegister)='deleteRisk($event.riskId)'
															 (duplicateRegister)='duplicateRegister($event)'
															 [exportGrid]='exportSubject.asObservable()'
															 [editingRiskId]='editingRiskRegister?.riskId'
															 [fileName]='fileName'></app-risk-register-table>
			@if (isOverview && hasNotes) {
				<app-overview-notes [componentId]="17"></app-overview-notes>
			}
		</kendo-gridlayout-item>
	</kendo-gridlayout>
</div>

<kendo-window
	*ngIf="addRegisterOpen"
	(close)="requestFormClose()"
	[resizable]='false'
	[(top)]="appWindowService.windowPosition.riskAdd.top"
	[(left)]="appWindowService.windowPosition.riskAdd.left"
	[height]="appWindowService.windowPosition.riskAdd.height"
	[width]="appWindowService.windowPosition.riskAdd.width"
	(leftChange)="restrictMovement('riskAdd')"
	(topChange)="restrictMovement('riskAdd')"
	id='addRiskWindow'
	class='form-container'
>
	<kendo-window-titlebar>
		<div class="k-window-title">Risk Register - {{(editingRiskRegister?.riskName | titlecase) || 'New Risk'}} (ID: {{editingRiskRegister === undefined ? nextRiskRegisterId : editingRiskRegister?.riskId}})</div>
		<button kendoWindowMinimizeAction></button>
		<button kendoWindowMaximizeAction></button>
		<button kendoWindowRestoreAction></button>
		<button kendoWindowCloseAction></button>
	</kendo-window-titlebar>
	<app-risk-register-form (registerSave)='saveRegister($event)'
													(formTouch)='setFormTouched($event)'
													[riskMitigationData]='editingRiskRegister'
													[registersByActvCode]='registersByActvCode'
													[editingRiskRegister]='editingRiskRegister'
													[nextRiskId]='nextRiskRegisterId'></app-risk-register-form>
</kendo-window>
<kendo-window
	*ngIf="dialogOpen"
	(close)="closeForm()"
	[resizable]='false'
	[minWidth]="250"
	[(top)]="appWindowService.windowPosition.riskUnsavedChanges.top"
	[(left)]="appWindowService.windowPosition.riskUnsavedChanges.left"
	[height]="appWindowService.windowPosition.riskUnsavedChanges.height"
	[width]="appWindowService.windowPosition.riskUnsavedChanges.width"
	(leftChange)="restrictMovement('riskUnsavedChanges')"
	(topChange)="restrictMovement('riskUnsavedChanges')"
	id='riskUnsavedChangesWindow'
	class='unsave-warning-container'
>
	<kendo-window-titlebar>
		<div class="k-window-title">Please Confirm</div>
		<button kendoWindowMinimizeAction></button>
		<button kendoWindowMaximizeAction></button>
		<button kendoWindowRestoreAction></button>
		<button kendoWindowCloseAction></button>
	</kendo-window-titlebar>
	<div style="margin: 25px; text-align: center;">
		Are you sure you wish to close this entry without saving? Any unsaved changes will be lost.
	</div>
	<kendo-dialog-actions style='margin: 0; padding: 0;'>
		<button kendoButton (click)="closeAll()" fillMode="solid" themeColor='success'>
			Yes
		</button>
		<button kendoButton (click)="dialogOpen = false" fillMode="solid" themeColor="error">No</button>
	</kendo-dialog-actions>
</kendo-window>
<ng-container #riskRegisterContainer>
	<ng-template #riskRegisterContainerTitle>
		<div style="font-size: 18px; line-height: 1.3em;margin-left: 48px">
			{{($projectData | async)?.name }} - Risk Register
		</div>
		<div style="margin-right: 0; margin-left: auto">
			<button kendoWindowCloseAction (click)='toggleView()'></button>
		</div>
	</ng-template>

</ng-container>

<kendo-dialog
	title="You found the easter egg!"
	*ngIf="eeBool"
	(close)="eeBool = false;"
	[minWidth]="250"
	[width]="850"
	style="z-index: 99999;"
>
	<div style="margin: 30px; text-align: center;">
		Billing the {{stateService.timeSinceStart}} seconds you spent looking for it is going to be a fun talk with your boss! <br><br>
		Rhinoworks Analytics v3.0 presented by the Sumatran Rhino
		<blockquote>
			The two-horned Sumatran rhinoceros shares the bleak distinction of world’s most endangered rhino with its regional cousin, the Javan rhino, both species of which are listed as critically endangered.

			The smallest of the five living rhino species, the Sumatran rhino’s hide is dark red-brown in color and covered with patches of short, dark, stiff hair. The hair helps keep mud caked to the body, which cools its skin and protects it from insects.

			The Sumatran rhino’s two horns are considerably smaller than those of their African relatives, the black rhinos and white rhinos. The front horn may grow up to 31 inches but is normally much smaller, while the posterior horn may grow up to three inches but is generally no more than a hump.
		</blockquote>
		<ion-row>
			<ion-col>
				<img src='/assets/icons/aegis/logo-tease3.png' style='height: auto; width: 250px'>
			</ion-col>
			<ion-col>
				<dl>
					<dt>Executive Producer</dt>
					<dd>Dave "The Rhino" Hatwell</dd>
					<dt>Project Lead</dt>
					<dd>Arash "The Negotiator" Khanlarbig</dd>
					<dt>Development Manager</dt>
					<dd>Matthew "The Wizard" Sowden</dd>
					<dt>Development Staff</dt>
					<dd>Richard "The Craftsman" Sevic</dd>
					<dd>Tyler "The Powerhouse" Wannamaker</dd>
				</dl>
			</ion-col>
		</ion-row>

	</div>
	<kendo-dialog-actions>

		<button kendoButton (click)='eeBool = false' themeColor='success'>Rhinos are Awesome!</button>

	</kendo-dialog-actions>
</kendo-dialog>

<kendo-window
	*ngIf="deleteWarningOpen"
	(close)="closeDeleteWarning(false)"
	[resizable]='false'
	[minWidth]="250"
	[(top)]="appWindowService.windowPosition.riskDelete.top"
	[(left)]="appWindowService.windowPosition.riskDelete.left"
	[height]="appWindowService.windowPosition.riskDelete.height"
	[width]="appWindowService.windowPosition.riskDelete.width"
	(leftChange)="restrictMovement('riskDelete')"
	(topChange)="restrictMovement('riskDelete')"
	id='riskDeleteWindow'
>
	<kendo-window-titlebar>
		<div class="k-window-title">Please Confirm</div>
		<button kendoWindowMinimizeAction></button>
		<button kendoWindowMaximizeAction></button>
		<button kendoWindowRestoreAction></button>
		<button kendoWindowCloseAction></button>
	</kendo-window-titlebar>
	<div style="margin: 30px; text-align: center;">
		Are you sure you want to delete this Risk Register?
	</div>
	<kendo-dialog-actions style='margin: 0; padding: 0;'>
		<button kendoButton (click)="closeDeleteWarning(false)">No</button>
		<button kendoButton (click)="closeDeleteWarning(true)" themeColor="primary">
			Yes
		</button>
	</kendo-dialog-actions>
</kendo-window>

<kendo-window
	*ngIf="importDialogOpen"
	(close)="closeImportDialog()"
	[resizable]='false'
	[(top)]="appWindowService.windowPosition.riskImport.top"
	[(left)]="appWindowService.windowPosition.riskImport.left"
	[height]="appWindowService.windowPosition.riskImport.height"
	[width]="appWindowService.windowPosition.riskImport.width"
	(leftChange)="restrictMovement('riskImport')"
	(topChange)="restrictMovement('riskImport')"
	id='riskImportWindow'
	class='form-container'
>
	<kendo-window-titlebar>
		<div class="k-window-title">Risk Register Import</div>
		<button kendoWindowMinimizeAction></button>
		<button kendoWindowMaximizeAction></button>
		<button kendoWindowRestoreAction></button>
		<button kendoWindowCloseAction></button>
	</kendo-window-titlebar>

	<app-import [currentRegisters]='riskRegisters' (saveRegisters)='saveImportedRegisters($event)'></app-import>
</kendo-window>
