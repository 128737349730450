import { Component, HostListener, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ButtonModule, ListModule } from '@progress/kendo-angular-buttons';
import { DatePipe, NgIf } from '@angular/common';
import {
	DropDownListModule,
	DropDownTreeComponent,
	DropDownTreesModule,
	SharedDirectivesModule,
	SharedModule,
} from '@progress/kendo-angular-dropdowns';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { GanttComponent, GanttModule } from '@progress/kendo-angular-gantt';
import { GridLayoutModule } from '@progress/kendo-angular-layout';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { TooltipMenuModule } from '../../../portfolio/tooltip-menu/tooltip-menu.module';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { ProjectDashboardService } from '../../../../services/project/project.service';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { Xer, XerActivity } from '@rhinoworks/xer-parse';
import { taskStatusDictionary, taskTypeDictionary } from '../../qc/schedule-analysis/schedule-analysis.component';
import { differenceInCalendarDays, format, getMonth, isAfter, isBefore, max, min, startOfDay } from 'date-fns';
import { groupBy, GroupResult, orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { caretAltDownIcon } from '@progress/kendo-svg-icons';
import { TreeItem } from '@progress/kendo-angular-treeview';
import { OverviewNotesModule } from '../../../shared/overview-notes/overview-notes.module';

interface MilestoneTreeItem {
	entry: {
		id: number;
		name: string;
	};
	children: XerActivity[];
}

@Component({
	selector: 'app-driving-path',
	standalone: true,
	imports: [
		ButtonModule,
		DatePipe,
		DropDownListModule,
		ExcelExportModule,
		GanttModule,
		GridLayoutModule,
		ListModule,
		NgIf,
		SVGIconModule,
		SharedDirectivesModule,
		TooltipMenuModule,
		TooltipModule,
		SharedModule,
		DropDownTreesModule,
		OverviewNotesModule,
	],
	templateUrl: './driving-path.component.html',
	styleUrl: './driving-path.component.scss',
})
export class DrivingPathComponent implements OnChanges {
	@Input() isOverview: boolean = false;
	@Input() hideNotes: boolean = false;
	@Input() isFocus: boolean = false;
	@Input() showOverlay: boolean = false;
	@ViewChild('drivingPathGantt') public gantt: GanttComponent;
	@ViewChild('dropDownTree') dropDownTree: DropDownTreeComponent;
	public caretAltDown = caretAltDownIcon;
	public availableActivities: Array<XerActivity & { display: string; taskType: string }> = [];
	public groupedActivities: MilestoneTreeItem[];
	public criticalActivities: Array<
		XerActivity & {
			display: string;
			start: Date;
			end: Date;
			'Activity ID': string;
			'Activity Type': string;
			'Activity Status': string;
			'Activity Name': string;
			OD: number;
			RD: number;
			'% Complete': string;
			Start: string;
			Finish: string;
			TF: number;
			Complete: boolean;
			Critical: boolean;
			entry: {
				name: string;
				id: number;
			};
			completionRatio: number;
		}
	>;
	@Input() public defaultFinishMilestoneCode: string;
	public selectedFinishMilestone: XerActivity & { entry?: { name: string; id: number } };
	public prevSelectedFinishMilestone: XerActivity & { entry?: { name: string; id: number } };
	public milestoneSelectorOpen = false;
	public loading = true;
	public slotWidth: number = 150;
	expandedNodes: number[] = [];
	dropdownIsOpen: boolean = false;
	public sort: SortDescriptor[] = [
		{
			field: 'StartGantt',
			dir: 'asc',
		},
	];
	public realSort: SortDescriptor[] = [
		{
			field: 'start',
			dir: 'asc',
		},
	];
	hasNotes: boolean = false;
	constructor(
		public projectService: ProjectDashboardService,
		public scheduleService: ScheduleStorageService
	) {
		this.scheduleService.$allUpdates.subscribe(async (updates) => {
			if (updates.length === projectService.$currentProjectData.value.updateIds.length) {
				this.defaultFinishMilestoneCode ||= updates[updates.length - 1].finishMilestone.task_code;

				const xerData = await this.scheduleService.grabUpdateXer(updates[updates.length - 1]._id);
				const xer = new Xer(xerData);
				this.selectedFinishMilestone ||= xer.activitiesByCode.get(this.defaultFinishMilestoneCode).raw_entry;
				this.selectedFinishMilestone.entry = {
					name: this.selectedFinishMilestone.task_code + ' - ' + this.selectedFinishMilestone.task_name,
					id: this.selectedFinishMilestone.task_id,
				};
				this.prevSelectedFinishMilestone = structuredClone(this.selectedFinishMilestone);
				this.availableActivities = Array.from(xer.activitiesByCode.values())
					.filter(
						(task) =>
							task.code === this.defaultFinishMilestoneCode || (task._activity.taskType !== 'TT_Mile' && !task.finish)
					)
					.map((task) => ({
						...task.raw_entry,
						display: `${task.code} - ${task._activity.activityName}`,
						taskType: task._activity.taskType === 'TT_FinMile' ? 'Finish Milestones' : 'All Activities',
						entry: {
							id: task.id,
							name: task.code + ' - ' + task.raw_entry.task_name,
						},
					}));
				const groupedActivities: GroupResult[] = groupBy(this.availableActivities, [
					{ field: 'taskType' },
				]).reverse() as GroupResult[];
				this.groupedActivities = [
					{
						entry: {
							id: 0,
							name: 'Finish Milestones',
						},
						children: groupedActivities.find((i) => i.value === 'Finish Milestones').items as XerActivity[],
					},
					{
						entry: {
							id: 1,
							name: 'All Activities',
						},
						children: groupedActivities.find((i) => i.value === 'All Activities').items as XerActivity[],
					},
				];
				this.loadDrivingPath(this.defaultFinishMilestoneCode, xer);
			}
		});
		this.projectService.$currentProjectData.subscribe((val) => {
			if (val) {
				const savedNotes = val.componentNotes?.find((n) => n.id === 27)?.notes;
				this.hasNotes = savedNotes?.length && savedNotes[savedNotes?.length - 1]?.note !== '';
			}
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.defaultFinishMilestoneCode) {
			this.selectedFinishMilestone = this.availableActivities.find(
				(i) => i.task_code === changes.defaultFinishMilestoneCode.currentValue
			);
			this.loadDrivingPath(changes.defaultFinishMilestoneCode.currentValue);
		}
	}

	async loadDrivingPath(finMilestoneCode: string = this.selectedFinishMilestone?.task_code, xer?: Xer) {
		this.loading = true;
		const updateIds = this.projectService.$currentProjectData.value.updateIds;
		const xerData = xer?.xerData || (await this.scheduleService.grabUpdateXer(updateIds[updateIds.length - 1]));
		if (!xerData) {
			return;
		}
		xer ||= new Xer(xerData);
		if (!xer.activitiesByCode.has(finMilestoneCode)) {
			return;
		}
		xer.reschedule();
		const finMilestone = xer.activitiesByCode.get(finMilestoneCode);
		let minDate: Date = finMilestone.start || finMilestone._activity.earlyStart;
		let maxDate: Date = finMilestone.finish || finMilestone._activity.earlyFinish;
		// fixes the driving path analysis on prod
		const [a, b] = [finMilestone.earlyFinishStamp, finMilestone.lateFinishStamp];
		this.criticalActivities = orderBy(
			[...xer.activitiesByCode.get(finMilestoneCode).critPath, { prevActivity: finMilestone }]
				.filter((pred) => !pred.prevActivity.finish)
				.map((pred) => {
					const predActv = pred.prevActivity;
					const dailyHrs = predActv.calendar.calendar.day_hr_cnt || 8;
					const taskPctComplete = Math.max(
						0,
						(predActv.targetDurationHrs - predActv.remainingDurationHrs) / predActv.targetDurationHrs
					);
					const start = predActv.start || predActv._activity.earlyStart;
					const finish = predActv.finish || predActv._activity.earlyFinish;
					minDate = min([minDate, start]);
					maxDate = max([maxDate, finish]);
					let completionRatio: number = 0;
					if (isAfter(xer.activitiesByCode.get(this.selectedFinishMilestone.task_code).recalcDate, start)) {
						const durationUntilDataDate: number = differenceInCalendarDays(
							xer.activitiesByCode.get(this.selectedFinishMilestone.task_code).recalcDate,
							startOfDay(start)
						);
						const taskDuration: number = differenceInCalendarDays(startOfDay(finish), startOfDay(start));
						completionRatio = durationUntilDataDate / (taskDuration || 1);
						completionRatio = completionRatio > 1 ? 1 : completionRatio;
					}
					return {
						...predActv.raw_entry,
						display: `${predActv.code} - ${predActv._activity.activityName}`,
						start: startOfDay(start),
						end: startOfDay(finish),
						'Activity ID': predActv.code,
						'Activity Type': taskTypeDictionary[predActv._activity.taskType],
						'Activity Status': taskStatusDictionary[predActv._activity.statusCode],
						'Activity Name': predActv._activity.activityName,
						OD: predActv._activity.targetDurationHrs / dailyHrs,
						RD: predActv._activity.remainingDurationHrs / dailyHrs,
						'% Complete': `${Math.round((taskPctComplete || 0) * 100)}%`,
						Start: `${format(start, 'dd-MMM-yy')}${predActv.start ? ' A' : ''}`,
						StartGantt: `${format(start, 'MMM dd, yyyy')}${predActv.start ? ' A' : ''}`,
						Finish: `${format(finish, 'dd-MMM-yy')}${predActv.finish ? ' A' : ''}`,
						FinishGantt: `${format(finish, 'MMM dd, yyyy')}${predActv.finish ? ' A' : ''}`,
						TF: predActv._activity.totalFloatHrs / dailyHrs,
						Complete: predActv.isComplete,
						Critical: predActv.isCritical,
						entry: {
							name: predActv.code + ' - ' + predActv.raw_entry.task_name,
							id: predActv.id,
						},
						completionRatio,
					};
				}),
			this.realSort
		);
		this.loading = false;
		this.updateSlotWidth(xer.activitiesByCode.get(this.selectedFinishMilestone.task_code).recalcDate);
	}

	updateSlotWidth(lastFinishRecalc: Date): void {
		setTimeout(() => {
			const drivingPathGantt: HTMLElement = document.getElementById('drivingPathGantt') as HTMLElement;
			if (drivingPathGantt) {
				const containerEl: HTMLElement = drivingPathGantt.querySelector('.k-gantt-timeline') as HTMLElement;
				const headerEl = containerEl?.children[0].children[0].children[0].children[0].children[1];
				const numberOfSubdivisions: number = headerEl?.children.length ?? 0;
				const containerWidth: number = containerEl?.getBoundingClientRect().width;
				const containerWidthNormalized: number =
					this.criticalActivities?.length > 12 ? containerWidth - 12 : containerWidth;
				if (numberOfSubdivisions) {
					this.slotWidth = containerWidthNormalized / numberOfSubdivisions;
				}
				setTimeout(() => {
					this.loading = true;
					setTimeout(() => {
						this.loading = false;
						setTimeout(() => {
							let minStart = this.criticalActivities[0].start;
							this.criticalActivities.forEach((bar) => {
								if (isBefore(bar.start, minStart)) {
									minStart = bar.start;
								}
							});
							//show gantt vertical line borders on a yearly basis once zoom is small enough
							if (this.slotWidth <= 20 && minStart !== undefined) {
								const columns = document.getElementsByClassName('k-gantt-columns');
								const row = columns.item(0)?.children[1]?.children[0];
								const startNumber = 13 - getMonth(minStart);
								if (startNumber < row.children.length - 1) {
									for (let i = startNumber; i < row.children.length; i += 12) {
										row.children[i].setAttribute('style', 'border-left: 1px solid rgba(0, 0, 0, 0.08)');
									}
								}
							}
							this.drawDataDateLine(lastFinishRecalc);
						}, 100);
					}, 500);
				}, 200);
			}
		}, 500);
	}

	handleFilterMilestone(value: string) {
		const groupedActivities: GroupResult[] = groupBy(this.availableActivities, [
			{ field: 'taskType' },
		]).reverse() as GroupResult[];
		const finMileOptions: XerActivity[] = groupedActivities.find((i) => i.value === 'Finish Milestones')
			.items as XerActivity[];
		const allOptions: XerActivity[] = groupedActivities.find((i) => i.value === 'All Activities')
			.items as XerActivity[];
		const finMileMatches: XerActivity[] = finMileOptions.filter(
			(s: XerActivity) =>
				s.task_code.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
				s.task_name.toLowerCase().indexOf(value.toLowerCase()) !== -1
		);
		const allMatches: XerActivity[] = allOptions.filter(
			(s: XerActivity) =>
				s.task_code.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
				s.task_name.toLowerCase().indexOf(value.toLowerCase()) !== -1
		);
		const newGroupedActivities: MilestoneTreeItem[] = [];
		if (finMileMatches?.length) {
			newGroupedActivities.push({
				entry: {
					id: 0,
					name: 'Finish Milestones',
				},
				children: finMileMatches,
			});
		}
		if (allMatches?.length) {
			newGroupedActivities.push({
				entry: {
					id: 1,
					name: 'All Activities',
				},
				children: allMatches,
			});
		}
		this.groupedActivities = newGroupedActivities;
	}

	drawDataDateLine(dataDate: Date): void {
		if (!this.gantt) {
			return;
		}
		let i: number = 0;
		const waitForDOMInterval = setInterval(() => {
			const drivingPathGantt: HTMLElement = document.getElementById('drivingPathGantt') as HTMLElement;
			const timeline = drivingPathGantt.querySelector('.k-gantt-timeline') as HTMLElement;
			if (timeline || i > 500) {
				clearInterval(waitForDOMInterval);
				const header = timeline.querySelector('.k-grid-header');
				const minStart: Date = this.gantt.timelineSlots[0].start;
				const maxEnd: Date = this.gantt.timelineSlots[this.gantt.timelineSlots.length - 1].end;
				const totalTimespan: number = differenceInCalendarDays(maxEnd, minStart);
				const daysElapsed: number = differenceInCalendarDays(dataDate, minStart);
				const rightPercent: number = 1 - daysElapsed / (totalTimespan || 1);
				const pxShift: number = this.criticalActivities?.length > 12 ? 2 : 0; // not exact science. 2 is for a shift that kendo's scrollbar brings.
				const rightValue: number = header.clientWidth * rightPercent + pxShift;
				const dataDateLine = document.getElementsByClassName('data-date-gantt-line-2');
				if (dataDateLine?.length) {
					dataDateLine.item(0).setAttribute('style', 'right:' + rightValue + 'px');
				}
				//hides data date line if its past the gantt timeline
				const ganttTimeline = timeline.getBoundingClientRect();
				const dateLine = dataDateLine?.item(0).getBoundingClientRect();
				if (dateLine.left < ganttTimeline.left || dateLine.left > ganttTimeline.right) {
					dataDateLine.item(0).setAttribute('style', 'display: none');
				}
			}
			i++;
		}, 200);
	}

	/**
	 * applies style class to timeline task bars
	 * @param dataItem
	 */
	public taskCallback(
		dataItem: XerActivity & {
			display: string;
			start: Date;
			end: Date;
			'Activity ID': string;
			'Activity Type': string;
			'Activity Status': string;
			'Activity Name': string;
			OD: number;
			RD: number;
			'% Complete': string;
			Start: string;
			Finish: string;
			TF: number;
			Complete: boolean;
			Critical: boolean;
			entry: {
				name: string;
				id: number;
			};
			completionRatio: number;
		}
	): string {
		return dataItem?.Complete
			? 'blue-current-update-gantt'
			: dataItem?.Critical
				? 'red-current-update-gantt'
				: 'green-current-update-gantt';
	}

	/**
	 * A function that checks whether a given node index exists in the expanded keys collection.
	 * If the item ID can be found, the node is marked as expanded.
	 */
	public isNodeExpanded = (node): boolean => this.expandedNodes.indexOf(node.entry.id) !== -1;

	/**
	 * A `nodeCollapse` event handler that will remove the node data item ID
	 * from the collection, collapsing its children.
	 */
	public handleCollapse(args: TreeItem): void {
		this.expandedNodes = this.expandedNodes.filter((id) => id !== args.dataItem.entry.id);
	}

	/**
	 * An `nodeExpand` event handler that will add the node data item ID
	 * to the collection, expanding its children.
	 */
	public handleExpand(args: TreeItem): void {
		this.expandedNodes = this.expandedNodes.concat(args.dataItem.entry.id);
	}

	/**
	 * dropdowntree open event handler
	 */
	open(): void {
		// sets finish milestones as only open dropdown
		this.expandedNodes = [0];
		this.dropdownIsOpen = true;
	}

	treeValChange(ev: XerActivity & { entry?: { name: string; id: number } }, dropDownTree: DropDownTreeComponent): void {
		if (ev.entry.id === 0 || ev.entry.id === 1) {
			this.selectedFinishMilestone = structuredClone(this.prevSelectedFinishMilestone);
		} else {
			this.selectedFinishMilestone = ev;
			if (ev?.entry?.id !== this.prevSelectedFinishMilestone?.entry?.id) {
				this.prevSelectedFinishMilestone = structuredClone(this.selectedFinishMilestone);
				this.prevSelectedFinishMilestone.entry = {
					name: this.prevSelectedFinishMilestone.task_code + ' - ' + this.prevSelectedFinishMilestone.task_name,
					id: this.prevSelectedFinishMilestone.task_id,
				};
				this.loadDrivingPath();
				dropDownTree.toggle(false);
				this.dropdownIsOpen = false;
			}
		}
	}

	disableClose(event): void {
		event.preventDefault();
	}

	@HostListener('window:mousedown', ['$event'])
	mouseDown(event) {
		//closes milestone dropdown popup if clicked outside the popup
		if (this.dropdownIsOpen) {
			const milestonesDropdown: DOMRect = document
				.getElementsByClassName('drivingPathDropdownTree')
				.item(0)
				?.getBoundingClientRect();
			if (
				milestonesDropdown &&
				event.x >= milestonesDropdown.left &&
				event.x <= milestonesDropdown.right &&
				event.y >= milestonesDropdown.top &&
				event.y <= milestonesDropdown.bottom
			) {
				return;
			} else {
				const dropdownBar: DOMRect = document
					.getElementsByClassName('drivingMileTree')
					.item(0)
					?.getBoundingClientRect();
				if (
					dropdownBar &&
					event.x >= dropdownBar.left &&
					event.x <= dropdownBar.right &&
					event.y >= dropdownBar.top &&
					event.y <= dropdownBar.bottom
				) {
					//delayed close bc clicking the bar opens it first
					setTimeout(() => {
						this.closeMilestoneDropdown();
					}, 500);
				} else {
					this.closeMilestoneDropdown();
				}
			}
		}
	}

	closeMilestoneDropdown(): void {
		this.dropdownIsOpen = false;
		this.dropDownTree.toggle(false);
	}

	updateSort(sort): void {
		const conversion = [
			{
				field: 'StartGantt',
				valueField: 'start',
			},
			{
				field: 'FinishGantt',
				valueField: 'end',
			},
		];
		this.sort = sort;
		this.realSort = structuredClone(sort);
		const matchingConversion = conversion.find((c) => c.field === sort[0].field);
		if (matchingConversion) {
			this.realSort[0].field = matchingConversion.valueField;
		}
		this.criticalActivities = orderBy(this.criticalActivities, this.realSort);
	}
}
