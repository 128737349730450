<div id='top-risks-container'>
	<kendo-gridlayout
		[rows]="['auto']"
		[cols]="['calc((100% / 3) - (10px / 3))', 'calc((100% / 3) - (10px / 3))', 'calc((100% / 3) - (10px / 3))']"
		[gap]="{ rows: 0, cols: 5 }"
		class='grid'
	>
		<kendo-gridlayout-item [row]='1' [col]='1'>
			<div class='card'>
				<kendo-gridlayout
					[rows]="['42.4px', 'calc(100% - 42.4px)']"
					[cols]="['100%']"
					[gap]="{ rows: 0, cols: 0 }"
					class='grid'
				>
					<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' [class.overviewHeader]="isOverview">
						<div class="card-header">
							Top Risks by Probability
						</div>
					</kendo-gridlayout-item>
					<kendo-gridlayout-item [row]='2' [col]='1' class="grid-wrapper">
						<kendo-grid
							[data]="gridViewProb"
							[kendoGridBinding]="gridDataProb"
							[style.max-height]="195"
							[size]='"small"'
							[sortable]='false'
							[navigable]="false"
							class='top-risks-grid'
						>
							<kendo-grid-column
								field="riskId"
								title="ID"
								[width]='40'
								[headerClass]='["list-header"]'
								[class]='["list-col"]'
							>
							</kendo-grid-column>
							<kendo-grid-column
								field="riskName"
								title="Name"
								[headerClass]='["list-header"]'
								[class]='["list-col"]'
							>
							</kendo-grid-column>
							<kendo-grid-column
								field="mitStatus"
								title="Status"
								[width]='115'
								[headerClass]='["list-header"]'
								[class]='["list-col"]'
							>
							</kendo-grid-column>
							<kendo-grid-column
								field="preMitigation.probability"
								title="Probability"
								[width]='90'
								[headerClass]='["list-header"]'
								[class]='["list-col"]'
							>
								<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
									<span class='whole-cell' style='padding: 0;'>
										{{ dataItem.preMitigation.probability }}
									</span>
								</ng-template>
							</kendo-grid-column>
						</kendo-grid>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			</div>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='1' [col]='2'>
			<div class='card'>
				<kendo-gridlayout
					[rows]="['42.4px', 'calc(100% - 42.4px)']"
					[cols]="['100%']"
					[gap]="{ rows: 0, cols: 0 }"
					class='grid'
				>
					<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' [class.overviewHeader]="isOverview">
						<div class="card-header">
							Top Risks by Activity Impact (Pre-Mitigation)
						</div>
					</kendo-gridlayout-item>
					<kendo-gridlayout-item [row]='2' [col]='1' class="grid-wrapper">
						<kendo-grid
							[data]="gridViewActivityImpact"
							[kendoGridBinding]="gridDataActivityImpact"
							[style.max-height]="195"
							[size]='"small"'
							[sortable]='false'
							[navigable]="false"
							class='top-risks-grid'
							*ngIf='gridDataActivityImpact.length > 0'
						>
							<kendo-grid-column
								field="riskId"
								title="ID"
								[width]='40'
								[headerClass]='["list-header"]'
								[class]='["list-col"]'
							>
							</kendo-grid-column>
							<kendo-grid-column
								field="riskName"
								title="Name"
								[headerClass]='["list-header"]'
								[class]='["list-col"]'
							>
							</kendo-grid-column>
							<kendo-grid-column
								field="mitStatus"
								title="Status"
								[width]='115'
								[headerClass]='["list-header"]'
								[class]='["list-col"]'
							>
							</kendo-grid-column>
							<kendo-grid-column
								field="preMitigation.activityImpact"
								title="Activity Impact"
								[width]='120'
								[headerClass]='["list-header"]'
								[class]='["list-col"]'
							>
								<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
									<span class='whole-cell' style='padding: 0;'>
										{{ ((dataItem.preMitigation.activityImpact || 0) * 100).toFixed(0) }}%
									</span>
								</ng-template>
							</kendo-grid-column>
						</kendo-grid>
						<div *ngIf='gridDataActivityImpact.length === 0' class='warning-div'>
							Add Pre-Mitigation Activity Impacts to your risks
						</div>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			</div>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='1' [col]='3'>
			<div class='card'>
				<kendo-gridlayout
					[rows]="['42.4px', 'calc(100% - 42.4px)']"
					[cols]="['100%']"
					[gap]="{ rows: 0, cols: 0 }"
					class='grid'
				>
					<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' [class.overviewHeader]="isOverview">
						<div class="card-header">
							Top Risks by Risk Score (Pre-Mitigation)
						</div>
					</kendo-gridlayout-item>
					<kendo-gridlayout-item [row]='2' [col]='1' class="grid-wrapper">
						<kendo-grid
							[data]="gridViewRiskScore"
							[kendoGridBinding]="gridDataRiskScore"
							[style.max-height]="195"
							[size]='"small"'
							[sortable]='false'
							[navigable]="false"
							class='top-risks-grid'
						>
							<kendo-grid-column
								field="riskId"
								title="ID"
								[width]='40'
								[headerClass]='["list-header"]'
								[class]='["list-col"]'
							>
							</kendo-grid-column>
							<kendo-grid-column
								field="riskName"
								title="Name"
								[headerClass]='["list-header"]'
								[class]='["list-col"]'
							>
							</kendo-grid-column>
							<kendo-grid-column
								field="mitStatus"
								title="Status"
								[width]='115'
								[headerClass]='["list-header"]'
								[class]='["list-col"]'
							>
							</kendo-grid-column>
							<kendo-grid-column
								field="preMitigationRiskScore"
								title="Risk Score"
								[width]='90'
								[headerClass]='["list-header"]'
								[class]='["list-col"]'
							>
								<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
									<span class='whole-cell' style='padding: 0;'>
										{{ dataItem.preMitigationRiskScore }}
									</span>
								</ng-template>
							</kendo-grid-column>
						</kendo-grid>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			</div>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
</div>
