<fieldset [style.height.px]="running? 408 : 400" [ngClass]="{'padding-db': fromNewProject}">
	<legend>Edit/Add Schedule Sync</legend>
	<form [formGroup]="scheduleFormGroup" style="padding-top: 5px">
		<!-- Row 1 -->
		<div [ngClass]="{'form-row': fromNewProject}">
			<kendo-formfield showHints="initial" class="sync-fields">
				<label>Connection</label>
				<kendo-combobox
					[disabled]="fieldsDisabled"
					formControlName="connection"
					[(ngModel)]='databaseInfo.selectedConnection'
					(valueChange)='loadNewDatabasesToSelect($event)'
					[data]="selectionConnectionList"
					[textField]="'friendly_name'"
					[valueField]="'id'"
					[filterable]="true"
					(filterChange)="this.selectionConnectionList = autoComplete($event, this.rawSelectionConnectionList, 'connection')"
				>
				</kendo-combobox>
				<kendo-formerror [align]="'end'">Error: This field is required</kendo-formerror>
			</kendo-formfield>
		</div>

		<!-- Row 2 -->
		<div [ngClass]="{'form-row': fromNewProject}">
			<kendo-formfield showHints="initial" class="sync-fields">
				<label>Database</label>
				<kendo-combobox
					[disabled]="fieldsDisabled"
					formControlName="database"
					[(ngModel)]='databaseInfo.database'
					(valueChange)='loadNewFilterCodesAndProjects($event)'
					[data]="selectionDatabaseList"
					[textField]="'name'"
					[valueField]="'id'"
					[valuePrimitive]="true"
					[placeholder]="'- Select -'"
					[filterable]="true"
					(filterChange)="this.selectionDatabaseList = autoComplete($event, this.rawSelectionDatabaseList, 'database')"
				>
				</kendo-combobox>
				<kendo-formerror [align]="'end'">Error: This field is required</kendo-formerror>
			</kendo-formfield>
		</div>

		<!-- Row 3 -->
		<div [ngClass]="{'form-row': fromNewProject}">
			<kendo-formfield showHints="initial" class="sync-fields">
				<label>Mode</label>
				<kendo-dropdownlist
					[disabled]="fieldsDisabled"
					formControlName="mode"
					[ngModel]="selectedMode"
					[data]="modeOptions"
					textField="text"
					valueField="value"
					(valueChange)="adjustScheduleSelectFromMode($event)"
				></kendo-dropdownlist>
				<kendo-formerror [align]="'end'">Error: This field is required</kendo-formerror>
			</kendo-formfield>
		</div>

		<!-- Row 4 (Two dropdowns on the same line) -->
		@if (filtersShowing) {
			<div class="same-row-grouping">
				<kendo-formfield style="height: 51px;">
					<kendo-label text="Filter Code"></kendo-label>
					<kendo-combobox
						[disabled]="fieldsDisabled"
						formControlName="filterCode"
						[(ngModel)]='databaseInfo.filterCode'
						(valueChange)='checkPCatFilterRequest($event)'
						[data]="selectionPCatList"
						[textField]="'name'"
						[valueField]="'id'"
						[valuePrimitive]="true"
						[placeholder]="'- Select -'"
						[filterable]="true"
						(filterChange)="this.selectionPCatList = autoComplete($event, this.rawSelectionPCatList, 'filterCode')"
					>
					</kendo-combobox>
					<kendo-formerror [align]="'end'">Error: This field is required</kendo-formerror>
				</kendo-formfield>

				<kendo-formfield style="height: 51px;">
					<kendo-label text="Code Value"></kendo-label>
					<kendo-combobox
						[disabled]="fieldsDisabled"
						formControlName="filterValue"
						[(ngModel)]='databaseInfo.filterCodeValue'
						(valueChange)='filterValueChangeRequest($event)'
						[data]="selectionPCatValList"
						[textField]="'value'"
						[valueField]="'id'"
						[valuePrimitive]="true"
						[placeholder]="'- Select -'"
						[filterable]="true"
						(filterChange)="this.selectionPCatValList = autoComplete($event, this.rawSelectionPCatValList, 'filterCodeValue')"
					>
					</kendo-combobox>
					<kendo-formerror [align]="'end'">Error: This field is required</kendo-formerror>
				</kendo-formfield>
			</div>
		}

		<!-- Row 5 -->
		<div [ngClass]="{'form-row': fromNewProject}" [hidden]="filtersShowing">
			<kendo-formfield showHints="initial" class="sync-fields">
				<label>{{onlyCanSelectSingleSchedule ? 'Select Schedule' : 'Select Folder'}}</label>
				<kendo-dropdowntree
					formControlName="schedule"
					class="project-tree"
					style="background-color: transparent !important;"
					kendoDropDownTreeExpandable
					[ngModel]="preLoadedProject"
					(valueChange)='handleProjectNumber($event)'
					[kendoDropDownTreeHierarchyBinding]="selectionProjectList"
					(close)="disableCloseForParents($event)"
					[textField]="'item.wbs_name'"
					[valueField]="'item.id'"
					[placeholder]="'- Select -'"
					[childrenField]="'children'"
					[disabled]="fieldsDisabled"
				>
					<ng-template kendoDropDownTreeNodeTemplate let-dataItem>
						<span [ngClass]="{'disabled-item': dataItem.item.proj_flag === (onlyCanSelectSingleSchedule ? 'N' : 'Y')}">
							{{ dataItem.item.wbs_name }}
						</span>
					</ng-template>
				</kendo-dropdowntree>
				<kendo-formerror [align]="'end'">Error: This field is required</kendo-formerror>
			</kendo-formfield>
		</div>

		<!-- Row 6 -->
		<div [ngClass]="{'form-row': fromNewProject}">
			<kendo-formfield showHints="initial" class="sync-fields">
				<label>Frequency</label>
				<kendo-dropdownlist
					[disabled]="fieldsDisabled"
					formControlName="frequency"
					[ngModel]="selectedFrequency"
					[data]="frequencyOptions"
					textField="text"
					valueField="value"
					(valueChange)="updateFrequency($event)"
				></kendo-dropdownlist>
				<kendo-formerror [align]="'end'">Error: This field is required</kendo-formerror>
			</kendo-formfield>
		</div>

		<div [ngClass]="{'form-row': fromNewProject}" [hidden]="syncFrequency !== 'weekly'">
			<kendo-formfield showHints="initial" class="sync-fields">
				<label>Select day of week</label>
				<kendo-dropdownlist
					[disabled]="fieldsDisabled"
					formControlName="weeklySelection"
					[ngModel]="weeklySelectionValueObject"
					[data]="weeklySelectionOptions"
					textField="text"
					valueField="value"
					(valueChange)="updateWeeklySelection($event)"
				></kendo-dropdownlist>
				<kendo-formerror [align]="'end'">Error: This field is required</kendo-formerror>
			</kendo-formfield>
		</div>

		<div [ngClass]="{'form-row': fromNewProject}" [hidden]="syncFrequency !== 'monthly'" style="margin-top: 9px">
			<kendo-formfield showHints="initial" class="sync-fields">
				<label style="padding-right: 5px;">Enter a day of the month (1-31)</label>
				<kendo-numerictextbox
					[decimals]="0"
					format="##"
					[disabled]="fieldsDisabled"
					formControlName="monthlySelection"
					[ngModel]="monthlySelectionValue"
					[style.width.px]="170"
					[autoCorrect]="true"
					[min]="1"
					[max]="31"
					(valueChange)="updateMonthlySelection($event)"
				></kendo-numerictextbox>
				<kendo-formerror [align]="'end'">Error: This field is required</kendo-formerror>
			</kendo-formfield>
		</div>

		<!-- Bottom Row (Checkbox and Submit Button) -->
		<div class="form-bottom">
			<div class="checkbox-container">
				<kendo-formfield showHints="initial" class="sync-fields">
					<kendo-checkbox formControlName="isActive" [disabled]="fieldsDisabled" (checkedStateChange)="updateSyncActive($event)" [checkedState]="isSyncActive"></kendo-checkbox>
					<kendo-label text="Sync is Active"></kendo-label>
				</kendo-formfield>
			</div>

			<div class="buttons-form-bottom">
				@if (this.hasExistingSync) {
					@if (!fromBulkUpdate) {
						<button kendoButton
										themeColor="error"
										(click)="removeSyncSetting()"
						>
							Delete
						</button>
					}

					<button kendoButton
									[hidden]="editClicked"
									themeColor="warning"
									(click)="editSyncSetting()"
					>
						Edit
					</button>
					<button kendoButton
									[hidden]="!fieldsDisabled"
									[disabled]="running"
									themeColor="success"
									(click)="runSyncSetting()"
									style="position: relative;"
									[style.margin-right.px]="running ? 28 : 0"
					>
						<kendo-circularprogressbar
							style="transform: scale(0.1); position: absolute; left: -9px; top: -86px;"
							[indeterminate]="true"
							[value]="30"
							[progressColor]='"#ffb500"'
							*ngIf="running"
						></kendo-circularprogressbar>
						{{running ? 'Running' : 'Run Now'}}
					</button>
				}

				<button kendoButton
								[hidden]="fieldsDisabled"
								[disabled]="!this.scheduleFormGroup.valid"
								themeColor="success"
								(click)="onSubmit()"
				>
					{{editClicked ? 'Save' : 'Add'}}
				</button>
			</div>
		</div>
		@if (running) {
			<div style="display: flex; flex-direction: row; justify-content: end;">
				Page will automatically refresh if new schedules are available
			</div>
		}
	</form>
</fieldset>
