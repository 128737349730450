import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import {
	customFieldOptionsMapping,
	emptyRegister,
	filterMenuFilter,
	PostMitigationRisk,
	PrePostMitigationRisk,
	RiskRegister,
} from '../../../../../models/risk';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import {
	AddEvent,
	CancelEvent,
	CellClickEvent,
	CellCloseEvent,
	ColumnBase,
	ColumnResizeArgs,
	ExcelExportEvent,
	FilterService,
	GridComponent,
	GridDataResult,
	RemoveEvent,
	RowClassArgs,
	SaveEvent,
} from '@progress/kendo-angular-grid';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { CompositeFilterDescriptor, filterBy, process, State } from '@progress/kendo-data-query';
import { SortDescriptor } from '@progress/kendo-data-query/dist/npm/sort-descriptor';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Keys } from '@progress/kendo-angular-common';
import {
	RiskRegisterEditService,
	riskRegisterFormControl,
	riskRegisterFormGroup,
} from '../../../../../services/project/register-edit.service';
import { map } from 'rxjs/operators';
import { RestService } from '../../../../../services/common/rest.service';
import { riskFormPresets } from '../risk-register-form/risk-register-form.component';
import { ProjectDashboardService } from '../../../../../services/project/project.service';
import { Optionalize } from '../../../../../util/types';
import { formatExcelExport } from '../../../../../util/excel';
import { cleanDateUTC } from '../../../../../util/pipes/date.pipe';
import { NavigationBarStorageService } from '../../../../../services/common/navigation-bar-storage.service';
import {
	columnsIcon,
	copyIcon,
	fileExcelIcon,
	filterIcon,
	importIcon,
	minusIcon,
	pencilIcon,
	plusIcon,
	saveIcon,
	SVGIcon,
	trashIcon,
} from '@progress/kendo-svg-icons';
export const allGroups = require('./risk-register-columns.json') as {
	groups: RiskRegisterColumnGroup[];
};
export type RiskRegisterColumn = {
	field: string;
	title: string;
	isRiskScore?: boolean;
	filterable?: boolean;
	sortable?: boolean;
	width?: number;
	__width?: number;
	fullscreenWidth?: number;
	fullscreenTitle?: string;
	group?: string;
	default?: boolean;
	alwaysVisible?: boolean;
	filterType?: 'numeric' | 'text' | 'date' | 'boolean';
	dir?: 'desc' | 'asc';
	sticky?: boolean;
	editor?: 'text' | 'numeric' | 'date' | 'boolean';
	options?: string[];
	allowCustom?: boolean;
	required?: boolean;
	editable?: boolean;
};

export type RiskRegisterColumnGroup = {
	title: string;
	bgColor?: string;
	columns: RiskRegisterColumn[];
};

const matches = (el, selector) => (el.matches || el.msMatchesSelector).call(el, selector);

@Component({
	selector: 'app-risk-register-table',
	templateUrl: './risk-register-table.component.html',
	styleUrls: ['./risk-register-table.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class RiskRegisterTableComponent implements OnInit, OnDestroy, OnChanges {
	public svgExcel: SVGIcon = fileExcelIcon;
	@ViewChild('grid', { read: GridComponent })
	public gridComponent: GridComponent;
	@Input() fileName = '';
	@Input() hiddenColumns = new Set<string>([]);
	@Input() allHiddenColumns: { riskPage: Set<string>; fullscreen: Set<string> };
	@Input() riskRegisters: Array<RiskRegister> = [];
	@Input() set tableFilter(newFilter) {
		this._tableFilter.next(newFilter);
	}
	_tableFilter = new BehaviorSubject<CompositeFilterDescriptor>({ filters: [], logic: 'and' });
	@Output() overrideQuickFilter = new EventEmitter<boolean>(false);
	@Input() groups: Array<RiskRegisterColumnGroup> = [];
	@Input() isFullscreen: boolean = true;
	@Input() exportGrid: Observable<void>;
	@Input() toggleColumnMenu: () => void;
	@Input() toggleFilterMenu: () => void;
	@Input() resetColumns: () => void;
	@Input() resetFilters: () => void;
	@Input() quickFilterChange: (filter) => any;
	@Input() allColumns = new Map<string, RiskRegisterColumn>([]);
	@Input() showColumnToggle: boolean;
	@Input() showFilterToggle: boolean;
	@Input() isProduction: boolean;
	@Input() filterList: filterMenuFilter[];
	@Input() selectedQuickFilter: string;
	@Input() children: (dataItem: any) => Observable<RiskRegisterColumnGroup[]>;
	@Input() hasChildren: (dataItem: any) => boolean;
	@Input() columnToTreeViewCoord = new Map<string, string>([]);
	@Input() disableEdit = new BehaviorSubject<boolean>(false);
	private exportSubscription: Subscription;
	@Output() addRegister = new EventEmitter<void>();
	@Output() editRegister = new EventEmitter<RiskRegister>();
	@Output() saveRegisterEmit = new EventEmitter<RiskRegister>();
	@Output() deleteRegister = new EventEmitter<RiskRegister>();
	@Output() constructRegisters = new EventEmitter<void>();
	@Output() toggleViewEmit = new EventEmitter<void>();
	@Output() openImportDialogEmit = new EventEmitter<void>();
	@Output() tableFilterEmit = new EventEmitter<CompositeFilterDescriptor>();
	@Output() duplicateRegister = new EventEmitter<{ register: RiskRegister; isFullscreen: boolean }>();
	@Input() editColumns: (columns: string[]) => void;
	@Input() saveRegister: (register: RiskRegister) => void;
	@Input() set visibleColumns(columns: string[]) {
		this._visibleColumns = columns;
		const visibleColumnFields = new Set<string>([]);
		for (const col of columns) {
			const coords = col.split('_');
			if (coords.length === 2) {
				const column = allGroups.groups[coords[0]]?.columns?.[coords[1]];
				if (column) {
					visibleColumnFields.add(column.field);
				}
			}
		}
		this.hiddenColumns.clear();
		for (const [colField, column] of this.allColumns) {
			if (!visibleColumnFields.has(colField)) {
				this.hiddenColumns.add(colField);
			}
		}
	}
	get visibleColumns() {
		return this._visibleColumns;
	}

	public icons = {
		plus: plusIcon,
		minus: minusIcon,
		columns: columnsIcon,
		filter: filterIcon,
		pencil: pencilIcon,
		copy: copyIcon,
		trash: trashIcon,
		import: importIcon,
		save: saveIcon,
	};

	public isDisabled = (dataItem: any) => dataItem.title === 'ID';
	allGroups = allGroups;
	minimizedGroups = new Set<string>([]);
	_visibleColumns: Array<string> = [];
	@Input() defaultVisibleColumns = new Set<string>([]);
	public dataColumns: Array<{ field: string; dir: string }> = [];
	existingEntries: { [field: string]: string[] } = {};
	existingEntriesWithoutTemps: { [field: string]: string[] } = {};
	public view: Observable<GridDataResult>;
	public gridState: State = {
		sort: [],
		skip: 0,
		take: 10,
	};
	public editingFormGroup: FormGroup;
	public isEditingNew: boolean;
	public editedRowIndex: number;
	private docClickSubscription: Subscription = new Subscription();
	public skip = 0;
	public widthPreferences: {
		fullscreen?: Record<string, number>;
		riskPage?: Record<string, number>;
		scheduleSelector?: Record<string, number>;
	} = {
		fullscreen: {},
		riskPage: {},
		scheduleSelector: {},
	};
	public filter: CompositeFilterDescriptor;
	allRiskRegisters: RiskRegister[] = [];

	customFieldOptions = customFieldOptionsMapping;
	@Input() editingRiskId = null;
	isInlineEditing = false;
	@Input() closeClicked = new BehaviorSubject(false);
	resetFilter: CompositeFilterDescriptor = { filters: [], logic: 'and' };
	resetState: State = {
		filter: this.resetFilter,
		group: [],
		skip: 0,
		sort: [],
		take: 10,
	};

	constructor(
		private restService: RestService,
		private sanitizer: DomSanitizer,
		private formBuilder: FormBuilder,
		public editService: RiskRegisterEditService,
		public project: ProjectDashboardService,
		private renderer: Renderer2,
		private cd: ChangeDetectorRef,
		public navBarStorage: NavigationBarStorageService
	) {
		this.groups = allGroups.groups;
		this.allData = this.allData.bind(this);
		this.allRiskRegisters = this.riskRegisters;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('riskRegisters')) {
			this.allRiskRegisters = changes.riskRegisters.currentValue;
		}
	}

	ngOnInit(): void {
		this.exportSubscription = this.exportGrid?.subscribe(() => this.exportToExcel());
		this.view = this.editService.pipe(map((data: unknown[]) => process(data, this.gridState)));

		this.editService.read();
		this._tableFilter.subscribe((newFilter: CompositeFilterDescriptor) => {
			if (newFilter?.logic === 'and') {
				const newState: State = {
					filter: newFilter,
					group: [],
					skip: 0,
					sort: [],
					take: 10,
				};
				//need to reset filters to not conflict with preset
				const tempReset = structuredClone(this.resetState);
				tempReset.skip = this.gridState.skip;
				this.onStateChange(tempReset);
				this.filterChange(this.resetFilter);

				//can now apply quick filter preset
				newState.skip = this.gridState.skip;
				this.onStateChange(newState);
				this.filterChange(newFilter);
				this.tableFilterEmit.emit(newFilter);
			}
		});
		this.project.$currentRiskRegisters.subscribe((registers: RiskRegister[]) => {
			const existingFields = {
				riskOwnerNames: new Set<string>([]),
				costOwnerNames: new Set<string>([]),
				responsibilityNames: new Set<string>([]),
				riskOwnerEmails: new Set<string>([]),
				costOwnerEmails: new Set<string>([]),
				responsibilityEmails: new Set<string>([]),
				category: new Set<string>(riskFormPresets.category),
			};
			for (const register of registers) {
				if (register.riskOwner?.name) {
					existingFields.riskOwnerNames.add(register.riskOwner.name);
				}
				if (register.riskOwner?.email) {
					existingFields.riskOwnerEmails.add(register.riskOwner.email);
				}
				if (register.costOwner?.name) {
					existingFields.costOwnerNames.add(register.costOwner.name);
				}
				if (register.costOwner?.email) {
					existingFields.costOwnerEmails.add(register.costOwner.email);
				}
				if (register.responsibility?.name) {
					existingFields.responsibilityNames.add(register.responsibility.name);
				}
				if (register.responsibility?.email) {
					existingFields.responsibilityEmails.add(register.responsibility.email);
				}
				if (register.category) {
					existingFields.category.add(register.category);
				}
			}
			let unassignedCustomFields =
				this.project.$currentProjectData.value?.preferences?.riskMitigation?.unassignedCustomFields;
			if (unassignedCustomFields === undefined) {
				unassignedCustomFields = {
					riskOwner: {
						name: [],
						email: [],
					},
					costOwner: {
						name: [],
						email: [],
					},
					responsibility: {
						name: [],
						email: [],
					},
					category: [],
				};
			}
			this.customFieldOptions.forEach((field) => {
				const unassignedFieldsArray = field.subpath
					? unassignedCustomFields[field.path][field.subpath]
					: unassignedCustomFields[field.path];
				unassignedFieldsArray.forEach((unassignedField) => {
					existingFields[field.efPath].add(unassignedField);
				});
			});
			this.existingEntries = {
				riskOwnerNames: Array.from(existingFields.riskOwnerNames),
				costOwnerNames: Array.from(existingFields.costOwnerNames),
				responsibilityNames: Array.from(existingFields.responsibilityNames),
				riskOwnerEmails: Array.from(existingFields.riskOwnerEmails),
				costOwnerEmails: Array.from(existingFields.costOwnerEmails),
				responsibilityEmails: Array.from(existingFields.responsibilityEmails),
				category: Array.from(existingFields.category),
			};
			this.existingEntriesWithoutTemps = {
				riskOwnerNames: Array.from(existingFields.riskOwnerNames),
				costOwnerNames: Array.from(existingFields.costOwnerNames),
				responsibilityNames: Array.from(existingFields.responsibilityNames),
				riskOwnerEmails: Array.from(existingFields.riskOwnerEmails),
				costOwnerEmails: Array.from(existingFields.costOwnerEmails),
				responsibilityEmails: Array.from(existingFields.responsibilityEmails),
				category: Array.from(existingFields.category),
			};
			this.riskRegisters = registers;
			this.allRiskRegisters = registers;
		});
		this.project.$currentProjectData.subscribe((projectData) => {
			this.widthPreferences = projectData?.preferences?.riskMitigation?.columnWidths || {
				fullscreen: {},
				riskPage: {},
				scheduleSelector: {},
			};
		});
		const scrollBars = document.getElementsByClassName('ps__rail-y');
		for (let i = 0; i < scrollBars.length; i++) {
			const e = scrollBars[i];
			// @ts-ignore
			e.style.visibility = this.isFullscreen ? 'hidden' : '';
		}
		this.docClickSubscription.add(this.renderer.listen('document', 'click', this.onDocumentClick.bind(this)));
		this.closeClicked.subscribe((data) => {
			//pop temporary row on close without saving
			if (data && this.riskRegisters[this.riskRegisters.length - 1]?.riskName === '') {
				this.riskRegisters.pop();
			}
		});
	}

	ngOnDestroy(): void {
		this.exportSubscription?.unsubscribe();
		this.docClickSubscription.unsubscribe();
		const scrollBars = document.getElementsByClassName('ps__rail-y');
		for (let i = 0; i < scrollBars.length; i++) {
			const e = scrollBars[i];
			// @ts-ignore
			e.style.visibility = this.isFullscreen ? 'visible' : '';
		}
	}

	toggleGroup(group: string) {
		if (this.minimizedGroups.has(group)) {
			this.minimizedGroups.delete(group);
		} else {
			this.minimizedGroups.add(group);
		}
	}

	public colorCodeRiskScore(score: number): SafeStyle {
		let result;

		if (score <= 5) {
			result = '#a6e398';
		} else if (score < 11) {
			result = '#f5f2ae';
		} else if (score < 18) {
			result = '#ffbe96';
		} else {
			result = '#f77d79';
		}

		return this.sanitizer.bypassSecurityTrustStyle(result);
	}

	public colorCodeRiskScoreDelta(delta: number): SafeStyle {
		let result;

		if (delta >= 19) {
			result = '#a6e398';
		} else if (delta > 11) {
			result = '#f5f2ae';
		} else if (delta > 5) {
			result = '#ffbe96';
		} else {
			result = '#f77d79';
		}

		return this.sanitizer.bypassSecurityTrustStyle(result);
	}

	public exportToExcel(grid: GridComponent = this.gridComponent): void {
		const oldData = structuredClone(grid.data);
		grid.data = this.riskRegisters;
		grid.saveAsExcel();
		grid.data = oldData;
	}
	public onExcelExport(e: ExcelExportEvent): void {
		e.preventDefault();
		formatExcelExport(
			e,
			this.fileName,
			this.project.$currentProjectData.getValue().name,
			'Risk Register Data Export',
			false,
			null,
			'A1',
			'Update ' +
				(this.project.$currentProjectData.value.updateIds.length - 1) +
				' - ' +
				cleanDateUTC(new Date(), 'MMMM d, yyyy')
		);
		console.log(e.workbook);
	}

	public allData(): ExcelExportData {
		const excelColumns: Array<SortDescriptor> = [];
		for (const group of allGroups.groups) {
			for (const column of group.columns) {
				excelColumns.push({
					field: column.field,
					dir: column.dir,
				});
			}
		}
		return {
			data: process(this.allRiskRegisters, {
				sort: excelColumns,
			}).data,
			group: allGroups.groups,
		};
	}

	public onStateChange(state: State): void {
		this.gridState = state;

		this.editService.read();
	}

	public cellClickHandler({ isEdited, dataItem, rowIndex }: CellClickEvent): void {
		if (
			isEdited ||
			this.disableEdit.value === true ||
			(this.isEditingNew && this.editingFormGroup && !this.editingFormGroup.valid && this.editingFormGroup.dirty)
		) {
			return;
		}
		this.saveCurrent();
		this.editingRiskId = dataItem.riskId;
		this.isInlineEditing = true;
		if (this.editingFormGroup) {
			this.closeEditor();
		}

		if (this.isEditingNew) {
			rowIndex += 1;
		}

		this.editingFormGroup = this.createFormGroup(dataItem);
		this.editedRowIndex = rowIndex;

		this.gridComponent.editRow(rowIndex, this.editingFormGroup);
	}

	public saveChanges(grid: GridComponent): void {
		if (this.editingFormGroup) {
			this.editService.saveRegisters(
				this.riskRegisters,
				this.project.$currentProjectData.value.preferences.riskMitigation.hiddenMitigationColumns
			);
			this.closeEditor(grid);
		}
	}

	private saveCurrent(formGroup: FormGroup = this.editingFormGroup): void {
		this.editingRiskId = null;
		this.isInlineEditing = false;
		this.constructRegisters.emit();
		if (formGroup) {
			const editingRegister: RiskRegister = formGroup.value;
			editingRegister.riskId = editingRegister.riskId || this.riskRegisters[this.editedRowIndex]?.riskId;
			const existingRegister = this.riskRegisters.find((register) => register.riskId === editingRegister.riskId);
			editingRegister.impactedTaskCodes = existingRegister?.impactedTaskCodes || [];
			this.editService.saveRegister(
				editingRegister,
				this.riskRegisters,
				this.project.$currentProjectData.value.preferences.riskMitigation.hiddenMitigationColumns
			);
			this.closeEditor();
		}
	}

	private closeEditor(grid: GridComponent = this.gridComponent, rowIndex = this.editedRowIndex): void {
		grid.closeRow(rowIndex);

		this.isEditingNew = false;
		this.editedRowIndex = undefined;
		this.editingFormGroup = undefined;
		this.editingRiskId = null;
	}

	public createFormGroup(register: RiskRegister): FormGroup {
		return this.formBuilder.group(riskRegisterFormControl(register, this.formBuilder, this.allColumns));
	}
	public addHandler(args?: AddEvent): void {
		this.closeEditor(args?.sender || this.gridComponent);
		const formGroup = this.createFormGroup(args?.dataItem ? args?.dataItem : emptyRegister());
		this.editingFormGroup = formGroup;
		formGroup.reset();
		// (args?.sender || this.gridComponent).addRow(formGroup);
		this.isEditingNew = true;
		// this.editedRowIndex = args?.rowIndex ? args?.rowIndex : -1;
		this.riskRegisters.push(emptyRegister());
		this.gridComponent.editRow(this.riskRegisters.length - 1, formGroup);
		this.editedRowIndex = this.riskRegisters.length - 1;
	}

	public cancelHandler(args: CancelEvent): void {
		this.closeEditor(args.sender, args.rowIndex);
	}

	public saveHandler(args: SaveEvent): void {
		args.formGroup.controls?.isDraft.setValue(args.formGroup.invalid);
		this.editingRiskId = null;
		this.isInlineEditing = false;
		this.saveCurrent(args.formGroup);
		args.sender.closeRow(args.rowIndex);
	}

	public removeHandler(args: RemoveEvent): void {
		this.editService.remove(args.dataItem);
		args.sender.cancelCell();
	}

	private onDocumentClick(e: Event): void {
		if (this.editingFormGroup && !matches(e.target, '#registerGrid tbody *, #registerGrid .k-grid-toolbar .k-button')) {
			this.editingFormGroup.controls?.isDraft.setValue(this.editingFormGroup.invalid);
			//this.saveCurrent();
		}
	}

	/**
	 * initiates the duplication of a register
	 * @param dataItem
	 */
	doDuplication(dataItem: any): void {
		this.duplicateRegister.emit({ register: dataItem, isFullscreen: this.isFullscreen });
		if (this.isFullscreen) {
			let nextRiskRegisterId = 0;
			this.allData().data.forEach((register) => {
				if (register.riskId >= nextRiskRegisterId) {
					nextRiskRegisterId = register.riskId + 1;
				}
			});
			const duplicatedRegister = structuredClone(dataItem);
			duplicatedRegister.riskId = nextRiskRegisterId;
			this.allData().data.push(duplicatedRegister);
			const formGroup = this.createFormGroup(duplicatedRegister);
			this.editingFormGroup = formGroup;
			// this.gridComponent.addRow(formGroup);
			this.riskRegisters.push(duplicatedRegister);
			this.gridComponent.editRow(this.riskRegisters.length - 1, formGroup);
			this.editedRowIndex = this.riskRegisters.length - 1;
			this.isEditingNew = true;
			this.cd.detectChanges();
		}
	}

	deleteItem(dataItem) {
		this.closeEditor(this.gridComponent, this.riskRegisters.length - 1);
		// const hiddenColumns = {
		// 	riskPage: Array.from(this.allHiddenColumns.riskPage),
		// 	fullscreen: Array.from(this.allHiddenColumns.fullscreen),
		// };
		// this.editService.remove(dataItem, hiddenColumns);
		this.deleteRegister.emit(dataItem);
	}

	saveAsPct(formGroup: FormGroup, field: string, value: number) {
		const splitParams = field.split('.');
		if (splitParams.length === 1) {
			formGroup.patchValue({ [splitParams[0]]: value / 100 });
		} else if (splitParams.length === 2) {
			formGroup.patchValue({ [splitParams[0]]: { [splitParams[1]]: value / 100 } });
		}
	}

	resizeEvent(columnResizeArgs: ColumnResizeArgs[]) {
		let hasChanges = false;
		for (const col of columnResizeArgs) {
			const column: ColumnBase & Optionalize<RiskRegisterColumn> = col.column;
			const field = column.field;
			const width = col.newWidth;
			if (col.newWidth !== col.oldWidth) {
				hasChanges = true;
				if (this.isFullscreen) {
					this.widthPreferences.fullscreen[field] = width;
				} else {
					this.widthPreferences.riskPage[field] = width;
				}
			}
		}
		if (hasChanges) {
			this.restService
				.post('project/preferences/registerColWidth/' + this.project.$currentProjectPageId.value, {
					data: this.widthPreferences,
				})
				.subscribe((resp) => {});
		}
	}

	editRiskRegister(dataItem, rowIndex) {
		this.editRegister.emit(dataItem);
		//this.saveCurrent();
		if (this.editingFormGroup) {
			this.closeEditor();
		}
		this.editingRiskId = dataItem.riskId;
		this.editingFormGroup = this.createFormGroup(dataItem);
		this.editedRowIndex = rowIndex;

		this.gridComponent.editRow(rowIndex, this.editingFormGroup);
	}

	openNew() {
		let nextRiskRegisterId = 0;
		this.allData().data.forEach((register) => {
			if (register.riskId >= nextRiskRegisterId) {
				nextRiskRegisterId = register.riskId + 1;
			}
		});
		const newRegister = emptyRegister();
		newRegister.riskId = nextRiskRegisterId;
		const formGroup = this.createFormGroup(newRegister);
		this.editingFormGroup = formGroup;
		formGroup.reset();
		this.isEditingNew = true;
		this.riskRegisters.push(newRegister);
		this.addRegister.emit();
		this.editRegister.emit(this.riskRegisters[this.riskRegisters.length - 1]);
	}

	parseFloat = parseFloat;

	public filterChange(filter: CompositeFilterDescriptor, noLongerQuickFilter?: boolean): void {
		if (noLongerQuickFilter === true) {
			this.overrideQuickFilter.emit(true);
		}
		this.filter = filter;
		this.riskRegisters = filterBy(this.allData().data, filter);
	}

	public categoryChange(values: string[], filterService: FilterService, field: string): void {
		filterService.filter({
			filters: values.map((value) => ({
				field,
				operator: 'eq',
				value,
			})),
			logic: 'or',
		});
	}

	toggleView(): void {
		this.toggleViewEmit.emit();
	}

	openImportDialog(): void {
		this.openImportDialogEmit.emit();
	}

	public rowCallback = (context: RowClassArgs) =>
		context.dataItem.isDraft ? ((context.index + 1) % 2 === 0 ? 'draftRowDarker' : 'draftRowLighter') : '';
}
