import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProjectCompletionTrendInterface } from '../../../../models/ProjectReport/ProjectReport';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { differenceInCalendarDays, format } from 'date-fns';
import { XerActivity } from '@rhinoworks/xer-parse';
import { UpdateInterface } from '../../../../models/Update/Task';
import { ProjectDashboardService } from '../../../../services/project/project.service';
import { hasObjChanged } from '../../../../util/projects';

@Component({
	selector: 'app-progress-trending',
	templateUrl: './progress-trending.component.html',
	styleUrls: ['./progress-trending.component.scss'],
})
export class ProgressTrendingComponent implements OnInit, OnChanges {
	@Input() hideTrending: boolean = false;
	@Input() isOverview: boolean = false;
	categories: string[] = [];
	plannedValues: number[] = [];
	actualValues: number[] = [];
	$roundedActual = new BehaviorSubject<number>(null);
	$roundedPlanned = new BehaviorSubject<number>(null);
	hasNotes: boolean = false;
	projectCompletionTrendingHasNotes: boolean = false;
	constructor(
		public scheduleStorage: ScheduleStorageService,
		public projectService: ProjectDashboardService
	) {
		this.contentOfLabels = this.contentOfLabels.bind(this);
	}

	ngOnInit(): void {
		this.scheduleStorage.$allUpdates.subscribe((updates) => {
			let i: number = 0;
			const waitingForProjectReportInterval = setInterval(() => {
				if (this.projectService.$currentProjectReport.value !== undefined || i > 500) {
					clearInterval(waitingForProjectReportInterval);
					this.updateData(this.projectService.$currentProjectReport.value?.projectCompletionTrend, updates);
				}
				i++;
			}, 200);
		});
		this.projectService.$currentProjectData.subscribe((val) => {
			if (val) {
				const savedNotes = val.componentNotes?.find((n) => n.id === 1)?.notes;
				const savedNotesProjectCompletionTrending = val.componentNotes?.find((n) => n.id === 0)?.notes;
				this.hasNotes = savedNotes?.length && savedNotes[savedNotes?.length - 1]?.note !== '';
				this.projectCompletionTrendingHasNotes =
					savedNotesProjectCompletionTrending?.length &&
					savedNotesProjectCompletionTrending[savedNotesProjectCompletionTrending?.length - 1]?.note !== '';
			}
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (Object.prototype.hasOwnProperty.call(changes, 'projectReport') && changes.projectReport.currentValue) {
			this.updateData(
				changes.projectReport.currentValue.projectCompletionTrend,
				this.scheduleStorage.$allUpdates.value
			);
		}
	}

	updateData(projectCompletionTrend: ProjectCompletionTrendInterface, updates: UpdateInterface[]) {
		if (!projectCompletionTrend || updates.length < 1) {
			return;
		}
		this.plannedValues = [];
		this.actualValues = [];
		let categories: string[] = [];
		for (let i = 1; i < updates.length; i++) {
			const updateName: string = !i ? 'Baseline' : `Update ${i}`;
			const dataDateFromUpdate: Date = new Date(updates[i]?.dataDate);
			const dateString: string = dataDateFromUpdate ? format(dataDateFromUpdate, 'MMM dd, yyyy') : '';
			const category: string =
				(dateString === '' ? updateName : updateName + '\n' + dateString) + (updates[i]?.baseline ? ' ®' : '');
			categories.push(category);
			const update = updates[i];
			const baselineStart = new Date(
				update?.startMilestone?.act_start_date ||
					update?.startMilestone?.early_start_date ||
					update?.startMilestone?.target_start_date ||
					update?.startMilestone?.update_date ||
					update?.dataDate
			);
			const baselineFinish = new Date(getContractCompletion(updates[0].finishMilestone));
			const currentDataDate = new Date(projectCompletionTrend.projectCompletionTrendArray[i].dataDate);
			const currentFinish = new Date(projectCompletionTrend.projectCompletionTrendArray[i].currentCompletion);
			const planned =
				(100 * differenceInCalendarDays(currentDataDate, baselineStart)) /
				(differenceInCalendarDays(baselineFinish, baselineStart) || 1);
			const actual =
				100 *
				(1 -
					differenceInCalendarDays(currentFinish, currentDataDate) /
						(differenceInCalendarDays(currentFinish, baselineStart) || 1));
			this.plannedValues.push(planned > 100 ? 100 : planned < 0 ? 0 : planned);
			this.actualValues.push(actual > 100 ? 100 : actual < 0 ? 0 : actual);
		}
		categories = categories.slice(1);
		if (hasObjChanged(this.categories, categories)) {
			this.categories = categories;
		}
		this.$roundedPlanned.next(Math.round(this.plannedValues[this.plannedValues.length - 1]));
		this.$roundedActual.next(Math.round(this.actualValues[this.actualValues.length - 1]));
	}

	contentOfLabels = (ev) => {
		const labelSplit: string[] = ev.text.split('\n');
		return labelSplit[labelSplit.length - 1];
	};
}

const getContractCompletion = (completionMilestone: XerActivity | undefined): Date | undefined =>
	completionMilestone?.act_end_date ||
	completionMilestone?.cstr_date ||
	completionMilestone?.early_end_date ||
	undefined;
