import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ProjectDashboardService } from '../../../services/project/project.service';
import { RestService } from '../../../services/common/rest.service';
import { ModalController } from '@ionic/angular';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../../services/common/notification.service';
import { AnalyticsDashboardService } from '../../../services/analytics/analytics.service';

@Component({
	selector: 'app-risk-calculation-method',
	templateUrl: './risk-calculation-method.component.html',
	styleUrls: ['./risk-calculation-method.component.scss'],
})
export class RiskCalculationMethodComponent implements OnInit {
	@Input() userInfo = null;
	@Input() projectReportInfo = null;
	@Output() closeRiskCalc = new EventEmitter<boolean>();

	user: any;
	riskMetricsOptions: any;

	projectReport: any;
	selectedRiskMetricsType: any;
	public opened: boolean = true;

	constructor(
		public projectService: ProjectDashboardService,
		private rest: RestService,
		private modalController: ModalController,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private notificationService: NotificationService,
		public services: AnalyticsDashboardService
	) {}

	ngOnInit(): void {
		this.user = this.userInfo;
		const rmt = [
			{
				value: 'default',
				viewValue: 'Default',
				defaultChecked: false,
			},
			{
				value: 'performanceFactor',
				viewValue: 'Monte Carlo - Performance Trending',
				defaultChecked: false,
			},
			{
				value: 'riskRegister',
				viewValue: 'Monte Carlo - Risk Register',
				defaultChecked: false,
			},
		];
		const matchingRiskType = rmt.find((risk) => risk.value === this.projectReportInfo?.project?.riskMetricsType);
		matchingRiskType.defaultChecked = true;
		this.riskMetricsOptions = rmt;
		this.projectReport = this.projectReportInfo;
	}

	/**
	 * updates selectedRiskMetricsType to match user selection
	 * @param newType
	 */
	updateSelection(newType: string): void {
		this.selectedRiskMetricsType = newType;
	}

	/**
	 * updates project record with new riskMetricsType
	 */
	updateRiskMetricsType(): void {
		const body = this.projectReport.project;
		if (!body) {
			this.notificationService.showNotification('Oops! Something went wrong!');
			this.cancelAndDismiss();
		}
		body.riskMetricsType = this.selectedRiskMetricsType;

		//band-aid until riskPurchased removed from code
		body.riskPagePurchased = this.selectedRiskMetricsType !== '';
		const url = `project/${this.projectReport.project._id}`;
		const activityCodeIds = body.pfCodes || [];
		if (
			this.selectedRiskMetricsType === 'performanceFactor' &&
			(activityCodeIds === undefined || activityCodeIds.length === 0) &&
			this.projectReport?.riskPage?.performanceFactor.pfTable.length > 0
		) {
			this.projectReport?.riskPage?.performanceFactor.pfTable.forEach((activity) => {
				if (activity.numStarted > 0 && !activityCodeIds.includes(activity.activityCode)) {
					activityCodeIds.push(activity.activityCode);
				}
			});
		}
		this.rest.put(url, body).subscribe(
			(response) => {
				this.notificationService.showNotification('Project updated successfully!');
				this.cancelAndDismiss();
			},
			(error) => {
				this.notificationService.showNotification(error.message || 'Oops! Something went wrong!');
				this.cancelAndDismiss();
			},
			() => {
				//recalc
				this.rest
					.post(`report/calculate/${this.projectReport.project._id}`, {
						activityCodeIds,
						onlySimulate: true,
					})
					.subscribe(
						(val) => {},
						(response) => {
							console.log('POST call in error', response);
						},
						() => {}
					);
				window.location.reload();
			}
		);
		this.open(false);
	}

	/**
	 * close dialog
	 */
	cancelAndDismiss() {
		this.dialog.closeAll();
	}

	/**
	 * notifies rhinoworks of a client's request to enable/swap risk metrics
	 */
	emailRhinoworks() {
		const body = {
			project: this.projectReport?.value?.project,
			client: this.user,
		};
		this.rest.post('project/enableRisk', body).subscribe(
			(success) => {
				this.notificationService.showNotification('Request sent successfully!');
				this.cancelAndDismiss();
			},
			(error) => {
				console.error('error', error);
			}
		);
		this.open(false);
	}

	public open(isOpened: boolean): void {
		this.opened = isOpened;
		this.closeRiskCalc.emit(false);
	}
}
