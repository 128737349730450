<div id="preset-window-content">
	<div class="header">
		<div class="desc">
			Change the values to your liking and press save to {{ganttService.isEdit ? 'edit this report.' : 'create a new gantt report with this name.'}}
		</div>
		<button kendoButton themeColor="error" title="Reset" [disabled]='saving || loading' (click)='reset()'>
			Reset
		</button>
	</div>
	<kendo-gridlayout
		[rows]="['auto']"
		[cols]="['calc(66% - (5px / 2))', 'calc(34% - (5px / 2))']"
		[gap]="{ rows: 0, cols: 5 }"
		class="grid"
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-container'>
			<fieldset>
				<legend>
					General
				</legend>
				<div style="height: 50px; display: flex; flex-direction: row; justify-content: center; margin-top: -14px;">
					<kendo-formfield class="preset-form-name" style="width: 100%; margin: 10px 0;">
						<kendo-label [for]="presetName" text="Report Name:" style="width: 92px;"></kendo-label>
						<kendo-textbox
							[(ngModel)]="name"
							#presetName
							required
							(ngModelChange)="updateName($event)"
							[clearButton]="true"
						>
						</kendo-textbox>
						<kendo-formerror [align]="'end'" style="margin-top: 0px;">Report Name is required</kendo-formerror>
					</kendo-formfield>
					<div class="dropdown-container" [style.cursor]="ganttService.isEdit && !isBasedOnOtherPreset ? 'not-allowed' : 'inherit'">
						<div class="dropdown-label">
							Based On:
						</div>
						<kendo-dropdownlist
							[data]="ganttService.isEdit && !isBasedOnOtherPreset ? [] : otherPresets"
							valueField='id'
							textField='name'
							(valueChange)='updateBasedOnSelection($event)'
							[(ngModel)]="basedOn"
							[disabled]="ganttService.isEdit && !isBasedOnOtherPreset"
							class='dropdown'
						></kendo-dropdownlist>
					</div>
				</div>
				<div style="height: 30px; display: flex; flex-direction: row; justify-content: flex-start; margin-top: 4px;">
					<div style="display: flex;">
						<kendo-label [for]="isFavoriteCheckbox" text="Favorite:" style="padding-right: 4px; margin: auto 0;"></kendo-label>
						<input type="checkbox" kendoCheckBox [(ngModel)]="isFavorite" #isFavoriteCheckbox style="margin: auto 0;"/>
						<span style="font-size: 12px; font-style: italic; padding-left: 4px; white-space: nowrap; margin: auto 0;">Favorite reports are sorted first</span>
					</div>
					<div style="display: flex; padding-left: 61px;">
						<span class="dropdown-label" style="white-space: nowrap; margin: auto 0;">
							Add to Folder:
						</span>
						<kendo-multiselect
							kendoMultiSelectSummaryTag
							[data]="ganttService.reportGroups"
							[(ngModel)]="selectedFolders"
							textField="name"
							valueField="id"
							[checkboxes]='true'
							[autoClose]='false'
							[clearButton]='false'
							style="width: 175px; margin-left: 5px; height: 30px;"
							[placeholder]="'All Reports'"
						>
							<ng-template kendoMultiSelectHeaderTemplate>
								<div>
									<input
										kendoCheckBox
										type='checkbox'
										[checked]="isSelectedAll()"
										(click)="onFilterSelectAllClick()"
									/>
									<kendo-label>Select all</kendo-label>
								</div>
							</ng-template>
							<ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
								<span>
									<kendo-svg-icon [icon]='icons.caretDown'></kendo-svg-icon>
									{{ dataItems.length + ' selected' }}
								</span>
							</ng-template>
						</kendo-multiselect>
					</div>
				</div>
			</fieldset>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-container'>
			<fieldset style="height: 100%; margin-right: 0;">
				<legend>
					Views
				</legend>
				<div class="main-wrapper">
					<div style="display: flex; flex-direction: column; justify-content: flex-start;">
						<div class='update-status-container' style='padding-top: 2px; height: 44px;' kendoTooltip>
							<div class='update-status-title'>
								Relationships:
							</div>
							<div class='checkbox-container' [title]="ganttService.groupByApplied || ganttService.radioSelection !== null ? 'Relationships are not compatible with grouped activities' : ''">
								<input type="radio" size='large' #relationshipsShow kendoRadioButton [(ngModel)]="showRelationships" [value]='false' name='relationshipView' [disabled]="ganttService.groupByApplied || ganttService.radioSelection !== null"/>
								<kendo-label
									class="k-checkbox-label"
									[for]="relationshipsShow"
									text="Hide"
								></kendo-label>
								<input type="radio" size='large' #relationshipsHide kendoRadioButton name='mapView' style='margin-left: 10px;' [(ngModel)]="showRelationships" [value]='true' [disabled]="ganttService.groupByApplied || ganttService.radioSelection !== null"/>
								<kendo-label
									class="k-checkbox-label"
									[for]="relationshipsHide"
									text="Show"
								></kendo-label>
							</div>
						</div>
						<div class='update-status-container' style='padding-top: 0;'>
							<div class='update-status-title'>
								Activities:
							</div>
							<div class='checkbox-container'>
								<input type="radio" size='large' #critical kendoRadioButton [(ngModel)]="showCritical" [value]='true' name='activityView'/>
								<kendo-label
									class="k-checkbox-label"
									[for]="critical"
									text="Critical"
								></kendo-label>
								<input type="radio" size='large' #all kendoRadioButton name='portfolioView' style='margin-left: 10px;' [(ngModel)]="showCritical" [value]='false'/>
								<kendo-label
									class="k-checkbox-label"
									[for]="all"
									text="Show All"
								></kendo-label>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
	<kendo-gridlayout
		[rows]="['auto']"
		[cols]="['calc(66% - (5px / 2))', 'calc(34% - (5px / 2))']"
		[gap]="{ rows: 0, cols: 5 }"
		class="grid"
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-container'>
			<div style='height: 100%; overflow: auto; padding-top: 8px;'>
				<kendo-tabstrip [tabIndex]='currentTab' class="preset-tabstrip">
					<kendo-tabstrip-tab title="Filters" [selected]="currentTab === 0">
						<ng-template kendoTabContent>
							<div class='tabContents' style="height: 342px;">
								<kendo-filter #filter id='filter' [value]="filterValue" style='width: 100%' class="gantt-filter" (valueChange)='onFilterChange($event)'>
									<kendo-filter-field *ngFor='let filter of filters' [field]='filter.field' [title]='filter.title' [editor]='filter.editor'></kendo-filter-field>
									<kendo-filter-field field='Status' editor="string" [operators]='["eq", "neq"]'>
										<ng-template kendoFilterValueEditorTemplate let-currentItem>
											<kendo-dropdownlist
												[data]="statusFilterList"
												[value]='currentItem.value'
												(valueChange)="editorValueChange($event, currentItem)"
											>
											</kendo-dropdownlist>
										</ng-template>
									</kendo-filter-field>
									<kendo-filter-field field='start' editor='date' title="Start">
										<ng-template kendoFilterValueEditorTemplate let-currentItem>
											<kendo-datepicker
												[value]='currentItem.value'
												(valueChange)="editorValueChange($event, currentItem)"
												style='width: 165px'
											>
											</kendo-datepicker>
										</ng-template>
									</kendo-filter-field>
									<kendo-filter-field field='trueEnd' editor='date' title="Finish">
										<ng-template kendoFilterValueEditorTemplate let-currentItem>
											<kendo-datepicker
												[value]='currentItem.value'
												(valueChange)="editorValueChange($event, currentItem)"
												style='width: 165px'
											>
											</kendo-datepicker>
										</ng-template>
									</kendo-filter-field>
									@for (type of allActivityTypes; track allActivityTypes) {
										<kendo-filter-field [field]='"ActvType_" + type.actv_code_type_id' [title]='type.actv_code_type?.toUpperCase()' editor='string' [operators]='["contains", "doesnotcontain"]'>
											<ng-template kendoFilterValueEditorTemplate let-currentItem>
												<kendo-dropdownlist
													[data]="actvCodesByType.get(type.actv_code_type_id)"
													[textField]="'actv_code_name'"
													[valueField]="'actv_code_id'"
													(valueChange)="editorValueChange($event, currentItem, true)"
												>
												</kendo-dropdownlist>
											</ng-template>
										</kendo-filter-field>
									}
									<kendo-filter-field field='tf' title="Total Float" editor="number" editorFormat="n0"></kendo-filter-field>
									<kendo-filter-field field='taskType' title="Task Type" editor="string" [operators]='["eq", "neq"]'>
										<ng-template kendoFilterValueEditorTemplate let-currentItem>
											<kendo-dropdownlist
												[data]="taskTypeFilterList"
												[value]='currentItem.value'
												(valueChange)="editorValueChange($event, currentItem)"
												[disabled]="currentItem === 'Level of Effort'"
											>
											</kendo-dropdownlist>
										</ng-template>
									</kendo-filter-field>
									<kendo-filter-field field='criticality' title="Criticality" editor="string" [operators]='["eq", "neq"]'>
										<ng-template kendoFilterValueEditorTemplate let-currentItem>
											<kendo-dropdownlist
												[data]="criticalityFilterList"
												[value]='currentItem.value'
												(valueChange)="editorValueChange($event, currentItem)"
											>
											</kendo-dropdownlist>
										</ng-template>
									</kendo-filter-field>
								</kendo-filter>
							</div>
						</ng-template>
					</kendo-tabstrip-tab>
					<kendo-tabstrip-tab title="Grouping" [selected]="currentTab === 1">
						<ng-template kendoTabContent>
							<div class='tabContents' style="height: 342px;">
								<app-gantt-group-by [fromPresetWindow]="true" ></app-gantt-group-by>
							</div>
						</ng-template>
					</kendo-tabstrip-tab>
				</kendo-tabstrip>
			</div>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-container'>
			<fieldset style="height: 100%; margin-right: 0;">
				<legend>
					Visible Columns:
				</legend>
				<div style='height: 392px; overflow: auto; margin-top: -5px; margin-left: -15px;'>
					<kendo-treeview
						[nodes]="allColumns.columns"
						textField="title"
						[kendoTreeViewCheckable]="{checkParents: true}"
						[(checkedKeys)]="checkedColumns"
						[isDisabled]='isDisabled'
						class="layout-columns-treeview"
					>
					</kendo-treeview>
				</div>
			</fieldset>
		</kendo-gridlayout-item>
	</kendo-gridlayout>

	<div class="action-btn-wrapper">
		<button kendoButton [disabled]='isDiffThanCurrent("apply")' (click)="apply()" themeColor="info">
			Apply
		</button>
		<button kendoButton [disabled]='saving' (click)="ganttService.cancelReport()" themeColor="error">
			Cancel
		</button>
		<button kendoButton [disabled]='isDiffThanCurrent("save")' (click)="save()" themeColor="success">
			Save
		</button>
	</div>
</div>
