import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapComponent } from './map/map.component';
import { IonicModule } from '@ionic/angular';
import { GlobalTooltipMenuModule } from '../global-toolip-menu/global-tooltip-menu.module';
import { MapIconComponent } from './map/map-icon/map-icon.component';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
	declarations: [MapComponent, MapIconComponent],
	imports: [
		CommonModule,
		IonicModule,
		GlobalTooltipMenuModule,
		GoogleMapsModule,
		/*		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyDLqs4B7aCg4mZv8SAO4bLr6Oy8zD1qKTw&libraries=places',
			libraries: ['places'],
		}),*/
	],
	exports: [MapComponent],
})
export class MapModule {}
