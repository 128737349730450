import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { addDays, format } from 'date-fns';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AxisSettings, KendoColumn, SeriesDataSettings } from '../../../../models/ChartSettings';
import { PlotBand } from '@progress/kendo-angular-charts';
import { SortDescriptor } from '@progress/kendo-data-query/dist/npm/sort-descriptor';
import { GridComponent, GridDataResult, SelectionEvent } from '@progress/kendo-angular-grid';
import { hasObjChanged } from '../../../../util/projects';
import { ProjectDashboardService } from '../../../../services/project/project.service';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { UpdateInterface } from '../../../../models/Update/Task';

export const allColumns = require('./milestones-columns.json') as {
	columns: KendoColumn[];
};

@Component({
	selector: 'app-milestones',
	templateUrl: './milestones.component.html',
	styleUrls: ['./milestones.component.scss'],
})
export class MilestonesComponent implements OnInit, OnDestroy {
	@Input() isOverview: boolean = false;
	@ViewChild('table') table: any;
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	milestoneData: any[] = [];
	categories: string[] = [];
	seriesData: SeriesDataSettings[] = [];
	public valuePlotBands: PlotBand[] = [
		{
			from: -999999,
			to: 0,
			color: '#DF5353',
			opacity: 0.2,
		},
		{
			from: 0,
			to: 999999,
			color: '#4fc931',
			opacity: 0.2,
		},
	];
	valueAxisItemSettings: AxisSettings[] = [
		{
			title: {
				text: 'Completion Variance',
				visible: true,
			},
			labels: {
				format: '{0} cd',
			},
			plotBands: this.valuePlotBands,
			majorGridLines: {
				visible: true,
				width: 3,
			},
		},
	];
	@ViewChild(GridComponent)
	public grid: GridComponent;
	public gridView: GridDataResult;
	gridData: Array<any> = [];
	loading: boolean = true;
	public sort: SortDescriptor[] = [
		{
			dir: 'asc',
			field: 'prevVariance',
		},
	];
	selectedColumns: KendoColumn[] = [];
	public selectedKeys = [];
	hasNotes: boolean = false;

	constructor(
		public projectService: ProjectDashboardService,
		public scheduleService: ScheduleStorageService
	) {}

	ngOnInit(): void {
		this.projectService.$currentProjectReport.pipe(takeUntil(this._unsubscribeAll)).subscribe((report) => {
			let i: number = 0;
			if (report !== undefined) {
				const allUpdatesInterval = setInterval(() => {
					if (this.scheduleService.$allUpdates.value?.length > 0 || i > 500) {
						clearInterval(allUpdatesInterval);
						this.getMilestoneTaskData(report);
						this.getMilestoneCompletionTrending(report);
					}
					i++;
				}, 200);
			}
		});
		this.projectService.$currentProjectData.subscribe((val) => {
			if (val) {
				const savedNotes = val.componentNotes?.find((n) => n.id === 4)?.notes;
				this.hasNotes = savedNotes?.length && savedNotes[savedNotes?.length - 1]?.note !== '';
			}
		});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	getMilestoneTaskData(report: any) {
		const updates: UpdateInterface[] = this.scheduleService.$allUpdates.value;
		this.loading = true;
		this.selectedColumns = allColumns.columns;
		const milestoneArray = report?.milestones?.milestoneArray || [];
		const milestones = [];
		const selectedKeys = [];
		milestoneArray.forEach((item: any) => {
			const previousDate =
				item.baselineCompletion === undefined
					? null
					: addDays(new Date(item.baselineCompletion), +item.previousVariance * -1);
			milestones.push({
				baseline: item.baselineCompletion === undefined ? null : new Date(item.baselineCompletion),
				current: item.currentCompletion === undefined ? null : new Date(item.currentCompletion),
				id: item.activityId,
				_id: item.activityId,
				name: item.activityName,
				prevVariance: item.previousVariance === 'NaN' ? null : +item.previousVariance,
				previous: previousDate,
				blVariance: item.baselineVariance,
				isComplete: item.status_code === 'TK_Complete',
			});
			if (!this.selectedKeys.some((key) => key === item.activityId)) {
				selectedKeys.push(item.activityId, +item.previousVariance * -1);
			}
		});
		const milestoneTrend = report?.milestoneCompletionTrend || [];
		const deletedMilestones = milestoneTrend.filter(
			(m) => milestoneArray.findIndex((g) => g.activityName === m.mileStoneTaskName) === -1
		);
		deletedMilestones.forEach((milestone) => {
			const matchingMilestone = updates[0]?.additionalTrackingMilestones.find(
				(m) => m.task_name === milestone.mileStoneTaskName
			);
			const backupId: string = crypto.randomUUID();
			milestones.push({
				baseline: null,
				current: null,
				id: 'Deleted',
				name: milestone.mileStoneTaskName,
				prevVariance: null,
				previous: null,
				blVariance: null,
				isComplete: null,
				_id: matchingMilestone?.task_id || backupId,
			});
			selectedKeys.push(matchingMilestone?.task_id || backupId);
		});
		this.loading = false;
		// Need to do this to trigger change detection ~TW
		this.milestoneData = milestones;
		this.selectedKeys = selectedKeys;
		this.loadMilestones();
	}

	/**
	 * retrieves specific milestone trending chart data from project report and fills in highcharts chart
	 * @param report
	 */
	getMilestoneCompletionTrending(report?: any): void {
		const milestoneTrend = report?.milestoneCompletionTrend || [];
		const seriesData = [];
		const categories: string[] = [];
		milestoneTrend.forEach((mTrend, j) => {
			const milestoneCompletionTrendArray = mTrend.milestoneCompletionTrendArray || [];
			const contractVarianceArray = milestoneCompletionTrendArray.map((item) => item.contractVariance);
			const data = [];
			contractVarianceArray.forEach((value, i) => {
				const update = this.scheduleService.$allUpdates.value[i];
				const dataDateFromUpdate: Date = new Date(
					update?.dataDate || report?.projectCompletionTrend?.projectCompletionTrendArray?.[i]?.dataDate
				);
				const dateString: string = dataDateFromUpdate ? format(dataDateFromUpdate, 'MMM dd, yyyy') : '';
				const updateName: string = i === 0 ? 'Baseline' : `Update ${i}`;
				const category: string =
					(dateString === '' ? updateName : updateName + '\n' + dateString) + (update?.baseline ? ' ®' : '');
				if (j === 0) {
					categories.push(category);
				}
				data.push({
					category,
					value,
				});
			});
			seriesData.push({
				type: 'line',
				data,
				name: mTrend.mileStoneTaskName,
				visible: true,
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			});
		});
		if (hasObjChanged(this.seriesData, seriesData)) {
			this.seriesData = seriesData;
		}
		if (hasObjChanged(categories, this.categories)) {
			this.categories = categories;
		}
	}

	public sortChange(sort: SortDescriptor[]): void {
		this.sort = sort;
		this.loadMilestones();
	}

	/**
	 * sets activities grid data to be the latest data at the current scroll/page spot
	 */
	public loadMilestones(): void {
		this.gridView = {
			data: this.milestoneData,
			total: this.milestoneData?.length,
		};
		this.gridData = this.milestoneData;
	}

	/**
	 * update milestone chart line visibility based on selection status change in table
	 * @param ev
	 */
	selectionChanged(ev: SelectionEvent) {
		if (ev.deselectedRows.length > 0) {
			const matchingLine = this.seriesData.find((line) => line.name === ev.deselectedRows[0].dataItem.name);
			if (matchingLine !== undefined) {
				matchingLine.visible = false;
			}
		}
		if (ev.selectedRows.length > 0) {
			const matchingLine = this.seriesData.find((line) => line.name === ev.selectedRows[0].dataItem.name);
			if (matchingLine !== undefined) {
				matchingLine.visible = true;
			}
		}
	}

	/**
	 * line clicked in legend. updates selection in grid to match
	 * @param ev
	 */
	legendItemClicked(ev) {
		const matchingSeries = this.seriesData.find((line) => line.name === ev);
		if (matchingSeries !== undefined) {
			matchingSeries.visible = !matchingSeries.visible;
		}
		const matchingGridData = this.milestoneData.find((row) => row.name === ev);
		if (matchingGridData !== undefined) {
			const id = matchingGridData._id;
			if (this.selectedKeys.some((item) => item === id)) {
				this.selectedKeys = this.selectedKeys.filter((item) => item !== id);
			} else {
				//has to be done like this to trigger change detection
				this.selectedKeys = [...this.selectedKeys, id];
			}
		}
	}
}
