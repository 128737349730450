<div [class.fullscreen-window-container]='isFullscreen' >
  <kendo-grid #grid="kendoGrid"
							id='registerGrid'
              [data]="isFullscreen ? riskRegisters : (view | async)"
							[pageSize]="gridState.take"
              [skip]="gridState.skip"
              [sort]="gridState.sort"
              [pageable]="!isFullscreen"
              [sortable]='true'
							(dataStateChange)='onStateChange($event)'
              (add)="addHandler($event)"
							(save)='saveHandler($event)'
							(cancel)='cancelHandler($event)'
							(columnResize)='resizeEvent($event)'
							(filterChange)="filterChange($event, true)"
              [navigable]="true"
              [resizable]='true'
              [filterable]='"menu"'
              [columnMenu]="false"
              [style.height]='isFullscreen ? 850 : "auto"'
							[filter]='filter'
							(excelExport)="onExcelExport($event)"
							[rowClass]='rowCallback'
							class='rr-table'
							[class.fullscreenClass]='isFullscreen'
  >
    <ng-template kendoGridToolbarTemplate *ngIf='isFullscreen'>
			<kendo-gridlayout
				[cols]="['calc(100% - 287px)', '282px']"
				[rows]='["42.4px"]'
				[gap]="{ rows: 0, cols: 5 }"
				class='grid full-width'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
					<div class="card-header" style='padding-left: 10px; color: white; white-space: nowrap;'>
						Risk Register
					</div>
					<tooltip-open type="risk-register"></tooltip-open>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2' class='toolbar-item-container' style='margin: 0 0 0 auto;'>
					<span style='display: inline-flex; align-items: center;' kendoTooltip>
						<button kendoButton themeColor="base" *ngIf='!project.$currentProjectData.value?.isArchived' title="Add New Risk" class='header-btn new-add-risk-icon' (click)='openNew()' [disabled]='navBarStorage.monteCarloCalculating' [imageUrl]='"/assets/icons/newIcons/noun-library-add-3643876.svg"'>
						</button>
						<button kendoButton themeColor="base" *ngIf='!project.$currentProjectData.value?.isArchived' title="Import" class='header-btn new-import-icon' (click)='openImportDialog()' [imageUrl]='"/assets/icons/newIcons/importRisk.svg"'></button>
						<button kendoGridExcelCommand themeColor="base" title="Export Report (XLSX)" class='header-btn' [imageUrl]='"/assets/icons/newIcons/excelExport.svg"'>
						</button>
						<button kendoButton [imageUrl]='"/assets/icons/newIcons/filter.svg"' [class.kendo-inline-header-btn]='isFullscreen' title="Quick Filters" themeColor='base' class="header-btn new-filter-icon" [class.active-quickfilter]='_tableFilter.value?.filters.length > 0' #anchor (click)='toggleFilterMenu()' [class.active-col-select]='showFilterToggle'>
						</button>
						<kendo-popup
							[anchor]="anchor.element"
							(anchorViewportLeave)="showFilterToggle = false"
							[popupAlign]='{horizontal: "center", vertical: "top"}'
							[popupClass]='"filter-menu"'
							*ngIf="showFilterToggle"
							[class.production-kendo-popup]='isProduction'
						>
							<div>
								<div style='display: flex; justify-content: space-between; padding-bottom: 10px;'>
									<button
										kendoButton
										[size]="'small'"
										fillMode="outline"
										[rounded]="'medium'"
										[themeColor]="'info'"
										(click)='resetFilters(); this.filterChange(this.resetFilter);'
										style='display: block; width: 84%;'
									>
										Reset
									</button>
									<button
										kendoButton
										[size]="'small'"
										[rounded]="'medium'"
										fillMode="outline"
										[themeColor]="'primary'"
										style='display: block; width: 15%;'
										(click)="toggleFilterMenu()"
									>
										X
									</button>
								</div>
								<div>
									<div *ngFor='let filter of filterList' class='quick-filter-container'>
										<input
											type="radio"
											name='radio'
											[id]='filter.name'
											[value]='filter.name'
											[(ngModel)]="selectedQuickFilter"
											(ngModelChange)='quickFilterChange(filter)'
											class='radio-button'
											kendoRadioButton />
										<kendo-label [for]="filter.name" class='radio-label'> {{filter.displayLabel}}</kendo-label>
									</div>
								</div>
							</div>
						</kendo-popup>
						<button kendoButton [imageUrl]='"/assets/icons/newIcons/noun-add-column2 1.svg"' [class.kendo-inline-header-btn]='isFullscreen' title="Add/Remove Columns" themeColor='base' class="header-btn new-column-icon" #anchor (click)='toggleColumnMenu()' [class.active-col-select]='showColumnToggle'>
						</button>
						<button kendoButton themeColor="base" [title]=" isFullscreen ? 'Minimize' : 'Expand Risk Register to Full Screen'" class='header-btn' (click)='toggleView()'
										[imageUrl]='isFullscreen ? "/assets/icons/newIcons/minScreen.svg" : "/assets/icons/newIcons/fullScreen2.svg"'>
						</button>
						<kendo-popup
							[anchor]="anchor.element"
							(anchorViewportLeave)="showColumnToggle = false"
							[popupAlign]='{horizontal: "center", vertical: "top"}'
							[popupClass]='"column-toggle-menu"'
							*ngIf="showColumnToggle"
						>
							<div>

						<div style='display: flex; justify-content: space-between; padding-bottom: 10px;'>
						<button
							kendoButton
							[size]="'small'"
							fillMode="outline"
							[rounded]="'medium'"
							[themeColor]="'info'"
							(click)='resetColumns()'
							style='display: block; width: 84%;'
						>
							Reset
						</button>
						<button
							kendoButton
							[size]="'small'"
							[rounded]="'medium'"
							fillMode="outline"
							[themeColor]="'primary'"
							style='display: block; width: 15%;'
							(click)="toggleColumnMenu()"
						>
							X
						</button>
						</div>

								<kendo-treeview
									[nodes]="allGroups.groups"
									[children]="children"
									[hasChildren]="hasChildren"
									[isDisabled]='isDisabled'
									textField="title"
									[kendoTreeViewCheckable]="{checkParents: true, checkParents: true}"
									[(checkedKeys)]="visibleColumns"
									(checkedKeysChange)='editColumns($event)'
								>
								</kendo-treeview>

							</div>
						</kendo-popup>
					</span>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
    </ng-template>
    <kendo-grid-column-group *ngFor='let group of groups'
                             [title]='group.title'
                             [headerStyle]="{'background-color': group.bgColor, 'padding': '0', 'font-weight': 'bold'}"
                             [resizable]='!minimizedGroups.has(group.title)'
                             class='no-padding grid-group-header'
                             [stickable]='true'
    >
      <ng-template
        kendoGridHeaderTemplate
        let-group
        let-columnIndex="columnIndex"
      >
        <div (click)='toggleGroup(group.title)' style='width: 100%;height: 100%;margin:0;padding: 3px; display: grid; grid-auto-flow: column; grid-template-columns: 30px 1fr'>
          <div style="z-index:50;">
            <button
              kendoButton
              [fillMode]='"outline"'
              style='width: 20px; height: 20px'
            ><kendo-svg-icon
                   [class.k-svg-i-minus]='!minimizedGroups.has(group.title)'
                   [class.k-svg-i-plus]='minimizedGroups.has(group.title)'
									 [icon]='minimizedGroups.has(group.title) ? icons.plus : icons.minus'
            ></kendo-svg-icon></button>
          </div>
          <div *ngIf='!minimizedGroups.has(group.title)' style='text-align: center; display: flex; justify-content: center; align-items: center'>
            {{ group.title}}
          </div>

        </div>

      </ng-template>
      <kendo-grid-column
        [hidden]='!minimizedGroups.has(group.title) || (group.title === "General")'
        [width]='isFullscreen ? 50 : 23'
      >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <span class='whole-cell'>

          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column *ngFor='let column of group.columns'
                         [field]="column.field"
                         [title]="(isFullscreen ? column.fullscreenTitle : column.title) || column.title"
                         [width]="(isFullscreen ? (widthPreferences.fullscreen?.[column.field] || column.fullscreenWidth) : widthPreferences.riskPage?.[column.field]) || column.__width || 100"
                         [format]='column.field.includes("estCost") ? { style: "currency"} : "" '
                         [class]='"register-table-col " + (isFullscreen ? "fullscreen" : column.field.toLowerCase().includes("score") ? "no-padding" : "")'
                         [hidden]='!(isEditingNew && column.required && editingFormGroup.touched) && (!column.alwaysVisible && (minimizedGroups.has(group.title) || (hiddenColumns.has(column.field))))'
                         [class.no-padding]='isFullscreen'
                         [filter]='column.filterType || "text"'
                         [sticky]='column.sticky'
                         [headerClass]='"risk-col-header"'
                         [editor]='column.editor'
						 [editable]="false"
      >
				<ng-template
					kendoGridFilterMenuTemplate
					let-filter="filter"
					let-filterService="filterService"
					*ngIf='column.options?.length'
				>
					<kendo-multiselect
						style="width: 220px"
						[data]="existingEntries[column.options] || column.options || []"
						[valuePrimitive]="true"
						[value]="filter | filterValues"
						(valueChange)="categoryChange($event, filterService, column.field)"
					>
					</kendo-multiselect>
				</ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup" *ngIf='column.options?.length'>
          <kendo-dropdownlist *ngIf='!column.allowCustom'
            [formControl]="formGroup.get(column.field)"
            [data]='column.options'>
          </kendo-dropdownlist>
          <kendo-combobox *ngIf='column.allowCustom'
            [formControl]="formGroup.get(column.field)"
                          [data]="existingEntries[column.options] || []"
                          [allowCustom]='true'>
          </kendo-combobox>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup" *ngIf='column.field === "preMitigation.activityImpact"'>
					<kendo-numerictextbox
						format="# '%'"
						[value]="parseFloat(parseFloat((dataItem.preMitigation.activityImpact * 100).toString()).toFixed(0))"
						(valueChange)='saveAsPct(formGroup, column.field, $event)'
						[step]="1"
						[spinners]='false'
					></kendo-numerictextbox>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup" *ngIf='column.field === "postMitigation.activityImpact"'>
					<kendo-numerictextbox
						format="# '%'"
						[value]="parseFloat(parseFloat((dataItem.postMitigation.activityImpact * 100).toString()).toFixed(0))"
						(valueChange)='saveAsPct(formGroup, column.field, $event)'
						[step]="1"
						[spinners]='false'
					></kendo-numerictextbox>
        </ng-template>
		  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" *ngIf='column.field === "preMitigation.probability"'>
                    <span class='whole-cell'>
            {{ (dataItem.preMitigation.probability || 0) }}
          </span>
		  </ng-template>
		  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" *ngIf='column.field === "preMitigation.scheduleImpact"'>
                    <span class='whole-cell'>
            {{ (dataItem.preMitigation.scheduleImpact || 0) }}
          </span>
		  </ng-template>
		  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" *ngIf='column.field === "preMitigation.costImpact"'>
                    <span class='whole-cell'>
            {{ (dataItem.preMitigation.costImpact || 0) }}
          </span>
		  </ng-template>
		  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" *ngIf='column.field === "preMitigation.performanceImpact"'>
                    <span class='whole-cell'>
            {{ (dataItem.preMitigation.performanceImpact || 0) }}
          </span>
		  </ng-template>
		  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" *ngIf='column.field === "preMitigation.qualityImpact"'>
                    <span class='whole-cell'>
            {{ (dataItem.preMitigation.qualityImpact || 0) }}
          </span>
		  </ng-template>
		  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" *ngIf='column.field === "postMitigation.scheduleImpact"'>
                    <span class='whole-cell'>
            {{ (dataItem.postMitigation.scheduleImpact || 0) }}
          </span>
		  </ng-template>
		  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" *ngIf='column.field === "postMitigation.costImpact"'>
                    <span class='whole-cell'>
            {{ (dataItem.postMitigation.costImpact || 0) }}
          </span>
		  </ng-template>
		  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" *ngIf='column.field === "postMitigation.performanceImpact"'>
                    <span class='whole-cell'>
            {{ (dataItem.postMitigation.performanceImpact || 0) }}
          </span>
		  </ng-template>
		  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" *ngIf='column.field === "postMitigation.qualityImpact"'>
                    <span class='whole-cell'>
            {{ (dataItem.postMitigation.qualityImpact || 0) }}
          </span>
		  </ng-template>
		  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" *ngIf='column.field === "preMitigation.activityImpact"'>
				<span class='whole-cell'>
            {{ parseFloat(((dataItem.preMitigation.activityImpact || 0) * 100).toString()).toFixed(0) }}%
          </span>
		  </ng-template>
		  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" *ngIf='column.field === "postMitigation.activityImpact"'>
                    <span class='whole-cell'>
            {{ parseFloat(((dataItem.postMitigation.activityImpact || 0) * 100).toString()).toFixed(0) }}%
          </span>
		  </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" *ngIf='column.field === "PreRiskScore"'>
                    <span class='whole-cell k-grid-ignore-click' [style.backgroundColor]='colorCodeRiskScore(dataItem.PreRiskScore || 0)'>
            {{ (dataItem.PreRiskScore || 0) }}
          </span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" *ngIf='column.field === "PostRiskScore"'>
                    <span class='whole-cell k-grid-ignore-click'  [style.backgroundColor]='colorCodeRiskScore(dataItem.PostRiskScore || 0)'>
                      {{ dataItem.PostRiskScore }}
          </span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" *ngIf='column.field === "RiskScoreDelta"'>
          <span class='whole-cell k-grid-ignore-click' >
                      {{ dataItem.RiskScoreDelta }}
          </span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" *ngIf='column.field.toLowerCase().includes("date")'>
          <span class='whole-cell'>
                      {{ dataItem[column.field] > 0 ? (dataItem[column.field] | date: 'MM/dd/yyyy') : '' }}
          </span>
        </ng-template>
      </kendo-grid-column>

    </kendo-grid-column-group>
    <kendo-grid-column-group [sticky]='true' [columnMenu]='false'>
      <kendo-grid-column [sortable]='false'
                         [filterable]='false'
                         [columnMenu]='false'
                         [width]='isFullscreen ? 105 : 95'
                         [sticky]='true'
                         [headerStyle]='{"background-color": "#ededed"}'
                         [headerClass]='"fullscreen-header"'
                         [class.no-padding]='isFullscreen'
                         [autoSize]='true'
						 [resizable]='false'
      >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
					<span style='display: flex;'>
						<button kendoButton [svgIcon]='icons.pencil' matTooltip="Edit" matTooltipPosition='above'  fillMode='flat' [disabled]='disableEdit.value || navBarStorage.monteCarloCalculating' (click)='editRiskRegister(dataItem, rowIndex)' *ngIf='(dataItem.riskId !== editingRiskId || !isInlineEditing) && !project.$currentProjectData.value?.isArchived'></button>
						<button kendoGridSaveCommand [svgIcon]='icons.save' matTooltip="Save" fillMode='flat' style='color:#37b400' [disabled]='disableEdit.value' *ngIf='dataItem.riskId === editingRiskId && !project.$currentProjectData.value?.isArchived'></button>
						<button kendoButton [svgIcon]='icons.copy' matTooltip="Duplicate" matTooltipPosition='above' fillMode='flat' [disabled]='disableEdit.value' (click)='doDuplication(dataItem)' *ngIf='rowIndex >= 0 && !project.$currentProjectData.value?.isArchived'></button>
						<button kendoButton [svgIcon]='icons.trash' matTooltip="Delete" matTooltipPosition='above' fillMode='flat' [disabled]='disableEdit.value' (click)='deleteItem(dataItem)' themeColor='error' *ngIf='rowIndex >= 0 && !project.$currentProjectData.value?.isArchived'></button>
						<button kendoGridCancelCommand *ngIf='rowIndex < 0'>
							Cancel
						</button>
					</span>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid-column-group>
    <kendo-grid-excel [fileName]="fileName">
      <kendo-excelexport-column-group
        *ngFor='let group of groups'
        [title]='group.title'
        [headerCellOptions]='{background: group.bgColor, bold: true, color: "black"}'
      >
        <kendo-excelexport-column
          *ngFor='let column of group.columns'
          [field]="column.field"
          [title]="column.fullscreenTitle || column.title"
        >
        </kendo-excelexport-column>
      </kendo-excelexport-column-group>
    </kendo-grid-excel>
  </kendo-grid>
</div>

