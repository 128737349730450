import { Component, ElementRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { caretAltDownIcon, folderIcon } from '@progress/kendo-svg-icons';
import { exportPDF } from '@progress/kendo-drawing';
import { AnalyticsDashboardService } from '../../../../services/analytics/analytics.service';
import { AppWindowService } from '../../../../services/common/window.service';
import { GanttService } from '../../../../services/project/gantt.service';

@Component({
	selector: 'app-current-update-gantt',
	templateUrl: './current-update-gantt.component.html',
	styleUrls: ['./current-update-gantt.component.scss'],
})
export class CurrentUpdateGanttComponent {
	customPaperSize = [3000, 1300];
	exportLoading: boolean = false;
	projectName: string = '';
	fullyExpanded: boolean = true;
	fullyCollapsed: boolean = false;
	$toggleExpand = new BehaviorSubject<boolean>(null);
	$expandOneLevel = new BehaviorSubject<boolean>(null);
	$collapseOneLevel = new BehaviorSubject<boolean>(null);
	$filtersOpened = new BehaviorSubject<ElementRef | HTMLElement>(undefined);
	$columnSelectorOpened = new BehaviorSubject<ElementRef | HTMLElement>(undefined);
	$groupSelectorOpened = new BehaviorSubject<ElementRef | HTMLElement>(undefined);
	$presetSelectorOpened = new BehaviorSubject<ElementRef | HTMLElement>(undefined);
	columnSelectorPopupIsOpen: boolean = false;
	groupSelectorPopupIsOpen: boolean = false;
	presetSelectorPopupIsOpen: boolean = false;
	public icons = {
		caretAltDown: caretAltDownIcon,
		folder: folderIcon,
	};

	constructor(
		public analyticsService: AnalyticsDashboardService,
		public windowService: AppWindowService,
		public ganttService: GanttService
	) {}

	updatePresetSelectorState(val: boolean): void {
		if (val !== undefined) {
			this.presetSelectorPopupIsOpen = val;
		}
	}

	updateButtonGroupsOnPresetClick(val: number[]) {
		if (val.length === 2) {
			this.ganttService.relationshipsButtons.forEach((item) => {
				item.selected = val[0] === item.value;
			});
			this.ganttService.taskTypeButtons.forEach((item) => {
				item.selected = val[1] === item.value;
			});
		}
	}

	updateProjectName(name: string): void {
		this.projectName = name;
	}

	updateFullyExpanded(val: boolean): void {
		this.fullyExpanded = val;
	}

	updateFullyCollapsed(val: boolean): void {
		this.fullyCollapsed = val;
	}

	resetButtonClicked(): void {
		this.ganttService.resetAll();
	}

	updateColumnSelectorState(val: boolean): void {
		if (val !== undefined) {
			this.columnSelectorPopupIsOpen = val;
		}
	}

	updateGroupSelectorState(val: boolean): void {
		if (val !== undefined) {
			this.groupSelectorPopupIsOpen = val;
		}
	}

	openFilterMenu(anchor: ElementRef | HTMLElement, menu: 'filters' | 'columns' | 'groups' | 'presets'): void {
		switch (menu) {
			case 'filters': {
				this.$filtersOpened.next(anchor);
				break;
			}
			case 'columns': {
				this.$columnSelectorOpened.next(anchor);
				break;
			}
			case 'groups': {
				const currentVal: boolean = this.analyticsService.ganttGroupByOpen.value;
				this.analyticsService.ganttGroupByOpen.next(!currentVal);
				if (!currentVal) {
					this.windowService.setViewport('ganttGroupBy');
				}
				// this.$groupSelectorOpened.next(anchor);
				break;
			}
			case 'presets': {
				this.$presetSelectorOpened.next(anchor);
				break;
			}
		}
	}

	toggleAllNodes(newVal: boolean): void {
		this.$toggleExpand.next(newVal);
	}

	expandOneLevel(): void {
		this.$expandOneLevel.next(true);
	}

	collapseOneLevel(): void {
		this.$collapseOneLevel.next(true);
	}

	/**
	 * Used to track how long the pdf is exporting and know when to put a loader icon
	 * @param pdf
	 */
	onExport(pdf) {
		this.exportLoading = true;
		pdf.export().then((group) => {
			exportPDF(group).then((data) => {
				saveAs(data, this.projectName + ' - ' + this.ganttService.updateName + ' - Gantt.pdf');
				this.exportLoading = false;
			});
		});
	}

	openReportCenter(): void {
		const currentVal: boolean = this.ganttService.$manageWindowOpen.value;
		this.ganttService.$manageWindowOpen.next(!currentVal);
		if (!currentVal) {
			this.windowService.setViewport('reportManage');
		}
	}
}
