import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SharedMatModule } from '../../../shared/shared-mat.module';
import { TooltipMenuModule } from '../../../portfolio/tooltip-menu/tooltip-menu.module';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { GridLayoutModule } from '@progress/kendo-angular-layout';
import { TopRisksComponent } from './top-risks.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { OverviewNotesModule } from '../../../shared/overview-notes/overview-notes.module';

@NgModule({
	declarations: [TopRisksComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		IonicModule,
		SharedMatModule,
		TooltipMenuModule,
		ChartsModule,
		GridLayoutModule,
		GridModule,
		OverviewNotesModule,
	],
	exports: [TopRisksComponent],
})
export class TopRisksModule {}
