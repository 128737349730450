import { SlimmedTaskCommon, TaskArrayInterface } from '../models/Update/Task';
import { addDays, differenceInBusinessDays, isValid, isWeekend, parseISO } from 'date-fns';
import { Activity, Calendar, XerActivity, XerData, XerTaskPredecessor } from '@rhinoworks/xer-parse';

export const getLowercaseValues = (obj: object, keys: Array<string> = []): Set<string> => {
	const lowerCaseValues: Set<string> = new Set();
	if (obj) {
		for (const [key, value] of Object.entries(obj)) {
			if (keys.length === 0 || keys.includes(key)) {
				lowerCaseValues.add(value?.toString().toLowerCase());
			}
		}
	}

	return lowerCaseValues;
};

export const isNearCriticalPlanned = (
	task: SlimmedTaskCommon,
	nextDataDate: Date | undefined,
	useStarts: boolean = false
): boolean => {
	if (task.isCritical || !nextDataDate || task.act_end_date) {
		return false;
	}
	if (useStarts) {
		return task.early_start_date <= nextDataDate && task.late_start_date <= nextDataDate;
	}
	if (task.task_type === 'TT_Mile' && !!task.late_start_date && !!task.early_start_date) {
		return task.early_start_date < nextDataDate && task.late_start_date < nextDataDate;
	}
	return (
		!!task.early_end_date &&
		!!task.late_end_date &&
		task.early_end_date < nextDataDate &&
		task.late_end_date < nextDataDate
	);
};

export const isLookaheadNearCriticalPlanned = (
	task: SlimmedTaskCommon,
	nextDataDate: Date | undefined,
	daysAdded: number,
	useStarts: boolean = false
): boolean => {
	if (task.isCritical || !nextDataDate || task.act_end_date) {
		return false;
	}
	const lookaheadDate: Date = addDays(nextDataDate, daysAdded);
	if (useStarts) {
		return task.early_start_date <= lookaheadDate && task.late_start_date <= lookaheadDate;
	}
	if (task.task_type === 'TT_Mile' && !!task.late_start_date && !!task.early_start_date) {
		return task.early_start_date < lookaheadDate && task.late_start_date < lookaheadDate;
	}
	return (
		!!task.early_end_date &&
		!!task.late_end_date &&
		task.early_end_date < lookaheadDate &&
		task.late_end_date < lookaheadDate
	);
};

export const isCriticalPlanned = (
	task: SlimmedTaskCommon,
	dataDate: Date | undefined,
	useStarts: boolean = false
): boolean => {
	if (!task.isCritical || !dataDate) {
		return false;
	}
	if (useStarts) {
		return !!task.early_start_date && task.early_start_date <= dataDate;
	}
	if (task.task_type === 'TT_Mile') {
		return !!task.early_start_date && task.early_start_date < dataDate;
	}
	return !!task.early_end_date && task.early_end_date < dataDate;
};

export const isLookaheadCriticalPlanned = (
	task: SlimmedTaskCommon,
	dataDate: Date | undefined,
	daysAdded: number,
	useStarts: boolean = false
): boolean => {
	if (!task.isCritical || !dataDate) {
		return false;
	}
	const lookaheadDate: Date = addDays(dataDate, daysAdded);
	if (useStarts) {
		return !!task.early_start_date && task.early_start_date <= lookaheadDate;
	}
	if (task.task_type === 'TT_Mile') {
		return !!task.early_start_date && task.early_start_date < lookaheadDate;
	}
	return !!task.early_end_date && task.early_end_date < lookaheadDate;
};

export const isNonCriticalPlanned = (
	task: SlimmedTaskCommon,
	dataDate: Date | undefined,
	useStarts: boolean = false
): boolean => {
	if (task.isCritical || !dataDate || !!task.act_end_date) {
		return false;
	}
	if (useStarts) {
		return (
			!!task.early_start_date &&
			!!task.late_start_date &&
			task.early_start_date <= dataDate &&
			task.late_start_date >= dataDate
		);
	}
	if (task.task_type === 'TT_Mile') {
		return (
			!!task.early_start_date &&
			!!task.late_start_date &&
			task.early_start_date < dataDate &&
			task.late_start_date >= dataDate
		);
	}
	return (
		!!task.early_end_date && !!task.late_end_date && task.early_end_date < dataDate && task.late_end_date >= dataDate
	);
};

export const isLookaheadNonCriticalPlanned = (
	task: SlimmedTaskCommon,
	dataDate: Date | undefined,
	daysAdded: number,
	useStarts: boolean = false
): boolean => {
	if (task.isCritical || !dataDate || !!task.act_end_date) {
		return false;
	}
	const lookaheadDate: Date = addDays(dataDate, daysAdded);
	if (useStarts) {
		return (
			!!task.early_start_date &&
			!!task.late_start_date &&
			task.early_start_date <= lookaheadDate &&
			task.late_start_date >= lookaheadDate
		);
	}
	if (task.task_type === 'TT_Mile') {
		return (
			!!task.early_start_date &&
			!!task.late_start_date &&
			task.early_start_date < lookaheadDate &&
			task.late_start_date >= lookaheadDate
		);
	}
	return (
		!!task.early_end_date &&
		!!task.late_end_date &&
		task.early_end_date < lookaheadDate &&
		task.late_end_date >= lookaheadDate
	);
};

export const taskDuration = <
	T extends { act_start_date: number | string | Date; act_end_date: number | string | Date },
>(
	task: T
): number | undefined => {
	if (!task.act_end_date) {
		return undefined;
	}
	return businessDuration(task.act_start_date, task.act_end_date);
};

export const businessDuration = (start: number | string | Date, end: number | string | Date): number | undefined => {
	const startDate = new Date(start);
	const endDate = new Date(end);
	if (isValid(startDate) && isValid(endDate) && endDate > startDate) {
		const startIsBusinessDay = !isWeekend(startDate);
		const endIsBusinessDay = !isWeekend(endDate);
		const additionalDays =
			startIsBusinessDay || endIsBusinessDay ? (startIsBusinessDay && endIsBusinessDay ? 1 : 2) : 0;
		return differenceInBusinessDays(endDate, startDate) + additionalDays;
	}
	return undefined;
};

export const shortNames = (task: Activity): Set<string> =>
	new Set<string>([...task.activityCodes.values()].map((code) => code.shortName));

export function dataFromXer<T>(data: XerData, tableName: string): T[] {
	const tableIndex = data.findIndex((row) => row[0] === '%T' && row[1] === tableName);
	if (tableIndex === -1) {
		return [];
	}
	const fieldRow = data[tableIndex + 1];
	if (fieldRow[0] !== '%F') {
		return [];
	}
	const tasks: T[] = [];
	for (let i = tableIndex + 2; i < data.length && data[i][0] === '%R'; i++) {
		const entry = {};
		for (let j = 1; j < fieldRow.length; j++) {
			if (data[i][j].length > 0) {
				if (fieldRow[j].includes('_date')) {
					entry[fieldRow[j]] = new Date(data[i][j]);
				} else if (fieldRow[j].includes('_cnt') || fieldRow[j].includes('_qty') || fieldRow[j].includes('_id')) {
					entry[fieldRow[j]] = +data[i][j];
				} else {
					entry[fieldRow[j]] = data[i][j];
				}
			}
		}
		tasks.push(entry as T);
	}
	return tasks;
}

export function convertObjFromStr(entry: Record<string, string | Date | number>) {
	for (const key of Object.keys(entry)) {
		const val = entry[key];
		if (!val?.toString().length) {
			continue;
		}
		if (key.includes('_date')) {
			entry[key] = new Date(val);
		} else if (key.includes('_cnt') || key.includes('_qty') || key.includes('_id')) {
			entry[key] = +val;
		}
	}
	return entry;
}

export const EXPLICIT_NUMBER_FIELDS = ['bcwp', 'bcws', 'total_assignments'];

export function buildEntryFromVariables(
	entry: Record<string, any>,
	entryValues: Array<string>,
	entryVariableMap: Map<string, string>,
	tableFields: Array<string>,
	entryValuesByKey?: { [key: string]: any }
): typeof entry {
	if (entry && (entryValues.length > 0 || Object.keys(entryValuesByKey).length > 0) && tableFields.length > 0) {
		tableFields.forEach((rawField: string, i: number) => {
			const value: string | undefined = entryValues?.[i] ?? entryValuesByKey?.[rawField] ?? undefined;
			const objectField: string = entryVariableMap?.get(rawField);
			const field: string = (objectField ?? rawField) || '';

			if (tableFields.length <= i || !entryVariableMap || !field) {
				return;
			}

			let isFlag: boolean = rawField.endsWith('_flag');

			let isDate: boolean = rawField.endsWith('_date');

			const isNumberReg = new RegExp('^-?\\d+(\\.\\d+)?$');
			const force_str = ['task_code'];
			const isNumber: boolean =
				EXPLICIT_NUMBER_FIELDS.includes(rawField) ||
				((rawField.endsWith('_id') ||
					rawField.endsWith('_qty') ||
					rawField.endsWith('_cnt') ||
					rawField.endsWith('_cost') ||
					rawField.endsWith('_num')) &&
					isNumberReg.test(value) &&
					!isFlag &&
					!isDate &&
					!force_str.includes(field));
			isFlag = isFlag && !isDate && !isNumber;
			isDate = isDate && !isNumber && !isFlag;

			if (isFlag) {
				const flagIsTrue = new RegExp('^[Yy]$');
				if (!entry.flags) {
					entry.flags = {} as { [key: string]: boolean };
				}
				entry.flags[field] = flagIsTrue.test(value);
			} else if (isDate && typeof value === 'string') {
				entry[field] = value ? parseISO(value) : undefined;
			} else if (isNumber) {
				entry[field] = Number(value);
			} else {
				entry[field] = value?.toString();
			}
		});
	}
	if (entryValuesByKey) {
		entry.raw_entry = entryValuesByKey;
	}
	return entry;
}

type Grapha = Map<number, number[]>;
type VisitState = Map<number, 'permanent' | 'temporary' | 'unvisited'>;
export function topologicalSortNodes(
	nodes: number[],
	dependencies: [number, number][],
	actvs: Map<number, XerActivity>
): { nodes: number[]; invalidDependencies: [number, number][] } {
	const graph: Grapha = new Map();
	const visitState: VisitState = new Map();
	const order: number[] = [];
	const cycleStack: number[] = []; // To track the path for cycle detection
	let cycleDetected = false;
	const invalidDependencies: [number, number][] = [];

	// Initialize graph and visitState
	nodes.forEach((node) => {
		graph.set(node, []);
		visitState.set(node, 'unvisited');
	});

	// Build graph
	dependencies.forEach(([u, v]) => {
		graph.get(u)!.push(v);
	});

	function dfs(node: number): boolean {
		if (visitState.get(node) === 'permanent') {
			return false;
		}
		if (visitState.get(node) === 'temporary') {
			cycleStack.push(node); // Add the current node to the cycle path
			cycleDetected = true;
			return true;
		}

		visitState.set(node, 'temporary');
		cycleStack.push(node);

		for (const neighbor of graph.get(node)!) {
			if (dfs(neighbor)) {
				if (cycleDetected) {
					return true;
				} // Continue the propagation of cycle detection
			}
		}

		cycleStack.pop();
		visitState.set(node, 'permanent');
		order.push(node);
		return false;
	}

	for (const node of nodes) {
		if (visitState.get(node) === 'unvisited') {
			if (dfs(node)) {
				break;
			}
		}
	}

	if (cycleDetected) {
		const cycleStart = cycleStack.pop()!;
		let cyclePath = `${cycleStart} (${actvs.get(cycleStart)?.task_code})`;
		let nextNode: number;
		let prevNode: XerActivity = actvs.get(cycleStart)!;
		while ((nextNode = cycleStack.pop()) !== undefined && nextNode !== cycleStart) {
			cyclePath = `${nextNode} (${actvs.get(nextNode).task_code}) -> ` + cyclePath;
			if (prevNode) {
				const predNode = actvs.get(nextNode);
				/*        const preds = prevNode.predecessors.filter(
					(p) => p.prevActivityId === predNode.id,
				);
				const mypred = preds[0];
				const predBindDate =
					mypred.previousRelationType[3] === 'S'
						? predNode.start || predNode.targetStart
						: predNode.finish || predNode.targetFinish;
				const succBindDate =
					mypred.previousRelationType[4] === 'S'
						? prevNode.start || prevNode.targetStart
						: prevNode.finish || prevNode.targetFinish;
				console.log(
					predNode.code,
					prevNode.code,
					mypred.previousRelationType,
					preds.length,
					predBindDate,
					succBindDate,
					mypred.lagHrs,
					predBindDate.getTime() > succBindDate.getTime() ? 'OUT OF ORDER' : '',
					prevNode.start,
					prevNode.hasUnfinishedPredecessors,
				);*/
				invalidDependencies.push([predNode.task_id, prevNode.task_id]);
			}
			prevNode = actvs.get(+nextNode);
		}
		cyclePath = `${cycleStart} -> ` + cyclePath;
		//console.log('Cycle detected in dependencies: ' + cyclePath);
	}

	return { nodes: order.reverse(), invalidDependencies };
}

export function sortActivities(
	activitiesByCode: Map<string, XerActivity>,
	activitiesById: Map<number, XerActivity>,
	successorsByCode: Map<string, XerTaskPredecessor[]>,
	predecessorsByCode: Map<string, XerTaskPredecessor[]>
): Array<XerActivity> {
	const adjacencies: [number, number][] = [];
	for (const actv of activitiesByCode.values()) {
		for (const pred of predecessorsByCode.get(actv.task_code) || []) {
			const prevActv = activitiesById.get(pred.pred_task_id);
			if (!(prevActv.task_type === 'TT_LOE' && actv.task_type !== 'TT_LOE')) {
				adjacencies.push([prevActv.task_id, actv.task_id]);
			}
		}
	}
	let actvSequence: string[] = [];
	let invalidDependencies: [number, number][] = [];
	const numDisabled = 0;

	function topologicalSort(): string[] {
		const visited: Set<string> = new Set();
		const sortedNodes: string[] = [];

		function dfs(node: string): void {
			visited.add(node);
			(successorsByCode.get(node) || [])
				.map((pr) => activitiesById.get(pr.task_id))
				.filter((na) => na.task_type !== 'TT_LOE')
				.map((na) => na.task_code)
				.forEach((neighbor) => {
					if (!visited.has(neighbor)) {
						dfs(neighbor);
					}
				});
			sortedNodes.push(node);
		}

		Array.from(activitiesByCode.values())
			.filter((na) => na.task_type !== 'TT_LOE')
			.map((na) => na.task_code)
			.forEach((node) => {
				if (!visited.has(node)) {
					dfs(node);
				}
			});

		return sortedNodes.reverse();
	}
	do {
		const result = topologicalSortNodes(Array.from(activitiesById.keys()), adjacencies, activitiesById);
		invalidDependencies = result.invalidDependencies;
		actvSequence = topologicalSort();
		if (invalidDependencies.length > 0) {
			const depIndex = adjacencies.findIndex(([a, b]) => {
				const [a1, b1] = invalidDependencies[0];
				return a === a1 && b === b1;
			});
			if (depIndex > -1) {
				adjacencies.splice(depIndex, 1);
				return Array.from(activitiesById.values());
			}
		}
	} while (invalidDependencies.length);
	if (numDisabled) {
		console.log(`${numDisabled} invalid dependencies deleted`);
	}
	const sortedActv: XerActivity[] = [];
	for (const actvId of actvSequence) {
		const actv = activitiesByCode.get(actvId);
		if (!sortedActv.includes(actv)) {
			sortedActv.push(actv);
		}
	}

	return sortedActv;
}

export function isCritical(activity: XerActivity): boolean {
	return !activity?.act_end_date && activity?.driving_path_flag === 'Y';
}

export function isDriving(
	relationship: XerTaskPredecessor,
	predecessor: XerActivity,
	successor: XerActivity,
	calendar: Calendar
): boolean {
	return (
		isCritical(predecessor) &&
		isCritical(successor) &&
		((relationship.pred_type === 'PR_FS' &&
			!!predecessor.early_end_date &&
			!!successor.early_start_date &&
			calendar.durationDays(predecessor.early_end_date, successor.early_start_date) <= relationship.lag_hr_cnt / 8) ||
			(relationship.pred_type === 'PR_SS' &&
				!!successor.early_start_date &&
				calendar.durationDays(predecessor.act_start_date || predecessor.early_start_date, successor.early_start_date) <=
					relationship.lag_hr_cnt / 8) ||
			(relationship.pred_type === 'PR_FF' &&
				!!predecessor.early_end_date &&
				!!successor.early_end_date &&
				calendar.durationDays(predecessor.early_end_date, successor.early_end_date) <= relationship.lag_hr_cnt / 8))
	);
}
