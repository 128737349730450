import { Injectable, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LicenseTier, PortfolioPreset, PresetInputs, ProfileCompanyPermission } from '../../models/auth/account-user';

export type AuthorizationLevel = 'admin' | 'viewer' | 'regular joe';

@Injectable({
	providedIn: 'root',
})
export class NavigationBarStorageService implements OnDestroy {
	$tabPointer = new BehaviorSubject<string>('');
	private _unsubscribeAll = new Subject<void>();
	constructor() {}
	showingNewProject: boolean = false;
	showingEditProject: boolean = false;
	showingBaselineProject: boolean = false;
	clientList = [];
	selectedTab: string = 'overview';
	selectedTabSchedules: string = 'manage';
	sidenavWidth = 0;
	summaryOffScreen: boolean = false;
	riskMetricsOptions = {};
	newProjectFormMaxHeight = 400;
	bulkUpdateFormMaxHeight = 466;
	reportFormMaxHeight = 400;
	reportFormPrevFloatWidth = 0;
	reportWindowOpen = false;
	selectedUpdateIndexes: number[];
	startFromIndex: number = null;
	monteCarloCalculating: boolean = false;
	_authorizationLevel: AuthorizationLevel = 'regular joe';
	companyToAuthorizationMap = new Map<number, AuthorizationLevel>([]);
	companyPermissionMap = new Map<number, ProfileCompanyPermission>([]);
	_highestCompanyTier: LicenseTier = 'ANALYTICS-BASIC-MPK';
	doingMonteCarloToggle: boolean = false;
	$triggerNewProjectTooltip = new BehaviorSubject<boolean>(false);
	$adDialogOpen = new BehaviorSubject<boolean>(false);
	eulaOpened: boolean = false;
	$portfolioPresetWindowOpen = new BehaviorSubject<PresetInputs>(null);
	selectedLayout: string = null;
	justApplied: number = 0;

	scroll = (event): void => {
		this.sidenavWidth = document.getElementsByClassName('navbar')[0]?.scrollWidth; //for resizing header bar based on folded state of sidenav
		const rect = document.getElementById('top-row-summary').getBoundingClientRect();

		this.summaryOffScreen =
			rect.x + rect.width < 0 || rect.y + rect.height < 0 || rect.x > window.innerWidth || rect.y > window.innerHeight;
		const schedulesGrid = document.getElementById('schedules-grid');
		if (schedulesGrid) {
			const header = schedulesGrid.querySelector('.k-grid-header') as HTMLElement;
			const bottomOfSchedulesGrid = schedulesGrid.getBoundingClientRect().bottom;
			if (this.justApplied === 0 || Date.now() - this.justApplied > 50 || bottomOfSchedulesGrid < 94) {
				if (!this.summaryOffScreen || bottomOfSchedulesGrid < 94) {
					header.classList.remove('fixed-header');
					schedulesGrid.classList.remove('fixed-buffer');
					this.justApplied = 0;
				} else {
					if (!header.classList.contains('fixed-header')) {
						header.classList.add('fixed-header');
						header.style.width = schedulesGrid.clientWidth + 'px';
						schedulesGrid.classList.add('fixed-buffer');
						this.justApplied = Date.now();
					}
				}
			}
		}
	};

	scrollGantt = (event): void => {
		const currentUpdateGantt = document.getElementById('currentUpdateGantt');
		if (currentUpdateGantt) {
			const header = currentUpdateGantt.querySelector('.k-grid-header') as HTMLElement;
			const headerParent = header.parentElement;
			const timelinePane = currentUpdateGantt.querySelector('.k-gantt-timeline') as HTMLElement;
			const timelineHeader = timelinePane.querySelector('.k-grid-header') as HTMLElement;
			const topOfScreen: number = window.scrollY;
			if (this.justApplied === 0 || Date.now() - this.justApplied > 50 || topOfScreen < 90) {
				if (topOfScreen < 90) {
					header.classList.remove('fixed-header');
					timelineHeader.classList.remove('fixed-header');
					currentUpdateGantt.classList.remove('fixed-buffer');
					this.justApplied = 0;
				} else {
					if (!header.classList.contains('fixed-header')) {
						header.classList.add('fixed-header');
						header.style.width = headerParent.clientWidth + 'px';
						timelineHeader.classList.add('fixed-header');
						timelineHeader.style.width = timelinePane.clientWidth + 'px';
						currentUpdateGantt.classList.add('fixed-buffer');
						this.justApplied = Date.now();
					}
				}
			}
		}
	};

	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	/**
	 * updates width of div to match current float div's width for alignment purposes
	 */
	updateReportFormPrevFloatDivWidth(): void {
		const currentFloatDiv = document.getElementsByClassName('current-float-div')[0];
		this.reportFormPrevFloatWidth = currentFloatDiv === undefined ? 0 : currentFloatDiv?.getBoundingClientRect()?.width;
	}
}
