<div id="preset-window-content">
	<div class="header">
		<div class="desc">
			These are the fields saved in a layout. Change the values to your liking and press save to create a new layout with this name.
		</div>
		<button kendoButton themeColor="error" title="Reset" [disabled]='saving || loading' (click)='reset()'>
			Reset
		</button>
	</div>
	<kendo-gridlayout
		[rows]="['auto']"
		[cols]="['calc(50% - 2.5px)', 'calc(50% - 2.5px)']"
		[gap]="{ rows: 0, cols: 5 }"
		class="grid"
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-container'>
			<fieldset>
				<legend>
					General
				</legend>
				<div class="layout-name-container">
					<kendo-formfield class="preset-form-name">
						<kendo-label [for]="presetName" text="Layout Name:" style="width: 91px;"></kendo-label>
						<kendo-textbox
							[(ngModel)]="name"
							#presetName
							required
							[clearButton]="true"
						>
						</kendo-textbox>
						<kendo-formerror [align]="'end'" style="margin-top: 0px;">Layout Name is required</kendo-formerror>
					</kendo-formfield>
<!--					<div>-->
<!--						<kendo-label [for]="isFavoriteCheckbox" text="Favorite:" style="padding-right: 4px;"></kendo-label>-->
<!--						<input type="checkbox" kendoCheckBox [(ngModel)]="isFavorite" #isFavoriteCheckbox style="margin-top: -2px;"/>-->
<!--						<span style="font-size: 12px; font-style: italic; padding-left: 4px; white-space: nowrap;">Favorite layouts show up at the top of the list</span>-->
<!--					</div>-->
				</div>
			</fieldset>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-container'>
			<fieldset style="height: 100%;">
				<legend>
					Views
				</legend>
				<div class="main-wrapper">
					<div style="display: flex; flex-direction: column; justify-content: space-between;">
						<div class='update-status-container' style='padding-top: 0;'>
							<div class='update-status-title'>
								Banner:
							</div>
							<div class='checkbox-container'>
								<input type="radio" size='large' #defaultBtn kendoRadioButton [(ngModel)]="banner" value='default' (ngModelChange)='checkboxChanged("banner")' name='banner'/>
								<kendo-label
									class="k-checkbox-label"
									[for]="defaultBtn"
									text="Project"
								></kendo-label>
								<input type="radio" size='large' #cost kendoRadioButton name='banner' style='margin-left: 10px;' [(ngModel)]="banner" value='cost' (ngModelChange)='checkboxChanged("banner")'/>
								<kendo-label
									class="k-checkbox-label"
									[for]="cost"
									text="Cost"
								></kendo-label>
								<input type="radio" size='large' #risk kendoRadioButton name='banner' style='margin-left: 10px;' [(ngModel)]="banner" value='risk' (ngModelChange)='checkboxChanged("banner")'/>
								<kendo-label
									class="k-checkbox-label"
									[for]="risk"
									text="Risk"
								></kendo-label>
							</div>
						</div>
						<div class='update-status-container' style='padding-top: 0;'>
							<div class='update-status-title'>
								Portfolio View:
							</div>
							<div class='checkbox-container'>
								<input type="radio" size='large' #table kendoRadioButton [(ngModel)]="projectsListView" value='grid' (ngModelChange)='checkboxChanged("portfolioView")' name='portfolioView'/>
								<kendo-label
									class="k-checkbox-label"
									[for]="table"
									text="Table"
								></kendo-label>
								<input type="radio" size='large' #gantt kendoRadioButton name='portfolioView' style='margin-left: 10px;' [(ngModel)]="projectsListView" value='gantt' (ngModelChange)='checkboxChanged("portfolioView")'/>
								<kendo-label
									class="k-checkbox-label"
									[for]="gantt"
									text="Gantt"
								></kendo-label>
							</div>
						</div>
					</div>
					<div style="display: flex; flex-direction: column; justify-content: space-between;">
						<div style="display: flex; flex-direction: row; justify-content: space-between;">
							<div style="width: 81px;">
								Gantt View:
							</div>
							<kendo-dropdownlist
								[data]="viewOptions"
								valueField='zoomVal'
								textField='display'
								(valueChange)='updateView($event)'
								[valuePrimitive]="true"
								[(ngModel)]="selectedGanttView"
								[disabled]="projectsListView === 'grid'"
								style='width: 100px; height: 24px; margin: -3px 0 auto 0;'
							></kendo-dropdownlist>
						</div>
						<div class='update-status-container' style='padding-top: 0;'>
							<div class='update-status-title'>
								Map:
							</div>
							<div class='checkbox-container'>
								<input type="radio" size='large' #mapShow kendoRadioButton [(ngModel)]="map" [value]='true' (ngModelChange)='checkboxChanged("mapView")' name='mapView'/>
								<kendo-label
									class="k-checkbox-label"
									[for]="mapShow"
									text="Show"
								></kendo-label>
								<input type="radio" size='large' #mapHide kendoRadioButton name='mapView' style='margin-left: 10px;' [(ngModel)]="map" [value]='false' (ngModelChange)='checkboxChanged("mapView")'/>
								<kendo-label
									class="k-checkbox-label"
									[for]="mapHide"
									text="Hide"
								></kendo-label>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
	<kendo-gridlayout
		[rows]="['auto']"
		[cols]="['calc((100% / 3) - (10px / 3))', 'calc((100% / 3) - (10px / 3))', 'calc((100% / 3) - (10px / 3))']"
		[gap]="{ rows: 0, cols: 5 }"
		class="grid"
	>
		<kendo-gridlayout-item [row]='1' [col]='1' colSpan="2" class='gridlayout-container'>
			<fieldset class="filters-fieldset">
				<legend>
					Filters
				</legend>
				<div class="filters-content-wrapper">
					<div class="fields-container">
						<div *ngIf="!loading">
							<kendo-label text="Client">
								<kendo-multiselect
									kendoMultiSelectSummaryTag
									[data]="nestedClientList"
									[(ngModel)]="filterValuesByField.clientId"
									textField="text"
									valueField="value"
									[checkboxes]='true'
									[filterable]="true"
									[autoClose]='false'
									(filterChange)='handleClientFilter($event)'
									(valueChange)='filterChanged("clientId", $event)'
									[clearButton]='false'
									#clientIdMultiselect
									(focus)="focus('clientIdMultiselect')"
								>
									<ng-template kendoMultiSelectFixedGroupTemplate kendoMultiSelectGroupTemplate let-groupName>
										<strong>{{ groupName }}</strong>
									</ng-template>
									<!--We need to do this custom template because [checkboxes] doesn't work with grouped multiselect-->
									<ng-template kendoMultiSelectItemTemplate let-dataItem>
										<span class='poc-item'>
											<input type="checkbox" kendoCheckBox [checked]='filterValuesByField.clientId.includes(dataItem)'/>
											<span class='poc-name'>
												{{ dataItem.text }}
											</span>
										</span>
									</ng-template>
									<ng-template kendoMultiSelectHeaderTemplate>
										<div>
											<input
												kendoCheckBox
												type='checkbox'
												[checked]="isSelectedAll('clientId', filteredClientList)"
												[indeterminate]="isIndet('clientId', filteredClientList)"
												(click)="onFilterSelectAllClick('clientId', filteredClientList)"
											/>
											<kendo-label for="chk">Select all</kendo-label>
										</div>
									</ng-template>
									<ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
										<div class="invisible-multiselect-tag-selector" (click)="toggleMultiselect('clientIdMultiselect')"></div>
										<span>
											<kendo-svg-icon [icon]='icons.caretDown' (click)="toggleMultiselect('clientIdMultiselect')"></kendo-svg-icon>
											{{ filterValuesByField.clientId.length + ' selected' }}
										</span>
									</ng-template>
								</kendo-multiselect>
							</kendo-label>
							<kendo-label text="Project Type">
								<kendo-multiselect
									kendoMultiSelectSummaryTag
									[data]="scheduleTypeList"
									[(ngModel)]="filterValuesByField['scheduleType']"
									textField="text"
									valueField="value"
									[valuePrimitive]='false'
									[checkboxes]='true'
									[autoClose]='false'
									(valueChange)='filterChanged("scheduleType", $event)'
									[clearButton]='false'
									#scheduleTypeMultiselect
									(focus)="focus('scheduleTypeMultiselect')"
								>
									<ng-template kendoMultiSelectHeaderTemplate>
										<div>
											<input
												kendoCheckBox
												type='checkbox'
												[checked]="isSelectedAll('scheduleType', scheduleTypeList)"
												[indeterminate]="isIndet('scheduleType', scheduleTypeList)"
												(click)="onFilterSelectAllClick('scheduleType', scheduleTypeList)"
											/>
											<kendo-label for="chk">Select all</kendo-label>
										</div>
									</ng-template>
									<ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
										<div class="invisible-multiselect-tag-selector" (click)="toggleMultiselect('scheduleTypeMultiselect')"></div>
										<span id='archivedTagTemplate'>
											<kendo-svg-icon [icon]='icons.caretDown' (click)="toggleMultiselect('scheduleTypeMultiselect')"></kendo-svg-icon>
											{{ filterValuesByField['scheduleType'].length + ' selected' }}
										</span>
									</ng-template>
								</kendo-multiselect>
							</kendo-label>
							<kendo-label text="Company">
								<kendo-multiselecttree
									#companyMultiselect
									kendoMultiSelectTreeSummaryTag
									kendoMultiSelectTreeExpandable
									[kendoMultiSelectTreeHierarchyBinding]="nestedCompanyList"
									[(value)]="filterValuesByField['company']"
									textField="text"
									valueField="value.value"
									childrenField='children'
									[valuePrimitive]='false'
									[filterable]='true'
									(valueChange)='filterChanged("company", $event);'
									[clearButton]='false'
									[(expandedKeys)]="expandedCompanyNodes"
									(close)='filterDropdownClose("company", companyList)'
									(focus)="focus('companyMultiselectTree')"
								>
									<ng-template kendoMultiSelectHeaderTemplate>
										<div>
											<input
												kendoCheckBox
												type='checkbox'
												[checked]="isSelectedAll('company', companyList)"
												[indeterminate]="isIndet('company', companyList)"
												(click)="onFilterSelectAllClick('company', companyList)"
											/>
											<kendo-label for="chk">Select all</kendo-label>
										</div>
									</ng-template>
									<ng-template kendoMultiSelectTreeGroupTagTemplate let-dataItems>
										<span id='regionTagTemplate'>
											<kendo-svg-icon [icon]='icons.caretDown'></kendo-svg-icon>
											{{ filterValuesByField['company'].length + ' selected' }}
										</span>
									</ng-template>
								</kendo-multiselecttree>
							</kendo-label>
							<kendo-label text="Risk Metrics Type">
								<kendo-multiselect
									kendoMultiSelectSummaryTag
									[data]="riskList"
									[(ngModel)]="filterValuesByField['riskMetricsType']"
									textField="text"
									valueField="value"
									[checkboxes]='true'
									[autoClose]='false'
									(valueChange)='filterChanged("riskMetricsType", $event)'
									[clearButton]='false'
									#rmtMultiselect
									(focus)="focus('riskMetricsTypeMultiselect')"
								>
									<ng-template kendoMultiSelectHeaderTemplate>
										<div>
											<input
												kendoCheckBox
												type='checkbox'
												[checked]="isSelectedAll('riskMetricsType', riskList)"
												[indeterminate]="isIndet('riskMetricsType', riskList)"
												(click)="onFilterSelectAllClick('riskMetricsType', riskList)"
											/>
											<kendo-label for="chk">Select all</kendo-label>
										</div>
									</ng-template>
									<ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
										<div class="invisible-multiselect-tag-selector" (click)="toggleMultiselect('riskMetricsTypeMultiselect')"></div>
										<span id='rmtTagTemplate'>
											<kendo-svg-icon [icon]='icons.caretDown' (click)="toggleMultiselect('riskMetricsTypeMultiselect')"></kendo-svg-icon>
											{{ filterValuesByField['riskMetricsType'].length + ' selected' }}
										</span>
									</ng-template>
								</kendo-multiselect>
							</kendo-label>
							<kendo-label text="Market">
								<kendo-multiselect
									kendoMultiSelectSummaryTag
									[data]="filteredProjectTypeList"
									[(ngModel)]="filterValuesByField['projectType']"
									textField="text"
									valueField="value"
									[checkboxes]='true'
									[autoClose]='false'
									[filterable]='true'
									(filterChange)='handleProjectTypeFilter($event)'
									(valueChange)='filterChanged("projectType", $event)'
									[clearButton]='false'
									#projectTypeMultiselect
									(focus)="focus('projectTypeMultiselect')"
								>
									<ng-template kendoMultiSelectHeaderTemplate>
										<div>
											<input
												kendoCheckBox
												type='checkbox'
												[checked]="isSelectedAll('projectType', filteredProjectTypeList)"
												[indeterminate]="isIndet('projectType', filteredProjectTypeList)"
												(click)="onFilterSelectAllClick('projectType', filteredProjectTypeList)"
											/>
											<kendo-label>Select all</kendo-label>
										</div>
									</ng-template>
									<ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
										<div class="invisible-multiselect-tag-selector" (click)="toggleMultiselect('projectTypeMultiselect')"></div>
										<span id='projectTypeTagTemplate'>
											<kendo-svg-icon [icon]='icons.caretDown' (click)="toggleMultiselect('projectTypeMultiselect')"></kendo-svg-icon>
											{{ filterValuesByField['projectType'].length + ' selected' }}
										</span>
									</ng-template>
								</kendo-multiselect>
							</kendo-label>
							<kendo-label text="Point of Contact">
								<kendo-multiselect
									kendoMultiSelectSummaryTag
									[data]="filteredPocList"
									[(ngModel)]="filterValuesByField['pocId']"
									textField="text"
									valueField="value"
									[filterable]="true"
									[checkboxes]='true'
									[autoClose]='false'
									(filterChange)='handlePocFilter($event)'
									(valueChange)='filterChanged("pocId", $event)'
									[clearButton]='false'
									#pocIdMultiselect
									(focus)="focus('pocIdMultiselect')"
								>
									<ng-template kendoMultiSelectHeaderTemplate>
										<div>
											<input
												kendoCheckBox
												type='checkbox'
												[checked]="isSelectedAll('pocId', filteredPocList)"
												[indeterminate]="isIndet('pocId', filteredPocList)"
												(click)="onFilterSelectAllClick('pocId', filteredPocList)"
											/>
											<kendo-label for="chk">Select all</kendo-label>
										</div>
									</ng-template>
									<ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
										<div class="invisible-multiselect-tag-selector" (click)="toggleMultiselect('pocIdMultiselect')"></div>
										<span>
											<kendo-svg-icon [icon]='icons.caretDown' (click)="toggleMultiselect('pocIdMultiselect')"></kendo-svg-icon>
											{{ filterValuesByField['pocId'].length + ' selected' }}
										</span>
									</ng-template>
								</kendo-multiselect>
							</kendo-label>
							<kendo-label text="Current Status">
								<kendo-multiselect
									kendoMultiSelectSummaryTag
									[data]="completionStatus"
									[(ngModel)]="filterValuesByField['deltaVsCcd']"
									textField="text"
									valueField="value"
									[checkboxes]='true'
									[autoClose]='false'
									(ngModelChange)='filterChanged("deltaVsCcd", $event)'
									[clearButton]='false'
									#deltaVsCcdMultiselect
									(focus)="focus('deltaVsCcdMultiselect')"
								>
									<ng-template kendoMultiSelectHeaderTemplate>
										<div>
											<input
												kendoCheckBox
												type='checkbox'
												[checked]="isSelectedAll('deltaVsCcd', completionStatus)"
												[indeterminate]="isIndet('deltaVsCcd', completionStatus)"
												(click)="onFilterSelectAllClick('deltaVsCcd', completionStatus)"
											/>
											<kendo-label for="chk">Select all</kendo-label>
										</div>
									</ng-template>
									<ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
										<div class="invisible-multiselect-tag-selector" (click)="toggleMultiselect('deltaVsCcdMultiselect')"></div>
										<span id='completionStatusTagTemplate'>
											<kendo-svg-icon [icon]='icons.caretDown' (click)="toggleMultiselect('deltaVsCcdMultiselect')"></kendo-svg-icon>
											{{ filterValuesByField['deltaVsCcd'].length + ' selected' }}
										</span>
									</ng-template>
								</kendo-multiselect>
							</kendo-label>
							<kendo-label text="Past Performance">
								<kendo-multiselect
									kendoMultiSelectSummaryTag
									[data]="pastPeriodPerformanceList"
									[(ngModel)]="filterValuesByField['prevVariance']"
									textField="text"
									valueField="value"
									[checkboxes]='true'
									[autoClose]='false'
									(ngModelChange)='filterChanged("prevVariance", $event)'
									[clearButton]='false'
									#prevVarMultiselect
									(focus)="focus('prevVarianceMultiselect')"
								>
									<ng-template kendoMultiSelectHeaderTemplate>
										<div>
											<input
												kendoCheckBox
												type='checkbox'
												[checked]="isSelectedAll('prevVariance', pastPeriodPerformanceList)"
												[indeterminate]="isIndet('prevVariance', pastPeriodPerformanceList)"
												(click)="onFilterSelectAllClick('prevVariance', pastPeriodPerformanceList)"
											/>
											<kendo-label for="chk">Select all</kendo-label>
										</div>
									</ng-template>
									<ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
										<div class="invisible-multiselect-tag-selector" (click)="toggleMultiselect('prevVarianceMultiselect')"></div>
										<span id='prevVarianceTagTemplate'>
											<kendo-svg-icon [icon]='icons.caretDown' (click)="toggleMultiselect('prevVarianceMultiselect')"></kendo-svg-icon>
											{{ filterValuesByField['prevVariance'].length + ' selected' }}
										</span>
									</ng-template>
								</kendo-multiselect>
							</kendo-label>
						</div>
					</div>
					<div class="fields-container">
						<div class='slider-filter-container'>
							<kendo-label text="Baseline Δ :">
								<span *ngIf='sliderValues.completionVar[0] !== 0 || sliderValues.completionVar[1] !== 5' class='slider-value-label-container'>{{completionVarLabel}} <button kendoButton [svgIcon]='icons.closeCircle' fillMode='clear' (click)='resetCompletionVariance()' class='slider-reset-btn'></button></span>
								<kendo-rangeslider
									[(ngModel)]="sliderValues.completionVar"
									[min]="0"
									[max]="5"
									[largeStep]="1"
									[title]="completionVarianceTickTitle"
									[tickPlacement]="sliderSettings.tickPlacement"
									(valueChange)='updateCompletionVarianceSliderValue($event)'
									(afterValueChanged)='completionVarianceSliderValueChanged($event)'
									class='compl-var-slider'
								></kendo-rangeslider>
							</kendo-label>
						</div>
						<div class='slider-filter-container'>
							<kendo-label text="Project Score:">
								<span *ngIf='sliderValues.projectScore[0] !== 0 || sliderValues.projectScore[1] !== 100' class='slider-value-label-container'>{{sliderValues.projectScore[0]}} - {{sliderValues.projectScore[1]}} <button kendoButton [svgIcon]='icons.closeCircle' fillMode='clear' (click)='resetSliderValue("projectScore")' class='slider-reset-btn'></button></span>
								<kendo-rangeslider
									[(ngModel)]="sliderValues.projectScore"
									[min]="sliderSettings.min"
									[max]="sliderSettings.max"
									[smallStep]="sliderSettings.smallStep"
									[largeStep]="sliderSettings.largeStep"
									[tickPlacement]="sliderSettings.tickPlacement"
									(valueChange)='updateSliderValue($event, "projectScore")'
									(afterValueChanged)='sliderValueChanged($event, "projectScore")'
									class='score-slider'
								></kendo-rangeslider>
							</kendo-label>
						</div>
						<div class='slider-filter-container'>
							<kendo-label text="Progress Score:">
								<span *ngIf='sliderValues.progressScore[0] !== 0 || sliderValues.progressScore[1] !== 100' class='slider-value-label-container'>{{sliderValues.progressScore[0]}} - {{sliderValues.progressScore[1]}} <button kendoButton [svgIcon]='icons.closeCircle' fillMode='clear' (click)='resetSliderValue("progressScore")' class='slider-reset-btn'></button></span>
								<kendo-rangeslider
									[(ngModel)]="sliderValues.progressScore"
									[min]="sliderSettings.min"
									[max]="sliderSettings.max"
									[smallStep]="sliderSettings.smallStep"
									[largeStep]="sliderSettings.largeStep"
									[tickPlacement]="sliderSettings.tickPlacement"
									(valueChange)='updateSliderValue($event, "progressScore")'
									(afterValueChanged)='sliderValueChanged($event, "progressScore")'
									class='score-slider'
								></kendo-rangeslider>
							</kendo-label>
						</div>
						<div class='slider-filter-container'>
							<kendo-label text="QC Score:">
								<span *ngIf='sliderValues.qcScore[0] !== 0 || sliderValues.qcScore[1] !== 100' class='slider-value-label-container'>{{sliderValues.qcScore[0]}} - {{sliderValues.qcScore[1]}} <button kendoButton [svgIcon]='icons.closeCircle' fillMode='clear' (click)='resetSliderValue("qcScore")' class='slider-reset-btn'></button></span>
								<kendo-rangeslider
									[(ngModel)]="sliderValues.qcScore"
									[min]="sliderSettings.min"
									[max]="sliderSettings.max"
									[smallStep]="sliderSettings.smallStep"
									[largeStep]="sliderSettings.largeStep"
									[tickPlacement]="sliderSettings.tickPlacement"
									(valueChange)='updateSliderValue($event, "qcScore")'
									(afterValueChanged)='sliderValueChanged($event, "qcScore")'
									class='score-slider'
								></kendo-rangeslider>
							</kendo-label>
						</div>
						<div class='slider-filter-container'>
							<kendo-label text="Risk Score:">
								<span *ngIf='sliderValues.predictabilityScore[0] !== 0 || sliderValues.predictabilityScore[1] !== 100' class='slider-value-label-container'>{{sliderValues.predictabilityScore[0]}} - {{sliderValues.predictabilityScore[1]}} <button kendoButton [svgIcon]='icons.closeCircle' fillMode='clear' (click)='resetSliderValue("predictabilityScore")' class='slider-reset-btn'></button></span>
								<kendo-rangeslider
									[(ngModel)]="sliderValues.predictabilityScore"
									[min]="sliderSettings.min"
									[max]="sliderSettings.max"
									[smallStep]="sliderSettings.smallStep"
									[largeStep]="sliderSettings.largeStep"
									[tickPlacement]="sliderSettings.tickPlacement"
									(valueChange)='updateSliderValue($event, "predictabilityScore")'
									(afterValueChanged)='sliderValueChanged($event, "predictabilityScore")'
									class='score-slider'
								></kendo-rangeslider>
							</kendo-label>
						</div>
						<div class='update-status-container' style='padding-top: 0;'>
							<div class='update-status-title'>
								Update Status:
							</div>
							<div class='checkbox-container'>
								<input type="radio" size='large' #current kendoRadioButton [(ngModel)]="updateStatus" value='current' (ngModelChange)='checkboxChanged("updateStatus")' name='updateStatus'/>
								<kendo-label
									class="k-checkbox-label"
									[for]="current"
									text="Current"
								></kendo-label>
								<input type="radio" size='large' #missing kendoRadioButton name='updateStatus' style='margin-left: 10px;' [(ngModel)]="updateStatus" value='missing' (ngModelChange)='checkboxChanged("updateStatus")'/>
								<kendo-label
									class="k-checkbox-label"
									[for]="missing"
									text="Missing"
								></kendo-label>
								<input type="radio" size='large' #all kendoRadioButton name='updateStatus' style='margin-left: 10px;' [(ngModel)]="updateStatus" value='all' (ngModelChange)='checkboxChanged("updateStatus")'/>
								<kendo-label
									class="k-checkbox-label"
									[for]="all"
									text="All"
								></kendo-label>
							</div>
						</div>
						<div class='update-status-container' style='padding-top: 0;'>
							<div class='update-status-title'>
								Cost-Loaded:
							</div>
							<div class='checkbox-container'>
								<input type="radio" size='large' #yesCost kendoRadioButton [(ngModel)]="costLoaded" value='yes' (ngModelChange)='checkboxChanged("cost")' name='costLoaded'/>
								<kendo-label
									class="k-checkbox-label"
									[for]="yesCost"
									text="Yes"
								></kendo-label>
								<input type="radio" size='large' #noCost kendoRadioButton name='costLoaded' style='margin-left: 10px;' [(ngModel)]="costLoaded" value='no' (ngModelChange)='checkboxChanged("cost")'/>
								<kendo-label
									class="k-checkbox-label"
									[for]="noCost"
									text="No"
								></kendo-label>
								<input type="radio" size='large' #allCost kendoRadioButton name='costLoaded' style='margin-left: 10px;' [(ngModel)]="costLoaded" value='all' (ngModelChange)='checkboxChanged("cost")'/>
								<kendo-label
									class="k-checkbox-label"
									[for]="allCost"
									text="All"
								></kendo-label>
							</div>
						</div>
						<!--			<div class='btngroup-label' style='margin-right: -5px;'>-->
						<!--				Portfolio View:-->
						<!--			</div>-->
						<!--			<kendo-buttongroup width='100%' selection="single" class='btn-group' style='width: 150px !important;'>-->
						<!--				<button-->
						<!--					*ngFor="let button of viewButtons"-->
						<!--					kendoButton-->
						<!--					[toggleable]="true"-->
						<!--					[selected]="button.selected"-->
						<!--					(selectedChange)="selectionChange($event, button)"-->
						<!--					class='item-btn'-->
						<!--					[class.selected-item-btn]='button.selected'-->
						<!--					[disabled]='button.disabled || loading'-->
						<!--				>-->
						<!--			<span>-->
						<!--				{{ button.text }}-->
						<!--			</span>-->
						<!--				</button>-->
						<!--			</kendo-buttongroup>-->
					</div>
				</div>
			</fieldset>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='1' [col]='3' class='gridlayout-container'>
			<fieldset style="height: 100%">
				<legend>
					Visible Columns:
				</legend>
				<div style='height: 405px; overflow: auto; margin-top: -5px; margin-left: -15px;'>
					<kendo-treeview
						[nodes]="allGroups.groups"
						[children]="children"
						[hasChildren]="hasChildren"
						[isDisabled]='isDisabled'
						textField="title"
						[kendoTreeViewCheckable]="{checkParents: true}"
						[(checkedKeys)]="visibleProjectListColumns"
					>
					</kendo-treeview>
				</div>
			</fieldset>
		</kendo-gridlayout-item>
	</kendo-gridlayout>

	<div class="action-btn-wrapper">
		<button kendoButton [disabled]='saving' (click)="cancel()" themeColor="error">
			Cancel
		</button>
		<button kendoButton [disabled]='saving || name?.length === 0' (click)="save()" themeColor="success">
			Save
		</button>
	</div>
</div>
