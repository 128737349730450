import { Injectable } from '@angular/core';
import {
	TaskPredArrayInterface,
	UpdateInterface,
	Wbs,
	XerActivityCode,
	XerActivityType,
} from '../../models/Update/Task';
import { addSeconds, differenceInCalendarDays, getMonth, isAfter, isBefore, isSameDay, startOfDay } from 'date-fns';
import { isNearCriticalPlanned } from '../../util/tasks';
import { BehaviorSubject } from 'rxjs';
import { DependencyType, GanttDependency } from '@progress/kendo-angular-gantt';
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { hasObjChanged } from '../../util/projects';
import { GanttActvType } from '../../components/project-page/schedule-updates-list/current-update-gantt/gantt-group-by/gantt-group-by.component';
import { AnalyticsDashboardService } from '../analytics/analytics.service';
import {
	CurrentUpdateGanttPreset,
	CurrentUpdateGanttPresetInputs,
	GanttGroupingPreset,
	IButton,
} from '../../models/Project';
import { ProjectDashboardService } from './project.service';
import { RestService } from '../common/rest.service';
import {
	allColumns,
	CurrentUpdateGanttColumn,
} from '../../components/project-page/schedule-updates-list/current-update-gantt/current-update-gantt-chart/current-update-gantt-chart.component';
import { ScheduleStorageService } from './schedule-storage.service';
import { XerActivity, XerWbs } from '@rhinoworks/xer-parse';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NotificationService } from '../common/notification.service';
import { ExpandedMetrics } from '@rhinoworks/analytics-calculations';

type ActvTypeIndex = `ActvType_${string}`;

export interface CurrentUpdateGanttItem {
	id: number;
	shortNames: string[];
	taskCode: string;
	start: Date;
	startIsAct: boolean;
	end: Date;
	trueEnd: Date; //real end date, used for filtering. some end dates are shifted to avoid kendo marking them as milestones if the task ends on the same day as it starts - RS
	endIsAct: boolean;
	name: string;
	originalDuration: number;
	remainingDuration: number;
	durationCompletionPercentage: number;
	earlyStart: Date;
	lateStart: Date;
	earlyEnd: Date;
	lateEnd: Date;
	tf: number;
	completionCost: number;
	actCost: number;
	costPercentComplete: number;
	isCritical: boolean;
	completionRatio: number;
	isMilestoneAndIsDone: boolean;
	Status: string;
	[key: ActvTypeIndex]: string;
	ActvTypeJoined?: string;
	ActvTypes?: number[];
	taskType: string;
	criticality: string;
	childTasks: CurrentUpdateGanttItem[];
	level: number; //starting from 1
	wbs_id?: number;
	hideSummaryBar: boolean;
	seqNum: number;
}

export interface SummaryBarValues {
	start: Date;
	end: Date;
	trueEnd: Date;
	earlyStart: Date;
	lateStart: Date;
	earlyEnd: Date;
	lateEnd: Date;
	od: number;
	rd: number;
	allChildrenActuallyStarted: boolean;
	allChildrenAreComplete: boolean;
	totalTargetCost: number;
	totalActualCost: number;
}

export interface WbsLevel {
	text: string;
	value: number;
}

export interface ReportGroup {
	name: string;
	id: string;
	reportIds: string[];
}

export interface ReportBatchExportObj {
	b64session: string;
	id: string;
	reportIds: string[];
}

@Injectable({
	providedIn: 'root',
})
export class GanttService {
	updateName: string = '';
	reportName: string = '';
	update: UpdateInterface;
	allActivityTypes: Array<XerActivityType> = [];
	actvCodesByType: Map<number, Array<XerActivityCode>> = new Map<number, Array<XerActivityCode>>([]);
	activitiesByCode: Map<number, Array<XerActivity>> = new Map<number, Array<XerActivity>>([]);
	activitiesByWbs: Map<number, Array<XerActivity>> = new Map<number, Array<XerActivity>>([]);
	actvCodesByShortCode: Map<string, XerActivityCode> = new Map<string, XerActivityCode>([]);
	actvCodesById: Map<number, XerActivityCode> = new Map<number, XerActivityCode>([]);
	actvCodesByTask: Map<number, XerActivityCode[]> = new Map<number, XerActivityCode[]>([]);
	wbsById: Map<number, Wbs> = new Map<number, Wbs>([]);
	wbsTreeData: Wbs[] = [];
	currentUpdateDataDate: Date = null;
	_dataDate: Date = null;
	timestamp: Date = null;
	public allItems: CurrentUpdateGanttItem[] = [];
	public items: CurrentUpdateGanttItem[] = [];
	isDragging: boolean = false;
	public slotWidth: number = 0;
	zoomValue: number = 0;
	selectedGanttView: number = null;
	loading: boolean = true;
	minStart: Date = null;
	maxEnd: Date = null;
	$drawDataDateLine: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	allDependencies: GanttDependency[] = [];
	dependencies: GanttDependency[] = [];
	showLines: boolean = false;
	showOnlyCritical: boolean = true;
	applyDisabled: boolean = true;
	resetDisabled: boolean = true;
	tempFilters: CompositeFilterDescriptor = { logic: 'and', filters: [] };
	applyPhysicallyClicked = false;
	public filterValue: CompositeFilterDescriptor = { logic: 'and', filters: [] };
	allNodesExpanded: boolean = true;
	expandedKeys: number[] = [];
	fullyExpanded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	fullyCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	noItems: boolean = false;
	linkTypeDictionary = {
		PR_FS: DependencyType.FS,
		PR_SF: DependencyType.SF,
		PR_FF: DependencyType.FF,
		PR_SS: DependencyType.SS,
	};
	selectedGanttGroupNode = null;
	noSelection: boolean = true;
	selectedGroupBy: GanttActvType[] = [];
	$groupByXClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	selectedGroupByDelayed: GanttActvType[] = [];
	radioSelection: 'wbs' | 'actvCodes' = null;
	radioSelectionDelayed: 'wbs' | 'actvCodes' = null;
	idsUsed: number[] = [];
	groupByApplied: boolean = false;
	allWbsLevels: WbsLevel[] = [];
	selectedWbsLevel: WbsLevel = { text: 'Please Select', value: 0 };
	highestLevel: number = 0;
	puppetLevel: number[] = [];
	$selectedWbsLevelChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	hideSummaryBars: boolean = true;
	$hideSummaryBarsChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	ganttPresetDeleteConfirmOpen: boolean = false;
	ganttPresetQuickSaveOpen: boolean = false;
	$currentUpdateGanttPresetWindowOpen = new BehaviorSubject<CurrentUpdateGanttPresetInputs>(null);
	$manageWindowOpen = new BehaviorSubject<boolean>(false);
	$addGroupOpen = new BehaviorSubject<boolean>(false);
	selectedGanttLayout: string = null;
	_checkedColumns: any[] = ['0', '1', '2', '3', '4', '5', '6'];
	public hiddenColumns: string[] = [];
	selectedPresetColumns = null;
	$reportApply: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	$reset: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public relationshipsButtons: IButton[] = [
		{
			text: 'Hide',
			value: 0,
			selected: true,
			disabled: false,
		},
		{
			text: 'Show',
			value: 1,
			selected: false,
			disabled: false,
		},
	];
	public taskTypeButtons: IButton[] = [
		{
			text: 'Critical',
			value: 0,
			selected: true,
			disabled: false,
		},
		{
			text: 'Show All',
			value: 1,
			selected: false,
			disabled: false,
		},
	];
	$showLines = new BehaviorSubject<boolean>(null);
	$showOnlyCritical = new BehaviorSubject<boolean>(null);
	editingPresetVals: CurrentUpdateGanttPreset = null;
	appliedReportVals: CurrentUpdateGanttPreset = null;
	applyCanBeClicked: boolean = false;
	saveCanBeClicked: boolean = false;
	unsavedChangesReport: boolean = false;
	$resetAllClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	$resetOptions: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	public sort: SortDescriptor[] = [
		{
			field: 'end',
			dir: 'asc',
		},
	];
	userPresets: CurrentUpdateGanttPreset[] = [];
	favoritePresets: CurrentUpdateGanttPreset[] = [];
	$reportGroupsUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	reportGroups: ReportGroup[] = [];
	reportsByGroup: Map<string, Array<CurrentUpdateGanttPreset>> = new Map<string, Array<CurrentUpdateGanttPreset>>([]);
	reportById: Map<string, CurrentUpdateGanttPreset> = new Map<string, CurrentUpdateGanttPreset>([]);
	editingGroup: ReportGroup = null;
	newEditingGroup: ReportGroup = null;
	duplicateGroup: ReportGroup = null;
	deleteGroupConfirmOpen: boolean = false;
	deletingGroup: ReportGroup = null;
	originalPreset: CurrentUpdateGanttPresetInputs = null;
	presetWindowContentFilters: CompositeFilterDescriptor = { logic: 'and', filters: [] };
	presetWindowName: string = '';
	isEdit: boolean = false;
	beforeEditPresetGroupingType: 'wbs' | 'actvCodes' = null;
	beforeEditPresetSelectedWbsLevel: WbsLevel = { text: 'Please Select', value: 0 };
	beforeEditPresetSelectedGroupBy: GanttActvType[] = [];
	beforeEditPresetHideSummaryBars: boolean = true;
	beforeEditPresePuppetLevel: number[] = [];
	$applyReportPreset: BehaviorSubject<CurrentUpdateGanttPreset> = new BehaviorSubject<CurrentUpdateGanttPreset>(null);
	readyToSS: boolean = false; //needed for batch export ss. DO NOT REMOVE >:c -RS
	deletingPreset: CurrentUpdateGanttPreset = null;
	$ganttPresetsUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	selectedGroup: string[] = [];
	quickSaveName: string = '';
	quickSaveIsFavorite: boolean = false;
	$addToFolderDialogOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	_checkedReports: string[] = [];
	$checkedReportChange: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>(null);
	selectedFoldersToAddReportsTo: any[] = [];
	searchTerm: string = '';
	$applyPreset: BehaviorSubject<CurrentUpdateGanttPreset> = new BehaviorSubject<CurrentUpdateGanttPreset>(null);
	wbsColorStrings: string[] = [
		'black',
		'#575757',
		'#AD2323',
		'#2A4BD7',
		'#1D6914',
		'#814A19',
		'#8126C0',
		'#A0A0A0',
		'#81C57A',
		'#9DAFFF',
	];
	tempWbsColorStrings: string[] = [
		'black',
		'#575757',
		'#AD2323',
		'#2A4BD7',
		'#1D6914',
		'#814A19',
		'#8126C0',
		'#A0A0A0',
		'#81C57A',
		'#9DAFFF',
	];
	editPresetHappening: boolean = false;
	selectedWbsLevels: WbsLevel[] = [];
	appliedGroupingType: 'wbs' | 'actvCodes' | null = null;
	$structureWindowOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		public analyticsService: AnalyticsDashboardService,
		public projectService: ProjectDashboardService,
		public restService: RestService,
		public schedStorage: ScheduleStorageService,
		private http: HttpClient,
		private notificationService: NotificationService
	) {
		this.projectService.$currentProjectData.subscribe((projectData) => {
			if (projectData !== undefined) {
				this.reportGroups = projectData?.reportGroups || [];
				this.reportsByGroup.clear();
				this.reportById.clear();
				if (projectData?.ganttPresets !== undefined && projectData?.ganttPresets !== null) {
					projectData.ganttPresets.forEach((report: CurrentUpdateGanttPreset) => {
						this.reportById.set(report.id, report);
					});
				}
				if (projectData?.reportGroups !== undefined && projectData?.reportGroups !== null) {
					projectData?.reportGroups.forEach((group: ReportGroup) => {
						const reports: CurrentUpdateGanttPreset[] = [];
						group.reportIds.forEach((id: string) => {
							const report: CurrentUpdateGanttPreset = this.reportById.get(id);
							if (report !== null) {
								reports.push(report);
							}
						});
						this.reportsByGroup.set(group.id, reports);
					});
				}
				this.$reportGroupsUpdated.next(true);
			}
		});
	}

	addNewGanttPreset(newPreset: CurrentUpdateGanttPreset): void {
		const existingPresets: CurrentUpdateGanttPreset[] = this.projectService.$currentProjectData.value?.ganttPresets;
		const newGanttPreset: CurrentUpdateGanttPreset[] =
			existingPresets === undefined || existingPresets?.length === 0
				? [newPreset]
				: [...existingPresets, newPreset].sort((a: CurrentUpdateGanttPreset, b: CurrentUpdateGanttPreset) => {
						if (a?.name?.toLowerCase() < b?.name?.toLowerCase()) {
							return -1;
						}
						if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
							return 1;
						}
						return 0;
					});
		this.updateGanttPresets(newGanttPreset);
	}

	editGanttPreset(ganttPresets: CurrentUpdateGanttPreset[]): void {
		this.editPresetHappening = true;
		this.updateGanttPresets(ganttPresets);
	}

	deleteGanttPreset(preset: CurrentUpdateGanttPreset): void {
		const existingPresets: CurrentUpdateGanttPreset[] = this.projectService.$currentProjectData.value?.ganttPresets;
		const newPresets: CurrentUpdateGanttPreset[] = existingPresets.filter((p) => p.id !== preset.id);
		this.updateGanttPresets(newPresets);
		this.ganttPresetDeleteConfirmOpen = false;
	}

	toggleFavoritePreset(ev, preset: CurrentUpdateGanttPreset): void {
		const existingPresets: CurrentUpdateGanttPreset[] = this.projectService.$currentProjectData.value?.ganttPresets;
		const matchingPreset: CurrentUpdateGanttPreset = existingPresets.find(
			(p: CurrentUpdateGanttPreset) => p.id === preset.id
		);
		if (matchingPreset !== undefined) {
			matchingPreset.isFavorite = !matchingPreset.isFavorite;
			this.updateGanttPresets(existingPresets);
		}
		// necessary to avoid bubbling the click event to the presetClicked function -RS
		ev.cancelBubble = true;
		ev.stopPropagation();
	}

	updateGanttPresets(ganttPresets: CurrentUpdateGanttPreset[]): void {
		const reportGroups: ReportGroup[] = this.reportGroups;
		this.patchProject(ganttPresets, reportGroups);
	}

	updateReportGroups(reportGroups: ReportGroup[]): void {
		const ganttPresets: CurrentUpdateGanttPreset[] = this.userPresets;
		this.patchProject(ganttPresets, reportGroups);
	}

	patchProject(ganttPresets: CurrentUpdateGanttPreset[], reportGroups: ReportGroup[]): void {
		const projectId: string = this.projectService.$currentProjectData.value?._id;
		if (projectId !== undefined) {
			this.restService.patch(`project/${projectId}`, { ganttPresets, reportGroups }).subscribe((val) => {
				console.log('patch res', val);
				if (
					this.editPresetHappening &&
					this.selectedGanttLayout &&
					this.originalPreset?.preset?.id === this.selectedGanttLayout
				) {
					const matchingPresetNewVals: CurrentUpdateGanttPreset = val.project.ganttPresets.find(
						(p) => p.id === this.selectedGanttLayout
					);
					if (matchingPresetNewVals) {
						this.$applyPreset.next(matchingPresetNewVals);
					}
				}
				if (this.$manageWindowOpen.value) {
					this.updateReportData(val.project?.ganttPresets);
				}
				this.editPresetHappening = false;
			});
		}
	}

	updateReportData(ganttPresets: CurrentUpdateGanttPreset[]): void {
		this.userPresets =
			ganttPresets.sort((a: CurrentUpdateGanttPreset, b: CurrentUpdateGanttPreset) =>
				a?.name?.toLowerCase() < b?.name?.toLowerCase() ? -1 : b?.name?.toLowerCase() < a?.name?.toLowerCase() ? 1 : 0
			) || [];
		this.favoritePresets = this.userPresets.filter((p) => p.isFavorite);
		this.$ganttPresetsUpdated.next(true);
	}

	async updateGanttData(updates: UpdateInterface[]): Promise<void> {
		const updateIndex: number = updates?.length - 1;
		this.updateName = updateIndex === 0 ? 'Baseline' : 'Update ' + updateIndex;
		const latestUpdate: UpdateInterface = updates[updateIndex];
		this.update = latestUpdate;
		const actvsByTask: Record<
			number,
			Array<{
				task_id: number;
				actv_code_type_id: number;
				actv_code_id: number;
				proj_id: number;
			}>
		> = {};
		const currTaskActv = await this.schedStorage.grabUpdateTable<{
			task_id: number;
			actv_code_type_id: number;
			actv_code_id: number;
			proj_id: number;
		}>(latestUpdate._id, 'TASKACTV');
		for (const ta of currTaskActv) {
			if (!actvsByTask[ta.task_id]) {
				actvsByTask[ta.task_id] = [];
			}
			actvsByTask[ta.task_id].push(ta);
		}
		this.allActivityTypes = await this.schedStorage.grabUpdateTable<XerActivityType>(latestUpdate._id, 'ACTVTYPE');
		this.actvCodesByType.clear();
		this.activitiesByCode.clear();
		this.activitiesByWbs.clear();
		this.actvCodesByShortCode.clear();
		this.actvCodesById.clear();
		this.actvCodesByTask.clear();
		for (const type of this.allActivityTypes) {
			this.actvCodesByType.set(type.actv_code_type_id, []);
		}
		const actvCodes: XerActivityCode[] = await this.schedStorage.grabUpdateTable<XerActivityCode>(
			latestUpdate._id,
			'ACTVCODE'
		);
		for (const code of actvCodes) {
			const siblings: XerActivityCode[] = this.actvCodesByType.get(code.actv_code_type_id) || [];
			const codeName: string = code?.actv_code_name || '';
			const shortName: string = code?.short_name || '';
			const fullName: string =
				(codeName ? `${codeName}` : '') + (codeName && shortName ? ' - ' : '') + (shortName || '');
			if (siblings && fullName && !siblings.some((s) => s.actv_code_id === code.actv_code_id)) {
				siblings.push(code);
			}

			this.actvCodesByType.set(code.actv_code_type_id, siblings);
			this.actvCodesByShortCode.set(shortName, code);
			this.actvCodesById.set(code.actv_code_id, code);
		}
		const updateTasks = await this.schedStorage.grabUpdateTable<XerActivity>(latestUpdate._id, 'TASK');
		for (const task of updateTasks) {
			if (task.wbs_id) {
				const tasksForWbs: XerActivity[] = this.activitiesByWbs.get(task.wbs_id) || [];
				tasksForWbs.push(task);
				this.activitiesByWbs.set(task.wbs_id, tasksForWbs);
			}
			const actvCodesForTask: XerActivityCode[] = (actvsByTask[task.task_id] || []).map((ta) =>
				this.actvCodesById.get(ta.actv_code_id)
			);
			this.actvCodesByTask.set(task.task_id, actvCodesForTask);
		}
		this.wbsById.clear();
		this.wbsTreeData = [];
		const projWbs: XerWbs[] = await this.schedStorage.grabUpdateTable<XerWbs>(latestUpdate._id, 'PROJWBS');
		if (projWbs !== undefined && projWbs?.length > 0) {
			for (const wbs of projWbs) {
				this.wbsById.set(wbs.wbs_id, wbs);
			}
		}
		const projWbsWithExtras: Wbs[] = this.AddExtrasToXerWbs(projWbs);
		projWbsWithExtras.forEach((p) => {
			if (p.parent === null) {
				const parent: Wbs = this.wbsById.get(p.parent_wbs_id);
				if (parent !== undefined) {
					p.parent = parent;
					p.parent.level++;
				}
			}
		});
		this.wbsTreeData = this.flatListToTree(projWbsWithExtras);
		this.allWbsLevels = [];
		this.traverseTree(1, this.wbsTreeData);
		for (let i: number = 0; i < this.highestLevel; i++) {
			this.allWbsLevels.push(
				i === 0
					? {
							text: 'All',
							value: null,
						}
					: {
							text: 'Level ' + i,
							value: i,
						}
			);
		}
		const dataDateFromUpdate: Date = new Date(
			latestUpdate.dataDate ||
				this.projectService.$currentProjectReport.value?.projectCompletionTrend.projectCompletionTrendArray[
					this.projectService.$currentProjectReport.value?.projectCompletionTrend.projectCompletionTrendArray.length - 1
				]?.dataDate
		);
		const dataDate: Date = new Date(
			dataDateFromUpdate.getUTCFullYear(),
			dataDateFromUpdate.getUTCMonth(),
			dataDateFromUpdate.getUTCDate(),
			dataDateFromUpdate.getUTCHours()
		);
		this.currentUpdateDataDate = dataDate;
		this._dataDate = dataDate;
		this.timestamp = new Date();
		const allItems: CurrentUpdateGanttItem[] = [];

		updateTasks.forEach((activity: XerActivity) => {
			const item: CurrentUpdateGanttItem = this.getGanttItemFromActivity(activity, false, actvsByTask);
			allItems.push(item);
		});
		this.allItems = allItems;
		if (this.zoomValue === null || this.slotWidth === null) {
			this.selectedGanttView = null;
			this.autoFitToContainer();
		} else if (this.zoomValue !== this.slotWidth) {
			this.selectedGanttView = this.zoomValue;
			this.updateView(this.zoomValue, true);
		}
		await this.updateGanttVisibleItems();
		let i = 0;
		const hasDomLoaded = setInterval(() => {
			const x = document.querySelector('.k-gantt-timeline');
			if (x !== undefined || i > 500) {
				this.setupScrolling();
				clearInterval(hasDomLoaded);
			}
			i++;
		}, 200);
	}

	AddExtrasToXerWbs(list: XerWbs[]): Wbs[] {
		const wbsList: Wbs[] = [];
		list.forEach((item: XerWbs) => {
			const itemWithExtras: Wbs = item;
			itemWithExtras.level = 1;
			let immediateParent: Wbs = item.parent_wbs_id === null ? null : this.wbsById.get(item.parent_wbs_id);
			if (immediateParent !== null && immediateParent !== undefined) {
				immediateParent.parent = null;
				if (immediateParent.wbs_id === item.wbs_id) {
					immediateParent = null;
				} else {
					immediateParent.level = 1;
					itemWithExtras.level += 1;
				}
			}
			itemWithExtras.parent = immediateParent;
			itemWithExtras.children = [];

			wbsList.push(itemWithExtras);
		});
		return wbsList;
	}

	traverseTree(level: number, tree: Wbs[]): Wbs[] {
		const nextLevel: number = level + 1;
		tree.forEach((node: Wbs) => {
			node.level = level;
			if (level > this.highestLevel) {
				this.highestLevel = level;
			}
			this.traverseTree(nextLevel, node.children);
		});
		return tree;
	}

	flatListToTree(list: Wbs[]): Wbs[] {
		const map = {};
		let node: Wbs;
		const roots: Wbs[] = [];
		let i: number;

		for (i = 0; i < list.length; i += 1) {
			map[list[i].wbs_id] = i;
			list[i].children = [];
		}

		for (i = 0; i < list.length; i += 1) {
			node = list[i];
			if (node.parent !== undefined && node.parent !== null) {
				list[map[node.parent.wbs_id]].children.push(node);
			} else {
				roots.push(node);
			}
		}
		return roots;
	}

	/**
	 * takes current values for all filters/views and applies them to the latest dataset
	 */
	async updateGanttVisibleItems(groupByAfter: boolean = false, headlessBrowserNumberParam?: number): Promise<void> {
		this.items = filterBy(this.allItems, this.filterValue).filter((item: CurrentUpdateGanttItem) =>
			this.showOnlyCritical ? item.isCritical : true
		);

		this.noItems = this.items?.length === 0;
		this.dependencies = this.showLines
			? this.allDependencies.filter(
					(dep: GanttDependency) =>
						this.items.some((item: CurrentUpdateGanttItem) => item.id === dep.fromId) &&
						this.items.some((item: CurrentUpdateGanttItem) => item.id === dep.toId)
				)
			: [];
		if (this.items.length) {
			let minStart: Date = this.items[0].start;
			let maxEnd: Date = this.items[0].end;
			this.items.forEach((item) => {
				if (isBefore(item.start, minStart)) {
					minStart = item.start;
				}
				if (isAfter(item.end, maxEnd)) {
					maxEnd = item.end;
				}
			});
			this.minStart = minStart;
			this.maxEnd = maxEnd;
			this.loading = true;
			setTimeout(() => {
				this.loading = false;
				setTimeout(() => {
					this.scrollGanttToToday();
					if (this.selectedGanttView === null) {
						this.autoFitToContainer();
					}
					if (groupByAfter) {
						if (this.radioSelection === 'wbs') {
							this.applyWbsGrouping(headlessBrowserNumberParam);
						} else if (this.radioSelection === 'actvCodes') {
							this.applyGroupBy(headlessBrowserNumberParam);
						}
					} else {
						setTimeout(() => {
							if (headlessBrowserNumberParam !== undefined) {
								this.readyToSS = true;
							}
						}, 3000);
					}
				}, 500);
			}, 200);
		} else {
			this.loading = false;
		}
		this.updateExpandedKeys();
	}

	updateExpandedKeys(): void {
		this.expandedKeys = [];
		if (this.allNodesExpanded) {
			this.expandAllChildren(this.items);
		}
	}

	checkIfAllCollapsed(items): boolean {
		return !this.expandedKeys.includes(items?.[0]?.id);
	}

	checkIfAllExpanded(items): boolean {
		// If there are no items, consider it as all expanded.
		if (!items || items.length === 0) {
			return true;
		}

		for (const item of items) {
			if (item.childTasks && !this.expandedKeys.includes(item.id)) {
				return false;
			}

			if (item.childTasks && !this.checkIfAllExpanded(item.childTasks)) {
				return false;
			}
		}

		return true;
	}

	updateExpandedKeysOneLevel(action: 'expand' | 'collapse', index: number, highestIndex: number): void {
		this.expandedKeys = [];
		if (action === 'expand') {
			this.expandOneLevel(this.items, highestIndex - index);
		} else {
			this.collapseOneLevel(this.items, highestIndex - index);
		}
		this.fullyExpanded.next(this.checkIfAllExpanded(this.items));
		this.fullyCollapsed.next(this.checkIfAllCollapsed(this.items));
		setTimeout(() => {
			this.doRowColorUpdate();
		});
	}

	expandOneLevel(items: CurrentUpdateGanttItem[], index: number, level = 0): void {
		if (items) {
			items.forEach((item: CurrentUpdateGanttItem) => {
				if (level < index) {
					this.expandedKeys = [...this.expandedKeys, item.id];
					this.collapseOneLevel(item.childTasks, index, level + 1);
				}
			});
		}
	}

	collapseOneLevel(items: CurrentUpdateGanttItem[], index: number, level = 0): void {
		if (items) {
			items.forEach((item: CurrentUpdateGanttItem) => {
				if (level < index) {
					this.expandedKeys = [...this.expandedKeys, item.id];
					this.collapseOneLevel(item.childTasks, index, level + 1);
				}
			});
		}
	}

	expandAllChildren(items: CurrentUpdateGanttItem[]): void {
		if (items) {
			items.forEach((item: CurrentUpdateGanttItem) => {
				this.expandedKeys.push(item.id);
				this.expandAllChildren(item.childTasks);
			});
		}
	}

	toggleExpandRow(e, action: 'expand' | 'collapse') {
		if (action === 'expand') {
			this.expandedKeys.push(e.dataItem.id);
			this.fullyExpanded.next(this.checkIfAllExpanded(this.items));
		} else {
			this.expandedKeys = this.expandedKeys.filter((id) => id !== e.dataItem.id);
			this.fullyCollapsed.next(this.checkIfAllCollapsed(this.items));
		}
		setTimeout(() => {
			this.doRowColorUpdate();
		});
	}

	async generateDependencies(metrics: Pick<ExpandedMetrics, 'totalRelationships'>): Promise<void> {
		const relationships = metrics.totalRelationships;
		const allDependencies: GanttDependency[] = [];
		relationships.forEach((rel: TaskPredArrayInterface) => {
			const dependency: GanttDependency = {
				fromId: rel.pred_task_id,
				id: rel.task_pred_id,
				toId: rel.task_id,
				type: this.linkTypeDictionary[rel.pred_type],
			};
			allDependencies.push(dependency);
		});
		this.allDependencies = allDependencies;
		await this.updateGanttVisibleItems();
	}

	onFilterChange(filter: CompositeFilterDescriptor) {
		this.tempFilters = filter;
		this.applyDisabled = JSON.stringify(this.tempFilters) === JSON.stringify(this.filterValue);
	}

	applyFilterChanges(groupAfter: boolean = false): void {
		this.selectedGanttLayout = null;
		this.filterValue = structuredClone(this.tempFilters);
		this.checkResetBtn();
		this.updateGanttVisibleItems(groupAfter);
		this.applyDisabled = true;
	}

	checkResetBtn(): void {
		this.resetDisabled = !(
			hasObjChanged(this.filterValue, { logic: 'and', filters: [] }) ||
			this.radioSelection !== null ||
			this.relationshipsButtons[1].selected ||
			this.taskTypeButtons[1].selected ||
			hasObjChanged(this._checkedColumns, ['0', '1', '2', '3', '4', '5', '6']) ||
			this.selectedGanttLayout !== null
		);
	}

	resetFilters(fromReportWindowReset: boolean = false): void {
		this.selectedGanttLayout = null;
		this.resetGroups(fromReportWindowReset);
		this.filterValue = { logic: 'and', filters: [] };
		if (!fromReportWindowReset) {
			this.tempFilters = this.filterValue;
			this.updateGanttVisibleItems();
		}
	}

	resetGroups(fromReportWindowReset: boolean = false): void {
		this.noSelection = true;
		this.selectedGanttGroupNode = null;
		this.radioSelection = null;
		this.resetActvCodes();
		this.resetWbs();
		this.$reset.next(true);
		if (!fromReportWindowReset) {
			this.sort = [
				{
					field: 'end',
					dir: 'asc',
				},
			];
			this.puppetLevel = [];
			this.selectedGroupByDelayed = [];
			this.radioSelectionDelayed = null;
			this.groupByApplied = false;
			this.updateGanttVisibleItems();
		}
	}

	resetWbs(): void {
		this.selectedWbsLevel = { text: 'Please Select', value: 0 };
		this.$selectedWbsLevelChanged.next(false);
	}

	resetActvCodes(): void {
		this.selectedGroupBy = [];
	}

	resetAll(): void {
		this.resetGroups(true);
		this.resetFilters(true);
		this.resetDisabled = true;
		this.tempFilters = this.filterValue;
		this.puppetLevel = [];
		this.selectedGroupBy = [];
		this.selectedGroupByDelayed = [];
		this.radioSelectionDelayed = null;
		this.groupByApplied = false;
		this.$showLines.next(false);
		this.relationshipsButtons.forEach((btn: IButton) => {
			btn.selected = btn.text === 'Hide';
		});
		this.$showOnlyCritical.next(true);
		this.taskTypeButtons.forEach((btn: IButton) => {
			btn.selected = btn.text === 'Critical';
		});
		this.checkedColumns = ['0', '1', '2', '3', '4', '5', '6'];
		this.selectedGanttLayout = null;
		this.updateGanttVisibleItems();
		this.$resetAllClicked.next(true);
		this.$resetOptions.next(true);
		this.sort = [
			{
				field: 'end',
				dir: 'asc',
			},
		];
	}

	/**
	 * update gantt 'view'
	 * @param ev
	 * @param skipLoadToggle
	 */
	updateView(ev: number, skipLoadToggle = false): void {
		localStorage.setItem('ganttZoom', ev === null ? 'null' : ev.toString());
		if (ev === null) {
			this.autoFitToContainer();
		} else {
			this.zoomValue = ev;
			this.setZoom(skipLoadToggle);
		}
	}

	/**
	 * auto-fit gantt timeline pane width to container
	 */
	autoFitToContainer(skipLoadToggle = false): void {
		//timeout so the dom can finish updating to the expanded/collapsed width
		setTimeout(() => {
			const containerEl = document.getElementsByClassName('k-gantt-timeline')[0] as HTMLElement;
			const headerEl = containerEl?.children[0].children[0].children[0].children[0].children[1];
			const numberOfSubdivisions = headerEl?.children.length ?? 0;
			//-12 for vertical scroll bar
			const containerWidth = containerEl?.getBoundingClientRect().width - 12;
			if (numberOfSubdivisions) {
				this.zoomValue = containerWidth / numberOfSubdivisions;
			}
			this.setZoom(skipLoadToggle);
		}, 500);
	}

	/**
	 * updates slot width based on zoom value and re-renders gantt
	 */
	setZoom(skipLoadToggle = false): void {
		if (this.slotWidth !== this.zoomValue) {
			this.slotWidth = this.zoomValue;
			setTimeout(() => {
				if (!skipLoadToggle) {
					this.loading = true;
				}
				setTimeout(() => {
					if (!skipLoadToggle) {
						this.loading = false;
					}
					setTimeout(() => {
						this.scrollGanttToToday();
					}, 500);
				}, 500);
			}, 200);
		}
	}

	scrollGanttToToday(toggle: boolean = false): void {
		if (this.minStart !== null && this.maxEnd !== null) {
			const timelineScrollPane = document.getElementsByClassName('k-grid-content');
			for (let i = 0; i < timelineScrollPane.length; i++) {
				const currentPane = timelineScrollPane.item(i);
				if (currentPane.classList.length === 1) {
					const maxWidth = currentPane.scrollWidth;
					const today = new Date();
					if (isAfter(today, this.minStart) && isBefore(today, this.maxEnd)) {
						const d =
							differenceInCalendarDays(today, this.minStart) /
							(differenceInCalendarDays(this.maxEnd, this.minStart) || 1);
					}
				}
			}
		}

		setTimeout(() => {
			//show gantt vertical line borders on a quarterly basis and replace months with quarters
			if (this.slotWidth > 25 && this.slotWidth <= 35) {
				const columns = document.getElementsByClassName('k-gantt-columns');
				const row = columns.item(0)?.children[1]?.children[0];
				const startNumber = 13 - getMonth(this.minStart);
				if (startNumber < row?.children?.length - 1) {
					for (let i = startNumber; i < row.children.length; i += 3) {
						row.children[i].setAttribute('style', 'border-left: 1px solid rgba(0, 0, 0, 0.08)');
					}
				}
				const timeline = document.getElementsByClassName('k-gantt-timeline');
				const monthsRow = timeline.item(0)?.children[0]?.children[0]?.children[0]?.children[0]?.children[1];
				const offset = (getMonth(this.minStart) - 1) % 3;
				const shortMonths = ['Feb', 'May', 'Aug', 'Nov'];
				if (monthsRow.children.length > 0) {
					for (let i = 0; i < row?.children?.length; i++) {
						const val = (i + offset) % 3;
						switch (val) {
							case 0: {
								monthsRow.children[i].setAttribute('style', 'color: transparent');
								break;
							}
							case 1: {
								const oldTextContent = structuredClone(monthsRow.children[i].textContent);
								const quarter = shortMonths.findIndex((month: string) => month === oldTextContent) + 1;
								if (quarter !== 0) {
									monthsRow.children[i].textContent = 'Q' + quarter;
								}
								monthsRow.children[i].setAttribute('style', 'border-left: none;');
								break;
							}
							case 2: {
								monthsRow.children[i].setAttribute('style', 'color: transparent; border-left: none;');
								break;
							}
						}
					}
				}
			}
			//show gantt vertical line borders on a yearly basis once zoom is small enough
			if (this.slotWidth <= 25) {
				const columns = document.getElementsByClassName('k-gantt-columns');
				const row = columns.item(0)?.children[1]?.children[0];
				const startNumber = 13 - getMonth(this.minStart);
				if (startNumber < row?.children.length - 1) {
					for (let i = startNumber; i < row.children.length; i += 12) {
						row.children[i].setAttribute('style', 'border-left: 1px solid rgba(0, 0, 0, 0.08)');
					}
				}
			}
			this.$drawDataDateLine.next(true);
		});
	}

	updateWbsLevelSelection(ev): void {
		this.$selectedWbsLevelChanged.next(true);
	}

	giveUpdatedLevel(level: number, tree: CurrentUpdateGanttItem[]): CurrentUpdateGanttItem[] {
		const nextLevel: number = level + 1;
		if (tree !== null) {
			tree.forEach((node: CurrentUpdateGanttItem) => {
				node.level = level;
				this.giveUpdatedLevel(nextLevel, node.childTasks);
			});
		}
		return tree;
	}

	cancelReport(): void {
		if (this.saveCanBeClicked) {
			this.unsavedChangesReport = true;
		} else {
			this.$currentUpdateGanttPresetWindowOpen.next(null);
			if (this.isEdit) {
				this.radioSelection = structuredClone(this.beforeEditPresetGroupingType);
				this.selectedWbsLevel = structuredClone(this.beforeEditPresetSelectedWbsLevel);
				this.selectedGroupBy = structuredClone(this.beforeEditPresetSelectedGroupBy);
				this.hideSummaryBars = structuredClone(this.beforeEditPresetHideSummaryBars);
				this.puppetLevel = structuredClone(this.beforeEditPresePuppetLevel);
			}
		}
	}

	closeStructureWindow(): void {
		this.$structureWindowOpen.next(false);
	}

	closeReportManage(): void {
		this.$manageWindowOpen.next(false);
	}

	closeAddGroupDialog(): void {
		this.$addGroupOpen.next(false);
		this.duplicateGroup = null;
		this.editingGroup = null;
	}

	async applyWbsGrouping(headlessBrowserNumberParam?: number): Promise<void> {
		this.resetActvCodes();
		this.selectedGroupByDelayed = [];
		this.$resetOptions.next(true);
		const wbsItems: CurrentUpdateGanttItem[] = await this.generateWbsItems(this.wbsTreeData);
		Promise.all(wbsItems).then(() => {
			this.giveUpdatedLevel(1, wbsItems);
			this.items = wbsItems;
			this.allNodesExpanded = true;
			this.updateExpandedKeys();
			this.autoFitToContainer();
			this.groupByApplied = true;
			this.applyGroupBySort();
			this.updateRowClassColors();
			this.appliedGroupingType = 'wbs';
			setTimeout(() => {
				if (headlessBrowserNumberParam !== undefined) {
					this.readyToSS = true;
				}
			}, 3000);
		});
	}

	async generateWbsItems(tree: Wbs[]): Promise<CurrentUpdateGanttItem[]> {
		if (tree?.length === 0) {
			return [];
		}
		let items: CurrentUpdateGanttItem[] = [];
		const maxLevel: number =
			this.selectedWbsLevel?.value === 0 || this.selectedWbsLevel?.value === null
				? this.highestLevel
				: this.selectedWbsLevel?.value;
		const actvsByTask: Record<
			number,
			Array<{
				task_id: number;
				actv_code_type_id: number;
				actv_code_id: number;
				proj_id: number;
			}>
		> = {};
		const currTaskActv = await this.schedStorage.grabUpdateTable<{
			task_id: number;
			actv_code_type_id: number;
			actv_code_id: number;
			proj_id: number;
		}>(this.update._id, 'TASKACTV');
		for (const ta of currTaskActv) {
			if (!actvsByTask[ta.task_id]) {
				actvsByTask[ta.task_id] = [];
			}
			actvsByTask[ta.task_id].push(ta);
		}
		for (const node of tree) {
			const tasksForThisWbs = this.activitiesByWbs.get(node.wbs_id);
			let tasksForThisWbsAsGanttTask: CurrentUpdateGanttItem[] = [];
			const noCodeBars: CurrentUpdateGanttItem[] = [];
			if (tasksForThisWbs !== undefined) {
				tasksForThisWbs.forEach((task) => {
					const item: CurrentUpdateGanttItem = this.getGanttItemFromActivity(task, true, actvsByTask);
					tasksForThisWbsAsGanttTask.push(item);
				});
				tasksForThisWbsAsGanttTask = filterBy(tasksForThisWbsAsGanttTask, this.filterValue).filter(
					(item: CurrentUpdateGanttItem) => (this.showOnlyCritical ? item.isCritical : true)
				);
				// const summaryValues: SummaryBarValues =
				// 	tasksForThisWbsAsGanttTask?.length > 0 ? this.determineSummaryBarValues(tasksForThisWbsAsGanttTask) : null;
				// const noCodeSummaryBars: CurrentUpdateGanttItem[] = [];
				// if (summaryValues !== null) {
				// 	for (let i: number = node.level; i < maxLevel; i++) {
				// 		const nextLevel: number = i + 1;
				// 		const summaryBar: CurrentUpdateGanttItem = this.generateSummaryBar(
				// 			summaryValues,
				// 			'No Level ' + nextLevel,
				// 			tasksForThisWbsAsGanttTask,
				// 			nextLevel
				// 		);
				// 		noCodeSummaryBars.push(summaryBar);
				// 	}
				// 	let tempBar: CurrentUpdateGanttItem[] = tasksForThisWbsAsGanttTask;
				// 	for (let i: number = noCodeSummaryBars.length - 1; i >= 0; i--) {
				// 		const child: CurrentUpdateGanttItem[] = tempBar;
				// 		const parent: CurrentUpdateGanttItem = noCodeSummaryBars[i];
				// 		parent.childTasks = child;
				// 		tempBar = [parent];
				// 	}
				// 	noCodeBars = tempBar;
				// }
			}
			const subtasks: CurrentUpdateGanttItem[] =
				node?.children !== undefined && node?.children !== null && node.children?.length > 0
					? await this.generateWbsItems(node.children)
					: [];
			const combinedChildren: CurrentUpdateGanttItem[] =
				tasksForThisWbs === undefined ? subtasks : [...tasksForThisWbsAsGanttTask, ...subtasks];
			if (combinedChildren?.length) {
				const summaryValues: SummaryBarValues = this.determineSummaryBarValues(combinedChildren);
				const summaryBar: CurrentUpdateGanttItem = this.generateSummaryBar(
					summaryValues,
					node.wbs_name,
					combinedChildren,
					node.level,
					node.level === 1 ? 0 : node.seq_num
				);
				if (node.level <= maxLevel) {
					items.push(summaryBar);
				} else {
					combinedChildren.forEach((item: CurrentUpdateGanttItem) => {
						items.push(item);
					});
				}
				items = items.sort((a: CurrentUpdateGanttItem, b: CurrentUpdateGanttItem) => {
					if (a?.childTasks) {
						return a?.seqNum < b?.seqNum ? -1 : b?.seqNum < a?.seqNum ? 1 : 0;
					}
					return 0;
				});
			}
		}
		return items;
	}

	applyGroupBySort(sort?: SortDescriptor[]): void {
		const combinedSort: SortDescriptor[] = [];
		combinedSort.push({
			field: 'seqNum',
			dir: 'asc',
		});
		if (sort?.length > 0) {
			combinedSort.push(structuredClone(sort[sort.length - 1]));
		}
		this.sort = combinedSort;
		this.items = this.items.sort((a: CurrentUpdateGanttItem, b: CurrentUpdateGanttItem) => {
			if (a?.childTasks) {
				return a?.seqNum < b?.seqNum ? -1 : b?.seqNum < a?.seqNum ? 1 : 0;
			}
			if (combinedSort?.length > 1) {
				const temp: CurrentUpdateGanttItem[] = [a, b];
				const sortedTemp: CurrentUpdateGanttItem[] = orderBy(temp, combinedSort);
				return sortedTemp[0].id === a.id ? -1 : sortedTemp[0].id === b.id ? 1 : 0;
			}
			return 0;
		});
	}

	generateSummaryBar(
		summaryValues: SummaryBarValues,
		name: string,
		subtasks: CurrentUpdateGanttItem[],
		level: number,
		seqNum: string | number
	): CurrentUpdateGanttItem {
		return {
			id: this.generateUniqueId(),
			shortNames: [],
			taskCode: '',
			start: summaryValues.start,
			startIsAct: summaryValues.allChildrenActuallyStarted,
			end: summaryValues.end,
			trueEnd: summaryValues.trueEnd,
			endIsAct: summaryValues.allChildrenAreComplete,
			name,
			originalDuration: summaryValues.od,
			remainingDuration: summaryValues.rd,
			durationCompletionPercentage:
				summaryValues.rd > summaryValues.od
					? 0
					: summaryValues.od === 0 && summaryValues.rd === 0
						? summaryValues.allChildrenAreComplete
							? 100
							: null
						: 100 * (1 - summaryValues.rd / summaryValues.od),
			earlyStart: summaryValues.earlyStart,
			lateStart: summaryValues.lateStart,
			earlyEnd: summaryValues.earlyEnd,
			lateEnd: summaryValues.lateEnd,
			tf: null,
			completionCost: summaryValues.totalTargetCost === 0 ? null : summaryValues.totalTargetCost,
			actCost: summaryValues.totalActualCost === 0 ? null : summaryValues.totalActualCost,
			costPercentComplete:
				summaryValues.totalTargetCost === 0
					? null
					: (100 * summaryValues.totalActualCost) / summaryValues.totalTargetCost,
			isCritical: false,
			completionRatio: null,
			isMilestoneAndIsDone: false,
			Status: '',
			ActvTypeJoined: '',
			ActvTypes: null,
			taskType: '',
			criticality: '',
			childTasks: subtasks,
			level,
			hideSummaryBar: this.hideSummaryBars,
			seqNum: Number(seqNum),
		};
	}

	async applyGroupBy(headlessBrowserNumberParam?: number): Promise<void> {
		this.resetWbs();
		this.$resetOptions.next(true);
		this.items = filterBy(this.allItems, this.filterValue).filter((item: CurrentUpdateGanttItem) =>
			this.showOnlyCritical ? item.isCritical : true
		);
		if (this.selectedGroupBy?.length === 0) {
			this.resetGroups();
			return;
		}
		const groupedItems: CurrentUpdateGanttItem[] = [];
		const usedActivities: number[] = [];

		const actvsByTask: Record<
			number,
			Array<{
				task_id: number;
				actv_code_type_id: number;
				actv_code_id: number;
				proj_id: number;
			}>
		> = {};
		const currTaskActv = await this.schedStorage.grabUpdateTable<{
			task_id: number;
			actv_code_type_id: number;
			actv_code_id: number;
			proj_id: number;
		}>(this.update._id, 'TASKACTV');
		Promise.all(currTaskActv).then(() => {
			for (const ta of currTaskActv) {
				if (!actvsByTask[ta.task_id]) {
					actvsByTask[ta.task_id] = [];
				}
				actvsByTask[ta.task_id].push(ta);
			}
			const actvCodesForThisType: XerActivityCode[] = this.actvCodesByType.get(
				this.selectedGroupBy[0].actv_code_type_id
			);
			actvCodesForThisType.forEach((code: XerActivityCode) => {
				const allActivitiesForThisCode: XerActivity[] = this.activitiesByCode.get(code.actv_code_id);
				const filteredActivitiesForThisCode =
					allActivitiesForThisCode === undefined || allActivitiesForThisCode?.length === 0
						? undefined
						: allActivitiesForThisCode.filter((actv) =>
								this.items.some((item: CurrentUpdateGanttItem) => item.id === actv.task_id)
							);
				const ganttItemsForThisCode: CurrentUpdateGanttItem[] = [];
				if (filteredActivitiesForThisCode !== undefined) {
					filteredActivitiesForThisCode.forEach((activity) => {
						const ganttItem: CurrentUpdateGanttItem = this.getGanttItemFromActivity(activity, true, actvsByTask);
						ganttItemsForThisCode.push(ganttItem);
						usedActivities.push(activity.task_id);
					});
				}
				const subtasks: CurrentUpdateGanttItem[] =
					filteredActivitiesForThisCode === undefined
						? []
						: this.selectedGroupBy?.length === 1
							? ganttItemsForThisCode
							: this.generateSubtasks(1, ganttItemsForThisCode, false);
				const summaryValues: SummaryBarValues = this.determineSummaryBarValues(subtasks);
				if (subtasks?.length > 0) {
					const thisLevelGrouping: CurrentUpdateGanttItem = {
						id: this.generateUniqueId(),
						shortNames: [],
						taskCode: '',
						start: summaryValues.start,
						startIsAct: summaryValues.allChildrenActuallyStarted,
						end: summaryValues.end,
						trueEnd: summaryValues.trueEnd,
						endIsAct: summaryValues.allChildrenAreComplete,
						name: code?.actv_code_name,
						originalDuration: summaryValues.od,
						remainingDuration: summaryValues.rd,
						durationCompletionPercentage:
							summaryValues.rd > summaryValues.od
								? 0
								: summaryValues.od === 0 && summaryValues.rd === 0
									? summaryValues.allChildrenAreComplete
										? 100
										: null
									: 100 * (1 - summaryValues.rd / summaryValues.od),
						earlyStart: summaryValues.earlyStart,
						lateStart: summaryValues.lateStart,
						earlyEnd: summaryValues.earlyEnd,
						lateEnd: summaryValues.lateEnd,
						tf: null,
						completionCost: summaryValues.totalTargetCost === 0 ? null : summaryValues.totalTargetCost,
						actCost: summaryValues.totalActualCost === 0 ? null : summaryValues.totalActualCost,
						costPercentComplete:
							summaryValues.totalTargetCost === 0
								? null
								: (100 * summaryValues.totalActualCost) / summaryValues.totalTargetCost,
						isCritical: false,
						completionRatio: null,
						isMilestoneAndIsDone: false,
						Status: '',
						ActvTypeJoined: '',
						ActvTypes: null,
						taskType: '',
						criticality: '',
						childTasks: subtasks,
						level: 1,
						hideSummaryBar: this.hideSummaryBars,
						seqNum: code.seq_num,
					};
					groupedItems.push(thisLevelGrouping);
				}
			});
			const noCodeItems: CurrentUpdateGanttItem[] = this.items.filter(
				(i: CurrentUpdateGanttItem) => !usedActivities.includes(i.id) && i.childTasks === null
			);
			const summaryValues: SummaryBarValues = this.determineSummaryBarValues(noCodeItems);
			if (noCodeItems?.length > 0) {
				const noCodesFakeBars: CurrentUpdateGanttItem[] =
					this.selectedGroupBy.length === 1 ? noCodeItems : this.generateFakeSubtasks(1, summaryValues, noCodeItems);
				const id: number = this.generateUniqueId();
				groupedItems.push({
					id,
					shortNames: [],
					taskCode: '',
					start: summaryValues.start,
					startIsAct: summaryValues.allChildrenActuallyStarted,
					end: summaryValues.end,
					trueEnd: summaryValues.trueEnd,
					endIsAct: summaryValues.allChildrenAreComplete,
					name: 'No ' + this.selectedGroupBy[0].actv_code_type,
					originalDuration: summaryValues.od,
					remainingDuration: summaryValues.rd,
					durationCompletionPercentage:
						summaryValues.rd > summaryValues.od
							? 0
							: summaryValues.od === 0 && summaryValues.rd === 0
								? summaryValues.allChildrenAreComplete
									? 100
									: null
								: 100 * (1 - summaryValues.rd / summaryValues.od),
					earlyStart: summaryValues.earlyStart,
					lateStart: summaryValues.lateStart,
					earlyEnd: summaryValues.earlyEnd,
					lateEnd: summaryValues.lateEnd,
					tf: null,
					completionCost: summaryValues.totalTargetCost === 0 ? null : summaryValues.totalTargetCost,
					actCost: summaryValues.totalActualCost === 0 ? null : summaryValues.totalActualCost,
					costPercentComplete:
						summaryValues.totalTargetCost === 0
							? null
							: (100 * summaryValues.totalActualCost) / summaryValues.totalTargetCost,
					isCritical: false,
					completionRatio: null,
					isMilestoneAndIsDone: false,
					Status: '',
					ActvTypeJoined: '',
					ActvTypes: null,
					taskType: '',
					criticality: '',
					childTasks: noCodesFakeBars,
					level: 1,
					hideSummaryBar: this.hideSummaryBars,
					seqNum: -1,
				});
			}
			this.items = groupedItems;
			this.allNodesExpanded = true;
			this.updateExpandedKeys();
			this.autoFitToContainer();
			this.groupByApplied = true;
			this.selectedGroupByDelayed = structuredClone(this.selectedGroupBy);
			this.applyGroupBySort();
			this.updateRowClassColors();
			this.appliedGroupingType = 'actvCodes';
			setTimeout(() => {
				if (headlessBrowserNumberParam !== undefined) {
					this.readyToSS = true;
				}
			}, 3000);
		});
	}

	generateFakeSubtasks(
		level: number,
		summaryValues: SummaryBarValues,
		noCodeItems: CurrentUpdateGanttItem[]
	): CurrentUpdateGanttItem[] {
		const subtasks: CurrentUpdateGanttItem[] =
			level + 1 < this.selectedGroupBy?.length
				? this.generateFakeSubtasks(level + 1, summaryValues, noCodeItems)
				: noCodeItems;
		return [
			{
				id: this.generateUniqueId(),
				shortNames: [],
				taskCode: '',
				start: summaryValues.start,
				startIsAct: summaryValues.allChildrenActuallyStarted,
				end: summaryValues.end,
				trueEnd: summaryValues.trueEnd,
				endIsAct: summaryValues.allChildrenAreComplete,
				name: 'No ' + this.selectedGroupBy[level].actv_code_type,
				originalDuration: summaryValues.od,
				remainingDuration: summaryValues.rd,
				durationCompletionPercentage:
					summaryValues.rd > summaryValues.od
						? 0
						: summaryValues.od === 0 && summaryValues.rd === 0
							? summaryValues.allChildrenAreComplete
								? 100
								: null
							: 100 * (1 - summaryValues.rd / summaryValues.od),
				earlyStart: summaryValues.earlyStart,
				lateStart: summaryValues.lateStart,
				earlyEnd: summaryValues.earlyEnd,
				lateEnd: summaryValues.lateEnd,
				tf: null,
				completionCost: summaryValues.totalTargetCost === 0 ? null : summaryValues.totalTargetCost,
				actCost: summaryValues.totalActualCost === 0 ? null : summaryValues.totalActualCost,
				costPercentComplete:
					summaryValues.totalTargetCost === 0
						? null
						: (100 * summaryValues.totalActualCost) / summaryValues.totalTargetCost,
				isCritical: false,
				completionRatio: null,
				isMilestoneAndIsDone: false,
				Status: '',
				ActvTypeJoined: '',
				ActvTypes: null,
				taskType: '',
				criticality: '',
				childTasks: subtasks,
				level: level + 1,
				hideSummaryBar: this.hideSummaryBars,
				seqNum: -1,
			},
		];
	}

	generateUniqueId(): number {
		let potentialNewId: number = this.idsUsed?.length === 0 ? 1000000000 : this.idsUsed[this.idsUsed.length - 1] + 1;
		while (this.idsUsed.includes(potentialNewId)) {
			potentialNewId++;
		}
		this.idsUsed.push(potentialNewId);
		return potentialNewId;
	}

	/**
	 * recursive subtask generation
	 * @param level
	 * @param eligibleActivities
	 * @param isWBS
	 */
	generateSubtasks(
		level: number,
		eligibleActivities: CurrentUpdateGanttItem[],
		isWBS: boolean
	): CurrentUpdateGanttItem[] {
		const actvCodesForThisType: XerActivityCode[] = this.actvCodesByType.get(
			this.selectedGroupBy[level].actv_code_type_id
		);
		const ganttItemByCode: Map<number, Array<CurrentUpdateGanttItem>> = new Map<number, Array<CurrentUpdateGanttItem>>(
			[]
		);
		const noCodeItems: CurrentUpdateGanttItem[] = [];
		eligibleActivities.forEach((item: CurrentUpdateGanttItem) => {
			const activityCodes: number[] = item.ActvTypes;
			let itemFallsUnderEligibleCode: boolean = false;
			activityCodes.forEach((actvCode: number) => {
				if (actvCodesForThisType.findIndex((c) => c.actv_code_id === actvCode)) {
					const itemsForCode: CurrentUpdateGanttItem[] = ganttItemByCode.get(actvCode) || [];
					itemsForCode.push(item);
					ganttItemByCode.set(actvCode, itemsForCode);
					itemFallsUnderEligibleCode = true;
				}
			});
			if (!itemFallsUnderEligibleCode) {
				noCodeItems.push(item);
			}
		});
		const thisLevelGroupings: CurrentUpdateGanttItem[] = [];
		const nextLevel: number = level + 1;
		actvCodesForThisType.forEach((actvCode: XerActivityCode) => {
			const ganttItemsForCode: CurrentUpdateGanttItem[] = ganttItemByCode.get(actvCode.actv_code_id);
			const subtasks: CurrentUpdateGanttItem[] =
				ganttItemsForCode === undefined
					? []
					: nextLevel >= this.selectedGroupBy?.length
						? ganttItemsForCode
						: this.generateSubtasks(nextLevel, ganttItemsForCode, isWBS);
			const summaryValues: SummaryBarValues = this.determineSummaryBarValues(subtasks);
			if (!(subtasks?.length === 0 || summaryValues.start === null || summaryValues.end === null)) {
				const thisLevelGrouping: CurrentUpdateGanttItem = {
					id: this.generateUniqueId(),
					shortNames: [],
					taskCode: '',
					start: summaryValues.start,
					startIsAct: summaryValues.allChildrenActuallyStarted,
					end: summaryValues.end,
					trueEnd: summaryValues.trueEnd,
					endIsAct: summaryValues.allChildrenAreComplete,
					name: actvCode?.actv_code_name,
					originalDuration: summaryValues.od,
					remainingDuration: summaryValues.rd,
					durationCompletionPercentage:
						summaryValues.rd > summaryValues.od
							? 0
							: summaryValues.od === 0 && summaryValues.rd === 0
								? summaryValues.allChildrenAreComplete
									? 100
									: null
								: 100 * (1 - summaryValues.rd / summaryValues.od),
					earlyStart: summaryValues.earlyStart,
					lateStart: summaryValues.lateStart,
					earlyEnd: summaryValues.earlyEnd,
					lateEnd: summaryValues.lateEnd,
					tf: null,
					completionCost: summaryValues.totalTargetCost === 0 ? null : summaryValues.totalTargetCost,
					actCost: summaryValues.totalActualCost === 0 ? null : summaryValues.totalActualCost,
					costPercentComplete:
						summaryValues.totalTargetCost === 0
							? null
							: (100 * summaryValues.totalActualCost) / summaryValues.totalTargetCost,
					isCritical: false,
					completionRatio: null,
					isMilestoneAndIsDone: false,
					Status: '',
					ActvTypeJoined: '',
					ActvTypes: null,
					taskType: '',
					criticality: '',
					childTasks: subtasks,
					level: nextLevel,
					hideSummaryBar: this.hideSummaryBars,
					seqNum: actvCode.seq_num,
				};
				thisLevelGroupings.push(thisLevelGrouping);
			}
		});
		const summaryValues: SummaryBarValues = this.determineSummaryBarValues(noCodeItems);
		if (noCodeItems?.length > 0) {
			const noCodeFakeBars: CurrentUpdateGanttItem[] =
				nextLevel < this.selectedGroupBy?.length
					? this.generateFakeSubtasks(nextLevel, summaryValues, noCodeItems)
					: noCodeItems;
			const id: number = this.generateUniqueId();
			thisLevelGroupings.push({
				id,
				shortNames: [],
				taskCode: '',
				start: summaryValues.start,
				startIsAct: summaryValues.allChildrenActuallyStarted,
				end: summaryValues.end,
				trueEnd: summaryValues.trueEnd,
				endIsAct: summaryValues.allChildrenAreComplete,
				name: 'No ' + this.selectedGroupBy[level].actv_code_type,
				originalDuration: summaryValues.od,
				remainingDuration: summaryValues.rd,
				durationCompletionPercentage:
					summaryValues.rd > summaryValues.od
						? 0
						: summaryValues.od === 0 && summaryValues.rd === 0
							? summaryValues.allChildrenAreComplete
								? 100
								: null
							: 100 * (1 - summaryValues.rd / summaryValues.od),
				earlyStart: summaryValues.earlyStart,
				lateStart: summaryValues.lateStart,
				earlyEnd: summaryValues.earlyEnd,
				lateEnd: summaryValues.lateEnd,
				tf: null,
				completionCost: summaryValues.totalTargetCost === 0 ? null : summaryValues.totalTargetCost,
				actCost: summaryValues.totalActualCost === 0 ? null : summaryValues.totalActualCost,
				costPercentComplete:
					summaryValues.totalTargetCost === 0
						? null
						: (100 * summaryValues.totalActualCost) / summaryValues.totalTargetCost,
				isCritical: false,
				completionRatio: null,
				isMilestoneAndIsDone: false,
				Status: '',
				ActvTypeJoined: '',
				ActvTypes: null,
				taskType: '',
				criticality: '',
				childTasks: noCodeFakeBars,
				level: nextLevel,
				hideSummaryBar: this.hideSummaryBars,
				seqNum: -1,
			});
		}
		return thisLevelGroupings;
	}

	getGanttItemFromActivity(
		activity: XerActivity,
		isGroupBy: boolean = false,
		actvByTask: Record<
			number,
			Array<{
				task_id: number;
				actv_code_type_id: number;
				actv_code_id: number;
				proj_id: number;
			}>
		>
	): CurrentUpdateGanttItem {
		const actvCodeList: number[] = [];
		(actvByTask[activity.task_id] || []).forEach((taskActv) => {
			const code: XerActivityCode = this.actvCodesById.get(taskActv.actv_code_id);
			if (code) {
				const codeName: string = code?.actv_code_name || '';
				const shortName: string = code?.short_name || '';
				const fullName: string =
					(codeName ? `${codeName}` : '') + (codeName && shortName ? ' - ' : '') + (shortName || '');
				actvCodeList.push(code.actv_code_id);
				const codesByTypeString = activity['ActvType_' + code.actv_code_type_id] || '';
				activity['ActvType_' + code.actv_code_type_id] = codesByTypeString.concat(code.actv_code_id.toString());
				if (!isGroupBy) {
					const existingActivityList = this.activitiesByCode.get(code.actv_code_id) || [];
					existingActivityList.push(activity);
					this.activitiesByCode.set(code.actv_code_id, existingActivityList);
				}
			}
		});
		const od: number =
			activity?.target_drtn_hr_cnt !== null
				? activity?.target_drtn_hr_cnt === 0
					? 0
					: activity?.target_drtn_hr_cnt / 8
				: null;
		const rd: number =
			activity?.remain_drtn_hr_cnt !== null
				? activity?.remain_drtn_hr_cnt === 0
					? 0
					: activity?.remain_drtn_hr_cnt / 8
				: null;
		const start: Date = startOfDay(new Date(activity?.act_start_date || activity?.early_start_date));
		const end: Date = startOfDay(new Date(activity?.act_end_date || activity?.early_end_date));
		let completionRatio: number = 0;
		if (isAfter(this._dataDate, start)) {
			const durationUntilDataDate: number = differenceInCalendarDays(this._dataDate, start);
			const taskDuration: number = differenceInCalendarDays(end, start);
			completionRatio = durationUntilDataDate / (taskDuration || 1);
			completionRatio = completionRatio > 1 ? 1 : completionRatio;
		}
		const isMilestoneAndIsDone: boolean = isSameDay(start, end) && isBefore(start, this._dataDate);
		const taskType: string =
			activity?.task_type === 'TT_Task'
				? 'Task Dependent'
				: activity?.task_type === 'TT_Rsrc'
					? 'Resource Dependent'
					: activity?.task_type === 'TT_LOE'
						? 'Level of Effort'
						: activity?.task_type === 'TT_Mile'
							? 'Milestone'
							: activity?.task_type === 'TT_FinMile'
								? 'Finish Milestone'
								: activity?.task_type === 'TT_WBS'
									? 'WBS Summary'
									: '';
		const costs = this.projectService.$currentProjectReport.value?.taskCosts?.[activity?.task_code];
		const ganttItem: CurrentUpdateGanttItem = {
			id: activity?.task_id,
			shortNames: (this.actvCodesByTask.get(activity?.task_id) || []).map((code) => code.short_name),
			taskCode: activity?.task_code,
			start,
			startIsAct: activity?.act_start_date !== undefined && activity?.act_start_date?.toString() !== '',
			end:
				activity?.task_type !== 'TT_Mile' && activity?.task_type !== 'TT_FinMile' && isSameDay(start, end)
					? addSeconds(end, 1)
					: end,
			trueEnd: end,
			endIsAct: activity?.act_end_date !== undefined && activity?.act_end_date?.toString() !== '',
			name: activity?.task_name,
			originalDuration: od,
			remainingDuration: rd,
			durationCompletionPercentage:
				rd > od
					? 0
					: od === null || rd === null || (od === 0 && rd === 0 && activity?.act_end_date === undefined)
						? null
						: 100 * (1 - rd / (od || 1)),
			earlyStart: activity?.early_start_date ? startOfDay(new Date(activity?.early_start_date)) : null,
			lateStart: activity?.late_start_date ? startOfDay(new Date(activity?.late_start_date)) : null,
			earlyEnd: activity?.early_end_date ? startOfDay(new Date(activity?.early_end_date)) : null,
			lateEnd: activity?.late_end_date ? startOfDay(new Date(activity?.late_end_date)) : null,
			tf: activity?.total_float_hr_cnt / 8 || null,
			completionCost: costs?.[0] || null,
			actCost: costs?.[1] || null,
			costPercentComplete:
				costs?.[0] !== 0 && costs?.[0] !== null && costs?.[1] !== undefined
					? (100 * costs?.[1]) / (costs?.[0] || 1)
					: null,
			isCritical: activity?.driving_path_flag === 'Y' && !activity.act_end_date,
			completionRatio,
			isMilestoneAndIsDone,
			Status:
				activity?.act_end_date !== undefined
					? 'Complete'
					: activity?.act_start_date !== undefined
						? 'Incomplete'
						: 'Not Started',
			ActvTypeJoined: actvCodeList.join(','),
			ActvTypes: actvCodeList,
			taskType,
			criticality:
				activity?.driving_path_flag === 'Y' && !activity.act_end_date
					? 'Critical'
					: isNearCriticalPlanned(activity, this._dataDate)
						? 'Near Critical'
						: 'Non Critical',
			childTasks: null,
			level: null,
			wbs_id: activity.wbs_id,
			hideSummaryBar: this.hideSummaryBars,
			seqNum: -999,
		};
		for (const key in activity) {
			if (key.substring(0, 9) === 'ActvType_') {
				ganttItem[key] = activity[key];
			}
		}
		return ganttItem;
	}

	checkedStateChange(ev: boolean): void {
		this.hideSummaryBars = ev;
		this.$hideSummaryBarsChanged.next(true);
	}

	determineSummaryBarValues(items: CurrentUpdateGanttItem[]): SummaryBarValues {
		let start: Date = null;
		let end: Date = null;
		let trueEnd: Date = null;
		let earlyStart: Date = null;
		let lateStart: Date = null;
		let earlyEnd: Date = null;
		let lateEnd: Date = null;
		let totalOD: number = 0;
		let totalRD: number = 0;
		let allChildrenActuallyStarted: boolean = true;
		let allChildrenAreComplete: boolean = true;
		let totalTargetCost: number = 0;
		let totalActualCost: number = 0;
		items.forEach((item: CurrentUpdateGanttItem) => {
			if (start === null || isBefore(item.start, start)) {
				start = item.start;
			}
			if (end === null || isAfter(item.end, end)) {
				end = item.end;
				trueEnd = item.trueEnd;
			}
			if (earlyStart === null || isBefore(item.earlyStart, earlyStart)) {
				earlyStart = item.earlyStart;
			}
			if (lateStart === null || isBefore(item.lateStart, lateStart)) {
				lateStart = item.lateStart;
			}
			if (earlyEnd === null || isBefore(item.earlyEnd, earlyEnd)) {
				earlyEnd = item.earlyEnd;
			}
			if (lateEnd === null || isBefore(item.lateEnd, lateEnd)) {
				lateEnd = item.lateEnd;
			}
			totalOD += item.originalDuration;
			totalRD += item.remainingDuration;
			if (!item.startIsAct) {
				allChildrenActuallyStarted = false;
			}
			if (!item.endIsAct) {
				allChildrenAreComplete = false;
			}
			totalTargetCost += item.completionCost;
			totalActualCost += item.actCost;
		});
		return {
			start,
			end,
			trueEnd,
			earlyStart,
			lateStart,
			earlyEnd,
			lateEnd,
			od: totalOD,
			rd: totalRD,
			allChildrenActuallyStarted,
			allChildrenAreComplete,
			totalTargetCost,
			totalActualCost,
		};
	}

	/**
	 * panning to scroll gantt pane setup
	 */
	setupScrolling() {
		let mouseDown: boolean = false;
		let startX: number;
		let scrollLeft: number;
		let startY: number;
		let scrollTop: number;
		const slider: any = document.querySelector('.k-gantt-timeline')?.children[1];

		if (slider !== null && slider !== undefined) {
			const startDragging = (e: any) => {
				this.isDragging = true;
				mouseDown = true;
				startX = e.pageX - slider.offsetLeft;
				startY = e.pageY - slider.offsetTop;
				scrollLeft = slider.scrollLeft;
				scrollTop = slider.scrollTop;
			};

			const stopDragging = (e: any) => {
				mouseDown = false;
				this.isDragging = false;
			};

			const move = (e: any) => {
				e.preventDefault();
				if (!mouseDown) {
					return;
				}
				const x = e.pageX - slider.offsetLeft;
				const y = e.pageY - slider.offsetTop;
				const scroll = x - startX;
				const top = y - startY;
				slider.scrollLeft = scrollLeft - scroll;
				slider.scrollTop = scrollTop - top;
			};

			// Add the event listeners
			slider.addEventListener('mousemove', move, false);
			slider.addEventListener('mousedown', startDragging, false);
			slider.addEventListener('mouseup', stopDragging, false);
			slider.addEventListener('mouseleave', stopDragging, false);
		}
	}

	editPreset(ev: Event, preset: CurrentUpdateGanttPreset, isDuplicate: boolean = false): void {
		this.beforeEditPresetSelectedGroupBy = structuredClone(this.selectedGroupBy);
		this.beforeEditPresetGroupingType = structuredClone(this.radioSelection);
		this.beforeEditPresetSelectedWbsLevel = structuredClone(this.selectedWbsLevel);
		this.beforeEditPresetHideSummaryBars = structuredClone(this.hideSummaryBars);
		this.beforeEditPresePuppetLevel = structuredClone(this.puppetLevel);
		const filters = JSON.parse(preset.filters);
		// After doing JSON.parse the date filters become strings which mess up the filterBy call, so converting to dates
		for (const filter of filters.filters) {
			if ('filters' in filter) {
				//a CompositeFilterDescriptor
			} else if (filter.field === 'start' || filter.field === 'trueEnd') {
				filter.value = new Date(filter.value);
			}
		}
		this.resetGroupingColors(true);

		this.$currentUpdateGanttPresetWindowOpen.next({
			preset,
			currentFilters: filters,
			groups: this.allActivityTypes,
			activityMap: this.actvCodesByType,
			isNew: isDuplicate,
			isBasedOnOtherPreset: isDuplicate,
		});
		// necessary to avoid bubbling the click event to the presetClicked function -RS
		ev.cancelBubble = true;
		ev.stopPropagation();
	}

	existsInGrouping(groups: XerActivityType[], item) {
		return groups.some((group) => {
			return group.actv_code_type_id === item.actv_code_type_id;
		});
	}

	getGrouping(): GanttGroupingPreset {
		return {
			type: this.radioSelection,
			wbsToLevel: this.radioSelection === 'wbs' ? this.selectedWbsLevel?.value : null,
			actvCodeGrouping: this.radioSelection === 'wbs' ? [] : this.selectedGroupBy,
			hideSummaryBars: this.hideSummaryBars,
			colors: JSON.stringify(this.tempWbsColorStrings),
		};
	}

	set checkedColumns(columns: string[]) {
		columns = columns.sort((a: string, b: string) => {
			const valA: number = Number(a);
			const valB: number = Number(b);
			return valA < valB ? -1 : valB < valA ? 1 : 0;
		});
		this._checkedColumns = columns;
		const hiddenColumnFields: string[] = [];
		allColumns.columns.forEach((column: CurrentUpdateGanttColumn) => {
			if (!columns.includes(column.sequence.toString())) {
				hiddenColumnFields.push(column.field);
			}
		});
		this.hiddenColumns = hiddenColumnFields;
		if (!this.loading) {
			if (this.selectedPresetColumns !== null && columns !== this.selectedPresetColumns) {
				this.selectedPresetColumns = null;
				this.selectedGanttLayout = null;
			}
			this.updateGanttVisibleItems();
		}
	}

	get checkedColumns() {
		return this._checkedColumns;
	}

	/**
	 * buttongroup selection change handler
	 * @param selected
	 * @param btn
	 * @param isRelationshipsBtnGroup
	 */
	public selectionChange(selected: boolean, btn: IButton, isRelationshipsBtnGroup: boolean = false): void {
		btn.selected = selected;
		if (isRelationshipsBtnGroup && btn.text === 'Show') {
			this.$showLines.next(btn.selected);
		} else if (!isRelationshipsBtnGroup && btn.text === 'Critical') {
			this.$showOnlyCritical.next(btn.selected);
		}
		this.selectedGanttLayout = null;
		this.checkResetBtn();
	}

	editWindowGroupSave(): void {
		const newReportGroups: ReportGroup[] = structuredClone(this.reportGroups);
		const matchingIndex: number = this.reportGroups.findIndex(
			(group: ReportGroup) => group.id === this.newEditingGroup.id
		);
		if (matchingIndex === -1) {
			newReportGroups.push(this.newEditingGroup);
		} else {
			newReportGroups[matchingIndex] = structuredClone(this.newEditingGroup);
		}
		this.updateReportGroups(newReportGroups);
		this.closeAddGroupDialog();
	}

	deleteGroup(id: string): void {
		const newReportGroups: ReportGroup[] = structuredClone(this.reportGroups).filter(
			(group: ReportGroup) => group.id !== id
		);
		this.updateReportGroups(newReportGroups);
		this.deleteGroupConfirmOpen = false;
		this.deletingGroup = null;
	}

	/**
	 * runs batchExport on reports and email the user the pdfs
	 * @param reportIds
	 */
	runBatchReportExport(reportIds: string[]): void {
		const projectId: string = this.projectService.$currentProjectPageId.value;
		if (projectId !== null && projectId !== undefined && projectId !== '') {
			const body: ReportBatchExportObj = {
				b64session: btoa(
					JSON.stringify({
						currentUser: localStorage.getItem('currentUser'),
						currentUserEmail: localStorage.getItem('currentUserEmail'),
						currentUserCredentials: localStorage.getItem('currentUserCredentials'),
					})
				),
				id: projectId,
				reportIds,
			};
			// http://localhost:4999/render/
			this.http
				.post('https://async-export.services.analytics.rhinoworks.dev/render/', body, {
					headers: new HttpHeaders().append('Content-Type', 'application/json'),
				})
				.subscribe({
					next: (r) => {
						console.log('batch export response', r);
						this.notificationService.showNotification(
							'Batch Export can take a few minutes to process. You will receive an email with the pdfs once it is complete.'
						);
						this.closeReportManage();
					},
					error: (er) => {
						console.log(er);
					},
				});
		}
	}

	openAddNewReportPopup(): void {
		const stringifiedFilters: string = JSON.stringify(this.filterValue);
		this.selectedWbsLevel = { text: 'Please Select', value: 0 };
		this.resetGroupingColors(true);
		const newPreset: CurrentUpdateGanttPreset = {
			filters: stringifiedFilters,
			visibleColumns: this.checkedColumns,
			name: '',
			isFavorite: false,
			id: crypto.randomUUID(),
			grouping: this.getGrouping(),
			showingRelationships: this.showLines,
			showingCritical: this.showOnlyCritical,
		};
		this.$currentUpdateGanttPresetWindowOpen.next({
			preset: newPreset,
			currentFilters: this.filterValue,
			groups: this.allActivityTypes,
			activityMap: this.actvCodesByType,
			isNew: true,
			isBasedOnOtherPreset: false,
		});
	}

	toggleQuickSaveDialog(val: boolean): void {
		this.ganttPresetQuickSaveOpen = val;
		this.quickSaveIsFavorite = false;
		this.quickSaveName = '';
	}

	get checkedReports() {
		return this._checkedReports;
	}

	set checkedReports(reports: string[]) {
		this._checkedReports = reports;
		this.$checkedReportChange.next(this._checkedReports);
	}

	closeAddToFolderDialog(): void {
		this.$addToFolderDialogOpen.next(false);
		this.selectedFoldersToAddReportsTo = [];
	}

	addToFolders(): void {
		const reportsToAdd: string[] = structuredClone(this.checkedReports);
		const newReportFolders: ReportGroup[] = [];
		this.reportGroups.forEach((folder: ReportGroup) => {
			if (this.selectedFoldersToAddReportsTo.findIndex((f) => f.id === folder.id) !== -1) {
				reportsToAdd.forEach((report: string) => {
					if (!folder.reportIds.includes(report)) {
						folder.reportIds.push(report);
					}
				});
				newReportFolders.push(folder);
			} else {
				newReportFolders.push(folder);
			}
		});
		this.updateReportGroups(newReportFolders);
		this.closeAddToFolderDialog();
	}

	updateRowClassColors(justColorChange: boolean = false): void {
		if (justColorChange) {
			setTimeout(() => {
				this.doRowColorUpdate();
			});
		} else {
			setTimeout(() => {
				let i: number = 0;
				const waitingForDOM = setInterval(() => {
					const topLevel = document.getElementsByClassName('ganttTopLevel');
					if (topLevel?.length || i > 500) {
						clearInterval(waitingForDOM);
						setTimeout(() => {
							this.doRowColorUpdate();
						});
					}
					i++;
				}, 200);
			}, 2000);
		}
	}

	doRowColorUpdate(): void {
		//removes existing rowclass to avoid colors sticking around when collapsing/expanding
		const matchingChildrenRows: HTMLCollectionOf<Element> = document.getElementsByClassName('ganttChild');
		for (let i = 0; i < matchingChildrenRows.length; i++) {
			matchingChildrenRows.item(i).setAttribute('style', 'background-color: inherit');
		}

		setTimeout(() => {
			this.wbsColorStrings.forEach((color: string, index: number) => {
				const nextNumber: string = (index + 1).toString();
				const className: string = index === 0 ? 'ganttTopLevel' : 'ganttLevel' + nextNumber;
				const matchingRows: HTMLCollectionOf<Element> = document.getElementsByClassName(className);
				for (let i = 0; i < matchingRows.length; i++) {
					matchingRows.item(i).setAttribute('style', 'background-color: ' + color + ' !important');
				}
			});
		});
	}

	resetGroupingColors(onlyTempReset: boolean = false): void {
		if (!onlyTempReset) {
			this.wbsColorStrings = [
				'black',
				'#575757',
				'#AD2323',
				'#2A4BD7',
				'#1D6914',
				'#814A19',
				'#8126C0',
				'#A0A0A0',
				'#81C57A',
				'#9DAFFF',
			];
		}
		this.tempWbsColorStrings = [
			'black',
			'#575757',
			'#AD2323',
			'#2A4BD7',
			'#1D6914',
			'#814A19',
			'#8126C0',
			'#A0A0A0',
			'#81C57A',
			'#9DAFFF',
		];
	}

	softResetGroupValues(): void {
		this.radioSelection = null;
		this.selectedWbsLevel = { text: 'Please Select', value: 0 };
		this.selectedGroupBy = [];
		this.wbsColorStrings = [
			'black',
			'#575757',
			'#AD2323',
			'#2A4BD7',
			'#1D6914',
			'#814A19',
			'#8126C0',
			'#A0A0A0',
			'#81C57A',
			'#9DAFFF',
		];
		this.$groupByXClicked.next(false);
	}

	updateApply(): void {
		if (this.radioSelection === 'wbs' || this.radioSelection === 'actvCodes') {
			this.$reportApply.next(true);
		} else {
			this.appliedGroupingType = null;
			this.resetGroups();
			this.sort = [
				{
					field: 'end',
					dir: 'asc',
				},
			];
		}
	}
}
