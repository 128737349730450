<div class='rating-container' (click)='selectTest.emit(rating.key)' [class.selected-rating]='isSelected'>

  <div *ngIf='isSelected && !rating.elements?.length && rating.passes' class='rating-elements looks-good'>
    <h1>{{(rating.value !== true && rating.value ? ('Score: ' + (rating.value | number: '1.0-0')) : '') }}</h1>
    <h2 class='green-icon'><b>Looks good!</b></h2>
    <p>No adjustments are necessary</p>
  </div>
  <div class='info-container'>
    <div class='dcma-assessment-desc'>{{rating.title}}</div>
    <div class='desc'>{{rating.description}}
    </div>
    <div *ngIf='isSelected && rating.elements?.length' class='rating-elements'>
      <h2><b>The following {{rating.elements.length}} {{elementType ? elementType + 's' : ''}} need to be adjusted:</b></h2>
      <ul style='max-height: 300px;overflow-y:scroll'>
        <li *ngFor='let element of rating.elements'>
          {{rating.denominatorKey?.includes('Relation') ? element.toString().split(',').join(' -> ') : element}}
        </li>
      </ul>
    </div>
  </div>

  <div class='pass-icon'>
    <mat-icon class='icon green-icon' *ngIf='rating.passes'>check</mat-icon>
    <mat-icon class='icon red-icon' *ngIf='!rating.passes'>priority_high</mat-icon>
  </div>

</div>
