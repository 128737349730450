import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { ForgotPassword2Component } from 'components/authentication/forgot-password-2/forgot-password-2.component';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
	declarations: [ForgotPassword2Component],
	imports: [CommonModule, MatIconModule, RouterModule, MatInputModule, ReactiveFormsModule],
})
export class ForgotPassword2Module {}
