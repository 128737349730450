import { Component, Input, OnInit } from '@angular/core';
import { ExpandedMetrics } from '@rhinoworks/analytics-calculations';

@Component({
	selector: 'app-dcma-assessment-icon',
	templateUrl: './dcma-icon.svg',
})
export class DcmaAssessmentIconComponent implements OnInit {
	@Input() expandedMetrics: ExpandedMetrics;

	constructor() {}

	ngOnInit(): void {}
}
