<div id="overviewTab">
	@if (isDefault) {
		<kendo-gridlayout
			[rows]="['auto']"
			[cols]="['100%']"
			[gap]="{ rows: 5, cols: 0 }"
			class='grid'
			style='padding-bottom: 5px;'
		>
			<kendo-gridlayout-item [row]='1' [col]='1'>
				<app-project-overview-summary></app-project-overview-summary>
			</kendo-gridlayout-item>
		</kendo-gridlayout>
		<div style="display: flex; flex-direction: row; justify-content: center; gap: 5px; width: 100%;">
			<kendo-gridlayout
				[rows]="['auto']"
				[cols]="['340px']"
				[gap]="{ rows: 0, cols: 0 }"
				class='grid'
				style='padding-bottom: 5px;'
			>
				<kendo-gridlayout-item [row]='1' [col]='1'>
					<app-progress-trending [hideTrending]="true" [isOverview]="true"></app-progress-trending>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
			<kendo-gridlayout
				[rows]="['auto']"
				[cols]="['calc(100vw - 365px)']"
				[gap]="{ rows: 0, cols: 0 }"
				class='grid'
				style='padding-bottom: 5px;'
			>
				<kendo-gridlayout-item [row]='1' [col]='1'>
					<app-project-completion-trending [visualizer]='visualizer' [isOverview]="true"></app-project-completion-trending>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</div>
		@if ((projectService.$currentProjectReport | async)?.criticalLookahead?.criticalActivityArray?.length > 0) {
			<kendo-gridlayout
				[rows]="['auto']"
				[cols]="['100%']"
				[gap]="{ rows: 5, cols: 0 }"
				class='grid'
				style='padding-bottom: 5px;'
			>
				<kendo-gridlayout-item [row]='1' [col]='1'>
					<app-schedule-lookahead [isOverview]="true" [small]="true"></app-schedule-lookahead>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		}
		<kendo-gridlayout
			[rows]="['auto']"
			[cols]="['100%']"
			[gap]="{ rows: 5, cols: 0 }"
			class='grid'
			style='padding-bottom: 5px;'
		>
			<kendo-gridlayout-item [row]='1' [col]='1'>
				<app-activity-completion [visualizer]='visualizer' [hideBtns]="true" [hideTrending]="true" [isOverview]="true"></app-activity-completion>
			</kendo-gridlayout-item>
		</kendo-gridlayout>
		<kendo-gridlayout
			[rows]="['auto']"
			[cols]="['100%']"
			[gap]="{ rows: 5, cols: 0 }"
			class='grid'
		>
			<kendo-gridlayout-item [row]='1' [col]='1'>
				<app-schedule-analysis [visualizer]='visualizer' [isOverview]="true" [small]="true"></app-schedule-analysis>
			</kendo-gridlayout-item>
		</kendo-gridlayout>
	} @else {
		@for (component of userComponents; track component) {
			@if (component.id === 0) {
				<kendo-gridlayout
					[rows]="['auto']"
					[cols]="['100%']"
					[gap]="{ rows: 0, cols: 0 }"
					class='grid'
					[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
				>
					<kendo-gridlayout-item [row]='1' [col]='1'>
						<app-project-completion-trending [visualizer]='visualizer' [isOverview]="true"></app-project-completion-trending>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			}
			@if (component.id === 1) {
				<kendo-gridlayout
					[rows]="['auto']"
					[cols]="['100%']"
					[gap]="{ rows: 0, cols: 0 }"
					class='grid'
					[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
				>
					<kendo-gridlayout-item [row]='1' [col]='1'>
						<app-progress-trending [isOverview]="true"></app-progress-trending>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			}
			@if (component.id === 2) {
				<kendo-gridlayout
					[rows]="['auto']"
					[cols]="['100%']"
					[gap]="{ rows: 0, cols: 0 }"
					class='grid'
					[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
				>
					<kendo-gridlayout-item [row]='1' [col]='1'>
						<app-schedule-compression [isOverview]="true"></app-schedule-compression>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			}
			@if ((projectService.$currentProjectReport | async)?.criticalLookahead?.criticalActivityArray?.length > 0 && component.id === 3) {
				<kendo-gridlayout
					[rows]="['auto']"
					[cols]="['100%']"
					[gap]="{ rows: 0, cols: 0 }"
					class='grid'
					[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
				>
					<kendo-gridlayout-item [row]='1' [col]='1'>
						<app-schedule-lookahead [isOverview]="true"></app-schedule-lookahead>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			}
			@if (component.id === 4) {
				@if ((projectService.$currentProjectReport | async)?.milestones?.milestoneArray?.length !== 0 && !visualizer && (projectService?.$currentProjectData | async)?.updateIds?.length > 1) {
					<kendo-gridlayout
						[rows]="['auto']"
						[cols]="['100%']"
						[gap]="{ rows: 5, cols: 0 }"
						class='grid'
						[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
					>
						<kendo-gridlayout-item [row]='1' [col]='1'>
							<app-milestones [isOverview]="true"></app-milestones>
						</kendo-gridlayout-item>
					</kendo-gridlayout>
				} @else if ((projectService?.$currentProjectData | async)?.updateIds?.length === 1) {
					<kendo-gridlayout
						[rows]="['auto']"
						[cols]="['100%']"
						[gap]="{ rows: 5, cols: 0 }"
						class='grid'
						[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
					>
						<kendo-gridlayout-item [row]='1' [col]='1'>
							<div class='card' style='page-break-inside: avoid;'>
								<kendo-gridlayout
									[rows]="['42.4px', 'calc(100% - 42.4px)']"
									[cols]="['100%']"
									[gap]="{ rows: 0, cols: 0 }"
									class='grid'
								>
									<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container overviewHeader'>
										<div class="card-header">
											Milestones
										</div>
										<tooltip-open type='overview' [fillColor]="'overview'"></tooltip-open>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='2' [col]='1'>
										<div class="warning-div" style="height: 150px;">
											Milestones requires at least 1 update to enable
										</div>
									</kendo-gridlayout-item>
								</kendo-gridlayout>
							</div>
						</kendo-gridlayout-item>
					</kendo-gridlayout>
				}
			}
			@if (component.id === 5) {
				<kendo-gridlayout
					[rows]="['auto']"
					[cols]="['100%']"
					[gap]="{ rows: 0, cols: 0 }"
					class='grid'
					[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
				>
					<kendo-gridlayout-item [row]='1' [col]='1'>
						<app-float-consumption [visualizer]='visualizer' [isOverview]="true"></app-float-consumption>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			}
			@if (component.id === 6) {
				<kendo-gridlayout
					[rows]="['auto']"
					[cols]="['100%']"
					[gap]="{ rows: 0, cols: 0 }"
					class='grid'
					[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
				>
					<app-performance-factors [isOverview]="true"></app-performance-factors>
				</kendo-gridlayout>
			}
			@if (component.id === 7) {
				<kendo-gridlayout
					[rows]="['auto']"
					[cols]="['100%']"
					[gap]="{ rows: 0, cols: 0 }"
					class='grid'
					[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
				>
					<app-activity-completion [visualizer]='visualizer' [hideTrending]="true" [isOverview]="true"></app-activity-completion>
				</kendo-gridlayout>
			}
			@if (component.id === 8) {
				<kendo-gridlayout
					[rows]="['auto']"
					[cols]="['100%']"
					[gap]="{ rows: 0, cols: 0 }"
					class='grid'
					[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
				>
					<kendo-gridlayout-item [row]='1' [col]='1'>
						<app-activity-completion [visualizer]='visualizer' [hideBars]="true" [isOverview]="true"></app-activity-completion>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			}
			@if (component.id === 9) {
				<kendo-gridlayout
					[rows]="['auto']"
					[cols]="[visualizer ? '50%' : '100%']"
					[gap]="{ rows: 5, cols: visualizer ? 5 : 0 }"
					class='grid'
					[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
				>
					<kendo-gridlayout-item [row]='1' [col]='1' [colSpan]='visualizer ? 2 : 1'>
						<app-qc-trend [visualizer]='visualizer' [isOverview]="true"></app-qc-trend>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			}
			@if (component.id === 10) {
				<kendo-gridlayout
					[rows]="['auto']"
					[cols]="['100%']"
					[gap]="{ rows: 0, cols: 0 }"
					class='grid'
					[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
				>
					<kendo-gridlayout-item [row]='1' [col]='1'>
						<app-schedule-analysis [visualizer]='visualizer' [isOverview]="true"></app-schedule-analysis>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			}
			@if (component.id === 11) {
				<kendo-gridlayout
					[rows]="['auto']"
					[cols]="[visualizer ? '50%' : '100%']"
					[gap]="{ rows: 5, cols: visualizer ? 5 : 0 }"
					class='grid'
					[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
				>
					<app-dcma-assessment [visualizer]='visualizer' [isOverview]="true"></app-dcma-assessment>
				</kendo-gridlayout>
			}
			@if(!['ANALYTICS-BASIC-MPK', 'ANALYTICS-BASIC-APK'].includes(currentProjectCompanyPermissions?.license) && isValidCostProject) {
				@if (component.id === 12) {
					<kendo-gridlayout
						[rows]="['auto']"
						[cols]="['100%']"
						[gap]="{ rows: 5, cols: 0 }"
						class='grid'
						[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
					>
						<kendo-gridlayout-item [row]='1' [col]='1'>
							<app-cumulative-cash-flow [isOverview]="true"></app-cumulative-cash-flow>
						</kendo-gridlayout-item>
					</kendo-gridlayout>
				}
				@if (component.id === 13) {
					<kendo-gridlayout
						[rows]="['auto']"
						[cols]="['100%']"
						[gap]="{ rows: 5, cols: 0 }"
						class='grid'
						[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
					>
						<kendo-gridlayout-item [row]='1' [col]='1'>
							<app-historic-performance [isOverview]="true"></app-historic-performance>
						</kendo-gridlayout-item>
					</kendo-gridlayout>
				}
				@if (component.id === 14) {
					<kendo-gridlayout
						[rows]="['auto']"
						[cols]="['100%']"
						[gap]="{ rows: 5, cols: 0 }"
						class='grid'
						[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
					>
						<kendo-gridlayout-item [row]='1' [col]='1'>
							<app-schedule-index-trending [isOverview]="true"></app-schedule-index-trending>
						</kendo-gridlayout-item>
					</kendo-gridlayout>
				}
				@if (component.id === 15) {
					<kendo-gridlayout
						[rows]="['auto']"
						[cols]="['100%']"
						[gap]="{ rows: 5, cols: 0 }"
						class='grid'
						[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
					>
						<kendo-gridlayout-item [row]='1' [col]='1'>
							<app-activity-costs [isOverview]="true"></app-activity-costs>
						</kendo-gridlayout-item>
					</kendo-gridlayout>
				}
			}
			@if (!['ANALYTICS-BASIC-MPK', 'ANALYTICS-BASIC-APK'].includes(currentProjectCompanyPermissions?.license)) {
				@if (['riskRegister', 'coreRisk'].includes((projectService.$currentProjectReport | async)?.project?.riskMetricsType) && !['ANALYTICS-BASIC-MPK', 'ANALYTICS-BASIC-APK', 'ANALYTICS-PRO-MPK', 'ANALYTICS-PRO-APK'].includes(currentProjectCompanyPermissions?.license)) {
					@if (activeRiskRegisters?.length > 5 && component.id === 16) {
						<kendo-gridlayout
							[rows]="['auto']"
							[cols]="['100%']"
							[gap]="{ rows: 5, cols: 0 }"
							class='grid'
							[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
						>
							<kendo-gridlayout-item [row]='1' [col]='1'>
								<app-top-risks [riskRegisters]='activeRiskRegisters' [isOverview]="true"></app-top-risks>
							</kendo-gridlayout-item>
						</kendo-gridlayout>
					}
					@if (component.id === 17) {
						<kendo-gridlayout
							[rows]="['auto']"
							[cols]="['100%']"
							[gap]="{ rows: 5, cols: 0 }"
							class='grid'
							[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
						>
							<kendo-gridlayout-item [row]='1' [col]='1'>
								<app-risk-register [$projectData]='$projectData' (dialogStateChange)='dialogStateChanged($event)' [isOverview]="true"></app-risk-register>
							</kendo-gridlayout-item>
						</kendo-gridlayout>
					}
					@if (component.id === 18) {
						<kendo-gridlayout
							[rows]="['auto']"
							[cols]="['100%']"
							[gap]="{ rows: 5, cols: 0 }"
							class='grid'
							[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
						>
							<kendo-gridlayout-item [row]='1' [col]='1'>
								<app-monte-carlo [isOverview]="true"></app-monte-carlo>
							</kendo-gridlayout-item>
						</kendo-gridlayout>
					}
				}
				@if ((projectService.$currentProjectReport | async)?.project?.riskMetricsType === 'performanceFactor' && component.id === 19) {
					<kendo-gridlayout
						[rows]="['auto']"
						[cols]="['100%']"
						[gap]="{ rows: 5, cols: 0 }"
						class='grid'
						[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
					>
						<kendo-gridlayout-item [row]='1' [col]='1'>
							<app-performance-factor [isOverview]="true"></app-performance-factor>
						</kendo-gridlayout-item>
					</kendo-gridlayout>
				}
				@if (component.id === 20) {
					<kendo-gridlayout
						[rows]="['auto']"
						[cols]="['100%']"
						[gap]="{ rows: 5, cols: 0 }"
						class='grid'
						[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
					>
						<kendo-gridlayout-item [row]='1' [col]='1'>
							<app-index-trending [isOverview]="true"></app-index-trending>
						</kendo-gridlayout-item>
					</kendo-gridlayout>
				}
				@if (component.id === 21) {
					<kendo-gridlayout
						[rows]="['auto']"
						[cols]="['100%']"
						[gap]="{ rows: 5, cols: 0 }"
						class='grid'
						[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
					>
						<kendo-gridlayout-item [row]='1' [col]='1'>
							<app-critical-path-risk [isOverview]="true"></app-critical-path-risk>
						</kendo-gridlayout-item>
					</kendo-gridlayout>
				}
				@if (component.id === 22) {
					<kendo-gridlayout
						[rows]="['auto']"
						[cols]="['100%']"
						[gap]="{ rows: 5, cols: 0 }"
						class='grid'
						[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
					>
						<kendo-gridlayout-item [row]='1' [col]='1'>
							<app-critical-path-reliability [isOverview]="true"></app-critical-path-reliability>
						</kendo-gridlayout-item>
					</kendo-gridlayout>
				}
			}
			@if (component.id === 23) {
				<kendo-gridlayout
					[rows]="['auto']"
					[cols]="['100%']"
					[gap]="{ rows: 5, cols: 0 }"
					class='grid'
					[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
				>
					<kendo-gridlayout-item [row]='1' [col]='1'>
						<app-schedule-impact-analysis [$projectData]='$projectData' [isOverview]="true"></app-schedule-impact-analysis>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			}
			@if (component.id === 24) {
				<kendo-gridlayout
					[rows]="['auto']"
					[cols]="['100%']"
					[gap]="{ rows: 5, cols: 0 }"
					class='grid'
					[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
				>
					<kendo-gridlayout-item [row]='1' [col]='1'>
						<app-critical-path-comparison [selectedDelayActivity]='projectService.selectedDelayActv' (selectionByClick)='ganttSelection($event)' [isOverview]="true"></app-critical-path-comparison>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			}
			@if (component.id === 25) {
				<kendo-gridlayout
					[rows]="['auto']"
					[cols]="['100%']"
					[gap]="{ rows: 5, cols: 0 }"
					class='grid'
					[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
				>
					<kendo-gridlayout-item [row]='1' [col]='1'>
						<app-schedule-comparison [isOverview]="true"></app-schedule-comparison>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			}
			@if (component.id === 26) {
				<kendo-gridlayout
					[rows]="['auto']"
					[cols]="['100%']"
					[gap]="{ rows: 5, cols: 0 }"
					class='grid'
					[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
				>
					<kendo-gridlayout-item [row]='1' [col]='1'>
						<app-project-overview-summary></app-project-overview-summary>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			}
			@if (component.id === 27) {
				<kendo-gridlayout
					[rows]="['auto']"
					[cols]="['100%']"
					[gap]="{ rows: 5, cols: 0 }"
					class='grid'
					[style.padding-bottom]='$index === userComponents?.length - 1 ? "0" : "5px"'
				>
					<kendo-gridlayout-item [row]='1' [col]='1'>
						<app-driving-path [isOverview]="true"></app-driving-path>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			}
		}
	}
</div>
