import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { ProjectDashboardService } from '../../../../services/project/project.service';
import { ProjectInterface, ScheduleType } from '../../../../models/Project';
import { AnalyticsDashboardService } from '../../../../services/analytics/analytics.service';
import { Client, CompanyInterface, Entry, HierarchyResponse, Member } from '../../../../models/company';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { cleanDateUTC } from '../../../../util/pipes/date.pipe';
import { UserService } from '../../../../services/common/user.service';
import { TreeItem } from '@progress/kendo-angular-treeview';
import { RestService } from '../../../../services/common/rest.service';
import { plusIcon } from '@progress/kendo-svg-icons';

@Component({
	selector: 'app-project-details',
	templateUrl: './project-details.component.html',
	styleUrls: ['./project-details.component.scss'],
})
export class ProjectDetailsComponent implements OnInit, AfterViewInit {
	@Input() isEdit: boolean = false;
	@Input() isBaseline: boolean = false;
	@Input() public projectDetails: FormGroup;
	@Input() public project: ProjectInterface;
	@Input() public showMilestones: boolean;
	@Output() saasRiskEditFormChanges = new EventEmitter<FormGroup>();
	@Output() companyChanged = new EventEmitter<number | undefined>();
	@Input() public googleScriptLoaded = false;
	@Input() viewerDisabledMode: boolean = false;
	@Input() eligibilityData: any = {
		usage: 0,
		total: 0,
	};
	autocomplete: google.maps.places.Autocomplete;
	expandedNodes: number[] = [];

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public filterSettings: DropDownFilterSettings = {
		caseSensitive: false,
		operator: 'startsWith',
	};
	public projectKeys: Array<string> = [];
	public address1Field: HTMLInputElement;
	@ViewChild('siteLocation')
	siteLocationEl: TextBoxComponent;
	@ViewChild('client')
	clientDropdown;
	@ViewChild('projectType')
	projectTypeDropdown;
	@ViewChild('aegisRegion')
	aegisRegionDropdown;
	@ViewChild('aegisPocId')
	aegisPocIdDropdown;
	@ViewChild('pocId')
	pocDropdown;

	public startMilestone;
	public finishMilestone;
	public loadingMessage = 'Loading...';
	public Regions: string[] = [];
	public companyHierarchy: HierarchyResponse[] = [];
	public initialCompany: HierarchyResponse = {
		entry: null,
		children: [],
		clients: [],
	};
	public pocMembers: Array<Member & { displayName: string }> = [];

	public icons = {
		addIcon: plusIcon,
	};

	availableProjectTypes = [
		'Airport',
		'Correctional Facility',
		'Data Center',
		'Educational Facility',
		'Government',
		'Healthcare',
		'Infrastructure',
		'Office/Retail',
		'Other',
		'Pharmaceutical',
		'Renovation',
		'Residential',
		'Sports & Entertainment',
		'Wastewater Treatment',
	];
	public clientList: Client[] = [];
	public filteredClients: Client[] = [];
	public companyList: Entry[] = [];
	public pocList: Member[] = [];
	public filteredPOCs: Member[] = [];
	public scheduleTypeItemDescriptors = {
		Active: 'Auto-assigned during first update upload. Enables missing update tracking (45+ days since last update)',
		Closed: 'Project is closed, but still accessible via Project List',
		Baseline: 'Auto-assigned when creating new Project',
		Consulting: 'Irregular update cycle',
	};
	loadingEligibility: boolean = false;

	user: any = {
		userType: 'aegis',
	};

	public projectDetailsSaasRisk = null;

	constructor(
		public projectService: ProjectDashboardService,
		public scheduleStorage: ScheduleStorageService,
		public userService: UserService,
		private rest: RestService,
		private fb: FormBuilder
	) {
		this.scheduleTypeDisabled = this.scheduleTypeDisabled.bind(this);
	}

	ngOnInit(): void {
		this.projectDetailsSaasRisk = this.fb.group({
			baselineFile: this.fb.group({
				updateId: new FormControl(''),
			}),
			projectCode: new FormControl(''),
			projectName: new FormControl('', Validators.required),
			projectImage: new FormControl(null),
			projectType: new FormControl('', Validators.required),
			siteLocation: new FormControl('', Validators.required),
			lat: new FormControl(0),
			lng: new FormControl(0),
			sharepoint: new FormControl({ value: '', disabled: this.viewerDisabledMode }),
			isAegisManaged: new FormControl(true),
			memberIds: new FormControl([this.userService.user.value?._id], Validators.required),
			scheduleType: new FormControl({ value: '', disabled: this.viewerDisabledMode }),
			company: new FormControl({ value: null, disabled: true }, Validators.required),
			pocId: new FormControl({ value: null, disabled: true }, Validators.required),
			clientId: new FormControl({ value: null, disabled: false }, Validators.required),
		});
		this.projectService.sqlProjects.subscribe((projects) => {
			this.projectKeys = Array.from(projects.keys());
		});

		this.userService.companies.subscribe((companies) => {
			this.companyHierarchy = Array.from(companies.values());
			const formCompanyId =
				this.user?.userType === 'saasRisk' && (this.isEdit || this.isBaseline)
					? this.projectDetailsSaasRisk.getRawValue()?.company
					: this.projectDetails.getRawValue()?.company;
			const lookingForId = this.project?.company ? this.project?.company : formCompanyId;
			let initCompany = this.searchTree(this.companyHierarchy[0], lookingForId);
			if (initCompany === null) {
				for (let i = 0; i < this.companyHierarchy.length; i++) {
					if (this.companyHierarchy[i].entry.id === lookingForId) {
						initCompany = this.searchTree(this.companyHierarchy[i], lookingForId);
						if (initCompany !== null) {
							break;
						}
					}
				}
				if (initCompany === null) {
					initCompany = this.searchTree(this.companyHierarchy[0], this.companyHierarchy[0].entry.id);
				}
			}
			this.initialCompany = {
				entry: initCompany?.entry,
				children: initCompany?.children || [],
				clients: initCompany?.clients || [],
			};
		});

		this.userService.allCompanies.subscribe((companies) => {
			this.companyList = Array.from(companies?.values() || []).sort((a, b) => a.name.localeCompare(b.name));
		});

		this.userService.clients.subscribe((clients) => {
			this.clientList = Array.from(clients?.values() || []).sort((a, b) => a.name.localeCompare(b.name));
			this.filteredClients = [...this.clientList];
		});

		this.userService.companyMembers.subscribe((members) => {
			if (members) {
				if (this.company.value) {
					this.filterForCompany(this.company.value);
				}
			}
		});

		this.userService.user.subscribe((data) => {
			if (data) {
				this.user = data;
			}
		});

		this.populateMilestones();
	}

	ngAfterViewInit(): void {
		if (this.googleScriptLoaded) {
			this.initAutocomplete();
		}
		if (this.user?.userType === 'saasRisk' && (this.isEdit || this.isBaseline)) {
			const form = this.projectDetails.getRawValue();
			this.projectDetailsSaasRisk.patchValue({
				projectCode: form?.projectCode,
				projectName: form?.projectName,
				projectImage: form?.projectImage,
				projectType: form?.projectType,
				siteLocation: form?.siteLocation,
				lat: form?.lat,
				lng: form?.lng,
				sharepoint: form?.sharepoint,
				isAegisManaged: form?.isAegisManaged,
				memberIds: form?.memberIds,
				scheduleType: form?.scheduleType,
				company: form?.company,
				pocId: form?.pocId,
				clientId: form?.clientId,
			});
			this.projectDetailsSaasRisk.controls.baselineFile.patchValue({ updateId: form?.baselineFile?.updateId });
			this.projectDetailsSaasRisk.updateValueAndValidity();
		}
		this.projectDetailsSaasRisk.valueChanges.subscribe((form) => {
			this.saasRiskEditFormChanges.emit(this.projectDetailsSaasRisk);
		});
	}

	/**
	 * searches hierarchy tree for node with matching id
	 * @param node
	 * @param id
	 */
	searchTree(node: any, id: number): any {
		if (node.entry.id === id) {
			return node;
		} else if (node?.children?.length) {
			let result = null;
			for (let i = 0; result === null && i < node.children.length; i++) {
				result = this.searchTree(node.children[i], id);
			}
			return result;
		}
		return null;
	}

	autoFill(code: number, userField?: string) {
		const project = this.projectService.sqlProjects.getValue().get(code.toString());
		if (project !== undefined) {
			let companyId = '';
			if (project?.data?.Customername) {
				for (const company of this.projectService.companiesById.values()) {
					if (company.name === project?.data?.Customername) {
						companyId = company._id;
						break;
					}
				}
			}

			if (this.user?.userType === 'saasRisk' && (this.isEdit || this.isBaseline)) {
				this.projectDetailsSaasRisk.patchValue({
					projectName: project?.data?.Name || '',
				});
			} else {
				this.projectDetails.patchValue({
					projectName: project?.data?.Name || '',
					client: companyId,
				});
			}
		}
	}

	initAutocomplete() {
		this.address1Field = this.siteLocationEl.input.nativeElement;
		if (this.address1Field) {
			// Create the autocomplete object, restricting the search predictions to
			// addresses in the US and Canada.
			this.autocomplete = new google.maps.places.Autocomplete(this.address1Field);
			// When the user selects an address from the drop-down, populate the
			// address fields in the form.
			this.autocomplete.addListener(
				'place_changed',
				(
					(that) => () =>
						this.fillInAddress(that)
				)(this)
			);
		}
	}

	fillInAddress(instance: this) {
		// Get the place details from the autocomplete object.
		const place = instance.autocomplete.getPlace();
		// Get each component of the address from the place details,
		// and then fill-in the corresponding field on the form.
		// place.address_components are google.maps.GeocoderAddressComponent objects
		// which are documented at http://goo.gle/3l5i5Mr
		const address = place.formatted_address;
		this.address1Field.value = address;

		if (this.user?.userType === 'saasRisk' && (this.isEdit || this.isBaseline)) {
			this.projectDetailsSaasRisk.patchValue({
				lat: place.geometry.location.lat(),
				lng: place.geometry.location.lng(),
				siteLocation: address,
			});
		} else {
			this.projectDetails.patchValue({
				lat: place.geometry.location.lat(),
				lng: place.geometry.location.lng(),
				siteLocation: address,
			});
		}
	}

	/**
	 * open the dropdown on focus
	 * @param viewChild
	 */
	focus(viewChild) {
		this[viewChild].toggle(true);
	}

	public scheduleTypeDisabled(itemArgs: { dataItem: string; index: number }): boolean {
		return itemArgs.dataItem === 'Baseline' && this.project?.updateIds?.length > 1;
	}

	handleClientFilter(value) {
		this.filteredClients = this.clientList.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
	}

	public populateMilestones(): void {
		this.scheduleStorage.$allUpdates.subscribe((updates) => {
			if (updates?.[updates.length - 1]?.startMilestone) {
				this.startMilestone =
					updates?.[updates.length - 1]?.startMilestone?.task_code +
					' - ' +
					updates?.[updates.length - 1]?.startMilestone?.task_name +
					' - ' +
					cleanDateUTC(
						new Date(
							updates?.[updates.length - 1]?.startMilestone?.early_start_date ||
								updates?.[updates.length - 1]?.startMilestone?.late_start_date
						),
						'MMM dd, yyyy'
					);
			}
			const blFinishMilestone = updates?.[0]?.finishMilestone;
			const finishMilestone = updates?.[updates.length - 1]?.finishMilestone;
			if (finishMilestone) {
				this.finishMilestone =
					finishMilestone?.task_code +
					' - ' +
					finishMilestone?.task_name +
					' - ' +
					cleanDateUTC(new Date(finishMilestone?.target_end_date), 'MMM dd, yyyy');
			} else if (blFinishMilestone) {
				this.finishMilestone =
					blFinishMilestone?.task_name +
					' ' +
					cleanDateUTC(new Date(blFinishMilestone?.target_end_date), 'MMM dd, yyyy');
			}
		});
	}

	/**
	 * A function that checks whether a given node index exists in the expanded keys collection.
	 * If the item ID can be found, the node is marked as expanded.
	 */
	public isNodeExpanded = (node: any): boolean => this.expandedNodes.indexOf(node.entry.id) !== -1;

	/**
	 * A `nodeCollapse` event handler that will remove the node data item ID
	 * from the collection, collapsing its children.
	 */
	public handleCollapse(args: TreeItem): void {
		this.expandedNodes = this.expandedNodes.filter((id) => id !== args.dataItem.entry.id);
	}

	/**
	 * An `nodeExpand` event handler that will add the node data item ID
	 * to the collection, expanding its children.
	 */
	public handleExpand(args: TreeItem): void {
		this.expandedNodes = this.expandedNodes.concat(args.dataItem.entry.id);
	}

	/**
	 * company dropdowntree open event handler
	 */
	open(): void {
		//initially sets all nodes to be expanded
		this.companyList.forEach((node) => {
			this.expandedNodes.push(node.id);
		});
	}

	handleValueChange($event: any) {
		if ($event === undefined) {
			this.loadingEligibility = false;
			this.eligibilityData = {
				usage: 0,
				total: 0,
			};
		} else {
			this.loadingEligibility = true;
			const url = this.user?.userType === 'saasRisk' ? '/seats/risk/' : '/seats/analytics/';
			this.rest.fetchAccount('v2/api/company/' + $event + url).subscribe({
				next: (res) => {
					this.eligibilityData = res;
					this.loadingEligibility = false;
				},
			});
		}
		if (typeof $event === 'number' || $event === undefined) {
			this.companyChanged.emit($event);
		}
	}

	filterForCompany(companyId: number) {
		const members = this.userService.companyMembers.value;
		if (members) {
			this.pocMembers = Array.from(members.values())
				.filter((member) => member.company.id === this.company.value)
				.sort((a, b) => a.displayName.localeCompare(b.displayName));
			const selectedPoc: number =
				this.user?.userType === 'saasRisk' && (this.isEdit || this.isBaseline)
					? this.projectDetailsSaasRisk.get('pocId')
					: this.projectDetails.get('pocId').value;
			const selectedMember = this.pocMembers.find((m) => m.account.id === selectedPoc);
			if (!this.viewerDisabledMode) {
				if (!selectedMember && selectedPoc) {
					this.projectDetails.patchValue({
						pocId: undefined,
					});
				}
			}
		}
		const clients = this.userService.clients.value;
		if (clients) {
			this.clientList = Array.from(clients.values())
				.filter((client) => client.parent.id === companyId)
				.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
			this.filteredClients = [...this.clientList];
			const targetClient =
				this.user?.userType === 'saasRisk' && (this.isEdit || this.isBaseline)
					? this.projectDetailsSaasRisk.get('clientId').value
					: this.projectDetails.get('clientId').value;
			const selectedClient = this.filteredClients.find((client) => client.id === targetClient);
			if (!this.viewerDisabledMode) {
				if (!selectedClient && targetClient) {
					this.projectDetails.patchValue({
						clientId: undefined,
					});
				}
			}
		}
		if (companyId == null) {
			this.projectDetails.get('pocId').disable();
			this.projectDetails.get('clientId').disable();
			if (this.user?.userType === 'saasRisk' && (this.isEdit || this.isBaseline)) {
				this.projectDetailsSaasRisk.get('pocId').disable();
				this.projectDetailsSaasRisk.get('clientId').disable();
			}
		} else {
			this.projectDetails.get('pocId').enable();
			if (!this.viewerDisabledMode) {
				this.projectDetails.get('clientId').enable();
			}
			if (this.user?.userType === 'saasRisk' && (this.isEdit || this.isBaseline)) {
				this.projectDetailsSaasRisk.get('pocId').enable();
				if (!this.viewerDisabledMode) {
					this.projectDetailsSaasRisk.get('clientId').enable();
				}
			}
		}
	}

	get company() {
		return this.user?.userType === 'saasRisk' && (this.isEdit || this.isBaseline)
			? this.projectDetailsSaasRisk.get('company')
			: this.projectDetails.get('company');
	}

	public clientNormalizer = (text: Observable<string>) => text.pipe(map((content: string) => content));

	focusClientDropdown(): void {
		this.clientDropdown.focus();
	}
}
