import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GanttService } from '../../../../../../services/project/gantt.service';
import { CurrentUpdateGanttPreset } from '../../../../../../models/Project';
import { BehaviorSubject } from 'rxjs';
import { copyIcon, pencilIcon, starIcon, starOutlineIcon, trashIcon } from '@progress/kendo-svg-icons';

@Component({
	selector: 'app-report-selection',
	templateUrl: './report-selection.component.html',
	styleUrls: ['./report-selection.component.scss'],
})
export class ReportSelectionComponent implements OnInit {
	_filteredUserReports: CurrentUpdateGanttPreset[] = [];
	@Input() preSelectedReports: string[] = [];
	@Input() disableReportChanges: boolean = false;
	@Input() $filteredReports: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>(null);
	@Output() checkedReportChange: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();
	icons = {
		star: starIcon,
		starOutline: starOutlineIcon,
		pencil: pencilIcon,
		copy: copyIcon,
		trash: trashIcon,
	};
	constructor(public ganttService: GanttService) {
		this.sortCompare = this.sortCompare.bind(this);
	}

	ngOnInit() {
		if (this.preSelectedReports?.length) {
			this.ganttService.checkedReports = structuredClone(this.preSelectedReports);
		}
		this.$filteredReports.subscribe((val: string[]) => {
			this._filteredUserReports = structuredClone(this.ganttService.userPresets)
				.filter((preset: CurrentUpdateGanttPreset) => {
					if (val === null) {
						return (
							this.ganttService.searchTerm?.length === 0 ||
							preset.name.toLowerCase().includes(this.ganttService.searchTerm.toLowerCase())
						);
					} else {
						return (
							val.includes(preset.id) &&
							(this.ganttService.searchTerm?.length === 0 ||
								preset.name.toLowerCase().includes(this.ganttService.searchTerm.toLowerCase()))
						);
					}
				})
				.sort(this.sortCompare);
			this.ganttService.checkedReports = this.preSelectedReports;
		});
		this.ganttService.$ganttPresetsUpdated.subscribe((val: boolean) => {
			if (val) {
				this._filteredUserReports = structuredClone(this.ganttService.userPresets)
					.filter((preset: CurrentUpdateGanttPreset) => {
						if (this.$filteredReports.value === null) {
							return (
								this.ganttService.searchTerm?.length === 0 ||
								preset.name.toLowerCase().includes(this.ganttService.searchTerm.toLowerCase())
							);
						} else {
							return (
								this.$filteredReports.value?.includes(preset.id) &&
								(this.ganttService.searchTerm?.length === 0 ||
									preset.name.toLowerCase().includes(this.ganttService.searchTerm.toLowerCase()))
							);
						}
					})
					.sort(this.sortCompare);
				this.ganttService.checkedReports = this.preSelectedReports;
			}
		});
	}

	sortCompare(a: CurrentUpdateGanttPreset, b: CurrentUpdateGanttPreset): number {
		if (this.disableReportChanges) {
			if (a?.isFavorite !== b?.isFavorite) {
				return a?.isFavorite ? -1 : 1;
			}
		}
		if (a?.name?.toLowerCase() < b?.name?.toLowerCase()) {
			return -1;
		}
		if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
			return 1;
		}
		return 0;
	}

	deleteReport(ev, dataItem: CurrentUpdateGanttPreset): void {
		this.ganttService.deletingPreset = dataItem;
		this.ganttService.ganttPresetDeleteConfirmOpen = true;
		// necessary to avoid bubbling the click event to the presetClicked function -RS
		ev.cancelBubble = true;
		ev.stopPropagation();
	}

	applyPreset(preset: CurrentUpdateGanttPreset): void {
		this.ganttService.$applyPreset.next(preset);
	}
}
