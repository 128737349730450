import { areArraysEqualSets, areArraysIdentical, areStringObjectsIdentical } from '../../util/strings';
import { GroupResult } from '@progress/kendo-data-query';
import { FilterItem } from '../../components/portfolio/project-list/project-list/project-list.component';
import { ProjectFieldFilter } from '../../util/kendo';

export class AccountUser {
	username: string;
	access_token: string;
	refresh_token: string;
}

export interface Profile {
	name?: string;
	firstName?: string;
	lastName?: string;
	picture?: string;
	pictureId?: string;
	email?: string;
	phoneNumber?: string;
	description?: string;
	activeProjectId?: string;
	activeCompanyId?: string;
	companyPermissions?: ProfileCompanyPermission[];
}

export type ProfileLicense =
	| 'ANALYTICS-BASIC-MPK'
	| 'ANALYTICS-BASIC-APK'
	| 'ANALYTICS-PRO-MPK'
	| 'ANALYTICS-PRO-APK'
	| 'ANALYTICS-CORP-MPK'
	| 'ANALYTICS-CORP-APK'
	| 'ANALYTICS-ENTERPRISE-MPK'
	| 'ANALYTICS-ENTERPRISE-APK';

export interface ProfileCompanyPermission {
	admin: boolean;
	manager: boolean;
	id: number;
	viewer: boolean;
	license: ProfileLicense;
	parent: number;
	parentCritPathRequired: boolean;
	parentTiaRequired: boolean;
}

export interface UserInterface extends Document {
	_id: string;
	companyIds?: string[];
	projectIds: string[];
	emails?: any[];
	isSuperAdmin?: boolean;
	profile?: Profile;
	region?: string[];
	userType?: 'client' | 'aegis' | 'saas' | 'saasRisk';
	activeCompanyId?: string;
	activeProjectId?: string;
	isArchived?: boolean;
	isOptOutOfEmails?: boolean;
	portfolioPresets?: PortfolioPreset[];
}

export interface PortfolioPreset {
	banner: string;
	filters: string;
	ganttView: number;
	quickView: string;
	tableView: 'grid' | 'gantt';
	visibleColumns: string[];
	name: string;
	isFavorite: boolean;
	id: string;
	showMap: boolean;
}

export interface PresetInputs {
	preset: PortfolioPreset;
	clientList: FilterItem[];
	filteredClientList: FilterItem[];
	nestedClientList: GroupResult[];
	companyList: FilterItem[];
	nestedCompanyList: FilterItem[];
	riskList: FilterItem[];
	projectTypeList: FilterItem[];
	filteredProjectTypeList: FilterItem[];
	pocList: FilterItem[];
	filteredPocList: FilterItem[];
	nestedPocList: GroupResult[];
	currentFilters: ProjectFieldFilter[];
	completionStatus: FilterItem[];
	pastPeriodPerformanceList: FilterItem[];
}

export const isUserDifferent = (oldUser: UserInterface, newUser: UserInterface): boolean => {
	if (!areArraysIdentical(Object.keys(oldUser || {}), Object.keys(newUser || {}))) {
		return true;
	}
	if (oldUser.profile && newUser.profile) {
		if (
			!areStringObjectsIdentical(oldUser.profile as Record<string, string>, newUser.profile as Record<string, string>)
		) {
			return true;
		}
	}
	for (const [key, value] of Object.entries(oldUser)) {
		if (key === 'profile') {
			continue;
		}
		if (key.includes('Ids') || key === 'emails' || key === 'region') {
			if (!areArraysIdentical(oldUser[key] as string[], newUser[key] as string[])) {
				console.log('differing ids', key);
				return true;
			}
			continue;
		}
		if (value !== newUser[key]) {
			console.log('differing values', key);
			return true;
		}
	}
	return false;
};

export type LicenseTier =
	| 'ANALYTICS-BASIC-MPK'
	| 'ANALYTICS-BASIC-APK'
	| 'ANALYTICS-PRO-MPK'
	| 'ANALYTICS-PRO-APK'
	| 'ANALYTICS-CORP-MPK'
	| 'ANALYTICS-CORP-APK'
	| 'ANALYTICS-ENTERPRISE-MPK'
	| 'ANALYTICS-ENTERPRISE-APK';
