import { Injectable } from '@angular/core';
import { differenceInDays, format } from 'date-fns';
import { cleanDateUTC } from '../../util/pipes/date.pipe';

@Injectable({
	providedIn: 'root',
})
export class CalculationsService {
	constructor() {}

	getProjectCompletionMilestone(update: any) {
		const finishMilestones = update?.['reqBody']?.['finishMilestones'];
		return finishMilestones && finishMilestones.length ? finishMilestones[0] : finishMilestones;
	}

	createSingleProjectData(project: any, update: any, baselineUpdate: any, projectCompletionMilestone: any) {
		const id = project['_id'];
		const projectId = project['projectSageId'];
		const lat = project['lat'];
		const lng = project['lng'];
		const projectType = project['projectType'];
		const projectName = project['name'];
		const siteAddressReference = project['siteAddressReference'];
		const sharepointPage = project['sharePoint'];

		const criticalPathNotes = update['reqBody']?.['newUpdate']?.['periodVal_criticalPathSummary'];
		const timeAnalysisNotes = update['update']?.['reqBody']?.['newUpdate']?.['periodVal_impactsIssuesTiaNotes'];
		const mostRecentDataDate = cleanDateUTC(new Date(update['newUpdate.periodCalc_dateUploaded']), 'yyyy-MM-dd 12:00');
		const lastUpdatedDate = cleanDateUTC(new Date(update['newUpdate.periodCalc_dateUploaded']), 'MMM dd, yyyy');

		const aegisScore = this.calculateProjectScore(update, baselineUpdate);

		const reliabilityScore = this.calculateReliabilityScore(update, baselineUpdate);
		const latestUpdate = update['newUpdate']?.['periodVal_updateName'];
		const deltaVsCcd = update['newUpdate']?.['periodCalc_projectCompletionDelta_cd'];
		const deltaVsPrev = update['newUpdate']?.['periodCalc_periodDuration_cd'];

		const contractCompletion = projectCompletionMilestone['cstr_date'];
		const currentCompletion: Date = new Date(projectCompletionMilestone['early_end_date']);
		const contractVariance = this.calculateContractVariance(projectCompletionMilestone);

		const projectScore = this.calculateProjectScore(update, baselineUpdate);
		const riskScore = this.calculateRiskScore(update, baselineUpdate);

		const dataDate = new Date(update['update']?.['newUpdate']?.['periodVal_dataDate']);
		const projectRemainingDuration = differenceInDays(currentCompletion, dataDate);
		const varianceRatio = this.calculateVarianceRatio(update);

		return {
			id,
			projectId,
			lat,
			lng,
			projectType,
			projectName,
			siteAddressReference,
			criticalPathNotes,
			timeAnalysisNotes,
			sharepointPage,
			mostRecentDataDate,
			lastUpdatedDate,
			aegisScore,
			riskScore,
			reliabilityScore,
			latestUpdate,
			contractCompletion,
			currentCompletion,
			deltaVsCcd,
			deltaVsPrev,
			contractVariance,
			projectScore,
			projectRemainingDuration,
			varianceRatio,
		};
	}

	createProjectTableData(data: any[]) {
		const tableData: any = [];

		data.forEach((row: any) => {
			const project = row['project'];
			const latestUpdate = row['latestUpdate'];
			const baselineUpdate = row['baselineUpdate'];
			const finishMilestones = this.getProjectCompletionMilestone(latestUpdate);

			tableData.push(this.createSingleProjectData(project, latestUpdate, baselineUpdate, finishMilestones));
		});

		return tableData;
	}

	calculateProjectScore(update: any, baselineUpdate: any) {
		const dataDate = update['newUpdate']?.['periodVal_dataDate'];

		const projectStartDate = baselineUpdate['newUpdate']?.['periodVal_projectStart'];

		const projectCompletionMilestone = this.getProjectCompletionMilestone(update);
		const currentCompletion = projectCompletionMilestone['early_end_date'];
		const projectRemainingDuration = differenceInDays(new Date(currentCompletion), new Date(dataDate));
		const projectElapsedDuration = differenceInDays(new Date(dataDate), new Date(projectStartDate));
		const contractVariance = this.calculateContractVariance(projectCompletionMilestone);

		const CPLI = (projectRemainingDuration + contractVariance) / projectRemainingDuration;
		const TFCI = (projectElapsedDuration + contractVariance) / projectElapsedDuration;

		const BEI = +update['newUpdate']?.['periodCalc_metric_bei'] || 0;
		const CEI = +update['newUpdate']?.['periodCalc_metric_cei'] || 0;
		const TDA = +update['newUpdate']?.['periodCalc_metric_tda'] || 0;
		const NCCR = +update['newUpdate']?.['periodCalc_metric_nccr'] || 0;

		const calculationFirst = 0.4 * ((CPLI + TFCI) / 2);
		const calculationSecond = 0.3 * ((BEI + CEI) / 2);
		return (calculationFirst + calculationSecond + 0.2 * NCCR + 0.1 * TDA) * 100;
	}

	calculateRiskScore(update: any, baselineUpdate: any) {
		const dataDate = update['newUpdate']?.['periodVal_dataDate'];

		const projectCompletionMilestone = this.getProjectCompletionMilestone(update);
		const currentCompletion = projectCompletionMilestone['early_end_date'];
		const projectRemainingDuration = differenceInDays(new Date(currentCompletion), new Date(dataDate));
		const contractVariance = this.calculateContractVariance(projectCompletionMilestone);

		const CPLI = (projectRemainingDuration + contractVariance) / projectRemainingDuration;
		const TDA = +update['newUpdate']?.['periodCalc_metric_tda'] || 0;
		const NCCR = +update['newUpdate']?.['periodCalc_metric_nccr'] || 0;

		return ((0.75 * NCCR + 0.75 * TDA + CPLI) / 2.5) * 100;
	}

	calculateContractVariance(update: any) {
		const contractCompletion = update['cstr_date'];
		const currentCompletion = update['early_end_date'];

		return currentCompletion && contractCompletion
			? differenceInDays(new Date(currentCompletion), new Date(contractCompletion))
			: 0;
	}

	calculateVarianceRatio(update: any) {
		const contractVariance = this.calculateContractVariance(update);
		const dataDate = new Date(update['update']?.['newUpdate.periodVal_dataDate']);
		const currentCompletion = update['newUpdate']?.['periodVal_completionProjected'];
		const projectRemainingDuration = differenceInDays(new Date(currentCompletion), new Date(dataDate));
		return (contractVariance / projectRemainingDuration) * 100;
	}

	calculateReliabilityScore(update: any, baselineUpdate: any) {
		const check1 = this.calculateCheck1(update);
		const check2 = this.calculateCheck2(update);
		const check3 = this.calculateCheck3(update);
		const check4 = this.calculateCheck4(update);
		const check5 = this.calculateCheck5(update);

		return ((check1 + check2 + check3 + check4 + check5) / 5) * 100;
	}

	calculateCheck1(update: any) {
		const taskArray = update['reqBody']?.['taskArray'] || [];

		const totalActivitiesArray = taskArray.filter((task: any) => {
			const task_type = task['task_type'];

			if (task_type === 'TT_Task' || task_type === 'TT_Rsrc' || task_type === 'TT_Mile' || task_type === 'TT_FinMile') {
				return task;
			}
		});

		const totalConstraintsArray = totalActivitiesArray.filter((task: any) => {
			const cstr_date = task['cstr_date'];

			if (cstr_date) {
				return task;
			}
		});

		const totalConstraints = totalConstraintsArray.length;

		return totalConstraints <= 2 ? 1 : 1 - totalConstraintsArray.length / totalActivitiesArray.length;
	}

	calculateCheck2(update: any) {
		const taskPredArray = update['reqBody']?.['taskPredArray'] || [];

		const fsLagArray = taskPredArray.filter((task: any) => {
			const pred_type = task['pred_type'];
			const lag_hr_cnt = +task['lag_hr_cnt'] || 0;

			if (pred_type === 'FS' && lag_hr_cnt) {
				return task;
			}
		});

		return fsLagArray.length === 0 ? 1 : 0;
	}

	calculateCheck3(update: any) {
		const taskArray = update['reqBody']?.['taskArray'] || [];
		const taskPredArray = update['reqBody']?.['taskPredArray'] || [];

		const activitiesMissingPredecessorsArray = taskPredArray.filter((task: any) => {
			const taskPred_id = task['task_id'];

			const existingTask: any = taskArray.filter((item: any) => {
				const id = item['task_id'];
				if (id === taskPred_id) {
					return item;
				}
			});

			if (!(existingTask && existingTask.length)) {
				return task;
			}
		});

		return activitiesMissingPredecessorsArray.length >= 2 ? 0 : 1;
	}

	calculateCheck4(update: any) {
		const taskArray = update['reqBody']?.['taskArray'] || [];
		const taskPredArray = update['reqBody']?.['taskPredArray'] || [];

		const activitiesMissingSuccessorsArray = taskPredArray.filter((task: any) => {
			const taskPred_id = task['pred_task_id'];

			const existingTask: any = taskArray.filter((item: any) => {
				const id = item['task_id'];
				if (id === taskPred_id) {
					return item;
				}
			});

			if (!(existingTask && existingTask.length)) {
				return task;
			}
		});

		return activitiesMissingSuccessorsArray.length >= 2 ? 0 : 1;
	}

	calculateCheck5(update: any) {
		const taskArray = update['reqBody']?.['taskArray'] || [];
		const dataDate = update['newUpdate']?.['periodVal_dataDate'] || [];

		const totalActivitiesArray = taskArray.filter((task: any) => {
			const task_type = task['task_type'];
			const act_start_date = task['act_start_date'];
			const act_end_date = task['act_end_date'];

			if (
				(task_type === 'TT_Task' || task_type === 'TT_Rsrc' || task_type === 'TT_Mile' || task_type === 'TT_FinMile') &&
				(new Date(act_start_date) >= new Date(dataDate) || new Date(act_end_date) >= new Date(dataDate))
			) {
				return task;
			}
		});

		const actualsBeyondDataDate = totalActivitiesArray.length;

		return actualsBeyondDataDate >= 1 ? 0 : 1;
	}
}
