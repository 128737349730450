import { Component, OnInit } from '@angular/core';
import { StatusCheckService } from 'services/infra/status-check.service';

@Component({
	selector: 'app-maintenance-bar',
	templateUrl: './maintenance-bar.component.html',
	styleUrls: ['./maintenance-bar.component.scss'],
})
export class MaintenanceBarComponent implements OnInit {
	constructor(public statusCheckService: StatusCheckService) {}

	ngOnInit(): void {}
}
