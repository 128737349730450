<div class='card' [style]='isFocus ? "" : "break-inside: avoid;"' id="floatConsumptionComponent">
  <kendo-gridlayout
	[rows]="[isFocus ? '32px' : '42.4px']"
	[cols]="['100%']"
	[gap]="{ rows: 0, cols: 0 }"
	class='grid'
	[style]='(visualizer && !isFocus) ? (((projectService.$currentProjectReport | async)?.milestones?.milestoneArray?.length !== 0) ? "counter: vis-3page;  page-break-inside: avoid;" : "counter: vis-2page; page-break-before: always;") : ""'
  >
	<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container'  [class.overviewHeader]="isOverview">
	  <!--   [cols]="['calc(33% - 3px)', 'calc(33% - 3px)', 'calc(33% - 3px)']"   -->
	  <kendo-gridlayout
		[rows]="[isFocus ? '32px' : '42.4px']"
		[cols]="['300px', 'calc(100% - 315px)']"
		[gap]="{ rows: 0, cols: 5 }"
		class='full-width'
	  >
		<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
		  <div class="card-header" [class.focusTabFontSizing]="isFocus">
			Float Consumption
		  </div>
		  <tooltip-open type='{{isOverview ? "overview" : "float-consumption"}}' [fillColor]="isOverview ? 'overview' : ''"></tooltip-open>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='1' [col]='2' class='toolbar-item-container' style="margin: auto; height: min-content; justify-content: end">
			@if (!isFocus) {
				<kendo-multiselecttree
					#activityCodesMultiselectTree
					kendoMultiSelectTreeSummaryTag
					kendoMultiSelectTreeExpandable
					[kendoMultiSelectTreeHierarchyBinding]="allActivityCodes"
					[(value)]="selectedActivityCodes"
					textField="name"
					valueField="id"
					childrenField='subCodes'
					[valuePrimitive]='false'
					[filterable]='true'
					(valueChange)='filterChanged($event);'
					[clearButton]='false'
					[expandOnFilter]="{expandMatches: true}"
					[itemDisabled]="itemDisabled"
					[placeholder]='"Activity Code Filter"'
					style='width: 200px; cursor: pointer; margin: auto 10px auto 0;'
					[disabled]='((projectService.$currentProjectData | async)?.updateIds.length || 0) <= 1'
					[class.focusTabHeaderSizing]="isFocus"
				>
					<ng-template kendoMultiSelectTreeGroupTagTemplate let-dataItems>
						<span>
							<kendo-svg-icon [icon]='icons.caretDown'></kendo-svg-icon>
							{{ codesTag }}
						</span>
					</ng-template>
				</kendo-multiselecttree>
			}
		  <span style='display: inline-flex; align-items: center;float: right; gap: 10px;' kendoTooltip>
			<button kendoButton (click)="eventsSubject.next()" *ngIf='(projectService.$currentProjectReport | async)?.updateIds?.length > 1 && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-APK"'
					[disabled]='analyticsService.floatConsumptionLoading'
					[style.background-color]="isOverview ? '#ffb500' : 'white'"
					themeColor='base' class="header-btn" [imageUrl]='"/assets/icons/newIcons/excelExport.svg"' title='Export Activities' [class.focusTabHeaderSizing]="isFocus">
			</button>
			<div kendoTooltip style="text-align: center;" *ngIf='currentProjectCompanyPermissions?.license === "ANALYTICS-BASIC-MPK" || currentProjectCompanyPermissions?.license === "ANALYTICS-BASIC-APK"'>
			  <div title='Upgrade to a Professional License to enable Exports'>
				<button kendoButton themeColor="base" class='header-btn' [imageUrl]='"/assets/icons/newIcons/excelExport.svg"' [disabled]='true' [style.background-color]="isOverview ? '#ffb500' : 'white'" [class.focusTabHeaderSizing]="isFocus"></button>
			  </div>
			</div>
			@if (isOverview) {
			  <button kendoButton themeColor="base" title="Go to source" [imageUrl]="'assets/icons/newIcons/noun-arrow-5544651.svg'" class='header-btn' (click)='projectService.goTo(5)' style="background-color: #ffb500;"></button>
			}
			<button *ngIf="!hideNotes" kendoButton themeColor="base" title="Add/Edit Notes" [imageUrl]="'assets/icons/newIcons/noun-notepad-6607257.svg'" class='header-btn overview-notes-icon' (click)='projectService.openNotesWindow({id: 5, name: "Float Consumption"})' [style.background-color]="isOverview ? '#ffb500' : 'white'"></button>
		  </span>
		</kendo-gridlayout-item>
	  </kendo-gridlayout>
	</kendo-gridlayout-item>
	@if (focusTabDisabledCode && !hasHistoricalData) {
	  <div class="warning-div" style="background-color: white; height: 150px;">
			No Float Data Available for {{focusTabSelectedActvCode[0]?.name}}.
	  </div>
	} @else {
	  <kendo-gridlayout-item [row]='2' [col]='1' style='padding: 5px 5px 5px 5px'>
		<kendo-gridlayout
		  [rows]="['100%']"
		  [cols]="['15%']"
		  [gap]="{ rows: 0, cols: 5 }"
		  class='grid'
		>
		  <kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-container'>
			<div [class.selected-button-avg]='selectedFloatConsumptionView === floatConsumptionView.averageFloat' (click)='changeFloatConsumptionView(floatConsumptionView.averageFloat)' class='grid-btn'>
			  <div><img [src]="isPrevAvgFloatGreater ? '/assets/ui/activity_type/downRedArrow.png' : '/assets/ui/activity_type/upGreenArrow.png'" [style.margin-top]="isPrevAvgFloatGreater ? '3px' : '2px'"><span> {{floatConsumptionView.averageFloat}}</span></div>
			  <div> {{cardActivitiesLabel.get(floatConsumptionView.averageFloat)}}</div>
			</div>
		  </kendo-gridlayout-item>
		  <kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-container'>
			<div [class.selected-button-negative]='selectedFloatConsumptionView === floatConsumptionView.negative' (click)='changeFloatConsumptionView(floatConsumptionView.negative)' class='grid-btn'>
			  <div>{{floatConsumptionView.negative}}</div>
			  <div> {{cardActivitiesLabel.get(floatConsumptionView.negative)}}</div>
			</div>
		  </kendo-gridlayout-item>
		  <kendo-gridlayout-item [row]='1' [col]='3' class='gridlayout-container'>
			<div [class.selected-button-zero]='selectedFloatConsumptionView === floatConsumptionView.criticalPathFloat' (click)='changeFloatConsumptionView(floatConsumptionView.criticalPathFloat)' class='grid-btn'>
			  <div>{{floatConsumptionView.criticalPathFloat}}</div>
			  <div> {{cardActivitiesLabel.get(floatConsumptionView.criticalPathFloat)}}</div>
			</div>
		  </kendo-gridlayout-item>
		  <kendo-gridlayout-item [row]='1' [col]='4' class='gridlayout-container'>
			<div [class.selected-button-week]='selectedFloatConsumptionView === floatConsumptionView.nearCriticalFloat' (click)='changeFloatConsumptionView(floatConsumptionView.nearCriticalFloat)' class='grid-btn'>
			  <div>{{floatConsumptionView.nearCriticalFloat}}</div>
			  <div> {{cardActivitiesLabel.get(floatConsumptionView.nearCriticalFloat)}}</div>
			</div>
		  </kendo-gridlayout-item>
		  <kendo-gridlayout-item [row]='1' [col]='5' class='gridlayout-container'>
			<div [class.selected-button-month]='selectedFloatConsumptionView === floatConsumptionView.monthFloat' (click)='changeFloatConsumptionView(floatConsumptionView.monthFloat)' class='grid-btn'>
			  <div>{{floatConsumptionView.monthFloat}}</div>
			  <div> {{cardActivitiesLabel.get(floatConsumptionView.monthFloat)}}</div>
			</div>
		  </kendo-gridlayout-item>
		  <kendo-gridlayout-item [row]='1' [col]='6' class='gridlayout-container'>
			<div [class.selected-button-large]='selectedFloatConsumptionView === floatConsumptionView.largeFloat' (click)='changeFloatConsumptionView(floatConsumptionView.largeFloat)' class='grid-btn'>
			  <div>{{floatConsumptionView.largeFloat}}</div>
			  <div> {{cardActivitiesLabel.get(floatConsumptionView.largeFloat)}}</div>
			</div>
		  </kendo-gridlayout-item>
		</kendo-gridlayout>
	  </kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='3' [col]='1'>
		  <div class='chart-container'>
			<kendo-chart style='height: 300px' (legendItemClick)="onLegendItemClick($event)">
			  <kendo-chart-legend
					position="bottom"
					orientation="horizontal"
					[title]="{ text: 'Float Category', font: '12px Muli, Helvetica Neue, Arial, sans-serif', position: 'bottom', margin: 0, padding: 0 }"
					[margin]="0"
					[padding]="0"
			  >
			  </kendo-chart-legend>
			  <kendo-chart-title text="Float Consumption Trending" [padding]='0' [margin]='0' [visible]="false"></kendo-chart-title>
			  <kendo-chart-value-axis>
					<kendo-chart-value-axis-item *ngIf="selectedFloatConsumptionView !== floatConsumptionView.averageFloat" pane="pane" [line]='{visible: false}' [title]="{ text: '% of Activities' }" [labels]="{ format: '{0}%'}">
					</kendo-chart-value-axis-item>
					<kendo-chart-value-axis-item *ngIf="selectedFloatConsumptionView === floatConsumptionView.averageFloat" pane="pane" [line]='{visible: false}' [title]="{ text: 'Average Float' }" [labels]="{ format: '{0}'}">
					</kendo-chart-value-axis-item>
			  </kendo-chart-value-axis>
				<kendo-chart-panes>
					<kendo-chart-pane name="pane" clip="false">
					</kendo-chart-pane>
			  </kendo-chart-panes>
			  <kendo-chart-category-axis>
					<kendo-chart-category-axis-item
						[categories]="categories"
						[crosshair]="{ color: '#bbbbbb', visible: true, width: 1 }"
						[labels]="{ rotation: 'auto' }"
						[majorGridLines]='{visible: false}'
					>
						<kendo-chart-category-axis-item-labels
						[position]="categoryLabelsPosition"
						[rotation]="'auto'"
						>
						</kendo-chart-category-axis-item-labels>
					</kendo-chart-category-axis-item>
			  </kendo-chart-category-axis>
			  <kendo-chart-tooltip [shared]='true'>
					<ng-template *ngIf="selectedFloatConsumptionView !== floatConsumptionView.averageFloat" kendoChartSeriesTooltipTemplate let-value="value" let-dataItem="dataItem" let-point="point">
						{{value | number : '1.0-1'}}%
					</ng-template>
					<ng-template *ngIf="selectedFloatConsumptionView === floatConsumptionView.averageFloat" kendoChartSeriesTooltipTemplate let-value="value" let-dataItem="dataItem" let-point="point">
						{{value | number: '1.0-1'}}
					</ng-template>
			  </kendo-chart-tooltip>
			  <kendo-chart-series>
					<kendo-chart-series-item
						*ngFor='let seriesLine of chartData'
						type="line"
						[style]="'smooth'"
						[data]="seriesLine.data"
						[name]='seriesLine.name'
						[color]="seriesLine.color"
						[visible]='FLOAT_VIEW_TO_SERIES.get(selectedFloatConsumptionView)?.includes(seriesLine.name)'
						[missingValues]='"gap"'
						[legendItem]="{type: 'line', markers: { visible: false, }, highlight: { visible: false }}"
					>
					</kendo-chart-series-item>
			  </kendo-chart-series>
			</kendo-chart>
		  </div>
		</kendo-gridlayout-item>
		@if (!focusTabDisabledCode) {
		  <kendo-gridlayout-item [row]='4' [col]='1'>
			<app-float-consumption-table-card
				[tableView]='selectedFloatConsumptionView'
				(selectedFloatConsumptionViewChange)='changeFloatConsumptionView($event)'
				(cardActivitiesSetChange)='setCardActivities($event)'
				(prevAvgFloatGreater)='prevAvgFloatGreater($event)'
				[exportExcel]='eventsSubject'
				[focusTabFloatHistoricalVals]="focusTabHistoricalVals"
				[focusTabActvCode]="focusTabActvCode"
				[focusTabTasks]="focusTabTasks"
				[focusTabPrevTasks]="focusTabPrevTasks"
				#gridCard
			></app-float-consumption-table-card>
			@if (isOverview && hasNotes) {
			  <app-overview-notes [componentId]="5"></app-overview-notes>
			}
		  </kendo-gridlayout-item>
		}
	}
  </kendo-gridlayout>
</div>
