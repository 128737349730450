import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	selector: '[formControlName][appMoneyMask]',
})
export class MoneyMaskDirective {
	constructor(public ngControl: NgControl) {}

	@HostListener('ngModelChange', ['$event'])
	onModelChange(event) {
		this.onInputChange(event, false);
	}

	@HostListener('keydown.backspace', ['$event'])
	keydownBackspace(event) {
		this.onInputChange(event.target.value, true);
	}

	onInputChange(event, backspace) {
		let newVal = event.replace(/[^0-9.]/g, '');
		newVal =
			newVal.length === 0
				? ''
				: '$' + new Intl.NumberFormat('en-us', { maximumFractionDigits: 12 }).format(Number(newVal));
		this.ngControl.valueAccessor.writeValue(newVal);
	}
}
