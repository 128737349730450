import { CompositeFilterDescriptor, FilterOperator, FilterDescriptor } from '@progress/kendo-data-query';
import { FilterItem } from '../components/portfolio/project-list/project-list/project-list.component';
import { EventEmitter } from '@angular/core';
import { QuickViewFilters } from '../models/Project';

export interface NamedFilterDescriptor extends FilterDescriptor {
	text?: string;
}
export interface NamedCompositeFilterDescriptor extends CompositeFilterDescriptor {
	filters: Array<NamedKendoFilter>;
	text?: string;
}

export type NamedKendoFilter = NamedFilterDescriptor | NamedCompositeFilterDescriptor;

export interface BottomLvlProjectFilter extends Omit<NamedCompositeFilterDescriptor, 'filters' | 'logic'> {
	logic: 'and' | 'or';
	filters: NamedFilterDescriptor[];
	text?: string;
}

export interface ProjectFieldFilter extends Omit<NamedCompositeFilterDescriptor, 'filters' | 'logic'> {
	filters: NamedFilterDescriptor[];
	logic: 'and' | 'or';
	text: string;
}

export type TopLvlProjectFilter = Omit<NamedCompositeFilterDescriptor, 'filters'> & { filters: ProjectFieldFilter[] };

export class ProjectsFilter extends EventEmitter implements TopLvlProjectFilter {
	private _filters: Array<ProjectFieldFilter> = [];
	public logic: 'or' | 'and';
	private _pauseEmissions: boolean = true;
	private filtersBeforePause: Array<ProjectFieldFilter> = [];

	constructor(defaultFilters?: TopLvlProjectFilter) {
		super();
		this.filters = defaultFilters?.filters || [];
		this.logic = defaultFilters?.logic || 'and';
		this._pauseEmissions = false;
	}

	public pauseEmissions(): void {
		this._pauseEmissions = true;
		this.filtersBeforePause = this._filters.slice();
		console.log('PAUSING');
	}

	public resumeEmissions(): void {
		console.log('RESUMING');
		if (!compareFilters(this.filtersBeforePause, this._filters)) {
			this.emit(this._filters);
		}
		this._pauseEmissions = false;
	}

	public get isPaused(): boolean {
		return this._pauseEmissions;
	}

	public set filters(newFilters: typeof this._filters) {
		const oldFilters = JSON.stringify(this._filters.slice());
		this._filters = newFilters || [];
		if (!this._pauseEmissions && !compareFilters(this._filters, JSON.parse(oldFilters))) {
			this.emit(this._filters);
		}
	}

	public get filters(): typeof this._filters {
		return this._filters;
	}

	public get filter(): CompositeFilterDescriptor {
		return {
			filters: this.filters,
			logic: this.logic,
		};
	}

	public get formFilters(): Record<string, FilterItem[]> {
		const items: Record<string, FilterItem[]> = {};
		for (const filter of this.filters) {
			items[filter.text] = (filter.filters || []).map((f) => ({
				text: f.text,
				value: f,
			}));
		}
		return items;
	}

	public setFilterDescriptor(text: string, newFilters: FilterItem[], logic: 'and' | 'or' = 'or'): void {
		const oldFilters = JSON.stringify(this._filters.slice());
		const rangeSliders = ['projectScore', 'progressScore', 'qcScore', 'predictabilityScore', 'completionVar'];
		for (let i = 0; i < this.filters.length; i++) {
			if (this.filters[i].text === text) {
				if (!newFilters?.length) {
					if (text === 'search') {
						this.filters.splice(i, 1);
					} else {
						this.filters[i].filters = [
							{
								text,
								operator: FilterOperator.IsNotNull,
								value: null,
								field: text,
							},
						];
					}
				} else {
					this.filters[i].filters = rangeSliders.includes(text)
						? newFilters
								.map((filter) => filter.value)
								.sort((a: NamedFilterDescriptor, b: NamedFilterDescriptor) => {
									if (typeof a.value === 'number' && typeof b.value === 'number') {
										if (a.value < b.value) {
											return -1;
										}
										if (a.value > b.value) {
											return 1;
										}
									}
									return 0;
								})
						: newFilters.map((filter) => filter.value);
					this.filters[i].logic = logic;
				}
				if (!this._pauseEmissions && !compareFilters(this._filters, JSON.parse(oldFilters))) {
					this.emit(this._filters);
				}
				return;
			}
		}
		if (newFilters?.length && text) {
			this.filters.push({
				text,
				filters: newFilters.map((filter) => filter.value),
				logic,
			});
			if (!this._pauseEmissions && !compareFilters(this._filters, JSON.parse(oldFilters))) {
				this.emit(this._filters);
			}
		}
	}

	public setFilterComposite(newFilter: ProjectFieldFilter): void {
		for (let i = 0; i < this.filters.length; i++) {
			if (this.filters[i].text === newFilter.text) {
				if (!newFilter?.filters?.length) {
					this.filters.splice(i, 1);
				} else {
					this.filters[i] = newFilter;
				}
				return;
			}
		}
		if (newFilter && newFilter.text) {
			this.filters.push(newFilter);
		}
	}

	public get uniqueFilterFields(): Set<string> {
		return new Set<string>(Object.keys(this.formFilters));
	}

	public mergeFilters(newFilters: ProjectFieldFilter[]): void {
		if (!newFilters) {
			return;
		}
		const existingFilters = this.filters;
		for (const filter of newFilters) {
			let foundExisting = false;
			for (let i = 0; i < existingFilters.length; i++) {
				const existingFilter = existingFilters[i];
				if (existingFilter.text === filter.text) {
					existingFilters[i].filters = filter.filters;
					existingFilters[i].logic = filter.logic;
					foundExisting = true;
					break;
				}
			}
			if (!foundExisting) {
				existingFilters.push(filter);
			}
		}
		this.filters = existingFilters;
	}
}

export const uniqueFilterFields = (filters: CompositeFilterDescriptor): Set<string> => {
	let fields = new Set<string>([]);
	if (!filters.filters) {
		return fields;
	}
	for (const filter of filters.filters) {
		if ('filters' in filter) {
			fields = new Set<string>([...fields, ...uniqueFilterFields(filter)]);
		} else {
			if (typeof filter.field === 'string') {
				fields.add(filter.field);
			}
		}
	}
	return fields;
};

export const compareFilters = (filterA: ProjectFieldFilter[], filterB: ProjectFieldFilter[]): boolean => {
	const sortedFiltersA = filterA?.sort((a, b) => a.text?.localeCompare(b.text));
	const sortedFiltersB = filterB?.sort((a, b) => a.text?.localeCompare(b.text));
	if (sortedFiltersA?.length !== sortedFiltersB?.length) {
		return false;
	}
	for (let i = 0; i < sortedFiltersA.length; i++) {
		const fieldFilterA = sortedFiltersA[i];
		const fieldFilterB = sortedFiltersB[i];
		if (
			fieldFilterA.text !== fieldFilterB.text ||
			fieldFilterA.logic !== fieldFilterB.logic ||
			fieldFilterA.filters?.length !== fieldFilterB.filters?.length
		) {
			return false;
		}
		if (!fieldFilterA.filters?.length) {
			continue;
		}
		const sortedFieldFilterFiltersA = fieldFilterA.filters?.sort((a, b) => a?.text?.localeCompare(b?.text));
		const sortedFieldFilterFiltersB = fieldFilterB.filters?.sort((a, b) => a?.text?.localeCompare(b?.text));
		for (let j = 0; j < sortedFieldFilterFiltersA.length; j++) {
			const fieldValueA = sortedFieldFilterFiltersA[j];
			const fieldValueB = sortedFieldFilterFiltersB[j];
			if (
				fieldValueA.text !== fieldValueB.text ||
				fieldValueA.value !== fieldValueB.value ||
				fieldValueA.operator !== fieldValueB.operator ||
				fieldValueA.field !== fieldValueB.field
			) {
				return false;
			}
		}
	}
	return true;
};

export const compareFilterItems = (filterA: FilterItem[], filterB: FilterItem[]): boolean => {
	const sortedFiltersA = filterA?.sort((a, b) => a.text?.localeCompare(b.text));
	const sortedFiltersB = filterB?.sort((a, b) => a.text?.localeCompare(b.text));
	if (sortedFiltersA?.length !== sortedFiltersB?.length) {
		return false;
	}
	for (let i = 0; i < sortedFiltersA.length; i++) {
		const fieldFilterA = sortedFiltersA[i];
		const fieldFilterB = sortedFiltersB[i];
		if (fieldFilterA.text !== fieldFilterB.text) {
			return false;
		}
		for (const [key, value] of Object.entries(fieldFilterA.value)) {
			if (fieldFilterB.value?.[key] !== value) {
				return false;
			}
		}
	}
	return true;
};

export const getFiltersFromValToRef = (
	reference: Array<FilterItem>,
	value: ProjectFieldFilter[],
	text: string
): FilterItem[] => {
	const eligibleFilter = value.find((filter) => filter.text === text)?.filters;
	if (!eligibleFilter?.length) {
		return [];
	}
	return reference.filter((ref) =>
		eligibleFilter.find((filter) => (text === 'clientId' ? ref.value.value === filter.value : ref.text === filter.text))
	);
};
