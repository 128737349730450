import * as Highcharts from 'highcharts';
export const monteCarloChartOptions: Highcharts.Options = {
	credits: {
		enabled: false,
	},
	title: {
		text: 'Monte Carlo Probability Distribution',
		style: {
			fontFamily: 'Muli, Helvetica Neue, Arial, sans-serif',
			fontWeight: 'normal',
			fontSize: '14',
			color: '#737373',
		},
		verticalAlign: 'top',
		x: 48,
	},
	xAxis: {
		title: {
			text: 'Projected Date',
			margin: 15,
			style: {
				fontFamily: 'Muli, Helvetica Neue, Arial, sans-serif',
				fontWeight: 'normal',
				fontSize: '14',
				color: '#737373',
			},
			x: -28,
		},
		labels: {
			format: '{value:%b %e, %Y}',
			rotation: 315,
			align: 'right',
		},
		crosshair: true,
		type: 'datetime',
		minTickInterval: 1000 * 60 * 60 * 24,
	},
	yAxis: [
		{
			title: {
				text: 'Hits',
				margin: 15,
				style: {
					fontFamily: 'Muli, Helvetica Neue, Arial, sans-serif',
					fontWeight: 'normal',
					fontSize: '14',
					color: '#737373',
				},
			},
			min: 0,
			labels: {
				style: {
					fontFamily: 'Muli, Helvetica Neue, Arial, sans-serif',
					fontWeight: 'normal',
					fontSize: '14',
					color: '#737373',
				},
			},
			gridLineWidth: 0,
		},
		{
			title: {
				text: 'Probability',
				margin: 15,
				style: {
					fontFamily: 'Muli, Helvetica Neue, Arial, sans-serif',
					fontWeight: 'normal',
					fontSize: '14',
					color: '#737373',
				},
			},
			plotLines: [
				{
					color: '#DF5353',
					value: 85,
					dashStyle: 'ShortDot',
					width: 2,
					zIndex: 5,
				},
			],
			tickPositions: [0, 20, 50, 85, 100],
			min: 0,
			max: 100,
			labels: {
				formatter(): string {
					return this.pos + '%';
				},
				style: {
					fontFamily: 'Muli, Helvetica Neue, Arial, sans-serif',
					fontWeight: 'normal',
					fontSize: '14',
					color: '#737373',
				},
			},
			opposite: true,
			alignTicks: false,
		},
	],
	chart: {
		type: 'spline',
		spacingLeft: 20,
		spacingTop: 5,
		spacingBottom: 5,
		//width: 710
		height: 430.5, //for option1 layout
		// height: 560 //for option2 layout
	},
	series: [],
	legend: {
		// enabled: false,
		//width: 100,
		itemWidth: 135,
		itemMarginTop: -5,
		itemStyle: {
			fontFamily: 'Muli, Helvetica Neue, Arial, sans-serif',
			fontWeight: 'normal',
			fontSize: '13',
			color: '#737373',
		},
		align: 'center',
		verticalAlign: 'bottom',
		// x: 59
		//y: -10
	},
	plotOptions: {
		series: {
			events: {
				legendItemClick: () => false,
			},
			marker: {
				enabled: false,
				fillColor: '#FFFFFF',
				lineWidth: 2,
				lineColor: null, // inherit from series
			},
		},
	},
	tooltip: {
		shared: true,
		style: {
			fontFamily: 'Muli, Helvetica Neue, Arial, sans-serif',
			fontWeight: 'normal',
			fontSize: '13',
		},
	},
};

export const performanceFactorScoreOptions: Highcharts.Options = {
	chart: {
		type: 'solidgauge',
		height: 240,
		marginTop: -10,
		backgroundColor: 'rgba(0,0,0,0)',
	},
	title: null,
	credits: {
		enabled: false,
	},
	tooltip: {
		enabled: false,
	},
	pane: {
		center: ['50%', '85%'],
		size: '140%',
		startAngle: -90,
		endAngle: 90,
		background: [
			{
				innerRadius: '60%',
				outerRadius: '100%',
				shape: 'arc',
			},
		],
	},
	yAxis: {
		stops: [
			[0.01, '#DF5353'], // red
			[0.5, '#DF5353'], // red
			[0.501, '#4fc931'], // green
			[0.849, '#4fc931'], // green
			[0.85, '#0059FF'], // blue
		],
		lineWidth: 0,
		tickWidth: 0,
		minorTickInterval: null,
		tickAmount: 2,
		title: {
			y: -70,
		},
		labels: {
			y: 16,
			formatter(): string {
				const label = this.axis.defaultLabelFormatter.call(this);
				return '';
			},
		},
		min: 0,
		max: 100,
	},
	plotOptions: {
		solidgauge: {
			dataLabels: {
				y: 5,
				borderWidth: 0,
				useHTML: true,
			},
		},
	},
	series: [],
};

export const p85HistoricalOptions: Highcharts.Options = {
	title: {
		text: 'P85 Historical Trend',
		style: {
			fontFamily: 'Muli, Helvetica Neue, Arial, sans-serif',
			fontWeight: 'normal',
			fontSize: '12',
			color: '#737373',
		},
	},
	credits: {
		enabled: false,
	},
	chart: {
		events: {},
		height: 240,
	},
	subtitle: {
		text: '',
	},

	yAxis: {
		title: {
			text: 'Behind ‎ ‎ ‎ ‎ ‎ ‎ ‎         ‎ ‎ ‎ ‎ ‎ ‎Ahead',
			style: {
				fontFamily: 'Muli, Helvetica Neue, Arial, sans-serif',
				fontWeight: 'normal',
				fontSize: '14',
				color: '#737373',
				float: 'left',
			},
		},
		labels: {
			formatter(): string {
				const label = this.axis.defaultLabelFormatter.call(this);
				if (this.value === 0) {
					return 'CCD';
				}
				return label; // + ' cd';
			},
		},
		plotLines: [
			{
				color: 'rgb(204, 214, 235)',
				width: 3,
				value: 0,
			},
		],
	},
	plotOptions: {
		column: {
			minPointLength: 3,
		},
		series: {
			events: {
				legendItemClick() {
					const gainLoses = ['Period Gain', 'Period Loss'];
					const isOnlyVisible = !this.chart.series.find(
						(series) => series.name !== this.name && series.visible && !gainLoses.includes(series.name)
					);
					this.chart.series.forEach((series) => {
						if (series.name !== this.name && !gainLoses.includes(series.name)) {
							series.setVisible(isOnlyVisible);
						} else {
							series.setVisible(true);
						}
						if (gainLoses.includes(series.name)) {
							series.setVisible(true);
						}
					});
					this.chart.redraw();
					return false;
				},
			},
			marker: {
				lineWidth: 2,
				lineColor: null, // inherit from series
			},
			point: {
				events: {},
			},
		},
	},
	tooltip: {
		formatter(): string {
			let index = 0;
			const yy = this.points.reduce(
				(s, point): string => {
					const dateSplit = s.split('&');
					let name = dateSplit[0] + '</b>';
					const date = '<b>' + dateSplit[1];
					if (dateSplit[0] === '<b>Update 0') {
						name = '<b>Baseline';
					}
					index = point.point.index;
					// console.log(point)
					if (point.series.name === 'Period Performance') {
						return (
							name + '<br/>' + date + '<br/>' + point.series.name + ': ' + '<b>' + Math.round(point.y) + ' cd' + '</b>'
						);
					} else if (point.series.name === 'Projected Completion Trend') {
						return (
							name +
							'<br/>' +
							date +
							'<br/>Projected Completion: ' +
							'<b>' +
							// @ts-ignore
							point.series.data[index]?.projectedDate +
							'</b>'
						);
					} else if (point.series.name === 'P85 Historical Trend') {
						// @ts-ignore
						return name + '<br/>' + date + '<br/>P85: ' + '<b>' + point.series.data[index].p85Date + '</b>';
					}
					return '';
				},
				'<b>' + this.x + '</b>'
			);
			const noBreaks = yy.split('<b></b><br/>');
			let actualYY = '';
			for (let j = 0; j < noBreaks.length; j++) {
				actualYY += noBreaks[j];
			}
			const removeUndefined = actualYY.split('undefined');
			let returnValue = '';
			for (let i = 0; i < removeUndefined.length; i++) {
				returnValue += removeUndefined[i];
			}
			return returnValue;
		},
		shared: true,
		style: {
			fontFamily: 'Muli, Helvetica Neue, Arial, sans-serif',
			fontWeight: 'normal',
			fontSize: '13',
		},
	},

	xAxis: {},

	legend: {
		layout: 'horizontal',
		align: 'center',
		verticalAlign: 'bottom',
		itemHiddenStyle: {},
		itemStyle: {
			fontFamily: 'Muli, Helvetica Neue, Arial, sans-serif',
			fontWeight: 'normal',
			fontSize: '13',
			color: '#737373',
		},
	},

	series: [],

	responsive: {
		rules: [
			{
				condition: {
					maxWidth: 500,
				},
				chartOptions: {
					legend: {
						layout: 'horizontal',
						align: 'center',
						verticalAlign: 'bottom',
					},
				},
			},
		],
	},
};
