import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipOpenComponent } from './tooltip-open/tooltip-open.component';
import { TooltipContentComponent } from './tooltip-content/tooltip-content.component';
import { WindowModule } from '@progress/kendo-angular-dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
	declarations: [TooltipContentComponent, TooltipOpenComponent],
	imports: [CommonModule, MatDialogModule, MatButtonModule, WindowModule],
	exports: [TooltipOpenComponent, TooltipContentComponent],
})
export class TooltipMenuModule {}
