import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PortfolioComponent } from 'components/portfolio/portfolio.component';
import { AnalyticsDashboardService } from '../../services/analytics/analytics.service';
import { CommonModule } from '@angular/common';

const routes: Routes = [
	{
		path: '',
		component: PortfolioComponent,
		resolve: {
			data: AnalyticsDashboardService,
		},
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes), CommonModule],
	exports: [RouterModule],
})
export class PortfolioRoutingModule {}
