<div class="component-container" [style.height]="!fromPerformanceTrendingComponent ? '430.5px' : '400px'">
	<kendo-dropdowntree
		kendoDropDownTreeExpandable
		style="margin-bottom: 5px"
		[kendoDropDownTreeHierarchyBinding]="activityCodeDropdownOptions"
		[textField]="'display'"
		[valueField]="'saveValue'"
		(valueChange)="updateSelectionTable($event)"
		[value]="savedSelectedActivityCodeType"
		childrenField="children"
		[filterable]="true"
	></kendo-dropdowntree>
  <div class="table-container">
		<kendo-grid
			[sortable]="true"
			[kendoGridBinding]="tablesCurrentCodes"
			id='pfTable'
			[rowClass]="rowCallback"
			(selectionChange)='onSelect($event)'
			[selectable]="selectableSettings"
			[kendoGridSelectBy]="mySelectionKey"
			[(selectedKeys)]='selectedKeys'
			[loading]='scheduleService.isLoading'
			[autoSize]="true"
		>
			<ng-template kendoGridNoRecordsTemplate>
				<div *ngIf='!scheduleService.isLoading'>
					No activity code or WBS selected
				</div>
			</ng-template>
			<kendo-grid-checkbox-column [width]="20" [showSelectAll]="true">
			</kendo-grid-checkbox-column>
			<kendo-grid-column
				field="activityCode"
				title="Code"
				[width]="95"
			>
				<ng-template kendoGridCellTemplate let-dataItem="dataItem">
					<span style="display: flex; flex-direction: column; text-align: center">{{dataItem['activityCode']}}</span>
				</ng-template>
			</kendo-grid-column>
			<kendo-grid-column field="description" title='Description' [autoSize]="true">
				<ng-template kendoGridCellTemplate let-dataItem="dataItem">
					<span style="display: flex; flex-direction: column; text-align: center">{{dataItem['description']}}</span>
				</ng-template>
			</kendo-grid-column>
			<kendo-grid-column field="pf" title='Performance' [width]="100">
				<ng-template kendoGridCellTemplate let-dataItem="dataItem">
					<span style="display: flex; flex-direction: column; text-align: center">{{ (dataItem.pf * 100) | number: '1.0-0' }}%</span>
        </ng-template>
			</kendo-grid-column>
		</kendo-grid>
  </div>

  <div style="text-align: center; padding-top: 4px;">
    {{selectedActivityCodes.length || selectedWbs.length}} {{tablesCurrentCodes?.length ? '/ ' + tablesCurrentCodes.length: ''}} {{selectedActivityCodes.length ? 'Activity Codes' : selectedWbs.length ?  'WBS' : ''}} Selected
  </div>


</div>
