import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	NgZone,
	OnInit,
	Output,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { addDays, differenceInCalendarDays, isValid, max, min, setHours } from 'date-fns';
import { take } from 'rxjs/operators';
import {
	CellClickEvent,
	ExcelExportEvent,
	GridComponent,
	GridDataResult,
	PageChangeEvent,
	RowArgs,
	RowClassArgs,
} from '@progress/kendo-angular-grid';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { ProjectInterface } from '../../../../models/Project';
import { Activity, dayOfWeek } from '@rhinoworks/xer-parse';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProjectDashboardService } from '../../../../services/project/project.service';
import { UpdateInterface } from '../../../../models/Update/Task';
import { ProjectReportInterface } from '@rhinoworks/analytics-calculations';
import { formatExcelExport } from '../../../../util/excel';
import { cleanDateUTC } from '../../../../util/pipes/date.pipe';
import { AnalyticsDashboardService } from '../../../../services/analytics/analytics.service';

export interface DelayTask {
	schedule: string;
	dataDate: Date;
	currentCompletion: Date;
	updateDelta: number;
	progressImpact: number;
	progressDate: Date;
	logicImpact: number;
	logicDate: Date;
	updateIndex?: number;
	isBaseline?: boolean;
}

@Component({
	selector: 'app-schedule-delays',
	templateUrl: './schedule-delays.component.html',
	styleUrls: ['./schedule-delays.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ScheduleDelaysComponent implements OnInit, AfterViewInit {
	@Input() projectInfo: ProjectInterface;
	@Input() pageSize = 4;
	@Input() hideSummary: boolean;
	delayList: DelayTask[] = [];
	@Input() exportEvent: Observable<void>;
	@Input() clearGridSelection = new BehaviorSubject<boolean>(false);
	@Input() previousVariance: number;
	@Output() activityClicked: EventEmitter<Activity> = new EventEmitter<Activity>();
	@ViewChild('schedDelayGrid')
	public grid: GridComponent;
	public skip = 0;
	public gridView: GridDataResult;
	constructor(
		private ngZone: NgZone,
		public scheduleStorage: ScheduleStorageService,
		public projectService: ProjectDashboardService,
		public analyticsService: AnalyticsDashboardService
	) {
		console.log('delay constructor');
	}
	selectedDelayTask: DelayTask = null;

	ngOnInit(): void {
		this.loadItems();

		this.clearGridSelection.subscribe((val) => {
			this.selectedDelayTask = null;
		});

		this.projectService.$currentProjectReport.subscribe((report) => {
			if (!report?.calculationFieldsHistorical) {
				return;
			}
			this.initGraph(report as unknown as ProjectReportInterface, this.scheduleStorage.$allUpdates.value);
		});
		this.scheduleStorage.$allUpdates.subscribe((updates) => {
			if (!updates.length) {
				this.initGraph(this.projectService.$currentProjectReport.value as unknown as ProjectReportInterface, updates);
			}
		});
	}

	public ngAfterViewInit(): void {
		this.fitColumns();
		this.exportEvent.subscribe(() => {
			console.log(this.grid);
			this.grid.saveAsExcel();
		});
	}

	public onExcelExport(e: ExcelExportEvent): void {
		e.preventDefault();
		formatExcelExport(
			e,
			'Schedule Impact Analysis_' +
				this.projectService.$currentProjectReport.value.project.name +
				'_' +
				this.analyticsService.yyyymmdd() +
				'.xlsx',
			this.projectService.$currentProjectReport.value.project.name,
			'Schedule Impact Analysis Data Export',
			false,
			this.projectService.$currentProjectReport.value.project.name +
				'\nUpdate ' +
				(this.projectService.$currentProjectReport.value.updateIds.length - 1) +
				' - ' +
				cleanDateUTC(new Date(), 'MMMM d, yyyy'),
			'F1'
		);
		console.log(e.workbook);
	}

	public initGraph(report: ProjectReportInterface, updates: UpdateInterface[]) {
		if (
			!report ||
			(!report.projectCompletionTrend?.projectCompletionTrendArray && report.updateIds.length !== updates.length) ||
			!report.progressDelayHistorical
		) {
			return;
		}
		const delays: DelayTask[] = [];
		let prevFinMileCode: string = null;
		let prevUpdateCurrentCompletion: Date = null;
		for (let i = 0; i < report.updateIds.length; i++) {
			const update = updates[i];
			const updateCurrentCompletion = new Date(
				update?.finishMilestone?.act_end_date ||
					update?.finishMilestone?.early_end_date ||
					report.projectCompletionTrend.projectCompletionTrendArray[i].currentCompletion
			);
			const isUpdate = prevUpdateCurrentCompletion;
			const dataDateFromUpdate: Date = new Date(
				update?.dataDate || report.projectCompletionTrend.projectCompletionTrendArray[i].dataDate
			);
			const dataDate: Date = new Date(
				dataDateFromUpdate.getUTCFullYear(),
				dataDateFromUpdate.getUTCMonth(),
				dataDateFromUpdate.getUTCDate(),
				dataDateFromUpdate.getUTCHours()
			);
			const delay: DelayTask = {
				schedule: i === 0 ? 'Baseline' : `Update ${i}`,
				dataDate,
				currentCompletion: updateCurrentCompletion,
				updateDelta: isUpdate ? differenceInCalendarDays(prevUpdateCurrentCompletion, updateCurrentCompletion) : 0,
				progressImpact: 0,
				progressDate: updateCurrentCompletion,
				logicDate: updateCurrentCompletion,
				logicImpact: 0,
				updateIndex: i,
				isBaseline: !isUpdate,
			};
			if (isUpdate) {
				let progressDelay: number | Date = report.progressDelayHistorical[i - 1];
				//probably not the best way to check this -RS
				if (progressDelay === null) {
					progressDelay = delays[i - 1]?.currentCompletion;
				}
				delay.progressImpact = differenceInCalendarDays(prevUpdateCurrentCompletion, new Date(progressDelay));
				delay.progressDate = new Date(progressDelay);
				delay.logicImpact = delay.updateDelta - delay.progressImpact;
				delay.logicDate = addDays(prevUpdateCurrentCompletion, -delay.logicImpact);
			}
			prevFinMileCode = update?.finishMilestone.task_code || prevFinMileCode;
			prevUpdateCurrentCompletion = updateCurrentCompletion;
			delays.push(delay);
		}
		this.delayList = delays;
	}

	public onDataStateChange(): void {
		this.fitColumns();
	}

	private fitColumns(): void {
		this.ngZone.onStable
			.asObservable()
			.pipe(take(1))
			.subscribe(() => {
				this.grid.autoFitColumns();
			});
	}

	public pageChange(event: PageChangeEvent): void {
		this.skip = event.skip;
		this.loadItems();
	}

	private loadItems(): void {
		if (!this.delayList) {
			return;
		}
		this.gridView = {
			data: this.delayList.slice(this.skip, this.skip + this.pageSize),
			total: this.delayList.length,
		};
	}

	public rowCallback = (context: RowClassArgs) =>
		context.dataItem === this.selectedDelayTask ? { selectedDelayRow: true } : { delayRow: true };
}
export const msToDaysDelta = (ms: number): number => (ms - BASE_DATE_MS) / DAY_MS;
export const msToDelta = (ms: number | Date): number => {
	const date = new Date(ms);
	const delta = msToDaysDelta(date.getTime());
	const floored = Math.floor(delta);
	return delta % 1 > 0.95 ? Math.ceil(delta) : delta % 1 < 0.5 ? floored + 0.25 : floored + 0.5;
};
export const BASE_DATE = new Date('12/30/1899 0:00');
export const BASE_DATE_MS = +BASE_DATE;
export const DAY_MS = 1000 * 60 * 60 * 24;

export const countEligibleDays = (S: number, F: number, A: number[], C: Set<number>): number => {
	// Calculate total days between S and F
	S = Math.round(S);
	F = Math.round(F);

	let eligibleDaysCount = 0;

	for (let day = S; day < F; day++) {
		// Check if day is in A
		if (A.includes(day)) {
			continue;
		}

		// Check if day is an excluded day of the week
		const dayWeek = Math.floor((day - 1) % 7);
		if (C.has(dayWeek)) {
			continue;
		}

		eligibleDaysCount++;
	}

	return eligibleDaysCount;
};

const actualProgressMade = (actv: Activity, prev: Activity): number => {
	if (!prev) {
		return 0;
	}
	return (
		(actv.targetDurationHrs - actv.remainingDurationHrs - (prev.targetDurationHrs - prev.remainingDurationHrs)) / 8
	);
};

const workableDaysSinceLastUpdate = (actv: Activity, prev: Activity): number => {
	if (!prev || prev.finish || prev.targetStart >= actv._activity.project.lastRecalculationDate) {
		return 0;
	}
	const start = Math.max(+actv.start, +prev._activity.project.lastRecalculationDate);
	const finish = actv._activity.project.lastRecalculationDate;
	const nonWorkDays = [...actv.calendar.deltaExceptions.entries()].filter(([, d]) => d.duration === 0).map(([k]) => k);
	const weekends = new Set<number>([]);
	for (let i = 0; i < actv.calendar.daysOfWeek.length; i++) {
		if (actv.calendar.daysOfWeek[i].duration === 0) {
			weekends.add(i);
		}
	}
	return countEligibleDays(msToDelta(start), msToDelta(finish), nonWorkDays, weekends);
};

export const expectedProgressMade = (actv: Activity, prev: Activity): number => {
	if (prev.targetStart > actv._activity.project.lastRecalculationDate) {
		return 0;
	}
	const start = max([actv.start, prev._activity.project.lastRecalculationDate]);
	const finish = min([prev.targetFinish, actv._activity.project.lastRecalculationDate]);
	const nonWorkDays = [...actv.calendar.deltaExceptions.entries()].filter(([, d]) => d.duration === 0).map(([k]) => k);
	const weekends = new Set<number>([]);
	for (let i = 0; i < actv.calendar.daysOfWeek.length; i++) {
		if (actv.calendar.daysOfWeek[i].duration === 0) {
			weekends.add(i);
		}
	}
	return countEligibleDays(msToDelta(start), msToDelta(finish), nonWorkDays, weekends);
};

const earlyDelayedBy = (actv: Activity, prev: Activity): number => {
	const nonWorkDays = [...actv.calendar.deltaExceptions.entries()].filter(([, d]) => d.duration === 0).map(([k]) => k);
	const weekends = new Set<number>([]);
	for (let i = 0; i < actv.calendar.daysOfWeek.length; i++) {
		if (actv.calendar.daysOfWeek[i].duration === 0) {
			weekends.add(i);
		}
	}
	return countEligibleDays(
		msToDelta(prev._activity.earlyFinish),
		msToDelta(actv._activity.earlyFinish),
		nonWorkDays,
		weekends
	);
};

export const addDaysExcluding = (S: number, N: number, A: number[], C: Set<number>): number => {
	// Adjust N for the days of the week in C
	const startingDayOfWeek = dayOfWeek(S);
	if (C.size === 7 || (N === 0 && !C.has(S) && !A.includes(Math.floor(S)))) {
		return S;
	}
	//console.log({ startingDayOfWeek });
	let initialN;
	const roundS = Math.round(S);
	let lastCheckedDay = roundS;
	let AIndex = N > 0 ? 0 : A.length - 1; // Adjust the starting index based on direction

	do {
		initialN = N;

		if (N > 0) {
			// Moving forward in days
			for (let i = lastCheckedDay; i <= roundS + initialN; i++) {
				const dayOfWeeek = (startingDayOfWeek + (i - roundS)) % 7;

				// Check for days in A
				while (AIndex < A.length && A[AIndex] < i) {
					AIndex++;
				}
				if (AIndex < A.length && A[AIndex] === i) {
					N++;
					AIndex++;
				}

				// Check for days of the week in C
				if (C.has(dayOfWeeek)) {
					N++;
				}
			}
		} else {
			// Moving backward in days
			for (let i = lastCheckedDay; i >= roundS + initialN; i--) {
				let dayOfWeeek = (startingDayOfWeek + (i - roundS)) % 7;
				if (dayOfWeeek < 0) {
					dayOfWeeek += 7;
				} // Adjust for negative modulo

				// Check for days in A
				while (AIndex >= 0 && A[AIndex] > i) {
					AIndex--;
				}
				if (AIndex >= 0 && A[AIndex] === i) {
					N--;
					AIndex--;
				}

				// Check for days of the week in C
				if (C.has(dayOfWeeek)) {
					N--;
				}
			}
		}

		if (N > 0) {
			lastCheckedDay = roundS + initialN + 1;
		} else {
			lastCheckedDay = roundS + initialN - 1;
		}
	} while (N !== initialN); // Continue until N stabilizes

	return S + N;
};

const addDaysToSchedule = (actv: Activity, start: number, days: number, toStart: boolean = false): number => {
	if (days === 0 && !toStart) {
		return start;
	}
	const holidays = [...actv.calendar.deltaExceptions.entries()].filter(([, d]) => d.duration === 0).map(([k]) => k);
	const weekends = new Set<number>([]);
	for (let i = 0; i < actv.calendar.daysOfWeek.length; i++) {
		if (actv.calendar.daysOfWeek[i].duration === 0) {
			weekends.add(i);
		}
	}
	let n = days;
	let startDelta = start;
	const workingFirstDay = !holidays.includes(Math.floor(start)) && !weekends.has(dayOfWeek(start));
	if (start % 1 < 0.5 && !toStart && days > 0) {
		startDelta = Math.floor(start) + 0.5;
		if (workingFirstDay) {
			n--;
		}
	}
	if (start % 1 > 0.5 && toStart && days < 0) {
		startDelta = Math.floor(start) + 0.25;
		if (workingFirstDay && days) {
			n++;
		}
	}
	return addDaysExcluding(startDelta, n, holidays, weekends);
};

export const deltaToDateMs = (delta: number): number => DAY_MS * delta + BASE_DATE_MS;

export const toMs = (value: number | Date): number => {
	value = +value;
	if (value > 100000) {
		return value;
	}
	let d: Date | number = deltaToDateMs(Math.floor(value));
	if (value % 1 > 0 && value % 1 < 0.5) {
		d = setHours(d, 8);
	} else if (value % 1 > 0.5) {
		d = setHours(d, 17);
	}

	return +d;
};
