import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { hasObjChanged } from '../../../../util/projects';
import {
	FloatConsumptionTableCardComponent,
	FloatConsumptionView,
} from './float-consumption-table-card/float-consumption-table-card.component';
import { AxisLabelsPosition, LegendItemClickEvent } from '@progress/kendo-angular-charts';
import { CurrentProjectReport, ProjectDashboardService } from '../../../../services/project/project.service';
import { RestService } from '../../../../services/common/rest.service';
import { NavigationBarStorageService } from '../../../../services/common/navigation-bar-storage.service';
import { ProfileCompanyPermission } from '../../../../models/auth/account-user';
import { caretAltDownIcon, fileExcelIcon, SVGIcon } from '@progress/kendo-svg-icons';
import { AnalyticsDashboardService } from '../../../../services/analytics/analytics.service';
import {
	ActvCodeFilterItem,
	ActvCompletionActv,
	SubCodeFilterItem,
} from '../activity-completion/activity-completion.component';
import { dataFromXer } from '../../../../util/tasks';
import {
	Activity,
	IActivityCode,
	IWBS,
	Xer,
	XerActivity,
	XerActivityCode,
	XerActivityType,
	XerData,
	XerTaskActivity,
} from '@rhinoworks/xer-parse';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { SlimmedTaskCommon, UpdateInterface } from '../../../../models/Update/Task';
import { TotalFloatIndexArgs } from '@rhinoworks/analytics-calculations';
import { haveCommonItem } from '../../../../util/strings';
import { union } from 'lodash';
import { TreeItem } from '@progress/kendo-angular-treeview';
import { format } from 'date-fns';
import { wbsDisplaySave } from '../../risk/risk-performance-factor/risk-performance-factor/pf-table-dropdown/pf-table-dropdown.component';

interface FloatHistoricalChartData {
	data: number[];
	name: string;
	color: string;
}
type DisablableWbs = IWBS & { disabled?: boolean; parentWbs: DisablableWbs; childrenWbs: DisablableWbs[] };

@Component({
	selector: 'app-float-consumption',
	templateUrl: './float-consumption.component.html',
	styleUrls: ['./float-consumption.component.scss'],
})
export class FloatConsumptionComponent implements OnInit {
	public svgExcel: SVGIcon = fileExcelIcon;
	@Input() visualizer: boolean = false;
	@Input() isOverview: boolean = false;
	@Input() isFocus: boolean = false;
	@Input() hideNotes: boolean = false;
	@Input() focusTabDisabledCode: boolean = false;
	@Input() floatHistorical: TotalFloatIndexArgs[];
	@Input() focusTabSelectedActvCode: SubCodeFilterItem[];
	categoryLabelsPosition: AxisLabelsPosition = 'start';
	selectedFloatConsumptionView: FloatConsumptionView = FloatConsumptionView.averageFloat;
	cardActivitiesLabel = new Map<FloatConsumptionView, string>([]);
	floatConsumptionView = FloatConsumptionView;
	isPrevAvgFloatGreater: boolean = false;
	isPrevNegFloatGreater: boolean = false;
	isPrevCritPathFloatGreater: boolean = false;
	isPrevNearCritFloatGreater: boolean = false;
	isPrevMonthFloatGreater: boolean = false;
	isPrevLargeFloatGreater: boolean = false;
	isPrevAvgFloatEqual: boolean = false;
	isPrevNegFloatEqual: boolean = false;
	isPrevCritPathFloatEqual: boolean = false;
	isPrevNearCritFloatEqual: boolean = false;
	isPrevMonthFloatEqual: boolean = false;
	isPrevLargeFloatEqual: boolean = false;

	totalActivityCodes = [];
	public selectedActivityCodes: { shortName: string; codeName: string; parentName: string }[] = [];
	allActivityCodes: ActvCodeFilterItem[] = [];
	allSubCodes;
	codesTag: string = '';
	icons = {
		caretDown: caretAltDownIcon,
	};
	loading: boolean = false;

	eventsSubject: Subject<void> = new Subject<void>();
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	categories: string[] = [];
	chartData: FloatHistoricalChartData[] = [];
	FLOAT_VIEW_TO_SERIES = new Map<FloatConsumptionView, string[]>([
		[FloatConsumptionView.averageFloat, ['Avg']],
		[FloatConsumptionView.negative, ['<0']],
		[FloatConsumptionView.criticalPathFloat, ['0']],
		[FloatConsumptionView.nearCriticalFloat, ['1-7']],
		[FloatConsumptionView.monthFloat, ['8-30']],
		[FloatConsumptionView.largeFloat, ['30+']],
	]);
	SERIES_TO_FLOAT_VIEW = new Map<string, FloatConsumptionView>([
		['Avg', FloatConsumptionView.averageFloat],
		['<0', FloatConsumptionView.negative],
		['0', FloatConsumptionView.criticalPathFloat],
		['1-7', FloatConsumptionView.nearCriticalFloat],
		['8-30', FloatConsumptionView.monthFloat],
		['30+', FloatConsumptionView.largeFloat],
	]);
	@ViewChild('gridCard') gridCard: FloatConsumptionTableCardComponent;
	currentProjectCompanyPermissions: ProfileCompanyPermission = null;
	hasNotes: boolean = false;
	historicalVals: Array<TotalFloatIndexArgs & { updateId?: string }> = [];
	historicalValsPct: Array<TotalFloatIndexArgs> = [];
	actvCodes: XerActivityCode[] = [];
	focusTabActvCode: XerActivityCode[];
	focusTabTasks: XerActivity[];
	hasHistoricalData: boolean = false;
	doSearch: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	allWbs: Array<DisablableWbs> = [];
	allWbsMap = new Map<number, DisablableWbs>([]);
	@Input() selectedWbs: Array<DisablableWbs> = [];
	expandedWbs: Array<number> = [];
	flatSelectedWbs = new Set<DisablableWbs>([]);
	wbsTag = '';
	tableTasks = new Map<string, Activity>([]);
	prevTableTasks = new Map<string, Activity>([]);

	constructor(
		public projectService: ProjectDashboardService,
		public restService: RestService,
		public navBarStorage: NavigationBarStorageService,
		public analyticsService: AnalyticsDashboardService,
		public storage: ScheduleStorageService
	) {
		this.contentOfLabels = this.contentOfLabels.bind(this);
	}

	ngOnInit(): void {
		combineLatest([this.projectService.$currentProjectReport.pipe(debounceTime(500)), this.storage.$allUpdates])
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(([report, updates]) => {
				// If report.floatHistorical is missing, no need to proceed
				if (!report?.floatHistorical) {
					return;
				}

				// Handle company permissions, if needed
				if (report?.project?.company) {
					this.currentProjectCompanyPermissions = this.navBarStorage.companyPermissionMap.get(report.project.company);
				}

				// Call updateChartData only if updates match what's expected in the report
				if (updates.length === report.updateIds.length) {
					this.updateChartData(updates, { ...report, floatHistorical: report.floatHistorical });
				}
			});
		this.projectService.$currentProjectData.subscribe((val) => {
			if (val) {
				const savedNotes = val.componentNotes?.find((n) => n.id === 5)?.notes;
				this.hasNotes = savedNotes?.length && savedNotes[savedNotes?.length - 1]?.note !== '';
			}
		});
		const flattened = new Set<DisablableWbs>([]);
		function addToFlat(wbs: DisablableWbs) {
			flattened.add(wbs);
			wbs.childrenWbs?.forEach(addToFlat);
		}
		this.selectedWbs?.forEach(addToFlat);
		this.flatSelectedWbs = flattened;
	}

	OnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	async updateChartData(
		updates: UpdateInterface[],
		report: CurrentProjectReport & { floatHistorical: TotalFloatIndexArgs[] }
	) {
		if (!updates || !updates?.length) {
			return;
		}

		// Grab historical graph data
		const focusTabCodeHistoricalVals: Array<TotalFloatIndexArgs & { updateId?: string }> =
			this.focusTabSelectedActvCode?.length || this.selectedWbs?.length ? [] : report.floatHistorical;
		function floatTotalCount(floats: TotalFloatIndexArgs): number {
			if (!floats) {
				return 0;
			}
			return floats.floatLarge + floats.floatMonth + floats.floatNegative + floats.floatNone + floats.floatWeek;
		}
		function floatTotalPct(floats: TotalFloatIndexArgs, totalCount: number): TotalFloatIndexArgs {
			return {
				floatAverage: 0,
				floatLarge: (floats.floatLarge * 100) / totalCount,
				floatMonth: (floats.floatMonth * 100) / totalCount,
				floatNegative: (floats.floatNegative * 100) / totalCount,
				floatNone: (floats.floatNone * 100) / totalCount,
				floatWeek: (floats.floatWeek * 100) / totalCount,
			};
		}
		const xer = await this.storage.grabUpdateXer(updates[updates.length - 1]?._id);
		const prevXer = updates?.length >= 2 ? await this.storage.grabUpdateXer(updates[updates.length - 2]?._id) : null;
		if (report?.actvCodeFloatHistorical && this.focusTabSelectedActvCode?.length > 0) {
			for (let i = 0; i < updates?.length; i++) {
				for (let j = 0; j < this.focusTabSelectedActvCode?.length; j++) {
					const updateFloatHistoricalByCode: Record<string, TotalFloatIndexArgs> = report.actvCodeFloatHistorical[i];
					const recordKey: string =
						this.focusTabSelectedActvCode[j]?.codeName + '%--//--%' + this.focusTabSelectedActvCode[j]?.shortName;
					const updateVal: TotalFloatIndexArgs & { updateId?: string } = updateFloatHistoricalByCode[recordKey];
					if (updateVal) {
						updateVal.updateId = report.updateIds[i];
						focusTabCodeHistoricalVals.push(updateVal);
					} else {
						focusTabCodeHistoricalVals.push({
							floatAverage: null,
							floatLarge: null,
							floatMonth: null,
							floatNegative: null,
							floatNone: null,
							floatWeek: null,
							updateId: report.updateIds[i],
						});
					}
				}
			}
		} else if (report?.wbsFloatHistorical && this.flatSelectedWbs.size > 0) {
			for (let i = 0; i < updates?.length; i++) {
				const updateCounts: TotalFloatIndexArgs = {
					floatAverage: 0,
					floatLarge: 0,
					floatMonth: 0,
					floatNegative: 0,
					floatNone: 0,
					floatWeek: 0,
				};
				const arrFlat = Array.from(this.flatSelectedWbs);
				const totalUpdateSelectedCount = arrFlat.reduce(
					(acc, curr) =>
						acc +
						(report.wbsFloatHistorical[i][wbsDisplaySave(curr)]
							? floatTotalCount(report.wbsFloatHistorical[i][wbsDisplaySave(curr)])
							: 0),
					0
				);
				for (let j = 0; j < arrFlat.length; j++) {
					const updateFloatHistoricalByWbs: Record<string, TotalFloatIndexArgs> = report.wbsFloatHistorical[i];
					const recordKey: string = wbsDisplaySave(arrFlat[j]);
					const updateVal: TotalFloatIndexArgs & { updateId?: string } = updateFloatHistoricalByWbs[recordKey];
					if (updateVal) {
						for (const [key, val] of Object.entries(updateVal)) {
							if (key === 'floatAverage') {
								const portion = floatTotalCount(updateVal) / (totalUpdateSelectedCount || 1);
								updateCounts.floatAverage += val * portion;
							} else {
								updateCounts[key] += val;
							}
						}
					}
				}
				focusTabCodeHistoricalVals.push(updateCounts);
			}
		}
		this.historicalVals = focusTabCodeHistoricalVals;
		this.historicalValsPct = focusTabCodeHistoricalVals.map((floats) => floatTotalPct(floats, floatTotalCount(floats)));
		const selectedActivityCodes = new Set<string>(this.selectedActivityCodes.map((item) => item.shortName));
		const prevUpdateXer: Xer =
			updates?.length > 1 ? await this.storage.grabUpdateXer(updates[updates.length - 2]?._id) : null;

		// Update filters
		this.allWbsMap = new Map<number, DisablableWbs>(
			Array.from(xer.wbs.entries()).map(([id, wbs]) => [
				id,
				{
					...wbs,
					disabled: false,
				},
			])
		);
		for (const wbs of this.allWbsMap.values()) {
			if (wbs.parentWbs) {
				wbs.parentWbs = this.allWbsMap.get(wbs.parentWbs.id);
			}
			if (wbs.childrenWbs) {
				wbs.childrenWbs = wbs.childrenWbsIds.map((id) => this.allWbsMap.get(id));
			}
		}
		this.allWbs = Array.from(this.allWbsMap.values()).filter((wbs) => !wbs.parentWbs);
		this.expandedWbs = [this.allWbs[0].id];
		const currTaskActv = xer.taskActivities;
		const currActvsByTask: Record<number, Array<XerTaskActivity>> = {};
		for (const ta of currTaskActv) {
			if (!currActvsByTask[ta.task_id]) {
				currActvsByTask[ta.task_id] = [];
			}
			currActvsByTask[ta.task_id].push(ta);
		}
		const currAllTasks = xer.sortedActivities;
		const prevAllTasks = prevXer === null ? null : prevXer.sortedActivities;
		this.allActivityCodes = this.generateActivityCodeFilter(xer);
		const allSubCodes = [];
		this.allActivityCodes.forEach((code) => {
			code.subCodes.forEach((subCode) => {
				const subcodeColumn = {
					title: subCode.name,
					shortName: subCode.shortName,
				};

				allSubCodes.push(subcodeColumn);
			});
		});
		this.allSubCodes = allSubCodes;

		const currShortCodes = new Map<number, string[]>(
			currAllTasks.map((task) => [task.id, Array.from(task.activityCodes).map((ac) => ac.shortName)])
		);
		const selectedWbs = new Set<number>(this.selectedWbs.map((wbs) => wbs.id));
		function hasSelectedWbs(wbs: DisablableWbs): boolean {
			const hasSelf = selectedWbs.has(wbs.id);
			if (hasSelf) {
				return true;
			}
			if (!wbs.parentWbs) {
				return false;
			}
			return hasSelectedWbs(wbs.parentWbs);
		}

		// Filter tasks
		const tasks = new Map<string, Activity>(
			currAllTasks
				.filter(
					(task) =>
						task._activity.taskType !== 'TT_LOE' &&
						(!selectedActivityCodes.size ||
							haveCommonItem(new Set(currShortCodes.get(task.id)), selectedActivityCodes)) &&
						(!selectedWbs.size || hasSelectedWbs(task._activity.wbs))
				)
				.map((task) => [task.code, task])
		);
		this.tableTasks = tasks;
		const prevTasks = new Map<string, Activity>(prevAllTasks.map((task) => [task.code, task]));
		this.prevTableTasks = prevTasks;

		let totalActivityCodes: IActivityCode[] = [];
		const usedWbs = new Set<number>();
		for (const [key, value] of tasks.entries()) {
			totalActivityCodes = [...value?.activityCodes, ...totalActivityCodes];
			usedWbs.add(value._activity.wbsId);
		}
		function wbsHasActvs(wbs: DisablableWbs): boolean {
			wbs.childrenWbs?.forEach((child) => {
				if (wbsHasActvs(child)) {
					usedWbs.add(child.id);
					usedWbs.add(wbs.id);
				}
			});
			wbs.disabled = !usedWbs.has(wbs.id);
			return usedWbs.has(wbs.id);
		}
		for (const wbs of this.allWbsMap.values()) {
			wbsHasActvs(wbs);
		}
		this.totalActivityCodes = totalActivityCodes;
		const categories: string[] = (this.storage.$allUpdates.value || []).map((a, index) => {
			const dataDateFromUpdate: Date = new Date(
				a?.dataDate || report?.projectCompletionTrend?.projectCompletionTrendArray?.[index]?.dataDate
			);
			const dateString: string = dataDateFromUpdate ? format(dataDateFromUpdate, 'MMM dd, yyyy') : '';
			const updateName: string = index === 0 ? 'Baseline' : `Update ${index}`;
			return (dateString === '' ? updateName : updateName + '\n' + dateString) + (a?.baseline ? ' ®' : '');
		});
		const floatHistorical: Array<TotalFloatIndexArgs & { updateId?: string }> =
			this.focusTabSelectedActvCode?.length || this.selectedWbs?.length
				? focusTabCodeHistoricalVals
				: this.floatHistorical || report.floatHistorical || [];
		this.hasHistoricalData = floatHistorical?.length > 0;
		const totalNumWithFloats: number[] = floatHistorical.map(
			(a) => a.floatLarge + a.floatNegative + a.floatNone + a.floatWeek + a.floatMonth || 1
		);
		const chartData = [
			{
				data: floatHistorical.map((a, index) => a.floatAverage),
				name: 'Avg',
				color: '#001489',
			},
			{
				data: floatHistorical.map((a, index) => (100 * a.floatNegative || 0) / (totalNumWithFloats[index] || 1)),
				name: '<0',
				color: 'rgb(229,113,113)',
			},
			{
				data: floatHistorical.map((a, index) => (100 * a.floatNone || 0) / (totalNumWithFloats[index] || 1)),
				name: '0',
				color: 'rgb(255,229,109)',
			},
			{
				data: floatHistorical.map((a, index) => (100 * a.floatWeek || 0) / (totalNumWithFloats[index] || 1)),
				name: '1-7',
				color: 'rgb(133,215,115)',
			},
			{
				data: floatHistorical.map((a, index) => (100 * a.floatMonth || 0) / (totalNumWithFloats[index] || 1)),
				name: '8-30',
				color: 'rgb(40, 112, 247)',
			},
			{
				data: floatHistorical.map((a, index) => (100 * a.floatLarge || 0) / (totalNumWithFloats[index] || 1)),
				name: '30+',
				color: 'rgb(181,99,255)',
			},
		];
		console.log({ chartData });

		if (hasObjChanged(chartData, this.chartData)) {
			this.chartData = chartData;
		}
		if (hasObjChanged(categories, this.categories)) {
			this.categories = categories;
		}
	}

	public onLegendItemClick(e: LegendItemClickEvent): void {
		e.preventDefault();

		const seriesName = e.series.name;
		const view = this.SERIES_TO_FLOAT_VIEW.get(seriesName);
		this.selectedFloatConsumptionView =
			this.selectedFloatConsumptionView === view ? FloatConsumptionView.averageFloat : view;
	}

	changeFloatConsumptionView(floatConsumptionView: FloatConsumptionView) {
		this.selectedFloatConsumptionView = floatConsumptionView;
		this.doSearch.next(true);
	}

	wbsFilterChanged(ev?: Array<DisablableWbs>): void {
		if (this.loading) {
			return;
		}
		const flattened = new Set<DisablableWbs>([]);
		function addToFlat(wbs: DisablableWbs) {
			flattened.add(wbs);
			wbs.childrenWbs?.forEach(addToFlat);
		}
		ev?.forEach(addToFlat);
		this.flatSelectedWbs = flattened;
		if (ev?.length === 0) {
			this.wbsTag = '';
		} else {
			const topLevelCode = ev.find((item) => item?.childrenWbs?.length > 0);
			if (topLevelCode !== undefined) {
				this.wbsTag = topLevelCode.name;
			} else {
				this.wbsTag = ev?.length === 1 ? ev[0].name : ev?.length + ' WBS selected';
			}
		}

		const report = this.projectService.$currentProjectReport.value;
		this.expandedWbs = [this.allWbs[0].id];
		this.updateChartData(this.storage.$allUpdates.value, {
			...report,
			floatHistorical: report.floatHistorical,
		});
	}

	/**
	 * multiselect valueChange handler
	 * @param ev
	 */
	actvCodeFilterChanged(ev?: ActvCodeFilterItem[]): void {
		if (this.loading) {
			return;
		}
		if (ev && ev.length > 1) {
			// @ts-ignore
			this.selectedActivityCodes = [ev[ev.length - 1]];
		}
		this.focusTabSelectedActvCode = (ev.length ? [ev[ev.length - 1]] : []).map((obj) => ({
			...obj,
			parentName: null,
		}));
		this.updateTagText([ev[ev.length - 1]]);

		const report = this.projectService.$currentProjectReport.value;
		this.updateChartData(this.storage.$allUpdates.value, {
			...report,
			floatHistorical: report.floatHistorical,
		}).then(() => {
			this.allActivityCodes.forEach((code) => {
				let disabledCount = 0;
				code.subCodes.forEach((subCode) => {
					subCode.alwaysDisabled = false;
					if (!this.totalActivityCodes.includes(subCode.shortName)) {
						subCode.alwaysDisabled = true;
						disabledCount += 1;
					}
				});

				code.alwaysDisabled = disabledCount === code.subCodes.length;
			});
		});
	}

	/**
	 * multiselect tag text updater
	 * @param ev
	 */
	updateTagText(ev?: ActvCodeFilterItem[]): void {
		if (ev?.length === 0 || ev[0] === undefined) {
			this.codesTag = '';
		} else {
			const topLevelCode = ev.find((item) => item?.subCodes?.length > 0);
			if (topLevelCode !== undefined) {
				this.codesTag = topLevelCode.name;
			} else {
				this.codesTag = ev?.length === 1 ? ev[0].name : ev?.length + ' codes selected';
			}
		}
	}

	/**
	 * generate activity code/subcode items for the multiselect filter
	 * @param xer
	 * @param prevXer
	 */
	generateActivityCodeFilter(xer: Xer): ActvCodeFilterItem[] {
		const allActivityCodes: ActvCodeFilterItem[] = [];
		const actvTypes = Array.from(xer.activityTypes.values());
		actvTypes.forEach((actvType) => {
			const code: ActvCodeFilterItem = {
				alwaysDisabled: false,
				disabled:
					this.selectedActivityCodes?.length === 0
						? false
						: !this.selectedActivityCodes.some(
								(selActvCode) =>
									selActvCode.shortName === actvType.codeType ||
									(selActvCode?.parentName === actvType.codeType && selActvCode?.parentName !== undefined)
							),
				id: actvType.id,
				name: actvType.codeType.toString(),
				sequenceNumber: Number(actvType.sequenceNumber),
				shortName: actvType.codeType,
				subCodes: [],
				codeName: '',
				totalAssignments: 0,
			};
			allActivityCodes.push(code);
		});
		const actvCodes = Array.from(xer.activityCodes.values());
		const tasks = xer.sortedActivities;
		const taskActv = Array.from(xer.taskActivities.values());
		if (this.focusTabSelectedActvCode?.length) {
			const focusTabTaskIds: number[] = taskActv
				.filter((t: XerTaskActivity) => this.focusTabSelectedActvCode.some((code) => t.actv_code_id === code.id))
				.map((a) => a.task_id);
			this.focusTabTasks = tasks.filter((t) => focusTabTaskIds.includes(t.id)).map((t) => t.raw_entry);
			this.focusTabActvCode = actvCodes
				.filter((c) => this.focusTabSelectedActvCode.some((code) => c.id === code.id))
				.map((c) => c.raw_entry);
		}
		actvCodes?.forEach((actvCode) => {
			const parent = allActivityCodes.find((code) => code.id === actvCode.typeId);
			if (parent !== undefined) {
				const subCode: SubCodeFilterItem = {
					alwaysDisabled: false,
					disabled: false,
					id: actvCode.id,
					name: actvCode?.codeName?.toString() || actvCode?.shortName?.toString(),
					parentName: parent.name,
					sequenceNumber: actvCode.sequenceNumber,
					shortName: actvCode.shortName,
					codeName: actvCode.codeName,
					totalAssignments: actvCode.numAssignments || 0,
				};
				if (actvCode.numAssignments > 0) {
					parent.subCodes.push(subCode);
					parent.totalAssignments += actvCode.numAssignments;
				}
			}
		});
		return allActivityCodes.filter((code) => code.totalAssignments > 0);
	}

	contentOfLabels = (ev) => {
		const labelSplit: string[] = ev.text.split('\n');
		return labelSplit[labelSplit.length - 1];
	};

	public itemDisabled(dataItem: { alwaysDisabled?: boolean; disabled?: boolean }) {
		return dataItem?.alwaysDisabled || dataItem?.disabled;
	}
	public wbsItemDisabled(dataItem: DisablableWbs): boolean {
		return dataItem?.disabled;
	}
	public isNodeExpanded = (node: DisablableWbs): boolean => {
		return this.expandedWbs.includes(node.id);
	};
	public handleCollapse(args: TreeItem): void {
		this.expandedWbs = this.expandedWbs.filter((id) => id !== args.dataItem.id);
	}

	public handleExpand(args: TreeItem): void {
		this.expandedWbs = this.expandedWbs.concat(args.dataItem.id);
	}
}
