<div class='report-container' id='report-container'>
	<kendo-pdf-export #pdf paperSize="letter" margin="5mm" [scale]='.8'>
		<div class='outside' [style.height.px]='navBarStorage.reportFormMaxHeight - 114'>
			<kendo-gridlayout
				[rows]="['100px']"
				[cols]="['100%']"
				[gap]="{ rows: 5, cols: 5 }"
				style='height: 100px; width: 100%;'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-item'>
					<kendo-gridlayout
						[rows]="['100px']"
						[cols]="['120px', 'auto', '120px']"
						[gap]="{ rows: 0, cols: 5 }"
						class='grid'
					>
						<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-item'>
							<img [src]="'/assets/images/logos/AEGIS-ANALYTICS-COLOR.png'" style="width: 120px; margin: auto 0;">
						</kendo-gridlayout-item>
						<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-item'>
							<div class='top-row-text'>Schedule Report</div>
							<div class='top-row-text'>{{($clientName | async)}}</div>
							<div class='top-row-text'>{{$projectReport?.value?.project?.name}}</div>
							<div class='top-row-text' style='font-weight: normal;'>{{(!isBaseline ? ($updateName | async) + ' - ' : 'Baseline - ') + (($dataDate | async) | date: 'mediumDate')}}</div>
						</kendo-gridlayout-item>
						<kendo-gridlayout-item [row]='1' [col]='3' class='gridlayout-item'>
							<img *ngIf='($projectReport | async)?.project?.imageBase64' [src]="($projectReport | async)?.project?.imageBase64" style="width: 100px; height: 100px; border-radius: 4px; margin-left: 20px;">
						</kendo-gridlayout-item>
					</kendo-gridlayout>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
			<div class="flip-card" [style.height.px]='navBarStorage.reportFormMaxHeight - 214'>
				<div class="flip-card-inner" [class.flip-animation]='sideShowing === "back"'>
					<kendo-gridlayout
						[rows]="isBaseline ? ['auto', 'auto', 'auto'] : ['auto', 'auto', 'auto', 'auto', 'auto']"
						[cols]="['100%']"
						[gap]="{ rows: 5, cols: 5 }"
						class='flip-card-front'
						style='overflow-y: auto;'
						[style.height.px]='navBarStorage.reportFormMaxHeight - 214'
					>
						<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-item grid-row'>
							<div class='report-section-title'>
								Overview
							</div>
							<div class='report-section-content'>
								<kendo-gridlayout
									[rows]="['95px']"
									[cols]="['calc(24% - 4px)', 'calc(19% - 4px)', 'calc(19% - 4px)', 'calc(19% - 4px)', 'calc(19% - 4px)']"
									[gap]="{ rows: 0, cols: 5 }"
									class='grid'
								>
									<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-item'>
										<div class='box black-box' style='margin-bottom: 5px; padding: 5px 0;'>
											<div>
												Contract Completion
											</div>
											<div>
												{{($contractCompletionDate | async) | date: 'mediumDate'}}
											</div>
										</div>
										<div class='box black-box' style='padding: 5px 0;'>
											<div>
												{{isBaseline ? 'Baseline ' : 'Current '}}Completion
											</div>
											<div>
												{{($currentCompletionDate | async) | date: 'mediumDate'}}
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-item' *ngIf='!isBaseline'>
										<div class='box' [class.green-bg]='($previousVariance | async) >= 0'>
											<div>
												Periodic Delta
											</div>
											<div>
												{{($previousVariance | async)}}
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='3' class='gridlayout-item' *ngIf='!isBaseline'>
										<div class='box' [class.green-bg]='($contractVariance | async) >= 0'>
											<div>
												Total Delta
											</div>
											<div>
												{{($contractVariance | async)}}
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='4' class='gridlayout-item' *ngIf='!isBaseline'>
										<div class='box' [class.green-bg]='($floatIndexCurrent | async) >= ($floatIndexPrevious | async)'>
											<div>
												Float Index
											</div>
											<div class='current-float-div'>
												{{($floatIndexCurrent | async)}}
												<svg *ngIf='arrowUp === true' xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill='white' style="height: 16px; width: 16px; position: absolute;" viewBox="0 0 512 512" class="ng-star-inserted"><path d="M384 288h-96v128h-64l-.001-128H128L255.999 96 384 288z"></path></svg>
												<svg *ngIf='arrowUp === false' xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill='white' style="height: 16px; width: 16px; position: absolute;" viewBox="0 0 512 512" class="ng-star-inserted"><path d="M128 224h96V96h64l.001 128H384L256.001 416 128 224z"></path></svg>
											</div>
											<div class='float-prev' [style.width]='navBarStorage.reportFormPrevFloatWidth + "px"' [style.display]='navBarStorage.reportFormPrevFloatWidth === 0 ? "none" : "initial"'>
												Previous: {{($floatIndexPrevious | async)}}
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='5' class='gridlayout-item' *ngIf='!isBaseline'>
										<kendo-gridlayout
											[rows]="['calc(50% - 2.5px)', 'calc(50% - 2.5px)']"
											[cols]="['calc(50% - 2.5px)', 'calc(50% - 2.5px)']"
											[gap]="{ rows: 5, cols: 5 }"
											class='grid'
											style='height: 100%;'
										>
											<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-item'>
												<div class='box no-pad' [class.green-bg]='($projectScorePassing | async) === true'>
													<div class='test-label' style="font-size: 13px;">
														Project
													</div>
													<div class='test-label'>
														{{$projectScore | async | number : '1.0-0'}}%
													</div>
												</div>
											</kendo-gridlayout-item>
											<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-item'>
												<div class='box no-pad' [class.green-bg]='($progressScorePassing | async) === true'>
													<div class='test-label' style="white-space: unset; font-size: 13px;">
														Progress
													</div>
													<div class='test-label'>
														{{$progressScore | async | number : '1.0-0'}}%
													</div>
												</div>
											</kendo-gridlayout-item>
											<kendo-gridlayout-item [row]='2' [col]='1' class='gridlayout-item'>
												<div class='box no-pad' [class.green-bg]='($qcScorePassing | async) === true'>
													<div class='test-label' style="font-size: 13px;">
														QC
													</div>
													<div class='test-label'>
														{{$qcScore | async | number : '1.0-0'}}%
													</div>
												</div>
											</kendo-gridlayout-item>
											<kendo-gridlayout-item [row]='2' [col]='2' class='gridlayout-item'>
												<div class='box no-pad' [class.green-bg]='($predictabilityScorePassing | async) === true'>
													<div class='test-label' style="font-size: 13px;">
														Risk
													</div>
													<div class='test-label'>
														{{$predictabilityScore | async | number : '1.0-0'}}%
													</div>
												</div>
											</kendo-gridlayout-item>
										</kendo-gridlayout>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-item' *ngIf='isBaseline'>
										<div class='box' [class.green-bg]='($contractVariance | async) >= 0'>
											<div>
												Completion Delta
											</div>
											<div>
												{{($contractVariance | async)}}
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='3' class='gridlayout-item' *ngIf='isBaseline'>
										<div class='box aegis-blue-bg'>
											<div>
												# of Activities
											</div>
											<div>
												{{($totalActivities | async) !== null ? ($totalActivities | async | number : '1.0-0') : '-'}}
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='4' class='gridlayout-item' *ngIf='isBaseline'>
										<div class='box' [class.green-bg]='($logicDensity | async) >= 2'>
											<div>
												Logic Density
											</div>
											<div>
												{{($logicDensity | async) !== null ? ($logicDensity | async | number : '1.1-1') : '-'}}
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='5' class='gridlayout-item' *ngIf='isBaseline'>
										<div class='box' [class.green-bg]='($floatIndexCurrentGreen | async)'>
											<div>
												Float Index
											</div>
											<div>
												{{($floatIndexCurrent | async)}}
											</div>
										</div>
									</kendo-gridlayout-item>
								</kendo-gridlayout>
							</div>
						</kendo-gridlayout-item>

						<kendo-gridlayout-item [row]='2' [col]='1' class='gridlayout-item grid-row' *ngIf='!isBaseline'>
							<div class='report-section-title'>
								Activity Completion
							</div>
							<div class='report-section-content'>
								<kendo-gridlayout
									[rows]="['95px']"
									[cols]="['calc(30% - (10px / 3))', 'calc(30% - (10px / 3))', 'calc(40% - (10px / 3))']"
									[gap]="{ rows: 0, cols: 5 }"
									class='grid'
								>
									<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-item'>
										<div class='box' [class.green-bg]='($totalPercentComplete | async) >= 80' *ngIf='($totalPercentComplete | async) !== -1'>
											<div>
												Activity Completion
											</div>
											<div>
												{{($totalPercentComplete | async) !== null ? ($totalPercentComplete | async | number : '1.0-0') + '%' : '-'}}
											</div>
										</div>
										<div class='box disabled-bg' *ngIf='($totalPercentComplete | async) === -1'>
											No Planned Activities
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-item'>
										<div class='box' [class.green-bg]='($criticalPercentComplete | async) >= 95.5'  *ngIf='($criticalPercentComplete | async) !== -1'>
											<div>
												Critical Activity Completion
											</div>
											<div>
												{{($criticalPercentComplete | async) !== null ? ($criticalPercentComplete | async | number : '1.0-0') + '%' : '-'}}
											</div>
										</div>
										<div class='box disabled-bg' *ngIf='($criticalPercentComplete | async) === -1'>
											No Planned Critical Activities
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='3' class='gridlayout-item'>
										<div class='box bar-box' style='border: none;'>
											<div style='height:45px; margin-bottom: 5px;'>
												<div class='bar-label'>
													Planned Progress: {{$percentPlannedProgress | async | number : '1.0-0'}}%
												</div>
												<div class='bar' style='margin-bottom: 5px;'>
													<div
														#plannedBar
														class='inner-bar'
														[style.width]='($percentPlannedProgress | async) + "%"'
														style='background-color: #808388;'
													>
													</div>
												</div>
											</div>
											<div style='height:45px;'>
												<div class='bar-label'>
													Actual Progress: {{$percentActualProgress | async | number : '1.0-0'}}%
												</div>
												<div class='bar'>
													<div
														#actualBar
														class='inner-bar'
														[style.width]='($percentActualProgress | async) + "%"'
														[class.green-bg]='($percentActualProgress | async) >= ($percentPlannedProgress | async)'
													>
													</div>
												</div>
											</div>
										</div>
									</kendo-gridlayout-item>
								</kendo-gridlayout>
							</div>
						</kendo-gridlayout-item>

						<kendo-gridlayout-item [row]='3' [col]='1' class='gridlayout-item grid-row' *ngIf='!isBaseline'>
							<div class='report-section-title'>
								Progress
							</div>
							<div class='report-section-content'>
								<kendo-gridlayout
									[rows]="['45px', '45px']"
									[cols]="['calc(50% - 57.5px)', '50px', 'calc(50% - 57.5px)', '50px']"
									[gap]="{ rows: 5, cols: 5 }"
									class='grid'
								>
									<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-item'>
										<div class='box white-box'>
											<div>
												Actuals Past DD
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-item'>
										<div class='box' [class.green-bg]='($actualsPastDataDate | async) === false'>
											<div>
												{{($actualsPastDataDate | async) === true ? 'Yes' : 'No'}}
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='3' class='gridlayout-item'>
										<div class='box white-box'>
											<div>
												Actuals w/ no Progress
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='4' class='gridlayout-item'>
										<div class='box' [class.green-bg]='($actualsWithNoProgress | async) === false'>
											<div>
												{{($actualsWithNoProgress | async) === true ? 'Yes' : 'No'}}
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='2' [col]='1' class='gridlayout-item'>
										<div class='box white-box'>
											<div>
												Out of Sequence
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='2' [col]='2' class='gridlayout-item'>
										<div class='box' [class.green-bg]='($outOfSequence | async) === false'>
											<div>
												{{($outOfSequence | async) === true ? 'Yes' : 'No'}}
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='2' [col]='3' class='gridlayout-item'>
										<div class='box white-box'>
											<div>
												PCT w/ no Change in RD
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='2' [col]='4' class='gridlayout-item'>
										<div class='box' [class.green-bg]='($pctWithNoChangeRD | async) === 0'>
											<div>
												{{($pctWithNoChangeRD | async) === 0 ? 'No' : (($pctWithNoChangeRD | async) + '%')}}
											</div>
										</div>
									</kendo-gridlayout-item>
								</kendo-gridlayout>
							</div>
						</kendo-gridlayout-item>

						<kendo-gridlayout-item [row]='isBaseline ? 2 : 4' [col]='1' class='gridlayout-item grid-row'>
							<div class='report-section-title'>
								{{isBaseline ? 'Logic Summary' : 'Logic'}}
							</div>
							<div class='report-section-content'>
								<kendo-gridlayout
									[rows]="['45px', '45px', '45px']"
									[cols]="['calc(50% - 57.5px)', '50px', 'calc(50% - 57.5px)', '50px']"
									[gap]="{ rows: 5, cols: 5 }"
									class='grid'
								>
									<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-item'>
										<div class='box white-box'>
											<div>
												% of Critical Activities
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-item'>
										<div class='box' [class.green-bg]='($percentCriticalActivities | async) <= 10'>
											<div>
												{{($percentCriticalActivities | async) !== null ? ($percentCriticalActivities | async | number : '1.0-0') + '%' : '-'}}
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='3' class='gridlayout-item'>
										<div class='box white-box'>
											<div>
												SS no FF
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='4' class='gridlayout-item'>
										<div class='box' [class.green-bg]='($ssNoFF | async) === false'>
											<div>
												{{($ssNoFF | async) === true ? 'Yes' : 'No'}}
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='2' [col]='1' class='gridlayout-item'>
										<div class='box white-box'>
											<div>
												% of Negative Floats
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='2' [col]='2' class='gridlayout-item'>
										<div class='box' [class.green-bg]='($percentNegativeFloats | async) === 0'>
											<div>
												{{($percentNegativeFloats | async) !== null ? ($percentNegativeFloats | async | number : '1.0-0') + '%' : '-'}}
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='2' [col]='3' class='gridlayout-item'>
										<div class='box white-box'>
											<div>
												FS Lags
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='2' [col]='4' class='gridlayout-item'>
										<div class='box' [class.green-bg]='($fsWithLags | async) === false'>
											<div>
												{{($fsWithLags | async) === true ? 'Yes' : 'No'}}
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='3' [col]='1' class='gridlayout-item'>
										<div class='box white-box'>
											<div>
												% High Duration
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='3' [col]='2' class='gridlayout-item'>
										<div class='box' [class.green-bg]='($percentHighDuration | async) <= 5'>
											<div>
												{{($percentHighDuration | async) !== null ? ($percentHighDuration | async | number : '1.0-0') + '%' : '-'}}
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='3' [col]='3' class='gridlayout-item'>
										<div class='box white-box'>
											<div>
												Missing Relationships
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='3' [col]='4' class='gridlayout-item'>
										<div class='box' [class.green-bg]='($missingRelationships | async) === false'>
											<div>
												{{($missingRelationships | async) === true ? 'Yes' : 'No'}}
											</div>
										</div>
									</kendo-gridlayout-item>
								</kendo-gridlayout>
							</div>
						</kendo-gridlayout-item>

						<kendo-gridlayout-item [row]='5' [col]='1' class='gridlayout-item grid-row' *ngIf='!isBaseline'>
							<div class='report-section-title'>
								Risk
							</div>
							<div class='report-section-content'>
								<kendo-gridlayout
									[rows]="['45px']"
									[cols]="['calc(50% - 57.5px)', '50px', 'calc(50% - 57.5px)', '50px']"
									[gap]="{ rows: 5, cols: 5 }"
									class='grid'
								>
									<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-item'>
										<div class='box white-box'>
											<div>
												CP Reliability
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-item'>
										<div class='box' [class.green-bg]='($criticalPathReliabilityScore | async) >= 70'>
											<div>
												{{!!($criticalPathReliabilityScore | async) ? ($criticalPathReliabilityScore | async | number : '1.0-0') + '%' : '-'}}
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='3' class='gridlayout-item'>
										<div class='box white-box'>
											<div>
												CP Shift
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='4' class='gridlayout-item'>
										<div class='box' [class.green-bg]='($criticalPathRiskSwitch | async) !== "Yes"'>
											<div>
												{{($criticalPathRiskSwitch | async)}}
											</div>
										</div>
									</kendo-gridlayout-item>
								</kendo-gridlayout>
							</div>
						</kendo-gridlayout-item>

						<kendo-gridlayout-item [row]='3' [col]='1' class='gridlayout-item grid-row' style='margin-bottom: 67px;' *ngIf='isBaseline'>
							<div class='report-section-title'>
								Logic Details
							</div>
							<div class='report-section-content'>
								<kendo-gridlayout
									[rows]="['45px', '45px', '45px', '45px', '45px']"
									[cols]="['calc(50% - 57.5px)', '50px', 'calc(50% - 57.5px)', '50px']"
									[gap]="{ rows: 5, cols: 5 }"
									class='grid'
								>
									<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-item'>
										<div class='box white-box'>
											<div>
												High Durations<br>RD > 44 WDs
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-item'>
										<div class='box aegis-blue-bg'>
											<div>
												{{($countHighDurations | async) !== null ? ($countHighDurations | async | number : '1.0-0') : '-'}}
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='3' class='gridlayout-item'>
										<div class='box white-box'>
											<div>
												SS/FF With Problematic Lags
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='4' class='gridlayout-item'>
										<div class='box aegis-blue-bg'>
											<div>
												{{($ssFFWithLags | async) !== null ? ($ssFFWithLags | async | number : '1.0-0') : '-'}}
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='2' [col]='1' class='gridlayout-item'>
										<div class='box white-box'>
											<div>
												Actuals Past Data Date
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='2' [col]='2' class='gridlayout-item'>
										<div class='box aegis-blue-bg'>
											<div>
												{{($countActualsPastDataDate | async) !== null ? ($countActualsPastDataDate | async | number : '1.0-0') : '-'}}
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='2' [col]='3' class='gridlayout-item'>
										<div class='box white-box'>
											<div>
												Soft Constraints<br>(SNET, SNLT, FNET, FNLT)
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='2' [col]='4' class='gridlayout-item'>
										<div class='box aegis-blue-bg'>
											<div>
												{{($softConstraints | async) !== null ? ($softConstraints | async | number : '1.0-0') : '-'}}
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='3' [col]='1' class='gridlayout-item'>
										<div class='box white-box'>
											<div>
												Missing Logic<br>(Pred/Succ)
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='3' [col]='2' class='gridlayout-item'>
										<div class='box aegis-blue-bg'>
											<div>
												{{($missingLogic | async) !== null ? ($missingLogic | async | number : '1.0-0') : '-'}}
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='3' [col]='3' class='gridlayout-item'>
										<div class='box white-box'>
											<div>
												Hard Constraints
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='3' [col]='4' class='gridlayout-item'>
										<div class='box aegis-blue-bg'>
											<div>
												{{($hardConstraints | async) !== null ? ($hardConstraints | async | number : '1.0-0') : '-'}}
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='4' [col]='1' class='gridlayout-item'>
										<div class='box white-box'>
											<div>
												FS With Lags
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='4' [col]='2' class='gridlayout-item'>
										<div class='box aegis-blue-bg'>
											<div>
												{{($countFsWithLags | async) !== null ? ($countFsWithLags | async | number : '1.0-0') : '-'}}
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='4' [col]='3' class='gridlayout-item'>
										<div class='box white-box'>
											<div>
												Negative Lags
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='4' [col]='4' class='gridlayout-item'>
										<div class='box aegis-blue-bg'>
											<div>
												{{($negativeLags | async) !== null ? ($negativeLags | async | number : '1.0-0') : '-'}}
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='5' [col]='1' class='gridlayout-item'>
										<div class='box white-box'>
											<div>
												High Float<br>TF > 80 + Critical Float WDs
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='5' [col]='2' class='gridlayout-item'>
										<div class='box aegis-blue-bg'>
											<div>
												{{($highFloat | async) !== null ? ($highFloat | async | number : '1.0-0') : '-'}}
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='5' [col]='3' class='gridlayout-item'>
										<div class='box white-box'>
											<div>
												Retained Logic
											</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='5' [col]='4' class='gridlayout-item'>
										<div class='box aegis-blue-bg'>
											<div>
												{{($retainedLogic | async) === true ? 'Yes' : 'No'}}
											</div>
										</div>
									</kendo-gridlayout-item>
								</kendo-gridlayout>
							</div>
						</kendo-gridlayout-item>
					</kendo-gridlayout>
					<div class="flip-card-back hidden-from-export">
						<kendo-gridlayout
							[rows]="['auto', 'auto']"
							[cols]="['100%']"
							[gap]="{ rows: 5, cols: 5 }"
						>
							<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-item grid-row'>
								<div class='report-section-title'>
									Critical Path Notes
								</div>
								<div class='report-section-content'>
									<div class='box white-box'>
										<div [style.height.px]='(navBarStorage.reportFormMaxHeight - 288) / 2'>
											<textarea [(ngModel)]='criticalPathNotes' maxlength='3000' class='textarea-notes' placeholder="Enter Notes...">
											</textarea>
										</div>
									</div>
								</div>
							</kendo-gridlayout-item>
							<kendo-gridlayout-item [row]='2' [col]='1' class='gridlayout-item grid-row'>
								<div class='report-section-title'>
									Time Analysis Notes
								</div>
								<div class='report-section-content'>
									<div class='box white-box'>
										<div [style.height.px]='(navBarStorage.reportFormMaxHeight - 288) / 2'>
											<textarea [(ngModel)]='timeAnalysisNotes' maxlength='3000' class='textarea-notes' placeholder="Enter Notes...">
											</textarea>
										</div>
									</div>
								</div>
							</kendo-gridlayout-item>
						</kendo-gridlayout>
					</div>
				</div>
			</div>
		</div>
	</kendo-pdf-export>
	<kendo-gridlayout
		[rows]="['30px']"
		[cols]="['calc(((100% - 100px) / 2) - 5px)','100px', 'calc(((100% - 100px) / 2) - 5px)']"
		[gap]="{ rows: 0, cols: 5 }"
		class='grid'
		style='padding-top: 10px;'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-item'>
			<button
				kendoButton
				title="Flip"
				fillMode='solid'
				themeColor='dark'
				(click)="flipReport()"
				class='hidden-from-export'
				style='width: 80px;'
			>
				<kendo-icon [name]='sideShowing === "front" ? "redo" : "undo"' style='margin-bottom: 2px; margin-left: 1px;'></kendo-icon>
				<span [style.margin-left.px]='sideShowing === "front" ? 6.26 : 0'>
					{{sideShowing === 'front' ? 'Notes' : 'Report'}}
				</span>
			</button>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-item' style='flex-direction: row; margin: auto 0;'>
			<button
				kendoButton
				title="Previous"
				rounded='full'
				themeColor='dark'
				(click)="nextPage(-1)"
				class='fab-btn hidden-from-export'
				[disabled]='currentPage === 1'
				*ngIf='totalPages > 1'
			>
				<kendo-icon class='small-margin-right' name='chevron-left'></kendo-icon>
			</button>
			<div class='pager-div hidden-from-export' *ngIf='totalPages > 1'>
				{{currentPage + ' / ' + totalPages}}
			</div>
			<button
				kendoButton
				title="Next"
				rounded='full'
				themeColor='dark'
				(click)="nextPage(1)"
				class='fab-btn hidden-from-export'
				[disabled]='totalPages === currentPage'
				*ngIf='totalPages > 1'
			>
				<kendo-icon class='small-margin-left' name='chevron-right'></kendo-icon>
			</button>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='1' [col]='3' class='gridlayout-item'>
			<div class="action-btn-container">
				<span>
					<button kendoButton fillMode="solid" themeColor='info' (click)='pdf.saveAs(fileName)' *ngIf='sideShowing === "front"'>Export (.PDF)</button>
					<button kendoButton fillMode="solid" themeColor='info' (click)='saveNotesChanges()' [disabled]='!isChanged()' *ngIf='sideShowing === "back"'>Save Notes</button>
				</span>
			</div>
		</kendo-gridlayout-item>
	</kendo-gridlayout>

</div>
