import { NgModule } from '@angular/core';
import { Error500Component } from './500/error-500.component';
import { ErrorsRoutingModule } from './errors-routing.module';
import { Error404Component } from './404/error-404.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
	declarations: [Error500Component, Error404Component],
	imports: [CommonModule, ErrorsRoutingModule, MatIconModule],
})
export class ErrorsModule {}
