import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';

export type MapIcon =
	| 'airport'
	| 'correctionalfacility'
	| 'datacenter'
	| 'educationalfacility'
	| 'government'
	| 'healthcare'
	| 'infrastructure'
	| 'officeretail'
	| 'other'
	| 'pharmaceutical'
	| 'renovation'
	| 'residential'
	| 'sports&entertainment'
	| 'wastewatertreatment';

@Component({
	selector: 'app-map-icon',
	templateUrl: './map-icon.component.svg',
	styleUrls: ['./map-icon.component.scss'],
})
export class MapIconComponent implements OnInit {
	@Input() color: string = 'blue';
	@Input() icon: MapIcon = 'airport';
	@Input() width: number;
	@Input() height: number;

	constructor() {}

	ngOnInit(): void {}
}
