import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ProjectDashboardService } from '../../../services/project/project.service';
import { ComponentNotes } from '../../../models/Project';
import { UserService } from '../../../services/common/user.service';
import { RestService } from '../../../services/common/rest.service';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { format } from 'date-fns';
import { ExpansionPanelActionEvent } from '@progress/kendo-angular-layout';

export interface Note {
	note: string;
	updateId: string;
	lastEditTimestamp: Date;
	lastEditUser: string;
}

@Component({
	selector: 'app-component-notes',
	templateUrl: './component-notes.component.html',
	styleUrls: ['./component-notes.component.scss'],
})
export class ComponentNotesComponent implements OnInit {
	@Input() insideWindow: boolean = false;
	notes: Note[] = [];
	currentUpdateNotes: string = '';
	lastSavedCurrentUpdateNotes: string = '';
	public gridView: GridDataResult;
	gridData: Array<any> = [];
	loading: boolean = false;
	@ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
	constructor(
		public projectService: ProjectDashboardService,
		private userService: UserService,
		private restService: RestService
	) {}
	ngOnInit() {
		this.projectService.$currentProjectData.subscribe((val) => {
			if (val) {
				this.getSavedNotes();
			}
		});
	}

	getSavedNotes(): void {
		let notes: Note[];
		const savedNotes: ComponentNotes[] = this.projectService.$currentProjectData.value?.componentNotes;
		if (savedNotes) {
			const thisComponentSavedNotes: ComponentNotes = savedNotes.find(
				(n) => n.id === this.projectService.notesWindow.id
			);
			if (thisComponentSavedNotes) {
				notes = this.projectService.$currentProjectData.value.updateIds.map((id: string) => ({
					note: thisComponentSavedNotes.notes.find((n) => n.updateId === id)?.note || '',
					updateId: id,
					lastEditTimestamp: thisComponentSavedNotes.notes.find((n) => n.updateId === id)?.lastEditTimestamp || null,
					lastEditUser: thisComponentSavedNotes.notes.find((n) => n.updateId === id)?.lastEditUser || '',
				}));
				notes.forEach(
					(note: Note) =>
						(note.lastEditTimestamp = note.lastEditTimestamp === null ? null : new Date(note.lastEditTimestamp))
				);
			} else {
				notes = this.getEmptyNotes();
			}
		} else {
			notes = this.getEmptyNotes();
		}
		this.notes = notes;
		this.currentUpdateNotes = structuredClone(notes[notes.length - 1]?.note);
		this.lastSavedCurrentUpdateNotes = structuredClone(this.currentUpdateNotes);
		this.loadGridData();
		this.checkForChanges();
	}

	getEmptyNotes(): Note[] {
		return this.projectService.$currentProjectData.value.updateIds.map((id: string) => ({
			note: '',
			updateId: id,
			lastEditTimestamp: null,
			lastEditUser: '',
		}));
	}

	checkForChanges(): void {
		this.projectService.componentNoteChanges = this.currentUpdateNotes !== this.lastSavedCurrentUpdateNotes;
	}

	saveNoteChanges(): void {
		const projectId: string = this.projectService.$currentProjectPageId.value;
		let newNotes: ComponentNotes[] = this.projectService.$currentProjectData.value?.componentNotes || [];
		const thisComponentNotes: ComponentNotes = newNotes?.find(
			(n: ComponentNotes) => n.id === this.projectService.notesWindow.id
		) || { id: this.projectService.notesWindow.id, notes: [] };
		const editedNotes: Note[] = structuredClone(this.notes);
		editedNotes[editedNotes.length - 1] = {
			note: this.currentUpdateNotes,
			updateId: editedNotes[editedNotes.length - 1].updateId,
			lastEditTimestamp: new Date(),
			lastEditUser: this.userService.user.value?.profile?.name,
		};
		thisComponentNotes.notes = editedNotes;
		const index: number = newNotes.findIndex((n: ComponentNotes) => n.id === thisComponentNotes.id);
		if (index === -1) {
			newNotes.push(thisComponentNotes);
		} else {
			newNotes[index] = thisComponentNotes;
		}
		newNotes = newNotes.sort((a: ComponentNotes, b: ComponentNotes) => {
			return a?.id < b?.id ? -1 : b?.id < a?.id ? 1 : 0;
		});
		this.restService.patch(`project/${projectId}`, { componentNotes: newNotes }).subscribe((val) => {
			console.log('patch res', val);
		});
	}

	loadGridData(): void {
		this.loading = true;
		const displayData: Note[] = [];
		this.notes.forEach((note: Note, index: number) => {
			const entry: Note = {
				note: note.note,
				updateId: index === 0 ? 'Baseline' : 'Update ' + index,
				lastEditTimestamp: note.lastEditTimestamp,
				lastEditUser: note?.lastEditUser || '',
			};
			if (entry.note !== '') {
				displayData.push(entry);
			}
		});
		this.gridView = {
			data: displayData,
			total: displayData?.length,
		};
		this.gridData = displayData;
		this.loading = false;
	}

	public showTooltip(e: MouseEvent): void {
		const element: HTMLElement & { lastUpdated: string } = e.target as HTMLElement & { lastUpdated: string };
		if (
			(element.nodeName === 'TD' || element.className === 'k-column-title') &&
			element.classList.contains('notes-force')
		) {
			const srcElement: HTMLElement = e.srcElement as HTMLElement;
			const indexAtr: Attr = srcElement.attributes.getNamedItem('ng-reflect-row-index');
			const matchingNoteEntry: Note = this.notes[Number(indexAtr.value)];
			element.lastUpdated = '';
			if (
				matchingNoteEntry &&
				matchingNoteEntry?.lastEditTimestamp !== null &&
				matchingNoteEntry?.lastEditUser !== ''
			) {
				element.lastUpdated =
					'Last Updated: ' +
					format(matchingNoteEntry?.lastEditTimestamp, 'MMM dd, yyyy') +
					' by ' +
					matchingNoteEntry?.lastEditUser;
			}
			if (element.lastUpdated !== '') {
				this.tooltipDir.toggle(element);
			} else {
				this.tooltipDir.hide();
			}
		} else {
			this.tooltipDir.hide();
		}
	}

	public onAction(ev: ExpansionPanelActionEvent): void {
		if (ev.action === 'collapse') {
			this.projectService.$newNotesHeight.next(378);
		} else {
			setTimeout(() => {
				const grid = document.getElementById('notesComponentGrid');
				if (grid) {
					const gridHeight: number = grid.getBoundingClientRect().height;
					const newHeight: number = gridHeight < 473.5 ? gridHeight + 276.5 : 750;
					this.projectService.$newNotesHeight.next(newHeight);
				}
			}, 500);
		}
	}
}
