import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SharedMatModule } from 'components/shared/shared-mat.module';
import { AddRiskButtonComponent } from './add-risk-button/add-risk-button.component';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
	declarations: [AddRiskButtonComponent],
	imports: [CommonModule, ReactiveFormsModule, FormsModule, IonicModule, SharedMatModule, MatRadioModule],
	exports: [AddRiskButtonComponent],
})
export class AddRiskModule {}
