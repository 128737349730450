import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CalculationFieldInterface } from '../../../../models/ProjectReport/ProjectReport';
import { AxisSettings, SeriesData, SeriesDataSettings } from '../../../../models/ChartSettings';
import { hasObjChanged } from '../../../../util/projects';
import { CurrentProjectReport, ProjectDashboardService } from '../../../../services/project/project.service';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { UpdateInterface } from '../../../../models/Update/Task';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { RestService } from '../../../../services/common/rest.service';
import { format } from 'date-fns';

@Component({
	selector: 'app-index-trending',
	templateUrl: './index-trending.component.html',
	styleUrls: ['./index-trending.component.scss'],
})
export class IndexTrendingComponent implements OnInit {
	@Input() isOverview: boolean = false;
	@Input() beiHistorical: number[];
	@Input() cpliHistorical: number[];
	@Input() isFocus: boolean = false;
	@Input() focusFinMile: string;
	@Input() needRecalc = false;

	private _unsubscribeAll: Subject<void> = new Subject<void>();
	beiData: SeriesData[] = [];
	cpliData: SeriesData[] = [];
	categories: string[] = [];
	seriesData: SeriesDataSettings[] = [];
	valueAxisItemSettings: AxisSettings[] = [
		{
			title: {
				text: 'Score',
				visible: true,
			},
			labels: {
				format: '{0:n2}',
			},
			majorGridLines: {
				visible: true,
			},
		},
	];
	hasNotes: boolean = false;
	fetchingSheets = false;

	constructor(
		public projectService: ProjectDashboardService,
		public scheduleStorage: ScheduleStorageService,
		private restService: RestService
	) {}

	ngOnInit(): void {
		combineLatest([
			// Debounce the project report if needed
			this.projectService.$currentProjectReport.pipe(debounceTime(100)),
			this.scheduleStorage.$allUpdates.pipe(debounceTime(100)),
		])
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(([report, updates]) => {
				// Ensure both have valid values, if you need an extra guard:
				if ((!(this.beiHistorical && this.cpliHistorical) && !report) || !updates) {
					return;
				}

				if (this.beiHistorical && this.cpliHistorical) {
					this.updateChartData(
						undefined,
						this.beiHistorical.map((val, i) => ({
							BEI: val * 100,
							CPLI: this.cpliHistorical[i] * 100,
						}))
					);
				} else {
					if (!report?.calculationFieldsHistorical) {
						return;
					}
					this.updateChartData(report);
				}
			});

		this.projectService.$currentProjectData.subscribe((val) => {
			if (val) {
				const savedNotes = val.componentNotes?.find((n) => n.id === 20)?.notes;
				this.hasNotes = savedNotes?.length && savedNotes[savedNotes?.length - 1]?.note !== '';
			}
		});
	}

	/**
	 * updates chart values with new ones
	 * @param report
	 * @param customVals
	 */
	updateChartData(report: CurrentProjectReport, customVals?: Array<{ BEI: number; CPLI: number }>): void {
		const valuesByUpdate = customVals || report?.calculationFieldsHistorical;
		const beiData = [];
		const cpliData = [];
		const categories = [];
		valuesByUpdate.forEach((update, i) => {
			const dataDateFromUpdate: Date = new Date(
				this.scheduleStorage.$allUpdates.value[i]?.dataDate ||
					report?.projectCompletionTrend?.projectCompletionTrendArray?.[i]?.dataDate
			);
			const dateString: string = dataDateFromUpdate ? format(dataDateFromUpdate, 'MMM dd, yyyy') : '';
			const updateName: string = i === 0 ? 'Baseline' : `Update ${i}`;
			const category: string =
				(dateString === '' ? updateName : updateName + '\n' + dateString) +
				(this.scheduleStorage.$allUpdates.value[i]?.baseline ? ' ®' : '');
			categories.push(category);
			beiData.push({
				category,
				value: update.BEI / 100,
			});
			cpliData.push({
				category,
				value: update.CPLI !== null ? update.CPLI / 100 : undefined,
			});
		});
		const seriesData: SeriesDataSettings[] = [
			{
				type: 'line',
				data: beiData,
				name: 'Baseline Execution Index',
				visible: true,
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
			{
				type: 'line',
				data: cpliData,
				name: 'Critical Path Length Index',
				visible: true,
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
		];
		if (hasObjChanged(this.seriesData, seriesData)) {
			this.seriesData = seriesData;
		}
		if (hasObjChanged(beiData, this.beiData)) {
			this.beiData = beiData;
		}
		if (hasObjChanged(cpliData, this.cpliData)) {
			this.cpliData = cpliData;
		}
		if (hasObjChanged(categories, this.categories)) {
			this.categories = categories;
		}
	}

	grabExport() {
		this.fetchingSheets = true;
		this.restService
			.fetch(`report/sheets/${this.projectService.$currentProjectData.value._id}/${this.focusFinMile || ''}`)
			.subscribe(
				async (val) => {
					console.log({ val });
					const workbook = new ExcelJS.Workbook();
					const sheets: Record<
						string,
						{ sheetName: string; columns: string[]; data: Record<string, string | number>[] }
					> = val.sheets;
					for (const sheet of Object.values(sheets)) {
						if (!sheet.sheetName.includes('BEI') && sheet.sheetName !== 'Scores') {
							continue;
						}
						const worksheet = workbook.addWorksheet(sheet.sheetName);

						// Define worksheet columns. You can adjust width as needed.
						worksheet.columns = sheet.columns.map((col) => ({
							header: col,
							key: col,
							width: 20,
						}));

						// Add each row to the worksheet
						sheet.data.forEach((row) => {
							if (row.Score && row.Score !== 'BEI' && row.Score !== 'CPLI') {
								return;
							}
							worksheet.addRow(row);
						});
					}

					// Write the workbook to a buffer
					const buffer = await workbook.xlsx.writeBuffer();

					// Create a Blob from the buffer
					const fileBlob = new Blob([buffer], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					});

					const updateName =
						this.projectService.$currentProjectData.value.updateIds.length === 1
							? 'Baseline'
							: `Update ${this.projectService.$currentProjectData.value.updateIds.length - 1}`;
					saveAs(fileBlob, `${updateName}_${this.focusFinMile?.replaceAll('.', '') || 'Overall'}_BEI_CPLI_Report.xlsx`);
				},
				(response) => {
					console.log('POST call in error', response);
					this.needRecalc = true;
				},
				() => {
					this.fetchingSheets = false;
				}
			);
	}
}
