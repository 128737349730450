import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
	AbstractControl,
	UntypedFormBuilder,
	UntypedFormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'register-2',
	templateUrl: './register-2.component.html',
	styleUrls: ['./register-2.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class Register2Component implements OnInit, OnDestroy {
	registerForm: UntypedFormGroup;

	// Private
	private _unsubscribeAll: Subject<void>;

	constructor(private _formBuilder: UntypedFormBuilder) {
		// Set the private defaults
		this._unsubscribeAll = new Subject<void>();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.registerForm = this._formBuilder.group({
			name: ['', Validators.required],
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required],
			passwordConfirm: ['', [Validators.required, confirmPasswordValidator]],
		});

		// Update the validity of the 'passwordConfirm' field
		// when the 'password' field changes
		this.registerForm
			.get('password')
			.valueChanges.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(() => {
				this.registerForm.get('passwordConfirm').updateValueAndValidity();
			});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
	if (!control.parent || !control) {
		return null;
	}

	const password = control.parent.get('password');
	const passwordConfirm = control.parent.get('passwordConfirm');

	if (!password || !passwordConfirm) {
		return null;
	}

	if (passwordConfirm.value === '') {
		return null;
	}

	if (password.value === passwordConfirm.value) {
		return null;
	}

	return { passwordsNotMatching: true };
};
