	<div class="content">
		<kendo-treelist
			kendoTreeListExpandable
			[data]="rootData | async"
			idField="customFieldId"
			expandBy='customFieldId'
			[fetchChildren]="fetchChildren"
			[hasChildren]="hasChildren"
			(add)="addHandler($event)"
			(edit)="editHandler($event)"
			(remove)="removeHandler($event)"
			(save)="saveHandler($event)"
			(cancel)="cancelHandler($event)"
			(cellClick)="cellClickHandler($event)"
			(expand)='expandHandler($event)'
			[(expandedKeys)]="expandedIds"
		>
			<kendo-treelist-column
				[expandable]="!disabled"
				field='field'
				title='Field'
				[style]='{
					"color": disabled ? "grey" : "black",
					"cursor": disabled ? "default" : "pointer"
				}'
			>
			</kendo-treelist-column>
			<kendo-treelist-column
				field='data'
				title='Data'
				[style]='{
					"color": disabled ? "grey" : "black",
					"cursor": disabled ? "default" : "pointer"
				}'
			>

			</kendo-treelist-column>
			<kendo-treelist-column
				field='riskId'
				title='Risk Ids'
				[style]='{
					"color": disabled ? "grey" : "black",
					"cursor": disabled ? "default" : "pointer"
				}'
			>
			</kendo-treelist-column>
			<kendo-treelist-command-column [width]="100"
																		 [style]='{
					"color": disabled ? "grey" : "black",
					"cursor": disabled ? "default" : "pointer"
				}'>
				<ng-template
					kendoTreeListCellTemplate
					let-isNew="isNew"
					let-cellContext="cellContext"
				>
					<span *ngIf='cellContext.viewItem.data.editable || cellContext.viewItem.data.isRoot'>
						<!-- "Add Child" command directive, will not be visible in edit mode -->
					<button
						[kendoTreeListAddCommand]="cellContext"
						[svgIcon]="icons.filterAddExpression"
						*ngIf='cellContext.viewItem.level === 0'
						matTooltip="Add New"
						[disabled]='disabled'
					></button>

						<!-- "Edit" command directive, will not be visible in edit mode -->
					<button
						[kendoTreeListEditCommand]="cellContext"
						[svgIcon]="icons.pencil"
						[primary]="true"
						*ngIf='cellContext.viewItem.level === 1'
						matTooltip="Edit"
						[disabled]='disabled'
					></button>

						<!-- "Remove" command directive, will not be visible in edit mode -->
					<button
						[kendoTreeListRemoveCommand]="cellContext"
						[svgIcon]="icons.trash"
						*ngIf='cellContext.viewItem.level === 1'
						matTooltip="Remove"
						[disabled]='disabled'
					></button>

						<!-- "Save" command directive, will be visible only in edit mode -->
					<button
						[kendoTreeListSaveCommand]="cellContext"
						[disabled]="form?.invalid || disabled"
						[svgIcon]="icons.save"
						matTooltip="Save"
						[ngClass]="{'no-left-margin': cellContext.viewItem.isEditing}"
					></button>

						<!-- "Cancel" command directive, will be visible only in edit mode -->
					<button
						[kendoTreeListCancelCommand]="cellContext"
						[svgIcon]="icons.cancel"
						matTooltip="Cancel"
						[disabled]='disabled'
					></button>
					</span>
				</ng-template>
			</kendo-treelist-command-column>
		</kendo-treelist>
	</div>
