import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../services/auth/authentication.service';

@Injectable()
export class LoginAuthGuard {
	constructor(
		public authService: AuthenticationService,
		public router: Router,
		public activatedRoute: ActivatedRoute
	) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		const isUserTokenAvailable = this.authService.getCurrentActiveUser();
		if (state.url.includes('auth')) {
			if (isUserTokenAvailable) {
				const u = new URL(
					decodeURI(localStorage.getItem('currentRoute').toString().replace('%253F', '?').replace('%253D', '=')),
					window.location.protocol + '//' + window.location.host + '/'
				);
				const params = {};
				u.searchParams.forEach((value, key) => {
					params[key] = value;
				});
				try {
					let timestamp =
						localStorage.getItem('raz-timestamp') !== null ? localStorage.getItem('raz-timestamp') : 'new';

					if (timestamp === 'new') {
						timestamp = new Date().getTime().toString();
						localStorage.setItem('raz-timestamp', timestamp);
					} else {
						if (new Date().getTime() - parseInt(timestamp) > 3600000) {
							// we're out of time, dont fetch this.
							this.router.navigate(['/portfolio']);
						} else {
							console.log(new Date().getTime() - parseInt(timestamp));
							const u = new URL(
								decodeURI(localStorage.getItem('currentRoute').toString().replace('%253F', '?').replace('%253D', '=')),
								window.location.protocol + '//' + window.location.host + '/'
							);
							const params1 = {};
							u.searchParams.forEach((value, key) => {
								params1[key] = value;
							});
							//alert("Im navigating via login-auth-guard");
							this.router.navigate([u.pathname], { queryParams: params1 as Params });
						}
					}
				} catch (e) {
					this.router.navigate(['/portfolio']);
				}
				return false;
			}
			return true;
		} else {
			if (!isUserTokenAvailable) {
				this.router.navigate(['/auth/login']);
				return false;
			}
			return true;
		}
	}
}
