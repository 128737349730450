<div class='card regular-container critical-path-card' style="page-break-before: always; height: auto;" id="drivingPathComponent">
	<kendo-gridlayout
		[rows]="['42.4px', 'calc(100% - 42.2px)']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='grid'
		style='page-break-inside: avoid;'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' [class.overviewHeader]="isOverview">
			<kendo-gridlayout
				[rows]="['42.4px']"
				[cols]="['calc(100% - 173px)', '168px']"
				[gap]="{ rows: 0, cols: 5 }"
				class='full-width'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
					<div class="card-header">
						Driving Path Analysis
					</div>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2' class='toolbar-item-container' style='justify-content: flex-end;'>
					<span style='display: inline-flex; align-items: center;float: right; gap: 10px; margin-right: 10px;' kendoTooltip>
						@if (!isFocus) {
							<div style="position: relative; border-radius: 4px;" id="milestoneBtn" class="header-btn">
								<kendo-dropdowntree
									style="background-color: transparent !important;"
									[kendoDropDownTreeHierarchyBinding]="groupedActivities"
									childrenField="children"
									[textField]="'entry.name'"
									[valueField]="'entry.id'"
									[filterable]='true'
									[value]='selectedFinishMilestone'
									[loading]='scheduleService.isLoading || loading'
									kendoDropDownTreeExpandable
									[expandedKeys]="expandedNodes"
									[expandBy]="'entry.id'"
									[expandOnFilter]="{expandMatches: true}"
									[isNodeExpanded]="isNodeExpanded"
									(nodeExpand)="handleExpand($event)"
									(nodeCollapse)="handleCollapse($event)"
									(valueChange)='treeValChange($event, dropDownTree)'
									(open)="open()"
									[popupSettings]="{popupClass: 'drivingPathDropdownTree'}"
									(close)="disableClose($event)"
									[clearButton]="false"
									(filterChange)="handleFilterMilestone($event)"
									#dropDownTree
									class="drivingMileTree"
								>
									<ng-template kendoDropDownTreeNodeTemplate let-dataItem>
										<span class="milestone-item">
											@if (dataItem?.task_code === this.defaultFinishMilestoneCode) {
												<div kendoTooltip class="milestone-item-finish-icon">
													<img src="/assets/icons/newIcons/checkered-flag.svg" [title]="'Schedule Finish Milestone'" [height]="20" width="20"/>
												</div>
											}
											<span [class.overrideSelection]="dataItem?.entry?.id === selectedFinishMilestone?.entry?.id" [style.margin-left.px]="dataItem?.children?.length ? 0 : 22" style="padding: 0 2px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: inline-block; max-width: 330px;">
												{{ dataItem?.entry?.name }}
											</span>
										</span>
									</ng-template>
								</kendo-dropdowntree>
								<kendo-svg-icon *ngIf='milestoneSelectorOpen' [icon]="caretAltDown" class="downArrowIcon" style="color: white; position: absolute; left: 8px; margin-top: 25px;"></kendo-svg-icon>
							</div>
						}
						<button kendoButton themeColor='base' title="Export Report (XLSX)" class='header-btn upload-btn'
										(click)="excelexport.save()"
										[disabled]="scheduleService.isLoading || loading"
										[style.background-color]="isOverview ? '#ffb500' : 'white'"
										style='position: relative; margin: auto 0;'
										[imageUrl]='"/assets/icons/newIcons/excelExport.svg"'>
						</button>
						@if (isOverview) {
							<button kendoButton themeColor="base" title="Go to source" [imageUrl]="'assets/icons/newIcons/noun-arrow-5544651.svg'" class='header-btn' (click)='projectService.goTo(27)' style="background-color: #ffb500;"></button>
						}
						<button *ngIf="!hideNotes" kendoButton themeColor="base" title="Add/Edit Notes" [imageUrl]="'assets/icons/newIcons/noun-notepad-6607257.svg'" class='header-btn overview-notes-icon' (click)='projectService.openNotesWindow({id: 27, name: "Driving Path Analysis"})' [style.background-color]="isOverview ? '#ffb500' : 'white'"></button>
					</span>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1' class="driving-path-gantt-container">
			@if (showOverlay) {
				<div class="warning-div" style="height: 150px">
					No Remaining Activities on this path.
				</div>
			}@else if (loading || scheduleService.isLoading) {
				<div class='warning-div' style='height: 150px;'>
					Loading...
				</div>
			} @else {
				<div class="toolbar-container">
					<div class="data-date-blurb">
						Driving Path to {{selectedFinishMilestone?.task_name}}
					</div>
					<div class='gantt-legend-items-container' style="align-self: flex-start">
						<div style='margin: auto 0;'>
							Legend:
						</div>
						<div class='gantt-legend-item'>
							<div class='fake-gantt-bar' style='background-color: rgb(0 89 255); height: 20px;'></div>
							<div class='fake-gantt-bar-label'>Completed Work</div>
						</div>
						<div class='gantt-legend-item'>
							<div class='fake-gantt-bar' style='background-color: rgb(79, 201, 49); height: 20px;'></div>
							<div class='fake-gantt-bar-label'>Remaining Work</div>
						</div>
						<div class='gantt-legend-item'>
							<div class='fake-gantt-bar' style='background-color: rgb(223, 83, 83); height: 20px;'></div>
							<div class='fake-gantt-bar-label'>Critical Work</div>
						</div>
						<div class='gantt-legend-item'>
							<svg xmlns="http://www.w3.org/2000/svg"
									 width="20"
									 height="20"
									 viewBox="0 0 8 8"
									 style='height: 24px; width: 24px; transform: translate(-2px, 9px);'
							>
								<path d="M 3 0 L 6 3 L 3 6 L 0 3 L 3 0" fill="black"/>
							</svg>
							<div class='fake-gantt-bar-label' style='margin-left: -11px;'>Milestone</div>
						</div>
					</div>
				</div>
				<div *ngIf='criticalActivities?.length > 0' class="data-date-gantt-line-2"></div>
				<kendo-gantt
					[data]="criticalActivities"
					[activeView]="'year'"
					[selectable]="true"
					[currentTimeMarker]="false"
					[toolbarSettings]='{ position: "none" }'
					[timelinePaneOptions]='{ collapsed: false, collapsible: false, resizable: false }'
					[treeListPaneOptions]='{ collapsed: false, collapsible: false }'
					*ngIf='criticalActivities && criticalActivities?.length > 0'
					[style.max-height.px]="500"
					[taskClass]='taskCallback'
					[sort]="sort"
					[sortable]="true"
					(sortChange)='updateSort($event)'
					id="drivingPathGantt"
					#drivingPathGantt
					[class.gantt-year-view-no-months-project]='slotWidth < 35'
					[class.hideMonthLines]='slotWidth <= 20'
				>
					<kendo-gantt-column
						field="display"
						title="Activity"
						[width]="400"
					>
					</kendo-gantt-column>
					<kendo-gantt-column
						field="StartGantt"
						title="Start"
						format="MMM dd, yyyy"
						[width]="110"
						filter="date"
						[class]='["center-text"]'
					>
					</kendo-gantt-column>
					<kendo-gantt-column
						field="FinishGantt"
						title="End"
						format="MMM dd, yyyy"
						[width]="110"
						filter="date"
						[class]='["center-text"]'
					>
					</kendo-gantt-column>
					<kendo-gantt-column
						field="TF"
						title="TF"
						format='n0'
						[width]="60"
						[class]='["center-text"]'
					>
					</kendo-gantt-column>
					<kendo-gantt-timeline-year-view [slotWidth]="slotWidth"></kendo-gantt-timeline-year-view>
				</kendo-gantt>
			}
		</kendo-gridlayout-item>
	</kendo-gridlayout>
	@if (isOverview && hasNotes) {
		<app-overview-notes [componentId]="27"></app-overview-notes>
	}
</div>
<kendo-excelexport
	[data]="criticalActivities"
	[collapsible]="true"
	[fileName]="'Driving path to ' + selectedFinishMilestone?.task_code + '.xlsx'"
	#excelexport
>
	<kendo-excelexport-column
		field="Activity ID"
		title="Activity ID"
	>
	</kendo-excelexport-column>
	<kendo-excelexport-column
		field="Activity Type"
	>
	</kendo-excelexport-column>
	<kendo-excelexport-column
		field="Activity Status"
	>
	</kendo-excelexport-column>
	<kendo-excelexport-column
		field="Activity Name"
	>
	</kendo-excelexport-column>
	<kendo-excelexport-column
		field="OD"
		[width]="30"
	>
	</kendo-excelexport-column>
	<kendo-excelexport-column
		field="RD"
		[width]="30"
	>
	</kendo-excelexport-column>
	<kendo-excelexport-column
		field="% Complete"
	>
	</kendo-excelexport-column>
	<kendo-excelexport-column
		field="Start"
	>
	</kendo-excelexport-column>
	<kendo-excelexport-column
		field="Finish"
	>
	</kendo-excelexport-column>
	<kendo-excelexport-column
		field="TF"
		[width]="30"
	>
	</kendo-excelexport-column>
</kendo-excelexport>
