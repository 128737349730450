import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProjectDashboardService } from '../../../services/project/project.service';
import { ProfileCompanyPermission } from '../../../models/auth/account-user';
import { CostService } from '../../../services/common/cost.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { RiskRegister } from '../../../models/risk';
import { RiskRegisterInfo } from '../risk/risk-register/risk-register.component';
import { ProjectInterface } from '../../../models/Project';
import { ScheduleStorageService } from '../../../services/project/schedule-storage.service';
import { OverviewComponent } from '../project-admin-settings/overview-tab-settings/overview-tab-settings.component';

@Component({
	selector: 'app-project-overview',
	templateUrl: './project-overview.component.html',
	styleUrls: ['./project-overview.component.scss'],
})
export class ProjectOverviewComponent implements OnInit {
	@Input() visualizer: boolean = false;
	@Input() currentProjectCompanyPermissions: ProfileCompanyPermission = null;
	isValidCostProject: boolean = false;
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	activeRiskRegisters: Array<RiskRegister & RiskRegisterInfo> = [];
	@Input() $projectData = new BehaviorSubject<any>({});
	@Output() dialogStateChange = new EventEmitter<boolean>();
	clearDelaySelection = new BehaviorSubject<boolean>(false);
	numUpdates: number = 0;
	isDefault: boolean = true;
	userComponents: OverviewComponent[] = [];

	constructor(
		public projectService: ProjectDashboardService,
		public costService: CostService,
		public scheduleStorage: ScheduleStorageService
	) {}

	ngOnInit(): void {
		this.projectService.$currentProjectReport
			.pipe(takeUntil(this._unsubscribeAll), debounceTime(100))
			.subscribe((report) => {
				this.isValidCostProject = this.costService.isValidCostProject(
					this.projectService.$currentProjectReport.value?.cashFlowHistorical || null
				);
			});
		this.projectService.$currentProjectData.subscribe((projectData) => {
			const projectInfo = projectData as ProjectInterface;
			if (!projectInfo) {
				return;
			}
			this.activeRiskRegisters = JSON.parse(JSON.stringify(projectInfo.riskMitigation || [])).filter(
				(risk) => risk.isDraft === false
			);
			this.userComponents = projectData.selectedOverviewTabComponents || [];
			this.isDefault = projectData.overviewTabDefault !== false;
		});
		this.scheduleStorage.$allUpdates.pipe(takeUntil(this._unsubscribeAll)).subscribe((updates) => {
			this.numUpdates = updates.length;
		});
	}

	/**
	 * passes new dialogStateChange value along to project component
	 * @param newVal
	 */
	dialogStateChanged(newVal) {
		this.dialogStateChange.emit(newVal);
	}

	/**
	 * handles gantt selection toggle
	 * @param originalSelectionExisted
	 */
	ganttSelection(originalSelectionExisted: boolean) {
		// this.clearDelaySelection.next(true);
		// if (!originalSelectionExisted) {
		// 	this.scroll();
		// }
	}

	/**
	 * scroll page to component start (using components above critical path comparison bc of the navbar/overview overhang)
	 */
	scroll() {
		const el = document.getElementById(
			this.numUpdates < 2 ? 'schedules-grid' : 'scheduleDelaysComponent'
		) as HTMLElement;
		if (el) {
			el.scrollIntoView();
		}
	}
}
