import { Pipe, PipeTransform } from '@angular/core';
import { getNonZeroDecimalPosition } from '../strings';

@Pipe({
	name: 'DCMAPct',
})
export class DCMAPctPipe implements PipeTransform {
	transform(value: number, ...boundArgs: number[][]): string | number {
		return toDCMAPct(value, ...boundArgs);
	}
}

export const toDCMAPct = (value: number, ...boundArgs: number[][]): string | number => {
	const bounds = boundArgs[0] || [];
	if (bounds.length === 0) {
		return value;
	}
	const nonZeroDecimalPlace = Math.max(0, getNonZeroDecimalPosition((value * 100).toString()));
	const roundedValue = Math.round(value * 100).toFixed(0);
	for (const bound of bounds) {
		if (Number.isNaN(bound)) {
			continue;
		}
		const roundedBound = Math.round(bound * 100).toFixed(0);
		if (roundedBound === roundedValue) {
			return (value * 100).toFixed(nonZeroDecimalPlace < 0 ? 0 : nonZeroDecimalPlace);
		}
	}
	return roundedValue === '0' && value > 0 ? (value * 100).toFixed(nonZeroDecimalPlace) : roundedValue;
};
