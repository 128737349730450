import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'app-add-risk-button',
	templateUrl: 'add-risk-button.component.html',
	styleUrls: ['add-risk-button.component.scss'],
})
export class AddRiskButtonComponent {
	note: string;
	@Input()
	set riskOptions(riskOptions) {
		this.$riskOptions.next(riskOptions);
	}
	@Output() updateRiskMetricsType = new EventEmitter<string>();
	riskMetricsType = 'default';
	$riskOptions = new BehaviorSubject<any[]>([]);

	constructor() {}

	/**
	 * updates riskMetricsType value and emits that to parent components
	 * @param newRiskValue
	 */
	updateRiskValue(newRiskValue: string) {
		this.riskMetricsType = newRiskValue;
		this.updateRiskMetricsType.emit(this.riskMetricsType);
	}
}
