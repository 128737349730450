import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AxisSettings, SeriesData, SeriesDataSettings } from '../../../../models/ChartSettings';
import { buildPfTable, BuiltPfTable, hasObjChanged } from '../../../../util/projects';
import { CurrentProjectReport, ProjectDashboardService } from '../../../../services/project/project.service';
import { PFTableValues } from '../../risk/risk-performance-factor/risk-performance-factor/performance-factor.component';
import { PlotBand } from '@progress/kendo-angular-charts';
import { PfTableValue } from '@rhinoworks/analytics-calculations';
import { IActivityCode } from '@rhinoworks/xer-parse';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { RestService } from '../../../../services/common/rest.service';
import {
	actvTypeToParentDropdownItem,
	ParentDropdownItem,
	wbsDisplaySave,
	wbsToParentDropdownItem,
} from '../../risk/risk-performance-factor/risk-performance-factor/pf-table-dropdown/pf-table-dropdown.component';
import { format } from 'date-fns';

@Component({
	selector: 'app-performance-factor-historical',
	templateUrl: './performance-factor-historical.component.html',
	styleUrls: ['./performance-factor-historical.component.scss'],
})
export class PerformanceFactorHistoricalComponent implements OnInit {
	@Input() isOverview: boolean = false;
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	hideComponent = false;
	parentFromCode = new Map<number, PFTableValues>();
	saveClicked = new BehaviorSubject<boolean>(false);
	saveButtonEnabled: boolean = false;
	showNoSelection: boolean = true;
	nothingSelected: boolean = false;
	categories: string[] = [];
	seriesData: SeriesDataSettings[] = [];
	selectedActivityTypeName: string = null;
	public valuePlotBands: PlotBand[] = [
		{
			from: -999999,
			to: 100,
			color: '#DF5353',
			opacity: 0.2,
		},
		{
			from: 100,
			to: 999999,
			color: '#4fc931',
			opacity: 0.2,
		},
	];
	valueAxisItemSettings: AxisSettings[] = [
		{
			title: {
				text: 'Planned / Actual Duration',
				visible: true,
			},
			labels: {
				format: '{0}%',
			},
			plotBands: this.valuePlotBands,
		},
	];
	hasNotes: boolean = false;
	pfTable: BuiltPfTable = {
		pfRows: [],
		selectedActivities: new Set([]),
		unselectedActivities: new Set([]),
	};
	selectedPfRowKeys: Array<number | string>;
	nestedPfTable: Array<ParentDropdownItem> = [];
	savedPfKeys: Array<number | string>;

	constructor(
		public projectService: ProjectDashboardService,
		public scheduleService: ScheduleStorageService,
		private restService: RestService
	) {}

	ngOnInit(): void {
		this.projectService.$currentProjectReport.pipe(takeUntil(this._unsubscribeAll)).subscribe(async (report) => {
			if (!report?.pfTableHistorical) {
				return;
			}
			if (!report?.project || !report?.pfTableHistorical) {
				return;
			}
			if (!report?.riskPage?.performanceFactor?.selectedActivityCodes) {
				return;
			}

			this.pfTable = buildPfTable(report?.pfTableHistorical, this.selectedPfRowKeys);
			const xer = await this.scheduleService.grabUpdateXer(
				report.project.updateIds[report.project.updateIds.length - 1]
			);
			const selectedCodes = report.project.performanceTrendingSelectedActivityCodes || [];
			const selectedWbs = report.project.performanceTrendingSelectedWbs || [];
			const actvCodes = Array.from(xer.activityCodes.values());
			const wbs = Array.from(xer.wbs.values());
			const codeDisplayToId = new Map<string, number>(
				actvCodes.map((code) => [`${code.shortName} ${code.codeName}`, code.id])
			);
			const wbsDisplayToId = new Map<string, number>(wbs.map((wbs) => [wbsDisplaySave(wbs), wbs.id]));
			this.savedPfKeys = [
				...selectedCodes.map((desc) => codeDisplayToId.get(desc)),
				...selectedWbs.map((desc) => wbsDisplayToId.get(desc)),
			].filter((id) => !!id);
			this.selectedPfRowKeys = [...this.savedPfKeys];
			this.selectedActivityTypeName = report.project.performanceTrendingSelectedCodeType;
			for (const code of actvCodes) {
				const item = this.pfTable.pfRows.find((row) => row.shortName === code.shortName);
				if (item) {
					item.typeId = code.typeId;
				}
			}
			const actvTypes = Array.from(xer.activityTypes.values());
			const activityCodesByType = new Map<number, IActivityCode[]>(
				actvTypes.map((actvType) => [
					actvType.id,
					actvCodes.filter(
						(code) => code.typeId === actvType.id && this.pfTable.pfRows.some((pf) => pf.activityCodeId === code.id)
					),
				])
			);
			this.nestedPfTable = [...activityCodesByType.entries()]
				.filter(([, codes]) => codes.length > 0)
				.map(
					([typeId, codes]): ParentDropdownItem =>
						actvTypeToParentDropdownItem(xer.activityTypes.get(typeId), codes, this.pfTable)
				)
				.concat(
					Array.from(xer.wbs.values())
						.filter((wbs) => !wbs.parentWbs)
						.map((wbs) => wbsToParentDropdownItem(wbs, this.pfTable))
				);
			this.updateChartData(report);
		});
		this.projectService.$currentProjectData.subscribe((val) => {
			if (val) {
				const savedNotes = val.componentNotes?.find((n) => n.id === 6)?.notes;
				this.hasNotes = savedNotes?.length && savedNotes[savedNotes?.length - 1]?.note !== '';
			}
		});
	}

	onSelectedKeysChange(keys: Array<number | string>): void {
		console.log('onselected keys', keys);
		const existing = new Set(this.savedPfKeys);
		this.saveButtonEnabled = existing.size !== keys.length || keys.some((key) => !existing.has(key));
		this.updateChartData();
	}
	onSelectedTypeChange(typeName: string): void {
		this.selectedActivityTypeName = typeName;
	}

	/**
	 * updates chart values with new ones
	 * @param report
	 */
	updateChartData(report: CurrentProjectReport = this.projectService.$currentProjectReport.value): void {
		if (!this.selectedPfRowKeys.length) {
			this.nothingSelected = true;
			this.seriesData = null;
			this.showNoSelection = true;
			return;
		}
		this.showNoSelection = false;

		const codesPfData: Map<number | string, SeriesData[]> = new Map<number, SeriesData[]>();
		const colors: Map<number | string, string> = new Map<number, string>();
		const valuesByUpdate: PfTableValue[][] = report?.pfTableHistorical;

		const colorOptions: string[] = [
			'Red',
			'Blue',
			'Green',
			'Yellow',
			'Orange',
			'Purple',
			'Pink',
			'Brown',
			'Gray',
			'Black',
			'White',
			'Cyan',
			'Magenta',
			'Lime',
			'Indigo',
			'Violet',
			'Turquoise',
			'Teal',
			'Lavender',
			'Maroon',
			'Beige',
			'Tan',
			'Salmon',
			'Olive',
			'Gold',
			'Silver',
			'Coral',
			'Khaki',
			'Crimson',
			'Ivory',
			'Navy',
			'Peach',
			'Plum',
			'Chocolate',
			'Amber',
			'Mint',
			'Emerald',
			'Jade',
			'Sapphire',
			'Ruby',
			'Charcoal',
			'Rose',
			'Periwinkle',
			'Burgundy',
			'Chartreuse',
			'Aquamarine',
			'Mustard',
			'Azure',
			'Mauve',
			'Slate',
		];

		const categories = [];
		let j = 0;
		valuesByUpdate.forEach((update: PfTableValue[], i) => {
			const dataDateFromUpdate: Date = new Date(
				this.scheduleService.$allUpdates.value[i]?.dataDate ||
					report?.projectCompletionTrend?.projectCompletionTrendArray?.[i]?.dataDate
			);
			const dateString: string = dataDateFromUpdate ? format(dataDateFromUpdate, 'MMM dd, yyyy') : '';
			const updateName: string = i === 0 ? 'Baseline' : `Update ${i}`;
			const category: string =
				(dateString === '' ? updateName : updateName + '\n' + dateString) +
				(this.scheduleService.$allUpdates.value[i]?.baseline ? ' ®' : '');
			categories.push(category);
			update.forEach((pfRow: PfTableValue) => {
				if (this.selectedPfRowKeys.includes(pfRow.wbsKey || pfRow.activityCodeId)) {
					if (!colors.has(pfRow.activityCodeId || pfRow.wbsKey)) {
						colors.set(pfRow.activityCodeId || pfRow.wbsKey, colorOptions[j]);
						j++;
					}
					const newValue = { category: categories[i], value: pfRow.pf * 100, name: pfRow.activityCode };

					if (codesPfData.has(pfRow.activityCodeId || pfRow.wbsKey)) {
						const existingValues = codesPfData.get(pfRow.activityCodeId || pfRow.wbsKey) || [];
						if (existingValues.length < i + 1 && pfRow.numActivities > 0) {
							existingValues.push(newValue);
							codesPfData.set(pfRow.activityCodeId || pfRow.wbsKey, existingValues);
						}
					} else {
						codesPfData.set(pfRow.activityCodeId || pfRow.wbsKey, [newValue]);
					}
				}
			});
		});

		const seriesData: SeriesDataSettings[] = [];
		for (const [name, data] of codesPfData) {
			const normalizedData: SeriesData[] = this.getNormalizedData(data, categories);
			seriesData.push({
				type: 'line',
				color: colors.get(name),
				data: normalizedData,
				name: data[0].name,
				visible: true,
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			});
		}
		if (hasObjChanged(this.seriesData, seriesData)) {
			this.seriesData = seriesData;
		}
		if (hasObjChanged(categories, this.categories)) {
			this.categories = categories;
		}
		this.nothingSelected = false;
	}

	/**
	 * fills in any updates where numActivities === 0 or before the code was made/activities assigned with gaps in the
	 * chart instead of aligning them with wrong updates
	 * @param data
	 * @param categories
	 */
	getNormalizedData(data: SeriesData[], categories: string[]): SeriesData[] {
		if (data?.length === categories?.length) {
			return data;
		}
		const normalizedData: SeriesData[] = [];
		categories.forEach((c: string) => {
			const matchingData: SeriesData = data.find((d: SeriesData) => d.category === c);
			normalizedData.push(matchingData ? matchingData : { category: c, value: null });
		});
		return normalizedData;
	}

	submit() {
		const xer = this.scheduleService.cachedXers.get(
			this.projectService.$currentProjectData.value.updateIds[
				this.projectService.$currentProjectData.value.updateIds.length - 1
			]
		);
		const performanceTrendingSelectedWbs: string[] = [];
		const performanceTrendingSelectedActivityCodes: string[] = [];
		console.log('saving', this.selectedPfRowKeys);
		for (const key of this.selectedPfRowKeys) {
			if (typeof key === 'number' && xer.activityCodes.has(key)) {
				const actvCode = xer.activityCodes.get(key);
				performanceTrendingSelectedActivityCodes.push(`${actvCode.shortName} ${actvCode.codeName}`);
			} else if (typeof key === 'string') {
				performanceTrendingSelectedWbs.push(key);
			}
		}

		const projectId: string = this.projectService.$currentProjectData.value?._id;
		if (projectId !== undefined) {
			this.restService
				.patch(`project/${projectId}`, {
					performanceTrendingSelectedActivityCodes,
					performanceTrendingSelectedWbs,
					performanceTrendingSelectedCodeType: this.selectedActivityTypeName,
				})
				.subscribe((val) => {
					console.log('patch res', val);
					this.savedPfKeys = val.project.performanceTrendingSelectedActivityCodes;
					this.saveButtonEnabled = false;
				});
		}
	}
}
