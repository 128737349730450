import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RiskPerformanceFactorModule } from './risk-performance-factor/risk-performance-factor.module';
import { RiskCriticalPathRiskModule } from './risk-critical-path-risk/risk-critical-path-risk.module';
import { AddRiskModule } from './add-risk/add-risk.module';
import { PfTableFilterModalModule } from './pf-table-filter-modal/pf-table-filter-modal.module';
import { RiskCriticalPathReliabilityModule } from './risk-critical-path-reliability/risk-critical-path-reliability.module';
import { SharedMatModule } from '../../shared/shared-mat.module';
import { HighchartsChartModule } from 'highcharts-angular';
import { SharedIonModule } from '../../shared/shared-ion.module';
import { RiskRegisterModule } from './risk-register/risk-register.module';
import { RiskPageComponent } from './risk-page.component';
import { RiskRegisterComponent } from './risk-register/risk-register.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule } from '@angular/forms';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { RiskCalculationMethodModule } from '../risk-calculation-method/risk-calculation-method.module';
import { IndexTrendingModule } from './index-trending/index-trending.module';
import { RiskSettingsModule } from './risk-settings/risk-settings.module';
import { TooltipMenuModule } from '../../portfolio/tooltip-menu/tooltip-menu.module';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { LabelModule } from '@progress/kendo-angular-label';
import { GridLayoutModule } from '@progress/kendo-angular-layout';
import { AddBaselineModule } from '../top-row/add-baseline/add-baseline.module';
import { TopRisksModule } from './top-risks/top-risks.module';
import { RadioButtonModule } from '@progress/kendo-angular-inputs';
import { PerformanceFactorsModule } from './performance-factors/performance-factors.module';
import { OverviewNotesModule } from '../../shared/overview-notes/overview-notes.module';
import { CostPageModule } from '../cost/cost-page.module';

@NgModule({
	declarations: [RiskPageComponent, RiskRegisterComponent],
	exports: [RiskPageComponent, RiskRegisterComponent],
	imports: [
		CommonModule,
		AddRiskModule,
		PfTableFilterModalModule,
		RiskCriticalPathRiskModule,
		RiskPerformanceFactorModule,
		SharedIonModule,
		SharedMatModule,
		RiskCriticalPathReliabilityModule,
		HighchartsChartModule,
		RiskRegisterModule,
		GridModule,
		DialogsModule,
		ButtonsModule,
		DropDownsModule,
		FormsModule,
		PopupModule,
		TreeViewModule,
		RiskCalculationMethodModule,
		IndexTrendingModule,
		RiskSettingsModule,
		TooltipMenuModule,
		TooltipsModule,
		LabelModule,
		GridLayoutModule,
		AddBaselineModule,
		TopRisksModule,
		RadioButtonModule,
		PerformanceFactorsModule,
		OverviewNotesModule,
		CostPageModule,
	],
})
export class RiskPageModule {}
