import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PfTableValue, ProjectReportInterface } from '../../../../models/ProjectReport/ProjectReport';
import { AxisSettings, SeriesData, SeriesDataSettings } from '../../../../models/ChartSettings';
import { hasObjChanged } from '../../../../util/projects';
import { ProjectDashboardService } from '../../../../services/project/project.service';
import { PFTableValues } from '../risk-performance-factor/risk-performance-factor/performance-factor.component';
import { PlotBand } from '@progress/kendo-angular-charts';

@Component({
	selector: 'app-performance-factors',
	templateUrl: './performance-factors.component.html',
	styleUrls: ['./performance-factors.component.scss'],
})
export class PerformanceFactorsComponent implements OnInit {
	@Input() isOverview: boolean = false;
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	hideComponent = false;
	parentFromCode = new Map<string, PFTableValues>();
	saveClicked = new BehaviorSubject<boolean>(false);
	saveButtonEnabled: boolean = false;
	selectedCodeArray: Array<string> = [];
	showNoSelection: boolean = false;
	nothingSelected: boolean = false;
	report;
	categories: string[] = [];
	seriesData: SeriesDataSettings[] = [];
	selectedActivityTypeName: string = null;
	public valuePlotBands: PlotBand[] = [
		{
			from: -999999,
			to: 100,
			color: '#DF5353',
			opacity: 0.2,
		},
		{
			from: 100,
			to: 999999,
			color: '#4fc931',
			opacity: 0.2,
		},
	];
	valueAxisItemSettings: AxisSettings[] = [
		{
			title: {
				text: 'Planned / Actual Duration',
				visible: true,
			},
			labels: {
				format: '{0}%',
			},
			plotBands: this.valuePlotBands,
		},
	];
	hasNotes: boolean = false;

	constructor(public projectService: ProjectDashboardService) {}

	ngOnInit(): void {
		this.projectService.$currentProjectReport.pipe(takeUntil(this._unsubscribeAll)).subscribe((report) => {
			if (!report?.pfTableHistorical) {
				return;
			}
			console.log('current pf data', report.riskPage.performanceFactor.pfTable);

			this.report = report;
			this.updateChartData(report);
		});
		this.projectService.$currentProjectData.subscribe((val) => {
			if (val) {
				const savedNotes = val.componentNotes?.find((n) => n.id === 6)?.notes;
				this.hasNotes = savedNotes?.length && savedNotes[savedNotes?.length - 1]?.note !== '';
			}
		});
	}

	/**
	 * updates chart values with new ones
	 * @param report
	 */
	updateChartData(report: Pick<ProjectReportInterface, 'pfTableHistorical' | 'baselineUpdateId' | 'updateIds'>): void {
		if (!this.selectedCodeArray.length) {
			this.nothingSelected = true;
			this.seriesData = null;
			return;
		}
		console.log('historical pf data', report?.pfTableHistorical);

		const codesPfData: Map<string, SeriesData[]> = new Map<string, SeriesData[]>();
		const valuesByUpdate: PfTableValue[][] = report?.pfTableHistorical;

		const categories = [];
		valuesByUpdate.forEach((update: PfTableValue[], i) => {
			const category =
				i === 0 ? 'Baseline' : 'Update ' + i + (report?.baselineUpdateId === report?.updateIds[i] ? ' ®' : '');
			categories.push(category);

			update.forEach((activityCode: PfTableValue) => {
				if (
					this.selectedCodeArray.includes(activityCode.description) &&
					this.parentFromCode.get(activityCode.activityCode)?.activityCode === this.selectedActivityTypeName
				) {
					const newValue = { category: categories[i], value: activityCode.pf * 100 };

					if (codesPfData.has(activityCode.activityCode)) {
						const existingValues = codesPfData.get(activityCode.activityCode);

						if (existingValues.length < i + 1 && activityCode.numActivities > 0) {
							existingValues.push(newValue);
							codesPfData.set(activityCode.activityCode, existingValues);
						}
					} else {
						codesPfData.set(activityCode.activityCode, [newValue]);
					}
				}
			});

			/*for (let j = 0; j < update.length; j++) {
				const activityCode = update[j];
				if (this.selectedCodeArray.includes(activityCode.description)) {
					if (codesPfData.has(activityCode.activityCode)) {
						const previousVal = codesPfData.get(activityCode.activityCode);
						previousVal.push({ category: categories[i], value: activityCode.pf * 100 });
						codesPfData.set(activityCode.activityCode, previousVal);
					} else {
						codesPfData.set(activityCode.activityCode, [{ category: categories[i], value: activityCode.pf * 100 }]);
					}
				}
			}*/
		});

		const seriesData: SeriesDataSettings[] = [];
		for (const [name, data] of codesPfData) {
			seriesData.push({
				type: 'line',
				data,
				name,
				visible: true,
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			});
		}
		if (hasObjChanged(this.seriesData, seriesData)) {
			this.seriesData = seriesData;
		}
		if (hasObjChanged(categories, this.categories)) {
			this.categories = categories;
		}
		this.nothingSelected = false;
	}

	updateSelectedCodes(e) {
		this.selectedCodeArray = e;
		this.updateChartData(this.report);
	}

	initializeParentMap(e) {
		this.parentFromCode = e;
		this.updateChartData(this.report);
	}

	submit() {
		this.saveClicked.next(true);
	}

	cancelChange(ev: boolean) {
		this.saveButtonEnabled = ev;
	}

	activityCodeDropDownNameChange(ev: string) {
		if (ev) {
			this.selectedActivityTypeName = ev;
			this.updateChartData(this.report);
		}
	}

	showNoSelectionText(ev: boolean) {
		this.showNoSelection = ev;
	}

	minimizeComponentIfNoOptions(ev: boolean) {
		if (ev) {
			this.hideComponent = true;
		}
	}
}
