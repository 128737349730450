<div id="overview-tab-settings">
	<div class="content-container">
		<div class="desc" style="text-align: center;">
			The Overview Tab has options to customize the components shown or show a default selection.
		</div>
		<div class="radio">
			<kendo-radiobutton
				#defaultOption
				value="default"
				[(ngModel)]="radioSelection"
				(checkedChange)="radioChoiceChanged($event, 'default')"
			></kendo-radiobutton>
			<kendo-label
				class="k-radio-label grouping-option-label"
				[for]="defaultOption"
				text="Default"
				style="margin-right: 25px;"
			></kendo-label>
			<kendo-radiobutton
				#custom
				value="custom"
				[(ngModel)]="radioSelection"
				(checkedChange)="radioChoiceChanged($event, 'custom')"
			></kendo-radiobutton>
			<kendo-label
				class="k-radio-label grouping-option-label"
				[for]="custom"
				text="Custom"
			></kendo-label>
		</div>
		<div class="selection-content">
			@if (radioSelection === 'custom') {
				<div class="desc" style="font-size: 12px;">
					Click the arrow buttons to add components to the selection. Drag to reorder or click the X button to deselect them.
				</div>
				<div class="adjustment-row">
					<kendo-gridlayout
						[rows]="['100%']"
						[cols]="['calc(50% - 5px)', 'calc(50% - 5px)']"
						[gap]="{ rows: 0, cols: 10 }"
						class='grid'
						style="height: 100%; width: 100%;"
						id="overviewTabOptionContainer"
						#overviewTabOptionContainer
					>
						<kendo-gridlayout-item [row]='1' [col]='1' class="gridlayout-container" style="justify-content: flex-start;">
							<div class="panel-title">
								<kendo-textbox
									[placeholder]="'Search...'"
									[clearButton]="true"
									[size]='"small"'
									[(value)]='searchTerm'
									(afterValueChangedStr)='search($event)'
									class="search"
								>
									<ng-template kendoTextBoxPrefixTemplate>
										<kendo-svgicon [icon]='icons.search'></kendo-svgicon>
										<kendo-textbox-separator></kendo-textbox-separator>
									</ng-template>
								</kendo-textbox>
								Options
								<button kendoButton themeColor="base" title="Move All" [svgIcon]="icons.arrowRight" [disabled]="!availableComponentOptions?.length" (click)='moveAll()' class="allBtn">All</button>
							</div>
							<div class="options-list">
								@for (component of availableComponentOptions; track component) {
									@if(!component.disabled) {
										<div class="option">
											<div class="label" style="width: calc(100% - 22px)">
												{{component.name}}
											</div>
											<span class="btn-row">
												<button kendoButton themeColor="base" title="Move To Selected List" [svgIcon]="icons.arrowRight" (click)='moveItem(component)' class="itemBtn"></button>
											</span>
										</div>
									} @else {
										<span style='display: inline-flex; align-items: center;float: right' kendoTooltip>
											<div class="option disabled-type" title="Option unavailable for this project">
												<div class="label" style="width: calc(100% - 22px)">
													{{component.name}}
												</div>
												<span class="btn-row">
													<button kendoButton themeColor="base" title="" [svgIcon]="icons.arrowRight" [disabled]="true" class="itemBtn"></button>
												</span>
											</div>
										</span>
									}
								}
							</div>
						</kendo-gridlayout-item>
						<kendo-gridlayout-item [row]='1' [col]='2' class="gridlayout-container" style="justify-content: flex-start;">
							<div class="panel-title">
								Selected
								<button kendoButton themeColor="base" title="Remove All" [svgIcon]="icons.close" [disabled]="!selectedComponentOptions?.length" (click)='removeAll()' class="allBtn">All</button>
							</div>
							<div class="options-list" dragula="SELECTEDCOMPONENTS" [(dragulaModel)]="selectedComponentOptions">
								@for (item of selectedComponentOptions; track item) {
									<div class="rowItem">
										<div class="option" style="width: calc(100% - 10px);">
											<span class="moveContainer">
												<kendo-svgicon [icon]='icons.move' title="Move" class="dragHandle"></kendo-svgicon>
											</span>
											<span
												class="option-name"
												style="width: calc(100% - 66px); align-items: center; overflow: hidden; position: relative; display: inline-block; text-overflow: ellipsis;"
											>
												{{item.name}}
											</span>
											<span class="btn-row">
												<button kendoButton themeColor="base" title="Deselect" [svgIcon]="icons.close" (click)='deselectItem(item)' class="itemBtn"></button>
											</span>
										</div>
									</div>
								}
							</div>
						</kendo-gridlayout-item>
					</kendo-gridlayout>
				</div>
			}
		</div>
	</div>
</div>
