<!-- TO GET THE SILVER STYLE USE -> [styles]="widgets.widget6.styles" OTHER STYLES ON https://mapstyle.withgoogle.com/-->
<div id='map' style='border-bottom-left-radius: 4px; border-bottom-right-radius: 4px;'>
</div>
<!--
  <div *ngFor='let marker of markers; let index = i'
       style='position: absolute;'
       [attr.id]='"marker" + marker.id'>
    <app-map-icon
      [color]='marker.icon.color'
      [icon]='marker.icon.name'
      [width]='marker.icon.scaledSize.width'
      [height]='marker.icon.scaledSize.height'
      (click)='navigateToSingle(marker.id, index)'>
    </app-map-icon>
  </div>
-->
