import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { RestService } from 'services/common/rest.service';
import { Router } from '@angular/router';

@Component({
	selector: 'forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ForgotPasswordComponent implements OnInit {
	forgotPasswordForm: UntypedFormGroup;
	httpProcessing = false;

	/**
	 * Constructor
	 *
	 * @param {FormBuilder} _formBuilder
	 */
	constructor(
		private _formBuilder: UntypedFormBuilder,
		private restService: RestService,
		private router: Router
	) {
		// Configure the layout
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.forgotPasswordForm = this._formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
		});
	}

	submit(): void {
		console.log('this.userForm', JSON.stringify(this.forgotPasswordForm.value));
		console.log('email', this.forgotPasswordForm.value.email);
		this.httpProcessing = true;

		const url = 'user/resendactivationcode/' + this.forgotPasswordForm.value.email;
		const body = this.forgotPasswordForm.value;
		console.log('body', body);

		if (!body.companyId) {
			delete body.companyId;
		}

		if (!body.projectIds) {
			delete body.projectIds;
		}

		// TODO: replace snackbar from fuse
		/*this.restService.post(url, body).subscribe(
			(response) => {
				console.log('response', response);
				this.httpProcessing = false;
				this.snackBar.open(response.message || 'Email sent successfully!', 'Success!', {
					duration: 500,
					horizontalPosition: 'end',
					verticalPosition: 'top',
				});
				// this.router.navigate(['/auth/login']);
			},
			(error) => {
				console.log('error', error);
				this.httpProcessing = false;
				this.snackBar.open(error.message || 'Something went wrong!', 'Error!', {
					duration: 500,
					horizontalPosition: 'end',
					verticalPosition: 'top',
				});
			}
		);*/
		console.log('end of submit function');
	}
}
