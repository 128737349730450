import { ChangeDetectorRef, Directive, OnInit, OnDestroy } from '@angular/core';
import { DatePickerComponent } from '@progress/kendo-angular-dateinputs';

@Directive({
	selector: '[clearInputButton]',
})
export class ClearDirective implements OnInit, OnDestroy {
	private button: HTMLButtonElement;
	private clickHandler = () => {
		this.component.value = null;
		this.component.valueChange.emit(null);
		this.cdr.markForCheck();
	};

	constructor(
		private component: DatePickerComponent,
		private cdr: ChangeDetectorRef
	) {}

	public ngOnInit(): void {
		this.button = document.createElement('button');
		this.button.setAttribute('kendoButton', '');
		this.button.setAttribute('type', 'button');
		this.button.setAttribute('icon', 'close');
		this.button.setAttribute('fillMode', 'flat');
		this.button.setAttribute('themeColor', 'primary');
		this.button.className = 'k-input-button k-button k-icon-button k-button-md k-button-solid k-button-solid-base';
		this.button.innerHTML = '<span class="k-button-icon k-icon k-i-close"></span>';
		this.button.addEventListener('click', this.clickHandler);

		this.component.wrapper.nativeElement.appendChild(this.button);
	}

	public ngOnDestroy(): void {
		this.button.removeEventListener('click', this.clickHandler);
	}
}
