import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProjectListItem } from '../project-list/project-list/project-list.component';
import { addMonths, isAfter, subMonths } from 'date-fns';
import { ProjectDashboardService } from '../../../services/project/project.service';
import { CostService } from '../../../services/common/cost.service';
import { QuickViewOption } from '../../../models/Project';

@Component({
	selector: 'app-cost-banner',
	templateUrl: './cost-banner.component.html',
	styleUrls: ['./cost-banner.component.scss'],
})
export class CostBannerComponent implements OnInit {
	@Input() $filteredProjects = new BehaviorSubject<Array<ProjectListItem>>(null);
	@Input() loading = false;
	@Input() selectedQuickView: QuickViewOption;
	@Output() quickViewChanged = new EventEmitter<QuickViewOption>();
	$costProjects = new BehaviorSubject<Array<ProjectListItem>>(null);
	$lastMonthVariance = new BehaviorSubject<number>(null);
	categoriesHistorical = [];
	categoriesOther = [];

	constructor(
		public projectService: ProjectDashboardService,
		public costService: CostService
	) {}

	ngOnInit() {
		this.$filteredProjects.subscribe((projects) => {
			if (!projects) {
				return;
			}
			const costProjects = projects.filter((proj) => proj.cashFlow !== null);
			let latestDataDate: Date = null;
			costProjects.forEach((proj: ProjectListItem) => {
				if (proj.lastUpdatedDate && (latestDataDate === null || isAfter(proj.lastUpdatedDate, latestDataDate))) {
					latestDataDate = proj.lastUpdatedDate;
				}
			});
			const activeCostProjects = costProjects.filter((proj) => proj.scheduleType === 'Active');
			this.costService.$activeCostProjects.next(activeCostProjects?.length || null);
			const historicDates = [];
			const otherDates = [];
			for (let i = 5; i >= 0; i--) {
				const monthDate = subMonths(latestDataDate, i);
				const dateSplit = monthDate.toDateString().split(' ');
				historicDates.push(dateSplit[1] + ' ' + dateSplit[3]);
			}
			for (let i = 1; i < 7; i++) {
				const monthDate = addMonths(latestDataDate, i);
				const dateSplit = monthDate.toDateString().split(' ');
				otherDates.push(dateSplit[1] + ' ' + dateSplit[3]);
			}
			this.categoriesHistorical = historicDates;
			this.categoriesOther = otherDates;
			this.$costProjects.next(costProjects);
		});
	}

	quickViewChange(view): void {
		this.quickViewChanged.emit(view);
	}
}
