import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProjectInterface } from '../../../../../models/Project';
import { RiskSettingsComponent } from '../risk-settings/risk-settings.component';

@Component({
	selector: 'app-risk-settings-button',
	templateUrl: 'risk-settings-button.component.html',
	styleUrls: ['risk-settings-button.component.scss'],
})
export class RiskSettingsButtonComponent {
	@Input() $projectData: ProjectInterface;
	@Input() isFullscreen: boolean = false;
	@Output() dialogStateChange = new EventEmitter<boolean>();
	@Output() reverseDialogStateChange = new EventEmitter<boolean>();
	settingsOpened = false;

	constructor() {}

	toggleRiskSettingsState(newState: boolean) {
		this.settingsOpened = newState;
		this.dialogStateChange.emit(newState);
		if (!this.settingsOpened) {
			console.log('settingOpened');
			this.reverseDialogStateChange.emit(true);
		}
	}
}
