import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'forgot-password-2',
	templateUrl: './forgot-password-2.component.html',
	styleUrls: ['./forgot-password-2.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ForgotPassword2Component implements OnInit {
	forgotPasswordForm: UntypedFormGroup;

	/**
	 * Constructor
	 *
	 */
	constructor(private _formBuilder: UntypedFormBuilder) {}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.forgotPasswordForm = this._formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
		});
	}
}
