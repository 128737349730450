import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnalyticsDashboardService } from '../../../services/analytics/analytics.service';
import { BehaviorSubject } from 'rxjs';
import { ProjectInterface, QuickFilterPreset, QuickViewOption } from '../../../models/Project';
import { RiskRegister } from '../../../models/risk';
import { riskFormPresets } from '../../project-page/risk/risk-register/risk-register-form/risk-register-form.component';

export interface MitStatusTableData {
	label: string;
	count: number;
	percentage: number;
}

@Component({
	selector: 'app-risk-banner',
	templateUrl: './risk-banner.component.html',
	styleUrls: ['./risk-banner.component.scss'],
})
export class RiskBannerComponent implements OnInit {
	@Input() riskSummaryTrendCategories;
	@Input() riskSummaryTrendData;
	@Input() $allRiskProjects;
	@Input() $averageRiskScore;
	@Input() $totalMissedLastUpload;
	@Input() $totalSlipped;
	@Input() $riskRegisterProjects = new BehaviorSubject<Array<ProjectInterface>>([]);
	@Input() quickFilterWidgets: QuickFilterPreset;
	@Input() selectedQuickView: QuickViewOption;
	@Output() quickViewChanged = new EventEmitter<QuickViewOption>();
	@Output() updateVal = new EventEmitter<boolean>();
	allRisks = new BehaviorSubject<Array<RiskRegister>>([]);
	totalRiskProjectsValueLabel = 0;
	mitStatusCounts = new Map<string, number>([]);
	mitStatusTableVals: MitStatusTableData[] = [];

	constructor(public _analyticsDashboardService: AnalyticsDashboardService) {}

	ngOnInit(): void {
		this.$allRiskProjects.subscribe((newTotal) => {
			if (newTotal !== null) {
				this.totalRiskProjectsValueLabel = newTotal;
			}
		});
		this.$riskRegisterProjects.subscribe((riskRegisterProjects) => {
			this.mitStatusCounts.clear();
			this.mitStatusTableVals = [];
			const newRisks = [];
			riskRegisterProjects.forEach((project) => {
				project.riskMitigation.forEach((risk) => {
					if (risk.isDraft) {
						return;
					}
					// @ts-ignore
					risk.value = 1; //used for groupBy aggregation
					newRisks.push(risk);
					const key = risk.mitStatus.toString();
					const existingVal = this.mitStatusCounts.get(key);
					this.mitStatusCounts.set(key, existingVal === undefined ? 1 : existingVal + 1);
				});
			});
			this.allRisks.next(newRisks);
			riskFormPresets.mitStatus.forEach((status: string) => {
				const matchingCount = this.mitStatusCounts.get(status);
				this.mitStatusTableVals.push({
					label: status,
					count: matchingCount === undefined ? 0 : matchingCount,
					percentage: matchingCount === undefined ? 0 : (100 * matchingCount) / newRisks.length,
				});
			});
			this.mitStatusTableVals = this.mitStatusTableVals
				.sort((a: MitStatusTableData, b: MitStatusTableData) =>
					a?.count > b?.count ? -1 : b?.count > a?.count ? 1 : 0
				)
				.slice(0, 5);
		});
	}

	update() {
		this.updateVal.emit(true);
	}

	quickViewChange(view) {
		this.quickViewChanged.emit(view);
	}
}
