import { NgModule } from '@angular/core';
import { LoginComponent } from 'components/authentication/login/login.component';
import { LoginAuthGuard } from 'routes/auth/login-auth.guard';
import { IonicModule } from '@ionic/angular';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { SharedMatModule } from '../../shared/shared-mat.module';
import { LoginHolderComponent } from '../login-holder/login-holder.component';

@NgModule({
	declarations: [LoginComponent, LoginHolderComponent],
	exports: [LoginComponent],
	imports: [
		ReactiveFormsModule,
		CommonModule,
		IonicModule,
		HttpClientModule,
		CommonModule,
		SharedMatModule,
		NgOptimizedImage,
	],
	providers: [LoginAuthGuard, AuthenticationService],
})
export class LoginModule {}
