import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddProjectButtonComponent } from './add-project/add-project-button/add-project-button.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedMatModule } from 'components/shared/shared-mat.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

@NgModule({
	declarations: [AddProjectButtonComponent],
	imports: [
		/*		AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDLqs4B7aCg4mZv8SAO4bLr6Oy8zD1qKTw&libraries=places',
      libraries: ['places'],
    }),*/
		GoogleMapsModule,
		CommonModule,
		ReactiveFormsModule,
		SharedMatModule,
		MatDialogModule,
		MatButtonModule,
		MatInputModule,
		MatSelectModule,
		MatListModule,
		MatCardModule,
		TooltipModule,
	],
	exports: [AddProjectButtonComponent],
})
export class AddProjectModule {}
