import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectNotesTableComponent } from './project-notes-table/project-notes-table.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
	declarations: [ProjectNotesTableComponent],
	imports: [CommonModule, NgxDatatableModule],
})
export class ProjectNotesTableModule {}
