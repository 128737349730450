import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, zip } from 'rxjs';
import { PostMitigationRisk, PrePostMitigationRisk, RiskRegister } from 'models/risk';
import { ProjectDashboardService } from './project.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RiskRegisterInfo } from '../../components/project-page/risk/risk-register/risk-register.component';
import { RiskRegisterColumn } from '../../components/project-page/risk/risk-register/risk-register-table/risk-register-table.component';
import { RestService } from '../common/rest.service';
import { ProjectInterface } from '../../models/Project';

const CREATE_ACTION = 'create';
const UPDATE_ACTION = 'update';
const REMOVE_ACTION = 'destroy';

const itemIndex = (item: RiskRegister, data: RiskRegister[]): number => {
	for (let idx = 0; idx < data.length; idx++) {
		if (data[idx].riskId === item.riskId) {
			return idx;
		}
	}

	return -1;
};

const cloneData = (data: RiskRegister[]) => data.map((item) => Object.assign({}, item));

@Injectable()
export class RiskRegisterEditService extends BehaviorSubject<unknown[]> {
	private data: RiskRegister[] = [];
	private originalData: RiskRegister[] = [];
	private createdItems: RiskRegister[] = [];
	private updatedItems: RiskRegister[] = [];
	private deletedItems: RiskRegister[] = [];

	constructor(
		private restService: RestService,
		private http: HttpClient,
		private project: ProjectDashboardService
	) {
		super([]);
	}

	public read(): void {
		if (this.data.length) {
			return super.next(this.data);
		}

		this.fetch().subscribe((data) => {
			this.data = data;
			this.originalData = cloneData(data);
			super.next(data);
		});
	}

	public create(item: RiskRegister): void {
		this.createdItems.push(item);
		this.data.unshift(item);

		super.next(this.data);
	}

	public update(item: RiskRegister): void {
		if (!this.isNew(item)) {
			const index = itemIndex(item, this.updatedItems);
			if (index !== -1) {
				this.updatedItems.splice(index, 1, item);
			} else {
				this.updatedItems.push(item);
			}
		} else {
			const index = this.createdItems.indexOf(item);
			this.createdItems.splice(index, 1, item);
		}
	}

	public updateAll(items: RiskRegister[]): void {
		for (const item of items) {
			this.update(item);
		}
	}

	public remove(item: RiskRegister, hiddenColumns?: { riskPage?: string[]; fullscreen?: string[] }): void {
		let index = itemIndex(item, this.data);
		this.data.splice(index, 1);

		index = itemIndex(item, this.createdItems);
		if (index >= 0) {
			this.createdItems.splice(index, 1);
		} else {
			this.deletedItems.push(item);
		}
		index = itemIndex(item, this.updatedItems);
		if (index >= 0) {
			this.updatedItems.splice(index, 1);
		}
		if (hiddenColumns) {
			this.saveRegisters(this.data, hiddenColumns);
		}
		super.next(this.data);
	}

	public isNew(item: RiskRegister): boolean {
		return !item.riskId;
	}

	public hasChanges(): boolean {
		return !!(this.deletedItems.length || this.updatedItems.length || this.createdItems.length);
	}

	public cancelChanges(): void {
		this.reset();

		this.data = this.originalData;
		this.originalData = cloneData(this.originalData);
		super.next(this.data);
	}

	public assignValues(target: unknown, source: unknown): void {
		Object.assign(target, source);
	}

	private reset() {
		this.data = [];
		this.deletedItems = [];
		this.updatedItems = [];
		this.createdItems = [];
	}

	private fetch(action = '', data?: RiskRegister[]): Observable<RiskRegister[]> {
		return this.project.$currentRiskRegisters.asObservable();
	}

	saveRegister(
		register: RiskRegister,
		allRegisters: RiskRegister[],
		hiddenColumns: { riskPage?: string[]; fullscreen?: string[] }
	) {
		let isExistingRegister = false;
		let maxId = 0;
		for (let i = 0; i < allRegisters.length; i++) {
			if (allRegisters[i].riskId === register.riskId) {
				allRegisters[i] = register;
				isExistingRegister = true;
				break;
			}
			if (allRegisters[i].riskId > maxId) {
				maxId = allRegisters[i].riskId;
			}
		}
		if (!isExistingRegister) {
			register.riskId = maxId + 1;
			allRegisters.push(register);
		}
		return this.saveRegisters(allRegisters, hiddenColumns);
	}

	saveRegisters(registers: RiskRegister[], hiddenColumns: { riskPage?: string[]; fullscreen?: string[] }) {
		let unassignedCustomFields =
			this.project.$currentProjectData.value.preferences.riskMitigation.unassignedCustomFields;
		if (unassignedCustomFields === undefined) {
			unassignedCustomFields = {
				riskOwner: {
					name: [],
					email: [],
				},
				costOwner: {
					name: [],
					email: [],
				},
				responsibility: {
					name: [],
					email: [],
				},
				category: [],
			};
		}
		return this.restService
			.post('project/riskregister/' + this.project.$currentProjectPageId.value, {
				data: registers,
				hiddenColumns: {
					riskPage: Array.from(hiddenColumns.riskPage),
					fullscreen: Array.from(hiddenColumns.fullscreen),
				},
				unassignedCustomFields: {
					riskOwner: {
						name: Array.from(unassignedCustomFields?.riskOwner?.name) || [],
						email: Array.from(unassignedCustomFields?.riskOwner?.email) || [],
					},
					costOwner: {
						name: Array.from(unassignedCustomFields?.costOwner?.name) || [],
						email: Array.from(unassignedCustomFields?.costOwner?.email) || [],
					},
					responsibility: {
						name: Array.from(unassignedCustomFields?.responsibility?.name) || [],
						email: Array.from(unassignedCustomFields?.responsibility?.email) || [],
					},
					category: Array.from(unassignedCustomFields?.category) || [],
				},
			})
			.subscribe((resp: { proj: ProjectInterface }) => {
				const riskMitigations: Array<RiskRegister & RiskRegisterInfo> = resp.proj.riskMitigation || [];
				for (const mitigation of riskMitigations) {
					mitigation.preMitigation = new PrePostMitigationRisk(mitigation.preMitigation);
					mitigation.postMitigation = new PostMitigationRisk(mitigation.postMitigation);
					mitigation.PreRiskScore = mitigation.preMitigation.riskScore();
					mitigation.PostRiskScore = mitigation.postMitigation.riskScore(mitigation.preMitigation.probability);
					mitigation.RiskScoreDelta = mitigation.PreRiskScore - mitigation.PostRiskScore;
					mitigation.PostRanking = mitigation.postMitigation.postRanking();
					mitigation.StatusDate = new Date(mitigation.strategy.statusDate);
				}
				this.project.$currentRiskRegisters.next(riskMitigations);
			});
	}
}

export const riskRegisterFormGroup = (register: RiskRegister, rmt: string): FormGroup =>
	new FormGroup({
		riskId: new FormControl(register.riskId, [Validators.required]),
		mitStatus: new FormControl(
			register.mitStatus,
			rmt === 'coreRisk' ? [] : [Validators.required, Validators.minLength(1)]
		),
		riskName: new FormControl(register.riskName, [Validators.required, Validators.minLength(1)]),
		category: new FormControl(
			register.category,
			rmt === 'coreRisk' ? [] : [Validators.required, Validators.minLength(1)]
		),
		triggerPhase: new FormControl(register.triggerPhase),
		riskOwnerName: new FormControl(
			register.riskOwner?.name,
			rmt === 'coreRisk' ? [] : [Validators.required, Validators.minLength(1)]
		),
		riskOwnerEmail: new FormControl(register.riskOwner.email, [Validators.email]),
		costOwnerName: new FormControl(register.costOwner?.name),
		costOwnerEmail: new FormControl(register.costOwner.email, [Validators.email]),
		responsibilityName: new FormControl(
			register.responsibility?.name,
			rmt === 'coreRisk' ? [] : [Validators.required, Validators.minLength(1)]
		),
		responsibilityEmail: new FormControl(register.responsibility.email, [Validators.email]),
		description: new FormControl(register.description),
		effect: new FormControl(register.effect, rmt === 'coreRisk' ? [] : [Validators.required, Validators.minLength(1)]),
		impactedTaskCodes: new FormControl([...register.impactedTaskCodes]),
		strategyStrategy: new FormControl(
			register.strategy.strategy,
			rmt === 'coreRisk' ? [] : [Validators.required, Validators.minLength(1)]
		),
		strategyMeasures: new FormControl(register.strategy.measures),
		strategyStatusDate: new FormControl(register.strategy.statusDate),
		preMitigationProbability: new FormControl(
			register.preMitigation.probability,
			rmt === 'coreRisk' ? [] : [Validators.required, Validators.min(0), Validators.max(5)]
		),
		preMitigationScheduleImpact: new FormControl(
			register.preMitigation.scheduleImpact,
			rmt === 'coreRisk' ? [] : [Validators.required, Validators.min(0), Validators.max(5)]
		),
		preMitigationCostImpact: new FormControl(
			register.preMitigation.costImpact,
			rmt === 'coreRisk' ? [] : [Validators.required, Validators.min(0), Validators.max(5)]
		),
		preMitigationPerformanceImpact: new FormControl(
			register.preMitigation.performanceImpact,
			rmt === 'coreRisk' ? [] : [Validators.required, Validators.min(0), Validators.max(5)]
		),
		preMitigationQualityImpact: new FormControl(
			register.preMitigation.qualityImpact,
			rmt === 'coreRisk' ? [] : [Validators.required, Validators.min(0), Validators.max(5)]
		),
		preMitigationActivityImpact: new FormControl(register.preMitigation.activityImpact),
		preMitigationEstCost: new FormControl(register.preMitigation.estCost),
		preMitigationEstCostDisplay: new FormControl(register.preMitigation.estCost),
		preMitigationImpactVarianceMin: new FormControl({
			value: register.preMitigation.impactVarianceMin,
			disabled: true,
		}),
		preMitigationImpactVarianceMax: new FormControl({
			value: register.preMitigation.impactVarianceMax,
			disabled: true,
		}),
		postMitigationScheduleImpact: new FormControl(
			register.postMitigation.scheduleImpact,
			rmt === 'coreRisk' ? [] : [Validators.required, Validators.min(0), Validators.max(5)]
		),
		postMitigationCostImpact: new FormControl(
			register.postMitigation.costImpact,
			rmt === 'coreRisk' ? [] : [Validators.required, Validators.min(0), Validators.max(5)]
		),
		postMitigationPerformanceImpact: new FormControl(
			register.postMitigation.performanceImpact,
			rmt === 'coreRisk' ? [] : [Validators.required, Validators.min(0), Validators.max(5)]
		),
		postMitigationQualityImpact: new FormControl(
			register.postMitigation.qualityImpact,
			rmt === 'coreRisk' ? [] : [Validators.required, Validators.min(0), Validators.max(5)]
		),
		postMitigationActivityImpact: new FormControl(register.postMitigation.activityImpact),
		postMitigationEstCost: new FormControl(register.postMitigation.estCost),
		postMitigationEstCostDisplay: new FormControl(register.postMitigation.estCost),
		postMitigationImpactVarianceMin: new FormControl({
			value: register.postMitigation.impactVarianceMin,
			disabled: true,
		}),
		postMitigationImpactVarianceMax: new FormControl({
			value: register.postMitigation.impactVarianceMax,
			disabled: true,
		}),
		disabled: new FormControl(register.disabled),
		isDraft: new FormControl(register.isDraft),
		costVarianceMin: new FormControl({ value: register.costVarianceMin, disabled: true }),
		costVarianceMax: new FormControl({ value: register.costVarianceMin, disabled: true }),
	});

export const riskRegisterFormControl = (
	register: RiskRegister & RiskRegisterInfo,
	formBuilder: FormBuilder,
	columns: Map<string, RiskRegisterColumn>
) => ({
	mitStatus: [register.mitStatus, Validators.required],
	riskId: [register.riskId, Validators.required],
	riskName: [register.riskName, Validators.required],
	category: [register.category, Validators.required],
	triggerPhase: register.triggerPhase,
	effect: [register.effect, Validators.required],
	riskOwner: formBuilder.group({
		name: [register.riskOwner?.name, Validators.required],
		email: [register.riskOwner.email, Validators.email],
	}),
	costOwner: formBuilder.group({
		name: register.costOwner?.name,
		email: [register.costOwner.email, Validators.email],
	}),
	responsibility: formBuilder.group({
		name: [register.responsibility?.name, Validators.required],
		email: [register.responsibility.email, Validators.email],
	}),
	description: register.description,
	strategy: formBuilder.group({
		strategy: [register.strategy.strategy, Validators.required],
		measures: register.strategy.measures,
		statusDate: register.strategy.statusDate,
	}),
	StatusDate: register.StatusDate,
	preMitigation: formBuilder.group({
		probability: [
			register.preMitigation.probability,
			Validators.compose([Validators.required, Validators.min(0), Validators.max(5)]),
		],
		scheduleImpact: [
			register.preMitigation.scheduleImpact,
			Validators.compose([Validators.required, Validators.min(0), Validators.max(5)]),
		],
		costImpact: [
			register.preMitigation.costImpact,
			Validators.compose([Validators.required, Validators.min(0), Validators.max(5)]),
		],
		performanceImpact: [
			register.preMitigation.performanceImpact,
			Validators.compose([Validators.required, Validators.min(0), Validators.max(5)]),
		],
		qualityImpact: [
			register.preMitigation.qualityImpact,
			Validators.compose([Validators.required, Validators.min(0), Validators.max(5)]),
		],
		activityImpact: register.preMitigation.activityImpact,
		estCost: register.preMitigation.estCost,
		impactVarianceMin: register.preMitigation.impactVarianceMin,
		impactVarianceMax: register.preMitigation.impactVarianceMax,
	}),
	postMitigation: formBuilder.group({
		scheduleImpact: [
			register.postMitigation.scheduleImpact,
			Validators.compose([Validators.required, Validators.min(0), Validators.max(5)]),
		],
		costImpact: [
			register.postMitigation.costImpact,
			Validators.compose([Validators.required, Validators.min(0), Validators.max(5)]),
		],
		performanceImpact: [
			register.postMitigation.performanceImpact,
			Validators.compose([Validators.required, Validators.min(0), Validators.max(5)]),
		],
		qualityImpact: [
			register.postMitigation.qualityImpact,
			Validators.compose([Validators.required, Validators.min(0), Validators.max(5)]),
		],
		activityImpact: register.postMitigation.activityImpact,
		estCost: register.postMitigation.estCost,
		impactVarianceMin: register.postMitigation.impactVarianceMin,
		impactVarianceMax: register.postMitigation.impactVarianceMax,
	}),
	disabled: register.disabled,
	isDraft: register.isDraft,
});
