import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { UserService } from '../../../services/common/user.service';

@Component({
	selector: 'app-oauth2-callback',
	templateUrl: './oauth2-callback.component.html',
	styleUrls: ['./oauth2-callback.component.scss'],
})
export class Oauth2CallbackComponent implements OnInit {
	constructor(private activatedRoute: ActivatedRoute) {
		activatedRoute.queryParams.subscribe((params) => console.log('queryParams', params['code']));
	}

	ngOnInit(): void {}
}
