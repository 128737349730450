import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RiskRegister, SaasRiskBannerExtras } from '../../../../models/risk';

@Component({
	selector: 'app-risk-mitigation-scores-trending',
	templateUrl: './risk-mitigation-scores-trending.component.html',
	styleUrls: ['./risk-mitigation-scores-trending.component.scss'],
})
export class RiskMitigationScoresTrendingComponent implements OnInit {
	@Input() $allRisks = new BehaviorSubject<Array<RiskRegister & SaasRiskBannerExtras>>([]);

	constructor() {}

	ngOnInit() {
		this.$allRisks.subscribe((allRisks) => {
			console.log(allRisks);
		});
	}
}
