<kendo-gridlayout
	[rows]="['164.4px']"
	[cols]="['calc((300% / 12) - (15px / 4))', 'calc((300% / 12) - (15px / 4))', '(calc(360% / 12) - (15px / 4))', 'calc((240% / 12) - (15px / 4))']"
	[gap]="{ rows: 0, cols: 5 }"
	class='grid'
	style='grid-template-columns: calc((300% / 12) - (15px / 4)) calc((300% / 12) - (15px / 4)) calc((360% / 12) - (15px / 4)) calc((240% / 12) - (15px / 4))'
>
	<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-container'>
		<kendo-gridlayout
			[rows]="['42.4px', '122px']"
			[cols]="['100%']"
			[gap]="{ rows: 0, cols: 0 }"
			class='grid'
		>
			<kendo-gridlayout-item [row]='1' [col]='1' class='risk-card-header-container'>
				<div class="card-header">
					Risk Summary
				</div>
				<global-tooltip-open type="risk-summary"></global-tooltip-open>
			</kendo-gridlayout-item>
			<kendo-gridlayout-item [row]='2' [col]='1'>
				<kendo-gridlayout
					[rows]="['122px']"
					[cols]="['calc((400% / 12) - (10px / 3))', 'calc((400% / 12) - (10px / 3))', 'calc((400% / 12) - (10px / 3))']"
					[gap]="{ rows: 0, cols: 5 }"
					class='grid'
				>
					<kendo-gridlayout-item
						[row]='1'
						[col]='1'
						class='card-content'
						[class.filter-selected-card-black]="selectedQuickView === 'riskActive'"
						(click)="quickViewChange('riskActive');update()"
					>
						<div class="centerBoth" [style.color]='selectedQuickView === "riskActive"? "white": "black"'>{{ (totalRiskProjectsValueLabel) || '-' }}</div>
						<div class="filterBoxLabel">Active Risk Projects</div>
					</kendo-gridlayout-item>
					<kendo-gridlayout-item
						[row]='1'
						[col]='2'
						class='card-content'
						[class.filter-selected-card-orange]="selectedQuickView === 'riskMissingUpdate'"
						(click)="quickViewChange('riskMissingUpdate');update()"
					>
						<div class="centerBoth" [style.color]='selectedQuickView === "riskMissingUpdate"? "white": "#ff8c00"'>{{ ($totalMissedLastUpload | async) || '-' }}</div>
						<div class="filterBoxLabel">Missing Updates</div>
					</kendo-gridlayout-item>
					<kendo-gridlayout-item
						[row]='1'
						[col]='3'
						class='card-content'
						[class.red-score-selected]="selectedQuickView === 'riskSlipped'"
						(click)="quickViewChange('riskSlipped');update()"
					>
						<div class="centerBoth" [style.color]='selectedQuickView === "riskSlipped"? "white": "rgba(223, 83, 83, .9)"'>{{ ($totalSlipped | async) || '-' }}</div>
						<div class="filterBoxLabel">Projects Slipped</div>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			</kendo-gridlayout-item>
		</kendo-gridlayout>
	</kendo-gridlayout-item>
	<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-container'>
		<kendo-gridlayout
			[rows]="['42.4px', '122px']"
			[cols]="['100%']"
			[gap]="{ rows: 0, cols: 0 }"
			class='grid'
		>
			<kendo-gridlayout-item [row]='1' [col]='1' class='risk-card-header-container'>
				<div class="card-header">
					Portfolio Risk
				</div>
				<global-tooltip-open type="portfolio-risk"></global-tooltip-open>
			</kendo-gridlayout-item>
			<kendo-gridlayout-item [row]='2' [col]='1'>
				<kendo-gridlayout
					[rows]="['122px']"
					[cols]="['calc((400% / 12) - 2.5px)', 'calc((800% / 12) - 2.5px)']"
					[gap]="{ rows: 0, cols: 5 }"
					class='grid'
				>
					<kendo-gridlayout-item
						[row]='1'
						[col]='1'
						class='card-content'
						[ngClass]="{
										'blue-score-selected': ($averageRiskScore | async) >= 85,
										'green-score-selected': ($averageRiskScore | async) >= 70 && ($averageRiskScore | async) < 85,
										'red-score-selected': ($averageRiskScore | async) < 70
										}"
						style="cursor: default;"
					>
						<div class="centerBoth">{{ ($averageRiskScore | async) || '-' }}</div>
						<div class="filterBoxLabel">Risk Score</div>
					</kendo-gridlayout-item>
					<kendo-gridlayout-item
						[row]='1'
						[col]='2'
						class='card-content'
					>
						<app-risk-summary-trend
							[categories]="riskSummaryTrendCategories"
							[data]="riskSummaryTrendData"
							[chartType]="'line'"
							[isFromPortfolio]='true'
							class='score-trend-chart'
							style="height: 122px; cursor: default;"
						></app-risk-summary-trend>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			</kendo-gridlayout-item>
		</kendo-gridlayout>
	</kendo-gridlayout-item>
	<kendo-gridlayout-item [row]='1' [col]='3' class='gridlayout-container'>
		<kendo-gridlayout
			[rows]="['42.4px', '122px']"
			[cols]="['100%']"
			[gap]="{ rows: 0, cols: 0 }"
			class='grid'
		>
			<kendo-gridlayout-item [row]='1' [col]='1' class='risk-card-header-container'>
				<div class="card-header">
					Categories Of Risk
				</div>
				<global-tooltip-open type="categories-of-risk"></global-tooltip-open>
			</kendo-gridlayout-item>
			<kendo-gridlayout-item [row]='2' [col]='1'>
				<kendo-gridlayout
					[rows]="['122px']"
					[cols]="allRisks.value.length > 0 ? ['calc((800% / 12) - 2.5px)', 'calc((400% / 12) - 2.5px)'] : ['100%']"
					[gap]="{ rows: 0, cols: 5 }"
					class='grid'
				>
					<kendo-gridlayout-item
						[row]='1'
						[col]='1'
						class='card-content'
						*ngIf="allRisks.value.length > 0"
						style="cursor: default;"
					>
						<div style='height: 100%'>
							<span class="chart-title">Risk by Category</span>
							<app-risk-by-category
								[chartType]="'bar'"
								[title]="'Risk by Category'"
								[$risks]="allRisks"
								[groupBy]="'category'"
							></app-risk-by-category>
							<span class="chart-title">Risk by Owner</span>
							<app-risk-by-category
								[chartType]="'bar'"
								[title]="'Risk by Owner'"
								[$risks]="allRisks"
								[groupBy]="'riskOwner.name'"
							></app-risk-by-category>
						</div>
					</kendo-gridlayout-item>
					<kendo-gridlayout-item
						[row]='1'
						[col]='2'
						class='card-content'
						*ngIf="allRisks.value.length > 0"
						style="cursor: default;"
					>
						<div style='height: 100%;'>
							<span class="chart-title">Top Risks by Status</span>
							<div class='mitStatus-table-container'>
								<div *ngFor='let status of mitStatusTableVals' class='mitStatusItem'>
									<div class='mitStatusLabel'>
										{{status.label}}
									</div>
									<div class='mitStatusVal'>
										{{status.percentage | number: '1.0-0'}}%
									</div>
								</div>
							</div>
						</div>
					</kendo-gridlayout-item>
					<kendo-gridlayout-item
						[row]='1'
						[col]='1'
						class='card-content'
						*ngIf="allRisks.value.length <= 0"
						style="cursor: default;"
					>
						<div class="no-data-chart-warning">
							None of the selected projects have risks in their risk registers. Add a risk to any risk register to view metrics.
						</div>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			</kendo-gridlayout-item>
		</kendo-gridlayout>
	</kendo-gridlayout-item>
	<kendo-gridlayout-item [row]='1' [col]='4' class='gridlayout-container'>
		<kendo-gridlayout
			[rows]="['42.4px', '122px']"
			[cols]="['100%']"
			[gap]="{ rows: 0, cols: 0 }"
			class='grid'
		>
			<kendo-gridlayout-item [row]='1' [col]='1' class='risk-card-header-container'>
				<div class="card-header">
					Heat Map
				</div>
				<global-tooltip-open type="heat-map"></global-tooltip-open>
			</kendo-gridlayout-item>
			<kendo-gridlayout-item [row]='2' [col]='1' [class.card-content]='allRisks.value.length <= 0' style="cursor: default;">
				<div *ngIf="allRisks.value.length > 0" class='likelihood-chart-container'>
					<span class="chart-title">Likelihood vs. Impact</span>
					<app-likelihood-heatmap
						[$risks]="allRisks"
					></app-likelihood-heatmap>
				</div>
				<div *ngIf="allRisks.value.length <= 0" class="no-data-chart-warning">
					None of the selected projects have risks in their risk registers. Add a risk to any risk register to view metrics.
				</div>
			</kendo-gridlayout-item>
		</kendo-gridlayout>
	</kendo-gridlayout-item>
</kendo-gridlayout>
