<div id="dashboard-analytics" style='z-index: 999999; position: relative; top: 0; left: 0; width: 100vw;' class="page-layout blank no-select new-background" [ngClass]="{'fixed-position-underlay': dialogOpened}">

	<!-- top rows -->
	<ion-grid>
		<app-top-row [riskMetricsOptions]='riskMetricsOptions' (dialogStateChange)='dialogStateChanged($event)'></app-top-row>
	</ion-grid>
	<!-- no baseline -->
	<ion-grid *ngIf="($projectData | async)">
		<ion-row
			style="margin-top: 20vh;"
			*ngIf='($projectData | async) && !($projectData | async)?.updateIds?.length && !($projectData | async)?.updateLock'
		>
			<ion-col size='auto' style='margin-left:auto;margin-right: auto'>
				<ion-card>
					<ion-card-content style='margin-left:auto;margin-right: auto;width:100%;padding: 30px !important'>
						<h2 style="text-align: center;margin: 20px;" *ngIf="_projectDashboardService.fullyLoaded">
							Project Has No Updates!
						</h2>
						<app-add-baseline-button *ngIf="_projectDashboardService.fullyLoaded"></app-add-baseline-button>
						<h2 style="text-align: center;margin: 20px"  *ngIf="!_projectDashboardService.fullyLoaded">Loading Project, this may take a moment...</h2>
						<ion-progress-bar type="indeterminate" *ngIf="!_projectDashboardService.fullyLoaded"></ion-progress-bar>
					</ion-card-content>
				</ion-card>
			</ion-col>
		</ion-row>
		<!-- queue stuff -->
		<ion-row *ngIf="($projectData | async)?.updateLock" style="margin-top: 20vh;">
			<ion-col size='auto' style='margin-left:auto;margin-right: auto'>
				<ion-card>
					<ion-card-content style='margin-left:auto;margin-right: auto;width:100%;padding: 0px !important'>
						<div style='padding: 30px;'>
						<h1 style="text-align: center;margin: 20px">Processing Update...</h1>
						<br/>
						<h2 style="text-align: center" *ngIf="($calculationStatus | async)?.jobProgress?.data?.stage">
							{{ ($calculationStatus | async)?.jobProgress?.data?.stage }}
						</h2>
						<ion-progress-bar type="indeterminate"></ion-progress-bar>
						<br/>
						<br/>
						<div style='display:flex;justify-content:center;align-items:center;'>
							<button
								mat-icon-button
								style='background: transparent;border: 0px;font-size: 20px;'
								(click)="reloadWindow()"
							>
								<mat-icon class="secondary-text">refresh</mat-icon>
							</button>
						</div>
						</div>
					</ion-card-content>
				</ion-card>
			</ion-col>
		</ion-row>
	</ion-grid>

	<!-- project page -->
	<ion-grid
		*ngIf="
      ($projectData | async) &&
      ($projectData | async)?.updateIds?.length &&
      !($projectData | async)?.updateLock
    "
	>
		<app-project-overview [visualizer]='true'></app-project-overview>
	</ion-grid>
	<!-- qc-page -->

	<ion-grid
		*ngIf="
      ($projectData | async) &&
      ($projectData | async)?.updateIds?.length &&
      !($projectData | async)?.updateLock
    "
		[style]='(($projectReport | async)?.milestones?.milestoneArray?.length !== 0) ? "" : "" '

	>
		<ion-row [style]='(($projectReport | async)?.milestones?.milestoneArray?.length !== 0) ? "" : "" '>
			<ion-col>
				<app-qc-page [visualizer]='true'></app-qc-page>
			</ion-col>
		</ion-row>
	</ion-grid>
	<!-- schedule page -->
<!--
	<ion-grid
		*ngIf="
      ($projectData | async) &&
      ($projectData | async)?.updateIds?.length &&
      !($projectData | async)?.updateLock
    "
	>
		<ion-row>
			<ion-col size="12">

				<app-schedule-updates-list
					[$projectData]='$projectData' [visualizer]='true' [$updates]='$allUpdates' [$projectReport]='$projectReport'></app-schedule-updates-list>
			</ion-col>
		</ion-row>
	</ion-grid>
-->
	<!-- risk page -->
	<!--
	<ion-grid
		*ngIf="
      ($projectData | async) &&
      ($projectData | async)?.updateIds?.length &&
      !($projectData | async)?.updateLock
    "
	>
		<app-risk-page [$projectReport]='$projectReport' [visualizer]='true' [$projectData]='$projectData' [user]="user" [$currentTab]="navBarStorage.$tabPointer" (emitTab)="navBarStorage.$tabPointer" (dialogStateChange)='dialogStateChanged($event)'></app-risk-page>
	</ion-grid> -->
</div>
<div id='placehold-render-gantt'></div>
