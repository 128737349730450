<div class='card' style="min-height: calc(100vh - 76px); height: auto;">
	<kendo-gridlayout
		[rows]="['42.4px', 'auto']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='grid'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container'>
			<kendo-gridlayout
				[rows]="['42.4px']"
				[cols]="['211px', 'calc(100% - 216px)']"
				[gap]="{ rows: 0, cols: 5 }"
				class='full-width'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
					<div class="card-header">
						{{ganttService.updateName}} - Gantt <span style="font-size: 9px; position: relative; top: -1px;">(BETA) </span>
					</div>
					<tooltip-open type="current-update-gantt"></tooltip-open>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2' class='toolbar-item-container'>
					<div class='btngroup-label' style='margin-right: -5px;'>
						Relationships:
					</div>
					@if (!ganttService.groupByApplied && ganttService.radioSelection === null) {
						<kendo-buttongroup width='100%' selection="single" class='btn-group'>
							<button
								*ngFor="let button of ganttService.relationshipsButtons"
								kendoButton
								[toggleable]="true"
								[selected]="button.selected"
								(selectedChange)="ganttService.selectionChange($event, button, true)"
								class='item-btn'
								[class.selected-item-btn]='button.selected'
								[disabled]='button.disabled || ganttService.noItems'
							>
								<span>
									{{ button.text }}
								</span>
							</button>
						</kendo-buttongroup>
					} @else {
						<span kendoTooltip style="display: flex;">
							<kendo-buttongroup width='100%' selection="single" class='btn-group' title="Relationships are not compatible with grouped activities">
								<button
									*ngFor="let button of ganttService.relationshipsButtons"
									kendoButton
									[toggleable]="true"
									[selected]="button.selected"
									class='item-btn'
									[class.selected-item-btn]='button.selected'
									[disabled]='true'
								>
									<span>
										{{ button.text }}
									</span>
								</button>
							</kendo-buttongroup>
						</span>
					}
					<div class='btngroup-label' style='margin-right: -5px;'>
						Activities:
					</div>
					<kendo-buttongroup width='100%' selection="single" class='btn-group'>
						<button
							*ngFor="let button of ganttService.taskTypeButtons"
							kendoButton
							[toggleable]="true"
							[selected]="button.selected"
							(selectedChange)="ganttService.selectionChange($event, button)"
							class='item-btn'
							[class.selected-item-btn]='button.selected'
							[disabled]='button.disabled || ganttService.allItems?.length === 0'
						>
							<span>
								{{ button.text }}
							</span>
						</button>
					</kendo-buttongroup>
					<span style='display: inline-flex; align-items: center;float: right; gap: 10px;' kendoTooltip>
						<button kendoButton themeColor="base" [title]="'Filter Menu'" class='header-btn new-filter-icon' [disabled]='ganttService.loading' [imageUrl]='"/assets/icons/newIcons/filter.svg"' (click)="openFilterMenu(popupAnchor.element, 'filters')" #popupAnchor></button>
						<div style="position: relative; margin: auto 0;">
							<button kendoButton themeColor="base" [title]="'Group By'" class='header-btn new-group-icon' [disabled]="ganttService.loading" [imageUrl]='"/assets/icons/newIcons/noun-windows-6802602.svg"'
											(click)="openFilterMenu(groupSelectorAnchor.element, 'groups')" #groupSelectorAnchor>
							</button>
							@if (groupSelectorPopupIsOpen) {
								<kendo-svg-icon [icon]="icons.caretAltDown" class="downArrowIcon" style="color: white; position: absolute; left: 8px; margin-top: 25px;"></kendo-svg-icon>
							}
						</div>
						<div style="position: relative; margin: auto 0;">
							<button kendoButton themeColor='base' title="Choose Columns" class='header-btn new-column-icon'
											(click)="openFilterMenu(columnSelectorPopupAnchor.element, 'columns')"
											[disabled]="ganttService.loading || ganttService.noItems"
											[imageUrl]='"/assets/icons/newIcons/noun-add-column2 1.svg"'
											#columnSelectorPopupAnchor
							>
							</button>
							@if (columnSelectorPopupIsOpen) {
								<kendo-svg-icon [icon]="icons.caretAltDown" class="downArrowIcon" style="color: white; position: absolute; left: 8px; margin-top: 25px;"></kendo-svg-icon>
							}
						</div>
						<button kendoButton themeColor="base" [title]="'Report Center'" class='header-btn new-folder-icon' [disabled]="ganttService.loading" [imageUrl]="'/assets/icons/newIcons/noun-folder-7139887.svg'" (click)="openReportCenter()"></button>
						<div style="position: relative; margin: auto 0;">
							<button kendoButton themeColor="base" [title]="'Favorite Reports'" class='header-btn new-layouts-icon' [disabled]="ganttService.loading" [imageUrl]='"/assets/icons/newIcons/layouts.svg"'
											(click)="openFilterMenu(presetSelectorAnchor.element, 'presets')" #presetSelectorAnchor>
							</button>
							@if (presetSelectorPopupIsOpen) {
								<kendo-svg-icon [icon]="icons.caretAltDown" class="downArrowIcon" style="color: white; position: absolute; margin-top: 25px;"></kendo-svg-icon>
							}
						</div>
						<button kendoButton themeColor="base" [imageUrl]='"/assets/icons/newIcons/collapse.svg"' class='header-btn' [title]="'Collapse One Level'" [disabled]='!ganttService.groupByApplied || ganttService.loading || fullyCollapsed' (click)="collapseOneLevel()"></button>
						<button kendoButton themeColor="base" [imageUrl]='"/assets/icons/newIcons/expand.svg"' class='header-btn' [title]="'Expand One Level'" [disabled]='!ganttService.groupByApplied || ganttService.loading || fullyExpanded' (click)="expandOneLevel()"></button>
						<!--<button kendoButton themeColor="base" [imageUrl]='ganttService.allNodesExpanded ? "/assets/icons/newIcons/collapseAll.svg" : "/assets/icons/newIcons/expandAll.svg"' class='header-btn' [title]="ganttService.allNodesExpanded ? 'Collapse All' : 'Expand All'" (click)='toggleAllNodes(!ganttService.allNodesExpanded)' [disabled]='!ganttService.groupByApplied || ganttService.loading'></button>-->
						@if (exportLoading) {
							<button kendoButton themeColor="base" [title]="'Exporting'" class='header-btn new-pdf-icon' [disabled]="ganttService.loading || ganttService.noItems" style="position: relative;">
								<kendo-circularprogressbar
									style="transform: scale(0.1); position: absolute; top: -89px; left: -85px;"
									[indeterminate]="true"
									[value]="30"
									[progressColor]='"#ffb500"'
								></kendo-circularprogressbar>
							</button>
						} @else {
							<button kendoButton themeColor="base" [title]="'Export as PDF'" class='header-btn new-pdf-icon' [disabled]="ganttService.loading || ganttService.noItems" [imageUrl]='"/assets/icons/newIcons/noun-pdf-3039009.svg"' (click)="onExport(pdf)"></button>
						}
						<button kendoButton themeColor="error" title="Reset" style="width: 53px; height: 24px;" [disabled]='ganttService.resetDisabled || ganttService.loading' (click)='resetButtonClicked()'>Reset</button>
					</span>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1'>
			<kendo-pdf-export #pdf>
				<div class="hidden-until-pdf" id="ganttExportTitleBar">
					<span style="display: flex; flex-direction: row; justify-content: flex-start; gap: 10px;">
						<img src="/assets/images/logos/AegisAnalyticsNormalG.png" style='max-width: 100px; height: auto; vertical-align: top; border: none;'>
						<span style="display: flex; flex-direction: row; justify-content: flex-start; margin: auto 0;">
							{{projectName}} - {{ganttService.updateName}} ({{ganttService._dataDate | date: 'MMM d, yyyy' }})
						</span>
					</span>
					<span style="display: flex; flex-direction: column; justify-content: center">
						<span style="font-size: 22px;">
							{{ganttService.reportName}}
						</span>
						<span style="margin: auto 0; font-size: 14px;">
							Exported on: {{ganttService.timestamp | date: 'MMM d, yyyy'}}
						</span>
					</span>

				</div>
				<app-current-update-gantt-chart
					[$filtersOpened]='$filtersOpened'
					[$columnSelectorOpened]='$columnSelectorOpened'
					[$groupSelectorOpened]='$groupSelectorOpened'
					[$showLines]="ganttService.$showLines"
					[$showOnlyCritical]="ganttService.$showOnlyCritical"
					[$toggleExpand]="$toggleExpand"
					[$expandOneLevel]="$expandOneLevel"
					[$collapseOneLevel]="$collapseOneLevel"
					[$presetSelectorOpened]='$presetSelectorOpened'
					(updateProjectName)="updateProjectName($event)"
					(columnSelectorPopupState)="updateColumnSelectorState($event)"
					(groupSelectorPopupState)="updateGroupSelectorState($event)"
					(presetSelectorPopupState)="updatePresetSelectorState($event)"
					(updateButtonGroupsOnPresetClick)="updateButtonGroupsOnPresetClick($event)"
					(updateFullyExpanded)="updateFullyExpanded($event)"
					(updateFullyCollapsed)="updateFullyCollapsed($event)"
				></app-current-update-gantt-chart>
			</kendo-pdf-export>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
</div>
