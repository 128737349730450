import { Component, Inject, OnInit } from '@angular/core';
import { PFTableValues } from '../../risk-performance-factor/risk-performance-factor/performance-factor.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { ProjectDashboardService } from '../../../../../services/project/project.service';
import { RestService } from '../../../../../services/common/rest.service';

@Component({
	selector: 'app-pf-table-filter-modal',
	templateUrl: './pf-table-filter-modal.component.html',
	styleUrls: ['./pf-table-filter-modal.component.scss'],
})
export class PfTableFilterModalComponent implements OnInit {
	riskPage;
	pfTable: PFTableValues[] = [];
	displayedColumns = ['select', 'activityCode', 'description', 'pf'];
	initialSelection = [];
	selectedActivityCodes: Array<number> = [];
	allowMultiSelect = true;
	selection: SelectionModel<PFTableValues> = new SelectionModel<PFTableValues>(this.allowMultiSelect, []);
	isReady: boolean = false;
	httpProcessing: boolean = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<PfTableFilterModalComponent>,
		private _projectDashboardService: ProjectDashboardService,
		private restService: RestService
	) {}

	/**
	 * helper sort function that sorts by pf descending
	 * @param a
	 * @param b
	 */
	compare(a, b) {
		return b.pf - a.pf;
	}

	sortAndRemoveDupes(tableValues) {
		if (!tableValues) {
			return [];
		}
		const pfIds: Array<number> = [];
		const pfRows: Array<PFTableValues> = [];
		for (const pfTableValue of tableValues) {
			if (pfTableValue?.numStarted > 0 && !pfIds.includes(pfTableValue.activityCodeId)) {
				pfIds.push(pfTableValue.activityCodeId);
				pfTableValue.isSelected = this.selectedActivityCodes.includes(pfTableValue.activityCodeId);
				if (pfTableValue.isSelected) {
					this.selection.select(pfTableValue);
				}
				pfRows.push(pfTableValue);
			}
		}
		if (this.selection.selected.length === 0) {
			for (const pfRow of pfRows) {
				this.selection.select(pfRow);
			}
		}

		return pfRows.sort(this.compare);
	}

	ngOnInit(): void {
		this.isReady = false;
		this.riskPage = this.data.riskPage;
		this.selection = new SelectionModel<PFTableValues>(this.allowMultiSelect, []);
		this.pfTable = [];
		this.initialSelection = [];
		this.selectedActivityCodes = [];

		for (const activityCodeType of this.riskPage?.performanceFactor?.selectedActivityCodes || []) {
			if (activityCodeType.hasOwnProperty('subcodes')) {
				for (const subActivityCode of activityCodeType?.subcodes || []) {
					this.selectedActivityCodes.push(subActivityCode?.actv_code_id);
				}
			} else {
				this.selectedActivityCodes.push(activityCodeType);
			}
		}
		this.isReady = true;
	}

	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.pfTable?.length;
		return numSelected == numRows;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		this.isAllSelected()
			? this.selection.clear()
			: this.pfTable?.forEach((row) => {
					this.selection.select(row);
					row.isSelected = this.selection.isSelected(row);
				});
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	submitFilter() {
		this.httpProcessing = true;
		this._projectDashboardService.queueProcessingChanged.next(true);
		const activityCodeIds: Array<number> = [];

		this.pfTable?.forEach((pfTableRow: PFTableValues) => {
			if (this.selection.isSelected(pfTableRow)) {
				activityCodeIds.push(pfTableRow.activityCodeId);
			}
		});

		this.restService
			.post(`report/calculate/${this.data.project._id}`, { activityCodeIds, onlySimulate: true })
			.subscribe(
				(val) => {
					this.dialogRef.close();
				},
				(response) => {
					console.log('POST call in error', response);
				},
				() => {}
			);
		this.dialogRef.close();
	}

	crossOut(isSelected: any) {
		return isSelected ? '' : 'crossout';
	}

	doRowCheck(row) {
		if (row.pf > 1) {
			return 'over1';
		}

		if (row.pf < 1) {
			return 'under1';
		}

		return 'nothanks';
	}
}
