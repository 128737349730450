import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RiskRegisterFormComponent } from './risk-register-form/risk-register-form.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LabelModule } from '@progress/kendo-angular-label';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { FilterModule } from '@progress/kendo-angular-filter';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { RiskRegisterTableComponent } from './risk-register-table/risk-register-table.component';
import { DialogModule, DragResizeService, WindowModule } from '@progress/kendo-angular-dialog';
import { ClearDirective } from '../../../../util/directives/clear.directive';
import { MatIconModule } from '@angular/material/icon';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { RiskRegisterEditService } from '../../../../services/project/register-edit.service';
import { RegisterRecalcNotificationComponent } from './register-recalc-notification/register-recalc-notification.component';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RiskSettingsModule } from '../risk-settings/risk-settings.module';
import { PipeModule } from '../../../../util/pipes/pipe.module';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { ImportComponent } from './import/import.component';
import { OverwriteDialogComponent } from './import/overwriteDialog/overwriteDialog.component';
import { TooltipMenuModule } from '../../../portfolio/tooltip-menu/tooltip-menu.module';
import { DirectivesModule } from '../../../../util/directives/directives.module';
import { SVGIconModule } from '@progress/kendo-angular-icons';

@NgModule({
	declarations: [
		RiskRegisterFormComponent,
		RiskRegisterTableComponent,
		ClearDirective,
		RegisterRecalcNotificationComponent,
		ImportComponent,
		OverwriteDialogComponent,
	],
	imports: [
		CommonModule,
		ButtonsModule,
		LayoutModule,
		InputsModule,
		ReactiveFormsModule,
		LabelModule,
		DateInputsModule,
		DropDownsModule,
		GridModule,
		FormsModule,
		FilterModule,
		IndicatorsModule,
		ExcelModule,
		MatIconModule,
		PopupModule,
		TreeViewModule,
		NotificationModule,
		MatTooltipModule,
		TooltipsModule,
		RiskSettingsModule,
		PipeModule,
		DialogModule,
		WindowModule,
		TooltipMenuModule,
		DirectivesModule,
		SVGIconModule,
	],
	exports: [
		RiskRegisterFormComponent,
		RiskRegisterTableComponent,
		RegisterRecalcNotificationComponent,
		ImportComponent,
		OverwriteDialogComponent,
	],
	providers: [DragResizeService, RiskRegisterEditService],
})
export class RiskRegisterModule {}
