import { Injectable, NgZone } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { environment } from '../../environments/environment';
import { AccountUser } from '../../models/auth/account-user';
import { AuthenticationService } from '../auth/authentication.service';

@Injectable({
	providedIn: 'root',
})
export class EventService {
	constructor(
		private zone: NgZone,
		private authService: AuthenticationService
	) {}

	getServerSentEvent(url: string): { observable: Observable<MessageEvent>; eventSource: EventSource } {
		const path = `${environment.baseURL}` + (url || ``);
		const headers = this.authService.getHeader();
		const eventSource = new EventSource(path + '?authorization=' + headers.accessToken);
		const observable = new Observable((subscriber: Subscriber<MessageEvent>) => {
			eventSource.onmessage = (event) => {
				this.zone.run(() => {
					subscriber.next(event);
				});
			};

			eventSource.onerror = (error) => {
				this.zone.run(() => {
					subscriber.error(error);
				});
			};

			return () => {
				eventSource.close();
			};
		});

		return { observable, eventSource };
	}
}
