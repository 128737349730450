import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-register-recalc-notification',
	templateUrl: './register-recalc-notification.component.html',
	styleUrls: ['./register-recalc-notification.component.scss'],
})
export class RegisterRecalcNotificationComponent implements OnInit {
	@Output() public ignore: EventEmitter<undefined> = new EventEmitter();
	@Output() public recalc: EventEmitter<undefined> = new EventEmitter();
	constructor() {}

	ngOnInit(): void {}

	public ignoreNotification(event: Event): void {
		event.preventDefault();
		this.ignore.emit();
	}
	public recalcRisk(event: Event): void {
		event.preventDefault();
		this.recalc.emit();
	}
}
