import { riskFormPresets } from '../components/project-page/risk/risk-register/risk-register-form/risk-register-form.component';

export interface MitigationRiskScoring {
	probability: number;
	scheduleImpact: number;
	costImpact: number;
	performanceImpact: number;
	qualityImpact: number;
	activityImpact: number;
	estCost: number;
	impactVarianceMin: number;
	impactVarianceMax: number;
	riskScore(): number;
}

export interface PostMitigationRiskInterface extends MitigationRiskScoring {
	postRanking(): Optimism;
}

export interface MitigationStrategy {
	strategy: 'accept' | 'reduce' | 'mitigate';
	measures: string;
	statusDate: Date;
}

export class PrePostMitigationRisk implements MitigationRiskScoring {
	probability: number = 0;
	scheduleImpact: number = 0;
	costImpact: number = 0;
	performanceImpact: number = 0;
	qualityImpact: number = 0;
	activityImpact: number = 0;
	estCost: number = 0;
	impactVarianceMin: number = 0;
	impactVarianceMax: number = 0;
	riskScore(probability?: number): number {
		return (
			(probability ?? this.probability ?? 0) *
			Math.max(this.performanceImpact ?? 0, this.scheduleImpact ?? 0, this.costImpact ?? 0, this.qualityImpact ?? 0)
		);
	}
	constructor(mitigation: MitigationRiskScoring) {
		this.probability = mitigation.probability;
		this.scheduleImpact = mitigation.scheduleImpact;
		this.costImpact = mitigation.costImpact;
		this.performanceImpact = mitigation.performanceImpact;
		this.qualityImpact = mitigation.qualityImpact;
		this.activityImpact = mitigation.activityImpact;
		this.estCost = mitigation.estCost;
		this.impactVarianceMin = mitigation.impactVarianceMin;
		this.impactVarianceMax = mitigation.impactVarianceMax;
	}
}

export type Optimism = 'optimistic' | 'slightly optimistic' | 'neutral' | 'slightly pessimistic' | 'pessimistic';

export class PostMitigationRisk extends PrePostMitigationRisk implements PostMitigationRiskInterface {
	postRanking(): Optimism {
		const riskScore = this.riskScore();
		if (riskScore <= 2) {
			return 'optimistic';
		}
		if (riskScore < 5) {
			return 'slightly optimistic';
		}
		if (riskScore === 5) {
			return 'neutral';
		}
		if (riskScore < 7) {
			return 'slightly pessimistic';
		}
		return 'pessimistic';
	}
	constructor(mitigation: MitigationRiskScoring) {
		super(mitigation);
	}
}

export type SaasRiskBannerExtras = {
	riskAssessmentScore: number;
	preMitigationRiskScore: number;
	postMitigationRiskScore: number;
};

export type RiskRegister = {
	riskId: number;
	riskName: string;
	mitStatus: string;
	category: string;
	triggerPhase: string;
	riskOwner: MitigationEntity;
	costOwner: MitigationEntity;
	responsibility: MitigationEntity;
	description: string;
	effect: string;
	impactedTaskCodes: string[];
	strategy: MitigationStrategy;
	preMitigation: PrePostMitigationRisk;
	postMitigation: PostMitigationRisk;
	disabled?: boolean;
	costVarianceMin?: number;
	costVarianceMax?: number;
	isDraft?: boolean;
	riskAssessmentScore?: number;
	preMitigationRiskScore?: number;
	postMitigationRiskScore?: number;
	value?: number;
	lastUpdated?: Date;
};

export const emptyRegister = (): RiskRegister => ({
	riskId: 0,
	riskName: '',
	mitStatus: '',
	category: '',
	triggerPhase: '',
	riskOwner: { name: '', email: '' },
	costOwner: { name: '', email: '' },
	responsibility: { name: '', email: '' },
	description: '',
	effect: '',
	impactedTaskCodes: [],
	strategy: {
		strategy: null,
		measures: null,
		statusDate: undefined,
	},
	preMitigation: {
		probability: null,
		scheduleImpact: null,
		costImpact: null,
		performanceImpact: null,
		qualityImpact: null,
		activityImpact: null,
		estCost: null,
		impactVarianceMax: 0.1,
		impactVarianceMin: 0.1,
		riskScore: (probability: number) => 0,
	},
	postMitigation: {
		probability: null,
		scheduleImpact: null,
		costImpact: null,
		performanceImpact: null,
		qualityImpact: null,
		activityImpact: null,
		estCost: null,
		impactVarianceMax: 0.1,
		impactVarianceMin: 0.1,
		postRanking: () => 'optimistic',
		riskScore: (probability: number) => 0,
	},
	lastUpdated: null,
});

export type MitigationEntity = {
	name: string;
	email?: string;
};

export interface MitigationEntityCollection {
	name?: string[];
	email?: string[];
}

export interface HeatMapDataObject {
	x: string;
	y: string;
	value: number;
}

export interface CustomFieldTreeData {
	data: string;
	field: string;
	hasChildren: boolean;
	isRoot: boolean;
	reportsTo: number;
	riskId: number[];
	customFieldId: number;
	editable: boolean;
}

export interface CustomFieldMapping {
	path: string;
	subpath?: string;
	efPath: string;
}

export interface ImportHeaderMapping {
	analyticsField: string;
	analyticsSubfield?: string;
	excelField: string;
	reactiveRiskField: string;
	headerIndex: number;
	required: boolean;
	fieldOptions?: string[] | number[];
	needsScoreConversion?: boolean;
	needsCurrencyMarkRemoved?: boolean;
	isDate?: boolean;
}

export interface P6ScoreMapping {
	analyticsScore: number;
	p6Score: number;
}

export const customFieldOptionsMapping: CustomFieldMapping[] = [
	{
		path: 'riskOwner',
		subpath: 'name',
		efPath: 'riskOwnerNames',
	},
	{
		path: 'riskOwner',
		subpath: 'email',
		efPath: 'riskOwnerEmails',
	},
	{
		path: 'costOwner',
		subpath: 'name',
		efPath: 'costOwnerNames',
	},
	{
		path: 'costOwner',
		subpath: 'email',
		efPath: 'costOwnerEmails',
	},
	{
		path: 'responsibility',
		subpath: 'name',
		efPath: 'responsibilityNames',
	},
	{
		path: 'responsibility',
		subpath: 'email',
		efPath: 'responsibilityEmails',
	},
	{
		path: 'category',
		efPath: 'category',
	},
];

export const importHeaderMappings: ImportHeaderMapping[] = [
	{
		analyticsField: 'riskId',
		excelField: 'Risk ID',
		reactiveRiskField: 'ID',
		headerIndex: null,
		required: true,
	},
	{
		analyticsField: 'riskName',
		excelField: 'Risk Name',
		reactiveRiskField: 'Name',
		headerIndex: null,
		required: true,
	},
	{
		analyticsField: 'mitStatus',
		excelField: 'Risk Status',
		reactiveRiskField: 'Status',
		headerIndex: null,
		required: true,
		fieldOptions: riskFormPresets.mitStatus,
	},
	{
		analyticsField: 'category',
		excelField: 'Risk Category',
		reactiveRiskField: 'Category',
		headerIndex: null,
		required: true,
	},
	{
		analyticsField: 'triggerPhase',
		excelField: 'Trigger Phase',
		reactiveRiskField: 'Trigger Phase',
		headerIndex: null,
		required: false,
		fieldOptions: riskFormPresets.triggerPhase,
	},
	{
		analyticsField: 'riskOwner',
		analyticsSubfield: 'name',
		excelField: 'Risk Owner',
		reactiveRiskField: 'Risk Owner',
		headerIndex: null,
		required: true,
	},
	{
		analyticsField: 'costOwner',
		analyticsSubfield: 'name',
		excelField: null,
		reactiveRiskField: 'Cost Owner',
		headerIndex: null,
		required: false,
	},
	{
		analyticsField: 'responsibility',
		analyticsSubfield: 'name',
		excelField: null,
		reactiveRiskField: 'Responsibility Owner',
		headerIndex: null,
		required: true,
	},
	{
		analyticsField: 'effect',
		excelField: 'Effect',
		reactiveRiskField: 'Effect',
		headerIndex: null,
		required: true,
	},
	{
		analyticsField: 'preMitigation',
		analyticsSubfield: 'probability',
		excelField: 'Pre-Response Probability',
		reactiveRiskField: 'Pre-Mitigation Probability',
		headerIndex: null,
		required: true,
		fieldOptions: riskFormPresets.scores,
		needsScoreConversion: true,
	},
	{
		analyticsField: 'preMitigation',
		analyticsSubfield: 'activityImpact',
		excelField: null,
		reactiveRiskField: 'Pre-Mitigation Activity Impact',
		headerIndex: null,
		required: false,
		fieldOptions: riskFormPresets.scores,
		needsScoreConversion: true,
	},
	{
		analyticsField: 'preMitigation',
		analyticsSubfield: 'scheduleImpact',
		excelField: 'Pre-Response Schedule',
		reactiveRiskField: 'Pre-Mitigation Schedule Impact',
		headerIndex: null,
		required: true,
		fieldOptions: riskFormPresets.scores,
		needsScoreConversion: true,
	},
	{
		analyticsField: 'preMitigation',
		analyticsSubfield: 'costImpact',
		excelField: 'Pre-Response Cost',
		reactiveRiskField: 'Pre-Mitigation Cost Impact',
		headerIndex: null,
		required: true,
		fieldOptions: riskFormPresets.scores,
		needsScoreConversion: true,
	},
	{
		analyticsField: 'preMitigation',
		analyticsSubfield: 'performanceImpact',
		excelField: 'Pre-Response Performance',
		reactiveRiskField: 'Pre-Mitigation Performance Impact',
		headerIndex: null,
		required: false,
		fieldOptions: riskFormPresets.scores,
		needsScoreConversion: true,
	},
	{
		analyticsField: 'preMitigation',
		analyticsSubfield: 'qualityImpact',
		excelField: 'Pre-Response Quality',
		reactiveRiskField: 'Pre-Mitigation Quality Impact',
		headerIndex: null,
		required: false,
		fieldOptions: riskFormPresets.scores,
		needsScoreConversion: true,
	},
	{
		analyticsField: 'strategy',
		analyticsSubfield: 'strategy',
		excelField: 'Response Type',
		reactiveRiskField: 'Mitigation Strategy',
		headerIndex: null,
		required: true,
		fieldOptions: riskFormPresets.mitigationStrategy,
	},
	{
		analyticsField: 'postMitigation',
		analyticsSubfield: 'activityImpact',
		excelField: null,
		reactiveRiskField: 'Post-Mitigation Activity Impact',
		headerIndex: null,
		required: false,
		fieldOptions: riskFormPresets.scores,
		needsScoreConversion: true,
	},
	{
		analyticsField: 'postMitigation',
		analyticsSubfield: 'scheduleImpact',
		excelField: 'Post-Response Schedule',
		reactiveRiskField: 'Post-Mitigation Schedule Impact',
		headerIndex: null,
		required: true,
		fieldOptions: riskFormPresets.scores,
		needsScoreConversion: true,
	},
	{
		analyticsField: 'postMitigation',
		analyticsSubfield: 'costImpact',
		excelField: 'Post-Response Cost',
		reactiveRiskField: 'Post-Mitigation Cost Impact',
		headerIndex: null,
		required: true,
		fieldOptions: riskFormPresets.scores,
		needsScoreConversion: true,
	},
	{
		analyticsField: 'postMitigation',
		analyticsSubfield: 'performanceImpact',
		excelField: 'Post-Response Performance',
		reactiveRiskField: 'Post-Mitigation Performance Impact',
		headerIndex: null,
		required: false,
		fieldOptions: riskFormPresets.scores,
		needsScoreConversion: true,
	},
	{
		analyticsField: 'postMitigation',
		analyticsSubfield: 'qualityImpact',
		excelField: 'Post-Response Quality',
		reactiveRiskField: 'Post-Mitigation Quality Impact',
		headerIndex: null,
		required: false,
		fieldOptions: riskFormPresets.scores,
		needsScoreConversion: true,
	},
	{
		analyticsField: 'description',
		excelField: 'Description',
		reactiveRiskField: 'Description',
		headerIndex: null,
		required: false,
	},
	{
		analyticsField: 'strategy',
		analyticsSubfield: 'statusDate',
		excelField: 'Identified On',
		reactiveRiskField: 'Status Date',
		headerIndex: null,
		required: false,
		isDate: true,
	},
	{
		analyticsField: 'preMitigation',
		analyticsSubfield: 'estCost',
		excelField: 'Pre-Response Exposure Cost',
		reactiveRiskField: 'Pre-Mitigation Estimated Cost',
		headerIndex: null,
		required: false,
		needsCurrencyMarkRemoved: true,
	},
	{
		analyticsField: 'strategy',
		analyticsSubfield: 'measures',
		excelField: 'Response Description',
		reactiveRiskField: 'Mitigation Measures',
		headerIndex: null,
		required: false,
	},
	{
		analyticsField: 'postMitigation',
		analyticsSubfield: 'estCost',
		excelField: 'Post-Response Exposure Cost',
		reactiveRiskField: 'Post-Mitigation Estimated Cost',
		headerIndex: null,
		required: false,
		needsCurrencyMarkRemoved: true,
	},
];

export const importP6ScoreMapping: P6ScoreMapping[] = [
	{
		analyticsScore: 0,
		p6Score: 6,
	},
	{
		analyticsScore: 1,
		p6Score: 5,
	},
	{
		analyticsScore: 2,
		p6Score: 4,
	},
	{
		analyticsScore: 3,
		p6Score: 3,
	},
	{
		analyticsScore: 4,
		p6Score: 2,
	},
	{
		analyticsScore: 5,
		p6Score: 1,
	},
];

export interface filterMenuFilter {
	active: boolean;
	displayLabel: string;
	name: string;
	filterField: string;
	optionType: string;
	options: string[] | boolean;
	quickFilter: boolean;
}

export const filterMenuOptions: filterMenuFilter[] = [
	{
		active: false,
		displayLabel: 'All',
		name: 'allRisks',
		filterField: '',
		optionType: '',
		options: [],
		quickFilter: true,
	},
	{
		active: false,
		displayLabel: 'Active Risks',
		name: 'activeRisks',
		filterField: 'mitStatus',
		optionType: 'object',
		options: ['Identified', 'Mitigation Planning', 'Tracking', 'Active', 'Mitigation'],
		quickFilter: true,
	},
	{
		active: false,
		displayLabel: 'Draft Risks',
		name: 'draftRisks',
		filterField: 'isDraft',
		optionType: 'boolean',
		options: true,
		quickFilter: true,
	},
];

export type OverwriteDecision = 'saveAsNew' | 'overwrite' | 'ignoreDuplicate';

export interface OverwriteResults {
	importedRiskId: string;
	importedRiskName: string;
	matchingAnalyticsRiskId: number;
	matchingAnalyticsRiskName: string;
	decision: OverwriteDecision;
}
