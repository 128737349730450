import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
	CheckBoxModule,
	FormFieldModule,
	NumericTextBoxModule,
	RadioButtonModule,
	SliderModule,
} from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ProjectInterface } from '../../../../models/Project';
import { NgIf } from '@angular/common';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { RestService } from '../../../../services/common/rest.service';
import { Observable } from 'rxjs';
import { QualityPreferences } from '@rhinoworks/analytics-calculations';

@Component({
	selector: 'app-quality-settings',
	standalone: true,
	imports: [
		CheckBoxModule,
		LabelModule,
		SliderModule,
		FormsModule,
		NumericTextBoxModule,
		NgIf,
		FormFieldModule,
		RadioButtonModule,
		ReactiveFormsModule,
		ButtonModule,
	],
	templateUrl: './quality-settings.component.html',
	styleUrl: './quality-settings.component.scss',
})
export class QualitySettingsComponent implements OnInit {
	public qualityFormGroup: FormGroup = new FormGroup({
		useDCMA: new FormControl(false, Validators.required),
		beiMin: new FormControl(95, [Validators.required, Validators.min(0), Validators.max(100)]),
		useBei: new FormControl(true, Validators.required),
		cpliMin: new FormControl(95, [Validators.required, Validators.min(0), Validators.max(100)]),
		useCpli: new FormControl(true, Validators.required),
		missedTaskMax: new FormControl(5, [Validators.required, Validators.min(0), Validators.max(100)]),
		useMissedTask: new FormControl(true, Validators.required),
		negativeFloatMax: new FormControl(0, [Validators.required, Validators.min(0), Validators.max(100)]),
		useNegativeFloat: new FormControl(true, Validators.required),
		nonFSMax: new FormControl(10, [Validators.required, Validators.min(0), Validators.max(100)]),
		useNonFS: new FormControl(true, Validators.required),
		missingPredSuccMax: new FormControl(5, [Validators.required, Validators.min(0), Validators.max(100)]),
		useMissingPredSucc: new FormControl(true, Validators.required),
		negativeLagMax: new FormControl(0, [Validators.required, Validators.min(0), Validators.max(100)]),
		useNegativeLag: new FormControl(true, Validators.required),
		positiveLagMax: new FormControl(5, [Validators.required, Validators.min(0), Validators.max(100)]),
		usePositiveLag: new FormControl(true, Validators.required),
		hardConstraintsMax: new FormControl(5, [Validators.required, Validators.min(0), Validators.max(100)]),
		useHardConstraints: new FormControl(true, Validators.required),
		highFloatMax: new FormControl(5, [Validators.required, Validators.min(0), Validators.max(100)]),
		useHighFloat: new FormControl(true, Validators.required),
		highDurationMax: new FormControl(5, [Validators.required, Validators.min(0), Validators.max(100)]),
		useHighDuration: new FormControl(true, Validators.required),
		invalidDatesMax: new FormControl(0, [Validators.required, Validators.min(0), Validators.max(100)]),
		useInvalidDates: new FormControl(true, Validators.required),
		unassignedResourceMax: new FormControl(0, [Validators.required, Validators.min(0), Validators.max(100)]),
		useUnassignedResource: new FormControl(false, Validators.required),
		requireCritPath: new FormControl(true, Validators.required),
	});
	@Input() projectData: ProjectInterface;
	@Output() hasUnsavedChanges = new EventEmitter<boolean>();
	@Output() changes = new EventEmitter<QualityPreferences>();
	@Output() closeWindow = new EventEmitter<void>();
	@Input() saveChangesEvent: Observable<boolean>;
	hasChange = false;

	constructor(private restService: RestService) {}

	ngOnInit(): void {
		this.qualityFormGroup.patchValue(
			this.projectData.qualityPreferences || {
				useDCMA: false,
				beiMin: 95,
				useBei: true,
				cpliMin: 95,
				useCpli: true,
				missedTaskMax: 5,
				useMissedTask: true,
				negativeFloatMax: 0,
				useNegativeFloat: true,
				nonFSMax: 10,
				useNonFS: true,
				missingPredSuccMax: 5,
				useMissingPredSucc: true,
				negativeLagMax: 0,
				useNegativeLag: true,
				positiveLagMax: 5,
				usePositiveLag: true,
				hardConstraintsMax: 5,
				useHardConstraints: true,
				highFloatMax: 5,
				useHighFloat: true,
				highDurationMax: 5,
				useHighDuration: true,
				invalidDatesMax: 0,
				useInvalidDates: true,
				unassignedResourceMax: 0,
				useUnassignedResource: false,
				requireCritPath: true,
			}
		);
		this.onCreateGroupFormValueChange();
		this.saveChangesEvent?.subscribe((val) => val && this.saveChanges());
	}

	onCreateGroupFormValueChange() {
		const initialValue = this.qualityFormGroup.value;
		this.qualityFormGroup.valueChanges.subscribe((value) => {
			this.hasChange = Object.keys(initialValue).some((key) => this.qualityFormGroup.value[key] != initialValue[key]);
			this.hasUnsavedChanges.emit(this.hasChange);
			this.changes.emit(this.hasChange ? this.qualityFormGroup.value : undefined);
		});
	}

	saveChanges() {
		console.log(this.qualityFormGroup.value, this.projectData);
		this.closeWindow.emit();
		this.restService
			.post(`project/preferences/quality/${this.projectData._id}`, this.qualityFormGroup.value)
			.subscribe((val) => {
				this.projectData = val.proj;

				console.log({ val }, this.projectData);
				this.restService.post(`report/calculate/${this.projectData._id}`, {}).subscribe(
					() => {},
					(response) => {
						console.log('POST call in error', response);
					},
					() => {}
				);
			});
	}
}
