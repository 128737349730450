import { AfterViewInit, Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { BaselineTargetDates, ExpandedMetricsValues } from '../../../../models/ProjectReport/ExpandedMetrics';
import { BehaviorSubject } from 'rxjs';
import { CurrentProjectReport, ProjectDashboardService } from '../../../../services/project/project.service';
import { DCMAMetric, DCMAMetrics } from '../../../../models/ProjectReport/DCMA';
import {
	CalendarArrayInterface,
	ScheduleAnalysisTask,
	TaskPredArrayInterface,
	UpdateInterface,
} from '../../../../models/Update/Task';
import { actvHasCode, getLowercaseValues } from '../../../../util/tasks';
import { PlotBand } from '@progress/kendo-angular-charts';
import { AxisSettings, SeriesData, SeriesDataSettings } from '../../../../models/ChartSettings';
import { GridComponent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query/dist/npm/sort-descriptor';
import { ScheduleAnalysisColumn } from '../schedule-analysis/schedule-analysis.component';
import { toDCMAPct } from '../../../../util/pipes/dcma-score.pipe';
import { filter, take } from 'rxjs/operators';
import { XerActivity, XerActivityCode } from '@rhinoworks/xer-parse';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { ExpandedMetrics, ProjectReportInterface, QualityPreferences } from '@rhinoworks/analytics-calculations';
import { SubCodeFilterItem } from '../../overview/activity-completion/activity-completion.component';

const metrics = require('./dcma-metrics.json');
export const allColumns = require('./dcma-assessment-columns.json') as {
	columns: ScheduleAnalysisColumn[];
	buttons: DcmaButton[];
};

export type DcmaButton = {
	columnsDisplayed: number[];
	displaySet?: string;
	dataType?: 'Activity' | 'Relationship';
	name: string;
	title?: string;
	hasTable?: boolean;
};

interface QualSettings {
	key: string;
	enabled: boolean;
	min: number;
	max: number;
	threshold: number;
}

@Component({
	selector: 'app-dcma-assessment',
	templateUrl: './dcma-assessment.component.html',
	styleUrls: ['./dcma-assessment.component.scss'],
})
export class DcmaAssessmentComponent implements OnInit, AfterViewInit {
	@Input() visualizer: boolean = false;
	@Input() isOverview: boolean = false;
	@Input() isFocus: boolean = false;
	@Input() focusReport: ProjectReportInterface;
	taskStatusDictionary = {
		TK_Complete: 'Completed',
		TK_NotStart: 'Not Started',
		TK_Active: 'Active',
	};
	taskTypeDictionary = {
		TT_Task: 'Task',
		TT_FinMile: 'Finish Milestone',
		TT_Mile: 'Milestone',
		TT_LOE: 'Level of Effort',
	};
	constraintDict = {
		CS_ALAP: 'As Late As Possible',
		CS_MANDFIN: 'Mandatory Finish',
		CS_MANDSTART: 'Mandatory Start',
		CS_MEO: 'Finish On',
		CS_MEOA: 'Finish On or After',
		CS_MEOB: 'Finish On or Before',
		CS_MSO: 'Start On',
		CS_MSOA: 'Start On or After',
		CS_MSOOB: 'Start On or Before',
	};
	linkTypeDictionary = {
		PR_FS: 'Finish to Start',
		PR_SF: 'Start to Finish',
		PR_FF: 'Finish to Finish',
		PR_SS: 'Start to Start',
	};
	calendars = new Map<number, CalendarArrayInterface>([]);
	@Input() expandedMetrics: BehaviorSubject<ExpandedMetrics> = new BehaviorSubject<ExpandedMetrics>(undefined);
	@Input() qualityPreferences: QualityPreferences;
	allTasksByCode = new Map<string, XerActivity>([]);
	allTasksById = new Map<number, XerActivity>([]);
	allRelationships = new Map<string, [ScheduleAnalysisTask, ScheduleAnalysisTask, TaskPredArrayInterface]>([]);
	baselineTargets = new Map<string, BaselineTargetDates>([]);

	totalActivities = 1;
	selectedTest = new BehaviorSubject<string>('overall');
	selectedIndex = 20;
	currentDisplaySet: ExpandedMetricsValues = [];
	unfilteredDisplaySet: ExpandedMetricsValues = [];
	tableSearch = '';
	tableDataType: 'Activity' | 'Relationship' = 'Activity';
	searchItemKeys: [ExpandedMetricsValues[0], Set<string>][] = [];
	isLoading = true;

	scheduleAnalysisSearchBarString = '';
	public assessments: Array<DCMAMetric> = [];
	qualitySettings: Map<string, QualSettings> = new Map<string, QualSettings>([]);
	categories: string[] = [];
	seriesData: SeriesDataSettings[] = [];
	valueAxisItemSettings: AxisSettings[] = [
		{
			title: {
				text: '',
				visible: false,
			},
			labels: {
				format: '{0}%',
			},
			min: 0,
			max: 100,
			majorGridLines: {
				visible: true,
			},
		},
	];
	@ViewChild(GridComponent)
	public grid: GridComponent;
	public gridView: GridDataResult;
	gridData: Array<any> = [];
	public pageSize = 100;
	public skip = 0;
	public sort: SortDescriptor[] = [];
	selectedColumns: ScheduleAnalysisColumn[] = [];
	hasNotes: boolean = false;
	@Input() focusCode: SubCodeFilterItem = null;

	constructor(
		public project: ProjectDashboardService,
		private ngZone: NgZone,
		public schedStorage: ScheduleStorageService
	) {}

	doHistoricals(historicalCounts: DCMAMetrics['historicalCounts']) {
		const historicalAssessments: Array<Array<DCMAMetric>> = [];
		const focusKey: string = this.focusCode?.name + '%--//--%' + this.focusCode?.shortName;
		for (let historicalUpdate = 0; historicalUpdate < historicalCounts.bei.length; historicalUpdate++) {
			const dcma = {
				bei: historicalCounts.bei[historicalUpdate],
				cpli: historicalCounts.cpli[historicalUpdate],
				hardConstraints: historicalCounts.hardConstraints[historicalUpdate],
				highDuration: historicalCounts.highDuration[historicalUpdate],
				highDurationUseRD: historicalCounts?.highDurationUseRD
					? historicalCounts.highDurationUseRD[historicalUpdate]
					: 0,
				highFloat: historicalCounts.highFloat[historicalUpdate],
				incompleteMissingPredSucc: historicalCounts.incompleteMissingPredSucc[historicalUpdate],
				incompleteMissingResources: historicalCounts.incompleteMissingResources[historicalUpdate],
				incompleteWithLag: historicalCounts.incompleteWithLag[historicalUpdate],
				invalidDates: historicalCounts.invalidDates[historicalUpdate],
				negativeFloat: historicalCounts.negativeFloat[historicalUpdate],
				negativeLags: historicalCounts.negativeLags[historicalUpdate],
				nonFSRelationships: historicalCounts.nonFSRelationships[historicalUpdate],
				numIncompleteRelationships: historicalCounts.numIncompleteRelationships[historicalUpdate],
				numIncompleteTasks: historicalCounts.numIncompleteTasks[historicalUpdate],
				passesCriticalPathTest: historicalCounts.passesCriticalPathTest[historicalUpdate],
				positiveLags: historicalCounts.positiveLags[historicalUpdate],
				slippedTasks: historicalCounts.slippedTasks[historicalUpdate],
			};
			if (this.isFocus) {
				for (const historicalByCodeKey in this.project.$currentProjectReport.value?.dcma?.historicalByCode) {
					const reportValsHistorical =
						this.project.$currentProjectReport.value?.dcma?.historicalByCode[historicalByCodeKey][focusKey];
					if (reportValsHistorical?.length) {
						const normalizedArr = [];
						for (let i = 0; i < historicalCounts.bei.length; i++) {
							const reportVal = i < reportValsHistorical.length ? reportValsHistorical[i] : 0;
							normalizedArr.push(reportVal === null || reportVal === undefined ? 0 : reportVal);
						}
						dcma[historicalByCodeKey] = normalizedArr[historicalUpdate];
					} else {
						dcma[historicalByCodeKey] = [];
					}
				}
			}

			const metricTypes: DCMAMetric[] = (metrics?.metrics as DCMAMetric[]) || [];
			let numPass: number = 0;
			let numTotal: number = 0;
			let assessments: Array<DCMAMetric> = [];
			for (const metricType of metricTypes) {
				const dcmaMetric: DCMAMetric = {
					...metricType,
				};
				let metricValue: number | boolean = dcma[metricType.key];
				const metricPool =
					metricType.key === 'highFloat'
						? this.isFocus
							? this.project.$currentProjectReport.value.dcma.historicalByCode.numIncompleteTasksInQC[focusKey]
								? this.project.$currentProjectReport.value.dcma.historicalByCode.numIncompleteTasksInQC[focusKey][
										this.project.$currentProjectReport.value.dcma.historicalByCode.numIncompleteTasksInQC[focusKey]
											.length - 1
									]
								: 0
							: this.project.$currentProjectReport.value.dcma.currentMetrics.numIncompleteTasksInQC || 0
						: metricType.key === 'highDuration'
							? this.isFocus
								? this.project.$currentProjectReport.value.dcma.historicalByCode
										.numIncompleteTasksExcludingMilestonesInQC[focusKey]
									? this.project.$currentProjectReport.value.dcma.historicalByCode
											.numIncompleteTasksExcludingMilestonesInQC[focusKey][
											this.project.$currentProjectReport.value.dcma.historicalByCode
												.numIncompleteTasksExcludingMilestonesInQC[focusKey].length - 1
										]
									: 0
								: this.project.$currentProjectReport.value.dcma.currentMetrics
										.numIncompleteTasksExcludingMilestonesInQC || 0
							: metricType.denominatorKey
								? metricType.denominatorKey === 'numIncompleteTasks'
									? this.isFocus
										? this.project.$currentProjectReport.value.dcma.historicalByCode.numIncompleteTasks[focusKey][
												this.project.$currentProjectReport.value.dcma.historicalByCode.numIncompleteTasks[focusKey]
													.length - 1
											]
										: this.project.$currentProjectReport.value.dcma.currentMetrics.numIncompleteTasks
									: metricType.denominatorKey === 'numIncompleteRelationships'
										? this.isFocus
											? this.project.$currentProjectReport.value.dcma.historicalByCode.numIncompleteRelationships[
													focusKey
												][
													this.project.$currentProjectReport.value.dcma.historicalByCode.numIncompleteRelationships[
														focusKey
													].length - 1
												]
											: this.project.$currentProjectReport.value.dcma.currentMetrics.numIncompleteRelationships
										: dcma[metricType.denominatorKey] || 1
								: undefined;
				if (typeof metricValue === 'number' && metricPool && +metricPool) {
					metricValue /= metricPool;
					const descValueStr = Math.round(metricValue * 100) + '%';

					dcmaMetric.description = dcmaMetric.description.replace('{value}', metricValue === 0 ? '0' : descValueStr);
				} else {
					dcmaMetric.description = dcmaMetric.description.replace('{value}', metricValue?.toString() || '0');
				}
				if (this.qualitySettings.get(dcmaMetric.key).threshold !== null) {
					const numDays: string = this.qualitySettings.get(dcmaMetric.key).threshold.toString();
					dcmaMetric.description = dcmaMetric.description.replace('{numDays}', numDays);
				}
				if (this.isFocus) {
					if (dcmaMetric?.denominatorKey) {
						const denominatorKeyArr =
							this.project.$currentProjectReport.value.dcma.historicalByCode[dcmaMetric.denominatorKey];
						if (denominatorKeyArr) {
							const denominatorByFocusKey = denominatorKeyArr[focusKey];
							if (denominatorByFocusKey) {
								const denominatorByFocusForThisUpdate = denominatorByFocusKey[historicalUpdate];
								if (denominatorByFocusForThisUpdate !== undefined) {
									dcmaMetric.denominator = denominatorByFocusForThisUpdate;
								}
							}
						}
					}
					if (this.project.$currentProjectReport.value.dcma.historicalByCode[metricType.key]) {
						if (this.project.$currentProjectReport.value.dcma.historicalByCode[metricType.key][focusKey]) {
							const reportValues =
								this.project.$currentProjectReport.value.dcma.historicalByCode[metricType.key][focusKey];
							dcmaMetric.numerator = historicalUpdate >= reportValues.length ? 0 : reportValues[historicalUpdate];
							metricValue = dcmaMetric.numerator / (dcmaMetric.denominator || 1);
						} else {
							dcmaMetric.elements = [];
							metricValue = 0;
						}
					} else {
						dcmaMetric.elements = [];
						metricValue = 0;
					}
				}

				metricValue = typeof metricValue === 'number' && metricValue >= 1 ? metricValue / 100 : metricValue;
				dcmaMetric.value = metricValue;

				const min: number =
					this.qualitySettings.get(dcmaMetric.key).min >= 1
						? this.qualitySettings.get(dcmaMetric.key).min / 100
						: this.qualitySettings.get(dcmaMetric.key).min !== undefined
							? this.qualitySettings.get(dcmaMetric.key).min
							: null;
				const max: number =
					this.qualitySettings.get(dcmaMetric.key).max >= 1
						? this.qualitySettings.get(dcmaMetric.key).max / 100
						: this.qualitySettings.get(dcmaMetric.key).max !== undefined
							? this.qualitySettings.get(dcmaMetric.key).max
							: null;
				if (min !== null) {
					dcmaMetric.min = min;
				}
				if (max !== null) {
					dcmaMetric.max = max;
				}

				dcmaMetric.passes =
					typeof metricValue === 'boolean'
						? metricValue === true
						: (min === null || metricValue >= min) && (max === null || metricValue <= max);
				if (dcmaMetric.passes) {
					assessments.push(dcmaMetric);
				} else {
					assessments.unshift(dcmaMetric);
				}
				if (this.qualitySettings.get(dcmaMetric.key).enabled) {
					numPass += dcmaMetric.title !== 'Resources' && dcmaMetric.passes ? 1 : 0;
					numTotal += 1;
				}
			}
			assessments = assessments.sort((a: DCMAMetric, b: DCMAMetric) => {
				const qualA: QualSettings = this.qualitySettings.get(a.key);
				const qualB: QualSettings = this.qualitySettings.get(b.key);
				const valA: number = qualA.enabled ? (a.passes ? 1 : 0) : 2;
				const valB: number = qualB.enabled ? (b.passes ? 1 : 0) : 2;
				return valA < valB ? -1 : valA > valB ? 1 : 0;
			});
			const passPct: number = (100 * numPass) / numTotal;
			assessments.unshift({
				title: 'Overall',
				description: numPass + ' / ' + numTotal + ' (' + Math.round(passPct) + '%) assessments passed',
				passes: numPass === numTotal,
				value: passPct,
				key: 'overall',
			});

			historicalAssessments.push(assessments);
		}

		return historicalAssessments;
	}

	ngOnInit(lateCall: string = ''): void {
		this.project.$expandedMetrics.subscribe(async (expandedMetrics) => {
			if (!expandedMetrics) {
				return;
			}
			this.allTasksByCode.clear();
			this.allRelationships.clear();
			this.allTasksById.clear();
			const tasks = expandedMetrics.totalActivities || [];
			this.totalActivities = tasks.length;
			const updateXer = await this.schedStorage.grabUpdateXer(expandedMetrics.updateId);
			for (const activity of updateXer.sortedActivities) {
				if (activity.code) {
					this.allTasksByCode.set(activity.code, activity.raw_entry);
				}
				this.allTasksById.set(activity.id, activity.raw_entry);
			}
			this.calendars.clear();
			const calendars = expandedMetrics.calendars || [];
			for (const calendar of calendars) {
				this.calendars.set(calendar.clndr_id, calendar);
			}
			const relationships = updateXer.activityPredecessors.values();
			for (const relationship of relationships) {
				const predTask = this.allTasksById.get(relationship.prevActivityId);
				const nextTask = this.allTasksById.get(relationship.activityId);
				const relationshipKey = `${predTask?.task_code},${nextTask?.task_code},${relationship.previousRelationType}`;
				this.allRelationships.set(relationshipKey, [
					predTask,
					nextTask,
					relationship.raw_entry as TaskPredArrayInterface,
				]);
			}
			this.baselineTargets.clear();
			for (const baselineTarget of expandedMetrics.baselineTargets || []) {
				this.baselineTargets.set(baselineTarget.code, baselineTarget);
			}
			this.updateAssessmentItems(expandedMetrics, this.assessments);
			const cpr = this.project.$currentProjectReport.value;
			this.callTheFunction(
				{
					...(this.focusReport || cpr),
					project: cpr.project,
				},
				lateCall
			);
		});
		this.selectedTest.subscribe((key: string) => {
			this.selectedIndex = 20;
			let selectedTest: DCMAMetric | undefined;
			for (let i = 0; i < this.assessments.length; i++) {
				if (this.assessments[i].key === key) {
					this.selectedIndex = i;
					selectedTest = this.assessments[i];
					break;
				}
			}

			this.searchItemKeys = [];
			if (selectedTest?.elements instanceof Array) {
				this.unfilteredDisplaySet = selectedTest.fullElements || [];
				if (selectedTest.denominatorKey === 'numIncompleteTasks') {
					this.tableDataType = 'Activity';
				} else if (selectedTest.denominatorKey === 'numIncompleteRelationships') {
					this.tableDataType = 'Relationship';
				}
				this.scheduleAnalysisSearchBarString = `Search ${this.tableDataType}...`;
				for (const item of this.unfilteredDisplaySet) {
					//eslint-disable-next-line
					const task = this.allTasksById.get(+item['task_id']);
					//eslint-disable-next-line
					const predTask = this.allTasksById.get(+item['pred_task_id']);
					const taskFilterKeys = ['task_id', 'task_name', 'task_code'];
					if (task) {
						this.searchItemKeys.push([item, getLowercaseValues(task, taskFilterKeys)]);
						item.succCode = task.task_code;
						item.succName = task.task_name;
						item.succStartAct = task.act_start_date;
						item.succStartEarly = task.early_start_date;
						item.succEndAct = task.act_end_date;
						item.succEndEarly = task.early_end_date;
						item.succRd = task.remain_drtn_hr_cnt / (this.calendars.get(+task.clndr_id)?.day_hr_cnt || 8);
						if (task.task_code && this.baselineTargets.has(task.task_code)) {
							item.baselineTargetStart = this.baselineTargets.get(task.task_code).start;
							item.baselineTargetFinish = this.baselineTargets.get(task.task_code).finish;
						}
						const hoursFields = [
							{
								taskField: 'remain_drtn_hr_cnt',
								customField: 'remainingHrs',
							},
							{
								taskField: 'target_drtn_hr_cnt',
								customField: 'odHrs',
							},
							{
								taskField: 'total_float_hr_cnt',
								customField: 'tfHrs',
							},
						];
						hoursFields.forEach((field) => {
							if (field.taskField in task) {
								item[field.customField] = task[field.taskField] / (this.calendars.get(+task.clndr_id)?.day_hr_cnt || 8);
							} else {
								item[field.customField] = 0;
							}
						});
					}
					if (predTask) {
						this.searchItemKeys.push([item, getLowercaseValues(predTask, taskFilterKeys)]);
						item.predCode = predTask.task_code;
						item.predName = predTask.task_name;
						item.predStartAct = predTask.act_start_date;
						item.predStartEarly = predTask.early_start_date;
						item.predEndAct = predTask.act_end_date;
						item.predEndEarly = predTask.early_end_date;
						item.predRd = predTask.remain_drtn_hr_cnt / (this.calendars.get(+predTask.clndr_id)?.day_hr_cnt || 8);
					}
					if ('lag_hr_cnt' in item) {
						item.lagHrs = this.divByCalendar(item.lag_hr_cnt, task || predTask);
					} else {
						item.lagHrs = 0;
					}
					const overviewDate = new Date(this.project.$currentProjectReport.value?.projectOverview?.dataDate);
					item.dataDate = new Date(
						overviewDate.getUTCFullYear(),
						overviewDate.getUTCMonth(),
						overviewDate.getUTCDate()
					);
				}
			}
			this.updateFilter({ searchTerm: this.tableSearch });
		});

		this.project.$currentProjectReport.subscribe((projectReport) => {
			this.callTheFunction(
				{
					...(this.focusReport || projectReport),
					project: projectReport.project,
				},
				lateCall
			);
		});
		this.project.$currentProjectData.subscribe((val) => {
			if (val) {
				if (val?.qualityPreferences?.highDurationUseRD) {
					const matchingBtnData = allColumns.buttons.find((btn) => btn.name === 'highDuration');
					matchingBtnData.columnsDisplayed = [0, 1, 6, 7, 12, 15, 17];
				}
				const savedNotes = val.componentNotes?.find((n) => n.id === 11)?.notes;
				this.hasNotes = savedNotes?.length && savedNotes[savedNotes?.length - 1]?.note !== '';
			}
		});
	}

	callTheFunction(report: CurrentProjectReport, lateCall: string): void {
		let i: number = 0;
		const hasLoaded = setInterval(() => {
			if (!this.isFocus || (this.isFocus && this.focusCode !== undefined) || i > 500) {
				this.updateDCMAMetrics(report, lateCall);
				clearInterval(hasLoaded);
			}
			i++;
		}, 200);
	}

	public ngAfterViewInit(): void {
		this.fitColumns();
	}

	updateFilter(args: { event?: any; searchTerm?: string }) {
		const searchTerm: string = args?.event?.target?.value?.toLowerCase() || args.searchTerm;
		this.tableSearch = searchTerm;
		let filteredDisplaySet = new Set<ExpandedMetricsValues[0]>([]);
		if (!searchTerm) {
			filteredDisplaySet = new Set<ExpandedMetricsValues[0]>(this.unfilteredDisplaySet || []);
		} else {
			for (const [item, valuesToLower] of this.searchItemKeys) {
				for (const valLower of valuesToLower) {
					if (valLower.includes(searchTerm)) {
						filteredDisplaySet.add(item);
					}
				}
			}
		}

		// update the rows
		this.currentDisplaySet = Array.from(filteredDisplaySet);
		const matchingBtnData = allColumns.buttons.find((btn) => btn.name === this.selectedTest.value);
		this.selectedColumns = [];
		if (matchingBtnData === undefined) {
			return;
		}
		allColumns.columns.forEach((column: ScheduleAnalysisColumn) => {
			if (matchingBtnData.columnsDisplayed.includes(column.id)) {
				this.selectedColumns.push(column);
			}
		});
		this.loadActivities();
	}

	updateAssessmentItems(expandedMetrics: ExpandedMetrics, assessments: DCMAMetric[]) {
		if (Object.keys(expandedMetrics || {}).length && assessments.length) {
			this.isLoading = false;
		}
		for (const assessment of assessments) {
			const assessmentItems: ExpandedMetricsValues = [];
			const assessmentElementCodes: string[] = assessment.elements || [];
			for (const element of assessmentElementCodes) {
				const taskItem = this.allTasksByCode.get(element);
				if (taskItem) {
					assessmentItems.push(taskItem);
				} else {
					if (element?.length === 3) {
						const relationKey = `${element[0]},${element[1]},${element[2]}`;
						const relationItem = this.allRelationships.get(relationKey);
						if (relationItem) {
							assessmentItems.push(relationItem[2]);
						}
					}
				}
			}
			assessment.fullElements = assessmentItems;
		}
	}

	updateDCMAMetrics(
		projectReport: Pick<ProjectReportInterface, 'dcma' | 'qualityControl' | 'baselineUpdateId' | 'updateIds'>,
		lateCall = '',
		qualitySettings?: QualityPreferences
	) {
		const qualPref: QualityPreferences = this.project.$currentProjectData.value.qualityPreferences;
		if (qualPref) {
			const qualSett: QualSettings[] = [
				{
					key: 'overall',
					enabled: true,
					min: null,
					max: null,
					threshold: null,
				},
				{
					key: 'bei',
					enabled: qualPref.useBei,
					min: qualPref.beiMin,
					max: null,
					threshold: null,
				},
				{
					key: 'cpli',
					enabled: qualPref.useCpli,
					min: qualPref.cpliMin,
					max: null,
					threshold: null,
				},
				{
					key: 'passesCriticalPathTest',
					enabled: qualPref.requireCritPath,
					min: null,
					max: null,
					threshold: null,
				},
				{
					key: 'hardConstraints',
					enabled: qualPref.useHardConstraints,
					min: null,
					max: qualPref.hardConstraintsMax,
					threshold: null,
				},
				{
					key: 'highDuration',
					enabled: qualPref.useHighDuration,
					min: null,
					max: qualPref.highDurationMax,
					threshold: qualPref.highDurationDays,
				},
				{
					key: 'highFloat',
					enabled: qualPref.useHighFloat,
					min: null,
					max: qualPref.highFloatMax,
					threshold: qualPref.highFloatDays,
				},
				{
					key: 'invalidDates',
					enabled: qualPref.useInvalidDates,
					min: null,
					max: qualPref.invalidDatesMax,
					threshold: null,
				},
				{
					key: 'positiveLags',
					enabled: qualPref.usePositiveLag,
					min: null,
					max: qualPref.positiveLagMax,
					threshold: null,
				},
				{
					key: 'negativeLags',
					enabled: qualPref.useNegativeLag,
					min: null,
					max: qualPref.negativeLagMax,
					threshold: null,
				},
				{
					key: 'incompleteMissingPredSucc',
					enabled: qualPref.useMissingPredSucc,
					min: null,
					max: qualPref.missingPredSuccMax,
					threshold: null,
				},
				{
					key: 'slippedTasks',
					enabled: qualPref.useMissedTask,
					min: null,
					max: qualPref.missedTaskMax,
					threshold: null,
				},
				{
					key: 'negativeFloat',
					enabled: qualPref.useNegativeFloat,
					min: null,
					max: qualPref.negativeFloatMax,
					threshold: null,
				},
				{
					key: 'nonFSRelationships',
					enabled: qualPref.useNonFS,
					min: null,
					max: qualPref.nonFSMax,
					threshold: null,
				},
				{
					key: 'incompleteMissingResources',
					enabled: qualPref.useUnassignedResource,
					min: null,
					max: qualPref.unassignedResourceMax,
					threshold: null,
				},
			];
			this.qualitySettings = new Map(qualSett.map((s: QualSettings) => [s.key, s]));
		}
		const metricTypes: DCMAMetric[] = (metrics?.metrics as DCMAMetric[]) || [];
		const dcma = projectReport?.dcma?.currentMetrics;
		this.totalActivities = projectReport?.qualityControl.totalActivities || 1;
		if (!dcma) {
			return;
		}
		let numPass: number = 0;
		let numTotal: number = 0;
		let assessments: Array<DCMAMetric> = [];
		for (const metricType of metricTypes) {
			const focusKey: string = this.focusCode?.name + '%--//--%' + this.focusCode?.shortName;
			const dcmaMetric: DCMAMetric = {
				...metricType,
			};
			let metricValue = dcma[metricType.key];
			if (metricValue instanceof Array) {
				/*const isRel: boolean = metricValue[0]?.length === 3;
				const filteredMetricValue = metricValue.filter((t) => {
					if (isRel) {
						// pred task needs to be in code
						const task1: XerActivity = this.allTasksByCode.get(t[0]);
						const task2: XerActivity = this.allTasksByCode.get(t[1]);
						const a = this.currActvsByTask[task1.task_id];
						return (
							(!this.isFocus || a?.some((ta) => ta.actv_code_id === this.focusCode.id)) &&
							task2.status_code !== 'TK_Complete'
						);
					} else {
						const task1: XerActivity = this.allTasksByCode.get(t);
						const a = this.currActvsByTask[task1.task_id];
						return a === undefined
							? false
							: (metricType.key === 'slippedTasks'
									? task1.status_code === 'TK_Complete'
									: task1.status_code !== 'TK_Complete') &&
									(!this.isFocus || a?.some((ta) => ta.actv_code_id === this.focusCode.id));
					}
				});*/
				dcmaMetric.elements = metricValue;
				metricValue = metricValue.length;
				if (this.isFocus) {
					if (projectReport.dcma.historicalByCode[metricType.key]) {
						if (projectReport.dcma.historicalByCode[metricType.key][focusKey]) {
							const reportValues = projectReport.dcma.historicalByCode[metricType.key][focusKey];

							metricValue =
								reportValues.length < projectReport.updateIds.length ? 0 : reportValues[reportValues.length - 1];
						} else {
							dcmaMetric.elements = [];
							metricValue = 0;
						}
					} else {
						dcmaMetric.elements = [];
						metricValue = 0;
					}
				}
			}
			const metricPool =
				metricType.key === 'highFloat'
					? this.isFocus
						? projectReport.dcma.historicalByCode.numIncompleteTasksInQC[focusKey]
							? projectReport.dcma.historicalByCode.numIncompleteTasksInQC[focusKey][
									projectReport.dcma.historicalByCode.numIncompleteTasksInQC[focusKey].length - 1
								]
							: 0
						: projectReport.dcma.currentMetrics.numIncompleteTasksInQC || 0
					: metricType.key === 'highDuration'
						? this.isFocus
							? projectReport.dcma.historicalByCode.numIncompleteTasksExcludingMilestonesInQC[focusKey]
								? projectReport.dcma.historicalByCode.numIncompleteTasksExcludingMilestonesInQC[focusKey][
										projectReport.dcma.historicalByCode.numIncompleteTasksExcludingMilestonesInQC[focusKey].length - 1
									]
								: 0
							: projectReport.dcma.currentMetrics.numIncompleteTasksExcludingMilestonesInQC || 0
						: metricType.denominatorKey
							? metricType.denominatorKey === 'numIncompleteTasks'
								? this.isFocus
									? projectReport.dcma.historicalByCode.numIncompleteTasks[focusKey][
											projectReport.dcma.historicalByCode.numIncompleteTasks[focusKey].length - 1
										]
									: projectReport.dcma.currentMetrics.numIncompleteTasks
								: metricType.denominatorKey === 'numIncompleteRelationships'
									? this.isFocus
										? projectReport.dcma.historicalByCode.numIncompleteRelationships[focusKey][
												projectReport.dcma.historicalByCode.numIncompleteRelationships[focusKey].length - 1
											]
										: projectReport.dcma.currentMetrics.numIncompleteRelationships
									: dcma[metricType.denominatorKey] || 1
							: undefined;
			dcmaMetric.denominator = metricPool;
			dcmaMetric.numerator = metricValue;
			if (metricPool && +metricPool) {
				metricValue /= metricPool;
				const descValueStr = toDCMAPct(metricValue, [metricType.min, metricType.max]) + '%';

				dcmaMetric.description = dcmaMetric.description.replace('{value}', metricValue === 0 ? '0' : descValueStr);
			} else {
				dcmaMetric.description = dcmaMetric.description.replace('{value}', metricValue?.toString() || '0');
			}
			if (this.qualitySettings.get(dcmaMetric.key).threshold !== null) {
				const numDays: string = this.qualitySettings.get(dcmaMetric.key).threshold.toString();
				dcmaMetric.description = dcmaMetric.description.replace('{numDays}', numDays);
			}
			metricValue = typeof metricValue === 'number' && metricValue >= 1 ? metricValue / 100 : metricValue;
			dcmaMetric.value = metricValue;

			const min: number =
				this.qualitySettings.get(dcmaMetric.key).min >= 1
					? this.qualitySettings.get(dcmaMetric.key).min / 100
					: this.qualitySettings.get(dcmaMetric.key).min !== undefined
						? this.qualitySettings.get(dcmaMetric.key).min
						: null;
			const max: number =
				this.qualitySettings.get(dcmaMetric.key).max >= 1
					? this.qualitySettings.get(dcmaMetric.key).max / 100
					: this.qualitySettings.get(dcmaMetric.key).max !== undefined
						? this.qualitySettings.get(dcmaMetric.key).max
						: null;

			if (min) {
				dcmaMetric.min = min;
			}
			if (max) {
				dcmaMetric.max = max;
			}

			dcmaMetric.passes =
				metricValue === true || (min != null && metricValue >= min) || (max != null && metricValue <= max);
			if (dcmaMetric.passes) {
				assessments.push(dcmaMetric);
			} else {
				assessments.unshift(dcmaMetric);
			}
			if (this.qualitySettings.get(dcmaMetric.key).enabled) {
				numPass += dcmaMetric.title === 'Resources' ? 0 : dcmaMetric.passes ? 1 : 0;
				numTotal += 1;
			}
		}
		assessments = assessments.sort((a: DCMAMetric, b: DCMAMetric) => {
			const qualA: QualSettings = this.qualitySettings.get(a.key);
			const qualB: QualSettings = this.qualitySettings.get(b.key);
			const valA: number = qualA.enabled ? (a.passes ? 1 : 0) : 2;
			const valB: number = qualB.enabled ? (b.passes ? 1 : 0) : 2;
			return valA < valB ? -1 : valA > valB ? 1 : 0;
		});

		const passPct: number = (100 * numPass) / numTotal;
		assessments.unshift({
			title: 'Overall',
			description: numPass + ' / ' + numTotal + ' (' + Math.round(passPct) + '%) assessments passed',
			passes: numPass === numTotal,
			value: passPct,
			key: 'overall',
		});

		const historicals = this.doHistoricals(this.project.$currentProjectReport.value.dcma.historicalCounts);
		const historicalOveralls = [];
		const categories: string[] = [];
		const data: SeriesData[] = [];
		historicals.forEach((val, index) => {
			historicalOveralls.push(val[0].value);
			const category =
				index === 0
					? 'Baseline'
					: 'Update ' + index + (projectReport?.baselineUpdateId === projectReport?.updateIds[index] ? ' ®' : '');
			categories.push(category);
			data.push({
				category,
				value: typeof val[0].value === 'number' ? val[0].value : val[0].value ? 100 : 0,
				valLabel: val[0].description.split(' assessments')[0],
			});
		});
		this.categories = categories;
		this.seriesData = [
			{
				type: 'line',
				data,
				name: '',
				visible: true,
				color: 'black',
			},
		];

		this.assessments = assessments;
		this.updateAssessmentItems(this.expandedMetrics.getValue(), this.assessments);
		this.selectedTest.next('overall');

		if (lateCall !== '') {
			this.selectedTest.next(lateCall);
		}
	}

	public pageChange(event: PageChangeEvent): void {
		this.skip = event.skip;
		this.loadActivities();
	}

	public sortChange(sort: SortDescriptor[]): void {
		this.sort = sort;
		this.loadActivities();
	}

	public loadActivities(): void {
		this.gridView = {
			data: this.currentDisplaySet?.slice(this.skip, this.skip + this.pageSize),
			total: this.currentDisplaySet?.length,
		};
		this.gridData = this.currentDisplaySet;
	}

	getCalendar(calendar_id: number) {
		for (let i = 0; i < this.project.$expandedMetrics.value.calendars.length; i++) {
			if (this.project.$expandedMetrics.value.calendars[i]?.clndr_id === calendar_id) {
				return this.project.$expandedMetrics.value.calendars[i];
			}
		}
		return null;
	}

	divByCalendar(value: any, row: any) {
		return value / (this.getCalendar(row?.clndr_id)?.day_hr_cnt || 8) || value / 8;
	}

	public onDataStateChange(): void {
		this.fitColumns();
	}

	private fitColumns(): void {
		this.ngZone.onStable
			.asObservable()
			.pipe(take(1))
			.subscribe(() => {
				this.grid?.autoFitColumns();
			});
	}
}
