import { Component, Input, OnInit } from '@angular/core';
import { ProjectDashboardService } from '../../../services/project/project.service';
import { ComponentNotes } from '../../../models/Project';
import { Note } from '../../project-page/component-notes/component-notes.component';

@Component({
	selector: 'app-overview-notes',
	templateUrl: './overview-notes.component.html',
	styleUrls: ['./overview-notes.component.scss'],
})
export class OverviewNotesComponent implements OnInit {
	@Input() componentId: number = -1;
	@Input() squareBrTop: boolean = false;
	notes: Note[] = [];
	currentUpdateNotes: string = '';
	isScreenshotMode: boolean = false;
	constructor(public projectService: ProjectDashboardService) {}

	ngOnInit() {
		this.projectService.$currentProjectData.subscribe((val) => {
			if (val && this.componentId > -1) {
				this.getSavedNotes();
			}
		});
	}

	getSavedNotes(): void {
		const savedNotes: ComponentNotes = this.projectService.getNotes(this.componentId);
		let notes: Note[];
		if (savedNotes) {
			notes = this.projectService.$currentProjectData.value.updateIds.map((id: string) => ({
				note: savedNotes.notes.find((n) => n.updateId === id)?.note || '',
				updateId: id,
				lastEditTimestamp: savedNotes.notes.find((n) => n.updateId === id)?.lastEditTimestamp || null,
				lastEditUser: savedNotes.notes.find((n) => n.updateId === id)?.lastEditUser || '',
			}));
			notes.forEach(
				(note: Note) =>
					(note.lastEditTimestamp = note.lastEditTimestamp === null ? null : new Date(note.lastEditTimestamp))
			);
		} else {
			notes = this.getEmptyNotes();
		}
		this.notes = notes;
		this.currentUpdateNotes = structuredClone(notes[notes.length - 1]?.note);
		this.isScreenshotMode = localStorage.getItem('isScreenshotMode') === 'true';
	}

	getEmptyNotes(): Note[] {
		return this.projectService.$currentProjectData.value.updateIds.map((id: string) => ({
			note: '',
			updateId: id,
			lastEditTimestamp: null,
			lastEditUser: '',
		}));
	}
}
