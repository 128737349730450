import { Injectable } from '@angular/core';
import { CashFlow } from '../../models/ProjectReport/ProjectReport';
import { SeriesData } from '../../models/ChartSettings';
import { addMonths, isAfter, isBefore, isSameDay, subMonths } from 'date-fns';
import { ProjectDashboardService } from '../project/project.service';
import { BehaviorSubject } from 'rxjs';
import { ProjectListItem } from '../../components/portfolio/project-list/project-list/project-list.component';

export interface CashFlowChartData {
	earlyPeriodic: SeriesData[];
	avgPeriodic: SeriesData[];
	latePeriodic: SeriesData[];
	actPeriodic: SeriesData[];
	earlyCumulative: SeriesData[];
	avgCumulative: SeriesData[];
	lateCumulative: SeriesData[];
	actCumulative: SeriesData[];
	plannedPeriodic: SeriesData[];
	plannedCumulative: SeriesData[];
	plannedPeriodicLate: SeriesData[];
	plannedCumulativeLate: SeriesData[];
	forecastEarly: SeriesData[];
	forecastAvg: SeriesData[];
	forecastLate: SeriesData[];
	categories: string[];
	remainingCost: number;
	nextPeriodPlanned: number;
	budgetedCost: number;
	currentBudget: number;
	currentRemaining: number;
}

export interface HistoricPerformanceChartData {
	plannedPeriodic: SeriesData[];
	actualPeriodic: SeriesData[];
	plannedCumulative: SeriesData[];
	actualCumulative: SeriesData[];
	historicPlannedLatePeriodic: SeriesData[];
	historicPlannedLateCumulative: SeriesData[];
	categories: string[];
}

export interface SPIChartData {
	spiPeriodic: SeriesData[];
	spiCumulative: SeriesData[];
	categories: string[];
}

@Injectable({
	providedIn: 'root',
})
export class CostService {
	spiPeriodic = null;
	spiCumulative = null;
	$lastMonthVariance = new BehaviorSubject<number>(null);
	$latestSPI = new BehaviorSubject<number>(null);
	$previousMonth = new BehaviorSubject<number>(null);
	$thisMonth = new BehaviorSubject<number>(null);
	$nextMonth = new BehaviorSubject<number>(null);
	projectCashFlowData = new Map<string, CashFlowChartData>([]);
	projectHistoricalPerformanceData = new Map<string, HistoricPerformanceChartData>([]);
	projectSPIData = new Map<string, SPIChartData>([]);
	$activeCostProjects = new BehaviorSubject<number>(null);
	$monthNames = new BehaviorSubject<string[]>(['', 'This', 'Next']);
	$updatePortfolioProjectData = new BehaviorSubject<boolean>(false);
	$prevPlannedBudgeted = new BehaviorSubject<number>(null);
	$prevActual = new BehaviorSubject<number>(null);
	$prevDelta = new BehaviorSubject<number>(null);
	$totalBudgetedCost = new BehaviorSubject<number>(null);
	$currentBudgetedCost = new BehaviorSubject<number>(null);
	$baselineRemaining = new BehaviorSubject<number>(null);
	$currentRemaining = new BehaviorSubject<number>(null);
	$totalActual = new BehaviorSubject<number>(null);
	$nextMonthPlanned = new BehaviorSubject<number>(null);
	$totalActualPercentLabel = new BehaviorSubject<string>('');
	$totalRemainingPercentLabel = new BehaviorSubject<string>('');
	$prevActualPercentLabel = new BehaviorSubject<string>('');
	$prevDeltaPercentLabel = new BehaviorSubject<string>('');
	months: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	constructor(public projectService: ProjectDashboardService) {}

	/**
	 * update portfolio data based on portfolio allProjects change. ready to pluck from for all portfolio charts
	 * @param projects
	 */
	updatePortfolioData(projects: ProjectListItem[]): void {
		const costProjects: ProjectListItem[] = projects.filter((proj) => proj.cashFlow !== null);
		this.projectCashFlowData.clear();
		this.projectHistoricalPerformanceData.clear();
		this.projectSPIData.clear();
		costProjects.forEach((proj: ProjectListItem) => {
			if (proj?.costLoaded) {
				const dateString = proj.rawReport?.projectOverview?.dataDate.toString();
				const dateSplit = dateString.split(' ');
				const dataDate: Date = new Date(
					Number(dateSplit[3]),
					this.months.findIndex((m) => m === dateSplit[1])
				);
				const costBlIndex: number = proj.updateIds.findIndex((u: string) => u === proj?.costBaseline);
				const cashFlowData: CashFlowChartData = this.generateCashFlowData(
					proj.rawReport.cashFlowHistorical,
					dataDate,
					costBlIndex
				);
				if (cashFlowData !== undefined) {
					this.projectCashFlowData.set(proj._id, cashFlowData);
				}
				const historicalData: HistoricPerformanceChartData = this.generateHistoricPerformanceData(
					proj.rawReport.cashFlowHistorical,
					costBlIndex
				);
				if (historicalData !== undefined) {
					this.projectHistoricalPerformanceData.set(proj._id, historicalData);
				}
				const spiData: SPIChartData = this.generateSPIData(proj.rawReport.cashFlowHistorical, costBlIndex);
				if (spiData !== undefined) {
					this.projectSPIData.set(proj._id, spiData);
				}
			}
		});
		this.$updatePortfolioProjectData.next(true);
	}

	generateCashFlowData(costs: CashFlow[], dataDate: Date, costBaselineIndex: number = -1): CashFlowChartData {
		let actualData: SeriesData[] = [];
		const actualCumulativeData: SeriesData[] = [];
		const earlyData: SeriesData[] = [];
		const lateData: SeriesData[] = [];
		let categories: string[] = [];
		let cumulativeActVal = 0;
		let earlyCumulative: number = 0;
		let lateCumulative: number = 0;
		const earlyDataFilledIn: SeriesData[] = [];
		const earlyDataCumulativeFilledIn: SeriesData[] = [];
		const averageDataFilledIn: SeriesData[] = [];
		const averageDataCumulativeFilledIn: SeriesData[] = [];
		const lateDataFilledIn: SeriesData[] = [];
		const lateDataCumulativeFilledIn: SeriesData[] = [];
		if (costs?.length === 0) {
			return;
		}
		const blIndex: number = costBaselineIndex === -1 ? this.determineBaselineIndex(costs) : costBaselineIndex;
		const latestIndex: number = this.determineLatestIndex(costs);
		if (blIndex === -1 || latestIndex === -1) {
			return;
		}
		const baselineCosts: CashFlow = costs[blIndex];
		const latestCosts: CashFlow = costs[latestIndex];
		if (baselineCosts?.historicPlannedTargetCost === undefined) {
			return;
		}
		let historicPlannedValues: SeriesData[] = [];
		const historicPlannedValuesCumulative: SeriesData[] = [];
		let historicPlannedValuesLate: SeriesData[] = [];
		const historicPlannedValuesLateCumulative: SeriesData[] = [];
		const forecastEarly: SeriesData[] = [];
		const forecastAvg: SeriesData[] = [];
		const forecastLate: SeriesData[] = [];
		let cumulativeHistoricPlannedVal: number = 0;
		let cumulativeHistoricPlannedLateVal: number = 0;
		let totalBudgetedCost: number = 0;
		Object.entries(baselineCosts.historicPlannedTargetCost || {}).forEach(([key, data]) => {
			const correctDateString: string = this.projectService.prettifyDateString(this.projectService.msToDateString(key));
			historicPlannedValues.push({
				category: correctDateString,
				value: data,
			});
			totalBudgetedCost += data;
			if (categories.findIndex((c) => c === correctDateString) === -1) {
				categories.push(correctDateString);
			}
		});
		historicPlannedValues = historicPlannedValues.sort((a, b) => {
			const dateA: Date = this.projectService.dateStringToDate(a.category);
			const dateB: Date = this.projectService.dateStringToDate(b.category);
			return isBefore(dateA, dateB) ? -1 : isBefore(dateB, dateA) ? 1 : 0;
		});
		Object.entries(baselineCosts.historicPlannedLateTargetCost || {}).forEach(([key, data]) => {
			const correctDateString: string = this.projectService.prettifyDateString(this.projectService.msToDateString(key));
			historicPlannedValuesLate.push({
				category: correctDateString,
				value: data,
			});
			if (categories.findIndex((c) => c === correctDateString) === -1) {
				categories.push(correctDateString);
			}
		});
		historicPlannedValuesLate = historicPlannedValuesLate.sort((a, b) => {
			const dateA: Date = this.projectService.dateStringToDate(a.category);
			const dateB: Date = this.projectService.dateStringToDate(b.category);
			return isBefore(dateA, dateB) ? -1 : isBefore(dateB, dateA) ? 1 : 0;
		});
		Object.entries(latestCosts?.historicActualCost || {}).forEach(([key, data]) => {
			const correctDateString: string = this.projectService.prettifyDateString(this.projectService.msToDateString(key));
			actualData.push({
				category: correctDateString,
				value: data,
			});
			if (categories.findIndex((c) => c === correctDateString) === -1) {
				categories.push(correctDateString);
			}
		});
		actualData = actualData.sort((a, b) => {
			const dateA: Date = this.projectService.dateStringToDate(a.category);
			const dateB: Date = this.projectService.dateStringToDate(b.category);
			return isBefore(dateA, dateB) ? -1 : isBefore(dateB, dateA) ? 1 : 0;
		});
		const lastActCumulativeCategory: string = actualData[actualData.length - 1]?.category;
		const lastActDate: Date = this.projectService?.dateStringToDate(lastActCumulativeCategory);
		if (!latestCosts?.cumulativeRemainingTotalCost) {
			return;
		}
		Object.entries(latestCosts.estimateAtCompletionEarly || {}).forEach(([key, data]) => {
			const correctDateString: string = this.projectService.prettifyDateString(this.projectService.msToDateString(key));
			earlyData.push({
				category: correctDateString,
				value: data,
			});
			if (categories.findIndex((c) => c === correctDateString) === -1) {
				categories.push(correctDateString);
			}
		});
		Object.entries(latestCosts.estimateAtCompletionLate || {}).forEach(([key, data]) => {
			const correctDateString: string = this.projectService.prettifyDateString(this.projectService.msToDateString(key));
			lateData.push({
				category: correctDateString,
				value: data,
			});
			if (categories.findIndex((c) => c === correctDateString) === -1) {
				categories.push(correctDateString);
			}
		});
		categories = categories.sort((a, b) => {
			const dateA: Date = this.projectService.dateStringToDate(a);
			const dateB: Date = this.projectService.dateStringToDate(b);
			return isBefore(dateA, dateB) ? -1 : isBefore(dateB, dateA) ? 1 : 0;
		});
		categories.forEach((category: string) => {
			const earlyDataAtPeriod: SeriesData = earlyData.find((v: SeriesData) => v.category === category);
			const earlyValPeriodic: number = earlyDataAtPeriod === undefined ? null : earlyDataAtPeriod.value;
			const lateDataAtPeriod: SeriesData = lateData.find((v: SeriesData) => v.category === category);
			const lateValPeriodic: number = lateDataAtPeriod === undefined ? null : lateDataAtPeriod.value;
			const historicPlannedAtPeriod: SeriesData = historicPlannedValues.find(
				(v: SeriesData) => v.category === category
			);
			const historicPlannedVal: number = historicPlannedAtPeriod === undefined ? null : historicPlannedAtPeriod.value;
			const historicPlannedLateAtPeriod: SeriesData = historicPlannedValuesLate.find(
				(v: SeriesData) => v.category === category
			);
			const historicPlannedLateVal: number =
				historicPlannedLateAtPeriod === undefined ? null : historicPlannedLateAtPeriod.value;
			if (historicPlannedVal !== null) {
				cumulativeHistoricPlannedVal += historicPlannedVal;
			}
			historicPlannedValuesCumulative.push({
				category,
				value: cumulativeHistoricPlannedVal,
			});
			if (historicPlannedLateVal !== null) {
				cumulativeHistoricPlannedLateVal += historicPlannedLateVal;
			}
			historicPlannedValuesLateCumulative.push({
				category,
				value: cumulativeHistoricPlannedLateVal,
			});
			if (earlyValPeriodic !== null) {
				earlyCumulative += earlyValPeriodic;
			}
			if (lateValPeriodic !== null) {
				lateCumulative += lateValPeriodic;
			}
			const categoryAsDate: Date = this.projectService.dateStringToDate(category);
			if (category === lastActCumulativeCategory || isBefore(categoryAsDate, lastActDate)) {
				const actualPeriodVal: SeriesData = actualData.find((a: SeriesData) => a.category === category);
				const actualVal: number = actualPeriodVal === undefined ? null : actualPeriodVal.value;
				if (actualVal !== null) {
					cumulativeActVal += actualVal;
				}
				actualCumulativeData.push({
					category,
					value: cumulativeActVal,
				});
			} else {
				actualCumulativeData.push({
					category,
					value: null,
				});
			}
			earlyDataFilledIn.push({
				category,
				value: earlyValPeriodic,
			});
			lateDataFilledIn.push({
				category,
				value: lateValPeriodic,
			});
			averageDataFilledIn.push({
				category,
				value: earlyValPeriodic === null || lateValPeriodic === null ? null : (earlyValPeriodic + lateValPeriodic) / 2,
			});
			earlyDataCumulativeFilledIn.push({
				category,
				value: earlyCumulative,
			});
			lateDataCumulativeFilledIn.push({
				category,
				value: lateCumulative,
			});
			averageDataCumulativeFilledIn.push({
				category,
				value: earlyCumulative === null || lateCumulative === null ? null : (earlyCumulative + lateCumulative) / 2,
			});
		});
		const monthBeforeDate: Date = subMonths(dataDate, 1);
		const monthAfterDate: Date = addMonths(dataDate, 1);
		const prevMonth: string = this.projectService.prettifyDateString(monthBeforeDate.toUTCString());
		const thisMonth: string = this.projectService.prettifyDateString(dataDate.toUTCString());
		const nextMonth: string = this.projectService.prettifyDateString(monthAfterDate.toUTCString());
		const prevPlannedBudgeted: number = historicPlannedValues.find((planned) => planned.category === prevMonth)?.value;
		this.$prevPlannedBudgeted.next(prevPlannedBudgeted);
		const prevActual: number = actualData.find((d) => d.category === prevMonth)?.value;
		this.$prevActual.next(prevActual);
		const prevActualPercentLabel: string =
			prevActual && prevPlannedBudgeted
				? Math.round((100 * prevActual) / prevPlannedBudgeted).toLocaleString() + '% Completed'
				: '';
		this.$prevActualPercentLabel.next(prevActualPercentLabel);
		const delta: number = prevPlannedBudgeted !== null && prevActual !== null ? prevPlannedBudgeted - prevActual : null;
		this.$prevDelta.next(delta);
		const prevDeltaPercentLabel: string =
			delta && prevPlannedBudgeted
				? Math.round((100 * delta) / prevPlannedBudgeted).toLocaleString() + '% of Plan'
				: '';
		this.$prevDeltaPercentLabel.next(
			delta >= 0
				? prevDeltaPercentLabel
				: delta < 0
					? Math.round((-100 * delta) / prevPlannedBudgeted).toLocaleString() + '% Over Plan'
					: ''
		);
		this.$totalBudgetedCost.next(totalBudgetedCost);
		this.$totalActual.next(cumulativeActVal);
		const totalActualPercentLabel: string =
			cumulativeActVal && totalBudgetedCost
				? Math.round((100 * cumulativeActVal) / totalBudgetedCost).toLocaleString() + '% Complete'
				: '';
		this.$totalActualPercentLabel.next(totalActualPercentLabel);
		const totalRemaining: number = totalBudgetedCost - cumulativeActVal;
		const totalRemainingPercentLabel: string =
			totalRemaining && totalBudgetedCost
				? Math.round((100 * totalRemaining) / totalBudgetedCost).toLocaleString() + '% Remaining'
				: '';
		this.$totalRemainingPercentLabel.next(
			totalRemaining >= 0
				? totalRemainingPercentLabel
				: totalRemaining < 0
					? Math.round((-100 * totalRemaining) / totalBudgetedCost).toLocaleString() + '% Over Plan'
					: ''
		);
		const nextMonthPlanned: number = historicPlannedValues.find((planned) => planned.category === nextMonth)?.value;
		this.$nextMonthPlanned.next(nextMonthPlanned);
		let lastForecastValEarly: number =
			earlyDataCumulativeFilledIn.find((v: SeriesData) => v.category === lastActCumulativeCategory)?.value || 0;
		let lastForecastValLate: number =
			lateDataCumulativeFilledIn.find((v: SeriesData) => v.category === lastActCumulativeCategory)?.value || 0;
		categories.forEach((category: string) => {
			const thisDate: Date = this.projectService.dateStringToDate(category);
			const earlyRemainingCost: SeriesData = earlyDataFilledIn.find((v: SeriesData) => v.category === category);
			const lateRemainingCost: SeriesData = lateDataFilledIn.find((v: SeriesData) => v.category === category);
			const earlyVal: number =
				category === lastActCumulativeCategory
					? cumulativeActVal + lastForecastValEarly
					: isAfter(thisDate, lastActDate)
						? lastForecastValEarly + (earlyRemainingCost === undefined ? 0 : earlyRemainingCost.value)
						: null;
			const lateVal: number =
				category === lastActCumulativeCategory
					? cumulativeActVal + lastForecastValLate
					: isAfter(thisDate, lastActDate)
						? lastForecastValLate + (lateRemainingCost === undefined ? 0 : lateRemainingCost.value)
						: null;
			const avgVal: number = earlyVal === null || lateVal === null ? null : (earlyVal + lateVal) / 2;
			forecastEarly.push({
				category,
				value: earlyVal,
			});
			forecastAvg.push({
				category,
				value: avgVal,
			});
			forecastLate.push({
				category,
				value: lateVal,
			});
			if (category === lastActCumulativeCategory || isAfter(thisDate, lastActDate)) {
				lastForecastValEarly = earlyVal;
				lastForecastValLate = lateVal;
			}
		});
		const currentBudgetedCost: number = forecastEarly[forecastEarly.length - 1].value;
		this.$currentBudgetedCost.next(currentBudgetedCost);
		this.$baselineRemaining.next(totalBudgetedCost - cumulativeActVal);
		const currentRemaining: number = currentBudgetedCost - cumulativeActVal;
		this.$currentRemaining.next(currentRemaining);
		return {
			earlyPeriodic: earlyDataFilledIn,
			avgPeriodic: averageDataFilledIn,
			latePeriodic: lateDataFilledIn,
			actPeriodic: actualData,
			earlyCumulative: earlyDataCumulativeFilledIn,
			avgCumulative: averageDataCumulativeFilledIn,
			lateCumulative: lateDataCumulativeFilledIn,
			actCumulative: actualCumulativeData,
			plannedPeriodic: historicPlannedValues,
			plannedCumulative: historicPlannedValuesCumulative,
			plannedPeriodicLate: historicPlannedValuesLate,
			plannedCumulativeLate: historicPlannedValuesLateCumulative,
			forecastEarly,
			forecastAvg,
			forecastLate,
			remainingCost: totalRemaining,
			nextPeriodPlanned: nextMonthPlanned,
			budgetedCost: totalBudgetedCost,
			categories,
			currentBudget: currentBudgetedCost,
			currentRemaining,
		};
	}

	generateHistoricPerformanceData(costs: CashFlow[], costBaselineIndex: number = -1): HistoricPerformanceChartData {
		const blIndex: number = costBaselineIndex === -1 ? this.determineBaselineIndex(costs) : costBaselineIndex;
		const latestIndex: number = this.determineLatestIndex(costs);
		if (blIndex === -1 || latestIndex === -1) {
			return;
		}
		const baselineCosts: CashFlow = costs[blIndex];
		const latestCosts: CashFlow = costs[latestIndex];
		const plannedData: SeriesData[] = [];
		const actualData: SeriesData[] = [];
		const plannedDataCumulative: SeriesData[] = [];
		const actualDataCumulative: SeriesData[] = [];
		let categories = [];
		let lastActDate = '0';
		const baselineCase = latestCosts?.historicActualCost === undefined;
		if (baselineCosts?.remainingTotalCost === undefined) {
			return;
		}
		Object.entries(latestCosts?.historicActualCost || {}).forEach(([key, data]) => {
			const correctDateString: string = this.projectService.prettifyDateString(this.projectService.msToDateString(key));
			actualData.push({
				category: correctDateString,
				value: data,
			});
			if (categories.findIndex((c) => c === correctDateString) === -1) {
				categories.push(correctDateString);
			}
			if (Number(key) > Number(lastActDate)) {
				lastActDate = key;
			}
		});
		const lastActDateAsDate: Date = this.projectService.dateStringToDate(
			this.projectService.prettifyDateString(this.projectService.msToDateString(lastActDate))
		);
		Object.entries(baselineCosts.historicPlannedTargetCost || {}).forEach(([key, data]) => {
			const correctDateString: string = this.projectService.prettifyDateString(this.projectService.msToDateString(key));
			const currentDateAsDate: Date = this.projectService.dateStringToDate(correctDateString);
			if (baselineCase || !isAfter(currentDateAsDate, lastActDateAsDate)) {
				plannedData.push({
					category: correctDateString,
					value: data,
				});
				if (categories.findIndex((c) => c === correctDateString) === -1) {
					categories.push(correctDateString);
				}
			}
		});
		let historicPlannedValuesLate: SeriesData[] = [];
		const historicPlannedValuesLateCumulative: SeriesData[] = [];
		Object.entries(baselineCosts.historicPlannedLateTargetCost || {}).forEach(([key, data]) => {
			const correctDateString: string = this.projectService.prettifyDateString(this.projectService.msToDateString(key));
			const currentDateAsDate: Date = this.projectService.dateStringToDate(correctDateString);
			if (baselineCase || !isAfter(currentDateAsDate, lastActDateAsDate)) {
				historicPlannedValuesLate.push({
					category: correctDateString,
					value: data,
				});
				if (categories.findIndex((c) => c === correctDateString) === -1) {
					categories.push(correctDateString);
				}
			}
		});

		categories = categories.sort((a, b) => {
			const dateA: Date = this.projectService.dateStringToDate(a);
			const dateB: Date = this.projectService.dateStringToDate(b);
			return isBefore(dateA, dateB) ? -1 : isBefore(dateB, dateA) ? 1 : 0;
		});
		const plannedDataFilledIn: SeriesData[] = [];
		const actualDataFilledIn: SeriesData[] = [];
		let plannedCumulative: number = 0;
		let actualCumulative: number = 0;
		let cumulativeHistoricLate: number = 0;
		for (let i = 0; i < categories.length; i++) {
			const thisActDateAsDate: Date = this.projectService.dateStringToDate(categories[i]);
			const indexPlanned: number = plannedData.findIndex((d) => d.category === categories[i]);
			const indexActual: number = actualData.findIndex((d) => d.category === categories[i]);
			const historicPlannedLateAtPeriod: SeriesData = historicPlannedValuesLate.find(
				(v: SeriesData) => v.category === categories[i]
			);
			const historicPlannedLateVal: number =
				historicPlannedLateAtPeriod === undefined ? null : historicPlannedLateAtPeriod.value;
			if (historicPlannedLateVal !== null) {
				cumulativeHistoricLate += historicPlannedLateVal;
			} else {
				historicPlannedValuesLate.push({
					category: categories[i],
					value: null,
				});
			}
			historicPlannedValuesLateCumulative.push({
				category: categories[i],
				value: cumulativeHistoricLate,
			});
			if (indexPlanned === -1) {
				plannedDataFilledIn.push({
					category: categories[i],
					value: null,
				});
			} else {
				plannedCumulative += plannedData[indexPlanned].value;
				plannedDataFilledIn.push(plannedData[indexPlanned]);
			}
			plannedDataCumulative.push({
				category: categories[i],
				value: plannedCumulative > 0 ? plannedCumulative : null,
			});
			if (indexActual === -1) {
				actualDataFilledIn.push({
					category: categories[i],
					value: isBefore(thisActDateAsDate, lastActDateAsDate) ? 0 : null,
				});
			} else {
				actualCumulative += actualData[indexActual].value;
				actualDataFilledIn.push(actualData[indexActual]);
			}
			actualDataCumulative.push({
				category: categories[i],
				value:
					isBefore(thisActDateAsDate, lastActDateAsDate) || isSameDay(thisActDateAsDate, lastActDateAsDate)
						? actualCumulative
						: null,
			});
		}

		historicPlannedValuesLate = historicPlannedValuesLate.sort((a, b) => {
			const dateA: Date = this.projectService.dateStringToDate(a.category);
			const dateB: Date = this.projectService.dateStringToDate(b.category);
			return isBefore(dateA, dateB) ? -1 : isBefore(dateB, dateA) ? 1 : 0;
		});

		if (this.projectService.$currentProjectReport.value?.projectOverview?.dataDate) {
			const dateString = this.projectService.$currentProjectReport.value?.projectOverview?.dataDate.toString();
			const dateSplit = dateString.split(' ');
			const dataDate: Date = new Date(
				Number(dateSplit[3]),
				this.months.findIndex((m) => m === dateSplit[1])
			);
			const monthBeforeDate: Date = subMonths(dataDate, 1);
			const monthAfterDate: Date = addMonths(dataDate, 1);
			const prevMonth: string = this.projectService.prettifyDateString(monthBeforeDate.toUTCString());
			const thisMonth: string = this.projectService.prettifyDateString(dataDate.toUTCString());
			const nextMonth: string = this.projectService.prettifyDateString(monthAfterDate.toUTCString());
			let plannedVal: number = null;
			plannedDataFilledIn.forEach((data: SeriesData) => {
				if (data.category === thisMonth) {
					this.$thisMonth.next(data.value);
				}
				if (data.category === nextMonth) {
					this.$nextMonth.next(data.value);
				}
			});
			actualDataFilledIn.forEach((data: SeriesData) => {
				if (data.category === prevMonth) {
					plannedVal = data.value;
					this.$previousMonth.next(data.value);
				}
			});
			const actDataPoint: SeriesData = actualDataFilledIn.find((data) => data.category === prevMonth);
			this.$lastMonthVariance.next(
				actDataPoint === undefined && plannedVal === null
					? null
					: actDataPoint === undefined || plannedVal === null
						? 0
						: actDataPoint.value - plannedVal
			);
		}
		return {
			plannedPeriodic: plannedDataFilledIn,
			actualPeriodic: actualDataFilledIn,
			plannedCumulative: plannedDataCumulative,
			actualCumulative: actualDataCumulative,
			historicPlannedLatePeriodic: historicPlannedValuesLate,
			historicPlannedLateCumulative: historicPlannedValuesLateCumulative,
			categories,
		};
	}

	generateSPIData(costs: CashFlow[], costBaselineIndex: number = -1): SPIChartData {
		const scheduleData: SeriesData[] = [];
		const scheduleDataCumulative: SeriesData[] = [];
		let plannedScheduleData: SeriesData[] = [];
		let actualScheduleData: SeriesData[] = [];
		let categories: string[] = [];
		if (costs.length === 0) {
			return;
		}
		const blIndex: number = costBaselineIndex === -1 ? this.determineBaselineIndex(costs) : costBaselineIndex;
		const latestIndex: number = this.determineLatestIndex(costs);
		if (blIndex === -1 || latestIndex === -1) {
			return;
		}
		const baselineCosts: CashFlow = costs[blIndex];
		const latestCosts: CashFlow = costs[latestIndex];
		let latestActDate = 0;
		if (latestCosts?.historicActualCost === undefined || baselineCosts?.remainingTotalCost === undefined) {
			return;
		}
		Object.entries(latestCosts.historicActualCost || {}).forEach(([key, data]) => {
			const correctDateString: string = this.projectService.prettifyDateString(this.projectService.msToDateString(key));
			actualScheduleData.push({
				category: correctDateString,
				value: data,
			});
			if (categories.findIndex((c) => c === correctDateString) === -1) {
				categories.push(correctDateString);
			}
			if (Number(key) > latestActDate) {
				latestActDate = Number(key);
			}
		});
		actualScheduleData = actualScheduleData.sort((a, b) => {
			const dateA: Date = this.projectService.dateStringToDate(a.category);
			const dateB: Date = this.projectService.dateStringToDate(b.category);
			return isBefore(dateA, dateB) ? -1 : isBefore(dateB, dateA) ? 1 : 0;
		});
		Object.entries(baselineCosts.historicPlannedTargetCost || {}).forEach(([key, data]) => {
			const correctDateString: string = this.projectService.prettifyDateString(this.projectService.msToDateString(key));
			if (
				actualScheduleData.findIndex((act) => act.category === correctDateString) !== -1 ||
				Number(key) < latestActDate
			) {
				plannedScheduleData.push({
					category: correctDateString,
					value: data,
				});
				if (categories.findIndex((c) => c === correctDateString) === -1) {
					categories.push(correctDateString);
				}
			}
		});
		categories = categories.sort((a, b) => {
			const dateA: Date = this.projectService.dateStringToDate(a);
			const dateB: Date = this.projectService.dateStringToDate(b);
			return isBefore(dateA, dateB) ? -1 : isBefore(dateB, dateA) ? 1 : 0;
		});
		plannedScheduleData = plannedScheduleData.sort((a, b) => {
			const dateA: Date = this.projectService.dateStringToDate(a.category);
			const dateB: Date = this.projectService.dateStringToDate(b.category);
			return isBefore(dateA, dateB) ? -1 : isBefore(dateB, dateA) ? 1 : 0;
		});
		let cumulativePlanned: number = 0;
		let cumulativeActual: number = 0;
		categories.forEach((val) => {
			const plannedDataIndex: number = plannedScheduleData.findIndex((d) => d.category === val);
			const actualDataIndex: number = actualScheduleData.findIndex((d) => d.category === val);
			if (plannedDataIndex !== -1) {
				cumulativePlanned += plannedScheduleData[plannedDataIndex].value;
			}
			if (actualDataIndex !== -1) {
				cumulativeActual += actualScheduleData[actualDataIndex].value;
			}
			scheduleData.push({
				category: val,
				value:
					plannedDataIndex !== -1
						? actualDataIndex === -1
							? 0
							: actualScheduleData[actualDataIndex].value / (plannedScheduleData[plannedDataIndex].value || 1)
						: null,
			});
			scheduleDataCumulative.push({
				category: val,
				value: cumulativeActual === 0 ? 0 : cumulativePlanned === 0 ? 1 : cumulativeActual / (cumulativePlanned || 1),
			});
		});
		const scheduleDataFilledIn: SeriesData[] = [];
		for (let i = 0; i < categories.length; i++) {
			const indexSchedule: number = scheduleDataCumulative.findIndex((d) => d.category === categories[i]);
			if (indexSchedule === -1) {
				scheduleDataFilledIn.push({
					category: categories[i],
					value: null,
				});
			} else {
				scheduleDataFilledIn.push(scheduleDataCumulative[indexSchedule]);
			}
		}
		this.spiPeriodic = scheduleData;
		this.spiCumulative = scheduleDataCumulative;
		this.$latestSPI.next(scheduleDataCumulative[scheduleDataCumulative.length - 1].value);
		return { spiPeriodic: scheduleData, spiCumulative: scheduleDataCumulative, categories };
	}

	determineBaselineIndex(costs: CashFlow[]): number {
		if (this.projectService.$currentProjectData.value?.costBaseline !== undefined) {
			return this.projectService.$currentProjectData.value.updateIds.findIndex(
				(id: string) => id === this.projectService.$currentProjectData.value?.costBaseline
			);
		}
		let blIndex: number = -1;
		if (costs?.length) {
			for (let i = 0; i < costs?.length; i++) {
				if (costs[i] === null) {
				} else {
					if (costs[i].remainingTotalCost) {
						blIndex = i;
						break;
					}
				}
			}
		}
		return blIndex;
	}

	determineLatestIndex(costs: CashFlow[]): number {
		let index: number = -1;
		if (costs?.length) {
			for (let i = costs.length - 1; i >= 0; i--) {
				if (costs[i] === null) {
				} else {
					if (
						costs[i].cumulativeRemainingTotalCost &&
						costs[i].cumulativeRemainingLateTotalCost &&
						costs[i].remainingTotalCost &&
						costs[i].remainingLateTotalCost &&
						costs[i].averageCashFlowCurve
					) {
						index = i;
						break;
					}
				}
			}
		}
		return index;
	}

	isValidCostProject(costs: CashFlow[]): boolean {
		const blIndex = this.determineBaselineIndex(costs);
		const latestIndex = this.determineLatestIndex(costs);
		return blIndex !== -1 && latestIndex !== -1;
	}
}
