import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ArcGaugeComponent } from '@progress/kendo-angular-gauges';

@Component({
	selector: 'app-gauge',
	templateUrl: './gauge.component.html',
	styleUrls: ['./gauge.component.scss'],
})
export class GaugeComponent implements OnInit, OnDestroy {
	@Input() value: BehaviorSubject<number>;
	@Input() colors;
	@Input() label: string;
	@Input() height: string = '172px';
	@Input() valueFontSize: string = '24px';
	@Input() labelFontSize: string = '14px';
	@Input() idToWatchFor: string = '';
	@ViewChild('gauge') gauge: ArcGaugeComponent;
	_value = 0;
	private _unsubscribeAll: Subject<void> = new Subject<void>();

	constructor() {}

	ngOnInit() {
		this.value.pipe(takeUntil(this._unsubscribeAll), debounceTime(100)).subscribe((val) => {
			this._value = val;
			setTimeout(() => {
				if (this.gauge) {
					this.gauge.resize();
				}
				if (this.idToWatchFor !== '') {
					let i: number = 0;
					const waitingForElementLoad = setInterval(() => {
						const el: HTMLElement = document.getElementById(this.idToWatchFor) as HTMLElement;
						if ((el || i > 500) && this.gauge) {
							this.gauge.resize();
							clearInterval(waitingForElementLoad);
						}
						i++;
					}, 200);
				}
			});
		});
	}

	ngOnDestroy() {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
