@if (blur) {
	<div class="blur-div">
		@if (!this._scheduleService.isLoading) {
			<div class="blur-text-div">
				You do not have permission to view this project. Please reach out to your Company administrator for more information
			</div>
		} @else {
			<span class="k-icon k-i-loading blur-spin"></span>
		}
	</div>
}
<div id="dashboard-analytics-project" class="page-layout blank no-select new-background" [ngClass]="{'fixed-position-underlay-project': dialogOpened, 'blur-page': blur && !this._scheduleService.isLoading}">
  <!-- top rows -->
	@if ((navBarStorage.$tabPointer | async) !== 'gantt') {
		@if (user?.userType !== "saasRisk") {
			<app-top-row
				id='topRow'
				[riskMetricsOptions]='riskMetricsOptions'
				(dialogStateChange)='dialogStateChanged($event)'
				class="no-whitespace"
			></app-top-row>
		} @else {
			<app-saas-risk-banner
				id='saasTopRow'
				[$riskRegisterProjects]="$riskRegisterProjects"
				[isFromProjectPage]='true'
				(dialogStateChange)='dialogStateChanged($event)'
				class="no-whitespace"
			></app-saas-risk-banner>
		}
	}

  <!-- no baseline -->
	@if (($projectData | async)?.updateLock || ($projectData | async)?.updateLockNotes || ($projectData | async) && !($projectData | async)?.updateIds?.length && !($projectData | async)?.updateLock && user?.userType !== 'saasRisk') {
		<ion-grid>
			@if (($projectData | async) && !($projectData | async)?.updateIds?.length && !($projectData | async)?.updateLock && user?.userType !== "saasRisk") {
				<ion-row
					style="margin-top: 20vh;"
				>
					<ion-col size='auto' style='margin-left:auto;margin-right: auto'>
						<ion-card>
							<ion-card-content style='margin-left:auto; margin-right: auto; padding: 30px !important; width: calc(100% - 60px); height: calc(100% - 60px);'>
								<h2 style="text-align: center;margin: 20px;" *ngIf="_projectDashboardService.fullyLoaded">
									Project Has No Updates!
								</h2>
								<app-add-baseline-button *ngIf="_projectDashboardService.fullyLoaded"></app-add-baseline-button>
								<h2 style="text-align: center;margin: 20px"  *ngIf="!_projectDashboardService.fullyLoaded">Loading Project, this may take a moment...</h2>
								<ion-progress-bar type="indeterminate" *ngIf="!_projectDashboardService.fullyLoaded"></ion-progress-bar>
							</ion-card-content>
						</ion-card>
					</ion-col>
				</ion-row>
			}
			@if (($projectData | async)?.updateLock || ($projectData | async)?.updateLockNotes) {
				<!-- queue stuff -->
				<ion-row style="margin-top: 20vh;">
					<ion-col size='auto' style='margin-left:auto;margin-right: auto'>
						<ion-card>
							<ion-card-content style='margin-left:auto;margin-right: auto;width:100%;padding: 0px !important'>
								<div style='padding: 30px;'>
									<h1 id='processingUpdateText' style="text-align: center;margin: 20px">Processing Update...</h1>
									<br/>
									<ion-progress-bar type="indeterminate"></ion-progress-bar>
									<br/>
									<br/>
									<div style='display:flex;justify-content:center;align-items:center;'>
										<button
											mat-icon-button
											(click)="reloadWindow()"
											style='background: transparent;border: 0px;font-size: 20px;'
										>
											<mat-icon class="secondary-text">refresh</mat-icon>
										</button>
									</div>
								</div>
							</ion-card-content>
						</ion-card>
					</ion-col>
				</ion-row>
			}
		</ion-grid>
	}

	<!-- tab pages -->
	@if(($projectData | async) &&
	!($projectData | async)?.updateLock
	) {
			<app-project-overview
				*ngIf="($projectData | async)?.updateIds?.length &&	(navBarStorage.$tabPointer | async) === 'overview' && user?.userType !== 'saasRisk' && !($projectData | async)?.hiddenTabs.includes('overview')"
				[currentProjectCompanyPermissions]='currentProjectCompanyPermissions'
				[$projectData]='$projectData'
				(dialogStateChange)='dialogStateChanged($event)'
				class="no-whitespace">
			</app-project-overview>
			<app-progress-page
				*ngIf="($projectData | async)?.updateIds?.length &&	(navBarStorage.$tabPointer | async) === 'progress' &&	user?.userType !== 'saasRisk' && !($projectData | async)?.hiddenTabs.includes('progress')"
				class="no-whitespace">
			</app-progress-page>
			<app-qc-page
				*ngIf="($projectData | async)?.updateIds?.length && (navBarStorage.$tabPointer | async) === 'qc' &&	user?.userType !== 'saasRisk' && !($projectData | async)?.hiddenTabs.includes('qc')"
				class="no-whitespace">
			</app-qc-page>
			<app-cost-page
				*ngIf="(navBarStorage.$tabPointer | async) === 'cost' &&
				 	!['ANALYTICS-BASIC-MPK', 'ANALYTICS-BASIC-APK'].includes(currentProjectCompanyPermissions?.license) && !($projectData | async)?.hiddenTabs.includes('cost')"
				[currentProjectCompanyPermissions]='currentProjectCompanyPermissions'
				class="no-whitespace">
			</app-cost-page>
			<app-risk-page
				*ngIf="(navBarStorage.$tabPointer | async) === 'risk' &&
				 	(($projectData | async)?.updateIds?.length || user?.userType === 'saasRisk') &&
				 	!['ANALYTICS-BASIC-MPK', 'ANALYTICS-BASIC-APK'].includes(currentProjectCompanyPermissions?.license) && !($projectData | async)?.hiddenTabs.includes('risk')"
				[$projectData]='$projectData'
				[user]="user"
				[$currentTab]="navBarStorage.$tabPointer"
				[currentProjectCompanyPermissions]='currentProjectCompanyPermissions'
				(emitTab)="navBarStorage.$tabPointer"
				(dialogStateChange)='dialogStateChanged($event)'
				class="no-whitespace">
			</app-risk-page>
			<app-analysis-page
				*ngIf="($projectData | async)?.updateIds?.length &&
					(navBarStorage.$tabPointer | async) === 'analysis' &&
					user?.userType !== 'saasRisk' && !($projectData | async)?.hiddenTabs.includes('analysis')"
				class="no-whitespace"
				[$projectData]='$projectData'
				[$updates]='$allUpdates'>
			</app-analysis-page>
		@if ((navBarStorage.$tabPointer | async) === 'schedules' &&
		($projectData | async)?.updateLockNotes === undefined &&
		(($projectData | async)?.updateIds?.length || user?.userType === 'saasRisk')) {
			<app-schedule-updates-list [$projectData]='$projectData' [$updates]='$allUpdates'>
			</app-schedule-updates-list>
				<app-schedule-lookahead *ngIf='user?.userType === "saasRisk" && ($projectData | async).updateIds?.length > 0'></app-schedule-lookahead>
				<app-schedule-analysis
					*ngIf='user?.userType === "saasRisk" && ($projectData | async).updateIds?.length > 0'
					[visualizer]='false'>
				</app-schedule-analysis>
				<div class='saas-risk-upload-btn' *ngIf='user?.userType === "saasRisk" && ($projectData | async).updateIds?.length > 0'>
					<button kendoButton size='large' themeColor='warning' class='shadow' (click)="openMarketingPage()" icon='lock' title='Unlock Additional Metrics/Schedules'>Unlock Additional Metrics/Schedules</button>
				</div>
		}
		<app-current-update-gantt *ngIf="($projectData | async)?.updateIds?.length &&
				(navBarStorage.$tabPointer | async) === 'gantt' &&
				user?.userType !== 'saasRisk' && !($projectData | async)?.hiddenTabs.includes('reports')">
		</app-current-update-gantt>
		<app-compare *ngIf="($projectData | async)?.updateIds?.length &&
				(navBarStorage.$tabPointer | async) === 'compare' &&
				user?.userType !== 'saasRisk' && !($projectData | async)?.hiddenTabs.includes('compare')"></app-compare>
		<app-focus *ngIf="($projectData | async)?.updateIds?.length &&
				(navBarStorage.$tabPointer | async) === 'focus' &&
				user?.userType !== 'saasRisk' && !($projectData | async)?.hiddenTabs.includes('focus')"></app-focus>
	}
</div>
