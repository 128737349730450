<div class='card' style='page-break-inside: avoid;' id="qcScoreComponent">
	<kendo-gridlayout
		[rows]="['42.4px', '303px']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='grid'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' [class.overviewHeader]="isOverview">
			<kendo-gridlayout
				[rows]="['42.4px']"
				[cols]="['300px', 'calc(100% - 315px)']"
				[gap]="{ rows: 0, cols: 5 }"
				class='full-width'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
					<div class="card-header">
						Quality Control Score
					</div>
					<tooltip-open type='{{isOverview ? "overview" : "qc-score-and-trend"}}' [fillColor]="isOverview ? 'overview' : ''"></tooltip-open>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2' class='toolbar-item-container' style="margin: auto; height: min-content; justify-content: end">
					<span style='display: inline-flex; align-items: center;float: right; gap: 10px;' kendoTooltip>
						@if (isOverview) {
							<button kendoButton themeColor="base" title="Go to source" [imageUrl]="'assets/icons/newIcons/noun-arrow-5544651.svg'" class='header-btn' (click)='projectService.goTo(9)' style="background-color: #ffb500;"></button>
						}
						<button kendoButton themeColor="base" title="Add/Edit Notes" [imageUrl]="'assets/icons/newIcons/noun-notepad-6607257.svg'" class='header-btn overview-notes-icon' (click)='projectService.openNotesWindow({id: 9, name: "Quality Control Score"})' [style.background-color]="isOverview ? '#ffb500' : 'white'"></button>
					</span>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1'>
			<kendo-gridlayout
				[rows]="['303px']"
				[cols]="['calc((400% / 12) - 2.5px)', 'calc((800% / 12) - 2.5px)']"
				[gap]="{ rows: 0, cols: 5 }"
				class='grid'
				style='page-break-inside: avoid;'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-container'>
					<app-gauge
						[value]='$qcScore'
						[colors]='qcColors'
						[height]='"222px"'
						[valueFontSize]='"32px"'
						[labelFontSize]='"20px"'
						style='page-break-inside: avoid;'
					></app-gauge>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2'>
					<div class='chart-container'>
						<app-chart
							[title]='"Current QC Score Trend"'
							[categories]='categories'
							[seriesData]='seriesData'
							[legendVisible]='true'
							[valueAxis]='valueAxisItemSettings'
						></app-chart>
					</div>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
	@if (isOverview && hasNotes) {
		<app-overview-notes [componentId]="9"></app-overview-notes>
	}
</div>
