import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AuthenticationService } from '../../../services/auth/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'services/common/user.service';
import { NotificationService } from 'services/common/notification.service';
import { environment } from '../../../environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
	selector: 'login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
	loginForm: UntypedFormGroup;
	loader: any;
	hide = true;
	httpProcessing = false;
	autofilled = false;
	oauthLogin: boolean = false;

	constructor(
		private _formBuilder: UntypedFormBuilder,
		private authService: AuthenticationService,
		private router: Router,
		private userService: UserService,
		private notificationService: NotificationService,
		public oauthService: OAuthService,
		public activatedRoute: ActivatedRoute
	) {
		// console.log('snapshot', this.activatedRoute.snapshot.queryParams['fromAccount']);
		// console.log('previousUrl', document.referrer);

		if (
			document.referrer === 'https://account.rhino.works/onboarding/analytics/' ||
			this.activatedRoute.snapshot.queryParams.fromAccount === 'true'
		) {
			this.oauthLogin = true;

			this.oauthService.loadDiscoveryDocumentAndTryLogin();
		}

		this.activatedRoute.queryParams.subscribe((params) => {
			// console.log('queryParams', params['fromAccount']);
			if (params.fromAccount === 'true') {
				this.oauthLogin = true;
				this.oauthService.loadDiscoveryDocumentAndTryLogin();
			}
		});
	}

	public async initiateOauthLogin() {
		//this.oauthService.initImplicitFlow();
		/**
     this.authService.
     await this.authService.login(
     this.loginForm.value.email,
     this.loginForm.value.password,
     undefined
     );
     **/
		await this.oauthService.loadDiscoveryDocumentAndTryLogin();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */

	environment: string;
	bleeding: boolean;

	ngOnInit(): void {
		this.loginForm = this._formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required],
		});

		if (this.authService.oauthConnected) {
			// console.log('Login contacted.');
		}

		if (!environment.staging) {
			this.environment = 'production';
		} else {
			this.environment = 'staging';
		}
		this.bleeding = environment.bleeding;
	}

	autofillOff() {
		this.autofilled = false;
	}

	checkIfBlank() {
		if (this.autofilled) {
			return false;
		}
		return this.httpProcessing || this.loginForm.invalid;
	}

	toggleHide() {
		this.hide = !this.hide;
	}
	async signMeIn() {
		this.hide = true;
		if (!this.loginForm.value.email || !this.loginForm.value.password) {
			//this.doAlert('Error', 'All fields are required');
			return;
		}
		try {
			// this.loader = await this.loadingCtrl.create({
			//   message: 'Please wait...',
			//   backdropDismiss: true,
			// });
			//await this.loader.present();

			this.httpProcessing = true;
			const loginRes = await this.authService.login(
				this.loginForm.value.email,
				this.loginForm.value.password,
				undefined
			);

			// console.log('looking at the login res: ', loginRes);

			if (loginRes === 'newpasswordrequired') {
				// console.log('new password required!');
				this.router.navigate(['auth/create-password']);
				this.userService.saveUserEmail(this.loginForm.value.email);
				this.loader.dismiss();
				return;
			}

			if (loginRes === 'success') {
				// console.log('success!');
				//Need to uncomment
				// this.userLoginService.getUserByEmail(this.loader);
				//TEMP
				// console.log('Success.');
			}
		} catch (error) {
			const message = error.message || 'Incorrect username or password.';
			this.notificationService.showNotification(message);
			//this.doAlert('Error', (error && error.message) || 'Incorrect username or password.');
			//this.loader.dismiss();
		} finally {
			this.httpProcessing = false;
		}
	}

	beginAccountLogin() {
		window.location.href = 'https://account.rhino.works/login/?next=/onboarding/analytics/';
	}
}
