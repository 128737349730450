import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddProjectModule } from './add-project/add-project.module';
import { GlobalTooltipMenuModule } from './global-toolip-menu/global-tooltip-menu.module';
import { MapModule } from './map/map.module';
import { ProjectListModule } from './project-list/project-list.module';
import { ProjectCompletionVarianceModule } from './project-completion-variance/project-completion-variance.module';
import { ProjectNotesTableModule } from './project-notes-table/project-notes-table.module';
import { TooltipMenuModule } from './tooltip-menu/tooltip-menu.module';
import { PortfolioRoutingModule } from './portfolio-routing.module';
import { AnalyticsDashboardService } from 'services/analytics/analytics.service';
import { PortfolioComponent } from './portfolio.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedMatModule } from 'components/shared/shared-mat.module';
import { RiskBannerComponent } from './risk-banner/risk-banner.component';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { RiskWidgetsModule } from '../shared/risk-widgets/risk-widgets.module';
import { NewProjectModule } from './new-project/new-project.module';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { IconsModule } from '@progress/kendo-angular-icons';
import { GridModule } from '@progress/kendo-angular-grid';
import { PipeModule } from '../../util/pipes/pipe.module';
import { DialogModule, WindowModule } from '@progress/kendo-angular-dialog';
import { ButtonGroupModule, ButtonModule } from '@progress/kendo-angular-buttons';
import { SaasRiskBannerModule } from '../shared/saas-risk-banner/saas-risk-banner.module';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import {
	CheckBoxModule,
	FormFieldModule,
	RadioButtonModule,
	RangeSliderModule,
	TextBoxModule,
} from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { DirectivesModule } from '../../util/directives/directives.module';
import { NgxGanttModule } from '@worktile/gantt';
import { GanttModule } from '@progress/kendo-angular-gantt';
import { DropDownListModule, DropDownTreesModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { CostBannerComponent } from './cost-banner/cost-banner.component';
import { CostPageModule } from '../project-page/cost/cost-page.module';
import { PresetWindowContentComponent } from './preset-window-content/preset-window-content.component';
import { TreeViewModule } from '@progress/kendo-angular-treeview';

@NgModule({
	providers: [AnalyticsDashboardService],
	declarations: [PortfolioComponent, RiskBannerComponent, CostBannerComponent, PresetWindowContentComponent],
	imports: [
		CommonModule,
		AddProjectModule,
		GlobalTooltipMenuModule,
		MapModule,
		ProjectCompletionVarianceModule,
		ProjectListModule,
		ProjectNotesTableModule,
		TooltipMenuModule,
		PortfolioRoutingModule,
		IonicModule,
		FormsModule,
		SharedMatModule,
		ChartsModule,
		RiskWidgetsModule,
		NewProjectModule,
		LayoutModule,
		IconsModule,
		GridModule,
		PipeModule,
		DialogModule,
		LayoutModule,
		IconsModule,
		GridModule,
		PipeModule,
		DialogModule,
		ButtonModule,
		SaasRiskBannerModule,
		WindowModule,
		TooltipModule,
		TextBoxModule,
		LabelModule,
		DirectivesModule,
		NgxGanttModule,
		ButtonGroupModule,
		GanttModule,
		DropDownListModule,
		CostPageModule,
		RadioButtonModule,
		CheckBoxModule,
		DropDownTreesModule,
		MultiSelectModule,
		RangeSliderModule,
		FormFieldModule,
		ReactiveFormsModule,
		TreeViewModule,
	],
	exports: [PresetWindowContentComponent],
})
export class PortfolioModule {}
