import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../../services/common/user.service';
import { RestService } from '../../../../services/common/rest.service';
import { AnalyticsDashboardService } from '../../../../services/analytics/analytics.service';

@Component({
	selector: 'app-profile',
	templateUrl: 'profile.component.html',
	styleUrls: ['profile.component.scss'],
})
export class ProfileComponent implements OnInit {
	profileForm = new FormGroup({
		firstName: new FormControl('', Validators.required),
		lastName: new FormControl('', Validators.required),
		email: new FormControl('', Validators.required),
		phoneNumber: new FormControl('', Validators.required),
	});
	firstNameOfUser: string;
	lastNameOfUser: string;
	emailOfUser: string;
	phoneNumberOfUser: string;
	user: any = {
		userType: 'aegis',
	};
	userEdits: any = {
		userType: 'aegis',
	};

	constructor(
		public userService: UserService,
		private restService: RestService,
		private analyticsService: AnalyticsDashboardService
	) {}
	ngOnInit() {
		this.userService.user.subscribe((data) => {
			if (data) {
				this.userEdits = data;
				this.profileForm.patchValue({
					firstName: data.profile.firstName,
					lastName: data.profile.lastName,
					email: data.profile.email,
					phoneNumber: data.profile.phoneNumber,
				});
			}
		});
	}

	saveProfileChanges() {
		if (
			!this.profileForm.value.firstName ||
			!this.profileForm.value.lastName ||
			!this.profileForm.value.email ||
			!this.profileForm.value.phoneNumber
		) {
			return;
		}
		if (this.firstNameOfUser) {
			this.userEdits.profile.firstName = this.firstNameOfUser;
		}
		if (this.lastNameOfUser) {
			this.userEdits.profile.lastName = this.lastNameOfUser;
		}
		if (this.phoneNumberOfUser) {
			this.userEdits.profile.phoneNumber = this.phoneNumberOfUser;
		}
		this.userEdits.email = this.userEdits.profile.email;
		this.userEdits.profile._id = this.userEdits._id;
		this.restService.put(`user`, this.userEdits.profile).subscribe(
			(val) => {},
			(error) => {
				console.log(error);
			}
		);
		this.analyticsService.editProfileOpen.next(false);
	}
	onFirstNameChange(value: string) {
		this.firstNameOfUser = value;
	}
	onLastNameChange(value: string) {
		this.lastNameOfUser = value;
	}
	onNumberChange(value: string) {
		this.phoneNumberOfUser = value;
	}
}
