import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'services/common/user.service';
import { NotificationService } from 'services/common/notification.service';
import { environment } from '../../../environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { AccountUser } from '../../../models/auth/account-user';

@Component({
	selector: 'login',
	templateUrl: './login-holder.component.html',
	styleUrls: ['./login-holder.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class LoginHolderComponent implements OnInit, OnDestroy {
	loginForm: UntypedFormGroup;
	loader: any;
	hide = true;
	httpProcessing = false;
	autofilled = false;
	oauthLogin: boolean = false;

	constructor(
		private _formBuilder: UntypedFormBuilder,
		public authService: AuthenticationService,
		private router: Router,
		private userService: UserService,
		private notificationService: NotificationService,
		public oauthService: OAuthService,
		public activatedRoute: ActivatedRoute
	) {
		this.authService.tryingLogin = true;

		// console.log('snapshot', this.activatedRoute.snapshot.queryParams['fromAccount']);
		// console.log('previousUrl', document.referrer);

		if (
			document.referrer === 'https://account.rhino.works/onboarding/analytics/' ||
			this.activatedRoute.snapshot.queryParams.fromAccount === 'true'
		) {
			this.oauthLogin = true;

			this.oauthService.loadDiscoveryDocumentAndTryLogin();
		}

		this.activatedRoute.queryParams.subscribe((params) => {
			// console.log('queryParams', params['fromAccount']);
			if (params.fromAccount === 'true') {
				this.oauthLogin = true;
				this.oauthService.loadDiscoveryDocumentAndTryLogin();
			}
		});
	}

	checkerTimer: any;

	public async initiateOauthLogin() {
		//this.oauthService.initImplicitFlow();
		/**
     this.authService.
     await this.authService.login(
     this.loginForm.value.email,
     this.loginForm.value.password,
     undefined
     );
     **/
		await this.oauthService.loadDiscoveryDocumentAndTryLogin();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */

	environment: string;
	bleeding: boolean;
	showLogs: boolean = false;

	ngOnDestroy() {
		clearInterval(this.checkerTimer);
	}

	ngOnInit(): void {
		this.loginForm = this._formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required],
		});

		if (this.authService.oauthConnected) {
			// console.log('Login connected');
		}

		if (!environment.staging) {
			this.environment = 'production';
		} else {
			this.environment = 'staging';
		}
		this.bleeding = environment.bleeding;
		const x = document.getElementById('pw');
		//let y = window.getComputedStyle(x, null).getPropertyValue('animation-name');
		//if (y == 'cdk-text-field-autofill-start') {
		//this.autofilled = true;
		//}
		setTimeout(() => {
			const user = JSON.parse(localStorage.getItem('currentUserCredentials')) as AccountUser;
			console.log('user', user);
			if (user === undefined) {
				this.checkerTimer = setInterval(() => {
					let u = JSON.parse(localStorage.getItem('currentUserCredentials')) as AccountUser;
					if (u.access_token !== undefined && u.access_token !== null) {
						window.location.reload();
					}
				}, 2500);
			} else {
				window.location.reload();
			}
		}, 3000);
	}

	autofillOff() {
		this.autofilled = false;
	}

	checkIfBlank() {
		if (this.autofilled) {
			return false;
		}
		return this.httpProcessing || this.loginForm.invalid;
	}

	toggleHide() {
		this.hide = !this.hide;
	}

	beginAccountLogin() {
		window.location.href = 'https://account.rhino.works/login/?next=/onboarding/analytics/';
	}
}
