import { NgModule } from '@angular/core';
import { SaasRiskBannerComponent } from './saas-risk-banner.component';
import { GridLayoutModule } from '@progress/kendo-angular-layout';
import { GlobalTooltipMenuModule } from '../../portfolio/global-toolip-menu/global-tooltip-menu.module';
import { RiskWidgetsModule } from '../risk-widgets/risk-widgets.module';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { DialogModule, WindowModule } from '@progress/kendo-angular-dialog';
import { UploadPictureModule } from '../upload-picture/upload-picture.module';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { SVGIconModule } from '@progress/kendo-angular-icons';

@NgModule({
	declarations: [SaasRiskBannerComponent],
	exports: [SaasRiskBannerComponent],
	imports: [
		GridLayoutModule,
		GlobalTooltipMenuModule,
		RiskWidgetsModule,
		CommonModule,
		MatIconModule,
		DialogModule,
		UploadPictureModule,
		WindowModule,
		ButtonModule,
		SVGIconModule,
	],
})
export class SaasRiskBannerModule {}
