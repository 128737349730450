import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopRowComponent } from './top-row.component';
import { AddBulkUpdateModule } from './add-bulk-update/add-bulk-update.module';
import { AddBaselineModule } from './add-baseline/add-baseline.module';
import { IonicModule } from '@ionic/angular';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TooltipMenuModule } from '../../portfolio/tooltip-menu/tooltip-menu.module';
import { RiskWidgetsModule } from '../../shared/risk-widgets/risk-widgets.module';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridLayoutModule } from '@progress/kendo-angular-layout';
import { GaugeModule } from '../../shared/gauge/gauge.module';
import { UploadPictureModule } from '../../shared/upload-picture/upload-picture.module';
import { RiskSettingsModule } from '../risk/risk-settings/risk-settings.module';
import { IconsModule } from '@progress/kendo-angular-icons';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';

@NgModule({
	declarations: [TopRowComponent],
	exports: [TopRowComponent],
	imports: [
		RouterModule,
		CommonModule,
		AddBaselineModule,
		AddBulkUpdateModule,
		IonicModule,
		MatButtonModule,
		FormsModule,
		MatIconModule,
		TooltipMenuModule,
		RiskWidgetsModule,
		DialogsModule,
		ButtonsModule,
		GridLayoutModule,
		GaugeModule,
		UploadPictureModule,
		RiskSettingsModule,
		IconsModule,
		TooltipsModule,
	],
})
export class TopRowModule {}
