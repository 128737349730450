<div class='card' style='page-break-inside: avoid;' id="milestonesComponent">
	<kendo-gridlayout
		[rows]="['42.4px', 'calc(100% - 42.4px)']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='grid'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' [class.overviewHeader]="isOverview">
			<kendo-gridlayout
				[rows]="['42.4px']"
				[cols]="['300px', 'calc(100% - 315px)']"
				[gap]="{ rows: 0, cols: 5 }"
				class='full-width'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
					<div class="card-header">
						Milestones
					</div>
					<tooltip-open type='{{isOverview ? "overview" : "milestones"}}' [fillColor]="isOverview ? 'overview' : ''"></tooltip-open>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2' class='toolbar-item-container' style="margin: auto; height: min-content; justify-content: end">
					<span style='display: inline-flex; align-items: center;float: right; gap: 10px;' kendoTooltip>
						@if (isOverview) {
							<button kendoButton themeColor="base" title="Go to source" [imageUrl]="'assets/icons/newIcons/noun-arrow-5544651.svg'" class='header-btn' (click)='projectService.goTo(4)' style="background-color: #ffb500;"></button>
						}
						<button kendoButton themeColor="base" title="Add/Edit Notes" [imageUrl]="'assets/icons/newIcons/noun-notepad-6607257.svg'" class='header-btn overview-notes-icon' (click)='projectService.openNotesWindow({id: 4, name: "Milestones"})' [style.background-color]="isOverview ? '#ffb500' : 'white'"></button>
					</span>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1'>
			<div class='chart-container'>
				<app-chart
					style='page-break-inside: avoid;'
					[subtitle]='"Milestone Completion Trending"'
					[categories]='categories'
					[seriesData]='seriesData'
					[legendVisible]='true'
					[valueAxis]='valueAxisItemSettings'
					(legendItemClicked)='legendItemClicked($event)'
				></app-chart>
			</div>
			<kendo-grid
				[data]="gridView"
				[kendoGridBinding]="gridData"
				[loading]='loading'
				[size]='"small"'
				[sortable]='true'
				[sort]="sort"
				(sortChange)="sortChange($event)"
				[navigable]="false"
				[selectable]="{ enabled: true, checkboxOnly: true }"
				kendoGridSelectBy="_id"
				[(selectedKeys)]="selectedKeys"
				(selectionChange)='selectionChanged($event)'
				class='mile-grid rounded-bottom-corners'
				id="milestones-grid"
			>
				<kendo-grid-column
					*ngFor='let column of selectedColumns'
					[field]="column.field"
					[title]="column.title"
					[width]='column.width'
					[format]="column?.format"
					headerClass="header-class"
				>
					<ng-template kendoGridCellTemplate let-dataItem>
						@if (column?.type === 'date') {
							<span style='white-space: nowrap; text-overflow: ellipsis; display: block; text-align: center; padding: 0'>{{dataItem[column.field] === null ? '-' : dataItem[column.field] | date: 'MMM d, yyyy'}}</span>
						} @else if (column?.type === 'number') {
							<span style='white-space: nowrap; text-overflow: ellipsis; display: block; text-align: center; padding: 0'>{{dataItem[column.field] === null ? '-' : dataItem[column.field]}}</span>
						}@else {
							<span style='white-space: nowrap; text-overflow: ellipsis;'>{{dataItem[column.field] === null ? '-' : dataItem[column.field]}}</span>
						}
					</ng-template>
				</kendo-grid-column>
				<kendo-grid-checkbox-column
					title='Chart'
					[width]='45'
				></kendo-grid-checkbox-column>
			</kendo-grid>
			@if (isOverview && hasNotes) {
				<app-overview-notes [componentId]="4"></app-overview-notes>
			}
		</kendo-gridlayout-item>
	</kendo-gridlayout>
</div>
