import { PFTableValues } from 'components/project-page/risk/risk-performance-factor/risk-performance-factor/performance-factor.component';
import { ProjectInterface, ScheduleType } from '../models/Project';
import { PostMitigationRisk, PrePostMitigationRisk, RiskRegister } from '../models/risk';
import { CompletionVarianceGraphInterface, ProjectReportInterface } from '../models/ProjectReport/ProjectReport';
import { PfTableValue } from '@rhinoworks/analytics-calculations';

export interface BuiltPfTable {
	pfRows: Array<PFTableValues>;
	selectedActivities: Set<PFTableValues>;
	unselectedActivities: Set<PFTableValues>;
}

export const buildPfTable = (
	pfTableHistorical: PfTableValue[][],
	fromPerformanceTrending: boolean,
	performanceTrendingSelectedActivityCodes?: string[],
	selectedActivityCodes?: any
): BuiltPfTable => {
	if (!performanceTrendingSelectedActivityCodes) {
		fromPerformanceTrending = false;
	}
	const pfTable = pfTableHistorical[pfTableHistorical.length - 1] || [];
	console.log('after return pfTable', pfTable);
	const pfIds = new Set<number>([]);
	const pfRows: Array<PFTableValues> = [];
	const selectedActivities = [];
	const unselectedActivities = [];
	for (const pfTableValue of pfTable) {
		if (pfTableValue.numStarted && !pfIds.has(pfTableValue.activityCodeId)) {
			const pfValue = {
				activityCodeId: pfTableValue.activityCodeId,
				activityCode: pfTableValue.activityCode,
				shortName: pfTableValue.activityCode,
				description: pfTableValue.description,
				pf: pfTableValue.pf,
				isSelected: fromPerformanceTrending
					? performanceTrendingSelectedActivityCodes?.includes(pfTableValue.description)
					: selectedActivityCodes?.includes(pfTableValue.activityCodeId),
				numStarted: pfTableValue.numStarted,
			} as PFTableValues;
			pfIds.add(pfTableValue.activityCodeId);
			pfRows.push(pfValue);
			if (!pfValue.isSelected) {
				unselectedActivities.push(pfValue);
			} else {
				selectedActivities.push(pfValue);
			}
		}
	}
	pfRows.sort((a, b) => {
		if (!a.isSelected && b.isSelected) {
			return -1;
		}
		if (!b.isSelected && a.isSelected) {
			return 1;
		}
		return b.pf - a.pf;
	});
	return {
		pfRows,
		selectedActivities: new Set<PFTableValues>(selectedActivities),
		unselectedActivities: new Set<PFTableValues>(unselectedActivities),
	};
};

export const canRunRisk = (project: ProjectInterface): boolean =>
	!!project.riskPagePurchased &&
	!!project.updateIds?.length &&
	((project.riskMetricsType === 'riskRegister' && (project.updateIds?.length || 0) > 0) ||
		(project.updateIds?.length || 0) > 1);

const SIMULATION_COUNT = 1000;
const SIM_PER_JOB_COUNT = 500;
const NUM_JOBS_PER_SIM = SIMULATION_COUNT / SIM_PER_JOB_COUNT;
const IS_SIMULATE_PREV_UPDATES = false;

export const numExpectedMCJobs = (project: ProjectInterface): number => {
	if (!canRunRisk(project)) {
		return 0;
	}

	return project.riskMetricsType === 'performanceFactor' ? 1 : (project.riskMitigation?.length || 0) * 2 + 3;
};

export const numActualMCJobProgress = (project: ProjectInterface): number => {
	if (!canRunRisk(project)) {
		return -1;
	}
	const mcJobs = project.mcJobs || {};
	let numCompletedJobs = 0;
	const numExpectedJobItems = numExpectedMCJobs(project) / NUM_JOBS_PER_SIM;
	numCompletedJobs +=
		(numExpectedJobItems - Object.values(mcJobs).reduce((sum, jobs) => sum + jobs.length, 0)) * NUM_JOBS_PER_SIM;
	numCompletedJobs = Object.values(project?.mcJobs || {}).reduce(
		(sum, updateJobs) => sum + updateJobs.reduce((updateSum, job) => updateSum + job.jobProgress, 0),
		numCompletedJobs
	);
	return numCompletedJobs;
};

export const createRegisterClass = (register: RiskRegister): RiskRegister => {
	register.preMitigation = new PrePostMitigationRisk(register.preMitigation);
	register.postMitigation = new PostMitigationRisk(register.postMitigation);
	return register;
};

export const hasReportChanged = (
	previous: Omit<ProjectReportInterface, 'project'> & { project?: ProjectInterface },
	current: Omit<ProjectReportInterface, 'project'> & { project?: ProjectInterface }
): boolean => {
	const previousShallow = { ...previous };
	const currentShallow = { ...current };
	delete previousShallow.project;
	delete currentShallow.project;
	return hasObjChanged(previousShallow, currentShallow);
};

export const hasObjChanged = (previous: object, current: object): boolean =>
	JSON.stringify(previous) !== JSON.stringify(current);

export const scheduleType = (project: ProjectInterface): ScheduleType => {
	if (!project) {
		return undefined;
	}
	if (project.isArchived) {
		return 'Archived';
	}
	if (project.isClosed) {
		return 'Closed';
	}
	if (project.isConsulting) {
		return 'Consulting';
	}
	if (project.isBaselineDevelopment) {
		return 'Baseline Development';
	}
	if (project.updateIds?.length > 1) {
		return 'Active';
	}
	return 'Baseline';
};

export const setScheduleType = (project: ProjectInterface, schedType: ScheduleType): ProjectInterface => {
	project.isArchived = schedType === 'Archived';
	project.isClosed = schedType === 'Closed';
	project.isConsulting = schedType === 'Consulting';
	project.isBaselineDevelopment = schedType === 'Baseline Development';
	return project;
};

export const compareVarianceArrayLength = (
	a: CompletionVarianceGraphInterface,
	b: CompletionVarianceGraphInterface
): number => {
	const c = a.contractVarianceArray[a.contractVarianceArray.length - 1];
	const d = b.contractVarianceArray[b.contractVarianceArray.length - 1];

	if (c < d) {
		return -1;
	}
	if (d > c) {
		return 1;
	}
	return 0;
};

export const qcImpacts = (
	report: ProjectReportInterface
): {
	fsLagPenalty: number;
	missingPredSuccPenalty: number;
	oosPenalty: number;
	negLagPenalty: number;
	hardConstraintPenalty: number;
	relationshipDensityPenalty: number;
	durationPenalty: number;
	floatPenalty: number;
	ssffProblematicPenalty: number;
	openStartFinishPenalty: number;
	sfPenalty: number;
} => {
	const relationshipDensity = report.qualityControl.totalRelationships / report.qualityControl.totalTaskActivities;
	const highDuration = report.qualityControl.highDurationActivities / report.qualityControl.totalTaskActivities;
	const highFloat = report.qualityControl.highFloatActivities / report.qualityControl.totalTaskActivities;
	const problematicFloat = report.qualityControl.problematicFloatActivities / report.qualityControl.totalTaskActivities;
	const problematicFloatPenalty =
		problematicFloat > 0.01 ? Math.min(0.1, Math.floor((problematicFloat - 0.01) / 0.01) * 0.01) : 0;

	const openStartFinish = report.qualityControl.openStartFinish / report.qualityControl.totalTaskActivities;
	const fsLagPenalty = report.qualityControl.fsLags > 2 ? Math.min(0.15, (report.qualityControl.fsLags - 2) * 0.02) : 0;
	const missingPredSuccPenalty =
		report.qualityControl.missingPredecessorsSuccessors > 2
			? Math.min(0.1, (report.qualityControl.missingPredecessorsSuccessors - 2) * 0.02)
			: 0;
	const oosCriticalPenalty =
		report.qualityControl.totalOutOfSequenceCriticalActivities > 1
			? Math.min(0.05, (report.qualityControl.totalOutOfSequenceCriticalActivities - 1) * 0.01)
			: 0;
	const oosNearCriticalPenalty =
		report.qualityControl.totalOutOfSequenceNearCriticalActivities > 1
			? Math.min(0.025, (report.qualityControl.totalOutOfSequenceNearCriticalActivities - 1) * 0.005)
			: 0;
	const oosNonCriticalPenalty =
		report.qualityControl.totalOutOfSequenceNonCriticalActivities > 1
			? Math.min(0.025, (report.qualityControl.totalOutOfSequenceNonCriticalActivities - 1) * 0.005)
			: 0;
	const oosPenalty = oosCriticalPenalty + oosNearCriticalPenalty + oosNonCriticalPenalty;
	const negLagPenalty =
		report.qualityControl.negativeLags > 1 ? Math.min(0.12, (report.qualityControl.negativeLags - 1) * 0.01) : 0;
	const hardConstraintPenalty =
		report.qualityControl.hardConstraints > 1 ? Math.min(0.1, (report.qualityControl.hardConstraints - 1) * 0.01) : 0;
	function computeUnits(x: number): number {
		if (x < 2) {
			return Math.floor((2 - x) / 0.05);
		} else if (x > 4) {
			return Math.floor((x - 4) / 0.05);
		} else {
			return 0;
		}
	}
	const relationshipDensityPenalty =
		relationshipDensity < 2 || relationshipDensity > 4 ? Math.min(0.05, computeUnits(relationshipDensity) * 0.05) : 0;
	const durationPenalty = highDuration > 0.1 ? Math.min(0.1, Math.floor((highDuration - 0.1) / 0.02) * 0.01) : 0;
	const floatPenalty =
		(highFloat > 0.05 ? Math.min(0.1, Math.floor((highFloat - 0.05) / 0.02) * 0.01) : 0) + problematicFloatPenalty;
	const ssffProblematicPenalty = report.qualityControl.ssFfProblematic > 0 ? 0.05 : 0;
	const openStartFinishPenalty = openStartFinish > 0.01 ? 0.05 : 0;
	const sfPenalty =
		report.qualityControl.percentSF > 0 ? Math.min(0.03, Math.floor(report.qualityControl.percentSF) * 0.01) : 0;
	return {
		fsLagPenalty,
		missingPredSuccPenalty,
		oosPenalty,
		negLagPenalty,
		hardConstraintPenalty,
		relationshipDensityPenalty,
		durationPenalty,
		floatPenalty,
		sfPenalty,
		ssffProblematicPenalty,
		openStartFinishPenalty,
	};
};
