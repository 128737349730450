import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AnalyticsDashboardService } from '../../../../services/analytics/analytics.service';
import { MatDialog } from '@angular/material/dialog';
/**
 * @title Dialog with header, scrollable content and actions
 */
export interface DialogData {
	type: string;
}

@Component({
	selector: 'tooltip-open',
	templateUrl: 'tooltip-open.component.html',
	styleUrls: ['tooltip-open.component.scss'],
})
export class TooltipOpenComponent {
	@Input() type: string;
	@Input() fillColor;

	kbLinkDict = {
		summary: '/project-page/overview-tab#summary',
		overview: '/project-page/overview-tab#overview-components',
		'trending-chart': '/project-page/progress-tab#project-completion-trending',
		'progress-trending': '/project-page/progress-tab#progress-trending',
		'critical-path': '/project-page/progress-tab#schedule-lookahead',
		milestones: '/project-page/progress-tab#milestones',
		'float-consumption': '/project-page/progress-tab#float-consumption',
		'activity-completion': '/project-page/progress-tab#activity-completion',
		'activity-completion-trending': '/project-page/progress-tab#activity-completion-trending',
		'qc-score-and-trend': '/project-page/quality-tab#quality-control-score',
		'schedule-analysis': '/project-page/quality-tab#schedule-analysis',
		'prob-activities': '/project-page/quality-tab#potential-schedule-issues',
		dcma: '/project-page/quality-tab#dcma-14-point-assessment',
		's-curve': '/project-page/cost-tab#s-curve-beta',
		'baseline-historic': '/project-page/cost-tab#baseline-historic-performance-beta',
		spi: '/project-page/cost-tab#schedule-performance-index-beta',
		'activity-costs': '/project-page/cost-tab#activity-cost-details-beta',
		'schedules-grid': '/project-page/schedules-tab/manage#schedule-updates',
		'schedule-impact-analysis': '/project-page/schedules-tab/analysis#schedule-impact-analysis',
		'critical-path-comparison': '/project-page/schedules-tab/analysis#critical-path-comparison',
		'index-trending': '/project-page/risk-tab#index-trending',
		'critical-path-switch': '/project-page/risk-tab#critical-path-switch',
		'critical-path-reliability': '/project-page/risk-tab#critical-path-reliability',
		'monte-carlo-performance-factor': '/project-page/risk-tab#monte-carlo---performance-factor',
		'monte-carlo-risk-register': '/project-page/risk-tab#monte-carlo---risk-register',
		'risk-register': '/project-page/risk-tab#risk-register',
		'schedule-comparison': '/project-page/schedules-tab/analysis#schedule-comparison',
		'schedule-compression': '/project-page/schedules-tab/analysis#schedule-compression',
		'current-update-gantt': '/project-page/schedules-tab/reports',
		'risk-score': '/scores-kpis#predictability-score',
	};

	constructor(
		public popoverController: PopoverController,
		public dialog: MatDialog,
		public services: AnalyticsDashboardService
	) {}
	openDialog() {
		if (this.type) {
			window.open('https://docs.rhino.works/docs/aegis-analytics' + this.kbLinkDict[this.type], '_blank');
		}
		// this.services.tooltipType.next(this.type);
	}
}
