<kendo-window
  id="kendoWindow"
  *ngIf="opened"
  (close)="open(false)"
  [resizable]="false"
  [minWidth]="650"
  [top]="4"
  style="position: absolute"
>
  <kendo-window-titlebar>
    <div class="k-window-title">Baseline Upload</div>
    <button kendoWindowMinimizeAction></button>
    <button kendoWindowMaximizeAction></button>
    <button kendoWindowRestoreAction></button>
    <button kendoWindowCloseAction></button>
  </kendo-window-titlebar>

  <div class="baseline">
    <mat-horizontal-stepper [linear]="isLinear" labelPosition="bottom" #stepper>
      <mat-step [stepControl]="null" [completed]="nextButtonIsEnabled">
        <ng-template matStepLabel>Upload Schedule File</ng-template>
        <h2 fxLayoutAlign="center">Select the Project's Baseline Schedule file (.xer)</h2>
        <div class="base-file-selection">
          <p class="base-file-selection-item" fxLayoutAlign="center">
            Select the baseline schedule file for the project (.xer version 7.0 recommended)
          </p>
          <kendo-fileselect [(ngModel)]="fileUploadService.xerFile"
                            (change)="makeFileReadyForUpload($event, stepper);"
                            [disabled]="fileUploadService.loading === 'file'"
														[restrictions]="xerRestrictions"
          > </kendo-fileselect>
          <input
            class="base-file-selection-item"
            type="file"
            name="xerFile"
            [(ngModel)]="fileUploadService.xerFile"
            [ngModelOptions]="{ standalone: true }"
            (change)="makeFileReadyForUpload($event, stepper);"
            [disabled]="fileUploadService.loading === 'file'"
            style="width: 70%; text-align-last: center;"
          />
          <br/>
          <div *ngIf='uploadedUpdate?.xer?.projects?.size > 1'>
            <kendo-label [for]="selected_proj_id" text="Select Project Schedule"></kendo-label>
            <kendo-dropdownlist
              #selected_proj_id
              formControlName="selected_proj_id"
              [data]="uploadedUpdate.xer.projects | keyvalue"
              valueField='key'
              textField='value.shortName'
              [valuePrimitive]='true'
              [filterable]="true"
              (valueChange)='projectChange($event)'
            >
            </kendo-dropdownlist>
          </div>
        </div>
        <hr class="custom-hr" />
        <div class="actions">
          <button
            style="float: left; font-size: 16px; background-color: #e04646; color: white; padding: 0 16px;"
            mat-button
            (click)="onNoClick()"
          >
            CANCEL
          </button>
          <button
            id="nextButton"
            style="float: right; font-size: 16px; background-color: #059ce5; color: white; padding: 0 16px;"
            *ngIf="fileUploadService.xerFile"
            mat-button
            matStepperNext
          >
            NEXT
          </button>
          <button
            id="nextButtonDisabled"
            style="float: right; font-size: 16px; background-color: #a9a9a9; color: white; padding: 0 16px; cursor: not-allowed"
            *ngIf="!fileUploadService.xerFile"
            mat-button
          >
            NEXT
          </button>
        </div>
      </mat-step>
      <mat-step [stepControl]="null" [completed]="!!fileUploadService.data.startMilestone">
        <ng-template matStepLabel>Project Start Date</ng-template>
        <h2 fxLayoutAlign="center">Project Start Date</h2>
        <div class="mile-stone-selection" [formGroup]="startMilestoneFormGroup">
          <p class="mile-stone-selection-item" fxLayoutAlign="center" style="margin-bottom: 16px">
            Select the start milestone that represents the beginning of the project's contract duration from the dropdown
            menu below.
          </p>
          <kendo-label [for]="startMilestone" text="Select Start Milestone or Activity"></kendo-label>
          <kendo-dropdownlist
            #startMilestone
            [(ngModel)]="selectedStartMilestone"
            [ngModelOptions]="{standalone: true}"
            (valueChange)="updateMilestoneSelect({start: $event, end: undefined})"
            [data]="fileUploadService.startMilestones"
            [textField]='"text"'
            [valueField]='"value"'
            [valuePrimitive]='false'
            [filterable]="true"
            (filterChange)="handleFilter($event, 'startMilestones', 'startMilestoneList')"
          >
            <ng-template kendoComboBoxFixedGroupTemplate let-groupName>
              <div><strong><u>{{groupName}}</u></strong></div>
            </ng-template>
            <!--<ng-template kendoDropDownListHeaderTemplate let-category>
							<div>
								<strong><u>{{ category }}</u></strong>
							</div>

						</ng-template>-->
            <ng-template kendoDropDownListGroupTemplate let-category>
              <div>
                <strong><u>{{ category }}</u></strong>
              </div>
            </ng-template>
          </kendo-dropdownlist>
        </div>
        <hr class="custom-hr" />
        <div class="actions">
          <button
            style="float: left; font-size: 16px; background-color: #e04646; color: white; padding: 0 16px;"
            mat-button
            matStepperCancel
            (click)="onNoClick()"
          >
            CANCEL
          </button>
          <button style="float: left; font-size: 16px; background-color: #059ce5; color: white; padding: 0 16px; margin-left: 8px;" mat-button matStepperPrevious>BACK</button>
          <button
            style="float: right; font-size: 16px; background-color: #059ce5; color: white; padding: 0 16px;"
            *ngIf="!!fileUploadService.data.startMilestone"
            mat-button
            matStepperNext
          >
            NEXT
          </button>
          <button
            style="float: right; font-size: 16px; background-color: #a9a9a9; color: white; padding: 0 16px; cursor: not-allowed"
            *ngIf="!fileUploadService.data.startMilestone"
            mat-button
          >
            NEXT
          </button>
        </div>
      </mat-step>
      <mat-step [stepControl]="null" [completed]="!!fileUploadService.data.finishMilestone">
        <ng-template matStepLabel>Project Completion (CCD)</ng-template>
        <h2 fxLayoutAlign="center">Project Completion (CCD)</h2>
        <div class="mile-stone-selection" [formGroup]="completionMilestoneFormGroup">
          <p class="mile-stone-selection-item" fxLayoutAlign="center" style="margin-bottom: 16px">
            Select the Finish Milestone the represents the contractual completion of the project from the dropdown menu
            below.
          </p>
          <kendo-label [for]="finishMilestone" text="Select Finish Milestone or Activity"></kendo-label>
          <kendo-dropdownlist
            #finishMilestone
            [(ngModel)]="selectedCompletionMilestone"
            [ngModelOptions]="{standalone: true}"
            (valueChange)="updateMilestoneSelect({start: undefined, end: $event})"
            [data]="fileUploadService.finishMilestones"
            valueField='value'
            textField='text'
            [valuePrimitive]='false'
            [filterable]="true"
            (filterChange)="handleFilter($event, 'finishMilestones', 'finishMilestoneList')"
          >
            <ng-template kendoComboBoxFixedGroupTemplate let-groupName>
              <div><strong><u>{{groupName}}</u></strong></div>
            </ng-template>
            <!--<ng-template kendoDropDownListHeaderTemplate let-category>
							<div>
								<strong><u>{{ category }}</u></strong>
							</div>

						</ng-template>-->
            <ng-template kendoDropDownListGroupTemplate let-category>
              <div>
                <strong><u>{{ category }}</u></strong>
              </div>
            </ng-template>
          </kendo-dropdownlist>
        </div>
        <hr class="custom-hr" />
        <div class="actions">
          <button
            style="float: left; font-size: 16px; background-color: #e04646; color: white; padding: 0 16px;"
            mat-button
            matStepperCancel
            (click)="onNoClick()"
          >
            CANCEL
          </button>
          <button style="float: left; font-size: 16px; background-color: #059ce5; color: white; padding: 0 16px; margin-left: 8px;" mat-button matStepperPrevious>BACK</button>
          <button
            style="float: right; font-size: 16px; background-color: #059ce5; color: white; padding: 0 16px;"
            *ngIf="fileUploadService.data.finishMilestone"
            mat-button
            matStepperNext
          >
            NEXT
          </button>
          <button
            style="float: right; font-size: 16px; background-color: #a9a9a9; color: white; padding: 0 16px; cursor: not-allowed"
            *ngIf="!fileUploadService.data.finishMilestone"
            mat-button
          >
            NEXT
          </button>
        </div>
      </mat-step>
      <mat-step [stepControl]="additionalCompletionMilestoneFormGroup" *ngIf='userType !== "saasRisk"'>
        <ng-template matStepLabel>Additional Milestones</ng-template>

        <h2 fxLayoutAlign="center">Additional Tracking Milestones - Optional</h2>
        <div class="mile-stone-selection" [formGroup]="additionalCompletionMilestoneFormGroup">
          <p class="mile-stone-selection-item" fxLayoutAlign="center">
            Select up to 5 additional Finish Milestones that you would like to track in addition to Project Completion
            (CCD).
          </p>
          <kendo-multiselect
            #additionalMilestones
            [data]="fileUploadService.finishMilestones"
            [valuePrimitive]='false'
            [autoClose]="false"
            valueField='value'
            textField='text'
            [formControl]='fileUploadService.additionalMilestonesFormControl'
            [(value)]='task'
            [filterable]="true"
            (filterChange)="handleFilter($event, 'finishMilestones', 'finishMilestoneList')"
          >
            <ng-template kendoComboBoxFixedGroupTemplate let-groupName>
              <div><strong><u>{{groupName}}</u></strong></div>
            </ng-template>
            <!--<ng-template kendoDropDownListHeaderTemplate let-category>
              <div>
                <strong><u>{{ category }}</u></strong>
              </div>

            </ng-template>-->
            <ng-template kendoDropDownListGroupTemplate let-category>
              <div>
                <strong><u>{{ category }}</u></strong>
              </div>
            </ng-template>
          </kendo-multiselect>
        </div>
        <div>
          <p *ngIf="task[0]" fxLayoutAlign="center">Selected Additional Milestones</p>
        </div>
        <ng-container *ngFor="let taskItem of task">
          <div fxLayoutAlign="center" *ngIf="taskItem">{{ taskItem.text }}</div>
        </ng-container>
        <hr class="custom-hr" />
        <div class="actions">
          <button
            style="float: left; font-size: 16px; background-color: #e04646; color: white; padding: 0 16px;"
            mat-button
            matStepperCancel
            (click)="onNoClick()"
          >
            CANCEL
          </button>
          <button style="float: left; font-size: 16px; background-color: #059ce5; color: white; padding: 0 16px; margin-left: 8px;" mat-button matStepperPrevious>BACK</button>
          <button
            style="float: right; font-size: 16px; background-color: #059ce5; color: white; padding: 0 16px;"
            mat-button
            matStepperNext
          >
            NEXT
          </button>
        </div>
      </mat-step>
      <mat-step [stepControl]="null" *ngIf='userType !== "saasRisk"'>
        <ng-template matStepLabel>Risk Calculation Method</ng-template>
        <h2 fxLayoutAlign="center">Risk Calculation Method</h2>
        <app-add-risk-button
          [riskOptions]="riskOptions"
          (updateRiskMetricsType)="setRisk($event)"
        ></app-add-risk-button>
        <hr class="custom-hr" />
        <div class="actions">
          <button
            style="float: left; font-size: 16px; background-color: #e04646; color: white; padding: 0 16px;"
            mat-button
            matStepperCancel
            (click)="onNoClick()"
          >
            CANCEL
          </button>
          <button style="float: left; font-size: 16px; background-color: #059ce5; color: white; padding: 0 16px; margin-left: 8px;" mat-button matStepperPrevious>BACK</button>
          <button
            style="float: right; font-size: 16px; background-color: #059ce5; color: white; padding: 0 16px;"
            mat-button
            matStepperNext
          >
            NEXT
          </button>
        </div>
      </mat-step>
      <mat-step [stepControl]="scheduleNotesFormGroup">
        <ng-template matStepLabel>Baseline Notes</ng-template>
        <h2 fxLayoutAlign="center">Baseline Notes</h2>
        <div class="baseline-notes" [formGroup]="scheduleNotesFormGroup">
          <p class="baseline-notes-item" fxLayoutAlign="center">Add notes to summarize the baseline schedule.</p>
          <mat-card class="notes" style="padding: 8px; width: 100%">
            <mat-card-content>
              <div
                class="flex-p"
                style="
                min-height: 75px;
                max-height: 75px;
                min-width: 100%;
                max-width: 100%;
                width: 100%;
                margin-bottom: 4px;
              "
              >
                <mat-form-field style="height: 75px; min-width: 100%; max-width: 100%; width: 100%">
                  <mat-label>Critical Path Summary</mat-label>
                  <textarea
                    matInput
                    maxlength="3000"
                    placeholder="Provide a summary of the Project's current critical path"
                    [(ngModel)]="fileUploadService.criticalPathSummary"
                    required
                    [ngModelOptions]="{ standalone: true }"
                    style="min-height: 50px; max-height: 50px; height: 50px; padding: 0 5px 0 5px; width: 100%; margin: 0"
                  ></textarea>
                </mat-form-field>
              </div>
              <div
                class="flex-p"
                style="min-height: 75px; max-height: 75px; min-width: 100%; max-width: 100%; width: 100%"
              >
                <mat-form-field style="height: 75px; min-width: 100%; max-width: 100%; width: 100%">
                  <mat-label>Impacts / Issues / TIA Notes</mat-label>
                  <textarea
                    matInput
                    maxlength="3000"
                    placeholder="Discuss any project impacts, issues or delays"
                    [(ngModel)]="fileUploadService.impactsIssuesTiaNotes"
                    required
                    [ngModelOptions]="{ standalone: true }"
                    style="min-height: 50px; max-height: 50px; height: 50px; padding: 0 5px 0 5px; width: 100%; margin: 0"
                  ></textarea>
                </mat-form-field>
              </div>
              <div
                class="flex-p"
                style="min-height: 25px; max-height: 25px; min-width: 100%; max-width: 100%; width: 100%; padding-top: 24px; padding-bottom: 24px;"
                *ngIf='userType !== "saasRisk"'
              >
                <div style="height: 25px; min-width: 100%; max-width: 100%; width: 100%">
                  <mat-label style="font-size: 16px;">Flag Schedule </mat-label>
                  <mat-slide-toggle [ngModelOptions]="{ standalone: true }" name="isAegisGeneratedSchedule" ngDefaultControl [(ngModel)]='fileUploadService.data.isAegisGeneratedSchedule'></mat-slide-toggle>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
          <!--<mat-form-field class="baseline-notes-item example-full-width">
											<mat-label>Critical Path Summary</mat-label>
											<textarea  [(ngModel)]="fileUploadService.criticalPathSummary" matInput placeholder="Provide a summary of the Project's current critical path here"></textarea>
									</mat-form-field>
									<mat-form-field class="baseline-notes-item example-full-width">
											<mat-label>Impacts / Issues / TIA Note</mat-label>
											<textarea  [(ngModel)]="fileUploadService.impactsIssuesTiaNotes" matInput placeholder="Discuss any admin-project impacts, issues or delays here"></textarea>
									</mat-form-field> -->
        </div>
        <hr class="custom-hr" />
        <div class="actions">
          <button
            style="float: left; font-size: 16px; background-color: #e04646; color: white; padding: 0 16px;"
            mat-button
            matStepperCancel
            (click)="onNoClick()"
          >
            CANCEL
          </button>
          <button style="float: left; font-size: 16px; background-color: #059ce5; color: white; padding: 0 16px; margin-left: 8px;" mat-button matStepperPrevious>BACK</button>
          <button
            style="float: right; font-size: 16px; background-color: #059ce5; color: white; padding: 0 16px;"
            mat-button
            matStepperNext
          >
            NEXT
          </button>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Review & Submit</ng-template>
        <mat-card style="background: #9e9e9e0d; box-shadow: none; overflow-y: auto; padding: 8px">
          <mat-card-header fxLayoutAlign="center">
            <div>
              <h2 fxLayoutAlign="center" style="margin-left: -28px; margin-top: 0; margin-bottom: 8px">
                Review & Submit Baseline Schedule
              </h2>
            </div>
          </mat-card-header>
          <mat-card-content style="text-align: left">
            <div>
              <p fxLayoutAlign="center" style="margin: 0">
                Make sure all your information is correct before confirming this upload.
              </p>
            </div>
            <div style="margin-top: 1rem">
              <hr style="margin: 2% 0" />
              <h3 style="margin-top: 0" fxLayoutAlign="left">Basic Information</h3>
              <table style="width: 100%">
                <tr>
                  <td>
                    <h4 style="margin: 0">Update Name</h4>
                  </td>
                  <td>
                    <h4 style="margin: 0">Schedule ID</h4>
                  </td>
                  <td>
                    <h4 style="margin: 0">Data Date</h4>
                  </td>
                  <td>
                    <h4 style="margin: 0">Activity Count</h4>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p style="margin-bottom: 0">Baseline</p>
                  </td>
                  <td>
                    <p *ngIf="fileUploadService.data.selectedProjectId && fileUploadService.xer.projects" style="margin-bottom: 0">
                      {{ fileUploadService.xer.projects.get(fileUploadService.data.selectedProjectId).shortName }}
                    </p>
                  </td>
                  <td>
                    <p *ngIf="fileUploadService.data.selectedProjectId && fileUploadService.xer.projects" style="margin-bottom: 0">
                      {{ fileUploadService.xer.projects.get(fileUploadService.data.selectedProjectId).lastRecalculationDate | date: 'MMM d, y' }}
                    </p>
                  </td>
                  <td>
                    <p *ngIf="fileUploadService.xer?.sortedActivities" style="margin-bottom: 0">
                      {{ fileUploadService.xer.sortedActivities.length }}
                    </p>
                  </td>
                </tr>
              </table>
              <hr style="margin: 2% 0" />
              <div style="margin-top: 1rem"></div>
              <h3 style="margin-top: 0" fxLayoutAlign="left">Milestones</h3>
              <table style="width: 100%">
                <tr>
                  <td>
                    <h4 style="margin: 0">Project Start</h4>
                  </td>
                  <td>
                    <h4 style="margin: 0">Project Completion</h4>
                  </td>
                  <td>
                    <h4 style="margin: 0">Additional Milestones</h4>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p *ngIf="fileUploadService.data.startMilestone" style="margin-bottom: 0; margin-top: 5px">
                      {{ fileUploadService.data.startMilestone.task_name }} - ({{
                      fileUploadService.data.startMilestone.task_code
                      }})
                    </p>
                    <p *ngIf="fileUploadService.data.startMilestone" style="margin-bottom: 0; margin-top: 5px">
                      {{ fileUploadService.data.startMilestone.early_end_date | date: 'MMM d, y' }}
                    </p>
                  </td>
                  <td>
                    <p *ngIf="fileUploadService.data.finishMilestone" style="margin-bottom: 0; margin-top: 5px">
                      {{ fileUploadService.data.finishMilestone.task_name }} - ({{
                      fileUploadService.data.finishMilestone.task_code
                      }})
                    </p>
                    <p *ngIf="fileUploadService.data.finishMilestone" style="margin-bottom: 0; margin-top: 5px">
                      {{ fileUploadService.data.finishMilestone.early_end_date | date: 'MMM d, y' }}
                    </p>
                  </td>
                  <td>
                    <p *ngIf="!task[0]" style="margin-bottom: 0; margin-top: 5px">N/A</p>
                    <p *ngIf="task[0]" style="margin-bottom: 0; margin-top: 5px">
                      {{ task[0].value.task_name }} - ({{ task[0].value.task_code }})
                    </p>
                    <p *ngIf="task[1]" style="margin-bottom: 0; margin-top: 5px">
                      {{ task[1].value.task_name }} - ({{ task[1].value.task_code }})
                    </p>
                    <p *ngIf="task[2]" style="margin-bottom: 0; margin-top: 5px">
                      {{ task[2].value.task_name }} - ({{ task[2].value.task_code }})
                    </p>
                  </td>
                </tr>
              </table>
            </div>
            <p *ngIf='userType !== "saasRisk"'>Risk calculation method is {{ riskMetricsType === 'performanceFactor' ? 'Monte Carlo - Performance Factor' : riskMetricsType === 'riskRegister' ? 'Monte Carlo - Risk Register' : 'default'}}</p>
            <p *ngIf='userType !== "saasRisk" && fileUploadService?.data.isAegisGeneratedSchedule'>This schedule was flagged.</p>
            <div style="margin-top: 1rem">
              <h3 style="margin-top: 0" fxLayoutAlign="left">Baseline Notes</h3>
              <table style="width: 100%">
                <tr style="margin-bottom: 5px">
                  <td style="width: 20%">
                    <h4 style="margin: 2px 0">Critical Path Summary</h4>
                  </td>
                  <td style="width: 80%">
                    <p *ngIf="fileUploadService.criticalPathSummary" style="margin-top: 6px; margin-bottom: 6px">
                      {{ fileUploadService.criticalPathSummary }}
                    </p>
                  </td>
                </tr>
                <tr style="margin-bottom: 5px">
                  <td style="width: 20%">
                    <h4 style="margin: 2px 0">Impacts / Issues / TIA Notes</h4>
                  </td>
                  <td style="width: 80%">
                    <p *ngIf="fileUploadService.impactsIssuesTiaNotes" style="margin-top: 6px; margin-bottom: 6px">
                      {{ fileUploadService.impactsIssuesTiaNotes }}
                    </p>
                  </td>
                </tr>
              </table>
            </div>
          </mat-card-content>
        </mat-card>
        <hr class="custom-hr" />
        <div style="margin-top: 2rem">
          <button
            style="float: left; font-size: 16px; background-color: #e04646; color: white; padding: 0 16px;"
            mat-button
            matStepperCancel
            (click)="onNoClick()"
          >
            CANCEL
          </button>
          <button style="float: left; font-size: 16px; background-color: #059ce5; color: white; padding: 0 16px; margin-left: 8px;" mat-button matStepperPrevious>BACK</button>
          <button
            style="float: right; font-size: 16px; background-color: #2ebb2e; color: white; margin-right: 8px; padding: 0 16px; cursor: pointer;"
            mat-button
            (click)="submit()"
            *ngIf="!httpProcessing"
          >
            SUBMIT
          </button>
          <button
            style="float: right; font-size: 16px; background-color: #a9a9a9; color: white; margin-right: 8px; padding: 0 16px; cursor: not-allowed;"
            mat-button
            *ngIf="httpProcessing"
          >
            SUBMITTING...
          </button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</kendo-window>
