import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleLookaheadComponent } from './schedule-lookahead.component';
import { IonicModule } from '@ionic/angular';
import { TooltipMenuModule } from '../../../portfolio/tooltip-menu/tooltip-menu.module';
import { CalculationsService } from '../../../../services/project/calculations.service';
import { GanttModule } from '@progress/kendo-angular-gantt';
import { ButtonGroupModule, ButtonModule } from '@progress/kendo-angular-buttons';
import { GridLayoutModule } from '@progress/kendo-angular-layout';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { LabelModule } from '@progress/kendo-angular-label';
import { DropDownTreesModule, SharedDirectivesModule } from '@progress/kendo-angular-dropdowns';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { GridModule } from '@progress/kendo-angular-grid';
import { OverviewNotesModule } from '../../../shared/overview-notes/overview-notes.module';

@NgModule({
	declarations: [ScheduleLookaheadComponent],
	providers: [CalculationsService],
	imports: [
		CommonModule,
		IonicModule,
		TooltipMenuModule,
		GanttModule,
		ButtonGroupModule,
		ButtonModule,
		GridLayoutModule,
		TooltipModule,
		LabelModule,
		DropDownTreesModule,
		SVGIconModule,
		SharedDirectivesModule,
		GridModule,
		OverviewNotesModule,
	],
	exports: [ScheduleLookaheadComponent],
})
export class ScheduleLookaheadModule {}
