import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedIonModule } from '../../shared/shared-ion.module';
import { TooltipMenuModule } from '../../portfolio/tooltip-menu/tooltip-menu.module';
import { PipeModule } from '../../../util/pipes/pipe.module';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { GridLayoutModule } from '@progress/kendo-angular-layout';
import { DbSyncTabComponent } from './db-sync-tab.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormFieldModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { FilterMenuModule } from '@progress/kendo-angular-grid';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';

@NgModule({
	declarations: [DbSyncTabComponent],
	imports: [
		CommonModule,
		SharedIonModule,
		TooltipMenuModule,
		PipeModule,
		ButtonsModule,
		TooltipsModule,
		GridLayoutModule,
		FormsModule,
		ReactiveFormsModule,
		FormFieldModule,
		LabelModule,
		DropDownsModule,
		FilterMenuModule,
		ProgressBarModule,
	],
	exports: [DbSyncTabComponent],
})
export class DbSyncTabModule {}
