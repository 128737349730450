import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { GlobalTooltipMenuModule } from '../global-toolip-menu/global-tooltip-menu.module';
import { HighchartsChartModule } from 'highcharts-angular';
import { ProfileComponent } from './profile/profile.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FilterMenuModule } from '@progress/kendo-angular-grid';
import { LabelModule } from '@progress/kendo-angular-label';

@NgModule({
	declarations: [ProfileComponent],
	imports: [
		CommonModule,
		IonicModule,
		GlobalTooltipMenuModule,
		HighchartsChartModule,
		ReactiveFormsModule,
		FilterMenuModule,
		LabelModule,
	],
	exports: [ProfileComponent],
})
export class ProfileModule {}
