import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectDashboardComponent } from './project.component';
import { ProjectDashboardService } from 'services/project/project.service';
import { CommonModule } from '@angular/common';
import { UnsavedChangeGuard } from './schedule-updates-list/unsaved-change.guard';
import { VisualizeReportComponent } from './visualize-report/visualize-report.component';

const routes: Routes = [
	{
		path: '',
		component: ProjectDashboardComponent,
		resolve: {
			data: ProjectDashboardService,
		},
		canDeactivate: [UnsavedChangeGuard],
	},
	{
		path: 'visualize',
		component: VisualizeReportComponent,
		resolve: {
			data: ProjectDashboardService,
		},
		canDeactivate: [UnsavedChangeGuard],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes), CommonModule],
	exports: [RouterModule],
})
export class ProjectRoutingModule {}
