import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalTooltipContentComponent } from './global-tooltip-content/global-tooltip-content.component';
import { GlobalTooltipOpenComponent } from './global-tooltip-open/global-tooltip-open.component';
import { WindowModule } from '@progress/kendo-angular-dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
	declarations: [GlobalTooltipOpenComponent, GlobalTooltipContentComponent],
	imports: [CommonModule, MatDialogModule, MatButtonModule, WindowModule],
	exports: [GlobalTooltipOpenComponent, GlobalTooltipContentComponent],
})
export class GlobalTooltipMenuModule {}
