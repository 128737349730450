import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AnalyticsDashboardService } from '../../../../services/analytics/analytics.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'global-tooltip-open',
	templateUrl: 'global-tooltip-open.html',
	styleUrls: ['global-tooltip-open.scss'],
})
export class GlobalTooltipOpenComponent {
	@Input() type: string;
	@Output() tooltipClicked = new EventEmitter<boolean>();

	kbLinkDict = {
		'projects-summary': '/portfolio-page/#projects-summary',
		'project-breakdown': '/portfolio-page/#projects-breakdown',
		'portfolio-summary': '/portfolio-page/#portfolio-summary',
		'risk-summary': '/portfolio-page/#risk-summary',
		'portfolio-risk': '/portfolio-page/#portfolio-risk',
		'categories-of-risk': '/portfolio-page/#categories-of-risk',
		'heat-map': '/portfolio-page/#heat-map',
		'saas-risk-summary': '/portfolio-page/#reactive-risk-banner',
		projects: '/portfolio-page/#projects-table',
		map: '/portfolio-page/#google-maps-integration',
	};

	constructor(
		public popoverController: PopoverController,
		public dialog: MatDialog,
		public services: AnalyticsDashboardService
	) {}
	openDialog() {
		if (this.type) {
			window.open('https://docs.rhino.works/docs/aegis-analytics' + this.kbLinkDict[this.type], '_blank');
		}
		// this.services.globalTooltipType.next(this.type);
	}
}
