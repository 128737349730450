<div class='overwrite-dialog-content'>
	<div class='body'>
		<div class='description'>
			<p>
				Some imported risks have ids that match existing risks in this project's register.
			</p>
			<ul class='description-ul'>
				<li>
					Save as New - Save the matching record as a new entry
				</li>
				<li>
					Overwrite - Replace the matching record in the Risk Register with new data
				</li>
				<li>
					Ignore Duplicate - Ignore the matching record (do not import)
				</li>
			</ul>
		</div>
		<table class='overwrite-table'>
			<thead>
				<tr>
					<th class='overwrite-header' style='border-radius: 4px 0 0 0;'>Analytics Id</th>
					<th class='overwrite-header'>Analytics Name</th>
					<th class='overwrite-header'>Imported Id</th>
					<th class='overwrite-header'>Imported Name</th>
					<th class='overwrite-header' style='border-radius: 0 4px 0 0;'>Choices</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor='let risk of overwriteChoices; let i = index'>
					<td [style.border-radius]='i === overwriteChoices.length - 1 ? "0 0 0 4px" : "0"'>{{risk.matchingAnalyticsRiskId}}</td>
					<td>{{risk.matchingAnalyticsRiskName}}</td>
					<td>{{risk.importedRiskId}}</td>
					<td>{{risk.importedRiskName}}</td>
					<td class='radio-group' [style.border-radius]='i === overwriteChoices.length - 1 ? "0 0 4px 0" : "0"'>
						<span class='radio-choice'>
							<input
								type="radio"
								[name]='"radio-" + i'
								[id]='"radio-saveAsNew-" + i'
								[value]='"saveAsNew"'
								[(ngModel)]="risk.decision"
								class='radio-button'
								kendoRadioButton />
						<kendo-label [for]='"radio-saveAsNew-" + i' class='radio-label'>Save As New </kendo-label>
						</span>
						<span class='radio-choice'>
							<input
								type="radio"
								[name]='"radio-" + i'
								[id]='"radio-overwrite-" + i'
								[value]='"overwrite"'
								[(ngModel)]="risk.decision"
								class='radio-button'
								kendoRadioButton />
						<kendo-label [for]='"radio-overwrite-" + i' class='radio-label'>Overwrite </kendo-label>
						</span>
						<span class='radio-choice'>
							<input
								type="radio"
								[name]='"radio-" + i'
								[id]='"radio-ignoreDuplicate-" + i'
								[value]='"ignoreDuplicate"'
								[(ngModel)]="risk.decision"
								class='radio-button'
								kendoRadioButton />
						<kendo-label [for]='"radio-ignoreDuplicate-" + i' class='radio-label'>Ignore Duplicate </kendo-label>
						</span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class='actions-container'>
		<button kendoButton (click)='confirmChoices()' themeColor='success'>Confirm</button>
	</div>
</div>
