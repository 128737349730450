<div class='card' id="mcPerformanceFactorComponent">
	<kendo-gridlayout
		[rows]="['42px', '442.5px']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='full-width'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' [class.overviewHeader]="isOverview">
			<kendo-gridlayout
				[cols]="['calc(300px)', 'calc(100% - 305px)',]"
				[gap]="{ rows: 0, cols: 5 }"
				class='full-width'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
					<div class="card-header">
						Monte Carlo Simulation
					</div>
					<tooltip-open type='{{isOverview ? "overview" : "monte-carlo-performance-factor"}}' [fillColor]="isOverview ? 'overview' : ''"></tooltip-open>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2' class='row-start-aligned-container' style='justify-content: flex-end'>
					<div style='position: relative; display: flex; flex-direction: row; justify-content: flex-end; gap: 10px; padding-right: 10px;'>
						<kendo-circularprogressbar
							style="transform: scale(0.1); right: -64%; margin-top: 2px;"
							[indeterminate]="true"
							[value]="30"
							[progressColor]='"#ffb500"'
							id='pf-table-spin'
							*ngIf="($projectData | async)?.updateIds?.length >= (($projectData | async)?.riskMetricsType === 'riskRegister' ? 1 : 2 ) && (!monteCarloFinished || (hasStartedMonte$ | async))"
						></kendo-circularprogressbar>
						<ion-button
							style="font-size: 13px; --background: #e04646; margin: auto 0;"
							(click)="resetFilter()"
							size='small'
							[disabled]="httpProcessing || !cancelEnabled">
							Cancel
						</ion-button>
						<ion-button
							style="font-size: 13px; --background: #2ebb2e; margin: auto 0;"
							(click)="submitFilter()"
							size='small'
							[disabled]="(httpProcessing || scheduleService.isLoading || projectService.$currentProjectData.value?.updateIds?.length <= 1)"
						>
							<span>
								{{ ($projectData | async)?.updateIds?.length >= (($projectData | async)?.riskMetricsType === 'riskRegister' ? 1 : 2 ) && (!monteCarloFinished || (hasStartedMonte$ | async)) ? '' : pfTable.pfRows.length > 0 ? 'Calculate' : 'INITIALIZE PF CODES'}}
							</span>
						</ion-button>
						<span style='display: inline-flex; align-items: center;float: right; gap: 10px;' kendoTooltip>
							@if (isOverview) {
								<button kendoButton themeColor="base" title="Go to source" [imageUrl]="'assets/icons/newIcons/noun-arrow-5544651.svg'" class='header-btn' (click)='projectService.goTo(19)' style="background-color: #ffb500;"></button>
							}
							<button kendoButton themeColor="base" title="Add/Edit Notes" [imageUrl]="'assets/icons/newIcons/noun-notepad-6607257.svg'" class='header-btn overview-notes-icon' (click)='projectService.openNotesWindow({id: 19, name: "Monte Carlo Simulation"})' [style.background-color]="isOverview ? '#ffb500' : 'white'"></button>
						</span>
					</div>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1' *ngIf="($projectData | async) && ($projectData | async)?.updateIds?.length < 2">
			<div class='warning-div'>
				The Monte Carlo - Performance Factor Simulation needs 1 update to enable
			</div>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1' *ngIf="($projectData | async)?.updateIds?.length >= (($projectData | async)?.riskMetricsType === 'riskRegister' ? 1 : 2 ) && (!monteCarloFinished || (hasStartedMonte$ | async))">
			<h2 style="padding-left: 12px; text-align: center; margin: 20px">
				Running Monte Carlo Simulations <kendo-loader themeColor='info'></kendo-loader>
			</h2>
			<div style='display: flex; flex-direction: column; justify-content: center; margin: 0 auto; height: 100%'>
				<p style='text-align: center;' *ngIf='!totalMCJobs && ((($projectData | async).mcJobs | json) === "{}") && ((hasStartedMonte$ | async) === false)'>Initializing Calculation Request... this might take a few seconds.<br><small>A progress bar will appear once we've begun your simulation.</small></p>
				<p style='text-align: center;' *ngIf='totalMCJobs && ((($projectData | async).mcJobs | json) !== "{}")'>{{((totalMCJobsProgress / totalMCJobs) * 100).toString().split(".")[0]}}% processed</p>
				<kendo-progressbar [value]="(totalMCJobsProgress / totalMCJobs) * 100" [indeterminate]='!totalMCJobs && ((($projectData | async).mcJobs | json) === "{}")' [label]='{format: "percent"}'></kendo-progressbar>
				<div style='margin-bottom: 70px; margin-top: 10px; height: 100%; color: white; background: url("https://consultaegis.com/wp-content/uploads/2022/12/iStock-600667654-1-1440x810.jpg") no-repeat center center; background-size: cover; border-radius: 0 0 4px 4px;'>
					<div style='background: rgba(0,19,120,0.78); margin-top:-32px; margin-bottom: 14px; height: 100%; border-radius: 0 0 4px 4px;'>
					<h5 style='font-size: 19px; padding-top: 15px; text-align: center;'>Did you know?</h5>
					<p style='text-align: center; padding: 20px 25px'>{{selectedRhinoFact}}</p>
					<p style='text-align: center; padding-bottom: 15px;'>
						<ion-button href='https://consultaegis.com/about/#block_82709170c0c07c7bddc83816ded336e9' target='_blank' fill='clear' size='small' style='color: yellow'>Why Rhinos?</ion-button>
					</p>
					</div>
				</div>
			</div>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1' *ngIf="($projectData | async) && ($projectData | async).updateIds.length >= 2 && ((($projectData | async).isMonteCarloDone && (hasStartedMonte$ | async) === false) ||
      ((projectService.$currentProjectReport | async) && !(projectService.$currentProjectReport | async)?.project?.riskPagePurchased))"
		>
			<kendo-gridlayout
				[rows]="['100%']"
				[cols]="['calc((300% / 12) - (10px / 3))', 'calc((600% / 12) - (10px / 3))', 'calc((300% / 12) - (10px / 3))']"
				[gap]="{ rows: 0, cols: 5 }"
				class='full-width'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' style='margin: 5px 0 5px 5px'>
					<kendo-gridlayout
						[rows]="['181.5px', 'calc(100% - 186.5px)']"
						[cols]="['100%']"
						[gap]="{ rows: 5, cols: 0 }"
						class='full-width'
					>
						<kendo-gridlayout-item [row]='1' [col]='1' style='border: 1px solid #ededed; border-radius: 4px;'>
							<app-gauge
								[value]='$performanceFactorScore'
								[colors]='pfColors'
								[label]='label'
							>
							</app-gauge>
						</kendo-gridlayout-item>
						<kendo-gridlayout-item [row]='2' [col]='1' style='border: 1px solid #ededed; border-radius: 4px;'>
							<app-mc-datagrid [$p50Date]="$p50Date"></app-mc-datagrid>
						</kendo-gridlayout-item>
					</kendo-gridlayout>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2'>
					<highcharts-chart
						[Highcharts]="Highcharts"
						[options]="monteCarloChartOptions"
						[(update)]="updatedCharts"
						(chartInstance)="p85HitInstance($event)"
						style="display: block; width: calc(100% - 2px); margin: 5px 0; border: 1px solid #ededed; border-radius: 4px;"
					></highcharts-chart>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='3' style='margin: 5px 5px 5px 0; border: 1px solid #ededed; border-radius: 4px;'>
					<app-pf-table-dropdown *ngIf="$projectData.value?.riskMetricsType === 'performanceFactor'" [resetFilterClicked]='resetFilterClicked' [submitFilterClicked]='submitFilterClicked' (cancelEnabledChange)='cancelChange($event)' (pfTableChange)='pfChange($event)' style='width: 100%'></app-pf-table-dropdown>
					<app-mc-register-selection *ngIf="$projectData.value?.riskMetricsType === 'riskRegister'"></app-mc-register-selection>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
		@if (isOverview && hasNotes) {
			<app-overview-notes [componentId]="19"></app-overview-notes>
		}
	</kendo-gridlayout>
</div>
