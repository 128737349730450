import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RestService } from '../../../../services/common/rest.service';

@Component({
	selector: 'app-project-notes-table',
	templateUrl: './project-notes-table.component.html',
	styleUrls: ['./project-notes-table.component.scss'],
})
export class ProjectNotesTableComponent implements OnInit {
	rows: any[];
	loadingIndicator: boolean;
	reorderable: boolean;

	private _unsubscribeAll: Subject<void>;

	constructor(
		private _httpClient: HttpClient,
		private restService: RestService
	) {
		this.loadingIndicator = true;
		this.reorderable = true;
		this._unsubscribeAll = new Subject<void>();
	}

	//todo: check if admin-project-notes is a thing or not -ms
	ngOnInit(): void {
		this.restService
			.fetch('api/admin-project-notes')
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((projectNotes: any) => {
				this.rows = projectNotes;
				this.loadingIndicator = false;
			});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
