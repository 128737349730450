<div class='card notes-visualizer-anchor regular-container' [class.saas-risk-component]='user?.userType === "saasRisk"'>
	<dialog-edit-notes [data]='editingUpdateData' (closeEvent)='editNotesClosed($event)' [notesRequirement]="notesRequirement" *ngIf='editingUpdateData'/>
	<div *ngIf='!scheduleStorage.isLoading' class='finished-loading-schedules'></div>
	<kendo-gridlayout
		[rows]="['42.4px', 'calc(100% - 42.2px)']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='grid'
		style='page-break-inside: avoid'
		*ngIf='!visualizer'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container'>
			<kendo-gridlayout
				[rows]="['42.4px']"
				[cols]="['180px', 'calc(100% - 185px)']"
				[gap]="{ rows: 0, cols: 5 }"
				class='full-width'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
					<div class="card-header">
						Schedule Updates
					</div>
					<tooltip-open type='schedules-grid'></tooltip-open>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2' class='toolbar-item-container' style='padding-right: 0;'>
					<span style='display: inline-flex; align-items: center;float: right' kendoTooltip>
						<button kendoButton themeColor='base' title="Add Schedule Updates" class='header-btn new-img-18'
										style='margin-right: 10px'
										(click)="openUploadDialog()"
										[disabled]="scheduleStorage.isLoading"
										*ngIf="(user?.userType !== 'saasRisk' || numUpdates === 0) && (projectService.$currentProjectReport | async)?.permissionData?.role !== 'viewer' && !$projectData.value?.isArchived"
										[imageUrl]='"/assets/icons/newIcons/noun-library-add-3643876.svg"'>
						</button>
						<button kendoButton themeColor='base' title="Upgrade to Access More Updates" class='header-btn disabled-btn'
										style='margin-right: 10px'
										[disabled]="scheduleStorage.isLoading || (user?.userType === 'saasRisk' && numUpdates !== 0)"
										*ngIf="user?.userType === 'saasRisk' && numUpdates !== 0"
										imageUrl='https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/add/default/48px.svg'>
						</button>
						<button kendoButton themeColor='base' title="Export Report (XLSX)" class='header-btn'
										style='margin-right: 10px'
										(click)="exportToExcel(grid)"
										[disabled]="scheduleStorage.isLoading"
										*ngIf='currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-APK"'
										[imageUrl]='"/assets/icons/newIcons/excelExport.svg"'>
						</button>
						<div kendoTooltip style="text-align: center;" *ngIf='currentProjectCompanyPermissions?.license === "ANALYTICS-BASIC-MPK" || currentProjectCompanyPermissions?.license === "ANALYTICS-BASIC-APK"'>
							<div title='Upgrade to a Professional License to enable Exports'>
								<button kendoButton themeColor="base" class='header-btn' [imageUrl]='"/assets/icons/newIcons/excelExport.svg"' style='padding-top: 2px; padding-bottom: 5px; margin-right: 10px' [disabled]='true'></button>
							</div>
						</div>
						<div id="columnsBtn" style="position: relative;">
							<button kendoButton themeColor='base' title="Add/Remove Columns" class='header-btn new-img-22'
											style='margin-right: 10px'
											(click)='toggleColumnSelector(columnsPopupAnchor.element)'
											[disabled]="scheduleStorage.isLoading"
											[imageUrl]='"/assets/icons/newIcons/noun-add-column2 1.svg"'
											#columnsPopupAnchor
							>
							</button>
							<kendo-svg-icon *ngIf='columnSelectorOpen' [icon]="icons.caretDown" class="downArrowIcon" style="color: white; position: absolute; left: 8px; margin-top: 25px;"></kendo-svg-icon>
						</div>
						<button kendoButton title="Save Changes" themeColor="none" class='header-btn save-btn'
										style='margin-right: 10px; width: 50px;'
										(click)="saveChanges()"
										[disabled]="scheduleStorage.isLoading || (projectService.queueProcessingChanged | async) !== true && !scheduleStorage.unsavedChanges.size"
										*ngIf='!scheduleStorage.doingSave'
						>
							Save
						</button>
						<button kendoButton title="Saving..." themeColor="none" class='header-btn save-btn' style='margin-right: 10px; width: 50px' *ngIf='scheduleStorage.doingSave'>
							<kendo-circularprogressbar
								[indeterminate]="true"
								[value]="30"
								[progressColor]='"white"'
								id='schedule-list-spin'
							></kendo-circularprogressbar>
						</button>
					</span>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1' *ngIf="scheduleStorage.isLoading">
			<div *ngIf="scheduleStorage.isLoading" style='width: 100%; text-align: center;font-size: 20px'>
				Loading Schedules... {{(scheduleStorage.numUpdatedLoaded | async)}} / {{($projectData | async)?.updateIds?.length}}
			</div>
			<ion-progress-bar *ngIf="scheduleStorage.isLoading"
												[value]='(scheduleStorage.numUpdatedLoaded | async) / (($projectData | async)?.updateIds?.length || 1)'
			></ion-progress-bar>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1' *ngIf="!scheduleStorage.isLoading">
			<kendo-grid
				[kendoGridBinding]="dataSource"
				[rowHeight]='32'
				[detailRowHeight]='30'
				scrollable="virtual"
				(excelExport)="onExcelExport($event)"
				class="schedule-update"
				[rowClass]="rowCallback"
				id='schedules-grid'
				[class.saas-risk-grid]='user?.userType === "saasRisk"'
				[size]='"small"'
				(cellClick)="cellClickHandler($event)"
			>
			<!--	put these 2 lines back in ^this grid to re-enable the row expansion (also uncomment the arrow icon in template below and the rowClass in rowCallback)
				[kendoGridExpandDetailsBy]="expandDetailsBy"
				[(expandedDetailKeys)]="expandedDetailKeys"-->
				<kendo-grid-column
					*ngFor='let col of columns'
					[field]="col.field"
					[title]="col.title"
					[hidden]="hiddenColumns.has(col.field)"
					[width]="col.width"
					headerClass="header-cell"
				>
					<ng-template kendoGridCellTemplate let-rowIndex="rowIndex" let-dataItem *ngIf='col.field === "updateName"'>
						<svg xmlns="http://www.w3.org/2000/svg"
								 width="10"
								 height="10"
								 viewBox="0 0 10 10"
								 kendoTooltip
								 style='position: absolute; top: 0; left: 0;'
								 *ngIf='dataItem.missingUpdate'
						>
							<path d="M 0 0 L 0 10 L 10 0 z" fill="#ff8c00" title='Missing Update'/>
						</svg>
						<svg xmlns="http://www.w3.org/2000/svg"
								 width="10"
								 height="10"
								 viewBox="0 0 10 10"
								 kendoTooltip
								 position='left'
								 style='position: absolute; bottom: 0; left: 0;'
								 *ngIf='dataItem.missingNotes'
						>
							<path d="M 0 0 L 0 10 L 10 10 z" fill="rgba(223, 83, 83, 0.9)" title='Missing Notes'/>
						</svg>
						<span>
							<!--<span class="k-icon k-font-icon"
										*ngIf='rowIndex > 0 && !dataItem?.toAdd && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-APK"'
										[class.k-i-arrow-60-right]='!expandedDetailKeys.includes(dataItem._id)'
										[class.k-i-arrow-60-down]='expandedDetailKeys.includes(dataItem._id)'
										(click)='toggleDetailRow(dataItem)'
							>
							</span>-->
							{{rowIndex === 0 ? 'Baseline' : ('Update ' + rowIndex)}}
						</span>
						@if (dataItem.baseline && rowIndex > 0) {
							<span kendoTooltip title='Re-baseline'>
								®
							</span>
						}
						@if (dataItem?.isBecomingCostBaseline || dataItem?.wasCostBaseline || dataItem?.isCostBaseline) {
							<span kendoTooltip [title]='dataItem?.isBecomingCostBaseline ? "Setting as Cost Baseline" : dataItem?.wasCostBaseline ? "No Longer Cost Baseline" :"Cost Baseline"' class="cost-baseline-icon" [style.color]="dataItem?.isBecomingCostBaseline ? '#2ebb2e' : dataItem?.wasCostBaseline ? '#dd5f60' : 'black'">
								$
							</span>
						}
					</ng-template>
					<ng-template kendoGridCellTemplate let-dataItem *ngIf='col.date'>
						<span class='centered-data'>
							{{validDate(dataItem[col.field]) ? ((dataItem[col.field] | cleanDateUTC: 'MMM dd, yyyy')) : ''}}
						</span>
					</ng-template>

					<ng-template kendoGridCellTemplate let-dataItem *ngIf='col.isNumber'>
						<span class='centered-data'>
							{{dataItem[col.field]}}
						</span>
					</ng-template>

					<ng-template kendoGridCellTemplate let-dataItem *ngIf='col.title === "Finish Milestone"'>
						<span class='centered-data'>
							{{dataItem?.finishMilestone?.task_code}}
						</span>
					</ng-template>

					<ng-template kendoGridCellTemplate let-dataItem *ngIf='col.field === "fileName"'>
						<a target='_blank' class='file-col' [title]='dataItem["fileName"]' (click)='requestDownload(dataItem["_id"], dataItem["updateFromSync"] ? dataItem["scheduleName"] + ".xer" : dataItem["fileName"])' class='a-link'>
							{{ dataItem["updateFromSync"] ? dataItem["scheduleName"] + ".xer" : dataItem["fileName"]}}
						</a>
					</ng-template>

					<ng-template kendoGridCellTemplate let-dataItem *ngIf='col.field === "report"'>
						<span class='centered-data'>
							<button kendoButton fillMode="clear" [disabled]='dataItem.toAdd' (click)='openReport(dataItem, dataItem["index"])' (focus)='openReport(dataItem, dataItem["index"])' style="padding: 2px 4px;">
								<kendo-svg-icon [icon]="icons.fileReport" style="height: 24px; width: 24px; color: dodgerblue;"></kendo-svg-icon>
							</button>
						</span>
					</ng-template>

					<ng-template kendoGridCellTemplate let-dataItem *ngIf='col.field === "actions"' let-rowIndex="rowIndex">
						<span class='centered-data action-col' style='gap: 5px'>
							<button kendoButton fillMode='flat' title='edit' [svgIcon]='icons.pencilIcon' (click)='openNotes(dataItem, rowIndex)' [style.color]='dataItem["criticalPathNotes"]?.length || dataItem["timeAnalysisNotes"]?.length ? "dodgerblue" : "grey"' class='larger-btn-icons'></button>
							<button kendoButton fillMode='flat' title='delete' [svgIcon]='icons.trashIcon' (click)='setUpdateDelete(dataItem, true)' *ngIf="!dataItem['toDelete']" class='larger-btn-icons' style='color: #f31700;'></button>
							<button kendoButton fillMode='flat' title='undo' [svgIcon]='icons.undoIcon' (click)='setUpdateDelete(dataItem, false)' *ngIf="dataItem['toDelete']" class='larger-btn-icons' style='color: dodgerblue;'></button>
						</span>
					</ng-template>
				</kendo-grid-column>
				<kendo-grid-excel>
					<kendo-excelexport-column
						title="Update"
						field='updateName'
					>
						<ng-template kendoGridCellTemplate let-rowIndex="rowIndex" let-dataItem>
							{{rowIndex === 0 ? 'Baseline' : ('Update ' + rowIndex) + (dataItem.isRebaseline && rowIndex > 0) ? '®' : ''}}
            </ng-template>
					</kendo-excelexport-column>
					<kendo-excelexport-column
						field="scheduleName"
						title="Schedule Name"
					>
					</kendo-excelexport-column>
					<kendo-excelexport-column
						title="Data Date"
						field='dataDate'
						[width]="100"
					>
					</kendo-excelexport-column>
					<kendo-excelexport-column
						field="currentCompletionDate"
						title="Current Completion"
						[width]="150"
					>
					</kendo-excelexport-column>
					<kendo-excelexport-column
						title="Contract Completion"
						field='contractCompletionDate'
						[width]="150"
					>
					</kendo-excelexport-column>
					<kendo-excelexport-column
						title="Contract Δ"
						field='contractVariance'
					>
					</kendo-excelexport-column>
					<kendo-excelexport-column
						field="previousVariance"
						title="Update Δ"
					>
					</kendo-excelexport-column>
					<!--<kendo-excelexport-column
						field="cpli"
						title="CPLI"
					>
					</kendo-excelexport-column>-->
					<!--<kendo-excelexport-column
						field="tfci"
						title="TCFI"
					>
					</kendo-excelexport-column>-->
					<kendo-excelexport-column
						field="progressImpact"
						title="Progress Impact"
					>
					</kendo-excelexport-column>
					<kendo-excelexport-column
						field="logicImpact"
						title="Logic Impact"
					>
					</kendo-excelexport-column>
					<kendo-excelexport-column
						field="drivingCriticalActivity"
						title="Driving Critical Activity"
					>
					</kendo-excelexport-column>
					<kendo-excelexport-column
						field="criticalPathNotes"
						title="Critical Path Notes"
						[width]="320"
					>
					</kendo-excelexport-column>
					<kendo-excelexport-column
						field="timeAnalysisNotes"
						title="Time Analysis Notes"
						[width]="320"
					>
					</kendo-excelexport-column>
				</kendo-grid-excel>
				<ng-template kendoGridDetailTemplate let-dataItem let-rowIndex="rowIndex" class='delay-container' *ngIf='currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-APK"'>
					<app-schedule-delays
					></app-schedule-delays>
				</ng-template>
			</kendo-grid>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
</div>

<ng-template #columnSelectorPopupTemplate>
	<div id='columnSelectorContainer'>
		<div class='columns-btns-container'>
			<button
				kendoButton
				[size]="'small'"
				fillMode="outline"
				[rounded]="'medium'"
				[themeColor]="'info'"
				(click)='resetColumns()'
				style='display: block; width: 85%;'
			>
				Reset
			</button>
			<button
				kendoButton
				[size]="'small'"
				[rounded]="'medium'"
				fillMode="outline"
				[themeColor]="'primary'"
				style='display: block; width: 15%;'
				(click)="closeColumns()"
			>
				X
			</button>
		</div>
		<div style='height: 300px; overflow: auto;'>
			<kendo-treeview
				[nodes]="nonActionColumns"
				[children]="children"
				[hasChildren]="hasChildren"
				[isDisabled]='isDisabled'
				textField="title"
				[kendoTreeViewCheckable]="{checkParents: true}"
				[(checkedKeys)]="visibleColumns"
				class="schedule-grid-update-treeview"
			>
			</kendo-treeview>
		</div>
	</div>
</ng-template>
