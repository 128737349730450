import { NgModule } from '@angular/core';
import { UploadPictureComponent } from './upload-picture.component';
import { GridLayoutModule } from '@progress/kendo-angular-layout';
import { ImageCropperModule } from 'ngx-image-cropper';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@progress/kendo-angular-buttons';

@NgModule({
	declarations: [UploadPictureComponent],
	exports: [UploadPictureComponent],
	imports: [GridLayoutModule, ImageCropperModule, IonicModule, CommonModule, ButtonModule],
})
export class UploadPictureModule {}
