import { Injectable } from '@angular/core';
import { RestService } from 'services/common/rest.service';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '../auth/authentication.service';
import { AccountUser, isUserDifferent, PortfolioPreset, UserInterface } from '../../models/auth/account-user';
import {
	Child,
	Client,
	CompanyInterface,
	Entry,
	HierarchyResponse,
	Member,
	MembersResponse,
} from '../../models/company';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	user = new BehaviorSubject<UserInterface>(null);
	email: string = '';
	name: string = '';
	isFirstLoad: boolean = false;
	companies = new BehaviorSubject<Map<string, HierarchyResponse>>(undefined);
	allCompanies = new BehaviorSubject<Map<number, Entry>>(undefined);
	allChildren = new BehaviorSubject<Map<number, HierarchyResponse>>(undefined);
	clients = new BehaviorSubject<Map<string, Client>>(undefined);
	newCompanyNoClients = false; //boolean used to stop infinitely spinning loader on new project form client field
	members = new BehaviorSubject<Map<number, Member & { displayName: string }>>(undefined);
	companyMembers = new BehaviorSubject<Map<number, Member & { displayName: string }>>(undefined);
	private userInfo: UserInterface | any = {};
	presetDeleteConfirmOpen: boolean = false;
	presetQuickSaveOpen: boolean = false;
	$updateLayoutList: BehaviorSubject<PortfolioPreset[]> = new BehaviorSubject<PortfolioPreset[]>(null);
	_userLayouts: PortfolioPreset[] = [];
	constructor(
		private restService: RestService,
		private authService: AuthenticationService
	) {
		this.user.subscribe((user: UserInterface) => {
			if (user?.companyIds?.length) {
				restService
					.postAccount('v2/my/company/members/', undefined, undefined, { companyId: user.companyIds })
					.subscribe((results: MembersResponse) => {
						const newMembers = new Map<number, Member & { displayName: string }>([]);
						const allCompanyMembers = new Map<number, Member & { displayName: string }>([]);
						for (const member of results.members) {
							const displayName = `${member.account.first_name} ${member.account.last_name}`.trim();
							if (displayName.length) {
								newMembers.set(member.account.id, {
									...member,
									displayName,
								});
								allCompanyMembers.set(member.id, {
									...member,
									displayName,
								});
							}
						}
						this.companyMembers.next(allCompanyMembers);
						this.members.next(newMembers);
					});
				restService
					.postAccount('v2/my/company/hierarchy/', undefined, undefined, { companyId: user.companyIds })
					.subscribe((results: Array<Child>) => {
						const newCompanies = new Map<string, Child>([]);
						const companies: Array<Child> = results || [];
						const clients = new Map<string, Client>([]);
						const allChildren = new Map<number, HierarchyResponse>([]);
						for (const company of companies) {
							newCompanies.set(company.entry.analyticsId, company);
						}
						const entryStack: Array<Child> = companies;
						const allEntries = new Map<number, Entry>([]);
						while (entryStack.length > 0) {
							const entry = entryStack.pop();
							allChildren.set(entry.entry.id, entry);
							if (!allEntries.has(entry.entry.id)) {
								allEntries.set(entry.entry.id, entry.entry);
								const children = entry.children || [];
								for (const child of children) {
									entryStack.push(child);
								}
								const entryClients = entry.clients || [];
								for (const client of entryClients) {
									clients.set(client.id, client);
								}
							}
						}
						this.allChildren.next(allChildren);
						this.companies.next(newCompanies);
						this.allCompanies.next(allEntries);
						this.clients.next(clients);
						if (clients.size === 0) {
							this.newCompanyNoClients = true;
						}
					});
			}
		});
	}

	getUserByEmail(email: string, layoutUpdateOnly: boolean = false): void {
		this.saveUserEmail(email);
		const url = `user/email/${email}`;
		this.restService.fetch(url).subscribe(
			(success) => {
				if (!layoutUpdateOnly && isUserDifferent(this.user.value, success.user)) {
					this.user.next(success.user);
				}
				if (layoutUpdateOnly) {
					this.$updateLayoutList.next(success.user?.portfolioPresets);
				}
				this.userInfo = success.user;
				this.saveUser(this.userInfo);
			},
			(error) => {
				console.log('error', error);
			}
		);
	}

	saveUserEmail(email: string): void {
		localStorage.setItem('currentUserEmail', email);
	}

	saveUser(user: any): void {
		localStorage.setItem('currentUser', JSON.stringify(user));
	}

	loadFromLocalStorage(): void {
		let user: any = localStorage.getItem('currentUserCredentials');
		let email = localStorage.getItem('currentUserEmail');
		email = email === 'undefined' && user ? JSON.parse(user)?.username : email;
		this.email = email;

		this.authService.accountUser = user as AccountUser;

		// const user: any = localStorage.getItem('currentUser') || {};
		if (user === undefined || user === null || user === 'undefined') {
			user = '{}';
		}

		if (email && user) {
			this.userInfo = JSON.parse(user);
			const parsedUser = JSON.parse(user);
			this.name = parsedUser?.profile?.name;
			this.getUserByEmail(email);
		} else {
			this.user.next(null);
		}
	}

	addNewPortfolioPreset(newPreset: PortfolioPreset): void {
		const user: UserInterface = this.user.getValue();
		const existingPresets: PortfolioPreset[] = this._userLayouts;
		const newPortfolioPreset: PortfolioPreset[] =
			existingPresets === undefined ? [newPreset] : [...existingPresets, newPreset];
		this.updatePortfolioPresets(newPortfolioPreset, user.emails[0]);
	}

	editPortfolioPreset(portfolioPresets: PortfolioPreset[]): void {
		const user: UserInterface = this.user.getValue();
		this.updatePortfolioPresets(portfolioPresets, user.emails[0]);
	}

	deletePortfolioPreset(preset: PortfolioPreset): void {
		const user: UserInterface = this.user.getValue();
		const existingPresets: PortfolioPreset[] = this._userLayouts;
		const newPresets: PortfolioPreset[] = existingPresets.filter((p) => p.id !== preset.id);
		this.updatePortfolioPresets(newPresets, user.emails[0]);
		this.presetDeleteConfirmOpen = false;
	}

	updatePortfolioPresets(portfolioPresets: PortfolioPreset[], email: string): void {
		this.restService.patch('user', { portfolioPresets, email }).subscribe((val) => {
			this.getUserByEmail(val?.user?.profile?.email, true);
			this._userLayouts = val?.user?.portfolioPresets || [];
		});
	}

	isAdmin(): boolean {
		return this.userInfo.isSuperAdmin;
	}
}
