import { Pipe, PipeTransform } from '@angular/core';
import { format, isValid } from 'date-fns';

//const formatInTimeZone = (date, fmt, tz) => format(utcToZonedTime(date, tz), fmt, { timeZone: tz });

@Pipe({
	name: 'cleanDateUTC',
})
export class DatePipe implements PipeTransform {
	transform(value: unknown, dateFormat: string = 'MM/dd/yyyy'): unknown {
		return datePipe(value, dateFormat);
	}
}

export const cleanDateUTC = (date: Date | string, formatting: string): string =>
	isValid(new Date(date)) ? format(date, formatting) : '';

export const datePipe = (value: unknown, dateFormat: string = 'MM/dd/yyyy'): unknown => {
	if (typeof value === 'string' || typeof value === 'number' || value instanceof Date) {
		const valueDate = new Date(value);
		if (isValid(valueDate)) {
			return cleanDateUTC(valueDate, dateFormat);
		}
	}
	return null;
};
