import { Pipe, PipeTransform } from '@angular/core';

export interface PctPipeArgs {
	append: string;
}

@Pipe({
	name: 'pct',
})
export class PctPipe implements PipeTransform {
	transform(value: number, ...append: string[]): number {
		if (value > 0 && value <= 1) {
			return 100 * value;
		}
		return value;
	}
}
