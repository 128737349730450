<kendo-arcgauge
	#gauge
	[value]="_value"
	[colors]="colors"
	[scale]="{ min: 0, max: 100, rangeSize: 50, rangeLineCap: 'butt', rangePlaceholderColor: '#dedede' }"
	[transitions]='true'
	class='gauge'
	[style.height]='height'
>
	<ng-template
		kendoArcGaugeCenterTemplate
		let-value="value"
		let-color="color"
	>
		<span [style.color]="color" class='gauge-label' [style.font-size]='valueFontSize'>{{ value | number: '1.0-0' }}%</span>
		<div *ngIf='label' style='font-weight: bold' [style.font-size]='labelFontSize'>{{ label }}</div>
	</ng-template>
</kendo-arcgauge>
