import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OverwriteResults, RiskRegister } from '../../../../../../models/risk';

@Component({
	selector: 'app-overwrite-dialog',
	templateUrl: './overwriteDialog.component.html',
	styleUrls: ['./overwriteDialog.component.scss'],
})
export class OverwriteDialogComponent implements OnInit {
	@Input() conflictingRisks: { analyticsRisk: RiskRegister; importedRisk: RiskRegister }[];
	@Output() confirm = new EventEmitter<OverwriteResults[]>();
	overwriteChoices: OverwriteResults[] = [];

	ngOnInit() {
		this.conflictingRisks.forEach((risk) => {
			this.overwriteChoices.push({
				importedRiskId: risk.importedRisk.riskId.toString(),
				importedRiskName: risk.importedRisk.riskName,
				matchingAnalyticsRiskId: risk.analyticsRisk.riskId,
				matchingAnalyticsRiskName: risk.analyticsRisk.riskName,
				decision: 'saveAsNew',
			});
		});
	}

	/**
	 * let import component know the confirmed choices
	 */
	confirmChoices() {
		this.confirm.emit(this.overwriteChoices);
	}
}
