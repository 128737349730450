import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsDashboardService } from '../services/analytics/analytics.service';

const routes: Routes = [
	{
		path: 'portfolio',
		loadChildren: () => import('./portfolio/portfolio.module').then((m) => m.PortfolioModule),
	},
	{
		path: 'project/:id',
		loadChildren: () => import('./project-page/project.module').then((m) => m.ProjectModule),
		resolve: {
			data: AnalyticsDashboardService,
		},
	},
	{
		path: 'auth',
		loadChildren: () => import('./authentication/auth.module').then((m) => m.AuthModule),
	},
	{
		path: '**',
		redirectTo: 'auth/login',
	},
];
@NgModule({
	imports: [RouterModule.forRoot(routes), CommonModule],
	exports: [RouterModule],
	providers: [],
})
export class AppRoutingModule {}
