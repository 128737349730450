import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ScheduleStorageService } from '../../../services/project/schedule-storage.service';
import { CurrentProjectReport, ProjectDashboardService } from '../../../services/project/project.service';
import { Activity, Xer, XerActivity, XerActivityType, XerData } from '@rhinoworks/xer-parse';
import { DropDownTreeComponent, DropDownTreesModule } from '@progress/kendo-angular-dropdowns';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { ActvCodeFilterItem, SubCodeFilterItem } from '../overview/activity-completion/activity-completion.component';
import { AsyncPipe, DecimalPipe, NgIf } from '@angular/common';
import { caretAltDownIcon } from '@progress/kendo-svg-icons';
import {
	ExpandedMetrics,
	projectPredictability,
	ProjectReportInterface,
	TotalFloatIndexArgs,
} from '@rhinoworks/analytics-calculations';
import { ActivityCompletionModule } from '../overview/activity-completion/activity.completion.module';
import { ProjectOverviewModule } from '../overview/project-overview.module';
import { DrivingPathComponent, MilestoneTreeItem } from '../schedule-updates-list/driving-path/driving-path.component';
import { haveCommonItem } from '../../../util/strings';
import { ExpansionPanelComponent, ExpansionPanelModule, GridLayoutModule } from '@progress/kendo-angular-layout';
import { TooltipMenuModule } from '../../portfolio/tooltip-menu/tooltip-menu.module';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { RestService } from '../../../services/common/rest.service';
import { hasObjChanged } from '../../../util/projects';
import { actvHasCode } from '../../../util/tasks';
import { UpdateInterface } from '../../../models/Update/Task';
import { QcPageModule } from '../qc/qc-page.module';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { forkJoin, from } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { WebWorkerService } from '../../../services/common/web-worker.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { TreeItem } from '@progress/kendo-angular-treeview';
import { IndexTrendingModule } from '../risk/index-trending/index-trending.module';
import { RiskCriticalPathReliabilityModule } from '../risk/risk-critical-path-reliability/risk-critical-path-reliability.module';
import { RiskCriticalPathRiskModule } from '../risk/risk-critical-path-risk/risk-critical-path-risk.module';
import { NavigationBarStorageService } from '../../../services/common/navigation-bar-storage.service';
import { isAfter } from 'date-fns';
import { Task } from '../../../models/ChartSettings';

@Component({
	selector: 'app-focus',
	standalone: true,
	imports: [
		DropDownTreesModule,
		SVGIconModule,
		AsyncPipe,
		ActivityCompletionModule,
		ProjectOverviewModule,
		DrivingPathComponent,
		GridLayoutModule,
		TooltipMenuModule,
		TooltipModule,
		ButtonModule,
		NgIf,
		ExpansionPanelModule,
		DecimalPipe,
		QcPageModule,
		IndicatorsModule,
		IndexTrendingModule,
		RiskCriticalPathReliabilityModule,
		RiskCriticalPathRiskModule,
	],
	templateUrl: './focus.component.html',
	styleUrl: './focus.component.scss',
})
export class FocusComponent implements OnInit {
	icons = {
		caretDown: caretAltDownIcon,
	};
	allActivityCodes: ActvCodeFilterItem[] = [];
	selectedActivityCodes: SubCodeFilterItem[] = [];
	latestSelection: ActvCodeFilterItem[] = [];
	selectedActivityCodesForPanel: Array<
		SubCodeFilterItem & {
			expanded: boolean;
			progressScore: number;
			qcScore: number;
			riskScore: number;
			actvCompletion: number;
			floatConsumption: string;
			floatConsumptionIsLarger: boolean;
			actvCodes: string[];
		}
	> = [];
	openPanel: SubCodeFilterItem;
	codesTag: string = '';
	progressScore: number = 0;
	qcScore = 0;
	riskScore = 0;
	latestXer: Xer;
	lastActivityCode: string;
	loading: boolean = true;
	hasChanges: boolean = false;
	@ViewChildren('expansionPanels') panels: QueryList<ExpansionPanelComponent>;
	reportByCode = new Map<string, [ProjectReportInterface, ExpandedMetrics]>([]);
	isScreenshotMode: boolean = false;
	allCodeKeys = new Set<string>();
	public groupedActivities: Record<string, MilestoneTreeItem[]> = {};
	public selectedFinishMilestones: Record<string, XerActivity & { entry?: { name: string; id: number } }> = {};
	public selectedFinishMilestonesEncoded: Record<string, string> = {};
	public isLoading = false;
	expandedNodes: number[] = [];
	existingGanttData: Record<string, { minDate: Date; maxDate: Date; data: Task[]; code: string; dataDate: Date }> = {};

	constructor(
		public scheduleService: ScheduleStorageService,
		public projectService: ProjectDashboardService,
		public restService: RestService,
		public webWorkerService: WebWorkerService,
		public navBarStorage: NavigationBarStorageService
	) {}

	ngOnInit() {
		const updateIds = this.projectService.$currentProjectData.value.updateIds;
		const updateXerObservable = this.scheduleService
			.grabUpdateXer(updateIds[updateIds.length - 1])
			.then(async (xer) => {
				this.latestXer = xer;
				const selectedActivityCodes: SubCodeFilterItem[] = [];
				const savedActCodes: string[] = this.projectService.$currentProjectData.value?.focusTabSelectedCodes || [];
				this.allActivityCodes = this.scheduleService.generateActivityCodeFilter(xer, this.selectedActivityCodes);
				const codes: SubCodeFilterItem[] = [];
				this.allActivityCodes.forEach((type: ActvCodeFilterItem) => {
					type.subCodes.forEach((code: SubCodeFilterItem) => {
						if (
							codes.findIndex(
								(c: SubCodeFilterItem) => c.parentId === code.parentId && c.shortName === code.shortName
							) === -1
						) {
							codes.push(code);
						}
					});
					const copyOfType = structuredClone<SubCodeFilterItem>({
						...type,
						parentName: undefined,
					});
					codes.push(copyOfType);
				});
				const availableActivitiesByCode = new Map<string, Array<XerActivity & { display: string; taskType: string }>>(
					[]
				);
				for (const [codeId, activities] of xer.tasksByActivityCodes) {
					const code = xer.activityCodes.get(codeId);
					const actvOptions = Array.from(activities).map((task) => ({
						...task.raw_entry,
						display: `${task.code} - ${task._activity.activityName}`,
						taskType: task._activity.taskType === 'TT_FinMile' ? 'Finish Milestones' : 'All Activities',
						entry: {
							id: task.id,
							name: task.code + ' - ' + task.raw_entry.task_name,
						},
					}));
					availableActivitiesByCode.set(code.codeName + '--//--' + code.shortName, actvOptions);
					const groupedActivities: GroupResult[] = groupBy(actvOptions, [
						{ field: 'taskType' },
					]).reverse() as GroupResult[];
					this.groupedActivities[code.codeName + '%--//--%' + code.shortName] = [
						{
							entry: {
								id: 0,
								name: 'Finish Milestones',
							},
							children: (groupedActivities.find((i) => i.value === 'Finish Milestones')?.items || []) as XerActivity[],
						},
						{
							entry: {
								id: 1,
								name: 'All Activities',
							},
							children: (groupedActivities.find((i) => i.value === 'All Activities')?.items || []) as XerActivity[],
						},
					];
					let latestActv: XerActivity & { display: string; taskType: string };
					let latestFin: Date;
					let latestActvMile: XerActivity & { display: string; taskType: string };
					let latestFinMile: Date;
					let usePref: boolean = false;
					for (const task of actvOptions) {
						if (
							this.projectService.$currentProjectData.value.preferences?.focusMilestones?.[
								code.codeName + '%--//--%' + code.shortName
							] === task.task_code
						) {
							latestActv = task;
							usePref = true;
							break;
						}
						const earlyFin = task.early_end_date || task.reend_date;
						if (task.task_type === 'TT_FinMile') {
							if (earlyFin && (!latestFinMile || isAfter(new Date(earlyFin), latestFinMile))) {
								latestActvMile = task;
								latestFinMile = new Date(earlyFin);
							}
						} else {
							if (earlyFin && (!latestFin || isAfter(new Date(earlyFin), latestFin))) {
								latestActv = task;
								latestFin = new Date(earlyFin);
							}
						}
					}
					this.selectedFinishMilestones[code.codeName + '%--//--%' + code.shortName] =
						latestActvMile && !usePref ? latestActvMile : latestActv;
					this.selectedFinishMilestonesEncoded[code.codeName + '%--//--%' + code.shortName] =
						latestActvMile && !usePref
							? latestActvMile.task_code.replace(/\./g, '__dot__')
							: latestActv.task_code.replace(/\./g, '__dot__');
				}
				for (const code of savedActCodes) {
					await this.getMatchingCode(code, codes).then((val: SubCodeFilterItem) => {
						if (val) {
							selectedActivityCodes.push(val);
						}
					});
				}
				this.allCodeKeys = new Set(codes.map((ac) => ac.codeName + '%--//--%' + ac.shortName));
				this.selectedActivityCodes = selectedActivityCodes;
				const projectData = this.projectService.$currentProjectData.value;
				const xers: Xer[] = [];
				const updates = this.scheduleService.$allUpdates.value || [];
				for (let i = Math.max(0, updateIds.length - 3); i < updateIds.length; i++) {
					await this.scheduleService.grabCalendar(updateIds[i]);
					xers[i] = new Xer(this.scheduleService.cachedXerData.get(updateIds[i]));
				}
				this.selectedActivityCodesForPanel = this.selectedActivityCodes
					.filter((code: SubCodeFilterItem) => code?.parentName !== undefined)
					.map((c: SubCodeFilterItem) => {
						return {
							...c,
							expanded: false,
							progressScore: undefined,
							qcScore: undefined,
							riskScore: undefined,
							actvCompletion: null,
							floatConsumption: '',
							floatConsumptionIsLarger: false,
							actvCodes: [],
						};
					});
				const tagCodes: ActvCodeFilterItem[] = selectedActivityCodes.map((c: SubCodeFilterItem) => ({
					...c,
					subCodes: c.parentName === undefined ? [null, null] : [],
				}));
				this.latestSelection = tagCodes;
				this.updateTagText(tagCodes);
				this.loading = false;
				this.checkForChanges();
			});
		forkJoin([
			from(updateXerObservable),
			this.scheduleService.$allUpdates.pipe(
				filter((updates) => updates && updates.length > 0),
				take(1)
			),
			this.projectService.$currentProjectReport.pipe(
				filter((report) => !!report),
				take(1)
			),
		]).subscribe(() => {
			this.updateScores();
			this.loading = false;
		});
	}

	public itemDisabledFinMileDropdown(dataItem: MilestoneTreeItem, index: string) {
		return dataItem?.children?.length === 0;
	}

	/**
	 * A function that checks whether a given node index exists in the expanded keys collection.
	 * If the item ID can be found, the node is marked as expanded.
	 */
	public isNodeExpanded = (node): boolean => this.expandedNodes.indexOf(node.entry.id) !== -1;

	/**
	 * A `nodeCollapse` event handler that will remove the node data item ID
	 * from the collection, collapsing its children.
	 */
	public handleCollapse(args: TreeItem): void {
		this.expandedNodes = this.expandedNodes.filter((id) => id !== args.dataItem.entry.id);
	}

	/**
	 * An `nodeExpand` event handler that will add the node data item ID
	 * to the collection, expanding its children.
	 */
	public handleExpand(args: TreeItem): void {
		this.expandedNodes = this.expandedNodes.concat(args.dataItem.entry.id);
	}

	/**
	 * converts saved code to current update code if available.
	 * codeString stored in format activityTypeId + /%%/ + updateId + /%%/ + activityCode's shortName
	 * @param codeString
	 * @param codes
	 */
	async getMatchingCode(codeString: string, codes: SubCodeFilterItem[]): Promise<SubCodeFilterItem> {
		const split: string[] = codeString.split('/%%/');
		if (split?.length === 1) {
			return this.getCode(split[0], codes);
		} else if (split?.length === 3) {
			const activityTypeId: number = +split[0];
			const savedUpdateId: string = split[1];
			const shortCode: string = split[2];
			const updateIds: string[] = this.projectService.$currentProjectData.value.updateIds;
			const currentUpdateId: string = updateIds[updateIds.length - 1];
			if (savedUpdateId !== currentUpdateId) {
				const currentActvTypes: XerActivityType[] = await this.scheduleService.grabUpdateTable<XerActivityType>(
					currentUpdateId,
					'ACTVTYPE'
				);
				const savedActvTypes: XerActivityType[] = await this.scheduleService.grabUpdateTable<XerActivityType>(
					savedUpdateId,
					'ACTVTYPE'
				);
				const savedActvType: XerActivityType = savedActvTypes.find(
					(t: XerActivityType) => t.actv_code_type_id === activityTypeId
				);
				if (savedActvType) {
					const currentActvType: XerActivityType = currentActvTypes.find(
						(t: XerActivityType) =>
							t.actv_code_type === savedActvType.actv_code_type &&
							t.actv_code_type_scope === savedActvType.actv_code_type_scope
					);
					if (currentActvType) {
						const codesForType: SubCodeFilterItem[] = codes.filter(
							(c: SubCodeFilterItem) => c.parentId === currentActvType.actv_code_type_id
						);
						return codesForType.find((c: SubCodeFilterItem) => c.shortName === shortCode);
					}
				}
			} else {
				const codesForType: SubCodeFilterItem[] = codes.filter((c: SubCodeFilterItem) => c.parentId === activityTypeId);
				return codesForType.find((c: SubCodeFilterItem) => c.shortName === shortCode);
			}
		}
		return undefined;
	}

	getCode(code: string, codes: SubCodeFilterItem[]): SubCodeFilterItem {
		return codes.find((c: SubCodeFilterItem) => c.shortName === code);
	}

	async updateScores(
		codes: Array<
			SubCodeFilterItem & {
				expanded: boolean;
				progressScore: number;
				qcScore: number;
				riskScore: number;
				actvCompletion: number;
				floatConsumption: string;
				floatConsumptionIsLarger: boolean;
			}
		> = this.selectedActivityCodesForPanel
	) {
		this.isLoading = true;
		const report: CurrentProjectReport = this.projectService.$currentProjectReport.value;
		const updates: UpdateInterface[] = this.scheduleService.$allUpdates.value;
		const updateId = updates[updates.length - 1]?._id;
		const prevUpdateId = updates?.length < 2 ? null : updates[updates.length - 2]?._id;
		const prevXer = updates?.length < 2 ? null : await this.scheduleService.grabUpdateXer(prevUpdateId);
		const currXer = await this.scheduleService.grabUpdateXer(updateId);
		const prevActvByCode = updates?.length < 2 ? null : prevXer.tasksByActivityCodes;
		const currActvByCode = currXer.tasksByActivityCodes;
		await this.scheduleService.grabCalendar(updates[0]._id);
		const xers: XerData[] = [this.scheduleService.cachedXerData.get(updates[0]._id)];
		for (let i = Math.max(0, updates.length - 3); i < updates.length; i++) {
			await this.scheduleService.grabCalendar(updates[i]._id);
			xers[i] = this.scheduleService.cachedXerData.get(updates[i]._id);
		}
		codes.forEach(
			(
				code: SubCodeFilterItem & {
					expanded: boolean;
					progressScore: number;
					qcScore: number;
					riskScore: number;
					actvCompletion: number;
					floatConsumption: string;
					floatConsumptionIsLarger: boolean;
				},
				index
			) => {
				const prevCode =
					updates?.length < 2
						? null
						: Array.from(prevXer.activityCodes.values()).find(
								(a) => a.shortName === code.shortName && a.codeName === code.codeName
							);
				const currCode = Array.from(currXer.activityCodes.values()).find(
					(a) => a.shortName === code.shortName && a.codeName === code.codeName
				);
				const selectedActivityCodes = [{ ...code }];
				const updateFloatHistoricalByCode: Record<string, TotalFloatIndexArgs> = report?.actvCodeFloatHistorical?.length
					? report?.actvCodeFloatHistorical[report?.actvCodeFloatHistorical?.length - 1]
					: null;
				const prevUpdateFloatHistoricalByCode: Record<string, TotalFloatIndexArgs> =
					updates?.length < 2
						? null
						: report?.actvCodeFloatHistorical?.length > 1
							? report?.actvCodeFloatHistorical[report?.actvCodeFloatHistorical?.length - 2]
							: undefined;
				const recordKey: string = code.codeName + '%--//--%' + code.shortName;
				let totalFloatAllActivities: number = 0;
				let prevTotalFloat: number = 0;
				let prevTotalIncomplete: number = 0;
				let totalIncompleteActivities: number = 0;
				const filteredTasks = currActvByCode.get(currCode?.id);
				const filteredPrevTasks =
					updates?.length < 2
						? null
						: Array.from(prevActvByCode.get(prevCode?.id) || []).filter((t) =>
								filteredTasks.has(currXer.activitiesByCode.get(t.code))
							);
				if (filteredTasks && filteredTasks.size > 0) {
					for (const task of filteredTasks) {
						const totalFloat: number = task._activity.totalFloatHrs;
						if (task.finish || totalFloat === undefined) {
							continue;
						}
						totalFloatAllActivities += Math.round(task._activity.totalFloatHrs / 8);
						totalIncompleteActivities += 1;
					}
					if (filteredPrevTasks !== null) {
						for (const task of filteredPrevTasks) {
							if (task.finish || task._activity.totalFloatHrs === undefined) {
								continue;
							}
							prevTotalFloat += Math.round(task._activity.totalFloatHrs / 8);
							prevTotalIncomplete += 1;
						}
					}
				}
				const currentFloat: number = Math.round(totalFloatAllActivities / (totalIncompleteActivities || 1));
				const prevFloat: number =
					updates?.length < 2
						? null
						: this.projectService.$currentProjectData.value?.updateIds?.length > 1
							? Math.round(prevTotalFloat / (prevTotalIncomplete || 1))
							: null;
				const floatDifference: number =
					prevFloat === null ? null : Math.abs(Math.round((currentFloat / prevFloat - 1) * 100));
				code.floatConsumption = isNaN(floatDifference)
					? currentFloat < prevFloat
						? '-100%'
						: '100%'
					: floatDifference === null || floatDifference === Infinity || floatDifference === -Infinity
						? null
						: currentFloat > prevFloat
							? '+' + floatDifference + '%'
							: (currentFloat < prevFloat ? '-' : '') + floatDifference + '%';
				code.floatConsumptionIsLarger = currentFloat > prevFloat;
				const nonCompletedFilteredTasks = filteredTasks ? Array.from(filteredTasks).filter((t) => !t.finish) : [];
				code.alwaysDisabled = filteredTasks?.size === 0;
				code.disabled = nonCompletedFilteredTasks?.length === 0;
				if (this.reportByCode.has(code.codeName + '%--//--%' + code.shortName)) {
					const report = this.reportByCode.get(code.codeName + '%--//--%' + code.shortName)[0];
					code.progressScore = report.progressScore;
					code.qcScore = report.qualityControl.qcScore;
					const projectReport = this.projectService.$currentProjectReport.value;
					code.riskScore = projectPredictability(
						projectReport.riskPage.criticalPathRiskByCode[code.codeName + '%--//--%' + code.shortName]?.overallScore ??
							report.riskPage.criticalPathRisk.overallScore,
						projectReport.riskPage.criticalPathReliabilityByCode[
							this.selectedFinishMilestonesEncoded[code.codeName + '%--//--%' + code.shortName]
						]?.overallScore || report.riskPage.criticalPathReliability.overallScore,
						report.calculationFields?.CPLI || 0,
						report.calculationFields?.BEI || 0,
						report.riskPage?.performanceFactor?.overallScore
					);

					const actvC = report.activityCompletionGraph.projectTrendGraph.totalCompletionPercentageArray;
					code.actvCompletion = actvC[actvC.length - 1];
				} else if (!code.disabled) {
					const projectData = this.projectService.$currentProjectData.value;

					const drivingSwitch = this.selectedFinishMilestones[code.codeName + '%--//--%' + code.shortName];

					this.webWorkerService
						.generateReport({
							reportGenerator: {
								project: projectData,
								scorePreferences: projectData.preferences.scores,
								qualityPreferences: projectData.qualityPreferences,
								selectedActivityCodes,
							},
							updates: updates.map((u) => ({
								...u,
								finishMilestone: drivingSwitch,
							})) as Array<Required<UpdateInterface>>,
							xers,
							performanceFactor: this.projectService.$currentProjectReport.value?.riskPage.performanceFactor,
							skipPaths: true,
						})
						.subscribe({
							next: (result) => {
								const actvC = result.report.activityCompletionGraph?.projectTrendGraph.totalCompletionPercentageArray;
								code.progressScore = result.report.progressScore;
								code.qcScore = result.report.qualityControl.qcScore;
								const report = result.report;
								const projectReport = this.projectService.$currentProjectReport.value;
								code.riskScore = projectPredictability(
									projectReport.riskPage.criticalPathRiskByCode[code.codeName + '%--//--%' + code.shortName]
										?.overallScore ?? report.riskPage.criticalPathRisk.overallScore,
									projectReport.riskPage.criticalPathReliabilityByCode[
										this.selectedFinishMilestonesEncoded[code.codeName + '%--//--%' + code.shortName]
									]?.overallScore || report.riskPage.criticalPathReliability.overallScore,
									report.calculationFields?.CPLI || 0,
									report.calculationFields?.BEI || 0,
									report.riskPage?.performanceFactor?.overallScore
								);

								code.actvCompletion = actvC?.[actvC.length - 1];

								this.reportByCode.set(code.codeName + '%--//--%' + code.shortName, [result.report, undefined]);
							},
							error: (err) => {
								console.error(err);
							},
						});
				}
			}
		);
		this.allActivityCodes.forEach((type: ActvCodeFilterItem) => {
			let hasAtLeastOneCodeWithTasks: boolean = false;
			let hasAtLeastOneCodeWithNonCompletedTasks: boolean = false;
			type.subCodes.forEach((code: SubCodeFilterItem) => {
				const prevCode =
					updates?.length < 2
						? null
						: Array.from(prevXer.activityCodes.values()).find(
								(a) => a.shortName === code.shortName && a.codeName === code.codeName
							);
				const currCode = Array.from(currXer.activityCodes.values()).find(
					(a) => a.shortName === code.shortName && a.codeName === code.codeName
				);
				const filteredTasks = currActvByCode.get(currCode?.id);
				const filteredPrevTasks =
					updates?.length < 2
						? null
						: Array.from(prevActvByCode.get(prevCode?.id) || []).filter((t) =>
								filteredTasks.has(currXer.activitiesByCode.get(t.code))
							);
				const nonCompletedFilteredTasks = updates?.length < 2 ? null : filteredPrevTasks.filter((t) => !t.finish);
				code.alwaysDisabled = filteredTasks?.size === 0;
				code.disabled = nonCompletedFilteredTasks?.length === 0;
				if (filteredTasks?.size > 0) {
					hasAtLeastOneCodeWithTasks = true;
				}
				if (nonCompletedFilteredTasks?.length > 0) {
					hasAtLeastOneCodeWithNonCompletedTasks = true;
				}
			});
			type.alwaysDisabled = !hasAtLeastOneCodeWithTasks;
			type.disabled = !hasAtLeastOneCodeWithNonCompletedTasks;
		});
		this.isScreenshotMode = localStorage.getItem('isScreenshotMode') === 'true';
	}

	filterChanged(ev?: ActvCodeFilterItem[]): void {
		this.latestSelection = ev;
		const newVal = this.selectedActivityCodes
			.filter((code: SubCodeFilterItem) => code?.parentName !== undefined)
			.map((c: SubCodeFilterItem) => {
				const existing = this.selectedActivityCodesForPanel.find((sc) => sc.id === c.id);
				return {
					...c,
					expanded: false,
					progressScore: undefined,
					qcScore: undefined,
					riskScore: undefined,
					actvCompletion: null,
					floatConsumption: '',
					floatConsumptionIsLarger: false,
					...existing,
				};
			});
		const newlySelected = newVal?.filter((c) => !this.selectedActivityCodesForPanel.some((sc) => sc.id === c.id));
		this.selectedActivityCodesForPanel = newVal;
		this.updateTagText(ev);
		this.updateScores(newlySelected);
		this.checkForChanges();
	}

	public itemDisabled(dataItem: { alwaysDisabled: boolean; disabled: boolean }) {
		return dataItem.alwaysDisabled;
	}

	updateTagText(ev?: ActvCodeFilterItem[]): void {
		if (ev?.length === 0) {
			this.codesTag = '';
		} else {
			const topLevelCode = ev.find((item) => item?.subCodes?.length > 0);
			if (topLevelCode !== undefined) {
				this.codesTag = topLevelCode.name;
			} else {
				this.codesTag = ev?.length === 1 ? ev[0].name : ev?.length + ' codes selected';
			}
		}
	}

	saveChanges(): void {
		this.loading = true;
		const updateId: string =
			this.projectService.$currentProjectData.value.updateIds[
				this.projectService.$currentProjectData.value.updateIds.length - 1
			];
		const newSelectedCodes: string[] = Array.from(
			this.selectedActivityCodes.map((c: SubCodeFilterItem) => c.parentId + '/%%/' + updateId + '/%%/' + c.shortName)
		);
		this.restService
			.patch(`project/${this.projectService.$currentProjectData.value._id}`, {
				focusTabSelectedCodes: newSelectedCodes,
			})
			.subscribe((val) => {
				console.log('patch res', val);
				this.loading = false;
				this.hasChanges = false;
			});
	}

	public onAction(
		code: SubCodeFilterItem & {
			expanded: boolean;
			progressScore: number;
			qcScore: number;
			riskScore: number;
			actvCompletion: number;
			floatConsumption: string;
			floatConsumptionIsLarger: boolean;
			actvCodes: string[];
		},
		index: number
	): void {
		const panelsToRemoveClass = document.getElementsByClassName('focus-tab-panel-header');
		for (let i = 0; i < panelsToRemoveClass.length; i++) {
			panelsToRemoveClass.item(i).classList.remove('focus-tab-panel-header');
		}
		this.panels.forEach((panel: ExpansionPanelComponent, idx: number) => {
			if (idx !== index && panel.expanded) {
				panel.toggle();
			}
		});
		this.openPanel = structuredClone(code);
		const latest: ActvCodeFilterItem = this.latestSelection.find(
			(c: ActvCodeFilterItem) => c.shortName === code.shortName
		);
		this.doLatest([latest]);
	}

	checkForChanges(): void {
		const lastSaved: string[] = this.projectService.$currentProjectData.value?.focusTabSelectedCodes || [];
		let trimmedLastSaved: string[] = [];
		lastSaved.forEach((code: string) => {
			const split: string[] = code.split('/%%/');
			trimmedLastSaved.push(split[split.length - 1]);
		});
		trimmedLastSaved = trimmedLastSaved.sort(this.sortAsc);
		const currentCodes: string[] = Array.from(
			this.selectedActivityCodes.map((c: SubCodeFilterItem) => c.shortName)
		).sort(this.sortAsc);
		this.hasChanges = this.selectedActivityCodes?.length && hasObjChanged(trimmedLastSaved, currentCodes);
	}

	sortAsc(a: string, b: string): number {
		return a < b ? -1 : b < a ? 1 : 0;
	}

	doLatest(ev: ActvCodeFilterItem[]): void {
		let latestActv: Activity;
		for (const actv of this.latestXer.sortedActivities) {
			if (latestActv && latestActv.targetFinish > actv.targetFinish) {
				continue;
			}
			if (!ev?.length || haveCommonItem(actv.activityCodeIds, new Set(ev.map((item) => item.id)))) {
				latestActv = actv;
			}
		}
		this.lastActivityCode = latestActv?.code;
	}

	isAllCaps(str: string): boolean {
		return str === str.toUpperCase();
	}

	test(e) {
		e.stopPropagation();
	}

	treeValChange(
		ev: XerActivity & { entry?: { name: string; id: number } },
		dropDownTree: DropDownTreeComponent,
		codeKey: string
	): void {
		if (ev.entry.id <= 1) {
			const finMiles: XerActivity[] = this.groupedActivities[codeKey][0].children;
			const allAct: XerActivity[] = this.groupedActivities[codeKey][1].children;
			const matchingMile: XerActivity = [...finMiles, ...allAct].find(
				(f) => f.task_code === this.projectService.$currentProjectData.value.preferences.focusMilestones[codeKey]
			);
			if (matchingMile) {
				this.selectedFinishMilestones[codeKey] = structuredClone(matchingMile);
				this.selectedFinishMilestonesEncoded[codeKey] = this.selectedFinishMilestones[codeKey].task_code.replace(
					/\./g,
					'__dot__'
				);
				dropDownTree.toggle(true);
			}
		} else {
			const pref = this.projectService.$currentProjectData.value.preferences;
			pref.focusMilestones = pref.focusMilestones || {};
			pref.focusMilestones[codeKey] = ev.task_code;
			this.restService
				.post('project/preferences/' + this.projectService.$currentProjectData.value._id, {
					data: pref,
				})
				.subscribe((r) => {
					console.log('preferences saved!', r);
				});
			this.selectedFinishMilestones[codeKey] = ev;
			this.reportByCode.delete(codeKey);
			this.updateScores();
		}

		/*if (ev.entry.id === 0 || ev.entry.id === 1) {
			this.selectedFinishMilestone = structuredClone(this.prevSelectedFinishMilestone);
		} else {
			this.selectedFinishMilestone = ev;
			if (ev?.entry?.id !== this.prevSelectedFinishMilestone?.entry?.id) {
				this.prevSelectedFinishMilestone = structuredClone(this.selectedFinishMilestone);
				this.prevSelectedFinishMilestone.entry = {
					name: this.prevSelectedFinishMilestone.task_code + ' - ' + this.prevSelectedFinishMilestone.task_name,
					id: this.prevSelectedFinishMilestone.task_id,
				};
				this.loadDrivingPath();
				dropDownTree.toggle(false);
				this.dropdownIsOpen = false;
			}
		}*/
	}
}
