import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ProjectInterface } from '../../../models/Project';
import { ProjectReportInterface } from '../../../models/ProjectReport/ProjectReport';
import { query, QueryOutput } from 'rx-query';
import { takeUntil } from 'rxjs/operators';
import { ProjectDashboardService } from '../../../services/project/project.service';
import { CalculationsService } from '../../../services/project/calculations.service';
import { RestService } from '../../../services/common/rest.service';
import { UserService } from '../../../services/common/user.service';
import { PopoverController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '../../../services/common/notification.service';
import { ScheduleStorageService } from '../../../services/project/schedule-storage.service';
import { NavigationBarStorageService } from '../../../services/common/navigation-bar-storage.service';
import { PdfVisualizerService } from '../../../services/pdf-visualizer.service';

@Component({
	selector: 'app-visualize-report',
	templateUrl: './visualize-report.component.html',
	styleUrls: ['./visualize-report.component.scss'],
})
export class VisualizeReportComponent implements OnInit {
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	readonly $projectData = new BehaviorSubject<ProjectInterface>(undefined);
	readonly $expandedMetrics = new BehaviorSubject<any>(undefined);
	readonly $allUpdates = new BehaviorSubject<any[]>([]);
	readonly $projectReport = new BehaviorSubject<
		Omit<ProjectReportInterface, 'project'> & { project?: ProjectInterface }
	>(undefined);
	readonly $linkToCompanyLogo = new BehaviorSubject<string>('/assets/images/logos/aegis_Analytics22.png');
	readonly $sharepointURL = new BehaviorSubject<string>('');
	readonly $calculationStatus: BehaviorSubject<any> = new BehaviorSubject<any>({ stage: 'Not Started', percent: 0 });
	user: any = {
		userType: 'aegis',
	};
	riskMetricsOptions = [
		{
			value: 'default',
			viewValue: 'Default',
			defaultChecked: false,
		},
		{
			value: 'performanceFactor',
			viewValue: 'Monte Carlo - Performance Trending',
			defaultChecked: false,
		},
		{
			value: 'riskRegister',
			viewValue: 'Monte Carlo - Risk Register',
			defaultChecked: false,
		},
	];
	dialogOpened = false;

	today = new Date();

	ngOnInit(): void {
		document.getElementById('rwanav').style.display = 'none';
		setTimeout(() => {
			const itms = document.getElementsByTagName('tooltip-open');
			for (let i = 0; i < itms.length; i++) {
				itms.item(i).classList.add('tooltip-hide');
			}
			document.getElementById('rwanav').classList.add('finished-loading-stylistics');
		}, 5000);

		const projectStatus$ = query('status', () => this._projectDashboardService.getProjectReport(), {
			refetchInterval: 5000,
		});

		projectStatus$.pipe(takeUntil(this._unsubscribeAll)).subscribe((prog: QueryOutput<any>) => {
			if (prog.status !== 'success') {
				return;
			}
			const report = prog?.data as Omit<ProjectReportInterface, 'project'> & { project?: ProjectInterface };
			const lastReport = this.$projectReport.value;
			const prevReport = JSON.stringify(this.$projectReport.value || {});
			const updatedReport = JSON.stringify(report || {});
			if (prevReport !== updatedReport && report) {
				const currentOption = this.riskMetricsOptions.find(
					(option) => option.value === report?.project?.riskMetricsType
				);
				if (currentOption !== undefined) {
					currentOption.defaultChecked = true;
				}
				this.$projectReport.next(report as Omit<ProjectReportInterface, 'project'> & { project?: ProjectInterface });
				const project = report?.project;
				const wasLocked = !!this.$projectData.value?.updateLock || this.$projectData.value?.isMonteCarloDone === false;
				if (project) {
					this.$projectData.next(project);
				}
				this._projectDashboardService.$currentProjectReport.next(report);
				const isNowLocked = (project && !!project?.updateLock) || project?.isMonteCarloDone === false;
				if (wasLocked && !isNowLocked) {
					const projectStatCompleted = this.$calculationStatus.value;
					projectStatCompleted.jobProgress = {
						data: {
							stage: 'Complete!',
							percent: 100,
						},
					};
					window.location.reload();
				}
			}
		});
		this.navBarStorage.riskMetricsOptions = this.riskMetricsOptions;
		this.$projectData.pipe(takeUntil(this._unsubscribeAll)).subscribe((project) => {
			this._scheduleService.$projectData.next(project);
			if (project?._id) {
				this._projectDashboardService.fullyLoaded = true;
				document.title = this._projectDashboardService.$currentProjectReport?.value.project?.name;
			}
			this.$sharepointURL.next(project?.sharePoint);
			if (project?.warnings?.length > 0) {
				this.showProjectWarning(project.warnings[0]);
			}
		});

		this._scheduleService.$allUpdates.pipe(takeUntil(this._unsubscribeAll)).subscribe((updates) => {
			this.$allUpdates.next(updates);
		});

		//

		this.userService.user.subscribe((data) => {
			if (data) {
				this.user = data;
			}
		});

		if (this.user.userType === 'client') {
			const thisProject = this._projectDashboardService.$currentProjectData.value;
			if (thisProject.companyId != null) {
				const url = `company/${thisProject.companyId}`;
				this.restService.fetch(url).subscribe((data) => {
					const companyData = data.Companies[0];
					this.$linkToCompanyLogo.next(companyData.logo);
				});
			}
		}

		this.navBarStorage.$tabPointer.next('overview');

		this.route.queryParams.subscribe((params) => {
			if (params.tab) {
				this.navBarStorage.$tabPointer.next(params.tab);
			}
		});

		const arr: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName(
			'loading-schedules-window'
		) as unknown as HTMLCollectionOf<HTMLElement>;

		for (let i = 0; i < arr.length; i++) {
			arr[i].style.display = 'none';
		}
	}

	constructor(
		public _projectDashboardService: ProjectDashboardService,
		private _calculationService: CalculationsService,
		private restService: RestService,
		public userService: UserService,
		private popoverController: PopoverController,
		private route: ActivatedRoute,
		public notificationService: NotificationService,
		public _scheduleService: ScheduleStorageService,
		public navBarStorage: NavigationBarStorageService,
		public pdfVisualizerService: PdfVisualizerService
	) {}

	ngOnDestroy() {
		this._scheduleService.clearAllData();
		this.$allUpdates.next([]);
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	reloadWindow() {
		window.location.reload();
	}

	showProjectWarning(msg: string, n: number = 0) {
		// TODO: replace fuse snackbar
		/*		const warningBar = this._snackBar.open(msg, 'Dismiss', {
			horizontalPosition: 'end',
			verticalPosition: 'top',
			panelClass: '',
		});

		warningBar.afterDismissed().subscribe(() => {
			if (this.$projectData.value?.warnings?.[n + 1]) {
				this.showProjectWarning(this.$projectData.value.warnings[n + 1], n + 1);
			}
		});*/
	}

	changeTab() {}

	/**
	 * updates dialog open state value
	 * @param newVal
	 */
	dialogStateChanged(newVal) {
		this.dialogOpened = newVal;
	}
}
