import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LikelihoodVsImpactHeatmapComponent } from './likelihood-vs-impact-heatmap/likelihood-vs-impact-heatmap.component';
import { ChartModule } from '@progress/kendo-angular-charts';
import { RiskByCategoryComponent } from './risk-by-category/risk-by-category.component';
import { RiskSummaryTrendComponent } from './risk-summary-trend/risk-summary-trend.component';
import { RiskMitigationScoresTrendingComponent } from './risk-mitigation-scores-trending/risk-mitigation-scores-trending.component';

@NgModule({
	imports: [CommonModule, ChartModule],
	declarations: [
		LikelihoodVsImpactHeatmapComponent,
		RiskByCategoryComponent,
		RiskSummaryTrendComponent,
		RiskMitigationScoresTrendingComponent,
	],
	exports: [
		LikelihoodVsImpactHeatmapComponent,
		RiskByCategoryComponent,
		RiskSummaryTrendComponent,
		RiskMitigationScoresTrendingComponent,
	],
})
export class RiskWidgetsModule {}
