import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Oauth2RapidComponent } from './oauth2-rapid/oauth2-rapid.component';
import { Oauth2CallbackComponent } from './oauth2-callback/oauth2-callback.component';
import { LoginComponent } from './login/login.component';
import { LoginAuthGuard } from '../../routes/auth/login-auth.guard';
import { RegisterComponent } from './register/register.component';
import { Register2Component } from './register-2/register-2.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPassword2Component } from './forgot-password-2/forgot-password-2.component';
import { MailConfirmComponent } from './mail-confirm/mail-confirm.component';
import { LoginHolderComponent } from './login-holder/login-holder.component';

const routes: Routes = [
	{
		path: 'login',
		component: LoginComponent,
		canActivate: [LoginAuthGuard],
	},
	{
		path: 'handoff',
		component: LoginHolderComponent,
		canActivate: [LoginAuthGuard],
	},
	{
		path: 'register',
		component: RegisterComponent,
	},
	{
		path: 'register-2',
		component: Register2Component,
	},
	{
		path: 'forgot-password',
		component: ForgotPasswordComponent,
	},
	{
		path: 'forgot-password-2',
		component: ForgotPassword2Component,
	},
	{
		path: 'mail-confirm',
		component: MailConfirmComponent,
	},
	{
		path: 'rapid',
		component: Oauth2RapidComponent,
	},
	{
		path: 'callback',
		component: Oauth2CallbackComponent,
	},
	{
		path: '**',
		redirectTo: 'auth/login',
	},
];
@NgModule({
	imports: [RouterModule.forChild(routes), CommonModule],
	exports: [RouterModule],
	providers: [],
})
export class AuthRoutingModule {}
