import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectCompletionVarianceComponent } from './project-completion-variance.component';
import { IonicModule } from '@ionic/angular';
import { GlobalTooltipMenuModule } from '../global-toolip-menu/global-tooltip-menu.module';
import { HighchartsChartModule } from 'highcharts-angular';

@NgModule({
	declarations: [ProjectCompletionVarianceComponent],
	imports: [CommonModule, IonicModule, GlobalTooltipMenuModule, HighchartsChartModule],
	exports: [ProjectCompletionVarianceComponent],
})
export class ProjectCompletionVarianceModule {}
