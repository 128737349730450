<!--<ion-grid style="background-color: white">-->
	<kendo-gridlayout
		[rows]="['300px']"
		[cols]="readyToShow ? ['340px', '310px'] : ['340px']"
		[gap]="{ rows: 0, cols: 0 }"
		class='grid'
	>
		<kendo-gridlayout-item [row]='1' [col]='2' class='img-cropper-container' *ngIf='readyToShow'>
			<image-cropper
				style="height: 300px; padding: 0;"
				[imageBase64]="b64image"
				format="png"
				output='base64'
				(imageCropped)="imageCropped($event)"
				[maintainAspectRatio]="false"
			></image-cropper>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-container'>
			<div class='right-side-title'>Choose a File</div>
			<ion-input class='file-input' type="file" (change)="checkUpload($event)" accept="image/*"></ion-input>
			<div style='padding-top: 5px; text-align: center;'>Must be JPG, PNG, or any Image File. Max Size: 2MB.</div>
			<div *ngIf="readyToShow" class='confirm-upload-container'>
				<div class='confirm-text-title'>Looks Good?</div>
				<button kendoButton style='background-color: #2ebb2e; color: white; border-radius: 4px; margin: 0 auto;' (click)="saveAndUpload()">Save and Upload</button>
			</div>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
<!--</ion-grid>-->
