import * as Highcharts from 'highcharts';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { PfTableFilterModalComponent } from '../../pf-table-filter-modal/pf-table-filter-modal/pf-table-filter-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { addDays, differenceInDays, endOfDay, format, fromUnixTime, isAfter, startOfDay } from 'date-fns';
import { cleanDateUTC } from '../../../../../util/pipes/date.pipe';
import { monteCarloChartOptions, p85HistoricalOptions, performanceFactorScoreOptions } from './pf-chart-options';
import { hitsInterface } from '../../../../../models/ProjectReport/ProjectReport';
import { ProjectInterface } from '../../../../../models/Project';
import { CurrentProjectReport, ProjectDashboardService } from '../../../../../services/project/project.service';
import { GaugeColorSettings } from '../../../../../models/ChartSettings';
import { RestService } from '../../../../../services/common/rest.service';
import { NavigationBarStorageService } from '../../../../../services/common/navigation-bar-storage.service';
import { ScheduleStorageService } from '../../../../../services/project/schedule-storage.service';
import { buildPfTable, BuiltPfTable } from '../../../../../util/projects';
import { ProjectReportInterface } from '@rhinoworks/analytics-calculations';
import {
	actvTypeToParentDropdownItem,
	ParentDropdownItem,
	wbsDisplaySave,
	wbsToParentDropdownItem,
} from './pf-table-dropdown/pf-table-dropdown.component';
import { IActivityCode } from '@rhinoworks/xer-parse';

export interface PFTableValues {
	activityCodeId: number;
	activityCode: string;
	display: string;
	wbsId: number;
	wbsKey: string;
	shortName: string;
	description: string;
	pf: number;
	isSelected: boolean;
	numStarted: number;
	typeId: number;
	parent?: number;
	subcategory: string;
}

function findParentFromId(ids: number[], nested: ParentDropdownItem[]): ParentDropdownItem | undefined {
	if (!ids.length) {
		return undefined;
	}
	for (const item of nested) {
		if (!item.childrenRows) {
			continue;
		}
		for (const child of item.childrenRows) {
			if (ids.includes(child.wbsId) || ids.includes(child.activityCodeId)) {
				return item;
			}
		}
	}
	for (const item of nested) {
		const found = findParentFromId(ids, item.children);
		if (found) {
			return found;
		}
	}
	return undefined;
}

@Component({
	selector: 'app-performance-factor',
	templateUrl: './performance-factor.component.html',
	styleUrls: ['./performance-factor.component.scss'],
})
export class PerformanceFactorComponent implements OnInit, OnDestroy {
	@Input() isOverview: boolean = false;
	$projectData = new BehaviorSubject<ProjectInterface>(undefined);
	hasStartedMonte$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	updatedCharts = false;
	Highcharts = Highcharts;
	p85DateInput: Date = new Date();
	p85ProbInput = 85;
	hitSeriesDateInput = [];
	probSeriesDateInput = [];
	probSeriesDateInput2 = [];
	dateArray = [];
	lastUpdated = 'none';
	$p85Date = new BehaviorSubject<Date>(undefined);
	$p50Date = new BehaviorSubject<Date>(undefined);
	$currentCompletion = new BehaviorSubject<Date>(undefined);
	$p85Diff = new BehaviorSubject<number>(0);
	$p85CurrentCompletionDifferenceString = new BehaviorSubject<string>(undefined);
	yValue;
	monteCarloChartOptions = monteCarloChartOptions;
	performanceFactorScoreOptions = performanceFactorScoreOptions;
	p85HistoricalOptions = p85HistoricalOptions;
	pfRiskChart: Highcharts.Chart;
	p85HistoricalChart: Highcharts.Chart;
	p85HitsChart: Highcharts.Chart;
	monteCarloFinished = true;
	totalMCJobs = 0;
	totalMCJobsProgress = 0;
	unableToCalcReason: string = '';
	resetFilterClicked = new BehaviorSubject<boolean>(false);
	submitFilterClicked = new BehaviorSubject<boolean>(false);
	httpProcessing: boolean = false;
	cancelEnabled = false;
	pfTable: BuiltPfTable = {
		pfRows: [],
		selectedActivities: new Set([]),
		unselectedActivities: new Set([]),
	};
	nestedPfTable: Array<ParentDropdownItem> = [];
	selectedActivityTypeName: ParentDropdownItem = null;

	selectedPfRowKeys: Array<number>;

	rhinoFacts: string[] = [
		'Rhino Horns are Made from the Same Materials as Human Fingernails. One of the most unique facts about rhinos is that their horns are made of keratin, which is also the same key protein that makes up human hair and fingernails. Their horns tend to grow and curve towards the head due to keratin at the front growing at a faster rate than it does in the back. Interestingly, if a rhino loses its horn – due to bulls fighting each other over dominance or territory – it will eventually grow back.',
		'Rhinos Have Special Relationships with Birds. Another one of the many interesting facts about rhinos is that while they tend to be solitary animals, they have a symbiotic relationship with birds. This is most notable with the African rhinos and the African Oxpeckers. The bird would often sit on a rhino’s back and feed off the insects on it. Birds would also provide warning calls should any potential danger or enemies that approaches – as rhinos are quite short-sighted.',
		'There Are Five Different Rhino Species. White rhinos, Black rhinos, Greater one-horned, Sumatran and Javan rhinos. Black and white rhinos are also known together as the African rhinos, as they are found only on the African continent.',
		'Rhinos can weigh over 3 tonnes. Sumatran rhinos are the smallest of all rhinos, but they can still weigh 600kg (that’s almost 95 stone). On the other hand, white rhinos are the largest of the rhino species, weighing up to 3,500 kg. That is more than 550 stone, or well over 3 tonnes, which is mighty impressive considering they mainly eat grass and leaves.',
	];

	$performanceFactorScore = new BehaviorSubject<number>(0);
	pfColors: GaugeColorSettings[] = [
		{
			to: 50,
			color: '#DF5353',
		},
		{
			from: 50,
			to: 85,
			color: '#4fc931',
		},
		{
			from: 85,
			color: '#0059FF',
		},
	];
	label = 'On-Time Completion';
	hasNotes: boolean = false;
	savedPfKeys: Array<number>;

	private _unsubscribeAll = new Subject<void>();
	constructor(
		public dialog: MatDialog,
		public projectService: ProjectDashboardService,
		private restService: RestService,
		private navBarStorage: NavigationBarStorageService,
		public scheduleService: ScheduleStorageService
	) {
		Highcharts.setOptions({
			lang: {
				thousandsSep: ',',
			},
		});
	}

	selectedRhinoFact: string = '';

	ngOnInit(): void {
		this.selectedRhinoFact = this.rhinoFacts[Math.floor(Math.random() * this.rhinoFacts.length)];

		this.projectService.$currentProjectData.subscribe((project) => {
			this.$projectData.next(project);
			if (!project?.isMonteCarloDone || Object.keys(project?.mcJobs || {})?.length) {
				this.hasStartedMonte$.next(true);
			}
			const monteCarloFinished =
				!project?.updateLock &&
				project?.isMonteCarloDone &&
				project?.updateIds?.length > (project.riskMetricsType !== 'riskRegister' ? 1 : 0) &&
				!Object.keys(project?.mcJobs || {})?.length;
			if (monteCarloFinished !== this.monteCarloFinished && monteCarloFinished) {
				this.refreshWindow();
			}
			if (!monteCarloFinished) {
				this.totalMCJobs =
					project.riskMetricsType === 'performanceFactor' ? 1 : (project.riskMitigation?.length || 0) * 2 + 3;
				this.totalMCJobsProgress =
					this.totalMCJobs -
						Object.values(project?.mcJobs || {}).reduce((sum, updateJobs) => sum + updateJobs.length, 0) || 0;
			}
			this.monteCarloFinished = monteCarloFinished;
			this.updatePerformanceFactor(this.projectService.$currentProjectReport.value);
			if (project) {
				const savedNotes = project.componentNotes?.find((n) => n.id === 19)?.notes;
				this.hasNotes = savedNotes?.length && savedNotes[savedNotes?.length - 1]?.note !== '';
			}
		});

		this.projectService.$currentProjectReport
			.pipe(takeUntil(this._unsubscribeAll), debounceTime(100))
			.subscribe(async (report) => {
				if (!report?.pfTableHistorical) {
					return;
				}
				if (!report?.project || !report?.pfTableHistorical) {
					return;
				}
				if (!report?.riskPage?.performanceFactor?.selectedActivityCodes) {
					return;
				}

				this.pfTable = buildPfTable(report?.pfTableHistorical, this.selectedPfRowKeys);
				const xer = await this.scheduleService.grabUpdateXer(
					report.project.updateIds[report.project.updateIds.length - 1]
				);
				const actvCodes = Array.from(xer.activityCodes.values());
				this.savedPfKeys = report.project.pfCodes;
				this.selectedPfRowKeys = [...this.savedPfKeys];
				for (const code of actvCodes) {
					const item = this.pfTable.pfRows.find((row) => row.shortName === code.shortName);
					if (item) {
						item.typeId = code.typeId;
					}
				}
				const actvTypes = Array.from(xer.activityTypes.values());
				const activityCodesByType = new Map<number, IActivityCode[]>(
					actvTypes.map((actvType) => [
						actvType.id,
						actvCodes.filter(
							(code) => code.typeId === actvType.id && this.pfTable.pfRows.some((pf) => pf.activityCodeId === code.id)
						),
					])
				);
				const nestedPfTable = [...activityCodesByType.entries()]
					.filter(([, codes]) => codes.length > 0)
					.map(
						([typeId, codes]): ParentDropdownItem =>
							actvTypeToParentDropdownItem(xer.activityTypes.get(typeId), codes, this.pfTable)
					)
					.concat(
						Array.from(xer.wbs.values())
							.filter((wbs) => !wbs.parentWbs)
							.map((wbs) => wbsToParentDropdownItem(wbs, this.pfTable))
					);
				this.selectedActivityTypeName = findParentFromId(report.project.pfCodes, nestedPfTable);
				this.nestedPfTable = nestedPfTable;
				this.updatePerformanceFactor(report);
			});

		setTimeout(() => {
			if (this.pfRiskChart) {
				this.pfRiskChart.reflow();
			}
			if (this.p85HitsChart) {
				this.p85HitsChart.reflow();
			}
			if (this.p85HistoricalChart) {
				this.p85HistoricalChart.reflow();
			}
		}, 2000);
	}
	ngOnDestroy() {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	updatePerformanceFactor(report: CurrentProjectReport = this.projectService.$currentProjectReport.value) {
		if (!report || !report?.project) {
			return;
		}
		if (report.project.riskPagePurchased) {
			this.pfTable = buildPfTable(report?.pfTableHistorical, this.selectedPfRowKeys);
			this.getPerformanceFactorScore(report);
			this.getp85HistoricalData(report);
		}
	}

	/**
	 * helper sort function that sorts by pf descending
	 * @param a
	 * @param b
	 */
	compare(a, b) {
		return b.pf - a.pf;
	}

	/**
	 * updates many things about the charts so that dynamic input works
	 */
	getPerformanceFactorScore(report: Omit<ProjectReportInterface, 'project'> & { project?: ProjectInterface }) {
		const contractCompletionDate = report?.projectOverview?.contractCompletion?.split(' ')?.[0];
		const riskPage = report?.riskPage;
		console.log({ riskPage });
		const projectName = report?.project?.name;
		const updateNumber = report?.projectOverview?.scheduleName;

		this.hitSeriesDateInput = [];
		this.probSeriesDateInput = [];
		this.probSeriesDateInput2 = [];
		this.dateArray = [];
		const hitsByStartDate = new Map<number, number>([]);
		for (const { unixDate, hits } of riskPage?.performanceFactor?.hits || []) {
			const hitDate = startOfDay(unixDate * 1000);
			const existingVal = hitsByStartDate.get(hitDate.getTime()) || 0;
			hitsByStartDate.set(hitDate.getTime(), existingVal + hits);
		}
		const probsByStartDate = new Map<number, number>([]);
		for (const { unixDate, prob } of riskPage?.performanceFactor?.prob || []) {
			const probDate = startOfDay(unixDate * 1000);
			probsByStartDate.set(probDate.getTime(), prob);
		}
		const hitsObject: hitsInterface[] = Array.from(hitsByStartDate.entries()).map(([unixDate, hits]) => ({
			unixDate,
			hits,
		}));
		const probObject = Array.from(probsByStartDate.entries()).map(([unixDate, prob]) => ({
			unixDate,
			prob,
		}));

		hitsObject?.forEach((hit) => {
			this.hitSeriesDateInput.push(hit?.hits);
			this.dateArray.push(this.convertToDateString(hit?.unixDate));
		});
		probObject?.forEach((prob) => {
			this.probSeriesDateInput2.push(prob?.prob);
			this.probSeriesDateInput.push([prob?.unixDate, prob?.prob]);
		});
		this.updateSeries();

		this.monteCarloChartOptions.title = {
			text: projectName + ' - Monte Carlo Probability Distribution',
			style: {
				fontFamily: 'Muli, Helvetica Neue, Arial, sans-serif',
				fontWeight: 'normal',
				fontSize: '14',
				color: '#737373',
			},
			verticalAlign: 'top',
			// x: 48
			//y: -15
		};

		//get p85 y value from prob series or closest x to 85
		this.yValue = this.probSeriesDateInput?.[0]?.[0];
		let p85DateProb = 0;
		let haveWeFoundP50 = false;
		let indexOfP85 = this.dateArray.length - 1;
		if (this.probSeriesDateInput.length) {
			for (let i = 0; i < this.probSeriesDateInput.length; i++) {
				const point = this.probSeriesDateInput[i];
				if (!haveWeFoundP50 && point[1] >= 50) {
					const p50Date = fromUnixTime(point[0]);
					this.$p50Date.next(p50Date);
					haveWeFoundP50 = true;
				}
				if (point[1] >= 85) {
					this.yValue = point[0];
					p85DateProb = point[1];
					indexOfP85 = Math.min(indexOfP85, i);
					break;
				}
			}
		}
		const p85Date = fromUnixTime(this.yValue);
		this.$p85Date.next(this.yValue ? p85Date : undefined);
		this.p85DateInput = p85Date;
		this.p85ProbInput = p85DateProb;

		this.$currentCompletion.next(new Date(report?.projectTable?.currentCompletion));
		this.$p85Diff.next(
			this.$p85Date.value ? differenceInDays(this.$p85Date.value, new Date(report?.projectTable?.currentCompletion)) : 0
		);
		this.$p85CurrentCompletionDifferenceString.next(
			this.$p85Diff.value ? '(' + (this.$p85Diff.value > 0 ? '+' : '') + this.$p85Diff.value + ' days)' : ''
		);

		const overallScore = report?.riskPage?.performanceFactor?.overallScore;
		const normalizedScore = overallScore >= 100 ? 100 : overallScore <= 0 ? 0 : overallScore;
		this.$performanceFactorScore.next(normalizedScore || 0);
		this.monteCarloChartOptions.xAxis = {
			categories: this.dateArray,
			title: {
				text: 'Projected Date',
				margin: 10,
			},
			labels: {
				rotation: 315,
				align: 'right',
			},
			crosshair: true,
			plotBands: [
				{
					color: 'lightblue',
					from: indexOfP85 - 0.5,
					to: indexOfP85 + 0.5,
				},
			],
		};
		this.updatedCharts = true;
	}

	convertToDateString(unixDate: number) {
		return cleanDateUTC(new Date(unixDate), 'MMM dd, yyyy');
	}

	getp85HistoricalData(report: Omit<ProjectReportInterface, 'project'> & { project?: ProjectInterface }) {
		const riskPage = report?.riskPage;
		const p85HistoricalTrendArray = report?.project?.p85h || riskPage?.performanceFactor?.p85HistoricalTrend || [];
		const projectedDateVarianceArray = [];
		const p85DateVarianceArray = [];
		const previousVarianceArray = [];
		const dataDateArray = [];
		let count = report.project?.riskMetricsType === 'riskRegister' ? 0 : 1;

		const updateNumbersArray = [];
		for (const item of p85HistoricalTrendArray) {
			const p85h = 'p85h' in item ? item.p85h : item;
			if (!p85h?.dataDate) {
				continue;
			}
			const x: string = 'Update ' + count.toString() + '&' + format(new Date(p85h.dataDate), 'MMM dd, yyyy');
			updateNumbersArray.push(x);
			count++;
			if (p85h.previousVariance < 0) {
				previousVarianceArray.push({
					color: '#DF5353',
					y: Math.round(p85h.previousVariance),
				});
			} else {
				previousVarianceArray.push({
					color: '#55BF3B',
					y: Math.round(p85h.previousVariance),
					projectedDate: p85h.projectedDate,
					p85Date: p85h.p85Date,
				});
			}
			projectedDateVarianceArray.push({
				y: p85h.projectedDateVariance,
				projectedDate: format(new Date(p85h.projectedDate), 'MMM dd, yyyy'),
			});
			p85DateVarianceArray.push({
				y: p85h.p85DateVariance,
				p85Date: p85h.p85Date,
			});
			dataDateArray.push(format(new Date(p85h.dataDate), 'dd MMM yyyy'));
		}

		this.p85HistoricalOptions.series = [
			{
				name: 'Period Gain',
				type: 'spline',
				data: [],
				color: '#55BF3B',
				marker: { symbol: 'square', radius: 12, fillColor: '#55BF3B' },
			},
			{
				name: 'Period Loss',
				type: 'spline',
				data: [],
				color: '#DF5353',
				marker: { symbol: 'square', radius: 12, fillColor: '#DF5353' },
			},
			{
				name: 'Period Performance',
				type: 'column',
				data: previousVarianceArray,
				showInLegend: false,
			},
			{
				name: 'Projected Completion Trend',
				type: 'spline',
				data: projectedDateVarianceArray,
				marker: { fillColor: '#FFFFFF' },
			},
		];
	}

	/**
	 * updates the montecarlo chart to have the series configured as i want, and assigns the data to the series's data.
	 * separated in case adding another series temporarily to fake a selection on update of probValue ever worked
	 */
	updateSeries(): void {
		this.monteCarloChartOptions.series = [
			{
				name: 'Probability',
				data: this.probSeriesDateInput2,
				color: 'blue',
				yAxis: 1,
				zIndex: 4,
				type: 'spline',
				tooltip: {
					valueSuffix: '%',
				},
			},
			{
				name: 'Hits',
				data: this.hitSeriesDateInput,
				color: '#ffcc00',
				yAxis: 0,
				type: 'column',
			},
		] as any;
	}

	refreshWindow() {
		setTimeout(() => {
			window.location.reload();
		}, 100);
	}

	p85HitInstance(x: Highcharts.Chart): void {
		this.p85HitsChart = x;
	}

	/**
	 * cancel btn clicked
	 */
	resetFilter(): void {
		this.selectedPfRowKeys = [...this.savedPfKeys];
		this.selectedActivityTypeName = findParentFromId(
			this.projectService.$currentProjectData.value.pfCodes,
			this.nestedPfTable
		);
		this.cancelEnabled = false;
	}

	onSelectedKeysChange(keys: Array<number>): void {
		const existing = new Set(this.savedPfKeys);
		this.cancelEnabled = existing.size !== keys.length || keys.some((key) => !existing.has(key));
	}

	/**
	 * calculate btn clicked
	 */
	submitFilter(): void {
		this.submitFilterClicked.next(true);
		this.httpProcessing = true;
		this.projectService.queueProcessingChanged.next(true);
		const activityCodeIds: Array<number> = this.selectedPfRowKeys;

		this.restService
			.post(`report/calculate/${this.projectService.$currentProjectReport.getValue().project?._id}`, {
				activityCodeIds,
				onlySimulate: true,
				lastRecalcedMonteCarlo: new Date(),
			})
			.subscribe(
				(val) => {},
				(response) => {
					console.log('POST call in error', response);
				},
				() => {}
			);
		this.scheduleService.$manualIdUpdate.next(this.projectService.$currentProjectReport.value?.project?._id);
	}
}
