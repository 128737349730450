import { Component, Input, OnInit } from '@angular/core';
import { ProjectDashboardService } from '../../../../services/project/project.service';

@Component({
	selector: 'app-visualizer-header',
	templateUrl: './visualizer-header.component.html',
	styleUrls: ['./visualizer-header.component.scss'],
})
export class VisualizerHeaderComponent implements OnInit {
	constructor(public _projectDashboardService: ProjectDashboardService) {}

	@Input() page: string = '1';
	today = new Date();

	ngOnInit(): void {}
}
