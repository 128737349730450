<div class="milestones-container">
	<h2 style='margin: 12px 0;'>Additional Milestones</h2>
	<div class="mile-stone-selection">
		<p class="mile-stone-selection-item" style="margin: 8px 0;">
			Select additional Finish Milestones or Activities that you would like to track in addition to Project Completion
			(CCD).
		</p>
		<kendo-multiselect
			#additionalMilestonesMultiselect
			[data]="finishMilestonesOptions"
			[valuePrimitive]='false'
			[autoClose]="false"
			(valueChange)='additionalMilestonesValueChanged($event)'
			[filterable]="true"
			(filterChange)="handleFilter($event)"
			(focusin)='addMileFocus($event, true)'
			(focusout)='addMileFocus($event, false)'
			valueField='value'
			textField='text'
			[value]='additionalMilestones'
			[tagMapper]="tagMapper"
			[virtual]="virtual"
		>
			<ng-template kendoComboBoxFixedGroupTemplate let-groupName>
				<div><strong><u>{{groupName}}</u></strong></div>
			</ng-template>
			<ng-template kendoDropDownListGroupTemplate let-category>
				<div>
					<strong><u>{{ category }}</u></strong>
				</div>
			</ng-template>
			<ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
				<div class="invisible-multiselect-tag-selector" (click)="toggleMultiselect()"></div>
				<span>
					<kendo-svg-icon [icon]='icons.caretDown' (click)="toggleMultiselect()"></kendo-svg-icon>
					{{ dataItems.length + ' selected' }}
				</span>
			</ng-template>
			<ng-template kendoMultiSelectTagTemplate let-dataItem>
				<div class="invisible-multiselect-tag-selector" (click)="toggleMultiselect()"></div>
				<span>
					{{ dataItem?.text }}
				</span>
			</ng-template>
		</kendo-multiselect>
	</div>
</div>
