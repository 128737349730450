import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

@NgModule({
	imports: [CommonModule],
	declarations: [],
	exports: [IonicModule],
})
export class SharedIonModule {}
