<kendo-gridlayout
	[rows]="hideTrending ? [(defaultSelectedActivityCodes && totalPlannedStart.get(lastUpdateId)?.length === 0 && totalPlanned.get(lastUpdateId)?.length === 0 && (unfilteredLookahead90DisplaySetStart.Total?.length + unfilteredLookahead90DisplaySet.Total?.length === 0)) || (project.$currentProjectReport | async)?.updateIds?.length <= 1 ? '192.4px' : defaultSelectedActivityCodes ? '370.2px' : '380.6px'] : hideBars ? [(project.$currentProjectReport | async)?.updateIds?.length <= 1 ? 'auto' : '345.4px'] : [(project.$currentProjectReport | async)?.updateIds?.length <= 1 ? '192.4px' : '380.6px', (project.$currentProjectReport | async)?.updateIds?.length <= 1 ? '192.4px' : '345.4px']"
	[cols]="['100%']"
	[gap]="{ rows: 0, cols: 0 }"
	class='full-height full-width'
	id='activity-completion-chart-anchor'
	[style]=' (visualizer && !defaultSelectedActivityCodes) ? (((project.$currentProjectReport | async)?.milestones?.milestoneArray?.length !== 0) ? "counter: vis-3page;  page-break-inside: avoid; margin-top: 0.5em;" : "counter: vis-2page; page-break-inside: avoid; margin-top: 0.5em;") : ""'
>
	@if (!hideBars) {
		<kendo-gridlayout-item [row]='1' [col]='1' [style.margin-bottom]="!isOverview ? '5px' : '0'">
			<div class='card' [style]='defaultSelectedActivityCodes ? "" : "break-inside: avoid;"' id="activityCompletionComponent" [class.overview-br-top]="isOverview && hasNotes">
				<kendo-gridlayout
					[rows]="[defaultSelectedActivityCodes ? '32px' : '42.4px', (defaultSelectedActivityCodes && totalPlannedStart.get(lastUpdateId)?.length === 0 && totalPlanned.get(lastUpdateId)?.length === 0 && (unfilteredLookahead90DisplaySetStart.Total?.length + unfilteredLookahead90DisplaySet.Total?.length === 0)) || (project.$currentProjectReport | async)?.updateIds?.length <= 1 ? '150px' : '333.2px']"
					[cols]="['100%']"
					[gap]="{ rows: 0, cols: 0 }"
					class='full-width'
				>
					<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' [class.overviewHeader]="isOverview" style="padding-right: 10px">
						<kendo-gridlayout
							[rows]="[defaultSelectedActivityCodes ? '32px' : '42.4px']"
							[cols]="['220px', 'calc(100% - 225px)']"
							[gap]="{ rows: 0, cols: 5 }"
							class='full-width'
						>
							<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
								<div class="card-header" [class.focusTabFontSizing]="defaultSelectedActivityCodes">
									Activity Completion
								</div>
								<tooltip-open type='{{isOverview ? "overview" : "activity-completion"}}' [fillColor]="isOverview ? 'overview' : ''"></tooltip-open>
							</kendo-gridlayout-item>
							<kendo-gridlayout-item [row]='1' [col]='2' class='toolbar-item-container' style="margin: auto; height: min-content; justify-content: end; gap: 10px;">
								@if (!hideBtns) {
									@if (!(totalPlannedStart.get(lastUpdateId)?.length === 0 && totalPlanned.get(lastUpdateId)?.length === 0 && (unfilteredLookahead90DisplaySetStart.Total?.length + unfilteredLookahead90DisplaySet.Total?.length === 0))) {
										<kendo-multiselecttree
											#activityCodesMultiselectTree
											kendoMultiSelectTreeSummaryTag
											kendoMultiSelectTreeExpandable
											[kendoMultiSelectTreeHierarchyBinding]="allActivityCodes"
											[(value)]="selectedActivityCodes"
											textField="name"
											valueField="id"
											childrenField='subCodes'
											[valuePrimitive]='false'
											[filterable]='true'
											(valueChange)='filterChanged($event);'
											[clearButton]='false'
											[expandOnFilter]="{expandMatches: true}"
											[itemDisabled]="itemDisabled"
											[placeholder]='"Activity Code Filter"'
											style='width: 200px; margin: auto 0; cursor: pointer;'
											[disabled]='((project.$currentProjectData | async)?.updateIds.length || 0) <= 1'
											[class.focusTabHeaderSizing]="defaultSelectedActivityCodes"
										>
											<ng-template kendoMultiSelectTreeGroupTagTemplate let-dataItems>
											<span>
												<kendo-svg-icon [icon]='icons.caretDown'></kendo-svg-icon>
												{{ codesTag }}
											</span>
											</ng-template>
										</kendo-multiselecttree>
										<kendo-buttongroup width='100%' selection="single" class='btn-group' style='height: 34px; margin: auto 0;' [class.focusTabHeaderSizing]="defaultSelectedActivityCodes">
											<button
												*ngFor="let button of basedOnButtons"
												kendoButton
												[toggleable]="true"
												[selected]="button.selected"
												(selectedChange)="selectionChange($event, button, false, true)"
												class='item-btn'
												[class.selected-item-btn]='button.selected'
												[disabled]='((project.$currentProjectData | async)?.updateIds.length || 0) <= 1'
											>
											<span>
												{{ button.text }}
											</span>
											</button>
										</kendo-buttongroup>
										<span class='slider-tooltips' kendoTooltip style='margin: auto 0; position: relative;'>
											<kendo-buttongroup width='100%' selection="single" class='btn-group' style='height: 34px; margin: auto 0;' [class.focusTabHeaderSizing]="defaultSelectedActivityCodes">
												<button
													*ngFor="let button of forwardOrBackwardButtons"
													kendoButton
													[toggleable]="true"
													[selected]="button.selected"
													(selectedChange)="selectionChange($event, button, false)"
													class='item-btn'
													[style.z-index]="button.selected ? 3 : !button.disabled ? 2 : 1"
													[class.selected-item-btn]='button.selected'
													[disabled]='((project.$currentProjectData | async)?.updateIds.length || 0) <= 1 || button?.disabled'
													[title]="button.disabled ? 'No Planned Activities' : ''"
												>
													<span>
														{{ button.text }}
													</span>
												</button>
											</kendo-buttongroup>
											@for (button of forwardOrBackwardButtons; track button; let i = $index;) {
												<span class="invis-tooltip" [title]="button.disabled ? 'No Planned Activities' : ''" [style.left.px]="i * 99" [class.disabled-invis]="button.disabled" style="width: 99px;" [class.focusTabHeaderSizing]="defaultSelectedActivityCodes"></span>
											}
										</span>
										<div class='btngroup-label' style='margin: auto 0;' [class.focusTabFontSizing]="defaultSelectedActivityCodes">
											Lookahead (Days):
										</div>
										<span class='slider-tooltips' kendoTooltip style='margin: auto 0; position: relative;'>
											<kendo-buttongroup width='100%' selection="single" class='btn-group' [title]='isLookahead || ((project.$currentProjectData | async)?.updateIds.length || 0) <= 1 ? "" : "Select Planned to edit"' [class.focusTabHeaderSizing]="defaultSelectedActivityCodes">
												<button
													*ngFor="let button of timespanButtons"
													kendoButton
													[toggleable]="true"
													[selected]="button.selected"
													(selectedChange)="selectionChange($event, button, true)"
													class='item-btn'
													[style.z-index]="button.selected ? 3 : !button.disabled ? 2 : 1"
													[class.selected-item-btn]='button.selected'
													[disabled]='!isLookahead || ((project.$currentProjectData | async)?.updateIds.length || 0) <= 1 || button?.disabled'
													[class.hide-selection-on-disabled]='!isLookahead || ((project.$currentProjectData | async)?.updateIds.length || 0) <= 1'
												>
													<span>
														{{ button.text }}
													</span>
												</button>
											</kendo-buttongroup>
											@for (button of timespanButtons; track button; let i = $index;) {
												<span class="invis-tooltip" [title]="button.disabled ? 'No Planned Activities' : ''" [style.left.px]="i * 66.66" [class.disabled-invis]="button.disabled"></span>
											}
										</span>
									}
									<span style='display: inline-flex; align-items: center;float: right' kendoTooltip>
										<div style='height: min-content; margin: auto 0;'>
											<button kendoButton (click)="doExport()" *ngIf='(project.$currentProjectReport | async)?.updateIds?.length > 1 && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-APK"'
															[disabled]="exportProcessing" [style.background-color]="isOverview ? '#ffb500' : 'white'" themeColor='base' class="header-btn" [imageUrl]='"/assets/icons/newIcons/excelExport.svg"' title='Export Activities' [class.focusTabHeaderSizing]="defaultSelectedActivityCodes">
											</button>
											<div kendoTooltip style="text-align: center;" *ngIf='currentProjectCompanyPermissions?.license === "ANALYTICS-BASIC-MPK" || currentProjectCompanyPermissions?.license === "ANALYTICS-BASIC-APK"'>
												<div title='Upgrade to a Professional License to enable Exports'>
													<button kendoButton themeColor="base" class='header-btn' [imageUrl]='"/assets/icons/newIcons/excelExport.svg"' [disabled]='true' [style.background-color]="isOverview ? '#ffb500' : 'white'" [class.focusTabHeaderSizing]="defaultSelectedActivityCodes"></button>
												</div>
											</div>
										</div>
									</span>
								}
								@if (isOverview) {
									<button kendoButton themeColor="base" title="Go to source" [imageUrl]="'assets/icons/newIcons/noun-arrow-5544651.svg'" class='header-btn' (click)='project.goTo(7)' style="background-color: #ffb500; margin: auto 0;"></button>
								}
								<button *ngIf="!hideNotes" kendoButton themeColor="base" title="Add/Edit Notes" [imageUrl]="'assets/icons/newIcons/noun-notepad-6607257.svg'" class='header-btn overview-notes-icon' (click)='project.openNotesWindow({id: 7, name: "Activity Completion"})' [style.background-color]="isOverview ? '#ffb500' : 'white'" style="margin: auto 0 auto 0;"></button>
							</kendo-gridlayout-item>
						</kendo-gridlayout>
					</kendo-gridlayout-item>
					@if (totalPlannedStart.get(lastUpdateId)?.length === 0 && totalPlanned.get(lastUpdateId)?.length === 0 && (unfilteredLookahead90DisplaySetStart.Total?.length + unfilteredLookahead90DisplaySet.Total?.length === 0)) {
						<kendo-gridlayout-item [row]='2' [col]='1'>
							<div class="warning-div" style="height: 150px;">
								No Planned Activities
							</div>
						</kendo-gridlayout-item>
					} @else if ((project.$currentProjectReport | async)?.updateIds?.length > 1) {
						<kendo-gridlayout-item [row]='2' [col]='1'>
							@if (selectedBasedOn !== 'changeThisTextToAllToUseCodeInElseBlock') {
								<kendo-gridlayout
									[rows]="['100%']"
									[cols]="['calc((400% / 12) - 2.5px)', 'calc((800% / 12) - 2.5px)']"
									[gap]="{ rows: 0, cols: 5 }"
									class='full-height full-width'
								>
									<kendo-gridlayout-item [row]='1' [col]='1' style='padding: 5px 0 5px 5px'>
										<kendo-gridlayout
											[rows]="['42px', 'calc(100% - 47px)']"
											[cols]="['auto']"
											[gap]="{ rows: 5, cols: 0 }"
											class='full-height full-height'
										>
											<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-item'>
												<div class="center-row" style='background-color: #001489; border-radius: 4px;'
														 [class.selected-button]='tableView === ActvCompletionView.overview'
														 (click)='setTableView(ActvCompletionView.overview)'>
													Overview
												</div>
											</kendo-gridlayout-item>
											<kendo-gridlayout-item [row]='2' [col]='1'>
												<kendo-gridlayout
													[rows]="['calc(50% - 2.5px)', 'calc(50% - 2.5px)']"
													[cols]="['calc(50% - 2.5px)', 'calc(50% - 2.5px)']"
													[gap]="{ rows: 5, cols: 5 }"
													class='full-height full-height'
												>
													<kendo-gridlayout-item
														[row]='1'
														[col]='1'
														class='gridlayout-item'
														[class.selected-button]='tableView === ActvCompletionView.critical'
														(click)='setTableView(ActvCompletionView.critical)'
													>
														<kendo-gridlayout
															[rows]="['35%', '65%']"
															[cols]="['calc(50% - 2.5px)','calc(50% - 2.5px)']"
															[gap]="{ rows: 0, cols: 5 }"
															class='full-height full-width score-red'
															[class.score-red-lookahead]='isLookahead'
														>
															<kendo-gridlayout-item [row]='1' [col]='1' [colSpan]="2" class='grid-label'>
																Critical
															</kendo-gridlayout-item>
															@if (selectedBasedOn === 'Start') {
																@if (!isLookahead && criticalPlannedStart.get(lastUpdateId)?.length) {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value red-value-bg'>
																		{{$criticalPercentCompleteStart | async | number : '1.0-0' }}%
																	</kendo-gridlayout-item>
																} @else if (isLookahead && (selectedTimespan === 30 ? unfilteredLookahead30DisplaySetStart.Critical?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySetStart.Critical?.length : unfilteredLookahead90DisplaySetStart.Critical?.length)) {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value lookahead-label red-value-bg-lookahead'>
																	<span class='lookahead-value'>
																		{{ selectedTimespan === 30 ? unfilteredLookahead30DisplaySetStart.Critical?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySetStart.Critical?.length : unfilteredLookahead90DisplaySetStart.Critical?.length }}
																	</span>
																		<span class='lookahead-unit'>
																		{{ selectedTimespan === 30 ? (unfilteredLookahead30DisplaySetStart.Critical?.length === 1 ? ' Activity' : ' Activities') : selectedTimespan === 60 ? (unfilteredLookahead60DisplaySetStart.Critical?.length === 1 ? ' Activity' : ' Activities') : (unfilteredLookahead90DisplaySetStart.Critical?.length === 1 ? ' Activity' : ' Activities') }}
																	</span>
																	</kendo-gridlayout-item>
																} @else {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value' [ngClass]="{'red-value-bg' : !isLookahead, 'red-value-bg-lookahead' : isLookahead}" style='font-size: 18px;'>
																		No Planned Activities
																	</kendo-gridlayout-item>
																}
															} @else if (selectedBasedOn === 'All') {
																@if (!isLookahead && (criticalPlannedStart.get(lastUpdateId)?.length || criticalPlanned.get(lastUpdateId)?.length)) {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value red-value-bg'>
																		{{$criticalPercentCompleteAll | async | number : '1.0-0' }}%
																	</kendo-gridlayout-item>
																} @else if (isLookahead && (selectedTimespan === 30 ? unfilteredLookahead30DisplaySetStart.Critical?.length + unfilteredLookahead30DisplaySet.Critical?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySetStart.Critical?.length + unfilteredLookahead60DisplaySet.Critical?.length : unfilteredLookahead90DisplaySetStart.Critical?.length + unfilteredLookahead90DisplaySet.Critical?.length)) {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value lookahead-label red-value-bg-lookahead'>
																		<span class='lookahead-value'>
																			{{ selectedTimespan === 30 ? unfilteredLookahead30DisplaySetStart.Critical?.length + unfilteredLookahead30DisplaySet.Critical?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySetStart.Critical?.length + unfilteredLookahead60DisplaySet.Critical?.length : unfilteredLookahead90DisplaySetStart.Critical?.length + unfilteredLookahead90DisplaySet.Critical?.length }}
																		</span>
																		<span class='lookahead-unit'>
																			{{ selectedTimespan === 30 ? (unfilteredLookahead30DisplaySetStart.Critical?.length + unfilteredLookahead30DisplaySet.Critical?.length === 1 ? ' Activity' : ' Activities') : selectedTimespan === 60 ? (unfilteredLookahead60DisplaySetStart.Critical?.length + unfilteredLookahead60DisplaySet.Critical?.length === 1 ? ' Activity' : ' Activities') : (unfilteredLookahead90DisplaySetStart.Critical?.length + unfilteredLookahead90DisplaySet.Critical?.length === 1 ? ' Activity' : ' Activities') }}
																		</span>
																	</kendo-gridlayout-item>
																} @else {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value' [ngClass]="{'red-value-bg' : !isLookahead, 'red-value-bg-lookahead' : isLookahead}" style='font-size: 18px;'>
																		No Planned Activities
																	</kendo-gridlayout-item>
																}
															} @else {
																@if (!isLookahead && criticalPlanned.get(lastUpdateId)?.length) {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value red-value-bg'>
																		{{$criticalPercentComplete | async | number : '1.0-0' }}%
																	</kendo-gridlayout-item>
																} @else if (isLookahead && (selectedTimespan === 30 ? unfilteredLookahead30DisplaySet.Critical?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySet.Critical?.length : unfilteredLookahead90DisplaySet.Critical?.length)) {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value lookahead-label red-value-bg-lookahead'>
																	<span class='lookahead-value'>
																		{{ selectedTimespan === 30 ? unfilteredLookahead30DisplaySet.Critical?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySet.Critical?.length : unfilteredLookahead90DisplaySet.Critical?.length }}
																	</span>
																		<span class='lookahead-unit'>
																		{{ selectedTimespan === 30 ? (unfilteredLookahead30DisplaySet.Critical?.length === 1 ? ' Activity' : ' Activities') : selectedTimespan === 60 ? (unfilteredLookahead60DisplaySet.Critical?.length === 1 ? ' Activity' : ' Activities') : (unfilteredLookahead90DisplaySet.Critical?.length === 1 ? ' Activity' : ' Activities') }}
																	</span>
																	</kendo-gridlayout-item>
																} @else {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value' [ngClass]="{'red-value-bg' : !isLookahead, 'red-value-bg-lookahead' : isLookahead}" style='font-size: 18px;'>
																		No Planned Activities
																	</kendo-gridlayout-item>
																}
															}
														</kendo-gridlayout>
													</kendo-gridlayout-item>
													<kendo-gridlayout-item
														[row]='1'
														[col]='2'
														class='gridlayout-item'
														[class.selected-button]='tableView === ActvCompletionView.nearcritical'
														(click)='setTableView(ActvCompletionView.nearcritical)'
													>
														<kendo-gridlayout
															[rows]="['35%', '65%']"
															[cols]="['calc(50% - 2.5px)','calc(50% - 2.5px)']"
															[gap]="{ rows: 0, cols: 5 }"
															class='full-height full-width score-green'
															[class.score-green-lookahead]='isLookahead'
														>
															<kendo-gridlayout-item [row]='1' [col]='1' [colSpan]="2" class='grid-label'>
																Near Critical
															</kendo-gridlayout-item>
															@if (selectedBasedOn === 'Start') {
																@if (!isLookahead && nearCriticalPlannedStart.get(lastUpdateId)?.length) {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value green-value-bg'>
																		{{$nearCriticalPercentCompleteStart | async | number : '1.0-0' }}%
																	</kendo-gridlayout-item>
																} @else if (isLookahead && (selectedTimespan === 30 ? unfilteredLookahead30DisplaySetStart["Near Critical"]?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySetStart["Near Critical"]?.length : unfilteredLookahead90DisplaySetStart["Near Critical"]?.length)) {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value lookahead-label green-value-bg-lookahead'>
																	<span class='lookahead-value'>
																		{{ selectedTimespan === 30 ? unfilteredLookahead30DisplaySetStart["Near Critical"]?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySetStart["Near Critical"]?.length : unfilteredLookahead90DisplaySetStart["Near Critical"]?.length }}
																	</span>
																		<span class='lookahead-unit'>
																		{{ selectedTimespan === 30 ? (unfilteredLookahead30DisplaySetStart["Near Critical"]?.length === 1 ? ' Activity' : ' Activities') : selectedTimespan === 60 ? (unfilteredLookahead60DisplaySetStart["Near Critical"]?.length === 1 ? ' Activity' : ' Activities') : (unfilteredLookahead90DisplaySetStart["Near Critical"]?.length === 1 ? ' Activity' : ' Activities') }}
																	</span>
																	</kendo-gridlayout-item>
																} @else {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value' [ngClass]="{'green-value-bg' : !isLookahead, 'green-value-bg-lookahead' : isLookahead}" style='font-size: 18px;'>
																		No Planned Activities
																	</kendo-gridlayout-item>
																}
															} @else if (selectedBasedOn === 'All') {
																@if (!isLookahead && (nearCriticalPlannedStart.get(lastUpdateId)?.length || nearCriticalPlanned.get(lastUpdateId)?.length)) {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value green-value-bg'>
																		{{$nearCriticalPercentCompleteAll | async | number : '1.0-0' }}%
																	</kendo-gridlayout-item>
																} @else if (isLookahead && (selectedTimespan === 30 ? unfilteredLookahead30DisplaySetStart["Near Critical"]?.length + unfilteredLookahead30DisplaySet["Near Critical"]?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySetStart["Near Critical"]?.length + unfilteredLookahead60DisplaySet["Near Critical"]?.length : unfilteredLookahead90DisplaySetStart["Near Critical"]?.length + unfilteredLookahead90DisplaySet["Near Critical"]?.length)) {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value lookahead-label green-value-bg-lookahead'>
																		<span class='lookahead-value'>
																			{{ selectedTimespan === 30 ? unfilteredLookahead30DisplaySetStart["Near Critical"]?.length + unfilteredLookahead30DisplaySet["Near Critical"]?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySetStart["Near Critical"]?.length + unfilteredLookahead60DisplaySet["Near Critical"]?.length : unfilteredLookahead90DisplaySetStart["Near Critical"]?.length + unfilteredLookahead90DisplaySet["Near Critical"]?.length }}
																		</span>
																		<span class='lookahead-unit'>
																			{{ selectedTimespan === 30 ? (unfilteredLookahead30DisplaySetStart["Near Critical"]?.length + unfilteredLookahead30DisplaySet["Near Critical"]?.length === 1 ? ' Activity' : ' Activities') : selectedTimespan === 60 ? (unfilteredLookahead60DisplaySetStart["Near Critical"]?.length + unfilteredLookahead60DisplaySet["Near Critical"]?.length === 1 ? ' Activity' : ' Activities') : (unfilteredLookahead90DisplaySetStart["Near Critical"]?.length + unfilteredLookahead90DisplaySet["Near Critical"]?.length === 1 ? ' Activity' : ' Activities') }}
																		</span>
																	</kendo-gridlayout-item>
																} @else {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value' [ngClass]="{'green-value-bg' : !isLookahead, 'green-value-bg-lookahead' : isLookahead}" style='font-size: 18px;'>
																		No Planned Activities
																	</kendo-gridlayout-item>
																}
															} @else {
																@if (!isLookahead && nearCriticalPlanned.get(lastUpdateId)?.length) {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value green-value-bg'>
																		{{$nearCriticalPercentComplete | async | number : '1.0-0' }}%
																	</kendo-gridlayout-item>
																} @else if (isLookahead && (selectedTimespan === 30 ? unfilteredLookahead30DisplaySet["Near Critical"]?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySet["Near Critical"]?.length : unfilteredLookahead90DisplaySet["Near Critical"]?.length)) {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value lookahead-label green-value-bg-lookahead'>
																	<span class='lookahead-value'>
																		{{ selectedTimespan === 30 ? unfilteredLookahead30DisplaySet["Near Critical"]?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySet["Near Critical"]?.length : unfilteredLookahead90DisplaySet["Near Critical"]?.length }}
																	</span>
																		<span class='lookahead-unit'>
																		{{ selectedTimespan === 30 ? (unfilteredLookahead30DisplaySet["Near Critical"]?.length === 1 ? ' Activity' : ' Activities') : selectedTimespan === 60 ? (unfilteredLookahead60DisplaySet["Near Critical"]?.length === 1 ? ' Activity' : ' Activities') : (unfilteredLookahead90DisplaySet["Near Critical"]?.length === 1 ? ' Activity' : ' Activities') }}
																	</span>
																	</kendo-gridlayout-item>
																} @else {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value' [ngClass]="{'green-value-bg' : !isLookahead, 'green-value-bg-lookahead' : isLookahead}" style='font-size: 18px;'>
																		No Planned Activities
																	</kendo-gridlayout-item>
																}
															}
														</kendo-gridlayout>
													</kendo-gridlayout-item>
													<kendo-gridlayout-item
														[row]='2'
														[col]='1'
														class='gridlayout-item'
														[class.selected-button]='tableView === ActvCompletionView.noncritical'
														(click)='setTableView(ActvCompletionView.noncritical)'
													>
														<kendo-gridlayout
															[rows]="['35%', '65%']"
															[cols]="['calc(50% - 2.5px)','calc(50% - 2.5px)']"
															[gap]="{ rows: 0, cols: 5 }"
															class='full-height full-width score-blue'
															[class.score-blue-lookahead]='isLookahead'
														>
															<kendo-gridlayout-item [row]='1' [col]='1' [colSpan]="2" class='grid-label'>
																Non Critical
															</kendo-gridlayout-item>
															@if (selectedBasedOn === 'Start') {
																@if (!isLookahead && nonCriticalPlannedStart.get(lastUpdateId)?.length) {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value blue-value-bg'>
																		{{$nonCriticalPercentCompleteStart | async | number : '1.0-0' }}%
																	</kendo-gridlayout-item>
																} @else if (isLookahead && (selectedTimespan === 30 ? unfilteredLookahead30DisplaySetStart["Non Critical"]?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySetStart["Non Critical"]?.length : unfilteredLookahead90DisplaySetStart["Non Critical"]?.length)) {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value lookahead-label blue-value-bg-lookahead'>
																	<span class='lookahead-value'>
																		{{ selectedTimespan === 30 ? unfilteredLookahead30DisplaySetStart["Non Critical"]?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySetStart["Non Critical"]?.length : unfilteredLookahead90DisplaySetStart["Non Critical"]?.length }}
																	</span>
																		<span class='lookahead-unit'>
																		{{ selectedTimespan === 30 ? (unfilteredLookahead30DisplaySetStart["Non Critical"]?.length === 1 ? ' Activity' : ' Activities') : selectedTimespan === 60 ? (unfilteredLookahead60DisplaySetStart["Non Critical"]?.length === 1 ? ' Activity' : ' Activities') : (unfilteredLookahead90DisplaySetStart["Non Critical"]?.length === 1 ? ' Activity' : ' Activities') }}
																	</span>
																	</kendo-gridlayout-item>
																} @else {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value' [ngClass]="{'blue-value-bg' : !isLookahead, 'blue-value-bg-lookahead' : isLookahead}" style='font-size: 18px;'>
																		No Planned Activities
																	</kendo-gridlayout-item>
																}
															} @else if (selectedBasedOn === 'All') {
																@if (!isLookahead && (nonCriticalPlannedStart.get(lastUpdateId)?.length || nonCriticalPlanned.get(lastUpdateId)?.length)) {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value blue-value-bg'>
																		{{$nonCriticalPercentCompleteAll | async | number : '1.0-0' }}%
																	</kendo-gridlayout-item>
																} @else if (isLookahead && (selectedTimespan === 30 ? unfilteredLookahead30DisplaySetStart["Non Critical"]?.length + unfilteredLookahead30DisplaySet["Non Critical"]?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySetStart["Non Critical"]?.length + unfilteredLookahead60DisplaySet["Non Critical"]?.length : unfilteredLookahead90DisplaySetStart["Non Critical"]?.length + unfilteredLookahead90DisplaySet["Non Critical"]?.length)) {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value lookahead-label blue-value-bg-lookahead'>
																		<span class='lookahead-value'>
																			{{ selectedTimespan === 30 ? unfilteredLookahead30DisplaySetStart["Non Critical"]?.length + unfilteredLookahead30DisplaySet["Non Critical"]?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySetStart["Non Critical"]?.length + unfilteredLookahead60DisplaySet["Non Critical"]?.length : unfilteredLookahead90DisplaySetStart["Non Critical"]?.length + unfilteredLookahead90DisplaySet["Non Critical"]?.length }}
																		</span>
																		<span class='lookahead-unit'>
																			{{ selectedTimespan === 30 ? (unfilteredLookahead30DisplaySetStart["Non Critical"]?.length + unfilteredLookahead30DisplaySet["Non Critical"]?.length === 1 ? ' Activity' : ' Activities') : selectedTimespan === 60 ? (unfilteredLookahead60DisplaySetStart["Non Critical"]?.length + unfilteredLookahead60DisplaySet["Non Critical"]?.length === 1 ? ' Activity' : ' Activities') : (unfilteredLookahead90DisplaySetStart["Non Critical"]?.length + unfilteredLookahead90DisplaySet["Non Critical"]?.length === 1 ? ' Activity' : ' Activities') }}
																		</span>
																	</kendo-gridlayout-item>
																} @else {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value' [ngClass]="{'blue-value-bg' : !isLookahead, 'blue-value-bg-lookahead' : isLookahead}" style='font-size: 18px;'>
																		No Planned Activities
																	</kendo-gridlayout-item>
																}
															} @else {
																@if (!isLookahead && nonCriticalPlanned.get(lastUpdateId)?.length) {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value blue-value-bg'>
																		{{$nonCriticalPercentComplete | async | number : '1.0-0' }}%
																	</kendo-gridlayout-item>
																} @else if (isLookahead && (selectedTimespan === 30 ? unfilteredLookahead30DisplaySet["Non Critical"]?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySet["Non Critical"]?.length : unfilteredLookahead90DisplaySet["Non Critical"]?.length)) {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value lookahead-label blue-value-bg-lookahead'>
																	<span class='lookahead-value'>
																		{{ selectedTimespan === 30 ? unfilteredLookahead30DisplaySet["Non Critical"]?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySet["Non Critical"]?.length : unfilteredLookahead90DisplaySet["Non Critical"]?.length }}
																	</span>
																		<span class='lookahead-unit'>
																		{{ selectedTimespan === 30 ? (unfilteredLookahead30DisplaySet["Non Critical"]?.length === 1 ? ' Activity' : ' Activities') : selectedTimespan === 60 ? (unfilteredLookahead60DisplaySet["Non Critical"]?.length === 1 ? ' Activity' : ' Activities') : (unfilteredLookahead90DisplaySet["Non Critical"]?.length === 1 ? ' Activity' : ' Activities') }}
																	</span>
																	</kendo-gridlayout-item>
																} @else {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value' [ngClass]="{'blue-value-bg' : !isLookahead, 'blue-value-bg-lookahead' : isLookahead}" style='font-size: 18px;'>
																		No Planned Activities
																	</kendo-gridlayout-item>
																}
															}
														</kendo-gridlayout>
													</kendo-gridlayout-item>
													<kendo-gridlayout-item
														[row]='2'
														[col]='2'
														class='gridlayout-item'
														[class.selected-button]='tableView === ActvCompletionView.total'
														(click)='setTableView(ActvCompletionView.total)'
													>
														<kendo-gridlayout
															[rows]="['35%', '65%']"
															[cols]="['calc(50% - 2.5px)','calc(50% - 2.5px)']"
															[gap]="{ rows: 0, cols: 5 }"
															class='full-height full-width score-black'
															[class.score-black-lookahead]='isLookahead'
														>
															<kendo-gridlayout-item [row]='1' [col]='1' [colSpan]="2" class='grid-label'>
																Total
															</kendo-gridlayout-item>
															@if (selectedBasedOn === 'Start') {
																@if (!isLookahead && totalPlannedStart.get(lastUpdateId)?.length) {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value black-value-bg'>
																		{{$totalPercentCompleteStart | async | number : '1.0-0' }}%
																	</kendo-gridlayout-item>
																} @else if (isLookahead && (selectedTimespan === 30 ? unfilteredLookahead30DisplaySetStart.Total?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySetStart.Total?.length : unfilteredLookahead90DisplaySetStart.Total?.length)) {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value lookahead-label black-value-bg-lookahead'>
																	<span class='lookahead-value'>
																		{{ selectedTimespan === 30 ? unfilteredLookahead30DisplaySetStart.Total?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySetStart.Total?.length : unfilteredLookahead90DisplaySetStart.Total?.length }}
																	</span>
																		<span class='lookahead-unit'>
																		{{ selectedTimespan === 30 ? (unfilteredLookahead30DisplaySetStart.Total?.length === 1 ? ' Activity' : ' Activities') : selectedTimespan === 60 ? (unfilteredLookahead60DisplaySetStart.Total?.length === 1 ? ' Activity' : ' Activities') : (unfilteredLookahead90DisplaySetStart.Total?.length === 1 ? ' Activity' : ' Activities') }}
																	</span>
																	</kendo-gridlayout-item>
																} @else {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value' [ngClass]="{'black-value-bg' : !isLookahead, 'black-value-bg-lookahead' : isLookahead}" style='font-size: 18px;'>
																		No Planned Activities
																	</kendo-gridlayout-item>
																}
															} @else if (selectedBasedOn === 'All') {
																@if (!isLookahead && (totalPlannedStart.get(lastUpdateId)?.length || totalPlanned.get(lastUpdateId)?.length)) {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value black-value-bg'>
																		{{$totalPercentCompleteAll | async | number : '1.0-0' }}%
																	</kendo-gridlayout-item>
																} @else if (isLookahead && (selectedTimespan === 30 ? unfilteredLookahead30DisplaySetStart.Total?.length + unfilteredLookahead30DisplaySet.Total?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySetStart.Total?.length + unfilteredLookahead60DisplaySet.Total?.length : unfilteredLookahead90DisplaySetStart.Total?.length + unfilteredLookahead90DisplaySet.Total?.length)) {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value lookahead-label black-value-bg-lookahead'>
																		<span class='lookahead-value'>
																			{{ selectedTimespan === 30 ? unfilteredLookahead30DisplaySetStart.Total?.length + unfilteredLookahead30DisplaySet.Total?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySetStart.Total?.length + unfilteredLookahead60DisplaySet.Total?.length : unfilteredLookahead90DisplaySetStart.Total?.length + unfilteredLookahead90DisplaySet.Total?.length }}
																		</span>
																		<span class='lookahead-unit'>
																			{{ selectedTimespan === 30 ? (unfilteredLookahead30DisplaySetStart.Total?.length + unfilteredLookahead30DisplaySet.Total?.length === 1 ? ' Activity' : ' Activities') : selectedTimespan === 60 ? (unfilteredLookahead60DisplaySetStart.Total?.length + unfilteredLookahead60DisplaySet.Total?.length === 1 ? ' Activity' : ' Activities') : (unfilteredLookahead90DisplaySetStart.Total?.length + unfilteredLookahead90DisplaySet.Total?.length === 1 ? ' Activity' : ' Activities') }}
																		</span>
																	</kendo-gridlayout-item>
																} @else {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value' [ngClass]="{'black-value-bg' : !isLookahead, 'black-value-bg-lookahead' : isLookahead}" style='font-size: 18px;'>
																		No Planned Activities
																	</kendo-gridlayout-item>
																}
															} @else {
																@if (!isLookahead && totalPlanned.get(lastUpdateId)?.length) {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value black-value-bg'>
																		{{$totalPercentComplete | async | number : '1.0-0' }}%
																	</kendo-gridlayout-item>
																} @else if (isLookahead && (selectedTimespan === 30 ? unfilteredLookahead30DisplaySet.Total?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySet.Total?.length : unfilteredLookahead90DisplaySet.Total?.length)) {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value lookahead-label black-value-bg-lookahead'>
																	<span class='lookahead-value'>
																		{{ selectedTimespan === 30 ? unfilteredLookahead30DisplaySet.Total?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySet.Total?.length : unfilteredLookahead90DisplaySet.Total?.length }}
																	</span>
																		<span class='lookahead-unit'>
																		{{ selectedTimespan === 30 ? (unfilteredLookahead30DisplaySet.Total?.length === 1 ? ' Activity' : ' Activities') : selectedTimespan === 60 ? (unfilteredLookahead60DisplaySet.Total?.length === 1 ? ' Activity' : ' Activities') : (unfilteredLookahead90DisplaySet.Total?.length === 1 ? ' Activity' : ' Activities') }}
																	</span>
																	</kendo-gridlayout-item>
																} @else {
																	<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value' [ngClass]="{'black-value-bg' : !isLookahead, 'black-value-bg-lookahead' : isLookahead}" style='font-size: 18px;'>
																		No Planned Activities
																	</kendo-gridlayout-item>
																}
															}
														</kendo-gridlayout>
													</kendo-gridlayout-item>
												</kendo-gridlayout>
											</kendo-gridlayout-item>
										</kendo-gridlayout>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='2' style='padding: 5px 5px 5px 0'>
										<app-chart
											[title]='isLookahead ? (selectedTimespan === 30 ? "30 Day Planned Lookahead" : selectedTimespan === 60 ? "60 Day Planned Lookahead" : "90 Day Planned Lookahead") : "Last Period"'
											[categories]='categories'
											[seriesData]='selectedBasedOn === "Start" ? (isLookahead ? (selectedTimespan === 30 ? lookahead30SeriesDataStart : selectedTimespan === 60 ? lookahead60SeriesDataStart : lookahead90SeriesDataStart) : seriesDataStart) : selectedBasedOn === "All" ? (isLookahead ? (selectedTimespan === 30 ? lookahead30SeriesDataAll : selectedTimespan === 60 ? lookahead60SeriesDataAll : lookahead90SeriesDataAll) : seriesDataAll) : (isLookahead ? (selectedTimespan === 30 ? lookahead30SeriesData : selectedTimespan === 60 ? lookahead60SeriesData : lookahead90SeriesData) : seriesData)'
											[legendVisible]='false'
											[valueAxis]='valueAxisItemSettings'
											[isActivityCompletionChart]='true'
											*ngIf='tableView === "overview"'
										></app-chart>
										<div *ngIf='tableView !== "overview"'>
											<div>
												<kendo-textbox
													class='searchInput'
													[placeholder]="'Search ' + tableView + '...'"
													[clearButton]="true"
													[size]='"large"'
													[(value)]='searchTerm'
													(valueChange)='updateFilter({searchTerm: $event})'
													[disabled]='loading'
												>
													<ng-template kendoTextBoxPrefixTemplate>
														<kendo-svgicon [icon]='svgSearch'></kendo-svgicon>
														<kendo-textbox-separator></kendo-textbox-separator>
													</ng-template>
												</kendo-textbox>
												<div style="text-align: center; font-size: large; padding: 5px 0;">
													<span>{{ tableView }} - Showing {{ currentDisplaySet.length || 0 }} of {{ selectedBasedOn === 'Start' ? (isLookahead ? (selectedTimespan === 30 ? unfilteredLookahead30DisplaySetStart[tableView]?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySetStart[tableView]?.length : unfilteredLookahead90DisplaySetStart[tableView]?.length) : unfilteredDisplaySetStart[tableView]?.length) : selectedBasedOn === "All" ? (isLookahead ? (selectedTimespan === 30 ? combine(unfilteredLookahead30DisplaySetStart[tableView], unfilteredLookahead30DisplaySet[tableView])?.length : selectedTimespan === 60 ? combine(unfilteredLookahead60DisplaySetStart[tableView], unfilteredLookahead60DisplaySet[tableView])?.length : combine(unfilteredLookahead90DisplaySetStart[tableView], unfilteredLookahead90DisplaySet[tableView])?.length) : combine(unfilteredDisplaySetStart[tableView], unfilteredDisplaySet[tableView])?.length) : (isLookahead ? (selectedTimespan === 30 ? unfilteredLookahead30DisplaySet[tableView]?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySet[tableView]?.length : unfilteredLookahead90DisplaySet[tableView]?.length) : unfilteredDisplaySet[tableView]?.length) || 0 }}</span>
												</div>
												<kendo-grid
													[data]="gridView"
													[kendoGridBinding]="gridData"
													[height]="264"
													[loading]='loading'
													[size]='"small"'
													[sortable]='true'
													[sort]="sort"
													(sortChange)="sortChange($event)"
													[skip]="skip"
													[pageSize]="pageSize"
													[navigable]="false"
													scrollable="virtual"
													[rowHeight]="26"
													class='activity-grid'
												>
													<kendo-grid-column
														*ngFor='let column of selectedColumns'
														[field]="column.field"
														[title]="column.title"
														[width]='column.width'
														[format]="column?.format"
														headerClass="header-class"
													>
														@if (column?.type === 'actIfPossible') {
															<!--See comment in fitDataToType function for explanation on this change below-->
															<ng-template kendoGridCellTemplate let-dataItem>
																{{dataItem[column.isActual] ? (dataItem[column.field] | date: 'MM/dd/yyyy') + ' A' : (dataItem[column.field] | date: 'MM/dd/yyyy')}}
															</ng-template>
														}
													</kendo-grid-column>
												</kendo-grid>
											</div>
											<div style='width: 100%;text-align: center;height: 350px; display: flex;justify-content: center; align-items: center'>
												Project is missing previous expanded metrics. Try recalculating report!
											</div>

										</div>
									</kendo-gridlayout-item>
								</kendo-gridlayout>
							} @else {
								<kendo-gridlayout
									[rows]="['100%']"
									[cols]="['calc(50% - 2.5px)', 'calc(50% - 2.5px)']"
									[gap]="{ rows: 0, cols: 5 }"
									class='full-height full-width'
								>
									<kendo-gridlayout-item [row]='1' [col]='1' style='padding: 5px 0 5px 5px'>
										<kendo-gridlayout
											[rows]="['42px', 'calc(100% - 47px)']"
											[cols]="['auto']"
											[gap]="{ rows: 5, cols: 0 }"
											class='full-height full-height'
										>
											<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-item'>
												<div class="center-row" style='background-color: #001489; border-radius: 4px;'>
													Early Start
												</div>
											</kendo-gridlayout-item>
											<kendo-gridlayout-item [row]='2' [col]='1'>
												<kendo-gridlayout
													[rows]="['100%']"
													[cols]="['calc(65% - 2.5px)','calc(35% - 2.5px)']"
													[gap]="{ rows: 0, cols: 5 }"
													class='full-height full-height'
												>
													<kendo-gridlayout-item [row]="1" [col]="1">
														<kendo-gridlayout
															[rows]="['calc(50% - 2.5px)', 'calc(50% - 2.5px)']"
															[cols]="['calc(50% - 2.5px)', 'calc(50% - 2.5px)']"
															[gap]="{ rows: 5, cols: 5 }"
															class='full-height full-height'
														>
															<kendo-gridlayout-item
																[row]='1'
																[col]='1'
																class='gridlayout-item'
															>
																<kendo-gridlayout
																	[rows]="['35%', '65%']"
																	[cols]="['calc(50% - 2.5px)','calc(50% - 2.5px)']"
																	[gap]="{ rows: 0, cols: 5 }"
																	class='full-height full-width score-red'
																	[class.score-red-lookahead]='isLookahead'
																>
																	<kendo-gridlayout-item [row]='1' [col]='1' [colSpan]="2" class='grid-label'>
																		Critical
																	</kendo-gridlayout-item>
																	@if (!isLookahead && criticalPlannedStart.get(lastUpdateId)?.length) {
																		<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value red-value-bg'>
																			{{$criticalPercentCompleteStart | async | number : '1.0-0' }}%
																		</kendo-gridlayout-item>
																	} @else if (isLookahead && (selectedTimespan === 30 ? unfilteredLookahead30DisplaySetStart.Critical?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySetStart.Critical?.length : unfilteredLookahead90DisplaySetStart.Critical?.length)) {
																		<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value lookahead-label red-value-bg-lookahead'>
																	<span class='lookahead-value'>
																		{{ selectedTimespan === 30 ? unfilteredLookahead30DisplaySetStart.Critical?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySetStart.Critical?.length : unfilteredLookahead90DisplaySetStart.Critical?.length }}
																	</span>
																			<span class='lookahead-unit'>
																		{{ selectedTimespan === 30 ? (unfilteredLookahead30DisplaySetStart.Critical?.length === 1 ? ' Activity' : ' Activities') : selectedTimespan === 60 ? (unfilteredLookahead60DisplaySetStart.Critical?.length === 1 ? ' Activity' : ' Activities') : (unfilteredLookahead90DisplaySetStart.Critical?.length === 1 ? ' Activity' : ' Activities') }}
																	</span>
																		</kendo-gridlayout-item>
																	} @else {
																		<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value' [ngClass]="{'red-value-bg' : !isLookahead, 'red-value-bg-lookahead' : isLookahead}" style='font-size: 18px;'>
																			No Planned Activities
																		</kendo-gridlayout-item>
																	}
																</kendo-gridlayout>
															</kendo-gridlayout-item>
															<kendo-gridlayout-item
																[row]='1'
																[col]='2'
																class='gridlayout-item'
															>
																<kendo-gridlayout
																	[rows]="['35%', '65%']"
																	[cols]="['calc(50% - 2.5px)','calc(50% - 2.5px)']"
																	[gap]="{ rows: 0, cols: 5 }"
																	class='full-height full-width score-green'
																	[class.score-green-lookahead]='isLookahead'
																>
																	<kendo-gridlayout-item [row]='1' [col]='1' [colSpan]="2" class='grid-label'>
																		Near Critical
																	</kendo-gridlayout-item>
																	@if (!isLookahead && nearCriticalPlannedStart.get(lastUpdateId)?.length) {
																		<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value green-value-bg'>
																			{{$nearCriticalPercentCompleteStart | async | number : '1.0-0' }}%
																		</kendo-gridlayout-item>
																	} @else if (isLookahead && (selectedTimespan === 30 ? unfilteredLookahead30DisplaySetStart["Near Critical"]?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySetStart["Near Critical"]?.length : unfilteredLookahead90DisplaySetStart["Near Critical"]?.length)) {
																		<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value lookahead-label green-value-bg-lookahead'>
																	<span class='lookahead-value'>
																		{{ selectedTimespan === 30 ? unfilteredLookahead30DisplaySetStart["Near Critical"]?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySetStart["Near Critical"]?.length : unfilteredLookahead90DisplaySetStart["Near Critical"]?.length }}
																	</span>
																			<span class='lookahead-unit'>
																		{{ selectedTimespan === 30 ? (unfilteredLookahead30DisplaySetStart["Near Critical"]?.length === 1 ? ' Activity' : ' Activities') : selectedTimespan === 60 ? (unfilteredLookahead60DisplaySetStart["Near Critical"]?.length === 1 ? ' Activity' : ' Activities') : (unfilteredLookahead90DisplaySetStart["Near Critical"]?.length === 1 ? ' Activity' : ' Activities') }}
																	</span>
																		</kendo-gridlayout-item>
																	} @else {
																		<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value' [ngClass]="{'green-value-bg' : !isLookahead, 'green-value-bg-lookahead' : isLookahead}" style='font-size: 18px;'>
																			No Planned Activities
																		</kendo-gridlayout-item>
																	}
																</kendo-gridlayout>
															</kendo-gridlayout-item>
															<kendo-gridlayout-item
																[row]='2'
																[col]='1'
																class='gridlayout-item'
															>
																<kendo-gridlayout
																	[rows]="['35%', '65%']"
																	[cols]="['calc(50% - 2.5px)','calc(50% - 2.5px)']"
																	[gap]="{ rows: 0, cols: 5 }"
																	class='full-height full-width score-blue'
																	[class.score-blue-lookahead]='isLookahead'
																>
																	<kendo-gridlayout-item [row]='1' [col]='1' [colSpan]="2" class='grid-label'>
																		Non Critical
																	</kendo-gridlayout-item>
																	@if (!isLookahead && nonCriticalPlannedStart.get(lastUpdateId)?.length) {
																		<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value blue-value-bg'>
																			{{$nonCriticalPercentCompleteStart | async | number : '1.0-0' }}%
																		</kendo-gridlayout-item>
																	} @else if (isLookahead && (selectedTimespan === 30 ? unfilteredLookahead30DisplaySetStart["Non Critical"]?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySetStart["Non Critical"]?.length : unfilteredLookahead90DisplaySetStart["Non Critical"]?.length)) {
																		<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value lookahead-label blue-value-bg-lookahead'>
																	<span class='lookahead-value'>
																		{{ selectedTimespan === 30 ? unfilteredLookahead30DisplaySetStart["Non Critical"]?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySetStart["Non Critical"]?.length : unfilteredLookahead90DisplaySetStart["Non Critical"]?.length }}
																	</span>
																			<span class='lookahead-unit'>
																		{{ selectedTimespan === 30 ? (unfilteredLookahead30DisplaySetStart["Non Critical"]?.length === 1 ? ' Activity' : ' Activities') : selectedTimespan === 60 ? (unfilteredLookahead60DisplaySetStart["Non Critical"]?.length === 1 ? ' Activity' : ' Activities') : (unfilteredLookahead90DisplaySetStart["Non Critical"]?.length === 1 ? ' Activity' : ' Activities') }}
																	</span>
																		</kendo-gridlayout-item>
																	} @else {
																		<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value' [ngClass]="{'blue-value-bg' : !isLookahead, 'blue-value-bg-lookahead' : isLookahead}" style='font-size: 18px;'>
																			No Planned Activities
																		</kendo-gridlayout-item>
																	}
																</kendo-gridlayout>
															</kendo-gridlayout-item>
															<kendo-gridlayout-item
																[row]='2'
																[col]='2'
																class='gridlayout-item'
															>
																<kendo-gridlayout
																	[rows]="['35%', '65%']"
																	[cols]="['calc(50% - 2.5px)','calc(50% - 2.5px)']"
																	[gap]="{ rows: 0, cols: 5 }"
																	class='full-height full-width score-black'
																	[class.score-black-lookahead]='isLookahead'
																>
																	<kendo-gridlayout-item [row]='1' [col]='1' [colSpan]="2" class='grid-label'>
																		Total
																	</kendo-gridlayout-item>
																	@if (!isLookahead && totalPlannedStart.get(lastUpdateId)?.length) {
																		<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value black-value-bg'>
																			{{$totalPercentCompleteStart | async | number : '1.0-0' }}%
																		</kendo-gridlayout-item>
																	} @else if (isLookahead && (selectedTimespan === 30 ? unfilteredLookahead30DisplaySetStart.Total?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySetStart.Total?.length : unfilteredLookahead90DisplaySetStart.Total?.length)) {
																		<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value lookahead-label black-value-bg-lookahead'>
																	<span class='lookahead-value'>
																		{{ selectedTimespan === 30 ? unfilteredLookahead30DisplaySetStart.Total?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySetStart.Total?.length : unfilteredLookahead90DisplaySetStart.Total?.length }}
																	</span>
																			<span class='lookahead-unit'>
																		{{ selectedTimespan === 30 ? (unfilteredLookahead30DisplaySetStart.Total?.length === 1 ? ' Activity' : ' Activities') : selectedTimespan === 60 ? (unfilteredLookahead60DisplaySetStart.Total?.length === 1 ? ' Activity' : ' Activities') : (unfilteredLookahead90DisplaySetStart.Total?.length === 1 ? ' Activity' : ' Activities') }}
																	</span>
																		</kendo-gridlayout-item>
																	} @else {
																		<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value' [ngClass]="{'black-value-bg' : !isLookahead, 'black-value-bg-lookahead' : isLookahead}" style='font-size: 18px;'>
																			No Planned Activities
																		</kendo-gridlayout-item>
																	}
																</kendo-gridlayout>
															</kendo-gridlayout-item>
														</kendo-gridlayout>
													</kendo-gridlayout-item>
													<kendo-gridlayout-item [row]="1" [col]="2" class="k-overflow-y-scroll">
														<kendo-treelist
															kendoTreeListExpandable
															[kendoTreeListHierarchyBinding]="nonDisabledActivityCodes"
															[initiallyExpanded]="true"
															childrenField='subCodes'
														>
															<kendo-treelist-column
																[expandable]="true"
																[title]="'Code'"
																width="90"
																field="name"
															></kendo-treelist-column>
															<kendo-treelist-column
																[title]="'Total'"
																width="50"
															>
																<ng-template kendoTreeListCellTemplate let-dataItem>
																	@if (!isLookahead) {
																		{{startTotalPerCode.get(dataItem.name) | number : '1.0-0'}}%
																	} @else {
																		{{ selectedTimespan === 30 ? start30TotalPerCode.get(dataItem.name) : selectedTimespan === 60 ? start60TotalPerCode.get(dataItem.name) : start90TotalPerCode.get(dataItem.name) }}
																	}
																</ng-template>
															</kendo-treelist-column>
														</kendo-treelist>
													</kendo-gridlayout-item>
												</kendo-gridlayout>
											</kendo-gridlayout-item>
										</kendo-gridlayout>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='2' style='padding: 5px 5px 5px 0'>
										<kendo-gridlayout
											[rows]="['42px', 'calc(100% - 47px)']"
											[cols]="['auto']"
											[gap]="{ rows: 5, cols: 0 }"
											class='full-height full-height'
										>
											<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-item'>
												<div class="center-row" style='background-color: #001489; border-radius: 4px;'>
													Early Finish
												</div>
											</kendo-gridlayout-item>
											<kendo-gridlayout-item [row]='2' [col]='1'>
												<kendo-gridlayout
													[rows]="['100%']"
													[cols]="['calc(65% - 2.5px)','calc(35% - 2.5px)']"
													[gap]="{ rows: 0, cols: 5 }"
													class='full-height full-height'
												>
													<kendo-gridlayout-item [row]="1" [col]="1">
														<kendo-gridlayout
															[rows]="['calc(50% - 2.5px)', 'calc(50% - 2.5px)']"
															[cols]="['calc(50% - 2.5px)', 'calc(50% - 2.5px)']"
															[gap]="{ rows: 5, cols: 5 }"
															class='full-height full-height'
														>
															<kendo-gridlayout-item
																[row]='1'
																[col]='1'
																class='gridlayout-item'
															>
																<kendo-gridlayout
																	[rows]="['35%', '65%']"
																	[cols]="['calc(50% - 2.5px)','calc(50% - 2.5px)']"
																	[gap]="{ rows: 0, cols: 5 }"
																	class='full-height full-width score-red'
																	[class.score-red-lookahead]='isLookahead'
																>
																	<kendo-gridlayout-item [row]='1' [col]='1' [colSpan]="2" class='grid-label'>
																		Critical
																	</kendo-gridlayout-item>
																	@if (!isLookahead && criticalPlanned.get(lastUpdateId)?.length) {
																		<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value red-value-bg'>
																			{{$criticalPercentComplete | async | number : '1.0-0' }}%
																		</kendo-gridlayout-item>
																	} @else if (isLookahead && (selectedTimespan === 30 ? unfilteredLookahead30DisplaySet.Critical?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySet.Critical?.length : unfilteredLookahead90DisplaySet.Critical?.length)) {
																		<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value lookahead-label red-value-bg-lookahead'>
																	<span class='lookahead-value'>
																		{{ selectedTimespan === 30 ? unfilteredLookahead30DisplaySet.Critical?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySet.Critical?.length : unfilteredLookahead90DisplaySet.Critical?.length }}
																	</span>
																			<span class='lookahead-unit'>
																		{{ selectedTimespan === 30 ? (unfilteredLookahead30DisplaySet.Critical?.length === 1 ? ' Activity' : ' Activities') : selectedTimespan === 60 ? (unfilteredLookahead60DisplaySet.Critical?.length === 1 ? ' Activity' : ' Activities') : (unfilteredLookahead90DisplaySet.Critical?.length === 1 ? ' Activity' : ' Activities') }}
																	</span>
																		</kendo-gridlayout-item>
																	} @else {
																		<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value' [ngClass]="{'red-value-bg' : !isLookahead, 'red-value-bg-lookahead' : isLookahead}" style='font-size: 18px;'>
																			No Planned Activities
																		</kendo-gridlayout-item>
																	}
																</kendo-gridlayout>
															</kendo-gridlayout-item>
															<kendo-gridlayout-item
																[row]='1'
																[col]='2'
																class='gridlayout-item'
															>
																<kendo-gridlayout
																	[rows]="['35%', '65%']"
																	[cols]="['calc(50% - 2.5px)','calc(50% - 2.5px)']"
																	[gap]="{ rows: 0, cols: 5 }"
																	class='full-height full-width score-green'
																	[class.score-green-lookahead]='isLookahead'
																>
																	<kendo-gridlayout-item [row]='1' [col]='1' [colSpan]="2" class='grid-label'>
																		Near Critical
																	</kendo-gridlayout-item>
																	@if (!isLookahead && nearCriticalPlanned.get(lastUpdateId)?.length) {
																		<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value green-value-bg'>
																			{{$nearCriticalPercentComplete | async | number : '1.0-0' }}%
																		</kendo-gridlayout-item>
																	} @else if (isLookahead && (selectedTimespan === 30 ? unfilteredLookahead30DisplaySet["Near Critical"]?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySet["Near Critical"]?.length : unfilteredLookahead90DisplaySet["Near Critical"]?.length)) {
																		<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value lookahead-label green-value-bg-lookahead'>
																	<span class='lookahead-value'>
																		{{ selectedTimespan === 30 ? unfilteredLookahead30DisplaySet["Near Critical"]?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySet["Near Critical"]?.length : unfilteredLookahead90DisplaySet["Near Critical"]?.length }}
																	</span>
																			<span class='lookahead-unit'>
																		{{ selectedTimespan === 30 ? (unfilteredLookahead30DisplaySet["Near Critical"]?.length === 1 ? ' Activity' : ' Activities') : selectedTimespan === 60 ? (unfilteredLookahead60DisplaySet["Near Critical"]?.length === 1 ? ' Activity' : ' Activities') : (unfilteredLookahead90DisplaySet["Near Critical"]?.length === 1 ? ' Activity' : ' Activities') }}
																	</span>
																		</kendo-gridlayout-item>
																	} @else {
																		<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value' [ngClass]="{'green-value-bg' : !isLookahead, 'green-value-bg-lookahead' : isLookahead}" style='font-size: 18px;'>
																			No Planned Activities
																		</kendo-gridlayout-item>
																	}
																</kendo-gridlayout>
															</kendo-gridlayout-item>
															<kendo-gridlayout-item
																[row]='2'
																[col]='1'
																class='gridlayout-item'
															>
																<kendo-gridlayout
																	[rows]="['35%', '65%']"
																	[cols]="['calc(50% - 2.5px)','calc(50% - 2.5px)']"
																	[gap]="{ rows: 0, cols: 5 }"
																	class='full-height full-width score-blue'
																	[class.score-blue-lookahead]='isLookahead'
																>
																	<kendo-gridlayout-item [row]='1' [col]='1' [colSpan]="2" class='grid-label'>
																		Non Critical
																	</kendo-gridlayout-item>
																	@if (!isLookahead && nonCriticalPlanned.get(lastUpdateId)?.length) {
																		<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value blue-value-bg'>
																			{{$nonCriticalPercentComplete | async | number : '1.0-0' }}%
																		</kendo-gridlayout-item>
																	} @else if (isLookahead && (selectedTimespan === 30 ? unfilteredLookahead30DisplaySet["Non Critical"]?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySet["Non Critical"]?.length : unfilteredLookahead90DisplaySet["Non Critical"]?.length)) {
																		<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value lookahead-label blue-value-bg-lookahead'>
																	<span class='lookahead-value'>
																		{{ selectedTimespan === 30 ? unfilteredLookahead30DisplaySet["Non Critical"]?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySet["Non Critical"]?.length : unfilteredLookahead90DisplaySet["Non Critical"]?.length }}
																	</span>
																			<span class='lookahead-unit'>
																		{{ selectedTimespan === 30 ? (unfilteredLookahead30DisplaySet["Non Critical"]?.length === 1 ? ' Activity' : ' Activities') : selectedTimespan === 60 ? (unfilteredLookahead60DisplaySet["Non Critical"]?.length === 1 ? ' Activity' : ' Activities') : (unfilteredLookahead90DisplaySet["Non Critical"]?.length === 1 ? ' Activity' : ' Activities') }}
																	</span>
																		</kendo-gridlayout-item>
																	} @else {
																		<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value' [ngClass]="{'blue-value-bg' : !isLookahead, 'blue-value-bg-lookahead' : isLookahead}" style='font-size: 18px;'>
																			No Planned Activities
																		</kendo-gridlayout-item>
																	}
																</kendo-gridlayout>
															</kendo-gridlayout-item>
															<kendo-gridlayout-item
																[row]='2'
																[col]='2'
																class='gridlayout-item'
															>
																<kendo-gridlayout
																	[rows]="['35%', '65%']"
																	[cols]="['calc(50% - 2.5px)','calc(50% - 2.5px)']"
																	[gap]="{ rows: 0, cols: 5 }"
																	class='full-height full-width score-black'
																	[class.score-black-lookahead]='isLookahead'
																>
																	<kendo-gridlayout-item [row]='1' [col]='1' [colSpan]="2" class='grid-label'>
																		Total
																	</kendo-gridlayout-item>
																	@if (!isLookahead && totalPlanned.get(lastUpdateId)?.length) {
																		<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value black-value-bg'>
																			{{$totalPercentComplete | async | number : '1.0-0' }}%
																		</kendo-gridlayout-item>
																	} @else if (isLookahead && (selectedTimespan === 30 ? unfilteredLookahead30DisplaySet.Total?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySet.Total?.length : unfilteredLookahead90DisplaySet.Total?.length)) {
																		<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value lookahead-label black-value-bg-lookahead'>
																	<span class='lookahead-value'>
																		{{ selectedTimespan === 30 ? unfilteredLookahead30DisplaySet.Total?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySet.Total?.length : unfilteredLookahead90DisplaySet.Total?.length }}
																	</span>
																			<span class='lookahead-unit'>
																		{{ selectedTimespan === 30 ? (unfilteredLookahead30DisplaySet.Total?.length === 1 ? ' Activity' : ' Activities') : selectedTimespan === 60 ? (unfilteredLookahead60DisplaySet.Total?.length === 1 ? ' Activity' : ' Activities') : (unfilteredLookahead90DisplaySet.Total?.length === 1 ? ' Activity' : ' Activities') }}
																	</span>
																		</kendo-gridlayout-item>
																	} @else {
																		<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="2" class='grid-value' [ngClass]="{'black-value-bg' : !isLookahead, 'black-value-bg-lookahead' : isLookahead}" style='font-size: 18px;'>
																			No Planned Activities
																		</kendo-gridlayout-item>
																	}
																</kendo-gridlayout>
															</kendo-gridlayout-item>
														</kendo-gridlayout>
													</kendo-gridlayout-item>
													<kendo-gridlayout-item [row]="1" [col]="2" class="k-overflow-y-scroll">
														<kendo-treelist
															kendoTreeListExpandable
															[kendoTreeListHierarchyBinding]="nonDisabledActivityCodes"
															[initiallyExpanded]="true"
															childrenField='subCodes'
														>
															<kendo-treelist-column
																[expandable]="true"
																[title]="'Code'"
																width="90"
																field="name"
															></kendo-treelist-column>
															<kendo-treelist-column
																[title]="'Total'"
																width="50"
															>
																<ng-template kendoTreeListCellTemplate let-dataItem>
																	@if (!isLookahead) {
																		{{finishTotalPerCode.get(dataItem.name) | number : '1.0-0'}}%
																	} @else {
																		{{ selectedTimespan === 30 ? finish30TotalPerCode.get(dataItem.name) : selectedTimespan === 60 ? finish60TotalPerCode.get(dataItem.name) : finish90TotalPerCode.get(dataItem.name) }}
																	}
																</ng-template>
															</kendo-treelist-column>
														</kendo-treelist>
														<!--<kendo-multiselecttree
															#activityCodesMultiselectTree
															kendoMultiSelectTreeSummaryTag
															kendoMultiSelectTreeExpandable
															[kendoMultiSelectTreeHierarchyBinding]="allActivityCodes"
															[(value)]="selectedActivityCodes"
															textField="name"
															valueField="id"
															childrenField='subCodes'
															[valuePrimitive]='false'
															[filterable]='true'
															(valueChange)='filterChanged($event);'
															[clearButton]='false'
															[expandOnFilter]="{expandMatches: true}"
															[itemDisabled]="itemDisabled"
															[placeholder]='"Activity Code Filter"'
															style='width: 200px; margin: auto 0; cursor: pointer;'
															[disabled]='((project.$currentProjectData | async)?.updateIds.length || 0) <= 1'
														>
															<ng-template kendoMultiSelectTreeGroupTagTemplate let-dataItems>
												<span>
													<kendo-svg-icon [icon]='icons.caretDown'></kendo-svg-icon>
													{{ codesTag }}
												</span>
															</ng-template>
														</kendo-multiselecttree>-->
													</kendo-gridlayout-item>
												</kendo-gridlayout>
											</kendo-gridlayout-item>
										</kendo-gridlayout>
									</kendo-gridlayout-item>
								</kendo-gridlayout>
							}
						</kendo-gridlayout-item>
					} @else if ((project.$currentProjectReport | async)?.updateIds?.length <= 1) {
						<kendo-gridlayout-item [row]='2' [col]='1'>
							<div class='warning-div'>
								Activity Completion requires at least 1 update to enable
							</div>
						</kendo-gridlayout-item>
					}
				</kendo-gridlayout>
			</div>
		</kendo-gridlayout-item>
		@if (isOverview && hasNotes) {
			<app-overview-notes [componentId]="7" [squareBrTop]="true"></app-overview-notes>
		}
	}
	@if (!hideTrending) {
		<kendo-gridlayout-item [row]='hideBars ? 1 : 2' [col]='1'>
			<div class='card' id="activityCompletionTrendingComponent" [class.overview-br-top]="isOverview && hasNotesTrending">
				<kendo-gridlayout
					[rows]="(project.$currentProjectReport | async)?.updateIds?.length <= 1 ? ['42.4px', '150px'] : ['42.4px', '303px']"
					[cols]="['100%']"
					[gap]="{ rows: 0, cols: 0 }"
					class='full-height full-width'
				>
					<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' [class.overviewHeader]="isOverview">
						<kendo-gridlayout
							[rows]="['42.4px']"
							[cols]="['300px', 'calc(100% - 315px)']"
							[gap]="{ rows: 0, cols: 5 }"
							class='full-width'
						>
							<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
								<div class="card-header">
									Activity Completion Trending
								</div>
								<tooltip-open type='{{isOverview ? "overview" : "activity-completion-trending"}}' [fillColor]="isOverview ? 'overview' : ''"></tooltip-open>
							</kendo-gridlayout-item>
							<kendo-gridlayout-item [row]='1' [col]='2' class='toolbar-item-container' style="margin: auto; height: min-content; justify-content: end">
								<span style='display: inline-flex; align-items: center;float: right; gap: 10px;' kendoTooltip>
									@if (isOverview) {
										<button kendoButton themeColor="base" title="Go to source" [imageUrl]="'assets/icons/newIcons/noun-arrow-5544651.svg'" class='header-btn' (click)='project.goTo(8)' style="background-color: #ffb500;"></button>
									}
									<button kendoButton themeColor="base" title="Add/Edit Notes" [imageUrl]="'assets/icons/newIcons/noun-notepad-6607257.svg'" class='header-btn overview-notes-icon' (click)='project.openNotesWindow({id: 8, name: "Activity Completion Trending"})' [style.background-color]="isOverview ? '#ffb500' : 'white'"></button>
								</span>
							</kendo-gridlayout-item>
						</kendo-gridlayout>
					</kendo-gridlayout-item>
					<kendo-gridlayout-item [row]='2' [col]='1'>
						<div class='chart-container' *ngIf='(project.$currentProjectReport | async)?.updateIds?.length > 1'>
							<app-chart
								[title]="'blank'"
								[categories]='trendCategories'
								[seriesData]='selectedBasedOn === "Start" ? trendSeriesDataStart : selectedBasedOn === "Finish" ? trendSeriesData : allTotalSeriesData'
								[legendVisible]='true'
								[valueAxis]='trendValueAxisItemSettings'
								[plotLines]='selectedBasedOn !== "All" ? trendPlotLines : null'
								(legendItemClicked)='setTableView($event)'
							></app-chart>
						</div>
						<div *ngIf='(project.$currentProjectReport | async)?.updateIds?.length <= 1' class='warning-div' style='height: 150px;'>
							Activity Completion Trend requires at least 1 update to enable
						</div>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			</div>
		</kendo-gridlayout-item>
		@if (isOverview && hasNotesTrending) {
			<app-overview-notes [componentId]="8" [squareBrTop]="true"></app-overview-notes>
		}
	}
</kendo-gridlayout>
