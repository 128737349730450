import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

import { Register2Component } from 'components/authentication/register-2/register-2.component';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
	declarations: [Register2Component],
	imports: [
		CommonModule,
		MatIconModule,
		RouterModule,
		MatCheckboxModule,
		MatInputModule,
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
	],
})
export class Register2Module {}
