<div class='import-dialog-content-container'>
	<div class='content-description'>
		Download the template below or export your p6 risks to excel and then save them as a .csv file. Make sure to export all columns (column order doesn't matter).
	</div>
	<div class='import-options'>
		<div class='content-title'>
			Reactive Risk Template
		</div>
		<div class='content-description'>
			This import matches the native Reactive Risk Export.
		</div>
		<div class='download-container'>
			<a target='_blank' class='file-col' [matTooltip]="'Download Template'" matTooltipShowDelay='500' href='https://cdn-rhino-works.s3.amazonaws.com/Reactive+Risk+Import+Template.csv'>
				Download Template
			</a>
		</div>
	</div>
	<div class='upload-container'>
		<input
			type="file"
			name="fileUpload"
			accept='.csv'
			[(ngModel)]="importedFiles"
			[ngModelOptions]="{ standalone: true }"
			(change)="handleUploadChange($event);"
			class='input-upload'
		/>
	</div>
	<div class='results-section' *ngIf='newRegisters.length > 0'>
		<div *ngIf='requiredHeadersMissing.length > 0' class='missing-headers-list'>
			<div>Some required headers are missing.</div>
			<ul class='unordered-list' style='padding-left: 120px'>
				<li class='list-item' *ngFor='let header of requiredHeadersMissing'>{{isP6Export ? header.excelField : header.reactiveRiskField}}</li>
			</ul>
		</div>
		<div *ngIf='requiredHeadersMissing.length === 0 && newRegistersWithInvalidFields.length > 0' class='invalid-fields-list'>
			{{newRegistersWithInvalidFields.length}} {{newRegistersWithInvalidFields.length > 1 ? 'rows contain' : 'row contains'}} incomplete or invalid entries.
			<ol class='ordered-list'>
				<li class='list-item' *ngFor='let invalidRegister of newRegistersWithInvalidFields'>
					{{invalidRegister.register.riskName}}<br *ngIf="invalidRegister.register.riskName === ''">
					<ul class='unordered-list'>
						<li class='list-item' *ngFor='let invalidField of invalidRegister.fields'>{{isP6Export ? invalidField.excelField : invalidField.reactiveRiskField}}</li>
					</ul>
				</li>
			</ol>
		</div>
		<div *ngIf='requiredHeadersMissing.length > 0 || newRegistersWithInvalidFields.length > 0'>
			Choose another file or save risks as drafts.
		</div>
		<div *ngIf='requiredHeadersMissing.length === 0 && newRegistersWithInvalidFields.length === 0 && newValidRegisters.length > 0' class='valid-registers-list'>
			{{newValidRegisters.length}} risks are valid and ready to import.
		</div>
		<div class='save-button-container'>
			<button kendoButton fillMode="solid" themeColor='success' (click)='submitRegisters()' [disabled]='submitClicked'>Save As Draft</button>
		</div>
	</div>
</div>
<kendo-dialog
	*ngIf="idOverwriteDialogOpen"
	[width]="1000"
	style="z-index: 99999;"
	class='hide-title-actions'
>
	<kendo-dialog-titlebar>
		<div class="k-dialog-title">Risk Id Conflicts</div>
	</kendo-dialog-titlebar>

	<app-overwrite-dialog [conflictingRisks]='conflictingRisks' (confirm)='confirmOverwrites($event)'></app-overwrite-dialog>
</kendo-dialog>
