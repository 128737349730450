import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	constructor(private snackBar: MatSnackBar) {}

	showNotification(
		message: string,
		action: string = 'X',
		horizontalPosition: MatSnackBarHorizontalPosition = 'right',
		verticalPosition: MatSnackBarVerticalPosition = 'top'
	): void {
		const isScreenshotMode: boolean = localStorage.getItem('isScreenshotMode') === 'true';
		if (!isScreenshotMode) {
			this.snackBar.open(message, action, {
				duration: 2000,
				horizontalPosition,
				verticalPosition,
			});
		}
	}
}
