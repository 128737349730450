<div id="dashboard-analytics" class="page-layout blank no-select none-expanded" >
	<kendo-gridlayout
		[rows]="['164.4px', isProjectExpanded ? 'calc(100% + 21px)'  : 'calc(100% + 81px)']"
		[cols]="['auto', '1fr']"
		[gap]="{ rows: 5, cols: 10 }"
		class='portfolio-grid'
	>
		<kendo-gridlayout-item [row]='1' [col]='1'>
			<div class='view-btn-group'>
				<div class='fake-slider' [class.move-to-proj]='currentBanner === "default"' [class.move-to-cost]='currentBanner === "cost"' [class.move-to-risk]='currentBanner === "risk"'></div>
				<button kendoButton title="All Projects" [disabled]='loading' (click)='updatePortfolioView("default")' class='view-btn default-btn' [class.default-selected]='currentBanner === "default"'>All</button>
				<div class="view-button-divider"></div>
				<button kendoButton title="Cost-Loaded Projects" [disabled]='loading' (click)='updatePortfolioView("cost")' class='view-btn cost-btn' [class.cost-selected]='currentBanner === "cost"'>Cost</button>
				<div class="view-button-divider"></div>
				<button kendoButton title="Risk Projects" [disabled]='loading' (click)='updatePortfolioView("risk")' class='view-btn risk-btn' [class.risk-selected]='currentBanner === "risk"'>Risk</button>
			</div>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-container'>
			<kendo-gridlayout
				[rows]="['164.4px']"
				[cols]="['100%']"
				[gap]="{ rows: 0, cols: 0 }"
				class='portfolio-grid'
				[class.default-banner-enabled]='user?.userType !== "saasRisk" && currentBanner === "default"'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-container' *ngIf="user?.userType !== 'saasRisk' && currentBanner === 'default'">
					<kendo-gridlayout
						[rows]="['164.4px']"
						[cols]="['calc((211% / 12) - (10px / 3))', 'calc((320% / 12) - (10px / 3))', 'calc((669% / 12) - (10px / 3))']"
						[gap]="{ rows: 0, cols: 5 }"
						class='portfolio-grid'
					>
						<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-container'>
							<kendo-gridlayout
								[rows]="['42.4px', '122px']"
								[cols]="['100%']"
								[gap]="{ rows: 0, cols: 0 }"
								class='portfolio-grid'
							>
								<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container shadow'>
									<div class="card-header">
										Projects Summary
									</div>
									<global-tooltip-open type="projects-summary" (tooltipClicked)="openTooltipWindow('projects-summary')"></global-tooltip-open>
								</kendo-gridlayout-item>
								<kendo-gridlayout-item [row]='2' [col]='1'>
									<kendo-gridlayout
										[rows]="['122px']"
										[cols]="['calc(50% - 2.5px)', 'calc(50% - 2.5px)']"
										[gap]="{ rows: 0, cols: 5 }"
										class='portfolio-grid'
									>
										<kendo-gridlayout-item
											[row]='1'
											[col]='1'
											class='card-content'
											[class.filter-selected-card-black]="selectedQuickView === 'active'"
											(click)="expandPieSlice('getRidOfExpansion');quickViewChange('active')"
										>
											<div class="centerBoth" [style.color]='selectedQuickView === "active"? "white": "black"'>{{ ($activeProjects | async) || '-' }}</div>
											<div class="filterBoxLabel">Active Projects</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item
											[row]='1'
											[col]='2'
											class='card-content'
											[class.filter-selected-card-orange]="selectedQuickView === 'missingUpdate'"
											(click)="expandPieSlice('getRidOfExpansion');quickViewChange('missingUpdate')"
										>
											<div class="centerBoth" [style.color]='selectedQuickView === "missingUpdate"? "white": "#ff8c00"'>{{ ($totalMissedLastUpload | async) || '-' }}</div>
											<div class="filterBoxLabel">Missing Update</div>
										</kendo-gridlayout-item>
									</kendo-gridlayout>
								</kendo-gridlayout-item>
							</kendo-gridlayout>
						</kendo-gridlayout-item>
						<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-container'>
							<kendo-gridlayout
								[rows]="['42.4px', '122px']"
								[cols]="['100%']"
								[gap]="{ rows: 0, cols: 0 }"
								class='portfolio-grid'
							>
								<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container shadow'>
									<div class="card-header">
										Projects Breakdown
									</div>
									<global-tooltip-open type="project-breakdown" (tooltipClicked)="openTooltipWindow('project-breakdown')"></global-tooltip-open>
								</kendo-gridlayout-item>
								<kendo-gridlayout-item [row]='2' [col]='1'>
									<kendo-gridlayout
										[rows]="['122px']"
										[cols]="['calc((100% / 3) - (10px / 3))', 'calc((100% / 3) - (10px / 3))', 'calc((100% / 3) - (10px / 3))']"
										[gap]="{ rows: 0, cols: 5 }"
										class='portfolio-grid'
									>
										<kendo-gridlayout-item
											[row]='1'
											[col]='1'
											class='card-content'
											[class.red-score-selected]="selectedQuickView === 'slipped'"
											(click)="expandPieSlice('Slipped');quickViewChange('slipped')"
											style="position: relative;"
										>
											<div class="centerBoth" [style.color]='selectedQuickView === "slipped"? "white": "rgba(223, 83, 83, .9)"'>{{ ($totalSlipped | async) || '-' }}</div>
											<div class="filterBoxLabel">Slipped</div>
											<div class="percentPieLabel" [style.color]='selectedQuickView === "slipped"? "white": "rgba(223, 83, 83, .9)"'>{{ ($slippedPercentage | async) === null ? '' : ('(' + ($slippedPercentage | async) + '%)') }}</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item
											[row]='1'
											[col]='2'
											class='card-content'
											[class.green-score-selected]="selectedQuickView === 'onTrack'"
											(click)="expandPieSlice('On-Track');quickViewChange('onTrack')"
											style="position: relative;"
										>
											<div class="centerBoth" [style.color]='selectedQuickView === "onTrack"? "white": "rgba(79, 201, 49, .9)"'>{{ ($totalMaintained | async) || '-' }}</div>
											<div class="filterBoxLabel">On-Track</div>
											<div class="percentPieLabel" [style.color]='selectedQuickView === "onTrack"? "white": "rgba(79, 201, 49, .9)"'>{{ ($maintainedPercentage | async) === null ? '' : ('(' + ($maintainedPercentage | async) + '%)') }}</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item
											[row]='1'
											[col]='3'
											class='card-content'
											[class.blue-score-selected]="selectedQuickView === 'improved'"
											(click)="expandPieSlice('Improved');quickViewChange('improved')"
											style="position: relative;"
										>
											<div class="centerBoth" [style.color]='selectedQuickView === "improved"? "white": "rgba(0, 89, 255, .9)"'>{{ ($totalGained | async) || '-' }}</div>
											<div class="filterBoxLabel">Improved</div>
											<div class="percentPieLabel" [style.color]='selectedQuickView === "improved"? "white": "rgba(0, 89, 255, .9)"'>{{ ($gainedPercentage | async) === null ? '' : ('(' + ($gainedPercentage | async) + '%)') }}</div>
										</kendo-gridlayout-item>
										<!--<kendo-gridlayout-item
											[row]='1'
											[col]='4'
											class='card-content'
											[class.filter-selected-card]="selectedQuickView === 'slipped' || selectedQuickView === 'onTrack' || selectedQuickView === 'improved'"
										>
											<kendo-chart class="k-chart k-widget k-breakdown-custom" (seriesClick)="clickedPieSlice($event)" *ngIf='($activeProjects | async) > 0'>
												<kendo-chart-legend position="bottom" [visible]="false"></kendo-chart-legend>
												<kendo-chart-tooltip>
													<ng-template kendoChartSeriesTooltipTemplate let-value="value" let-dataItem="dataItem">
														{{dataItem.category}} - {{value | percent}}
													</ng-template>
												</kendo-chart-tooltip>
												<kendo-chart-series>
													<kendo-chart-series-item
														type="donut"
														holeSize="0"
														[data]="pieData"
														field="valueAsPercentage"
														categoryField="category"
														explodeField="exploded"
														[labels]="{ visible: true, content: pieLabelContent, background: 'none', color: 'white', font: '12px Arial, sans-serif' }"
													>
													</kendo-chart-series-item>
												</kendo-chart-series>
											</kendo-chart>
											<div class='warning-div' *ngIf='($activeProjects | async) === 0'>
												No active projects
											</div>
										</kendo-gridlayout-item>-->
									</kendo-gridlayout>
								</kendo-gridlayout-item>
							</kendo-gridlayout>
						</kendo-gridlayout-item>
						<kendo-gridlayout-item [row]='1' [col]='3' class='gridlayout-container'>
							<kendo-gridlayout
								[rows]="['42.4px', '122px']"
								[cols]="['100%']"
								[gap]="{ rows: 0, cols: 0 }"
								class='portfolio-grid'
							>
								<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container shadow'>
									<div class="card-header">
										Portfolio Summary
									</div>
									<global-tooltip-open type="portfolio-summary" (tooltipClicked)="openTooltipWindow('portfolio-summary')"></global-tooltip-open>
								</kendo-gridlayout-item>
								<kendo-gridlayout-item [row]='2' [col]='1'>
									<kendo-gridlayout
										[rows]="['122px']"
										[cols]="['calc((190% / 12) - 4px)', 'calc((190% / 12) - 4px)', 'calc((190% / 12) - 4px)', 'calc((190% / 12) - 4px)', 'calc((440% / 12) - 4px)']"
										[gap]="{ rows: 0, cols: 5 }"
										class='portfolio-grid'
									>
										<kendo-gridlayout-item
											[row]='1'
											[col]='1'
											class='card-content'
											[ngClass]="{
													'blue-score': ($averageProjectScore | async) >= 85 && selectedSummaryChart !== 'projectScore',
													'blue-score-selected': ($averageProjectScore | async) >= 85 && selectedSummaryChart === 'projectScore',
													'green-score': ($averageProjectScore | async) >= 70 && ($averageProjectScore | async) < 85 && selectedSummaryChart !== 'projectScore',
													'green-score-selected': ($averageProjectScore | async) >= 70 && ($averageProjectScore | async) < 85 && selectedSummaryChart === 'projectScore',
													'red-score': ($averageProjectScore | async) < 70 && selectedSummaryChart !== 'projectScore',
													'red-score-selected': ($averageProjectScore | async) < 70 && selectedSummaryChart === 'projectScore'
													}"
											(click)="updateChartSelection('projectScore')"
										>
											<div class="centerBoth">{{ ($averageProjectScore | async | number : '1.0-0') || '-' }}</div>
											<div class="filterBoxLabel" [class.not-selected-score]="selectedSummaryChart !== 'projectScore'">Project Score</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item
											[row]='1'
											[col]='2'
											class='card-content'
											[ngClass]="{
													'blue-score': ($averageProgressScore | async) >= 95 && selectedSummaryChart !== 'progressScore',
													'blue-score-selected': ($averageProgressScore | async) >= 95 && selectedSummaryChart === 'progressScore',
													'green-score': ($averageProgressScore | async) >= 80 && ($averageProgressScore | async) < 85 && selectedSummaryChart !== 'progressScore',
													'green-score-selected': ($averageProgressScore | async) >= 80 && ($averageProgressScore | async) < 85 && selectedSummaryChart === 'progressScore',
													'red-score': ($averageProgressScore | async) < 80 && selectedSummaryChart !== 'progressScore',
													'red-score-selected': ($averageProgressScore | async) < 80 && selectedSummaryChart === 'progressScore'
													}"
											(click)="updateChartSelection('progressScore')"
										>
											<div class="centerBoth">{{ ($averageProgressScore | async | number : '1.0-0') || '-' }}</div>
											<div class="filterBoxLabel" [class.not-selected-score]="selectedSummaryChart !== 'progressScore'">Progress Score</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item
											[row]='1'
											[col]='3'
											class='card-content'
											[ngClass]="{
													'blue-score': ($averageQCScore | async) >= 95 && selectedSummaryChart !== 'qcScore',
													'blue-score-selected': ($averageQCScore | async) >= 95 && selectedSummaryChart === 'qcScore',
													'green-score': ($averageQCScore | async) >= 80 && ($averageQCScore | async) < 85 && selectedSummaryChart !== 'qcScore',
													'green-score-selected': ($averageQCScore | async) >= 80 && ($averageQCScore | async) < 85 && selectedSummaryChart === 'qcScore',
													'red-score': ($averageQCScore | async) < 80 && selectedSummaryChart !== 'qcScore',
													'red-score-selected': ($averageQCScore | async) < 80 && selectedSummaryChart === 'qcScore'
													}"
											(click)="updateChartSelection('qcScore')"
										>
											<div class="centerBoth">{{ ($averageQCScore | async | number : '1.0-0') || '-' }}</div>
											<div class="filterBoxLabel" [class.not-selected-score]="selectedSummaryChart !== 'qcScore'">QC Score</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item
											[row]='1'
											[col]='4'
											class='card-content'
											[ngClass]="{
													'blue-score': ($averagePredictabilityScore | async) >= 85 && selectedSummaryChart !== 'predictabilityScore',
													'blue-score-selected': ($averagePredictabilityScore | async) >= 85 && selectedSummaryChart === 'predictabilityScore',
													'green-score': ($averagePredictabilityScore | async) >= 70 && ($averagePredictabilityScore | async) < 85 && selectedSummaryChart !== 'predictabilityScore',
													'green-score-selected': ($averagePredictabilityScore | async) >= 70 && ($averagePredictabilityScore | async) < 85 && selectedSummaryChart === 'predictabilityScore',
													'red-score': ($averagePredictabilityScore | async) < 70 && selectedSummaryChart !== 'predictabilityScore',
													'red-score-selected': ($averagePredictabilityScore | async) < 70 && selectedSummaryChart === 'predictabilityScore'
													}"
											(click)="updateChartSelection('predictabilityScore')"
										>
											<div class="centerBoth">{{ ($averagePredictabilityScore | async | number : '1.0-0') || '-' }}</div>
											<div class="filterBoxLabel" [class.not-selected-score]="selectedSummaryChart !== 'predictabilityScore'">Risk Score</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='1' [col]='5' class='card-content' style="cursor: default;">
											<app-risk-summary-trend
												[categories]="riskSummaryTrendCategories"
												[data]="portfolioSummaryTrendData"
												[title]='portfolioSummaryTrendTitle'
												[chartType]="'line'"
												[isFromPortfolio]='true'
												class='score-trend-chart'
												*ngIf='($totalShowingProjects | async) > 0 && updatesExistInPastSixMonths'
												style="height: 122px;"
											></app-risk-summary-trend>
											<div class='warning-div' *ngIf='($totalShowingProjects | async) === 0 || !updatesExistInPastSixMonths'>
												No updates within the last 6 months
											</div>
										</kendo-gridlayout-item>
									</kendo-gridlayout>
								</kendo-gridlayout-item>
							</kendo-gridlayout>
						</kendo-gridlayout-item>
					</kendo-gridlayout>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-container' *ngIf="user?.userType === 'saasRisk'">
					<app-saas-risk-banner
						[$riskRegisterProjects]="$riskRegisterProjects"
						(dialogStateChange)='dialogStateChanged($event)'
					></app-saas-risk-banner>
				</kendo-gridlayout-item>
				<app-risk-banner
					[riskSummaryTrendCategories]="riskSummaryTrendCategories"
					[riskSummaryTrendData]="riskSummaryTrendData"
					[$allRiskProjects]="$allRiskProjects"
					[$averageRiskScore]="$averageRiskScore"
					[$totalMissedLastUpload]="$totalMissedLastUpload"
					[$totalSlipped]="$totalSlipped"
					[$riskRegisterProjects]="$riskRegisterProjects"
					[quickFilterWidgets]='quickFilterWidgets'
					[selectedQuickView]='selectedQuickView'
					(quickViewChanged)="quickViewChange($event)"
					(updateVal)='update()'
					*ngIf="user?.userType !== 'saasRisk' && currentBanner === 'risk'"
				></app-risk-banner>
				<app-cost-banner
					*ngIf="user?.userType !== 'saasRisk' && currentBanner === 'cost'"
					[$filteredProjects]='$filteredProjects'
					[loading]='loading'
					[selectedQuickView]='selectedQuickView'
					(quickViewChanged)="quickViewChange($event)"
				></app-cost-banner>
			</kendo-gridlayout>
		</kendo-gridlayout-item>

		<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]='2' id="projectsMapSection" class='gridlayout-container nothing-expanded' [class.bottom-padding]='selectedProject'>
			<div class='card'>
				<kendo-gridlayout
					[rows]="['42.4px', 'calc(100% - 42.4px)']"
					[cols]="['100%']"
					[gap]="{ rows: 0, cols: 0 }"
					class='portfolio-grid'
					style='max-width: calc(100vw - 20px);'
				>
					<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container'>
						<kendo-gridlayout
							[rows]="['42.4px']"
							[cols]="['450px', 'calc(100% - 455px)']"
							[gap]="{ rows: 0, cols: 5 }"
							class='portfolio-grid'
						>
							<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
								<div class="card-header">
									Projects
								</div>
								<global-tooltip-open type="projects" (tooltipClicked)="openTooltipWindow('projects')"></global-tooltip-open>
								<div class='projectSearchContainer'>
									<kendo-textbox
										class='projectSearchInput'
										placeholder="Search Projects..."
										[clearButton]="true"
										[size]='"small"'
										[(value)]='searchTerm'
										(afterValueChangedStr)='tellProjectListToSearchProjects($event)'
										[disabled]='loading'
										#portfolioSearch
									>
										<ng-template kendoTextBoxPrefixTemplate>
											<kendo-svgicon [icon]='svgSearch'></kendo-svgicon>
											<kendo-textbox-separator></kendo-textbox-separator>
										</ng-template>
										<!--<ng-template kendoTextBoxSuffixTemplate>
											<span style='padding-right: 4px;' *ngIf='!loading'>
												{{$filteredProjects.value?.length}} of {{$allProjects.value?.length}}
											</span>
										</ng-template>-->
									</kendo-textbox>
								</div>
							</kendo-gridlayout-item>
							<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-container' style='flex-direction: row; justify-content: flex-end; align-items: center; gap: 10px; padding-right: 10px;'>
								<div class='btngroup-label' style='margin-right: -5px;'>
									View:
								</div>
								<kendo-buttongroup width='100%' selection="single" class='btn-group' style='width: 150px !important;'>
									<button
										*ngFor="let button of viewButtons"
										kendoButton
										[toggleable]="true"
										[selected]="button.selected"
										(selectedChange)="selectionChange($event, button)"
										class='item-btn'
										[class.selected-item-btn]='button.selected'
										[disabled]='button.disabled || loading'
									>
										<span>
											{{ button.text }}
										</span>
									</button>
								</kendo-buttongroup>
								<button kendoButton themeColor="base" title="Export Data (XLSX)" class='header-btn-portfolio' [imageUrl]='"/assets/icons/newIcons/excelExport.svg"' (click)='exportGrid.next()' [disabled]='loading' *ngIf='navBarStorage._highestCompanyTier !== "ANALYTICS-BASIC-MPK" && navBarStorage._highestCompanyTier !== "ANALYTICS-BASIC-APK"'></button>
								<div kendoTooltip style="text-align: center;" *ngIf='navBarStorage._highestCompanyTier === "ANALYTICS-BASIC-MPK" || navBarStorage._highestCompanyTier === "ANALYTICS-BASIC-APK"'>
									<div title='Upgrade to a Professional License to enable Exports'>
										<button kendoButton themeColor="base" class='header-btn-portfolio' [svgIcon]='svgExcel' [disabled]='true'></button>
									</div>
								</div>
								<button kendoButton themeColor="base" [title]="isProjectsGridMaximized ? 'Minimize' : 'Maximize'" class='header-btn-portfolio new-img-18' [imageUrl]='isProjectsGridMaximized ? "/assets/icons/newIcons/collapseWidth.svg" : "/assets/icons/newIcons/expandWidth.svg"' (click)="toggleProjectsGridWidth()" [disabled]='loading || projectsListView === "gantt"'></button>
								<div style='position: relative;' id="presetsOpenBtn">
									<button kendoButton themeColor="base" [title]="'Layouts'" class='header-btn-portfolio new-img-26' [disabled]='loading' [imageUrl]='"/assets/icons/newIcons/layouts.svg"' (click)="openFilterMenu(presetsPopupAnchor.element, 'presets')" #presetsPopupAnchor></button>
									<kendo-svg-icon *ngIf='presetsPopupIsOpen' [icon]="icons.caretAltDown" class="downArrowIcon" style="color: white; position: absolute; left: 8px; margin-top: 25px;"></kendo-svg-icon>
								</div>
								<div style='position: relative;' id="columnsOpenBtn">
									<button kendoButton themeColor="base" [title]="'Column Selector'" class='header-btn-portfolio new-img-22' [disabled]='loading' [imageUrl]='"/assets/icons/newIcons/noun-add-column2 1.svg"' (click)="openFilterMenu(columnSelectorPopupAnchor.element, 'columns')" #columnSelectorPopupAnchor></button>
									<kendo-svg-icon *ngIf='columnSelectorPopupIsOpen' [icon]="icons.caretAltDown" class="downArrowIcon" style="color: white; position: absolute; left: 8px; margin-top: 25px;"></kendo-svg-icon>
								</div>
								<div style='position: relative;'>
									<button kendoButton themeColor="base" [title]="'Toggle Filter Menu'" class='header-btn-portfolio new-filter-icon' [disabled]='loading' [imageUrl]='"/assets/icons/newIcons/filter.svg"' (click)="openFilterMenu(popupAnchor.element, 'filters')" #popupAnchor></button>
								</div>
								<button kendoButton themeColor="error" title="Reset Filters" class='reset-btn' [disabled]='resetDisabled || loading' (click)='resetFiltersButtonClicked()'>Reset Filters</button>
							</kendo-gridlayout-item>
						</kendo-gridlayout>
					</kendo-gridlayout-item>
					<kendo-gridlayout-item [row]='2' [col]='1'>
						<kendo-splitter class='projects-grid-splitter' [style.display]='projectsListView === "grid" ? "flex" : "none"'>
							<kendo-splitter-pane min="600px">
								<projects-project-list
									#projectList
									[$projects]='$allProjects'
									[$filteredProjects]='$filteredProjects'
									[ifilters]='$filters'
									[clickedOnMapMarker]='clickedOnMapMarker'
									[currentView]='selectedQuickView'
									[exportExcel]='exportGrid'
									(selectProject)='selectProject($event)'
									(deselectProject)='deselectProject()'
									(currentFiltersChanged)='currentFiltersChanged($event)'
									(projectsFiltered)='onProjectsFiltered($event)'
									[projectTypeList]='projectTypeList'
									[filteredProjectTypeList]='filteredProjectTypeList'
									[clientList]='clientList'
									[nestedCompanyList]='nestedCompanyList'
									[nestedPocList]='nestedPocList'
									[nestedClientList]='nestedClientList'
									[companyList]='companyList'
									[riskList]='riskList'
									[loading]='loading'
									[uploadList]='uploadList'
									[filteredClientList]='filteredClientList'
									[pastPeriodPerformanceList]='pastPeriodPerformanceList'
									[pocList]='pocList'
									[filteredPocList]='filteredPocList'
									[completionStatus]='completionStatus'
									[resetFilterEvent]='resetFiltersSubject.asObservable()'
									(resetFiltersClicked)='resetFiltersClicked()'
									[$checkForResetBtnDisabled]='$checkForResetBtnDisabled'
									(removeQuickView)='quickViewChange(null)'
									[isProjectsGridMaximized]='isProjectsGridMaximized'
									[$filtersOpened]='$filtersOpened'
									[$columnSelectorOpened]='$columnSelectorOpened'
									(columnSelectorPopupState)='updatePopupState($event, "columns")'
									[headerSearch]='headerSearch'
									(resetStateChanged)='updateResetBtnState($event)'
									(searchStateChanged)='updateSearch($event)'
									[resetClickedPortfolio]='resetClickedPortfolio'
									(sortChangeProjectList)='updateSortProjectList($event)'
									(columnSelectorSelectionChange)='columnSelectorUpdated($event)'
									[$portfolioPresetApplied]="$presetApplied"
									(doFilterEmit)="doFilter($event)"
								></projects-project-list>
							</kendo-splitter-pane>
							<kendo-splitter-pane [size]="mapPaneSize" [collapsible]='true'>
								<app-map
									#appMap
									[zoomCoords]="clickedCoords"
									[$projects]="$filteredProjects"
									[selectedProject]='selectedProject'
									[googleScriptLoaded]='googleScriptLoaded'
									(clickedOnMarker)='clickedOnMarker($event)'
									style='height: 100%; width: 100%;'
								>
								</app-map>
							</kendo-splitter-pane>
						</kendo-splitter>
						<div *ngIf='projectsListView === "gantt"' class='gantt-view-container'>
							<kendo-grid *ngIf='loading' [loading]='true' style='height: 100%;'>
								<ng-template kendoGridNoRecordsTemplate>
								</ng-template>
							</kendo-grid>
							<div class='gantt-legend-items-container' *ngIf='!loading'>
								<div style='margin: auto 0;'>
									View:
								</div>
								<kendo-dropdownlist
									[data]="viewOptions"
									valueField='zoomVal'
									textField='display'
									(valueChange)='updateView($event)'
									[valuePrimitive]="true"
									[(ngModel)]="selectedGanttView"
									style='width: 100px; height: 24px; margin: auto 0;'
								></kendo-dropdownlist>
								<div style='margin: auto 0;'>
									Legend:
								</div>
								<div class='gantt-legend-item'>
									<div class='fake-gantt-bar' style='background-color: rgb(0 89 255);'></div>
									<div class='fake-gantt-bar-label'>Completed Work</div>
								</div>
								<div class='gantt-legend-item'>
									<div class='fake-gantt-bar' style='background-color: rgb(79, 201, 49);'></div>
									<div class='fake-gantt-bar-label'>Remaining Work</div>
								</div>
								<div class='gantt-legend-item'>
									<div class='fake-gantt-bar' style='background-color: rgb(223, 83, 83);'></div>
									<div class='fake-gantt-bar-label'>Late Work</div>
								</div>
								<div class='gantt-legend-item'>
									<svg xmlns="http://www.w3.org/2000/svg"
											 width="10"
											 height="10"
											 viewBox="0 0 10 10"
											 style='height: 24px; width: 24px; transform: translate(0px, 11px);'
									>
										<path d="M 3 0 L 6 3 L 3 6 L 0 3 L 3 0" fill="black"/>
									</svg>
									<div class='fake-gantt-bar-label' style='margin-left: -11px;'>Contract Milestone</div>
								</div>
							</div>
							<kendo-gantt
								[kendoGanttHierarchyBinding]="items"
								childrenField="links"
								kendoGanttExpandable
								[initiallyExpanded]="false"
								[dependencies]="[]"
								[filterable]="false"
								[sortable]="true"
								[sort]="sort"
								(sortChange)="sortChange($event)"
								[columnMenu]="false"
								[columnsResizable]="true"
								[columnsReorderable]="false"
								[navigable]='false'
								[timelinePaneOptions]='{ collapsed: false, collapsible: true, resizable: true, size: "calc(100% - 715px)" }'
								[treeListPaneOptions]='{ collapsed: false, collapsible: true }'
								[toolbarSettings]='{ position: "none" }'
								[activeView]="activeView"
								[taskClass]='taskCallback'
								[selectable]="false"
								*ngIf='!loading'
								class='portfolio-gantt'
								[class.gantt-year-view-no-months]='slotWidth <= 25'
								[class.currentlyDragging]='isDragging'
								[class.hideMonthLines]='slotWidth <= 35'
							>
								<kendo-gantt-column
									[headerClass]='["list-header"]'
									[width]='40'
								>
									<ng-template kendoGanttCellTemplate let-dataItem>
										<svg xmlns="http://www.w3.org/2000/svg"
												 width="10"
												 height="10"
												 viewBox="0 0 10 10"
												 kendoTooltip
												 style='position: absolute; top: 0; left: 0;'
												 *ngIf='dataItem.missingUpdate'
										>
											<path d="M 0 0 L 0 10 L 10 0 z" fill="#ff8c00"/>
										</svg>
										<nav style='height: 20px; margin: auto 0; cursor: pointer'>
											<a (click)='navToProjectEvent()' routerLink="../project/{{ dataItem.id }}">
												<img
													src="/assets/icons/aegis/noun-dashboard-1102368-3B6BF9.svg"
													title='Go To Project'
													height="24"
													alt='Goto'
													width="24" />
											</a>
										</nav>
									</ng-template>
								</kendo-gantt-column>
								<kendo-gantt-column
									*ngFor='let column of allColumns'
									[field]="column.field"
									[title]="column.title"
									[width]='column.width || column.defaultWidth'
									[headerClass]='["list-header"]'
									[class]='column?.isCentered? ["list-col", "center-text"] : ["list-col"]'
									[hidden]='hiddenColumns.indexOf(column.field) > -1'
								>
									<ng-template kendoGanttCellTemplate let-dataItem *ngIf='column.dataType === "date"'>
										{{dataItem[column.field] | date: 'MMM d, yyyy'}}
									</ng-template>
									<ng-template kendoGanttCellTemplate let-dataItem *ngIf='column.dataType === "number"'>
										{{dataItem[column.field] | number: '1.0-0'}}
									</ng-template>
									<ng-template kendoGanttCellTemplate let-dataItem *ngIf='column.dataType === "twoDigitDecimalNumber"'>
										{{dataItem[column.field] | number: '1.0-2'}}
									</ng-template>
									<ng-template kendoGanttCellTemplate let-dataItem *ngIf='column.dataType === "currency"'>
										{{dataItem[column.field] | currency: 'USD' : '$' :'1.0-0'}}
									</ng-template>
									<ng-template kendoGanttCellTemplate let-dataItem *ngIf='column.field === "progressScore"'>
										{{dataItem.progressScore === null ? '-' : (dataItem.progressScore | number: '1.0-0')}}
									</ng-template>
								</kendo-gantt-column>
								<kendo-gantt-timeline-year-view [slotWidth]="slotWidth">
									<ng-template kendoGanttTaskTemplate let-task>
										<div class="main-progress" #bar (mouseenter)='toggleTooltip($event, bar, task, true)' (mouseleave)='toggleTooltip($event, bar, task, false)'>
											<div class="sub-progress">
												<div
													class="contract-bar"
													[style.width.%]="calculateBarWidth(task, 'contractCompletion')"
												></div>
											</div>
											<div class="sub-progress">
												<div
													class="current-bar"
													[style.width.%]="calculateBarWidth(task, 'currentCompletion')"
												></div>
											</div>
											<div class="sub-progress">
												<div
													class="progress-bar"
													[style.width.%]="calculateBarWidth(task, 'lastUpdatedDate', false, true)"
												></div>
											</div>
											<svg xmlns="http://www.w3.org/2000/svg"
													 width="10"
													 height="10"
													 viewBox="0 0 10 10"
													 style='position: absolute; height: 24px; width: 24px; margin-top: 1px;'
													 [style.margin-left]="'calc(' + calculateBarWidth(task, 'contractCompletion', true) + '% - 9px)'"
											>
												<path d="M 3 0 L 6 3 L 3 6 L 0 3 L 3 0" fill="black"/>
											</svg>
										</div>
									</ng-template>
								</kendo-gantt-timeline-year-view>
							</kendo-gantt>
							<div *ngIf='showingTooltip' [style.left.px]='tooltipVals.x' [style.top.px]='tooltipVals.y' class='tooltip-wrapper'>
								<div class='tooltip-hover-items'>
									<div class='tooltip-item-project-name'>
										<b>{{tooltipVals.name}}</b>
									</div>
									<div>
										Start Date: <b>{{tooltipVals.projectStart | date: 'MMM d, yyyy'}}</b>
									</div>
									<div>
										Data Date: <b>{{tooltipVals.lastUpdatedDate | date: 'MMM d, yyyy'}}</b>
									</div>
									<div>
										Current Completion: <b>{{tooltipVals.currentCompletion | date: 'MMM d, yyyy'}}</b>
									</div>
									<div>
										Contract Completion: <b>{{tooltipVals.contractCompletion | date: 'MMM d, yyyy'}}</b>
									</div>
									<div>
										Contract Δ: <b>{{tooltipVals.deltaVsCcd | number: '1.0-0'}}</b>
									</div>
									<div>
										CDs Remaining: <b>{{tooltipVals.calendarDaysRemaining}} cd</b>
									</div>
								</div>
								<svg xmlns="http://www.w3.org/2000/svg"
										 width="10"
										 height="10"
										 viewBox="0 0 10 10"
										 style='position: relative; left: 110.8px; height: 24px; width: 24px;'
								>
									<path d="M 3 0 L 0 0 L 3 4 L 6 0" fill="rgba(0, 0, 0, .7)"/>
								</svg>
							</div>
						</div>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			</div>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
</div>

<!--<div class='k-overlay' *ngIf="eulaOpened"></div>-->
<kendo-dialog
	*ngIf="navBarStorage.eulaOpened"
	[minWidth]="250"
	(close)='null'
	[height]="220"
	[width]="450"
	id='eulaWindow'
	class='eula-k'
>
	<kendo-dialog-titlebar>
		<div class="k-dialog-title">Terms Of Service</div>
<!--		<button kendoWindowMinimizeAction></button>-->
<!--		<button kendoWindowMaximizeAction></button>-->
<!--		<button kendoWindowRestoreAction></button>-->
<!--		<button kendoWindowCloseAction></button>-->
	</kendo-dialog-titlebar>
	<p style='text-align: center'>Aegis Analytics has a new Terms of Service. please click below to agree.</p>

	<ion-button class='view-icon-btn' size='block' (click)='agreeTerms()'>I Agree</ion-button>

	<p style='text-align: center; margin-bottom: 0px; margin-top: 16px;'><a href='https://account.rhino.works/documents/analytics/tos/' target='_blank'>Terms of Service</a></p>

	<!--
	<div style='margin: 20px; padding: 20px; background-color: #011689; border-radius: 10px; color: white;'>
		<ion-grid>
			<ion-row>
				<ion-col size='8'>
					<h4>Privacy Policy</h4>
					<p>Last Updated: 2023/02/13</p>
				</ion-col>
				<ion-col size='4' style='margin-top: 1.8em;'>
					<ion-button>I agree</ion-button>
				</ion-col>
			</ion-row>
		</ion-grid>

	</div> -->
</kendo-dialog>

<kendo-window
	class="container"
	*ngIf="tooltipOpened"
	(close)="closeTooltip(false)"
	[resizable]='false'
	[(top)]="windowService.windowPosition.globalTooltip.top"
	[(left)]="windowService.windowPosition.globalTooltip.left"
	[height]="windowService.windowPosition.globalTooltip.height"
	[width]="windowService.windowPosition.globalTooltip.width"
	(leftChange)="restrictMovement('globalTooltip')"
	(topChange)="restrictMovement('globalTooltip')"
	id='globalTooltipWindow'
>
	<kendo-window-titlebar>
		<div class="k-window-title">{{tooltipTitle}}</div>
		<button kendoWindowMinimizeAction></button>
		<button kendoWindowMaximizeAction></button>
		<button kendoWindowRestoreAction></button>
		<button kendoWindowCloseAction></button>
	</kendo-window-titlebar>
	<global-tooltip-content (tooltipName)="fillTooltipName($event)" [tooltipType]="tooltipType"></global-tooltip-content>
</kendo-window>

<ng-template #presetsPopupTemplate>
	<div id='presetsContainer'>
		<div class="presets-btns-container">
			<button
				kendoButton
				[size]="'small'"
				fillMode="outline"
				[rounded]="'medium'"
				[themeColor]="'info'"
				(click)='openAddNewPresetPopup()'
				style='display: block; width: 85%;'
			>
				+ New
			</button>
			<button
				kendoButton
				[size]="'small'"
				fillMode="outline"
				[rounded]="'medium'"
				[themeColor]="'info'"
				(click)='toggleQuickSaveDialog(true)'
				style='display: block; width: 85%;'
			>
				Quick-Save
			</button>
			<button
				kendoButton
				[size]="'small'"
				[rounded]="'medium'"
				fillMode="outline"
				[themeColor]="'primary'"
				style='display: block; width: 15%;'
				(click)="closePresetsPopup()"
			>
				X
			</button>
		</div>
		<div class="presets-popup-content">
			<div>
				{{userPresets?.length ? 'Saved Layouts' : 'No Saved Layouts'}}
			</div>
			<div class="presets-wrapper" *ngIf="userPresets?.length">
				<div *ngFor="let preset of userPresets" class="preset-tile" (click)="presetClicked($event, preset)" [style.background-color]="navBarStorage.selectedLayout === preset.id ? 'rgba(18, 116, 172, 0.25)' : 'transparent'">
					<div class="preset-tile-desc">
<!--						<span *ngIf="preset?.isFavorite" class="default-preset">-->
<!--							<kendo-svg-icon [icon]="icons.star" style="color: rgba(223, 83, 83, 0.9); height: 14px; width: 14px;"></kendo-svg-icon>-->
<!--						</span>-->
						<span class="preset-name">
							{{preset?.name}}
						</span>
					</div>
					<div class="preset-action-btns">
						<button kendoButton fillMode='flat' title='edit' [svgIcon]='icons.pencilIcon' (click)='editPreset($event, preset)' style='color: dodgerblue;' class='larger-btn-icons'></button>
						<button kendoButton fillMode='flat' title='delete' [svgIcon]='icons.trashIcon' (click)='deletePreset($event, preset)' style='color: #f31700;'></button>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>
<kendo-dialog
	*ngIf="userService.presetDeleteConfirmOpen"
	(close)="closeConfirmDialog()"
	[minWidth]="250"
	[width]="450"
	id="presetDeleteDialog"
>
	<kendo-dialog-titlebar>
		<div class="dialog" style="text-align: left;font-size: 16px; color: black">Confirm Delete - {{deletingPreset?.name}}</div>
	</kendo-dialog-titlebar>
	Are you sure you want to delete layout {{deletingPreset?.name}}? This action cannot be undone.
	<kendo-dialog-actions style='margin: 0; background-color: #c8cad6; border-top: none;  border-radius: 0 0 4px 4px;'>
		<button kendoButton (click)="closeConfirmDialog()">Cancel</button>
		<button kendoButton (click)="confirmDelete(deletingPreset)" themeColor="success">
			Confirm
		</button>
	</kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog
	*ngIf="userService.presetQuickSaveOpen"
	(close)="toggleQuickSaveDialog(false)"
	[minWidth]="250"
	[width]="400"
	[height]="205.5"
	id="presetQuickSaveDialog"
>
	<kendo-dialog-titlebar>
		<div class="dialog" style="text-align: left;font-size: 16px; color: black">Quick-Save</div>
	</kendo-dialog-titlebar>
	<div style="text-align: center; padding-bottom: 10px;">
		Save your current portfolio layout
	</div>
	<div class="quick-save-report-fields">
		<kendo-formfield class="preset-form-name">
			<kendo-label [for]="layoutName" text="Layout Name:" style="width: 92px;"></kendo-label>
			<kendo-textbox
				[(ngModel)]="quickSaveName"
				#layoutName
				required
				[clearButton]="true"
			>
			</kendo-textbox>
			<kendo-formerror [align]="'end'" style="margin-top: 0px;">Layout Name is required</kendo-formerror>
		</kendo-formfield>
<!--		<div>-->
<!--			<kendo-label [for]="isFavoriteQuickSaveCheckbox" text="Favorite:" style="padding-right: 4px;"></kendo-label>-->
<!--			<input type="checkbox" kendoCheckBox [(ngModel)]="quickSaveIsFavorite" #isFavoriteQuickSaveCheckbox style="margin-top: -2px;"/>-->
<!--			<span style="font-size: 12px; font-style: italic; padding-left: 4px; white-space: nowrap;">Favorite layouts show up at the top of the list</span>-->
<!--		</div>-->
	</div>
	<kendo-dialog-actions style='margin: 0; background-color: #c8cad6; border-top: none;  border-radius: 0 0 4px 4px;'>
		<button kendoButton (click)="toggleQuickSaveDialog(false)">Cancel</button>
		<button kendoButton (click)="confirmQuickSave()" themeColor="success">
			Confirm
		</button>
	</kendo-dialog-actions>
</kendo-dialog>
