<div>
	<div style="text-align: center; padding-bottom: 10px;">
		Create a folder for organizing reports
	</div>
	<div class="quick-save-report-fields">
		<kendo-formfield class="preset-form-name">
			<kendo-label [for]="layoutName" text="Folder Name:" style="width: 92px;"></kendo-label>
			<kendo-textbox
				[(ngModel)]="ganttService.newEditingGroup.name"
				#layoutName
				required
				[clearButton]="true"
			>
			</kendo-textbox>
			<kendo-formerror [align]="'end'" style="margin-top: 0px;">Folder Name is required</kendo-formerror>
		</kendo-formfield>
		@if (ganttService.userPresets?.length) {
			<div style="padding: 10px 0;">
				Reports:
			</div>
			<app-report-selection
				[disableReportChanges]="true"
				[preSelectedReports]="ganttService?.duplicateGroup?.reportIds || ganttService?.editingGroup?.reportIds || []"
				(checkedReportChange)="updateSelectedReports($event)"
			></app-report-selection>
		}
	</div>
</div>
