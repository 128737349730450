import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RiskRegister, SaasRiskBannerExtras } from '../../../models/risk';
import { ProjectInterface } from '../../../models/Project';
import { UserService } from '../../../services/common/user.service';
import { AnalyticsDashboardService } from '../../../services/analytics/analytics.service';
import { imageAddIcon } from '@progress/kendo-svg-icons';
import { ProjectDashboardService } from '../../../services/project/project.service';

@Component({
	selector: 'app-saas-risk-banner',
	templateUrl: './saas-risk-banner.component.html',
	styleUrls: ['./saas-risk-banner.component.scss'],
})
export class SaasRiskBannerComponent implements OnInit {
	@Input() $riskRegisterProjects = new BehaviorSubject<Array<ProjectInterface>>([]);
	// @Input() riskSummaryTrendCategories;
	// @Input() riskSummaryTrendData;
	@Input() isFromProjectPage = false;
	@Output() dialogStateChange = new EventEmitter<boolean>();
	allRisks = new BehaviorSubject<Array<RiskRegister & SaasRiskBannerExtras>>([]);
	$avgRiskAssessmentScore = new BehaviorSubject<number>(null);
	$avgPreMitigationScore = new BehaviorSubject<number>(null);
	$avgPostMitigationScore = new BehaviorSubject<number>(null);
	imageUploadDialogOpen = false;
	isHoveringOverImage = false;
	imageReadyToShow = false;
	companyImg;
	companyId;
	public icons = { imageAdd: imageAddIcon };
	user: any = {
		userType: 'aegis',
	};

	constructor(
		private userService: UserService,
		public _analyticsDashboardService: AnalyticsDashboardService,
		public _projectDashboardService: ProjectDashboardService
	) {}

	ngOnInit(): void {
		this.$riskRegisterProjects.subscribe((riskRegisterProjects) => {
			const newRisks = [];
			riskRegisterProjects.forEach((project) => {
				project.riskMitigation.forEach((risk: RiskRegister & SaasRiskBannerExtras) => {
					if (risk.isDraft) {
						return;
					}
					const preImpact = Math.max(
						risk.preMitigation.qualityImpact,
						risk.preMitigation.performanceImpact,
						risk.preMitigation.costImpact,
						risk.preMitigation.scheduleImpact
					);
					const postImpact = Math.max(
						risk.postMitigation.qualityImpact,
						risk.postMitigation.performanceImpact,
						risk.postMitigation.costImpact,
						risk.postMitigation.scheduleImpact
					);
					const likelihood = risk.preMitigation.probability;
					const preMitigationRiskScore = likelihood * preImpact;
					const postMitigationRiskScore = likelihood * postImpact;
					const riskAssessmentScore = preMitigationRiskScore * 4;
					risk.riskAssessmentScore = riskAssessmentScore;
					risk.preMitigationRiskScore = preMitigationRiskScore;
					risk.postMitigationRiskScore = postMitigationRiskScore;
					// @ts-ignore
					risk.value = 1; //used for groupBy aggregation
					newRisks.push(risk);
				});
			});
			this.allRisks.next(newRisks);
		});

		this.allRisks.subscribe((newRisks: Array<RiskRegister & SaasRiskBannerExtras>) => {
			let summedRiskAssessmentScores = 0;
			let summedPreMitigationRiskScores = 0;
			let summedPostMitigationRiskScores = 0;
			newRisks.forEach((risk: RiskRegister & SaasRiskBannerExtras) => {
				summedRiskAssessmentScores += risk.riskAssessmentScore;
				summedPreMitigationRiskScores += risk.preMitigationRiskScore;
				summedPostMitigationRiskScores += risk.postMitigationRiskScore;
			});
			const avgRiskAssessmentScore = Math.round(summedRiskAssessmentScores / (newRisks.length || 1));
			const avgPreMitigationRiskScore = Math.round(summedPreMitigationRiskScores / (newRisks.length || 1));
			const avgPostMitigationRiskScore = Math.round(summedPostMitigationRiskScores / (newRisks.length || 1));
			this.$avgRiskAssessmentScore.next(avgRiskAssessmentScore || null);
			this.$avgPreMitigationScore.next(avgPreMitigationRiskScore || null);
			this.$avgPostMitigationScore.next(avgPostMitigationRiskScore || null);
		});

		this.userService.user.subscribe((data) => {
			if (data) {
				this.user = data;
			}
			const allCompanies = this._analyticsDashboardService.$companies.value;
			const matchingCompany = allCompanies.find((company) => company._id === data.companyIds[0] && !!company._id);
			if (matchingCompany) {
				this.companyId = matchingCompany._id;
				const companyImg = !!matchingCompany.logo ? matchingCompany.logo : null;
				this.updateCompanyImg(companyImg);
			}
		});
	}

	/**
	 * opens image upload editor with current image if available
	 */
	openImageUpload(): void {
		if (
			this._projectDashboardService.$currentProjectData.value === undefined &&
			this.user?.profile?.companyPermissions[0][1]?.admin === true
		) {
			this.imageUploadDialogOpen = true;
		}
	}

	/**
	 * sets hovering variable to false
	 */
	mouseoverImage(): void {
		this.isHoveringOverImage = true;
	}

	/**
	 * sets hovering variable to false
	 */
	mouseoutImage(): void {
		this.isHoveringOverImage = false;
	}

	closeDialog() {
		this.dialogStateChange.emit(false);
		this.imageUploadDialogOpen = false;
		this.imageReadyToShow = false;
	}

	/**
	 * updates image dialog width based on if image is showing
	 * @param newVal
	 */
	imageReadyToShowUpdated(newVal: boolean): void {
		this.imageReadyToShow = newVal;
	}

	/**
	 * update company image showing with the latest image
	 * @param newImage
	 */
	updateCompanyImg(newImage: string): void {
		this.companyImg = newImage;
	}
}
