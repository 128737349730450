import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ScheduleStorageService } from '../../../services/project/schedule-storage.service';
import { CurrentProjectReport, ProjectDashboardService } from '../../../services/project/project.service';
import { Activity, Xer, XerActivity, XerActivityCode, XerData, XerTaskActivity } from '@rhinoworks/xer-parse';
import { DropDownTreesModule } from '@progress/kendo-angular-dropdowns';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { ActvCodeFilterItem, SubCodeFilterItem } from '../overview/activity-completion/activity-completion.component';
import { AsyncPipe, DecimalPipe, NgIf } from '@angular/common';
import { caretAltDownIcon } from '@progress/kendo-svg-icons';
import { TotalFloatIndexArgs } from '@rhinoworks/analytics-calculations';
import { ActivityCompletionModule } from '../overview/activity-completion/activity.completion.module';
import { ProjectOverviewModule } from '../overview/project-overview.module';
import { DrivingPathComponent } from '../schedule-updates-list/driving-path/driving-path.component';
import { haveCommonItem } from '../../../util/strings';
import { ExpansionPanelComponent, ExpansionPanelModule, GridLayoutModule } from '@progress/kendo-angular-layout';
import { TooltipMenuModule } from '../../portfolio/tooltip-menu/tooltip-menu.module';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { RestService } from '../../../services/common/rest.service';
import { hasObjChanged } from '../../../util/projects';
import { dataFromXer } from '../../../util/tasks';
import { UpdateInterface } from '../../../models/Update/Task';

@Component({
	selector: 'app-focus',
	standalone: true,
	imports: [
		DropDownTreesModule,
		SVGIconModule,
		AsyncPipe,
		ActivityCompletionModule,
		ProjectOverviewModule,
		DrivingPathComponent,
		GridLayoutModule,
		TooltipMenuModule,
		TooltipModule,
		ButtonModule,
		NgIf,
		ExpansionPanelModule,
		DecimalPipe,
	],
	templateUrl: './focus.component.html',
	styleUrl: './focus.component.scss',
})
export class FocusComponent implements OnInit {
	icons = {
		caretDown: caretAltDownIcon,
	};
	allActivityCodes: ActvCodeFilterItem[] = [];
	selectedActivityCodes: SubCodeFilterItem[] = [];
	latestSelection: ActvCodeFilterItem[] = [];
	selectedActivityCodesForPanel: Array<
		SubCodeFilterItem & {
			expanded: boolean;
			progressScore: number;
			qcScore: number;
			riskScore: number;
			actvCompletion: number;
			floatConsumption: string;
			floatConsumptionIsLarger: boolean;
		}
	> = [];
	openPanel: SubCodeFilterItem;
	codesTag: string = '';
	progressScore: number = 0;
	qcScore = 0;
	riskScore = 0;
	latestXer: Xer;
	lastActivityCode: string;
	loading: boolean = true;
	hasChanges: boolean = false;
	@ViewChildren('expansionPanels') panels: QueryList<ExpansionPanelComponent>;
	constructor(
		public scheduleService: ScheduleStorageService,
		public projectService: ProjectDashboardService,
		public restService: RestService
	) {}

	ngOnInit() {
		const updateIds = this.projectService.$currentProjectData.value.updateIds;
		this.scheduleService.grabUpdateXer(updateIds[updateIds.length - 1]).then((xerData) => {
			const xer: Xer = new Xer(xerData);
			this.latestXer = xer;
			const selectedActivityCodes: SubCodeFilterItem[] = [];
			const savedActCodes: string[] = this.projectService.$currentProjectData.value?.focusTabSelectedCodes || [];
			this.allActivityCodes = this.scheduleService.generateActivityCodeFilter(xerData, this.selectedActivityCodes);
			const codes: SubCodeFilterItem[] = [];
			this.allActivityCodes.forEach((type: ActvCodeFilterItem) => {
				type.subCodes.forEach((code: SubCodeFilterItem) => {
					if (codes.findIndex((c: SubCodeFilterItem) => c.shortName === code.shortName) === -1) {
						codes.push(code);
					}
				});
				const copyOfType: any = structuredClone(type);
				copyOfType.parentName = undefined;
				codes.push(copyOfType);
			});
			savedActCodes.forEach((code: string) => {
				const matchingCode: SubCodeFilterItem = codes.find((c: SubCodeFilterItem) => c.shortName === code);
				if (matchingCode) {
					selectedActivityCodes.push(matchingCode);
				}
			});
			this.selectedActivityCodes = selectedActivityCodes;
			this.selectedActivityCodesForPanel = this.selectedActivityCodes
				.filter((code: SubCodeFilterItem) => code?.parentName !== undefined)
				.map((c: SubCodeFilterItem) => ({
					...c,
					expanded: false,
					progressScore: null,
					qcScore: null,
					riskScore: null,
					actvCompletion: null,
					floatConsumption: '',
					floatConsumptionIsLarger: false,
				}));
			const tagCodes: ActvCodeFilterItem[] = selectedActivityCodes.map((c: SubCodeFilterItem) => ({
				...c,
				subCodes: c.parentName === undefined ? [null, null] : [],
			}));
			this.latestSelection = tagCodes;
			this.updateTagText(tagCodes);
			this.updateScoresBuffer();
			this.loading = false;
			this.checkForChanges();
		});
		this.scheduleService.$allUpdates.subscribe((updates) => {
			if (updates?.length > 0) {
				this.updateScoresBuffer();
			}
		});
	}

	async updateScores() {
		const report: CurrentProjectReport = this.projectService.$currentProjectReport.value;
		const updates: UpdateInterface[] = this.scheduleService.$allUpdates.value;
		const data: XerData = await this.scheduleService.grabUpdateXer(updates[updates.length - 1]?._id);
		const prevData: XerData =
			updates?.length > 1 ? await this.scheduleService.grabUpdateXer(updates[updates.length - 2]?._id) : null;
		const tasks: XerActivity[] = dataFromXer<XerActivity>(data, 'TASK');
		const prevTasks: XerActivity[] = dataFromXer<XerActivity>(prevData, 'TASK');
		const prevActvCodes: XerActivityCode[] = dataFromXer<XerActivityCode>(prevData, 'ACTVCODE');
		const taskActv: XerTaskActivity[] = dataFromXer<XerTaskActivity>(data, 'TASKACTV');
		const prevTaskActv: XerTaskActivity[] = dataFromXer<XerTaskActivity>(prevData, 'TASKACTV');
		this.selectedActivityCodesForPanel.forEach(
			(
				code: SubCodeFilterItem & {
					expanded: boolean;
					progressScore: number;
					qcScore: number;
					riskScore: number;
					actvCompletion: number;
					floatConsumption: string;
					floatConsumptionIsLarger: boolean;
				}
			) => {
				const updateFloatHistoricalByCode: Record<string, TotalFloatIndexArgs> =
					report?.actvCodeFloatHistorical[report?.actvCodeFloatHistorical?.length - 1];
				const prevUpdateFloatHistoricalByCode: Record<string, TotalFloatIndexArgs> =
					report?.actvCodeFloatHistorical?.length > 1
						? report?.actvCodeFloatHistorical[report?.actvCodeFloatHistorical?.length - 2]
						: undefined;
				const recordKey: string = code.name + '-//-' + code.shortName;
				const updateVal: TotalFloatIndexArgs = updateFloatHistoricalByCode[recordKey];
				let totalFloatAllActivities: number = 0;
				let prevTotalFloat: number = 0;
				let prevTotalIncomplete: number = 0;
				let totalIncompleteActivities: number = 0;
				const currentUpdateTaskIds: number[] = taskActv
					.filter((t: XerTaskActivity) => t.actv_code_id === code.id)
					.map((a) => a.task_id);
				const codeInPrevUpdate: XerActivityCode = prevActvCodes.find(
					(c: XerActivityCode) => c.short_name === code.shortName
				);
				const prevUpdateTaskIds: number[] = prevTaskActv
					.filter((t: XerTaskActivity) => t.actv_code_id === codeInPrevUpdate.actv_code_id)
					.map((a) => a.task_id);
				const filteredTasks: XerActivity[] = tasks.filter((t: XerActivity) => currentUpdateTaskIds.includes(t.task_id));
				const filteredPrevTasks: XerActivity[] = prevTasks.filter((t: XerActivity) =>
					prevUpdateTaskIds.includes(t.task_id)
				);
				if (filteredTasks.length > 0) {
					for (const task of filteredTasks) {
						const totalFloat: number = task.total_float_hr_cnt;
						if (task.act_end_date || totalFloat === undefined) {
							continue;
						}
						totalFloatAllActivities += Math.round(task.total_float_hr_cnt / 8);
						totalIncompleteActivities += 1;
					}
					for (const task of filteredPrevTasks) {
						if (task.act_end_date || task.total_float_hr_cnt === undefined) {
							continue;
						}
						prevTotalFloat += Math.round(task.total_float_hr_cnt / 8);
						prevTotalIncomplete += 1;
					}
				}
				const currentFloat: number = Math.round(totalFloatAllActivities / (totalIncompleteActivities || 1));
				const prevFloat: number =
					this.projectService.$currentProjectData.value?.updateIds?.length > 1
						? Math.round(prevTotalFloat / (prevTotalIncomplete || 1))
						: null;
				const floatDifference: number =
					prevFloat === null ? null : Math.abs(Math.round((currentFloat / prevFloat - 1) * 100));
				code.floatConsumption =
					floatDifference === null ||
					isNaN(floatDifference) ||
					floatDifference === Infinity ||
					floatDifference === -Infinity
						? null
						: currentFloat > prevFloat
							? '+' + floatDifference + '%'
							: (currentFloat < prevFloat ? '-' : '') + floatDifference + '%';
				code.floatConsumptionIsLarger = currentFloat > prevFloat;
				const nonCompletedFilteredTasks: XerActivity[] = filteredTasks.filter(
					(t: XerActivity) => t.status_code !== 'TK_Complete'
				);
				code.alwaysDisabled = filteredTasks?.length === 0;
				code.disabled = nonCompletedFilteredTasks?.length === 0;
				//todo: update these 3 scores with new code-specific vals
				// code.progressScore = null;
				// code.qcScore = null;
				// code.riskScore = null;
			}
		);
		this.allActivityCodes.forEach((type: ActvCodeFilterItem) => {
			let hasAtLeastOneCodeWithTasks: boolean = false;
			let hasAtLeastOneCodeWithNonCompletedTasks: boolean = false;
			type.subCodes.forEach((code: SubCodeFilterItem) => {
				const currentUpdateTaskIds: number[] = taskActv
					.filter((t: XerTaskActivity) => t.actv_code_id === code.id)
					.map((a) => a.task_id);
				const filteredTasks: XerActivity[] = tasks.filter((t: XerActivity) => currentUpdateTaskIds.includes(t.task_id));
				const nonCompletedFilteredTasks: XerActivity[] = filteredTasks.filter(
					(t: XerActivity) => t.status_code !== 'TK_Complete'
				);
				code.alwaysDisabled = filteredTasks?.length === 0;
				code.disabled = nonCompletedFilteredTasks?.length === 0;
				if (filteredTasks?.length > 0) {
					hasAtLeastOneCodeWithTasks = true;
				}
				if (nonCompletedFilteredTasks?.length > 0) {
					hasAtLeastOneCodeWithNonCompletedTasks = true;
				}
			});
			type.alwaysDisabled = !hasAtLeastOneCodeWithTasks;
			type.disabled = !hasAtLeastOneCodeWithNonCompletedTasks;
		});
	}

	filterChanged(ev?: ActvCodeFilterItem[]): void {
		this.latestSelection = ev;
		this.selectedActivityCodesForPanel = this.selectedActivityCodes
			.filter((code: SubCodeFilterItem) => code?.parentName !== undefined)
			.map((c: SubCodeFilterItem) => ({
				...c,
				expanded: false,
				progressScore: null,
				qcScore: null,
				riskScore: null,
				actvCompletion: null,
				floatConsumption: '',
				floatConsumptionIsLarger: false,
			}));
		this.updateTagText(ev);
		this.updateScoresBuffer();
		this.checkForChanges();
	}

	public itemDisabled(dataItem: { alwaysDisabled: boolean; disabled: boolean }) {
		return dataItem.alwaysDisabled;
	}

	updateTagText(ev?: ActvCodeFilterItem[]): void {
		if (ev?.length === 0) {
			this.codesTag = '';
		} else {
			const topLevelCode = ev.find((item) => item?.subCodes?.length > 0);
			if (topLevelCode !== undefined) {
				this.codesTag = topLevelCode.name;
			} else {
				this.codesTag = ev?.length === 1 ? ev[0].name : ev?.length + ' codes selected';
			}
		}
	}

	saveChanges(): void {
		this.loading = true;
		const newSelectedCodes: string[] = Array.from(
			this.selectedActivityCodes.map((c: SubCodeFilterItem) => c.shortName)
		);
		this.restService
			.patch(`project/${this.projectService.$currentProjectData.value._id}`, {
				focusTabSelectedCodes: newSelectedCodes,
			})
			.subscribe((val) => {
				console.log('patch res', val);
				this.loading = false;
				this.hasChanges = false;
			});
	}

	public onAction(code: SubCodeFilterItem, index: number): void {
		this.panels.forEach((panel: ExpansionPanelComponent, idx: number) => {
			if (idx !== index && panel.expanded) {
				panel.toggle();
			}
		});
		this.openPanel = structuredClone(code);
		const latest: ActvCodeFilterItem = this.latestSelection.find(
			(c: ActvCodeFilterItem) => c.shortName === code.shortName
		);
		this.doLatest([latest]);
	}

	checkForChanges(): void {
		const lastSaved: string[] = this.projectService.$currentProjectData.value?.focusTabSelectedCodes || [];
		const currentCodes: string[] = Array.from(this.selectedActivityCodes.map((c: SubCodeFilterItem) => c.shortName));
		this.hasChanges = this.selectedActivityCodes?.length && hasObjChanged(lastSaved, currentCodes);
	}

	doLatest(ev: ActvCodeFilterItem[]): void {
		let latestActv: Activity;
		for (const actv of this.latestXer.sortedActivities) {
			if (latestActv && latestActv.targetFinish > actv.targetFinish) {
				continue;
			}
			if (!ev?.length || haveCommonItem(actv.activityCodeIds, new Set(ev.map((item) => item.id)))) {
				latestActv = actv;
			}
		}
		this.lastActivityCode = latestActv?.code;
	}

	updateScoresBuffer(): void {
		let i: number = 0;
		const waitingInterval = setInterval(() => {
			if (
				(this.projectService.$currentProjectReport.value !== undefined &&
					this.scheduleService.$allUpdates.value !== undefined) ||
				i > 500
			) {
				clearInterval(waitingInterval);
				this.updateScores();
			}
			i++;
		}, 200);
	}
}
