<kendo-gridlayout
	[rows]="['40px','220px']"
	[cols]="['100%']"
	[gap]="{ rows: 0, cols: 0 }"
	class='grid'
>
	<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-container'>
		<div class='top-risks-titlebar'>
			Top Active Risks
		</div>
	</kendo-gridlayout-item>
	<kendo-gridlayout-item [row]='2' [col]='1' class='gridlayout-container'>
		<kendo-grid
			[data]="gridView"
			[kendoGridBinding]="gridData"
			[height]="220"
			[selectable]='false'
			[size]='"small"'
			[sortable]='false'
			[sort]="sort"
			[navigable]="false"
			class='saas-risk-top-active-risks-grid'
		>
			<kendo-grid-column field="riskId" title="ID" width='45'></kendo-grid-column>
			<kendo-grid-column field="riskName" title="Name"></kendo-grid-column>
			<kendo-grid-column field="category" title="Category"></kendo-grid-column>
			<kendo-grid-column field="triggerPhase" title="Trigger Phase"></kendo-grid-column>
			<kendo-grid-column field="preMitigation.probability" title="Probability">
				<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
					<span class='number-cell'>
						{{ (dataItem.preMitigation.probability || 0) }}
					</span>
				</ng-template>
			</kendo-grid-column>
			<kendo-grid-column field="preMitigation.activityImpact" title="Activity Impact">
				<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
					<span class='number-cell'>
						{{ ((100 * (dataItem.preMitigation.activityImpact || 0)) | number : '1.0-0') + '%' }}
					</span>
				</ng-template>
			</kendo-grid-column>
			<kendo-grid-column field="preMitigationRiskScore" title="Pre Mitigation Risk Score" class='no-padding'>
				<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
					<span class='whole-cell k-grid-ignore-click' [style.backgroundColor]='colorCodeRiskScore(dataItem.preMitigationRiskScore || 0)'>
            {{ (dataItem.preMitigationRiskScore || 0) }}
          </span>
				</ng-template>
			</kendo-grid-column>
		</kendo-grid>
	</kendo-gridlayout-item>
</kendo-gridlayout>
