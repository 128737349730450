import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
export interface DialogData {
	type: string;
}
@Component({
	selector: 'global-tooltip-content',
	templateUrl: 'global-tooltip-content.html',
	styleUrls: ['global-tooltip-content.scss'],
})
export class GlobalTooltipContentComponent implements OnInit {
	@Output() closeGlobalTooltip = new EventEmitter<boolean>();
	@Output() tooltipName = new EventEmitter<string>();
	@Input() tooltipType: string;

	titleText: string = '';
	firstParagraph: string = '';
	secondParagraph: string = '';
	thirdParagraph: string = '';
	i1: string = '';
	i2: string = '';
	i3: string = '';
	i4: string = '';
	i5: string = '';
	i6: string = '';
	i7: string = '';
	i8: string = '';
	i9: string = '';
	i10: string = '';
	i11: string = '';
	i12: string = '';
	i13: string = '';
	i14: string = '';
	i15: string = '';
	subtitle1 = '';
	subtitle2 = '';
	strongText: string = '';
	moderateText: string = '';
	needsImprovementText: string = '';
	strongRange: string = '';
	moderateRange: string = '';
	needsImprovementRange: string = '';
	subtitle4: string = '';
	p4: string = '';
	subtitle5: string = '';
	p5: string = '';
	disclaimer = '';
	saas1 = '';
	saas2 = '';
	saas3 = '';
	saas4 = '';
	saas5 = '';
	isSaas = false;
	tooltipText: any[] = [
		{
			name: 'projects-summary',
			text: 'Projects Summary tracks the number of active projects uploaded to the dashboard, as well as the number of projects missing an update (45 or more calendar days since last data date)',
			title: 'Projects Summary',
		},
		{
			name: 'project-breakdown',
			text: 'Project Breakdown gives a breakdown of projects based on their update variance.',
			item1:
				'Projects Slipped tracks the number of projects that lost days in the latest update (update variance < 0).',
			item2:
				'Projects On-Track tracks the number of projects with no variance compared to the previous update (update variance = 0).',
			item3:
				'Projects Improved tracks the number of projects that gained days in the latest update (update variance > 0).',
			text3: 'Click on a box to quick-filter by projects of that type.',
			title: 'Project Breakdown',
		},
		/*{
			name: 'projects-slipped',
			text: 'Projects Slipped tracks the number of projects that lost days in the latest update (update variance <0)',
			title: 'Projects Slipped',
		},
		{
			name: 'projects-improved',
			text: 'Projects Improved tracks the number of projects that gained days in the latest update (update variance >0)',
			title: 'Projects Improved',
		},
		{
			name: 'projects-on-track',
			text: 'Projects On-Track tracks the number of projects with no variance compared to the previous update (update variance = 0)',
			title: 'Projects On-Track',
		},*/
		{
			name: 'projects',
			text: 'The list of projects that are being tracked in the dashboard. This also includes:',
			title: 'Projects',
			item1: 'Contract Completion – The completion date stipulated by the contract',
			item2:
				'Current Completion – The completion date stipulated by the project schedule. This date is determined by the duration, sequencing and progress that is input into the schedule.',
			item3:
				'Variance – The variance of the project is the delta between the contract completion date and the current completion date. If the variance is negative the project has slipped, if the variance is positive the project is ahead of schedule.',
			item4:
				'Project Score – The Project Score metric reflects the overall status, reliability, and health of the Project. Use this score to quickly identify how well your project is performing and how well the project schedules align with project controls best practices.',
			item5:
				'QC Score – The Quality Control Score gauge represents the current schedule updates Quality Control Score. Scores less than 80% should be reviewed with the project team.',
			item6:
				'Updates – The number of updates uploaded to the project dashboard. This number excludes the baseline schedule.',
			item7: 'Last Updated – The latest Data Date uploaded to the project dashboard.',
			text2: 'Filter – Filter the project by the following criteria:',
			item8:
				'Market – Filter your portfolio by the type of construction project. For example, airports, data centers, educational facilities.',
			item9:
				'Completion Variance – Filter your projects by how much the current completion date has gained or slipped when compared to the contract completion date.',
			item10:
				'Current Completion Status – Filter the projects by whether they are behind schedule, on time, or ahead of schedule.',
			item11:
				'Project Score – Filter the projects based on the project score criteria; good, moderate, needs improvement.',
			item12:
				'Past Period Performance – Filter the projects based on whether the project slipped, maintained progress, or gained time during the last update cycle.',
			item13: 'Risk Score – Filter the projects based on the risk score criteria; good, moderate, needs improvement.',
			item14:
				'Last Upload – Track whether your project has been updated during the normal update cycle. An update cycle that is 45+ days late should be reviewed by the team.',
			item15:
				'Reliability Score - Filter the projects based on the reliability score criteria; good, moderate, needs improvement.',
		},
		{
			name: 'map',
			text: 'A visual snapshot of your projects overlaid on map. The color of the icons correlate to the project’s status:',
			title: 'Map',
			item1: 'Orange - Missing Update',
			item2: 'Black - Missing Data',
			item3: 'Red - Slipped',
			item4: 'Green - On-Track',
			item5: 'Blue - Improved',
			text3: 'Click on an icon to jump to the single project dashboard page.',
		},
		{
			name: 'project-completion-variance',
			text: 'This a trending graph of your projects current completion date compared to the contract completion date. The graph is a quick snapshot into whether your project it on time, gaining time, or slipping. The vertical axis displays whether you are on time, gaining time or slipping the horizontal axis are the individual updates plotted over time.',
			title: 'Project Completion Variance',
		},
		{
			name: 'risk-summary',
			text: 'The Risk Score represents the likelihood of the project completing on-time. This metric considers past performance periods, evaluating how consistently critical and near critical activities are executed as planned. Evaluate the reliability of your project per your latest schedule update by using the thresholds below.',
			title: 'Risk Summary',
			strongR: '85% to 100%',
			strongT: 'Strong = ',
			moderateR: '70% to 84.9%',
			moderateT: 'Moderate = ',
			needsImprovementR: '0% to 69.9%',
			needsImprovementT: 'Needs Improvement = ',
			sub1: 'What does this score represent?',
			sub2: 'Ways to improve the score:',
			sub4: 'Current / Previous Critical Path Switch',
			sub5: 'Actual Start / Actual Finish Ratios',
			paragraph4:
				'Critical and near-critical paths to each substantial completion milestone should be reviewed prior to submission. Critical path activities should be communicated to the field team and completed during each update period. In instances where the critical path slips, fragnet activities should be created to account for lost time.',
			paragraph5:
				'Planned progress from previous update should be reviewed to ensure no progress updates are missed. Similarly, the one month lookahead schedule should be reviewed prior to each submission to ensure planned progress is achievable with focus placed on two higher criticality thresholds.',
			disclaimer: '*Data only reflects projects with Monte Carlo enabled',
		},
		{
			name: 'saas-risk-summary',
			saas1: 'Pre-Mitigation Risk Impacts',
			saas2:
				"The pre-mitigation risk impacts show the potential effects of each risk on the project schedule if no mitigating actions are taken. These represent the 'raw' or unmitigated risk impacts.",
			saas3: 'Post-Mitigation Risk Impacts',
			saas4:
				'The post-mitigation risk impacts show the potential effects of each risk on the project schedule after planned mitigating actions are implemented. These represent the residual risk impacts after mitigation is applied.',
			saas5:
				'The pre-mitigation impacts are usually higher than the post-mitigation impacts, since the mitigating actions are designed to reduce the risks. Comparing the pre and post-mitigation impacts helps the project team evaluate the effectiveness of planned mitigation strategies.',
			text: 'The Predictability Score utilizes forward-looking components to give a warning of potential risks in the remainder of your project.',
			title: 'Risk Summary',
			strongR: '85% to 100%',
			strongT: 'Strong = ',
			moderateR: '70% to 84.9%',
			moderateT: 'Moderate = ',
			needsImprovementR: '0% to 69.9%',
			needsImprovementT: 'Needs Improvement = ',
			sub1: 'What does the Predictability Score represent?',
		},
		{
			name: 'portfolio-summary',
			text: 'Portfolio Summary tracks average scores across all visible projects, taking the latest update of each project into account. Project Score Trending charts the average project score for all visible projects over the last 6 months.',
			title: 'Portfolio Summary',
		},
		{
			name: 'portfolio-risk',
			title: 'Portfolio Risk',
			item1:
				'Missing Updates tracks the number of Monte Carlo enabled projects missing an update (45 or more calendar days since last data date).',
			item2:
				'Projects Slipped tracks the number of Monte Carlo enabled projects that lost days in the latest update (update variance < 0).',
		},
		{
			name: 'categories-of-risk',
			title: 'Categories of Risk',
			text: 'Categories of Risk is a summary of all Risks logged for projects using “Monte Carlo - Risk Register"',
		},
		{
			name: 'heat-map',
			title: 'Heat Map',
			text: 'Heat Map provides a summary of the Likelihood vs. Impact of all Risks logged for projects using “Monte Carlo - Risk Register”',
		},
	];
	public opened: boolean = true;

	constructor() {}

	ngOnInit(): void {
		const type = this.tooltipType;
		const index = this.tooltipText.findIndex((x) => x.name === type);
		if (this.tooltipText[index].sub1) {
			this.subtitle1 = this.tooltipText[index].sub1;
		}
		if (this.tooltipText[index].sub2) {
			this.subtitle2 = this.tooltipText[index].sub2;
		}
		if (this.tooltipText[index].strongR) {
			this.strongRange = this.tooltipText[index].strongR;
		}
		if (this.tooltipText[index].strongT) {
			this.strongText = this.tooltipText[index].strongT;
		}
		if (this.tooltipText[index].moderateR) {
			this.moderateRange = this.tooltipText[index].moderateR;
		}
		if (this.tooltipText[index].moderateT) {
			this.moderateText = this.tooltipText[index].moderateT;
		}
		if (this.tooltipText[index].needsImprovementR) {
			this.needsImprovementRange = this.tooltipText[index].needsImprovementR;
		}
		if (this.tooltipText[index].needsImprovementT) {
			this.needsImprovementText = this.tooltipText[index].needsImprovementT;
		}
		if (this.tooltipText[index].text) {
			this.firstParagraph = this.tooltipText[index].text;
		}
		if (this.tooltipText[index].text2) {
			this.secondParagraph = this.tooltipText[index].text2;
		}
		if (this.tooltipText[index].text3) {
			this.thirdParagraph = this.tooltipText[index].text3;
		}
		if (this.tooltipText[index].item1) {
			this.i1 = this.tooltipText[index].item1;
		}
		if (this.tooltipText[index].item2) {
			this.i2 = this.tooltipText[index].item2;
		}
		if (this.tooltipText[index].item3) {
			this.i3 = this.tooltipText[index].item3;
		}
		if (this.tooltipText[index].item4) {
			this.i4 = this.tooltipText[index].item4;
		}
		if (this.tooltipText[index].item5) {
			this.i5 = this.tooltipText[index].item5;
		}
		if (this.tooltipText[index].item6) {
			this.i6 = this.tooltipText[index].item6;
		}
		if (this.tooltipText[index].item7) {
			this.i7 = this.tooltipText[index].item7;
		}
		if (this.tooltipText[index].item8) {
			this.i8 = this.tooltipText[index].item8;
		}
		if (this.tooltipText[index].item9) {
			this.i9 = this.tooltipText[index].item9;
		}
		if (this.tooltipText[index].item10) {
			this.i10 = this.tooltipText[index].item10;
		}
		if (this.tooltipText[index].item11) {
			this.i11 = this.tooltipText[index].item11;
		}
		if (this.tooltipText[index].item12) {
			this.i12 = this.tooltipText[index].item12;
		}
		if (this.tooltipText[index].item13) {
			this.i13 = this.tooltipText[index].item13;
		}
		if (this.tooltipText[index].item14) {
			this.i14 = this.tooltipText[index].item14;
		}
		if (this.tooltipText[index].item15) {
			this.i15 = this.tooltipText[index].item15;
		}
		if (this.tooltipText[index].sub4) {
			this.subtitle4 = this.tooltipText[index].sub4;
		}
		if (this.tooltipText[index].sub5) {
			this.subtitle5 = this.tooltipText[index].sub5;
		}
		if (this.tooltipText[index].paragraph4) {
			this.p4 = this.tooltipText[index].paragraph4;
		}
		if (this.tooltipText[index].paragraph5) {
			this.p5 = this.tooltipText[index].paragraph5;
		}
		if (this.tooltipText[index].disclaimer) {
			this.disclaimer = this.tooltipText[index].disclaimer;
		}
		if (this.tooltipText[index].name === 'saas-risk-summary') {
			this.saas1 = this.tooltipText[index].saas1;
			this.saas2 = this.tooltipText[index].saas2;
			this.saas3 = this.tooltipText[index].saas3;
			this.saas4 = this.tooltipText[index].saas4;
			this.saas5 = this.tooltipText[index].saas5;
			this.isSaas = true;
		}
		this.titleText = this.tooltipText[index].title;
		this.tooltipName.emit(this.titleText);
	}
}
