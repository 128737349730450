<div id="forgot-password" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">
  <div id="forgot-password-intro" fxFlex fxHide fxShow.gt-xs>
    <div class="logo" [@animate]="{ value: '*', params: { scale: '0.2' } }">
      <img src='../../../assets/images/logos/fuse.svg' />
    </div>

    <div class="title" [@animate]="{ value: '*', params: { delay: '50ms', y: '25px' } }">Welcome to the FUSE!</div>

    <div class="description" [@animate]="{ value: '*', params: { delay: '100ms', y: '25px' } }">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ullamcorper nisl erat, vel convallis elit
      fermentum pellentesque. Sed mollis velit facilisis facilisis viverra.
    </div>
  </div>

  <div
    id="forgot-password-form-wrapper"
    [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }"
  >
    <div id="forgot-password-form">
      <div class="logo" fxHide.gt-xs>
        <img src='../../../assets/images/logos/fuse.svg' />
      </div>

      <div class="title">RECOVER YOUR PASSWORD</div>

      <form name="forgoPasswordForm" [formGroup]="forgotPasswordForm" novalidate>
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" />
          <mat-icon matSuffix class="secondary-text">mail</mat-icon>
          <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')"> Email is required </mat-error>
          <mat-error *ngIf="forgotPasswordForm.get('email').hasError('email')">
            Please enter a valid email address
          </mat-error>
        </mat-form-field>

        <button
          mat-raised-button
          class="submit-button"
          color="accent"
          aria-label="SEND RESET LINK"
          [disabled]="forgotPasswordForm.invalid"
        >
          SEND RESET LINK
        </button>
      </form>

      <div class="login" fxLayout="row" fxLayoutAlign="center center">
        <a class="link" [routerLink]="'/auth/login-2'">Go back to login</a>
      </div>
    </div>
  </div>
</div>
