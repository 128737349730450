import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-oauth2-rapid',
	templateUrl: './oauth2-rapid.component.html',
	styleUrls: ['./oauth2-rapid.component.scss'],
})
export class Oauth2RapidComponent implements OnInit {
	constructor(
		private oauthService: OAuthService,
		private activatedRoute: ActivatedRoute
	) {}

	public async initiateOauthLogin() {
		// console.log('Instantiating OAuth Login');
		//this.oauthService.initImplicitFlow();
		/**
     this.authService.
     await this.authService.login(
     this.loginForm.value.email,
     this.loginForm.value.password,
     undefined
     );
     **/

		this.activatedRoute.queryParams.subscribe((params) => {
			// console.log('queryParams', params['fromAccount']);
			if (params.fromAccount === 'true') {
				this.oauthService.loadDiscoveryDocumentAndTryLogin();
			} else {
				window.location.href = 'https://account.rhino.works/login/?next=/onboarding/analytics/';
			}
		});
	}

	ngOnInit(): void {
		/**
		 * setTimeout here is essentially a desperate attempt at making the oauth call
		 */
		this.initiateOauthLogin();
	}
}
