import { Xer, XerActivity } from '@rhinoworks/xer-parse';
import { UpdateInterface } from '../../../models/Update/Task';
import { datePipe } from '../../../util/pipes/date.pipe';
import { groupBy, GroupResult, orderBy } from '@progress/kendo-data-query';
import { ProjectInterface } from '../../../models/Project';

export class Update {
	data: UpdateInterface = {
		_id: '',
		finishMilestone: undefined,
		hasCostData: false,
	};
	xer: Xer;
	finishMilestones:
		| GroupResult[]
		| Array<{ text: string; earlyFinish: Date; actv: XerActivity; category: string } & XerActivity>;

	constructor(
		private project?: ProjectInterface,
		private lastUpdate?: UpdateInterface
	) {}

	activityCategory(actv: XerActivity): string {
		switch (actv.task_type) {
			case 'TT_FinMile':
				return 'Finish Milestones';
			case 'TT_Mile':
				return 'Milestone';
		}
		return 'All Activities';
	}

	filterFinishMilestones(term?: string): typeof this.finishMilestones {
		term = term ? term?.toLowerCase() : term;
		this.finishMilestones = groupBy(
			orderBy(
				Array.from(
					this.xer.sortedActivities
						.filter(
							(actv) =>
								actv._activity.taskType !== 'TT_Mile' &&
								(!term || actv._activity.activityName.includes(term) || actv._activity.code.includes(term))
						)
						.map((actv) => ({
							...actv.raw_entry,
							actv: actv.raw_entry,
							category: this.activityCategory(actv.raw_entry),
							earlyFinish: actv._activity.earlyFinish,
							text: `${actv._activity.code} - ${actv._activity.activityName} - ${datePipe(
								actv._activity.earlyFinish,
								'MMM d, y'
							)}`,
						}))
				),
				[
					{ field: 'category', dir: 'desc' },
					{ field: 'earlyFinish', dir: 'asc' },
				]
			),
			[{ field: 'category' }]
		);
		return this.finishMilestones;
	}

	// step 1 - go through and delete anything extra in the baseLineUploadComponent -- admin-company-project-admin-user-list any functions to bring over
	async handleFileSelect(evt, index = 0) {
		const xerFile = evt.target.files[index];
		try {
			(window as any).global = window;
			window.Buffer = window.Buffer || require('buffer').Buffer;
			this.data.xerFileName = xerFile?.name;
			const buffer = await evt.target.files[index].arrayBuffer();
			const buf: Buffer = Buffer.from(buffer);
			const a: Xer = new Xer();
			a.buffer = buf;
			this.xer = a;
			console.log(this.data.xerFileName, a.buffer, this.xer, buf.buffer);
			const allProjectIds = Array.from(a.projects.keys());
			this.finishMilestones = this.filterFinishMilestones();
			this.data.selectedProjectId = this.xer.projects.has(this.lastUpdate?.selectedProjectId)
				? this.lastUpdate?.selectedProjectId
				: allProjectIds[0];
			this.data.finishMilestone =
				this.xer.sortedActivities.find(
					(actv) =>
						(!this.data.selectedProjectId || actv._activity.projectId === this.data.selectedProjectId) &&
						actv.code === this.lastUpdate?.finishMilestone?.task_code
				)?.raw_entry ||
				this.xer.sortedActivities.find(
					(actv) =>
						(!this.data.selectedProjectId || actv._activity.projectId === this.data.selectedProjectId) &&
						actv.code === this.project?.finishMilestoneCode
				)?.raw_entry ||
				this.xer.sortedActivities.find(
					(actv) =>
						(!this.data.selectedProjectId || actv._activity.projectId === this.data.selectedProjectId) &&
						+actv.code > 0 &&
						+actv.code === +this.lastUpdate?.finishMilestone?.task_code
				)?.raw_entry ||
				this.xer.sortedActivities.find(
					(actv) =>
						(!this.data.selectedProjectId || actv._activity.projectId === this.data.selectedProjectId) &&
						+actv.code > 0 &&
						+actv.code === +this.project?.finishMilestoneCode
				)?.raw_entry;
			this.data.xerFileBuffer = a.xerData;
			this.data.isAegisGeneratedSchedule = true;
			const taskRsrcTableIndex: number = this.data.xerFileBuffer.findIndex((l) => l[0] === '%T' && l[1] === 'TASKRSRC');
			this.data.hasCostData = taskRsrcTableIndex > 0;
			this.data.isCostBaseline = false;
		} catch (err) {
			console.log('Error during file upload', err);
			return err.toString();
		}
	}
}
