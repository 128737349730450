import { SelectedActivityCodeInterface, XerActivityCode, XerActivityType } from './Update/Task';
import { MitigationEntity, MitigationEntityCollection, RiskRegister } from './risk';
import { ProjectFieldFilter } from '../util/kendo';
import { CompositeFilterDescriptor, FilterOperator } from '@progress/kendo-data-query';
import { ActivityFilterType, FilterCases } from '../pkg-test/services';
import { GanttActvType } from '../components/project-page/schedule-updates-list/current-update-gantt/gantt-group-by/gantt-group-by.component';
import { ReportGroup } from '../services/project/gantt.service';
import { OverviewComponent } from '../components/project-page/project-admin-settings/overview-tab-settings/overview-tab-settings.component';
import { QualityPreferences } from '@rhinoworks/analytics-calculations';

export interface P85HInterface {
	update: number;
	p85h: P85HistoricalTrendInterface;
}

export interface P85HistoricalTrendInterface {
	dataDate?: string;
	previousVariance?: number;
	projectedDate?: string;
	projectedDateVariance?: number; //days +/- from ccd
	p85Date?: string;
	p85DateVariance?: number; //days +/- from ccd
}

export interface HiddenMitigationColumnSettings {
	fullscreen?: string[];
	riskPage?: string[];
	scheduleSelector?: string[];
}

export interface ProjectPreferences {
	riskMitigation: {
		hiddenMitigationColumns?: HiddenMitigationColumnSettings;
		columnWidths?: {
			fullscreen?: Record<string, number>;
			riskPage?: Record<string, number>;
			scheduleSelector?: Record<string, number>;
		};
		mitigationEntities?: MitigationEntity[];
		unassignedCustomFields?: {
			riskOwner?: MitigationEntityCollection;
			costOwner?: MitigationEntityCollection;
			responsibility?: MitigationEntityCollection;
			category?: string[];
		};
	};
	monteCarlo?: {
		pfVariance?: [number, number];
	};
	scores?: {
		includeTypes?: Record<FilterCases, ActivityFilterType[]>;
	};
}

export const SCHEDULE_TYPE = ['Active', 'Archived', 'Closed', 'Baseline', 'Consulting'] as const;

export type ScheduleType = (typeof SCHEDULE_TYPE)[number];

export interface DatabaseSettings {
	connection: string;
	database: string;
	isAutomaticSetup: boolean;
	selectedFolder?: string;
	pcatFilter?: string;
	pcatFilterValue?: string;
	selectedBaselineId?: string;
}

export interface ProjectInterface {
	/**
	 * Existing Fields
	 */
	_id: string;
	updaterIds?: string[];
	updateIds: string[];
	baselineUpdateId?: string;
	projectSageId?: string;
	projectType?: string;
	name?: string;
	siteAddressReference?: string;
	lat?: any;
	lng?: any;
	creatorId?: string;
	companyId?: string;
	sharePoint?: string;
	memberIds?: string[];
	aegisPocName?: string;
	aegisPocPhone?: string;
	aegisPocEmail?: string;
	updateLock?: boolean;
	jobInitiationTimestamp?: Date;
	isMonteCarloDone?: boolean;
	mcWorkerAmt?: number;
	region: string;
	projectLead: string;
	imageBase64?: string;

	/**
	 * New Fields
	 */
	pmIds?: string[];
	currentUpdateId?: string;
	projectCode?: string;
	ntpDate?: Date;
	contractCompletionDate?: Date;
	projectNoticeToProceedDate?: Date;
	createdAt?: Date;
	archivedUpdateIds?: string[];
	isAegisManaged?: boolean;
	costBaseline?: string;

	database?: DatabaseSettings;

	/**
	 * ProjectModel Report Fields
	 */
	currentProjectReport: string;
	projectReport: string[];
	selectedActivityCodes?: SelectedActivityCodeInterface[];
	riskPagePurchased?: boolean;
	riskMetricsType?: string;
	pfCodes?: string[];
	p85h?: P85HInterface[];
	mcJobs?: Record<
		string,
		Array<{
			updateId: string;
			jobCount: number;
			jobProgress: number;
			riskId?: number;
			isPre?: boolean;
		}>
	>;
	isArchived?: boolean;
	isClosed?: boolean;
	isConsulting?: boolean;
	warnings?: Array<string>;
	riskMitigation?: RiskRegister[];
	preferences?: ProjectPreferences;
	finishMilestoneCode?: string;
	showMonteCarlo?: boolean;
	company?: number;
	pocId: number;
	clientId?: string;
	viewerEmails?: string[];
	lastRecalcedMonteCarlo?: Date;
	clientData?: Partial<ProjectInterface> & Omit<ProjectInterface, 'clientData'>;
	updateLockNotes?: boolean;
	updatedAt?: Date;
	ganttPresets?: CurrentUpdateGanttPreset[];
	performanceTrendingSelectedActivityCodes: string[];
	performanceTrendingSelectedCodeType: string;
	reportGroups?: ReportGroup[];
	invalidXerReason?: string;
	hasExistingSyncSettings?: boolean;
	projectSelectedDatabase?: string;
	dbSyncIds?: string[];
	selectedOverviewTabComponents?: OverviewComponent[];
	overviewTabDefault?: boolean;
	hiddenTabs?: string[];
	hiddenComponents?: OverviewComponent[];
	componentNotes?: ComponentNotes[];
	qualityPreferences?: QualityPreferences;
	focusTabSelectedCodes?: string[];
}

export interface ComponentNotes {
	id: number;
	notes: Array<{
		updateId: string;
		note: string;
		lastEditTimestamp: Date;
		lastEditUser: string;
	}>;
}

export interface QuickFilterMapping {
	active: boolean;
	projectListFilterName: string;
	projectListModelName: string;
	filterValue: string[];
	totalCurrentFilters: number;
}

export interface QuickFilterPreset {
	missingUpdate: QuickFilterMapping;
	slipped: QuickFilterMapping;
	onTrack: QuickFilterMapping;
	improved: QuickFilterMapping;
	rmt: QuickFilterMapping;
	active: QuickFilterMapping;
}

export const QuickFilterWidgets: QuickFilterPreset = {
	missingUpdate: {
		active: false,
		projectListFilterName: 'uploadList',
		projectListModelName: 'lastUploadDifference',
		filterValue: ['missing-upload'],
		totalCurrentFilters: 2,
	},
	slipped: {
		active: false,
		projectListFilterName: 'pastPeriodPerformanceList',
		projectListModelName: 'prevVariance',
		filterValue: ['slipped-period'],
		totalCurrentFilters: 2,
	},
	onTrack: {
		active: false,
		projectListFilterName: 'pastPeriodPerformanceList',
		projectListModelName: 'prevVariance',
		filterValue: ['maintained-period'],
		totalCurrentFilters: 2,
	},
	improved: {
		active: false,
		projectListFilterName: 'pastPeriodPerformanceList',
		projectListModelName: 'prevVariance',
		filterValue: ['gained-period'],
		totalCurrentFilters: 2,
	},
	rmt: {
		active: false,
		projectListFilterName: 'riskList',
		projectListModelName: 'riskMetricsType',
		filterValue: ['performanceFactor', 'riskRegister'],
		totalCurrentFilters: 2,
	},
	active: {
		active: false,
		projectListFilterName: 'scheduleTypeList',
		projectListModelName: 'scheduleType',
		filterValue: ['Active'],
		totalCurrentFilters: 2,
	},
};

export interface MultiselectDropdownCloseMapping {
	id: string;
	tagId: string;
	viewChildName: string;
}

export const MultiselectDropdownClosePreset: MultiselectDropdownCloseMapping[] = [
	{
		id: 'projectTypeMultiselect',
		tagId: 'projectTypeTagTemplate',
		viewChildName: 'projectTypeMultiselect',
	},
	{
		id: 'completionStatusMultiselect',
		tagId: 'completionStatusTagTemplate',
		viewChildName: 'completionStatusMultiselect',
	},
	{
		id: 'prevVarianceMultiselect',
		tagId: 'prevVarianceTagTemplate',
		viewChildName: 'prevVarianceMultiselect',
	},
	{
		id: 'lastUploadMultiselect',
		tagId: 'lastUploadTagTemplate',
		viewChildName: 'lastUploadMultiselect',
	},
	{
		id: 'aegisScoreMultiselect',
		tagId: 'aegisScoreTagTemplate',
		viewChildName: 'aegisScoreMultiselect',
	},
	{
		id: 'qcScoreMultiselect',
		tagId: 'qcScoreTagTemplate',
		viewChildName: 'qcScoreMultiselect',
	},
	{
		id: 'riskScoreMultiselect',
		tagId: 'riskScoreTagTemplate',
		viewChildName: 'riskScoreMultiselect',
	},
	{
		id: 'progressScoreMultiselect',
		tagId: 'progressScoreTagTemplate',
		viewChildName: 'progressScoreMultiselect',
	},
	{
		id: 'companyMultiselect',
		tagId: 'companyTagTemplate',
		viewChildName: 'companyMultiselect',
	},
	{
		id: 'rmtMultiselect',
		tagId: 'rmtTagTemplate',
		viewChildName: 'rmtMultiselect',
	},
	{
		id: 'archivedMultiselect',
		tagId: 'archivedTagTemplate',
		viewChildName: 'archivedMultiselect',
	},
	{
		id: 'varianceMultiselect',
		tagId: 'varianceTagTemplate',
		viewChildName: 'varianceSelect',
	},
];

export interface PortfolioQuickStats {
	totalProjects: number;
	totalProjectsMissingUpdates: number;
	totalProjectsSlipped: number;
	totalProjectsOnTrack: number;
	totalProjectsImproved: number;
}

export const QuickViewMapping = {
	missingUpdate: ['update-status', 'scheduleType'],
	slipped: ['prevVariance', 'scheduleType'],
	onTrack: ['prevVariance', 'scheduleType'],
	improved: ['prevVariance', 'scheduleType'],
	active: ['scheduleType'],
	riskActive: ['riskMetricsType', 'scheduleType'],
	riskMissingUpdate: ['update-status', 'riskMetricsType', 'scheduleType'],
	riskSlipped: ['prevVariance', 'riskMetricsType', 'scheduleType'],
	costActive: ['cost-loaded', 'scheduleType'],
};

const quickViewOption = [
	'missingUpdate',
	'slipped',
	'onTrack',
	'improved',
	'active',
	'riskActive',
	'riskMissingUpdate',
	'riskSlipped',
	'costActive',
] as const;

export type QuickViewOption = (typeof quickViewOption)[number];

export const isQuickViewOption = (value: any): value is QuickViewOption => quickViewOption.includes(value);

export const QuickViewFilters: Record<QuickViewOption, ProjectFieldFilter[]> = {
	riskActive: [
		{
			text: 'riskMetricsType',
			logic: 'or',
			filters: [
				{
					text: 'Performance Factor',
					field: 'riskMetricsType',
					value: 'performanceFactor',
					operator: FilterOperator.EqualTo,
				},
				{
					text: 'Risk Register',
					field: 'riskMetricsType',
					value: 'riskRegister',
					operator: FilterOperator.EqualTo,
				},
			],
		},
		{
			text: 'scheduleType',
			logic: 'and',
			filters: [
				{
					text: 'Active',
					field: 'scheduleType',
					value: 'Active',
					operator: FilterOperator.EqualTo,
				},
			],
		},
	],
	riskMissingUpdate: [
		{
			text: 'update-status',
			logic: 'and',
			filters: [
				{
					field: 'dUpload',
					value: 45,
					operator: FilterOperator.GreaterThanOrEqual,
				},
			],
		},
		{
			text: 'riskMetricsType',
			logic: 'or',
			filters: [
				{
					text: 'Performance Factor',
					field: 'riskMetricsType',
					value: 'performanceFactor',
					operator: FilterOperator.EqualTo,
				},
				{
					text: 'Risk Register',
					field: 'riskMetricsType',
					value: 'riskRegister',
					operator: FilterOperator.EqualTo,
				},
			],
		},
		{
			text: 'scheduleType',
			logic: 'and',
			filters: [
				{
					text: 'Active',
					field: 'scheduleType',
					value: 'Active',
					operator: FilterOperator.EqualTo,
				},
			],
		},
	],
	riskSlipped: [
		{
			text: 'prevVariance',
			logic: 'and',
			filters: [
				{
					text: 'Slipped Last Period (prev var < 0)',
					field: 'prevVariance',
					value: 0,
					operator: FilterOperator.LessThan,
				},
			],
		},
		{
			text: 'riskMetricsType',
			logic: 'or',
			filters: [
				{
					text: 'Performance Factor',
					field: 'riskMetricsType',
					value: 'performanceFactor',
					operator: FilterOperator.EqualTo,
				},
				{
					text: 'Risk Register',
					field: 'riskMetricsType',
					value: 'riskRegister',
					operator: FilterOperator.EqualTo,
				},
			],
		},
		{
			text: 'scheduleType',
			logic: 'and',
			filters: [
				{
					text: 'Active',
					field: 'scheduleType',
					value: 'Active',
					operator: FilterOperator.EqualTo,
				},
			],
		},
	],
	active: [
		{
			text: 'scheduleType',
			logic: 'or',
			filters: [
				{
					text: 'Active',
					field: 'scheduleType',
					value: 'Active',
					operator: FilterOperator.EqualTo,
				},
			],
		},
	],
	improved: [
		{
			text: 'prevVariance',
			logic: 'or',
			filters: [
				{
					text: 'Improved Last Period (prev var > 0)',
					field: 'prevVariance',
					value: 0,
					operator: FilterOperator.GreaterThan,
				},
			],
		},
		{
			text: 'scheduleType',
			logic: 'or',
			filters: [
				{
					text: 'Active',
					field: 'scheduleType',
					value: 'Active',
					operator: FilterOperator.EqualTo,
				},
			],
		},
	],
	missingUpdate: [
		{
			text: 'update-status',
			logic: 'and',
			filters: [
				{
					field: 'dUpload',
					value: 45,
					operator: FilterOperator.GreaterThanOrEqual,
				},
			],
		},
		{
			text: 'scheduleType',
			logic: 'and',
			filters: [
				{
					text: 'Active',
					field: 'scheduleType',
					value: 'Active',
					operator: FilterOperator.EqualTo,
				},
			],
		},
	],
	onTrack: [
		{
			text: 'prevVariance',
			logic: 'or',
			filters: [
				{
					text: 'Progress On-Track (prev var = 0)',
					field: 'prevVariance',
					value: 0,
					operator: FilterOperator.EqualTo,
				},
			],
		},
		{
			text: 'scheduleType',
			logic: 'or',
			filters: [
				{
					text: 'Active',
					field: 'scheduleType',
					value: 'Active',
					operator: FilterOperator.EqualTo,
				},
			],
		},
	],
	slipped: [
		{
			text: 'prevVariance',
			logic: 'or',
			filters: [
				{
					text: 'Slipped Last Period (prev var < 0)',
					field: 'prevVariance',
					value: 0,
					operator: FilterOperator.LessThan,
				},
			],
		},
		{
			text: 'scheduleType',
			logic: 'or',
			filters: [
				{
					text: 'Active',
					field: 'scheduleType',
					value: 'Active',
					operator: FilterOperator.EqualTo,
				},
			],
		},
	],
	costActive: [
		{
			text: 'cost-loaded',
			logic: 'or',
			filters: [
				{
					text: 'cost-loaded',
					field: 'costLoaded',
					value: true,
					operator: FilterOperator.EqualTo,
				},
			],
		},
		{
			text: 'scheduleType',
			logic: 'and',
			filters: [
				{
					text: 'Active',
					field: 'scheduleType',
					value: 'Active',
					operator: FilterOperator.EqualTo,
				},
			],
		},
	],
};

export const PROJECT_TYPES = [
	'Airport',
	'Correctional Facility',
	'Data Center',
	'Educational Facility',
	'Government',
	'Healthcare',
	'Infrastructure',
	'Office/Retail',
	'Other',
	'Pharmaceutical',
	'Renovation',
	'Residential',
	'Sports & Entertainment',
	'Wastewater Treatment',
] as const;

export const PROJECT_REGIONS = [
	'Ireland',
	'Mid-Atlantic',
	'Northeast',
	'Risk Management',
	'Southeast',
	'Southwest',
	'West Coast',
] as const;

export interface IButton {
	text: string;
	value: number;
	selected?: boolean;
	disabled?: boolean;
}

export interface CurrentUpdateGanttPreset {
	grouping: GanttGroupingPreset;
	showingRelationships: boolean;
	showingCritical: boolean;
	filters: string;
	visibleColumns: string[];
	name: string;
	isFavorite: boolean;
	id: string;
}

export interface GanttGroupingPreset {
	type: 'wbs' | 'actvCodes';
	wbsToLevel: number;
	actvCodeGrouping: GanttActvType[];
	hideSummaryBars: boolean;
	colors: string;
}

export interface CurrentUpdateGanttPresetInputs {
	preset: CurrentUpdateGanttPreset;
	currentFilters: CompositeFilterDescriptor;
	groups: XerActivityType[];
	activityMap: Map<number, Array<XerActivityCode>>;
	isNew: boolean;
	isBasedOnOtherPreset: boolean;
}
