import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

import { MailConfirmComponent } from 'components/authentication/mail-confirm/mail-confirm.component';
import { CommonModule } from '@angular/common';

@NgModule({
	declarations: [MailConfirmComponent],
	imports: [CommonModule, MatIconModule, RouterModule],
})
export class MailConfirmModule {}
