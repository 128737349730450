import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';

@NgModule({
	imports: [CommonModule],
	declarations: [],
	exports: [
		CommonModule,
		MatDividerModule,
		MatSortModule,
		MatIconModule,
		MatRippleModule,
		MatToolbarModule,
		MatExpansionModule,
		MatStepperModule,
	],
})
export class SharedMatModule {}
