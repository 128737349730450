import { Component, OnInit } from '@angular/core';
import { GanttService } from '../../../../../../services/project/gantt.service';
import { Wbs } from '../../../../../../models/Update/Task';
import { Observable, of } from 'rxjs';

@Component({
	selector: 'app-wbs-structure',
	templateUrl: './wbs-structure.component.html',
	styleUrls: ['./wbs-structure.component.scss'],
})
export class WbsStructureComponent implements OnInit {
	wbsKeys: string[] = [];

	constructor(public ganttService: GanttService) {}

	ngOnInit() {}

	public fetchChildren(node: Wbs): Observable<Wbs[]> {
		//Return the items collection of the parent node as children.
		return of(node.children);
	}

	public hasChildren(node: Wbs): boolean {
		//Check if the parent node has children.
		return node.children && node.children.length > 0;
	}

	public isExpanded = (dataItem: Wbs, index: string) => {
		return dataItem?.level === 1 || this.wbsKeys.indexOf(index) > -1;
	};

	/**
	 * A `collapse` event handler that will remove the node hierarchical index
	 * from the collection, collapsing its children.
	 */
	public handleCollapse(node) {
		this.wbsKeys = this.wbsKeys.filter((k) => k !== node.index);
	}

	/**
	 * An `expand` event handler that will add the node hierarchical index
	 * to the collection, expanding its children.
	 */
	public handleExpand(node) {
		this.wbsKeys = this.wbsKeys.concat(node.index);
	}
}
