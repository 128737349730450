<div class="datagrid-container">
  <table mat-table [dataSource]="datagrid" class="table">
    <ng-container matColumnDef="field">
      <td mat-cell *matCellDef="let element">{{element.field}}:</td>
    </ng-container>
    <ng-container matColumnDef="value">
      <td mat-cell *matCellDef="let element">
        <span *ngIf="(element.value | typeof) === 'object'">
          {{element.value | date : 'MM/dd/yyyy'}}
        </span>
        <span *ngIf="(element.value | typeof) === 'number'">
          {{element.value | number : '1.0-2'}}%
        </span>
		  <span *ngIf="(element.value | typeof) === 'string'">
          {{element.value}}
        </span>
      </td>
    </ng-container>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
<div>
	<ion-row class='delta-row'>
		<ion-col size='6' class='vertical-align-children'>
			<p class='p'>Delta:</p>
		</ion-col>
		<ion-col size='6' class='vertical-align-children'>
			<p class='p' style='padding-left: 35px'>{{(p85AndCcdDelta || 0) * -1}} CDs</p>
		</ion-col>
	</ion-row>
</div>
<div class='date-card-container'>
	<ion-row class='date-card-row'>
		<ion-col size='4' class='date-card-col'>
			<ion-card style="border-width: 0;">
				<ion-card-content class='date-card'>
					<div>
						{{earliestDate | date : 'MM/dd/YY'}}
					</div>
					<div>
						Earliest
					</div>
				</ion-card-content>
			</ion-card>
		</ion-col>
		<ion-col size='4' class='date-card-col'>
			<ion-card style="border-width: 0;">
				<ion-card-content class='date-card'>
					<div>
						{{averageDate | date: 'MM/dd/YY'}}
					</div>
					<div>
						Average
					</div>
				</ion-card-content>
			</ion-card>
		</ion-col>
		<ion-col size='4' class='date-card-col'>
			<ion-card style="border-width: 0;">
				<ion-card-content class='date-card'>
					<div>
						{{latestDate | date : 'MM/dd/YY'}}
					</div>
					<div>
						Latest
					</div>
				</ion-card-content>
			</ion-card>
		</ion-col>
	</ion-row>
</div>
