import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { DialogUnsavedScheduleChangesComponent, ScheduleUpdatesListComponent } from './schedule-updates-list.component';
import { ScheduleStorageService } from '../../../services/project/schedule-storage.service';
import { AnalyticsDashboardService } from '../../../services/analytics/analytics.service';

@Injectable({ providedIn: 'root' })
export class UnsavedChangeGuard {
	constructor(
		private scheduleService: ScheduleStorageService,
		private analyticsService: AnalyticsDashboardService
	) {}

	canDeactivate(
		component: ScheduleUpdatesListComponent,
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | boolean {
		if (this.scheduleService.unsavedChanges.size && !this.scheduleService.$projectData.value?.updateLock) {
			this.analyticsService.navOutsideProj = true;
			this.analyticsService.unsavedScheduleChangesOpen.next(true);
			return false;
		} else {
			return true;
		}
	}
}
