<div class="component-container" [style.height]="!fromPerformanceTrendingComponent ? '430.5px' : '400px'">
	<kendo-dropdownlist
		style="margin-bottom: 5px"
		[data]="activityCodeDropdownOptions"
		[textField]="'activityCode'"
		[valueField]="'activityCodeId'"
		(valueChange)="updateSelectionTable($event)"
		[value]="savedSelectedActivityCodeType"
		[defaultItem]="defaultDropdownObject"
	></kendo-dropdownlist>
  <div class="table-container">
		<kendo-grid
			[sortable]="true"
			[kendoGridBinding]="tablesCurrentCodes"
			id='pfTable'
			[rowClass]="rowCallback"
			(selectionChange)='onSelect($event)'
			[selectable]="selectableSettings"
			kendoGridSelectBy="activityCodeId"
			[(selectedKeys)]='selectedItems'
			[loading]='scheduleService.isLoading'
			[autoSize]="true"
		>
			<ng-template kendoGridNoRecordsTemplate>
				<div *ngIf='!scheduleService.isLoading'>
					No SubCodes for selected activity code
				</div>
			</ng-template>
			<kendo-grid-checkbox-column [width]="20" [showSelectAll]="true">
			</kendo-grid-checkbox-column>
			<kendo-grid-column
				field="activityCode"
				title="Code"
				[width]="95"
			>
				<ng-template kendoGridCellTemplate let-dataItem="dataItem">
					<span style="display: flex; flex-direction: column; text-align: center">{{dataItem['activityCode']}}</span>
				</ng-template>
			</kendo-grid-column>
			<kendo-grid-column field="description" title='Description' [autoSize]="true">
				<ng-template kendoGridCellTemplate let-dataItem="dataItem">
					<span style="display: flex; flex-direction: column; text-align: center">{{dataItem['description']}}</span>
				</ng-template>
			</kendo-grid-column>
			<kendo-grid-column field="pf" title='Performance' [width]="100">
				<ng-template kendoGridCellTemplate let-dataItem="dataItem">
					<span style="display: flex; flex-direction: column; text-align: center">{{ (dataItem.pf * 100) | number: '1.0-0' }}%</span>
        </ng-template>
			</kendo-grid-column>
		</kendo-grid>
  </div>

  <div style="text-align: center; padding-top: 4px;">
    {{selectedActivityCodes.length}} {{tablesCurrentCodes?.length ? '/ ' + tablesCurrentCodes.length: ''}} Activity Codes Selected
  </div>


</div>
