import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ProjectDashboardService } from '../../../../services/project/project.service';
import { NavigationBarStorageService } from '../../../../services/common/navigation-bar-storage.service';
import { ProfileCompanyPermission } from '../../../../models/auth/account-user';
import { CostService } from '../../../../services/common/cost.service';
import { arrowRightIcon, menuIcon, searchIcon, xCircleIcon, xIcon } from '@progress/kendo-svg-icons';
import { hasObjChanged } from '../../../../util/projects';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';

export interface OverviewComponent {
	disabled: boolean;
	id: number;
	licenseExclusions?: Array<string>;
	name: string;
	rmtRequirement?: Array<string>;
	tabOrigin: string;
	componentId: string;
	overviewOption: boolean;
	sequenceWithinTab: number;
}

export const allComponents = require('./overview-tab-components.json') as {
	components: OverviewComponent[];
};

@Component({
	selector: 'app-overview-tab-settings',
	templateUrl: './overview-tab-settings.component.html',
	styleUrls: ['./overview-tab-settings.component.scss'],
})
export class OverviewTabSettingsComponent implements OnInit, OnDestroy {
	@Output() canSave: EventEmitter<boolean> = new EventEmitter<boolean>(false);
	allComponentOptions: OverviewComponent[] = [];
	availableComponentOptions: OverviewComponent[] = [];
	selectedComponentOptions: OverviewComponent[] = [];
	currentProjectCompanyPermissions: ProfileCompanyPermission = null;
	isValidCostProject: boolean = false;
	radioSelection: 'default' | 'custom' = 'default';
	icons = {
		close: xCircleIcon,
		move: menuIcon,
		arrowRight: arrowRightIcon,
		x: xIcon,
		search: searchIcon,
	};
	lastSavedSelected: OverviewComponent[] = [];
	lastSavedRadio: 'default' | 'custom' = 'default';
	searchTerm: string = '';
	subs = new Subscription();
	constructor(
		public projectService: ProjectDashboardService,
		public navBarStorage: NavigationBarStorageService,
		public costService: CostService,
		private dragulaService: DragulaService
	) {
		this.subs.add(
			this.dragulaService.drop().subscribe(() => {
				this.updateCanSave();
			})
		);
		// restricts dragging handle to components with class dragHandle
		/*dragulaService.createGroup('SELECTEDCOMPONENTS', {
			moves: (el, container, handle) => {
				return handle?.parentElement?.parentElement?.classList.contains('dragHandle');
			},
		});*/
	}

	ngOnInit() {
		this.projectService.$currentProjectData.subscribe((data) => {
			this.currentProjectCompanyPermissions =
				data === undefined ? null : this.navBarStorage.companyPermissionMap.get(data?.company);
			this.initComponentOptions();
		});
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
		// this.dragulaService.destroy('SELECTEDCOMPONENTS');
	}

	initComponentOptions(): void {
		const rmt: string = this.projectService.$currentProjectReport.value?.project?.riskMetricsType;
		let allComponentsOptions: OverviewComponent[] = [];
		this.isValidCostProject = this.costService.isValidCostProject(
			this.projectService.$currentProjectReport.value?.cashFlowHistorical || null
		);
		allComponents.components.forEach((component: OverviewComponent) => {
			component.disabled = false;
			if (!component.disabled && component.tabOrigin === 'cost') {
				component.disabled = !this.isValidCostProject;
			}
			if (!component.disabled && component?.licenseExclusions) {
				component.disabled = component.licenseExclusions.includes(this.currentProjectCompanyPermissions?.license);
			}
			if (!component.disabled && component?.rmtRequirement) {
				component.disabled = !component.rmtRequirement.includes(rmt);
			}
			if (component.overviewOption) {
				allComponentsOptions.push(component);
			}
		});
		allComponentsOptions = allComponentsOptions.sort((a: OverviewComponent, b: OverviewComponent) => {
			if (a.id === 26 || a.id < b.id) {
				return -1;
			}
			if (b.id === 26 || b.id < a.id) {
				return 1;
			}
			return 0;
		});
		this.allComponentOptions = allComponentsOptions;
		const savedVals: OverviewComponent[] =
			this.projectService.$currentProjectData.value?.selectedOverviewTabComponents || [];
		this.availableComponentOptions = structuredClone(allComponentsOptions).filter(
			(a: OverviewComponent) => savedVals.findIndex((s: OverviewComponent) => s.id === a.id) === -1
		);
		this.selectedComponentOptions = structuredClone(savedVals);
		this.lastSavedSelected = structuredClone(savedVals);
		this.lastSavedRadio =
			this.projectService.$currentProjectData.value?.overviewTabDefault || savedVals?.length === 0
				? 'default'
				: 'custom';
		this.radioSelection = structuredClone(this.lastSavedRadio);
		this.projectService.overviewTabDefault = this.lastSavedRadio === 'default';
		this.updateCanSave();
	}

	radioChoiceChanged(ev, val): void {
		this.radioSelection = val;
		this.projectService.overviewTabDefault = val === 'default';
		this.updateCanSave();
	}

	moveItem(item: OverviewComponent): void {
		this.availableComponentOptions = this.availableComponentOptions.filter((o: OverviewComponent) => o.id !== item.id);
		this.selectedComponentOptions.push(item);
		this.updateCanSave();
	}

	deselectItem(item: OverviewComponent): void {
		this.selectedComponentOptions = this.selectedComponentOptions.filter((s: OverviewComponent) => s.id !== item.id);
		this.availableComponentOptions = structuredClone(this.allComponentOptions).filter(
			(c: OverviewComponent) => this.selectedComponentOptions.findIndex((s: OverviewComponent) => s.id === c.id) === -1
		);
		this.updateCanSave();
	}

	moveAll(): void {
		const copyOfSelected: OverviewComponent[] = structuredClone(this.selectedComponentOptions);
		const copyOfOptions: OverviewComponent[] = structuredClone(this.availableComponentOptions).filter(
			(c: OverviewComponent) => !c.disabled
		);
		this.selectedComponentOptions = [...copyOfSelected.values(), ...copyOfOptions.values()];
		this.availableComponentOptions = structuredClone(this.allComponentOptions).filter(
			(c: OverviewComponent) => c.disabled
		);
		this.updateCanSave();
	}

	removeAll(): void {
		this.selectedComponentOptions = [];
		this.availableComponentOptions = structuredClone(this.allComponentOptions);
		this.updateCanSave();
	}

	updateCanSave(): void {
		const lastSavedIds: number[] = [];
		const currentSelectedIds: number[] = [];
		this.lastSavedSelected.forEach((component: OverviewComponent) => {
			lastSavedIds.push(component.id);
		});
		this.selectedComponentOptions.forEach((component: OverviewComponent) => {
			currentSelectedIds.push(component.id);
		});
		this.projectService.selectedOverviewTabComponents = structuredClone(this.selectedComponentOptions);
		this.canSave.emit(
			(this.radioSelection !== this.lastSavedRadio &&
				(this.radioSelection === 'default' || this.selectedComponentOptions?.length > 0)) ||
				(this.radioSelection === this.lastSavedRadio &&
					this.radioSelection === 'custom' &&
					hasObjChanged(currentSelectedIds, lastSavedIds) &&
					this.selectedComponentOptions?.length > 0)
		);
	}

	search(term: string): void {
		this.availableComponentOptions =
			term === ''
				? this.allComponentOptions.filter(
						(c: OverviewComponent) => this.selectedComponentOptions.findIndex((s) => s.id === c.id) === -1
					)
				: this.allComponentOptions.filter(
						(c: OverviewComponent) =>
							c.name.toLowerCase().includes(term.toLowerCase()) &&
							this.selectedComponentOptions.findIndex((s: OverviewComponent) => s.id === c.id) === -1
					);
	}
}
