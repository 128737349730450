import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsDashboardService } from '../../../../services/analytics/analytics.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { differenceInDays, isValid } from 'date-fns';
import { airportIcon, findCenter, mapIcon } from '../../../../util/map';
import { ProjectInterface } from '../../../../models/Project';
import LatLngLiteral = google.maps.LatLngLiteral;

const months: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const clustering = require('density-clustering');

@Component({
	selector: 'app-map',
	templateUrl: './map.component.html',
})
export class MapComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
	@Input() $projects = new BehaviorSubject<Array<any>>([]);
	@Input() googleScriptLoaded = false;
	@Output() clickedOnMarker = new EventEmitter<boolean>();
	private _unsubscribeAll: Subject<void>;

	constructor(
		public router: Router,
		private route: ActivatedRoute,
		public _analyticsDashboardService: AnalyticsDashboardService
	) {
		this._unsubscribeAll = new Subject<void>();
	}

	@Input() projectList: Array<any>;
	@Input() zoomCoords = new BehaviorSubject<LatLngLiteral>({
		lat: 36.1167256667,
		lng: -94.0130476667,
	});
	@Input() selectedProject = new BehaviorSubject<ProjectInterface>(undefined);
	projectToMarker = new Map<string, google.maps.Marker>([]);
	infoWindow: google.maps.InfoWindow;

	mapOptions: google.maps.MapOptions = {
		minZoom: 1,
		maxZoom: 12,
		scrollwheel: false,
		mapTypeControl: false,
	};

	advancedMarkers: google.maps.Marker[] = []; //the AdvancedMarkerView is their beta version that doesnt have a setMap() function for removing markers and the marker.map = null stopped removing markers recently, so we go back to just Marker type
	zoom = 4;
	center: google.maps.LatLngLiteral = {
		lat: 36.1167256667,
		lng: -94.0130476667,
	};
	latTotal = 0;
	longTotal = 0;
	openedWindow: number = 0;
	map: google.maps.Map;

	ngOnInit(): void {}

	ngAfterViewInit(): void {
		if (this.googleScriptLoaded) {
			this.initMap(this);
		}
		this.selectedProject.subscribe((project) => {
			const marker = this.projectToMarker.get(project?._id);
			this.infoWindow?.close();
			if (marker !== undefined) {
				const position = marker.getPosition();
				if (
					position.lat() !== undefined &&
					position.lng() !== undefined &&
					!isNaN(position.lat()) &&
					!isNaN(position.lng())
				) {
					this.map?.setCenter(position);
					const x = marker.getPosition();
					this.zoomCoords.next({ lat: x.lat(), lng: x.lng() } as google.maps.LatLngLiteral);
					this.infoWindow = new google.maps.InfoWindow();
					this.infoWindow.setContent(marker.getTitle());
					this.infoWindow.open(marker.getMap(), marker as google.maps.MVCObject);
				}
			} else {
				this.zoomCoords.next(null);
				this.map?.setZoom(4);
			}
			this.adjustZoom();
		});
		this.$projects.subscribe((projects) => {
			if (!!projects) {
				this.adjustZoom();
			}
		});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	initMap(component: MapComponent) {
		const maxLat = (Math.atan(Math.sinh(Math.PI)) * 180) / Math.PI;
		this.map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
			center: {
				lat: 36.1167256667,
				lng: -94.0130476667,
			},
			zoom: 4,
			mapId: '4504f8b37365c3d0',
			minZoom: 1,
			maxZoom: 12,
			scrollwheel: false,
			mapTypeControl: false,
			streetViewControl: false,
			fullscreenControl: false,
			zoomControlOptions: { position: 1.0 },
			restriction: {
				latLngBounds: { north: maxLat, south: -maxLat, west: -180, east: 180 },
				strictBounds: true,
			},
		});
		//this.infoWindow = new google.maps.InfoWindow();
		component.$projects.pipe(takeUntil(this._unsubscribeAll)).subscribe((projects) => {
			if (projects) {
				console.log('initing markers', projects.length);

				this.initMarkers(projects);
			}
		});
	}

	isInfoWindowOpen(id) {
		return this.openedWindow === id;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.zoomCoords) {
			this.openedWindow = this.zoomCoords[2];
		}
	}

	adjustZoom() {
		this.latTotal = 0;
		this.longTotal = 0;
		let minLat = 999;
		let maxLat = -999;
		let minLong = 999;
		let maxLong = -999;
		const latLngs: Array<{ lat: number; lng: number }> = [];
		const DBSCANLatLngs: Array<[number, number]> = [];
		this.$projects.value?.forEach((project: any) => {
			if (!project || project.lat === undefined || project.lng === undefined) {
				return;
			}
			if (project.lat && project.lng) {
				const lat = +project.lat ?? 0;
				const lng = +project.lng ?? 0;
				this.latTotal += lat;
				this.longTotal += lng;
				latLngs.push({ lat, lng });
				DBSCANLatLngs.push([lat, lng]);
				DBSCANLatLngs.push([lat, lng + 360]);
				DBSCANLatLngs.push([lat, lng - 360]);
			}
		});
		const neighborhoodRadius = 5;
		const minClusterSize = 2;
		const dbscan = new clustering.DBSCAN();
		if (latLngs.length > 0) {
			const clusters = dbscan.run(DBSCANLatLngs, neighborhoodRadius, minClusterSize);
			let maxCluster = clusters[0] || [];
			for (const cluster of clusters) {
				if (cluster.length > maxCluster.length) {
					maxCluster = cluster;
				}
			}
			for (const point of maxCluster) {
				const lat = DBSCANLatLngs[point][0];
				const lng = DBSCANLatLngs[point][1];
				if (lat < minLat) {
					minLat = lat;
				}
				if (lat > maxLat) {
					maxLat = lat;
				}
				if (lng < minLong) {
					minLong = lng;
				}
				if (lng > maxLong) {
					maxLong = lng;
				}
			}
			this.center = findCenter(
				maxCluster.length > 0
					? maxCluster.map((point) => ({ lat: DBSCANLatLngs[point][0], lng: DBSCANLatLngs[point][1] }))
					: latLngs
			);
		} else {
			this.center = {
				lat: 0,
				lng: 0,
			};
		}

		if (Math.abs(maxLong - minLong) < 10) {
			this.zoom = 5;
		} else if (Math.abs(maxLong - minLong) < 25) {
			this.zoom = 4;
		} else if (Math.abs(maxLong - minLong) < 40) {
			this.zoom = 3;
		} else if (Math.abs(maxLong - minLong) < 55) {
			this.zoom = 2;
		} else {
			this.zoom = 2;
		}
		if (this.zoomCoords.value && this.zoomCoords.value.lat && this.zoomCoords.value.lng) {
			this.center = this.zoomCoords.value;
			this.zoom = 9;
		}
		this.map?.setZoom(this.zoom);
		if (this.center.lat && this.center.lng) {
			this.map?.setCenter(this.center);
		} else {
			this.map?.setZoom(4);
		}
	}

	initMarkers(projectList: Array<any>): void {
		this.advancedMarkers.forEach((marker) => {
			marker.setMap(null);
		});
		this.advancedMarkers = [];
		this.projectToMarker.clear();
		projectList?.forEach((project: any) => {
			if (
				!project?._id ||
				isNaN(project.lat) ||
				project.lat === undefined ||
				isNaN(project.lng) ||
				project.lng === undefined
			) {
				return;
			}
			const iconName = project.projectType?.toLowerCase().replace(' ', '');
			//const markerIconElement = document.createElement('div');
			//markerIconElement.innerHTML = airportIcon(this.getPinColor(project));
			//const div = document.createElement('div');
			//div.innerHTML = mapIcon(iconName, this.getPinColor(project), 35);
			const m = new google.maps.Marker({
				position: {
					lat: +project.lat,
					lng: +project.lng,
				},
				map: this.map,
				title: project.name,
			}); /*
		const m = new google.maps.marker.AdvancedMarkerView({
			content: div,
			position: {
				lat: +project.lat,
				lng: +project.lng,
			},
			map: this.map,
			title: project.name,
		});*/
			m.addListener('click', async () => {
				await this.navigateToSingle(project._id);
			});
			this.advancedMarkers.push(m);
			this.projectToMarker.set(project._id, m);
		});
		this.adjustZoom();
	}

	onMouseOver(gm): void {
		if (gm.lastOpen != null) {
			try {
				gm.lastOpen.close();
			} catch (e) {
				console.log(e);
			}
		}
	}

	async navigateToSingle(id: string) {
		this.clickedOnMarker.emit(true);
		await this.router.navigate([`/project/${id}`], { relativeTo: this.route });
	}

	/**
	 * determines pin color
	 * @param project
	 */
	getPinColor(project): string {
		const red = '#e26464';
		const blue = '#0059FF';
		const green = '#4fc931';
		const orange = '#ff8c00';
		const black = '#000000';

		const lastUpdated = new Date(project?.rawReport?.projectOverview?.dataDate);
		if (isValid(lastUpdated)) {
			const now = new Date();
			const dUpload = differenceInDays(now, lastUpdated);
			//missing update
			if (dUpload > 45) {
				return orange;
			}
		}
		const prevVariance = project.rawReport?.projectTable?.previousVariance;
		if (prevVariance !== undefined) {
			//slipped
			if (prevVariance < 0) {
				return red;
			}
			//on-track
			if (prevVariance === 0) {
				return green;
			}
			//improved
			if (prevVariance > 0) {
				return blue;
			}
		}
		//not enough data
		return black;
	}

	getRemainingDuration(incomingDataDate: string, incomingProjectedCompletion: string): number {
		const incomingProjectedCompletionCorrectFormat: string = this.convertToCorrectFormat(incomingProjectedCompletion);

		const noTime = incomingDataDate.split(' ')[0];
		const year = noTime.split('-')[0];
		const month = noTime.split('-')[1];
		const day = noTime.split('-')[2];
		const dataDate = new Date(+year, +month - 1, +day);

		const projectedCompletionDate: Date = this.convertCorrectFormatToDate(incomingProjectedCompletionCorrectFormat);

		return projectedCompletionDate.getTime() - dataDate.getTime();
	}

	getContractVariance(incomingProjectedCompletion: string, incomingContractCompletion: string): number {
		const incomingProjectedCompletionCorrectFormat: string = this.convertToCorrectFormat(incomingProjectedCompletion);
		const incomingContractCompletionCorrectFormat: string = this.convertToCorrectFormat(incomingContractCompletion);

		const projectedCompletionDate: Date = this.convertCorrectFormatToDate(incomingProjectedCompletionCorrectFormat);
		const contractCompletionDate: Date = this.convertCorrectFormatToDate(incomingContractCompletionCorrectFormat);

		return projectedCompletionDate.getTime() - contractCompletionDate.getTime();
	}

	convertCorrectFormatToDate = (s: string) => new Date(s);

	convertToCorrectFormat = (s: string) => {
		const y = s.split('-')[0];
		const m = +s.split('-')[1];
		const mName = months[m - 1];
		const d = s.split('-')[2];
		const dNoTime = d.split(' ')[0];
		return mName + ' ' + dNoTime + ', ' + y;
	};
}
