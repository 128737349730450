import { Component, OnInit } from '@angular/core';
import { GanttService } from '../../../../../../services/project/gantt.service';

@Component({
	selector: 'app-edit-group',
	templateUrl: './edit-group.component.html',
	styleUrls: ['./edit-group.component.scss'],
})
export class EditGroupComponent implements OnInit {
	constructor(public ganttService: GanttService) {}

	ngOnInit() {
		this.ganttService.newEditingGroup =
			this.ganttService.editingGroup === null
				? { name: '', id: crypto.randomUUID(), reportIds: [] }
				: structuredClone(this.ganttService.editingGroup);
		if (this.ganttService.duplicateGroup !== null) {
			this.ganttService.newEditingGroup = {
				name: '',
				id: crypto.randomUUID(),
				reportIds: this.ganttService.duplicateGroup.reportIds,
			};
		}
		this.ganttService.$checkedReportChange.subscribe((val: string[]) => {
			if (val !== null) {
				this.updateSelectedReports(val);
			}
		});
	}

	updateSelectedReports(reportIds: string[]): void {
		this.ganttService.newEditingGroup.reportIds = structuredClone(reportIds);
	}
}
