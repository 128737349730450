import { Component, Input, OnInit } from '@angular/core';
import { ProjectDashboardService } from '../../../services/project/project.service';
import { ScheduleStorageService } from '../../../services/project/schedule-storage.service';
import { UserService } from '../../../services/common/user.service';
import { RestService } from '../../../services/common/rest.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ProjectRedirectInterface } from '../../../models/ProjectReport/ProjectReport';
import { NotificationService } from '@progress/kendo-angular-notification';

@Component({
	selector: 'app-client-viewer-settings',
	templateUrl: './client-viewer-settings.component.html',
	styleUrls: ['./client-viewer-settings.component.scss'],
})
export class ClientViewerSettingsComponent implements OnInit {
	constructor(
		public projectService: ProjectDashboardService,
		public scheduleStorage: ScheduleStorageService,
		public userService: UserService,
		private rest: RestService,
		private fb: FormBuilder,
		private notificationService: NotificationService
	) {}

	@Input() redirectData: ProjectRedirectInterface = {
		projectId: '',
		_id: '',
		name: '',
		company: '',
		clientId: '',
		projectCode: '',
		isRedirect: true,
		projectType: '',
		siteAddressReference: '',
		lat: '',
		lng: '',
		pocId: '',
		imageBase64: '',
	} as unknown as ProjectRedirectInterface;

	ngOnInit(): void {
		this.redirectDetails = this.fb.group({
			_id: new FormControl(this.redirectData._id),
			projectCode: new FormControl(this.redirectData.projectCode),
			name: new FormControl(this.redirectData.name, Validators.required),
			projectImage: new FormControl(null),
			projectType: new FormControl(this.redirectData.projectType, Validators.required),
			siteAddressReference: new FormControl(this.redirectData.siteAddressReference, Validators.required),
			lat: new FormControl(this.redirectData.lat),
			lng: new FormControl(this.redirectData.lng),
			company: new FormControl(this.redirectData.company, Validators.required),
			pocId: new FormControl({ value: this.redirectData.pocId, disabled: true }, Validators.required),
			clientId: new FormControl({ value: this.redirectData.clientId, disabled: true }, Validators.required),
		});
		this.projectService.aegisPocsLoaded.subscribe((loaded) => {
			if (loaded) {
				this.redirectDetails = this.fb.group({
					_id: new FormControl(this.redirectData._id),
					projectCode: new FormControl(this.redirectData.projectCode),
					name: new FormControl(this.redirectData.name, Validators.required),
					projectImage: new FormControl(null),
					projectType: new FormControl(this.redirectData.projectType, Validators.required),
					siteAddressReference: new FormControl(this.redirectData.siteAddressReference, Validators.required),
					lat: new FormControl(this.redirectData.lat),
					lng: new FormControl(this.redirectData.lng),
					company: new FormControl(this.redirectData.company, Validators.required),
					pocId: new FormControl({ value: this.redirectData.pocId, disabled: true }, Validators.required),
					clientId: new FormControl({ value: this.redirectData.clientId, disabled: true }, Validators.required),
				});
			}
		});
	}

	public redirectDetails = null;

	public scheduleTypeItemDescriptors = {
		Active: 'Auto-assigned during first update upload. Enables missing update tracking (45+ days since last update)',
		Closed: 'Project is closed, but still accessible via Project List',
		Baseline: 'Auto-assigned when creating new Project',
		Consulting: 'Irregular update cycle',
	};

	focus(viewChild) {
		this[viewChild].toggle(true);
	}

	user: any = {
		userType: 'aegis',
	};

	public projectKeys: Array<string> = [];

	availableProjectTypes = [
		'Airport',
		'Correctional Facility',
		'Data Center',
		'Educational Facility',
		'Government',
		'Healthcare',
		'Infrastructure',
		'Office/Retail',
		'Other',
		'Pharmaceutical',
		'Renovation',
		'Residential',
		'Sports & Entertainment',
		'Wastewater Treatment',
	];
	doingPatchClientData: boolean = false;

	patchClientData() {
		this.doingPatchClientData = true;
		this.rest.post('pr/update', this.redirectDetails.value).subscribe((res) => {
			this.doingPatchClientData = false;
			this.notificationService.show({
				content: 'Updated Project Data Successfully!',
			});
		});
	}
}
