import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
	emptyRegister,
	ImportHeaderMapping,
	importHeaderMappings,
	importP6ScoreMapping,
	OverwriteResults,
	RiskRegister,
} from '../../../../../models/risk';
import { riskFormPresets } from '../risk-register-form/risk-register-form.component';
import { AppWindowService } from '../../../../../services/common/window.service';

@Component({
	selector: 'app-import',
	templateUrl: './import.component.html',
	styleUrls: ['./import.component.scss'],
})
export class ImportComponent implements OnInit {
	@Input() currentRegisters = [];
	@Output() saveRegisters = new EventEmitter<RiskRegister[]>();
	public importedFiles: Array<File> = [];
	newRegisters = [];
	newRegistersWithInvalidFields = [];
	newValidRegisters = [];
	requiredHeadersMissing: ImportHeaderMapping[] = [];
	submitClicked = false;
	idOverwriteDialogOpen = false;
	conflictingRisks: { analyticsRisk: RiskRegister; importedRisk: RiskRegister }[] = [];
	isP6Export = false;

	constructor(public appWindowService: AppWindowService) {}

	ngOnInit() {}

	/**
	 * handles file selection change
	 * @param event
	 */
	handleUploadChange(event) {
		if (event.target.files.length > 0) {
			const file = event.target.files[0];
			const reader: FileReader = new FileReader();
			reader.readAsText(file);
			reader.onload = () => {
				const readerResult: string = reader.result as string;
				this.parseCsvResults(readerResult);
				this.submitClicked = false;
			};
		} else {
			this.newValidRegisters = [];
			this.newRegistersWithInvalidFields = [];
			this.newRegisters = [];
			this.requiredHeadersMissing = [];
			this.updateImportWindowHeight();
		}
	}

	/**
	 * parses csv file and transforms the data into risk registers if there is adequate data
	 * @param readerResult
	 */
	parseCsvResults(readerResult: string) {
		const csvAsObjectByRow = readerResult.split('\r\n');
		const newRegistersAfterParsing = [];
		const newRegistersWithInvalidFieldsAfterParsing = [];
		const newValidRegistersAfterParsing = [];
		const newConflictingRisks = [];
		const headerMappings = structuredClone(importHeaderMappings);
		const headerMappingsMissing = [];
		const requiredHeadersMissingAfterParsing = [];
		const headersSeparated = csvAsObjectByRow[0].split(',');
		this.isP6Export = headersSeparated.includes('Risk ID');
		const responsibilityHeader = headerMappings.find((mapping) => mapping.analyticsField === 'responsibility');
		if (responsibilityHeader) {
			responsibilityHeader.required = !this.isP6Export;
		}
		headersSeparated.forEach((header, i) => {
			const mappingIndex = headerMappings.findIndex((headerMap) =>
				this.isP6Export
					? header === headerMap.excelField && headerMap.excelField !== null
					: header === headerMap.reactiveRiskField
			);
			if (mappingIndex !== -1) {
				headerMappings[mappingIndex].headerIndex = i;
			}
		});
		headerMappings.forEach((header) => {
			if (header.headerIndex === null) {
				headerMappingsMissing.push(header);
				if (header.required) {
					requiredHeadersMissingAfterParsing.push(header);
				}
			}
		});
		for (let i = 1; i < csvAsObjectByRow.length; i++) {
			const currentRegister: RiskRegister = emptyRegister();
			currentRegister.preMitigation.performanceImpact = 0;
			currentRegister.preMitigation.qualityImpact = 0;
			currentRegister.postMitigation.performanceImpact = 0;
			currentRegister.postMitigation.qualityImpact = 0;
			currentRegister.isDraft = true;
			//replace \" with ** for easier string matching.
			const replaced = csvAsObjectByRow[i].replace(/\"/g, '**').split(',');
			//correct for false-flagged " from ^
			const filteredDoubleQuotes = this.replace(replaced);
			//now we can combine same cell but spread over many array entries into 1
			const dataRowSeparated = this.combineCellsThatHadCommas(filteredDoubleQuotes);
			//data should be good to go now. we can resume parsing/transforming data
			dataRowSeparated.forEach((item, j) => {
				const matchingHeader = headerMappings.find((header) => header.headerIndex === j);
				if (matchingHeader !== undefined) {
					// eslint-disable-next-line @typescript-eslint/no-unused-expressions
					matchingHeader.analyticsSubfield
						? (currentRegister[matchingHeader.analyticsField][matchingHeader.analyticsSubfield] = item)
						: (currentRegister[matchingHeader.analyticsField] = item);
					if (matchingHeader.needsScoreConversion && this.isP6Export) {
						currentRegister[matchingHeader.analyticsField][matchingHeader.analyticsSubfield] =
							this.convertP6ScoreToAnalyticsScore(
								currentRegister[matchingHeader.analyticsField][matchingHeader.analyticsSubfield]
							);
					}
					if (matchingHeader.needsCurrencyMarkRemoved) {
						currentRegister[matchingHeader.analyticsField][matchingHeader.analyticsSubfield] = this.removeCurrencyMarks(
							currentRegister[matchingHeader.analyticsField][matchingHeader.analyticsSubfield]
						);
					}
					if (matchingHeader.fieldOptions) {
						// eslint-disable-next-line @typescript-eslint/no-unused-expressions
						matchingHeader.analyticsSubfield
							? (currentRegister[matchingHeader.analyticsField][matchingHeader.analyticsSubfield] = this.validateField(
									currentRegister[matchingHeader.analyticsField][matchingHeader.analyticsSubfield],
									matchingHeader.fieldOptions
								))
							: (currentRegister[matchingHeader.analyticsField] = this.validateField(
									currentRegister[matchingHeader.analyticsField],
									matchingHeader.fieldOptions
								));
					}
				}
			});

			//after getting values
			currentRegister.postMitigation.probability = currentRegister.preMitigation.probability;
			currentRegister.responsibility.name = currentRegister.riskOwner.name;
			//also make sure riskScores are good
			newRegistersAfterParsing.push(currentRegister);
		}
		newRegistersAfterParsing.forEach((register, i) => {
			const conflictingAnalyticsRisk = this.doesThisRiskConflict(register);
			if (conflictingAnalyticsRisk !== undefined) {
				newConflictingRisks.push({ analyticsRisk: conflictingAnalyticsRisk, importedRisk: register });
			}
			const invalidFields = [];
			let isRegisterValid = true;
			headerMappings.forEach((mapping) => {
				if (mapping.required) {
					const isFieldValid = mapping.analyticsSubfield
						? register[mapping.analyticsField][mapping.analyticsSubfield] !== null
						: register[mapping.analyticsField] !== null;
					if (!isFieldValid) {
						const invalidField: any = structuredClone(mapping);
						invalidField.currentValue = mapping.analyticsSubfield
							? register[mapping.analyticsField][mapping.analyticsSubfield]
							: register[mapping.analyticsField];
						invalidFields.push(invalidField);
						isRegisterValid = false;
					}
				}
				if (mapping?.isDate === true) {
					const field = mapping.analyticsSubfield
						? register[mapping.analyticsField][mapping.analyticsSubfield]
						: register[mapping.analyticsField];
					if (new Date(field).toString() === 'Invalid Date') {
						const invalidField: any = structuredClone(mapping);
						invalidField.currentValue = mapping.analyticsSubfield
							? register[mapping.analyticsField][mapping.analyticsSubfield]
							: register[mapping.analyticsField];
						invalidFields.push(invalidField);
						isRegisterValid = false;
						if (mapping.analyticsSubfield) {
							register[mapping.analyticsField][mapping.analyticsSubfield] = undefined;
						} else {
							register[mapping.analyticsField] = undefined;
						}
					}
				}
			});
			if (isRegisterValid) {
				newValidRegistersAfterParsing.push(register);
			} else {
				newRegistersWithInvalidFieldsAfterParsing.push({ register, fields: invalidFields });
			}
		});
		this.newRegisters = newRegistersAfterParsing;
		this.newValidRegisters = newValidRegistersAfterParsing;
		this.newRegistersWithInvalidFields = newRegistersWithInvalidFieldsAfterParsing;
		this.requiredHeadersMissing = requiredHeadersMissingAfterParsing;
		this.conflictingRisks = newConflictingRisks;
		if (newConflictingRisks.length > 0) {
			this.idOverwriteDialogOpen = true;
		} else {
			let nextId = 0;
			this.currentRegisters.forEach((register) => {
				if (register.riskId >= nextId) {
					nextId = register.riskId + 1;
				}
			});
			let i = 0;
			this.newRegisters.forEach((reg) => {
				if (typeof reg.riskId === 'string') {
					reg.riskId = nextId + i;
					i++;
				}
			});
		}
		this.updateImportWindowHeight();
	}

	/**
	 * manually update import window height to look correct and make bounds restrict movement work as intended
	 */
	updateImportWindowHeight(): void {
		setTimeout(() => {
			const importContainer = document.getElementsByClassName('import-dialog-content-container')[0];
			const importWindow = document.getElementById('riskImportWindow');
			if (importContainer && importWindow) {
				const newHeight = importContainer.getBoundingClientRect().height + 85;
				importWindow.style.height = newHeight + 'px';
				this.appWindowService.windowPosition.riskImport.height = newHeight;
			}
		});
	}

	/**
	 * fields that are dropdowns on forms (i.e. have only a few specific options for the user to select) should only be
	 * one of those values or null
	 */
	validateField(currentValue, options): string | number | null {
		const returnVal = null;
		const matchingValue = options.find((val: string | number) =>
			typeof val === 'string' ? val.toLowerCase() === currentValue.toLowerCase() : Number(val) === Number(currentValue)
		);
		if (matchingValue !== undefined) {
			return matchingValue;
		}
		return returnVal;
	}

	/**
	 * all instances of " were replaced with ** for easier string matching later. this is mainly for when there are
	 * commas because a /" will sandwich the same-cell values after a split by commas. This will also replace regular
	 * " with some **. This function corrects that while maintaining the ** markings at the start/end of same-cell but
	 * different indexed values.
	 * @param stringArr
	 */
	replace(stringArr): string[] {
		const newArr = [];
		stringArr.forEach((entry) => {
			let newEntry = entry.replaceAll('******', '**');
			newEntry = newEntry.replaceAll('****', '');
			newArr.push(newEntry);
		});
		return newArr;
	}

	/**
	 * some fields such as notes and description could have commas in them after being exported or the user could have
	 * entered some in - all of which disrupt the header to data-cell mapping. this function corrects the splitting that
	 * separated same cell values into multiple entries
	 */
	combineCellsThatHadCommas(splitStringWithCommas): string[] {
		const returnString = [];
		let modifier = 0;
		let startIndex = 0;
		let isInCommaString = false;
		for (let i = 0; i < splitStringWithCommas.length; i++) {
			if (!isInCommaString) {
				if (splitStringWithCommas[i].charAt(0) !== '*' && splitStringWithCommas[i].substring(0, 2) !== '**') {
					returnString.push(splitStringWithCommas[i]);
				} else {
					if (
						splitStringWithCommas[i].substring(splitStringWithCommas[i].length - 2, splitStringWithCommas[i].length) ===
						'**'
					) {
						returnString.push(splitStringWithCommas[i].replaceAll('**', ''));
					} else {
						isInCommaString = true;
						startIndex = i;
					}
				}
			} else {
				modifier++;
				if (splitStringWithCommas[i].charAt(splitStringWithCommas[i].length - 1) === '*') {
					let newString = '';
					for (let j = startIndex; j < startIndex + modifier + 1; j++) {
						newString += splitStringWithCommas[j];
					}
					returnString.push(newString.substring(2, newString.length - 2));
					isInCommaString = false;
				}
			}
		}
		return returnString;
	}

	/**
	 * remove $ if there was a value TODO: also parse out other currency marks if they appear on the p6 exported file from other regions
	 * @param excelInputMoneyString
	 */
	removeCurrencyMarks(excelInputMoneyString): number {
		return +excelInputMoneyString.toString().split('$')[0];
	}

	/**
	 * p6 scores come in with 3 parts: a p6 score, a description, then another (description). this function parses out
	 * the pure number and if it was a p6 generated score, converts it to an analytics score
	 * @param excelInputScore
	 */
	convertP6ScoreToAnalyticsScore(excelInputScore): number {
		if (excelInputScore === null) {
			return null;
		} else {
			const scoreMapping = importP6ScoreMapping;
			const splitString = excelInputScore.toString().split('');
			let returnScore = null;
			if (!Number.isNaN(Number(splitString[0]))) {
				if (splitString.length === 1) {
					// eslint-disable-next-line @typescript-eslint/no-unused-expressions
					Number(splitString[0]) >= 0 && Number(splitString[0]) <= 5
						? (returnScore = Number(splitString[0]))
						: (returnScore = null);
				} else {
					const matchingAnalyticsScore = scoreMapping.find(
						(score) => score.p6Score === Number(splitString[0])
					)?.analyticsScore;
					// eslint-disable-next-line @typescript-eslint/no-unused-expressions
					matchingAnalyticsScore === undefined ? (returnScore = null) : (returnScore = matchingAnalyticsScore);
				}
			}
			return returnScore;
		}
	}

	/**
	 * add all risks to project record
	 */
	submitRegisters(): void {
		this.submitClicked = true;
		this.saveRegisters.emit(this.newRegisters);
	}

	/**
	 * determines if there is a riskId conflict between an imported risk and the
	 * existing risks in this project's register
	 * @param risk
	 */
	doesThisRiskConflict(risk: RiskRegister): RiskRegister | undefined {
		const riskId: string = risk.riskId.toString();
		const numbersInId: number[] = (riskId.match(/\d+\.\d+|\d+\b|\d+(?=\w)/g) || []).map((v) => +v);
		return numbersInId.length > 0 ? this.currentRegisters.find((reg) => reg.riskId === numbersInId[0]) : undefined;
	}

	/**
	 * update riskIds and replace overwritten risks
	 * @param decisions
	 */
	confirmOverwrites(decisions: OverwriteResults[]): void {
		this.idOverwriteDialogOpen = false;
		let nextId = 0;
		this.currentRegisters.forEach((register) => {
			if (register.riskId >= nextId) {
				nextId = register.riskId + 1;
			}
		});
		decisions.forEach((choice) => {
			if (choice.decision !== 'saveAsNew') {
				const matchingIndex = this.newRegisters.findIndex((reg) => reg.riskId === choice.importedRiskId);
				if (matchingIndex !== -1) {
					switch (choice.decision) {
						case 'overwrite': {
							this.newRegisters[matchingIndex].riskId = choice.matchingAnalyticsRiskId;
							break;
						}
						case 'ignoreDuplicate': {
							this.newRegisters = this.newRegisters.filter((reg) => reg.riskId !== choice.importedRiskId);
							break;
						}
					}
				}
			}
		});
		let i = 0;
		this.newRegisters.forEach((reg) => {
			if (typeof reg.riskId === 'string') {
				reg.riskId = nextId + i;
				i++;
			}
		});
	}
}
