<div>
	<fieldset style="margin-right: 0;display: flex; gap: 10px">
		<legend>Exclude Activity Codes</legend>
		<kendo-multiselecttree
			kendoMultiSelectTreeSummaryTag
			kendoMultiSelectTreeExpandable
			[kendoMultiSelectTreeHierarchyBinding]="allActivityCodes"
			[(value)]="selectedActivityCodes"
			textField="name"
			valueField="id"
			childrenField='subCodes'
			[valuePrimitive]='false'
			[filterable]='true'
			(valueChange)='filterChanged($event);'
			[clearButton]='false'
			[expandOnFilter]="{expandMatches: true}"
			[itemDisabled]="itemDisabled"
			[placeholder]='"Activity Code Filter"'
			style='width: 300px; margin: auto 0; cursor: pointer;'
			class="focusActvFilter"
		>
			<ng-template kendoMultiSelectTreeGroupTagTemplate let-dataItems>
				<span>
					<kendo-svg-icon [icon]='caretDown'></kendo-svg-icon>
					{{ codesTag }}
				</span>
			</ng-template>
		</kendo-multiselecttree>

			<ul class="k-checkbox-list" style="display: flex; flex-direction: row; gap: 10px;">
				<form [formGroup]='qualityFormGroup' style="display: flex; gap: 10px">
					<span style="margin: auto 0;">Exclude From:</span>
					<kendo-formfield showHints="initial" [orientation]="'horizontal'" style="margin-top: 1px;">
						<li class="k-radio-item">
							<input
								type="checkbox"
								#excludeD
								[value]="false"
								kendoCheckBox
								formControlName="excludeForDuration"
							/>
							<kendo-label
								class="k-radio-label"
								[for]="excludeD"
								text="High Duration"
							></kendo-label>
						</li>
					</kendo-formfield>

					<kendo-formfield showHints="initial" [orientation]="'horizontal'" style="margin-top: 1px;">
						<li class="k-radio-item">
							<input
								type="checkbox"
								#excludeF
								[value]="true"
								kendoCheckBox
								formControlName="excludeForFloat"
							/>
							<kendo-label
								class="k-radio-label"
								[for]="excludeF"
								text="High Float"
							></kendo-label>
						</li>

					</kendo-formfield>
				</form>
			</ul>
	</fieldset>
	<div style="display: flex; justify-content: space-between; gap: 5px; margin-bottom: 23px">
		<fieldset style="margin-right: 0; flex: 1;">
			<legend>Quality Calculation Method</legend>
			<form [formGroup]="qualityFormGroup">
				<kendo-formfield showHints="initial" [orientation]="'horizontal'">
					<ul class="k-radio-list" style="display: flex; flex-direction: row; gap: 10px;">
						<li class="k-radio-item">
							<input
								type="radio"
								#useDcmaF
								[value]="false"
								kendoRadioButton
								formControlName="useDCMA"
							/>
							<kendo-label
								class="k-radio-label"
								[for]="useDcmaF"
								text="Default"
							></kendo-label>
						</li>
						<li class="k-radio-item">
							<input
								type="radio"
								#useDcmaT
								[value]="true"
								kendoRadioButton
								formControlName="useDCMA"
							/>
							<kendo-label
								class="k-radio-label"
								[for]="useDcmaT"
								text="DCMA"
							></kendo-label>
						</li>
					</ul>
					<kendo-formerror [align]="'end'">Error: This field is required</kendo-formerror>
				</kendo-formfield>
			</form>
		</fieldset>
		<fieldset style="margin-right: 0; flex: 1;" [attr.disabled]="!qualityFormGroup.get('excludeForDuration')?.value ? true : null">
			<legend>High Duration Method</legend>
			<form [formGroup]="qualityFormGroup">
				<kendo-formfield showHints="initial" [orientation]="'horizontal'">
					<ul class="k-radio-list" style="display: flex; flex-direction: row; gap: 10px;">
						<li class="k-radio-item">
							<input
								type="radio"
								#highDurRD
								[value]="true"
								kendoRadioButton
								formControlName="highDurationUseRD"
							/>
							<kendo-label
								class="k-radio-label"
								[for]="highDurRD"
								text="Remaining Duration"
							></kendo-label>
						</li>
						<li class="k-radio-item">
							<input
								type="radio"
								#highDurOD
								[value]="false"
								kendoRadioButton
								formControlName="highDurationUseRD"
							/>
							<kendo-label
								class="k-radio-label"
								[for]="highDurOD"
								text="Original Duration"
							></kendo-label>
						</li>
					</ul>
					<kendo-formerror [align]="'end'">Error: This field is required</kendo-formerror>
				</kendo-formfield>
			</form>
		</fieldset>
	</div>
	<div style="position:relative;">
		<button kendoButton [disabled]="!qualityFormGroup.get('useDCMA').value || !hasDCMAChange" (click)="resetDCMA()"
						style="position: absolute; top: -20px; right: 0; margin-right: 0; z-index: 30" size="small">Reset</button>
		<fieldset style="margin-right: 0;">
			<legend>DCMA Options</legend>
			<div class="dcmaGridHeaders">
				<div>
					<span>
						Assessment
					</span>
				</div>
				<div style="border-left: 1px solid #d3d3d3">
					<span>
						Target
					</span>
				</div>
				<div style="border-left: 1px solid #d3d3d3">
					<span>
						Other
					</span>
				</div>
			</div>
			<form [formGroup]="qualityFormGroup" style="max-height: 274px; overflow: auto">
				<div class="dcmaGrid">
					<div class="dcma-assessment">
						<div class="assessment-lbl">
							<kendo-checkbox #beicheck formControlName="useBei" ></kendo-checkbox>
							<kendo-label
								class="k-checkbox-label"
								[for]="beicheck"
								text="BEI"
							></kendo-label>
						</div>
						<div>
							<span style="padding-right: 5px; margin: auto 0;">Min %:</span>
							<kendo-numerictextbox
								class="dcma-numerictextbox"
								[autoCorrect]="true"
								[min]="0"
								[max]="100"
								formControlName="beiMin"
							></kendo-numerictextbox>
						</div>
						<div class="placeholder-cell"></div>
					</div>
					<div class="dcma-assessment">
						<div class="assessment-lbl">
							<kendo-checkbox #cplicheck formControlName="useCpli" ></kendo-checkbox>
							<kendo-label
								class="k-checkbox-label"
								[for]="cplicheck"
								text="CPLI"
							></kendo-label>
						</div>
						<div>
							<span style="padding-right: 5px; margin: auto 0;">Min %:</span>
							<kendo-numerictextbox
								class="dcma-numerictextbox"
								[autoCorrect]="true"
								[min]="0"
								[max]="100"
								formControlName="cpliMin"
							></kendo-numerictextbox>
						</div>
						<div class="placeholder-cell"></div>
					</div>
					<div class="dcma-assessment">
						<div class="assessment-lbl">
							<kendo-checkbox #critcheck formControlName="requireCritPath" ></kendo-checkbox>
							<kendo-label
								class="k-checkbox-label"
								[for]="critcheck"
								text="Critical Path Test"
							></kendo-label>
						</div>
						<div class="placeholder-cell"></div>
						<div class="placeholder-cell"></div>
					</div>
					<div class="dcma-assessment">
						<div class="assessment-lbl">
							<kendo-checkbox #hardcheck formControlName="useHardConstraints" ></kendo-checkbox>
							<kendo-label
								class="k-checkbox-label"
								[for]="hardcheck"
								text="Hard Constraints"
							></kendo-label>
						</div>
						<div>
							<span style="padding-right: 5px; margin: auto 0;">Max %:</span>
							<kendo-numerictextbox
								class="dcma-numerictextbox"
								[autoCorrect]="true"
								[min]="0"
								[max]="100"
								formControlName="hardConstraintsMax"
							></kendo-numerictextbox>
						</div>
						<div class="placeholder-cell"></div>
					</div>
					<div class="dcma-assessment">
						<div class="assessment-lbl">
							<kendo-checkbox #durationcheck formControlName="useHighDuration" ></kendo-checkbox>
							<kendo-label
								class="k-checkbox-label"
								[for]="durationcheck"
								text="High Duration"
							></kendo-label>
						</div>
						<div>
							<span style="padding-right: 5px; margin: auto 0;">Max %:</span>
							<kendo-numerictextbox
								class="dcma-numerictextbox"
								[autoCorrect]="true"
								[min]="0"
								[max]="100"
								formControlName="highDurationMax"
							></kendo-numerictextbox>
						</div>
						<div>
							<span style="margin: auto 0;">Threshold (days):</span>
							<kendo-numerictextbox
								class="dcma-numerictextbox"
								[autoCorrect]="true"
								[min]="0"
								formControlName="highDurationDays"
								style="margin-left: 5px;"
							></kendo-numerictextbox>
						</div>
					</div>
					<div class="dcma-assessment">
						<div class="assessment-lbl">
							<kendo-checkbox #floatcheck formControlName="useHighFloat" ></kendo-checkbox>
							<kendo-label
								class="k-checkbox-label"
								[for]="floatcheck"
								text="High Float"
							></kendo-label>
						</div>
						<div>
							<span style="padding-right: 5px; margin: auto 0;">Max %:</span>
							<kendo-numerictextbox
								class="dcma-numerictextbox"
								[autoCorrect]="true"
								[min]="0"
								[max]="100"
								formControlName="highFloatMax"
							></kendo-numerictextbox>
						</div>
						<div>
							<span style="margin: auto 0;">Threshold (days):</span>
							<kendo-numerictextbox
								class="dcma-numerictextbox"
								[autoCorrect]="true"
								[min]="0"
								formControlName="highFloatDays"
								style="margin-left: 5px;"
							></kendo-numerictextbox>
						</div>
					</div>
					<div class="dcma-assessment">
						<div class="assessment-lbl">
							<kendo-checkbox #invalidcheck formControlName="useInvalidDates" ></kendo-checkbox>
							<kendo-label
								class="k-checkbox-label"
								[for]="invalidcheck"
								text="Invalid Dates"
							></kendo-label>
						</div>
						<div>
							<span style="padding-right: 5px; margin: auto 0;">Max %:</span>
							<kendo-numerictextbox
								class="dcma-numerictextbox"
								[autoCorrect]="true"
								[min]="0"
								[max]="100"
								formControlName="invalidDatesMax"
							></kendo-numerictextbox>
						</div>
						<div class="placeholder-cell"></div>
					</div>
					<div class="dcma-assessment">
						<div class="assessment-lbl">
							<kendo-checkbox #positivecheck formControlName="usePositiveLag" ></kendo-checkbox>
							<kendo-label
								class="k-checkbox-label"
								[for]="positivecheck"
								text="Lags"
							></kendo-label>
						</div>
						<div>
							<span style="padding-right: 5px; margin: auto 0;">Max %:</span>
							<kendo-numerictextbox
								class="dcma-numerictextbox"
								[autoCorrect]="true"
								[min]="0"
								[max]="100"
								formControlName="positiveLagMax"
							></kendo-numerictextbox>
						</div>
						<div>
							<kendo-label
								class="k-display-block"
								[for]="lagTypeMultiselect"
								text="Type:"
								style="white-space: nowrap"
							>
							</kendo-label>
							<kendo-multiselect
								#lagTypeMultiselect
								kendoMultiSelectSummaryTag
								[autoClose]='false'
								[data]="relTypes"
								[valuePrimitive]="true"
								textField="text"
								valueField="value"
								formControlName="lagRelationTypes"
								style="height: 24px; margin-left: 5px;"
								class="showTheName"
							>
								<ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
									<div class="invisible-multiselect-tag-selector" (click)="toggleMultiselect('lagTypeMultiselect')"></div>
									<span>
										<kendo-svg-icon size="small" [icon]="caretAltDownIcon" (click)="toggleMultiselect('lagTypeMultiselect')"></kendo-svg-icon>
										{{ dataItems.length === 1 ? dataItems[0].text : dataItems.length + ' selected' }}
									</span>
								</ng-template>
							</kendo-multiselect>
						</div>
					</div>
					<div class="dcma-assessment">
						<div class="assessment-lbl">
							<kendo-checkbox #negativelagcheck formControlName="useNegativeLag" ></kendo-checkbox>
							<kendo-label
								class="k-checkbox-label"
								[for]="negativelagcheck"
								text="Leads"
							></kendo-label>
						</div>
						<div>
							<span style="padding-right: 5px; margin: auto 0;">Max %:</span>
							<kendo-numerictextbox
								class="dcma-numerictextbox"
								[autoCorrect]="true"
								[min]="0"
								[max]="100"
								formControlName="negativeLagMax"
							></kendo-numerictextbox>
						</div>
						<div class="placeholder-cell"></div>
					</div>
					<div class="dcma-assessment">
						<div class="assessment-lbl">
							<kendo-checkbox #missingcheck formControlName="useMissingPredSucc" ></kendo-checkbox>
							<kendo-label
								class="k-checkbox-label"
								[for]="missingcheck"
								text="Logic"
							></kendo-label>
						</div>
						<div>
							<span style="padding-right: 5px; margin: auto 0;">Max %:</span>
							<kendo-numerictextbox
								class="dcma-numerictextbox"
								[autoCorrect]="true"
								[min]="0"
								[max]="100"
								formControlName="missingPredSuccMax"
							></kendo-numerictextbox>
						</div>
						<div class="placeholder-cell"></div>
					</div>
					<div class="dcma-assessment">
						<div class="assessment-lbl">
							<kendo-checkbox #missedcheck formControlName="useMissedTask" ></kendo-checkbox>
							<kendo-label
								class="k-checkbox-label"
								[for]="missedcheck"
								text="Missed Tasks"
							></kendo-label>
						</div>
						<div>
							<span style="padding-right: 5px; margin: auto 0;">Max %:</span>
							<kendo-numerictextbox
								class="dcma-numerictextbox"
								[autoCorrect]="true"
								[min]="0"
								[max]="100"
								formControlName="missedTaskMax"
							></kendo-numerictextbox>
						</div>
						<div class="placeholder-cell"></div>
					</div>
					<div class="dcma-assessment">
						<div class="assessment-lbl">
							<kendo-checkbox #negativecheck formControlName="useNegativeFloat" ></kendo-checkbox>
							<kendo-label
								class="k-checkbox-label"
								[for]="negativecheck"
								text="Negative Float"
							></kendo-label>
						</div>
						<div>
							<span style="padding-right: 5px; margin: auto 0;">Max %:</span>
							<kendo-numerictextbox
								class="dcma-numerictextbox"
								[autoCorrect]="true"
								[min]="0"
								[max]="100"
								formControlName="negativeFloatMax"
							></kendo-numerictextbox>
						</div>
						<div class="placeholder-cell"></div>
					</div>
					<div class="dcma-assessment">
						<div class="assessment-lbl">
							<kendo-checkbox #relTypeCheck formControlName="useNonFS" ></kendo-checkbox>
							<kendo-label
								class="k-checkbox-label"
								[for]="relTypeCheck"
								text="Relationship Types"
							></kendo-label>
						</div>
						<div>
							<span style="padding-right: 5px; margin: auto 0;">Max %:</span>
							<kendo-numerictextbox
								class="dcma-numerictextbox"
								[autoCorrect]="true"
								[min]="0"
								[max]="100"
								formControlName="nonFSMax"
							></kendo-numerictextbox>
						</div>
						<div class="placeholder-cell"></div>
					</div>
					<div class="dcma-assessment">
						<div class="assessment-lbl">
							<kendo-checkbox #rsrccheck formControlName="useUnassignedResource" ></kendo-checkbox>
							<kendo-label
								class="k-checkbox-label"
								[for]="rsrccheck"
								text="Resources"
							></kendo-label>
						</div>
						<div>
							<span style="padding-right: 5px; margin: auto 0;">Max %:</span>
							<kendo-numerictextbox
								class="dcma-numerictextbox"
								[autoCorrect]="true"
								[min]="0"
								[max]="100"
								formControlName="unassignedResourceMax"
							></kendo-numerictextbox>
						</div>
						<div class="placeholder-cell"></div>
					</div>
				</div>
			</form>
		</fieldset>
	</div>

</div>
<button kendoButton themeColor='success' style='float: right; margin-top: 10px' [disabled]="!hasChange" (click)="saveChanges()">Save</button>
