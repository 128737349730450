import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { format } from 'date-fns';
import { BehaviorSubject } from 'rxjs';
import { SeriesSplineOptions } from 'highcharts';
import { cleanDateUTC } from '../../../util/pipes/date.pipe';

@Component({
	selector: 'app-project-completion-variance',
	templateUrl: './project-completion-variance.component.html',
	styleUrls: [],
})
export class ProjectCompletionVarianceComponent implements OnInit {
	@Input() public projectCompletionVariance: BehaviorSubject<Array<any>>;

	Highcharts = Highcharts;
	initialized = false;
	projectCompletionOptions: Highcharts.Options = {
		chart: {
			type: 'spline',
		},
		title: {
			text: '',
		},
		credits: {
			enabled: false,
		},

		subtitle: {
			text: '',
		},

		yAxis: {
			title: {
				text: 'Completion Variance',
			},
			labels: {
				formatter(): string {
					const label = this.axis.defaultLabelFormatter.call(this);
					if (this.value === 0) {
						return 'On Time';
					}
					return label + ' cd';
				},
			},
		},

		xAxis: {
			type: 'datetime',
			dateTimeLabelFormats: {
				month: '%b %e, %Y',
				year: '%Y',
			},
			title: {
				text: 'Update',
				margin: 50,
			},
			labels: {
				rotation: 315,
				align: 'right',
			},
		},

		tooltip: {
			shared: true,
			formatter(): string {
				const rawDate = new Date(this.x);
				const finalDate = cleanDateUTC(new Date(rawDate), 'MMM dd, yyyy');
				return this.points.reduce(
					function (s, point): string {
						return s + '<br/>' + point.series.name + ': ' + '<b>' + point.y + ' cd' + '</b>';
					},
					'<b>' + finalDate + ' Completion Variance' + '</b>'
				);
			},
		},

		legend: {
			layout: 'vertical',
			align: 'center',
			verticalAlign: 'bottom',
		},

		plotOptions: {
			series: {
				marker: {
					fillColor: '#FFFFFF',
					lineWidth: 2,
					lineColor: null, // inherit from series,
					enabled: true,
				},
			},
		},
	};
	projectCompletionChart: Highcharts.Chart;
	$projectCompletionVariance = new BehaviorSubject<any[]>([]);

	constructor() {}

	ngOnInit(): void {
		this.projectCompletionVariance.subscribe((projectCompletionVariance) => {
			if (!projectCompletionVariance?.length) {
				return;
			}
			this.$projectCompletionVariance.next(projectCompletionVariance);
			this.getCompletionVarianceData(projectCompletionVariance);
			this.initialized = true;
		});
	}

	getCompletionVarianceData(projectCompletionVariance): any {
		if (!projectCompletionVariance?.length) {
			return;
		}
		const series = [];
		for (const project of projectCompletionVariance) {
			const scheduleNameArray = project.scheduleNameArray || [];
			const contractVarianceArray = project.contractVarianceArray || [];
			const dataDateArray = project.dataDateArray || [];

			const data = scheduleNameArray.map((s: any, index: number) => {
				const dataDateArrayElement = dataDateArray[index];
				const date = new Date(dataDateArrayElement);
				const contractVarianceArrayElement = contractVarianceArray[index];
				return [Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()), contractVarianceArrayElement];
			});

			const name = project.projectName || '';
			const seriesElement = {
				data,
				name,
				type: 'spline',
				states: {
					inactive: {
						opacity: 0.1,
					},
				},
			} as SeriesSplineOptions;
			this.projectCompletionChart?.addSeries(seriesElement, false);
			series.push(seriesElement);
		}

		this.projectCompletionOptions.series = series;
		return series;
	}

	projectCompletionVarGraphInstance(x: Highcharts.Chart): void {
		this.projectCompletionChart = x;
	}
}
