import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { RiskSettingsService } from '../../../../../services/project/risk-settings.service';
import { CustomFieldTreeData } from 'models/risk';
import { ProjectDashboardService } from '../../../../../services/project/project.service';
import { RestService } from '../../../../../services/common/rest.service';
import { ProjectInterface } from '../../../../../models/Project';
import { testNumericKeypress, testPaste } from '../../../../../util/strings';

@Component({
	selector: 'app-risk-settings',
	templateUrl: 'risk-settings.component.html',
	styleUrls: ['risk-settings.component.scss'],
})
export class RiskSettingsComponent implements OnInit, OnDestroy {
	_projectData = new BehaviorSubject<ProjectInterface>(undefined);
	@Input() isOpened = false;
	@Input() set $projectData(data: ProjectInterface) {
		this._projectData.next(data);
	}
	@Output() closeSettings = new EventEmitter<boolean>();
	tabIndex = 0;
	public rootData: Observable<CustomFieldTreeData[]>;
	private _unsubscribeAll: Subject<void>;
	public globalMCVariance = [0, 0];

	constructor(
		protected editService: RiskSettingsService,
		private project: ProjectDashboardService,
		private restService: RestService
	) {
		this._unsubscribeAll = new Subject<void>();
	}

	public ngOnInit(): void {
		if (this.isOpened) {
			this.closeCustomFieldsDialog();
		}
		this.rootData = this.editService;
		this.project.$currentProjectData.subscribe((data) => {
			//for when custom fields added/updated/removed
			this.editService.read();
			if (data) {
				this.globalMCVariance = [
					(data?.preferences?.monteCarlo?.pfVariance[0] ?? 0.1) * 100,
					(data?.preferences?.monteCarlo?.pfVariance[1] ?? 0.1) * 100,
				];
			}
		});
		this._projectData.subscribe((data) => {
			//for when register is updated
			this.editService.read();
		});
		this.project.$currentRiskRegisters.subscribe((data) => {
			//for when register is added/removed
			this.editService.read();
		});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	tabChange(modifier: number) {
		this.tabIndex += modifier;
	}

	public onTabSelect(e: SelectEvent): void {
		this.tabIndex = e.index;
	}

	closeCustomFieldsDialog() {
		this.closeSettings.emit(true);
	}

	closeConfirmDialog() {
		this.editService.confirmDialogOpen = false;
		this.editService.conflictingRiskIds = [];
	}

	saveGlobalVariance(variances: [number, number]) {
		this.globalMCVariance = variances;
		this.restService
			.post('project/preferences/montecarlo/' + this.project.$currentProjectPageId.value, {
				data: {
					pfVariance: [variances[0] / 100, variances[1] / 100],
				},
			})
			.subscribe(() => {});
	}

	protected readonly testNumericKeypress = testNumericKeypress;
	protected readonly testPaste = testPaste;
}
