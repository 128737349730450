import { Component, Input, OnInit } from '@angular/core';
import { HeatMapDataObject, RiskRegister } from '../../../../models/risk';
import { BehaviorSubject } from 'rxjs';
import { SeriesMarkers } from '@progress/kendo-angular-charts';
import { SeriesData } from '../../../../models/ChartSettings';

@Component({
	selector: 'app-likelihood-heatmap',
	templateUrl: './likelihood-vs-impact-heatmap.component.html',
	styleUrls: ['./likelihood-vs-impact-heatmap.component.scss'],
})
export class LikelihoodVsImpactHeatmapComponent implements OnInit {
	@Input() $risks = new BehaviorSubject<Array<RiskRegister>>([]);
	data: HeatMapDataObject[] = [];

	constructor() {}

	ngOnInit(): void {
		this.$risks.subscribe((risks) => {
			this.updateChartValues(risks);
		});
	}

	/**
	 * update chart values based on new risk array
	 * @param risks
	 */
	updateChartValues(risks: RiskRegister[]): void {
		if (!risks) {
			return;
		}
		const newData = [];
		const numRisks = risks.length;
		risks.forEach((risk) => {
			if (risk.isDraft) {
				return;
			}
			const xValue = Math.max(
				risk.preMitigation.qualityImpact,
				risk.preMitigation.performanceImpact,
				risk.preMitigation.costImpact,
				risk.preMitigation.scheduleImpact
			).toString();
			const yValue = risk.preMitigation.probability.toString();
			const matchingIndex = newData.findIndex((point) => point.x === xValue && point.y === yValue);
			if (matchingIndex === -1) {
				newData.push({
					x: xValue,
					y: yValue,
					value: 1,
					totalRisks: numRisks,
				});
			} else {
				newData[matchingIndex].value++;
			}
		});
		this.data = newData;
	}

	/**
	 * sets marker size dynamically based on the number of risks at that coordinate. 7/9/11/13 for 1-4, 15 for 5+
	 * @param e
	 */
	public markerSize(e: { SeriesMarkers; dataItem: { totalRisks: number; value: number }; series: SeriesData }) {
		let size = 11;
		if (e?.dataItem?.totalRisks && e?.dataItem?.value) {
			if (e?.dataItem?.value >= 5) {
				size = 15;
			} else {
				size = 5 + 2 * e?.dataItem?.value;
			}
		}
		return size;
	}
}
