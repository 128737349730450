import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedIonModule } from '../../shared/shared-ion.module';
import { TooltipMenuModule } from '../../portfolio/tooltip-menu/tooltip-menu.module';
import { PipeModule } from '../../../util/pipes/pipe.module';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { GridLayoutModule } from '@progress/kendo-angular-layout';
import { AegisChartModule } from '../../shared/chart/chart.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { LabelModule } from '@progress/kendo-angular-label';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { ProgressPageComponent } from './progress-page.component';
import { ActivityCompletionModule } from '../overview/activity-completion/activity.completion.module';
import { MilestonesModule } from '../overview/milestones/milestones.module';
import { ProjectCompletionTrendingModule } from '../overview/project-completion-trending/project-completion-trending.module';
import { ProjectOverviewModule } from '../overview/project-overview.module';
import { ScheduleLookaheadModule } from '../overview/schedule-lookahead/schedule-lookahead.module';
import { ScheduleCompressionModule } from '../analysis/schedule-compression/schedule-compression.module';
import { PerformanceFactorHistoricalModule } from './performance-factor-historical/performance-factor-historical.module';

@NgModule({
	declarations: [ProgressPageComponent],
	imports: [
		CommonModule,
		SharedIonModule,
		TooltipMenuModule,
		PipeModule,
		ButtonsModule,
		TooltipsModule,
		GridLayoutModule,
		AegisChartModule,
		GridModule,
		LabelModule,
		TextBoxModule,
		SVGIconModule,
		ActivityCompletionModule,
		MilestonesModule,
		ProjectCompletionTrendingModule,
		ProjectOverviewModule,
		ScheduleLookaheadModule,
		ScheduleCompressionModule,
		PerformanceFactorHistoricalModule,
	],
	exports: [ProgressPageComponent],
})
export class ProgressPageModule {}
