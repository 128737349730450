import { Injectable } from '@angular/core';
import {
	ActivatedRoute,
	Event,
	NavigationEnd,
	NavigationError,
	NavigationStart,
	Params,
	Router,
} from '@angular/router';
import { environment } from '../../environments/environment';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { AccountUser, UserInterface } from '../../models/auth/account-user';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import jwt_decode from 'jwt-decode';
import { NotificationService } from '@progress/kendo-angular-notification';
@Injectable({
	providedIn: 'root',
})
export class AuthenticationService {
	accountUser: AccountUser;
	newPasswordUserAttributes: any;
	oauthConnected: boolean = false;
	oauthCompletedLogin: boolean = false;
	public currentRoute: string;

	timedout: boolean = false;

	errorcatcher: any[] = [];

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private oauthService: OAuthService,
		private http: HttpClient,
		private notificationService: NotificationService
	) {
		this.currentRoute = '';
		if (localStorage.getItem('currentRoute') == null) {
			localStorage.setItem('currentRoute', '/portfolio');
		}

		window.onerror = (error, url, line) => {
			this.errorcatcher.push({ line: line, message: error, url: url });
		};

		let user: any = localStorage.getItem('currentUserCredentials');

		this.accountUser = user as AccountUser;
		setTimeout(() => {
			if (this.accountUser === null && this.router.url.indexOf('handoff') === -1) {
				this.router.navigate(['/auth/login']);
			}
		}, 1500);

		const cognitoUser = this.getCurrentActiveUser();
		if (cognitoUser != null) {
			//if user is logged in

			user = JSON.parse(localStorage.getItem('currentUserCredentials')) as AccountUser;
			const options = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + user.access_token,
				},
			};

			this.http.get<any>('https://account.rhino.works/v2/my/company/list/', options).subscribe({
				next: (data) => {
					let u: UserInterface | null = JSON.parse(localStorage.getItem('currentUser')) || null;
					if (u != null) {
						if (JSON.stringify(data.companies) !== JSON.stringify(u.companyIds)) {
							//console.log(data.companies, u.companyIds);
							this.http.get<any>('https://account.rhino.works/v2/my/company/list/?doUpdate=true', options).subscribe({
								next: (d2) => {
									console.warn('A list update was triggered.');
									//window.location.reload();
								},
							});
						}
					}
				},
				error: (error) => {
					console.log(error);
				},
			});

			this.router.events.subscribe((event: Event) => {
				if (event instanceof NavigationStart) {
					// console.log('Route change detected');
				}

				if (event instanceof NavigationEnd) {
					this.currentRoute = event.url;
					// console.log(event.url);
					localStorage.setItem('currentRoute', event.url);
					// console.log(event);
				}

				if (event instanceof NavigationError) {
					// Present error to user
					console.log(event.error);
				}
			});
		}

		const getQueryStringValue = (key) =>
			decodeURIComponent(
				window.location.search.replace(
					new RegExp(
						'^(?:.*[&\\?]' + encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$',
						'i'
					),
					'$1'
				)
			);

		const authCodeFlowConfig: AuthConfig = {
			// Url of the Identity Provider
			issuer: 'https://account.rhino.works/o',

			tokenEndpoint: 'https://account.rhino.works/o/token/',
			userinfoEndpoint: 'https://account.rhino.works/o/userinfo/',

			// URL of the SPA to redirect the user to after login
			redirectUri: window.location.origin + '/auth/handoff',

			// The SPA's id. The SPA is registerd with this id at the auth-server
			// clientId: 'server.code',
			clientId: '0bRKdrAGd0oOQ40bPLc5ut6RE4hZSHAgvyVTmxCr',

			// Just needed if your auth server demands a secret. In general, this
			// is a sign that the auth server is not configured with SPAs in mind
			// and it might not enforce further best practices vital for security
			// such applications.
			dummyClientSecret:
				'dl8KDgg12nW6dYPLWS7Q0Ivrgr1qGzSJa198IlbSiQhPuqmapbN4I7Nrm2vlgi1LmviOGLVq57Rl4AUo3ih5wy0dRfWwDhN50N1Q90VZgzmLN4JpvQvnS5YOBnJxIJrK',

			strictDiscoveryDocumentValidation: false,

			responseType: 'code',

			// set the scope for the permissions the client should request
			// The first four are defined by OIDC.
			// Important: Request offline_access to get a refresh token
			// The api scope is a usecase specific one
			scope: 'openid name email read',

			showDebugInformation: true,
		};
		//console.log("This should go first.");
		this.oauthService.initCodeFlow();
		this.oauthService.configure(authCodeFlowConfig);
		//this.oauthService.loadDiscoveryDocumentAndLogin();

		const code = getQueryStringValue('code');
		//console.log("This second.");
		if (code !== '') {
			this.oauthService
				.tryLogin({
					onTokenReceived: (context) => {
						//
						// Output just for purpose of demonstration
						// Don't try this at home ... ;-)
						//
						// console.log('logged in');
						this.oauthCompletedLogin = true;
						//console.log(context);
					},
				})
				.then((r) => {
					//console.log(r);
					//console.log(this.oauthService.getIdentityClaims());
					//console.log(this.oauthService.getAccessToken());
					//console.log();
					//console.log(this.oauthService.getRefreshToken());
					this.accountUser = {
						//eslint-disable-next-line
						username: this.oauthService.getIdentityClaims()['email'],
						access_token: this.oauthService.getIdToken(),
						refresh_token: this.oauthService.getIdToken(),
					};
					//console.log("Setting currentUser");
					localStorage.setItem('currentUserCredentials', JSON.stringify(this.accountUser));
					//eslint-disable-next-line
					localStorage.setItem('currentUserEmail', this.oauthService.getIdentityClaims()['email']);
					this.getHeader();
					window.location.reload();
					//console.log('we would have reloaded here.');

					try {
						let timestamp =
							localStorage.getItem('raz-timestamp') !== null ? localStorage.getItem('raz-timestamp') : 'new';

						if (timestamp === 'new') {
							timestamp = new Date().getTime().toString();
							localStorage.setItem('raz-timestamp', timestamp);
						} else {
							console.log(timestamp, new Date().getTime());
							if (new Date().getTime() - parseInt(timestamp, 10) > 3600000) {
								//if (new Date().getTime() - parseInt(timestamp) > 1000) {
								// we're out of time, dont fetch this.
								this.router.navigate(['/portfolio']);
							} else {
								const u = new URL(
									decodeURI(
										localStorage.getItem('currentRoute').toString().replace('%253F', '?').replace('%253D', '=')
									),
									window.location.protocol + '//' + window.location.host + '/'
								);
								const params = {};
								u.searchParams.forEach((value, key) => {
									params[key] = value;
								});
								//alert("Im navigating via authentication-service");
								this.router.navigate([u.pathname], { queryParams: params as Params });
							}
						}
					} catch (e) {
						this.router.navigate(['/portfolio']);
					}

					//window.location.reload();
				});
			//console.log("A code was passed from IdP");
			/**
      let authUser = new CognitoUser();
      console.log(currUser);
      currUser.setAuthenticationFlowType("REFRESH_TOKEN_AUTH");
      currUser.refreshSession(new CognitoRefreshToken({ RefreshToken: code }), (res: any) => {
        console.log(res);
      })
       **/
		} else {
			setTimeout(() => {
				this.timedout = true;
			}, 10000);
		}
	}

	isAuthTokenExpired(): boolean {
		const helper = new JwtHelperService();

		try {
			jwt_decode(this.accountUser.access_token);
			return helper.isTokenExpired(this.accountUser.access_token);
		} catch (e) {
			localStorage.removeItem('currentUserCredentials');
			localStorage.removeItem('currentUserEmail');
			localStorage.removeItem('currentUser');
			console.log('authToken401', 'This key is WRONG JAMES.');
			return true;
		}
	}

	async imageConverter(image: string) {
		return `${environment.baseURL}/api/images?image=${image.split('')[1]}&authorization=${await this.getHeader()}`;
	}

	deconvertImage(image: string) {
		try {
			return image.split(`${environment.baseURL}/api/images?image=`)[1].split(`&authorization=`)[0];
		} catch (err) {}
	}

	tryingLogin: boolean = false;

	public getHeader(): {
		accessToken: string;
		refreshToken: string;
		idToken: string;
	} {
		try {
			const user = JSON.parse(localStorage.getItem('currentUserCredentials')) as AccountUser;

			return {
				accessToken: user?.access_token,
				refreshToken: user?.refresh_token,
				idToken: user?.access_token,
			};
		} catch (err) {
			console.log(err);
			return err;
		}
	}

	login(email: string, password: string, cb?: any): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			resolve('true');
		});
	}

	public getCurrentUserFromConfig() {}

	public getCurrentActiveUser() {
		try {
			return this.accountUser;
			// return currentUser ? Users.findOne({"emails.address":currentUser['username']}) : null
		} catch (err) {
			console.log('user cognito error:', err);
		}
	}
}
