import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const toTitleCase = (str: string) =>
	str.replace(/\w\S*/g, (txt: string) => txt.charAt(0).toUpperCase() + txt.substr(1));

/** assumes array elements are primitive types
 * check whether 2 arrays are equal sets.
 * @param  {} a1 is an array
 * @param  {} a2 is an array
 */
export const areArraysEqualSets = (a1: Array<any>, a2: Array<any>): boolean => {
	const superSet = {};
	for (const i of a1) {
		const e = i + typeof i;
		superSet[e] = 1;
	}

	for (const i of a2) {
		const e = i + typeof i;
		if (!superSet[e]) {
			return false;
		}
		superSet[e] = 2;
	}

	for (const e in superSet) {
		if (superSet[e] === 1) {
			return false;
		}
	}

	return true;
};

export const areArraysIdentical = (arr1: string[], arr2: string[]): boolean => {
	// Check if the arrays are of the same length
	if (arr1.length !== arr2.length) {
		return false;
	}

	// Sort the arrays and compare each element
	const sortedArr1 = arr1.slice().sort();
	const sortedArr2 = arr2.slice().sort();

	for (let i = 0; i < sortedArr1.length; i++) {
		if (sortedArr1[i] !== sortedArr2[i]) {
			return false;
		}
	}

	return true;
};

export const areStringObjectsIdentical = (obj1: Record<string, string>, obj2: Record<string, string>): boolean => {
	// Check if both objects have the same number of keys
	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	// Check if the keys and their corresponding values are the same in both objects
	for (const key of keys1) {
		if (obj1[key] !== obj2[key]) {
			return false;
		}
	}

	return true;
};

export const getNonZeroDecimalPosition = (v: string): number => {
	const v2 = v.replace(/\.(0+)?/, '');
	return v2.length !== v.length ? v.length - v2.length : -1;
};

/**
 * determines whether a pasted string is valid for an input
 * @param ev
 */
export const testPaste = (ev): boolean => {
	const clipboardData = ev.clipboardData;
	const pastedData = clipboardData.getData('Text');
	return /^\d*$/.test(pastedData);
};

/**
 * determines whether a typed character is numeric (only tested on a US keyboard)
 * @param keyEvent
 */
export const testNumericKeypress = (keyEvent): boolean => {
	if (
		keyEvent.code === 'Backspace' ||
		keyEvent.code === 'Tab' ||
		keyEvent.code.substring(0, 5) === 'Digit' ||
		keyEvent.code.substring(0, 6) === 'Numpad'
	) {
		return true;
	}
	return false;
};

export const isValidUrl = (urlString: string): boolean => {
	let url: URL;
	try {
		url = new URL(urlString);
		return !!url;
	} catch (e) {
		return false;
	}
};

export const validUrlValidator =
	(): ValidatorFn =>
	(control: AbstractControl): ValidationErrors | null => {
		const value = control.value;

		if (!value) {
			return null;
		}
		return isValidUrl(value) ? null : { invalidUrl: true };
	};

export const haveCommonItem = <T>(set1: Set<T>, set2: Set<T>): boolean => {
	// Determine the smaller set to iterate over
	if (set1.size > set2.size) {
		[set1, set2] = [set2, set1];
	}

	// Iterate through the smaller set and check for common items
	for (const item of set1) {
		if (set2.has(item)) {
			return true;
		}
	}

	return false;
};
