<kendo-window
	*ngIf="opened"
	(close)="open(false)"
	[resizable]='false'
	[(top)]="appWindowService.windowPosition.unsavedScheduleChanges.top"
	[(left)]="appWindowService.windowPosition.unsavedScheduleChanges.left"
	[height]="appWindowService.windowPosition.unsavedScheduleChanges.height"
	[width]="appWindowService.windowPosition.unsavedScheduleChanges.width"
	(leftChange)="restrictMovement('unsavedScheduleChanges')"
	(topChange)="restrictMovement('unsavedScheduleChanges')"
	id='unsavedScheduleChangesWindow'
>
	<kendo-window-titlebar>
		<div class="k-window-title">Unsaved Changes</div>
		<button kendoWindowMinimizeAction></button>
		<button kendoWindowMaximizeAction></button>
		<button kendoWindowRestoreAction></button>
		<button kendoWindowCloseAction></button>
	</kendo-window-titlebar>
	<div style='background-color: white;margin: 0 -18px;padding: 8px 12px 18px 12px;'>
		<p style='padding: 12px 0 0 0; margin: 0;'>You have unsaved changes to your schedule. Would you like to save them?</p>
		<br/>
		<div *ngFor='let change of scheduleService.unsavedChangesDisplay() | keyvalue'
					 [style.color]="change.value === 'add' ? 'forestgreen' : change.value === 'delete' ? 'indianred' : change.value === 'notes' ? 'blue' : ''">
					{{ change.value === 'Add' ? '+' : change.value === 'Delete' ? '-' : change.value === 'baseline' ? 'Edited Re-Baseline: ' : change.value === 'costBaseline' ? 'Edited Cost Baseline: ' : change.value === 'finishMilestone' ? 'Edited Finish Milestone: ' : change.value === 'notes' ? 'Edited Notes: ' : ''  }}
				{{(updateIds.indexOf(change.key) === 0 ? 'Baseline' : ('Update ' +  updateIds.indexOf(change.key)))}}
					{{ scheduleService.updateMap.get(change.key)?.fileName ?  '(' + scheduleService.updateMap.get(change.key)?.fileName + ')' : ''}}
		</div>
	</div>
<div style='padding: 0; display: flex; flex-direction: row; justify-content: space-between;'>
  <button kendoButton style='background-color: #e04646; color: white; border-radius: 4px;'
          (click)="onNoClick()">DISCARD</button>
  <button kendoButton style='background-color: #2ebb2e; color: white; border-radius: 4px;'
          (click)='onSaveClick()'>SAVE</button>
</div>
</kendo-window>
