import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ProjectDashboardService } from '../../../../../../services/project/project.service';
import { takeUntil } from 'rxjs/operators';
import { differenceInCalendarDays, differenceInDays, isAfter, isBefore } from 'date-fns';
import { ProjectReportInterface } from '../../../../../../models/ProjectReport/ProjectReport';
import { ProjectInterface } from '../../../../../../models/Project';

export interface MonteCarloStatistics {
	field: string;
	value: Date | number | string;
}

@Component({
	selector: 'app-mc-datagrid',
	templateUrl: './mc-datagrid.component.html',
	styleUrls: ['./mc-datagrid.component.scss'],
})
export class McDatagridComponent implements OnInit, OnDestroy {
	@Input() $p50Date = new BehaviorSubject<Date>(undefined);
	datagrid: MonteCarloStatistics[] = [];
	displayedColumns: string[] = ['field', 'value'];
	_unsubscribeAll = new Subject<void>();
	p85AndCcdDelta = 0;
	earliestDate: Date;
	latestDate: Date;
	averageDate: Date;

	constructor(private projectService: ProjectDashboardService) {}

	ngOnInit(): void {
		this.projectService.$currentProjectReport.pipe(takeUntil(this._unsubscribeAll)).subscribe((report) => {
			this.updateDatagridValues(report);
		});
		this.$p50Date.pipe(takeUntil(this._unsubscribeAll)).subscribe((p50Date) => {
			this.averageDate = p50Date;
		});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	/**
	 * generates new values for datagrid based on updated monte carlo report
	 * @param report
	 */
	updateDatagridValues(report: Omit<ProjectReportInterface, 'project'> & { project?: ProjectInterface }): void {
		if (!report?.riskPage?.performanceFactor) {
			return;
		}
		const mcReport = report?.riskPage?.performanceFactor;
		const probArray = mcReport?.prob || [];

		this.earliestDate = new Date(probArray[0]?.unixDate * 1000);
		this.latestDate = new Date(probArray[probArray.length - 1]?.unixDate * 1000);

		const p85Date =
			report.riskPage?.performanceFactor?.p85HistoricalTrend?.[
				report.riskPage?.performanceFactor?.p85HistoricalTrend?.length - 1
			]?.p85Date;
		this.p85AndCcdDelta = differenceInCalendarDays(
			new Date(p85Date),
			new Date(report.projectOverview.currentCompletion)
		);
		this.datagrid = [
			{
				field: 'Current Completion',
				value: new Date(report.projectOverview.currentCompletion),
			},
			{
				field: 'Probabilistic (85%)',
				value: new Date(p85Date),
			},
		];
	}
}
