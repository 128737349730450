<div class='card' id="spiComponent">
	<kendo-gridlayout
		[rows]="['42.4px', noData ? '150px' : '303px']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='grid'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' [class.overviewHeader]="isOverview">
			<kendo-gridlayout
				[rows]="['42.4px']"
				[cols]="['300px', 'calc(100% - 315px)']"
				[gap]="{ rows: 0, cols: 5 }"
				class='full-width'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
					<div class="card-header">
						Schedule Performance Index <span style="font-size: 9px; position: relative; top: -1px;">(BETA) </span>
					</div>
					<tooltip-open type='{{isOverview ? "overview" : "spi"}}' [fillColor]="isOverview ? 'overview' : ''"></tooltip-open>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2' class='toolbar-item-container' style="margin: auto; height: min-content; justify-content: end">
					<span style='display: inline-flex; align-items: center;float: right; gap: 10px;' kendoTooltip>
						@if (isOverview) {
							<button kendoButton themeColor="base" title="Go to source" [imageUrl]="'assets/icons/newIcons/noun-arrow-5544651.svg'" class='header-btn' (click)='projectService.goTo(14)' style="background-color: #ffb500;"></button>
						}
						<button kendoButton themeColor="base" title="Add/Edit Notes" [imageUrl]="'assets/icons/newIcons/noun-notepad-6607257.svg'" class='header-btn overview-notes-icon' (click)='projectService.openNotesWindow({id: 14, name: "Schedule Performance Index"})' [style.background-color]="isOverview ? '#ffb500' : 'white'"></button>
					</span>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
		@if (noData) {
			<kendo-gridlayout-item [row]="2" [col]="1">
				<div class='warning-div'>
					No Actual Cost Data Available
				</div>
			</kendo-gridlayout-item>
		} @else {
			<kendo-gridlayout-item [row]='2' [col]='1'>
				<div style='height: 300px; margin-bottom: 3px'>
					<app-chart
						[categories]='categories'
						[seriesData]='seriesData'
						[legendVisible]='true'
						[valueAxis]='valueAxisItemSettings'
						[legendClickable]='true'
						[categoryPlotBands]="categoryPlotBands"
						[isSPIChart]="true"
					></app-chart>
				</div>
			</kendo-gridlayout-item>
		}
	</kendo-gridlayout>
	@if (isOverview && hasNotes) {
		<app-overview-notes [componentId]="14"></app-overview-notes>
	}
</div>
