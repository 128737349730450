@if (!['ANALYTICS-BASIC-MPK', 'ANALYTICS-BASIC-APK'].includes(currentProjectCompanyPermissions?.license)) {
	<div class="tab-page-container">
		@if (!projectService.componentHidden(12)) {
			<kendo-gridlayout
				[rows]="['auto']"
				[cols]="['100%']"
				[gap]="{ rows: 5, cols: 0 }"
				class='grid'
			>
				<kendo-gridlayout-item [row]='1' [col]='1'>
					<app-cumulative-cash-flow></app-cumulative-cash-flow>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		}
		@if (!projectService.componentHidden(13)) {
			<kendo-gridlayout
				[rows]="['auto']"
				[cols]="['100%']"
				[gap]="{ rows: 5, cols: 0 }"
				class='grid'
			>
				<kendo-gridlayout-item [row]='1' [col]='1'>
					<app-historic-performance></app-historic-performance>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		}
		@if (!projectService.componentHidden(14)) {
			<kendo-gridlayout
				[rows]="['auto']"
				[cols]="['100%']"
				[gap]="{ rows: 5, cols: 0 }"
				class='grid'
			>
				<kendo-gridlayout-item [row]='1' [col]='1'>
					<app-schedule-index-trending></app-schedule-index-trending>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		}
		@if (!projectService.componentHidden(15)) {
			<kendo-gridlayout
				[rows]="['auto']"
				[cols]="['100%']"
				[gap]="{ rows: 5, cols: 0 }"
				class='grid'
			>
				<kendo-gridlayout-item [row]='1' [col]='1'>
					<app-activity-costs></app-activity-costs>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		}
	</div>
}
