import { ComparisonData } from '@rhinoworks/analytics-calculations';
import { filter } from 'rxjs/operators';

export function createExcelSheetsFromComparisonData(
	data: ComparisonData,
	sheetTitles: Record<keyof ComparisonData, string>
) {
	const sheets2 = Object.keys(data)
		.map((key) => {
			const rowsData = data[key as keyof ComparisonData];
			if (!rowsData || rowsData.length === 0) {
				return null;
			}
			const rows = rowsData
				.map((item: any) => {
					const cells = Object.keys(item).map((field) => {
						const value = item[field];
						return {
							value: value ? value : '',
						};
					});
					if (cells.every((cell) => cell.value === '')) {
						return null;
					} // Skip entirely empty rows
					return { cells };
				})
				.filter(Boolean);

			return {
				name: sanitizeSheetName(sheetTitles[key as keyof ComparisonData] || key),
				rows: [
					{
						cells: Object.keys(data[key as keyof ComparisonData][0] || {}).map((field) => ({
							value: field,
						})),
					},
					...rows,
				],
			};
		})
		.filter(Boolean);

	const orderVals = Object.values(sheetTitles);
	return filterAndSortSheets(sheets2, orderVals);
}

function sanitizeSheetName(name: string) {
	return name.replace(/[^a-zA-Z0-9 ]/g, '_'); // Replace invalid characters with underscore
}

function filterAndSortSheets(sheets, orderVals: string[]) {
	return sheets
		.filter((sheet) => orderVals.includes(sheet.name)) // Filter by keys in orderKeys
		.sort((a, b) => orderVals.indexOf(a.name) - orderVals.indexOf(b.name)); // Sort based on order in orderKeys
}
