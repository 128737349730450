import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsDashboardService } from '../../../../services/analytics/analytics.service';
import { SeriesLabelsContentArgs } from '@progress/kendo-angular-charts';
import { BehaviorSubject } from 'rxjs';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { SeriesData } from '../../../../models/ChartSettings';
import { hasObjChanged } from '../../../../util/projects';

@Component({
	selector: 'app-risk-by-category',
	templateUrl: './risk-by-category.component.html',
	styleUrls: ['./risk-by-category.component.scss'],
})
export class RiskByCategoryComponent implements OnInit {
	@Input() chartType;
	@Input() title;
	@Input() $risks = new BehaviorSubject<Array<any>>([]);
	@Input() groupBy: string;
	@Input() isFromProjectPage: boolean = false;
	data: SeriesData[] = [];
	totalRisks = 1;

	constructor(public _analyticsDashboardService: AnalyticsDashboardService) {
		this.labelContent = this.labelContent.bind(this);
	}

	ngOnInit(): void {
		this.$risks.subscribe((risks) => {
			this.totalRisks = risks.length || 1;
			this.updateChartValues(risks);
		});
	}

	/**
	 * binds/formats label content for individual series items
	 * @param e
	 */
	public labelContent(e: SeriesLabelsContentArgs): string {
		return Math.round(100 * e.value) + '%';
	}

	public donutLabelContent(e: SeriesLabelsContentArgs): string {
		return e.category + ' - ' + Math.round(100 * e.value) + '%';
	}

	/**
	 * function called on bar click
	 * @param ev
	 */
	clickedBar(ev) {
		console.log(ev);
	}

	/**
	 * update chart values based on new risk array
	 * @param risks
	 */
	updateChartValues(risks): void {
		if (!risks) {
			return;
		}
		let newChartData = [];
		const groupedByCategory = groupBy(risks, [
			{
				field: this.groupBy,
				aggregates: [
					{
						field: 'value',
						aggregate: 'sum',
					},
				],
			},
		]);
		newChartData = [];
		groupedByCategory.forEach((grouping: GroupResult) => {
			newChartData.push({
				category: grouping.value,
				value: Math.round(100 * (grouping.aggregates.value.sum / this.totalRisks)) / 100,
			});
		});
		if (hasObjChanged(this.data, newChartData)) {
			this.data = newChartData;
		}
	}
}
