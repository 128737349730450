<div class="tab-page-container">
	@if (['riskRegister', 'coreRisk'].includes((_projectDashboardService.$currentProjectReport | async)?.project?.riskMetricsType) && !['ANALYTICS-BASIC-MPK', 'ANALYTICS-BASIC-APK', 'ANALYTICS-PRO-MPK', 'ANALYTICS-PRO-APK'].includes(currentProjectCompanyPermissions?.license)) {
		@if (!_projectDashboardService.componentHidden(16) && activeRiskRegisters?.length > 5) {
			<kendo-gridlayout
				[rows]="['auto']"
				[cols]="['100%']"
				[gap]="{ rows: 5, cols: 0 }"
				class='grid'
			>
				<kendo-gridlayout-item [row]='1' [col]='1'>
					<app-top-risks [riskRegisters]='activeRiskRegisters'></app-top-risks>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		}
		@if (!_projectDashboardService.componentHidden(17)) {
			<kendo-gridlayout
				[rows]="['auto']"
				[cols]="['100%']"
				[gap]="{ rows: 5, cols: 0 }"
				class='grid'
			>
				<kendo-gridlayout-item [row]='1' [col]='1'>
					<app-risk-register [$projectData]='$projectData' (dialogStateChange)='dialogStateChanged($event)'></app-risk-register>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		}
		@if (!_projectDashboardService.componentHidden(18) && ['riskRegister', 'coreRisk'].includes((_projectDashboardService.$currentProjectReport | async)?.project?.riskMetricsType) && (user?.userType !== 'saasRisk' || (_projectDashboardService.$currentProjectReport | async)?.project?.showMonteCarlo)) {
			<kendo-gridlayout
				[rows]="['auto']"
				[cols]="['100%']"
				[gap]="{ rows: 5, cols: 0 }"
				class='grid'
			>
				<kendo-gridlayout-item [row]='1' [col]='1'>
					<app-monte-carlo></app-monte-carlo>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		}
		@if (user?.userType === 'saasRisk' && !(_projectDashboardService.$currentProjectReport | async)?.updateIds?.length) {
			<kendo-gridlayout
				[rows]="['auto']"
				[cols]="['100%']"
				[gap]="{ rows: 5, cols: 0 }"
				class='grid'
			>
				<kendo-gridlayout-item [row]='1' [col]='1'>
					<div class='saas-risk-upload-btn'>
						<button kendoButton size='large' class='shadow' (click)="openSaasRiskBaselineForm()" icon='file-add' title='Upload Schedule (XER)'>Upload Schedule (XER)</button>
					</div>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		}
	}
	@if (!_projectDashboardService.componentHidden(19) && (_projectDashboardService.$currentProjectReport | async)?.project?.riskMetricsType === 'performanceFactor' && !['ANALYTICS-BASIC-MPK', 'ANALYTICS-BASIC-APK', 'ANALYTICS-PRO-MPK', 'ANALYTICS-PRO-APK'].includes(currentProjectCompanyPermissions?.license)) {
		<kendo-gridlayout
			[rows]="['auto', 'auto', 'auto', 'auto']"
			[cols]="['100%']"
			[gap]="{ rows: 0, cols: 0 }"
			class='full-width full-height'
		>
			<kendo-gridlayout-item [row]='1' [col]='1' *ngIf="(_projectDashboardService.$currentProjectReport | async)?.project?.riskMetricsType === 'performanceFactor'">
				<app-performance-factor></app-performance-factor>
			</kendo-gridlayout-item>
		</kendo-gridlayout>
	}
	@if (!_projectDashboardService.componentHidden(20) && user?.userType !== "saasRisk" && !['ANALYTICS-BASIC-MPK', 'ANALYTICS-BASIC-APK'].includes(currentProjectCompanyPermissions?.license)) {
		<kendo-gridlayout
			[rows]="['auto']"
			[cols]="['100%']"
			[gap]="{ rows: 5, cols: 0 }"
			class='grid'
		>
			<kendo-gridlayout-item [row]='1' [col]='1'>
				<app-index-trending></app-index-trending>
			</kendo-gridlayout-item>
		</kendo-gridlayout>
	}
	@if (!_projectDashboardService.componentHidden(21) && user?.userType !== "saasRisk" && !['ANALYTICS-BASIC-MPK', 'ANALYTICS-BASIC-APK'].includes(currentProjectCompanyPermissions?.license)) {
		<kendo-gridlayout
			[rows]="['auto']"
			[cols]="['100%']"
			[gap]="{ rows: 5, cols: 0 }"
			class='grid'
		>
			<kendo-gridlayout-item [row]='1' [col]='1'>
				<app-critical-path-risk></app-critical-path-risk>
			</kendo-gridlayout-item>
		</kendo-gridlayout>
	}
	@if (!_projectDashboardService.componentHidden(22) && user?.userType !== "saasRisk" && !['ANALYTICS-BASIC-MPK', 'ANALYTICS-BASIC-APK'].includes(currentProjectCompanyPermissions?.license)) {
		<kendo-gridlayout
			[rows]="['auto']"
			[cols]="['100%']"
			[gap]="{ rows: 5, cols: 0 }"
			class='grid'
		>
			<kendo-gridlayout-item [row]='1' [col]='1'>
				<app-critical-path-reliability></app-critical-path-reliability>
			</kendo-gridlayout-item>
		</kendo-gridlayout>
	}
</div>
