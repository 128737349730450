import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectDashboardService } from '../../../../../../services/project/project.service';
import { RestService } from '../../../../../../services/common/rest.service';
import { RiskRegister } from '../../../../../../models/risk';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProjectInterface } from '../../../../../../models/Project';

@Component({
	selector: 'app-mc-register-selection',
	templateUrl: './mc-register-selection.component.html',
	styleUrls: ['./mc-register-selection.component.scss'],
})
export class McRegisterSelectionComponent implements OnInit, OnDestroy {
	public allRegisters: RiskRegister[] = [];
	public selectableRegisters: RiskRegister[] = [];
	public numDisabled = 0;
	public recalcing = false;
	cancelEnabled = false;
	recalcEnabled = false;
	_unsubscribeAll = new Subject<void>();
	constructor(
		private _projectDashboardService: ProjectDashboardService,
		private restService: RestService
	) {}

	ngOnInit(): void {
		this._projectDashboardService.$currentRiskRegisters.pipe(takeUntil(this._unsubscribeAll)).subscribe((registers) => {
			this.registers = registers || [];
			this.allRegisters = structuredClone(registers) || [];
		});
		this._projectDashboardService.$currentProjectData.pipe(takeUntil(this._unsubscribeAll)).subscribe((projectInfo) => {
			if (Object.keys(projectInfo?.mcJobs || {})?.length) {
				this.recalcing = true;
			}
		});
	}

	toggleAll() {
		const enableAll = this.numDisabled > 0;
		const registers = structuredClone(this.allRegisters);
		for (let i = 0; i < registers.length; i++) {
			registers[i].disabled = !enableAll;
		}
		this.registers = registers;
		this.numDisabled = enableAll ? 0 : this.selectableRegisters.length;
		this.checkForDifferences();
	}

	toggleRow(register: RiskRegister) {
		for (const existingRegister of this.selectableRegisters) {
			if (existingRegister.riskId === register.riskId) {
				existingRegister.disabled = !existingRegister.disabled;
			}
		}
		this.registers = this.selectableRegisters;
		this.checkForDifferences();
	}

	resetSelection() {
		const registers = structuredClone(this.allRegisters);
		this.registers = registers || [];
		this.cancelEnabled = false;
		this.recalcEnabled = false;
	}

	saveRegisters() {
		this.recalcing = true;
		const projectData = this._projectDashboardService.$currentProjectData.value;
		if (!projectData) {
			return;
		}
		let unassignedCustomFields = projectData?.preferences?.riskMitigation?.unassignedCustomFields;
		if (unassignedCustomFields === undefined) {
			unassignedCustomFields = {
				riskOwner: {
					name: [],
					email: [],
				},
				costOwner: {
					name: [],
					email: [],
				},
				responsibility: {
					name: [],
					email: [],
				},
				category: [],
			};
		}
		this.restService
			.post('project/riskregister/' + this._projectDashboardService.$currentProjectPageId.value, {
				data: this.allRegisters,
				hiddenColumns: {
					riskPage: Array.from(projectData.preferences?.riskMitigation?.hiddenMitigationColumns?.riskPage || []),
					fullscreen: Array.from(projectData.preferences?.riskMitigation?.hiddenMitigationColumns?.fullscreen || []),
					scheduleSelector: Array.from(
						projectData.preferences?.riskMitigation?.hiddenMitigationColumns?.scheduleSelector || []
					),
				},
				unassignedCustomFields: {
					riskOwner: {
						name: Array.from(unassignedCustomFields?.riskOwner?.name) || [],
						email: Array.from(unassignedCustomFields?.riskOwner?.email) || [],
					},
					costOwner: {
						name: Array.from(unassignedCustomFields?.costOwner?.name) || [],
						email: Array.from(unassignedCustomFields?.costOwner?.email) || [],
					},
					responsibility: {
						name: Array.from(unassignedCustomFields?.responsibility?.name) || [],
						email: Array.from(unassignedCustomFields?.responsibility?.email) || [],
					},
					category: Array.from(unassignedCustomFields?.category) || [],
				},
			})
			.subscribe((resp) => {
				console.log({ resp });
			});
		this.restService.post(`report/calculate/${projectData._id}`, { onlySimulate: true }).subscribe(
			(val) => {
				console.log('POST call successful value returned in body', val);
			},
			(response) => {
				console.log('POST call in error', response);
			},
			() => {
				console.log('The POST observable is now completed.');
			}
		);
	}

	public set registers(registers: RiskRegister[]) {
		this.numDisabled = 0;
		const selectableRegisters: RiskRegister[] = [];
		for (const register of registers) {
			if (register.impactedTaskCodes.length && register.preMitigation.activityImpact > 0 && !register.isDraft) {
				selectableRegisters.push(register);
				if (register.disabled) {
					this.numDisabled++;
				}
			}
		}
		this.selectableRegisters = selectableRegisters;
	}

	/**
	 * updates cancel disabled state if user's selection differs from last successful calculation selection
	 */
	checkForDifferences(): void {
		let areThereDifferences = false;
		this.selectableRegisters.forEach((register) => {
			const matchingRegister = this.allRegisters.find((allR) => allR.riskId === register.riskId);
			if (matchingRegister === undefined || register.disabled !== matchingRegister.disabled) {
				areThereDifferences = true;
			}
		});
		this.cancelEnabled = areThereDifferences;
		this.recalcEnabled = areThereDifferences;
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	round = Math.round;
}
