import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsDashboardService } from '../../../../../services/analytics/analytics.service';
import { GanttService, WbsLevel } from '../../../../../services/project/gantt.service';
import { XerActivityCode, XerActivityType } from '../../../../../models/Update/Task';
import { arrowDownIcon, arrowRightIcon, arrowUpIcon, xCircleIcon, xIcon } from '@progress/kendo-svg-icons';
import { AppWindowService } from '../../../../../services/common/window.service';

export interface GanttActvType {
	actv_code_type_id: number;
	seq_num: number;
	actv_code_type: string;
	proj_id: number | undefined;
	activitiesUnderCode?: number;
	actv_code_type_scope: string;
	scope?: string;
}

@Component({
	selector: 'app-gantt-group-by',
	templateUrl: './gantt-group-by.component.html',
	styleUrls: ['./gantt-group-by.component.scss'],
})
export class GanttGroupByComponent implements OnInit {
	@Input() fromPresetWindow: boolean = false;
	allGroupByOptions: GanttActvType[] = [];
	currentGroupByOptions: GanttActvType[] = [];
	allActivityTypesSelected: boolean = false;
	currentlyDragging: GanttActvType = null;
	isWithin: boolean = false;
	originalSelectedList: GanttActvType[] = [];
	originalSelectedWbsLevel: WbsLevel = { text: 'Please Select', value: 0 };
	originalHideSummaryBars: boolean = true;
	unsavedChanges: boolean = false;
	icons = {
		close: xCircleIcon,
		arrowUp: arrowUpIcon,
		arrowDown: arrowDownIcon,
		arrowRight: arrowRightIcon,
		x: xIcon,
	};
	wbsKeys: string[] = [];
	currentWbsOptions: WbsLevel[] = [];

	constructor(
		public analyticsService: AnalyticsDashboardService,
		public ganttService: GanttService,
		public appWindowService: AppWindowService
	) {
		if (this.ganttService.originalPreset !== null) {
			this.ganttService.radioSelection = this.ganttService.originalPreset.preset.grouping.type;
			this.ganttService.selectedGroupBy = this.ganttService.originalPreset.preset.grouping.actvCodeGrouping;
			const presetLevel: number = this.ganttService.originalPreset.preset.grouping.wbsToLevel;
			this.ganttService.selectedWbsLevel =
				presetLevel === 0 || presetLevel === null || presetLevel === undefined
					? { text: 'Please Select', value: 0 }
					: { text: 'Level ' + presetLevel, value: presetLevel };
		}
	}

	ngOnInit() {
		this.allGroupByOptions = this.getOptions();
		const optionNames: string[] = [];
		const duplicateNames: string[] = [];
		this.allGroupByOptions.forEach((option: GanttActvType) => {
			if (optionNames.includes(option.actv_code_type)) {
				if (!duplicateNames.includes(option.actv_code_type)) {
					duplicateNames.push(option.actv_code_type);
				}
			} else {
				optionNames.push(option.actv_code_type);
			}
		});
		if (duplicateNames?.length) {
			for (let i: number = 0; i < this.allGroupByOptions.length; i++) {
				this.allGroupByOptions[i].scope = duplicateNames.includes(this.allGroupByOptions[i].actv_code_type)
					? this.allGroupByOptions[i].actv_code_type_scope.split('AS_')[1]
					: undefined;
			}
		}
		this.currentGroupByOptions = structuredClone(this.allGroupByOptions).filter(
			(item) => !this.ganttService.selectedGroupBy.some((i) => i.actv_code_type_id === item.actv_code_type_id)
		);
		const newWbsOptions: WbsLevel[] = [];
		const selectedWbs: WbsLevel[] = [];
		for (let i = 1; i <= this.ganttService.highestLevel; i++) {
			const wbsItem: WbsLevel = {
				value: i,
				text: 'Level ' + i,
			};
			if (this.ganttService.selectedWbsLevel.value === 0 || i > this.ganttService.selectedWbsLevel.value) {
				newWbsOptions.push(wbsItem);
			} else {
				selectedWbs.push(wbsItem);
			}
		}
		this.currentWbsOptions = newWbsOptions;
		this.ganttService.selectedWbsLevels = selectedWbs;
		this.originalSelectedList = structuredClone(this.ganttService.selectedGroupBy);
		this.originalSelectedWbsLevel = structuredClone(this.ganttService.selectedWbsLevel);
		this.originalHideSummaryBars = structuredClone(this.ganttService.hideSummaryBars);
		this.ganttService.$selectedWbsLevelChanged.subscribe((val: boolean) => {
			if (!val) {
				this.originalSelectedWbsLevel = structuredClone(this.ganttService.selectedWbsLevel);
			}
			this.testSelectedWbs();
		});
		this.ganttService.$hideSummaryBarsChanged.subscribe((val: boolean) => {
			if (val) {
				if (this.ganttService.radioSelection === 'wbs') {
					this.testSelectedWbs();
				} else {
					this.testSelectedList();
				}
			}
		});
		this.ganttService.$resetOptions.subscribe((val: boolean) => {
			if (val) {
				this.resetOptions();
			}
		});

		if (this.ganttService.appliedReportVals === null) {
			this.ganttService.appliedReportVals = {
				filters: null,
				grouping: {
					type: this.ganttService.radioSelection,
					wbsToLevel: this.ganttService.selectedWbsLevel.value,
					actvCodeGrouping: this.ganttService.selectedGroupBy,
					hideSummaryBars: this.ganttService.hideSummaryBars,
					colors: JSON.stringify(this.ganttService.wbsColorStrings),
				},
				id: null,
				isFavorite: false,
				name: null,
				showingCritical: true,
				showingRelationships: false,
				visibleColumns: null,
			};
		}
	}

	testSelectedWbs(): void {
		this.unsavedChanges =
			JSON.stringify(this.ganttService.selectedWbsLevel) !== JSON.stringify(this.originalSelectedWbsLevel) ||
			this.ganttService.hideSummaryBars !== this.originalHideSummaryBars;
	}

	testSelectedList(): void {
		this.unsavedChanges =
			JSON.stringify(this.ganttService.selectedGroupBy) !== JSON.stringify(this.originalSelectedList) ||
			this.ganttService.hideSummaryBars !== this.originalHideSummaryBars;
	}

	getOptions(): GanttActvType[] {
		return this.ganttService.allActivityTypes
			.map((a: XerActivityType) => ({
				actv_code_type_id: a.actv_code_type_id,
				seq_num: a.seq_num,
				actv_code_type: a.actv_code_type,
				proj_id: a.proj_id,
				activitiesUnderCode: this.getActivityCount(a.actv_code_type_id),
				actv_code_type_scope: a.actv_code_type_scope,
			}))
			.sort((a: GanttActvType, b: GanttActvType) => {
				return a?.activitiesUnderCode === 0
					? 1
					: b?.activitiesUnderCode === 0
						? -1
						: a.seq_num < b.seq_num
							? -1
							: b.seq_num < a.seq_num
								? 1
								: 0;
			});
	}

	getActivityCount(typeId: number): number {
		const codesForType: XerActivityCode[] = this.ganttService.actvCodesByType.get(typeId);
		let countOfActivities: number = 0;
		codesForType.forEach((code: XerActivityCode) => {
			const activitiesCount: number = this.ganttService.activitiesByCode.get(code.actv_code_id)?.length || 0;
			countOfActivities += activitiesCount;
		});
		return countOfActivities || 0;
	}

	radioChoiceChanged(ev, option: 'wbs' | 'actvCodes'): void {
		// const newHeight: number = option === 'wbs' ? 600 : 30 * this.allGroupByOptions?.length + 248;
		// this.appWindowService.windowPosition.ganttGroupBy.height = newHeight;
		// const groupByWindow = document.getElementById('ganttGroupByWindow');
		// groupByWindow.setAttribute('style', 'height:' + newHeight + 'px');
		// setTimeout(() => {
		// 	this.appWindowService.windowPosition.ganttGroupBy.top = (window.innerHeight - newHeight) / 2;
		// 	this.appWindowService.windowPosition.ganttGroupBy.left =
		// 		(window.innerWidth - this.appWindowService.windowPosition.ganttGroupBy.width) / 2;
		// 	this.appWindowService.restrictMovement('ganttGroupBy');
		// }, 500);
	}

	public handleDrop(ev, item: GanttActvType): void {
		const isBelowTarget: boolean = ev?.dropTarget?.getBoundingClientRect()?.top < ev?.dragEvent?.clientY;
		if (this.isWithin) {
			this.ganttService.selectedGroupBy = this.ganttService.selectedGroupBy.filter(
				(i: GanttActvType) => i.actv_code_type_id !== this.currentlyDragging.actv_code_type_id
			);
		}
		const index: number = this.ganttService.selectedGroupBy.findIndex(
			(i: GanttActvType) => i.actv_code_type_id === item.actv_code_type_id
		);
		if (index !== -1) {
			if (
				!this.ganttService.selectedGroupBy.some(
					(i: GanttActvType) => i.actv_code_type_id === this.currentlyDragging.actv_code_type_id
				)
			) {
				this.ganttService.selectedGroupBy.splice(isBelowTarget ? index + 1 : index, 0, this.currentlyDragging);
				this.currentGroupByOptions = this.currentGroupByOptions.filter(
					(i: GanttActvType) => i.actv_code_type_id !== this.currentlyDragging.actv_code_type_id
				);
			}
		}
		this.currentlyDragging = null;
		this.testSelectedList();
	}

	resetOptions(): void {
		this.currentGroupByOptions = structuredClone(this.allGroupByOptions)
			.sort((a: GanttActvType, b: GanttActvType) => {
				return a?.activitiesUnderCode === 0
					? 1
					: b?.activitiesUnderCode === 0
						? -1
						: a.seq_num < b.seq_num
							? -1
							: b.seq_num < a.seq_num
								? 1
								: 0;
			})
			.filter(
				(option: GanttActvType) =>
					this.ganttService.selectedGroupBy.findIndex(
						(o: GanttActvType) => o.actv_code_type_id === option.actv_code_type_id
					) === -1
			);
	}

	resetGroupBy(): void {
		this.ganttService.$groupByXClicked.next(true);
		this.ganttService.radioSelection = null;
		this.resetWbsSelection();
	}

	resetWbsSelection(): void {
		const newWbs: WbsLevel[] = [];
		for (let i = 1; i <= this.ganttService.highestLevel; i++) {
			const wbsItem: WbsLevel = {
				value: i,
				text: 'Level ' + i,
			};
			newWbs.push(wbsItem);
		}
		this.currentWbsOptions = newWbs;
		this.ganttService.selectedWbsLevels = [];
		this.ganttService.selectedWbsLevel = { text: 'Please Select', value: 0 };
	}

	deselectItem(item: GanttActvType, isWbs: boolean = false, wbsLevel: number = -1): void {
		if (isWbs) {
			this.ganttService.selectedWbsLevels = this.ganttService.selectedWbsLevels.filter((w) => w.value < wbsLevel);
			const newWbs: WbsLevel[] = [];
			for (let i = wbsLevel; i <= this.ganttService.highestLevel; i++) {
				const wbsItem: WbsLevel = {
					value: i,
					text: 'Level ' + i,
				};
				newWbs.push(wbsItem);
			}
			this.currentWbsOptions = newWbs;
			this.ganttService.selectedWbsLevel = {
				text: wbsLevel === 1 ? 'Please Select' : 'Level ' + (wbsLevel - 1),
				value: wbsLevel - 1,
			};
		} else {
			this.ganttService.selectedGroupBy = this.ganttService.selectedGroupBy.filter(
				(i: GanttActvType) => i.actv_code_type_id !== item.actv_code_type_id
			);
			this.currentGroupByOptions.push(item);
			this.currentGroupByOptions = this.currentGroupByOptions.sort((a: GanttActvType, b: GanttActvType) => {
				return a?.activitiesUnderCode === 0
					? 1
					: b?.activitiesUnderCode === 0
						? -1
						: a.seq_num < b.seq_num
							? -1
							: b.seq_num < a.seq_num
								? 1
								: 0;
			});
			this.testSelectedList();
		}
	}

	moveItem(item: GanttActvType, direction: 'up' | 'down' | 'in', isWbs: boolean = false, wbsLevel: number = -1): void {
		if (isWbs) {
			const newWbs: WbsLevel[] = [];
			for (let i = 1; i <= wbsLevel; i++) {
				const wbsItem: WbsLevel = {
					value: i,
					text: 'Level ' + i,
				};
				newWbs.push(wbsItem);
			}
			this.ganttService.selectedWbsLevel = { text: 'Level ' + wbsLevel, value: wbsLevel };
			this.ganttService.selectedWbsLevels = newWbs;
			this.currentWbsOptions = this.currentWbsOptions.filter((w) => w.value > wbsLevel);
			this.testSelectedWbs();
		} else {
			if (direction === 'in') {
				this.addToSelectedList(item);
			} else {
				this.doMove(item, direction);
			}
		}
	}

	doMove(item: GanttActvType, direction: 'up' | 'down'): void {
		const currentSelectedGroupBy: GanttActvType[] = structuredClone(this.ganttService.selectedGroupBy);
		const currentIndexOfItem: number = currentSelectedGroupBy.findIndex(
			(i: GanttActvType) => i.actv_code_type_id === item.actv_code_type_id
		);
		if (currentIndexOfItem !== -1) {
			currentSelectedGroupBy.splice(currentIndexOfItem, 1);
			const newIndex: number = direction === 'up' ? currentIndexOfItem - 1 : currentIndexOfItem + 1;
			if (!currentSelectedGroupBy.some((i: GanttActvType) => i.actv_code_type_id === item.actv_code_type_id)) {
				currentSelectedGroupBy.splice(newIndex, 0, item);
				if (currentSelectedGroupBy?.length === this.ganttService.selectedGroupBy?.length) {
					this.ganttService.selectedGroupBy = currentSelectedGroupBy;
				}
			}
		}
		this.currentlyDragging = null;
		this.testSelectedList();
	}

	dropOnPane(ev): void {
		this.ganttService.selectedGroupBy = this.ganttService.selectedGroupBy.filter(
			(i: GanttActvType) => i.actv_code_type_id !== this.currentlyDragging.actv_code_type_id
		);
		this.addToSelectedList(this.currentlyDragging);
	}

	addToSelectedList(item: GanttActvType): void {
		this.ganttService.selectedGroupBy.push(item);
		this.currentGroupByOptions = this.currentGroupByOptions.filter(
			(a: GanttActvType) => a.actv_code_type_id !== item.actv_code_type_id
		);
		this.currentlyDragging = null;
		this.testSelectedList();
	}

	handleDragStart(ev, item: GanttActvType, isWithin: boolean = false): void {
		this.currentlyDragging = item;
		this.isWithin = isWithin;
	}

	public itemDisabled(itemArgs: { dataItem: string; index: number }) {
		return itemArgs.index === 1; // disables level 1
	}

	colorChange(newColor: string, index: number, c): void {
		this.ganttService.tempWbsColorStrings[index] = newColor;
		c.toggle(false);
	}

	moveAll(type: 'wbs' | 'actvCodes'): void {
		if (type === 'wbs') {
			const newWbs: WbsLevel[] = [];
			for (let i = 1; i <= this.ganttService.highestLevel; i++) {
				const wbsItem: WbsLevel = {
					value: i,
					text: 'Level ' + i,
				};
				newWbs.push(wbsItem);
			}
			this.currentWbsOptions = [];
			this.ganttService.selectedWbsLevels = newWbs;
			this.ganttService.selectedWbsLevel = {
				text: 'Level ' + this.ganttService.highestLevel,
				value: this.ganttService.highestLevel,
			};
		} else {
			const copyOfSelected: GanttActvType[] = structuredClone(this.ganttService.selectedGroupBy);
			const copyOfOptions: GanttActvType[] = structuredClone(this.currentGroupByOptions);
			this.ganttService.selectedGroupBy = [...copyOfSelected.values(), ...copyOfOptions.values()];
			this.currentGroupByOptions = [];
		}
	}

	removeAll(type: 'wbs' | 'actvCodes'): void {
		if (type === 'wbs') {
			this.resetWbsSelection();
		} else {
			this.ganttService.selectedGroupBy = [];
			this.resetOptions();
		}
	}

	viewStructure(): void {
		this.ganttService.$structureWindowOpen.next(true);
		this.appWindowService.setViewport('structure');
	}

	isDiffThanCurrent(): boolean {
		if (
			this.ganttService.loading ||
			(this.ganttService.radioSelection === 'wbs' && this.ganttService.selectedWbsLevel.value === 0) ||
			(this.ganttService.radioSelection === 'actvCodes' && this.ganttService.selectedGroupBy?.length === 0)
		) {
			return true;
		}
		let normalizedAppliedWbsLevel: number = this.ganttService.appliedReportVals.grouping.wbsToLevel;
		normalizedAppliedWbsLevel =
			normalizedAppliedWbsLevel === 0 || normalizedAppliedWbsLevel === null
				? this.ganttService.highestLevel
				: normalizedAppliedWbsLevel;
		let groupingChanges: boolean = false;
		if (
			this.ganttService.radioSelection !== this.ganttService.appliedReportVals.grouping.type ||
			(this.ganttService.radioSelection === 'wbs' &&
				(this.ganttService.selectedWbsLevel.value !== normalizedAppliedWbsLevel ||
					(this.ganttService.selectedWbsLevel.value === normalizedAppliedWbsLevel &&
						this.ganttService.appliedReportVals.grouping.wbsToLevel !== normalizedAppliedWbsLevel))) ||
			(this.ganttService.radioSelection === 'actvCodes' &&
				JSON.stringify(this.ganttService.selectedGroupBy) !==
					JSON.stringify(this.ganttService.appliedReportVals.grouping.actvCodeGrouping)) ||
			JSON.stringify(this.ganttService.tempWbsColorStrings) !== this.ganttService.appliedReportVals.grouping.colors ||
			this.ganttService.hideSummaryBars !== this.ganttService.appliedReportVals.grouping.hideSummaryBars
		) {
			groupingChanges = true;
		}
		return !groupingChanges;
	}

	async applyGrouping(): Promise<void> {
		this.ganttService.applyPhysicallyClicked = true;
		if (this.ganttService.$groupByXClicked.value) {
			this.ganttService.softResetGroupValues();
		}
		this.ganttService.radioSelectionDelayed = structuredClone(this.ganttService.radioSelection);
		this.ganttService.wbsColorStrings = structuredClone(this.ganttService.tempWbsColorStrings);
		this.ganttService.beforeEditPresetSelectedGroupBy = structuredClone(this.ganttService.selectedGroupBy);
		this.ganttService.beforeEditPresetGroupingType = structuredClone(this.ganttService.radioSelection);
		this.ganttService.beforeEditPresetSelectedWbsLevel = structuredClone(this.ganttService.selectedWbsLevel);
		this.ganttService.beforeEditPresetHideSummaryBars = structuredClone(this.ganttService.hideSummaryBars);
		this.ganttService.beforeEditPresePuppetLevel = structuredClone(this.ganttService.puppetLevel);
		this.ganttService.applyPhysicallyClicked = false;
		this.ganttService.appliedReportVals = {
			filters: null,
			grouping: {
				type: this.ganttService.radioSelection,
				wbsToLevel: this.ganttService.selectedWbsLevel.value,
				actvCodeGrouping: this.ganttService.selectedGroupBy,
				hideSummaryBars: this.ganttService.hideSummaryBars,
				colors: JSON.stringify(this.ganttService.wbsColorStrings),
			},
			id: null,
			isFavorite: false,
			name: null,
			showingCritical: this.ganttService.showOnlyCritical,
			showingRelationships: this.ganttService.showLines,
			visibleColumns: null,
		};
		this.ganttService.checkResetBtn();
		if (this.ganttService.radioSelection === null) {
			this.ganttService.resetGroups();
		} else if (this.ganttService.radioSelection === 'wbs') {
			await this.ganttService.applyWbsGrouping();
			const test: number =
				this.ganttService.selectedWbsLevel?.value === null
					? this.ganttService.highestLevel
					: this.ganttService.selectedWbsLevel?.value;
			this.ganttService.puppetLevel = [];
			for (let i: number = 0; i < test; i++) {
				this.ganttService.puppetLevel.push(i);
			}
		} else {
			await this.ganttService.applyGroupBy();
		}
		this.ganttService.applyGroupBySort();
	}
}
