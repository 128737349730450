import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AxisSettings, SeriesData, SeriesDataSettings } from '../../../../../models/ChartSettings';
import { PlotBand } from '@progress/kendo-angular-charts';
import { hasObjChanged } from '../../../../../util/projects';
import { CurrentProjectReport, ProjectDashboardService } from '../../../../../services/project/project.service';
import { CriticalPathReliability } from '@rhinoworks/analytics-calculations/dist/models/report/ProjectReport';
import { RestService } from '../../../../../services/common/rest.service';
import { ScheduleStorageService } from '../../../../../services/project/schedule-storage.service';
import * as ExcelJS from 'exceljs';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';
import { UpdateInterface } from '../../../../../models/Update/Task';

@Component({
	selector: 'app-critical-path-reliability',
	templateUrl: './critical-path-reliability.component.html',
	styleUrls: ['./critical-path-reliability.component.scss'],
})
export class CriticalPathReliabilityComponent implements OnInit, OnDestroy {
	@Input() isOverview: boolean = false;
	@Input() criticalPathReliability: CriticalPathReliability;
	@Input() isFocus: boolean = false;
	@Input() focusCode: string;
	@Input() needRecalc = false;
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	$overallScore = new Subject<number>();
	$consistencyScore = new Subject<number>();
	$baseScore = new Subject<number>();
	$updateScore = new Subject<number>();
	categories: string[] = [];
	chartData: SeriesDataSettings[] = [];
	public valuePlotBands: PlotBand[] = [
		{
			from: 0,
			to: 70,
			color: '#DF5353',
			opacity: 0.2,
		},
		{
			from: 70,
			to: 85,
			color: '#4fc931',
			opacity: 0.2,
		},
		{
			from: 85,
			to: 100,
			color: '#0059FF',
			opacity: 0.2,
		},
	];
	valueAxisItemSettings: AxisSettings[] = [
		{
			title: {
				text: '% of Activities',
				visible: false,
			},
			labels: {
				format: '{0}%',
			},
			plotBands: this.valuePlotBands,
			min: 0,
			max: 100,
			majorGridLines: {
				visible: false,
			},
		},
	];
	hasNotes: boolean = false;
	doingRecalc = false;
	fetchingSheets = false;

	constructor(
		public projectService: ProjectDashboardService,
		private restService: RestService,
		private storageService: ScheduleStorageService
	) {}

	ngOnInit(): void {
		this.projectService.$currentProjectReport
			.pipe(takeUntil(this._unsubscribeAll), debounceTime(100))
			.subscribe((report) => {
				if (report) {
					this.updateValues(report, this.criticalPathReliability);
				}
			});
		this.storageService.$allUpdates.subscribe((val: UpdateInterface[]) => {
			// needs all updates loaded to be able to tell if there have been rebaselines
			if (val) {
				this.updateValues(this.projectService.$currentProjectReport.value, this.criticalPathReliability);
			}
		});
		this.projectService.$currentProjectData.subscribe((val) => {
			if (val) {
				const savedNotes = val.componentNotes?.find((n) => n.id === 22)?.notes;
				this.hasNotes = savedNotes?.length && savedNotes[savedNotes?.length - 1]?.note !== '';
			}
		});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	/**
	 * updates 4 square and chart values with the latest ones from the given project report
	 * @param report
	 * @param criticalPathReliability
	 */
	updateValues(report: CurrentProjectReport, criticalPathReliability?: CriticalPathReliability): void {
		let chartData = [];
		const categories = [];
		criticalPathReliability ||= report?.riskPage?.criticalPathReliability;
		if (!criticalPathReliability) {
			return;
		}
		const activityConsistency = criticalPathReliability.activityConsistencyScore;
		this.$consistencyScore.next(activityConsistency);

		const baseLogic = criticalPathReliability.baseLogicScore;
		this.$baseScore.next(baseLogic);

		const updateLogic = criticalPathReliability.updateLogicScore;
		this.$updateScore.next(updateLogic);

		const overallScore = criticalPathReliability.overallScore;
		this.$overallScore.next(overallScore);
		const x = criticalPathReliability?.overallScoreTrend;

		const overallScores: SeriesData[] = [];
		const consistencyScores: SeriesData[] = [];
		const baseLogicScores: SeriesData[] = [];
		const updateLogicScores: SeriesData[] = [];
		if (x) {
			for (let i = 0; i < x.length; i++) {
				const dataDateFromUpdate: Date = new Date(
					this.storageService.$allUpdates.value[i]?.dataDate ||
						report?.projectCompletionTrend?.projectCompletionTrendArray?.[i]?.dataDate
				);
				const dateString: string = dataDateFromUpdate ? format(dataDateFromUpdate, 'MMM dd, yyyy') : '';
				const updateName: string = i === 0 ? 'Baseline' : `Update ${i}`;
				const category: string =
					(dateString === '' ? updateName : updateName + '\n' + dateString) +
					(this.storageService.$allUpdates.value[i]?.baseline ? ' ®' : '');
				overallScores.push({
					category,
					value: x[i].overallScore,
				});
				consistencyScores.push({
					category,
					value: x[i].activityConsistencyScore,
				});
				baseLogicScores.push({
					category,
					value: x[i].baseLogicScore,
				});
				updateLogicScores.push({
					category,
					value: x[i].updateLogicScore,
				});
				categories.push(category);
				chartData = [
					{
						data: baseLogicScores,
						type: 'line',
						name: 'Base Logic Score',
						visible: true,
						color: '#00000040',
						marker: 'square',
					},
					{
						data: updateLogicScores,
						type: 'line',
						name: 'Update Logic Score',
						visible: true,
						color: '#00000040',
						marker: 'triangle',
					},
					{
						data: consistencyScores,
						type: 'line',
						name: 'Activity Consistency Score',
						visible: true,
						color: '#00000040',
						marker: 'roundedRect',
					},
					{
						data: overallScores,
						type: 'line',
						name: 'Overall Score',
						visible: true,
						color: 'black',
						marker: 'circle',
					},
				];
			}
			if (hasObjChanged(categories, this.categories)) {
				this.categories = categories;
			}
			if (hasObjChanged(chartData, this.chartData)) {
				this.chartData = chartData;
			}
		}
	}

	refreshCalculations() {
		this.restService
			.post(`report/calculate/${this.projectService.$currentProjectReport.value?.project?._id}`, {})
			.subscribe(
				(val) => {
					this.doingRecalc = false;
					this.storageService.$manualIdUpdate.next(this.projectService.$currentProjectReport.value?.project?._id);
				},
				(response) => {
					console.log('POST call in error', response);
					this.doingRecalc = false;
				},
				() => {}
			);
	}

	grabExport() {
		this.fetchingSheets = true;
		this.restService
			.fetch(`report/sheets/${this.projectService.$currentProjectData.value._id}/${this.focusCode || ''}`)
			.subscribe(
				async (val) => {
					console.log({ val });
					const workbook = new ExcelJS.Workbook();
					const sheets: Record<
						string,
						{ sheetName: string; columns: string[]; data: Record<string, string | number>[] }
					> = val.sheets;
					for (const sheet of Object.values(sheets)) {
						if (sheet.sheetName.endsWith('Path') || sheet.sheetName.includes('BEI')) {
							continue;
						}
						const worksheet = workbook.addWorksheet(sheet.sheetName);

						// Define worksheet columns. You can adjust width as needed.
						worksheet.columns = sheet.columns.map((col) => ({
							header: col,
							key: col,
							width: 20,
						}));

						// Add each row to the worksheet
						sheet.data.forEach((row) => {
							if (row.Score && ['Critical Path Risk', 'CPLI', 'BEI'].includes(row.Score.toString())) {
								return;
							}
							worksheet.addRow(row);
						});
					}

					// Write the workbook to a buffer
					const buffer = await workbook.xlsx.writeBuffer();

					// Create a Blob from the buffer
					const fileBlob = new Blob([buffer], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					});

					const updateName =
						this.projectService.$currentProjectData.value.updateIds.length === 1
							? 'Baseline'
							: `Update ${this.projectService.$currentProjectData.value.updateIds.length - 1}`;
					saveAs(fileBlob, `${updateName}_${this.focusCode?.replaceAll('.', '') || 'Overall'}_Reliability_Report.xlsx`);
				},
				(response) => {
					console.log('POST call in error', response);
					this.needRecalc = true;
				},
				() => {
					this.fetchingSheets = false;
				}
			);
	}
}
