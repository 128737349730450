import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RestService } from '../common/rest.service';
import { SlimmedTaskCommon, UpdateInterface } from '../../models/Update/Task';
import { EditedUpdate } from '../../components/project-page/schedule-updates-list/schedule-updates-list.component';
import {
	Activity,
	Calendar,
	CalendarVariableAliases,
	IActivityCode,
	ICalendar,
	Xer,
	XerActivity,
	XerActivityCode,
	XerActivityType,
	XerCalendar,
	XerData,
} from '@rhinoworks/xer-parse';
import { ProjectInterface } from '../../models/Project';
import { GroupResult } from '@progress/kendo-data-query';
import Papa from 'papaparse';
import { buildEntryFromVariables, convertObjFromStr, dataFromXer } from '../../util/tasks';
import {
	ActvCodeFilterItem,
	SubCodeFilterItem,
} from '../../components/project-page/overview/activity-completion/activity-completion.component';
import { ActivityCode } from './update/pre-test-models';

@Injectable({
	providedIn: 'root',
})
export class ScheduleStorageService {
	isLoading = true;
	public $allUpdates = new BehaviorSubject<UpdateInterface[]>([]);
	public numUpdatedLoaded = new BehaviorSubject<number>(0);
	public unsavedChanges = new Map<string, Set<string>>([]);
	public $modifiedUpdates = new BehaviorSubject<Array<EditedUpdate>>([]);
	public updateMap = new Map<string, UpdateInterface>([]);
	public $projectData = new BehaviorSubject<ProjectInterface & { updateLockNotes?: boolean }>(undefined);
	private $currentProjectId = new BehaviorSubject<string>('');
	public $manualIdUpdate = new BehaviorSubject<string>('');
	public $clearCostBlHistory = new BehaviorSubject<boolean>(false);
	public $softAddIsCostBl = new BehaviorSubject<boolean>(false);
	doingSave = false;
	unsavedRiskRegisterFormChanges = false;
	additionalMilestones:
		| GroupResult[]
		| {
				text: string;
				value:
					| XerActivity
					| SlimmedTaskCommon
					| { task_name: string; task_code: string; deleted: boolean; proj_id: number };
				earlyFinish: Date;
				category: string;
		  }[];
	costBaselineChangesAreRemove: boolean = false;
	$costResponse = new BehaviorSubject<boolean>(null);
	public cachedXerData = new Map<string, XerData>([]);
	public cachedXers = new Map<string, Xer>([]);
	public cachedXerTables = new Map<string, Map<string, never[]>>([]);
	public cachedXerTableIndices = new Map<string, Map<string, [number, number]>>([]);
	public cachedCalendars = new Map<string, Map<number, Calendar>>([]);

	constructor(private restService: RestService) {
		this.$allUpdates.subscribe((updates) => {
			console.log('updates nexted', updates);
			const projectData = this.$projectData.value;
			if (
				projectData &&
				updates.length === projectData.updateIds?.length &&
				!updates.find((update) => !projectData?.updateIds.includes(update._id))
			) {
				this.isLoading = false;
			}
		});
		this.$projectData.subscribe(async (data) => {
			if (!data?._id || data.updateLock) {
				return;
			}
			this.$currentProjectId.next(data._id);
			this.isLoading = true;
			const numUpdates = data?.updateIds?.length || 0;
			this.$allUpdates.next([]);

			this.updateMap.clear();
			if (numUpdates === 0) {
				this.isLoading = false;
				return;
			}
		});
		this.$modifiedUpdates.subscribe((updates) => {
			for (const update of updates) {
				const changes = this.unsavedChanges.get(update._id) || new Set<string>([]);
				if (update.toDelete) {
					changes.clear();
					changes.add('delete');
				} else {
					changes.delete('delete');
					if (update.toAdd) {
						changes.clear();
						changes.add('add');
					} else {
						changes.delete('add');
						if (update.toBaseline || update.unBaseline) {
							changes.add('baseline');
						} else {
							changes.delete('baseline');
						}
					}
					const oldUpdate = this.$allUpdates.value.find((u) => u._id === update._id);
					if (
						(update?.criticalPathNotes !== oldUpdate?.criticalPathNotes && update?.criticalPathNotes !== '') ||
						(update?.timeAnalysisNotes !== oldUpdate?.timeAnalysisNotes && update?.timeAnalysisNotes !== '')
					) {
						changes.add('notes');
					}
				}
				if (changes.size) {
					this.unsavedChanges.set(update._id, changes);
				} else {
					this.unsavedChanges.delete(update._id);
				}
			}
		});
	}

	clearAllData() {
		this.unsavedChanges.clear();
		this.$allUpdates.next([]);
		this.$projectData.next(undefined);
		this.$modifiedUpdates.next([]);
	}

	async saveAndCalculate() {
		this.doingSave = true;
		const addedUpdates = [...this.unsavedChanges.values()].some((changes) => changes.has('add'));
		const removedUpdates = this.$modifiedUpdates.value.some((update) => update.toDelete);
		const milestoneChanges = new Set<string>(
			[...this.unsavedChanges.entries()]
				.filter(([id, changes]) => changes.has('finishMilestone'))
				.map(([id, changes]) => id)
		);
		const baselineChanges = new Set<string>(
			[...this.unsavedChanges.entries()].filter(([id, changes]) => changes.has('baseline')).map(([id, changes]) => id)
		);
		const costBaselineChanges = new Set<string>(
			[...this.unsavedChanges.entries()]
				.filter(([id, changes]) => changes.has('costBaseline'))
				.map(([id, changes]) => id)
		);
		const updates = [];
		for (let i = 0; i < this.$modifiedUpdates.value.length; i++) {
			if (!this.$modifiedUpdates.value[i].toDelete) {
				updates.push(this.$modifiedUpdates.value[i]._id);
				this.updateMap.set(this.$modifiedUpdates.value[i]._id, this.$modifiedUpdates.value[i]);
			}
		}
		for (const [updateId, changes] of this.unsavedChanges) {
			if (
				this.updateMap.has(updateId) &&
				(changes.has('notes') || changes.has('baseline') || changes.has('finishMilestone'))
			) {
				await this.saveNotes(this.updateMap.get(updateId));
			}
		}
		const projectId = this.$allUpdates.value?.[0]?.projectId || updates[0]?.projectId;
		if (!projectId) {
			return;
		}
		this.unsavedChanges.clear();
		if (costBaselineChanges.size) {
			let costBaseline: string = undefined;
			for (const item of costBaselineChanges.values()) {
				if (!this.costBaselineChangesAreRemove) {
					costBaseline = item;
				}
			}
			this.restService
				.post(`project/${projectId}/setCostBaseline`, { costBaseline: costBaseline || undefined })
				.subscribe((res) => {
					this.costBaselineChangesAreRemove = false;
					this.$clearCostBlHistory.next(true);
				});
		}
		if (!addedUpdates && !removedUpdates) {
			if (milestoneChanges.size || baselineChanges.size) {
				this.restService.post(`report/calculate/${projectId}`, {}).subscribe((res) => {
					console.log(res);
					this.$manualIdUpdate.next(projectId);
				});
			}
			this.doingSave = false;
			return;
		}
		this.unsavedChanges.clear();
		return this.restService.post(`updatelistchange/${projectId}`, { updateIds: updates }).subscribe(
			(response) => {
				this.doingSave = false;
				this.$manualIdUpdate.next(projectId);
			},
			(error) => {
				console.log('error', error);
				this.doingSave = false;
			},
			() => {}
		);
	}

	// !!THIS SHOULD BE USED FOR READONLY - DO NOT MODIFY THE DATA!!
	async grabUpdateXer(updateId: string): Promise<Xer> {
		if (!updateId) {
			return undefined;
		}
		if (this.cachedXers.has(updateId)) {
			return this.cachedXers.get(updateId);
		}
		const xerData = await this.grabUpdateXerData(updateId);
		const xer = new Xer(xerData);
		this.cachedXers.set(updateId, xer);
		return xer;
	}

	async grabUpdateXerData(updateId: string, skipCache?: boolean, returnRaw?: boolean): Promise<XerData> {
		if (!updateId) {
			return undefined;
		}
		return new Promise<XerData>((resolve, reject) => {
			if (this.cachedXerData.has(updateId) && !skipCache) {
				return resolve(this.cachedXerData.get(updateId));
			}
			this.restService
				.fetch('update/' + updateId + '/download', undefined, undefined, undefined, true)
				.subscribe((resp) => {
					const reader = new FileReader();
					reader.onload = (event) => {
						try {
							const datas = Papa.parse(event.target.result.toString(), { quoteChar: '""' })?.data as XerData;
							if (!skipCache) {
								this.cachedXerData.set(updateId, datas);
								if (!this.cachedXerTables.has(updateId)) {
									this.cachedXerTables.set(updateId, new Map<string, never[]>());
								}
							}
							console.log('retrieved xer for ', updateId);
							resolve(datas);
						} catch (error) {
							console.error('Error parsing JSON:', error);
						}
					};
					reader.onerror = (error) => {
						console.error('Error reading Blob:', error);
						reject(error);
					};
					reader.readAsText(resp);
				});
		});
	}

	async grabUpdateTable<T>(updateId: string, table: string): Promise<T[]> {
		if (!updateId || !table) {
			return [];
		}
		if (this.cachedXerTables.get(updateId)?.has(table)) {
			return this.cachedXerTables.get(updateId).get(table) as T[];
		}
		const xerData = await this.grabUpdateXerData(updateId);
		if (!xerData) {
			return [];
		}
		const results = dataFromXer<T>(xerData, table, this.cachedXerTableIndices.get(updateId)?.get(table)?.[0]);
		let data: T[] = results.data;
		const existingUpdateIndices = this.cachedXerTableIndices.get(updateId) || new Map<string, [number, number]>([]);
		existingUpdateIndices.set(table, [results.startIndex, results.endIndex]);
		this.cachedXerTableIndices.set(updateId, existingUpdateIndices);
		if (table === 'TASK') {
			const taskData = data as XerActivity[];
			const hasCrit = taskData.some((task) => task.driving_path_flag === 'Y');
			if (!hasCrit) {
				const xer = new Xer(xerData);
				xer.reschedule();
				data = Array.from(xer.activitiesMap.values()).map((task) => convertObjFromStr(task.raw_entry) as T);
			}
		}
		if (!this.cachedXerTables.has(updateId)) {
			this.cachedXerTables.set(updateId, new Map<string, never[]>());
		}
		this.cachedXerTables.get(updateId).set(table, data as never[]);
		return data as T[];
	}

	async grabCalendar(updateId: string, id?: number): Promise<Calendar> {
		if (this.cachedCalendars.has(updateId)) {
			return (
				this.cachedCalendars.get(updateId).get(id) ||
				Array.from(this.cachedCalendars.get(updateId).values()).find(
					(cal) => cal.calendar.raw_entry.default_flag === 'Y'
				)
			);
		}
		const currCalendars = await this.grabUpdateTable<XerCalendar>(updateId, 'CALENDAR');

		const iCalendars: Calendar[] = [];
		for (const cal of currCalendars) {
			let calendarInterface = {} as ICalendar;
			calendarInterface = buildEntryFromVariables(
				calendarInterface,
				[],
				CalendarVariableAliases,
				Array.from(CalendarVariableAliases.keys()),
				cal
			) as ICalendar;
			iCalendars.push(new Calendar(calendarInterface));
		}
		this.cachedCalendars.set(updateId, new Map<number, Calendar>(iCalendars.map((cal) => [cal.calendar.id, cal])));
		return this.cachedCalendars.get(updateId).get(id);
	}

	resetUpdates() {
		this.$modifiedUpdates.next(this.$allUpdates.value);
		this.unsavedChanges.clear();
	}

	async saveNotes(update: UpdateInterface) {
		return this.restService.post(`update/${update._id}/notes`, { update }).subscribe(
			(val) => {
				const newUpdates = structuredClone(this.$allUpdates.value);
				const matchingUpdateIndex = newUpdates.findIndex((up) => up._id === update._id);
				if (matchingUpdateIndex !== -1) {
					newUpdates[matchingUpdateIndex] = {
						...update,
						...val.update,
					};
				}
				this.$allUpdates.next(newUpdates);
				if (this.$projectData.value === undefined) {
					this.$projectData.next({
						performanceTrendingSelectedCodeType: '',
						performanceTrendingSelectedActivityCodes: [],
						performanceTrendingSelectedWbs: [],
						_id: '',
						currentProjectReport: '',
						pocId: 0,
						projectLead: '',
						projectReport: [],
						region: '',
						updateLockNotes: true,
						updateIds: [],
					});
				} else {
					this.$projectData.value.updateLockNotes = true;
				}
			},
			(response) => {
				console.log('POST call in error', response);
			},
			() => {
				setTimeout(() => {
					this.$projectData.value.updateLockNotes = false;
					delete this.$projectData.value.updateLockNotes;
				}, 5000);
			}
		);
	}

	unsavedUpdateIds(): Iterable<string> {
		return this.unsavedChanges.keys();
	}

	unsavedChangesDisplay(): Map<string, string> {
		const unsavedChangesDisplayMap = new Map<string, string>([]);
		for (const [updateId, changes] of this.unsavedChanges) {
			unsavedChangesDisplayMap.set(
				updateId,
				changes.has('add')
					? 'add'
					: changes.has('delete')
						? 'delete'
						: changes.has('baseline')
							? 'baseline'
							: changes.has('costBaseline')
								? 'costBaseline'
								: changes.has('finishMilestone')
									? 'finishMilestone'
									: 'notes'
			);
		}
		return unsavedChangesDisplayMap;
	}

	/**
	 * generate activity code/subcode items for the multiselect filter
	 * @param xer
	 * @param selectedActivityCodes
	 */
	generateActivityCodeFilter(xer: Xer, selectedActivityCodes: SubCodeFilterItem[] = []): ActvCodeFilterItem[] {
		selectedActivityCodes = selectedActivityCodes.filter((a) => a);
		const allActivityCodes: ActvCodeFilterItem[] = [];
		Array.from(xer.activityTypes.values()).forEach((actvType) => {
			const code: ActvCodeFilterItem = {
				alwaysDisabled: false,
				disabled:
					selectedActivityCodes?.length === 0
						? false
						: !selectedActivityCodes.some(
								(selActvCode) =>
									selActvCode.shortName === actvType.codeType ||
									(selActvCode?.parentName === actvType.codeType && selActvCode?.parentName !== undefined)
							),
				id: actvType.id,
				name: actvType.codeType.toString(),
				sequenceNumber: Number(actvType.sequenceNumber),
				shortName: actvType.codeType,
				codeName: '',
				subCodes: [],
				totalAssignments: 0,
				parentId: actvType.id,
			};
			allActivityCodes.push(code);
		});
		const tasksByActvCode: Map<number, Set<Activity>> = xer.tasksByActivityCodes;
		Array.from(xer.activityCodes.values())?.forEach((actvCode) => {
			const parent: ActvCodeFilterItem = allActivityCodes.find(
				(code: ActvCodeFilterItem) => code.id === actvCode.typeId
			);
			if (parent !== undefined) {
				const subCode: SubCodeFilterItem = {
					alwaysDisabled: false,
					disabled: false,
					id: actvCode.id,
					name: actvCode?.codeName?.toString() || actvCode?.shortName?.toString(),
					parentName: parent.name,
					parentId: parent.id,
					sequenceNumber: actvCode.sequenceNumber,
					shortName: actvCode.shortName,
					totalAssignments: actvCode.numAssignments || 0,
					codeName: actvCode.codeName,
				};
				actvCode.numAssignments = tasksByActvCode.get(actvCode.id)?.size;
				if (actvCode.numAssignments > 0) {
					parent.subCodes.push(subCode);
					parent.totalAssignments += actvCode.numAssignments;
				}
			}
		});
		return allActivityCodes.filter((code: ActvCodeFilterItem) => code.totalAssignments > 0);
	}
}
