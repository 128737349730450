import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	TemplateRef,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AnalyticsDashboardService, PortfolioProject } from 'services/analytics/analytics.service';
import { ProjectDashboardService } from '../../../../services/project/project.service';
import {
	isQuickViewOption,
	MultiselectDropdownClosePreset,
	ProjectInterface,
	QuickViewFilters,
	QuickViewMapping,
	QuickViewOption,
	SCHEDULE_TYPE,
	ScheduleType,
} from '../../../../models/Project';
import {
	CellClickEvent,
	ExcelExportEvent,
	GridComponent,
	GridDataResult,
	PageChangeEvent,
	RowArgs,
} from '@progress/kendo-angular-grid';
import {
	CompositeFilterDescriptor,
	FilterOperator,
	groupBy,
	GroupResult,
	process,
	SortDescriptor,
} from '@progress/kendo-data-query';
import { CashFlow, ProjectReportInterface } from '../../../../models/ProjectReport/ProjectReport';
import { AxisLabelContentArgs, PlotBand } from '@progress/kendo-angular-charts';
import { formatExcelExport } from '../../../../util/excel';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { cleanDateUTC } from '../../../../util/pipes/date.pipe';
import {
	compareFilters,
	getFiltersFromValToRef,
	NamedFilterDescriptor,
	ProjectFieldFilter,
	ProjectsFilter,
} from '../../../../util/kendo';
import { UserService } from '../../../../services/common/user.service';
import { RiskRegister } from '../../../../models/risk';
import { RestService } from '../../../../services/common/rest.service';
import { LOCAL_STORAGE } from '../../portfolio.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MultiSelectComponent, MultiSelectTreeComponent } from '@progress/kendo-angular-dropdowns';
import { PopupRef, PopupService } from '@progress/kendo-angular-popup';
import { AppWindowService } from '../../../../services/common/window.service';
import { WindowRef, WindowService } from '@progress/kendo-angular-dialog';
import { caretAltDownIcon, xCircleIcon } from '@progress/kendo-svg-icons';
import { NavigationBarStorageService } from '../../../../services/common/navigation-bar-storage.service';
import { PortfolioPreset } from '../../../../models/auth/account-user';

export interface ProjectListItem extends PortfolioProject {
	contractCompletion?: Date;
	currentCompletion?: Date;
	deltaVsCcd?: number;
	projectScore?: number;
	aegisScore?: number;
	predictabilityScore?: number;
	progressScore?: number;
	qcScore?: number;
	updatesPerProject?: number;
	lastUpdatedDate?: Date;
	missingUpdate?: boolean;
	scheduleType?: ScheduleType;
	dUpload?: number;
	companyName?: string;
	clientName?: string;
	prevVariance?: number;
	cumulativeVariance?: number;
	totalActivities?: number;
	avgPreMitigationRiskScore?: number;
	avgPostMitigationRiskScore?: number;
	avgMitigationRiskScoreDelta?: number;
	likelihoodImpact?: number;
	activeRisks?: number;
	monteCarloShowing?: boolean;
	pocName?: string;
	scoreAltered?: boolean;
	market?: string;
	projectStart?: Date;
	highDuration?: number;
	highFloat?: number;
	actualsPastDD?: number;
	ssFFWithLags?: number;
	softConstraints?: number;
	missingLogic?: number;
	negativeLags?: number;
	fsWithLags?: number;
	hardConstraints?: number;
	outOfSequence?: number;
	cashFlow?: CashFlow[];
	costLoaded?: boolean;
	spi?: number;
	spiCumulative?: number;
	actualCost?: number;
	plannedCost?: number;
	actualCostCumulative?: number;
	remainingCost?: number;
	nextPeriodPlanned?: number;
	openStartFinish?: number;
	sfRelationships?: number;
	costBaseline?: string;
	currentBudget?: number;
	currentRemainingCost?: number;
}

export const scheduleTypeList: Array<FilterItem> = SCHEDULE_TYPE.map((schedType) => ({
	text: schedType,
	value: {
		text: schedType,
		field: 'scheduleType',
		operator: FilterOperator.EqualTo,
		value: schedType,
	},
}));

export interface FilterUpdated {
	projects: ProjectListItem[];
	viewChange: boolean;
}

export type FilterItem = {
	text: string;
	value: NamedFilterDescriptor;
	children?: FilterItem[];
	companyName?: string;
	id?: number | string;
};

export const allGroups = require('./project-list-columns.json') as {
	groups: ProjectListColumnGroup[];
};

export type ProjectListColumnGroup = {
	title: string;
	columns: ProjectListColumn[];
};

export type ProjectListColumn = {
	field: string;
	title: string;
	excelTitle: string;
	width?: number;
	defaultWidth?: number;
	group?: string;
	default: boolean;
	alwaysVisible: boolean;
	dataType?: string;
	isCentered?: boolean;
	visibleInSaasRisk?: boolean;
	isSticky?: boolean;
	sequence?: number;
	autoWidth?: boolean;
	// required?: boolean;
	// editable?: boolean;
};

@Component({
	selector: 'projects-project-list',
	templateUrl: './project-list.component.html',
	styleUrls: ['./project-list.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: [
		trigger('detailExpand', [
			state('collapsed, void', style({ height: '0', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
			transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	],
})
export class ProjectListComponent implements OnInit, OnChanges, OnDestroy {
	@Input() public $projects: BehaviorSubject<Array<ProjectListItem>>;
	@Input() public $filteredProjects: BehaviorSubject<Array<ProjectListItem>>;
	@Input() ifilters: ProjectsFilter;
	@Input() resetFilterEvent: Observable<void>;
	@Output() changeFilter: EventEmitter<ProjectFieldFilter[]> = new EventEmitter<ProjectFieldFilter[]>();
	@Output() resetFiltersClicked = new EventEmitter<any>();
	@Input() clickedOnMapMarker = new EventEmitter<void>();
	@Input() currentView: QuickViewOption;
	@Input() $checkForResetBtnDisabled: BehaviorSubject<boolean>;
	@Output() selectProject = new EventEmitter<ProjectInterface>();
	@Output() deselectProject = new EventEmitter<boolean>();
	@Output() projectSelectedStateChange = new EventEmitter<boolean>();
	@Output() removeQuickView = new EventEmitter<boolean>();
	@Output() projectsFiltered = new EventEmitter<boolean>();
	@Output() currentFiltersChanged = new EventEmitter<Record<string, FilterItem[]>>();
	@Output() doneLoading = new EventEmitter<boolean>();
	@Output() currentlyDisplayedProjects = new EventEmitter<Array<any>>();
	@ViewChild('companyMultiselect', { static: true }) public companyMultiselect: MultiSelectTreeComponent;
	multiselectOptions: string[] = [
		'clientIdMultiselect',
		'scheduleTypeMultiselect',
		'riskMetricsTypeMultiselect',
		'projectTypeMultiselect',
		'pocIdMultiselect',
		'deltaVsCcdMultiselect',
		'prevVarianceMultiselect',
	];
	@ViewChild('clientIdMultiselect') clientIdMultiselect: MultiSelectComponent;
	@ViewChild('scheduleTypeMultiselect') scheduleTypeMultiselect: MultiSelectComponent;
	@ViewChild('rmtMultiselect') riskMetricsTypeMultiselect: MultiSelectComponent;
	@ViewChild('projectTypeMultiselect') projectTypeMultiselect: MultiSelectComponent;
	@ViewChild('pocIdMultiselect') pocIdMultiselect: MultiSelectComponent;
	@ViewChild('deltaVsCcdMultiselect') deltaVsCcdMultiselect: MultiSelectComponent;
	@ViewChild('prevVarMultiselect') prevVarianceMultiselect: MultiSelectComponent;
	@Input() isProjectsGridMaximized: boolean = false;
	@Input() $filtersOpened = new BehaviorSubject<ElementRef | HTMLElement>(undefined);
	@Input() $columnSelectorOpened = new BehaviorSubject<ElementRef | HTMLElement>(undefined);
	@ViewChild('columnSelectorPopupTemplate') columnSelectorTemplateRef: TemplateRef<any>;
	@ViewChild('filterWindowContentTemplate') filterWindowContentTemplateRef: TemplateRef<any>;
	@Output() columnSelectorPopupState = new EventEmitter<boolean>();
	@Output() resetStateChanged = new EventEmitter<boolean>();
	@Output() searchStateChanged = new EventEmitter<string>();
	@Input() resetClickedPortfolio = new BehaviorSubject<boolean>(false);
	@Output() columnSelectorSelectionChange = new EventEmitter<{
		visible: string[];
		hidden: string[];
		all: ProjectListColumn[];
	}>();
	@Output() sortChangeProjectList = new EventEmitter<SortDescriptor[]>();
	@Input() $portfolioPresetApplied = new BehaviorSubject<PortfolioPreset>(null);
	@Output() doFilterEmit = new EventEmitter<boolean>(false);
	scheduleTypeList = scheduleTypeList;

	public icons = {
		caretDown: caretAltDownIcon,
		closeCircle: xCircleIcon,
	};

	$numUniqueFilters = new BehaviorSubject<number>(0);
	filterValuesByField: Record<string, FilterItem[]> = {};
	allGroups = allGroups;
	_visibleColumns = [];
	visibleColumns: ProjectListColumn[] = [];
	public defaultHiddenColumns = new Set<string>([]);
	public defaultVisibleColumns = new Set<ProjectListColumn>([]);
	public columnToTreeViewCoord = new Map<string, string>([]);
	allColumns = new Map<string, ProjectListColumn>([]);
	public hiddenColumnsTitles: string[] = [];
	public visibleColumnsTitles: string[] = [];
	showGrid = true;

	public valuePlotBands: PlotBand[] = [
		{
			from: -999999,
			to: 0,
			color: '#DF5353',
			opacity: 0.2,
		},
		{
			from: 0,
			to: 999999,
			color: '#4fc931',
			opacity: 0.2,
		},
	];

	multiselectDropdowns = MultiselectDropdownClosePreset;
	projects: Array<any> = [];
	gridData: Array<any> = [];
	projectCompletionSubtitleName;
	projectVarianceSeries: Array<{
		dataDate: Date;
		completionVariance: number;
		updateName: string;
	}> = [];
	canUpdateLocalStorage = false;
	searchTerm = '';
	resetDisabled = true;
	$avgRiskAssessmentScore = new BehaviorSubject<number>(null);
	$avgPreMitigationScore = new BehaviorSubject<number>(null);
	$avgPostMitigationScore = new BehaviorSubject<number>(null);
	$selectedProjectRiskMitigations = new BehaviorSubject<RiskRegister[]>([]);
	lastFilterChangeWasViewChange = true;
	public expandedKeys: any[] = ['0'];

	public sort: SortDescriptor[] = [
		{
			field: 'name',
			dir: 'asc',
		},
	];
	@Input() projectTypeList: Array<FilterItem> = [];
	@Input() filteredProjectTypeList: Array<FilterItem> = [];
	quickProjectStatusProject: ProjectListItem;

	@Input() completionStatus: Array<FilterItem> = [];

	@Input() pastPeriodPerformanceList: Array<FilterItem> = [];

	@Input() uploadList: Array<FilterItem> = [];

	@Input() clientList: Array<FilterItem>;
	@Input() nestedClientList: GroupResult[];
	@Input() filteredClientList: Array<FilterItem>;

	@Input() nestedCompanyList: Array<FilterItem>;
	@Input() companyList: Array<FilterItem>;
	@Input() filteredCompanyList: Array<FilterItem>;

	@Input() nestedPocList: GroupResult[];
	@Input() pocList: Array<FilterItem>;
	@Input() filteredPocList: Array<FilterItem>;

	@Input() riskList: Array<FilterItem> = [];
	@Input() loading = true;
	@Input() headerSearch = new BehaviorSubject<string>('');

	varianceMin: number;
	varianceMax: number;

	currentlyExpandedId: string = '';
	hideCategoryLabels = false;
	sliderSettings = {
		min: 0,
		max: 100,
		smallStep: 5,
		largeStep: 4,
		tickPlacement: 'after',
		showButtons: true,
		incrementTitle: 'Increase',
		decrementTitle: 'Decrease',
		dragTitle: 'Drag',
	};
	readonly DEFAULT_SLIDER_VALUES = {
		projectScore: [0, 100],
		progressScore: [0, 100],
		qcScore: [0, 100],
		predictabilityScore: [0, 100],
		completionVar: [0, 5],
	};
	sliderValues = {
		...this.DEFAULT_SLIDER_VALUES,
	};
	updateStatus: 'current' | 'missing' | 'all' = 'all';
	costLoaded: 'yes' | 'no' | 'all' = 'all';
	completionVarianceNumberToTitleDict = {
		0: 'On Time',
		1: '-30',
		2: '-60',
		3: '-120',
		4: '-360',
		5: 'Behind',
	};
	completionVarianceValueToFilterValueDict = {
		0: 0,
		1: -30,
		2: -60,
		3: -120,
		4: -360,
		5: -Number.MAX_SAFE_INTEGER,
	};
	completionVarianceValueToLabelDict = {
		0: '-',
		1: '30',
		2: '60',
		3: '120',
		4: '360',
		5: '-',
	};
	completionVarLabel = '';

	private _unsubscribeAll: Subject<void>;
	filterOpen: boolean;
	@ViewChild(GridComponent)
	public grid: GridComponent;
	public filter: CompositeFilterDescriptor = {
		logic: 'and',
		filters: [],
	};
	public baselineFilter: CompositeFilterDescriptor = {
		logic: 'and',
		filters: [],
	};
	public filters: Record<string, FilterItem[]> = {};
	expandedDetailKeys = [];
	user: any = {
		userType: 'aegis',
	};

	public pageSize = 100;
	public skip = 0;
	public gridView: GridDataResult;
	@Input() exportExcel: Observable<void>;
	allProjectsInitialized = false;
	isFirstLoad = false;
	expandedCompanyNodes: string[] = ['0'];
	private columnSelectorPopupRef: PopupRef;
	showingFilters = false;
	private projectListFilterWindowRef: WindowRef;
	timer: any;
	hasConstraints: boolean = false;

	constructor(
		private _analyticsDashboardService: AnalyticsDashboardService,
		private _projectDashboardService: ProjectDashboardService,
		private userService: UserService,
		private restService: RestService,
		public router: Router,
		private route: ActivatedRoute,
		private popupService: PopupService,
		public windowService: AppWindowService,
		private cdr: ChangeDetectorRef,
		public kendoWindowService: WindowService,
		private navBarStorage: NavigationBarStorageService
	) {
		this._unsubscribeAll = new Subject<void>();
		this.allExportData = this.allExportData.bind(this);
		this.defaultVisibleColumns.clear();
		this.defaultHiddenColumns.clear();
		this.allColumns.clear();
		this.hiddenColumnsTitles = [];
		this.visibleColumnsTitles = [];
		for (const group of allGroups.groups) {
			for (const column of group.columns) {
				column.group = group.title;
				if (column.default) {
					this.defaultVisibleColumns.add(column);
					this.visibleColumnsTitles.push(column.field);
				} else {
					this.defaultHiddenColumns.add(column.field);
					this.hiddenColumnsTitles.push(column.field);
				}
				this.allColumns.set(column.field, column);
			}
		}
		this.columnSelectorSelectionChange.emit({
			visible: this.visibleColumnsTitles,
			hidden: this.hiddenColumnsTitles,
			all: Array.from(this.allColumns.values()).sort((a: ProjectListColumn, b: ProjectListColumn) =>
				a.sequence < b.sequence ? -1 : b.sequence < a.sequence ? 1 : 0
			),
		});
	}

	public expandDetailsBy = (dataItem: any): number => dataItem._id;

	ngOnInit(): void {
		this.isFirstLoad = true;
		this._analyticsDashboardService.$resetFilters.subscribe((result) => {
			// this is not really a reset. its more of a take the default filters for the new banner and add on the existing filters for non-overlapping fields
			if (result) {
				const banner: string = localStorage.getItem('currentBanner');
				const filters: ProjectsFilter = new ProjectsFilter();
				filters.filters = this.defaultFilters(banner === 'risk', banner === 'cost');
				const excludedFields: string[] =
					banner === 'cost'
						? ['cost-loaded', 'scheduleType']
						: banner === 'risk'
							? ['riskMetricsType', 'scheduleType']
							: ['scheduleType'];
				const prevBanner: string = localStorage.getItem('previousBanner');
				const excludedFromPrevBanner: string[] =
					prevBanner === 'cost'
						? ['cost-loaded', 'scheduleType']
						: prevBanner === 'risk'
							? ['riskMetricsType', 'scheduleType']
							: ['scheduleType'];
				const existingFilters: ProjectFieldFilter[] = structuredClone(this.ifilters.filters).filter(
					(f) => !excludedFields.includes(f.text) && !excludedFromPrevBanner.includes(f.text)
				);
				filters.mergeFilters(existingFilters);
				const newFilters: any = {};
				filters.filters.forEach((filter) => {
					const arrayOfFilters = [];
					filter.filters.forEach((f2) => {
						const newF2 = {
							text: filter.text === 'search' ? f2.field : f2.text,
							value: f2,
						};
						arrayOfFilters.push(newF2);
					});
					newFilters[filter.text] = arrayOfFilters.sort((c, d) => c?.text.localeCompare(d?.text));
				});
				localStorage.setItem(LOCAL_STORAGE.PREV_BASELINE_LIST_FILTERS, JSON.stringify(newFilters));
				this.ifilters.next(filters.filters);
				this.doFilterEmit.emit(true);
			}
		});
		this.resetFilterEvent.subscribe(() => {
			this.resetFilters();
		});
		this.ifilters.subscribe((filters: ProjectFieldFilter[]) => {
			this.resetDisabled = this.isResetFilterBtnDisabled();
			this.setFilterInputs(filters);
		});

		this.$projects.pipe(takeUntil(this._unsubscribeAll)).subscribe((allProjects) => {
			if (!allProjects) {
				return;
			}
			const localStorageSort = JSON.parse(localStorage.getItem('projectListSort'));
			if (localStorageSort !== null) {
				this.sortChange(localStorageSort);
			}
			this.canUpdateLocalStorage = true;
			this.allProjectsInitialized = true;
		});

		this.$filteredProjects.pipe(takeUntil(this._unsubscribeAll)).subscribe((filteredProjects) => {
			if (filteredProjects) {
				this.loadProjects(filteredProjects);
			}
			// sets filter inputs on first load to prevent scenario where user goes back to portfolio page and filter dropdowns are not displaying the updated values
			if (this.ifilters.filters.length && this.isFirstLoad) {
				this.setFilterInputs(this.ifilters.filters);
				this._analyticsDashboardService.canEditPortfolioLocalStorage = true;
			}
		});

		this.clickedOnMapMarker.subscribe(() => {
			//disable localstorage filter updates when nav event happens
			this.canUpdateLocalStorage = false;
		});

		this.exportExcel.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
			this.exportToExcel(this.grid);
		});

		this.userService.user.subscribe((data) => {
			if (data) {
				this.user = data;
				const existingCols: string = localStorage.getItem('visibleColumns');
				if (existingCols) {
					setTimeout(() => {
						this.visibleProjectListColumns = JSON.parse(existingCols);
					}, 2000);
				}
			}
		});

		this.$checkForResetBtnDisabled.subscribe((val) => {
			this.resetDisabled = this.isResetFilterBtnDisabled();
		});

		this.windowService.$closeFilters.subscribe((val: boolean) => {
			if (val) {
				this.closeFilterWindow();
			}
		});

		this.$filtersOpened.subscribe((anchor) => {
			if (this.showingFilters && this.projectListFilterWindowRef !== undefined) {
				this.closeFilterWindow();
			} else {
				if (this.filterWindowContentTemplateRef !== undefined) {
					this.projectListFilterWindowRef = this.kendoWindowService.open({
						title: 'Project List Filters',
						content: this.filterWindowContentTemplateRef,
						cssClass: 'project-list-filters-window-container',
						top: 362,
						left: this.windowService.windowPosition.projectGridFilters.left,
						height: this.windowService.windowPosition.projectGridFilters.height,
						width: this.windowService.windowPosition.projectGridFilters.width,
						resizable: false,
					});
					this.showingFilters = true;
					this.projectListFilterWindowRef.window.location.nativeElement.setAttribute('id', 'projectsGridFiltersWindow');
					this.projectListFilterWindowRef.result.subscribe(() => {
						this.closeFilterWindow();
					});
					this.windowService.setViewport('projectGridFilters');
				}
			}
		});

		this.$columnSelectorOpened.subscribe((anchor) => {
			const popupColumnSelectorContainer = document.getElementById('columnSelectorContainer');
			if (popupColumnSelectorContainer !== null || anchor === null) {
				this.closePopup('columns');
			} else {
				if (anchor !== undefined) {
					this.closePopup('filters');
					this.columnSelectorPopupRef = this.popupService.open({
						anchor,
						content: this.columnSelectorTemplateRef,
						popupClass: ['columns-popup-class'],
						margin: { vertical: 10, horizontal: 0 },
					});
					this.columnSelectorPopupState.emit(true);
				}
			}
		});

		this.userService.user.subscribe((user) => {
			this.setColumns();
		});
		this.headerSearch.subscribe((term: string) => {
			if (term !== this.searchTerm) {
				this.searchTerm = term;
				this.searchProjects(term);
			}
		});
		this.resetClickedPortfolio.subscribe((val: boolean) => {
			if (val) {
				this.resetFiltersButtonClicked();
			}
		});

		this._analyticsDashboardService.ganttSort.subscribe((sort) => {
			if (sort !== null) {
				this.sort = structuredClone(sort);
			}
		});

		this._analyticsDashboardService.$closeAll.subscribe((val: boolean) => {
			if (val) {
				this.closePopup('columns');
				this.closeFilterWindow();
			}
		});

		this.$portfolioPresetApplied.subscribe((preset: PortfolioPreset) => {
			if (preset !== null) {
				this.setColumns(preset);
				this.visibleProjectListColumns = this._visibleColumns;
			}
		});

		const existingCols: string = localStorage.getItem('visibleColumns');
		if (existingCols) {
			this.visibleProjectListColumns = JSON.parse(existingCols);
		}
	}

	/**
	 * set visible columns from defaults or preset value
	 * @param preset
	 */
	setColumns(preset: PortfolioPreset = null): void {
		const isPreset: boolean = preset !== null;
		this._visibleColumns = [];
		this.visibleColumns = [];
		this.hiddenColumnsTitles = [];
		this.visibleColumnsTitles = [];
		if (preset === null) {
			this.defaultVisibleColumns.clear();
			this.defaultHiddenColumns.clear();
		}
		for (let i = 0; i < allGroups.groups.length; i++) {
			const group = allGroups.groups[i];
			for (let j = 0; j < group.columns.length; j++) {
				const column = group.columns[j];
				column.group = group.title;
				const treeViewKey = `${i}_${j}`;
				this.columnToTreeViewCoord.set(column.field, treeViewKey);

				if (isPreset ? preset.visibleColumns.includes(column.field) : column.default) {
					this._visibleColumns.push(treeViewKey);
					this.visibleColumns.push(column);
					if (preset === null) {
						this.defaultVisibleColumns.add(column);
					}
					this.visibleColumnsTitles.push(column.field);
				} else {
					if (preset === null) {
						this.defaultHiddenColumns.add(column.field);
					}
					this.hiddenColumnsTitles.push(column.field);
				}
				this.allColumns.set(column.field, column);
			}
		}
		this.columnSelectorSelectionChange.emit({
			visible: this.visibleColumnsTitles,
			hidden: this.hiddenColumnsTitles,
			all: Array.from(this.allColumns.values()).sort((a: ProjectListColumn, b: ProjectListColumn) =>
				a.sequence < b.sequence ? -1 : b.sequence < a.sequence ? 1 : 0
			),
		});
	}

	public children = (dataItem: any): Observable<ProjectListColumnGroup[]> => of(dataItem.columns);
	public hasChildren = (dataItem: any): boolean => !!dataItem.columns;
	public isDisabled = (dataItem: any) => dataItem.alwaysVisible;

	set visibleProjectListColumns(columns: string[]) {
		this._visibleColumns = columns;
		localStorage.setItem('visibleColumns', JSON.stringify(columns));
		const visibleColumnFieldsProjectList = new Set<string>([]);
		const newVisibleColumnFields: ProjectListColumn[] = [];
		this.visibleColumnsTitles = [];
		for (const col of columns) {
			const coords = col.split('_');
			if (coords.length === 2) {
				const column = allGroups.groups[coords[0]]?.columns?.[coords[1]];
				if (column) {
					visibleColumnFieldsProjectList.add(column.field);
					newVisibleColumnFields.push(column);
					this.visibleColumnsTitles.push(column.field);
				}
			}
		}
		this.hiddenColumnsTitles = [];
		this.allColumns.forEach((col) => {
			if (!this.visibleColumnsTitles.includes(col.field)) {
				this.hiddenColumnsTitles.push(col.field);
			}
		});
		this.visibleColumns = structuredClone(newVisibleColumnFields).sort((a: ProjectListColumn, b: ProjectListColumn) =>
			a.sequence < b.sequence ? -1 : b.sequence < a.sequence ? 1 : 0
		);
		this.columnSelectorSelectionChange.emit({
			visible: this.visibleColumnsTitles,
			hidden: this.hiddenColumnsTitles,
			all: Array.from(this.allColumns.values()).sort((a: ProjectListColumn, b: ProjectListColumn) =>
				a.sequence < b.sequence ? -1 : b.sequence < a.sequence ? 1 : 0
			),
		});
	}

	get visibleProjectListColumns() {
		return this._visibleColumns;
	}

	/**
	 * forces window to stay within bounds of viewport
	 * @param window
	 */
	restrictMovement(window: string): void {
		this.windowService.restrictMovement(window, this.cdr);
	}

	closeFilterWindow(): void {
		this.showingFilters = false;
		if (this.projectListFilterWindowRef) {
			this.projectListFilterWindowRef.close();
		}
	}

	resetColumns() {
		const columns = [];
		for (const col of this.defaultVisibleColumns) {
			columns.push(this.columnToTreeViewCoord.get(col.field));
		}
		localStorage.setItem('visibleColumns', JSON.stringify(columns));
		this.visibleProjectListColumns = columns;
		const newVisibleColumnFields: ProjectListColumn[] = [];
		this.visibleColumnsTitles = [];
		for (const col of columns) {
			const coords = col.split('_');
			if (coords.length === 2) {
				const column = allGroups.groups[coords[0]]?.columns?.[coords[1]];
				if (column) {
					newVisibleColumnFields.push(column);
					this.visibleColumnsTitles.push(column.field);
				}
			}
		}
		this.showGrid = false;
		this.hiddenColumnsTitles = [];
		this.allColumns.forEach((col) => {
			if (!this.visibleColumnsTitles.includes(col.field)) {
				this.hiddenColumnsTitles.push(col.field);
			}
		});
		this.visibleColumns = structuredClone(newVisibleColumnFields).sort((a: ProjectListColumn, b: ProjectListColumn) =>
			a.sequence < b.sequence ? -1 : b.sequence < a.sequence ? 1 : 0
		); //structured clone forces change detection which allows for column widths to be reset
		this.columnSelectorSelectionChange.emit({
			visible: this.visibleColumnsTitles,
			hidden: this.hiddenColumnsTitles,
			all: Array.from(this.allColumns.values()).sort((a: ProjectListColumn, b: ProjectListColumn) =>
				a.sequence < b.sequence ? -1 : b.sequence < a.sequence ? 1 : 0
			),
		});
		setTimeout(() => {
			//although change detection does force the column width change, kendo doesn't reset the inner table width to the old value. trying to manually update that value results in desynced column header/cell widths, so the only working solution i found (which was suggested by a kendo dev) was to toggle hide/show the grid so it remakes a new one at the correct width - RS
			this.showGrid = true;
		});
		this.navBarStorage.selectedLayout = null;
	}

	/**
	 * close selected popup
	 * @param menu
	 */
	closePopup(menu: 'filters' | 'columns'): void {
		switch (menu) {
			case 'columns': {
				const popupColumnSelectorContainer = document.getElementById('columnSelectorContainer');
				this.columnSelectorPopupState.emit(false);
				if (popupColumnSelectorContainer !== null) {
					this.columnSelectorPopupRef.close();
				}
				break;
			}
		}
	}

	setFilterInputs(filters: ProjectFieldFilter[] = this.ifilters.filters) {
		if (!filters.length) {
			this.resetFilters();
			return;
		}
		this.filterValuesByField.projectType = getFiltersFromValToRef(this.projectTypeList, filters, 'projectType');
		this.filterValuesByField.companyName = getFiltersFromValToRef(this.filteredClientList, filters, 'companyName');
		this.filterValuesByField.scheduleType = getFiltersFromValToRef(this.scheduleTypeList, filters, 'scheduleType');
		this.filterValuesByField.company = getFiltersFromValToRef(this.companyList, filters, 'company');
		//this.companyMultiselect.writeValue(this.filterValuesByField.company);
		this.filterValuesByField.pocId = getFiltersFromValToRef(this.pocList, filters, 'pocId');
		this.filterValuesByField.clientId = getFiltersFromValToRef(this.clientList, filters, 'clientId');
		this.filterValuesByField.riskMetricsType = getFiltersFromValToRef(this.riskList, filters, 'riskMetricsType');
		this.filterValuesByField.deltaVsCcd = getFiltersFromValToRef(this.completionStatus, filters, 'deltaVsCcd');
		this.filterValuesByField.prevVariance = getFiltersFromValToRef(
			this.pastPeriodPerformanceList,
			filters,
			'prevVariance'
		);
		this.$numUniqueFilters.next(this.ifilters.uniqueFilterFields.size);
		this.searchTerm = filters.find((filter) => filter.text === 'search')?.filters?.[0]?.value || '';
		this.searchStateChanged.emit(this.searchTerm);
		const updateStatusFilters = (filters.find((filter) => filter.text === 'update-status')?.filters || []).filter(
			(filter) => filter.operator !== 'isnotnull'
		);
		const costLoadedFilters = (filters.find((filter) => filter.text === 'cost-loaded')?.filters || []).filter(
			(filter) => filter.operator !== 'isnotnull'
		);

		if (updateStatusFilters?.length === 1 && updateStatusFilters[0].operator === 'gte') {
			this.updateStatus = 'missing';
		} else if (updateStatusFilters?.length === 1 && updateStatusFilters[0].operator === 'lt') {
			this.updateStatus = 'current';
		} else {
			this.updateStatus = 'all';
		}
		if (costLoadedFilters?.length === 1 && costLoadedFilters[0].value === true) {
			this.costLoaded = 'yes';
		} else if (costLoadedFilters?.length === 1 && costLoadedFilters[0].value === false) {
			this.costLoaded = 'no';
		} else {
			this.costLoaded = 'all';
		}

		Object.keys(this.sliderValues).forEach((key) => {
			const savedFilterValue = filters.find((filter) => filter.text === key)?.filters?.[0]?.value;
			const savedFilterValueOther = filters.find((filter) => filter.text === key)?.filters?.[1]?.value;
			if (savedFilterValue !== undefined) {
				let newVal: number[] = [savedFilterValue, savedFilterValueOther];
				newVal = newVal.sort((a: number, b: number) => {
					if (a < b) {
						return -1;
					}
					if (b < a) {
						return 1;
					}
					return 0;
				});
				if (key === 'completionVar') {
					let normalizedLeft: number = newVal[1] > 0 ? 0 : newVal[1];
					let normalizedRight: number = newVal[0] < -360 ? 5 : newVal[0];
					for (const [key, value] of Object.entries(this.completionVarianceValueToFilterValueDict)) {
						if (normalizedLeft !== 0 && value === normalizedLeft) {
							normalizedLeft = Number(key);
						}
						if (normalizedRight !== 5 && value === normalizedRight) {
							normalizedRight = Number(key);
						}
					}
					this.sliderValues.completionVar = [normalizedLeft, normalizedRight];
					this.updateCompletionVarianceLabel([normalizedLeft, normalizedRight]);
				}
				if (key !== 'completionVar') {
					this.sliderValues[key] = newVal;
				}
			}
		});
		const newFilters: any = {};
		filters.forEach((filter) => {
			const arrayOfFilters = [];
			filter.filters.forEach((f2) => {
				const newF2 = {
					text: filter.text === 'search' ? f2.field : f2.text,
					value: f2,
				};
				arrayOfFilters.push(newF2);
			});
			newFilters[filter.text] = arrayOfFilters.sort((a, b) => a?.text.localeCompare(b?.text));
		});
		localStorage.setItem('projectListFilterMap', JSON.stringify(newFilters));
	}

	/**-
	 * handles filter value change by forwarding it to the updateFilters function after letting the portfolio page know this filter change was not a view change
	 * @param a
	 * @param b
	 */
	filterChanged(a?: string, b?: FilterItem[]): void {
		if (this.loading) {
			return;
		}
		const currentView = localStorage.getItem(LOCAL_STORAGE.LIST_VIEW);
		if (isQuickViewOption(currentView) && QuickViewMapping[currentView].includes(a)) {
			localStorage.removeItem(LOCAL_STORAGE.LIST_VIEW);
			this.removeQuickView.emit(true);
		}
		this.updateFilters(a, b);
		this.navBarStorage.selectedLayout = null;
	}

	isChecked(field: string, dataItem): boolean {
		return (
			this.filterValuesByField[field].findIndex(
				(item) => item?.value?.value === dataItem?.value?.value && item?.value?.value !== undefined
			) !== -1
		);
	}

	/**
	 * updates baseline filter set in localstorage. only called when a filter in the menu is changed or the searchbar is searched
	 * @param a
	 * @param b
	 * @param isRangeSlider
	 */
	updateFilters(a?: string, b?: FilterItem[], isRangeSlider = false): void {
		if (!this.$projects.value) {
			return;
		}
		if (a) {
			this.ifilters.setFilterDescriptor(a, b, isRangeSlider ? 'and' : 'or');
		}
		const newFilters: any = {};
		this.ifilters.filters.forEach((filter) => {
			const arrayOfFilters = [];
			filter.filters.forEach((f2) => {
				const newF2 = {
					text: filter.text === 'search' ? f2.field : f2.text,
					value: f2,
				};
				arrayOfFilters.push(newF2);
			});
			newFilters[filter.text] = arrayOfFilters.sort((c, d) => c?.text.localeCompare(d?.text));
		});
		const storedFilters: Record<string, FilterItem[]> =
			JSON.parse(localStorage.getItem(LOCAL_STORAGE.PREV_BASELINE_LIST_FILTERS)) || {};
		const banner = localStorage.getItem('currentBanner');
		const defaultFilters = this.defaultFilters(banner === 'risk', banner === 'cost');
		const currentView = localStorage.getItem(LOCAL_STORAGE.LIST_VIEW);
		//now we must make sure that existing quickview-related filters are not saved in the new baseline
		if (isQuickViewOption(currentView)) {
			for (const [key, value] of Object.entries(QuickViewFilters[currentView])) {
				if (Object.keys(storedFilters).length > 0) {
					newFilters[value.text] = storedFilters[value.text];
				} else {
					const matchingDescriptor = defaultFilters.find((descriptor) => descriptor.text === value.text);
					const arrayOfFilters = [];
					matchingDescriptor.filters.forEach((f3) => {
						const newF3 = {
							text: f3.text,
							value: f3,
						};
						arrayOfFilters.push(newF3);
					});
					newFilters[matchingDescriptor.text] = arrayOfFilters.sort((e, f) => e?.text.localeCompare(f?.text));
				}
			}
		}
		localStorage.setItem('prevBaselineProjectListFilterMap', JSON.stringify(newFilters));
		this.projectsFiltered.emit(false);
		this.resetDisabled = this.isResetFilterBtnDisabled();
	}

	public filterChange(filter: CompositeFilterDescriptor, isViewChange: boolean = false): void {
		this.filter = filter;
		this.currentFiltersChanged.emit(this.filters);
	}

	isResetFilterBtnDisabled() {
		if (isQuickViewOption(localStorage.getItem('currentPortfolioView'))) {
			this.resetDisabled = false;
			this.resetStateChanged.emit(false);
			return false;
		}
		let currentBanner = localStorage.getItem('currentBanner');
		currentBanner = currentBanner === null ? 'default' : currentBanner;
		const defaultFilters = this.defaultFilters(currentBanner === 'risk', currentBanner === 'cost');
		const defaultFilter = new ProjectsFilter();
		defaultFilter.filters = defaultFilters;
		const currentFilters = this.ifilters.filters;
		const result =
			compareFilters(defaultFilter.filters, currentFilters) &&
			JSON.stringify(this.sliderValues) === JSON.stringify(this.DEFAULT_SLIDER_VALUES);
		this.resetDisabled = result;
		this.resetStateChanged.emit(result);
		return result;
	}

	/**
	 * disable localstorage filter updates when nav event happens
	 */
	navToProjectEvent() {
		this._analyticsDashboardService.canEditPortfolioLocalStorage = false;
		this.navBarStorage.showingNewProject = false;
		this.canUpdateLocalStorage = false;
		this.closePopup('columns');
		this.closeFilterWindow();
	}

	public pageChange(event: PageChangeEvent): void {
		this.skip = event.skip;
		this.loadProjects(this.$filteredProjects.value);
	}

	public sortChange(sort: SortDescriptor[], skipEmit = false): void {
		this.sort = structuredClone(sort);
		if (this._analyticsDashboardService.canEditPortfolioLocalStorage) {
			localStorage.setItem('projectListSort', JSON.stringify(this.sort));
		}
		this.loadProjects(this.$filteredProjects.value);
		if (!skipEmit) {
			this.sortChangeProjectList.emit(sort);
		}
	}

	public loadProjects(projects: ProjectListItem[]): void {
		if (this.skip > projects?.length) {
			this.skip = 0;
		}
		this.gridView = {
			data: projects?.slice(this.skip, this.skip + this.pageSize),
			total: projects?.length,
		};
		this.gridData = projects;
	}

	getProjectCompletionVarianceTrending(report: ProjectReportInterface) {
		this.restService
			.fetch(`update-files/${report.updateIds[report.updateIds.length - 1]}`)
			.pipe(takeUntil(this._unsubscribeAll), debounceTime(100))
			.subscribe((response) => {
				console.log(response);
				this.hasConstraints =
					response?.finishMilestone?.cstr_date !== null && response?.finishMilestone?.cstr_date !== undefined;
				this.projectCompletionSubtitleName =
					response.finishMilestone.task_code + ' - ' + response.finishMilestone.task_name;
			});
		this.projectVarianceSeries = [];
		if (!report) {
			return;
		}
		const projectCompletionVarianceTrendArray = report?.projectCompletionTrend?.projectCompletionTrendArray || [];
		const projectVarianceSeries: typeof this.projectVarianceSeries = [];
		let contractVariance: number = 0;
		for (let i = 0; i < projectCompletionVarianceTrendArray.length; i++) {
			const update = projectCompletionVarianceTrendArray[i];
			if (!update.dataDate) {
				continue;
			}
			const dataDate = new Date(update.dataDate);
			contractVariance += +update.previousVariance || 0;
			projectVarianceSeries.push({
				dataDate,
				updateName:
					i === 0 ? 'Baseline' : 'Update ' + i + (report?.baselineUpdateId === report?.updateIds[i] ? ' ®' : ''),
				completionVariance: contractVariance,
			});
		}
		this.projectVarianceSeries = projectVarianceSeries;
	}

	toggleRow(row: CellClickEvent) {
		if (row.columnIndex === 0) {
			return;
		}
		this.projectCompletionSubtitleName = '';
		const proj = row.dataItem;
		this.currentlyExpandedId = proj?._id === this.currentlyExpandedId ? undefined : proj._id;
		this.projectSelectedStateChange.emit(this.currentlyExpandedId !== undefined);
		this.expandedDetailKeys = [this.currentlyExpandedId];
		this.selectProject.emit(this.currentlyExpandedId ? proj : undefined);
		if (!this.currentlyExpandedId) {
			this.deselectProject.emit(true);
		}
		if (this.currentlyExpandedId) {
			if (this.user.userType === 'saasRisk') {
				this.getRiskRegisterData(this.currentlyExpandedId);
			} else {
				this.getProjectCompletionVarianceTrending(this._projectDashboardService.projectReportMap.get(proj?._id));
			}
		}
		// kendo dev's suggested solution to scroll selected row to top of list
		setTimeout(() => document?.querySelector('.k-selected')?.scrollIntoView({ behavior: 'smooth', block: 'start' }));
	}

	/**
	 * gathers risk register data for expanded row components
	 * @param projId
	 */
	getRiskRegisterData(projId: string): void {
		const project: ProjectInterface = this._projectDashboardService.projectsMap.get(projId);
		const riskMitigations: RiskRegister[] = project.riskMitigation;
		this.$selectedProjectRiskMitigations.next(riskMitigations);
		const newRisks = riskMitigations.filter((risk) => !risk.isDraft);
		let summedRiskAssessmentScores = 0;
		let summedPreMitigationRiskScores = 0;
		let summedPostMitigationRiskScores = 0;
		riskMitigations.forEach((risk) => {
			const preImpact = Math.max(
				risk.preMitigation.qualityImpact,
				risk.preMitigation.performanceImpact,
				risk.preMitigation.costImpact,
				risk.preMitigation.scheduleImpact
			);
			const postImpact = Math.max(
				risk.postMitigation.qualityImpact,
				risk.postMitigation.performanceImpact,
				risk.postMitigation.costImpact,
				risk.postMitigation.scheduleImpact
			);
			const likelihood = risk.preMitigation.probability;
			const preMitigationRiskScore = likelihood * preImpact;
			const postMitigationRiskScore = likelihood * postImpact;
			const riskAssessmentScore = preMitigationRiskScore * 4;
			summedRiskAssessmentScores += riskAssessmentScore;
			summedPreMitigationRiskScores += preMitigationRiskScore;
			summedPostMitigationRiskScores += postMitigationRiskScore;
		});
		const avgRiskAssessmentScore = Math.round(summedRiskAssessmentScores / (newRisks.length || 1));
		const avgPreMitigationRiskScore = Math.round(summedPreMitigationRiskScores / (newRisks.length || 1));
		const avgPostMitigationRiskScore = Math.round(summedPostMitigationRiskScores / (newRisks.length || 1));
		this.$avgRiskAssessmentScore.next(avgRiskAssessmentScore || null);
		this.$avgPreMitigationScore.next(avgPreMitigationRiskScore || null);
		this.$avgPostMitigationScore.next(avgPostMitigationRiskScore || null);
	}

	/**
	 * reset button click handler. makes sure portfolio page knows this was not a view change
	 */
	resetFiltersButtonClicked(): void {
		this.lastFilterChangeWasViewChange = false;
		this.resetFilters();
		this.navBarStorage.selectedLayout = null;
	}

	resetFilters(): void {
		this.varianceMin = undefined;
		this.varianceMax = undefined;
		this.searchTerm = '';
		this.searchStateChanged.emit('');

		const currentBanner = localStorage.getItem('currentBanner');
		this.ifilters.filters = this.defaultFilters(currentBanner === 'risk', currentBanner === 'cost');
		this.sliderValues = {
			projectScore: [0, 100],
			progressScore: [0, 100],
			qcScore: [0, 100],
			predictabilityScore: [0, 100],
			completionVar: [0, 5],
		};
		this.updateStatus = 'all';
		this.costLoaded = currentBanner === 'cost' ? 'yes' : 'all';
		this.updateFilters();
		this.resetFiltersClicked.emit();
		this.resetDisabled = true;
		this.resetStateChanged.emit(true);
	}

	colorOfQc(qcScore: number) {
		if (qcScore >= 95) {
			return '#377be0';
		} else if (qcScore >= 80) {
			return '#4db94d';
		}
		return '#e26464';
	}

	colorOfRisk(risk: number) {
		if (risk >= 85) {
			return '#377be0';
		} else if (risk >= 70) {
			return '#4db94d';
		}
		return '#e26464';
	}

	colorOfProgress(score: number, numberOfUpdates?: number) {
		if (numberOfUpdates === 0) {
			return '#adadad';
		}
		if (score >= 95) {
			return '#377be0';
		} else if (score >= 80) {
			return '#4db94d';
		}
		return '#e26464';
	}

	colorOfScore(score: number) {
		if (score < 70) {
			return '#e26464';
		} else if (score < 85) {
			return '#4db94d';
		}
		return '#377be0';
	}

	ngOnDestroy(): void {
		clearInterval(this.timer);
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	ngOnChanges(changes: SimpleChanges): void {
		//close project list item expansion if filter menu is opening
		if (changes?.filterPanelOpenState?.currentValue && !changes?.filterPanelOpenState?.previousValue) {
			this.currentlyExpandedId = undefined;
		}
		if (changes?.hasOwnProperty('companyList')) {
			this.filterValuesByField.company = getFiltersFromValToRef(this.companyList, this.ifilters.filters, 'company');
			//this.companyMultiselect.valueChange.emit(this.companyMultiselect.value);
			this.isResetFilterBtnDisabled();
		}
		if (changes?.hasOwnProperty('pocList')) {
			this.filterValuesByField.pocId = getFiltersFromValToRef(this.pocList, this.ifilters.filters, 'pocId');
			this.isResetFilterBtnDisabled();
		}
		if (changes?.hasOwnProperty('clientList')) {
			this.filterValuesByField.clientId = getFiltersFromValToRef(this.clientList, this.ifilters.filters, 'clientId');
			this.isResetFilterBtnDisabled();
		}
	}
	compare(a: number | string, b: number | string, isAsc: boolean) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}
	public isRowSelected = (e: RowArgs): boolean => this.currentlyExpandedId === e.dataItem._id;

	searchProjects(term: string) {
		if (this.loading) {
			return;
		}
		if (!term) {
			this.searchTerm = '';
			this.searchStateChanged.emit('');
			this.ifilters.setFilterDescriptor('search', []);
		} else {
			this.ifilters.setFilterDescriptor(
				'search',
				[
					{
						text: 'search',
						value: {
							field: 'name',
							operator: 'contains',
							value: term,
							ignoreCase: true,
						},
					},
					{
						text: 'search',
						value: {
							field: 'projectCode',
							operator: 'contains',
							value: term,
							ignoreCase: true,
						},
					},
				],
				'or'
			);
		}
		//todo: rm if this borks -ms
		this.updateFilters();
	}

	public varianceLabel = (e: AxisLabelContentArgs): string => (e.value === 0 ? 'On Time' : e.value + ' cd');

	// /**
	//  * updates filters with preset quick filter value
	//  * @param newFilter
	//  */
	// applyQuickFilter(newFilter: QuickFilterMapping): void {
	// 	this.updateFilters();
	// }

	/**
	 * reset individual slider value
	 * @param sliderId
	 */
	resetSliderValue(sliderId: string): void {
		this.updateSliderValue([0, 100], sliderId);
		this.sliderValueChanged([0, 100], sliderId);
	}

	/**
	 * updates slider value tracker for display in the label above
	 * @param val
	 * @param sliderId
	 */
	updateSliderValue(val: number[], sliderId: string): void {
		this.sliderValues[sliderId] = val;
	}

	/**
	 * score slider value change handler. applies filter based on input
	 * @param val
	 * @param sliderId
	 */
	sliderValueChanged(val: number[], sliderId: string): void {
		this.lastFilterChangeWasViewChange = false;
		if (val) {
			this.updateFilters(
				sliderId,
				[
					{
						text: sliderId,
						value: {
							text: sliderId,
							field: sliderId,
							operator: FilterOperator.LessThanOrEqual,
							value: val[1],
						},
					},
					{
						text: sliderId,
						value: {
							text: sliderId,
							field: sliderId,
							operator: FilterOperator.GreaterThanOrEqual,
							value: val[0],
						},
					},
				],
				true
			);
		}
		this.navBarStorage.selectedLayout = null;
	}

	/**
	 * checkbox change handler
	 */
	updateStatusCheckboxChanged(): void {
		const temp = this.updateStatus;
		const currentView = localStorage.getItem(LOCAL_STORAGE.LIST_VIEW);
		if (currentView === 'missingUpdate' && this.updateStatus !== 'missing') {
			this.removeQuickView.emit(true);
		}
		this.updateStatusFilterChange(temp);
		this.navBarStorage.selectedLayout = null;
	}

	/**
	 * checkbox change handler
	 */
	costLoadedCheckboxChanged(): void {
		const temp = this.costLoaded;
		this.costLoadedFilterChange(temp);
		this.navBarStorage.selectedLayout = null;
	}

	costLoadedFilterChange(val): void {
		switch (val) {
			case 'yes': {
				this.updateFilters('cost-loaded', [
					{
						text: 'cost-loaded',
						value: {
							text: 'cost-loaded',
							field: 'costLoaded',
							operator: FilterOperator.EqualTo,
							value: true,
						},
					},
				]);
				break;
			}
			case 'no': {
				this.updateFilters('cost-loaded', [
					{
						text: 'cost-loaded',
						value: { text: 'cost-loaded', field: 'costLoaded', operator: FilterOperator.EqualTo, value: false },
					},
				]);
				break;
			}
			case 'all': {
				this.updateFilters('cost-loaded', [
					{
						text: 'cost-loaded',
						value: { text: 'cost-loaded', field: 'costLoaded', operator: FilterOperator.IsNotNull },
					},
				]);
				break;
			}
		}
	}

	updateStatusFilterChange(val): void {
		switch (val) {
			case 'missing': {
				this.updateFilters('update-status', [
					{
						text: 'update-status',
						value: {
							text: 'update-status',
							field: 'dUpload',
							operator: FilterOperator.GreaterThanOrEqual,
							value: 45,
						},
					},
				]);
				this.updateFilters('scheduleType', [
					{
						text: 'Active',
						value: {
							text: 'Active',
							field: 'scheduleType',
							operator: FilterOperator.EqualTo,
							value: 'Active',
						},
					},
				]);
				break;
			}
			case 'current': {
				this.updateFilters('update-status', [
					{
						text: 'update-status',
						value: { text: 'update-status', field: 'dUpload', operator: FilterOperator.LessThan, value: 45 },
					},
				]);
				this.updateFilters('scheduleType', [
					{
						text: 'Active',
						value: {
							text: 'Active',
							field: 'scheduleType',
							operator: FilterOperator.EqualTo,
							value: 'Active',
						},
					},
				]);
				break;
			}
			case 'all': {
				this.updateFilters('update-status', [
					{
						text: 'update-status',
						value: { text: 'update-status', field: 'dUpload', operator: FilterOperator.IsNotNull, value: 0 },
					},
				]);
				this.updateFilters('scheduleType', [
					{
						text: 'Active',
						value: {
							text: 'Active',
							field: 'scheduleType',
							operator: FilterOperator.EqualTo,
							value: 'Active',
						},
					},
					{
						text: 'Baseline',
						value: {
							text: 'Baseline',
							field: 'scheduleType',
							operator: FilterOperator.EqualTo,
							value: 'Baseline',
						},
					},
					{
						text: 'Closed',
						value: {
							text: 'Closed',
							field: 'scheduleType',
							operator: FilterOperator.EqualTo,
							value: 'Closed',
						},
					},
					{
						text: 'Consulting',
						value: {
							text: 'Consulting',
							field: 'scheduleType',
							operator: FilterOperator.EqualTo,
							value: 'Consulting',
						},
					},
				]);
				break;
			}
		}
	}

	/**
	 * tracks completion variance slider value changes
	 * @param val
	 */
	updateCompletionVarianceSliderValue(val: number[]): void {
		this.sliderValues.completionVar = val;
	}

	/**
	 * resets completion variance slider to default and removes its filters
	 */
	resetCompletionVariance(): void {
		this.updateCompletionVarianceSliderValue([0, 5]);
		this.updateFilters(
			'completionVar',
			[
				{
					text: 'completionVar',
					value: {
						text: 'completionVar',
						field: 'cumulativeVariance',
						operator: FilterOperator.GreaterThanOrEqual,
						value: -Number.MAX_SAFE_INTEGER,
					},
				},
				{
					text: 'completionVar',
					value: {
						text: 'completionVar',
						field: 'cumulativeVariance',
						operator: FilterOperator.LessThanOrEqual,
						value: Number.MAX_SAFE_INTEGER,
					},
				},
			],
			true
		);
	}

	/**
	 * applies filter for completion variance based on val
	 * @param val
	 */
	completionVarianceSliderValueChanged(val: number[]): void {
		let leftVal = this.completionVarianceValueToFilterValueDict[val[0]];
		let rightVal = this.completionVarianceValueToFilterValueDict[val[1]];
		leftVal = leftVal === 0 ? Number.MAX_SAFE_INTEGER : leftVal;
		rightVal = val[1] === 5 ? -Number.MAX_SAFE_INTEGER : rightVal;
		this.updateCompletionVarianceLabel(val);
		this.lastFilterChangeWasViewChange = false;
		this.updateFilters(
			'completionVar',
			[
				{
					text: 'completionVar',
					value: {
						text: 'completionVar',
						field: 'cumulativeVariance',
						operator: FilterOperator.GreaterThanOrEqual,
						value: rightVal,
					},
				},
				{
					text: 'completionVar',
					value: {
						text: 'completionVar',
						field: 'cumulativeVariance',
						operator: FilterOperator.LessThanOrEqual,
						value: leftVal,
					},
				},
			],
			true
		);
		this.navBarStorage.selectedLayout = null;
	}

	/**
	 * create label for the completion variance slider based on the new values
	 * @param val
	 */
	updateCompletionVarianceLabel(val: number[]): void {
		if (val[0] !== 0 || val[1] !== 5) {
			if (val[1] === 5) {
				this.completionVarLabel = this.completionVarianceValueToLabelDict[val[0]] + '+ days behind';
			} else if (val[0] === 0) {
				this.completionVarLabel = 'up to ' + this.completionVarianceValueToLabelDict[val[1]] + ' days behind';
			} else {
				this.completionVarLabel =
					'min: -' +
					this.completionVarianceValueToLabelDict[val[0]] +
					' days, max: -' +
					this.completionVarianceValueToLabelDict[val[1]] +
					' days';
			}
		}
	}

	/**
	 * assigns custom tick title to completion variance slider
	 * @param value
	 */
	public completionVarianceTickTitle = (value: number): string => `${this.completionVarianceNumberToTitleDict[value]}`;

	/**
	 * force closes multiselect dropdown if it is open and the user clicks anywhere on the page that is not in the
	 * dropdown content or tag area. function necessary only because hiding the kendo-searchbar breaks the default close
	 * logic when clicking on random spots on the page
	 * @param event
	 */
	@HostListener('window:mouseup', ['$event'])
	mouseUp(event) {
		return;
		this.multiselectDropdowns.forEach((dropdown) => {
			if (this[dropdown.viewChildName]?._open === true) {
				const classList: DOMTokenList = event?.target?.classList;
				const parentElement: Element = event?.target?.parentElement;
				const parentOfParentElement: Element = event?.target?.parentElement?.parentElement;
				const parentOfParentOfParentElement: Element = event?.target?.parentElement?.parentElement?.parentElement;
				if (
					!classList.contains('k-checkbox') &&
					!classList.contains('k-list-item-text') &&
					!classList.contains('k-list-item') &&
					!classList.contains('k-list-content') &&
					event?.target?.id !== dropdown.tagId &&
					parentElement?.id !== dropdown.tagId &&
					parentElement?.id !== dropdown.id &&
					parentOfParentElement?.id !== dropdown.id &&
					parentOfParentOfParentElement?.id !== dropdown.id &&
					!(
						dropdown.id === 'varianceMultiselect' &&
						(classList.contains('k-no-data') ||
							classList.contains('k-input-inner') ||
							classList.contains('k-i-caret-alt-up') ||
							classList.contains('k-i-caret-alt-down') ||
							classList.contains('k-spinner-increase') ||
							classList.contains('k-spinner-decrease') ||
							classList.contains('k-icon-wrapper-host') ||
							classList.contains('k-input') ||
							classList.contains('k-input-inner') ||
							classList.contains('variance-input') ||
							classList.contains('k-label'))
					)
				) {
					this[dropdown.viewChildName].toggle(false);
				}
			}
		});
	}
	public exportToExcel(grid: GridComponent): void {
		grid.saveAsExcel();
	}

	public numberToExcelColumn(num: number): string {
		let column = '';
		while (num > 0) {
			const remainder = (num - 1) % 26;
			column = String.fromCharCode(65 + remainder) + column;
			num = Math.floor((num - 1) / 26);
		}
		return column;
	}

	public onExcelExport(e: ExcelExportEvent): void {
		const columnLetter = this.numberToExcelColumn(this.visibleColumns.length - 2); //assigns where aegis logo is placed based on how many columns are visible
		e.preventDefault();
		formatExcelExport(
			e,
			'Analytics_Portfolio.xlsx',
			'',
			'Project Portfolio',
			true,
			'Aegis Project Controls\n' + cleanDateUTC(new Date(), 'MMMM d, yyyy'),
			`${columnLetter}1`
		);
		console.log(e.workbook);
	}

	public allExportData(): ExcelExportData {
		const result: ExcelExportData = {
			data: process(this.$filteredProjects.value, {
				sort: this.sort,
			}).data,
		};
		return result;
	}

	handleClientFilter(value: string) {
		this.filteredClientList = this.clientList.filter((s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1);
		this.nestedClientList = groupBy(this.filteredClientList, [{ field: 'companyName' }]) as GroupResult[];
	}
	handlePocFilter(value: string) {
		this.filteredPocList = this.pocList.filter((s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1);
		this.nestedPocList = groupBy(this.filteredPocList, [{ field: 'companyName' }]) as GroupResult[];
	}
	handleCompanyFilter(value: string) {
		this.filteredCompanyList = this.companyList.filter((s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1);
	}
	handleProjectTypeFilter(value: string) {
		this.filteredProjectTypeList = this.projectTypeList.filter(
			(s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
		);
	}

	public onFilterSelectAllClick(filter: string, baseFilters: FilterItem[]) {
		const newValue = this.isSelectedAll(filter, baseFilters) ? [] : baseFilters.slice();
		this.filterValuesByField[filter] = newValue.reduce((prev, f) => prev.concat(f.value), []);
		this.updateFilters(filter, newValue);
	}
	public isSelectedAll(filter: string, baseFilters: FilterItem[]) {
		return !baseFilters.some((baseItem) => !(this.filterValuesByField[filter] || []).includes(baseItem));
	}
	public isIndet(filter: string, baseFilters: FilterItem[]): boolean {
		const selectedOfBase = (this.filterValuesByField[filter] || []).filter((selectedItem) =>
			baseFilters.includes(selectedItem)
		);
		return (selectedOfBase?.length || 0) !== 0 && selectedOfBase.length !== baseFilters.length;
	}

	public defaultFilters(isRisk?: boolean, isCost = false): ProjectFieldFilter[] {
		const filters: Array<ProjectFieldFilter> = [];
		filters.push({
			logic: 'or',
			text: 'companyName',
			filters: this.clientList.reduce((accumulator, f) => accumulator.concat(f.value), []),
		});
		filters.push({
			logic: 'or',
			text: 'scheduleType',
			filters: this.scheduleTypeList
				.filter((schedType) => schedType.text !== 'Archived')
				.reduce((accumulator, f) => accumulator.concat(f.value), []),
		});
		filters.push({
			logic: 'or',
			text: 'company',
			filters: this.companyList.reduce((accumulator, f) => accumulator.concat(f.value), []),
		});

		if (isRisk) {
			filters.push({
				logic: 'or',
				text: 'riskMetricsType',
				filters: this.riskList
					.filter(
						(risk) =>
							risk.value.value === 'performanceFactor' ||
							risk.value.value === 'riskRegister' ||
							risk.value.value === 'coreRisk'
					)
					.reduce((accumulator, f) => accumulator.concat(f.value), []),
			});
		} else {
			filters.push({
				logic: 'or',
				text: 'riskMetricsType',
				filters: this.riskList.reduce((accumulator, f) => accumulator.concat(f.value), []),
			});
		}
		filters.push({
			logic: 'or',
			text: 'pocId',
			filters: this.pocList.reduce((accumulator, f) => accumulator.concat(f.value), []),
		});
		filters.push({
			logic: 'or',
			text: 'clientId',
			filters: this.clientList.reduce((accumulator, f) => accumulator.concat(f.value), []),
		});
		filters.push({
			logic: 'or',
			text: 'projectType',
			filters: this.projectTypeList.reduce((accumulator, f) => accumulator.concat(f.value), []),
		});
		filters.push({
			logic: 'or',
			text: 'deltaVsCcd',
			filters: this.completionStatus.reduce((accumulator, f) => accumulator.concat(f.value), []),
		});
		filters.push({
			logic: 'or',
			text: 'prevVariance',
			filters: this.pastPeriodPerformanceList.reduce((accumulator, f) => accumulator.concat(f.value), []),
		});
		filters.push({
			logic: 'or',
			text: 'update-status',
			filters: [{ text: 'update-status', field: 'dUpload', operator: FilterOperator.IsNotNull, value: 0 }],
		});
		if (isCost) {
			filters.push({
				logic: 'or',
				text: 'cost-loaded',
				filters: [{ text: 'cost-loaded', field: 'costLoaded', operator: FilterOperator.EqualTo, value: true }],
			});
		} else {
			filters.push({
				logic: 'or',
				text: 'cost-loaded',
				filters: [{ text: 'cost-loaded', field: 'costLoaded', operator: FilterOperator.IsNotNull }],
			});
		}
		filters.push({
			logic: 'and',
			text: 'progressScore',
			filters: [
				{ text: 'progressScore', field: 'progressScore', operator: FilterOperator.GreaterThanOrEqual, value: 0 },
				{ text: 'progressScore', field: 'progressScore', operator: FilterOperator.LessThanOrEqual, value: 100 },
			],
		});
		filters.push({
			logic: 'and',
			text: 'projectScore',
			filters: [
				{ text: 'projectScore', field: 'projectScore', operator: FilterOperator.GreaterThanOrEqual, value: 0 },
				{ text: 'projectScore', field: 'projectScore', operator: FilterOperator.LessThanOrEqual, value: 100 },
			],
		});
		filters.push({
			logic: 'and',
			text: 'qcScore',
			filters: [
				{ text: 'qcScore', field: 'qcScore', operator: FilterOperator.GreaterThanOrEqual, value: 0 },
				{ text: 'qcScore', field: 'qcScore', operator: FilterOperator.LessThanOrEqual, value: 100 },
			],
		});
		filters.push({
			logic: 'and',
			text: 'predictabilityScore',
			filters: [
				{
					text: 'predictabilityScore',
					field: 'predictabilityScore',
					operator: FilterOperator.GreaterThanOrEqual,
					value: 0,
				},
				{
					text: 'predictabilityScore',
					field: 'predictabilityScore',
					operator: FilterOperator.LessThanOrEqual,
					value: 100,
				},
			],
		});
		filters.push({
			text: 'completionVar',
			filters: [
				{
					text: 'completionVar',
					field: 'cumulativeVariance',
					operator: FilterOperator.GreaterThanOrEqual,
					value: -Number.MAX_SAFE_INTEGER,
				},
				{
					text: 'completionVar',
					field: 'cumulativeVariance',
					operator: FilterOperator.LessThanOrEqual,
					value: Number.MAX_SAFE_INTEGER,
				},
			],
			logic: 'and',
		});
		return filters;
	}

	public filterDropdownClose(filter: string, baseFilters: FilterItem[]) {
		if (this.filterValuesByField[filter].length === 0) {
			this.onFilterSelectAllClick(filter, baseFilters);
		}
	}

	toggleMultiselect(multiselect: string): void {
		this.multiselectOptions.forEach((dropdown: string) => {
			this[dropdown].toggle(dropdown === multiselect ? !this[multiselect].isOpen : false);
		});
	}

	/**
	 * close any other open multiselect once this one is focused. called on the input click
	 * @param multiselect
	 */
	focus(multiselect: string): void {
		this.multiselectOptions.forEach((dropdown: string) => {
			if (dropdown !== multiselect) {
				this[dropdown].toggle(false);
			}
		});
	}

	protected readonly Math = Math;
}
