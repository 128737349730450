<div class="tab-page-container" id="focusTabComponent">
	<kendo-gridlayout
		[rows]="['42.4px', 'auto']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='grid'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container'>
			<kendo-gridlayout
				[rows]="['42.4px']"
				[cols]="['226px', 'calc(100% - 231px)']"
				[gap]="{ rows: 0, cols: 5 }"
				class='full-width'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
					<div class="card-header">
						Focus Areas
					</div>
<!--					<tooltip-open type='focus-areas'></tooltip-open>-->
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2' class='toolbar-item-container'>
					<span style='display: inline-flex; align-items: center;float: right' kendoTooltip>
						<div style='height: min-content; margin: auto 10px auto 0; display: flex; justify-content: flex-end; width: 100%; gap: 10px;'>
							<kendo-multiselecttree
								kendoMultiSelectTreeSummaryTag
								kendoMultiSelectTreeExpandable
								[kendoMultiSelectTreeHierarchyBinding]="allActivityCodes"
								[(value)]="selectedActivityCodes"
								textField="name"
								valueField="id"
								childrenField='subCodes'
								[valuePrimitive]='false'
								[filterable]='true'
								(valueChange)='filterChanged($event);'
								[clearButton]='false'
								[expandOnFilter]="{expandMatches: true}"
								[itemDisabled]="itemDisabled"
								[placeholder]='"Activity Code Filter"'
								style='width: 300px; margin: auto 0; cursor: pointer;'
								[disabled]='((projectService.$currentProjectData | async)?.updateIds.length || 0) <= 1'
								class="focusActvFilter"
							>
								<ng-template kendoMultiSelectTreeGroupTagTemplate let-dataItems>
									<span>
										<kendo-svg-icon [icon]='icons.caretDown'></kendo-svg-icon>
										{{ codesTag }}
									</span>
								</ng-template>
							</kendo-multiselecttree>
							<button kendoButton title="Save Changes" themeColor="none" class='header-btn save-btn' style='width: 50px; margin: auto 0;' (click)="saveChanges()" [disabled]="loading || !hasChanges">
								Save
							</button>
						</div>
					</span>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1' class="expansionpanel-container-focus-tab">
			@for (code of selectedActivityCodesForPanel; track code; let i = $index) {
				<kendo-expansionpanel
					[expanded]="code.expanded"
					(action)="onAction(code, i)"
					#expansionPanels
				>
					<ng-template kendoExpansionPanelTitleDirective>
						<span class="expansionpanel-title-container">
							<span class="panel-name">
								<span>
									{{code.name}}
								</span>
								@if (code.disabled) {
									<span style="font-size: 12px; color: #8e8e8e; vertical-align: text-top;">
										(No remaining Activities)
									</span>
								}
							</span>
							<span class="scores-container">
								<span class="panel-score" [style.background-color]="code?.progressScore >= 95 ? 'rgba(0, 89, 255, .9)' : code?.progressScore >= 80 ? 'rgba(79, 201, 49, .9)' : 'rgba(223, 83, 83, .9)'">
									Progress: @if (code.disabled || code?.progressScore === null) { <span style="padding-right: 2px;">-</span> } @else { {{code?.progressScore | number : '1.0-0'}}% }
								</span>
								<span class="panel-score" [style.background-color]="code?.qcScore >= 95 ? 'rgba(0, 89, 255, .9)' : code?.qcScore >= 80 ? 'rgba(79, 201, 49, .9)' : 'rgba(223, 83, 83, .9)'">
									QC: @if (code.disabled || code?.qcScore === null) { <span style="padding-right: 2px;">-</span> } @else { {{code?.qcScore | number : '1.0-0'}}% }
								</span>
								<span class="panel-score" [style.background-color]="code?.riskScore >= 85 ? 'rgba(0, 89, 255, .9)' : code?.riskScore >= 70 ? 'rgba(79, 201, 49, .9)' : 'rgba(223, 83, 83, .9)'">
									Risk: @if (code.disabled || code?.riskScore === null) { <span style="padding-right: 2px;">-</span> } @else { {{code?.riskScore | number : '1.0-0'}}% }
								</span>
								<span class="panel-score" [style.background-color]="code?.actvCompletion >= 95 ? 'rgba(0, 89, 255, .9)' : code?.actvCompletion >= 80 ? 'rgba(79, 201, 49, .9)' : 'rgba(223, 83, 83, .9)'">
									Activity Completion: @if (code.disabled || code?.actvCompletion === null) { <span style="padding-right: 2px;">-</span> } @else { {{code?.actvCompletion | number : '1.0-0'}}% }
								</span>
								<span class="panel-score" [style.background-color]="code?.floatConsumptionIsLarger >= 80 ? 'rgba(79, 201, 49, .9)' : 'rgba(223, 83, 83, .9)'">
									Float Consumption: @if (code.disabled || code?.floatConsumption === '') { <span style="padding-right: 2px;">-</span> } @else { {{code?.floatConsumption}} }
								</span>
							</span>
						</span>
					</ng-template>
					<div class="expansionpanel-content-container">
						@if (expansionPanels.expanded) {
							<app-activity-completion [hideTrending]="true" [hideNotes]="true" [defaultSelectedActivityCodes]="selectedActivityCodes"></app-activity-completion>
							<app-float-consumption [hideNotes]="true" [focusTabSelectedActvCode]="openPanel" [focusTabDisabledCode]="code.disabled"></app-float-consumption>
							<app-driving-path [hideNotes]="true" [isFocus]="true" [defaultFinishMilestoneCode]="lastActivityCode" [showOverlay]="code.disabled"></app-driving-path>
						}
					</div>
				</kendo-expansionpanel>
			}
			@if (selectedActivityCodesForPanel?.length === 0) {
				<div class="warning-div" style="background-color: white; height: 150px;">
					Select codes from the dropdown above.
				</div>
			}
		</kendo-gridlayout-item>
	</kendo-gridlayout>
</div>
