import { Component, OnInit } from '@angular/core';
import { GanttService, ReportGroup } from '../../../../../services/project/gantt.service';
import { BehaviorSubject } from 'rxjs';
import {
	copyIcon,
	filePdfIcon,
	folderAddIcon,
	folderIcon,
	pencilIcon,
	plusIcon,
	saveIcon,
	searchIcon,
	trashIcon,
} from '@progress/kendo-svg-icons';
@Component({
	selector: 'app-manage-reports',
	templateUrl: './manage-reports.component.html',
	styleUrls: ['./manage-reports.component.scss'],
})
export class ManageReportsComponent implements OnInit {
	_groups: ReportGroup[] = [];
	filteredReportIds: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>(null);
	icons = {
		pencil: pencilIcon,
		copy: copyIcon,
		trash: trashIcon,
		plus: plusIcon,
		save: saveIcon,
		folder: folderIcon,
		folderAdd: folderAddIcon,
		pdf: filePdfIcon,
	};
	selectedReportIds: string[] = [];
	loading: boolean = false;

	constructor(public ganttService: GanttService) {}

	ngOnInit() {
		this.ganttService.selectedGroup = ['all'];
		this.ganttService.$reportGroupsUpdated.subscribe((val: boolean) => {
			if (val) {
				this.fetchGroups();
			}
		});
		this.ganttService.$checkedReportChange.subscribe((val: string[]) => {
			if (val !== null) {
				this.updateSelectedReports(val);
			}
		});
	}

	fetchGroups(): void {
		const groups: ReportGroup[] = [
			{ id: 'all', name: 'All Reports', reportIds: null },
			{ id: 'favorites', name: 'Favorites', reportIds: null },
		];
		this.ganttService.reportGroups.forEach((group: ReportGroup) => {
			groups.push(group);
		});
		this._groups = groups;
		if (this.ganttService.selectedGroup[0] !== 'all') {
			if (this.ganttService.selectedGroup[0] === 'favorites') {
				this.filteredReportIds.next(
					Array.from(this.ganttService.reportById.values())
						.filter((r) => r.isFavorite)
						.map((v) => v.id)
				);
			} else {
				const updatedGroup: ReportGroup = groups.find((g: ReportGroup) => g.id === this.ganttService.selectedGroup[0]);
				if (updatedGroup !== undefined) {
					this.filteredReportIds.next(structuredClone(updatedGroup.reportIds));
				}
			}
		}
	}

	addGroup(): void {
		this.ganttService.editingGroup = null;
		this.ganttService.$addGroupOpen.next(true);
	}

	addToFolder(): void {
		this.ganttService.$addToFolderDialogOpen.next(true);
	}

	groupSelectionChange(ev): void {
		this.filteredReportIds.next(
			ev.dataItem.id === 'all'
				? null
				: ev.dataItem.id === 'favorites'
					? Array.from(this.ganttService.reportById.values())
							.filter((r) => r.isFavorite)
							.map((v) => v.id)
					: ev.dataItem.reportIds
		);
	}

	editGroup(ev, group: ReportGroup): void {
		this.ganttService.editingGroup = group;
		this.ganttService.$addGroupOpen.next(true);
		ev.cancelBubble = true;
		ev.stopPropagation();
	}

	copyGroup(ev, group: ReportGroup): void {
		this.ganttService.duplicateGroup = group;
		this.addGroup();
		ev.cancelBubble = true;
		ev.stopPropagation();
	}

	deleteGroup(ev, group: ReportGroup): void {
		this.ganttService.deletingGroup = group;
		this.ganttService.deleteGroupConfirmOpen = true;
		ev.cancelBubble = true;
		ev.stopPropagation();
	}

	updateSelectedReports(reportIds: string[]): void {
		this.selectedReportIds = reportIds;
	}

	batchExport(): void {
		this.ganttService.runBatchReportExport(this.selectedReportIds);
	}

	searchReports(ev): void {
		this.ganttService.$ganttPresetsUpdated.next(true);
	}

	protected readonly svgSearch = searchIcon;
}
