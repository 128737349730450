import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectListComponent } from './project-list/project-list.component';
import { RouterModule } from '@angular/router';
import { SharedMatModule } from 'components/shared/shared-mat.module';
import { HighchartsChartModule } from 'highcharts-angular';
import { ProjectCompletionTrendingModule } from '../../project-page/overview/project-completion-trending/project-completion-trending.module';
import { ProjectCompletionVarianceModule } from '../project-completion-variance/project-completion-variance.module';
import { IonicModule } from '@ionic/angular';
import { TableSortingDirective } from '../../../util/directives/save-table-sort.directive';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { ExpansionPanelModule, GridLayoutModule } from '@progress/kendo-angular-layout';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LabelModule } from '@progress/kendo-angular-label';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DirectivesModule } from '../../../util/directives/directives.module';
import { SaasRiskTopActiveRisksGridComponent } from './project-list/saas-risk-top-active-risks-grid/saas-risk-top-active-risks-grid.component';
import { PipeModule } from '../../../util/pipes/pipe.module';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { IconsModule } from '@progress/kendo-angular-icons';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { WindowModule } from '@progress/kendo-angular-dialog';

@NgModule({
	declarations: [ProjectListComponent, TableSortingDirective, SaasRiskTopActiveRisksGridComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		RouterModule,
		SharedMatModule,
		HighchartsChartModule,
		ProjectCompletionTrendingModule,
		ProjectCompletionVarianceModule,
		IonicModule,
		TooltipsModule,
		ExpansionPanelModule,
		GridModule,
		ButtonsModule,
		LabelModule,
		DropDownsModule,
		FormsModule,
		InputsModule,
		ChartsModule,
		DirectivesModule,
		GridLayoutModule,
		ExcelModule,
		PipeModule,
		IconsModule,
		LoaderModule,
		TreeViewModule,
		WindowModule,
	],
	exports: [ProjectListComponent],
})
export class ProjectListModule {}
