import { NgModule } from '@angular/core';
import { UpdateReportComponent } from './update-report.component';
import { AsyncPipe, CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { GridLayoutModule } from '@progress/kendo-angular-layout';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ButtonModule, FloatingActionButtonModule } from '@progress/kendo-angular-buttons';
import { IconModule, SVGIconModule } from '@progress/kendo-angular-icons';
import { FormsModule } from '@angular/forms';

@NgModule({
	declarations: [UpdateReportComponent],
	exports: [UpdateReportComponent],
	imports: [
		DatePipe,
		GridLayoutModule,
		AsyncPipe,
		DecimalPipe,
		PDFExportModule,
		ButtonModule,
		SVGIconModule,
		CommonModule,
		FloatingActionButtonModule,
		IconModule,
		FormsModule,
	],
})
export class UpdateReportModule {}
