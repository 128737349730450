<kendo-gridlayout
	[rows]="['100%']"
	[cols]="['100%']"
	[gap]="{ rows: 0, cols: 5 }"
	class='project-list-container'
	id="projectListComponent"
>
	<kendo-gridlayout-item [row]='1' [col]='1' style='border-radius: 0 0 4px 4px;'>
			<kendo-grid
				#grid
				[style.height.%]="100"
				[data]="gridView"
				[kendoGridBinding]="gridData"
				[rowHeight]='36'
				[detailRowHeight]='112'
				[selectable]='true'
				[loading]='loading'
				[size]='"small"'
				[sortable]='true'
				[resizable]='true'
				[sort]="sort"
				(sortChange)="sortChange($event)"
				[filter]='filter'
				(filterChange)="filterChange($event)"
				[rowSelected]="isRowSelected"
				(cellClick)='toggleRow($event)'
				(pageChange)="pageChange($event)"
				(excelExport)='onExcelExport($event)'
				[kendoGridExpandDetailsBy]="expandDetailsBy"
				[skip]="skip"
				[pageSize]="pageSize"
				[(expandedDetailKeys)]="expandedDetailKeys"
				scrollable="virtual"
				class='projects-grid'
				[navigable]="false"
				id='projectsGridProjectList'
				*ngIf='showGrid'
			>
				<ng-template kendoGridNoRecordsTemplate *ngIf="!loading">
					<p class='doneLoadingClass'>No Results for Applied Filters</p>
				</ng-template>

				<ng-template kendoGridNoRecordsTemplate *ngIf="loading">

				</ng-template>

				<kendo-grid-excel [fetchData]='allExportData'>
					<kendo-excelexport-column
						*ngFor='let column of visibleColumns'
						[title]="column.excelTitle"
						[field]='column.field'
						[width]="column.defaultWidth"
					>
					</kendo-excelexport-column>
				</kendo-grid-excel>
				<kendo-grid-column
					[headerClass]='["list-header"]'
					[width]='40'
				>
					<ng-template kendoGridCellTemplate let-dataItem>
						<svg xmlns="http://www.w3.org/2000/svg"
								 width="10"
								 height="10"
								 viewBox="0 0 10 10"
								 kendoTooltip
								 style='position: absolute; top: 0; left: 0;'
								 *ngIf='dataItem.missingUpdate'
						>
							<path d="M 0 0 L 0 10 L 10 0 z" fill="#ff8c00" title='Missing Update'/>
						</svg>
						<nav style='height: 24px; padding: 2px 0; cursor: pointer;'>
							<a (click)='navToProjectEvent()' routerLink="../project/{{ dataItem._id }}">
								<img
									src="/assets/icons/aegis/noun-dashboard-1102368-3B6BF9.svg"
									title='Go To Project'
									height="24"
									width="24" />
							</a>
						</nav>
					</ng-template>
				</kendo-grid-column>
				<kendo-grid-column
					*ngFor='let column of visibleColumns'
					[field]="column.field"
					[title]="column.title"
					[width]='column.width || column.defaultWidth'
					[headerClass]='["list-header"]'
					[class]='column?.isCentered? ["list-col", "center-text center-shrink"] : ["list-col"]'
				>

					<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.dataType === "string" && column.field === "name"'>
						<span style='white-space: nowrap; text-overflow: ellipsis;'>{{dataItem[column.field]}}</span>
					</ng-template>

					<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.dataType === "date"'>
						{{dataItem[column.field] | date: 'MMM d, yyyy'}}
					</ng-template>
					<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.dataType === "number"'>
						{{dataItem[column.field] | number: '1.0-0'}}
					</ng-template>
					<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.dataType === "twoDigitDecimalNumber"'>
						{{dataItem[column.field] | number: '1.0-2'}}
					</ng-template>
					<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.dataType === "currency"'>
						{{dataItem[column.field] === 0 ? '$0' : dataItem[column.field] | currency: 'USD' : '$' :'1.0-0'}}
					</ng-template>
					<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.field === "progressScore"'>
						{{dataItem.progressScore === null ? '-' : (dataItem.progressScore | number: '1.0-0')}}
					</ng-template>
				</kendo-grid-column>
				<kendo-grid-column
					title="Pre Mitigation"
					field='avgPreMitigationRiskScore'
					[headerClass]='["list-header"]'
					[width]='80'
					[class]='["list-col", "center-text"]'
					*ngIf='user?.userType === "saasRisk"'
				>
					<ng-template kendoGridCellTemplate let-dataItem>
						{{ dataItem?.avgPreMitigationRiskScore | number: '1.0-0' }}
					</ng-template>
				</kendo-grid-column>
				<kendo-grid-column
					title="Post Mitigation"
					field='avgPostMitigationRiskScore'
					[headerClass]='["list-header"]'
					[width]='80'
					[class]='["list-col", "center-text"]'
					*ngIf='user?.userType === "saasRisk"'
				>
					<ng-template kendoGridCellTemplate let-dataItem>
						{{ dataItem?.avgPostMitigationRiskScore | number: '1.0-0' }}
					</ng-template>
				</kendo-grid-column>
				<kendo-grid-column
					title="Delta"
					field='avgMitigationRiskScoreDelta'
					[headerClass]='["list-header"]'
					[width]='80'
					[class]='["list-col", "center-text"]'
					*ngIf='user?.userType === "saasRisk"'
				>
					<ng-template kendoGridCellTemplate let-dataItem>
						{{ dataItem?.avgMitigationRiskScoreDelta | number: '1.0-0' }}
					</ng-template>
				</kendo-grid-column>
				<kendo-grid-column
					title="Likelihood Impact"
					field='likelihoodImpact'
					[headerClass]='["list-header"]'
					[width]='80'
					[class]='["list-col", "center-text"]'
					*ngIf='user?.userType === "saasRisk"'
				>
					<ng-template kendoGridCellTemplate let-dataItem>
						{{ dataItem?.likelihoodImpact | number: '1.0-0' }}
					</ng-template>
				</kendo-grid-column>
				<kendo-grid-column
					title="Active Risks"
					field='activeRisks'
					[headerClass]='["list-header"]'
					[width]='80'
					[class]='["list-col", "center-text"]'
					*ngIf='user?.userType === "saasRisk"'
				>
					<ng-template kendoGridCellTemplate let-dataItem>
						{{ dataItem?.activeRisks | number: '1.0-0' }}
					</ng-template>
				</kendo-grid-column>
				<kendo-grid-column
					title="Monte Carlo"
					field='monteCarloShowing'
					[headerClass]='["list-header"]'
					[width]='80'
					[class]='["list-col", "center-text"]'
					*ngIf='user?.userType === "saasRisk"'
				>
					<ng-template kendoGridCellTemplate let-dataItem>
						{{ dataItem?.monteCarloShowing ? '✓' : '' }}
					</ng-template>
				</kendo-grid-column>
				<ng-template
					kendoGridDetailTemplate
					let-dataItem
					class='detail-row'
				>
					<kendo-gridlayout
						[rows]="['120px']"
						[cols]="['484px', 'calc(100% - 489px)']"
						[gap]="{ rows: 0, cols: 5 }"
						class="project-list-grid"
						id='project-list-score-grid'
						style="cursor: default;"
						*ngIf='user.userType !== "saasRisk"'
					>
						<kendo-gridlayout-item [row]="1" [col]="1">
							<kendo-gridlayout
								[rows]="['calc(50% - 2.5px)', 'calc(50% - 2.5px)']"
								[cols]="['305px', 'calc((100% - 305px) / 2 - 5px)', 'calc((100% - 305px) / 2 - 5px)']"
								[gap]="{ rows: 5, cols: 5}"
								style="height: 120px;"
							>
								<kendo-gridlayout-item [row]="1" [col]="2" class='gridlayout-item'>
									<div class='box no-pad' [class.green-bg]='dataItem.aegisScore >= 70' style="border: none">
										<div class='test-label' style="font-weight: bold;">
											Project
										</div>
										<div class='test-label'>
											{{ (dataItem.aegisScore | number: '1.0-0') || 0 }}%
										</div>
									</div>
								</kendo-gridlayout-item>
								<kendo-gridlayout-item [row]="1" [col]="3" class='gridlayout-item'>
									<div class='box no-pad' [class.green-bg]='dataItem.rawReport?.progressScore >= 80' style="border: none">
										<div class='test-label' style="font-weight: bold;">
											Progress
										</div>
										<div class='test-label' *ngIf="dataItem?.updatesPerProject > 0">
											{{ ((dataItem.rawReport?.progressScore | number: '1.0-0') + '%' || '0%') }}
										</div>
										<div class='test-label' *ngIf="dataItem?.updatesPerProject <= 0">
											N/A
										</div>
									</div>
								</kendo-gridlayout-item>
								<kendo-gridlayout-item [row]="2" [col]="2" class='gridlayout-item'>
									<div class='box no-pad' [class.green-bg]='dataItem.qcScore >= 80' style="border: none">
										<div class='test-label' style="font-weight: bold;">
											QC
										</div>
										<div class='test-label'>
											{{ (dataItem.qcScore | number: '1.0-0') || 0 }}%
										</div>
									</div>
								</kendo-gridlayout-item>
								<kendo-gridlayout-item [row]="2" [col]="3" class='gridlayout-item'>
									<div class='box no-pad' [class.green-bg]='dataItem.rawReport?.riskScore >= 70' style="border: none">
										<div class='test-label' style="font-weight: bold;">
											Risk
										</div>
										<div class='test-label'>
											{{ (dataItem.rawReport?.riskScore | number: '1.0-0') || 0 }}%
										</div>
									</div>
								</kendo-gridlayout-item>
								<kendo-gridlayout-item [row]="1" [col]="1" rowSpan="2" class='gridlayout-item details-container'>
									<div class="details-item-row">
										<div class="details-label">
											Client:
										</div>
										<div class="details-val">
											{{dataItem?.clientName}}
										</div>
									</div>
									<div class="details-item-row">
										<div class="details-label">
											Market Segment:
										</div>
										<div class="details-val">
											{{dataItem?.market}}
										</div>
									</div>
									<div class="details-item-row">
										<div class="details-label">
											Company:
										</div>
										<div class="details-val">
											{{dataItem?.companyName}}
										</div>
									</div>
									<div class="details-item-row">
										<div class="details-label">
											Point of Contact:
										</div>
										<div class="details-val">
											{{dataItem?.pocName}}
										</div>
									</div>
									<div class="details-item-row">
										<div class="details-label">
											Project Type:
										</div>
										<div class="details-val">
											{{dataItem?.scheduleType}}
										</div>
									</div>
								</kendo-gridlayout-item>
							</kendo-gridlayout>
						</kendo-gridlayout-item>
						<kendo-gridlayout-item [row]="1" [col]="2">
							<div class='project-detail-variance'>
								<kendo-chart [seriesColors]='["black"]'>
									<kendo-chart-area [margin]="6" [height]='120' background='#f5f5f5'> </kendo-chart-area>
									<kendo-chart-category-axis>
										<kendo-chart-category-axis-item
											[maxDivisions]="5"
											[crosshair]="{ color: '#bbbbbb', visible: true, width: 1 }"
											[majorGridLines]='{visible: false}'
										>
											<kendo-chart-category-axis-item-labels
												[position]="'start'"
												[step]="Math.ceil(this.projectVarianceSeries?.length / 8)"
											>
											</kendo-chart-category-axis-item-labels>
										</kendo-chart-category-axis-item>
									</kendo-chart-category-axis>
									<kendo-chart-value-axis>
										<kendo-chart-value-axis-item
											[line]='{visible: false}'
											[plotBands]="valuePlotBands"
											pane='pane'
										>
											<kendo-chart-value-axis-item-labels [content]="varianceLabel" [rotation]='"auto"' [step]="2">
											</kendo-chart-value-axis-item-labels>
										</kendo-chart-value-axis-item>
									</kendo-chart-value-axis>
									<!--<kendo-chart-title text="Project Completion Variance" [padding]='0' [margin]='0'></kendo-chart-title>-->
									<kendo-chart-subtitle [text]="projectCompletionSubtitleName" [padding]='0' [margin]='0'></kendo-chart-subtitle>
									<kendo-chart-tooltip [shared]='true'>
										<ng-template kendoChartSeriesTooltipTemplate let-value="value" let-dataItem="dataItem" let-point="point">
											<div>
												<b>{{dataItem.dataDate | date: 'MM/dd/yyyy'}}</b>
											</div>
											<div>
												{{hasConstraints ? 'Contract Δ' : 'Cumulative Δ'}}: <b>{{value}} cd</b>
											</div>
										</ng-template>
									</kendo-chart-tooltip>
									<kendo-chart-series>
										<kendo-chart-series-item
											type="line"
											[style]="'smooth'"
											[data]="projectVarianceSeries"
											field='completionVariance'
											categoryField='updateName'
										>
										</kendo-chart-series-item>
									</kendo-chart-series>
									<kendo-chart-x-axis>
										<kendo-chart-x-axis-item [visible]="true">
										</kendo-chart-x-axis-item>
									</kendo-chart-x-axis>
									<kendo-chart-y-axis>
										<kendo-chart-y-axis-item [visible]="true">
										</kendo-chart-y-axis-item>
									</kendo-chart-y-axis>
									<kendo-chart-panes>
										<kendo-chart-pane name="pane" clip="false">
										</kendo-chart-pane>
									</kendo-chart-panes>
								</kendo-chart>
							</div>
						</kendo-gridlayout-item>
					</kendo-gridlayout>
					<kendo-gridlayout
						[rows]="['260px']"
						[cols]="['calc(10% - 4px)', 'calc(10% - 4px)', 'calc(80% - 4px)']"
						[gap]="{ rows: 0, cols: 5 }"
						class='grid'
						*ngIf='user.userType === "saasRisk"'
					>
						<kendo-gridlayout-item [row]='1' [col]='1' style='border-radius: 4px;' class='gridlayout-container'>
							<kendo-gridlayout
								[rows]="['calc(50% - 2.5px)', 'calc(50% - 2.5px)']"
								[cols]="['100%']"
								[gap]="{ rows: 5, cols: 0 }"
								class='grid'
							>
								<kendo-gridlayout-item [row]='1' [col]='1' class='expanded-row-mitigation-score-card'>
									<div class="centerBoth" style="color: #df5353e6;">{{ ($avgPreMitigationScore | async) || '-' }}</div>
									<div class="filterBoxLabel">Pre Mitigation Risk Score</div>
								</kendo-gridlayout-item>
								<kendo-gridlayout-item [row]='2' [col]='1' class='expanded-row-mitigation-score-card'>
									<div class="centerBoth" style="color: #4fc931e6;">{{ ($avgPostMitigationScore | async) || '-' }}</div>
									<div class="filterBoxLabel">Post Mitigation Risk Score</div>
								</kendo-gridlayout-item>
							</kendo-gridlayout>
						</kendo-gridlayout-item>
						<kendo-gridlayout-item [row]='1' [col]='2' style='background-color: #df5353e6; border-radius: 4px;' class='gridlayout-container'>
							<div class="centerBoth" style="color: white">{{ ($avgRiskAssessmentScore | async) ? (($avgRiskAssessmentScore | async) + '%') : '-' }}</div>
							<div class="filterBoxLabel" style='color: white'>Risk Assessment</div>
						</kendo-gridlayout-item>
						<kendo-gridlayout-item [row]='1' [col]='3'>
							<app-saas-risk-top-active-risks [riskMitigations]='$selectedProjectRiskMitigations'></app-saas-risk-top-active-risks>
						</kendo-gridlayout-item>
					</kendo-gridlayout>
				</ng-template>
			</kendo-grid>
	</kendo-gridlayout-item>
	<ng-template #columnSelectorPopupTemplate>
		<div id='columnSelectorContainer'>
			<div class='columns-btns-container' style="width: 272px;">
				<button
					kendoButton
					[size]="'small'"
					fillMode="outline"
					[rounded]="'medium'"
					[themeColor]="'info'"
					(click)='resetColumns()'
					style='display: block; width: 85%;'
				>
					Reset
				</button>
				<button
					kendoButton
					[size]="'small'"
					[rounded]="'medium'"
					fillMode="outline"
					[themeColor]="'primary'"
					style='display: block; width: 15%;'
					(click)="closePopup('columns')"
				>
					X
				</button>
			</div>
			<div style='max-height: 300px; overflow: auto;'>
				<kendo-treeview
					[nodes]="allGroups.groups"
					[children]="children"
					[hasChildren]="hasChildren"
					[isDisabled]='isDisabled'
					textField="title"
					kendoTreeViewExpandable
					[expandedKeys]="expandedKeys"
					[kendoTreeViewCheckable]="{checkParents: true}"
					[(checkedKeys)]="visibleProjectListColumns"
					[loadOnDemand]="false"
				>
				</kendo-treeview>
			</div>
		</div>
	</ng-template>
</kendo-gridlayout>

<ng-template #filterWindowContentTemplate>
	<div style='border-radius: 0 0 4px 4px; background-color: white;' id='popupFiltersContainer'>
		<div style='overflow-x: hidden; overflow-y: auto;'>
			<div class='filters-container' *ngIf='user?.userType !== "saasRisk"'>
				<fieldset class='vertical-fieldset' style='margin-top: -5px;'>
					<legend>Project Filters</legend>
					<div class='fieldset-content'>
						<div class='two-column-filters border-container'>
							<kendo-label text="Client">
								<kendo-multiselect
									kendoMultiSelectSummaryTag
									[data]="nestedClientList"
									[(ngModel)]="filterValuesByField['clientId']"
									textField="text"
									valueField="value"
									[checkboxes]='true'
									[filterable]="true"
									[autoClose]='false'
									(filterChange)='handleClientFilter($event)'
									(valueChange)='filterChanged("clientId", $event)'
									[clearButton]='false'
									#clientIdMultiselect
									(focus)="focus('clientIdMultiselect')"
								>
									<ng-template kendoMultiSelectFixedGroupTemplate kendoMultiSelectGroupTemplate let-groupName>
										<strong>{{ groupName }}</strong>
									</ng-template>
									<!--We need to do this custom template because [checkboxes] doesn't work with grouped multiselect-->
									<ng-template kendoMultiSelectItemTemplate let-dataItem>
										<span class='poc-item'>
											<input type="checkbox" kendoCheckBox [checked]='isChecked("clientId", dataItem)'/>
											<span class='poc-name'>
												{{ dataItem.text }}
											</span>
										</span>
									</ng-template>
									<ng-template kendoMultiSelectHeaderTemplate>
										<div>
											<input
												kendoCheckBox
												type='checkbox'
												[checked]="isSelectedAll('clientId', filteredClientList)"
												[indeterminate]="isIndet('clientId', filteredClientList)"
												(click)="onFilterSelectAllClick('clientId', filteredClientList)"
											/>
											<kendo-label for="chk">Select all</kendo-label>
										</div>
									</ng-template>
									<ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
										<div class="invisible-multiselect-tag-selector" (click)="toggleMultiselect('clientIdMultiselect')"></div>
										<span>
											<kendo-svg-icon [icon]='icons.caretDown' (click)="toggleMultiselect('clientIdMultiselect')"></kendo-svg-icon>
											{{ dataItems.length + ' selected' }}
										</span>
									</ng-template>
								</kendo-multiselect>
							</kendo-label>
							<kendo-label text="Project Type">
								<kendo-multiselect
									kendoMultiSelectSummaryTag
									[data]="scheduleTypeList"
									[(ngModel)]="filterValuesByField['scheduleType']"
									textField="text"
									valueField="value"
									[valuePrimitive]='false'
									[checkboxes]='true'
									[autoClose]='false'
									(valueChange)='filterChanged("scheduleType", $event)'
									[clearButton]='false'
									#scheduleTypeMultiselect
									(focus)="focus('scheduleTypeMultiselect')"
								>
									<ng-template kendoMultiSelectHeaderTemplate>
										<div>
											<input
												kendoCheckBox
												type='checkbox'
												[checked]="isSelectedAll('scheduleType', scheduleTypeList)"
												[indeterminate]="isIndet('scheduleType', scheduleTypeList)"
												(click)="onFilterSelectAllClick('scheduleType', scheduleTypeList)"
											/>
											<kendo-label for="chk">Select all</kendo-label>
										</div>
									</ng-template>
									<ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
										<div class="invisible-multiselect-tag-selector" (click)="toggleMultiselect('scheduleTypeMultiselect')"></div>
										<span id='archivedTagTemplate'>
											<kendo-svg-icon [icon]='icons.caretDown' (click)="toggleMultiselect('scheduleTypeMultiselect')"></kendo-svg-icon>
											{{ dataItems.length + ' selected' }}
										</span>
									</ng-template>
								</kendo-multiselect>
							</kendo-label>
							<kendo-label text="Company">
								<kendo-multiselecttree
									#companyMultiselect
									kendoMultiSelectTreeSummaryTag
									kendoMultiSelectTreeExpandable
									[kendoMultiSelectTreeHierarchyBinding]="nestedCompanyList"
									[(value)]="filterValuesByField['company']"
									textField="text"
									valueField="value.value"
									childrenField='children'
									[valuePrimitive]='false'
									[filterable]='true'
									(valueChange)='filterChanged("company", $event);'
									[clearButton]='false'
									[(expandedKeys)]="expandedCompanyNodes"
									(focus)="focus('companyMultiselectTree')"
								>
									<ng-template kendoMultiSelectHeaderTemplate>
										<div>
											<input
												kendoCheckBox
												type='checkbox'
												[checked]="isSelectedAll('company', companyList)"
												[indeterminate]="isIndet('company', companyList)"
												(click)="onFilterSelectAllClick('company', companyList)"
											/>
											<kendo-label for="chk">Select all</kendo-label>
										</div>
									</ng-template>
									<ng-template kendoMultiSelectTreeGroupTagTemplate let-dataItems>
								<span id='regionTagTemplate'>
									<kendo-svg-icon [icon]='icons.caretDown'></kendo-svg-icon>
									{{ dataItems.length + ' selected' }}
								</span>
									</ng-template>
								</kendo-multiselecttree>
								<a href='https://account.rhino.works/onboarding/analytics/' class='a-link' style='margin-bottom: -10px; margin-top: 5px;'>Reload Companies</a>
							</kendo-label>
							<kendo-label text="Risk Metrics Type">
								<kendo-multiselect
									kendoMultiSelectSummaryTag
									[data]="riskList"
									[(ngModel)]="filterValuesByField['riskMetricsType']"
									textField="text"
									valueField="value"
									[checkboxes]='true'
									[autoClose]='false'
									(valueChange)='filterChanged("riskMetricsType", $event)'
									[clearButton]='false'
									#rmtMultiselect
									(focus)="focus('riskMetricsTypeMultiselect')"
								>
									<ng-template kendoMultiSelectHeaderTemplate>
										<div>
											<input
												kendoCheckBox
												type='checkbox'
												[checked]="isSelectedAll('riskMetricsType', riskList)"
												[indeterminate]="isIndet('riskMetricsType', riskList)"
												(click)="onFilterSelectAllClick('riskMetricsType', riskList)"
											/>
											<kendo-label for="chk">Select all</kendo-label>
										</div>
									</ng-template>
									<ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
										<div class="invisible-multiselect-tag-selector" (click)="toggleMultiselect('riskMetricsTypeMultiselect')"></div>
										<span id='rmtTagTemplate'>
											<kendo-svg-icon [icon]='icons.caretDown' (click)="toggleMultiselect('riskMetricsTypeMultiselect')"></kendo-svg-icon>
											{{ dataItems.length + ' selected' }}
										</span>
									</ng-template>
								</kendo-multiselect>
							</kendo-label>
							<kendo-label text="Market">
								<kendo-multiselect
									kendoMultiSelectSummaryTag
									[data]="filteredProjectTypeList"
									[(ngModel)]="filterValuesByField['projectType']"
									textField="text"
									valueField="value"
									[checkboxes]='true'
									[autoClose]='false'
									[filterable]='true'
									(filterChange)='handleProjectTypeFilter($event)'
									(valueChange)='filterChanged("projectType", $event)'
									[clearButton]='false'
									#projectTypeMultiselect
									(focus)="focus('projectTypeMultiselect')"
								>
									<ng-template kendoMultiSelectHeaderTemplate>
										<div>
											<input
												kendoCheckBox
												type='checkbox'
												[checked]="isSelectedAll('projectType', filteredProjectTypeList)"
												[indeterminate]="isIndet('projectType', filteredProjectTypeList)"
												(click)="onFilterSelectAllClick('projectType', filteredProjectTypeList)"
											/>
											<kendo-label>Select all</kendo-label>
										</div>
									</ng-template>
									<ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
										<div class="invisible-multiselect-tag-selector" (click)="toggleMultiselect('projectTypeMultiselect')"></div>
										<span id='projectTypeTagTemplate'>
											<kendo-svg-icon [icon]='icons.caretDown' (click)="toggleMultiselect('projectTypeMultiselect')"></kendo-svg-icon>
											{{ dataItems.length + ' selected' }}
										</span>
									</ng-template>
								</kendo-multiselect>
							</kendo-label>
							<kendo-label text="Point of Contact">
								<kendo-multiselect
									kendoMultiSelectSummaryTag
									[data]="filteredPocList"
									[(ngModel)]="filterValuesByField['pocId']"
									textField="text"
									valueField="value"
									[filterable]="true"
									[checkboxes]='true'
									[autoClose]='false'
									(filterChange)='handlePocFilter($event)'
									(valueChange)='filterChanged("pocId", $event)'
									[clearButton]='false'
									#pocIdMultiselect
									(focus)="focus('pocIdMultiselect')"
								>
									<!--								<ng-template kendoMultiSelectFixedGroupTemplate kendoMultiSelectGroupTemplate let-groupName>
																		<strong>{{ groupName }}</strong>
																	</ng-template>
																	&lt;!&ndash;We need to do this custom template because [checkboxes] doesn't work with grouped multiselect&ndash;&gt;
																	<ng-template kendoMultiSelectItemTemplate let-dataItem>
																		<span class='poc-item'>
																			<input type="checkbox" kendoCheckBox [checked]='filterValuesByField["pocId"].includes(dataItem)'/>
																			<span class='poc-name'>
																				{{ dataItem.text }}
																			</span>

																		</span>

																	</ng-template>-->
									<ng-template kendoMultiSelectHeaderTemplate>
										<div>
											<input
												kendoCheckBox
												type='checkbox'
												[checked]="isSelectedAll('pocId', filteredPocList)"
												[indeterminate]="isIndet('pocId', filteredPocList)"
												(click)="onFilterSelectAllClick('pocId', filteredPocList)"
											/>
											<kendo-label for="chk">Select all</kendo-label>
										</div>
									</ng-template>
									<ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
										<div class="invisible-multiselect-tag-selector" (click)="toggleMultiselect('pocIdMultiselect')"></div>
										<span>
											<kendo-svg-icon [icon]='icons.caretDown' (click)="toggleMultiselect('pocIdMultiselect')"></kendo-svg-icon>
											{{ dataItems.length + ' selected' }}
										</span>
									</ng-template>
								</kendo-multiselect>
							</kendo-label>
							<div class='update-status-container' style='padding-top: 0;'>
								<div class='update-status-title'>
									Cost-Loaded:
								</div>
								<div class='checkbox-container'>
									<input type="radio" size='large' #yesCost kendoRadioButton [(ngModel)]="costLoaded" value='yes' (ngModelChange)='costLoadedCheckboxChanged()' name='costLoaded'/>
									<kendo-label
										class="k-checkbox-label"
										[for]="yesCost"
										text="Yes"
									></kendo-label>
									<input type="radio" size='large' #noCost kendoRadioButton name='costLoaded' style='margin-left: 10px;' [(ngModel)]="costLoaded" value='no' (ngModelChange)='costLoadedCheckboxChanged()'/>
									<kendo-label
										class="k-checkbox-label"
										[for]="noCost"
										text="No"
									></kendo-label>
									<input type="radio" size='large' #allCost kendoRadioButton name='costLoaded' style='margin-left: 10px;' [(ngModel)]="costLoaded" value='all' (ngModelChange)='costLoadedCheckboxChanged()'/>
									<kendo-label
										class="k-checkbox-label"
										[for]="allCost"
										text="All"
									></kendo-label>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
				<fieldset class='vertical-fieldset' style='margin-top: -5px;'>
					<legend>Status Filters</legend>
					<div class='fieldset-content'>
						<div>
							<kendo-gridlayout
								[rows]="['calc(100% / 3)', 'calc(100% / 3)', 'calc(100% / 3)']"
								[cols]="['100%']"
								[gap]="{ rows: 0, cols: 0 }"
								class='grid'
							>
								<kendo-gridlayout-item [row]='1' [col]='1'>
									<kendo-gridlayout
										[rows]="['100%']"
										[cols]="['calc(50% - 2.5px)', 'calc(50% - 2.5px)']"
										[gap]="{ rows: 0, cols: 5 }"
										class='grid'
									>
										<kendo-gridlayout-item [row]='1' [col]='1' class='portfolio-filter-container'>
											<kendo-label text="Current Status">
												<kendo-multiselect
													kendoMultiSelectSummaryTag
													[data]="completionStatus"
													[(ngModel)]="filterValuesByField['deltaVsCcd']"
													textField="text"
													valueField="value"
													[checkboxes]='true'
													[autoClose]='false'
													(ngModelChange)='filterChanged("deltaVsCcd", $event)'
													[clearButton]='false'
													#deltaVsCcdMultiselect
													(focus)="focus('deltaVsCcdMultiselect')"
												>
													<ng-template kendoMultiSelectHeaderTemplate>
														<div>
															<input
																kendoCheckBox
																type='checkbox'
																[checked]="isSelectedAll('deltaVsCcd', completionStatus)"
																[indeterminate]="isIndet('deltaVsCcd', completionStatus)"
																(click)="onFilterSelectAllClick('deltaVsCcd', completionStatus)"
															/>
															<kendo-label for="chk">Select all</kendo-label>
														</div>
													</ng-template>
													<ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
														<div class="invisible-multiselect-tag-selector" (click)="toggleMultiselect('deltaVsCcdMultiselect')"></div>
														<span id='completionStatusTagTemplate'>
															<kendo-svg-icon [icon]='icons.caretDown' (click)="toggleMultiselect('deltaVsCcdMultiselect')"></kendo-svg-icon>
															{{ dataItems.length + ' selected' }}
														</span>
													</ng-template>
												</kendo-multiselect>
											</kendo-label>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='1' [col]='2' class='portfolio-filter-container'>
											<kendo-label text="Past Performance">
												<kendo-multiselect
													kendoMultiSelectSummaryTag
													[data]="pastPeriodPerformanceList"
													[(ngModel)]="filterValuesByField['prevVariance']"
													textField="text"
													valueField="value"
													[checkboxes]='true'
													[autoClose]='false'
													(ngModelChange)='filterChanged("prevVariance", $event)'
													[clearButton]='false'
													#prevVarMultiselect
													(focus)="focus('prevVarianceMultiselect')"
												>
													<ng-template kendoMultiSelectHeaderTemplate>
														<div>
															<input
																kendoCheckBox
																type='checkbox'
																[checked]="isSelectedAll('prevVariance', pastPeriodPerformanceList)"
																[indeterminate]="isIndet('prevVariance', pastPeriodPerformanceList)"
																(click)="onFilterSelectAllClick('prevVariance', pastPeriodPerformanceList)"
															/>
															<kendo-label for="chk">Select all</kendo-label>
														</div>
													</ng-template>
													<ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
														<div class="invisible-multiselect-tag-selector" (click)="toggleMultiselect('prevVarianceMultiselect')"></div>
														<span id='prevVarianceTagTemplate'>
															<kendo-svg-icon [icon]='icons.caretDown' (click)="toggleMultiselect('prevVarianceMultiselect')"></kendo-svg-icon>
															{{ dataItems.length + ' selected' }}
														</span>
													</ng-template>
												</kendo-multiselect>
											</kendo-label>
										</kendo-gridlayout-item>
									</kendo-gridlayout>
								</kendo-gridlayout-item>
								<kendo-gridlayout-item [row]='2' [col]='1'>
									<div class='slider-filter-container' style='padding-top: 20px;'>
										<kendo-label text="Baseline Δ :">
											<span *ngIf='sliderValues.completionVar[0] !== 0 || sliderValues.completionVar[1] !== 5' class='slider-value-label-container'>{{completionVarLabel}} <button kendoButton [svgIcon]='icons.closeCircle' fillMode='clear' (click)='resetCompletionVariance()' class='slider-reset-btn'></button></span>
											<kendo-rangeslider
												[(ngModel)]="sliderValues.completionVar"
												[min]="0"
												[max]="5"
												[largeStep]="1"
												[title]="completionVarianceTickTitle"
												[tickPlacement]="sliderSettings.tickPlacement"
												(valueChange)='updateCompletionVarianceSliderValue($event)'
												(afterValueChanged)='completionVarianceSliderValueChanged($event)'
												class='compl-var-slider'
											></kendo-rangeslider>
										</kendo-label>
									</div>
								</kendo-gridlayout-item>
								<kendo-gridlayout-item [row]='3' [col]='1'>
									<div class='update-status-container'>
										<div class='update-status-title'>
											Update Status:
										</div>
										<div class='checkbox-container'>
											<input type="radio" size='large' #current kendoRadioButton [(ngModel)]="updateStatus" value='current' (ngModelChange)='updateStatusCheckboxChanged()' name='updateStatus'/>
											<kendo-label
												class="k-checkbox-label"
												[for]="current"
												text="Current"
											></kendo-label>
											<input type="radio" size='large' #missing kendoRadioButton name='updateStatus' style='margin-left: 10px;' [(ngModel)]="updateStatus" value='missing' (ngModelChange)='updateStatusCheckboxChanged()'/>
											<kendo-label
												class="k-checkbox-label"
												[for]="missing"
												text="Missing"
											></kendo-label>
											<input type="radio" size='large' #all kendoRadioButton name='updateStatus' style='margin-left: 10px;' [(ngModel)]="updateStatus" value='all' (ngModelChange)='updateStatusCheckboxChanged()'/>
											<kendo-label
												class="k-checkbox-label"
												[for]="all"
												text="All"
											></kendo-label>
										</div>
									</div>
								</kendo-gridlayout-item>
							</kendo-gridlayout>
						</div>
					</div>
				</fieldset>
				<fieldset class='vertical-fieldset' style='margin-top: -5px;'>
					<legend>KPI Filters</legend>
					<div class='fieldset-content'>
						<div class='sliders-container'>
							<div class='slider-filter-container'>
								<kendo-label text="Project Score:">
									<span *ngIf='sliderValues.projectScore[0] !== 0 || sliderValues.projectScore[1] !== 100' class='slider-value-label-container'>{{sliderValues.projectScore[0]}} - {{sliderValues.projectScore[1]}} <button kendoButton [svgIcon]='icons.closeCircle' fillMode='clear' (click)='resetSliderValue("projectScore")' class='slider-reset-btn'></button></span>
									<kendo-rangeslider
										[(ngModel)]="sliderValues.projectScore"
										[min]="sliderSettings.min"
										[max]="sliderSettings.max"
										[smallStep]="sliderSettings.smallStep"
										[largeStep]="sliderSettings.largeStep"
										[tickPlacement]="sliderSettings.tickPlacement"
										(valueChange)='updateSliderValue($event, "projectScore")'
										(afterValueChanged)='sliderValueChanged($event, "projectScore")'
										class='score-slider'
									></kendo-rangeslider>
								</kendo-label>
							</div>
							<div class='slider-filter-container'>
								<kendo-label text="Progress Score:">
									<span *ngIf='sliderValues.progressScore[0] !== 0 || sliderValues.progressScore[1] !== 100' class='slider-value-label-container'>{{sliderValues.progressScore[0]}} - {{sliderValues.progressScore[1]}} <button kendoButton [svgIcon]='icons.closeCircle' fillMode='clear' (click)='resetSliderValue("progressScore")' class='slider-reset-btn'></button></span>
									<kendo-rangeslider
										[(ngModel)]="sliderValues.progressScore"
										[min]="sliderSettings.min"
										[max]="sliderSettings.max"
										[smallStep]="sliderSettings.smallStep"
										[largeStep]="sliderSettings.largeStep"
										[tickPlacement]="sliderSettings.tickPlacement"
										(valueChange)='updateSliderValue($event, "progressScore")'
										(afterValueChanged)='sliderValueChanged($event, "progressScore")'
										class='score-slider'
									></kendo-rangeslider>
								</kendo-label>
							</div>
							<div class='slider-filter-container'>
								<kendo-label text="QC Score:">
									<span *ngIf='sliderValues.qcScore[0] !== 0 || sliderValues.qcScore[1] !== 100' class='slider-value-label-container'>{{sliderValues.qcScore[0]}} - {{sliderValues.qcScore[1]}} <button kendoButton [svgIcon]='icons.closeCircle' fillMode='clear' (click)='resetSliderValue("qcScore")' class='slider-reset-btn'></button></span>
									<kendo-rangeslider
										[(ngModel)]="sliderValues.qcScore"
										[min]="sliderSettings.min"
										[max]="sliderSettings.max"
										[smallStep]="sliderSettings.smallStep"
										[largeStep]="sliderSettings.largeStep"
										[tickPlacement]="sliderSettings.tickPlacement"
										(valueChange)='updateSliderValue($event, "qcScore")'
										(afterValueChanged)='sliderValueChanged($event, "qcScore")'
										class='score-slider'
									></kendo-rangeslider>
								</kendo-label>
							</div>
							<div class='slider-filter-container'>
								<kendo-label text="Risk Score:">
									<span *ngIf='sliderValues.predictabilityScore[0] !== 0 || sliderValues.predictabilityScore[1] !== 100' class='slider-value-label-container'>{{sliderValues.predictabilityScore[0]}} - {{sliderValues.predictabilityScore[1]}} <button kendoButton [svgIcon]='icons.closeCircle' fillMode='clear' (click)='resetSliderValue("predictabilityScore")' class='slider-reset-btn'></button></span>
									<kendo-rangeslider
										[(ngModel)]="sliderValues.predictabilityScore"
										[min]="sliderSettings.min"
										[max]="sliderSettings.max"
										[smallStep]="sliderSettings.smallStep"
										[largeStep]="sliderSettings.largeStep"
										[tickPlacement]="sliderSettings.tickPlacement"
										(valueChange)='updateSliderValue($event, "predictabilityScore")'
										(afterValueChanged)='sliderValueChanged($event, "predictabilityScore")'
										class='score-slider'
									></kendo-rangeslider>
								</kendo-label>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
			<div class='filters-container' style='grid-template-columns: unset; height: 100px;' *ngIf='user?.userType === "saasRisk"'>
				<fieldset style='margin-top: -5px;'>
					<legend style='padding: 0 2px;'>Filters</legend>
					<div class='saas-risk-filter-container'>
						<kendo-label text="Client">
							<kendo-multiselect
								kendoMultiSelectSummaryTag
								[data]="nestedClientList"
								[(ngModel)]="filterValuesByField['clientId']"
								textField="text"
								valueField="value"
								[checkboxes]='true'
								[filterable]="true"
								[autoClose]='false'
								(filterChange)='handleClientFilter($event)'
								(valueChange)='filterChanged("clientId", $event)'
								[clearButton]='false'
							>
								<ng-template kendoMultiSelectFixedGroupTemplate kendoMultiSelectGroupTemplate let-groupName>
									<strong>{{ groupName }}</strong>
								</ng-template>
								<!--We need to do this custom template because [checkboxes] doesn't work with grouped multiselect-->
								<ng-template kendoMultiSelectItemTemplate let-dataItem>
								<span class='poc-item'>
									<input type="checkbox" kendoCheckBox [checked]='filterValuesByField["clientId"].includes(dataItem)'/>
									<span class='poc-name'>
										{{ dataItem.text }}
									</span>

								</span>

								</ng-template>
								<ng-template kendoMultiSelectHeaderTemplate>
									<div>
										<input
											kendoCheckBox
											type='checkbox'
											[checked]="isSelectedAll('clientId', filteredClientList)"
											[indeterminate]="isIndet('clientId', filteredClientList)"
											(click)="onFilterSelectAllClick('clientId', filteredClientList)"
										/>
										<kendo-label for="chk">Select all</kendo-label>
									</div>
								</ng-template>
								<ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
									<span>
										<kendo-svg-icon [icon]='icons.caretDown'></kendo-svg-icon>
										{{ dataItems.length + ' selected' }}
									</span>
								</ng-template>
							</kendo-multiselect>
						</kendo-label>
						<kendo-label text="Company">
							<kendo-multiselecttree
								#companyMultiselect
								kendoMultiSelectTreeSummaryTag
								kendoMultiSelectTreeExpandable
								[kendoMultiSelectTreeHierarchyBinding]="nestedCompanyList"
								[(value)]="filterValuesByField['company']"
								textField="text"
								valueField="value.value"
								childrenField='children'
								[valuePrimitive]='false'
								[filterable]='true'
								(valueChange)='filterChanged("company", $event);'
								[clearButton]='false'
								[(expandedKeys)]="expandedCompanyNodes"
								(close)='filterDropdownClose("company", companyList)'
							>
								<ng-template kendoMultiSelectHeaderTemplate>
									<div>
										<input
											kendoCheckBox
											type='checkbox'
											[checked]="isSelectedAll('company', companyList)"
											[indeterminate]="isIndet('company', companyList)"
											(click)="onFilterSelectAllClick('company', companyList)"
										/>
										<kendo-label for="chk">Select all</kendo-label>
									</div>
								</ng-template>
								<ng-template kendoMultiSelectTreeGroupTagTemplate let-dataItems>
								<span>
									<kendo-svg-icon [icon]='icons.caretDown'></kendo-svg-icon>
									{{ dataItems.length + ' selected' }}
								</span>
								</ng-template>
							</kendo-multiselecttree>
						</kendo-label>
						<kendo-label text="Market">
							<kendo-multiselect
								kendoMultiSelectSummaryTag
								[data]="filteredProjectTypeList"
								[(ngModel)]="filterValuesByField['projectType']"
								textField="text"
								valueField="value"
								[checkboxes]='true'
								[autoClose]='false'
								[filterable]='true'
								(filterChange)='handleProjectTypeFilter($event)'
								(valueChange)='filterChanged("projectType", $event)'
								[clearButton]='false'
							>
								<ng-template kendoMultiSelectHeaderTemplate>
									<div>
										<input
											kendoCheckBox
											type='checkbox'
											[checked]="isSelectedAll('projectType', filteredProjectTypeList)"
											[indeterminate]="isIndet('projectType', filteredProjectTypeList)"
											(click)="onFilterSelectAllClick('projectType', filteredProjectTypeList)"
										/>
										<kendo-label>Select all</kendo-label>
									</div>
								</ng-template>
								<ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
								<span>
									<kendo-svg-icon [icon]='icons.caretDown'></kendo-svg-icon>
									{{ dataItems.length + ' selected' }}
								</span>
								</ng-template>
							</kendo-multiselect>
						</kendo-label>
						<kendo-label text="Point of Contact">
							<kendo-multiselect
								kendoMultiSelectSummaryTag
								[data]="filteredPocList"
								[(ngModel)]="filterValuesByField['pocId']"
								textField="text"
								valueField="value"
								[filterable]="true"
								[checkboxes]='true'
								[autoClose]='false'
								(filterChange)='handlePocFilter($event)'
								(valueChange)='filterChanged("pocId", $event)'
								[clearButton]='false'
							>
								<!--								<ng-template kendoMultiSelectFixedGroupTemplate kendoMultiSelectGroupTemplate let-groupName>
																	<strong>{{ groupName }}</strong>
																</ng-template>
																&lt;!&ndash;We need to do this custom template because [checkboxes] doesn't work with grouped multiselect&ndash;&gt;
																<ng-template kendoMultiSelectItemTemplate let-dataItem>
																	<span class='poc-item'>
																		<input type="checkbox" kendoCheckBox [checked]='filterValuesByField["pocId"].includes(dataItem)'/>
																		<span class='poc-name'>
																			{{ dataItem.text }}
																		</span>

																	</span>

																</ng-template>-->
								<ng-template kendoMultiSelectHeaderTemplate>
									<div>
										<input
											kendoCheckBox
											type='checkbox'
											[checked]="isSelectedAll('pocId', filteredPocList)"
											[indeterminate]="isIndet('pocId', filteredPocList)"
											(click)="onFilterSelectAllClick('pocId', filteredPocList)"
										/>
										<kendo-label for="chk">Select all</kendo-label>
									</div>
								</ng-template>
								<ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
								<span>
									<kendo-svg-icon [icon]='icons.caretDown'></kendo-svg-icon>
									{{ dataItems.length + ' selected' }}
								</span>
								</ng-template>
							</kendo-multiselect>
						</kendo-label>
					</div>
				</fieldset>
			</div>
		</div>
	</div>
	<div style='padding: 5px 5px 0 5px; display: flex; justify-content: center; background-color: #c8cad6;'>
		<button kendoButton themeColor="error" title="Reset" [disabled]='resetDisabled || loading' (click)='resetFiltersButtonClicked()'>Reset</button>
	</div>
</ng-template>
