import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { ProjectDashboardService } from '../../../services/project/project.service';
import { PopoverController } from '@ionic/angular';
import { UserService } from 'services/common/user.service';
import { RestService } from 'services/common/rest.service';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import {
	differenceInCalendarDays,
	differenceInDays,
	format,
	isAfter,
	isBefore,
	isValid,
	startOfDay,
	startOfToday,
	subMonths,
} from 'date-fns';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ScheduleStorageService } from 'services/project/schedule-storage.service';
import { cleanDateUTC } from '../../../util/pipes/date.pipe';
import { RiskRegister } from '../../../models/risk';
import { ProjectInterface } from '../../../models/Project';
import { NavigationBarStorageService } from '../../../services/common/navigation-bar-storage.service';
import { ChartScoreSelection } from '../../../models/ChartSettings';
import { formatNumber } from '@progress/kendo-angular-intl';
import { hasObjChanged, hasReportChanged, scheduleType } from '../../../util/projects';
import { isDefaultScoreFilters } from '../project-admin-settings/score-playground/score-playground.component';
import { AppWindowService } from '../../../services/common/window.service';
import { imageAddIcon, trashIcon } from '@progress/kendo-svg-icons';
import { Router } from '@angular/router';
import { CostService } from '../../../services/common/cost.service';
import { riskFormPresets } from '../risk/risk-register/risk-register-form/risk-register-form.component';
import { MitStatusTableData } from '../../portfolio/risk-banner/risk-banner.component';
import { NotesPreferences } from '../../../models/company';
import { ProjectReportInterface } from '@rhinoworks/analytics-calculations';
import { UpdateInterface } from '../../../models/Update/Task';

@Component({
	selector: 'app-top-row',
	templateUrl: './top-row.component.html',
	styleUrls: ['./top-row.component.scss'],
	animations: [
		trigger('slideInOutAnimation', [
			state('*', style({})),
			transition(':enter', [
				style({
					transform: 'translate3d(0,-10px,-1px)',
					opacity: 0,
					zIndex: -1,
				}),
				animate(
					'0.2s ease-in-out',
					style({
						transform: 'translate3d(0,0,0)',
						opacity: 1,
						zIndex: -1,
					})
				),
			]),
			transition(':leave', [
				animate(
					'.2s ease-in-out',
					style({
						transform: 'translate3d(0,0,-10%)',
						opacity: 0,
					})
				),
			]),
		]),
	],
})
export class TopRowComponent implements OnInit, OnDestroy {
	private _unsubscribeAll = new Subject<void>();
	scoresAltered: boolean = false;
	@Input() riskMetricsOptions = {};
	@Output() dialogStateChange = new EventEmitter<boolean>();
	imageUploadDialogOpen: boolean = false;
	imageReadyToShow: boolean = false;
	removeImageDialogOpen: boolean = false;
	imageShowing: boolean = false;

	user: any = {
		userType: 'aegis',
	};
	$linkToCompanyLogo: BehaviorSubject<string> = new BehaviorSubject<string>(
		'/assets/images/logos/AEGIS-ANALYTICS-COLOR.png'
	);
	$sharepointPage: BehaviorSubject<string> = new BehaviorSubject<string>('');
	$isRiskEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	isMissingUpdate: boolean = false;
	isMissingNotes: boolean = false;
	$projectScore: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	$reliabilityScore: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	$progressScore: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	$riskScore: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	$riskScoreLabel: BehaviorSubject<string> = new BehaviorSubject<string>('');
	$qualityControlScore: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	$completionRatioScore: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	$lastMonthVariance: BehaviorSubject<number> = new BehaviorSubject<number>(null);
	$spi: BehaviorSubject<number> = new BehaviorSubject<number>(null);
	$previousMonth: BehaviorSubject<number> = new BehaviorSubject<number>(null);
	$thisMonth: BehaviorSubject<number> = new BehaviorSubject<number>(null);
	$nextMonth: BehaviorSubject<number> = new BehaviorSubject<number>(null);
	allRisks: BehaviorSubject<Array<RiskRegister>> = new BehaviorSubject<Array<RiskRegister>>([]);
	riskSummaryTrendCategories = [];
	riskSummaryTrendData = [];
	public icons = { imageAdd: imageAddIcon, imageRemove: trashIcon };
	mitStatusCounts: Map<string, number> = new Map<string, number>([]);
	mitStatusTableVals: MitStatusTableData[] = [];
	$prevPlannedBudgeted: BehaviorSubject<number> = new BehaviorSubject<number>(null);
	$prevActual: BehaviorSubject<number> = new BehaviorSubject<number>(null);
	$prevDelta: BehaviorSubject<number> = new BehaviorSubject<number>(null);
	$totalBudgetedCost: BehaviorSubject<number> = new BehaviorSubject<number>(null);
	$currentBudgetedCost: BehaviorSubject<number> = new BehaviorSubject<number>(null);
	$baselineRemaining: BehaviorSubject<number> = new BehaviorSubject<number>(null);
	$currentRemaining: BehaviorSubject<number> = new BehaviorSubject<number>(null);
	$totalActual: BehaviorSubject<number> = new BehaviorSubject<number>(null);
	$nextMonthPlanned: BehaviorSubject<number> = new BehaviorSubject<number>(null);
	$totalActualPercentLabel: BehaviorSubject<string> = new BehaviorSubject<string>('');
	$totalRemainingPercentLabel: BehaviorSubject<string> = new BehaviorSubject<string>('');
	$prevActualPercentLabel: BehaviorSubject<string> = new BehaviorSubject<string>('');
	$prevDeltaPercentLabel: BehaviorSubject<string> = new BehaviorSubject<string>('');
	@Input() notesRequirement: BehaviorSubject<NotesPreferences> = new BehaviorSubject<NotesPreferences>({
		critPathRequired: false,
		tiaRequired: false,
	});

	/**
	 *
	 * @param _projectDashboardService
	 * @param userService
	 * @param restService
	 * @param navBarStorage
	 * @param appWindowService
	 * @param cdr
	 * @param router
	 * @param costService
	 * @param scheduleStorage
	 */
	constructor(
		public _projectDashboardService: ProjectDashboardService,
		public userService: UserService,
		private restService: RestService,
		public navBarStorage: NavigationBarStorageService,
		public appWindowService: AppWindowService,
		public cdr: ChangeDetectorRef,
		private router: Router,
		public costService: CostService,
		public scheduleStorage: ScheduleStorageService
	) {}

	@ViewChild('summary') summary: HTMLIonGridElement;

	scheduleName = new BehaviorSubject<string>('');
	aegisPOCName = new BehaviorSubject<string>('');
	dataDate = new BehaviorSubject<string>('');
	completionDate: Date = null;
	aegisPOCEmail = new BehaviorSubject<string>('');
	aegisPOCPhone = new BehaviorSubject<string>('');
	currentCompletion = new BehaviorSubject<string>('');
	contractCompletion = new BehaviorSubject<string>('');
	previousCompletion = new BehaviorSubject<string>('');
	contractVariance = new BehaviorSubject<number>(0);
	previousVariance = new BehaviorSubject<number>(0);
	daysLeft = new BehaviorSubject<number>(0);
	isHoveringOverImage = false;
	showArchiveWarning = false;
	selectedSummaryChart: ChartScoreSelection = 'projectScore';
	allScoreMapping = {
		projectScore: {
			data: [],
			title: 'Project Score',
			categories: [],
		},
		progressScore: {
			data: [],
			title: 'Progress Score',
			categories: [],
		},
		predictabilityScore: {
			data: [],
			title: 'Risk Score',
			categories: [],
		},
		qcScore: {
			data: [],
			title: 'QC Score',
			categories: [],
		},
	};
	summaryScoreTrendData = [];
	summaryScoreCategories = [];
	summaryScoreTrendTitle = 'Project Score';
	isSaasRiskCompanyImg = false;
	companyId = '';
	currentProjectId = '';

	ngOnInit(): void {
		this._projectDashboardService.$currentProjectData.subscribe((projectData) => {
			if (projectData) {
				//variables needed for image endpoint
				this.isSaasRiskCompanyImg = projectData?.riskPagePurchased;
				this.companyId = projectData?.companyId;
				this.currentProjectId = projectData?._id;
				this.updateGraphs(projectData);

				//if there is a logo, make sure the remove image icon is visible on hover
				if (projectData.imageBase64) {
					this.imageShowing = true;
				}
			}
		});
		if (localStorage.getItem('isScreenshotMode') !== 'true') {
			window.addEventListener('scroll', this.navBarStorage.scroll, true);
		}

		this._projectDashboardService.$currentProjectReport
			.pipe(takeUntil(this._unsubscribeAll), debounceTime(100))
			.subscribe((report) => {
				if (!report) {
					return;
				}
				this.updateData(report);
			});

		this.userService.user.subscribe((data) => {
			if (data) {
				this.user = data;
			}
		});

		this._projectDashboardService.showArchiveConfirmation.subscribe((showWarning) => {
			this.showArchiveWarning = showWarning;
			if (showWarning) {
				this.appWindowService.setViewport('archiveProjectWarning');
			}
			this.dialogStateChange.emit(showWarning);
		});

		this.navBarStorage.$tabPointer.subscribe((currentTab) => {
			const trendScoreDefault: ChartScoreSelection =
				currentTab === 'qc' ? 'qcScore' : currentTab === 'risk' ? 'predictabilityScore' : 'projectScore';
			if (
				(!!this._projectDashboardService.$currentProjectReport.value &&
					this._projectDashboardService.$currentProjectReport.value.project.riskMetricsType !== 'riskRegister') ||
				currentTab !== 'risk'
			) {
				this.updateChartSelection(trendScoreDefault);
			}
		});

		this.appWindowService.$closeTopRowWindows.subscribe((val) => {
			if (val) {
				this.closeDialog();
			}
		});
		this.costService.$lastMonthVariance.subscribe((val: number) => {
			this.$lastMonthVariance.next(val === undefined || val === null ? null : val);
		});
		this.costService.$latestSPI.subscribe((val: number) => {
			this.$spi.next(val === undefined || val === null ? null : val);
		});
		this.costService.$previousMonth.subscribe((val: number) => {
			this.$previousMonth.next(val === undefined || val === null ? null : val);
		});
		this.costService.$thisMonth.subscribe((val: number) => {
			this.$thisMonth.next(val === undefined || val === null ? null : val);
		});
		this.costService.$nextMonth.subscribe((val: number) => {
			this.$nextMonth.next(val === undefined || val === null ? null : val);
		});
		this.costService.$prevPlannedBudgeted.subscribe((val: number) => {
			this.$prevPlannedBudgeted.next(val === undefined || val === null ? null : val);
		});
		this.costService.$prevActual.subscribe((val: number) => {
			this.$prevActual.next(val === undefined || val === null ? null : val);
		});
		this.costService.$prevDelta.subscribe((val: number) => {
			this.$prevDelta.next(val === undefined || val === null ? null : val);
		});
		this.costService.$totalBudgetedCost.subscribe((val: number) => {
			this.$totalBudgetedCost.next(val === undefined || val === null ? null : val);
		});
		this.costService.$baselineRemaining.subscribe((val: number) => {
			this.$baselineRemaining.next(val === undefined || val === null ? null : val);
		});
		this.costService.$currentRemaining.subscribe((val: number) => {
			this.$currentRemaining.next(val === undefined || val === null ? null : val);
		});
		this.costService.$totalActual.subscribe((val: number) => {
			this.$totalActual.next(val === undefined || val === null ? null : val);
		});
		this.costService.$nextMonthPlanned.subscribe((val: number) => {
			this.$nextMonthPlanned.next(val === undefined || val === null ? null : val);
		});
		this.costService.$totalActualPercentLabel.subscribe((val: string) => {
			this.$totalActualPercentLabel.next(val === undefined || val === null ? null : val);
		});
		this.costService.$totalRemainingPercentLabel.subscribe((val: string) => {
			this.$totalRemainingPercentLabel.next(val === undefined || val === null ? null : val);
		});
		this.costService.$prevActualPercentLabel.subscribe((val: string) => {
			this.$prevActualPercentLabel.next(val === undefined || val === null ? null : val);
		});
		this.costService.$prevDeltaPercentLabel.subscribe((val: string) => {
			this.$prevDeltaPercentLabel.next(val === undefined || val === null ? null : val);
		});
		this.costService.$currentBudgetedCost.subscribe((val: number) => {
			this.$currentBudgetedCost.next(val === undefined || val === null ? null : val);
		});
		combineLatest([this.scheduleStorage.$allUpdates.pipe(takeUntil(this._unsubscribeAll)), this.notesRequirement])
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(([updates, notesReq]) => {
				if (updates?.length && notesReq) {
					updates.forEach((update: UpdateInterface) => {
						if (
							(notesReq.critPathRequired && (!update?.criticalPathNotes || !update?.criticalPathNotes?.length)) ||
							(notesReq.tiaRequired && (!update?.timeAnalysisNotes || !update?.timeAnalysisNotes?.length))
						) {
							this.isMissingNotes = true;
							return;
						}
					});
					// update again once all updates loaded to account for rebaselines
					this.updateData(this._projectDashboardService.$currentProjectReport.value);
				}
			});
	}

	updateData(report): void {
		this.initializeProjectOverviewData(report?.projectOverview);
		this.$projectScore.next(report?.projectScore || 0);
		this.$reliabilityScore.next(report?.reliabilityScore || 0);
		this.$progressScore.next(report?.progressScore || 0);
		this.$riskScore.next(report?.riskScore || 0);
		if (report?.riskScore >= 85) {
			this.$riskScoreLabel.next('Strong');
		} else if (report?.riskScore >= 70) {
			this.$riskScoreLabel.next('Moderate');
		} else {
			this.$riskScoreLabel.next('Needs Improvement');
		}
		this.$qualityControlScore.next(report?.qualityControl?.qcScore || 0);
		this.$completionRatioScore.next(report?.activityCompletionGraph?.lastPeriodGraph?.totalPercentComplete || 0);
		this.scoresAltered = !isDefaultScoreFilters(report?.preferences?.scores?.includeTypes);
		/*
	TODO: For company logo
	if (this.user.userType == 'client') {
		if (report?.project?.companyId != null) {
			const url = `company/${report.project.companyId}`;
			this.restService.fetch(url).subscribe((data) => {
				const companyData = data?.Companies?.[0];
				this.$linkToCompanyLogo.next(companyData?.logo);
			});
		}
	}
*/
		const projectData = report?.project;
		this.scheduleName.next(
			projectData
				? projectData.updateIds?.length > 1
					? `Update ${projectData?.updateIds?.length - 1}`
					: projectData.updateIds?.length === 1
						? 'Baseline'
						: ''
				: ''
		);
		this.$isRiskEnabled.next(!!projectData?.riskPagePurchased);
		this.$sharepointPage.next(projectData?.sharePoint);
		let projectScoreTrendData = [];
		let progressScoreTrendData = [];
		let predictabilityScoreTrendData = [];
		let qcScoreTrendData = [];
		let noBaselineCategories = [];
		let categories = [];
		this.riskSummaryTrendCategories = [];
		this.riskSummaryTrendData = [];
		this.mitStatusCounts.clear();
		this.mitStatusTableVals = [];
		const newRisks = [];
		report?.project?.riskMitigation.forEach((risk: RiskRegister) => {
			if (risk.isDraft) {
				return;
			}
			newRisks.push(risk);
			const key = risk.mitStatus.toString();
			const existingVal = this.mitStatusCounts.get(key);
			this.mitStatusCounts.set(key, existingVal === undefined ? 1 : existingVal + 1);
		});
		riskFormPresets.mitStatus.forEach((status: string) => {
			const matchingCount = this.mitStatusCounts.get(status);
			this.mitStatusTableVals.push({
				label: status,
				count: matchingCount === undefined ? 0 : matchingCount,
				percentage: matchingCount === undefined ? 0 : (100 * matchingCount) / newRisks.length,
			});
		});
		this.mitStatusTableVals = this.mitStatusTableVals
			.sort((a: MitStatusTableData, b: MitStatusTableData) => (a?.count > b?.count ? -1 : b?.count > a?.count ? 1 : 0))
			.slice(0, 5);
		report?.calculationFieldsHistorical?.forEach((update, i) => {
			const dataDateFromUpdate: Date = new Date(
				this.scheduleStorage.$allUpdates.value[i]?.dataDate ||
					report?.projectCompletionTrend?.projectCompletionTrendArray?.[i]?.dataDate
			);
			const dateString: string = dataDateFromUpdate ? format(dataDateFromUpdate, 'MMM dd, yyyy') : '';
			const updateName: string = i === 0 ? 'Baseline' : `Update ${i}`;
			const category: string =
				(dateString === '' ? updateName : updateName + '\n' + dateString) +
				(this.scheduleStorage.$allUpdates.value[i]?.baseline ? ' ®' : '');
			if (i > 0) {
				this.riskSummaryTrendCategories.push(category);
				this.riskSummaryTrendData.push(Math.round(update.riskScore));
				noBaselineCategories.push(category);
				progressScoreTrendData.push(update.progressScore ? formatNumber(update.progressScore, 'n0') : 0);
			}
			categories.push(category);
			projectScoreTrendData.push(update.projectScore ? formatNumber(update.projectScore, 'n0') : 0);
			predictabilityScoreTrendData.push(
				update.predictabilityScore ? formatNumber(update.predictabilityScore, 'n0') : 0
			);
			qcScoreTrendData.push(update.qcScore ? formatNumber(update.qcScore, 'n0') : 0);
			if (i === report?.calculationFieldsHistorical.length - 1) {
				const lastUploaded = new Date(report?.projectOverview?.dataDate);
				const now = new Date();
				const dUpload = differenceInDays(now, lastUploaded);
				const type = scheduleType(report?.project);
				this.isMissingUpdate = dUpload >= 45 && type === 'Active';
			}
		});
		categories = categories.slice(-6);
		noBaselineCategories = noBaselineCategories.slice(-6);
		projectScoreTrendData = projectScoreTrendData.slice(-6);
		progressScoreTrendData = progressScoreTrendData.slice(-6);
		predictabilityScoreTrendData = predictabilityScoreTrendData.slice(-6);
		qcScoreTrendData = qcScoreTrendData.slice(-6);
		this.allScoreMapping.projectScore.data = projectScoreTrendData;
		this.allScoreMapping.projectScore.categories = categories;
		this.allScoreMapping.progressScore.data = progressScoreTrendData;
		this.allScoreMapping.progressScore.categories = noBaselineCategories;
		this.allScoreMapping.predictabilityScore.data = predictabilityScoreTrendData;
		this.allScoreMapping.predictabilityScore.categories = categories;
		this.allScoreMapping.qcScore.data = qcScoreTrendData;
		this.allScoreMapping.qcScore.categories = categories;
		if (hasObjChanged(this.summaryScoreTrendData, this.allScoreMapping[this.selectedSummaryChart].data)) {
			this.summaryScoreTrendData = this.allScoreMapping[this.selectedSummaryChart].data;
		}
		if (this.summaryScoreTrendTitle !== this.allScoreMapping[this.selectedSummaryChart].title) {
			this.summaryScoreTrendTitle = this.allScoreMapping[this.selectedSummaryChart].title;
		}
		if (hasObjChanged(this.summaryScoreCategories, this.allScoreMapping[this.selectedSummaryChart].categories)) {
			this.summaryScoreCategories = this.allScoreMapping[this.selectedSummaryChart].categories;
		}
		this.updateGraphs(report?.project);
		if (report?.updateIds?.length) {
			const dataDateFromUpdate = new Date(
				report.projectCompletionTrend.projectCompletionTrendArray[
					report.projectCompletionTrend.projectCompletionTrendArray.length - 1
				]?.dataDate
			);
			const dataDate: Date = new Date(
				dataDateFromUpdate.getUTCFullYear(),
				dataDateFromUpdate.getUTCMonth(),
				dataDateFromUpdate.getUTCDate(),
				dataDateFromUpdate.getUTCHours()
			);
			this.dataDate.next(isValid(dataDate) ? cleanDateUTC(dataDate, 'MMM dd, yyyy') : 'N/A');
			if (this.dataDate.value !== '' && this.completionDate !== null) {
				this.daysLeft.next(
					this.completionDate ? differenceInCalendarDays(this.completionDate, this.dataDate.value) : 0
				);
			}
		} else {
			this.dataDate.next('N/A');
		}
	}

	public updateGraphs(project: ProjectInterface) {
		if (project?.riskMetricsType === 'riskRegister' || project?.riskMetricsType === 'performanceFactor') {
			const newRisks: RiskRegister[] = [];
			if (project?.riskMitigation) {
				project.riskMitigation.forEach((risk) => {
					const newRisk = {
						...risk,
						value: 1, //used for groupBy aggregation
					};
					newRisks.push(newRisk);
				});
			}
			this.allRisks.next(newRisks);
		}
	}

	ngOnDestroy() {
		// Unsubscribe from all subscriptions
		window.removeEventListener('scroll', this.navBarStorage.scroll, true);
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	initializeProjectOverviewData(projectOverview: any) {
		if (!projectOverview) {
			return;
		}
		this.aegisPOCName.next(projectOverview.aegisPOCName || 'N/A');
		this.aegisPOCEmail.next(projectOverview.aegisPOCEmail || 'N/A');
		this.aegisPOCPhone.next(projectOverview.aegisPOCPhone || 'N/A');
		const currentCompletion = new Date(projectOverview.currentCompletion);
		this.currentCompletion.next(isValid(currentCompletion) ? cleanDateUTC(currentCompletion, 'MMM dd, yyyy') : 'N/A');
		const contractCompletion = new Date(projectOverview.contractCompletion);
		this.contractCompletion.next(
			isValid(contractCompletion) ? cleanDateUTC(contractCompletion, 'MMM dd, yyyy') : 'N/A'
		);
		const previousCompletion = new Date(projectOverview.previousCompletion);
		this.previousCompletion.next(
			isValid(previousCompletion) ? cleanDateUTC(previousCompletion, 'MMM dd, yyyy') : 'N/A'
		);
		// const dateSplit = projectOverview.dataDate.split(' ');
		// const dateString = dateSplit[1] + ' ' + dateSplit[2] + ' ' + dateSplit[3];
		// const dataDate = new Date(dateString);
		// this.dataDate.next(isValid(dataDate) ? cleanDateUTC(dataDate, 'MMM dd, yyyy') : 'N/A');
		this.contractVariance.next(Math.round(Number(projectOverview.contractVariance ?? 0)));
		this.previousVariance.next(Math.round(Number(projectOverview.previousVariance ?? 0)));
		const completionDate = startOfDay(new Date(projectOverview.currentCompletion));
		this.completionDate = completionDate;
		if (this.dataDate.value !== '') {
			this.daysLeft.next(completionDate ? differenceInCalendarDays(completionDate, this.dataDate.value) : 0);
		}
	}

	minZero(percentage: any) {
		if (percentage._value < 0) {
			return 0;
		}
		return percentage._value;
	}

	removeImage(): void {
		const url = 'project/' + this.currentProjectId + '/uploadNewProjectImage';
		this.restService
			.post(url, {
				imageBase64: '',
			})
			.subscribe(
				(res) => {
					console.log(res);
				},
				(err) => {
					console.log(err);
				}
			);
		this.removeImageDialogOpen = false;
		this.imageShowing = false;
	}

	openRemoveImage() {
		this.removeImageDialogOpen = true;
		this.appWindowService.setViewport('removeLogo');
	}

	/**
	 * opens image upload editor with current image if available
	 */
	openImageUpload(): void {
		this.imageUploadDialogOpen = true;
		this.appWindowService.setViewport('image');
	}

	/**
	 * sets hovering variable to false
	 */
	mouseoverImage(): void {
		this.isHoveringOverImage = true;
	}

	/**
	 * sets hovering variable to false
	 */
	mouseoutImage(): void {
		this.isHoveringOverImage = false;
	}

	saveArchiveChange() {
		this.restService
			.post(`project/${this._projectDashboardService.$currentProjectPageId.value}/toggleArchived`, {
				isArchived: !this._projectDashboardService.$projectArchived.value,
			})
			.subscribe(
				(val) => {
					this.showArchiveWarning = false;
					this.dialogStateChange.emit(false);
					this._projectDashboardService.$projectArchived.next(!this._projectDashboardService.$projectArchived.value);
					this.router.navigate(['/portfolio']);
				},
				(response) => {
					console.log('POST call in error', response);
				},
				() => {}
			);
	}

	closeDialog() {
		this.showArchiveWarning = false;
		this.dialogStateChange.emit(false);
		this.imageUploadDialogOpen = false;
		this.imageReadyToShow = false;
		this.removeImageDialogOpen = false;
	}

	/**
	 * updates which score is showing in the trend chart based on user selection
	 * @param newVal
	 */
	updateChartSelection(newVal: ChartScoreSelection): void {
		this.selectedSummaryChart = newVal;
		this.summaryScoreTrendData = this.allScoreMapping[this.selectedSummaryChart].data;
		this.summaryScoreTrendTitle = this.allScoreMapping[this.selectedSummaryChart].title;
		this.summaryScoreCategories = this.allScoreMapping[this.selectedSummaryChart].categories;
	}

	/**
	 * updates image dialog width based on if image is showing
	 * @param newVal
	 */
	imageReadyToShowUpdated(newVal: boolean): void {
		this.imageReadyToShow = newVal;
		this.appWindowService.$imageWidth.next(newVal ? 686 : 376);
	}

	/**
	 * forces window to stay within bounds of viewport
	 * @param window
	 */
	restrictMovement(window: string): void {
		this.appWindowService.restrictMovement(window, this.cdr);
	}
}
