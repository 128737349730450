import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AxisSettings, GaugeColorSettings, SeriesData, SeriesDataSettings } from '../../../../../models/ChartSettings';
import { hasObjChanged } from '../../../../../util/projects';
import { CurrentProjectReport, ProjectDashboardService } from '../../../../../services/project/project.service';
import { CriticalPathRisk } from '@rhinoworks/analytics-calculations';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { RestService } from '../../../../../services/common/rest.service';
import { format } from 'date-fns';
import { ScheduleStorageService } from '../../../../../services/project/schedule-storage.service';
import { UpdateInterface } from '../../../../../models/Update/Task';

@Component({
	selector: 'app-critical-path-risk',
	templateUrl: './critical-path-risk.component.html',
	styleUrls: ['./critical-path-risk.component.scss'],
})
export class CriticalPathRiskComponent implements OnInit {
	@Input() isOverview: boolean = false;
	@Input() cpr: CriticalPathRisk;
	@Input() isFocus: boolean = false;
	@Input() focusCode: string;
	@Input() needRecalc = false;
	fetchingSheets: boolean;
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	$overallScore = new BehaviorSubject<number>(0);
	cprColors: GaugeColorSettings[] = [
		{
			to: 50,
			color: '#DF5353',
		},
		{
			from: 50,
			to: 85,
			color: '#4fc931',
		},
		{
			from: 85,
			color: '#0059FF',
		},
	];
	label: string = '';
	categories: string[] = [];
	seriesData: SeriesDataSettings[] = [];
	valueAxisItemSettings: AxisSettings[] = [
		{
			title: {
				text: 'Total Float',
				visible: true,
			},
			labels: {
				format: '{0}',
			},
			majorGridLines: {
				visible: true,
			},
		},
	];
	hasNotes: boolean = false;

	constructor(
		public projectService: ProjectDashboardService,
		private restService: RestService,
		public storage: ScheduleStorageService
	) {}

	ngOnInit(): void {
		this.projectService.$currentProjectReport
			.pipe(takeUntil(this._unsubscribeAll), debounceTime(100))
			.subscribe((report) => {
				if (report) {
					this.getCriticalPathRiskScore(report);
				}
			});
		this.storage.$allUpdates.subscribe((val: UpdateInterface[]) => {
			// needs all updates loaded to be able to tell if there have been rebaselines
			if (val) {
				this.getCriticalPathRiskScore(this.projectService.$currentProjectReport.value);
			}
		});
		this.projectService.$currentProjectData.subscribe((val) => {
			if (val) {
				const savedNotes = val.componentNotes?.find((n) => n.id === 21)?.notes;
				this.hasNotes = savedNotes?.length && savedNotes[savedNotes?.length - 1]?.note !== '';
			}
		});
	}

	/**
	 * updates cpr gauge and chart values based on the latest incoming report
	 * @param report
	 * @param cprArg
	 */
	getCriticalPathRiskScore(report: CurrentProjectReport, cprArg: CriticalPathRisk = this.cpr): void {
		const cpr = cprArg || report?.riskPage?.criticalPathRisk;
		if (!cpr) {
			return;
		}
		const overallScore = cpr?.overallScore || 0;
		this.$overallScore.next(overallScore);
		const firstPath: SeriesData[] = [];
		const secondPath: SeriesData[] = [];
		const thirdPath: SeriesData[] = [];
		const categories = [];
		cpr.cprTrends.firstPathTrend.forEach((y, index) => {
			const dataDateFromUpdate: Date = new Date(
				this.storage.$allUpdates.value[index]?.dataDate ||
					report?.projectCompletionTrend?.projectCompletionTrendArray?.[index]?.dataDate
			);
			const dateString: string = dataDateFromUpdate ? format(dataDateFromUpdate, 'MMM dd, yyyy') : '';
			const updateName: string = index === 0 ? 'Baseline' : `Update ${index}`;
			const category: string =
				(dateString === '' ? updateName : updateName + '\n' + dateString) +
				(this.storage.$allUpdates.value[index]?.baseline ? ' ®' : '');
			categories.push(category);
			firstPath.push({
				category,
				value: y,
			});
		});
		cpr.cprTrends.secondPathTrend.forEach((y, index) => {
			secondPath.push({
				category: categories[index],
				value: y,
			});
		});
		cpr.cprTrends.thirdPathTrend.forEach((y, index) => {
			thirdPath.push({
				category: categories[index],
				value: y,
			});
		});
		const seriesData: SeriesDataSettings[] = [
			{
				type: 'line',
				data: firstPath,
				name: 'First Path',
				visible: true,
				color: '#DF5353',
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
			{
				type: 'line',
				data: secondPath,
				name: 'Second Path',
				visible: true,
				color: '#0059FF',
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
			{
				type: 'line',
				data: thirdPath,
				name: 'Third Path',
				visible: true,
				color: 'gray',
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
		];
		if (hasObjChanged(this.seriesData, seriesData)) {
			this.seriesData = seriesData;
		}
		if (hasObjChanged(categories, this.categories)) {
			this.categories = categories;
		}
		this.label = overallScore >= 85 ? 'Low Risk' : overallScore >= 50 ? 'Moderate Risk' : 'High Risk';
	}

	grabExport() {
		this.fetchingSheets = true;
		this.restService
			.fetch(`report/sheets/${this.projectService.$currentProjectData.value._id}/${this.focusCode || ''}`)
			.subscribe(
				async (val) => {
					console.log({ val });
					const workbook = new ExcelJS.Workbook();
					const sheets: Record<
						string,
						{ sheetName: string; columns: string[]; data: Record<string, string | number>[] }
					> = val.sheets;
					for (const sheet of Object.values(sheets)) {
						if (!sheet.sheetName.endsWith('Path') && sheet.sheetName !== 'Scores') {
							continue;
						}
						const worksheet = workbook.addWorksheet(sheet.sheetName);

						// Define worksheet columns. You can adjust width as needed.
						worksheet.columns = sheet.columns.map((col) => ({
							header: col,
							key: col,
							width: 20,
						}));

						// Add each row to the worksheet
						sheet.data.forEach((row) => {
							if (row.Score && row.Score !== 'Critical Path Risk') {
								return;
							}
							worksheet.addRow(row);
						});
					}

					// Write the workbook to a buffer
					const buffer = await workbook.xlsx.writeBuffer();

					// Create a Blob from the buffer
					const fileBlob = new Blob([buffer], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					});

					const updateName =
						this.projectService.$currentProjectData.value.updateIds.length === 1
							? 'Baseline'
							: `Update ${this.projectService.$currentProjectData.value.updateIds.length - 1}`;
					saveAs(fileBlob, `${updateName}_${this.focusCode?.replaceAll('.', '') || 'Overall'}_Risk_Report.xlsx`);
				},
				(response) => {
					console.log('POST call in error', response);
					this.needRecalc = true;
				},
				() => {
					this.fetchingSheets = false;
				}
			);
	}
}
