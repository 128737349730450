import {
	Calendar,
	ICalendar,
	XerActivity,
	XerCalendar,
	XerData,
	XerProject,
	XerTaskPredecessor,
	XerWbs,
} from '@rhinoworks/xer-parse';

export type TaskArrayInterface = XerActivity & ScheduleAnalysisCustomInterface;

export interface CalendarArrayInterface extends ScheduleAnalysisCustomInterface {
	clndr_id: number;
	default_flag: 'Y' | 'N';
	clndr_name: string;
	proj_id?: number;
	base_clndr_id?: number;
	last_chng_date: Date;
	clndr_type?: string;
	day_hr_cnt: number;
	week_hr_cnt: number;
	month_hr_cnt: number;
	year_hr_cnt: number;
	rsrc_private: 'Y' | 'N';
	clndr_data: string;
	act_start_date?: Date;
	early_start_date?: Date;
	act_end_date?: Date;
	early_end_date?: Date;
}

export interface ActivityCodesInterface {
	actv_code_type_id?: any;
	actv_short_len?: any;
	seq_num?: any;
	actv_code_type?: any;
	proj_id?: any;
	wbs_id?: any;
	actv_code_type_scope?: any;
}

export interface ActivitySubCodesArrayInterface {
	actv_code_id?: any;
	parent_actv_code_id?: any;
	actv_code_type_id?: any;
	actv_code_name?: any;
	short_name?: any;
	seq_num?: any;
	color?: any;
	total_assignments?: any;
}

export interface TaskPredArrayInterface extends ScheduleAnalysisCustomInterface {
	task_pred_id: number;
	task_id: number;
	pred_task_id: number;
	proj_id: number;
	pred_proj_id: number;
	pred_type: string;
	lag_hr_cnt: number;
	float_path: number;
	aref: Date;
	arls: Date;
	early_start_date: Date;
	status_code: string;
	task_code: string;
	act_start_date?: Date;
	act_end_date?: Date;
	early_end_date?: Date;
}

export interface ScheduleAnalysisCustomInterface {
	//used in schedule analysis for the kendo-grid [format] so auto-sorting grabs the correct values
	customStart?: Date;
	customEnd?: Date;
	succCode?: string;
	succName?: string;
	succType?: string;
	succStartAct?: Date;
	succStartEarly?: Date;
	succEndAct?: Date;
	succEndEarly?: Date;
	succRd?: number;
	predCode?: string;
	predName?: string;
	predType?: string;
	predStartAct?: Date;
	predStartEarly?: Date;
	predEndAct?: Date;
	predEndEarly?: Date;
	predRd?: number;
	lagHrs?: number;
	calendar?: string;
	remainingHrs?: number;
	odHrs?: number;
	tfHrs?: number;
	baselineTargetStart?: Date;
	baselineTargetFinish?: Date;
	dataDate?: Date;
	issueType?: string;
	criticality?: string;
}

export interface SelectedActivityCodeInterface {
	fullActvCode: ActivityCodesInterface;
	parentCode: string;
	subcodes: ActivitySubCodesArrayInterface[];
}

export interface UpdateInterface {
	_id?: string;
	taskArray?: XerActivity[];
	projectArray?: XerProject[];
	selectedProjectId?: number;
	activityCodesArray?: XerActivityCode[];
	activitySubCodesArray?: ActivitySubCodesArrayInterface[];
	taskPredArray?: XerTaskPredecessor[];
	additionalTrackingMilestones?: XerActivity[];
	finishMilestone?: XerActivity;
	startMilestone?: XerActivity;
	criticalPathNotes?: string;
	generalNotes?: string;
	timeAnalysisNotes?: string;
	isBaselineUpdate?: boolean;
	projectId?: string;
	xerFileName?: string;
	xerFileBuffer?: XerData;
	dataDate?: string | Date;
	updateName?: string;
	isArchived?: boolean;
	isGridFSStored?: boolean;
	gridFsLocation?: string;
	isAegisGeneratedSchedule?: boolean;
	url?: string;
	LastModified?: string;
	baseline?: boolean;
	hasCostData?: boolean;
	isCostBaseline?: boolean;
	updateFromSync?: boolean;
}

export type SlimmedTaskCommon = Pick<
	XerActivity,
	| 'task_id'
	| 'task_code'
	| 'task_name'
	| 'status_code'
	| 'task_type'
	| 'act_start_date'
	| 'act_end_date'
	| 'restart_date'
	| 'reend_date'
	| 'early_start_date'
	| 'early_end_date'
	| 'late_start_date'
	| 'late_end_date'
	| 'target_start_date'
	| 'target_end_date'
	| 'total_float_hr_cnt'
	| 'complete_pct_type'
	| 'phys_complete_pct'
	| 'act_work_qty'
	| 'remain_work_qty'
	| 'remain_drtn_hr_cnt'
	| 'target_drtn_hr_cnt'
	| 'cstr_date'
	| 'cstr_type'
	| 'proj_id'
	| 'clndr_id'
	| 'wbs_id'
> & {
	activityCodeShortnames?: string[];
	activityCodeIds?: number[];
	isCritical?: boolean;
	lastRecalcDate?: Date;
	preds?: [string, string, number, boolean][];
	actDuration?: number;
	dailyHrs?: number;
	critPathFloatDur?: number;
	longestPath?: boolean;
	actCost?: number;
	targetCost?: number;
	target?: string;
};

export type XerActivityCode = {
	actv_code_id: number;
	parent_actv_code_id: number | undefined;
	actv_code_type_id: number;
	actv_code_name: string | undefined;
	short_name: string;
	seq_num: number;
	color: string | undefined;
	total_assignments: number | undefined;
};

export type XerActivityType = {
	actv_code_type_id: number;
	actv_short_len: number;
	seq_num: number;
	actv_code_type: string;
	proj_id: number | undefined;
	wbs_id: number | undefined;
	actv_code_type_scope: `AS_${string}`;
};

export interface Wbs extends XerWbs {
	parent?: Wbs;
	children?: Wbs[];
	level?: number;
}
