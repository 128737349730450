<!--<kendo-window
	*ngIf="opened"
	(close)="open(false)"
	style="position: absolute"
>
	<kendo-window-titlebar>
		<div class="k-window-title" style="text-align: left;font-size: 16px; color: white">Update Risk Calculation Method</div>
		<button style="color: white" kendoWindowMinimizeAction></button>
		<button style="color: white" kendoWindowMaximizeAction></button>
		<button style="color: white" kendoWindowRestoreAction></button>
		<button style="color: white" kendoWindowCloseAction></button>
	</kendo-window-titlebar>-->
	<div>
		<p fxLayoutAlign="center">Risk Register entries will be preserved in this project's record.</p>
		<app-add-risk-button

			*ngIf="user.userType === 'aegis'"
			[riskOptions]="riskMetricsOptions"
			(updateRiskMetricsType)="updateSelection($event)"
		></app-add-risk-button>
		<p *ngIf="user.userType === 'client'">Please contact Aegis Rhinoworks to request a risk calculation method change.</p>
		<div class='action-buttons'>
			<!--<ion-button class='button' color="cancel" (click)="cancelAndDismiss()">Cancel</ion-button>-->
			<ion-button *ngIf="user.userType === 'aegis'" class='button' (click)="updateRiskMetricsType()">Save</ion-button>
			<ion-button *ngIf="user.userType === 'client'" class='button' (click)="emailRhinoworks()">Contact Rhinoworks</ion-button>
		</div>
	</div>

<!--</kendo-window>-->
