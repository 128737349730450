import { Component, Input } from '@angular/core';
import { NavigationBarStorageService } from '../../../../../../services/common/navigation-bar-storage.service';
import { AnalyticsDashboardService } from '../../../../../../services/analytics/analytics.service';

/**
 * @title Dialog Overview
 */

@Component({
	selector: 'app-add-baseline-button',
	templateUrl: 'add-baseline-button.component.html',
	styleUrls: ['../add-baseline.component.scss'],
})
export class AddBaselineButtonComponent {
	constructor(
		public navBarStorage: NavigationBarStorageService,
		public analyticsService: AnalyticsDashboardService
	) {}

	openDialog(): void {
		this.navBarStorage.showingBaselineProject = true;
		this.navBarStorage.newProjectFormMaxHeight = Math.min(window.innerHeight - 132, 750);
		this.navBarStorage.showingNewProject = true;
		this.analyticsService.baselineOpen.next(true);
	}
}
