<div>
	<fieldset>
		<legend>Quality Calculation Method</legend>
		<form [formGroup]='qualityFormGroup'>
			<kendo-formfield showHints="initial" >
				<ul class="k-radio-list">
					<li class="k-radio-item">
						<input
							type="radio"
							#useDcmaT
							[value]="true"
							kendoRadioButton
							formControlName="useDCMA"
						/>
						<kendo-label
							class="k-radio-label"
							[for]="useDcmaT"
							text="DCMA"
						></kendo-label>
					</li>
					<li class="k-radio-item">
						<input
							type="radio"
							#useDcmaF
							[value]="false"
							kendoRadioButton
							formControlName="useDCMA"
						/>
						<kendo-label
							class="k-radio-label"
							[for]="useDcmaF"
							text="Default"
						></kendo-label>
					</li>
				</ul>
				<kendo-formerror [align]="'end'">Error: This field is required</kendo-formerror>
			</kendo-formfield>
		</form>
	</fieldset>
	<fieldset>
		<legend>DCMA Options</legend>
		<form [formGroup]="qualityFormGroup" style="max-height: 360px; overflow: auto">
			<div class="dcmaGrid">
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #beicheck formControlName="useBei"></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="beicheck"
							text="BEI"
						></kendo-label>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 50px auto">
						<span>Min %:</span>
						<kendo-numerictextbox
							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							[max]="100"
							formControlName="beiMin"
						></kendo-numerictextbox>
					</div>
				</div>
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #cplicheck formControlName="useCpli"></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="cplicheck"
							text="CPLI"
						></kendo-label>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 50px auto">
						<span>Min %:</span>
						<kendo-numerictextbox
							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							[max]="100"
							formControlName="cpliMin"
						></kendo-numerictextbox>
					</div>
				</div>
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #missedcheck formControlName="useMissedTask"></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="missedcheck"
							text="Missed Tasks"
						></kendo-label>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 50px auto">
						<span>Max %:</span>
						<kendo-numerictextbox
							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							[max]="100"
							formControlName="missedTaskMax"
						></kendo-numerictextbox>
					</div>
				</div>
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #negativecheck formControlName="useNegativeFloat"></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="negativecheck"
							text="Negative Float"
						></kendo-label>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 50px auto">
						<span>Max %:</span>
						<kendo-numerictextbox
							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							[max]="100"
							formControlName="negativeFloatMax"
						></kendo-numerictextbox>
					</div>
				</div>
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #relTypeCheck formControlName="useNonFS"></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="relTypeCheck"
							text="Relationship Types"
						></kendo-label>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 50px auto">
						<span>Max %:</span>
						<kendo-numerictextbox
							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							[max]="100"
							formControlName="nonFSMax"
						></kendo-numerictextbox>
					</div>
				</div>
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #missingcheck formControlName="useMissingPredSucc"></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="missingcheck"
							text="Logic"
						></kendo-label>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 50px auto">
						<span>Max %:</span>
						<kendo-numerictextbox
							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							[max]="100"
							formControlName="missingPredSuccMax"
						></kendo-numerictextbox>
					</div>
				</div>
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #negativelagcheck formControlName="useNegativeLag"></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="negativelagcheck"
							text="Leads"
						></kendo-label>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 50px auto">
						<span>Max %:</span>
						<kendo-numerictextbox
							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							[max]="100"
							formControlName="negativeLagMax"
						></kendo-numerictextbox>
					</div>
				</div>
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #positivecheck formControlName="usePositiveLag"></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="positivecheck"
							text="Lags"
						></kendo-label>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 50px auto">
						<span>Max %:</span>
						<kendo-numerictextbox
							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							[max]="100"
							formControlName="positiveLagMax"
						></kendo-numerictextbox>
					</div>
				</div>
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #hardcheck formControlName="useHardConstraints"></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="hardcheck"
							text="Hard Constraints"
						></kendo-label>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 50px auto">
						<span>Max %:</span>
						<kendo-numerictextbox
							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							[max]="100"
							formControlName="hardConstraintsMax"
						></kendo-numerictextbox>
					</div>
				</div>
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #floatcheck formControlName="useHighFloat"></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="floatcheck"
							text="High Float"
						></kendo-label>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 50px auto">
						<span>Max %:</span>
						<kendo-numerictextbox
							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							[max]="100"
							formControlName="highFloatMax"
						></kendo-numerictextbox>
					</div>
				</div>
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #durationcheck formControlName="useHighDuration"></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="durationcheck"
							text="High Duration"
						></kendo-label>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 50px auto">
						<span>Max %:</span>
						<kendo-numerictextbox
							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							[max]="100"
							formControlName="highDurationMax"
						></kendo-numerictextbox>
					</div>
				</div>
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #invalidcheck formControlName="useInvalidDates"></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="invalidcheck"
							text="Invalid Dates"
						></kendo-label>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 50px auto">
						<span>Max %:</span>
						<kendo-numerictextbox
							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							[max]="100"
							formControlName="invalidDatesMax"
						></kendo-numerictextbox>
					</div>
				</div>
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #rsrccheck formControlName="useUnassignedResource"></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="rsrccheck"
							text="Resources"
						></kendo-label>
					</div>
					<div style="display: grid; grid-auto-flow: column; align-items: center; justify-content: start; gap: 5px; grid-template-columns: 50px auto">
						<span>Max %:</span>
						<kendo-numerictextbox
							[style.width.px]="100"
							[autoCorrect]="true"
							[min]="0"
							[max]="100"
							formControlName="unassignedResourceMax"
						></kendo-numerictextbox>
					</div>
				</div>
				<div>
					<div style="margin-bottom: 5px">
						<kendo-checkbox #critcheck formControlName="requireCritPath"></kendo-checkbox>
						<kendo-label
							class="k-checkbox-label"
							[for]="critcheck"
							text="Critical Path Test"
						></kendo-label>
					</div>
				</div>
			</div>
		</form>

	</fieldset>
</div>
<button kendoButton themeColor='success' style='float: right; margin-top: 10px' [disabled]="!hasChange" (click)="saveChanges()">Save</button>
