import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChartModule } from '@progress/kendo-angular-charts';
import { ChartComponent } from './chart.component';

@NgModule({
	imports: [CommonModule, ChartModule],
	declarations: [ChartComponent],
	exports: [ChartComponent],
})
export class AegisChartModule {}
