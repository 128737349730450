<ng-container [formGroup]="profileForm">
	<div id='profile-form' class='profile-form'>
		<kendo-formfield>
			<kendo-label [for]="firstName" text="First Name"> </kendo-label>
			<kendo-textbox
				formControlName="firstName"
				#firstName
				required
				[clearButton]="true"
				(valueChange)="onFirstNameChange($event)"
			>
			</kendo-textbox>
			<kendo-formerror [align]="'end'">First Name is required</kendo-formerror>
		</kendo-formfield>
		<kendo-formfield>
			<kendo-label [for]="lastName" text="Last Name"> </kendo-label>
			<kendo-textbox
				formControlName="lastName"
				#lastName
				required
				[clearButton]="true"
				(valueChange)="onLastNameChange($event)"
			>
			</kendo-textbox>
			<kendo-formerror [align]="'end'">Last Name is required</kendo-formerror>
		</kendo-formfield>
		<kendo-formfield>
			<kendo-label [for]="email" text="Email"> </kendo-label>
			<kendo-textbox
				formControlName="email"
				#email
				required
				[clearButton]="false"
				[readonly]="true"
				class='readonly-textbox'
			>
			</kendo-textbox>
			<kendo-formerror [align]="'end'">Email is required</kendo-formerror>
		</kendo-formfield>
		<kendo-formfield>
			<kendo-label [for]="phoneNumber" text="Phone Number"> </kendo-label>
			<kendo-textbox
				formControlName="phoneNumber"
				#phoneNumber
				[clearButton]="true"
				(valueChange)="onNumberChange($event)"
			>
			</kendo-textbox>
		</kendo-formfield>
		<div class="profileContainer">
			<button (click)="saveProfileChanges()" [disabled]="!profileForm.value.firstName || !this.profileForm.value.lastName || !this.profileForm.value.email" kendoButton style='background-color: #2ebb2e; color: white; border-radius: 4px; margin: 0;'>Save</button>
		</div>
	</div>
</ng-container>

