import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { RestService } from '../common/rest.service';
import { NotificationService } from '../common/notification.service';
import { take } from 'rxjs/operators';
import { ExpandedMetrics } from '../../models/ProjectReport/ExpandedMetrics';
import { CalendarArrayInterface, SelectedActivityCodeInterface, UpdateInterface } from '../../models/Update/Task';
import { ActvCompletionActv } from '../../components/project-page/overview/activity-completion/activity-completion.component';
import { isValid } from 'date-fns';
import { RiskRegister } from '../../models/risk';
import { ComponentNotes, ProjectInterface } from '../../models/Project';
import { ProjectReportInterface } from '../../models/ProjectReport/ProjectReport';
import { CompanyInterface } from '../../models/company';
import { Profile, UserInterface } from '../../models/auth/account-user';
import { MsqserveResult } from '../../models/msqserve';
import { UserService } from '../common/user.service';
import { NavigationBarStorageService } from '../common/navigation-bar-storage.service';
import { EventService } from '../infra/event.service';
import { ScheduleStorageService } from './schedule-storage.service';
import { DelayTask } from '../../components/project-page/schedule-updates-list/schedule-delays/schedule-delays.component';
import { Activity, XerActivity } from '@rhinoworks/xer-parse';
import {
	allComponents,
	OverviewComponent,
} from '../../components/project-page/project-admin-settings/overview-tab-settings/overview-tab-settings.component';
export type CurrentProjectReport = Omit<ProjectReportInterface, 'project'> & { project?: ProjectInterface };

@Injectable({
	providedIn: 'root',
})
export class ProjectDashboardService {
	projectsMap = new Map<string, any>([]);
	projectReportMap = new Map<string, any>([]);
	$currentProjectPageId = new BehaviorSubject<string>('');
	$currentProjectData = new BehaviorSubject<ProjectInterface>(undefined);
	$currentRiskRegisters = new BehaviorSubject<RiskRegister[]>([]);
	$currentProjectReport = new BehaviorSubject<CurrentProjectReport>(undefined);
	$expandedMetrics = new BehaviorSubject<ExpandedMetrics>(null);
	$previousExpandedMetrics = new BehaviorSubject<ExpandedMetrics>({} as ExpandedMetrics);
	queueProcessingChanged = new BehaviorSubject<boolean>(false);
	activitiesByCode = new BehaviorSubject<Map<string, XerActivity>>(new Map<string, XerActivity>([]));
	previousActivitiesByCode = new BehaviorSubject<Map<string, ActvCompletionActv>>(
		new Map<string, ActvCompletionActv>([])
	);
	calendars = new Map<number, CalendarArrayInterface>([]);
	isMissingPreviousMetrics = false;
	public fullyLoaded: boolean = false;
	public showArchiveConfirmation = new EventEmitter<boolean>();
	public allSQLProjects: Array<MsqserveResult> = [];
	public sqlProjects = new BehaviorSubject<Map<string, MsqserveResult>>(new Map<string, MsqserveResult>([]));
	public availableClientNames: string[] = [];
	public companiesById = new Map<string, CompanyInterface>([]);
	public allCompanies: CompanyInterface[] = [];
	availableAegisPocs: (UserInterface & Profile)[] = [];
	public aegisPocsLoaded = new BehaviorSubject<boolean>(false);
	public $projectArchived = new BehaviorSubject<boolean>(false);
	saveDisabledScorePlayground = true;
	resetDisabledScorePlayground = true;
	loadingScoresTab = false;
	public updatesEventSource: EventSource;
	readonly $calculationStatus: BehaviorSubject<any> = new BehaviorSubject<any>({ stage: 'Not Started', percent: 0 });
	public delayTasksByUpdate = new BehaviorSubject<DelayTask[][]>(null);
	selectedDelayActv: Activity = null;
	selectedOverviewTabComponents: OverviewComponent[] = [];
	hiddenLayoutTabs: string[] = [];
	hiddenLayoutComponents: OverviewComponent[] = [];
	overviewTabDefault: boolean = true;
	layoutTabDefault: boolean = true;
	public $componentNotes: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	notesWindow: { id: number; name: string } = { id: -1, name: '' };
	componentNoteChanges: boolean = false;
	$newNotesHeight: BehaviorSubject<number> = new BehaviorSubject<number>(null);
	currentProjectImg: string = '';

	constructor(
		private _httpClient: HttpClient,
		private restService: RestService,
		private notificationService: NotificationService,
		public userService: UserService,
		public navBarStorage: NavigationBarStorageService,
		private eventService: EventService,
		public _scheduleService: ScheduleStorageService
	) {
		this.queueProcessingChanged = new BehaviorSubject(false);
		const url = 'user?userType=aegis';
		this.restService.fetch(url).subscribe((response) => {
			this.availableAegisPocs = (response.userList || []).map((user, position) => ({
				_id: user._id,
				isArchived: user.isArchived,
				name: user.profile.name,
				...user.profile,
			}));
			this.availableAegisPocs = this.availableAegisPocs.filter((user) => !user.isArchived);
			this.availableAegisPocs.sort((a, b) => (a.name > b.name ? 1 : -1));
			this.aegisPocsLoaded.next(true);
		});
		this.$currentProjectPageId.subscribe((id) => {
			let totalmem = 0;
			if (id) {
				const updatesById = new Map<string, UpdateInterface>([]);
				this._scheduleService.$allUpdates.next([]);
				console.log('observing updates', id);

				const updateEvent = this.eventService.getServerSentEvent(`allUpdatesStream/${id}`);
				this.updatesEventSource = updateEvent.eventSource;

				const sortUpdates = (projectData: ProjectInterface) => {
					if (!projectData) {
						return;
					}
					const updates = Array.from(updatesById.values())
						.filter((update) => projectData.updateIds.includes(update._id))
						.sort((a, b) => projectData.updateIds.indexOf(a._id) - projectData.updateIds.indexOf(b._id));
					this._scheduleService.numUpdatedLoaded.next(updates.length);
					this._scheduleService.isLoading = updates.length !== projectData.updateIds.length;
					if (updates.length === projectData.updateIds.length) {
						this._scheduleService.$allUpdates.next(updates);
					}
				};

				updateEvent.observable.subscribe({
					next: (event: MessageEvent) => {
						const update: UpdateInterface = JSON.parse(event.data);
						totalmem += event.data.length;
						updatesById.set(update._id, update);
						const projectData = this.$currentProjectData.value;
						sortUpdates(projectData);
					},
					error: (error) => {
						// Handle the error
						console.error('EventSource failed:', error);
					},
				});

				this.getProjectData(id).then((project) => {
					project?.riskMitigation?.forEach((risk: RiskRegister) => {
						const preImpact = Math.max(
							risk.preMitigation.qualityImpact,
							risk.preMitigation.performanceImpact,
							risk.preMitigation.costImpact,
							risk.preMitigation.scheduleImpact
						);
						const postImpact = Math.max(
							risk.postMitigation.qualityImpact,
							risk.postMitigation.performanceImpact,
							risk.postMitigation.costImpact,
							risk.postMitigation.scheduleImpact
						);
						const likelihood = risk.preMitigation.probability;
						const preMitigationRiskScore = likelihood * preImpact;
						const postMitigationRiskScore = likelihood * postImpact;
						risk.riskAssessmentScore = preMitigationRiskScore * 4;
						risk.preMitigationRiskScore = preMitigationRiskScore;
						risk.postMitigationRiskScore = postMitigationRiskScore;
						risk.value = 1;
					});
					this.$currentProjectData.next(project);
					sortUpdates(project);
				});
				//this._projectDashboardService.$currentProjectReport.next(undefined);

				this._scheduleService.isLoading = true;
			}
		});

		this._scheduleService.$manualIdUpdate.subscribe((val) => {
			// if (
			// 	environment.baseURL === 'https://aegis-dashboards-backend-bleed.herokuapp.com/' ||
			// 	environment.baseURL === 'http://localhost:3000/'
			// ) {
			this.$currentProjectPageId.next(val);
			// }
		});

		const activityCriticalStatus = (prev: Activity, curr: Activity): string => {
			let criticalStatus = 'Not Critical';
			if (curr?.isCritical) {
				criticalStatus = 'Critical';
				if (!prev?.isCritical) {
					criticalStatus = 'Newly Critical';
				}
			} else if (prev && prev?.isCritical) {
				criticalStatus = 'No Longer Critical';
			}
			return criticalStatus;
		};
	}

	/**
	 * Resolver
	 *
	 * @param route
	 * @param state
	 * @returns
	 */
	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		/*		console.log('resolve', route.params.id);
		this.eventService.getServerSentEvent('sampleStream').subscribe({
			next: (event: MessageEvent) => {
				console.log('msg:', event.data);
			},
			error: (error) => {
				// Handle the error
				console.error('EventSource failed:', error);
			},
		});*/
		if (route.params.id) {
			console.log('resolve!', route.params.id);
			this.$currentProjectPageId.next(route.params.id);
			this.getSingleProjectReport(route.params.id).then(async (report) => {
				this.$currentProjectReport.next(report);
				this.$currentProjectData.next(report?.project);
				this.$currentRiskRegisters.next(report?.project?.riskMitigation || []);
				this.$projectArchived.next(report?.project?.isArchived);
				if (report?.project?.updateIds?.length) {
					const latestUpdateId = report?.project?.updateIds[report?.project?.updateIds.length - 1];
					const actvs = await this._scheduleService.grabUpdateTable<XerActivity>(latestUpdateId, 'TASK');
					this.activitiesByCode.next(new Map<string, XerActivity>(actvs.map((actv) => [actv.task_code, actv])));
					const prevUpdateId = report?.project?.updateIds[report?.project?.updateIds.length - 2];
					const prevActvs = await this._scheduleService.grabUpdateTable<XerActivity>(prevUpdateId, 'TASK');
					this.previousActivitiesByCode.next(
						new Map<string, XerActivity>(prevActvs.map((actv) => [actv.task_code, actv]))
					);
				}
				this.getUpdateExpandedMetrics();
			});
		}
	}

	getSingleProjectReport(projectId?: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.getProjectReport(projectId).subscribe((response: any) => {
				this.projectReportMap.set(projectId, response);
				resolve(response);
			}, reject);
		});
	}

	getProjectData(projectId: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.restService.fetch(`project/${projectId}`).subscribe((response: any) => {
				this.projectsMap.set(projectId, response);
				resolve(response);
			}, reject);
		});
	}

	getDetailedProjectData(projectId: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.restService.fetch(`dashboard/project/${projectId}`).subscribe((response: any) => {
				this.projectsMap.set(projectId, response);
				resolve(response);
			}, reject);
		});
	}

	addBaseline(projectId: string, baseline: any) {
		this.restService.post(`baselineupdate/${projectId}`, baseline).subscribe(
			(val) => {},
			(response) => {
				this.notificationService.showNotification(response.message || 'Unknown error!');
				console.log('POST call in error', response);
			},
			() => {
				window.location.reload();
				//this.calculateReport(projectId);
			}
		);
	}

	getProjectJobs() {
		return this.restService.fetch(`project/status/${this.$currentProjectPageId.value}`);
	}

	getProjectReport(projectId: string = this.$currentProjectPageId.value) {
		return this.restService.fetch(`report/single/${projectId}`);
	}

	getAllProjectReports() {
		return this.restService.fetch(`report/all/${this.$currentProjectPageId.value}`);
	}
	getAllProjectUpdates() {
		return this.restService.fetch(`allUpdates/${this.$currentProjectPageId.value}`);
	}

	getProjectUpdate() {
		return this.restService.fetch(`update-files/${this.$currentProjectData.value.currentUpdateId}`);
	}

	public async fetchAllProjects() {
		this._httpClient
			.get('https://rhinodeck.consultaegis.com/api/msqserve/projects/', {
				headers: {
					Authorization: 'Token 4d134a93e70ba3f26567fa3a4418005817054068',
				},
			})
			.subscribe(
				(result: MsqserveResult[]) => {
					this.allSQLProjects = result;
					const sqlProjects = new Map<string, MsqserveResult>([]);
					for (const project of this.allSQLProjects) {
						if (project.data?.Projectid && project.data?.Customername !== 'Aegis') {
							sqlProjects.set(project.data?.Projectid.toString(), project);
						}
					}
					this.sqlProjects.next(sqlProjects);
				},
				(e) => {
					console.log(e);
					this.sqlProjects.next(null);
				}
			);
	}

	getUpdateExpandedMetrics() {
		this.$expandedMetrics.next({} as ExpandedMetrics);
		const promises: Array<Promise<any>> = [];
		promises.push(
			new Promise((resolve, reject) => {
				if (!this.$currentProjectData.value?.updateIds?.length) {
					return resolve({});
				}
				this.restService
					.fetch(
						`expanded-metrics/${
							this.$currentProjectData.value.updateIds[this.$currentProjectData.value.updateIds?.length - 1]
						}`
					)
					.subscribe((response: any) => {
						const metrics = response as ExpandedMetrics;
						metrics.activityCodeMap = {};
						const actvCodes = metrics.activityCodes || [];
						for (const actvCode of actvCodes) {
							metrics.activityCodeMap[actvCode.actv_code_id] = actvCode;
						}
						metrics.activityTypeMap = {};
						const actvTypes = metrics.activityTypes || [];
						for (const actvType of actvTypes) {
							metrics.activityTypeMap[actvType.actv_code_type_id] = actvType;
						}
						this.$expandedMetrics.next(metrics);
						this.calendars.clear();
						const calendars = metrics.calendars || [];
						for (const calendar of calendars) {
							this.calendars.set(calendar.clndr_id, calendar);
						}
						if (
							!this.$currentProjectData.value?.updateIds?.length ||
							this.$currentProjectData.value.updateIds.length < 2
						) {
							return resolve({});
						}
						try {
							this.restService
								.fetch(
									`expanded-metrics/${
										this.$currentProjectData.value.updateIds[this.$currentProjectData.value.updateIds?.length - 2]
									}`
								)
								.subscribe((prevUpdateResponse: any) => {
									this.$previousExpandedMetrics.next(prevUpdateResponse);
									const unparsedPrevActivities = prevUpdateResponse.totalActivities || [];
									const prevTasksByCode = new Map<string, ActvCompletionActv>([]);
									for (const activity of unparsedPrevActivities) {
										for (const [key, value] of Object.entries(activity)) {
											if (value && key.includes('date') && isValid(new Date(value.toString()))) {
												activity[key] = new Date(value.toString());
											}
										}
										const actvCompletionActv = activity as ActvCompletionActv;
										const actvCurrently = this.activitiesByCode.value.get(actvCompletionActv.task_code);
										if (activity?.task_code) {
											if (actvCurrently) {
												if (actvCurrently.act_end_date) {
													actvCompletionActv.latest_status = 'Completed';
												} else if (actvCurrently.act_start_date) {
													actvCompletionActv.latest_status = 'Incomplete';
												} else {
													actvCompletionActv.latest_status = 'Not Started';
												}
												actvCompletionActv.latest_start_date =
													actvCompletionActv.act_start_date || actvCurrently.act_start_date
														? new Date(actvCurrently.act_start_date)
														: undefined;
												actvCompletionActv.latest_end_date =
													actvCompletionActv.act_end_date || actvCurrently.act_end_date
														? new Date(actvCurrently.act_end_date)
														: undefined;
											} else {
												actvCompletionActv.latest_status = 'Deleted';
											}
											prevTasksByCode.set(activity.task_code, actvCompletionActv);
										}
									}
									this.previousActivitiesByCode.next(prevTasksByCode);
									resolve(prevUpdateResponse);
								}, reject);
						} catch (err) {
							console.log('oopsie!', err);
						}

						resolve(response);
					}, reject);
			})
		);
		promises.push(new Promise((resolve, reject) => {}));
		Promise.all(promises).then((r) => {});
	}

	getMonteIsComplete() {
		return this.restService.fetch(`montecarlo/iscomplete/${this.$currentProjectPageId.value}`);
	}

	removeProjectUpdate(projectId: string = this.$currentProjectPageId.value, updateId: string) {
		return this.restService.delete(`update/${updateId}`, false, { updateId, projectId });
	}

	calculateReport(projectId: string) {
		return this.restService.post(`report/calculate/${projectId}`, {}).subscribe(
			(val) => {
				//this will return a key.
			},
			(response) => {
				this.notificationService.showNotification(response.message || 'Unknown error!');
				console.log('POST call in error', response);
			},
			() => {}
		);
	}

	addUpdate(projectId: string, update: any) {
		this.restService.post(`update/${projectId}`, update).subscribe(
			(val) => {},
			(response) => {
				console.log('POST call in error', response);
			},
			() => {}
		);
	}

	getBaselineUpdate(projectId: string) {
		return new Promise((resolve) => {
			this.restService
				.fetch(`baselineupdate/${projectId}`)
				.pipe(take(1))
				.subscribe((data: any) => {
					resolve(data);
				});
		});
	}

	shotPut(project: ProjectInterface) {
		this.$currentProjectData.next(project);
		this.$projectArchived.next(!!project.isArchived);
		this.restService.put(`project/${project._id}`, project).subscribe((val) => {});
	}

	updateProjectRisk(
		newRiskVal: boolean,
		newRiskMetricsType: string,
		currentProjectId: any,
		selectedActivityCodes: SelectedActivityCodeInterface[]
	) {
		this.restService.fetch(`project/${currentProjectId}`).subscribe((data) => {
			const newProject: ProjectInterface = data[0];
			newProject.riskPagePurchased = newRiskVal;
			newProject.riskMetricsType = newRiskMetricsType;
			newProject.selectedActivityCodes = selectedActivityCodes;
			this.restService.put(`project/${currentProjectId}`, newProject).subscribe((val) => {});
		});
	}

	updateProjectStatus(projectId: string, status: string) {
		console.log('updating status....', projectId, status);
		if (projectId && status) {
			return new Promise<void>((resolve, reject) =>
				this.restService.put(`project/status/${projectId}`, { status }).subscribe(() => {
					resolve();
				})
			);
		}
	}

	msToDateString(msDate: string): string {
		return new Date(Number(msDate)).toUTCString();
	}

	dateStringToDate(dateString: string): Date {
		if (!dateString) {
			return null;
		}
		const x = dateString.split(' ');
		const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
		const z = months.findIndex((m) => m === x[0]);
		return new Date(Number(x[1]), z, 1);
	}

	prettifyDateString(dateString: string): string {
		const dateSplit = dateString.split(' ');
		return dateSplit[2] + ' ' + dateSplit[3];
	}

	dateToDateStr(date: Date): string {
		const dateString = date.toDateString();
		const dateSplit = dateString.split(' ');
		return dateSplit[1] + ' ' + dateSplit[3];
	}

	openNotesWindow(componentInfo: { id: number; name: string }): void {
		const existingNotesWindow: HTMLElement = document.getElementById('componentNotesWindow');
		if (existingNotesWindow) {
			this.$componentNotes.next(false);
		}
		setTimeout(() => {
			this.notesWindow = componentInfo;
			this.$componentNotes.next(true);
		});
	}

	getNotes(id: number): ComponentNotes {
		return this.$currentProjectData.value?.componentNotes?.find((n) => n.id === id);
	}

	goTo(id: number): void {
		const component: OverviewComponent = allComponents.components?.find((c) => c.id === id);
		if (component) {
			const navFragment: string = component?.tabOrigin;
			if (navFragment === 'analysis') {
				this.navBarStorage.selectedTabSchedules = 'analysis';
				this.navBarStorage.selectedTab = 'schedules';
			} else {
				this.navBarStorage.selectedTab = navFragment;
			}
			const url = new URLSearchParams(new URL(window.location.href).search);
			url.set('tab', navFragment);
			history.pushState(null, '', window.location.pathname + '?' + url.toString());
			this.navBarStorage.$tabPointer.next(navFragment);
			window.scrollTo({ top: 0, behavior: 'instant' });
			let i: number = 0;
			const hasDomLoaded = setInterval(() => {
				const componentDOM: HTMLElement = document.getElementById(component.componentId) as HTMLElement;
				if (componentDOM !== undefined || i > 500) {
					const bb: DOMRect = componentDOM.getBoundingClientRect();
					window.scrollTo({ top: bb.top < 300 ? 0 : bb.top - 130, behavior: 'instant' });
					clearInterval(hasDomLoaded);
				}
				i++;
			}, 200);
		}
	}

	resetLayoutVals(): void {
		this.hiddenLayoutTabs = structuredClone(this.$currentProjectData.value.hiddenTabs);
		this.hiddenLayoutComponents = structuredClone(this.$currentProjectData.value.hiddenComponents);
	}

	componentHidden(id: number): boolean {
		const hiddenComponents: OverviewComponent[] = this.$currentProjectData.value.hiddenComponents;
		if (hiddenComponents?.length > 0) {
			const index: number = hiddenComponents.findIndex((c: OverviewComponent) => c.id === id);
			return index >= 0;
		}
		return false;
	}
}
