import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'floor' })
export class FloorPipe implements PipeTransform {
	/**
	 *
	 * @param value
	 * @returns {number}
	 */
	transform(value: number): number | undefined {
		if (isNaN(value)) {
			return undefined;
		}
		return Math.floor(value);
	}
}
