import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MilestonesComponent } from './milestones.component';
import { IonicModule } from '@ionic/angular';
import { TooltipMenuModule } from '../../../portfolio/tooltip-menu/tooltip-menu.module';
import { AegisChartModule } from '../../../shared/chart/chart.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { GridLayoutModule } from '@progress/kendo-angular-layout';
import { OverviewNotesModule } from '../../../shared/overview-notes/overview-notes.module';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

@NgModule({
	declarations: [MilestonesComponent],
	imports: [
		CommonModule,
		IonicModule,
		TooltipMenuModule,
		AegisChartModule,
		GridModule,
		GridLayoutModule,
		OverviewNotesModule,
		ButtonModule,
		TooltipModule,
	],
	exports: [MilestonesComponent],
})
export class MilestonesModule {}
