import { NgModule } from '@angular/core';
import { LoginModule } from 'components/authentication/login/login.module';
import { RegisterModule } from 'components/authentication/register/register.module';
import { Register2Module } from 'components/authentication/register-2/register-2.module';
import { ForgotPasswordModule } from 'components/authentication/forgot-password/forgot-password.module';
import { ForgotPassword2Module } from 'components/authentication/forgot-password-2/forgot-password-2.module';
import { MailConfirmModule } from 'components/authentication/mail-confirm/mail-confirm.module';
import { CommonModule } from '@angular/common';
import { ErrorsModule } from '../errors/errors.module';
import { AuthRoutingModule } from './auth-routing.module';

@NgModule({
	imports: [
		CommonModule,
		LoginModule,
		RegisterModule,
		Register2Module,
		ForgotPasswordModule,
		ForgotPassword2Module,
		MailConfirmModule,
		ErrorsModule,
		AuthRoutingModule,
	],
	exports: [
		LoginModule,
		RegisterModule,
		Register2Module,
		ForgotPasswordModule,
		ForgotPassword2Module,
		MailConfirmModule,
		ErrorsModule,
		AuthRoutingModule,
	],
})
export class AuthModule {}
