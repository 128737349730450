import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PfTableFilterModalComponent } from './pf-table-filter-modal/pf-table-filter-modal.component';
import { IonicModule } from '@ionic/angular';
import { SharedMatModule } from 'components/shared/shared-mat.module';

@NgModule({
	declarations: [PfTableFilterModalComponent],
	imports: [CommonModule, IonicModule, SharedMatModule],
})
export class PfTableFilterModalModule {}
