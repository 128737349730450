import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class UiStorageService {
	portfolioEmpty: boolean = false;

	databaseImportIsEnabled: boolean = true;

	constructor() {}
}
