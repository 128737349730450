import { Component, Input } from '@angular/core';
import { ProfileCompanyPermission } from '../../../models/auth/account-user';
import { ProjectDashboardService } from '../../../services/project/project.service';

@Component({
	selector: 'app-cost-page',
	templateUrl: './cost-page.component.html',
	styleUrls: ['./cost-page.component.scss'],
})
export class CostPageComponent {
	@Input() currentProjectCompanyPermissions: ProfileCompanyPermission = null;

	constructor(public projectService: ProjectDashboardService) {}
}
