<div id="manage-reports-component">
	<kendo-gridlayout
		[rows]="['auto', '30px']"
		[cols]="['calc(50% - (5px / 2))', 'calc(50% - (5px / 2))']"
		[gap]="{ rows: 5, cols: 3 }"
		class="grid"
		style="height: 100%;"
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-container'>
			<fieldset style="height: 100%;">
				<legend>
					Folders
				</legend>
				<kendo-treeview
					[nodes]="_groups"
					textField="name"
					[selectBy]="'id'"
					kendoTreeViewSelectable
					[(selectedKeys)]="ganttService.selectedGroup"
					(selectionChange)="groupSelectionChange($event)"
					class="report-groups-treeview"
				>
					<ng-template kendoTreeViewNodeTemplate let-dataItem>
						<span class="group-item-template">
							<span class="group-name">
								{{ dataItem.name }}
							</span>
							@if (dataItem.id !== 'all' && dataItem.id !== 'favorites') {
								<span class="actions">
									<button kendoButton fillMode='flat' title='Edit' [svgIcon]='icons.pencil' (click)='editGroup($event, dataItem)' style='color: dodgerblue;' class='report-icons'></button>
									<button kendoButton fillMode='flat' title='Duplicate' [svgIcon]='icons.copy' (click)='copyGroup($event, dataItem)' class='report-icons'></button>
									<button kendoButton fillMode='flat' title='Delete' [svgIcon]='icons.trash' (click)='deleteGroup($event, dataItem)' style='color: #f31700;' class='report-icons'></button>
								</span>
							}
						</span>
					</ng-template>
				</kendo-treeview>
			</fieldset>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-container'>
			<fieldset style="height: 100%;">
				<legend style="display: flex;">
					Reports
				</legend>
				<div class='projectSearchContainer' style="width: calc(100% - 9px); border: 1px solid #c2c2c2; margin: 1px 4px;">
					<kendo-textbox
						class='projectSearchInput'
						placeholder="Search Reports..."
						[clearButton]="true"
						[size]='"small"'
						[(value)]='ganttService.searchTerm'
						(afterValueChangedStr)='searchReports($event)'
						[disabled]='loading'
						#portfolioSearch
					>
						<ng-template kendoTextBoxPrefixTemplate>
							<kendo-svgicon [icon]='svgSearch'></kendo-svgicon>
							<kendo-textbox-separator></kendo-textbox-separator>
						</ng-template>
						<!--<ng-template kendoTextBoxSuffixTemplate>
							<span style='padding-right: 4px;' *ngIf='!loading'>
								{{$filteredProjects.value?.length}} of {{$allProjects.value?.length}}
							</span>
						</ng-template>-->
					</kendo-textbox>
				</div>
				<app-report-selection
					[$filteredReports]="filteredReportIds"
					(checkedReportChange)="updateSelectedReports($event)"
					class="manage-report-treeview"
				></app-report-selection>
			</fieldset>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1' class='gridlayout-container'>
			<div class="action-btns">
				<button kendoButton [svgIcon]="icons.folderAdd" title="Add New Folder" (click)="addGroup()" themeColor="info" class="icon-22" style="height: 30px; width: 34px;"></button>
			</div>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='2' class='gridlayout-container'>
			<div class="action-btns">
				<button kendoButton [svgIcon]="icons.plus" title="Add New Report" (click)="ganttService.openAddNewReportPopup()" themeColor="info" class="icon-24" style="height: 30px; width: 34px;"></button>
				<button kendoButton [svgIcon]="icons.save" title="Quick-Save Report" (click)="ganttService.toggleQuickSaveDialog(true)" themeColor="info" class="icon-20" style="height: 30px; width: 34px;"></button>
				<button kendoButton [svgIcon]="icons.folder" title="Add To Folder" [disabled]='selectedReportIds?.length === 0' (click)="addToFolder()" themeColor="info" class="icon-24" style="height: 30px; width: 34px;"></button>
				<button kendoButton [imageUrl]='"/assets/icons/newIcons/noun-pdf-3039009.svg"' title="Export as PDF" [disabled]='selectedReportIds?.length === 0' (click)="batchExport()" themeColor="info" class="new-img-20 white-btn-img" style="height: 30px; width: 34px;"></button>
			</div>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
</div>
