<div class="tab-page-container">
	<div class='card notes-visualizer-anchor regular-container' [class.saas-risk-component]='user?.userType === "saasRisk"'>
		@if (!visualizer && !['ANALYTICS-BASIC-MPK', 'ANALYTICS-BASIC-APK'].includes(currentProjectCompanyPermissions?.license) && !projectService.componentHidden(23)) {
			<div style="break-after: page">
				<app-schedule-impact-analysis [$projectData]='$projectData'></app-schedule-impact-analysis>
			</div>
		}
		@if (visualizer && !scheduleStorage.isLoading) {
			<div style='padding: 15px;'>
				<div class *ngFor='let element of (dataSource !== null && dataSource !== undefined) ? dataSource : []' style='margin-bottom: 0.5em;'>
					{{element.updateName}}<br>
					<span *ngIf='element.criticalPathNotes !== "" && element.criticalPathNotes !== null && element.criticalPathNotes !== undefined'><strong>{{element.criticalPathNotes}}</strong></span>
					<span *ngIf='element.criticalPathNotes === "" || element.criticalPathNotes === null || element.criticalPathNotes === undefined'><em>No Critical Path Notes Provided for this Update</em></span>
				</div>
			</div>
		}
	</div>
	@if (!['ANALYTICS-BASIC-MPK', 'ANALYTICS-BASIC-APK'].includes(currentProjectCompanyPermissions?.license)) {
		@if (!projectService.componentHidden(27)) {
			<kendo-gridlayout
				[rows]="['auto']"
				[cols]="['100%']"
				[gap]="{ rows: 5, cols: 0 }"
				class='grid'
			>
				<kendo-gridlayout-item [row]='1' [col]='1'>
					<app-driving-path></app-driving-path>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		}
		@if (!projectService.componentHidden(24)) {
			<kendo-gridlayout
				[rows]="['auto']"
				[cols]="['100%']"
				[gap]="{ rows: 5, cols: 0 }"
				class='grid'
			>
				<kendo-gridlayout-item [row]='1' [col]='1'>
					<app-critical-path-comparison [selectedDelayActivity]='projectService.selectedDelayActv' (selectionByClick)='ganttSelection($event)'></app-critical-path-comparison>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		}
		@if (!projectService.componentHidden(25)) {
			<kendo-gridlayout
				[rows]="['auto']"
				[cols]="['100%']"
				[gap]="{ rows: 5, cols: 0 }"
				class='grid'
			>
				<kendo-gridlayout-item [row]='1' [col]='1'>
					<app-schedule-comparison></app-schedule-comparison>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		}
	}
</div>


