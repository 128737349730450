<div id="login">
  <div id="login-form-wrapper" [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }">
    <div id="login-form">

      <div class="title" style="text-align: center">
        <img src='../../../assets/images/logos/AEGIS-ANALYTICS-COLOR.png' alt='Aegis Analytics' style='object-fit: contain; width: 100%'/>
      </div>

      <div [formGroup]="loginForm" *ngIf="!oauthLogin">
				<p
					class="text"
					[style]="this.bleeding ? 'color: white; background: red; text-align: center; padding: 10px; white-space: unset;' : 'color: white; background: orange; text-align: center; padding: 10px; white-space: unset;'"
					*ngIf="this.bleeding || environment === 'staging'"
				>
					This is a {{this.bleeding ? 'development' : 'pre-release'}} build of Aegis Analytics. You may encounter bugs or unstable behavior.
				</p>

        <ion-button size="full" (click)="beginAccountLogin()" *ngIf="environment === 'production'"
          >Login with Rhino.works</ion-button
        >
        <ion-button
          size="full"
          style="background: red !important"
          *ngIf="this.bleeding || environment === 'staging'"
          (click)="initiateOauthLogin()"
          >Sign In to {{this.bleeding ? 'Dev Environment ' : ''}}{{this.environment === 'staging' ? 'Staging' : ''}}</ion-button
        >
      </div>
      <ion-card *ngIf="oauthLogin">
        <ion-card-header>
          <ion-card-title><h4>Authenticating with Rhino.works</h4></ion-card-title>
        </ion-card-header>
        <ion-card-content style="text-align: center">
          <ion-spinner color="primary"></ion-spinner>
        </ion-card-content>
      </ion-card>

      <p class="text" style="color: darkgray; text-align: center">environment: {{ environment }}.{{this.environment === 'production' ? 'nyc3' : 'nyc3.edge'}}</p>

    </div>
  </div>
</div>
