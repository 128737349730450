import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { RiskRegister } from '../../../../models/risk';
import { RiskRegisterInfo } from '../risk-register/risk-register.component';
import { GridDataResult } from '@progress/kendo-angular-grid';

export type TopRisksGridTypes = 'prob' | 'activityImpact' | 'riskScore';

@Component({
	selector: 'app-top-risks',
	templateUrl: './top-risks.component.html',
	styleUrls: ['./top-risks.component.scss'],
})
export class TopRisksComponent implements OnInit, OnChanges {
	//these risks are all active risks
	@Input() riskRegisters: Array<RiskRegister & RiskRegisterInfo> = [];
	@Input() isOverview: boolean = false;
	public gridViewProb: GridDataResult;
	gridDataProb: Array<RiskRegister & RiskRegisterInfo> = [];
	public gridViewActivityImpact: GridDataResult;
	gridDataActivityImpact: Array<RiskRegister & RiskRegisterInfo> = [];
	public gridViewRiskScore: GridDataResult;
	gridDataRiskScore: Array<RiskRegister & RiskRegisterInfo> = [];

	constructor() {}

	ngOnInit() {
		this.generateTopRisks();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.riskRegisters) {
			this.generateTopRisks();
		}
	}

	/**
	 * generate new data from the latest active risks
	 */
	generateTopRisks(): void {
		const risksByProb = this.riskRegisters.sort((a, b) =>
			a.preMitigation.probability < b.preMitigation.probability
				? 1
				: a.preMitigation.probability > b.preMitigation.probability
					? -1
					: 0
		);
		const risksByActivityImpact = this.riskRegisters
			.filter((risk) => risk.preMitigation.activityImpact !== null && risk.preMitigation.activityImpact > 0)
			.sort((a, b) =>
				a.preMitigation.activityImpact > b.preMitigation.activityImpact
					? -1
					: a.preMitigation.activityImpact < b.preMitigation.activityImpact
						? 1
						: 0
			);
		const risksByRiskScore = this.riskRegisters.sort((a, b) =>
			a.preMitigationRiskScore < b.preMitigationRiskScore
				? 1
				: a.preMitigationRiskScore > b.preMitigationRiskScore
					? -1
					: 0
		);
		risksByRiskScore.forEach((risk) => {
			risk.preMitigationRiskScore =
				risk.preMitigation.probability *
				Math.max(
					risk.preMitigation.qualityImpact,
					risk.preMitigation.performanceImpact,
					risk.preMitigation.costImpact,
					risk.preMitigation.scheduleImpact
				);
		});
		this.loadRisks('prob', risksByProb.slice(0, 5));
		this.loadRisks('activityImpact', risksByActivityImpact.slice(0, 5));
		this.loadRisks('riskScore', risksByRiskScore.slice(0, 5));
	}

	/**
	 * update grids with new values
	 * @param grid
	 * @param risks
	 */
	loadRisks(grid: TopRisksGridTypes, risks: Array<RiskRegister & RiskRegisterInfo>): void {
		switch (grid) {
			case 'prob': {
				this.gridViewProb = {
					data: risks,
					total: risks?.length,
				};
				this.gridDataProb = risks;
				break;
			}
			case 'activityImpact': {
				this.gridViewActivityImpact = {
					data: risks,
					total: risks?.length,
				};
				this.gridDataActivityImpact = risks;
				break;
			}
			case 'riskScore': {
				this.gridViewRiskScore = {
					data: risks,
					total: risks?.length,
				};
				this.gridDataRiskScore = risks;
				break;
			}
		}
	}
}
