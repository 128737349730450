import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddBaselineComponent } from './add-baseline/add-baseline.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedMatModule } from 'components/shared/shared-mat.module';
import { AddBaselineButtonComponent } from './add-baseline/add-baseline-button/add-baseline-button.component';
import { IonicModule } from '@ionic/angular';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AddRiskModule } from '../../risk/add-risk/add-risk.module';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LabelModule } from '@progress/kendo-angular-label';
import { WindowModule } from '@progress/kendo-angular-dialog';
import { FileSelectModule } from '@progress/kendo-angular-upload';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

@NgModule({
	declarations: [AddBaselineComponent, AddBaselineButtonComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		SharedMatModule,
		IonicModule,
		MatSlideToggleModule,
		AddRiskModule,
		DropDownsModule,
		LabelModule,
		WindowModule,
		FileSelectModule,
		MatButtonModule,
		MatInputModule,
	],
	exports: [AddBaselineButtonComponent],
})
export class AddBaselineModule {}
