import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationBarStorageService } from '../../../../../services/common/navigation-bar-storage.service';
import { AnalyticsDashboardService } from '../../../../../services/analytics/analytics.service';
import { AppWindowService } from '../../../../../services/common/window.service';

@Component({
	selector: 'app-add-project-button',
	templateUrl: 'add-project-button.component.html',
	styleUrls: ['./add-project-button.component.scss'],
})
export class AddProjectButtonComponent {
	@Input() clientList;
	@Output() addNewProject = new EventEmitter<boolean>();
	note: string;
	constructor(
		public dialog: MatDialog,
		public navBarStorage: NavigationBarStorageService,
		public services: AnalyticsDashboardService,
		public appWindowService: AppWindowService
	) {}

	openDialog(): void {
		if (!this.navBarStorage.eulaOpened) {
			this.navBarStorage.newProjectFormMaxHeight = Math.min(window.innerHeight - 132, 750);
			this.services.addProjectOpen.next(true);
			this.appWindowService.setViewport('baseline');
			this.navBarStorage.showingBaselineProject = false;
			this.navBarStorage.showingNewProject = true;
		}
	}

	openAdDialog(): void {
		this.navBarStorage.$adDialogOpen.next(true);
	}
}
