import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { Directive } from '@angular/core';

const KEY = 'MAT_SORT';

@Directive({
	selector: '[appTableSortingCache]',
})
export class TableSortingDirective {
	get matSort(): MatSortData {
		return JSON.parse(localStorage.getItem(window.location.pathname + '?' + KEY));
	}

	set matSort(mat: MatSortData) {
		localStorage.setItem(window.location.pathname + '?' + KEY, JSON.stringify(mat));
	}

	constructor(private el: MatSort) {}

	ngOnInit(): void {
		if (this.matSort) {
			this.el.active = this.matSort.active;
			this.el.direction = this.matSort.direction;
		} else {
			this.el.active = 'name';
			this.el.direction = 'asc';
		}
		this.el.sortChange.subscribe((sort: Sort) => {
			const needsDefault = !sort.direction?.length;
			this.matSort = {
				active: needsDefault ? 'name' : sort.active,
				direction: needsDefault ? 'asc' : sort.direction,
			};
		});
	}
}

interface MatSortData {
	active: string;
	direction: SortDirection;
}
