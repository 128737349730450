import {
	Component,
	OnInit,
	ViewChild,
	Input,
	Output,
	EventEmitter,
	ChangeDetectorRef,
	ViewEncapsulation,
	ElementRef,
	TemplateRef,
	HostListener,
} from '@angular/core';
import { differenceInCalendarDays, differenceInDays, isValid } from 'date-fns';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CurrentProjectReport, ProjectDashboardService } from '../../../services/project/project.service';
import { UserService } from '../../../services/common/user.service';
import { ScheduleStorageService } from '../../../services/project/schedule-storage.service';
import { RestService } from '../../../services/common/rest.service';
import { NotificationService } from '../../../services/common/notification.service';
import { CashFlow } from '../../../models/ProjectReport/ProjectReport';
import { ProjectInterface } from '../../../models/Project';
import { NavigationBarStorageService } from '../../../services/common/navigation-bar-storage.service';
import { PopoverController } from '@ionic/angular';
import { AnalyticsDashboardService } from '../../../services/analytics/analytics.service';
import {
	caretAltDownIcon,
	columnsIcon,
	fileExcelIcon,
	fileReportIcon,
	pencilIcon,
	SVGIcon,
	trashIcon,
	undoIcon,
} from '@progress/kendo-svg-icons';
import { CellClickEvent, ExcelExportEvent, GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { formatExcelExport } from '../../../util/excel';
import { cleanDateUTC } from '../../../util/pipes/date.pipe';
import { AppWindowService } from '../../../services/common/window.service';
import { hasObjChanged, scheduleType } from '../../../util/projects';
import { SlimmedTaskCommon, ScheduleAnalysisTask, UpdateInterface } from '../../../models/Update/Task';
import { DelayTask } from './schedule-delays/schedule-delays.component';
import { Activity, ActivityPredecessor, Xer, XerActivity, XerData } from '@rhinoworks/xer-parse';
import { ProfileCompanyPermission } from '../../../models/auth/account-user';
import { EditUpdateResult } from './edit-notes/edit-notes.component';
import { PopupRef, PopupService } from '@progress/kendo-angular-popup';
import { NavigationCancel, Router } from '@angular/router';
import { Update } from '../../../services/project/update/update.model';
import Papa from 'papaparse';
import { NotesPreferences } from '../../../models/company';
interface ScheduleListColumn {
	key: string;
	display: string;
	optional?: boolean;
	selected?: boolean;
	isDate?: boolean;
	index?: number;
}

export interface ColumnType {
	field: string;
	title: string;
	date?: boolean;
	isNumber?: boolean;
	optional?: boolean;
	selected?: boolean;
	width: number;
	sequence?: number;
	defaultSelected: boolean;
}

export interface EditedUpdate extends UpdateInterface {
	toAdd?: boolean;
	toDelete?: boolean;
	toBaseline?: boolean;
	unBaseline?: boolean;
	newFinish?: boolean;
	createdAtDate?: Date;
	contractCompletionDate?: Date;
	currentCompletionDate?: Date;
	contractVariance?: number;
	previousVariance?: number;
	cpli?: string;
	tfci?: string;
	fileName?: string;
	url?: string;
	updateName?: string;
	scheduleName?: string;
	progressImpact?: number;
	logicImpact?: number;
	drivingCriticalActivity?: string;
	dataDate?: Date | string;
	index?: number;
	missingUpdate?: boolean;
	missingNotes?: boolean;
	update?: UpdateInterface;
	isRebaseline?: boolean;
	hasCostData?: boolean;
	isCostBaseline?: boolean;
	isBecomingCostBaseline?: boolean;
	wasCostBaseline?: boolean;
	wouldBeAutoCostBaseline?: boolean;
}

@Component({
	selector: 'app-schedule-updates-list',
	templateUrl: './schedule-updates-list.component.html',
	styleUrls: ['./schedule-updates-list.component.css'],
	encapsulation: ViewEncapsulation.None,
})
export class ScheduleUpdatesListComponent implements OnInit {
	public svgExcel: SVGIcon = fileExcelIcon;
	public svgColumns: SVGIcon = columnsIcon;
	@Input() $projectData = new BehaviorSubject<ProjectInterface>(undefined);
	@Input() $updates = new BehaviorSubject<EditedUpdate[]>([]);
	@Input() visualizer: boolean = false;
	@Output() exportImpactAnalysis = new EventEmitter<void>();
	@ViewChild(GridComponent)
	public grid: GridComponent;
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	hiddenColumns = new Set<string>([]);
	public chooserOpen = false;
	//public dataSource = new MatTableDataSource([]);
	public dataSource: EditedUpdate[];
	public icons = { fileReport: fileReportIcon, pencilIcon, trashIcon, undoIcon, caretDown: caretAltDownIcon };
	public columns: ColumnType[] = [
		{
			field: 'updateName',
			title: 'Update',
			selected: true,
			optional: false,
			width: 70,
			sequence: 0,
			defaultSelected: true,
		},
		{
			field: 'scheduleName',
			title: 'Schedule Name',
			selected: true,
			optional: true,
			width: 80,
			sequence: 1,
			defaultSelected: true,
		},
		{
			field: 'finishMilestone.task_code',
			title: 'Finish Milestone',
			selected: false,
			optional: true,
			width: 80,
			sequence: 2,
			defaultSelected: false,
		},
		{
			field: 'dataDate',
			title: 'Data Date',
			date: true,
			selected: true,
			optional: false,
			width: 100,
			sequence: 3,
			defaultSelected: true,
		},
		{
			field: 'currentCompletionDate',
			title: 'Current Completion',
			date: true,
			selected: true,
			optional: false,
			width: 90,
			sequence: 4,
			defaultSelected: true,
		},
		{
			field: 'contractCompletionDate',
			title: 'Contract Completion',
			date: true,
			selected: true,
			optional: true,
			width: 90,
			sequence: 5,
			defaultSelected: true,
		},
		{
			field: 'contractVariance',
			title: 'Contract Δ',
			selected: true,
			isNumber: true,
			optional: true,
			width: 65,
			sequence: 6,
			defaultSelected: true,
		},
		{
			field: 'previousVariance',
			title: 'Update Δ',
			selected: true,
			isNumber: true,
			optional: true,
			width: 60,
			sequence: 7,
			defaultSelected: true,
		},
		{
			field: 'progressImpact',
			title: 'Progress Impact',
			selected: true,
			isNumber: true,
			optional: true,
			width: 65,
			sequence: 8,
			defaultSelected: true,
		},
		{
			field: 'logicImpact',
			title: 'Logic Impact',
			selected: true,
			isNumber: true,
			optional: true,
			width: 60,
			sequence: 9,
			defaultSelected: true,
		},
		{
			field: 'drivingCriticalActivity',
			title: 'Driving Critical Activity',
			selected: true,
			optional: true,
			width: 100,
			sequence: 10,
			defaultSelected: true,
		},
		{
			field: 'cpli',
			title: 'CPLI',
			selected: false,
			isNumber: true,
			optional: true,
			width: 60,
			sequence: 11,
			defaultSelected: false,
		},
		/*{
			field: 'tfci',
			title: 'TFCI',
			selected: true,
			isNumber: true,
			optional: true,
			width: 60,
			sequence: 8,
			defaultSelected: true,
		},*/
		{
			field: 'report',
			title: 'Report',
			selected: true,
			optional: false,
			width: 60,
			sequence: 12,
			defaultSelected: true,
		},
		// { field: 'notes', title: 'Notes', selected: true, optional: false, width: 40 },
		{
			field: 'fileName',
			title: 'File',
			selected: true,
			optional: false,
			width: 100,
			sequence: 13,
			defaultSelected: true,
		},
		{
			field: 'createdAtDate',
			title: 'Uploaded',
			date: true,
			selected: true,
			optional: true,
			width: 90,
			sequence: 14,
			defaultSelected: true,
		},
		// { field: 'actions', title: '', selected: true, optional: false, width: 50 },
	];
	nonActionColumns: ColumnType[] = structuredClone(this.columns.filter((c) => c.title !== ''));
	selectedColumns: any[] = [];
	optionalColumns: string[] = [];
	selectedOptionalColumns: string[] = [];
	numUpdates = 0;
	user: any = {
		userType: 'aegis',
	};
	selectedDelayActv: Activity = null;
	clearDelaySelection = new BehaviorSubject<boolean>(false);
	currentProjectCompanyPermissions: ProfileCompanyPermission = null;
	editingUpdateData: { element: EditedUpdate; name: string; index: number } = null;
	columnSelectorOpen: boolean = false;
	private columnSelectorPopupRef: PopupRef;
	@ViewChild('columnSelectorPopupTemplate') columnSelectorTemplateRef: TemplateRef<any>;
	_visibleColumns: any[] = [];

	public expandedDetailKeys: string[] = [];
	public delayTasks: DelayTask[] = [];
	public delayTasksByUpdate: DelayTask[][] = [];
	lastUsedCashFlowHistoricalData: CashFlow[];
	costBaselineId: string;
	manualCostBaselineId: string; //this one includes softadded but not saved yet cases
	@Input() notesRequirement: BehaviorSubject<NotesPreferences> = new BehaviorSubject<NotesPreferences>({
		critPathRequired: false,
		tiaRequired: false,
	});

	validDate = isValid;
	constructor(
		public projectService: ProjectDashboardService,
		public userService: UserService,
		public scheduleStorage: ScheduleStorageService,
		public restService: RestService,
		public notificationService: NotificationService,
		public navbar: NavigationBarStorageService,
		public popoverController: PopoverController,
		public analyticsService: AnalyticsDashboardService,
		public navBarStorage: NavigationBarStorageService,
		public appWindowService: AppWindowService,
		private popupService: PopupService,
		private router: Router
	) {}
	ngOnInit(): void {
		this.updateColumns(true);

		const initRows = (updates: UpdateInterface[], report: CurrentProjectReport) => {
			updates = updates?.[0]?.projectId === this.$projectData.value?._id ? updates : [] || [];
			if (this.numUpdates !== 0 && updates.length === 0 && this.user?.userType === 'saasRisk') {
				this.navbar.selectedTab = 'risk';
				const url = new URLSearchParams(new URL(window.location.href).search);
				url.set('tab', this.navbar.selectedTab);
				history.pushState(null, '', window.location.pathname + '?' + url.toString());
				this.navbar.$tabPointer.next('risk');
			}
			this.numUpdates = updates.length;
			const updatedUpdates: EditedUpdate[] = [];
			let startMilestone: XerActivity;
			let finishMilestoneCode: string;
			let prevUpdateCurrentCompletion: Date = null;

			for (let i = 0; i < updates.length; i++) {
				const update = updates[i];
				const isBaseline = i === 0 || update.baseline;
				const finishMilestone = update.finishMilestone;

				if (i === 0 || !startMilestone) {
					startMilestone = update.startMilestone;
				}
				if (finishMilestone) {
					finishMilestoneCode = finishMilestone.task_code;
				}
				const contractCompletion = new Date(
					finishMilestone?.act_end_date || finishMilestone?.cstr_date || finishMilestone?.early_end_date
				);
				const currentCompletion = new Date(finishMilestone?.act_end_date || finishMilestone?.early_end_date);
				const dataDateFromUpdate: Date = new Date(
					update?.dataDate || report.projectCompletionTrend.projectCompletionTrendArray[i].dataDate
				);
				//timezones make non-matching dates :) so we make UTC
				const dataDate: Date = new Date(
					dataDateFromUpdate.getUTCFullYear(),
					dataDateFromUpdate.getUTCMonth(),
					dataDateFromUpdate.getUTCDate(),
					dataDateFromUpdate.getUTCHours()
				);
				const contractVariance =
					isValid(contractCompletion) && isValid(currentCompletion)
						? differenceInCalendarDays(contractCompletion, currentCompletion)
						: undefined;
				let previousVariance;
				if (
					update._id !== updates[0]._id &&
					isValid(updatedUpdates[i - 1].currentCompletionDate) &&
					isValid(currentCompletion)
				) {
					previousVariance = differenceInCalendarDays(updatedUpdates[i - 1].currentCompletionDate, currentCompletion);
				}
				let cpli;
				let tfci;
				if (update?.isAegisGeneratedSchedule === false) {
					//only show the flag column if there is a client schedule
					//if issue with aegis generated schedule flag column: come back and fix this
					//this.columns.isAegisGeneratedSchedule.selected = true;
					this.updateColumns();
				}
				if (this.projectService.$currentProjectReport.value?.calculationFieldsHistorical?.[i]) {
					cpli = this.projectService.$currentProjectReport.value.calculationFieldsHistorical[i].CPLI / 100;
					tfci = this.projectService.$currentProjectReport.value.calculationFieldsHistorical[i].TFCI / 100;
				}
				let drivingCriticalActivity;
				if (this.projectService.$currentProjectReport.value?.drivingCriticalActivityHistorical?.[i]) {
					drivingCriticalActivity =
						this.projectService.$currentProjectReport.value.drivingCriticalActivityHistorical[i];
					if (drivingCriticalActivity && drivingCriticalActivity === 'undefined - undefined') {
						drivingCriticalActivity = 'N/A';
					}
				}

				let scheduleName;
				if (this.projectService.$currentProjectReport.value?.xerScheduleNameHistorical?.[i]) {
					scheduleName = this.projectService.$currentProjectReport.value.xerScheduleNameHistorical[i];
				}
				const lastUploaded = new Date(update?.dataDate);
				const now = new Date();
				const dUpload = differenceInDays(now, lastUploaded);
				const type = scheduleType(this.projectService.$currentProjectReport.value?.project);
				let progressImpact;
				let logicImpact;
				if (update._id !== updates[0]._id && isValid(prevUpdateCurrentCompletion)) {
					let progressDelay: number | Date =
						this.projectService.$currentProjectReport.value?.progressDelayHistorical[i - 1];
					//potentially not the best way to check this -RS
					if (progressDelay === null) {
						progressDelay = updatedUpdates[i - 1].currentCompletionDate;
					}
					progressImpact = differenceInCalendarDays(prevUpdateCurrentCompletion, new Date(progressDelay));
					logicImpact = previousVariance - progressImpact;
				}
				const missingNotes: boolean =
					(this.notesRequirement.value.critPathRequired &&
						(!update?.criticalPathNotes || !update?.criticalPathNotes?.length)) ||
					(this.notesRequirement.value.tiaRequired &&
						(!update?.timeAnalysisNotes || !update?.timeAnalysisNotes?.length));
				const updatedUpdate: EditedUpdate = {
					...update,
					update,
					fileName: update.xerFileName,
					url: update.url,
					dataDate,
					updateName: updatedUpdates.length === 0 ? 'Baseline' : `Update ${updatedUpdates.length}`,
					scheduleName,
					progressImpact,
					logicImpact,
					drivingCriticalActivity,
					createdAtDate: new Date(update.LastModified),
					contractCompletionDate: new Date(contractCompletion),
					currentCompletionDate: new Date(currentCompletion),
					contractVariance,
					previousVariance,
					cpli: isNaN(cpli) || !isFinite(cpli) ? '0' : cpli.toFixed(2),
					tfci: isNaN(tfci) || !isFinite(cpli) ? '0' : tfci.toFixed(2),
					_id: update._id,
					projectId: update.projectId,
					criticalPathNotes: update.criticalPathNotes,
					timeAnalysisNotes: update.timeAnalysisNotes,
					isAegisGeneratedSchedule: update?.isAegisGeneratedSchedule,
					toDelete: false,
					toAdd: false,
					toBaseline: false,
					index: i,
					missingUpdate: i < updates.length - 1 ? false : dUpload >= 45 && type === 'Active',
					missingNotes,
					isRebaseline: update.baseline || false,
				};
				updatedUpdates.push(updatedUpdate);
				prevUpdateCurrentCompletion = currentCompletion;
			}
			this.dataSource = updatedUpdates;
			if (this.projectService.$currentProjectReport.value) {
				this.updateCostData(this.projectService.$currentProjectReport.value?.cashFlowHistorical);
			}
			if (this.showFinishMilestone()) {
				const column = this.columns.find((col) => col.field === 'finishMilestone.task_code');
				if (column) {
					column.selected = true;
				}
				this.updateColumns();
			}
			this.scheduleStorage.$modifiedUpdates.next(updatedUpdates);
			this.addActionColumn();
		};
		this.scheduleStorage.$allUpdates.pipe(takeUntil(this._unsubscribeAll)).subscribe((updates) => {
			if (this.projectService.$currentProjectReport.value) {
				initRows(updates, this.projectService.$currentProjectReport.value);
			}
		});

		this.projectService.$currentProjectReport.subscribe((report) => {
			if (report && this.dataSource) {
				this.updateCostData(report.cashFlowHistorical);
			}
			if (this.scheduleStorage.$allUpdates.value) {
				initRows(this.scheduleStorage.$allUpdates.value, report);
			}
		});

		this.userService.user.subscribe((data) => {
			if (data) {
				this.user = data;
			}
		});

		this.projectService.$currentProjectData.subscribe((data) => {
			this.currentProjectCompanyPermissions =
				data === undefined ? null : this.navBarStorage.companyPermissionMap.get(data?.company);
		});
		this.addActionColumn();

		this.router.events.subscribe((el) => {
			if (el instanceof NavigationCancel) {
				this.analyticsService.navUrl = el.url;
			}
		});

		this.scheduleStorage.$clearCostBlHistory.subscribe((val: boolean) => {
			if (val) {
				this.dataSource.forEach((update) => {
					update.wasCostBaseline = false;
					update.isBecomingCostBaseline = false;
				});
			}
		});

		this.scheduleStorage.$softAddIsCostBl.subscribe((val: boolean) => {
			if (val) {
				const addedUpdates: EditedUpdate[] = this.dataSource.filter((u: EditedUpdate) => u.toAdd);
				const addedIsCostBl: boolean = addedUpdates.some((u: EditedUpdate) => u.isCostBaseline);
				if (addedIsCostBl) {
					this.dataSource.forEach((update: EditedUpdate) => {
						if (!update.toAdd && update.isCostBaseline) {
							update.wasCostBaseline = true;
							update.isCostBaseline = false;
						}
					});
				}
			}
		});
	}

	updateCostData(cashFlowHistorical: CashFlow[]): void {
		if (
			this.lastUsedCashFlowHistoricalData === undefined ||
			hasObjChanged(this.lastUsedCashFlowHistoricalData, cashFlowHistorical)
		) {
			this.lastUsedCashFlowHistoricalData = cashFlowHistorical;
		}
		const userSelectedCostBaseline: string = this.$projectData.value?.costBaseline;
		let hasAssignedAutoBl: boolean = false;
		if (this.dataSource) {
			this.dataSource.forEach((update: EditedUpdate, index: number) => {
				update.hasCostData =
					cashFlowHistorical !== undefined &&
					//cashFlowHistorical[index]?.historicActualCost !== undefined &&
					cashFlowHistorical[index]?.cumulativeRemainingTotalCost !== undefined &&
					cashFlowHistorical[index]?.cumulativeRemainingLateTotalCost !== undefined &&
					cashFlowHistorical[index]?.remainingTotalCost !== undefined &&
					cashFlowHistorical[index]?.remainingLateTotalCost !== undefined &&
					cashFlowHistorical[index]?.averageCashFlowCurve !== undefined;
				update.wouldBeAutoCostBaseline = update.hasCostData && !hasAssignedAutoBl;
				if (update.wouldBeAutoCostBaseline) {
					hasAssignedAutoBl = true;
				}
				update.isCostBaseline =
					userSelectedCostBaseline !== undefined
						? update._id === userSelectedCostBaseline
						: update.wouldBeAutoCostBaseline;
				if (update.isCostBaseline) {
					this.costBaselineId = update._id;
					this.manualCostBaselineId = update._id;
				}
			});
		}
	}

	@HostListener('window:mousedown', ['$event'])
	mouseDown(event) {
		//closes column selector popup if clicked outside the popup
		if (this.columnSelectorOpen) {
			const columnsBtn: DOMRect = document.getElementById('columnsBtn')?.getBoundingClientRect();
			if (
				columnsBtn &&
				event.x >= columnsBtn.left &&
				event.x <= columnsBtn.right &&
				event.y >= columnsBtn.top &&
				event.y <= columnsBtn.bottom
			) {
				return;
			}
			const columnSelectorContainer: HTMLElement = document.getElementById('columnSelectorContainer');
			const columnSelectorPopupBounds: DOMRect =
				columnSelectorContainer?.parentElement?.parentElement?.getBoundingClientRect();
			if (
				columnSelectorPopupBounds &&
				!(
					event.x >= columnSelectorPopupBounds.left &&
					event.x <= columnSelectorPopupBounds.right &&
					event.y >= columnSelectorPopupBounds.top &&
					event.y <= columnSelectorPopupBounds.bottom
				)
			) {
				this.closeColumns();
			}
		}
	}

	toggleColumnSelector(anchor: ElementRef | HTMLElement): void {
		const popupColumnSelectorContainer: HTMLElement = document.getElementById('columnSelectorContainer');
		if (popupColumnSelectorContainer !== null || anchor === null) {
			this.columnSelectorPopupRef.close();
			this.columnSelectorOpen = false;
		} else {
			if (anchor !== undefined) {
				this.columnSelectorPopupRef = this.popupService.open({
					anchor,
					content: this.columnSelectorTemplateRef,
					popupClass: ['columns-popup-class'],
					margin: { vertical: 10, horizontal: 0 },
				});
				this.columnSelectorOpen = true;
			}
		}
	}

	closeColumns(): void {
		const popupColumnSelectorContainer: HTMLElement = document.getElementById('columnSelectorContainer');
		if (popupColumnSelectorContainer !== null) {
			this.columnSelectorPopupRef.close();
			this.columnSelectorOpen = false;
		}
	}

	resetColumns(): void {
		const defaultColumns: string[] = [];
		this.columns.forEach((column: ColumnType) => {
			if (column.defaultSelected) {
				defaultColumns.push(column.sequence.toString());
			}
		});
		this.visibleColumns = defaultColumns;
	}

	public children = (dataItem: any): Observable<ColumnType[]> => of(dataItem.children);
	public hasChildren = (dataItem: any): boolean => !!dataItem.children;
	public isDisabled = (dataItem: any) => !dataItem.optional;

	set visibleColumns(columns: string[]) {
		columns = columns.sort((a: string, b: string) => {
			const valA: number = Number(a);
			const valB: number = Number(b);
			return valA < valB ? -1 : valB < valA ? 1 : 0;
		});
		this._visibleColumns = columns;
		const hiddenCols = new Set<string>();
		this.columns.forEach((column) => {
			column.selected = !column.optional || columns.includes(column.sequence.toString());
			if (!column.selected) {
				hiddenCols.add(column.field);
			}
		});
		this.nonActionColumns = this.columns.filter((c) => c.title !== '');

		this.hiddenColumns = hiddenCols;
	}

	get visibleColumns() {
		return this._visibleColumns;
	}

	/**
	 * add action column if eligible
	 */
	addActionColumn(): void {
		if (
			this.projectService.$currentProjectReport.value?.permissionData?.role !== 'viewer' &&
			!this.$projectData.value?.isArchived &&
			this.columns.findIndex((col) => col.field === 'actions') === -1
		) {
			this.columns.push({
				field: 'actions',
				title: '',
				selected: true,
				optional: false,
				width: 50,
				defaultSelected: true,
				sequence: 12,
			});
		}
	}

	updateColumns(isFirst: boolean = false) {
		const columns: string[] = [];
		const selectedKeys: string[] = [];
		const optionalColumns: string[] = [];
		const selectedOptionalColumns: string[] = [];
		for (const [key, col] of Object.entries(this.columns)) {
			if (col.selected) {
				columns.push(key);
			}
			if (col.optional) {
				optionalColumns.push(key);
				if (col.selected) {
					selectedOptionalColumns.push(key);
					this.hiddenColumns.delete(col.field);
				} else {
					this.hiddenColumns.add(col.field);
				}
			}
			if (isFirst && col.defaultSelected) {
				selectedKeys.push(col.sequence.toString());
			}
		}
		columns.sort((a, b) => this.columns[a].index - this.columns[b].index);
		this.selectedColumns = columns;
		if (isFirst) {
			this.visibleColumns = selectedKeys;
		}
		this.optionalColumns = optionalColumns;
		this.selectedOptionalColumns = selectedOptionalColumns;
	}

	removeUpdate(projectId: string, updateId: string) {
		this.projectService.removeProjectUpdate(projectId, updateId).subscribe((a) => {
			const currentUpdates = this.scheduleStorage.$modifiedUpdates.value;
			for (let i = 0; i < currentUpdates.length; i++) {
				if (currentUpdates[i]._id?.toString() === updateId) {
					currentUpdates.splice(i, 1);
					this.scheduleStorage.$modifiedUpdates.next(currentUpdates);
					break;
				}
			}
		});
	}

	saveAndCalculate() {
		const updates = [];
		for (let i = 0; i < this.scheduleStorage.$modifiedUpdates.value.length; i++) {
			if (!this.scheduleStorage.$modifiedUpdates.value[i].toDelete) {
				updates.push(this.scheduleStorage.$modifiedUpdates.value[i]._id);
			}
		}

		this.restService
			.post(`updatelistchange/${this.projectService.$currentProjectPageId.value}`, { updateIds: updates })
			.subscribe(
				(response) => {
					this.notificationService.showNotification('Data saved successfully! Now recalculating...');
				},
				(error) => {
					console.log('error', error);
					this.notificationService.showNotification(error.message || 'Oops! Something went wrong!');
				}
			);
	}

	saveChanges() {
		const updatesAdded = this.dataSource.filter((update) => update?.toAdd);
		const addedIndexes = [];
		updatesAdded.forEach((update) => {
			const indexInList = this.dataSource.findIndex((u) => u._id === update._id);
			if (indexInList !== -1) {
				addedIndexes.push(indexInList);
			}
		});
		if (addedIndexes.length) {
			localStorage.setItem('updatesAdded', JSON.stringify(addedIndexes));
			localStorage.setItem('newUpdatesLength', JSON.stringify(this.dataSource.length));
		}
		this.scheduleStorage.$modifiedUpdates.next(this.dataSource);
		this.scheduleStorage.saveAndCalculate();
	}

	requestDownload(updateId: string, fileName: string) {
		this.restService
			.fetch('update/' + updateId + '/download', undefined, undefined, undefined, true)
			.subscribe((resp) => {
				const a = document.createElement('a');
				document.body.appendChild(a);
				a.style.display = 'none';
				const url = window.URL.createObjectURL(resp);
				a.href = url;
				a.download = fileName;
				a.click();
				window.URL.revokeObjectURL(url);
			});
	}

	setUpdateDelete(element: any, toDelete: boolean = false) {
		const scheduleUpdates = this.scheduleStorage.$modifiedUpdates.value;
		const index = scheduleUpdates.indexOf(element);
		if (scheduleUpdates[index]?.toAdd) {
			scheduleUpdates.splice(index, 1);
			this.scheduleStorage.unsavedChanges.delete(element._id);
		} else {
			scheduleUpdates[index].toDelete = toDelete;
			if (scheduleUpdates[index].isCostBaseline) {
				scheduleUpdates[index].wasCostBaseline = true;
				if (toDelete) {
					for (const update of scheduleUpdates) {
						if (!update?.toDelete && update.hasCostData) {
							update.isBecomingCostBaseline = true;
							break;
						}
					}
				}
			}
		}
		this.scheduleStorage.$modifiedUpdates.next(scheduleUpdates);
		this.dataSource = this.scheduleStorage.$modifiedUpdates.value;
	}

	async editNotesClosed(result: EditUpdateResult) {
		if (result) {
			const originalSchedule: UpdateInterface | undefined = this.scheduleStorage.$allUpdates.value.find(
				(up) => up._id === this.editingUpdateData.element._id
			);

			if (result.hasChanges) {
				const elementChanges =
					this.scheduleStorage.unsavedChanges.get(this.editingUpdateData.element._id) || new Set<string>([]);

				if (originalSchedule) {
					if (result.baseline !== !!originalSchedule.baseline && result._id !== this.$updates.value[0]._id) {
						elementChanges.add('baseline');
					} else {
						elementChanges.delete('baseline');
					}
					if (result.costBaseline !== (originalSchedule._id === this.costBaselineId)) {
						elementChanges.add('costBaseline');
					} else {
						elementChanges.delete('costBaseline');
					}
					if (
						(result.timeAnalysisNotes || '') !== (originalSchedule.timeAnalysisNotes || '') ||
						(result.criticalPathNotes || '') !== (originalSchedule.criticalPathNotes || '')
					) {
						elementChanges.add('notes');
					} else {
						elementChanges.delete('notes');
					}
					this.editingUpdateData.element.newFinish =
						originalSchedule.finishMilestone?.task_code !== result.finishMilestone?.task_code;
					if (originalSchedule.finishMilestone?.task_code !== result.finishMilestone?.task_code) {
						elementChanges.add('finishMilestone');
					} else {
						elementChanges.delete('finishMilestone');
					}
				}
				if (result.cascadeMilestone) {
					const modifiedUpdates = this.dataSource;
					const updateIndex = modifiedUpdates.findIndex((u) => u._id === result._id);
					for (let i = updateIndex + 1; i < modifiedUpdates.length; i++) {
						const updateUnsavedChanges =
							this.scheduleStorage.unsavedChanges.get(modifiedUpdates[i]._id) || new Set<string>([]);
						const updateOgFinMile = modifiedUpdates[i].finishMilestone;
						const updateTasks = await this.scheduleStorage.grabUpdateTable<XerActivity>(modifiedUpdates[i]._id, 'TASK');
						const finMileInUpdate = updateTasks.find((t) => t.task_code === result.finishMilestone.task_code);
						if (
							!finMileInUpdate ||
							updateUnsavedChanges.has('finishMilestone') ||
							updateOgFinMile?.task_code === result.finishMilestone?.task_code
						) {
							break;
						}
						if (updateOgFinMile && updateOgFinMile.task_code !== result.finishMilestone?.task_code) {
							modifiedUpdates[i].finishMilestone = finMileInUpdate;
							updateUnsavedChanges.add('finishMilestone');
							this.scheduleStorage.unsavedChanges.set(modifiedUpdates[i]._id, updateUnsavedChanges);
						}
					}
				}
				if (elementChanges.size) {
					this.scheduleStorage.unsavedChanges.set(this.editingUpdateData.element._id, elementChanges);
				} else {
					this.scheduleStorage.unsavedChanges.delete(this.editingUpdateData.element._id);
				}
				if (result.timeAnalysisNotes != null) {
					this.editingUpdateData.element.timeAnalysisNotes = result.timeAnalysisNotes;
				}
				if (result.criticalPathNotes != null) {
					this.editingUpdateData.element.criticalPathNotes = result.criticalPathNotes;
				}
				this.editingUpdateData.element.finishMilestone = result.finishMilestone as XerActivity;
				this.editingUpdateData.element.toBaseline = elementChanges.has('baseline') && result.baseline;
				this.editingUpdateData.element.unBaseline = elementChanges.has('baseline') && !result.baseline;
				this.editingUpdateData.element.baseline = result.baseline;
				this.editingUpdateData.element.isBecomingCostBaseline =
					result.costBaseline &&
					!this.editingUpdateData.element.wouldBeAutoCostBaseline &&
					this.projectService.$currentProjectData.value.costBaseline !== this.editingUpdateData.element._id;
				this.editingUpdateData.element.wasCostBaseline =
					result.costBaseline === false && this.$projectData.value.costBaseline === this.editingUpdateData.element._id;
				this.scheduleStorage.costBaselineChangesAreRemove = this.editingUpdateData.element.wasCostBaseline;
				this.editingUpdateData.element.isCostBaseline = result.costBaseline;
				this.dataSource[this.editingUpdateData.index] = this.editingUpdateData.element;
				let settingCostBaseline: boolean = false;
				let removedSoftAddCostBl: boolean = false;
				if (result.costBaseline) {
					this.manualCostBaselineId = result._id;
					settingCostBaseline = true;
				} else if (this.editingUpdateData.element.toAdd) {
					removedSoftAddCostBl = true;
				}
				const removedManualCostBaseline: boolean =
					result.costBaseline === false && this.manualCostBaselineId === this.editingUpdateData.element._id;
				this.dataSource.forEach((update: EditedUpdate) => {
					if (update._id !== this.editingUpdateData.element._id) {
						update.wasCostBaseline = removedManualCostBaseline
							? false
							: settingCostBaseline
								? this.projectService.$currentProjectData.value.costBaseline === update._id
								: false;
						update.isCostBaseline = removedSoftAddCostBl
							? this.projectService.$currentProjectData.value.costBaseline === update._id
							: removedManualCostBaseline
								? update.wouldBeAutoCostBaseline
								: false;
						update.isBecomingCostBaseline =
							update.isCostBaseline &&
							this.$projectData.value.costBaseline !== undefined &&
							update.wouldBeAutoCostBaseline &&
							this.projectService.$currentProjectData.value.costBaseline !== update._id;
						const unsavedChangesForUpdate: Set<string> = this.scheduleStorage.unsavedChanges.get(update._id);
						if (unsavedChangesForUpdate !== undefined) {
							if (unsavedChangesForUpdate.has('costBaseline') && !update.isBecomingCostBaseline) {
								unsavedChangesForUpdate.delete('costBaseline');
								if (unsavedChangesForUpdate.size === 0) {
									this.scheduleStorage.unsavedChanges.delete(update._id);
								}
							}
						}
					}
				});
				if (this.showFinishMilestone()) {
					const column = this.columns.find((col) => col.field === 'finishMilestone.task_code');
					if (column) {
						column.selected = true;
					}
					this.updateColumns();
				}
			}
		}
		this.editingUpdateData = null;
	}

	openNotes(element: EditedUpdate, index: number) {
		this.appWindowService.setViewport('editNotesChanges');
		this.appWindowService.$editNotesHeight.next(619);
		this.editingUpdateData = { element, name: index === 0 ? 'Baseline' : 'Update ' + index || '', index };
	}

	openUploadDialog(): void {
		this.navBarStorage.bulkUpdateFormMaxHeight = Math.min(window.innerHeight - 132, 750);
		this.analyticsService.shouldBeSoftAdd.next(true);
		this.analyticsService.addUpdateOpenSchedulesTab.next(true);
	}

	/**
	 * opens report window for selected update
	 * @param element
	 * @param index
	 */
	openReport(element: any, index: number): void {
		//close any open report window
		this.navBarStorage.reportWindowOpen = false;
		//timeout to make sure open report window fully closes before opening another one to make sure the visuals update
		setTimeout(() => {
			this.navBarStorage.reportFormMaxHeight = Math.min(window.innerHeight, 877);
			this.navBarStorage.reportWindowOpen = true;
			this.analyticsService.updateReportOpen.next(true);
			const selectedIndexes = [];
			for (let i = 0; i < this.$updates.value.length; i++) {
				selectedIndexes.push(i);
			}
			this.navBarStorage.selectedUpdateIndexes = selectedIndexes;
			this.navBarStorage.startFromIndex = index;
			setTimeout(() => {
				this.navBarStorage.updateReportFormPrevFloatDivWidth();
				this.appWindowService.restrictMovement('report');
			});
		});
	}

	exportToExcel(grid: GridComponent): void {
		grid.saveAsExcel();
	}

	onExcelExport(e: ExcelExportEvent): void {
		e.preventDefault();
		formatExcelExport(
			e,
			this.$projectData.value.name + '_Schedules_' + this.analyticsService.yyyymmdd() + '.xlsx',
			'',
			'Schedule List Data Export',
			false,
			this.$projectData.value.name + '\n' + cleanDateUTC(new Date(), 'MMMM d, yyyy'),
			'J1'
		);
		console.log(e.workbook);
	}

	/**
	 * adds softAdd/softDelete class to row when needed
	 * @param context
	 */
	public rowCallback = (context: RowClassArgs) => {
		if (context.dataItem.toDelete) {
			return { softDelete: true };
		}
		const rowClasses: Record<string, boolean> = {};
		if (context.dataItem.toAdd) {
			rowClasses.softAdd = true;
		} /*else if (this.expandedDetailKeys.includes(context.dataItem.update._id)) {
			return { expandedDetail: true };
		}*/
		if (context.dataItem.update?.baseline) {
			if (context.index > 0) {
				rowClasses.baseline = true;
			}
		}
		if (context.dataItem.toBaseline) {
			rowClasses.newBaseline = true;
		}
		if (context.dataItem.unBaseline) {
			rowClasses.unbaseline = true;
		}
		if (context.dataItem.newFinish) {
			rowClasses.newFinish = true;
		}
		return rowClasses;
	};

	public expandDetailsBy = (dataItem: UpdateInterface): string => dataItem._id;

	public cellClickHandler(args: CellClickEvent) {
		if (
			['actions', 'fileName', 'report'].includes(args.column.field) ||
			args.rowIndex === 0 ||
			args.dataItem.toAdd ||
			this.currentProjectCompanyPermissions?.license === 'ANALYTICS-BASIC-MPK' ||
			this.currentProjectCompanyPermissions?.license === 'ANALYTICS-BASIC-APK'
		) {
			return;
		}
		this.toggleDetailRow(args.dataItem);
	}

	toggleDetailRow(dataItem): void {
		if (dataItem.toAdd) {
			return;
		}
		if (this.expandedDetailKeys.includes(dataItem._id)) {
			this.expandedDetailKeys = [];
		} else {
			this.expandedDetailKeys = [dataItem._id];
		}
	}

	/**
	 * handles activity click events in the delay grid
	 * @param actv
	 */
	activityClickedDelay(actv: Activity): void {
		this.selectedDelayActv = actv;
	}

	/**
	 * scroll page to component start (using components above critical path comparison bc of the navbar/overview overhang)
	 */
	scroll() {
		const el = document.getElementById(
			this.numUpdates < 2 ? 'schedules-grid' : 'scheduleDelaysComponent'
		) as HTMLElement;
		if (el) {
			el.scrollIntoView();
		}
	}

	/**
	 * handles gantt selection toggle
	 * @param originalSelectionExisted
	 */
	ganttSelection(originalSelectionExisted: boolean) {
		this.clearDelaySelection.next(true);
		if (!originalSelectionExisted) {
			this.scroll();
		}
	}

	showFinishMilestone(): boolean {
		return new Set(this.dataSource.map((update) => update.finishMilestone.task_code)).size > 1;
	}
}

@Component({
	selector: 'dialog-unsaved-schedule',
	templateUrl: 'dialog-unsaved-schedule-changes.html',
})
export class DialogUnsavedScheduleChangesComponent {
	unsavedUpdateIds = new Set<string>([]);
	updateIds: string[] = [];
	public opened: boolean = true;
	constructor(
		public scheduleService: ScheduleStorageService,
		public appWindowService: AppWindowService,
		public analyticsService: AnalyticsDashboardService,
		public cdr: ChangeDetectorRef,
		private router: Router
	) {
		this.unsavedUpdateIds = new Set<string>(this.scheduleService.unsavedChanges.keys());
		this.updateIds = this.scheduleService.$modifiedUpdates.value.map((update) => update._id);
	}
	onNoClick(): boolean {
		this.scheduleService.resetUpdates();
		this.analyticsService.unsavedScheduleChangesOpen.next(false);
		if (this.analyticsService.navOutsideProj) {
			this.analyticsService.navOutsideProj = false;
			if (this.analyticsService.navUrl !== null) {
				this.router.navigate([this.analyticsService.navUrl]);
				setTimeout(() => {
					this.analyticsService.navUrl = null;
				});
			}
		}
		return true;
	}
	onSaveClick(): boolean {
		this.scheduleService.saveAndCalculate();
		this.analyticsService.unsavedScheduleChangesOpen.next(false);
		if (this.analyticsService.navOutsideProj) {
			this.analyticsService.navOutsideProj = false;
			if (this.analyticsService.navUrl !== null) {
				this.router.navigate([this.analyticsService.navUrl]);
				setTimeout(() => {
					this.analyticsService.navUrl = null;
				});
			}
		}
		return true;
	}

	public open(isOpened: boolean): void {
		this.opened = isOpened;
		if (isOpened) {
			this.appWindowService.setViewport('unsavedScheduleChanges');
		}
		this.onNoClick();
	}

	/**
	 * forces window to stay within bounds of viewport
	 * @param window
	 */
	restrictMovement(window: string): void {
		this.appWindowService.restrictMovement(window, this.cdr);
	}
}

const relationPredecessorBindingDate = (relation: ActivityPredecessor): Date => {
	switch (relation.previousRelationType[3]) {
		case 'S': {
			return relation.activity._activity.earlyStart;
		}
		default: {
			return relation.activity._activity.earlyFinish;
		}
	}
};
