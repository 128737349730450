<div class="card" id="schedImpctAnalysisComponent">
	<kendo-gridlayout
		[rows]="['42.4px', 'calc(100% - 42.2px)']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='grid'
		style='page-break-inside: avoid;'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' [class.overviewHeader]="isOverview">
			<kendo-gridlayout
				[rows]="['42.4px']"
				[cols]="['250px', 'calc(100% - 265px)']"
				[gap]="{ rows: 0, cols: 5 }"
				class='full-width'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
					<div class="card-header">
						Schedule Impact Analysis
					</div>
					<tooltip-open type='{{isOverview ? "overview" : "schedule-impact-analysis"}}' [fillColor]="isOverview ? 'overview' : ''"></tooltip-open>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2' class='row-start-aligned-container' style='justify-content: flex-end;'>
				<span style='display: inline-flex; align-items: center;float: right; gap: 10px;' kendoTooltip>
					<button kendoButton themeColor='base' title="Export Report (XLSX)" class='header-btn upload-btn'
									(click)="exportImpactAnalysis.emit()"
									[style.background-color]="isOverview ? '#ffb500' : 'white'"
									[disabled]="scheduleStorage.isLoading || !(projectService.$currentProjectReport | async)?.progressDelayHistorical || (projectService.$currentProjectReport | async)?.updateIds.length <= 1"
									[imageUrl]='"/assets/icons/newIcons/excelExport.svg"'>
					</button>
					@if (isOverview) {
						<button kendoButton themeColor="base" title="Go to source" [imageUrl]="'assets/icons/newIcons/noun-arrow-5544651.svg'" class='header-btn' (click)='projectService.goTo(23)' style="background-color: #ffb500;"></button>
					}
					<button kendoButton themeColor="base" title="Add/Edit Notes" [imageUrl]="'assets/icons/newIcons/noun-notepad-6607257.svg'" class='header-btn overview-notes-icon' (click)='projectService.openNotesWindow({id: 23, name: "Schedule Impact Analysis"})' [style.background-color]="isOverview ? '#ffb500' : 'white'"></button>
				</span>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
		<!--		<kendo-gridlayout-item [row]='2' [col]='1' class='warning-div' style='height: 253px;'>
					<img src='assets/img/schedule-impact-analysis-teaser.png' class='blurred'>
					<span style='text-align: center; z-index: 2; font-size: 24px; font-weight: bold;'>
						Schedule Impact Analysis Coming Soon
					</span>
				</kendo-gridlayout-item>-->
		@if ((projectService.$currentProjectReport | async)?.updateIds.length > 1) {
			<kendo-gridlayout-item [row]='2' [col]='1'>
				<app-schedule-delays
					[projectInfo]='$projectData | async'
					[pageSize]='8'
					[hideSummary]='true'
					[exportEvent]='exportImpactAnalysis'
					(activityClicked)='activityClickedDelay($event)'
					[clearGridSelection]='clearDelaySelection'
					id='scheduleDelaysComponent'
				></app-schedule-delays>
			</kendo-gridlayout-item>
		} @else {
			<kendo-gridlayout-item [row]='2' [col]='1'>
				<div class='warning-div' style='height: 150px;'>
					Schedule Impact Analysis requires at least 1 update to enable
				</div>
			</kendo-gridlayout-item>
		}
	</kendo-gridlayout>
	@if (isOverview && hasNotes) {
		<app-overview-notes [componentId]="23"></app-overview-notes>
	}
</div>

