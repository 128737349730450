<kendo-chart class="heatmap">
  <kendo-chart-tooltip>
    <ng-template kendoChartSeriesTooltipTemplate let-value="value" let-dataItem="dataItem">
      Risks: {{dataItem.value}}
    </ng-template>
  </kendo-chart-tooltip>
  <kendo-chart-plot-area background="url(#svg-gradient)"> </kendo-chart-plot-area>
  <kendo-chart-series>
    <kendo-chart-series-item
      type="scatter"
      [data]="data"
      xField="x"
      yField="y"
			[markers]='{size: markerSize}'
    >
    </kendo-chart-series-item>
  </kendo-chart-series>
  <kendo-chart-value-axis>
    <kendo-chart-value-axis-item pane="pane">
    </kendo-chart-value-axis-item>
  </kendo-chart-value-axis>
  <kendo-chart-panes>
    <kendo-chart-pane name="pane" clip="false">
    </kendo-chart-pane>
  </kendo-chart-panes>
  <kendo-chart-x-axis>
    <kendo-chart-x-axis-item [min]="0" [max]="5" [visible]="true">
    </kendo-chart-x-axis-item>
  </kendo-chart-x-axis>
  <kendo-chart-y-axis>
    <kendo-chart-y-axis-item [min]="0" [max]="5" [visible]="true">
    </kendo-chart-y-axis-item>
  </kendo-chart-y-axis>
</kendo-chart>
<!--<kendo-chart class="heatmap">
  <kendo-chart-tooltip [opacity]="1" background="white">
    <ng-template kendoChartSeriesTooltipTemplate let-value="value" let-dataItem="dataItem">
      Impact: {{dataItem.x}}<br>
      Likelihood: {{dataItem.y}}<br>
      Projects: {{dataItem.tooltip === '' ? 0 : dataItem.tooltip}}
    </ng-template>
  </kendo-chart-tooltip>
  <kendo-chart-plot-area background="url(#svg-gradient)"> </kendo-chart-plot-area>
    <kendo-chart-series>
      <kendo-chart-series-item
        type="heatmap"
        [data]="data"
        xField="x"
        yField="y"
        opacity="0"
        color="black"
        zIndex="3"
      >
      </kendo-chart-series-item>
    </kendo-chart-series>
</kendo-chart>-->
