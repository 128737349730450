export const loadBinary = (url, callback) => {
	const xhr = new XMLHttpRequest();
	xhr.onload = () => {
		//callback(xhr.response, xhr.getResponseHeader('Content-Type'));
	};
	xhr.onerror = () => {
		callback(null);
	};
	xhr.onloadend = () => {
		callback(xhr.response, xhr.getResponseHeader('Content-Type'));
	};
	xhr.open('GET', url);
	xhr.responseType = 'arraybuffer';
	xhr.send();
};
