import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'tooltip-content',
	templateUrl: 'tooltip-content.component.html',
	styleUrls: ['tooltip-content.component.scss'],
})
export class TooltipContentComponent implements OnInit {
	@Output() closeTooltip = new EventEmitter<boolean>();
	@Output() tooltipName = new EventEmitter<string>();
	@Input() tooltipType: string;

	titleText: string = '';
	strongText: string = '';
	moderateText: string = '';
	needsImprovementText: string = '';
	strongRange: string = '';
	moderateRange: string = '';
	needsImprovementRange: string = '';
	subtitle1: string = '';
	subtitle2: string = '';
	subtitle3: string = '';
	ul1 = '';
	ul2 = '';
	disclaimer = '';
	sub0 = '';
	sub01 = '';
	sub02 = '';
	pre11 = '';
	pre12 = '';
	sub11 = '';
	p11 = '';
	sub12 = '';
	p12 = '';
	firstParagraph: string = '';
	firstParagraph2: string = '';
	firstParagraph3: string = '';
	firstParagraph33: string = '';
	firstParagraph4: string = '';
	secondParagraph: string = '';
	isReliabilityScore: boolean = false;
	isMilestones: boolean = false;
	third1: string = '';
	third2: string = '';
	third3: string = '';
	third4: string = '';
	third5: string = '';
	third6: string = '';
	third7: string = '';
	third8: string = '';
	item1: string = '';
	item2: string = '';
	item3: string = '';
	bold1: string = '';
	bold2: string = '';
	bold3: string = '';
	restOf1: string = '';
	restOf2: string = '';
	restOf3: string = '';
	subtitle4: string = '';
	p4: string = '';
	subtitle5: string = '';
	p5: string = '';
	subtitle6: string = '';
	oItem1: string = '';
	oItem2: string = '';
	oItem3: string = '';
	oItem4: string = '';
	pre: string = '';
	highlight: string = '';
	post: string = '';
	notes1: string = '';
	item4: string = '';
	item5: string = '';
	isProblematicActivities: boolean = false;
	needsBR: boolean = false;
	rp: boolean = false;
	rpText: string = '';
	rp1: string = '';
	rp2: string = '';
	rp3: string = '';
	saas1 = '';
	saas2 = '';
	saas3 = '';
	saas4 = '';
	saas5 = '';
	isSaas = false;
	tooltipText: any[] = [
		{
			name: 'project-overview',
			text: 'Project overview shows the latest schedule update that was performed on the project, this also indicates what schedule data is being used to generate the single project dashboard.',
			text2:
				'Additionality, this section shows the current completion date of the project, the contract completion date of the project and any gains / slips, as well as the previous update’s completion date and any gains / slips to the project.',
			text4:
				'You can also use this component to quickly email the Aegis Point of Contact for the project by clicking their name',
			title: 'Project Overview',
			subtitle3: '',
		},
		{
			name: 'project-score',
			text: 'The Project Score metric reflects the overall status, reliability, and health of the Project. Use this score to quickly identify how well your project is performing and how well the project schedules align with project controls best practices.',
			title: 'Project Score',
			strongR: '85% to 100%',
			strongT: 'Strong = ',
			moderateR: '70% to 84.9%',
			moderateT: 'Moderate = ',
			needsImprovementR: '0% to 69.9%',
			needsImprovementT: 'Needs Improvement = ',
			sub1: 'What does this score represent?',
			sub2: 'Ways to improve the score:',
			i1: 'Complete ',
			b1: 'baseline',
			ro1: ' critical and near critical activities that were planned to complete during this update period',
			i2: 'Complete the ',
			b2: 'previous updates',
			ro2: ' critical and near critical activities that were planned to complete during this update period',
			i3: 'Complete activities within the planned duration',
		},
		{
			name: 'risk-score',
			text: 'The Risk Score represents the likelihood of the project completing on-time. This metric considers past performance periods, evaluating how consistently critical and near critical activities are executed as planned. Evaluate the reliability of your project per your latest schedule update by using the thresholds below.',
			title: 'Risk Score',
			strongR: '85% to 100%',
			strongT: 'Strong = ',
			moderateR: '70% to 84.9%',
			moderateT: 'Moderate = ',
			needsImprovementR: '0% to 69.9%',
			needsImprovementT: 'Needs Improvement = ',
			sub1: 'What does this score represent?',
			sub2: 'Ways to improve the score:',
			sub4: 'Current / Previous Critical Path Switch',
			sub5: 'Actual Start / Actual Finish Ratios',
			paragraph4:
				'Critical and near-critical paths to each substantial completion milestone should be reviewed prior to submission. Critical path activities should be communicated to the field team and completed during each update period. In instances where the critical path slips, fragnet activities should be created to account for lost time.',
			paragraph5:
				'Planned progress from previous update should be reviewed to ensure no progress updates are missed. Similarly, the one month lookahead schedule should be reviewed prior to each submission to ensure planned progress is achievable with focus placed on two higher criticality thresholds.',
		},
		{
			name: 'reliability-score',
			text: 'The Reliability Score represents the quality of the most recent update schedule, in essence, is the schedule a reliable tool to manage the project with. A strong reliability score means that all of project controls scheduling guidelines and standards have been satisfied. Lower reliability scores indicate that one or more of Aegis’s scheduling guidelines have not been satisfied.',
			title: 'Reliability Score',
			strongR: '95% to 100%',
			strongT: 'Strong = ',
			moderateR: '80% to 94.9%',
			moderateT: 'Moderate = ',
			needsImprovementR: '0% to 79.9%',
			needsImprovementT: 'Needs Improvement = ',
			sub1: 'What does this score represent?',
			sub4: 'Accuracy of As-Built (Reporting)',
			sub5: 'Quality of Lookahead',
			paragraph4:
				'Ensure activities are progressed accurately within their current update period and fragnets are developed to represent required comeback work or breakout areas. Ensure delay is accounted for in fragnets or breakout work activities.',
			paragraph5:
				'Ensure 8 and 12-week lookahead schedule does not include any summary or long duration activities. Detail activities to ensure ability for schedule to effectively coordinate work in the field between trades and areas.',
			sub6: 'QC Score',
			oi1: 'Clean up FS with lags and ensure no actuals are past the data date',
			oi2: 'Clean up SF relationships and hard constraints',
			oi3: 'Clean up open ends',
			oi4: 'Clean up out of sequence',
		},
		{
			name: 'trending-chart',
			preText:
				'The graph represents the projects completion milestone’s progress over time, the y-axis shows the project gain or slippage in ',
			hl: 'calendar days',
			postText: ', and the x-axis represents the schedule updates.',
			text3:
				'A positive value represents the number of calendar days your Project has gained, whereas a negative value represents the number of calendar days your Project has slipped.',
			text8:
				'The line on the graph is the cumulative performance trend of your project. The cumulative performance value for each update is the difference between the Current Completion date and the Baseline Current Completion date for the selected milestone. The points plotted along the trend line represent the baseline and update schedule files for your project, in chronological order.',
			text88:
				'The grey bars that go along with the trend line represent the Period Performance for the corresponding update periods. Period Performance for each update is the difference between its Current Completion date and the Current Completion date for the previous period.',
			title: 'Trending Chart',
			sub1: 'What does this graph represent?',
		},
		{
			name: 'milestone-trending',
			preText:
				'Similar to the Project Completion Trending graph this represents the selected milestone’s progress over time, the y-axis shows the project gain or slippage in ',
			hl: 'calendar days',
			postText:
				', and the x-axis represents the schedule updates.  Select the milestone in the upper right corner and see a trend for that particular milestone.',
			title: 'Milestones Trending',
			sub1: 'What does this graph represent?',
		},
		{
			name: 'milestones',
			text: 'This table shows the status of all the milestones for the project that were selected to be tracked upon uploading the baseline schedule. Each milestone displays the following information:',
			title: 'Milestones',
			sub1: 'What does this table represent?',
		},
		{
			name: 'activity-completion',
			text: 'This graph shows a detailed summary of your activity completion efficiency during the most recent progress period for your project. Activity completion efficiency is the measure of activities that actually completed as planned over the number of activities planned to be completed.',
			title: 'Activity Completion',
			sub1: 'What does the ‘Last Period’ graph represent?',
			text8:
				'Hover over one of the bar clusters to see the values for each bar, as well as the completion efficiency percentage.',
			text3:
				'Only the activities from the previous update schedule that were planned to be completed before the latest update schedule’s data date are considered in this graph. These activities are broken out into four (4) activity groupings, and are displayed on the graph as gray bars. The colored bars represent the number of those activities that actually completed in the most recent update.',
		},
		{
			name: 'activity-completion-trending',
			title: 'Activity Completion Trending',
			sub2: 'What does the ‘Project Trend’ graph represent?',
			text2:
				'This graph shows the trends of the activity completion efficiency values for each of the four (4) activity groupings across each project update.',
		},
		{
			name: 'critical-path',
			text: 'The Schedule Lookahead represents a snapshot of the longest sequence of activities that are driving the project’s completion date.',
			text3:
				'Hover over the activity bars within the gantt chart to see the planned start and finish dates of the critical activities displayed.',
			title: 'Schedule Lookahead',
			sub1: 'What does this chart represent?',
		},
		{
			name: 'quality-control',
			text: 'The Quality Control Score represents the current schedule update’s Quality Control Score. Scores less than 80% should be reviewed with the project team.',
			strongR: '95% to 100%',
			strongT: 'Strong = ',
			moderateR: '80% to 94.9%',
			moderateT: 'Moderate = ',
			needsImprovementR: '0% to 79.9%',
			needsImprovementT: 'Needs Improvement = ',
			title: 'Quality Control',
		},
		{
			name: 'qc-score-and-trend',
			text: 'The Quality Control Score gauge represents the current schedule updates Quality Control Score. Scores less than 80% should be reviewed with the project team.',
			strongR: '95% to 100%',
			strongT: 'Strong = ',
			moderateR: '80% to 94%',
			moderateT: 'Moderate = ',
			needsImprovementR: '0% to 79%',
			needsImprovementT: 'Needs Improvement = ',
			title: 'Quality Control Score',
			text21:
				'The Quality Control Score trending graph shows improvements and slips in your QC score over the history of the project. The goal is to ensure the QC score is trending upwards.',
		},
		{
			name: 'schedule-analysis',
			text: 'The schedule analysis section is a snapshot of the activities and relationships in the schedule. This is separated into:',
			title: 'Schedule Analysis',
			i1: 'Total number of activities, and the breakdown of activity types',
			i2: 'Total number of relationships in the schedule, and the breakdown of the relationship types. Note, FS (finish-to-start) relationships should make up a majority of the types.',
			i3: 'Total number of out of sequence activities and the ratio compared to the total number of relationships in the schedule',
			i4: 'Relationships to one activity',
		},
		{
			name: 'prob-relationships',
			text: 'Problematic relationships are logic ties in the schedule that are achieved my adjusting the schedule logic ties to achieve a desired date. This is an issue because the schedule is being artificially driven, instead of representing the work that is occuring in the field. This section include:',
			title: 'Problematic Relationships',
			i1: 'Graphical display of the percentage of problematic relationships trending over time.',
			i2: 'Missing Predecessors and Successors - A logic relationship determines the effect of an on-time, delayed, or accelerated activity on subsequent activities. Any missing or incorrect logic relationship is potentially damaging to the entire schedule. Complete network logic between all activities is essential if the schedule is to correctly forecast the start and end dates of activities within the plan.',
			i3: 'Negative Lags imply the atypical calculation of negative time and exact foresight about future events. These should be avoided and replaced with start-to-start / finish-to-finish relationships.',
			i4: 'SS/FF with Problematic represent the passage of time, they are often used to force successor activities to begin on specific dates. A simple example is adding a lag instead of an activity to represent concrete cure time.',
			i5: 'FS with Lags – Simialr to above a finish-to-start relationship with a lag is used to force a successsor activity to a specific date. Problematic lags should be avoided as they can interfere with float calculations in the schedule and can alter the calculation of the critical path.',
		},
		{
			name: 'prob-activities',
			text8:
				'Problematic activities are activities that do not adhere to best scheduling practices. This is represented by:',
			title: 'Potential Schedule Issues',
			text2:
				'High Duration Activities – Aegis Project Controls tracks activities with a duration greater than 20 days.   Long durations should be broken into shorter activities if logical breaks can be identified in the work being performed. Aegis recommends breaking long activities into enough detail that finish-to-start logic relationships can be identified. Additional detail might be necessary if it helps to understand and address risk in the schedule.',
			text21:
				'High Float Activities – Activities with a total float value greater than 100 days are considered to have excessive float and should be reviewed. High total float on an activity or path indicates that schedule logic might be missing or incorrect.',
			text5:
				'Actuals past the DD - Activities past the data date represent future work and should not have actual start or actual finish dates. They also should not have actual duration or work values',
			text6:
				'Hard Constraints - Using hard constraints forces activity dates to a certain point in time, which degrades the validity of the critical path calculations. It also reduces the integrity of schedule dates on activities that logically occur after the hard constraint.',
			text7:
				'Soft Constraints - Restricts an activity from starting or finishing early. These constraints allow delays to exist in the schedule and possibly affect the projects end date.',
			text13:
				'Problematic relationships are logic ties in the schedule that are achieved my adjusting the schedule logic ties to achieve a desired date. This is an issue because the schedule is being artificially driven, instead of representing the work that is occurring in the field. This section includes:',
			text9:
				'Missing Predecessors and Successors - A logic relationship determines the effect of an on-time, delayed, or accelerated activity on subsequent activities. Any missing or incorrect logic relationship is potentially damaging to the entire schedule. Complete network logic between all activities is essential if the schedule is to correctly forecast the start and end dates of activities within the plan.',
			text10:
				'Negative Lags imply the atypical calculation of negative time and exact foresight about future events. These should be avoided and replaced with start-to-start / finish-to-finish relationships.',
			text11:
				'SS/FF with Lags represent the passage of time, they are often used to force successor activities to begin on specific dates. A simple example is adding a lag instead of an activity to represent concrete cure time.',
			text12:
				'FS with Lags – Similar to above a finish-to-start relationship with a lag is used to force a successor activity to a specific date. Problematic lags should be avoided as they can interfere with float calculations in the schedule and can alter the calculation of the critical path.',
		},
		{
			name: 'monte-carlo-performance-factor',
			//eslint-disable-next-line
			text: "The Performance Factor utilizes as-built performance data to determine the likelihood of finishing on or before the schedule's current completion date",
			title: 'Monte Carlo Simulation - Performance Factor',
		},
		{
			name: 'monte-carlo-risk-register',
			//eslint-disable-next-line
			text: "The Risk Register utilizes identified Risks and their Activity Impacts to determine the likelihood of finishing on or before the schedule's current completion date",
			title: 'Monte Carlo Simulation - Risk Register',
		},
		{
			name: 'critical-path-switch',
			title: 'Critical Path Switch',
			text3:
				'The Critical Path Switch component is derived from the Critical Path (CP) and the Secondary (SCP) and Tertiary (TCP) paths.',
			text8:
				'Analyzing the CP and sub-critical paths provides valuable trending and stability information.  It provides the Project management team a tool to evaluate its current and future forecasts.  With each update, the Project will be provided trends which will give the team the ability to understand and prepare for current and potential CP risk factors.',
			needsBR: true,
			strongR: '85% to 100%',
			strongT: 'Low Risk = ',
			moderateR: '50% to 84%',
			moderateT: 'Moderate Risk = ',
			needsImprovementR: '0% to 49%',
			needsImprovementT: 'High Risk = ',
		},
		{
			name: 'critical-path-reliability',
			title: 'Critical Path Reliability',
			preText:
				'Critical Path Reliability assesses the reliability of the critical path by measuring the consistency of the activities on the critical path and logic ties between them. The overall score for Critical Path Reliability is an average of the Activity Consistency ',
			hl: 'and the ',
			postText: 'Logic Consistency.',
			text3:
				'Activity Consistency gauges if critical activities remain critical, with a zero indicating a total path change and 100 indicating an identical path between the schedules, after correcting for completed activities.',
			text8:
				'Logic Consistency measures changes in the driving logic ties along the critical path of both the base schedule and the update. The values of the base logic and update logic are averaged for an overall Logic Consistency score.',
		},
		{
			name: 'risk-score-risk-purchased',
			title: 'Risk Assessment',
			text: 'The Risk Score is an assessment tool that provides additional insight into the health of a project and the level of exposure to potential risks. This tool focuses on factors most likely to impact the overall project duration and evaluates three main components:',
			i1: 'Critical Path Switch',
			i2: 'Critical Path Reliability',
			i3: 'Performance Factor',
			rp: true,
			rpText:
				'Using these components, the Risk Score provides insight into the critical and near critical paths of the project as well as performance of work to assess the likelihood of on-time project completion. Values range from Low Risk to High Risk.',
			rp1: 'Low Risk – 85% to 100%',
			rp2: 'Moderate Risk – 70% to 84.9%',
			rp3: 'High Risk – 0% to 69.9%',
		},
		{
			name: 'completion-ratio',
			title: 'Activity Completion',
			text:
				'Summary of your activity completion efficiency during the most recent progress period for your project. Activity completion efficiency is the measure of activities that actually completed as planned over the number of activities planned to be completed.' +
				' Only the activities from the previous update schedule that were planned to be completed before the latest update schedule’s data date are considered in this value.',
		},
		{
			name: 'risk-register',
			title: 'Risk Register',
			sub0: 'The ',
			sub01: 'Risk Register',
			sub02: ' allows you to log and track potential Risks to the project',
			ul1: 'Risks with an identified Activity Impact that are tagged to at least one activity in the schedule will be available for selection in the Monte Carlo simulation.',
			ul2: 'Column selections, column widths, and logged risks are all saved at the project level, allowing you to provide only the necessary level of detail for each project team’s unique needs.',
			pre11: 'Risk Settings',
			pre12: ' includes the following options:',
			sub11: 'Global Impact',
			sub12: 'Data Fields',
			p11: 'Set a duration variance for all incomplete activities in the latest schedule',
			p12: 'Add, edit, or remove custom field values',
			disclaimer: '*Risk Settings are unique to each project.',
			saas1: 'Pre-Mitigation Risk Impacts',
			saas2:
				"The pre-mitigation risk impacts show the potential effects of each risk on the project schedule if no mitigating actions are taken. These represent the 'raw' or unmitigated risk impacts.",
			saas3: 'Post-Mitigation Risk Impacts',
			saas4:
				'The post-mitigation risk impacts show the potential effects of each risk on the project schedule after planned mitigating actions are implemented. These represent the residual risk impacts after mitigation is applied.',
			saas5:
				'The pre-mitigation impacts are usually higher than the post-mitigation impacts, since the mitigating actions are designed to reduce the risks. Comparing the pre and post-mitigation impacts helps the project team evaluate the effectiveness of planned mitigation strategies.',
		},
		{
			name: 'index-trending',
			title: 'Index Trending',
			pre11: 'Index Trending',
			pre12: ' tracks the following metrics over time:',
			sub11: 'Baseline Execution Index (BEI)',
			sub12: 'Critical Path Length Index (CPLI)',
			p11: 'This metric tracks the number of completed activities to date, against the number of activities which were projected to be completed in the baseline.',
			p12: 'The CPLI indicates the probability of finishing a project on time. It is a function of the total float value and the remaining time left on the project. A CPLI of 1.0 indicates healthy progress and projects an on-time project completion. A CPLI greater than 1.0 indicates that a project is progressing more efficiently than planned, and is likely to reach the final milestone on time or better. A CPLI below 1.0 indicates a project is at risk of not meeting the project deadline.',
		},
		{
			name: 'float-consumption',
			title: 'Float Consumption',
			text: 'Float Consumption tracks the percentage of activities that fall into the following categories of float:',
			i1: 'Negative Float: TF<0',
			i2: 'Critical Path Float: TF=0',
			i3: 'Near Critical Path Float: TF between 1-7',
			i4: 'Month Float: TF between 8-30',
			i5: 'Large Float: TF>30',
		},
	];
	public opened: boolean = true;

	constructor() {}

	ngOnInit(): void {
		const type = this.tooltipType;
		const index = this.tooltipText.findIndex((x) => x.name === type);
		if (this.tooltipText[index].sub1) {
			this.subtitle1 = this.tooltipText[index].sub1;
		}
		if (this.tooltipText[index].ul1) {
			this.ul1 = this.tooltipText[index].ul1;
		}
		if (this.tooltipText[index].ul2) {
			this.ul2 = this.tooltipText[index].ul2;
		}
		if (this.tooltipText[index].text) {
			this.firstParagraph = this.tooltipText[index].text;
		}
		if (this.tooltipText[index].strongR) {
			this.strongRange = this.tooltipText[index].strongR;
		}
		if (this.tooltipText[index].strongT) {
			this.strongText = this.tooltipText[index].strongT;
		}
		if (this.tooltipText[index].moderateR) {
			this.moderateRange = this.tooltipText[index].moderateR;
		}
		if (this.tooltipText[index].moderateT) {
			this.moderateText = this.tooltipText[index].moderateT;
		}
		if (this.tooltipText[index].needsImprovementR) {
			this.needsImprovementRange = this.tooltipText[index].needsImprovementR;
		}
		if (this.tooltipText[index].needsImprovementT) {
			this.needsImprovementText = this.tooltipText[index].needsImprovementT;
		}
		if (this.tooltipText[index].sub2) {
			this.subtitle2 = this.tooltipText[index].sub2;
		}
		if (this.tooltipText[index].sub0) {
			this.sub0 = this.tooltipText[index].sub0;
		}
		if (this.tooltipText[index].sub01) {
			this.sub01 = this.tooltipText[index].sub01;
		}
		if (this.tooltipText[index].sub02) {
			this.sub02 = this.tooltipText[index].sub02;
		}
		if (this.tooltipText[index].sub3) {
			this.subtitle3 = this.tooltipText[index].sub3;
		}
		if (type === 'reliability-score') {
			this.isReliabilityScore = true;
		}
		if (type === 'prob-activities') {
			this.isProblematicActivities = true;
		} else if (type === 'milestones') {
			this.isMilestones = true;
		}
		if (this.tooltipText[index].text2) {
			this.secondParagraph = this.tooltipText[index].text2;
		}
		if (this.tooltipText[index].text3) {
			this.firstParagraph2 = this.tooltipText[index].text3;
		}
		if (this.tooltipText[index].text8) {
			this.firstParagraph3 = this.tooltipText[index].text8;
		}
		if (this.tooltipText[index].text88) {
			this.firstParagraph33 = this.tooltipText[index].text88;
		}
		if (this.tooltipText[index].text13) {
			this.firstParagraph4 = this.tooltipText[index].text13;
		}
		if (this.tooltipText[index].text4) {
			this.third1 = this.tooltipText[index].text4;
		}
		if (this.tooltipText[index].text5) {
			this.third2 = this.tooltipText[index].text5;
		}
		if (this.tooltipText[index].text6) {
			this.third3 = this.tooltipText[index].text6;
		}
		if (this.tooltipText[index].text7) {
			this.third4 = this.tooltipText[index].text7;
		}
		if (this.tooltipText[index].text9) {
			this.third5 = this.tooltipText[index].text9;
		}
		if (this.tooltipText[index].text10) {
			this.third6 = this.tooltipText[index].text10;
		}
		if (this.tooltipText[index].text11) {
			this.third7 = this.tooltipText[index].text11;
		}
		if (this.tooltipText[index].text12) {
			this.third8 = this.tooltipText[index].text12;
		}
		if (this.tooltipText[index].i1) {
			this.item1 = this.tooltipText[index].i1;
		}
		if (this.tooltipText[index].i2) {
			this.item2 = this.tooltipText[index].i2;
		}
		if (this.tooltipText[index].i3) {
			this.item3 = this.tooltipText[index].i3;
		}
		if (this.tooltipText[index].i4) {
			this.item4 = this.tooltipText[index].i4;
		}
		if (this.tooltipText[index].i5) {
			this.item5 = this.tooltipText[index].i5;
		}
		if (this.tooltipText[index].b1) {
			this.bold1 = this.tooltipText[index].b1;
		}
		if (this.tooltipText[index].b2) {
			this.bold2 = this.tooltipText[index].b2;
		}
		if (this.tooltipText[index].b3) {
			this.bold3 = this.tooltipText[index].b3;
		}
		if (this.tooltipText[index].ro1) {
			this.restOf1 = this.tooltipText[index].ro1;
		}
		if (this.tooltipText[index].ro2) {
			this.restOf2 = this.tooltipText[index].ro2;
		}
		if (this.tooltipText[index].ro3) {
			this.restOf3 = this.tooltipText[index].ro3;
		}
		if (this.tooltipText[index].sub4) {
			this.subtitle4 = this.tooltipText[index].sub4;
		}
		if (this.tooltipText[index].sub5) {
			this.subtitle5 = this.tooltipText[index].sub5;
		}
		if (this.tooltipText[index].paragraph4) {
			this.p4 = this.tooltipText[index].paragraph4;
		}
		if (this.tooltipText[index].paragraph5) {
			this.p5 = this.tooltipText[index].paragraph5;
		}
		if (this.tooltipText[index].sub6) {
			this.subtitle6 = this.tooltipText[index].sub6;
		}
		if (this.tooltipText[index].oi1) {
			this.oItem1 = this.tooltipText[index].oi1;
		}
		if (this.tooltipText[index].oi2) {
			this.oItem2 = this.tooltipText[index].oi2;
		}
		if (this.tooltipText[index].oi3) {
			this.oItem3 = this.tooltipText[index].oi3;
		}
		if (this.tooltipText[index].oi4) {
			this.oItem4 = this.tooltipText[index].oi4;
		}
		if (this.tooltipText[index].preText) {
			this.pre = this.tooltipText[index].preText;
		}
		if (this.tooltipText[index].hl) {
			this.highlight = this.tooltipText[index].hl;
		}
		if (this.tooltipText[index].postText) {
			this.post = this.tooltipText[index].postText;
		}
		if (this.tooltipText[index].text21) {
			this.notes1 = this.tooltipText[index].text21;
		}
		this.titleText = this.tooltipText[index].title;
		if (this.tooltipText[index].needsBR) {
			this.needsBR = true;
		}
		if (this.tooltipText[index].rp) {
			this.rp = true;
		}
		if (this.tooltipText[index].rpText) {
			this.rpText = this.tooltipText[index].rpText;
		}
		if (this.tooltipText[index].rp1) {
			this.rp1 = this.tooltipText[index].rp1;
		}
		if (this.tooltipText[index].rp2) {
			this.rp2 = this.tooltipText[index].rp2;
		}
		if (this.tooltipText[index].rp3) {
			this.rp3 = this.tooltipText[index].rp3;
		}
		if (this.tooltipText[index].disclaimer) {
			this.disclaimer = this.tooltipText[index].disclaimer;
		}
		if (this.tooltipText[index].pre11) {
			this.pre11 = this.tooltipText[index].pre11;
		}
		if (this.tooltipText[index].pre12) {
			this.pre12 = this.tooltipText[index].pre12;
		}
		if (this.tooltipText[index].p11) {
			this.p11 = this.tooltipText[index].p11;
		}
		if (this.tooltipText[index].p12) {
			this.p12 = this.tooltipText[index].p12;
		}
		if (this.tooltipText[index].sub11) {
			this.sub11 = this.tooltipText[index].sub11;
		}
		if (this.tooltipText[index].sub12) {
			this.sub12 = this.tooltipText[index].sub12;
		}
		if (this.tooltipText[index].name === 'risk-register') {
			this.saas1 = this.tooltipText[index].saas1;
			this.saas2 = this.tooltipText[index].saas2;
			this.saas3 = this.tooltipText[index].saas3;
			this.saas4 = this.tooltipText[index].saas4;
			this.saas5 = this.tooltipText[index].saas5;
			this.isSaas = true;
		}
		this.tooltipName.emit(this.titleText);
	}
}
