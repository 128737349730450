<div class='playground-container' [style.maxHeight.px]='appWindowService.windowPosition.adminSettings.height - 180'>
	<div class='score-header-row'>
		<div
				 [ngClass]="{
													'blue-score-selected': projectScore >= 85,
													'green-score-selected': projectScore >= 70 && projectScore < 85,
													'red-score-selected': projectScore < 70
													}">
			<div>
				Project
			</div>
			<div>
				{{projectScore | number: '1.0-0'}}%
				<div>
					<div>
						<kendo-icon name='chevron-down' *ngIf='projectReport.projectScore - projectScore >= 1'></kendo-icon>
						<kendo-icon name='chevron-up' *ngIf='projectScore - projectReport.projectScore >= 1'></kendo-icon>
					</div>
					<small *ngIf='(projectScore - projectReport.projectScore | absolute) >= 1'>{{projectScore - projectReport.projectScore | absolute | number: '1.0-0'}}</small>

				</div>

			</div>
		</div>
		<div
			*ngIf='projectData.updateIds.length > 1'
				 [ngClass]="{
													'blue-score-selected': progressScore >= 95,
													'green-score-selected': progressScore >= 80 && progressScore < 95,
													'red-score-selected': progressScore < 80
													}">
			<div>
				Progress
			</div>
			<div>
				{{progressScore | number: '1.0-0'}}%
				<div>
					<div>
						<kendo-icon name='chevron-down' *ngIf='projectReport.progressScore - progressScore >= 1'></kendo-icon>
						<kendo-icon name='chevron-up' *ngIf='progressScore - projectReport.progressScore >= 1'></kendo-icon>
					</div>
					<small *ngIf='(progressScore - projectReport.progressScore | absolute) >= 1'>{{progressScore - projectReport.progressScore | absolute | number: '1.0-0'}}</small>

				</div>

			</div>
		</div>
		<div
				 [ngClass]="{
													'blue-score-selected': qcScore >= 95,
													'green-score-selected': qcScore >= 80 && qcScore < 95,
													'red-score-selected': qcScore < 80
													}">
			<div>
				QC
			</div>
			<div>
				{{qcScore | number: '1.0-0'}}%
				<div>
					<div>
						<kendo-icon name='chevron-down' *ngIf='projectReport.qualityControl.qcScore - qcScore >= 1'></kendo-icon>
						<kendo-icon name='chevron-up' *ngIf='qcScore - projectReport.qualityControl.qcScore >= 1'></kendo-icon>
					</div>
					<small *ngIf='(qcScore - projectReport.qualityControl.qcScore | absolute) >= 1'>{{qcScore - projectReport.qualityControl.qcScore | absolute | number: '1.0-0'}}</small>

				</div>

			</div>
		</div>
		<div
				 [ngClass]="{
													'blue-score-selected': predictabilityScore >= 85,
													'green-score-selected': predictabilityScore >= 70 && predictabilityScore < 85,
													'red-score-selected': predictabilityScore < 70
													}">
			<div>
				Risk
			</div>
			<div>
				{{predictabilityScore | number: '1.0-0'}}%
				<div>
					<div>
						<kendo-icon name='chevron-down' *ngIf='projectReport.riskScore - predictabilityScore >= 1'></kendo-icon>
						<kendo-icon name='chevron-up' *ngIf='predictabilityScore - projectReport.riskScore >= 1'></kendo-icon>
					</div>
					<small *ngIf='(predictabilityScore - projectReport.riskScore | absolute) >= 1'>{{predictabilityScore - projectReport.riskScore | absolute | number: '1.0-0'}}</small>

				</div>

			</div>
		</div>
	</div>
	<br/>
	<div class='playground-table-container'>
		<kendo-grid [data]="filtersByScore" id='scorePlaygroundGrid' [style.maxHeight.px]='appWindowService.windowPosition.adminSettings.height - 296'>
			<kendo-grid-column field="name" title="Type" [width]='200' class='type-col'>
				<ng-template
					kendoGridHeaderTemplate
					let-column
					let-columnIndex="columnIndex"
				>
					<b>
						Type
					</b>
				</ng-template>
			</kendo-grid-column>
			<kendo-grid-column title="Tasks" [width]='75' [class]='"checkCol"'>
				<ng-template
					kendoGridHeaderTemplate
					let-column
					let-columnIndex="columnIndex"
					let-dataItem
				>
					<b>
						Tasks
					</b>
					<input type="checkbox" kendoCheckBox
								 [checked]='columnBoxStates[columnIndex - 1] === 1'
								 [class.greyCheck]='!typeTotalCounts.task'
								 [indeterminate]='columnBoxStates[columnIndex - 1] % 1 > 0'
								 (click)='columnToggle(columnIndex - 1)'
								 class='header-checkbox'
					/>
				</ng-template>
				<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
					<div>
						<input type="checkbox" kendoCheckBox
									 [disabled]='filtersByScore[rowIndex].hiddenOptions?.includes("task")'
									 (change)='updateSelections()'
									 [class.greyCheck]='!dataItem.typeCounts["task"]'
									 [(ngModel)]='dataItem.selectedOptions[0]'/>
					</div>

				</ng-template>
			</kendo-grid-column>
			<kendo-grid-column title="Milestones" [width]='95' [class]='"checkCol"'>
				<ng-template
					kendoGridHeaderTemplate
					let-column
					let-columnIndex="columnIndex"
				>
					<b>
						Milestone
					</b>
					<input type="checkbox" kendoCheckBox
								 [checked]='columnBoxStates[columnIndex - 1] === 1'
								 [class.greyCheck]='!typeTotalCounts.milestone'
								 [indeterminate]='columnBoxStates[columnIndex - 1] % 1 > 0'
								 (click)='columnToggle(columnIndex - 1)'
								 class='header-checkbox'
					/>
				</ng-template>
				<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
					<div>
						<input type="checkbox" kendoCheckBox
									 [disabled]='filtersByScore[rowIndex].hiddenOptions?.includes("milestone")'
									 (change)='updateSelections()'
									 [class.greyCheck]='!dataItem.typeCounts["milestone"]'
									 [(ngModel)]='dataItem.selectedOptions[1]'/>
					</div>

				</ng-template>
			</kendo-grid-column>
			<kendo-grid-column title="LOE's" [width]='70' [class]='"checkCol"'>
				<ng-template
					kendoGridHeaderTemplate
					let-column
					let-columnIndex="columnIndex"
				>
					<b>
						LOE's
					</b>
					<div>
						<input type="checkbox" kendoCheckBox
									 [checked]='columnBoxStates[columnIndex - 1] === 1'
									 [class.greyCheck]='!typeTotalCounts.loe'
									 [indeterminate]='columnBoxStates[columnIndex - 1] % 1 > 0'
									 (click)='columnToggle(columnIndex - 1)'
									 class='header-checkbox'
						/>
					</div>

				</ng-template>
				<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
					<div>
						<input type="checkbox" kendoCheckBox
									 [class.greyCheck]='!dataItem.typeCounts["loe"]'
									 (change)='updateSelections()'
									 [(ngModel)]='dataItem.selectedOptions[2]'/>
					</div>
				</ng-template>
			</kendo-grid-column>
			<kendo-grid-column title="Finish Milestone" [width]='130' [class]='"checkCol"'>
				<ng-template
					kendoGridHeaderTemplate
					let-column
					let-columnIndex="columnIndex"
				>
					<b>
						Finish Milestone
					</b>
					<input type="checkbox" kendoCheckBox
								 [checked]='columnBoxStates[columnIndex - 1] === 1'
								 [class.greyCheck]='!typeTotalCounts.finishMilestone'
								 [indeterminate]='columnBoxStates[columnIndex - 1] % 1 > 0'
								 (click)='columnToggle(columnIndex - 1)'
								 class='header-checkbox'
					/>
				</ng-template>
				<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
					<div>
						<input type="checkbox" kendoCheckBox
									 [class.greyCheck]='!dataItem.typeCounts["finishMilestone"]'
									 (change)='updateSelections()'
									 [(ngModel)]='dataItem.selectedOptions[3]'/>
					</div>

				</ng-template>
			</kendo-grid-column>
			<kendo-grid-column field="liveCount" title="Count" class='text-center'>
				<ng-template
					kendoGridHeaderTemplate
					let-column
					let-columnIndex="columnIndex"
				>
					<b>
						Count
					</b>
				</ng-template>
			</kendo-grid-column>
		</kendo-grid>
			<div style='display: flex; justify-content: center; font-style: italic; padding-top: 5px;'>
				<div style='margin-right: 30px'>
					Present in latest schedule <input type="checkbox" kendoCheckBox
																						[(ngModel)]='trueVal'
																						(change)='keepTrue("presentCheck")'
																						id='presentCheck'
																						style='margin-top: -2px;'
				/>
				</div>

				<div>
					Not Applicable
					<input type="checkbox" kendoCheckBox
								 [class.greyCheck]='true'
								 [(ngModel)]='trueVal'
								 (change)='keepTrue("naCheck")'
								 id='naCheck'
								 style='margin-top: -2px;'
					/>
				</div>

			</div>
	</div>
</div>
