import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DCMAMetric } from '../../../../../models/ProjectReport/DCMA';

@Component({
	selector: 'app-dcma-rating',
	templateUrl: './dcma-rating.component.html',
	styleUrls: ['./dcma-rating.component.scss'],
})
export class DcmaRatingComponent implements OnInit {
	@Input() rating: DCMAMetric;
	@Input() isSelected: boolean = false;
	@Input() tasks: Array<any> = [];
	@Output() selectTest = new EventEmitter<string>();
	elementType: string;

	icons: any = {};

	constructor() {}

	ngOnInit(): void {
		const poolKey = this.rating.denominatorKey?.toString()?.toLowerCase();
		if (poolKey?.includes('relation')) {
			this.elementType = 'relation';
		} else if (poolKey?.includes('task')) {
			this.elementType = 'task';
		}
	}
}
