<div id="componentNotesContainer">
	<ng-template #textAreaTemplate>
		<div [style.padding-bottom]="insideWindow ? '10px' : '0'">
			<kendo-label [for]="notesTextArea" [text]="(notes?.length === 1 ? 'Baseline' :'Update ' + (notes?.length - 1)) + ' Notes'"></kendo-label>
			<kendo-textarea
				#notesTextArea
				maxlength='3000'
				[(ngModel)]='currentUpdateNotes'
				(afterValueChangedStr)="checkForChanges()"
				resizable="none"
				style='height: 124px;'
				[readonly]='!insideWindow'
				[class.readonly-textbox]='!insideWindow'
			>
			</kendo-textarea>
			@if (insideWindow) {
				<div style="margin-top: 10px; display: flex; justify-content: space-between; position: relative;">
					@if (notes?.length && notes[notes?.length - 1]?.lastEditTimestamp !== null && notes[notes?.length]?.lastEditUser !== '') {
						<span style="margin-top: -10px; font-size: 12px; color: #8e8e8e;">
							Last Updated: {{notes[notes?.length - 1]?.lastEditTimestamp | date: 'MMM d, yyyy'}} by {{notes[notes?.length - 1]?.lastEditUser}}
						</span>
					}
					<div style="font-size: 12px; color: #8e8e8e; position: absolute; left: 0; bottom: 0;">
						*Latest schedule's notes will appear on the Overview Tab export.
					</div>
					<span></span>
					<button kendoButton themeColor='success' (click)='saveNoteChanges()' [disabled]='!projectService.componentNoteChanges'>Save</button>
				</div>
			}
		</div>
	</ng-template>
	@if (insideWindow) {
		<ng-container *ngTemplateOutlet="textAreaTemplate"></ng-container>
		@if (projectService?.$currentProjectData.value?.updateIds?.length > 1 && gridData?.length > 0) {
			<kendo-expansionpanel
				subtitle="All Updates Notes"
				style="border-radius: 4px;"
				(action)="onAction($event)"
			>
				<ng-template #template let-anchor>
					<span>{{ anchor.nativeElement.lastUpdated }}</span>
				</ng-template>
				<div
					kendoTooltip
					showOn="none"
					[tooltipTemplate]="template"
					filter=".k-grid td .notes-force"
					(mouseover)="showTooltip($event)"
				>
					<kendo-grid
						[data]="gridView"
						[kendoGridBinding]="gridData"
						[selectable]='false'
						[size]='"small"'
						[sortable]='false'
						[navigable]="false"
						[loading]="loading"
						id="notesComponentGrid"
					>
						<kendo-grid-column field="updateId" title="Schedule" [width]="90" [class]="'center-for-real'"></kendo-grid-column>
						<!--		<kendo-grid-column field="lastEditTimestamp" title="Last Updated" format="MMM dd, yyyy" [class]="'center-for-real'"></kendo-grid-column>-->
						<!--		<kendo-grid-column field="lastEditUser" title="Updated By" [class]="'center-for-real'"></kendo-grid-column>-->
						<kendo-grid-column field="note" title="Notes" [class]="'notes-force'">
							<ng-template let-dataItem>
								<div [title]="'Last Updated ' + dataItem?.lastEditTimestamp + ' by ' + dataItem?.lastEditUser">
									{{dataItem}}
								</div>
							</ng-template>
						</kendo-grid-column>
					</kendo-grid>
				</div>
			</kendo-expansionpanel>
		}
	} @else {
		<kendo-expansionpanel
			subtitle="Notes"
			[disabled]="currentUpdateNotes === ''"
			style="border-radius: 4px;"
		>
			<ng-container *ngTemplateOutlet="textAreaTemplate"></ng-container>
		</kendo-expansionpanel>
	}
</div>
