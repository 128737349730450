import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProjectDashboardService } from '../../../services/project/project.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { RestService } from '../../../services/common/rest.service';

@Component({
	selector: 'app-upload-picture',
	templateUrl: './upload-picture.component.html',
	styleUrls: ['./upload-picture.component.scss'],
})
export class UploadPictureComponent implements OnInit {
	readyToShow: boolean = false;
	b64image: string = '';
	b64cropped: string = '';
	@Input() isSaasRiskCompanyImg = false;
	@Input() companyId;
	@Output() imageReadyToShow = new EventEmitter<boolean>();
	@Output() closeDialog = new EventEmitter<boolean>();
	@Output() updatePortfolioCompanyImg = new EventEmitter<string>();

	constructor(
		public projectService: ProjectDashboardService,
		private rest: RestService
	) {}

	ngOnInit(): void {}

	checkUpload($event: Event) {
		const t = $event.target as HTMLInputElement;
		if (t.files[0].size > 2097152) {
			alert('Please upload another image. Max file size is 2MB.');
			t.value = '';
		} else {
			const f: FileReader = new FileReader();
			f.onloadend = () => {
				const b = f.result;
				if (typeof b === 'string') {
					this.b64image = b;
					this.b64cropped = b;
					this.readyToShow = true;
					this.imageReadyToShow.emit(true);
				} else {
					alert('An error occurred with uploading your image. Please try again.');
					t.value = '';
				}
			};
			f.readAsDataURL(t.files[0]);
		}
	}

	imageCropped($event: ImageCroppedEvent) {
		this.b64cropped = $event.base64 || '';
	}

	saveAndUpload() {
		const url = this.isSaasRiskCompanyImg
			? 'company/' + this.companyId + '/uploadNewCompanyImage'
			: 'project/' + this.projectService.$currentProjectPageId.value + '/uploadNewProjectImage';
		this.rest
			.post(url, {
				imageBase64: this.b64cropped,
			})
			.subscribe(
				(res) => {
					if (this.isSaasRiskCompanyImg) {
						this.updatePortfolioCompanyImg.emit(res.logo);
					} else {
						this.projectService.$currentProjectData.next(res);
					}
					this.closeDialog.emit(true);
				},
				(err) => {
					console.log(err);
				}
			);
	}
}
