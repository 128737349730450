<kendo-gridlayout
	[rows]="['200px']"
	[cols]="['262.6px', 'calc(((100% - 262.6px) / 2) - 5px)', 'calc(((100% - 262.6px) / 2) - 5px)']"
	[gap]="{ rows: 0, cols: 5 }"
	class='grid'
	[style.padding-bottom]='isFromProjectPage ? "5px" : "0"'
>
	<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-container'>
		<kendo-gridlayout
			[rows]="['42.4px', '157.6px']"
			[cols]="['100%']"
			[gap]="{ rows: 0, cols: 0 }"
			class='grid'
		>
			<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container risk-banner shadow'>
				<div class="card-header">
					Risk Summary
				</div>
				<global-tooltip-open type="saas-risk-summary"></global-tooltip-open>
			</kendo-gridlayout-item>
			<kendo-gridlayout-item [row]='2' [col]='1'>
				<kendo-gridlayout
					[rows]="['157.6px']"
					[cols]="['157.6px', '100px']"
					[gap]="{ rows: 0, cols: 5 }"
					class='grid'
				>
					<kendo-gridlayout-item [row]='1' [col]='1' class='card-content' style='border: none;'>
						<div class="image-container" style='width: 100%;' (mouseover)="mouseoverImage()" (mouseout)="mouseoutImage()">
							<img *ngIf='companyImg' [src]="companyImg" style="width: 157.6px; height: 157.6px; border-radius: 4px;">
							<kendo-button
								title="Edit Company Image"
								[ngClass]='{"edit-image-icon": companyImg, "edit-image-icon-unset": !companyImg}'
								[class.opacity0]='companyImg && !isHoveringOverImage'
							>
								<kendo-svg-icon [icon]="icons.imageAdd" [ngClass]='{"icon-set": companyImg, "icon-unset": !companyImg}'></kendo-svg-icon>
							</kendo-button>
							<div style='width: 100%; height: 100%; position: absolute; top: 0;' (click)="openImageUpload()"></div>
						</div>
					</kendo-gridlayout-item>
					<kendo-gridlayout-item [row]='1' [col]='2'>
						<kendo-gridlayout
							[rows]="['calc(50% - 2.5px)', 'calc(50% - 2.5px)']"
							[cols]="['100%']"
							[gap]="{ rows: 5, cols: 0 }"
							class='grid'
						>
							<kendo-gridlayout-item [row]='1' [col]='1' class='card-content'>
								<div class="centerBoth" style="color: #df5353e6; margin-top: -10px;">{{ ($avgPreMitigationScore | async) || '-' }}</div>
								<div class="filterBoxLabel" style='margin-top: -10px;'>Pre</div>
							</kendo-gridlayout-item>
							<kendo-gridlayout-item [row]='2' [col]='1' class='card-content' style='border-radius: 4px;'>
								<div class="centerBoth" style="color: #4fc931e6; margin-top: -10px;">{{ ($avgPostMitigationScore | async) || '-' }}</div>
								<div class="filterBoxLabel" style='margin-top: -10px;'>Post</div>
							</kendo-gridlayout-item>
						</kendo-gridlayout>
					</kendo-gridlayout-item>
					<!--<kendo-gridlayout-item [row]='1' [col]='3' class='card-content'>
						<app-risk-summary-trend
							[categories]="riskSummaryTrendCategories"
							[data]="riskSummaryTrendData"
							[chartType]="'line'"
							[isFromPortfolio]='true'
							class='score-trend-chart'
						></app-risk-summary-trend>
					</kendo-gridlayout-item>-->
				</kendo-gridlayout>
			</kendo-gridlayout-item>
		</kendo-gridlayout>
	</kendo-gridlayout-item>
	<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-container'>
		<kendo-gridlayout
			[rows]="['42.4px', '157.6px']"
			[cols]="['100%']"
			[gap]="{ rows: 0, cols: 0 }"
			class='grid'
		>
			<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container risk-banner shadow'>
				<div class="card-header">
					Categories Of Risk
				</div>
				<global-tooltip-open type="categories-of-risk"></global-tooltip-open>
			</kendo-gridlayout-item>
			<kendo-gridlayout-item [row]='2' [col]='1'>
				<kendo-gridlayout
					[rows]="['157.6px']"
					[cols]="allRisks.value.length > 0 ? ['calc((800% / 12) - 2.5px)', 'calc((400% / 12) - 2.5px)'] : ['100%']"
					[gap]="{ rows: 0, cols: 5 }"
					class='grid'
				>
					<kendo-gridlayout-item
						[row]='1'
						[col]='1'
						class='card-content'
						*ngIf="allRisks.value.length > 0"
					>
						<div style='height: 100%'>
							<span class="chart-title">Risk by Category</span>
							<app-risk-by-category
								[chartType]="'bar'"
								[title]="'Risk by Category'"
								[$risks]="allRisks"
								[groupBy]="'category'"
							></app-risk-by-category>
							<span class="chart-title">Risk by Owner</span>
							<app-risk-by-category
								[chartType]="'bar'"
								[title]="'Risk by Owner'"
								[$risks]="allRisks"
								[groupBy]="'riskOwner.name'"
							></app-risk-by-category>
						</div>
					</kendo-gridlayout-item>
					<kendo-gridlayout-item
						[row]='1'
						[col]='2'
						class='card-content'
						style='position: relative;'
						*ngIf="allRisks.value.length > 0"
					>
						<div>
							<span class="chart-title donut-title">Risk by Status</span>
							<app-risk-by-category
								[chartType]="'donut'"
								[$risks]="allRisks"
								[groupBy]="'mitStatus'"
							></app-risk-by-category>
						</div>
					</kendo-gridlayout-item>
					<kendo-gridlayout-item
						[row]='1'
						[col]='1'
						class='card-content'
						*ngIf="allRisks.value.length <= 0"
					>
						<div class="no-data-chart-warning">
							None of the selected projects have risks in their risk registers. Add a risk to any risk register to view metrics.
						</div>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			</kendo-gridlayout-item>
		</kendo-gridlayout>
	</kendo-gridlayout-item>
	<kendo-gridlayout-item [row]='1' [col]='3' class='gridlayout-container'>
		<kendo-gridlayout
			[rows]="['42.4px', '157.6px']"
			[cols]="['100%']"
			[gap]="{ rows: 0, cols: 0 }"
			class='grid'
		>
			<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container risk-banner shadow'>
				<div class="card-header">
					Heat Map
				</div>
				<global-tooltip-open type="heat-map"></global-tooltip-open>
			</kendo-gridlayout-item>
			<kendo-gridlayout-item [row]='2' [col]='1'>
				<kendo-gridlayout
					[rows]="['157.6px']"
					[cols]="allRisks.value.length > 0 ? ['calc((400% / 12) - 2.5px)', 'calc((800% / 12) - 2.5px)'] : ['100%']"
					[gap]="{ rows: 0, cols: 5 }"
					class='grid'
				>
					<kendo-gridlayout-item
						[row]='1'
						[col]='1'
						class='card-content'
						*ngIf="allRisks.value.length > 0"
						[ngClass]="{
							'green-card-bg': ($avgRiskAssessmentScore | async) < 30,
							'orange-card-bg': ($avgRiskAssessmentScore | async) >= 30 && ($avgRiskAssessmentScore | async) < 60,
							'red-card-bg': ($avgRiskAssessmentScore | async) >= 60
						}"
					>
						<div class="centerBoth" style="color: white">{{ ($avgRiskAssessmentScore | async) + '%' || '-' }}</div>
						<div class="filterBoxLabel" style='color: white'>Risk Assessment</div>
					</kendo-gridlayout-item>
					<kendo-gridlayout-item
						[row]='1'
						[col]='2'
						class='card-content'
						*ngIf="allRisks.value.length > 0"
					>
						<div style='height: 100%;'>
							<span class="chart-title">Likelihood vs. Impact</span>
							<app-likelihood-heatmap
								[$risks]="allRisks"
							></app-likelihood-heatmap>
						</div>
					</kendo-gridlayout-item>
					<kendo-gridlayout-item
						[row]='1'
						[col]='1'
						class='card-content'
						*ngIf="allRisks.value.length <= 0"
					>
						<div class="no-data-chart-warning">
							None of the selected projects have risks in their risk registers. Add a risk to any risk register to view metrics.
						</div>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			</kendo-gridlayout-item>
		</kendo-gridlayout>
	</kendo-gridlayout-item>
</kendo-gridlayout>
<kendo-window
	*ngIf="imageUploadDialogOpen"
	[minWidth]="376"
	[width]="imageReadyToShow ? 686 : 376"
	(close)='closeDialog()'
	[resizable]='false'
	class='project-image-upload-dialog'
>
	<kendo-window-titlebar>
		<div class="k-window-title">Upload Your Image</div>
		<button kendoWindowMinimizeAction></button>
		<button kendoWindowMaximizeAction></button>
		<button kendoWindowRestoreAction></button>
		<button kendoWindowCloseAction></button>
	</kendo-window-titlebar>
	<app-upload-picture
		[isSaasRiskCompanyImg]='true'
		[companyId]='companyId'
		(imageReadyToShow)='imageReadyToShowUpdated($event)'
		(closeDialog)='closeDialog()'
		(updatePortfolioCompanyImg)='updateCompanyImg($event)'
	></app-upload-picture>
</kendo-window>
