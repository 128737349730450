import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { KendoColumn } from '../../../../models/ChartSettings';
import { takeUntil } from 'rxjs/operators';
import { ProjectDashboardService } from '../../../../services/project/project.service';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { Subject } from 'rxjs';
import { SlimmedTaskCommon, UpdateInterface } from '../../../../models/Update/Task';
import { addDays } from 'date-fns';
import { GridDataResult, SelectionEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query/dist/npm/sort-descriptor';
import { groupBy, GroupResult, orderBy } from '@progress/kendo-data-query';
import { Xer, XerActivity, XerProject } from '@rhinoworks/xer-parse';
import { update } from 'lodash';
import { datePipe } from '../../../../util/pipes/date.pipe';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { caretAltDownIcon } from '@progress/kendo-svg-icons';
import { RestService } from '../../../../services/common/rest.service';
import { hasObjChanged } from '../../../../util/projects';

export const allColumns = require('../../overview/milestones/milestones-columns.json') as {
	columns: KendoColumn[];
};

@Component({
	selector: 'app-milestones-tab',
	templateUrl: './milestones-tab.component.html',
	styleUrls: ['./milestones-tab.component.scss'],
})
export class MilestonesTabComponent implements OnInit {
	@Output() canSave: EventEmitter<boolean> = new EventEmitter<boolean>(false);
	public virtual: any = {
		itemHeight: 28,
	};
	addMilestoneFocused: boolean = false;
	finishMilestonesOptions:
		| GroupResult[]
		| {
				text: string;
				value:
					| XerActivity
					| SlimmedTaskCommon
					| { task_name: string; task_code: string; deleted: boolean; proj_id: number };
				earlyFinish: Date;
				category: string;
		  }[];
	allFinishMilestones:
		| GroupResult[]
		| {
				text: string;
				value:
					| XerActivity
					| SlimmedTaskCommon
					| { task_name: string; task_code: string; deleted: boolean; proj_id: number };
				earlyFinish: Date;
				category: string;
		  }[];
	additionalMilestones:
		| GroupResult[]
		| {
				text: string;
				value:
					| XerActivity
					| SlimmedTaskCommon
					| { task_name: string; task_code: string; deleted: boolean; proj_id: number };
				earlyFinish: Date;
				category: string;
		  }[];
	existingMilestones:
		| GroupResult[]
		| {
				text: string;
				value:
					| XerActivity
					| SlimmedTaskCommon
					| { task_name: string; task_code: string; deleted: boolean; proj_id: number };
				earlyFinish: Date;
				category: string;
		  }[];
	@ViewChild('additionalMilestonesMultiselect') additionalMilestonesMultiselect: MultiSelectComponent;
	public icons = {
		caretDown: caretAltDownIcon,
	};
	currentFilter: string = '';
	proj_id: number = undefined;

	constructor(
		public projectService: ProjectDashboardService,
		public scheduleService: ScheduleStorageService,
		private restService: RestService
	) {
		//key listener to stop backspace from deleting additional milestones
		window.addEventListener(
			'keydown',
			(e) => {
				if (e.key === 'Backspace' && this.addMilestoneFocused && this.currentFilter === '') {
					e.stopImmediatePropagation();
					e.preventDefault();
				}
			},
			true
		);
	}

	ngOnInit() {
		this.scheduleService.$allUpdates.subscribe((updates) => {
			if (updates?.length > 0) {
				this.updateGrid();
			}
		});
	}

	async updateGrid(): Promise<void> {
		const updates: UpdateInterface[] = this.scheduleService.$allUpdates.value;
		const tasks = await this.scheduleService.grabUpdateTable<XerActivity>(updates[updates.length - 1]._id, 'TASK');
		const projects = await this.scheduleService.grabUpdateTable<XerProject>(updates[updates.length - 1]._id, 'PROJECT');
		const finishMilestones = new Set<{
			text: string;
			value:
				| XerActivity
				| SlimmedTaskCommon
				| { task_name: string; task_code: string; deleted: boolean; proj_id: number };
			earlyFinish: Date;
			category: string;
		}>(
			tasks
				.filter(
					(task) =>
						task.task_type === 'TT_FinMile' ||
						(task.task_type !== 'TT_Mile' &&
							!task.task_name.toLowerCase().includes('igmp') &&
							!task.task_name.toLowerCase().includes('fgmp'))
				)
				.map((task) => ({
					text: `${task?.task_code} - ${task?.task_name} - ${datePipe(task?.early_end_date, 'MMM d, y')}`,
					value: task,
					earlyFinish: task.early_end_date,
					category: task.task_type === 'TT_FinMile' ? 'Finish Milestone' : 'All Activities',
				}))
		);
		let preSelectedMilestones = [];
		updates[updates?.length - 1].additionalTrackingMilestones.forEach((trackedMilestone) => {
			const matchingMilestone = Array.from(finishMilestones).find(
				(m) => m.value?.task_code === trackedMilestone?.task_code
			);
			const proj_id: number =
				projects?.length === 1
					? projects?.[0]?.proj_id
					: updates[updates?.length - 1]?.finishMilestone
						? updates[updates?.length - 1]?.finishMilestone?.proj_id
						: undefined;
			this.proj_id = proj_id;
			if (matchingMilestone === undefined) {
				const currentUpdateMilestone = {
					text: `${trackedMilestone?.task_code} - ${trackedMilestone?.task_name} - DELETED`,
					value: {
						task_code: trackedMilestone?.task_code,
						task_name: trackedMilestone?.task_name,
						deleted: true,
						proj_id,
					},
					earlyFinish: null,
					category: 'DELETED',
				};
				finishMilestones.add(currentUpdateMilestone);
				preSelectedMilestones.push(currentUpdateMilestone);
			} else {
				matchingMilestone.value.proj_id = proj_id;
				preSelectedMilestones.push(matchingMilestone);
			}
		});
		this.allFinishMilestones = groupBy(
			orderBy(Array.from(finishMilestones), [
				{ field: 'category', dir: 'desc' },
				{ field: 'earlyFinish', dir: 'asc' },
			]),
			[{ field: 'category' }]
		);
		preSelectedMilestones = preSelectedMilestones.sort((a, b) => (a.text < b.text ? -1 : b.text < a.text ? 1 : 0));
		this.additionalMilestones = preSelectedMilestones;
		this.existingMilestones = preSelectedMilestones;
		this.handleFilter(this.currentFilter);
	}

	additionalMilestonesValueChanged(newVals): void {
		newVals.forEach((val) => {
			val.value.proj_id = this.proj_id;
		});
		this.additionalMilestones = newVals.sort((a, b) => (a.text < b.text ? -1 : b.text < a.text ? 1 : 0));
		this.canSave.emit(hasObjChanged(this.additionalMilestones, this.existingMilestones));
		this.scheduleService.additionalMilestones = this.additionalMilestones;
	}

	public tagMapper(tags: any[]): any[] {
		return tags.length < 2 ? tags : [tags];
	}

	addMileFocus(e, focused: boolean): void {
		this.addMilestoneFocused = focused;
	}

	handleFilter(value): void {
		this.currentFilter = value.toLowerCase();
		const newData = [];
		this.allFinishMilestones.forEach((group) => {
			const groupMatchingItems = group.items.filter(
				(item) => item.text.toLowerCase().indexOf(this.currentFilter.toLocaleString()) !== -1
			);
			newData.push(...groupMatchingItems);
		});
		this.finishMilestonesOptions = groupBy(
			orderBy(Array.from(newData), [
				{ field: 'category', dir: 'desc' },
				{ field: 'earlyFinish', dir: 'asc' },
			]),
			[{ field: 'category' }]
		);
	}

	toggleMultiselect(): void {
		this.additionalMilestonesMultiselect.toggle(!this.additionalMilestonesMultiselect.isOpen);
	}
}
