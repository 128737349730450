<div id="login" class="inner-scroll">
  <div id="login-form-wrapper" [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }">
    <div id="login-form">

      <div class="title" style="text-align: center">
        <img src='../../../assets/images/logos/AEGIS-ANALYTICS-COLOR.png' alt='Aegis Analytics' style='object-fit: contain; width: 100%'/>
      </div>

      <form name="loginForm" [formGroup]="loginForm" novalidate *ngIf="!oauthLogin">
        <ion-card *ngIf="!authService.timedout">
          <ion-card-header>
            <ion-card-title><h4 style='color: white;'>Authenticating...</h4></ion-card-title>
          </ion-card-header>
          <ion-card-content>

            <ion-progress-bar type="indeterminate"></ion-progress-bar>

           We're checking your credentials... this should only take a moment...
          </ion-card-content>
        </ion-card>
        <ion-card *ngIf="authService.timedout">
          <ion-card-header>
            <ion-card-title><h4 style='color: white;'>Something went wrong.</h4></ion-card-title>
          </ion-card-header>
          <ion-card-content>
            Please try again later. <br>

						<a (click)='showLogs = true' href='javascript:///'>Troubleshooting Logs</a>

						<div *ngIf='showLogs'>
							<ul>
								<li *ngFor='let log of authService.errorcatcher'>{{log.line}}: {{log.message}}</li>
							</ul>
						</div>
            <br/>

            <ion-button routerLink="/auth/login">Back to Login</ion-button>
          </ion-card-content>
        </ion-card>
  </form>
    </div>
  </div>
</div>
