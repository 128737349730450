<div class='card' [style]='isFocus ? "" : "break-inside: avoid;"' id="indexTrendingComponent">
	<kendo-gridlayout
		[rows]="[isFocus ? '32px' : '42.4px', '303px']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' [class.overviewHeader]="isOverview">
			<kendo-gridlayout
				[rows]="[isFocus ? '32px' : '42.4px']"
				[cols]="['300px', 'calc(100% - 315px)']"
				[gap]="{ rows: 0, cols: 5 }"
				class='full-width'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
					<div class="card-header" [class.focusTabFontSizing]="isFocus">
						Index Trending
					</div>
					<tooltip-open type='{{isOverview ? "overview" : "index-trending"}}' [fillColor]="isOverview ? 'overview' : ''"></tooltip-open>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2' class='toolbar-item-container' style="margin: auto; height: min-content; justify-content: end">
					<span style='display: inline-flex; align-items: center;float: right; gap: 10px;' kendoTooltip>
						@if (isOverview) {
							<button kendoButton themeColor="base" title="Go to source" [imageUrl]="'assets/icons/newIcons/noun-arrow-5544651.svg'" class='header-btn' (click)='projectService.goTo(20)' style="background-color: #ffb500;"></button>
						}
						@if (!isFocus) {
							<button kendoButton themeColor="base" title="Add/Edit Notes" [imageUrl]="'assets/icons/newIcons/noun-notepad-6607257.svg'" class='header-btn overview-notes-icon' (click)='projectService.openNotesWindow({id: 20, name: "Index Trending"})' [style.background-color]="isOverview ? '#ffb500' : 'white'"></button>
						}
					</span>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1'>
			<div style='height: 300px; margin-bottom: 3px'>
				<app-chart
					[categories]='categories'
					[seriesData]='seriesData'
					[legendVisible]='true'
					[valueAxis]='valueAxisItemSettings'
				></app-chart>
			</div>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
	@if (isOverview && hasNotes) {
		<app-overview-notes [componentId]="20"></app-overview-notes>
	}
</div>
