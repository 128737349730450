import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { forkJoin, Observable, Subscriber } from 'rxjs';
import { RestService } from 'services/common/rest.service';
import { NotificationService } from 'services/common/notification.service';
import { ProjectDashboardService } from '../../../../../services/project/project.service';
import { FileUploadService } from '../../../../../services/common/fileUpload.service';
import { ScheduleStorageService } from '../../../../../services/project/schedule-storage.service';
import { differenceInDays, isValid } from 'date-fns';
import { Update } from '../../../../../services/project/update/update.model';
import { AnalyticsDashboardService } from '../../../../../services/analytics/analytics.service';
import { NavigationBarStorageService } from '../../../../../services/common/navigation-bar-storage.service';
import { AppWindowService } from '../../../../../services/common/window.service';
import { UpdateInterface } from '../../../../../models/Update/Task';
import { CredentialsInterface, S3UploadInterface } from '../../../../../models/Update/S3Upload';
import { Xer, XerActivity } from '@rhinoworks/xer-parse';
import { EditedUpdate } from '../../../schedule-updates-list/schedule-updates-list.component';
import { UiStorageService } from '../../../../../services/common/ui-storage.service';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { UserService } from '../../../../../services/common/user.service';

interface WBSItem {
	id: number;
	parent_wbs_id: number | null;
	proj_flag: 'Y' | 'N';
	proj_id: number;
	seq_num: number;
	wbs_name: string;
	wbs_short_name: string;
}

interface WBSTreeNode {
	item: WBSItem;
	children: WBSTreeNode[];
}

@Component({
	selector: 'app-add-bulk-update',
	templateUrl: './add-bulk-update.component.html',
	styleUrls: ['./add-bulk-update.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class AddBulkUpdateComponent {
	@ViewChild('XERFileInput') xerInput: ElementRef;
	@Input() projectData: any;
	@Input() isScheduleModification: boolean = false;
	@Output() resizedWindow = new EventEmitter<boolean>();
	selectedFiles = [];
	uploadedFiles: Array<
		{
			name: string;
			updateUrl: CredentialsInterface;
			uploadedFile: string;
			updateId: string;
		} & Partial<UpdateInterface>
	>;
	fileUploadInProgress = false;
	httpProcessing = false;
	uploadedUpdates: Update[] = [];
	windowHeightsByUpdateLength = [230, 755, 955];
	_exceedsLicenseTaskLimit: boolean[] = [];
	noTasks: boolean[] = [];
	disableParent = false;
	confirmOpen: number = null;
	errorMessage: string = '';
	uploadType: number = 0;
	fileFromImport = false;

	constructor(
		private _projectDashboardService: ProjectDashboardService,
		private restService: RestService,
		private notificationService: NotificationService,
		public fileUploadService: FileUploadService,
		private scheduleService: ScheduleStorageService,
		public analyticsService: AnalyticsDashboardService,
		public navBarService: NavigationBarStorageService,
		public windowService: AppWindowService,
		private navBarStorage: NavigationBarStorageService,
		public uiStorage: UiStorageService,
		public userService: UserService,
		private cdr: ChangeDetectorRef
	) {
		this.restService.getAccount('v2/my/databases/').subscribe((res: any) => {
			console.log(res);
			this.userService?.user.subscribe((data) => {
				if (data) {
					console.log(data);
					const companyList = data.companyIds;
					res = res.filter((database) => {
						const allowedCompanyList = database.company_assignments;
						if (!allowedCompanyList) {
							return true;
						}
						return allowedCompanyList.some((company) => companyList.includes(company.analyticsId));
					});
					console.log(res);
				}
			});
			this.selectionConnectionList = res;
			this.rawSelectionConnectionList = res;
			if (res.length === 1) {
				this.databaseInfo.selectedConnection = res[0];
				this.loadNewDatabasesToSelect(res[0]);
				this.cdr.detectChanges();
			}
			if (this.projectData.projectSelectedDatabase) {
				this.loadNewFilterCodesAndProjects(this.projectData.projectSelectedDatabase);
			}
		});
	}

	async makeFileReadyForUpload(event, fromImport = false) {
		if (event.target.files.length > 5) {
			alert('Unable to process more than 5');
			this.xerInput.nativeElement.value = '';
		}
		this.uploadedUpdates = [];
		this._exceedsLicenseTaskLimit = [];
		this.noTasks = [];
		const companyPermissions = this.navBarStorage.companyPermissionMap.get(
			this._projectDashboardService.$currentProjectData.value.company
		);
		const restricted_licenses = ['ANALYTICS-BASIC-MPK', 'ANALYTICS-BASIC-APK'];

		for (let i = 0; i < event.target.files.length; i++) {
			const update = new Update(
				this._projectDashboardService.$currentProjectData.value,
				this.scheduleService.$allUpdates.value[this.scheduleService.$allUpdates.value.length - 1]
			);
			this.errorMessage = await update.handleFileSelect(event, i);
			update.data.criticalPathNotes = '';
			update.data.timeAnalysisNotes = '';
			this._exceedsLicenseTaskLimit.push(
				restricted_licenses.includes(companyPermissions.license) && update.xer.sortedActivities.length > 10_000
			);
			this.noTasks.push(update.xer.sortedActivities.length <= 0);
			this.uploadedUpdates.push(update);
		}
		this.selectedFiles = Object.values(event.target.files);
		this.fileFromImport = fromImport;
		this.uploadXERFiles(this.uploadedUpdates, event.target.files);
	}
	uploadXERFiles(updates: Update[], files: any[]) {
		const apiCalls: Promise<{
			name: string;
			updateUrl: CredentialsInterface;
			uploadedFile: string;
			updateId: string;
		}>[] = [];
		this.fileUploadInProgress = true;
		this.selectedFiles.forEach((file: File, index) => {
			const uploadPromise = this.uploadXERFile(file, updates[index]);
			apiCalls.push(uploadPromise);
		});

		Promise.allSettled(apiCalls).then(
			(results) => {
				this.uploadedFiles = results.map((r, index) => (r.status === 'fulfilled' ? r.value : undefined));
				this.proceedToReview();
				// this.onNoClick();
				this.fileUploadInProgress = false;
				this.updateNewBulkHeight();
			},
			(error) => {
				console.log(error);
				this.fileUploadInProgress = false;
			}
		);
	}

	updateNewBulkHeight() {
		const newBulkHeight = !this.uploadedUpdates?.length
			? 230
			: this.uploadedUpdates?.length >= 3 ||
				  (this.uploadedUpdates?.length < 3 &&
						this.windowHeightsByUpdateLength[this.uploadedUpdates.length] > this.navBarService.bulkUpdateFormMaxHeight)
				? this.navBarService.bulkUpdateFormMaxHeight
				: this.windowHeightsByUpdateLength[this.uploadedUpdates.length];
		this.windowService.$bulkUpdateHeight.next(newBulkHeight);
		this.resizedWindow.emit(true);
	}

	uploadXERFile(
		file: File,
		update: Update
	): Promise<{
		name: string;
		updateUrl: CredentialsInterface;
		uploadedFile: string;
		updateId: string;
	}> {
		const metadata = {
			name: this.fileFromImport
				? update.xer.projects.get(update.data.finishMilestone.proj_id).shortName + '.xer'
				: file.name,
			type: 'text/plain',
			size: file.size,
		};
		const body = { metadata, projectId: this.projectData?._id };

		return new Promise((resolve, reject) => {
			this.restService.post(`project/${this.projectData?._id}/fileUpload`, body).subscribe(
				async (uploadResponse: any) => {
					const s3Upload: S3UploadInterface = uploadResponse.data;
					const tempUrlRes = s3Upload.credentials;
					const tempUrl = JSON.parse(JSON.stringify(tempUrlRes));
					if (uploadResponse.isErr === false) {
						const { s3Credentials, filePath } = tempUrlRes;
						let { type } = metadata;
						type = type ? type : 'text/plain';
						const uploadRes = await this.restService.uploadS3File(
							s3Credentials.replace('%2F', '/'),
							filePath,
							type,
							file
						);
						const uploadedFile = s3Credentials.split('?')[0];
						const data = {
							name: metadata.name,
							updateUrl: tempUrlRes,
							uploadedFile,
							updateId: s3Upload.updateId,
						};
						resolve(data);
					} else {
						reject();
						console.log('error in temp url POST', tempUrl);
					}
				},
				(error) => {
					console.log('error', error);
					reject();
				}
			);
		});
	}
	proceedToReview() {
		const compareUpdates = (a, b) => new Date(a.dataDate).getTime() - new Date(b.dataDate).getTime() || 0;
		this.uploadedUpdates.sort(compareUpdates);
	}

	addUpdate(): void {
		const body = this.uploadedFiles;
		localStorage.setItem('updatesAdded', JSON.stringify([this.projectData?.updateIds?.length]));
		localStorage.setItem('newUpdatesLength', JSON.stringify(this.projectData?.updateIds?.length + 1));
		let costBl: string = undefined;
		for (let i = 0; i < body.length; i++) {
			body[i] = {
				...body[i],
				baseline: this.uploadedUpdates[i].data.baseline,
				criticalPathNotes: this.uploadedUpdates[i].data.criticalPathNotes,
				timeAnalysisNotes: this.uploadedUpdates[i].data.timeAnalysisNotes,
				selectedProjectId: this.uploadedUpdates[i].data.selectedProjectId,
				finishMilestone: this.uploadedUpdates[i].data.finishMilestone,
			};
			if (this.uploadedUpdates[i].data.isCostBaseline) {
				costBl = this.uploadedUpdates[i].data._id;
			}
		}
		this.httpProcessing = true;
		if (costBl !== undefined) {
			this.restService
				.post(`project/${this.projectData?._id}/setCostBaseline`, { costBaseline: costBl || undefined })
				.subscribe((res) => {
					console.log(res);
				});
		}
		this._projectDashboardService.queueProcessingChanged.next(true);
		this.analyticsService.addUpdateOpenSchedulesTab.next(false);
		this.restService.post(`bulkupdate/${this.projectData?._id}`, body).subscribe(
			(response) => {
				this.notificationService.showNotification('Updates added successfully!');
				this.proceedToReview();
			},
			(error) => {
				console.log('error', error);
				this.notificationService.showNotification(error.message || 'Oops! Something went wrong!');
			},
			() => (this.httpProcessing = false)
		);
	}

	addUpdatesScheduleMod() {
		const body = this.uploadedFiles || [];
		let softAddedUpdateIsCostBaseline: boolean = false;
		for (let i = 0; i < body.length; i++) {
			body[i] = {
				...body[i],
				baseline: this.uploadedUpdates[i].data.baseline,
				criticalPathNotes: this.uploadedUpdates[i].data.criticalPathNotes,
				timeAnalysisNotes: this.uploadedUpdates[i].data.timeAnalysisNotes,
				selectedProjectId: this.uploadedUpdates[i].data.selectedProjectId,
				finishMilestone: this.uploadedUpdates[i].data.finishMilestone,
			};
			if (this.uploadedUpdates[i].data.isCostBaseline) {
				softAddedUpdateIsCostBaseline = true;
			}
		}
		this.httpProcessing = true;
		this.scheduleService.isLoading = true;
		this.analyticsService.addUpdateOpenSchedulesTab.next(false);
		this.restService.post(`softupdate/${this.projectData?._id}`, body).subscribe(
			(response: { updates: Array<UpdateInterface> }) => {
				this.notificationService.showNotification('Updates added successfully!');
				const d = this.scheduleService.$modifiedUpdates.value;
				let startMilestone: XerActivity;
				for (let i = 0; i < response.updates.length; i++) {
					const update = response.updates[i];
					const xer = new Xer(update.xerFileBuffer);
					if (i === 0) {
						startMilestone = update.startMilestone;
					}
					const finishMilestone = update.finishMilestone;
					const contractCompletion = new Date(finishMilestone?.cstr_date);
					const currentCompletion = new Date(finishMilestone?.act_end_date || finishMilestone?.early_end_date);
					const contractVariance =
						isValid(contractCompletion) && isValid(currentCompletion)
							? differenceInDays(contractCompletion, currentCompletion)
							: undefined;
					const dataDate = new Date(
						update.dataDate ||
							(xer.projects.get(update.selectedProjectId) || xer.projects.get(finishMilestone.proj_id))
								?.lastRecalculationDate
					);
					const startDate = new Date(startMilestone?.early_start_date || startMilestone?.target_start_date);
					const daysElapsed = isValid(startDate) ? differenceInDays(dataDate, startDate) ?? undefined : undefined;
					const cpliDaysElapsed = differenceInDays(contractCompletion, dataDate) + 1;
					const cpli = cpliDaysElapsed ? (contractVariance ?? 0) / cpliDaysElapsed : 0;
					const tfci = (daysElapsed + (contractVariance ?? 0)) / daysElapsed;
					d.push({
						...update,
						fileName: update.xerFileName,
						url: update.url || '#',
						dataDate,
						updateName: '',
						createdAtDate: new Date(),
						contractCompletionDate: contractCompletion,
						currentCompletionDate: currentCompletion,
						contractVariance,
						previousVariance: 0,
						cpli: isNaN(cpli) || !isFinite(cpli) ? '0' : cpli.toFixed(2),
						tfci: isNaN(tfci) || !isFinite(tfci) ? '0' : tfci.toFixed(2),
						projectId: update.projectId,
						criticalPathNotes: this.uploadedUpdates[i].data.criticalPathNotes,
						timeAnalysisNotes: this.uploadedUpdates[i].data.timeAnalysisNotes,
						// isAegisGeneratedSchedule: this.uploadedUpdates[i]?.isAegisGeneratedSchedule || false,
						toAdd: true,
						toDelete: false,
						toBaseline: update.baseline,
						index: d.length,
						isCostBaseline: this.uploadedUpdates[i].data.isCostBaseline,
						isBecomingCostBaseline: this.uploadedUpdates[i].data.isCostBaseline,
						wasCostBaseline: false,
						hasCostData: this.uploadedUpdates[i].data.hasCostData,
						scheduleName: xer.projects.get(update.selectedProjectId).shortName,
					});
				}
				d.sort((a, b) => new Date(a.dataDate).getTime() - new Date(b.dataDate).getTime() || 0);
				for (let i = 0; i < d.length; i++) {
					if (
						i > 0 &&
						!d[i].baseline &&
						isValid(d[i - 1].currentCompletionDate) &&
						isValid(d[i].currentCompletionDate)
					) {
						d[i].previousVariance = differenceInDays(d[i - 1].currentCompletionDate, d[i].currentCompletionDate);
					}
					d[i].updateName = `Update ${i}`;
				}
				this.scheduleService.$modifiedUpdates.next(d);
				if (softAddedUpdateIsCostBaseline) {
					this.scheduleService.$softAddIsCostBl.next(true);
				}
				this.scheduleService.isLoading = false;
			},
			(error) => {
				console.log('error', error);
				this.notificationService.showNotification(error.message || 'Oops! Something went wrong!');
			},
			() => (this.httpProcessing = false)
		);
	}

	get hasMissingFinishMilestones(): boolean {
		return !!this.uploadedUpdates.find((update) => !update.data.finishMilestone);
	}

	closeConfirm(accept?: boolean) {
		if (accept !== null && this.uploadedUpdates[this.confirmOpen]) {
			this.uploadedUpdates[this.confirmOpen].data.baseline = accept;
		}
		this.confirmOpen = null;
	}

	// db stuff
	selectionDatabaseList: any[] = [];
	rawSelectionDatabaseList: any[] = [];
	selectionPCatList: any[] = [];
	rawSelectionPCatList: any[] = [];
	selectionPCatValList: any[] = [];
	rawSelectionPCatValList: any[] = [];

	selectionProjectList: any[] = [];

	databaseInfo: any = {
		database: '',
		selectedPCat: '',
		selectedPCatVal: '',
		projectNumber: '',
		projectName: '',
		selectedId: '',
	};

	string_to_slug(str) {
		str = str.replace(/^\s+|\s+$/g, ''); // trim
		//str = str.toLowerCase();

		// remove accents, swap ñ for n, etc
		const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;' + 'àáäâèéëêìíïîòóöôùúüûñç'.toUpperCase();
		const to = 'aaaaeeeeiiiioooouuuunc------' + 'aaaaeeeeiiiioooouuuunc'.toUpperCase();
		for (let i = 0, l = from.length; i < l; i++) {
			str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
		}

		str = str
			.replace(/[^a-zA-Z0-9 -]/g, '') // remove invalid chars
			.replace(/\s+/g, '-') // collapse whitespace and replace by -
			.replace(/-+/g, '-'); // collapse dashes

		return str;
	}

	doSecretMagic() {
		this.restService
			.postAccountXerFetch(
				'v2/apps/importengine/convert/schedule/',
				{
					id: this.databaseInfo.selectedId,
					database: this.databaseInfo.database,
					projectNumber: this.databaseInfo.projectNumber,
				},
				'text'
			)
			.subscribe(async (response: any) => {
				// response will be a text file.
				console.log(response);
				const file = new File(
					[new Blob([response], { type: 'text/plain' })],
					this.string_to_slug(this.databaseInfo.projectName) + '.xer',
					{ type: 'text/plain' }
				);
				let event: any = { target: document.getElementById('bulk-update-file-input') as HTMLInputElement };
				const dataTransfer = new DataTransfer();
				dataTransfer.items.add(file);
				for (let i = 0; i < event.target.files.length; i++) {
					dataTransfer.items.add(event.target.files[i]);
				}
				event.target.files = dataTransfer.files;
				event = { target: { files: dataTransfer.files } };
				//this.getGroupAt(1).markAsTouched();
				//this.uploadedUpdates = [];
				const update = new Update();
				await update.handleFileSelect(event);
				await this.makeFileReadyForUpload(event, true);
				/**
				await this.fileUploadService.handleFileSelect(event);
				this.fileUploadService.xerFile = event;
				this.uploadedUpdates.push(update);
				console.log(update.xer);
				this.selectedFiles = Object.values(event.target.files);
				this.uploadXERFiles(this.uploadedUpdates, [event.target.files[0]]);
					**/
				alert('Schedule has been imported from P6 Database successfully.');

				const projectId: string = this._projectDashboardService.$currentProjectData.value?._id;
				const projectSelectedDatabase = this.databaseInfo.database;
				if (projectId !== undefined) {
					this.restService
						.patch(`project/${projectId}`, {
							projectSelectedDatabase,
						})
						.subscribe((val) => {
							console.log('patch res', val);
						});
				}
			});
	}
	pcatRaws: any[] = [];
	projectRaws: any[] = [];
	selectionConnectionList: any[] = [];
	rawSelectionConnectionList: any[] = [];
	databaseFailedConnect: boolean = false;
	loadNewFilterCodesAndProjects($event: any) {
		this.databaseInfo.filterCode = '';
		this.databaseInfo.filterCodeValue = '';
		this.databaseInfo.database = $event;
		this.restService
			.postAccount('v2/apps/importengine/connection/validate/', {}, false, {
				id: this.databaseInfo.selectedId,
				database: $event,
			})
			.subscribe((res) => {
				this.restService
					.postAccount('v2/apps/importengine/connection/wbs/', {}, false, {
						id: this.databaseInfo.selectedId,
						database: $event,
					})
					.subscribe((res2) => {
						console.log(res2);
						const res = this.buildWbsProjectTree(res2.wbs);
						this.selectionProjectList = res;
						this.projectRaws = res;
					});
				console.log(res);
				//this.selectionProjectList = res.projects;
				this.selectionPCatList = res.pcats;
				this.rawSelectionPCatList = res.pcats;
				//this.projectRaws = res.projects;
				this.pcatRaws = res.pcats;
			});
	}

	checkPCatFilterRequest($event: any) {
		this.databaseInfo.selectedPCat = $event;
		this.databaseInfo.selectedPCatVal = '';
		this.databaseInfo.filterCodeValue = '';
		this.selectionProjectList = this.projectRaws;
		this.selectionPCatValList = [];
		this.rawSelectionPCatValList = [];
		for (let i = 0; i < this.pcatRaws.length; i++) {
			if (this.pcatRaws[i].id === $event) {
				this.selectionPCatValList = this.pcatRaws[i].values;
				this.rawSelectionPCatValList = this.pcatRaws[i].values;
				break;
			}
		}
	}

	filterValueChangeRequest($event: any) {
		if (!$event) {
			this.selectionProjectList = this.projectRaws;
			return;
		}
		this.databaseInfo.selectedPCatVal = $event;
		this.selectionProjectList = [];
		this.restService
			.postAccount('v2/apps/importengine/connection/filter/', {}, false, {
				id: this.databaseInfo.selectedId,
				database: this.databaseInfo.database,
				selectedPCat: this.databaseInfo.selectedPCat,
				selectedPCatVal: $event,
			})
			.subscribe((res) => {
				console.log(res);
				const p = [];
				for (const proj of res.filteredProjects) {
					p.push(Number(proj));
				}

				const filter = (nodes: WBSTreeNode[]): WBSTreeNode[] => {
					return nodes
						.filter((node) => node.item && !(node.item?.proj_flag === 'Y' && !p.includes(node.item.proj_id)))
						.map((node) => ({
							...node,
							children: node.children ? filter(node.children) : [],
						}));
				};

				this.selectionProjectList = filter(structuredClone(this.projectRaws));
			});
	}

	loadNewDatabasesToSelect($event: any) {
		this.databaseFailedConnect = false;
		this.databaseInfo.selectedId = $event;
		if ($event !== '') {
			this.restService.postAccount('v2/apps/importengine/connection/list/', {}, false, { id: $event }).subscribe(
				(res: any) => {
					this.selectionDatabaseList = res.dbs;
					this.rawSelectionDatabaseList = res.dbs;
				},
				(err) => {
					this.databaseFailedConnect = true;
				}
			);
		} else {
			this.selectionDatabaseList = [];
			this.rawSelectionDatabaseList = [];
			this.selectionPCatList = [];
			this.rawSelectionPCatList = [];
			this.selectionPCatValList = [];
			this.rawSelectionPCatValList = [];
			this.selectionProjectList = [];
		}
	}

	handleProjectNumber(item: any) {
		let found = false;
		this.databaseInfo.projectName = '';
		this.databaseInfo.projectNumber = null;

		// Recursive function to search for the item in the tree
		const findItem = (nodes: WBSTreeNode[]): boolean => {
			for (const node of nodes) {
				if (node.item.id === item.item.id) {
					this.databaseInfo.projectName = node.item.wbs_name;
					this.databaseInfo.projectNumber = node.item.proj_id;
					return item.item.proj_flag === 'Y';
				}
				if (node.children && node.children.length > 0) {
					if (findItem(node.children)) {
						return true;
					}
				}
			}
			return false;
		};

		found = findItem(this.selectionProjectList);
		this.disableParent = found;
	}

	disableCloseForParents(event): void {
		if (!this.disableParent && this.databaseInfo.projectNumber && this.selectionProjectList.length > 0) {
			event.preventDefault();
		}
	}

	buildWbsProjectTree(data: WBSItem[]): WBSTreeNode[] {
		const idToNodeMap: { [key: number]: WBSTreeNode } = {};

		// Create a map of nodes
		data.forEach((item) => {
			idToNodeMap[item.id] = {
				item,
				children: [],
			};
		});

		const rootNodes: any[] = [];

		// Link nodes to their parents
		data.forEach((item) => {
			const node = idToNodeMap[item.id];
			if (item.parent_wbs_id !== null && item.parent_wbs_id in idToNodeMap) {
				idToNodeMap[item.parent_wbs_id].children.push(node);
			} else {
				// If there's no valid parent, it must be a root node
				rootNodes.push(node);
			}
		});

		return rootNodes;
	}

	autoComplete(value, rawFieldList, field: 'connection' | 'database' | 'filterCode' | 'filterCodeValue') {
		console.log(value, rawFieldList);
		switch (field) {
			case 'connection':
				return rawFieldList.filter((s) => s.friendly_name.toLowerCase().includes(value.toLowerCase()));
			case 'database':
				return rawFieldList.filter((s) => s.toLowerCase().includes(value.toLowerCase()));
			case 'filterCode':
				return rawFieldList.filter((s) => s.name.toLowerCase().includes(value.toLowerCase()));
			case 'filterCodeValue':
				return rawFieldList.filter((s) => s.value.toLowerCase().includes(value.toLowerCase()));
		}
	}

	public onSelect(e: SelectEvent): void {
		this.uploadType = e.index;
		if (e.index === 0) {
			this.updateNewBulkHeight();
		}
		if (e.index === 1) {
			this.windowService.$bulkUpdateHeight.next(390);
		}
		if (e.index === 2) {
			this.windowService.$bulkUpdateHeight.next(575);
		}
	}
}
