import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { cprTrendsInterface } from '../../../../models/ProjectReport/ProjectReport';
import { CurrentProjectReport, ProjectDashboardService } from '../../../../services/project/project.service';
import { differenceInCalendarDays } from 'date-fns';
import { NavigationBarStorageService } from '../../../../services/common/navigation-bar-storage.service';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { chevronRightIcon, chevronLeftIcon, SVGIcon } from '@progress/kendo-svg-icons';
import { XerActivity, XerProject } from '@rhinoworks/xer-parse';
import { NotesPreferences } from '../../../../models/company';

@Component({
	selector: 'app-update-report',
	templateUrl: './update-report.component.html',
	styleUrls: ['./update-report.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class UpdateReportComponent implements OnInit, OnDestroy {
	@Input() $projectReport = new BehaviorSubject<CurrentProjectReport>(undefined);
	@Input() updateIndexes: number[];
	@Output() windowTitle = new EventEmitter<string>();
	_currentUpdateIndex: number = 0;
	$totalPercentComplete = new BehaviorSubject<number>(null);
	$criticalPercentComplete = new BehaviorSubject<number>(null);
	$projectScorePassing = new BehaviorSubject<boolean>(false);
	$progressScorePassing = new BehaviorSubject<boolean>(false);
	$qcScorePassing = new BehaviorSubject<boolean>(false);
	$predictabilityScorePassing = new BehaviorSubject<boolean>(false);
	$projectScore = new BehaviorSubject<number>(null);
	$progressScore = new BehaviorSubject<number>(null);
	$qcScore = new BehaviorSubject<number>(null);
	$predictabilityScore = new BehaviorSubject<number>(null);
	$percentNegativeFloats = new BehaviorSubject<number>(null);
	$percentHighDuration = new BehaviorSubject<number>(null);
	$percentCriticalActivities = new BehaviorSubject<number>(null);
	$criticalPathReliabilityScore = new BehaviorSubject<number>(null);
	$percentPlannedProgress = new BehaviorSubject<number>(null);
	$percentActualProgress = new BehaviorSubject<number>(null);
	$criticalPathRiskSwitch = new BehaviorSubject<string>(null);
	$clientName = new BehaviorSubject<string>(null);
	$missingRelationships = new BehaviorSubject<boolean>(false);
	$actualsPastDataDate = new BehaviorSubject<boolean>(false);
	$percentageNegativeFloat = new BehaviorSubject<number>(null);
	$actualsWithNoProgress = new BehaviorSubject<boolean>(false);
	$outOfSequence = new BehaviorSubject<boolean>(false);
	$pctWithNoChangeRD = new BehaviorSubject<number>(null);
	$ssNoFF = new BehaviorSubject<boolean>(false);
	$fsWithLags = new BehaviorSubject<boolean>(false);
	$floatIndexCurrent = new BehaviorSubject<number>(null);
	$floatIndexPrevious = new BehaviorSubject<number>(null);
	$contractCompletionDate = new BehaviorSubject<Date>(null);
	$currentCompletionDate = new BehaviorSubject<Date>(null);
	$dataDate = new BehaviorSubject<Date>(null);
	$updateName = new BehaviorSubject<string>(null);
	$contractVariance = new BehaviorSubject<number>(null);
	$previousVariance = new BehaviorSubject<number>(null);
	$countHighDurations = new BehaviorSubject<number>(null);
	$ssFFWithLags = new BehaviorSubject<number>(null);
	$countActualsPastDataDate = new BehaviorSubject<number>(null);
	$softConstraints = new BehaviorSubject<number>(null);
	$missingLogic = new BehaviorSubject<number>(null);
	$hardConstraints = new BehaviorSubject<number>(null);
	$countFsWithLags = new BehaviorSubject<number>(null);
	$negativeLags = new BehaviorSubject<number>(null);
	$highFloat = new BehaviorSubject<number>(null);
	$retainedLogic = new BehaviorSubject<boolean>(false);
	$floatIndexCurrentGreen = new BehaviorSubject<boolean>(false);
	$totalActivities = new BehaviorSubject<number>(null);
	$logicDensity = new BehaviorSubject<number>(null);
	$criticalPathNotes = new BehaviorSubject<string>(null);
	$timeAnalysisNotes = new BehaviorSubject<string>(null);
	criticalPathNotes: string;
	timeAnalysisNotes: string;
	arrowUp = null;
	fileName = 'test.pdf';
	public chevronLeft: SVGIcon = chevronLeftIcon;
	public chevronRight: SVGIcon = chevronRightIcon;
	currentPage = 1;
	totalPages = 4;
	isBaseline = false;
	sideShowing: 'front' | 'back' = 'front';
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	@Input() notesRequirement: BehaviorSubject<NotesPreferences> = new BehaviorSubject<NotesPreferences>({
		critPathRequired: false,
		tiaRequired: false,
	});

	constructor(
		public project: ProjectDashboardService,
		public navBarStorage: NavigationBarStorageService,
		public scheduleStorage: ScheduleStorageService
	) {}

	ngOnInit() {
		this._currentUpdateIndex =
			this.navBarStorage.startFromIndex !== null
				? this.navBarStorage.startFromIndex
				: this.updateIndexes[this.updateIndexes.length - 1];
		this.currentPage = this.updateIndexes.findIndex((index) => index === this._currentUpdateIndex) + 1;
		this.totalPages = this.updateIndexes.length;
		this.navBarStorage.startFromIndex = null;
		this.generateReport();
		localStorage.removeItem('updatesAdded');
		// adjusts styles of report window to make only form content scrollable, not entire window (other styles to make this work on template)
		const reportWindow = document.getElementById('reportWindow');
		if (reportWindow) {
			const contentContainer = reportWindow.querySelectorAll('.k-window-content')[0];
			if (contentContainer) {
				contentContainer.classList.add('hide-overflow');
			}
		}
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
	}

	isChanged(): boolean {
		return (
			this.criticalPathNotes !== this.$criticalPathNotes.value ||
			this.timeAnalysisNotes !== this.$timeAnalysisNotes.value
		);
	}

	/**
	 * generates data for report based on update
	 */
	async generateReport(): Promise<void> {
		this.windowTitle.emit(
			this._currentUpdateIndex === 0 ? 'Baseline Report' : 'Report - Update ' + this._currentUpdateIndex
		);
		this.isBaseline = this._currentUpdateIndex === 0;
		const report: CurrentProjectReport = this.$projectReport.value;
		const update = this.scheduleStorage.$allUpdates.value[this._currentUpdateIndex];
		if (!!report && !!update) {
			let finishMilestoneCode;
			let finishMilestone: XerActivity;
			if (update.finishMilestone || finishMilestoneCode) {
				const updateTasks = await this.scheduleStorage.grabUpdateTable<XerActivity>(update._id, 'TASK');
				finishMilestone = updateTasks.find(
					(task) => task.task_code === finishMilestoneCode || update.finishMilestone.task_code === task.task_code
				);
			}
			if (finishMilestone && !finishMilestoneCode) {
				finishMilestoneCode = finishMilestone.task_code;
			}
			const baselineFinishMilestone = this.scheduleStorage.$allUpdates.value[0]?.finishMilestone;
			const baselineFinish =
				baselineFinishMilestone?.act_end_date ||
				baselineFinishMilestone?.cstr_date ||
				baselineFinishMilestone?.early_end_date ||
				undefined;
			const currentCompletion = new Date(finishMilestone?.act_end_date || finishMilestone?.early_end_date);
			if (!update.selectedProjectId) {
				return;
			}
			const finProj =
				finishMilestone &&
				((await this.scheduleStorage.grabUpdateTable<XerProject>(update._id, 'PROJECT')) || []).find(
					(p) => p.proj_id === finishMilestone.proj_id
				);
			const dataDate = new Date(finProj?.last_recalc_date);
			this.$contractCompletionDate.next(new Date(baselineFinish));
			this.$currentCompletionDate.next(new Date(currentCompletion));
			this.$dataDate.next(new Date(dataDate));
			this.$updateName.next(this._currentUpdateIndex === 0 ? 'Baseline' : 'Update ' + this._currentUpdateIndex);
			this.fileName = this.$projectReport?.value?.project?.name + '_' + this.$updateName.value + '_Report.pdf';
			this.$contractVariance.next(
				Number(report?.projectCompletionTrend?.projectCompletionTrendArray[this._currentUpdateIndex]?.contractVariance)
			);
			this.$previousVariance.next(
				Number(report?.projectCompletionTrend?.projectCompletionTrendArray[this._currentUpdateIndex]?.previousVariance)
			);
			const plannedTotalActivities = report?.qualityControl?.countTotalPlannedTrending[this._currentUpdateIndex];
			this.$totalPercentComplete.next(
				plannedTotalActivities === 0
					? -1
					: report?.activityCompletionGraph?.projectTrendGraph?.totalCompletionPercentageArray[this._currentUpdateIndex]
			);
			const plannedCriticalActivities = report?.qualityControl?.countCriticalPlannedTrending[this._currentUpdateIndex];
			this.$criticalPercentComplete.next(
				plannedCriticalActivities === 0
					? -1
					: report?.activityCompletionGraph?.projectTrendGraph?.criticalCompletionPercentageArray[
							this._currentUpdateIndex
						]
			);
			this.$projectScorePassing.next(report?.calculationFieldsHistorical[this._currentUpdateIndex]?.projectScore >= 70);
			this.$progressScorePassing.next(
				report?.calculationFieldsHistorical[this._currentUpdateIndex]?.progressScore >= 80
			);
			this.$qcScorePassing.next(report?.calculationFieldsHistorical[this._currentUpdateIndex]?.qcScore >= 80);
			this.$predictabilityScorePassing.next(
				report?.calculationFieldsHistorical[this._currentUpdateIndex]?.predictabilityScore >= 70
			);
			this.$projectScore.next(report?.calculationFieldsHistorical[this._currentUpdateIndex]?.projectScore);
			this.$progressScore.next(report?.calculationFieldsHistorical[this._currentUpdateIndex]?.progressScore);
			this.$qcScore.next(report?.calculationFieldsHistorical[this._currentUpdateIndex]?.qcScore);
			this.$predictabilityScore.next(
				report?.calculationFieldsHistorical[this._currentUpdateIndex]?.predictabilityScore
			);
			const negativeFloats = report?.dcma?.historicalCounts?.negativeFloat[this._currentUpdateIndex];
			let totalActivities = report?.dcma?.historicalCounts?.numIncompleteTasks[this._currentUpdateIndex];
			totalActivities = totalActivities > 0 ? totalActivities : 1;
			const highDurations = report?.dcma?.historicalCounts?.highDuration[this._currentUpdateIndex];
			const percentageNegativeFloats = (100 * negativeFloats) / totalActivities;
			const percentageHighDuration = (100 * highDurations) / totalActivities;
			this.$percentNegativeFloats.next(percentageNegativeFloats);
			this.$percentHighDuration.next(percentageHighDuration);
			const criticalActivities = report?.qualityControl?.numCriticalTrending[this._currentUpdateIndex];
			const percentageCriticalActivities = (100 * criticalActivities) / totalActivities;
			this.$percentCriticalActivities.next(percentageCriticalActivities > 100 ? 100 : percentageCriticalActivities);
			this.$criticalPathReliabilityScore.next(
				report?.riskPage?.criticalPathReliability?.overallScoreTrend[this._currentUpdateIndex]?.overallScore
			);
			const baselineStart = new Date(
				update?.startMilestone?.act_start_date ||
					update?.startMilestone?.early_start_date ||
					update?.startMilestone?.target_start_date ||
					update?.startMilestone?.update_date
			);
			const currentDataDate = this.$dataDate.value;
			const currentFinish = this.$currentCompletionDate.value;
			const planned =
				(100 * differenceInCalendarDays(currentDataDate, baselineStart)) /
				(differenceInCalendarDays(baselineFinish, baselineStart) || 1);
			const actual =
				100 *
				(1 -
					differenceInCalendarDays(currentFinish, currentDataDate) /
						(differenceInCalendarDays(currentFinish, baselineStart) || 1));
			this.$percentPlannedProgress.next(planned > 100 ? 100 : planned < 0 ? 0 : Math.round(planned));
			this.$percentActualProgress.next(actual > 100 ? 100 : actual < 0 ? 0 : Math.round(actual));
			const didCriticalPathRiskSwitch: string = this.didCriticalPathRiskSwitch(
				report?.riskPage?.criticalPathRisk?.cprTrends,
				this._currentUpdateIndex
			);
			this.$criticalPathRiskSwitch.next(didCriticalPathRiskSwitch);
			this.$missingRelationships.next(report?.qualityControl?.missingPredecessorsSuccessors > 2);
			this.$actualsPastDataDate.next(report?.qualityControl?.actualsPastDataDateTrending[this._currentUpdateIndex] > 0);
			this.$actualsWithNoProgress.next(
				report?.qualityControl?.numActualsNoProgressTrending[this._currentUpdateIndex] > 0
			);
			this.$outOfSequence.next(report?.qualityControl?.oosTrending[this._currentUpdateIndex] > 0);
			const pctWithNoChangeRD = Math.round(
				report?.qualityControl?.noChangeInRemainingTrending[this._currentUpdateIndex]
			);
			this.$pctWithNoChangeRD.next(pctWithNoChangeRD > 100 ? 100 : pctWithNoChangeRD < 0 ? 0 : pctWithNoChangeRD);
			this.$ssNoFF.next(report?.qualityControl?.ssWithoutFFTrending[this._currentUpdateIndex] > 0);
			this.$fsWithLags.next(report?.qualityControl?.fsWithLagTrending[this._currentUpdateIndex] > 0);
			const currentFloatIndex = Math.round(report?.floatHistorical[this._currentUpdateIndex]?.floatAverage);
			const prevFloatIndex =
				this._currentUpdateIndex > 0
					? Math.round(report?.floatHistorical[this._currentUpdateIndex - 1]?.floatAverage)
					: 0;
			this.$floatIndexCurrent.next(currentFloatIndex);
			this.$floatIndexPrevious.next(prevFloatIndex);
			const scheduleTD = currentFloatIndex / (differenceInCalendarDays(baselineFinish, baselineStart) || 1);
			this.$floatIndexCurrentGreen.next(scheduleTD >= 0.1);
			this.arrowUp = currentFloatIndex > prevFloatIndex ? true : currentFloatIndex < prevFloatIndex ? false : null;
			const allClients = this.project.allCompanies.slice();
			const matchingClient =
				allClients.find((client) => client?._id === this.$projectReport.value?.project?.companyId)?.name || '';
			this.$clientName.next(matchingClient);
			this.$criticalPathNotes.next(update?.criticalPathNotes);
			this.$timeAnalysisNotes.next(update?.timeAnalysisNotes);
			this.criticalPathNotes = update?.criticalPathNotes;
			this.timeAnalysisNotes = update?.timeAnalysisNotes;
			if (this.isBaseline) {
				this.$countHighDurations.next(report?.dcma?.historicalCounts?.highDuration[0]);
				this.$ssFFWithLags.next(report?.qualityControl?.ssFfProblematicTrending[0]);
				this.$countActualsPastDataDate.next(report?.qualityControl?.actualsPastDataDateTrending[0]);
				this.$softConstraints.next(report?.qualityControl?.softConstraintsTrending[0]);
				this.$missingLogic.next(report?.dcma?.historicalCounts?.incompleteMissingPredSucc[0]);
				this.$hardConstraints.next(report?.dcma?.historicalCounts?.hardConstraints[0]);
				this.$countFsWithLags.next(report?.qualityControl?.fsWithLagTrending[0]);
				this.$negativeLags.next(report?.dcma?.historicalCounts?.negativeLags[0]);
				this.$highFloat.next(report?.dcma?.historicalCounts?.highFloat[0]);
				this.$retainedLogic.next(report?.qualityControl?.retainedLogicHistorical[0]);
				this.$totalActivities.next(report.qualityControl.totalActivities);
				this.$logicDensity.next(
					(report?.qualityControl?.totalRelationships || 0) / (report?.qualityControl?.totalActivities || 1)
				);
			}
		}
	}

	/**
	 * determines if the paths in critical path risk switched
	 * @param cprTrends
	 * @param updateIndex
	 */
	didCriticalPathRiskSwitch(cprTrends: cprTrendsInterface, updateIndex: number): string {
		if (updateIndex === 0) {
			return 'N/A';
		}
		const currentUpdate = [
			{
				path: 'first',
				value: cprTrends?.firstPathTrend[updateIndex],
				priority: 1,
			},
			{
				path: 'second',
				value: cprTrends?.secondPathTrend[updateIndex],
				priority: 2,
			},
			{
				path: 'third',
				value: cprTrends?.thirdPathTrend[updateIndex],
				priority: 3,
			},
		].sort(this.pathCompareFunction);
		const previousUpdate = [
			{
				path: 'first',
				value: cprTrends?.firstPathTrend[updateIndex - 1],
				priority: 1,
			},
			{
				path: 'second',
				value: cprTrends?.secondPathTrend[updateIndex - 1],
				priority: 2,
			},
			{
				path: 'third',
				value: cprTrends?.thirdPathTrend[updateIndex - 1],
				priority: 3,
			},
		].sort(this.pathCompareFunction);
		const currentUpdateSecondLowestTfPathDelta = currentUpdate[1].value - currentUpdate[0].value;
		const previousUpdateSecondLowestTfPathDelta = previousUpdate[1].value - previousUpdate[0].value;
		if (
			currentUpdate[0].path !== previousUpdate[0].path ||
			(currentUpdateSecondLowestTfPathDelta === 0 && previousUpdateSecondLowestTfPathDelta !== 0) ||
			(currentUpdateSecondLowestTfPathDelta !== 0 && previousUpdateSecondLowestTfPathDelta === 0)
		) {
			return 'Yes';
		}
		return 'No';
	}

	/**
	 * compare helper function for critical path risk path sort
	 * @param a
	 * @param b
	 */
	pathCompareFunction(a, b): number {
		return a.value < b.value
			? -1
			: a.value > b.value
				? 1
				: a.priority < b.priority
					? -1
					: a.priority > b.priority
						? 1
						: 0;
	}

	/**
	 * goes to next/prev page
	 * @param increment
	 */
	nextPage(increment: number = 1) {
		let newPage = this.currentPage + increment;
		newPage = newPage < 1 ? 1 : newPage > this.totalPages ? this.totalPages : newPage;
		if (newPage !== this.currentPage) {
			this.currentPage = newPage;
			this._currentUpdateIndex = this.updateIndexes[this.currentPage - 1];
			this.generateReport();
		}
	}

	/**
	 * toggles which side of the report card is showing which will be used to show a flip animation
	 */
	flipReport(): void {
		this.sideShowing = this.sideShowing === 'front' ? 'back' : 'front';
	}

	/**
	 * save changes to notes and keep report open
	 */
	saveNotesChanges(): void {
		const currentUpdate = this.scheduleStorage.$modifiedUpdates.value[this._currentUpdateIndex];
		currentUpdate.criticalPathNotes = this.criticalPathNotes;
		currentUpdate.timeAnalysisNotes = this.timeAnalysisNotes;
		this.$criticalPathNotes.next(this.criticalPathNotes);
		this.$timeAnalysisNotes.next(this.timeAnalysisNotes);
		this.scheduleStorage.saveNotes(currentUpdate);
	}
}
