import { Component, Input } from '@angular/core';
import { ProjectDashboardService } from '../../../services/project/project.service';

@Component({
	selector: 'app-progress-page',
	templateUrl: './progress-page.component.html',
	styleUrls: ['./progress-page.component.scss'],
})
export class ProgressPageComponent {
	@Input() visualizer: boolean = false;
	constructor(public projectService: ProjectDashboardService) {}
}
