// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//     production: false,
//     hmr       : false
// };

export const environment = {
	production: false,
	hmr: false,
	staging: true,
	bleeding: false,
	//baseURL: 'https://f7gdou1sng.execute-api.us-east-1.amazonaws.com/dev/'

	// baseURL: 'https://bc6oe39kti.execute-api.us-east-1.amazonaws.com/dev/'

	// baseURL: 'https://usea.api.dashboards.rhino.works/',

	//baseURL: 'https://aegis-dashboards-backend-bleed.herokuapp.com/',

	//baseURL: 'https://tranquil-gorge-34672.herokuapp.com/',

	//baseURL: 'http://localhost:3000/',

	baseURL: 'https://stage-connector.rhinoworks.dev/',
	queues: {
		general: 'general',
		dashboards: {
			processBaseline: 'dashboards.processBaseline.staging',
			calculate: 'dashboards.calculate.staging',
		},
	},
	bullSettings: {
		redisUrl: '???',
	},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
