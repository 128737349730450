<div>
	<div *ngIf='isSaas'>
		<div style="font-weight: bold">{{saas1}}</div>
		<div class="indent">{{saas2}}</div>
		<div><br /></div>
		<div style="font-weight: bold">{{saas3}}</div>
		<div class="indent">{{saas4}}</div>
		<div><br /></div>
		<div>{{saas5}}</div>
		<div><br /></div>
	</div>
	<div style="font-weight: bold" *ngIf='subtitle1'>{{subtitle1}}</div>
	<div class="indent">{{firstParagraph}}</div>
	<div class="indent" *ngIf="strongRange">
		<div><br /></div>
		<span class="greenColor;">{{strongText}}</span>
		{{strongRange}}
	</div>
	<div class="indent" *ngIf="moderateRange">
		<span class="orangeColor;">{{moderateText}}</span>
		{{moderateRange}}
	</div>
	<div class="indent" *ngIf="needsImprovementRange">
		<span class="redColor;">{{needsImprovementText}}</span>
		{{needsImprovementRange}}
	</div>
	<div style="font-weight: bold" *ngIf="subtitle2">
		<div><br /></div>
		{{subtitle2}}
	</div>
	<ul *ngIf="i1">
		<li>{{i1}}</li>
		<li>{{i2}}</li>
		<li *ngIf="i3">{{i3}}</li>
		<li *ngIf="i4">{{i4}}</li>
		<li *ngIf="i5">{{i5}}</li>
		<li *ngIf="i6">{{i6}}</li>
		<li *ngIf="i7">{{i7}}</li>
	</ul>
	<div class="indent">{{secondParagraph}}</div>
	<ul *ngIf="i8">
		<li>{{i8}}</li>
		<li>{{i9}}</li>
		<li>{{i10}}</li>
		<li>{{i11}}</li>
		<li>{{i12}}</li>
		<li>{{i13}}</li>
		<li>{{i14}}</li>
		<li>{{i15}}</li>
	</ul>
	<div class="indent">{{thirdParagraph}}</div>
	<div class="indent" *ngIf="subtitle4">
		<div><br /></div>
		<b>{{subtitle4}}</b> - {{p4}}
	</div>
	<div class="indent" *ngIf="subtitle5">
		<div><br /></div>
		<b>{{subtitle5}}</b> - {{p5}}
	</div>
	<div *ngIf='disclaimer' style='font-style: italic'>
		<div><br /></div>{{disclaimer}}
	</div>
</div>

