import {
	AfterViewChecked,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { XerActivity } from '@rhinoworks/xer-parse';
import { groupBy, GroupResult, orderBy } from '@progress/kendo-data-query';
import { isValid } from 'date-fns';
import { SlimmedTaskCommon, UpdateInterface } from '../../../../models/Update/Task';
import { AppWindowService } from '../../../../services/common/window.service';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { datePipe } from '../../../../util/pipes/date.pipe';
import { EditedUpdate } from '../schedule-updates-list.component';

export interface EditUpdateResult {
	baseline: boolean;
	costBaseline: boolean;
	fromFinishMilestone: Partial<XerActivity>;
	finishMilestone: Partial<XerActivity>;
	criticalPathNotes: string;
	timeAnalysisNotes: string;
	_id: string;
	hasChanges: boolean;
	ogUpdate: UpdateInterface;
	cascadeMilestone: boolean;
}

@Component({
	selector: 'dialog-edit-notes',
	templateUrl: 'dialog-edit-notes.html',
})
export class DialogEditNotesComponent implements OnChanges, AfterViewChecked, OnInit {
	criticalPathNotes: string;
	timeAnalysisNotes: string;
	baseline: boolean;
	costBaseline: boolean;
	finishMilestone: { text: string; value: SlimmedTaskCommon; earlyFinish: Date };
	finishMilestoneOptions: Array<{ text: string; value: SlimmedTaskCommon; earlyFinish: Date }>;
	availableFinishMilestones: GroupResult[] | { text: string; value: SlimmedTaskCommon; earlyFinish: Date }[];
	validDate = isValid;
	public opened: boolean = true;
	confirmOpen = false;
	ogUpdate: UpdateInterface;
	eligibleTasks = new Set<string>([]);
	cascadeMilestone: boolean = false;
	fromFinishMilestone: XerActivity;
	@Input() data: { element: EditedUpdate; name: string; index: number };
	@Output() closeEvent = new EventEmitter<EditUpdateResult>();
	type: 'costBl' | 'bl';
	turnedOffCostBl: boolean = false;
	constructor(
		public appWindowService: AppWindowService,
		public cdr: ChangeDetectorRef,
		private scheduleService: ScheduleStorageService
	) {}

	ngOnInit() {
		console.log(this.data.element?.update?._id, 'id edit');
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (Object.prototype.hasOwnProperty.call(changes, 'data')) {
			const data: typeof this.data = changes.data.currentValue;
			this.ogUpdate = this.data?.element?.update?._id
				? this.scheduleService.$allUpdates.value.find((update) => update._id === data.element?.update._id)
				: undefined;
			this.baseline = data?.element?.baseline || data.index === 0;
			this.costBaseline = data?.element?.isCostBaseline;
			this.criticalPathNotes = data?.element?.criticalPathNotes;
			this.timeAnalysisNotes = data?.element?.timeAnalysisNotes;
			this.fromFinishMilestone = data.element?.finishMilestone;
			this.scheduleService.grabUpdateTable<XerActivity>(this.data?.element?.update?._id, 'TASK').then((xerTasks) => {
				this.eligibleTasks = new Set(xerTasks.map((task) => task.task_code));
				for (let i = data.index + 1; i < this.scheduleService.$modifiedUpdates.value.length; i++) {
					const update = this.scheduleService.$modifiedUpdates.value[i];
					if (update.toBaseline) {
						break;
					}
					const updateCodes = new Set<string>(xerTasks.map((task) => task.task_code));
					for (const code of this.eligibleTasks) {
						if (!updateCodes.has(code)) {
							this.eligibleTasks.delete(code);
						}
					}
				}
				const finishMilestones = new Set<{
					text: string;
					value: SlimmedTaskCommon;
					earlyFinish: Date;
					category: string;
				}>(
					xerTasks
						.filter(
							(task) =>
								task.task_type === 'TT_FinMile' ||
								(task.task_type !== 'TT_Mile' &&
									!task.task_name.toLowerCase().includes('igmp') &&
									!task.task_name.toLowerCase().includes('fgmp'))
						)
						.map((task) => ({
							text: `${task?.task_code} - ${task?.task_name} - ${datePipe(task?.early_end_date, 'MMM d, y')}`,
							value: task,
							earlyFinish: task.early_end_date,
							category: task.task_type === 'TT_FinMile' ? 'Finish Milestone' : 'All Activities',
						}))
				);
				this.finishMilestone = Array.from(finishMilestones).find((item) => {
					const itemTaskCode = +item.value.task_code;
					const milestoneTaskCode = +data.element.finishMilestone?.task_code;

					if (isNaN(itemTaskCode) && isNaN(milestoneTaskCode)) {
						return item.value.task_code === data.element.finishMilestone?.task_code;
					}

					return !isNaN(itemTaskCode) && !isNaN(milestoneTaskCode) && itemTaskCode === milestoneTaskCode;
				});

				this.availableFinishMilestones = groupBy(
					orderBy(Array.from(finishMilestones), [
						{ field: 'category', dir: 'desc' },
						{ field: 'earlyFinish', dir: 'asc' },
					]),
					[{ field: 'category' }]
				);
				this.finishMilestoneOptions = Array.from(finishMilestones);
			});
		}
	}

	ngAfterViewChecked(): void {
		this.cdr.detectChanges();
	}

	async filterFinishMilestones(term?: string): Promise<typeof this.availableFinishMilestones> {
		term = term ? term?.toLowerCase() : term;
		this.availableFinishMilestones = groupBy(
			orderBy(
				Array.from(
					(this.finishMilestoneOptions || [])
						.filter(
							(actv) =>
								actv.value.task_type !== 'TT_Mile' &&
								(!term ||
									actv.value.task_name.toLowerCase().includes(term) ||
									actv.value.task_code.toLowerCase().includes(term))
						)
						.map((actv) => ({
							value: actv.value,
							category: this.activityCategory(actv.value),
							earlyFinish: new Date(actv.value.early_end_date),
							text: `${actv.value.task_code} - ${actv.value.task_name} - ${datePipe(actv.value.early_end_date, 'MMM d, y')}`,
						}))
				),
				[
					{ field: 'category', dir: 'desc' },
					{ field: 'earlyFinish', dir: 'asc' },
				]
			),
			[{ field: 'category' }]
		) as GroupResult[] | { text: string; value: XerActivity; earlyFinish: Date }[];
		return this.availableFinishMilestones;
	}

	activityCategory({ task_type }: { task_type: string }): string {
		switch (task_type) {
			case 'TT_FinMile':
				return 'Finish Milestones';
			case 'TT_Mile':
				return 'Milestone';
		}
		return 'All Activities';
	}
	onNoClick(): boolean {
		if (this.turnedOffCostBl) {
			this.closeEvent.emit({
				baseline: this.baseline,
				costBaseline: this.costBaseline,
				finishMilestone: this.finishMilestone?.value,
				criticalPathNotes: this.criticalPathNotes,
				timeAnalysisNotes: this.timeAnalysisNotes,
				_id: this.data?.element?._id,
				hasChanges: this.isChanged(),
				ogUpdate: this.ogUpdate,
				cascadeMilestone: this.cascadeMilestone,
				fromFinishMilestone: this.fromFinishMilestone,
			});
		} else {
			this.closeEvent.emit(null);
		}
		return false;
	}
	isChanged(): boolean {
		return (
			this.criticalPathNotes !== this.data?.element?.criticalPathNotes ||
			this.timeAnalysisNotes !== this.data?.element?.timeAnalysisNotes ||
			this.finishMilestone?.value?.task_code !== this.data?.element.finishMilestone?.task_code ||
			this.baseline !== this.data?.element?.baseline ||
			this.costBaseline !== this.data?.element?.isCostBaseline ||
			this.cascadeMilestone
		);
	}

	public open(isOpened: boolean): void {
		this.opened = isOpened;
		if (isOpened) {
			this.appWindowService.setViewport('editNotesChanges');
		}
		if (this.turnedOffCostBl) {
			this.closeEvent.emit({
				baseline: this.baseline,
				costBaseline: this.costBaseline,
				finishMilestone: this.finishMilestone?.value,
				criticalPathNotes: this.criticalPathNotes,
				timeAnalysisNotes: this.timeAnalysisNotes,
				_id: this.data?.element?._id,
				hasChanges: this.isChanged(),
				ogUpdate: this.ogUpdate,
				cascadeMilestone: this.cascadeMilestone,
				fromFinishMilestone: this.fromFinishMilestone,
			});
		} else {
			this.closeEvent.emit(null);
		}
	}
	/**
	 * forces window to stay within bounds of viewport
	 * @param window
	 */
	restrictMovement(window: string): void {
		this.appWindowService.restrictMovement(window, this.cdr);
	}

	closeConfirm(accept?: boolean, type?: string) {
		this.confirmOpen = false;
		if (accept !== null) {
			switch (this.type) {
				case 'bl': {
					this.baseline = accept;
					break;
				}
				case 'costBl': {
					this.costBaseline = accept;
					break;
				}
			}
			if (type === 'cost') {
				this.costBaseline = accept;
				this.turnedOffCostBl = true;
			}
		}
	}

	openConfirm(type: 'costBl' | 'bl'): void {
		this.confirmOpen = true;
		this.type = type;
	}

	public itemDisabled = (itemArgs: {
		dataItem: { text: string; value: SlimmedTaskCommon; earlyFinish: Date };
		index: number;
	}) => {
		return !this.eligibleTasks.has(itemArgs.dataItem.value.task_code);
	};
}
