<div>
	<kendo-treeview
		[nodes]="_filteredUserReports"
		textField="name"
		[kendoTreeViewCheckable]="{checkParents: true}"
		[(checkedKeys)]="ganttService.checkedReports"
		[checkBy]="'id'"
		class="report-selection-treeview"
		[style.max-height]="disableReportChanges ? '300px' : '347px'"
	>
		<ng-template kendoTreeViewNodeTemplate let-dataItem>
			<span class="group-item-template" style="border-radius: 4px;" (click)="applyPreset(dataItem)" [style.background-color]="ganttService.selectedGanttLayout === dataItem.id ? 'rgba(18, 116, 172, 0.25)' : 'transparent'">
				<span>
					@if (dataItem?.isFavorite || !disableReportChanges) {
						<span class="default-preset" (click)="ganttService.toggleFavoritePreset($event, dataItem)">
							<kendo-svg-icon [icon]="dataItem?.isFavorite ? icons.star : icons.starOutline" style="color: #ffb500; height: 20px; width: 20px;"></kendo-svg-icon>
						</span>
					}
					<span class="group-name" [style.padding-left]="!dataItem?.isFavorite && disableReportChanges ? '4px' : '0'">
						{{ dataItem.name }}
					</span>
				</span>
				@if (dataItem.id !== 'all' && dataItem.id !== 'favorites' && !disableReportChanges) {
					<span class="actions">
						<button kendoButton fillMode='flat' title='Edit' [svgIcon]='icons.pencil' (click)='ganttService.editPreset($event, dataItem, false)' style='color: dodgerblue;' class='report-icons'></button>
						<button kendoButton fillMode='flat' title='Duplicate' [svgIcon]='icons.copy' (click)='ganttService.editPreset($event, dataItem, true)' class='report-icons'></button>
						<button kendoButton fillMode='flat' title='Delete' [svgIcon]='icons.trash' (click)='deleteReport($event, dataItem)' style='color: #f31700;' class='report-icons'></button>
					</span>
				}
			</span>
		</ng-template>
	</kendo-treeview>
</div>
