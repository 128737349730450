import { Error404Component } from './404/error-404.component';
import { Error500Component } from './500/error-500.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

const routes = [
	{
		path: 'errors/error-404',
		component: Error404Component,
	},
	{
		path: 'errors/error-500',
		component: Error500Component,
	},
];
@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
	providers: [],
})
export class ErrorsRoutingModule {}
