import {
	Component,
	Input,
	OnInit,
	OnDestroy,
	ViewEncapsulation,
	Output,
	EventEmitter,
	ViewChild,
	AfterViewInit,
	NgZone,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { PFTableValues } from '../performance-factor.component';
import { BuiltPfTable } from '../../../../../../util/projects';
import { RestService } from '../../../../../../services/common/rest.service';
import { ProjectDashboardService } from '../../../../../../services/project/project.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ScheduleStorageService } from '../../../../../../services/project/schedule-storage.service';
import { ActivityTypeInterface, IActivityCode, IWBS, Xer } from '@rhinoworks/xer-parse';
import { GridComponent, RowArgs, RowClassArgs, SelectionEvent } from '@progress/kendo-angular-grid';

export interface ParentDropdownItem {
	id: number;
	saveValue: string;
	activityCodeId?: number;
	wbsId?: number;
	display: string;
	children?: ParentDropdownItem[];
	childrenRows?: PFTableValues[];
}

export function actvTypeToParentDropdownItem(
	actvType: ActivityTypeInterface,
	typeCodes: IActivityCode[],
	pfTable: BuiltPfTable
): ParentDropdownItem {
	return {
		id: actvType.id,
		activityCodeId: null,
		wbsId: null,
		display: actvType.codeType,
		saveValue: actvType.codeType,
		children: [],
		childrenRows: pfTable.pfRows.filter((row) => row.typeId === actvType.id),
	};
}

export function wbsToParentDropdownItem(wbs: IWBS, pfTable: BuiltPfTable): ParentDropdownItem {
	return {
		id: wbs.id,
		activityCodeId: null,
		wbsId: wbs.id,
		saveValue: wbsDisplaySave(wbs),
		display: `${wbs.shortName} - ${wbs.name}`,
		children: wbs.childrenWbs
			?.map((child) => wbsToParentDropdownItem(child, pfTable))
			.filter((item) => item.childrenRows?.length > 0),
		childrenRows: pfTable.pfRows.filter((row) =>
			wbs.childrenWbs?.some((subwbs) => wbsDisplaySave(subwbs) === row.wbsKey)
		),
	};
}

export function wbsDisplaySave(wbs: IWBS): string {
	if (!wbs) {
		return '';
	}
	const key = `${wbs.shortName} - ${wbs.name}`;
	if (wbs.parentWbs && wbs.parentWbs.parentWbs) {
		return `${wbsDisplaySave(wbs.parentWbs)} -> ${key}`;
	}
	return key;
}

function findTypeFromTable(table: ParentDropdownItem[], saveKey: string): ParentDropdownItem | undefined {
	const topItem = table.find((item) => item.saveValue === saveKey);
	if (topItem) {
		return topItem;
	}
	for (const item of table) {
		if (item.children) {
			const found = findTypeFromTable(item.children, saveKey);
			if (found) {
				return found;
			}
		}
	}
	return undefined;
}

@Component({
	selector: 'app-pf-table-dropdown',
	templateUrl: './pf-table-dropdown.component.html',
	styleUrls: ['./pf-table-dropdown.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class PfTableDropdownComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
	@ViewChild(GridComponent)
	public grid: GridComponent;

	@Input() pfTable: BuiltPfTable;
	@Output() pfTableChange = new EventEmitter<BuiltPfTable>();
	@Input() selectedKeys: Array<string | number> = [];
	@Output() selectedKeysChange = new EventEmitter<Array<number | string>>();
	@Input() fromPerformanceTrendingComponent: boolean = false;
	@Input() nestedPfTable: Array<ParentDropdownItem>;
	@Input() savedSelectedActivityCodeType: ParentDropdownItem;
	@Output() selectedCodeTypeChange = new EventEmitter<string>();

	@Output() selectedActivityCodesEmitter = new EventEmitter<Array<string>>();
	@Output() activityCodeDropDownNameChange = new EventEmitter<string>();
	@Output() parentFromActivityCode = new EventEmitter<Map<number, PFTableValues>>();
	@Output() noActivityCodeTypes = new EventEmitter<boolean>();
	@Output() noSelections = new EventEmitter<boolean>();
	selectedCodeTypeName: string = null;
	activityCodeDropdownOptions: Array<ParentDropdownItem>;
	tablesCurrentCodes: PFTableValues[] = undefined;
	selectedActivityCodes: Array<number> = [];
	selectedWbs: Array<number> = [];
	selectableSettings = {
		checkboxOnly: true,
	};
	_unsubscribeAll = new Subject<void>();
	currentXer: Xer;
	constructor(
		private _projectDashboardService: ProjectDashboardService,
		private restService: RestService,
		public scheduleService: ScheduleStorageService,
		private ngZone: NgZone
	) {}

	ngOnInit(): void {
		if (this.nestedPfTable) {
			this.activityCodeDropdownOptions = this.nestedPfTable;
			if (!this.savedSelectedActivityCodeType) {
				this._projectDashboardService.$currentProjectData.subscribe((data) => {
					if (data) {
						this.savedSelectedActivityCodeType = findTypeFromTable(
							this.nestedPfTable,
							data.performanceTrendingSelectedCodeType
						);
					}
				});
			}
		}
		this._projectDashboardService.$currentProjectData.subscribe(async (data) => {
			this.currentXer = await this.scheduleService.grabUpdateXer(data.updateIds[data.updateIds.length - 1]);
		});
	}

	ngAfterViewInit(): void {
		this.fitColumns();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('nestedPfTable')) {
			this.activityCodeDropdownOptions = changes.nestedPfTable.currentValue;
			if (!this.savedSelectedActivityCodeType) {
				this.savedSelectedActivityCodeType = findTypeFromTable(
					changes.nestedPfTable.currentValue,
					this._projectDashboardService.$currentProjectData.value.performanceTrendingSelectedCodeType
				);
			}
			this.updateSelectionTable(this.savedSelectedActivityCodeType);
		}
	}

	updateSelectionTable(e: ParentDropdownItem) {
		if (!e) {
			this.tablesCurrentCodes = [];
			return;
		}
		this.selectedCodeTypeName = e.saveValue;
		this.tablesCurrentCodes = e.childrenRows;
	}

	public onSelect(e: SelectionEvent) {
		this.selectedKeys = this.selectedKeys.filter(
			(key) => !!key && this.tablesCurrentCodes.some((item) => item.activityCodeId === key || item.wbsKey === key)
		);
		if (
			this.selectedCodeTypeName !==
			this._projectDashboardService.$currentProjectData.value.performanceTrendingSelectedCodeType
		) {
			this.selectedCodeTypeChange.emit(this.selectedCodeTypeName);
		}
		this.selectedKeysChange.emit(this.selectedKeys);
	}

	public rowCallback = (context: RowClassArgs) => ({
		tableRow: true,
		over1: context.dataItem.pf > 1,
		under1: context.dataItem.pf < 1,
		nothanks: context.dataItem.pf === 0,
		bold: this.selectedKeys.find((selectedItem) => selectedItem === context.dataItem.activityCodeId),
	});

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	private fitColumns(): void {
		this.ngZone.onStable
			.asObservable()
			.pipe(take(1))
			.subscribe(() => {
				this.grid.autoFitColumns();
			});
	}

	public mySelectionKey(context: RowArgs): string {
		return context.dataItem.wbsKey || context.dataItem.activityCodeId;
	}
}
