import { NgModule } from '@angular/core';
import { AfterValueChangedDirective } from './after-value-changed.directive';
import { AfterValueChangedStrDirective } from './after-value-changed-str.directive';
import { MoneyMaskDirective } from './money-mask.directive';

@NgModule({
	declarations: [AfterValueChangedDirective, AfterValueChangedStrDirective, MoneyMaskDirective],
	exports: [AfterValueChangedDirective, AfterValueChangedStrDirective, MoneyMaskDirective],
})
export class DirectivesModule {}
