import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FloorPipe } from './FloorPipe';
import { DatePipe } from './date.pipe';
import { PctPipe } from './pct.pipe';
import { FilterValuesPipe } from './filter-values.pipe';
import { ProjectInfoPipe } from './project-info';
import { DCMAPctPipe } from './dcma-score.pipe';
import { AbsolutePipe } from './absolute.pipe';

@NgModule({
	declarations: [FloorPipe, DatePipe, PctPipe, FilterValuesPipe, ProjectInfoPipe, DCMAPctPipe, AbsolutePipe],
	exports: [FloorPipe, PctPipe, DatePipe, FilterValuesPipe, ProjectInfoPipe, DCMAPctPipe, AbsolutePipe],
	imports: [CommonModule],
})
export class PipeModule {}
