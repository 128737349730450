import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationBarComponent } from './interface/navigation-bar/navigation-bar.component';
import { AddProjectModule } from '../components/portfolio/add-project/add-project.module';
import { PopoverModule, TooltipModule } from '@progress/kendo-angular-tooltip';
import { IonicModule } from '@ionic/angular';
import { GridLayoutModule } from '@progress/kendo-angular-layout';
import { RouterLink } from '@angular/router';
import { IconsModule, SVGIconModule } from '@progress/kendo-angular-icons';
import { FormsModule } from '@angular/forms';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { WindowModule } from '@progress/kendo-angular-dialog';
import { ProjectModule } from '../components/project-page/project.module';
import { ButtonsModule } from '@progress/kendo-angular-buttons';

@NgModule({
	declarations: [NavigationBarComponent],
	imports: [
		CommonModule,
		AddProjectModule,
		PopoverModule,
		IonicModule,
		GridLayoutModule,
		RouterLink,
		SVGIconModule,
		IconsModule,
		FormsModule,
		ProgressBarModule,
		WindowModule,
		ProjectModule,
		ButtonsModule,
		TooltipModule,
	],
	exports: [NavigationBarComponent],
})
export class RhinoworksModule {}
