import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { ForgotPasswordComponent } from 'components/authentication/forgot-password/forgot-password.component';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
	declarations: [ForgotPasswordComponent],
	imports: [CommonModule, MatIconModule, RouterModule, MatInputModule, ReactiveFormsModule],
})
export class ForgotPasswordModule {}
