<kendo-window
	id="editNotesChangesWindow"
	*ngIf="opened"
	(close)="open(false)"
	[resizable]='false'
	[minWidth]="650"
	[(top)]="appWindowService.windowPosition.editNotesChanges.top"
	[(left)]="appWindowService.windowPosition.editNotesChanges.left"
	(leftChange)="restrictMovement('editNotesChanges')"
	(topChange)="restrictMovement('editNotesChanges')"
>
	<kendo-window-titlebar>
		<div class="k-window-title">{{data.name}}</div>
		<button kendoWindowMinimizeAction></button>
		<button kendoWindowMaximizeAction></button>
		<button kendoWindowRestoreAction></button>
		<button kendoWindowCloseAction></button>
	</kendo-window-titlebar>
	<div style='background-color: white;padding: 8px; width: 636px;'>
		<div style='display: grid;
    grid-auto-flow: column;'>
			<div style="display: flex; flex-direction: column; gap: 5px; height: 57px;">
				<div style="height: 26px; align-items: center; display: flex;">
					<div style="vertical-align: middle; margin-bottom: 1px;">
						File Name: {{data.element.fileName}}
					</div>
				</div>
				<div style="height: 26px; align-items: center; display: flex;">
					<div style="vertical-align: middle; margin-bottom: 1px;">
						Data Date: {{validDate(data.element.dataDate) ? (data.element.dataDate | cleanDateUTC: 'MMM dd, yyyy') : 'Unknown'}}
					</div>
				</div>
			</div>
			<div style='justify-content: flex-end; display: flex; align-items: end; flex-direction: column; gap: 5px;'>
				<div kendoTooltip style="text-align: center;">
					<kendo-label [title]="data.element.hasCostData ? '' : 'No Cost Data for Update'" [text]="'Cost Baseline'" class="sched-edit-label">
						<kendo-switch [(ngModel)]="costBaseline" [disabled]='data.element?.hasCostData === false || (data.element?.isCostBaseline && data.element.wouldBeAutoCostBaseline)' (valueChange)='costBaseline = $event' style='margin-left: 5px' [size]="'small'"></kendo-switch>
					</kendo-label>
				</div>
				<kendo-label [text]="data.index === 0 ? 'Baseline' : 'Re-Baseline'" class="sched-edit-label">
					<kendo-switch [(ngModel)]="baseline" [disabled]='data.index === 0' (valueChange)='!$event ? closeConfirm(false) : openConfirm("bl")' style='margin-left: 5px' [size]="'small'"></kendo-switch>
				</kendo-label>
			</div>
		</div>

		<br/>
		<div>
			<kendo-label [for]="finishMilestoneList" text="Finish Milestone"> </kendo-label>
			<kendo-dropdownlist
				#finishMilestoneList
				[data]="availableFinishMilestones"
				[(ngModel)]='finishMilestone'
				valueField='value.task_code'
				textField='text'
				[valuePrimitive]='false'
				[filterable]="true"
				(filterChange)="filterFinishMilestones($event)"
				[popupSettings]="{height: 227}"
				[itemDisabled]="itemDisabled"
				[virtual]='true'
				[loading]='!availableFinishMilestones?.length'
			>
				<ng-template kendoComboBoxFixedGroupTemplate let-groupName>
					<div><strong><u>{{groupName}}</u></strong></div>
				</ng-template>
				<ng-template kendoDropDownListHeaderTemplate let-category>
					<div>
						<strong><u>{{ category }}</u></strong>
					</div>
				</ng-template>
				<ng-template kendoDropDownListGroupTemplate let-category>
					<div>
						<strong><u>{{ category }}</u></strong>
					</div>
				</ng-template>
			</kendo-dropdownlist>
		</div>
		<div *ngIf='data.element.newFinish || finishMilestone?.value?.task_code !== data?.element.finishMilestone?.task_code' style='margin-top: 5px'>
			<kendo-checkbox #cascadeCheck [(ngModel)]="cascadeMilestone"></kendo-checkbox>
			<kendo-label
				class="k-checkbox-label"
				[for]="cascadeCheck"
				text="Apply to future milestones if unchanged"
			></kendo-label>
		</div>

		<div style='width: 100%'>
			<div style='font-weight: bold; margin-bottom: 10px; margin-top: 10px; padding-top: 10px'>Critical Path Notes</div>
			<textarea [(ngModel)]='criticalPathNotes' style='width: calc(100% - 6px);height: 120px; resize: none; font: 14px Muli, Helvetica Neue, Arial, sans-serif;' maxlength='3000'>
			</textarea>
		</div>
		<div style='width: 100%'>
			<div style='font-weight: bold; margin-bottom: 10px;; padding-top: 20px'>Time Analysis Notes</div>
			<textarea [(ngModel)]='timeAnalysisNotes' style='font: 14px Muli, Helvetica Neue, Arial, sans-serif; width: calc(100% - 6px);height: 120px; resize: none;' maxlength='3000'>
			</textarea>
		</div>
	</div>
	<div style='padding: 0 8px; display: block; min-height: 52px; box-sizing: content-box; margin-bottom: -24px;'>
		<button kendoButton style='background-color: #e04646; color: white; margin-top: 12px;' (click)="onNoClick()">CANCEL</button>
		<button kendoButton style='background-color: #2ebb2e; color: white; margin-top: 12px; float: right;'
						(click)="closeEvent.emit({baseline, costBaseline, finishMilestone: finishMilestone?.value, criticalPathNotes: criticalPathNotes, timeAnalysisNotes: timeAnalysisNotes, _id: data?.element?._id, hasChanges: isChanged(), ogUpdate, cascadeMilestone, fromFinishMilestone})">
			OK
		</button>
	</div>
</kendo-window>
<kendo-window
	title="Confirm action"
	*ngIf="confirmOpen"
	(close)="closeConfirm(false)"
	[minWidth]="250"
	[width]="450"
	[resizable]='false'
>
	<kendo-window-titlebar>
		<span class="k-window-title">Confirm action</span>
		<button kendoWindowCloseAction></button>
	</kendo-window-titlebar>
	<form class="k-form">
		<div>
			All future updates will be calculated based on this schedule.
		</div>
		<div style='font-weight: bold; padding: 12px 0;'>
			Please review the schedule to confirm the finish milestone constraint matches the expected contract completion date.
		</div>
		<div>
			Are you sure you want to continue?
		</div>

		<div class="k-actions k-actions-end">
			<button kendoButton type="button" (click)="closeConfirm(false)" style='background-color: #f44336; color: white;'>Cancel</button>
			<button
				kendoButton
				type="button"
				(click)="closeConfirm(true)"
				style='background-color: #2ebb2e; color: white;'
			>
				Confirm
			</button>
		</div>
	</form>
</kendo-window>
