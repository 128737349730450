<ng-container [formGroup]="user?.userType === 'saasRisk' ? projectDetailsSaasRisk : projectDetails">
	<kendo-formfield *ngIf='isEdit && user?.userType !== "saasRisk"'>
		<kendo-label [for]="scheduleType" text="Project Type"> </kendo-label>
		<kendo-dropdownlist
			#scheduleType
			[data]="['Active', 'Closed', 'Baseline', 'Baseline Development', 'Consulting']"
			[itemDisabled]='scheduleTypeDisabled'
			formControlName='scheduleType'>
			<ng-template kendoDropDownListItemTemplate let-dataItem>
				{{dataItem}} <span class='status-item-description'>{{' - ' + scheduleTypeItemDescriptors[dataItem]}}</span>
			</ng-template>
		</kendo-dropdownlist>
		<kendo-formerror [align]="'end'">Project Type is required</kendo-formerror>
	</kendo-formfield>
	<div class='same-row-grouping'>
		<kendo-formfield>
			<kendo-label [for]="projectCode" text="Project Code"></kendo-label>
			<kendo-autocomplete
				#projectCode
				[data]="projectKeys"
				formControlName="projectCode"
				[kendoDropDownFilter]="filterSettings"
				[clearButton]='true'
				(afterValueChanged)='autoFill($event)'
				[loading]='!projectService.allSQLProjects.length && (userService.user | async)?.userType === "aegis"'
			>
			</kendo-autocomplete>
			<kendo-formerror [align]="'end'">Project Code is required</kendo-formerror>
		</kendo-formfield>
		<kendo-formfield>
			<kendo-label [for]="projectName" text="Project Name"> </kendo-label>
			<kendo-textbox
				formControlName="projectName"
				#projectName
				required
				[clearButton]="true"
			>
			</kendo-textbox>
			<kendo-formerror [align]="'end'" style="margin-right: 1px;">Project Name is required</kendo-formerror>
		</kendo-formfield>
	</div>

	<div class='same-row-grouping'>
		<kendo-formfield>
			<kendo-label [for]="company" text="Company"> </kendo-label>
			<kendo-dropdowntree
				#company
				[kendoDropDownTreeHierarchyBinding]="companyHierarchy"
				childrenField="children"
				[textField]="'entry.name'"
				[valueField]="'entry.id'"
				[valuePrimitive]='true'
				[filterable]='true'
				[dataItem]='initialCompany'
				[loading]='!(userService.allCompanies | async)?.size'
				kendoDropDownTreeExpandable
				formControlName="company"
				[expandedKeys]="expandedNodes"
				[expandBy]="'entry.id'"
				[expandOnFilter]="{expandMatches: true}"
				[isNodeExpanded]="isNodeExpanded"
				(nodeExpand)="handleExpand($event)"
				(nodeCollapse)="handleCollapse($event)"
				(valueChange)='handleValueChange($event); filterForCompany($event)'
				(open)="open()"
			>
			</kendo-dropdowntree>
			<kendo-formerror [align]="'end'">Company is required</kendo-formerror>
		</kendo-formfield>

		<kendo-formfield style='width: calc(50% - 4px)'>
			<kendo-label [for]="pocId" text="Point of Contact"> </kendo-label>
			<kendo-combobox
				#pocId
				[data]="pocMembers"
				[suggest]="true"
				[kendoDropDownFilter]="{ operator: 'contains' }"
				formControlName="pocId"
				[textField]="'displayName'"
				[valueField]="'account.id'"
				[valuePrimitive]='true'
				(focus)="focus('pocDropdown')"
				[loading]='(userService.members | async)?.size === 0'
			></kendo-combobox>
			<kendo-formerror [align]="'end'" style="margin-right: 1px;">Point of Contact is required</kendo-formerror>
		</kendo-formfield>
	</div>
	<div class='same-row-grouping'>
		<kendo-formfield>
			<kendo-label [for]="client" text="Client"><kendo-svg-icon [icon]="icons.addIcon" style="margin: auto 0; cursor: pointer;" (click)="focusClientDropdown()"></kendo-svg-icon></kendo-label>
			<kendo-combobox
				#client
				[data]="filteredClients"
				[valueField]='"id"'
				[textField]='"name"'
				[valuePrimitive]='true'
				[filterable]="true"
				(filterChange)='handleClientFilter($event)'
				[loading]='!(userService.clients | async)?.size && userService.newCompanyNoClients === false'
				(focus)="focus('clientDropdown')"
				[allowCustom]='true'
				[valueNormalizer]="clientNormalizer"
				formControlName="clientId"
			>
				<ng-template kendoComboBoxFooterTemplate>
					<div class='footer-separator'>
						<p>
							New entries are saved for later use
						</p>
					</div>
				</ng-template>
			</kendo-combobox>
			<kendo-formerror [align]="'end'">Client is required</kendo-formerror>
		</kendo-formfield>

		<kendo-formfield style='width: calc(50% - 4px)'>
			<kendo-label [for]="projectType" text="Market"> </kendo-label>
			<kendo-combobox
				#projectType
				[data]="availableProjectTypes"
				[suggest]="true"
				[kendoDropDownFilter]="{ operator: 'contains' }"
				(focus)="focus('projectTypeDropdown')"
				formControlName="projectType"
				required
			></kendo-combobox>
			<kendo-formerror [align]="'end'" style="margin-right: 1px;">Market is required</kendo-formerror>
		</kendo-formfield>
	</div>
	<kendo-formfield>
		<kendo-label [for]="siteLocation" text="Site Location"> </kendo-label>
		<kendo-textbox
			formControlName="siteLocation"
			#siteLocation
			required
			[clearButton]="true"
		>
		</kendo-textbox>
		<kendo-formerror [align]="'end'" style="margin-right: 1px;">Site Location is required</kendo-formerror>
	</kendo-formfield>
	<kendo-formfield
		*ngIf='!isEdit'
		style="height: 51px;"
	>
		<kendo-label [for]="sharepoint" text="Project Files (Link)"> </kendo-label>
		<kendo-textbox
			formControlName="sharepoint"
			#sharepoint
			[clearButton]="true"
		>
		</kendo-textbox>
		<kendo-formerror [align]="'end'">Project Files (Link) is required</kendo-formerror>
	</kendo-formfield>
	<div
		*ngIf="showMilestones"
		class='fake-formfield'
	>
		<kendo-label text="Start Milestone"> </kendo-label>
		<kendo-textbox
			[clearButton]="false"
			[readonly]="true"
			[value]="startMilestone"
			[hidden]="scheduleStorage.isLoading"
			[disabled]='viewerDisabledMode'
			class='readonly-textbox'
		>
		</kendo-textbox>
		<kendo-textbox
			[hidden]="!scheduleStorage.isLoading"
			[clearButton]="false"
			[readonly]="true"
			[value]="loadingMessage"
			[disabled]='viewerDisabledMode'
		>
		</kendo-textbox>
	</div>
	<div
		*ngIf="showMilestones"
		class='fake-formfield'
	>
		<kendo-label text="Finish Milestone"> </kendo-label>
		<kendo-textbox
			[clearButton]="false"
			[readonly]="true"
			[value]="finishMilestone"
			[hidden]="scheduleStorage.isLoading"
			[disabled]='viewerDisabledMode'
			class='readonly-textbox'
		>
		</kendo-textbox>
		<kendo-textbox
			[hidden]="!scheduleStorage.isLoading"
			[clearButton]="false"
			[readonly]="true"
			[value]="loadingMessage"
		>
		</kendo-textbox>
	</div>

	<div *ngIf='!loadingEligibility && eligibilityData.total !== 0 && eligibilityData.usage >= eligibilityData.total && !isEdit' style='text-align: center; color: red'>
		You've reached the active project limit for this company's license.
	</div>

</ng-container>
