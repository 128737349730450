import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { ProjectDashboardService } from '../../../services/project/project.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { ScheduleStorageService } from '../../../services/project/schedule-storage.service';
import { MatDialog } from '@angular/material/dialog';
import {
	AuthorizationLevel,
	NavigationBarStorageService,
} from '../../../services/common/navigation-bar-storage.service';
import { PopoverController } from '@ionic/angular';
import { FileUploadService } from '../../../services/common/fileUpload.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../services/common/user.service';
import { AnalyticsDashboardService } from '../../../services/analytics/analytics.service';
import { UiStorageService } from '../../../services/common/ui-storage.service';
import { PopoverAnchorDirective, PopoverContainerDirective } from '@progress/kendo-angular-tooltip';
import { caretAltDownIcon, chevronLeftIcon, fileConfigIcon, SVGIcon } from '@progress/kendo-svg-icons';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NotificationService } from '../../../services/common/notification.service';
import { IconThemeColor } from '@progress/kendo-angular-icons';
import { RestService } from '../../../services/common/rest.service';
import { WindowRef, WindowService } from '@progress/kendo-angular-dialog';
import { AppWindowService } from '../../../services/common/window.service';
import { LicenseTier, ProfileCompanyPermission } from '../../../models/auth/account-user';
import { CostService } from '../../../services/common/cost.service';
import { GanttService } from '../../../services/project/gantt.service';
import {
	ProjectPageTab,
	projectPageTabs,
} from '../../../components/project-page/project-admin-settings/layout-settings/layout-settings.component';
import {
	allComponents,
	OverviewComponent,
} from '../../../components/project-page/project-admin-settings/overview-tab-settings/overview-tab-settings.component';

@Component({
	selector: 'rhinoworks-navigation-bar',
	templateUrl: './navigation-bar.component.html',
	styleUrls: ['./navigation-bar.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class NavigationBarComponent implements OnInit {
	public chevronLeft: SVGIcon = chevronLeftIcon;
	@ViewChild('content') content: HTMLDivElement;
	adminPanelExpanded = false;
	light: IconThemeColor = 'light';
	user: any = {
		userType: 'aegis',
	};
	$isRiskEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	updates = 0;
	private _unsubscribeAll = new Subject<void>();
	public icons = { caretAltDown: caretAltDownIcon, fileConfig: fileConfigIcon };
	scheduleNotOpen: boolean = true;
	userNotOpen: boolean = true;
	helpNotOpen: boolean = true;
	projectNotOpen: boolean = true;
	doingExportPost: boolean = false;

	currentProjectCompanyPermissions: ProfileCompanyPermission = null;
	showPopup = false;
	isValidCostProject = false;
	navAttempt: string = null;
	visibleTabs: string[] = [];
	segments: number = 0;

	@ViewChild('container', { static: false })
	private container: PopoverContainerDirective;

	@ViewChild('scheduleAnchor') schedulePopoverAnchor: PopoverAnchorDirective;
	@ViewChild('anchor') projectPopoverAnchor: PopoverAnchorDirective;
	@ViewChild('anchor1') newProjectAnchor: ElementRef;
	@ViewChild('anchor2') userPopoverAnchor: PopoverAnchorDirective;
	@ViewChild('anchor3') helpPopoverAnchor: PopoverAnchorDirective;

	@ViewChild('adminSettingsWindow') adminSettingsWindowRef: WindowRef;

	constructor(
		public auth: AuthenticationService,
		public _projectDashboardService: ProjectDashboardService,
		private scheduleStorageService: ScheduleStorageService,
		public dialog: MatDialog,
		private popoverController: PopoverController,
		public navBarStorage: NavigationBarStorageService,
		public fileUploadService: FileUploadService,
		private route: ActivatedRoute,
		public userService: UserService,
		public services: AnalyticsDashboardService,
		public ui: UiStorageService,
		private http: HttpClient,
		private notificationService: NotificationService,
		private restService: RestService,
		private windowService: WindowService,
		public appWindowService: AppWindowService,
		public cdr: ChangeDetectorRef,
		public analyticsService: AnalyticsDashboardService,
		public costService: CostService,
		public ganttService: GanttService
	) {}

	ngOnInit(): void {
		this._projectDashboardService.$currentProjectReport
			.pipe(takeUntil(this._unsubscribeAll), debounceTime(100))
			.subscribe((report) => {
				this.isValidCostProject = this.costService.isValidCostProject(
					this._projectDashboardService.$currentProjectReport.value?.cashFlowHistorical || null
				);
				const projectData = report?.project;
				if (projectData?.updateIds?.length) {
					this.updates = projectData?.updateIds?.length;
				}
				this.$isRiskEnabled.next(!!projectData?.riskPagePurchased);
			});
		this.route.queryParams.subscribe((params) => {
			if (params.tab) {
				this.navBarStorage.$tabPointer.next(params.tab);
			}
		});
		this.userService.user.subscribe((data) => {
			if (data) {
				this.user = data;
				let hasALicense: boolean = false;
				if (data?.profile?.companyPermissions) {
					let highestAuthorizationLevel: AuthorizationLevel = 'viewer';
					let highestLicenseTier: LicenseTier = 'ANALYTICS-BASIC-APK';
					for (const company of data.profile.companyPermissions) {
						let authorizationLevelOfThisCompany: AuthorizationLevel = 'viewer';
						if (company[1]?.admin === true && company[1]?.license !== null) {
							authorizationLevelOfThisCompany = 'admin';
							highestAuthorizationLevel = 'admin';
						} else if (company[1]?.admin === false && company[1]?.viewer === false && company[1]?.license !== null) {
							authorizationLevelOfThisCompany = 'regular joe';
							if (highestAuthorizationLevel === 'viewer') {
								highestAuthorizationLevel = 'regular joe';
							}
						}
						this.navBarStorage.companyToAuthorizationMap.set(company[0], authorizationLevelOfThisCompany);
						this.navBarStorage.companyPermissionMap.set(company[0], company[1]);
						if (company[1]?.license !== undefined && company[1]?.license !== null) {
							hasALicense = true;
						}
						if (
							company[1]?.viewer === false &&
							company[1]?.license !== undefined &&
							this.isHigher(highestLicenseTier, company[1]?.license)
						) {
							highestLicenseTier = company[1]?.license;
						}
					}
					this.navBarStorage._authorizationLevel = highestAuthorizationLevel;
					this.navBarStorage._highestCompanyTier = highestLicenseTier;
				}
				if (!hasALicense) {
					window.location.href = 'https://rhino.works/analytics/pricing';
				}
			}
		});
		this.navBarStorage.$tabPointer.pipe(takeUntil(this._unsubscribeAll), debounceTime(100)).subscribe((tab) => {
			this.closeAllWindows();
			const defaultTab = this.user?.userType === 'saasRisk' ? 'risk' : 'overview';
			const normalizedTab = tab === 'manage' || tab === 'analysis' || tab === 'gantt' ? 'schedules' : tab;
			this.navBarStorage.selectedTab = normalizedTab || defaultTab;
			this.navBarStorage.selectedTabSchedules = tab === 'analysis' || tab === 'gantt' ? tab : 'manage';
			const currentRoute = localStorage.getItem('currentRoute');
			if (currentRoute.substring(0, 8) === '/project') {
				const newRoute = currentRoute.split('?tab=')[0] + '?tab=' + tab;
				localStorage.setItem('currentRoute', newRoute);
			}
		});
		this.navBarStorage.$triggerNewProjectTooltip.subscribe((val) => {
			if (!this.navBarStorage.eulaOpened && this.navBarStorage._authorizationLevel !== 'viewer') {
				this.container?.show(this.newProjectAnchor);
			} else {
				this.container.hide();
			}
		});
		this._projectDashboardService.$currentProjectData.subscribe((data) => {
			this.currentProjectCompanyPermissions =
				data === undefined ? null : this.navBarStorage.companyPermissionMap.get(data?.company);
			const hiddenTabs: string[] = data?.hiddenTabs || [];
			const hiddenComponents: OverviewComponent[] = data?.hiddenComponents || [];
			const visibleTabs: string[] = [];
			let segmentCount: number = 0;
			projectPageTabs.tabs.forEach((tab: ProjectPageTab) => {
				if (!hiddenTabs.includes(tab.id)) {
					if (['progress', 'qc', 'cost', 'risk', 'analysis'].includes(tab.id)) {
						const componentsOnTab: OverviewComponent[] = allComponents.components.filter(
							(c: OverviewComponent) => c.tabOrigin === tab.id
						);
						const hiddenComponentsOnTab: OverviewComponent[] = hiddenComponents.filter(
							(c: OverviewComponent) => c.tabOrigin === tab.id
						);
						if (hiddenComponentsOnTab?.length === componentsOnTab?.length) {
							return;
						}
					}
					visibleTabs.push(tab.id);
					if (!tab?.parentTab || tab.id === 'manage') {
						segmentCount++;
					}
				}
			});
			this.visibleTabs = visibleTabs;
			this.segments = segmentCount;
		});
		this.analyticsService.unsavedScheduleChangesOpen.subscribe((val) => {
			if (val === false && this.navAttempt !== null) {
				this.closeAllWindows();
				this.navigateToTab(this.navAttempt);
			}
		});
	}

	segmentChanged(e: any) {
		if (e.detail.value === 'schedules') {
			this.scheduleNotOpen = false;
		}
		if (this.scheduleStorageService.unsavedChanges.size) {
			this.navAttempt = e;
			this.analyticsService.unsavedScheduleChangesOpen.next(true);
			this.appWindowService.setViewport('unsavedScheduleChanges');
			// this.appWindowService.$unsavedChangesHeight.next(221);
		} else {
			this.closeAllWindows();
			this.navigateToTab(e);
		}
	}

	/**
	 * compares new license tier to old one. true if new license tier is higher, false otherwise
	 * @param a
	 * @param b
	 */
	isHigher(a: LicenseTier, b: LicenseTier): boolean {
		const levelDict = {
			'ANALYTICS-BASIC-MPK': 0,
			'ANALYTICS-BASIC-APK': 0,
			'ANALYTICS-PRO-MPK': 1,
			'ANALYTICS-PRO-APK': 1,
			'ANALYTICS-CORP-MPK': 2,
			'ANALYTICS-CORP-APK': 2,
			'ANALYTICS-ENTERPRISE-MPK': 3,
			'ANALYTICS-ENTERPRISE-APK': 3,
		};
		const levelA: number = levelDict[a];
		const levelB: number = levelDict[b];
		return levelB > levelA;
	}

	navigateToTab(e) {
		document.body.scrollTo(0, 0);
		const schedulesTabOptions: string[] = ['manage', 'analysis', 'gantt'];
		const isSchedulesDropdown: boolean = schedulesTabOptions.includes(e.detail.value);
		const navFragment: string = e.detail.value === 'manage' ? 'schedules' : e.detail.value;
		if (isSchedulesDropdown) {
			this.navBarStorage.selectedTabSchedules = e.detail.value;
			this.navBarStorage.selectedTab = 'schedules';
			this.scheduleNotOpen = true;
			this.schedulePopoverAnchor.hide();
		} else {
			this.navBarStorage.selectedTab = e.detail.value;
		}
		const url = new URLSearchParams(new URL(window.location.href).search);
		url.set('tab', navFragment);
		history.pushState(null, '', window.location.pathname + '?' + url.toString());
		this.navBarStorage.$tabPointer.next(navFragment);
		this.navAttempt = null;
		window.scrollTo({ top: 0, behavior: 'instant' });
	}

	async openUpdateMenu() {
		this.appWindowService.showingAdminSettings = true;
		this.projectPopoverAnchor.hide();
		this.helpPopoverAnchor.hide();
		this.appWindowService.setViewport('adminSettings');
	}

	/**
	 * open project files in new tab
	 */
	openProjectFiles(): void {
		const url = this._projectDashboardService.$currentProjectData.value.sharePoint;
		if (url) {
			window.open(url, '_blank');
		}
		this.projectPopoverAnchor.hide();
	}

	loadUrl(works: string, newTab: boolean) {
		this.userPopoverAnchor.hide();
		if (newTab) {
			window.open(works, '_blank');
		} else {
			window.location.href = works;
		}
	}

	/**
	 * track admin panel open state
	 * @param ev
	 */
	onAction(ev): void {
		this.adminPanelExpanded = !this.adminPanelExpanded;
	}

	/**
	 * closes admin panel on item selection
	 */
	closeAdminPanel(): void {
		this.adminPanelExpanded = false;
		this.userPopoverAnchor.hide();
	}

	/**
	 * logs out the user
	 */
	doLogout(): void {
		localStorage.removeItem('currentUserCredentials');
		localStorage.removeItem('currentUserEmail');
		localStorage.removeItem('currentUser');
		localStorage.removeItem('currentRoute');
		localStorage.removeItem('previousBanner');
		window.location.href = 'https://account.rhino.works/logout/';
		localStorage.clear();
	}

	onBack() {
		this._projectDashboardService.$currentProjectData.next(undefined);
		this.services.tooltipType.next('');
		this.services.projectDetailsOpen.next(null);
		this.services.riskSettingsOpen.next(null);
		this.services.addUpdateOpen.next(null);
		this.services.riskCalcMethodOpen.next(null);
		this.services.editProfileOpen.next(false);
	}

	dismissPopup() {
		this.container.hide();
	}

	openProjectSettings() {
		this.navBarStorage.newProjectFormMaxHeight = Math.min(window.innerHeight - 132, 683);
		this.navBarStorage.showingEditProject = true;
		this.services.projectDetailsOpen.next(true);
	}

	openBulkUpdate() {
		if (this.user?.userType !== 'saasRisk') {
			this.navBarStorage.bulkUpdateFormMaxHeight = Math.min(window.innerHeight - 132, 750);
			this.services.addUpdateOpenSchedulesTab.next(true);
			this.appWindowService.setViewport('bulkUpdate');
			this.projectPopoverAnchor.hide();
		} else {
			this.navBarStorage.showingBaselineProject = true;
			this.navBarStorage.newProjectFormMaxHeight = Math.min(window.innerHeight - 132, 750);
			this.navBarStorage.showingNewProject = true;
			this.analyticsService.baselineOpen.next(true);
		}
	}

	openProfile() {
		this.services.editProfileOpen.next(true);
		this.userPopoverAnchor.hide();
	}

	doExportPublish() {
		if (this.doingExportPost) {
			return;
		}
		this.doingExportPost = true;
		this.http
			.post(
				'https://pdf-stage.services.analytics.rhinoworks.dev/render/' +
					// 	'http://localhost:4999/render/' +
					this.navBarStorage.$tabPointer.value +
					'/' +
					this._projectDashboardService.$currentProjectReport.value?.project._id +
					'/',
				btoa(
					JSON.stringify({
						currentUser: localStorage.getItem('currentUser'),
						currentUserEmail: localStorage.getItem('currentUserEmail'),
						currentUserCredentials: localStorage.getItem('currentUserCredentials'),
					})
				),
				{ responseType: 'blob', headers: new HttpHeaders().append('Content-Type', 'application/json') }
			)
			.subscribe({
				next: (r) => {
					saveAs(
						r,
						'AegisAnalyticsExecutiveExport-' +
							this._projectDashboardService.$currentProjectReport.value?.project._id +
							'.pdf'
					);
					this.doingExportPost = false;
					this.projectPopoverAnchor.hide();
				},
				error: (er) => {
					this.doingExportPost = false;
					this.projectPopoverAnchor.hide();
					this.notificationService.showNotification(er.message);
				},
			});
	}

	openingUser() {
		//needed to override the onclick behavior to allow anchor to toggle and clicking outside popover to close via showOn="click"
		setTimeout(() => {
			this.userNotOpen = true;
			this.userPopoverAnchor.hide();
		});
	}
	closingUser() {
		this.userNotOpen = true;
	}

	openingHelp() {
		//needed to override the onclick behavior to allow anchor to toggle and clicking outside popover to close via showOn="click"
		setTimeout(() => {
			this.helpNotOpen = true;
			this.helpPopoverAnchor.hide();
		});
	}
	closingHelp() {
		this.helpNotOpen = true;
	}

	openingProject() {
		//needed to override the onclick behavior to allow anchor to toggle and clicking outside popover to close via showOn="click"
		setTimeout(() => {
			this.projectNotOpen = true;
			this.projectPopoverAnchor.hide();
		});
	}
	closingProject() {
		this.projectNotOpen = true;
	}
	openingSchedule() {
		//needed to override the onclick behavior to allow anchor to toggle and clicking outside popover to close via showOn="click"
		setTimeout(() => {
			this.scheduleNotOpen = true;
			this.schedulePopoverAnchor.hide();
		});
	}
	closingSchedule() {
		this.scheduleNotOpen = true;
	}

	/**
	 * toggle visibility of popover based on anchor click, 1 open max
	 * @param anchor
	 */
	togglePopover(anchor: string): void {
		this[anchor].toggle();
		const isSchedulePopoverOpen = this.schedulePopoverAnchor?.popupRef !== undefined;
		const isProjectPopoverOpen = this.projectPopoverAnchor?.popupRef !== null;
		const isUserPopoverOpen = this.userPopoverAnchor?.popupRef !== null;
		const isHelpPopoverOpen: boolean = this.helpPopoverAnchor?.popupRef !== null;
		switch (anchor) {
			case 'projectPopoverAnchor': {
				this.projectNotOpen = !isProjectPopoverOpen;
				this.userNotOpen = true;
				this.userPopoverAnchor.hide();
				this.helpNotOpen = true;
				this.helpPopoverAnchor.hide();
				this.scheduleNotOpen = true;
				this.schedulePopoverAnchor.hide();
				break;
			}
			case 'schedulePopoverAnchor': {
				this.scheduleNotOpen = !isSchedulePopoverOpen;
				this.projectNotOpen = true;
				if (this.projectPopoverAnchor) {
					this.projectPopoverAnchor.hide();
				}
				this.helpNotOpen = true;
				this.helpPopoverAnchor.hide();
				this.userNotOpen = true;
				this.userPopoverAnchor.hide();
				this.styleSchedulePopover();
				break;
			}
			case 'userPopoverAnchor': {
				this.userNotOpen = !isUserPopoverOpen;
				this.projectNotOpen = true;
				if (this.projectPopoverAnchor) {
					this.projectPopoverAnchor.hide();
				}
				this.helpNotOpen = true;
				this.helpPopoverAnchor.hide();
				this.scheduleNotOpen = true;
				this.schedulePopoverAnchor.hide();
				break;
			}
			case 'helpPopoverAnchor': {
				this.helpNotOpen = !isHelpPopoverOpen;
				this.projectNotOpen = true;
				if (this.projectPopoverAnchor) {
					this.projectPopoverAnchor.hide();
				}
				this.userNotOpen = true;
				this.userPopoverAnchor.hide();
				this.scheduleNotOpen = true;
				this.schedulePopoverAnchor.hide();
				break;
			}
		}
	}

	/**
	 * styling the popover through here bc it will look different from the others and kendo doesn't want to let me throw a class or id onto the popup or popover elements
	 */
	styleSchedulePopover() {
		setTimeout(() => {
			const container = document.getElementsByClassName('schedules-popover-body-container');
			const popoverBodyDiv = container.item(0).parentElement;
			const popoverContainer = popoverBodyDiv.parentElement;
			popoverBodyDiv.style.padding = '0';
			popoverBodyDiv.style.backgroundColor = '#050C32';
			popoverBodyDiv.style.borderRadius = '8px';
			popoverBodyDiv.style.position = 'relative';
			popoverContainer.style.border = 'none';
			popoverContainer.style.borderRadius = '8px';
		});
	}

	openJiraFeedbackCollector(): void {
		const jiraBtn: HTMLElement = document.getElementById('atlwdg-trigger');
		if (jiraBtn) {
			jiraBtn.click();
		}
	}

	/**
	 * forces window to stay within bounds of viewport
	 * @param window
	 */
	restrictMovement(window: string): void {
		this.appWindowService.restrictMovement(window, this.cdr);
	}

	closeAllWindows(): void {
		this.analyticsService.tooltipType.next('');
		this.analyticsService.editProfileOpen.next(false);
		this.navBarStorage.reportWindowOpen = false;
		this.analyticsService.addUpdateOpenSchedulesTab.next(false);
		this.navBarStorage.showingNewProject = false;
		this.analyticsService.globalTooltipType.next('');
		this.appWindowService.$closeRiskRegisterWindows.next(true);
		this.appWindowService.$closeTopRowWindows.next(true);
		this.appWindowService.showingAdminSettings = false;
		this.ganttService.$currentUpdateGanttPresetWindowOpen.next(null);
		this.ganttService.unsavedChangesReport = false;
		this.analyticsService.closeAll();
	}

	/**
	 * toggle monte carlo component visibility and save that choice
	 */
	toggleMonteCarlo(): void {
		const currentValue = this._projectDashboardService.$currentProjectData.value.showMonteCarlo;
		this.navBarStorage.doingMonteCarloToggle = true;
		this.restService
			.post(`project/${this._projectDashboardService.$currentProjectReport.getValue().project?._id}`, {
				showMonteCarlo: !currentValue,
			})
			.subscribe(
				(val) => {},
				(response) => {
					console.log('POST call in error', response);
				},
				() => {}
			);
	}
}
