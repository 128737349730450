import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CurrentProjectReport, ProjectDashboardService } from '../../../../services/project/project.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { AxisSettings, GaugeColorSettings, SeriesData, SeriesDataSettings } from '../../../../models/ChartSettings';
import { PlotBand } from '@progress/kendo-angular-charts';
import { hasObjChanged } from '../../../../util/projects';
import { format } from 'date-fns';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { UpdateInterface } from '../../../../models/Update/Task';

@Component({
	selector: 'app-qc-trend',
	templateUrl: './qc-trend.component.html',
	styleUrls: ['./qc-trend.component.scss'],
})
export class QcTrendComponent implements OnInit, OnDestroy {
	@Input() isOverview: boolean = false;
	@Input() visualizer: boolean = false;
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	$qcScore = new BehaviorSubject<number>(0);
	qcColors: GaugeColorSettings[] = [
		{
			to: 79.999,
			color: '#DF5353',
		},
		{
			from: 80,
			to: 94.999,
			color: '#4fc931',
		},
		{
			from: 95,
			color: '#0059FF',
		},
	];
	public valuePlotBands: PlotBand[] = [
		{
			from: 0,
			to: 80,
			color: '#DF5353',
			opacity: 0.2,
		},
		{
			from: 80,
			to: 95,
			color: '#4fc931',
			opacity: 0.2,
		},
		{
			from: 95,
			to: 100,
			color: '#0059FF',
			opacity: 0.2,
		},
	];
	categories: string[] = [];
	seriesData: SeriesDataSettings[] = [];
	valueAxisItemSettings: AxisSettings[] = [
		{
			title: {
				text: 'Score',
				visible: true,
			},
			labels: {
				format: '{0}%',
			},
			plotBands: this.valuePlotBands,
			min: 0,
			max: 100,
			majorGridLines: {
				visible: false,
			},
		},
	];
	hasNotes: boolean = false;
	constructor(
		public projectService: ProjectDashboardService,
		public storage: ScheduleStorageService
	) {}
	ngOnInit(): void {
		this.projectService.$currentProjectReport
			.pipe(takeUntil(this._unsubscribeAll), debounceTime(100))
			.subscribe((report) => {
				if (report) {
					this.updateQCGraphs(report);
				}
			});
		this.storage.$allUpdates.subscribe((val: UpdateInterface[]) => {
			if (val) {
				// needs all updates loaded to be able to tell if there have been rebaselines
				this.updateQCGraphs(this.projectService.$currentProjectReport.value);
			}
		});
		this.projectService.$currentProjectData.subscribe((val) => {
			if (val) {
				const savedNotes = val.componentNotes?.find((n) => n.id === 9)?.notes;
				this.hasNotes = savedNotes?.length && savedNotes[savedNotes?.length - 1]?.note !== '';
			}
		});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	updateQCGraphs(report: CurrentProjectReport): void {
		if (!report) {
			return;
		}

		this.initCurrentQcTrendingChart(report);
		this.$qcScore.next(report?.qualityControl?.qcScore);
	}

	initCurrentQcTrendingChart(report: CurrentProjectReport): void {
		const qcTrending: number[] = report.qualityControl?.qcTrending || [0];
		const categories: string[] = [];
		const data: SeriesData[] = [];
		qcTrending.forEach((val, index) => {
			const dataDateFromUpdate: Date = new Date(
				this.storage.$allUpdates.value[index]?.dataDate ||
					report?.projectCompletionTrend?.projectCompletionTrendArray?.[index]?.dataDate
			);
			const dateString: string = dataDateFromUpdate ? format(dataDateFromUpdate, 'MMM dd, yyyy') : '';
			const updateName: string = index === 0 ? 'Baseline' : `Update ${index}`;
			const category: string =
				(dateString === '' ? updateName : updateName + '\n' + dateString) +
				(this.storage.$allUpdates.value[index]?.baseline ? ' ®' : '');
			categories.push(category);
			data.push({
				category,
				value: val,
			});
		});
		if (hasObjChanged(categories, this.categories)) {
			this.categories = categories;
		}
		const seriesData: SeriesDataSettings[] = [
			{
				type: 'line',
				data,
				name: 'QC Value',
				visible: true,
				color: 'black',
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
		];
		if (hasObjChanged(this.seriesData, seriesData)) {
			this.seriesData = seriesData;
		}
	}
}
