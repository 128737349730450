<ngx-datatable
  class="material"
  [rows]="rows"
  [loadingIndicator]="loadingIndicator"
  [columnMode]="'force'"
  [headerHeight]="48"
  [footerHeight]="0"
  [rowHeight]="'auto'"
  [scrollbarH]="true"
  [reorderable]="reorderable"
  [selectionType]="'checkbox'"
  [limit]="5"
>
  <ngx-datatable-column name="Schedule Name" prop="scheduleName"></ngx-datatable-column>
  <ngx-datatable-column name="Data Date" prop="dataDate"></ngx-datatable-column>
  <ngx-datatable-column name="Current Completion" prop="currentCompletion"></ngx-datatable-column>
  <ngx-datatable-column name="Delta Vs Prev" prop="deltaVsPrev"></ngx-datatable-column>
  <ngx-datatable-column name="Delta Vs CCD" prop="deltaVsCcd"></ngx-datatable-column>
  <ngx-datatable-column
    name="Critical Path Notes"
    prop="criticalPathNotes"
    [width]="300"
    [canAutoResize]="false"
  ></ngx-datatable-column>
  <ngx-datatable-column
    name="Time Analysis Notes"
    prop="timeAnalysisNotes"
    [width]="300"
    [canAutoResize]="false"
  ></ngx-datatable-column>
</ngx-datatable>
