import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityCompletionComponent } from './activity-completion.component';
import { IonicModule } from '@ionic/angular';
import { TooltipMenuModule } from '../../../portfolio/tooltip-menu/tooltip-menu.module';
import { PipeModule } from '../../../../util/pipes/pipe.module';
import { MatIconModule } from '@angular/material/icon';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { AegisChartModule } from '../../../shared/chart/chart.module';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { GridLayoutModule } from '@progress/kendo-angular-layout';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { IconsModule } from '@progress/kendo-angular-icons';
import { OverviewNotesModule } from '../../../shared/overview-notes/overview-notes.module';
import { TreeListModule } from '@progress/kendo-angular-treelist';

@NgModule({
	declarations: [ActivityCompletionComponent],
	imports: [
		CommonModule,
		IonicModule,
		TooltipMenuModule,
		PipeModule,
		MatIconModule,
		ButtonsModule,
		AegisChartModule,
		GridModule,
		TextBoxModule,
		LabelModule,
		GridLayoutModule,
		ExcelExportModule,
		ExcelModule,
		DropDownsModule,
		TooltipModule,
		IconsModule,
		OverviewNotesModule,
		TreeListModule,
	],
	exports: [ActivityCompletionComponent],
})
export class ActivityCompletionModule {}
