import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CriticalPathRiskComponent } from './risk-critical-path-risk/critical-path-risk.component';
import { IonicModule } from '@ionic/angular';
import { TooltipMenuModule } from '../../../portfolio/tooltip-menu/tooltip-menu.module';
import { HighchartsChartModule } from 'highcharts-angular';
import { GaugeModule } from '../../../shared/gauge/gauge.module';
import { GridLayoutModule } from '@progress/kendo-angular-layout';
import { AegisChartModule } from '../../../shared/chart/chart.module';
import { OverviewNotesModule } from '../../../shared/overview-notes/overview-notes.module';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

@NgModule({
	declarations: [CriticalPathRiskComponent],
	imports: [
		CommonModule,
		IonicModule,
		TooltipMenuModule,
		HighchartsChartModule,
		GaugeModule,
		GridLayoutModule,
		AegisChartModule,
		OverviewNotesModule,
		ButtonModule,
		TooltipModule,
	],
	exports: [CriticalPathRiskComponent],
})
export class RiskCriticalPathRiskModule {}
