<div class='card-header-container' style='padding: 0.7em 11px' *ngIf='visualizer'>
	<div class="card-header" [class.focusTabFontSizing]="isFocus">
		Quality Control Review
	</div>
</div>

<div class='card' [style]='isFocus ? "" : "page-break-inside: avoid;"' id="scheduleAnalysisComponent">
	<kendo-gridlayout
		[rows]="visualizer ? ['662.56px'] : small ? ['42.4px', '437.4px'] : isFocus ? ['32px', '668px'] : ['42.4px', project.$currentProjectData.value?.qualityPreferences?.useDCMA ? '562.56' : '708px']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='full-width full-height'
		[style.grid-template-rows]="isFocus ? '32px auto' : '42.4px auto'"
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' *ngIf='!visualizer' [class.overviewHeader]="isOverview">
			<kendo-gridlayout
				[rows]="[isFocus ? '32px' : '42.4px']"
				[cols]="['300px', 'calc(100% - 305px)']"
				[gap]="{ rows: 0, cols: 5 }"
				class='full-width full-height'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
					<div class="card-header" [class.focusTabFontSizing]="isFocus">
						Quality Control Review
					</div>
					<tooltip-open type='{{isOverview ? "overview" : "schedule-analysis"}}' style='padding-left: 3px;' [fillColor]="isOverview ? 'overview' : ''"></tooltip-open>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2' class='toolbar-item-container' style="margin: auto; height: min-content; justify-content: end">
					@if (!small) {
						<span style='display: inline-flex; align-items: center; height: min-content;' kendoTooltip *ngIf='currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-APK"' [style.margin-right]="isOverview ? '0' : '10px'">
							<button
								kendoButton
								(click)="doExport($event)"
								*ngIf="(project.$expandedMetrics | async) && !isExportRunning"
								[disabled]="isExportRunning"
								title='Export Report'
								themeColor='base'
								[style.background-color]="isOverview ? '#ffb500' : 'white'"
								class="header-btn"
								[imageUrl]='"/assets/icons/newIcons/excelExport.svg"'
								[class.focusTabHeaderSizing]="isFocus"
							>
							</button>
							<button
								kendoButton
								*ngIf="(project.$expandedMetrics | async) && isExportRunning"
								title='Exporting...'
								themeColor='base'
								class="header-btn"
								[style.background-color]="isOverview ? '#ffb500' : 'white'"
								style='padding-top: 2px; padding-bottom: 5px;'
								[class.focusTabHeaderSizing]="isFocus"
							>
								<kendo-circularprogressbar
									[indeterminate]="true"
									[value]="30"
									[progressColor]='"black"'
									id='schedule-analysis-excel-export-spin'
								></kendo-circularprogressbar>
							</button>
						</span>
						<div kendoTooltip style="text-align: center; margin-right: 10px;" *ngIf='currentProjectCompanyPermissions?.license === "ANALYTICS-BASIC-MPK" || currentProjectCompanyPermissions?.license === "ANALYTICS-BASIC-APK"'>
							<div title='Upgrade to a Professional License to enable Exports' style='margin-top: 9px; margin-right: 4px;'>
								<button kendoButton themeColor="base" class='header-btn' [imageUrl]='"/assets/icons/newIcons/excelExport.svg"' [disabled]='true' [style.background-color]="isOverview ? '#ffb500' : 'white'" [class.focusTabHeaderSizing]="isFocus"></button>
							</div>
						</div>
					}
					<span style='display: inline-flex; align-items: center;float: right; gap: 10px;' kendoTooltip>
						@if (isOverview) {
							<button kendoButton themeColor="base" title="Go to source" [imageUrl]="'assets/icons/newIcons/noun-arrow-5544651.svg'" class='header-btn' (click)='project.goTo(10)' style="background-color: #ffb500; margin-left: 10px;"></button>
						}
						@if (!isFocus) {
							<button kendoButton themeColor="base" title="Add/Edit Notes" [imageUrl]="'assets/icons/newIcons/noun-notepad-6607257.svg'" class='header-btn overview-notes-icon' (click)='project.openNotesWindow({id: 10, name: "Quality Control Review"})' [style.background-color]="isOverview ? '#ffb500' : 'white'" style="margin-right: 10px"></button>
						}
					</span>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1' *ngIf="(project.$expandedMetrics | async) === null" [style]='isFocus ? "" : "page-break-inside: avoid;"'>
			<ion-progress-bar type="indeterminate"></ion-progress-bar>
			<p class="ion-text-center"></p>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='visualizer ? 1 : 2' [col]='1' *ngIf="(project.$expandedMetrics | async)" style="height: min-content;">
			<kendo-gridlayout
				[rows]="small ? ['437.4px'] : [isFocus ? '668px' : project.$currentProjectData.value?.qualityPreferences?.useDCMA ? '562.56' : 'auto']"
				[cols]="visualizer ? ['100%'] : ['minmax(530px, calc((400% / 12) - 2.5px))', 'calc((800% / 12) - 2.5px)']"
				[gap]="{ rows: 0, cols: 5 }"
			>
				<kendo-gridlayout-item [row]='1' [col]='1'>
					<kendo-gridlayout
						[gap]="{ rows: 5, cols: 0 }"
					>
						@if (!small) {
							<kendo-gridlayout-item [row]='1' [col]='1'>
								<kendo-gridlayout
									[rows]="['calc((100% - 25px) / 6)', 'calc((100% - 25px) / 6)', 'calc((100% - 25px) / 6)', 'calc((100% - 25px) / 6)', 'calc((100% - 25px) / 6)', 'calc((100% - 25px) / 6)']"
									[cols]="['1fr', '1fr']"
									[gap]="5"
									style='padding: 5px 0 0 5px;'
									[style.height]="project.$currentProjectData.value?.qualityPreferences?.useDCMA ? '552px' : '235px'"
								>
									<kendo-gridlayout-item [row]='1' [col]='1' class='grid-btn-container'>
										<div [class.selected-button]="currentDisplayButton === 'totalActivities'" (click)="loadNewDisplaySet('totalActivities')" class='grid-btn'>
											<div>Total Activities: {{ allTasksArray?.filter(fn2).length | number:'1.0-0'}}</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='2' class='grid-btn-container'>
										<div [class.selected-button]="currentDisplayButton === 'totalRelationships'" (click)="loadNewDisplaySet('totalRelationships')" class='grid-btn'>
											<div>Total Relationships: {{ expandedMetrics.value?.totalRelationships?.length | number:'1.0-0'}}</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='2' [col]='1' class='grid-btn-container'>
										<div [class.selected-button]="currentDisplayButton === 'remainingActivities'" (click)="loadNewDisplaySet('remainingActivities')" class='grid-btn'>
											<div>Remaining Activities: {{ remainingActivities.size | number:'1.0-0'}}</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='2' [col]='2' class='grid-btn-container'>
										<div [class.selected-button]="currentDisplayButton === 'fs'" (click)="loadNewDisplaySet('fs')" class='grid-btn'>
											<div><img [src]="'/assets/ui/gantt/PR_FS' + (currentDisplayButton === 'fs' ? '_white' : '' ) + '.png'"> FS: {{ (this.$fs | async) | number: '1.0-1'}}%</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='3' [col]='1' class='grid-btn-container'>
										<div [class.selected-button]="currentDisplayButton === 'Tasks'" (click)="loadNewDisplaySet('Tasks')" class='grid-btn'>
											<div><img [src]="'/assets/ui/activity_type/TT_Task' + (currentDisplayButton === 'Tasks' ? '_white' : '') + '.png'"> Tasks: {{allTasksArray?.filter(fn).length | number:'1.0-0'}}</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='3' [col]='2' class='grid-btn-container'>
										<div [class.selected-button]="currentDisplayButton === 'ss'" (click)="loadNewDisplaySet('ss')" class='grid-btn'>
											<div><img [src]="'/assets/ui/gantt/PR_SS' + (currentDisplayButton === 'ss' ? '_white' : '' ) + '.png'"> SS: {{ (this.$ss | async) | number: '1.0-1'}}%</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='4' [col]='1' class='grid-btn-container'>
										<div [class.selected-button]="currentDisplayButton === 'Milestones'" (click)="loadNewDisplaySet('Milestones')" class='grid-btn'>
											<div><img [src]="'/assets/ui/activity_type/TT_Mile' + (currentDisplayButton === 'Milestones' ? '_white' : '') + '.png'"> Milestones: {{(this.expandedMetrics | async)?.milestones?.length | number:'1.0-0'}}</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='4' [col]='2' class='grid-btn-container'>
										<div [class.selected-button]="currentDisplayButton === 'ff'" (click)="loadNewDisplaySet('ff')" class='grid-btn'>
											<div><img [src]="'/assets/ui/gantt/PR_FF' + (currentDisplayButton === 'ff' ? '_white' : '' ) + '.png'"> FF: {{(this.$ff | async) | number: '1.0-1'}}%</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='5' [col]='1' class='grid-btn-container'>
										<div [class.selected-button]="currentDisplayButton === 'LOES'" (click)="loadNewDisplaySet('LOES')" class='grid-btn'>
											<div><img [src]="'/assets/ui/activity_type/TT_LOE' + (currentDisplayButton === 'LOES' ? '_white' : '') + '.png'"> LOE's: {{ loes.size | number:'1.0-0'}}</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='5' [col]='2' class='grid-btn-container'>
										<div [class.selected-button]="currentDisplayButton === 'sf'" (click)="loadNewDisplaySet('sf')" class='grid-btn'>
											<div><img [src]="'/assets/ui/gantt/PR_SF' + (currentDisplayButton === 'sf' ? '_white' : '' ) + '.png'"> SF: {{ (this.$sf | async) | number: '1.0-1'}}%</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='6' [col]='1' class='grid-btn-container'>
										<div [class.selected-button]="currentDisplayButton === 'redundantLogic'" (click)="loadNewDisplaySet('redundantLogic')" class='grid-btn'>
											<div>Redundant Logic: {{ redundantLogic.size | number:'1.0-0' }}</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='6' [col]='2' class='grid-btn-container'>
										<div [class.selected-button]="currentDisplayButton === 'multipleTies'" (click)="loadNewDisplaySet('multipleTies')" class='grid-btn'>
											<div>Multiple Ties: {{ multipleTies.size | number:'1.0-0'}}</div>
										</div>
									</kendo-gridlayout-item>
								</kendo-gridlayout>
							</kendo-gridlayout-item>
						}
						@if (!project.$currentProjectData.value?.qualityPreferences?.useDCMA) {
							<kendo-gridlayout-item [row]='small ? 1 : 2' [col]='1' [style.padding]='small ? "5px 0 5px 5px" : "0 0 5px 5px"'>
								<kendo-gridlayout
									[rows]="['25px', '1fr']"
									[cols]="['calc(70% - (5px / 3))', 'calc(15% - (5px / 3))', 'calc(15% - (5px / 3))']"
									[gap]="{ rows: 0, cols: 0 }"
									class='warning-bottom-section-container'
								>
									<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' style='background: transparent; font-weight: 600;'>
										<div class="card-header">
											Potential Schedule Issues
										</div>
										<tooltip-open type="prob-activities"></tooltip-open>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='2' class='card-header-container-score-impact' style='background: transparent; font-weight: 600;'>
										<div class="card-header">
											Count
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='3' class='card-header-container-score-impact' style='background: transparent; font-weight: 600;'>
										<div class="card-header">
											QC Impact
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="3">
										<kendo-gridlayout
											[rows]="[
											'calc((100% - 60px) / 13)',
											'calc((100% - 60px) / 13)',
											'calc((100% - 60px) / 13)',
											'calc((100% - 60px) / 13)',
											'calc((100% - 60px) / 13)',
											'calc((100% - 60px) / 13)',
											'calc((100% - 60px) / 13)',
											'calc((100% - 60px) / 13)',
											'calc((100% - 60px) / 13)',
											'calc((100% - 60px) / 13)',
											'calc((100% - 60px) / 13)',
											'calc((100% - 60px) / 13)',
											'calc((100% - 60px) / 13)'
										]"

											[cols]="['calc(70% - (5px / 3))', 'calc(15% - (5px / 3))', 'calc(15% - (5px / 3))']"
											[gap]="5"
											style='padding: 5px 0 0 5px; height: 388px'
										>
											@for (row of sortedRows; track row.name; let idx = $index) {
												<kendo-gridlayout-item [row]='idx + 1' [col]='1' class='grid-btn-container'>
													<div [class.selected-button-issues]="currentDisplayButton === row.label" (click)="loadNewDisplaySet(row.label)" class='grid-btn-label'>
														<div>{{row.name}}</div>
													</div>
												</kendo-gridlayout-item>
												<kendo-gridlayout-item [row]='idx + 1' [col]='2' class='grid-btn-container'>
													<div [class.selected-button-issues]="currentDisplayButton === row.label" (click)="loadNewDisplaySet(row.label)" class='grid-btn'>
														@if (row.name === 'Logic Density') {
															<div>{{ row.count | number:'1.0-1'}}</div>
														} @else {
															<div>{{ row.count | number:'1.0-0'}}</div>
														}
													</div>
												</kendo-gridlayout-item>
												<kendo-gridlayout-item [row]='idx + 1' [col]='3' class='grid-btn-container'>
													<div class="grid-btn-score-impact" [ngStyle]="{'color': row.scoreImpact < 0 ? scoreImpactRedColor : scoreImpactGreenColor}" (click)="loadNewDisplaySet(row.label)" [class.selected-button-issues]="currentDisplayButton === row.label">
														<div>{{row.scoreImpact < 0 ? (row.scoreImpact | number:'1.0-1') + '%' : '-'}}</div>
													</div>
												</kendo-gridlayout-item>
											}
										</kendo-gridlayout>
									</kendo-gridlayout-item>
								</kendo-gridlayout>
							</kendo-gridlayout-item>
						}
					</kendo-gridlayout>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item *ngIf='!visualizer' [row]='1' [col]='2' class='task-table'>
					@if (!small || (small && isProblematicScheduleActivitiesBtn)) {
						<div style="padding-right: 5px;">
							<kendo-textbox
								class='searchInput'
								[placeholder]="scheduleAnalysisSearchBarString"
								[clearButton]="true"
								[size]='"large"'
								[(value)]='searchTerm'
								(valueChange)='updateFilter({searchTerm: $event})'
								[disabled]='loading'
								style="margin-top: 5px;"
							>
								<ng-template kendoTextBoxPrefixTemplate>
									<kendo-svgicon [icon]='svgSearch'></kendo-svgicon>
									<kendo-textbox-separator></kendo-textbox-separator>
								</ng-template>
							</kendo-textbox>
							<div style="text-align: center; font-size: large; padding: 5px 0;">
								<span>{{ tableTitle }} - Showing {{ currentDisplaySet?.length || 0 }} of {{ unfilteredDisplaySet?.length || 0 }}</span>
							</div>
							<kendo-grid
								#grid
								[data]="gridView"
								[kendoGridBinding]="gridData"
								[height]="small ? 363 : isFocus ? 591 : project.$currentProjectData.value?.qualityPreferences?.useDCMA ? 488 : 604"
								[rowHeight]='40'
								[loading]='loading'
								[size]='"small"'
								[sortable]='true'
								[sort]="sort"
								(sortChange)="sortChange($event)"
								[filter]='filter'
								(filterChange)="filterChange($event)"
								(pageChange)="pageChange($event)"
								[skip]="skip"
								[pageSize]="pageSize"
								scrollable="virtual"
								[navigable]="false"
								class='sched-grid'
							>
								<kendo-grid-column
									*ngFor='let column of selectedColumns'
									[field]="column.field"
									[title]="column.title"
									[width]='column.width'
									[format]="column?.format"
									headerClass="header-class"
								>
									<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "string"'>
										{{ dataItem[column.field] }}
									</ng-template>
									<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "img"'>
										<img [src]="column.id === 25 ? '/assets/ui/gantt/' + dataItem.pred_type + '.png' : '/assets/ui/activity_type/' + dataItem.task_type + '.png'" style='vertical-align: -1.5px'/>
										{{ column.id === 0 ? dataItem.task_code : column.id === 20 ? taskTypeDictionary[dataItem.task_type] : linkTypeDictionary[dataItem.pred_type] }}
									</ng-template>
									<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "date"'>
										{{ (dataItem[column.field] | date: 'MM/dd/yyyy') || 'N/A' }}
									</ng-template>
									<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "actIfPossible"'>
										{{ dataItem[column.otherField] === null ? ((dataItem[column.field] | date: 'MM/dd/yyyy') + ' A') : dataItem[column.otherField] ? (dataItem[column.field] | date: 'MM/dd/yyyy') : 'N/A' }}
									</ng-template>
									<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "hours"'>
										{{ dataItem[column.field] | floor }}
									</ng-template>
									<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "calendar"'>
										{{ dataItem?.calendar || 'N/A' }}
									</ng-template>
									<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "status"'>
										{{ tableDataType === 'Relationship' ? taskStatusDictionary[allActivities.get(+dataItem.task_id)?.status_code || allActivities.get(+dataItem.pred_task_id)?.status_code] : taskStatusDictionary[dataItem.status_code] }}
									</ng-template>
									<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "missing"'>
										Missing {{(expandedMetrics | async)?.missingSuccessors?.includes(dataItem) ? 'Successor' : 'Predecessor'}}
									</ng-template>
									<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "constraint"'>
										{{ constraintDict[dataItem[column.field]] || 'N/A' }}
									</ng-template>
									<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "lag"'>
										{{ dataItem.lagHrs | floor }}
									</ng-template>
								</kendo-grid-column>
							</kendo-grid>
						</div>
					}
					@if (small && !isProblematicScheduleActivitiesBtn) {
						<div class="warning-div" style='height: 100%; background: url("https://consultaegis.com/wp-content/uploads/2022/12/iStock-600667654-1-1440x810.jpg") no-repeat center center; background-size: cover; border-radius: 0 0 4px 0;'>
							<div style="background: rgba(0,19,120,0.78); height: 100%; justify-content: center; align-items: center; display: flex; color: white; width: 100%; border-radius: 0 0 4px 0;">
								Select an issue type to view the activities contained
							</div>
						</div>
					}
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
		@if (isOverview && hasNotes) {
			<app-overview-notes [componentId]="10"></app-overview-notes>
		}
	</kendo-gridlayout>
</div>
