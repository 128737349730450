import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddBulkUpdateComponent } from './add-bulk-update/add-bulk-update.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ComboBoxModule, DropDownListModule, DropDownTreesModule } from '@progress/kendo-angular-dropdowns';
import { WindowModule } from '@progress/kendo-angular-dialog';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { ButtonGroupModule, ButtonModule } from '@progress/kendo-angular-buttons';
import { FormFieldModule, SwitchModule, TextAreaModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { PipeModule } from '../../../../util/pipes/pipe.module';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { TabStripModule } from '@progress/kendo-angular-layout';
import { DbSyncTabModule } from '../../db-sync-tab/db-sync-tab.module';

@NgModule({
	declarations: [AddBulkUpdateComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		IonicModule,
		DropDownListModule,
		WindowModule,
		LoaderModule,
		ButtonModule,
		FormFieldModule,
		LabelModule,
		TextBoxModule,
		TextAreaModule,
		PipeModule,
		SwitchModule,
		ProgressBarModule,
		TooltipModule,
		ButtonGroupModule,
		ComboBoxModule,
		DropDownTreesModule,
		TabStripModule,
		DbSyncTabModule,
	],
	exports: [AddBulkUpdateComponent],
})
export class AddBulkUpdateModule {}
