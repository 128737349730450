import {
	Component,
	Input,
	OnInit,
	OnDestroy,
	ViewEncapsulation,
	ChangeDetectorRef,
	Output,
	EventEmitter,
	ViewChild,
	AfterViewInit,
	NgZone,
} from '@angular/core';
import { PFTableValues } from '../performance-factor.component';
import { MatTableDataSource } from '@angular/material/table';
import { buildPfTable, BuiltPfTable } from '../../../../../../util/projects';
import { RestService } from '../../../../../../services/common/rest.service';
import { ProjectDashboardService } from '../../../../../../services/project/project.service';
import { BehaviorSubject, interval, Observable, of, Subject, switchMap, takeWhile } from 'rxjs';
import { delay, filter, retryWhen, take, takeUntil } from 'rxjs/operators';
import { ProjectReportInterface } from '../../../../../../models/ProjectReport/ProjectReport';
import { CurrentUpdateGanttPreset, ProjectInterface } from '../../../../../../models/Project';
import { ScheduleStorageService } from '../../../../../../services/project/schedule-storage.service';
import { ActivityTypeInterface, IActivityCode, Xer, XerActivityCode, XerActivityType } from '@rhinoworks/xer-parse';
import { ActivityCode } from '../../../../../../services/project/update/pre-test-models';
import { GridComponent, RowClassArgs, SelectableSettings, SelectionEvent } from '@progress/kendo-angular-grid';
import { SelectionItem, SelectionChangeEvent, ExpandEvent } from '@progress/kendo-angular-treelist';
import { ReportGroup } from '../../../../../../services/project/gantt.service';
import { UpdateInterface } from '../../../../../../models/Update/Task';

@Component({
	selector: 'app-pf-table-dropdown',
	templateUrl: './pf-table-dropdown.component.html',
	styleUrls: ['./pf-table-dropdown.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class PfTableDropdownComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild(GridComponent)
	public grid: GridComponent;
	currentVariances: [number, number] = [0, 0];
	@Input() fromPerformanceTrendingComponent: boolean = false;
	@Input() resetFilterClicked = new BehaviorSubject<boolean>(false);
	@Input() saveClicked = new BehaviorSubject<boolean>(false);
	@Input() submitFilterClicked = new BehaviorSubject<boolean>(false);
	@Output() cancelEnabledChange = new EventEmitter<boolean>();
	@Output() pfTableChange = new EventEmitter<any>();
	@Output() selectedActivityCodesEmitter = new EventEmitter<Array<string>>();
	@Output() activityCodeDropDownNameChange = new EventEmitter<string>();
	@Output() parentFromActivityCode = new EventEmitter<Map<string, PFTableValues>>();
	@Output() noActivityCodeTypes = new EventEmitter<boolean>();
	@Output() noSelections = new EventEmitter<boolean>();
	selectedCodeTypeName: string = null;
	pfTable: BuiltPfTable = {
		pfRows: [],
		selectedActivities: new Set([]),
		unselectedActivities: new Set([]),
	};
	nestedPfTable: Array<
		Partial<PFTableValues & SelectionItem> & {
			children: Array<PFTableValues & SelectionItem>;
		}
	>;
	defaultDropdownObject: PFTableValues = {
		activityCode: 'Select Activity Code Type...',
		activityCodeId: null,
		description: null,
		isSelected: null,
		numStarted: null,
		pf: null,
		shortName: null,
		typeId: null,
	};
	childrenFromPfTable: Map<number, PFTableValues[]>;
	activityCodeDropdownOptions: PFTableValues[];
	tablesCurrentCodes: PFTableValues[] = undefined;
	selectedItems: Array<number> = [];
	allChildren: SelectionItem[] = [];
	expandedKeys = [];
	displayedColumns = ['select', 'activityCode', 'description', 'pf'];
	parentCodeByCode = new Map<number, number>();
	parentFullCodeByCode = new Map<number, PFTableValues>();
	parentFullCodeByName = new Map<string, PFTableValues>();
	codesByType = new Map<number, Set<number>>([]);
	fullCodesByType = new Map<number, XerActivityCode[]>([]);
	savedSelectedActivityCodeType: PFTableValues;
	selectedActivityCodes: Array<number> = [];
	eligibleCodes = new Set<string>([]);
	savedPerformanceTrendingSelectedActivityCodes: string[] = null;

	httpProcessing: boolean = false;
	panelOpenState: boolean = true;
	cancelEnabled = false;
	recalcEnabled = false;
	selectedActivityCodesName: Array<string> = [];
	selectableSettings = {
		checkboxOnly: true,
	};
	_unsubscribeAll = new Subject<void>();
	public settings: SelectableSettings = {
		mode: 'single',
		drag: false,
	};
	constructor(
		private _projectDashboardService: ProjectDashboardService,
		private restService: RestService,
		public scheduleService: ScheduleStorageService,
		private changeDetectorRef: ChangeDetectorRef,
		private ngZone: NgZone
	) {}

	ngAfterViewInit(): void {
		this.fitColumns();
	}

	ngOnInit(): void {
		this._projectDashboardService.$currentProjectData.subscribe((projectData) => {
			if (projectData !== undefined && this.fromPerformanceTrendingComponent) {
				console.log('projectData saved performance trending activity codes', projectData);
				this.savedPerformanceTrendingSelectedActivityCodes = projectData.performanceTrendingSelectedActivityCodes;
				this.selectedCodeTypeName = projectData.performanceTrendingSelectedCodeType;
			}
		});
		this._projectDashboardService.$currentProjectReport.pipe(takeUntil(this._unsubscribeAll)).subscribe((report) => {
			if (this.fromPerformanceTrendingComponent) {
				if (
					!report?.project ||
					!report?.pfTableHistorical ||
					!this?._projectDashboardService?.$currentProjectData?.value
				) {
					return;
				}
			} else {
				if (
					!report?.project ||
					!report?.pfTableHistorical ||
					!report?.riskPage?.performanceFactor?.selectedActivityCodes
				) {
					return;
				}
			}

			console.log('passed return');
			this.pfTable = buildPfTable(
				report?.pfTableHistorical,
				this.fromPerformanceTrendingComponent,
				this?._projectDashboardService?.$currentProjectData?.value?.performanceTrendingSelectedActivityCodes,
				report?.riskPage?.performanceFactor?.selectedActivityCodes
			);
			this.pfTableChange.emit(this.pfTable);
			const lowerVariance = report.project?.preferences?.monteCarlo?.pfVariance?.[0] ?? 0.1;
			const upperVariance = report.project?.preferences?.monteCarlo?.pfVariance?.[1] ?? 0.1;
			if (
				this.currentVariances &&
				(lowerVariance !== this.currentVariances[0] || upperVariance !== this.currentVariances[1])
			) {
				this.recalcEnabled = true;
			}
			this.currentVariances = report?.project.preferences?.monteCarlo?.pfVariance;
		});
		let updatesArray: UpdateInterface[] = [];
		let processCompleted = false; // Flag to track if the process has completed

		this.scheduleService.$allUpdates
			.pipe(
				takeUntil(this._unsubscribeAll),
				switchMap((updates) => {
					return interval(1000).pipe(
						takeWhile(() => !processCompleted),
						switchMap(() => {
							const expectedLength = this._projectDashboardService?.$currentProjectReport?.value?.updateIds?.length;

							if (!processCompleted && expectedLength && Array.isArray(updates) && updates.length === expectedLength) {
								updatesArray = updates; // Save updates when lengths match
								return of(updatesArray);
							}

							return of([]); // Keep retrying until lengths match
						})
					);
				}),
				filter((updates) => updates.length > 0) // Proceed only when we have valid updates
			)
			.subscribe(async () => {
				const expectedLength = this._projectDashboardService?.$currentProjectReport?.value?.updateIds?.length;

				if (updatesArray.length !== expectedLength) {
					return;
				}

				processCompleted = true;
				const latestUpdate = updatesArray[updatesArray.length - 1];
				const actvCodes = await this.scheduleService.grabUpdateTable<XerActivityCode>(latestUpdate._id, 'ACTVCODE');
				for (const row of this.pfTable.pfRows) {
					const xerCode = actvCodes.find((c) => c.short_name === row.shortName && c.actv_code_name === row.description);
					if (xerCode) {
						this.eligibleCodes.add(xerCode.short_name);
					}
				}
				for (const code of actvCodes) {
					const item = this.pfTable.pfRows.find((row) => row.shortName === code.short_name);
					if (item) {
						item.typeId = code.actv_code_type_id;
					}
				}
				const actvTypes = await this.scheduleService.grabUpdateTable<XerActivityType>(latestUpdate._id, 'ACTVTYPE');
				const activityCodesByType = new Map<number, XerActivityCode[]>(
					actvTypes.map((actvType) => [
						actvType.actv_code_type_id,
						actvCodes.filter(
							(code) =>
								code.actv_code_type_id === actvType.actv_code_type_id &&
								this.pfTable.pfRows.some((pf) => pf.activityCodeId === code.actv_code_id)
						),
					])
				);
				this.fullCodesByType = activityCodesByType;

				for (const [typeId, codes] of activityCodesByType.entries()) {
					this.codesByType.set(typeId, new Set(codes.map((code) => code.actv_code_id)));
				}
				this.nestedPfTable = [...activityCodesByType.entries()]
					.filter(([, codes]) => codes.length > 0)
					.map(([typeId, codes]) => ({
						activityCode: actvTypes.find((at) => at.actv_code_type_id === typeId).actv_code_type,
						activityCodeId: typeId,
						typeId,
						itemKey: typeId,
						children: codes.map((code) => {
							const item = this.pfTable.pfRows.find((pf) => pf.activityCodeId === code.actv_code_id);
							return {
								...item,
								parent: typeId,
								itemKey: item.activityCodeId,
							};
						}),
					}));
				this.nestedPfTable.forEach((item) => {
					item.children.forEach((child) => {
						this.parentCodeByCode.set(child.activityCodeId, item.activityCodeId);
						this.parentFullCodeByCode.set(child.activityCodeId, item as PFTableValues);
						this.parentFullCodeByName.set(child.activityCode, item as PFTableValues);
					});
					if (
						this.nestedPfTable.some((i) => i.shortName === item.shortName && i.activityCodeId !== item.activityCodeId)
					) {
						const type = actvTypes.find((a) => a.actv_code_type_id === item.activityCodeId);
						item.description =
							type === undefined
								? ''
								: type.actv_code_type_scope === 'AS_Global'
									? 'Global'
									: type.actv_code_type_scope === 'AS_Project'
										? 'Project'
										: '';
					}
				});
				this.parentFromActivityCode.emit(this.parentFullCodeByName);

				const childrenFromPfTable: Map<number, PFTableValues[]> = new Map();
				this.nestedPfTable.forEach((item) => {
					childrenFromPfTable.set(item.activityCodeId, item.children);
				});
				this.childrenFromPfTable = childrenFromPfTable;
				this.activityCodeDropdownOptions = this.nestedPfTable.map((item) => {
					const { children, ...parent } = item;
					return parent as PFTableValues;
				});
				if (this.activityCodeDropdownOptions.length === 0) {
					this.noActivityCodeTypes.emit(true);
				}

				this.resetFilter();
				if (this.selectedActivityCodes.length > 0) {
					this.savedSelectedActivityCodeType = this.parentFullCodeByCode.get(this.selectedActivityCodes[0]);
					this.activityCodeDropDownNameChange.emit(this.savedSelectedActivityCodeType.activityCode);
					this.tablesCurrentCodes = this.childrenFromPfTable.get(
						this.parentCodeByCode.get(this.selectedActivityCodes[0])
					);
				}
				this.noSelections.emit(!this.tablesCurrentCodes || !this.tablesCurrentCodes.length);
			});
		this.resetFilterClicked.subscribe((val) => {
			if (val === true) {
				this.resetFilter();
			}
		});
		this.saveClicked.subscribe((val) => {
			if (val === true) {
				this.savePerformanceTrendingSelections();
			}
		});
		this.submitFilterClicked.subscribe((val) => {
			if (val === true) {
				this.submitFilter();
			}
		});
	}

	public onExpandCollapse(e: ExpandEvent) {
		this.expandedKeys = [];
		const item = e.dataItem;
		if (e.expand) {
			this.expandedKeys.push(item.itemKey);
		}
	}

	updateSelectionTable(e) {
		this.tablesCurrentCodes = this.childrenFromPfTable.get(e.activityCodeId);
		this.noSelections.emit(!this.tablesCurrentCodes || !this.tablesCurrentCodes.length);
		this.selectedCodeTypeName = e.activityCode;
	}

	public onSelect(e: SelectionEvent) {
		console.log('onSelect', e);
		let parentIdOfSelected: number;
		let isADeselect = false;
		if (e.selectedRows.length > 0) {
			parentIdOfSelected = this.parentCodeByCode.get(e.selectedRows[0]?.dataItem?.activityCodeId) || 0;
		} else {
			isADeselect = true;
		}

		const deselectedActivityCodes: number[] = [];
		e.deselectedRows.forEach((row) => {
			deselectedActivityCodes?.push(row?.dataItem?.activityCodeId);
		});
		this.selectedActivityCodes = this.selectedItems.filter(
			(codeId) =>
				!this.codesByType.has(codeId) &&
				(this.parentCodeByCode.get(codeId) === parentIdOfSelected || isADeselect) &&
				!deselectedActivityCodes.includes(codeId)
		);
		this.selectedItems = this.selectedActivityCodes;
		this.checkForDifferences();
	}

	public rowCallback = (context: RowClassArgs) => ({
		tableRow: true,
		over1: context.dataItem.pf > 1,
		under1: context.dataItem.pf < 1,
		nothanks: context.dataItem.pf === 0,
		bold: this.selectedItems.find((selectedItem) => selectedItem === context.dataItem.activityCodeId),
	});

	submitFilter() {
		this.httpProcessing = true;
		this._projectDashboardService.queueProcessingChanged.next(true);
		const activityCodeIds: Array<number> = this.selectedActivityCodes;

		this.restService
			.post(`report/calculate/${this._projectDashboardService.$currentProjectReport.getValue().project?._id}`, {
				activityCodeIds,
				onlySimulate: true,
				lastRecalcedMonteCarlo: new Date(),
			})
			.subscribe(
				(val) => {},
				(response) => {
					console.log('POST call in error', response);
				},
				() => {}
			);
		this.scheduleService.$manualIdUpdate.next(this._projectDashboardService.$currentProjectReport.value?.project?._id);
	}

	savePerformanceTrendingSelections() {
		const performanceTrendingSelectedActivityCodes: string[] = this.pfTable.pfRows
			.filter((item) => this.selectedActivityCodes.includes(item.activityCodeId))
			.map((row) => row.description);

		const performanceTrendingSelectedCodeType: string = this.selectedCodeTypeName;

		const projectId: string = this._projectDashboardService.$currentProjectData.value?._id;
		if (projectId !== undefined) {
			this.restService
				.patch(`project/${projectId}`, {
					performanceTrendingSelectedActivityCodes,
					performanceTrendingSelectedCodeType,
				})
				.subscribe((val) => {
					console.log('patch res', val);
					this.savedPerformanceTrendingSelectedActivityCodes = val.project.performanceTrendingSelectedActivityCodes;
					this.selectedCodeTypeName = val.project.performanceTrendingSelectedCodeType;
					this.checkForDifferences();
				});
		}
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	/**
	 * resets filter to last calculated state and rolls up expansion panel
	 */
	resetFilter() {
		this.expandedKeys = [];
		this.pfTable.unselectedActivities.clear();
		this.pfTable.selectedActivities.clear();

		this.pfTable?.pfRows?.forEach((row) => {
			if (
				this.fromPerformanceTrendingComponent &&
				this?._projectDashboardService?.$currentProjectData?.value?.performanceTrendingSelectedActivityCodes
			) {
				if (
					this?._projectDashboardService?.$currentProjectData?.value?.performanceTrendingSelectedActivityCodes?.includes(
						row.description
					) &&
					this.selectedCodeTypeName === this.parentFullCodeByCode.get(row.activityCodeId)?.activityCode
				) {
					row.isSelected = true;
					this.pfTable.selectedActivities.add(row);
				} else {
					row.isSelected = false;
					this.pfTable.unselectedActivities.add(row);
				}
			} else {
				if (
					this._projectDashboardService.$currentProjectReport
						.getValue()
						.riskPage?.performanceFactor.selectedActivityCodes?.includes(row.activityCodeId)
				) {
					row.isSelected = true;
					this.pfTable.selectedActivities.add(row);
				} else {
					row.isSelected = false;
					this.pfTable.unselectedActivities.add(row);
				}
			}
		});
		this.selectedActivityCodes = this.pfTable.pfRows
			.filter((item) => {
				return (
					this.pfTable.selectedActivities.has(item) &&
					(!this.selectedCodeTypeName ||
						this.selectedCodeTypeName === this.parentFullCodeByCode.get(item.activityCodeId)?.activityCode)
				);
			})
			.map((row) => row.activityCodeId);
		this.selectedActivityCodesName = this.pfTable.pfRows
			.filter(
				(item) =>
					this.pfTable.selectedActivities.has(item) &&
					(!this.selectedCodeTypeName ||
						this.selectedCodeTypeName === this.parentFullCodeByCode.get(item.activityCodeId)?.activityCode)
			)
			.map((row) => row.description);

		setTimeout(() => {
			this.selectedActivityCodesEmitter.emit(this.selectedActivityCodesName);
		}, 1000);
		const allChildren: SelectionItem[] = [];
		this.nestedPfTable.forEach((item) => {
			if (item.children) {
				allChildren.push(
					...item.children.map((child) => ({
						...child,
						isSelected:
							this.fromPerformanceTrendingComponent &&
							this?._projectDashboardService?.$currentProjectData?.value?.performanceTrendingSelectedActivityCodes
								? this?._projectDashboardService?.$currentProjectData?.value?.performanceTrendingSelectedActivityCodes.includes(
										child.description
									) && this.selectedCodeTypeName === this.parentFullCodeByCode.get(child.activityCodeId)?.activityCode
								: this.selectedActivityCodes.includes(child.activityCodeId),
					}))
				);
			}
		});
		this.allChildren = allChildren;
		this.selectedItems = allChildren
			.filter((item) => this.selectedActivityCodes.includes(item.itemKey))
			.map((item) => item.itemKey);
		/*for (const [key, value] of this.codesByType) {
			if (value.size === this.selectedItems.length && this.selectedItems.some((subItem) => value.has(subItem))) {
				this.selectedItems.push(key);
				this.expandedKeys.push(key);
			}
		}*/
		this.panelOpenState = false;
		this.cancelEnabled = false;
		this.recalcEnabled = false;
	}

	/**
	 * updates cancel disabled state if user's selection differs from last successful calculation selection
	 */
	checkForDifferences(): void {
		if (this.selectedActivityCodes.length === 0) {
			this.selectedActivityCodesEmitter.emit([]);
		} else {
			const selectedActivityCodesName: string[] = [];
			const selectedItemsForEmitting = this.allChildren.filter((item) =>
				this.selectedActivityCodes.includes(item.itemKey)
			);
			for (let i = 0; i < selectedItemsForEmitting.length; i++) {
				//eslint-disable-next-line
				selectedActivityCodesName.push(selectedItemsForEmitting[i]['description']);
			}

			this.selectedActivityCodesEmitter.emit(selectedActivityCodesName);
			this.activityCodeDropDownNameChange.emit(this.selectedCodeTypeName);
		}
		let areThereDifferences = false;
		const selectedActivityCodes = this.fromPerformanceTrendingComponent
			? this.savedPerformanceTrendingSelectedActivityCodes
			: this._projectDashboardService.$currentProjectReport.getValue().riskPage?.performanceFactor
					.selectedActivityCodes;
		this.pfTable?.pfRows?.forEach((row) => {
			if (this.fromPerformanceTrendingComponent) {
				const isIncluded =
					selectedActivityCodes?.includes(row.description) &&
					this.selectedCodeTypeName === this.parentFullCodeByCode.get(row.activityCodeId)?.activityCode;
				if (isIncluded !== this.selectedActivityCodes.includes(row.activityCodeId)) {
					areThereDifferences = true;
				}
			} else {
				const isIncluded = selectedActivityCodes.includes(row.activityCodeId);
				if (isIncluded !== this.selectedActivityCodes.includes(row.activityCodeId)) {
					areThereDifferences = true;
				}
			}
		});
		this.cancelEnabled = areThereDifferences;
		this.recalcEnabled = areThereDifferences;
		this.cancelEnabledChange.emit(areThereDifferences);
		this.pfTableChange.emit(this.pfTable);
	}

	private fitColumns(): void {
		this.ngZone.onStable
			.asObservable()
			.pipe(take(1))
			.subscribe(() => {
				this.grid.autoFitColumns();
			});
	}
}
