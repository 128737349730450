<div class='card notes-visualizer-anchor' style='page-break-inside: avoid;' [style.margin-bottom]='user?.userType === "saasRisk" ? "5px" : 0'>
	<div *ngIf='!scheduleStorage.isLoading' class='finished-loading-schedules'></div>
	<kendo-gridlayout
		[rows]="['42.4px', showNotesGrid ? 'calc(100% - 42.2px)' : '0px']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='grid'
		style='page-break-inside: avoid'
		*ngIf='!visualizer'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' [class.closed-bar-corners]='scheduleStorage.isLoading || !showNotesGrid' [class.all-corners]='showNotesGrid'>
			<kendo-gridlayout
				[rows]="['42.4px']"
				[cols]="['100%']"
				[gap]="{ rows: 0, cols: 5 }"
				class='grid'
			>
				<kendo-gridlayout-item [row]='1' [col]='1'>
					<div (click)='toggleNotesGrid()' class='toggle-container' [class.disabled-click]='scheduleStorage.isLoading'>
						<mat-icon *ngIf="!showNotesGrid" class='toggle-bar-icon'>arrow_forward_ios</mat-icon>
						<mat-icon *ngIf="showNotesGrid" class='toggle-bar-icon'>arrow_back_ios</mat-icon>
						<span class='card-header toggle-bar-text'>{{showNotesGrid ? 'Collapse Notes' : 'Project Notes'}}</span>
						<mat-icon *ngIf="!showNotesGrid" class='toggle-bar-icon'>arrow_forward_ios</mat-icon>
						<mat-icon *ngIf="showNotesGrid" class='toggle-bar-icon'>arrow_back_ios</mat-icon>
					</div>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1'>
			<div *ngIf='!scheduleStorage.isLoading && showNotesGrid'>
				<kendo-grid
					[data]="gridView"
					[kendoGridBinding]="gridData"
					[height]="200"
					[loading]='loading'
					[size]='"small"'
					[sortable]='true'
					[sort]="sort"
					(sortChange)="sortChange($event)"
					[skip]="skip"
					[pageSize]="pageSize"
					[navigable]="false"
					class='notes-grid'
				>
					<kendo-grid-column
						field="updateNumber"
						title="Update"
						[width]='100'
						headerClass="header-class"
					>
						<ng-template kendoGridCellTemplate let-dataItem>
							{{ dataItem.updateNumber === 0 ? 'Baseline' : 'Update ' + dataItem.updateNumber }}
						</ng-template>
					</kendo-grid-column>
					<kendo-grid-column
						field="dataDate"
						title="Data Date"
						[format]="'{0:MM/dd/yyyy}'"
						[width]='100'
						headerClass="header-class"
					>
						<ng-template kendoGridCellTemplate let-dataItem>
							{{ (dataItem.dataDate | date: 'MM/dd/yyyy') || 'N/A' }}
						</ng-template>
					</kendo-grid-column>
					<kendo-grid-column
						field="criticalPathNotes"
						title="Critical Path Notes"
						headerClass="header-class"
					>
						<ng-template kendoGridCellTemplate let-dataItem>
							<span class='notes-cells'>
								{{ dataItem.criticalPathNotes }}
							</span>
						</ng-template>
					</kendo-grid-column>
					<kendo-grid-column
						field="timeAnalysisNotes"
						title="Time Analysis Notes"
						headerClass="header-class"
					>
						<ng-template kendoGridCellTemplate let-dataItem>
							<span class='notes-cells'>
								{{ dataItem.timeAnalysisNotes }}
							</span>
						</ng-template>
					</kendo-grid-column>
				</kendo-grid>
			</div>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
</div>
