import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
	note: string;
	currentProjectId: string;
	riskMetricsOptions: any;
	userType: string;
}

import { UntypedFormBuilder, UntypedFormGroup, FormControl } from '@angular/forms';

import { Task, Activity, FilterCode, PreUpdate } from '../../../../../services/project/update/pre-test-models';
import { Papa } from 'ngx-papaparse';
import { FileUploadService } from '../../../../../services/common/fileUpload.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { Update } from '../../../../../services/project/update/update.model';
import { forkJoin, Observable } from 'rxjs';
import { RestService } from '../../../../../services/common/rest.service';
import { groupBy, orderBy } from '@progress/kendo-data-query';
import { UserService } from '../../../../../services/common/user.service';
import { NavigationBarStorageService } from '../../../../../services/common/navigation-bar-storage.service';
import { FileRestrictions } from '@progress/kendo-angular-upload';

const reader = new FileReader();
reader.onerror = (error) => console.log(error);
export const MY_FORMATS = {
	parse: {
		dateInput: 'YYYY/MM/DD',
	},
	display: {
		dateInput: 'YYYY/MM/DD',
		monthYearLabel: 'YYYY MMM DD',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'YYYY MMM DD',
	},
};

@Component({
	selector: 'app-add-baseline',
	templateUrl: 'add-baseline.component.html',
	styleUrls: ['add-baseline.component.scss'],
})
export class AddBaselineComponent {
	@Output() closeBaselineUpload = new EventEmitter<boolean>();
	currentProjectId: any;
	//upload schedule form component variables
	isLinear = true;
	shouldNextStepBeDisabled = true;
	startMilestoneFormGroup: UntypedFormGroup;
	completionMilestoneFormGroup: UntypedFormGroup;
	activityCodesFormGroup: UntypedFormGroup;
	additionalCompletionMilestoneFormGroup: UntypedFormGroup;
	scheduleNotesFormGroup: UntypedFormGroup;
	//1d arrays for parent and sub activity codes
	activitiesByUser: {
		[userId: string]: Array<Partial<Activity>>;
	};

	// Selected
	selectedActivityCode: FilterCode;
	selectedFilteringCodes: Array<FilterCode>;
	totalFloatPermissionUserIdsMap: { [key: string]: boolean };
	isFirstSlide: boolean;
	isLastSlide: boolean;
	subCodes: any[];
	ccdDateString: string;
	// finish milestones
	finishMilestones: Array<Task>;
	additionalFinishMilestones: Array<Task>;
	startMilestone: Task;
	finishMilestone: Task;
	finishMilestone1: Task;
	finishMilestone2: Task;
	finishMilestone3: Task;
	updates: Array<PreUpdate>;
	userFinishMilestoneSelection: Task | void;
	selectedCompletionMilestone: any;
	selectedStartMilestone: any;
	task = [];
	httpProcessing = false;
	nextButtonIsEnabled: boolean = false;
	riskEnabled: boolean;
	riskMetricsType = 'default';
	riskOptions = [];
	selectedFiles = [];
	uploadedFiles;
	fileUploadInProgress = false;
	uploadedUpdate: Update;
	baselineUpdateId: string;
	startMilestoneList = [];
	finishMilestoneList = [];
	userType = 'default';
	public opened: boolean = true;
	xerRestrictions: FileRestrictions = {
		allowedExtensions: ['.xer'],
	};

	constructor(
		private papa: Papa,
		private _formBuilder: UntypedFormBuilder,
		public dialogRef: MatDialogRef<AddBaselineComponent>,
		public fileUploadService: FileUploadService,
		private restService: RestService,
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		private _snackBar: MatSnackBar,
		public navBarStorage: NavigationBarStorageService
	) {}

	updateMilestoneSelect({ start, end }) {
		if (start) {
			this.fileUploadService.data.startMilestone = start.value;
		}
		if (end) {
			this.fileUploadService.data.finishMilestone = end.value;
		}
	}

	onNoClick(): void {
		this.dialogRef.close();
		this.activityCodesFormGroup.reset();
	}

	isNextEnabled() {
		this.nextButtonIsEnabled = true;
	}

	ngOnInit() {
		this.riskEnabled = false;
		this.currentProjectId = this.data.currentProjectId;
		this.riskOptions = this.data.riskMetricsOptions;
		this.userType = this.data.userType;
		if (this.userType === 'saasRisk') {
			this.riskMetricsType = 'riskRegister';
		}
		if (!!this.riskOptions) {
			const previouslySelectedRiskMetricsType = this.riskOptions.find((rmt) => rmt.defaultChecked === true);
			if (previouslySelectedRiskMetricsType !== undefined) {
				this.riskMetricsType = previouslySelectedRiskMetricsType.value;
			}
		}
		this.startMilestoneFormGroup = this._formBuilder.group({
			// startMilestoneCtrl: ['', Validators.required]
		});

		this.additionalCompletionMilestoneFormGroup = this._formBuilder.group({
			//additionalCompletionMilestoneCtrl: ['', Validators.required]
		});

		this.scheduleNotesFormGroup = this._formBuilder.group({
			// scheduleNotesCtrl: ['', Validators.required]
		});

		this.completionMilestoneFormGroup = this._formBuilder.group({
			// completionMilestoneCtrl: ['', Validators.required]
		});

		this.activityCodesFormGroup = this._formBuilder.group({
			// completionMilestoneCtrl: ['', Validators.required]
		});

		this.fileUploadService.OnInit();
	}

	setRisk(newVal: string) {
		this.riskEnabled = newVal === 'performanceFactor' || newVal === 'riskRegister';
		this.riskMetricsType = newVal;
	}

	async submit() {
		//added
		this.httpProcessing = true;
		localStorage.setItem('updatesAdded', JSON.stringify([0]));
		localStorage.setItem('newUpdatesLength', JSON.stringify(1));
		await this.fileUploadService.updateRisk(this.riskEnabled, this.riskMetricsType, this.currentProjectId);
		const baseLine = this.fileUploadService.createBaselineUpdate(this.currentProjectId, this.baselineUpdateId);
		if (this.userType === 'saasRisk') {
			this.navBarStorage.selectedTab = 'schedules';
			const url = new URLSearchParams(new URL(window.location.href).search);
			url.set('tab', this.navBarStorage.selectedTab);
			history.pushState(null, '', window.location.pathname + '?' + url.toString());
			this.navBarStorage.$tabPointer.next('schedules');
		}
		this.dialogRef.close();
		this._snackBar.open('Adding Baseline... Please Wait', undefined, {
			duration: 5000,
			panelClass: 'message-position',
		});
	}
	async makeFileReadyForUpload(event, stepper: MatStepper) {
		this.uploadedUpdate = null;
		const file = event.target.files[0];
		const update = new Update();
		await update.handleFileSelect(event);
		await this.fileUploadService.handleFileSelect(event);
		this.uploadedUpdate = update;
		this.selectedFiles = Object.values(event.target.files);
		this.uploadXERFiles(this.uploadedUpdate, event.target.files);
		this.isNextEnabled();
	}
	projectChange(ev: number) {
		this.fileUploadService.assignTasks(
			this.fileUploadService.xer.sortedActivities.filter((actv) => !ev || actv._activity.projectId === ev)
		);
		this.fileUploadService.data.selectedProjectId = ev;
	}
	uploadXERFiles(update: Update, files: any[]) {
		const apiCalls = {};
		this.fileUploadInProgress = true;
		this.selectedFiles.forEach((file: File, index) => {
			apiCalls[index] = this.uploadXERFile(file);
		});

		forkJoin(apiCalls).subscribe(
			(result) => {
				this.uploadedFiles = Object.values(result);
				// this.onNoClick();
			},
			(error) => console.log(error),
			() => {
				this.fileUploadInProgress = false;
				this.startMilestoneList = this.fileUploadService.startMilestones;
				this.finishMilestoneList = this.fileUploadService.finishMilestones;
			}
		);
	}

	uploadXERFile(file: File): Observable<any> {
		const metadata = {
			name: file.name,
			type: 'text/plain',
			size: file.size,
		};
		const body = { metadata, projectId: this.currentProjectId };

		return new Observable((subscriber) => {
			this.restService.post(`project/${this.currentProjectId}/fileUpload`, body).subscribe(
				async (uploadResponse: any) => {
					const s3Upload = uploadResponse.data;
					this.baselineUpdateId = s3Upload.updateId;
					const tempUrlRes = s3Upload.credentials;
					const tempUrl = JSON.parse(JSON.stringify(tempUrlRes));
					if (uploadResponse.isErr === false) {
						const { s3Credentials, filePath } = s3Upload.credentials;
						let { type } = metadata;
						type = type ? type : 'text/plain';
						const uploadRes = await this.restService.uploadS3File(
							s3Credentials.replace('%2F', '/'),
							filePath,
							type,
							file
						);
						const uploadedFile = s3Credentials.split('?')[0];
						const data = { name: file.name, updateUrl: filePath, uploadedFile };
						subscriber.next(data);
						subscriber.complete();
					} else {
						console.log('error in temp url POST', tempUrl);
					}
				},
				(error) => {
					console.log('error', error);
				}
			);
		});
	}
	formSubmit() {
		this.httpProcessing = true;
		this.fileUploadService.createBaselineUpdate(this.currentProjectId, this.baselineUpdateId);
	}

	/**
	 * updates dropdownlist values based on search text
	 * @param value
	 * @param fileUploadServiceRef
	 * @param localList
	 */
	handleFilter(value, fileUploadServiceRef, localList): void {
		const newData = [];
		this[localList].forEach((group) => {
			const groupMatchingItems = group.items.filter(
				(item) => item.text.toLowerCase().indexOf(value.toLocaleString()) !== -1
			);
			newData.push(...groupMatchingItems);
		});
		const newDataGrouped = groupBy(
			orderBy(Array.from(newData), [
				{ field: 'category', dir: 'desc' },
				{ field: 'earlyFinish', dir: 'asc' },
			]),
			[{ field: 'category' }]
		);
		this.fileUploadService[fileUploadServiceRef] = newDataGrouped;
	}

	public open(isOpened: boolean): void {
		this.opened = isOpened;
		this.closeBaselineUpload.emit(false);
	}
}
