<!--<ion-item (click)="enableRisk()" href="javascript:///"-->
<!--  ><ion-icon slot="start" icon="toggle-outline"></ion-icon-->
<!--  ><ion-label>-->
<!--    <h2>Enable Risk</h2>-->
<!--    <p>Enable the risk section for this project.</p>-->
<!--  </ion-label></ion-item-->
<!--&gt;-->
<!--<div>-->
<!--  <label>Would you like to enable the risk page?</label><br />-->
<!--  <mat-radio-group class="risk-enable-radio-group">-->
<!--    <mat-radio-button-->
<!--      class="radio"-->
<!--      *ngFor="let option of riskOptions"-->
<!--      [value]="option.value"-->
<!--      (click)="enableRisk(option.value)"-->
<!--      [checked]="option.defaultChecked"-->
<!--    >-->
<!--      {{ option.viewValue }}<br />-->
<!--    </mat-radio-button>-->
<!--  </mat-radio-group>-->
<!--</div>-->
<label style="text-align: center; display: flex; flex-direction: column;">Select the risk metrics calculation method for your project</label><br />
<div class="risk-enable-radio-buttons">
  <mat-radio-group>
    <mat-radio-button
      class="risk-enable-radio-group"
      *ngFor="let option of $riskOptions.value"
      [value]="option.value"
      (click)="updateRiskValue(option.value)"
      [checked]="option.defaultChecked"
    >
      {{ option.viewValue }}<br />
    </mat-radio-button>
  </mat-radio-group>
  <div *ngIf="riskMetricsType === 'performanceFactor'" class="extra-text">
	  Uses past performance from schedule activities in the Monte Carlo simulation (Automated)
  </div>
	<div *ngIf="riskMetricsType === 'riskRegister'" class="extra-text">
		Uses Risk Register entries in the Monte Carlo simulation (User-managed)
	</div>
</div>
