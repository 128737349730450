import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomFieldTreeData, RiskRegister } from '../../../../../../models/risk';
import { RiskSettingsService } from '../../../../../../services/project/risk-settings.service';
import { ProjectDashboardService } from '../../../../../../services/project/project.service';
import { RestService } from '../../../../../../services/common/rest.service';
import { Observable, Subject, Subscription } from 'rxjs';
import {
	AddEvent,
	CancelEvent,
	CellClickEvent,
	EditEvent,
	ExpandEvent,
	RemoveEvent,
	SaveEvent,
	TreeListComponent,
} from '@progress/kendo-angular-treelist';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import _ from 'lodash';
import { cancelIcon, filterAddExpressionIcon, pencilIcon, saveIcon, trashIcon } from '@progress/kendo-svg-icons';

@Component({
	selector: 'app-risk-data-fields',
	templateUrl: './risk-data-fields.component.html',
	styleUrls: ['./risk-data-fields.component.scss'],
})
export class RiskDataFieldsComponent implements OnInit {
	@Input() disabled = false;
	public form: FormGroup;
	public expandedIds: number[] = [];
	public editedItem: CustomFieldTreeData;
	public rootData: Observable<CustomFieldTreeData[]>;
	@Output() canSave = new EventEmitter<boolean>();
	icons = {
		filterAddExpression: filterAddExpressionIcon,
		pencil: pencilIcon,
		trash: trashIcon,
		save: saveIcon,
		cancel: cancelIcon,
	};
	constructor(
		protected editService: RiskSettingsService,
		private project: ProjectDashboardService,
		private restService: RestService
	) {}

	ngOnInit(): void {
		this.rootData = this.editService;

		this.rootData.subscribe(() => {
			this.canSave.emit(this.hasChanges);
		});
		this.project.$currentRiskRegisters.subscribe((data) => {
			//for when register is added/removed
			this.editService.read(true);
		});
	}

	public fetchChildren = (item: CustomFieldTreeData): Observable<CustomFieldTreeData[]> =>
		this.editService.fetchChildren(item.customFieldId);

	public hasChildren = (item: CustomFieldTreeData): boolean => item.hasChildren;

	public addHandler({ sender, parent }: AddEvent): void {
		this.expandedIds = parent ? [parent.customFieldId] : [];
		this.closeEditor(sender);
		if (parent?.isRoot) {
			setTimeout(() => {
				sender.expand(parent);
			}, 500);
		}
		this.form = new FormGroup({
			reportsTo: new FormControl(parent ? parent.field : null),
			data: new FormControl('', Validators.required),
			editable: new FormControl(true),
		});
		sender.addRow(this.form, parent);
	}

	public expandHandler({ dataItem, sender, expand }: ExpandEvent): void {
		setTimeout(() => {
			this.expandedIds = [dataItem.customFieldId];
		}, 500);
	}

	public editHandler({ sender, dataItem }: EditEvent): void {
		this.closeEditor(sender);
		this.form = new FormGroup({
			reportsTo: new FormControl(dataItem ? dataItem.reportsTo : null),
			data: new FormControl(dataItem?.data, Validators.required),
		});
		this.editedItem = dataItem;
		sender.editRow(dataItem, this.form);
	}

	public cancelHandler({ sender, dataItem, isNew }: CancelEvent): void {
		this.closeEditor(sender, dataItem, isNew);
	}

	public saveHandler({ sender, dataItem, parent, formGroup, isNew }: SaveEvent): void {
		const customFieldsTreeData = formGroup.value;
		const oldValue = structuredClone(dataItem);
		if (!isNew) {
			Object.assign(dataItem, customFieldsTreeData);
		} else if (parent) {
			parent.hasChildren = true;
		}
		if (parent === undefined) {
			parent = { customFieldId: dataItem.reportsTo };
		}
		this.canSave.emit(true);
		/*		if (parent) {
			sender.reload(parent);
		}*/
		this.editService.save(customFieldsTreeData, parent, isNew, oldValue);
		//this.project.$currentRiskRegisters.next(this.project.$currentRiskRegisters.value);
		this.editService.read();

		sender.closeRow(dataItem, isNew);
	}

	public removeHandler({ sender, dataItem, parent }: RemoveEvent): void {
		this.editService.remove(dataItem, parent);
		this.editService.read();
		if (parent) {
			sender.reload(parent);
			setTimeout(() => {
				this.expandedIds = [parent.customFieldId];
			}, 500);
		}
	}

	private closeEditor(treelist: TreeListComponent, dataItem: any = this.editedItem, isNew = false): void {
		treelist.closeRow(dataItem, isNew);
		this.editedItem = undefined;
		this.form = undefined;
	}

	public cellClickHandler({ sender, columnIndex, dataItem, isEdited }: CellClickEvent): void {
		if (!isEdited && dataItem.editable && !this.disabled) {
			this.editHandler({ sender, dataItem, isNew: false });
		}
	}

	public get hasChanges(): boolean {
		const changedRegisters = this.editService.lastSavedRegisters || [];
		const actualRegisters = this.project.$currentProjectData.value.riskMitigation || [];
		const originalUnassignedFields =
			this.project.$currentProjectData.value.preferences.riskMitigation.unassignedCustomFields;
		const latestUnassignedFields = this.editService.lastSavedUnassignedCustomFields;
		if (originalUnassignedFields !== undefined || latestUnassignedFields !== undefined) {
			return false;
		}
		if (JSON.stringify(originalUnassignedFields) !== JSON.stringify(latestUnassignedFields)) {
			return true;
		}
		let hasUnassignedChanges = false;
		if (!changedRegisters.length) {
			return false;
		}
		for (let i = 0; i < actualRegisters.length; i++) {
			const changed = changedRegisters[i];
			const actual = actualRegisters[i];
			if (
				!_.isEqual(changed.riskOwner, actual.riskOwner) ||
				!_.isEqual(changed.responsibility, actual.responsibility) ||
				!_.isEqual(changed.costOwner, actual.costOwner)
			) {
				hasUnassignedChanges = true;
				break;
			}
		}
		return hasUnassignedChanges;
	}
}
