import { Component, OnInit, ViewChild } from '@angular/core';
import {
	allColumns,
	CurrentUpdateGanttColumn,
} from '../current-update-gantt-chart/current-update-gantt-chart.component';
import { FilterComponent, FilterExpression } from '@progress/kendo-angular-filter';
import { TreeItem } from '@progress/kendo-angular-treeview';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { XerActivityCode, XerActivityType } from '../../../../../models/Update/Task';
import { CurrentUpdateGanttPreset, CurrentUpdateGanttPresetInputs } from '../../../../../models/Project';
import { GanttService, ReportGroup, WbsLevel } from '../../../../../services/project/gantt.service';
import { ProjectDashboardService } from '../../../../../services/project/project.service';
import { GanttActvType } from '../gantt-group-by/gantt-group-by.component';
import { caretAltDownIcon } from '@progress/kendo-svg-icons';

@Component({
	selector: 'app-gantt-preset-window-content',
	templateUrl: './gantt-preset-window-content.component.html',
	styleUrls: ['./gantt-preset-window-content.component.scss'],
})
export class GanttPresetWindowContentComponent implements OnInit {
	@ViewChild('filter') filter: FilterComponent;
	public hiddenColumns: string[] = [];
	_checkedColumns: any[] = ['0', '1', '2', '3', '4', '5', '6'];
	name: string = '';
	showRelationships: boolean = false;
	actvCodesByType: Map<number, Array<XerActivityCode>> = new Map<number, Array<XerActivityCode>>([]);
	showCritical: boolean = true;
	_id: string = null;
	selectedGanttGroupNode: TreeItem = null;
	isFavorite: boolean = false;
	public filterValue: CompositeFilterDescriptor = { logic: 'and', filters: [] };
	public tempFilters: CompositeFilterDescriptor = { logic: 'and', filters: [] };
	selectedColumns: CurrentUpdateGanttColumn[] = [];
	loading: boolean = false;
	saving: boolean = false;
	isBasedOnOtherPreset: boolean = false;
	allActivityTypes: XerActivityType[] = null;
	untouchedAllActivityTypes: XerActivityType[] = null;
	public statusFilterList: Array<string> = ['Complete', 'Incomplete', 'Not Started'];
	public taskTypeFilterList: Array<string> = [
		'Task Dependent',
		'Resource Dependent',
		'Milestone',
		'Finish Milestone',
		'WBS Summary',
		'Level of Effort',
	];
	public criticalityFilterList: Array<string> = ['Critical', 'Non Critical']; //todo: add 'Near Critical' back when near critical vs critical distinctions are accurate - RS
	public filters: FilterExpression[] = [
		{
			field: 'taskCode',
			title: 'ID',
			editor: 'string',
		},
		{
			field: 'name',
			title: 'Name',
			editor: 'string',
		},
	];
	currentTab: number = 0;
	currentlyAppliedWbsLevel: WbsLevel = { text: 'Please Select', value: 0 };
	currentlyAppliedActvTypes: GanttActvType[] = [];
	currentlyAppliedGrouping: 'wbs' | 'actvCodes' = null;
	currentlyAppliedColors: string[] = [
		'black',
		'#575757',
		'#AD2323',
		'#2A4BD7',
		'#1D6914',
		'#814A19',
		'#8126C0',
		'#A0A0A0',
		'#81C57A',
		'#9DAFFF',
	];
	radioSelectionDelayed: 'wbs' | 'actvCodes' = null;
	otherPresets: CurrentUpdateGanttPreset[] = [];
	basedOn: CurrentUpdateGanttPreset = null;
	selectedFolders: any[] = [];
	icons = {
		caretDown: caretAltDownIcon,
	};

	constructor(
		public ganttService: GanttService,
		public projectService: ProjectDashboardService
	) {}

	ngOnInit() {
		this.ganttService.$reportApply.subscribe((val: boolean) => {
			if (val) {
				this.applyGroupBy();
			}
		});
		this.ganttService.$currentUpdateGanttPresetWindowOpen.subscribe((preset: CurrentUpdateGanttPresetInputs) => {
			this._id = null;
			if (preset !== null && preset) {
				if (this.ganttService.appliedReportVals === null || this.ganttService.appliedReportVals.id === null) {
					this.ganttService.appliedReportVals = {
						filters: JSON.stringify(this.tempFilters),
						grouping: {
							type: this.ganttService.radioSelection,
							wbsToLevel: this.ganttService.selectedWbsLevel.value,
							actvCodeGrouping: this.ganttService.selectedGroupBy,
							hideSummaryBars: this.ganttService.hideSummaryBars,
							colors: JSON.stringify(this.ganttService.wbsColorStrings),
						},
						id: this._id,
						isFavorite: this.isFavorite,
						name: this.name,
						showingCritical: this.showCritical,
						showingRelationships: this.showRelationships,
						visibleColumns: this._checkedColumns,
					};
				}
				this.updatePresetValues(preset.preset, preset.isBasedOnOtherPreset);
				this.isBasedOnOtherPreset = preset.isBasedOnOtherPreset;
				this.basedOn = preset.isBasedOnOtherPreset ? preset.preset : null;
				this.loading = true;
				this.ganttService.isEdit = !preset.isNew;
				this.actvCodesByType = structuredClone(preset.activityMap);
				this.untouchedAllActivityTypes = structuredClone(preset.groups);
				this.filterValue = preset.currentFilters;
				this.tempFilters = preset.currentFilters;
				this.ganttService.presetWindowContentFilters = preset.currentFilters;
				this.ganttService.originalPreset = preset;
				this.allActivityTypes = structuredClone(preset.groups);
				if (
					this.ganttService.isEdit &&
					this.selectedGanttGroupNode !== null &&
					!this.existsInGrouping(preset.groups, this.selectedGanttGroupNode?.dataItem)
				) {
					this.allActivityTypes.push(this.selectedGanttGroupNode.dataItem);
				}
				const otherPresets: CurrentUpdateGanttPreset[] = this.ganttService.isEdit
					? structuredClone(this.ganttService.userPresets).filter((p: CurrentUpdateGanttPreset) => p.id !== this._id)
					: structuredClone(this.ganttService.userPresets);
				otherPresets.splice(0, 0, {
					id: null,
					name: 'None (New Report)',
					grouping: null,
					showingRelationships: false,
					showingCritical: false,
					filters: null,
					isFavorite: false,
					visibleColumns: null,
				});
				this.otherPresets = otherPresets;
				this.loading = false;
				//this.defaultPreset = this.isFavorite ? 'Yes' : 'No';
				this.updateGroupingVals();
				if (!this.ganttService.isEdit) {
					this.ganttService.selectedGroupBy = [];
					this.ganttService.tempWbsColorStrings = [
						'black',
						'#575757',
						'#AD2323',
						'#2A4BD7',
						'#1D6914',
						'#814A19',
						'#8126C0',
						'#A0A0A0',
						'#81C57A',
						'#9DAFFF',
					];
					this.ganttService.radioSelection = null;
					this.ganttService.selectedWbsLevel = { text: 'Please Select', value: 0 };
					this.ganttService.originalPreset = null;
				}
			}
			if (preset === null) {
				this.tempFilters = structuredClone(this.ganttService.tempFilters);
				this.filterValue = structuredClone(this.ganttService.filterValue);
			}
		});
		this.ganttService.$reset.subscribe((val: boolean) => {
			if (val) {
				this.updateGroupingVals();
			}
		});
		this.ganttService.$groupByXClicked.subscribe((val: boolean) => {
			if (val) {
				this.currentlyAppliedGrouping = null;
				this.currentlyAppliedWbsLevel = null;
				this.currentlyAppliedActvTypes = [];
				this.currentlyAppliedColors = [
					'black',
					'#575757',
					'#AD2323',
					'#2A4BD7',
					'#1D6914',
					'#814A19',
					'#8126C0',
					'#A0A0A0',
					'#81C57A',
					'#9DAFFF',
				];
				this.ganttService.radioSelection = null;
				this.ganttService.selectedGroupBy = [];
				this.ganttService.selectedWbsLevel = { text: 'Please Select', value: 0 };
			}
		});
	}

	updateName(ev: string): void {
		this.ganttService.presetWindowName = ev;
	}

	/**
	 * update report form values based on input preset
	 * @param preset
	 * @param ignoreName
	 */
	updatePresetValues(preset: CurrentUpdateGanttPreset, ignoreName: boolean = false): void {
		this.checkedColumns = preset.visibleColumns;
		this.showCritical = preset.showingCritical;
		this.showRelationships = preset.showingRelationships;
		this.isFavorite = preset.isFavorite;
		if (!ignoreName) {
			this.name = preset.name;
			this.ganttService.presetWindowName = preset.name;
		}
		this._id = preset.id;
		const presetColors: string[] = JSON.parse(preset.grouping.colors);
		if (presetColors === null || presetColors?.length === 0) {
			this.ganttService.resetGroupingColors(true);
		} else {
			this.ganttService.tempWbsColorStrings = presetColors;
		}
		this.ganttService.radioSelection = preset.grouping.type;
		if (this.ganttService.radioSelection) {
			this.radioSelectionDelayed = this.ganttService.radioSelection;
		}
		const wbsLevel: number = preset.grouping.wbsToLevel;
		this.ganttService.selectedWbsLevel =
			wbsLevel === 0 || wbsLevel === null
				? { text: 'Please Select', value: 0 }
				: { text: 'Level ' + wbsLevel, value: wbsLevel };
		this.ganttService.selectedGroupBy = preset.grouping.actvCodeGrouping;
		this.ganttService.hideSummaryBars = preset.grouping.hideSummaryBars;
		const folders: ReportGroup[] = [];
		this.ganttService.reportGroups.forEach((folder: ReportGroup) => {
			if (folder.reportIds.includes(preset.id)) {
				folders.push(folder);
			}
		});
		this.selectedFolders = structuredClone(folders);
	}

	// Moved from gantt-group-by to here because the group by applying wasn't happening unless you switched to 'Grouping' tab and that component opened.
	// Moving it here allowed the apply button to work without having to open 'Grouping' tab. - KF
	async applyGroupBy(): Promise<void> {
		if (!this.ganttService.applyPhysicallyClicked) {
			return;
		}
		if (this.ganttService.radioSelection === 'wbs') {
			await this.ganttService.applyWbsGrouping();
			const test: number =
				this.ganttService.selectedWbsLevel?.value === null
					? this.ganttService.highestLevel
					: this.ganttService.selectedWbsLevel?.value;
			this.ganttService.puppetLevel = [];
			for (let i: number = 0; i < test; i++) {
				this.ganttService.puppetLevel.push(i);
			}
		} else {
			await this.ganttService.applyGroupBy();
		}
		this.ganttService.applyGroupBySort();
		this.updateGroupingVals();
	}

	existsInGrouping(groups, item) {
		return groups.some((group) => {
			return group.actv_code_type_id === item.actv_code_type_id;
		});
	}

	get checkedColumns() {
		return this._checkedColumns;
	}

	public isDisabled = (dataItem: any) => dataItem?.sequence === 0;

	set checkedColumns(columns: string[]) {
		columns = columns.sort((a: string, b: string) => {
			const valA: number = Number(a);
			const valB: number = Number(b);
			return valA < valB ? -1 : valB < valA ? 1 : 0;
		});
		this._checkedColumns = columns;
		const hiddenColumnFields: string[] = [];
		allColumns.columns.forEach((column: CurrentUpdateGanttColumn) => {
			if (!columns.includes(column.sequence.toString())) {
				hiddenColumnFields.push(column.field);
			}
		});
		this.hiddenColumns = hiddenColumnFields;
	}

	onFilterChange(filter: CompositeFilterDescriptor) {
		this.tempFilters = filter;
		this.ganttService.presetWindowContentFilters = filter;
	}

	save(): void {
		const existingPresets: CurrentUpdateGanttPreset[] = structuredClone(
			this.projectService.$currentProjectData.value?.ganttPresets
		);
		const matchingPreset: CurrentUpdateGanttPreset =
			existingPresets === undefined ? undefined : existingPresets.find((preset) => preset.id === this._id);
		const thisReportId: string = matchingPreset === undefined ? crypto.randomUUID() : this._id;
		if (this.selectedFolders?.length) {
			const newReportFolders: ReportGroup[] = [];
			this.ganttService.reportGroups.forEach((folder) => {
				if (this.selectedFolders.findIndex((f) => f.id === folder.id) !== -1) {
					folder.reportIds.push(thisReportId);
				} else {
					folder.reportIds = folder.reportIds.filter((r) => r !== thisReportId);
				}
				newReportFolders.push(folder);
			});
			this.ganttService.updateReportGroups(newReportFolders);
		}
		if (matchingPreset === undefined) {
			const newPreset: CurrentUpdateGanttPreset = {
				grouping: this.ganttService.getGrouping(),
				showingRelationships: this.showRelationships,
				showingCritical: this.showCritical,
				filters: JSON.stringify(this.tempFilters),
				visibleColumns: this.checkedColumns,
				name: this.name,
				isFavorite: this.isFavorite,
				id: thisReportId,
			};
			this.ganttService.addNewGanttPreset(newPreset);
			this.ganttService.$currentUpdateGanttPresetWindowOpen.next(null);
		} else {
			let newPresets: CurrentUpdateGanttPreset[] = [];
			existingPresets.forEach((preset: CurrentUpdateGanttPreset) => {
				if (preset.id === this._id) {
					newPresets.push({
						grouping: this.ganttService.getGrouping(),
						showingRelationships: this.showRelationships,
						showingCritical: this.showCritical,
						filters: JSON.stringify(this.tempFilters),
						visibleColumns: this.checkedColumns,
						name: this.name,
						isFavorite: this.isFavorite,
						id: thisReportId,
					});
				} else {
					newPresets.push(structuredClone(preset));
				}
			});
			newPresets = newPresets.sort((a: CurrentUpdateGanttPreset, b: CurrentUpdateGanttPreset) => {
				// if (a?.isFavorite && !b?.isFavorite) {
				// 	return -1;
				// }
				// if (b?.isFavorite && !a?.isFavorite) {
				// 	return 1;
				// }
				if (a?.name?.toLowerCase() < b?.name?.toLowerCase()) {
					return -1;
				}
				if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
					return 1;
				}
				return 0;
			});
			this.ganttService.editGanttPreset(newPresets);
			this.ganttService.$currentUpdateGanttPresetWindowOpen.next(null);
		}
	}

	reset(ignoreServiceCalls: boolean = false): void {
		this.isFavorite = false;
		this._checkedColumns = ['0', '1', '2', '3', '4', '5', '6'];
		this.showRelationships = false;
		this.showCritical = true;
		this.filterValue = { logic: 'and', filters: [] };
		this.tempFilters = this.filterValue;
		this.ganttService.presetWindowContentFilters = this.filterValue;
		this.selectedGanttGroupNode = null;
		if (!ignoreServiceCalls) {
			this.ganttService.resetFilters(true);
			this.ganttService.$resetOptions.next(true);
		}
		this.loading = false;
		this.currentlyAppliedGrouping = null;
		this.basedOn = null;
		this.updateGroupingVals();
		this.isDiffThanCurrent('apply');
		this.isDiffThanCurrent('save');
	}

	apply(): void {
		this.ganttService.applyPhysicallyClicked = true;
		if (this.currentlyAppliedGrouping === null && this.ganttService.$groupByXClicked.value) {
			this.ganttService.softResetGroupValues();
		}
		if (this.radioSelectionDelayed) {
			this.radioSelectionDelayed = null;
		}
		this.ganttService.tempFilters = structuredClone(this.tempFilters);
		this.filterValue = structuredClone(this.tempFilters);
		this.ganttService.applyFilterChanges();
		this.ganttService.checkedColumns = structuredClone(this.checkedColumns);
		this.ganttService.radioSelectionDelayed = structuredClone(this.ganttService.radioSelection);
		this.ganttService.updateApply();
		this.ganttService.wbsColorStrings = structuredClone(this.ganttService.tempWbsColorStrings);
		this.ganttService.taskTypeButtons[0].selected = structuredClone(this.showCritical);
		this.ganttService.taskTypeButtons[1].selected = !structuredClone(this.showCritical);
		this.ganttService.$showOnlyCritical.next(this.showCritical);
		this.ganttService.relationshipsButtons[0].selected = !structuredClone(this.showRelationships);
		this.ganttService.relationshipsButtons[1].selected = structuredClone(this.showRelationships);
		this.ganttService.$showLines.next(this.showRelationships);
		this.updateGroupingVals();
		this.ganttService.applyPhysicallyClicked = false;
		this.ganttService.selectedGanttLayout = null;
		if (this.ganttService.isEdit && !this.ganttService.saveCanBeClicked) {
			this.ganttService.selectedGanttLayout = structuredClone(this._id);
			this.ganttService.reportName = this.name;
		}
		this.ganttService.appliedReportVals = {
			filters: JSON.stringify(this.tempFilters),
			grouping: {
				type: this.ganttService.radioSelection,
				wbsToLevel: this.ganttService.selectedWbsLevel.value,
				actvCodeGrouping: this.ganttService.selectedGroupBy,
				hideSummaryBars: this.ganttService.hideSummaryBars,
				colors: JSON.stringify(this.ganttService.wbsColorStrings),
			},
			id: this._id,
			isFavorite: this.isFavorite,
			name: this.name,
			showingCritical: this.showCritical,
			showingRelationships: this.showRelationships,
			visibleColumns: this._checkedColumns,
		};
		this.ganttService.checkResetBtn();
	}

	updateGroupingVals(): void {
		this.currentlyAppliedGrouping = structuredClone(this.ganttService.radioSelection);
		this.currentlyAppliedActvTypes = structuredClone(this.ganttService.selectedGroupBy);
		this.currentlyAppliedWbsLevel = structuredClone(this.ganttService.selectedWbsLevel);
		this.ganttService.beforeEditPresetSelectedGroupBy = structuredClone(this.ganttService.selectedGroupBy);
		this.ganttService.beforeEditPresetGroupingType = structuredClone(this.ganttService.radioSelection);
		this.ganttService.beforeEditPresetSelectedWbsLevel = structuredClone(this.ganttService.selectedWbsLevel);
		this.ganttService.beforeEditPresetHideSummaryBars = structuredClone(this.ganttService.hideSummaryBars);
		this.ganttService.beforeEditPresePuppetLevel = structuredClone(this.ganttService.puppetLevel);
	}

	isDiffThanCurrent(type: 'apply' | 'save'): boolean {
		if (type === 'apply') {
			if (
				this.saving ||
				(this.ganttService.radioSelection === 'wbs' && this.ganttService.selectedWbsLevel.value === 0) ||
				(this.ganttService.radioSelection === 'actvCodes' && this.ganttService.selectedGroupBy?.length === 0)
			) {
				return true;
			}
			let normalizedAppliedWbsLevel: number = this.ganttService.appliedReportVals.grouping.wbsToLevel;
			normalizedAppliedWbsLevel =
				normalizedAppliedWbsLevel === 0 || normalizedAppliedWbsLevel === null
					? this.ganttService.highestLevel
					: normalizedAppliedWbsLevel;
			let groupingChanges: boolean = false;
			if (
				this.ganttService.radioSelection !== this.ganttService.appliedReportVals.grouping.type ||
				(this.ganttService.radioSelection === 'wbs' &&
					(this.ganttService.selectedWbsLevel.value !== normalizedAppliedWbsLevel ||
						(this.ganttService.selectedWbsLevel.value === normalizedAppliedWbsLevel &&
							this.ganttService.appliedReportVals.grouping.wbsToLevel !== normalizedAppliedWbsLevel))) ||
				(this.ganttService.radioSelection === 'actvCodes' &&
					JSON.stringify(this.ganttService.selectedGroupBy) !==
						JSON.stringify(this.ganttService.appliedReportVals.grouping.actvCodeGrouping)) ||
				JSON.stringify(this.ganttService.tempWbsColorStrings) !== this.ganttService.appliedReportVals.grouping.colors ||
				this.ganttService.hideSummaryBars !== this.ganttService.appliedReportVals.grouping.hideSummaryBars
			) {
				groupingChanges = true;
			}
			this.ganttService.applyCanBeClicked = !(
				this.showCritical === this.ganttService.appliedReportVals.showingCritical &&
				this.showRelationships === this.ganttService.appliedReportVals.showingRelationships &&
				JSON.stringify(this._checkedColumns) === JSON.stringify(this.ganttService.appliedReportVals.visibleColumns) &&
				JSON.stringify(this.tempFilters) === this.ganttService.appliedReportVals.filters &&
				!groupingChanges
			);
			return !this.ganttService.applyCanBeClicked;
		} else {
			if (
				this.saving ||
				this.name?.length === 0 ||
				(this.ganttService.radioSelection === 'wbs' && this.ganttService.selectedWbsLevel.value === 0) ||
				(this.ganttService.radioSelection === 'actvCodes' && this.ganttService.selectedGroupBy?.length === 0)
			) {
				return true;
			}
			let folderChanges: boolean = false;
			this.ganttService.reportGroups.forEach((folder: ReportGroup) => {
				const folderHasThisReport: boolean = folder.reportIds.includes(this.ganttService.editingPresetVals.id);
				const formHasThisFolder: boolean = this.selectedFolders.findIndex((f) => f.id === folder.id) !== -1;
				if (folderHasThisReport !== formHasThisFolder) {
					folderChanges = true;
				}
			});
			let normalizedSavedWbsLevel: number = this.ganttService.editingPresetVals.grouping.wbsToLevel;
			normalizedSavedWbsLevel =
				normalizedSavedWbsLevel === 0 || normalizedSavedWbsLevel === null
					? this.ganttService.highestLevel
					: normalizedSavedWbsLevel;
			let groupingChanges: boolean = false;
			if (
				this.ganttService.radioSelection !== this.ganttService.editingPresetVals.grouping.type ||
				(this.ganttService.radioSelection === 'wbs' &&
					(this.ganttService.selectedWbsLevel.value !== normalizedSavedWbsLevel ||
						(this.ganttService.selectedWbsLevel.value === normalizedSavedWbsLevel &&
							this.ganttService.editingPresetVals.grouping.wbsToLevel !== normalizedSavedWbsLevel))) ||
				(this.ganttService.radioSelection === 'actvCodes' &&
					JSON.stringify(this.ganttService.selectedGroupBy) !==
						JSON.stringify(this.ganttService.editingPresetVals.grouping.actvCodeGrouping)) ||
				JSON.stringify(this.ganttService.tempWbsColorStrings) !== this.ganttService.editingPresetVals.grouping.colors ||
				this.ganttService.hideSummaryBars !== this.ganttService.editingPresetVals.grouping.hideSummaryBars
			) {
				groupingChanges = true;
			}
			this.ganttService.saveCanBeClicked = !(
				this.name === this.ganttService.editingPresetVals.name &&
				this.showCritical === this.ganttService.editingPresetVals.showingCritical &&
				this.showRelationships === this.ganttService.editingPresetVals.showingRelationships &&
				JSON.stringify(this._checkedColumns) === JSON.stringify(this.ganttService.editingPresetVals.visibleColumns) &&
				JSON.stringify(this.tempFilters) === this.ganttService.editingPresetVals.filters &&
				this.isFavorite === this.ganttService.editingPresetVals.isFavorite &&
				!folderChanges &&
				!groupingChanges
			);
			return !this.ganttService.saveCanBeClicked;
		}
	}

	public isSelectedAll(): boolean {
		const allOptions: string[] = structuredClone(this.ganttService.reportGroups)
			.map((g) => g.id)
			.sort((a: string, b: string) => {
				return a < b ? -1 : b < a ? 1 : 0;
			});
		const selectedOptions: string[] = structuredClone(this.selectedFolders)
			.map((g) => g.id)
			.sort((a: string, b: string) => {
				return a < b ? -1 : b < a ? 1 : 0;
			});
		return JSON.stringify(allOptions) === JSON.stringify(selectedOptions);
	}

	/**
	 * toggle selectAll folders
	 */
	onFilterSelectAllClick(): void {
		if (this.selectedFolders?.length === this.ganttService.reportGroups?.length) {
			this.selectedFolders = [];
		} else {
			this.selectedFolders = structuredClone(this.ganttService.reportGroups);
		}
	}

	public editorValueChange(value, currentItem?: FilterDescriptor, useIdFromObj?: boolean): void {
		if (currentItem) {
			currentItem.value = useIdFromObj ? value.actv_code_id.toString() : value;
		}
		this.tempFilters = this.filter.value;
		this.ganttService.presetWindowContentFilters = this.filter.value;
	}

	updateBasedOnSelection(ev: CurrentUpdateGanttPreset): void {
		this.updatePresetValues(ev, true);
	}

	protected readonly allColumns = allColumns;
}
