import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GridComponent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query/dist/npm/sort-descriptor';
import { AxisSettings, SeriesData, SeriesDataSettings } from '../../../../models/ChartSettings';
import { PlotBand } from '@progress/kendo-angular-charts';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '../../../../services/common/user.service';

@Component({
	selector: 'app-project-notes',
	templateUrl: './project-notes.component.html',
	styleUrls: ['./project-notes.component.scss'],
})
export class ProjectNotesComponent implements OnInit {
	@Input() visualizer: boolean = false;
	@Input()
	set projectReport(projectReport) {
		this._$projectReport.next(projectReport);
	}
	get projectReport(): any {
		return this._$projectReport.getValue();
	}
	@Input()
	set allUpdates(allUpdates) {
		this._$allUpdates.next(allUpdates);
	}
	get allUpdates(): any[] {
		return this._$allUpdates.getValue();
	}
	@ViewChild(GridComponent)
	public grid: GridComponent;
	public gridView: GridDataResult;
	gridData: Array<any> = [];
	loading: boolean = true;
	public sort: SortDescriptor[] = [
		{
			dir: 'asc',
			field: 'updateNumber',
		},
	];
	public pageSize = 100;
	public skip = 0;
	showNotesGrid = false;
	user: any;

	constructor(
		public scheduleStorage: ScheduleStorageService,
		public userService: UserService
	) {}
	_$projectReport = new BehaviorSubject<any>(undefined);

	_$allUpdates = new BehaviorSubject<any[]>([]);
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	scheduleData: any[];

	ngOnInit() {
		this._$projectReport.pipe(takeUntil(this._unsubscribeAll)).subscribe((report) => {
			this.getProjectNotes(report || {}, this._$allUpdates.value || []);
		});
		this._$allUpdates.pipe(takeUntil(this._unsubscribeAll)).subscribe((updates) => {
			this.getProjectNotes(this._$projectReport.value, updates || []);
		});
		this.userService.user.subscribe((data) => {
			if (data) {
				this.user = data;
			}
		});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	getProjectNotes(report: any, updates: any[] = []) {
		this.loading = true;
		const projectCompletionTrendArray = report?.projectCompletionTrend?.projectCompletionTrendArray || [];
		const schedule = [];
		let contractVariance = 0;
		for (let i = 0; i < projectCompletionTrendArray.length; i++) {
			const reportUpdateItem = projectCompletionTrendArray?.[i];
			const update = updates?.[i] || projectCompletionTrendArray?.[i];
			const updateName = i === 0 ? 'Baseline' : 'Update ' + i;
			let dataDate: Date;
			if (reportUpdateItem?.dataDate) {
				dataDate = new Date(reportUpdateItem.dataDate);
			}
			contractVariance += +reportUpdateItem?.previousVariance || 0;
			schedule.push({
				completion: reportUpdateItem?.contractCompletion,
				criticalPathNotes: update.criticalPathNotes,
				dataDate,
				deltaCcd: contractVariance,
				deltaPrev: reportUpdateItem?.previousVariance,
				name: updateName,
				timeAnalysisNotes: update.timeAnalysisNotes,
				updateNumber: i,
			});
		}
		this.scheduleData = schedule;
		this.loadNotes();
		this.loading = false;
	}

	public pageChange(event: PageChangeEvent): void {
		this.skip = event.skip;
		this.loadNotes();
	}

	public sortChange(sort: SortDescriptor[]): void {
		this.sort = sort;
		this.loadNotes();
	}

	/**
	 * sets notes grid data to be the latest data at the current scroll/page spot
	 */
	public loadNotes(): void {
		this.gridView = {
			data: this.scheduleData?.slice(this.skip, this.skip + this.pageSize),
			total: this.scheduleData?.length,
		};
		this.gridData = this.scheduleData;
	}

	reset() {
		this.skip = 0;
		this?.grid?.scrollTo({ row: 0, column: 0 });
	}

	toggleNotesGrid() {
		if (!this.scheduleStorage.isLoading) {
			this.showNotesGrid = !this.showNotesGrid;
		}
	}
}
