<div style='padding: 0 5px 5px 5px;'>
	<div>
		<div style="text-align: center; font-size: large; padding: 5px 0; background-color: #ededed; border-radius: 4px;">
			<span>{{ tableView === FloatConsumptionView.averageFloat? 'All Activities': tableView }} - Showing {{ gridView?.total || 0 }} of {{ unfilteredDisplaySet[tableView]?.length || 0 }}</span>
		</div>
		<kendo-textbox
			style='width: 100%; margin: 5px 0;'
			[placeholder]="'Search Activities...'"
			[clearButton]="true"
			[size]='"large"'
			[(value)]='searchTerm'
			(valueChange)='updateFilter({searchTerm: $event})'
			[disabled]='analyticsService.floatConsumptionLoading'
			class="searchInput"
		>
			<ng-template kendoTextBoxPrefixTemplate>
				<kendo-svgicon [icon]='svgSearch'></kendo-svgicon>
				<kendo-textbox-separator></kendo-textbox-separator>
			</ng-template>
		</kendo-textbox>
		<kendo-grid
			[data]="gridView"
			[kendoGridBinding]='gridData'
			[rowHeight]='32'
			[loading]='analyticsService.floatConsumptionLoading || !gridView'
			[size]='"small"'
			[sortable]='true'
			[sort]="sort"
			(sortChange)="sortChange($event)"
			[skip]="skip"
			[pageSize]="pageSize"
			[navigable]="false"
			class='activity-grid'
			(excelExport)="onExcelExport($event)"
			(pageChange)="pageChange($event)"
			scrollable="virtual"
			style="max-height: 251px;"
		>
			<kendo-grid-column
				*ngFor='let column of columns'
				[field]="column.field"
				[title]="column.title"
				[width]='column.width'
				[format]="column?.format"
				headerClass="header-class"
			>
				<ng-template kendoGridCellTemplate let-dataItem>
					@if (column?.type === 'date') {
						<span style='white-space: nowrap; text-overflow: ellipsis; display: block; text-align: center; padding: 0'>{{dataItem[column.field] === null ? '-' : dataItem[column.field] | date: 'MMM d, yyyy'}}</span>
					} @else if (column?.type === 'number' || column?.type === 'center-string') {
						<span style='white-space: nowrap; text-overflow: ellipsis; display: block; text-align: center; padding: 0'>{{dataItem[column.field] === null ? '-' : dataItem[column.field]}}</span>
					}@else {
						<span style='white-space: nowrap; text-overflow: ellipsis;'>{{dataItem[column.field] === null ? '-' : dataItem[column.field]}}</span>
					}
				</ng-template>
			</kendo-grid-column>
			<kendo-grid-excel fileName="AegisAnalytics-ExportFloatConsumption.xlsx" [fetchData]='allData'>
				<kendo-excelexport-column
					*ngFor='let column of EXCEL_COLUMNS'
					[field]="column.field"
					[title]="column.title"
					[width]='column.width'
				>
				</kendo-excelexport-column>
			</kendo-grid-excel>
		</kendo-grid>
	</div>
	<div *ngIf='!analyticsService.floatConsumptionLoading && (project.activitiesByCode)?.value?.size === 0' style='width: 100%;text-align: center;height: 350px; display: flex;justify-content: center; align-items: center'>
		Project is missing expanded metrics. Try recalculating report!
	</div>

</div>
