<div class="table-filter-modal">
  <h1 class="card-intro-title">Modify Performance Factors</h1>
  <p>Select the performance factors that you would like to see as part of the Monte Carlo Simulation.</p>
  <div *ngIf="!isReady">
    <ion-grid>
      <ion-row>
        <ion-col size="3"><ion-spinner name="crescent"></ion-spinner></ion-col>
        <ion-col>Populating Data.. Please Wait.</ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <div class="container mat-elevation-z1 pf-table-popup" *ngIf="isReady">
    <table mat-table [dataSource]="pfTable" class="tableStyles">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Activity Code Column -->
      <ng-container matColumnDef="activityCode">
        <th mat-header-cell *matHeaderCellDef>Activity Code</th>
        <td mat-cell *matCellDef="let pf" [className]="crossOut(selection.isSelected(pf))">{{ pf.activityCode }}</td>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let pf" [className]="crossOut(selection.isSelected(pf))">{{ pf.description }}</td>
      </ng-container>

      <!-- PF Column -->
      <ng-container matColumnDef="pf">
        <th mat-header-cell *matHeaderCellDef>PF</th>
        <td mat-cell *matCellDef="let pf" [className]="crossOut(selection.isSelected(pf))">
          <span>{{ pf.pf | number: '1.1-2' }}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="doRowCheck(row)"></tr>
    </table>
  </div>
  <p style="margin: 20px 0 0 0">Note: This will submit the Monte Carlo simulation for reprocessing.</p>
  <hr style="border-top: 1px solid lightgrey" />
  <div style="padding-top: 1rem; padding-bottom: 3rem">
    <button
      style="float: left; margin-right: 3%; font-size: 16px; color: red"
      mat-button
      color="warn"
      (click)="onNoClick()"
    >
      CANCEL
    </button>
    <button
      style="float: right; font-size: 16px"
      mat-button
      (click)="submitFilter()"
      color="primary" [disabled]="httpProcessing || (selection.isEmpty() && pfTable.length > 0)">{{httpProcessing ? 'SUBMITTING...' : pfTable.length > 0 ? 'SUBMIT' : 'INITIALIZE PF CODES'}}
    </button>
    &nbsp;
  </div>
</div>
