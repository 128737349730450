import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SharedMatModule } from 'components/shared/shared-mat.module';
import { RiskCalculationMethodComponent } from './risk-calculation-method.component';
import { AddRiskModule } from '../risk/add-risk/add-risk.module';
import { WindowModule } from '@progress/kendo-angular-dialog';
import { GridModule } from '@progress/kendo-angular-grid';

@NgModule({
	declarations: [RiskCalculationMethodComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		IonicModule,
		SharedMatModule,
		AddRiskModule,
		WindowModule,
		GridModule,
	],
	exports: [RiskCalculationMethodComponent],
})
export class RiskCalculationMethodModule {}
