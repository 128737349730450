import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { CustomFieldTreeData, MitigationEntityCollection, RiskRegister } from '../../models/risk';
import { ProjectDashboardService } from './project.service';
import { RestService } from '../common/rest.service';
import { HiddenMitigationColumnSettings } from '../../models/Project';

@Injectable()
export class RiskSettingsService extends BehaviorSubject<CustomFieldTreeData[]> {
	private originalTreeData: CustomFieldTreeData[];
	customFieldOptions = [
		{
			path: 'riskOwner',
			subpath: 'name',
			rootId: 0,
		},
		{
			path: 'riskOwner',
			subpath: 'email',
			rootId: 1,
		},
		{
			path: 'costOwner',
			subpath: 'name',
			rootId: 2,
		},
		{
			path: 'costOwner',
			subpath: 'email',
			rootId: 3,
		},
		{
			path: 'responsibility',
			subpath: 'name',
			rootId: 4,
		},
		{
			path: 'responsibility',
			subpath: 'email',
			rootId: 5,
		},
		{
			path: 'category',
			rootId: 6,
		},
	];
	confirmDialogOpen = false;
	conflictingRiskIds = [];
	lastSavedRegisters: RiskRegister[];
	lastSavedHiddenColumns: HiddenMitigationColumnSettings;
	lastSavedUnassignedCustomFields?: {
		riskOwner?: MitigationEntityCollection;
		costOwner?: MitigationEntityCollection;
		responsibility?: MitigationEntityCollection;
		category?: string[];
	};

	constructor(
		private http: HttpClient,
		private project: ProjectDashboardService,
		private restService: RestService
	) {
		super(null);
	}

	public read(forceReset?: boolean): void {
		this.fetch(forceReset)
			.pipe(take(1))
			.subscribe((data) => this.next(data));
	}

	public fetchChildren(reportsTo: number = null): Observable<CustomFieldTreeData[]> {
		return of(this.originalTreeData.filter((data) => data.reportsTo === reportsTo));
	}

	public save(item: CustomFieldTreeData, parent: CustomFieldTreeData, isNew: boolean, dataItem: CustomFieldTreeData) {
		const updatedRegisters = this.lastSavedRegisters;
		const hiddenColumns = this.lastSavedHiddenColumns;
		let unassignedCustomFields = this.lastSavedUnassignedCustomFields;
		if (unassignedCustomFields === undefined) {
			unassignedCustomFields = {
				riskOwner: {
					name: [],
					email: [],
				},
				costOwner: {
					name: [],
					email: [],
				},
				responsibility: {
					name: [],
					email: [],
				},
				category: [],
			};
		}
		const matchingFieldType = this.customFieldOptions.find((field) => field.rootId === parent.customFieldId);
		if (isNew) {
			//add item in db. will always be unassigned if added this way
			// eslint-disable-next-line @typescript-eslint/no-unused-expressions
			matchingFieldType.subpath
				? unassignedCustomFields[matchingFieldType.path][matchingFieldType.subpath].push(item.data)
				: unassignedCustomFields[matchingFieldType.path].push(item.data);
		} else {
			//update existing record. may be unassigned
			if (dataItem.riskId) {
				dataItem.riskId.forEach((riskId) => {
					const matchingIndex = updatedRegisters.findIndex((reg) => reg.riskId === riskId);
					if (matchingIndex !== -1) {
						// eslint-disable-next-line @typescript-eslint/no-unused-expressions
						matchingFieldType.subpath
							? (updatedRegisters[matchingIndex][matchingFieldType.path][matchingFieldType.subpath] = item.data)
							: (updatedRegisters[matchingIndex][matchingFieldType.path] = item.data);
					}
				});
			} else {
				const matchingUnassignedFieldType = matchingFieldType.subpath
					? unassignedCustomFields[matchingFieldType.path][matchingFieldType.subpath]
					: unassignedCustomFields[matchingFieldType.path];
				const matchingUnassignedIndex = matchingUnassignedFieldType.findIndex(
					(unassignedItem) => unassignedItem === dataItem.data
				);
				if (matchingUnassignedIndex !== -1) {
					matchingUnassignedFieldType[matchingUnassignedIndex] = item.data;
				}
			}
		}
		this.lastSavedRegisters = updatedRegisters;
		this.next(this.value);
		this.lastSavedUnassignedCustomFields = {
			riskOwner: {
				name: Array.from(unassignedCustomFields.riskOwner.name),
				email: Array.from(unassignedCustomFields.riskOwner.email),
			},
			costOwner: {
				name: Array.from(unassignedCustomFields.costOwner.name),
				email: Array.from(unassignedCustomFields.costOwner.email),
			},
			responsibility: {
				name: Array.from(unassignedCustomFields.responsibility.name),
				email: Array.from(unassignedCustomFields.responsibility.email),
			},
			category: Array.from(unassignedCustomFields.category),
		};
		this.lastSavedHiddenColumns = {
			riskPage: Array.from(hiddenColumns.riskPage),
			fullscreen: Array.from(hiddenColumns.fullscreen),
			scheduleSelector: Array.from(hiddenColumns.scheduleSelector),
		};
	}

	public remove(item: any, parent?: CustomFieldTreeData) {
		const updatedRegisters = this.project.$currentRiskRegisters.value.map((rr) => Object.assign({}, rr));
		const hiddenColumns = this.project.$currentProjectData.value.preferences.riskMitigation.hiddenMitigationColumns;
		let unassignedCustomFields =
			this.project?.$currentProjectData.value?.preferences?.riskMitigation?.unassignedCustomFields;
		if (unassignedCustomFields === undefined) {
			unassignedCustomFields = {
				riskOwner: {
					name: [],
					email: [],
				},
				costOwner: {
					name: [],
					email: [],
				},
				responsibility: {
					name: [],
					email: [],
				},
				category: [],
			};
		}
		const matchingFieldType = this.customFieldOptions.find((field) => field.rootId === parent.customFieldId);
		if (item.riskId === null) {
			//unassigned
			const matchingUnassignedFieldType = matchingFieldType.subpath
				? unassignedCustomFields[matchingFieldType.path][matchingFieldType.subpath]
				: unassignedCustomFields[matchingFieldType.path];
			const matchingUnassignedIndex = matchingUnassignedFieldType.findIndex(
				(unassignedItem) => unassignedItem === item.data
			);
			if (matchingUnassignedIndex !== -1) {
				matchingUnassignedFieldType.splice(matchingUnassignedIndex, 1);
			}
		} else {
			this.confirmDialogOpen = true;
			this.conflictingRiskIds = item.riskId;
			return of(false);
		}
		this.lastSavedRegisters = updatedRegisters;
		this.lastSavedUnassignedCustomFields = {
			riskOwner: {
				name: Array.from(unassignedCustomFields.riskOwner.name),
				email: Array.from(unassignedCustomFields.riskOwner.email),
			},
			costOwner: {
				name: Array.from(unassignedCustomFields.costOwner.name),
				email: Array.from(unassignedCustomFields.costOwner.email),
			},
			responsibility: {
				name: Array.from(unassignedCustomFields.responsibility.name),
				email: Array.from(unassignedCustomFields.responsibility.email),
			},
			category: Array.from(unassignedCustomFields.category),
		};
		this.lastSavedHiddenColumns = {
			riskPage: Array.from(hiddenColumns.riskPage),
			fullscreen: Array.from(hiddenColumns.fullscreen),
			scheduleSelector: Array.from(hiddenColumns.scheduleSelector),
		};
	}

	private fetch(forceReset?: boolean): Observable<CustomFieldTreeData[]> {
		console.log({ forceReset });
		const formattedRegisters: CustomFieldTreeData[] = [
			{
				data: '',
				field: 'Risk Owner Name',
				hasChildren: true,
				isRoot: true,
				reportsTo: null,
				riskId: null,
				customFieldId: 0,
				editable: false,
			},
			{
				data: '',
				field: 'Risk Owner Email',
				hasChildren: true,
				isRoot: true,
				reportsTo: null,
				riskId: null,
				customFieldId: 1,
				editable: false,
			},
			{
				data: '',
				field: 'Cost Owner Name',
				hasChildren: true,
				isRoot: true,
				reportsTo: null,
				riskId: null,
				customFieldId: 2,
				editable: false,
			},
			{
				data: '',
				field: 'Cost Owner Email',
				hasChildren: true,
				isRoot: true,
				reportsTo: null,
				riskId: null,
				customFieldId: 3,
				editable: false,
			},
			{
				data: '',
				field: 'Responsibility Name',
				hasChildren: true,
				isRoot: true,
				reportsTo: null,
				riskId: null,
				customFieldId: 4,
				editable: false,
			},
			{
				data: '',
				field: 'Responsibility Email',
				hasChildren: true,
				isRoot: true,
				reportsTo: null,
				riskId: null,
				customFieldId: 5,
				editable: false,
			},
			{
				data: '',
				field: 'Category',
				hasChildren: true,
				isRoot: true,
				reportsTo: null,
				riskId: null,
				customFieldId: 6,
				editable: false,
			},
		];
		const defaultCategories = ['Permitting', 'Environmental', 'Design', 'Schedule', 'Contractual'];
		defaultCategories.forEach((category) => {
			const newChild: CustomFieldTreeData = {
				data: category,
				field: '',
				hasChildren: false,
				isRoot: false,
				reportsTo: 6,
				riskId: [],
				customFieldId: formattedRegisters[formattedRegisters.length - 1].customFieldId + 1,
				editable: false,
			};
			formattedRegisters.push(newChild);
		});
		if (this.project?.$currentProjectData.value === undefined) {
			return of(formattedRegisters);
		}
		let unassignedCustomFields =
			(!forceReset && this.lastSavedUnassignedCustomFields) ||
			this.project?.$currentProjectData.value?.preferences?.riskMitigation?.unassignedCustomFields;
		if (unassignedCustomFields === undefined) {
			unassignedCustomFields = {
				riskOwner: {
					name: [],
					email: [],
				},
				costOwner: {
					name: [],
					email: [],
				},
				responsibility: {
					name: [],
					email: [],
				},
				category: [],
			};
		}
		const registers = ((!forceReset && this.lastSavedRegisters) || this.project.$currentRiskRegisters.value).map(
			(register): RiskRegister => ({
				...register,
				riskOwner: Object.assign({}, { ...register.riskOwner }),
				responsibility: Object.assign({}, { ...register.responsibility }),
				costOwner: Object.assign({}, { ...register.costOwner }),
				strategy: Object.assign({}, { ...register.strategy }),
			})
		);
		if (registers.length > 0) {
			registers.forEach((register) => {
				this.customFieldOptions.forEach((field) => {
					const matchingIndex = formattedRegisters.findIndex((reg) =>
						!reg.isRoot && reg.reportsTo === field.rootId && field.subpath
							? reg.data === register[field.path][field.subpath]
							: reg.data === register[field.path]
					);
					if (matchingIndex === -1) {
						if ((field.subpath ? register[field.path][field.subpath] : register[field.path]) !== '') {
							const newChild: CustomFieldTreeData = {
								data: field.subpath ? register[field.path][field.subpath] : register[field.path],
								field: '',
								hasChildren: false,
								isRoot: false,
								reportsTo: field.rootId,
								riskId: [register.riskId],
								customFieldId: formattedRegisters[formattedRegisters.length - 1].customFieldId + 1,
								editable: true,
							};
							formattedRegisters.push(newChild);
						}
					} else {
						if (
							formattedRegisters[matchingIndex].riskId &&
							!formattedRegisters[matchingIndex].riskId.includes(register.riskId)
						) {
							formattedRegisters[matchingIndex].riskId.push(register.riskId);
						}
					}
					const matchingArray = field.subpath
						? unassignedCustomFields[field.path][field.subpath]
						: unassignedCustomFields[field.path];
					matchingArray.forEach((unassignedField) => {
						const matchingUnassignedIndex = formattedRegisters.findIndex((reg) => reg.data === unassignedField);
						if (matchingUnassignedIndex === -1) {
							const newUnassignedChild: CustomFieldTreeData = {
								data: unassignedField,
								field: '',
								hasChildren: false,
								isRoot: false,
								reportsTo: field.rootId,
								riskId: null,
								customFieldId: formattedRegisters[formattedRegisters.length - 1].customFieldId + 1,
								editable: true,
							};
							formattedRegisters.push(newUnassignedChild);
						}
					});
				});
			});
		} else {
			//no risk registers, but there may still be unassignedCustomFields
			this.customFieldOptions.forEach((field) => {
				const matchingArray = field.subpath
					? unassignedCustomFields[field.path][field.subpath]
					: unassignedCustomFields[field.path];
				matchingArray.forEach((unassignedField) => {
					const matchingUnassignedIndex = formattedRegisters.findIndex((reg) => reg.data === unassignedField);
					if (matchingUnassignedIndex === -1) {
						const newUnassignedChild: CustomFieldTreeData = {
							data: unassignedField,
							field: '',
							hasChildren: false,
							isRoot: false,
							reportsTo: field.rootId,
							riskId: null,
							customFieldId: formattedRegisters[formattedRegisters.length - 1].customFieldId + 1,
							editable: true,
						};
						formattedRegisters.push(newUnassignedChild);
					}
				});
			});
		}
		for (const register of formattedRegisters) {
			register.hasChildren = formattedRegisters.some((reg) => reg.reportsTo === register.customFieldId);
		}
		this.originalTreeData = formattedRegisters;
		this.lastSavedRegisters = registers;
		this.lastSavedUnassignedCustomFields = {
			riskOwner: {
				name: Array.from(unassignedCustomFields.riskOwner.name),
				email: Array.from(unassignedCustomFields.riskOwner.email),
			},
			costOwner: {
				name: Array.from(unassignedCustomFields.costOwner.name),
				email: Array.from(unassignedCustomFields.costOwner.email),
			},
			responsibility: {
				name: Array.from(unassignedCustomFields.responsibility.name),
				email: Array.from(unassignedCustomFields.responsibility.email),
			},
			category: Array.from(unassignedCustomFields.category),
		};
		const hiddenColumns =
			(!forceReset && this.lastSavedHiddenColumns) ||
			this.project.$currentProjectData.value?.preferences?.riskMitigation?.hiddenMitigationColumns;
		this.lastSavedHiddenColumns = {
			riskPage: hiddenColumns === undefined ? [] : Array.from(hiddenColumns.riskPage),
			fullscreen: hiddenColumns === undefined ? [] : Array.from(hiddenColumns.fullscreen),
			scheduleSelector: hiddenColumns === undefined ? [] : Array.from(hiddenColumns.scheduleSelector),
		};
		return of(formattedRegisters.filter((reg) => reg.isRoot));
	}

	public saveAll() {
		return this.restService
			.post('project/riskregister/' + this.project.$currentProjectPageId.value, {
				data: this.lastSavedRegisters,
				hiddenColumns: this.lastSavedHiddenColumns,
				unassignedCustomFields: this.lastSavedUnassignedCustomFields,
			})
			.subscribe((data) => {
				this.project.$currentProjectData.next(data.proj);
				this.project.$currentRiskRegisters.next(data.proj.riskMitigation);
			});
	}
}
