import { Component, ElementRef, EventEmitter, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { WindowCloseResult, WindowRef, WindowService } from '@progress/kendo-angular-dialog';
import { ProjectInterface } from '../../../models/Project';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AnalyticsDashboardService } from '../../../services/analytics/analytics.service';
import { scheduleType } from '../../../util/projects';
import { ProjectDashboardService } from '../../../services/project/project.service';
import { isValidUrl, testNumericKeypress, testPaste, validUrlValidator } from '../../../util/strings';
import { RestService } from '../../../services/common/rest.service';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { Observable, Subject } from 'rxjs';
import { ProjectRedirectInterface, ProjectReportInterface } from '../../../models/ProjectReport/ProjectReport';
import { UserService } from '../../../services/common/user.service';
import { AppWindowService } from '../../../services/common/window.service';
import { RiskSettingsService } from '../../../services/project/risk-settings.service';
import { NewProjectComponent } from '../../portfolio/new-project/new-project.component';
import { ScorePlaygroundComponent } from './score-playground/score-playground.component';
import { DbSyncTabComponent } from '../db-sync-tab/db-sync-tab.component';
import { NotificationService } from '@progress/kendo-angular-notification';
import { ScheduleStorageService } from '../../../services/project/schedule-storage.service';
import { ProjectPageTab, projectPageTabs } from './layout-settings/layout-settings.component';
import { QualityPreferences } from '@rhinoworks/analytics-calculations';

@Component({
	selector: 'app-project-admin-settings',
	templateUrl: './project-admin-settings.component.html',
	styleUrls: ['./project-admin-settings.component.scss'],
})
export class ProjectAdminSettingsComponent implements OnInit {
	@Input() projectData: ProjectInterface;
	@Input() projectReport: ProjectReportInterface;
	@ViewChild('newProject') newProject: NewProjectComponent;
	@ViewChild('scorePlayground') scorePlayground: ScorePlaygroundComponent;
	public detailsFormGroup: FormGroup;
	public riskFormGroup: FormGroup = new FormGroup({
		riskMetricsType: new FormControl('default', [Validators.required]),
	});

	public globalMCVariance: [number, number] = [0, 0];
	public initialGlobalMCVariance: [number, number] = [0, 0];
	public sharepointForm: FormGroup;
	public unsavedChangesOpen = false;
	public currentTab = 0;
	public hasUnsavedDetailChanges = false;
	public hasUnsavedScoreSettings = false;
	public hasUnsavedRiskSettings = false;
	public hasUnsavedMilestoneSettings = false;
	public hasUnsavedOverviewTabSettings = false;
	public hasUnsavedLayoutTabSettings = false;
	public afterSave = new EventEmitter<ProjectInterface>();
	public resetValues = new EventEmitter<void>();
	public closeWindowAwaiting = false;
	public nextTab: number = undefined;
	public qualityChanges: QualityPreferences;
	user: any = {
		userType: 'aegis',
	};
	doingRecalc: boolean = false;
	constructor(
		public analyticsService: AnalyticsDashboardService,
		public projectService: ProjectDashboardService,
		private restService: RestService,
		private windowService: WindowService,
		public userService: UserService,
		public appWindowService: AppWindowService,
		protected riskEditService: RiskSettingsService,
		public notificationService: NotificationService,
		private storageService: ScheduleStorageService
	) {}

	ngOnInit(): void {
		if (!this.projectData) {
			return;
		}
		if (this.projectData?.isArchived) {
			this.currentTab = this.user?.userType === 'saasRisk' ? 6 : 7;
		}
		this.riskFormGroup.patchValue({ riskMetricsType: this.projectData.riskMetricsType || 'default' });

		this.globalMCVariance = [
			(this.projectData?.preferences?.monteCarlo?.pfVariance[0] ?? 0.1) * 100,
			(this.projectData?.preferences?.monteCarlo?.pfVariance[1] ?? 0.1) * 100,
		];
		this.initialGlobalMCVariance = [
			(this.projectData?.preferences?.monteCarlo?.pfVariance[0] ?? 0.1) * 100,
			(this.projectData?.preferences?.monteCarlo?.pfVariance[1] ?? 0.1) * 100,
		];
		this.sharepointForm = new FormGroup({
			sharepoint: new FormControl(this.projectData?.sharePoint ?? '', [Validators.required, validUrlValidator()]),
		});
		this.projectService.aegisPocsLoaded.subscribe((loaded) => {
			if (loaded) {
				this.detailsFormGroup = new FormGroup({
					projectCode: new FormControl(this.projectData.projectCode, Validators.required),
					projectName: new FormControl(this.projectData.name, Validators.required),
					companyId: new FormControl(this.projectData.companyId, Validators.required),
					scheduleType: new FormControl(scheduleType(this.projectData), Validators.required),
					projectType: new FormControl(this.projectData.projectType, Validators.required),
					siteLocation: new FormControl(this.projectData.siteAddressReference, Validators.required),
					lat: new FormControl(this.projectData.lat),
					lng: new FormControl(this.projectData.lng),
					pocId: new FormControl(this.projectData.pocId, Validators.required),
					company: new FormControl(this.projectData.company, Validators.required),
					clientId: new FormControl(this.projectData.clientId, Validators.required),
					sharepoint: new FormControl(this.projectData.sharePoint, Validators.required),
				});
			}
		});
		this.userService.user.subscribe((data) => {
			if (data) {
				this.user = data;
			}
		});
	}

	public onSelect(e: SelectEvent): void {
		if (this.hasChanges) {
			this.unsavedChangesOpen = true;
			this.nextTab = e.index;
			e.preventDefault();
		} else {
			this.currentTab = e.index;
			if (e.index === 4) {
				this.projectService.loadingScoresTab = true;
			}
		}
	}

	updateCanSaveRisk(ev: boolean): void {
		this.hasUnsavedRiskSettings = ev;
	}

	updateCanSaveMilestones(ev: boolean): void {
		this.hasUnsavedMilestoneSettings = ev;
	}
	updateCanSaveOverviewTab(ev: boolean): void {
		this.hasUnsavedOverviewTabSettings = ev;
	}
	updateCanSaveLayoutTab(ev: boolean): void {
		this.hasUnsavedLayoutTabSettings = ev;
	}

	public setCanSave(canSave: boolean): void {
		this.hasUnsavedDetailChanges = canSave;
	}

	saveGlobalVariance(variances: [number, number]) {
		this.globalMCVariance = variances;
	}

	saveSharepoint() {
		this.restService
			.post(`project/${this.projectData._id}`, {
				sharePoint: this.sharepointForm.value.sharepoint,
			})
			.subscribe(
				(val) => {
					this.projectData.sharePoint = this.sharepointForm.value.sharepoint;
				},
				(response) => {
					console.log('POST call in error', response);
				},
				() => {}
			);
	}

	toggleArchived(): void {
		if (this.projectService.$projectArchived.value) {
			this.saveArchiveChange();
		} else {
			this.projectService.showArchiveConfirmation.emit(true);
		}
	}
	saveArchiveChange() {
		this.restService
			.post(`project/${this.projectData?._id}/toggleArchived`, {
				isArchived: false,
			})
			.subscribe(
				(val) => {
					this.projectService.$projectArchived.next(false);
				},
				(response) => {
					console.log('POST call in error', response);
				},
				() => {}
			);
	}

	closeUnsavedWindow() {
		this.unsavedChangesOpen = false;
	}

	closeAdminWindow(override?: boolean): void {
		this.hasUnsavedDetailChanges = false;
		if (this.closeWindowAwaiting || override) {
			this.appWindowService.showingAdminSettings = false;
		}
	}

	saveAddMileChanges(): void {
		const projectId: string = this.projectService.$currentProjectData.value?._id;
		const additionalMilestones = [];
		this.storageService.additionalMilestones.forEach((milestone) => {
			additionalMilestones.push(milestone.value);
		});
		this.restService
			.post(`update/addMilesChange/${projectId}`, { additionalTrackingMilestones: additionalMilestones })
			.subscribe(
				(resp) => {
					this.hasUnsavedMilestoneSettings = false;
					this.appWindowService.showingAdminSettings = false;
					setTimeout(() => {
						this.restService
							.post(`report/calculate/${this.projectService.$currentProjectReport.value?.project?._id}`, {})
							.subscribe(
								(val) => {
									this.storageService.$manualIdUpdate.next(
										this.projectService.$currentProjectReport.value?.project?._id
									);
								},
								(response) => {
									console.log('POST call in error', response);
								},
								() => {}
							);
					}, 1000);
				},
				(response) => {
					console.log('POST call in error', response);
				}
			);
	}

	public async saveAll(discardChanges?: boolean) {
		if (discardChanges) {
			this.riskFormGroup.patchValue({ riskMetricsType: this.projectData.riskMetricsType || 'default' });
			this.globalMCVariance = [
				(this.projectData?.preferences?.monteCarlo?.pfVariance[0] ?? 0.1) * 100,
				(this.projectData?.preferences?.monteCarlo?.pfVariance[1] ?? 0.1) * 100,
			];
			if (this.closeWindowAwaiting) {
				this.closeAdminWindow();
			} else if (this.nextTab !== undefined) {
				this.currentTab = this.nextTab;
			}
			this.nextTab = undefined;
			this.hasUnsavedDetailChanges = false;
			this.closeWindowAwaiting = false;
			this.unsavedChangesOpen = false;
			this.hasUnsavedScoreSettings = false;
			this.hasUnsavedRiskSettings = false;
			this.hasUnsavedOverviewTabSettings = false;
			this.hasUnsavedLayoutTabSettings = false;
			this.projectService.resetLayoutVals();
		} else {
			const changeBody: Partial<ProjectInterface & { scheduleType: string }> = {
				riskMetricsType: this.riskFormGroup.value.riskMetricsType,
			};
			if (this.hasUnsavedScoreSettings) {
				changeBody.preferences = {
					...this.projectData.preferences,
					scores: {
						includeTypes: this.scorePlayground.currentScoreFilters,
					},
				};
			}
			changeBody.riskMitigation = this.riskEditService.lastSavedRegisters;
			changeBody.preferences = {
				...(changeBody.preferences || this.projectData.preferences),
				riskMitigation: {
					...(changeBody.preferences || this.projectData.preferences)?.riskMitigation,
					hiddenMitigationColumns: this.riskEditService.lastSavedHiddenColumns,
					unassignedCustomFields: this.riskEditService.lastSavedUnassignedCustomFields,
				},
				monteCarlo: { ...((changeBody.preferences || this.projectData.preferences)?.monteCarlo || {}) },
			};
			changeBody.preferences.monteCarlo.pfVariance = [this.globalMCVariance[0] / 100, this.globalMCVariance[1] / 100];
			if (this.hasUnsavedDetailChanges) {
				changeBody.name = this.newProject.form.value.projectDetails.projectName;
				changeBody.projectCode = this.newProject.form.value.projectDetails.projectCode;
				changeBody.projectType = this.newProject.form.value.projectDetails.projectType;
				changeBody.lat = this.newProject.form.value.projectDetails.lat;
				changeBody.lng = this.newProject.form.value.projectDetails.lng;
				changeBody.siteAddressReference = this.newProject.form.value.projectDetails.siteLocation;
				changeBody.scheduleType = this.newProject.form.value.projectDetails.scheduleType;
				changeBody.pocId = this.newProject.form.value.projectDetails.pocId;
				changeBody.company = this.newProject.form.value.projectDetails.company;
				changeBody.clientId = this.newProject.form.value.projectDetails.clientId;
			}
			if (this.projectService?.selectedOverviewTabComponents?.length > 0) {
				changeBody.selectedOverviewTabComponents = this.projectService.selectedOverviewTabComponents;
			}
			changeBody.overviewTabDefault = this.projectService.overviewTabDefault;
			changeBody.hiddenTabs = this.hasUnsavedLayoutTabSettings
				? this.projectService.hiddenLayoutTabs
				: this.projectService.$currentProjectData.value?.hiddenTabs;
			changeBody.hiddenComponents = this.hasUnsavedLayoutTabSettings
				? this.projectService.hiddenLayoutComponents
				: this.projectService.$currentProjectData.value?.hiddenComponents;
			const existingClients = this.userService.clients.value;
			const promises: Promise<void>[] = [];
			if (changeBody.company && changeBody.clientId && !existingClients.has(changeBody.clientId)) {
				promises.push(
					new Promise<void>((resolve) =>
						this.restService
							.postAccount('v2/api/client/', undefined, undefined, {
								name: changeBody.clientId,
								parent: changeBody.company,
								viewerEmails: [],
							})
							.subscribe((results: any) => {
								changeBody.clientId = results.client.id;
								resolve();
							})
					)
				);
			}
			await Promise.all(promises);
			this.restService.post(`project/${this.projectData._id}`, changeBody).subscribe(
				(val) => {
					this.projectData = val.project;
					this.projectService.$currentProjectData.next(this.projectData);
					this.projectService.$currentRiskRegisters.next(this.projectData.riskMitigation);
				},
				(response) => {
					console.log('POST call in error', response);
				},
				() => {
					if (this.closeWindowAwaiting) {
						this.closeAdminWindow();
					} else if (this.nextTab !== undefined) {
						this.currentTab = this.nextTab;
					}
					this.nextTab = undefined;
					this.closeWindowAwaiting = false;
					this.hasUnsavedDetailChanges = false;
					this.closeWindowAwaiting = false;
					this.unsavedChangesOpen = false;
					this.userService.user.next(this.userService.user.value);
					if (this.hasUnsavedScoreSettings) {
						this.closeWindowAwaiting = true;
						this.projectService.saveDisabledScorePlayground = true;
						this.restService.post(`report/calculate/${this.projectData._id}`, {}).subscribe(
							() => {},
							(response) => {
								console.log('POST call in error', response);
							},
							() => {
								this.closeAdminWindow();
							}
						);
					}
					if (this.hasUnsavedScoreSettings) {
						this.scorePlayground.updateSelections();
					}
					this.hasUnsavedScoreSettings = false;
					this.hasUnsavedRiskSettings = false;
					this.afterSave.emit(this.projectData);
					if (this.currentTab === 5 || this.currentTab === 6) {
						window.location.reload();
					}
					if (this.projectService.$currentProjectReport.value?.permissionData.role === 'viewer') {
						this.closeAdminWindow();
						window.location.reload();
					}
				}
			);
		}
	}

	/**
	 * score playground reset
	 */
	resetScorePlayground(): void {
		this.resetValues.emit();
	}

	public get hasChanges(): boolean {
		return (
			!!this.qualityChanges ||
			this.hasUnsavedDetailChanges ||
			this.hasUnsavedScoreSettings ||
			this.hasUnsavedRiskSettings ||
			this.hasUnsavedMilestoneSettings ||
			this.hasUnsavedOverviewTabSettings ||
			this.hasUnsavedLayoutTabSettings ||
			(this.projectData.preferences &&
				this.projectData?.preferences.monteCarlo &&
				(this.projectData.preferences.monteCarlo.pfVariance[0] !== this.globalMCVariance[0] / 100 ||
					this.projectData.preferences.monteCarlo.pfVariance[1] !== this.globalMCVariance[1] / 100)) ||
			this.projectData.riskMetricsType !== this.riskFormGroup.value.riskMetricsType ||
			this.globalMCVariance[0] !== this.initialGlobalMCVariance[0] ||
			this.globalMCVariance[1] !== this.initialGlobalMCVariance[1]
		);
	}

	protected readonly testNumericKeypress = testNumericKeypress;
	protected readonly testPaste = testPaste;

	openViewerDisplayBox() {
		this.restService.post('pr', this.setupNewData()).subscribe({
			next: (e) => {
				this.notificationService.show({
					content: 'Activated Client Editing',
				});
			},
		});
	}

	setupNewData(): ProjectRedirectInterface {
		return {
			projectId: this.projectData._id,
			name: this.projectData.name,
			projectCode: this.projectData.projectCode,
			isRedirect: true,
			projectType: this.projectData.projectType,
			siteAddressReference: this.projectData.siteAddressReference,
			lat: this.projectData.lat,
			lng: this.projectData.lng,
			pocId: this.projectData.pocId,
			company: this.projectData.company,
			clientId: this.projectReport.permissionData.viewer_by,
			imageBase64: this.projectData.imageBase64,
		} as ProjectRedirectInterface;
	}

	refreshCalculations() {
		this.doingRecalc = true;
		this.restService
			.post(`report/calculate/${this.projectService.$currentProjectReport.value?.project?._id}`, {})
			.subscribe(
				(val) => {
					this.doingRecalc = false;
					this.storageService.$manualIdUpdate.next(this.projectService.$currentProjectReport.value?.project?._id);
					this.appWindowService.showingAdminSettings = false;
				},
				(response) => {
					console.log('POST call in error', response);
					this.doingRecalc = false;
					this.appWindowService.showingAdminSettings = false;
				},
				() => {}
			);
	}
}
