import { NgModule } from '@angular/core';
import { OverviewNotesComponent } from './overview-notes.component';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { DatePipe } from '@angular/common';
import { LabelModule } from '@progress/kendo-angular-label';
import { SharedModule } from '@progress/kendo-angular-dropdowns';
import { TextAreaModule } from '@progress/kendo-angular-inputs';
import { ExpansionPanelModule } from '@progress/kendo-angular-layout';

@NgModule({
	declarations: [OverviewNotesComponent],
	imports: [ButtonModule, DatePipe, LabelModule, SharedModule, TextAreaModule, ExpansionPanelModule],
	exports: [OverviewNotesComponent],
})
export class OverviewNotesModule {}
