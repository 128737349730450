<div class="component-container">
	<div class="title">
		Risk Register
	</div>
	<div class="table-container">
		<table mat-table [dataSource]="selectableRegisters" class="tableStyles">
			<ng-container matColumnDef="select">
				<th mat-header-cell *matHeaderCellDef  style='padding: 2px !important'>
					<mat-checkbox
						(change)="$event ? toggleAll() : null"
						[checked]="!numDisabled"
						[indeterminate]="numDisabled > 0 && numDisabled < selectableRegisters.length"
						style="display: flex;"
					>
					</mat-checkbox>
				</th>
				<td mat-cell *matCellDef="let row" style='padding: 2px !important'>
					<mat-checkbox
						(click)="$event.stopPropagation()"
						(change)="toggleRow(row)"
						[checked]="!row.disabled"
						style="display: flex;"
					>
					</mat-checkbox>
				</td>
			</ng-container>

			<ng-container matColumnDef="riskId">
				<th mat-header-cell *matHeaderCellDef>Risk ID</th>
				<td mat-cell *matCellDef="let register" [class.crossout]='register.disabled' style='padding: 0 4px 0 4px'>{{ register.riskId }}</td>
			</ng-container>

			<ng-container matColumnDef="riskName">
				<th mat-header-cell *matHeaderCellDef>Risk Name</th>
				<td mat-cell *matCellDef="let register" [class.crossout]='register.disabled' >{{ register.riskName }}</td>
			</ng-container>

			<ng-container matColumnDef="impact">
				<th mat-header-cell *matHeaderCellDef>Impact</th>
				<td mat-cell *matCellDef="let register" [class.crossout]='register.disabled'>
					<span>{{ round(register.preMitigation?.activityImpact * 100)}}%</span>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="['select', 'riskId', 'riskName', 'impact']; sticky: true"></tr>
			<tr mat-row *matRowDef="let row; columns: ['select', 'riskId', 'riskName', 'impact']"></tr>
		</table>
	</div>

	<div style="text-align: center">
		{{selectableRegisters.length - numDisabled}} / {{selectableRegisters.length}} Risks Selected
	</div>
	<div>
		<ion-button
			style="float: left; font-size: 13px; --background: #e04646;"
			[disabled]='!cancelEnabled'
			(click)="resetSelection()"
			size='small'>
			Cancel
		</ion-button>
		<ion-button
			style="float: right; font-size: 13px; --background: #2ebb2e;"
			(click)="saveRegisters()"
			[disabled]='recalcing || !recalcEnabled'
			size='small'>
			{{recalcing ? 'Calculating...' : 'Calculate'}}
		</ion-button>
	</div>


</div>
