<kendo-gridlayout
	[rows]="['164.4']"
	[cols]="['calc((220% / 12) - (15px / 4))', 'calc((300% / 12) - (15px / 4))', 'calc((340% / 12) - (15px / 4))', 'calc((340% / 12) - (15px / 4))']"
	[gap]="{ rows: 0, cols: 5 }"
	class='grid'
>
	<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-container'>
		<kendo-gridlayout
			[rows]="['42.4px', '122px']"
			[cols]="['100%']"
			[gap]="{ rows: 0, cols: 0 }"
			class='grid'
		>
			<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container shadow' style='background-color: #1c9400'>
				<div class="card-header">
					Costs Summary
				</div>
			</kendo-gridlayout-item>
			<kendo-gridlayout-item [row]='2' [col]='1'>
				<kendo-gridlayout
					[rows]="['122px']"
					[cols]="['calc(100% / 2 - 2.5px)', 'calc(100% / 2 - 2.5px)']"
					[gap]="{ rows: 0, cols: 5 }"
					class='grid'
				>
					<kendo-gridlayout-item
						[row]='1'
						[col]='1'
						class='card-content'
						[class.filter-selected-card-black]="selectedQuickView === 'costActive'"
						(click)="quickViewChange('costActive')"
					>
						<div class="centerBoth" >{{ (costService.$activeCostProjects | async) || '-' }}</div>
						<div class="filterBoxLabel" style="margin-bottom: -16px;">Active Cost-Loaded Projects</div>
					</kendo-gridlayout-item>
					<kendo-gridlayout-item
						[row]='1'
						[col]='2'
						class='card-content'
						style="cursor: default;"
					>
						<div class="centerBoth" style="font-size: 2.7vw !important;">{{ (costService.$latestSPI | async | number: '1.0-2') || '-' }}</div>
						<div class="filterBoxLabel">Current SPI</div>
					</kendo-gridlayout-item>
					<!--<kendo-gridlayout-item
						[row]='1'
						[col]='3'
					>
						<kendo-gridlayout
							[rows]="['calc((100% / 3) - (10px / 3))', 'calc((100% / 3) - (10px / 3))', 'calc((100% / 3) - (10px / 3))']"
							[cols]="['100%']"
							[gap]="{ rows: 5, cols: 5 }"
							class='grid'
							style='height: 122px; font-size: 13px; font-weight: bold'
						>
							<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-item card-content' style='display: flex; background-color: #1d66fc; color: white; cursor: default;'>
								<div style='margin: auto 0; width: 100%; text-align: center;'>
									<div class="valueLabelMoney"><span *ngIf='(costService.$previousMonth | async) !== null'>$</span>{{ (costService.$previousMonth | async | number: '1.0-0') || '-' }}</div>
									<div class="labelLabel">{{ (costService.$monthNames | async)[0] }} Actual</div>
								</div>
							</kendo-gridlayout-item>
							<kendo-gridlayout-item [row]='2' [col]='1' class='gridlayout-item card-content' style='display: flex; background-color: #5fca46; color: white; border-radius: 4px; cursor: default;'>
								<div style='margin: auto 0; width: 100%; text-align: center;'>
									<div class="valueLabelMoney"><span *ngIf='(costService.$thisMonth | async) !== null'>$</span>{{ (costService.$thisMonth | async | number: '1.0-0') || '-' }}</div>
									<div class="labelLabel">{{ (costService.$monthNames | async)[1] }} Planned</div>
								</div>
							</kendo-gridlayout-item>
							<kendo-gridlayout-item [row]='3' [col]='1' class='gridlayout-item card-content' style='display: flex; background-color: #ffb500; color: white; border-radius: 4px; cursor: default;'>
								<div style='margin: auto 0; width: 100%; text-align: center;'>
									<div class="valueLabelMoney"><span *ngIf='(costService.$nextMonth | async) !== null'>$</span>{{ (costService.$nextMonth | async | number: '1.0-0') || '-' }}</div>
									<div class="labelLabel">{{ (costService.$monthNames | async)[2] }} Planned</div>
								</div>
							</kendo-gridlayout-item>
						</kendo-gridlayout>
					</kendo-gridlayout-item>-->
				</kendo-gridlayout>
			</kendo-gridlayout-item>
		</kendo-gridlayout>
	</kendo-gridlayout-item>
	<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-container'>
		<kendo-gridlayout
			[rows]="['42.4px', '122px']"
			[cols]="['100%']"
			[gap]="{ rows: 0, cols: 0 }"
			class='grid'
		>
			<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container shadow' style='background-color: #1c9400'>
				<div class="card-header">
					Baseline Historic Performance
				</div>
			</kendo-gridlayout-item>
			<kendo-gridlayout-item [row]='2' [col]='1' class='card-content'>
				<app-historic-performance
					[projects]='$costProjects'
					[loading]='loading'
					[categoriesHistorical]='categoriesHistorical'
					style="height: 122px; cursor: default;"
				></app-historic-performance>
			</kendo-gridlayout-item>
		</kendo-gridlayout>
	</kendo-gridlayout-item>
	<kendo-gridlayout-item [row]='1' [col]='3' class='gridlayout-container'>
		<kendo-gridlayout
			[rows]="['42.4px', '122px']"
			[cols]="['100%']"
			[gap]="{ rows: 0, cols: 0 }"
			class='grid'
		>
			<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container shadow' style='background-color: #1c9400'>
				<div class="card-header">
					Periodic Remaining Costs
				</div>
			</kendo-gridlayout-item>
			<kendo-gridlayout-item [row]='2' [col]='1' class='card-content'>
				<app-cumulative-cash-flow
					[projects]='$costProjects'
					[loading]='loading'
					[categoriesOther]='categoriesOther'
					[isPeriod]='true'
					style="height: 122px; cursor: default;"
				></app-cumulative-cash-flow>
			</kendo-gridlayout-item>
		</kendo-gridlayout>
	</kendo-gridlayout-item>
	<kendo-gridlayout-item [row]='1' [col]='4' class='gridlayout-container'>
		<kendo-gridlayout
			[rows]="['42.4px', '122px']"
			[cols]="['100%']"
			[gap]="{ rows: 0, cols: 0 }"
			class='grid'
		>
			<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container shadow' style='background-color: #1c9400'>
				<div class="card-header">
					Cumulative Remaining Costs
				</div>
			</kendo-gridlayout-item>
			<kendo-gridlayout-item [row]='2' [col]='1' class='card-content'>
				<app-cumulative-cash-flow
					[projects]='$costProjects'
					[loading]='loading'
					[categoriesOther]='categoriesOther'
					[isPeriod]='false'
					style="height: 122px; cursor: default;"
				></app-cumulative-cash-flow>
			</kendo-gridlayout-item>
		</kendo-gridlayout>
	</kendo-gridlayout-item>
</kendo-gridlayout>
