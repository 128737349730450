import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ProjectDashboardService } from '../../../../services/project/project.service';
import { hasObjChanged } from '../../../../util/projects';
import { allComponents, OverviewComponent } from '../overview-tab-settings/overview-tab-settings.component';
import {
	arrowRightIcon,
	eyeIcon,
	eyeSlashIcon,
	menuIcon,
	pencilIcon,
	searchIcon,
	warningCircleIcon,
	xCircleIcon,
	xIcon,
} from '@progress/kendo-svg-icons';
import { CostService } from '../../../../services/common/cost.service';
import { ProfileCompanyPermission } from '../../../../models/auth/account-user';
import { ChipRemoveEvent } from '@progress/kendo-angular-buttons';
import { ChipContentClickEvent } from '@progress/kendo-angular-buttons/chip/chip-content-click-event-args.interface';

export interface ProjectPageTab {
	name: string;
	id: string;
	required: boolean;
	defaultSequence: number;
	parentTab?: string;
	licenseExclusions?: string;
	disabled: boolean;
}
export const projectPageTabs = require('./project-page-tabs.json') as {
	tabs: ProjectPageTab[];
};
@Component({
	selector: 'app-layout-settings',
	templateUrl: './layout-settings.component.html',
	styleUrls: ['./layout-settings.component.scss'],
})
export class LayoutSettingsComponent implements OnInit {
	@Output() canSave: EventEmitter<boolean> = new EventEmitter<boolean>(false);
	@Output() canSaveOverview: EventEmitter<boolean> = new EventEmitter<boolean>(false);
	radioSelection: 'default' | 'custom' = 'default';
	icons = {
		close: xCircleIcon,
		move: menuIcon,
		arrowRight: arrowRightIcon,
		x: xIcon,
		search: searchIcon,
		pencil: pencilIcon,
		eye: eyeIcon,
		hideEye: eyeSlashIcon,
		warning: warningCircleIcon,
	};
	allComponentOptions: OverviewComponent[] = [];
	availableComponentOptions: OverviewComponent[] = [];
	selectedComponentOptions: OverviewComponent[] = [];
	componentsByTab: Map<string, OverviewComponent[]> = new Map<string, OverviewComponent[]>([]);
	searchTerm: string = '';
	isValidCostProject: boolean = false;
	lastSavedSelectedTabs: string[] = [];
	lastSavedHiddenComponents: OverviewComponent[] = [];
	lastSavedRadio: 'default' | 'custom' = 'default';
	currentProjectCompanyPermissions: ProfileCompanyPermission = null;
	chips: Array<ProjectPageTab & { selected?: boolean }> = [];
	tabs: Array<ProjectPageTab> = [];
	hiddenComponents: OverviewComponent[] = [];
	hiddenTabs: string[];
	filteredTab: string = 'overview';
	constructor(
		public projectService: ProjectDashboardService,
		public costService: CostService
	) {}

	ngOnInit() {
		this.projectService.$currentProjectData.subscribe((data) => {
			// this.currentProjectCompanyPermissions =
			// 	data === undefined ? null : this.navBarStorage.companyPermissionMap.get(data?.company);
			this.initComponentOptions();
		});
	}

	initComponentOptions(): void {
		const rmt: string = this.projectService.$currentProjectReport.value?.project?.riskMetricsType;
		let allComponentsOptions: OverviewComponent[] = [];
		this.isValidCostProject = this.costService.isValidCostProject(
			this.projectService.$currentProjectReport.value?.cashFlowHistorical || null
		);
		allComponents.components.forEach((component: OverviewComponent) => {
			component.disabled = false;
			if (!component.disabled && component.tabOrigin === 'cost') {
				component.disabled = !this.isValidCostProject;
			}
			if (!component.disabled && component?.licenseExclusions) {
				component.disabled = component.licenseExclusions.includes(this.currentProjectCompanyPermissions?.license);
			}
			if (!component.disabled && component?.rmtRequirement) {
				component.disabled = !component.rmtRequirement.includes(rmt);
			}
			if (component.id !== 26) {
				allComponentsOptions.push(component);
				let componentsForTab: OverviewComponent[] = this.componentsByTab.get(component.tabOrigin) || [];
				componentsForTab.push(component);
				componentsForTab = componentsForTab.sort((a: OverviewComponent, b: OverviewComponent) => {
					return a.sequenceWithinTab < b.sequenceWithinTab ? -1 : b.sequenceWithinTab < a.sequenceWithinTab ? 1 : 0;
				});
				this.componentsByTab.set(component.tabOrigin, componentsForTab);
			}
		});
		allComponentsOptions = allComponentsOptions.sort((a: OverviewComponent, b: OverviewComponent) => {
			if (a.id < b.id) {
				return -1;
			}
			if (b.id < a.id) {
				return 1;
			}
			return 0;
		});
		this.allComponentOptions = allComponentsOptions;
		const hiddenTabs: string[] = this.projectService.$currentProjectData.value?.hiddenTabs || [];
		this.hiddenTabs = structuredClone(hiddenTabs);
		const lastSavedSelectedTabs: string[] = [];
		// this.chips = structuredClone(projectPageTabs.tabs);
		// this.chips.forEach((chip: ProjectPageTab & {selected?: boolean}) => {
		// 	chip.selected = !hiddenTabs.includes(chip.id);
		// 	if (chip.selected) {
		// 		lastSavedSelectedTabs.push(chip.id);
		// 	}
		// });
		const tabs: ProjectPageTab[] = [];
		projectPageTabs.tabs.forEach((tab: ProjectPageTab) => {
			if ((!hiddenTabs?.length || !hiddenTabs.includes(tab.id)) && tab.id !== 'manage') {
				lastSavedSelectedTabs.push(tab.id);
			}
			const newTab: ProjectPageTab = structuredClone(tab);
			if (hiddenTabs.includes(tab.id)) {
				newTab.disabled = true;
			}
			if (tab.id !== 'manage') {
				tabs.push(newTab);
			}
		});
		this.tabs = tabs;
		this.lastSavedSelectedTabs = lastSavedSelectedTabs;
		const hiddenComponents: OverviewComponent[] = this.projectService.$currentProjectData.value?.hiddenComponents || [];
		this.selectedComponentOptions = structuredClone(allComponentsOptions).filter(
			(a: OverviewComponent) =>
				a.tabOrigin !== 'overview' &&
				a.tabOrigin !== 'manage' &&
				!hiddenTabs.includes(a.tabOrigin) &&
				(a.tabOrigin === this.filteredTab || this.filteredTab === '') &&
				hiddenComponents.findIndex((s: OverviewComponent) => s.id === a.id) === -1 &&
				!a.disabled
		);
		this.availableComponentOptions = structuredClone(allComponentsOptions).filter(
			(a: OverviewComponent) =>
				!hiddenTabs.includes(a.tabOrigin) &&
				a.tabOrigin !== 'overview' &&
				a.tabOrigin !== 'manage' &&
				(a.tabOrigin === this.filteredTab || this.filteredTab === '') &&
				this.selectedComponentOptions.findIndex((s: OverviewComponent) => s.id === a.id) === -1
		);
		this.lastSavedHiddenComponents = structuredClone(hiddenComponents);
		this.hiddenComponents = structuredClone(hiddenComponents);
		// this.lastSavedRadio =
		// 	this.projectService.$currentProjectData.value?.layoutDefault || (hiddenTabs?.length === 0 && hiddenComponents?.length === 0)
		// 		? 'default'
		// 		: 'custom';
		this.radioSelection = structuredClone(this.lastSavedRadio);
		this.projectService.layoutTabDefault = this.lastSavedRadio === 'default';
		this.updateCanSave();
	}

	updateLists(): void {
		this.selectedComponentOptions = structuredClone(this.allComponentOptions).filter(
			(a: OverviewComponent) =>
				a.tabOrigin !== 'overview' &&
				a.tabOrigin !== 'manage' &&
				!this.hiddenTabs.includes(a.tabOrigin) &&
				(a.tabOrigin === this.filteredTab || this.filteredTab === '') &&
				this.hiddenComponents.findIndex((s: OverviewComponent) => s.id === a.id) === -1 &&
				!a.disabled
		);
		this.availableComponentOptions = structuredClone(this.allComponentOptions).filter(
			(c: OverviewComponent) =>
				this.selectedComponentOptions.findIndex((s: OverviewComponent) => s.id === c.id) === -1 &&
				c.tabOrigin !== 'overview' &&
				c.tabOrigin !== 'manage' &&
				(c.tabOrigin === this.filteredTab || this.filteredTab === '') &&
				!this.hiddenTabs.includes(c.tabOrigin)
		);
		this.updateCanSave();
	}

	radioChoiceChanged(ev, val): void {
		this.radioSelection = val;
		this.projectService.layoutTabDefault = val === 'default';
		this.updateCanSave();
	}

	moveItem(item: OverviewComponent): void {
		this.hiddenComponents = this.hiddenComponents.filter((c: OverviewComponent) => c.id !== item.id);
		this.availableComponentOptions = this.availableComponentOptions.filter((o: OverviewComponent) => o.id !== item.id);
		this.selectedComponentOptions.push(item);
		this.updateCanSave();
	}

	deselectItem(item: OverviewComponent): void {
		this.hiddenComponents.push(item);
		this.selectedComponentOptions = this.selectedComponentOptions.filter((s: OverviewComponent) => s.id !== item.id);
		this.availableComponentOptions = structuredClone(this.allComponentOptions).filter(
			(c: OverviewComponent) =>
				this.selectedComponentOptions.findIndex((s: OverviewComponent) => s.id === c.id) === -1 &&
				c.tabOrigin !== 'overview' &&
				c.tabOrigin !== 'manage' &&
				(c.tabOrigin === this.filteredTab || this.filteredTab === '') &&
				!this.hiddenTabs.includes(c.tabOrigin)
		);
		this.updateCanSave();
	}

	moveAll(): void {
		const copyOfSelected: OverviewComponent[] = structuredClone(this.selectedComponentOptions);
		const copyOfOptions: OverviewComponent[] = structuredClone(this.availableComponentOptions).filter(
			(c: OverviewComponent) =>
				!c.disabled &&
				c.tabOrigin !== 'overview' &&
				c.tabOrigin !== 'manage' &&
				!this.hiddenTabs.includes(c.tabOrigin) &&
				(c.tabOrigin === this.filteredTab || this.filteredTab === '')
		);
		this.selectedComponentOptions = [...copyOfSelected.values(), ...copyOfOptions.values()];
		this.availableComponentOptions = structuredClone(this.allComponentOptions).filter(
			(c: OverviewComponent) =>
				c.disabled &&
				c.tabOrigin !== 'overview' &&
				c.tabOrigin !== 'manage' &&
				!this.hiddenTabs.includes(c.tabOrigin) &&
				(c.tabOrigin === this.filteredTab || this.filteredTab === '')
		);
		if (this.filteredTab === '') {
			this.hiddenComponents = [];
		} else {
			this.hiddenComponents = this.hiddenComponents.filter(
				(c: OverviewComponent) => copyOfOptions.findIndex((o: OverviewComponent) => o.id === c.id) === -1
			);
		}
		this.updateCanSave();
	}

	removeAll(): void {
		const copyOfSelected: OverviewComponent[] = structuredClone(this.selectedComponentOptions);
		this.selectedComponentOptions = [];
		this.availableComponentOptions = structuredClone(this.allComponentOptions).filter(
			(c: OverviewComponent) =>
				c.tabOrigin !== 'overview' &&
				c.tabOrigin !== 'manage' &&
				!this.hiddenTabs.includes(c.tabOrigin) &&
				(c.tabOrigin === this.filteredTab || this.filteredTab === '')
		);
		if (this.filteredTab === '') {
			this.hiddenComponents = structuredClone(this.allComponentOptions).filter((c: OverviewComponent) => !c.disabled);
		} else {
			copyOfSelected.forEach((c: OverviewComponent) => {
				this.hiddenComponents.push(c);
			});
		}
		this.updateCanSave();
	}

	updateCanSave(): void {
		this.projectService.hiddenLayoutTabs = structuredClone(this.hiddenTabs);
		this.projectService.hiddenLayoutComponents = structuredClone(this.hiddenComponents);
		const currentSelectedTabs: string[] = [];
		this.tabs.forEach((tab: ProjectPageTab) => {
			if (!tab.disabled) {
				currentSelectedTabs.push(tab.id);
			}
		});
		const lastSavedHiddenIds: number[] = [];
		const currentHiddenIds: number[] = [];
		this.lastSavedHiddenComponents.forEach((component: OverviewComponent) => {
			lastSavedHiddenIds.push(component.id);
		});
		this.hiddenComponents.forEach((component: OverviewComponent) => {
			currentHiddenIds.push(component.id);
		});
		this.projectService.hiddenLayoutComponents = structuredClone(this.hiddenComponents);
		const unfilteredSelected: OverviewComponent[] = structuredClone(this.allComponentOptions).filter(
			(a: OverviewComponent) =>
				a.tabOrigin !== 'overview' &&
				a.tabOrigin !== 'manage' &&
				!this.hiddenTabs.includes(a.tabOrigin) &&
				this.hiddenComponents.findIndex((s: OverviewComponent) => s.id === a.id) === -1 &&
				!a.disabled
		);
		this.canSave.emit(
			hasObjChanged(currentSelectedTabs, this.lastSavedSelectedTabs) ||
				(hasObjChanged(currentHiddenIds, lastSavedHiddenIds) && unfilteredSelected?.length > 0)
		);
	}

	search(term: string): void {
		this.availableComponentOptions =
			term === ''
				? this.allComponentOptions.filter(
						(c: OverviewComponent) => this.selectedComponentOptions.findIndex((s) => s.id === c.id) === -1
					)
				: this.allComponentOptions.filter(
						(c: OverviewComponent) =>
							c.name.toLowerCase().includes(term.toLowerCase()) &&
							this.selectedComponentOptions.findIndex((s: OverviewComponent) => s.id === c.id) === -1
					);
	}

	public onRemove(e: ChipRemoveEvent): void {
		const index: number = this.chips.findIndex((c) => c.name === e.sender.label);
		this.chips[index].selected = false;
	}

	selectionChange(e: ChipContentClickEvent): void {
		const index: number = this.chips.findIndex((c) => c.name === e.sender.label);
		this.chips[index].selected = !this.chips[index].selected;
	}

	editItem(item: ProjectPageTab): void {
		if (!['reports', 'compare', 'focus'].includes(item.id) && !item.required) {
			this.filteredTab = this.filteredTab !== item.id ? item.id : '';
			this.updateLists();
		}
	}

	toggleTabVis(ev, tab: ProjectPageTab): void {
		ev.cancelBubble = true;
		ev.stopPropagation();
		const index: number = this.tabs.findIndex((t: ProjectPageTab) => t.id === tab.id);
		this.tabs[index].disabled = !this.tabs[index].disabled;
		if (this.tabs[index].disabled) {
			this.hiddenTabs.push(tab.id);
		} else {
			this.hiddenTabs = this.hiddenTabs.filter((t: string) => t !== tab.id);
		}
		this.updateLists();
	}

	allComponentsOnTabHidden(id: string): boolean {
		if (!['progress', 'qc', 'cost', 'risk', 'analysis'].includes(id)) {
			return false;
		}
		const componentsOnTab: OverviewComponent[] = allComponents.components.filter(
			(c: OverviewComponent) => c.tabOrigin === id
		);
		const hiddenComponentsOnTab: OverviewComponent[] = this.hiddenComponents.filter(
			(c: OverviewComponent) => c.tabOrigin === id
		);
		if (hiddenComponentsOnTab?.length === 0) {
			return false;
		}
		return hiddenComponentsOnTab?.length === componentsOnTab?.length;
	}

	updateCanSaveOverviewTab(ev: boolean): void {
		this.canSaveOverview.next(ev);
	}

	protected readonly projectPageTabs = projectPageTabs;
}
