import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SharedMatModule } from 'components/shared/shared-mat.module';
import { TooltipMenuModule } from '../../../portfolio/tooltip-menu/tooltip-menu.module';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { AegisChartModule } from '../../../shared/chart/chart.module';
import { GridLayoutModule } from '@progress/kendo-angular-layout';
import { ScheduleCompressionComponent } from './schedule-compression.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { PipeModule } from '../../../../util/pipes/pipe.module';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { OverviewNotesModule } from '../../../shared/overview-notes/overview-notes.module';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

@NgModule({
	declarations: [ScheduleCompressionComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		IonicModule,
		SharedMatModule,
		TooltipMenuModule,
		ChartsModule,
		AegisChartModule,
		GridLayoutModule,
		GridModule,
		PipeModule,
		ProgressBarModule,
		OverviewNotesModule,
		ButtonModule,
		TooltipModule,
	],
	exports: [ScheduleCompressionComponent],
})
export class ScheduleCompressionModule {}
