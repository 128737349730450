<div class="tab-page-container">
	@if (!projectService.componentHidden(9)) {
		<kendo-gridlayout
			[rows]="['345.4px']"
			[cols]="[visualizer ? '50%' : '100%']"
			[gap]="{ rows: 5, cols: visualizer ? 5 : 0 }"
			class='grid'
		>
			<kendo-gridlayout-item [row]='1' [col]='1' [colSpan]='visualizer ? 2 : 1'>
				<app-qc-trend [visualizer]='visualizer'></app-qc-trend>
			</kendo-gridlayout-item>
		</kendo-gridlayout>
	}
	@if (!projectService.componentHidden(10)) {
		<kendo-gridlayout
			[rows]="['719.96px']"
			[cols]="[visualizer ? '50%' : '100%']"
			[gap]="{ rows: 5, cols: visualizer ? 5 : 0 }"
			class='grid'
		>
			<kendo-gridlayout-item [row]='1' [col]='1'>
				<app-schedule-analysis [visualizer]='visualizer'></app-schedule-analysis>
			</kendo-gridlayout-item>
		</kendo-gridlayout>
	}
	@if (!projectService.componentHidden(11)) {
		<kendo-gridlayout
			[rows]="['604.96px']"
			[cols]="[visualizer ? '50%' : '100%']"
			[gap]="{ rows: 5, cols: visualizer ? 5 : 0 }"
			class='grid'
		>
			<kendo-gridlayout-item [row]='1' [col]='visualizer ? 2 : 1'>
				<app-dcma-assessment [visualizer]='visualizer'></app-dcma-assessment>
			</kendo-gridlayout-item>
		</kendo-gridlayout>
	}
</div>
