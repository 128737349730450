import {
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { ButtonModule, ListModule } from '@progress/kendo-angular-buttons';
import { DatePipe, NgIf } from '@angular/common';
import {
	DropDownListModule,
	DropDownTreeComponent,
	DropDownTreesModule,
	SharedDirectivesModule,
	SharedModule,
} from '@progress/kendo-angular-dropdowns';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { CellClickEvent, GanttComponent, GanttModule, TaskClickEvent } from '@progress/kendo-angular-gantt';
import { GridLayoutModule } from '@progress/kendo-angular-layout';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { TooltipMenuModule } from '../../../portfolio/tooltip-menu/tooltip-menu.module';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { ProjectDashboardService } from '../../../../services/project/project.service';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { Activity, ActivityPredecessor, wbsDisplaySave, Xer, XerActivity } from '@rhinoworks/xer-parse';
import { differenceInCalendarDays, getMonth, isAfter, isBefore, startOfDay } from 'date-fns';
import { groupBy, GroupResult, orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { caretAltDownIcon } from '@progress/kendo-svg-icons';
import { TreeItem } from '@progress/kendo-angular-treeview';
import { OverviewNotesModule } from '../../../shared/overview-notes/overview-notes.module';
import { findKShortestPaths, findNLeastFloatPaths, isCritical } from '@rhinoworks/analytics-calculations';
import { Task } from '../../../../models/ChartSettings';
import { ExpandEvent } from '@progress/kendo-angular-gantt/expanded-state/expand-event';
import { saveAs } from 'file-saver';
import { RestService } from '../../../../services/common/rest.service';

export interface MilestoneTreeItem {
	entry: {
		id: number;
		name: string;
	};
	children: XerActivity[];
}

@Component({
	selector: 'app-driving-path',
	standalone: true,
	imports: [
		ButtonModule,
		DatePipe,
		DropDownListModule,
		ExcelExportModule,
		GanttModule,
		GridLayoutModule,
		ListModule,
		NgIf,
		SVGIconModule,
		SharedDirectivesModule,
		TooltipMenuModule,
		TooltipModule,
		SharedModule,
		DropDownTreesModule,
		OverviewNotesModule,
	],
	templateUrl: './driving-path.component.html',
	styleUrl: './driving-path.component.scss',
})
export class DrivingPathComponent implements OnChanges {
	@Input() isOverview: boolean = false;
	@Input() hideNotes: boolean = false;
	@Input() isFocus: boolean = false;
	@Input() showOverlay: boolean = false;
	@Input() actvCodeKey: string;
	@Input() wbsKey: string;
	@Input() existingData: Record<string, { minDate: Date; maxDate: Date; data: Task[]; code: string; dataDate: Date }> =
		null;
	@ViewChild('drivingPathGantt') public gantt: GanttComponent;
	@ViewChild('dropDownTree') dropDownTree: DropDownTreeComponent;
	public caretAltDown = caretAltDownIcon;
	public availableActivities: Array<XerActivity & { display: string; taskType: string }> = [];
	public groupedActivities: MilestoneTreeItem[];
	public criticalActivities: Array<
		XerActivity & {
			display: string;
			start: Date;
			end: Date;
			'Activity ID': string;
			'Activity Type': string;
			'Activity Status': string;
			'Activity Name': string;
			OD: number;
			RD: number;
			'% Complete': string;
			Start: string;
			Finish: string;
			TF: number;
			Complete: boolean;
			Critical: boolean;
			entry: {
				name: string;
				id: number;
			};
			completionRatio: number;
		}
	>;
	@Input() public defaultFinishMilestoneCode: string;
	public selectedFinishMilestone: XerActivity & { entry?: { name: string; id: number } };
	public prevSelectedFinishMilestone: XerActivity & { entry?: { name: string; id: number } };
	public milestoneSelectorOpen = false;
	public loading = true;
	public slotWidth: number = 150;
	expandedNodes: number[] = [];
	dropdownIsOpen: boolean = false;
	public sort: SortDescriptor[] = [
		{
			field: 'info.start',
			dir: 'asc',
		},
	];
	hasNotes: boolean = false;
	@Input() userSelectedFinMile: XerActivity = null;
	public data: Task[] = [];
	public expandedKeys = [];
	dataDate: Date = null;
	minDate: Date = null;
	maxDate: Date = null;
	isExportRunning: boolean = false;
	allNodesExpanded: boolean = false;
	constructor(
		public projectService: ProjectDashboardService,
		public scheduleService: ScheduleStorageService,
		private rest: RestService
	) {
		this.scheduleService.$allUpdates.subscribe(async (updates) => {
			if (updates.length === projectService.$currentProjectData.value?.updateIds?.length) {
				this.defaultFinishMilestoneCode ||= updates[updates.length - 1].finishMilestone.task_code;

				const xerData = await this.scheduleService.grabUpdateXerData(updates[updates.length - 1]._id);
				const xer = new Xer(xerData);
				this.selectedFinishMilestone ||= xer.activitiesByCode.get(this.defaultFinishMilestoneCode).raw_entry;
				this.selectedFinishMilestone.entry = {
					name: this.selectedFinishMilestone.task_code + ' - ' + this.selectedFinishMilestone.task_name,
					id: this.selectedFinishMilestone.task_id,
				};
				this.prevSelectedFinishMilestone = structuredClone(this.selectedFinishMilestone);
				this.availableActivities = Array.from(xer.activitiesByCode.values())
					.filter(
						(task) =>
							(!this.actvCodeKey || task.actvInfo.activityCodeKeys.has(this.actvCodeKey)) &&
							(!this.wbsKey || wbsDisplaySave(task._activity.wbs) === this.wbsKey) &&
							(task.code === this.defaultFinishMilestoneCode || (task._activity.taskType !== 'TT_Mile' && !task.finish))
					)
					.map((task) => ({
						...task.raw_entry,
						display: `${task.code} - ${task._activity.activityName}`,
						taskType: task._activity.taskType === 'TT_FinMile' ? 'Finish Milestones' : 'All Activities',
						entry: {
							id: task.id,
							name: task.code + ' - ' + task.raw_entry.task_name,
						},
					}));
				const groupedActivities: GroupResult[] = groupBy(this.availableActivities, [
					{ field: 'taskType' },
				]).reverse() as GroupResult[];
				this.groupedActivities = [
					{
						entry: {
							id: 0,
							name: 'Finish Milestones',
						},
						children: (groupedActivities.find((i) => i.value === 'Finish Milestones')?.items || []) as XerActivity[],
					},
					{
						entry: {
							id: 1,
							name: 'All Activities',
						},
						children: (groupedActivities.find((i) => i.value === 'All Activities')?.items || []) as XerActivity[],
					},
				];
				this.loadDrivingPath(this.defaultFinishMilestoneCode, xer);
			}
		});
		this.projectService.$currentProjectData.subscribe((val) => {
			if (val) {
				const savedNotes = val.componentNotes?.find((n) => n.id === 27)?.notes;
				this.hasNotes = savedNotes?.length && savedNotes[savedNotes?.length - 1]?.note !== '';
			}
		});
		this.taskCallback = this.taskCallback.bind(this);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.defaultFinishMilestoneCode) {
			this.selectedFinishMilestone = this.availableActivities.find(
				(i) => i.task_code === changes.defaultFinishMilestoneCode.currentValue
			);
			this.loadDrivingPath(changes.defaultFinishMilestoneCode.currentValue);
		}
	}

	async loadDrivingPath(finMilestoneCode: string = this.selectedFinishMilestone?.task_code, xer?: Xer) {
		if (this.existingData?.[finMilestoneCode]) {
			const { data, minDate, maxDate, dataDate } = this.existingData[finMilestoneCode];
			this.data = data;
			this.minDate = minDate;
			this.maxDate = maxDate;
			this.dataDate = dataDate;
			let j: number = 0;
			const hasDomLoaded = setInterval(() => {
				const drivingPathGantt: HTMLElement = document.getElementById('drivingPathGantt') as HTMLElement;
				if ((drivingPathGantt !== undefined && this.minDate !== undefined) || j > 500) {
					this.updateSlotWidth(this.dataDate);
					clearInterval(hasDomLoaded);
				}
				j++;
			}, 200);
			this.loading = false;
			return;
		}
		this.loading = true;
		const updateIds = this.projectService.$currentProjectData.value.updateIds;
		const xerData = xer?.xerData || (await this.scheduleService.grabUpdateXerData(updateIds[updateIds.length - 1]));
		if (!xerData) {
			return;
		}
		xer ||= new Xer(xerData);
		if (!xer.activitiesByCode.has(finMilestoneCode)) {
			return;
		}
		if (this.isFocus) {
			this.selectedFinishMilestone = structuredClone(this.userSelectedFinMile);
		}
		const threePaths = findKShortestPaths(
			xer.sortedActivities,
			Array.from(xer.activityPredecessors.values()),
			xer.activitiesByCode.get(
				this.isFocus ? this.userSelectedFinMile.task_code : this.selectedFinishMilestone.task_code
			),
			3
		).sort((a, b) => a.float - b.float || (a.path[0]?.hasStarted && !b.path[0]?.hasStarted ? 1 : -1));
		if (!threePaths.length) {
			return;
		}
		const data: Task[] = [];
		let i: number = 0;
		this.dataDate = xer.activitiesByCode.get(this.selectedFinishMilestone.task_code).recalcDate;
		let earliestOverallDate: Date = structuredClone(
			threePaths[0].path[0].raw_entry.act_start_date || threePaths[0].path[0].raw_entry.early_start_date
		);
		let latestOverallDate: Date = structuredClone(
			threePaths[0].path[0].raw_entry.act_end_date || threePaths[0].path[0].raw_entry.early_end_date
		);
		for (const path of threePaths) {
			const ganttData: Array<Task & { parentId: number }> = this.generateGanttData(
				{ float: path.minFloat, path: path.predecessors },
				this.dataDate,
				i,
				xer.activitiesByCode.get(
					this.isFocus ? this.userSelectedFinMile.task_code : this.selectedFinishMilestone.task_code
				)
			);
			let earliestDate: Date = structuredClone(ganttData[0]?.start);
			let latestDate: Date = structuredClone(ganttData[0]?.end);
			ganttData.forEach((bar: Task) => {
				if (!earliestDate || isBefore(bar.start, earliestDate)) {
					earliestDate = structuredClone(bar.start);
				}
				if (isAfter(bar.end, latestDate)) {
					latestDate = structuredClone(bar.end);
				}
			});
			if (isBefore(earliestDate, earliestOverallDate)) {
				earliestOverallDate = structuredClone(earliestDate);
			}
			if (isAfter(latestDate, latestOverallDate)) {
				latestOverallDate = structuredClone(latestDate);
			}
			if (ganttData.length) {
				const updateData: Task = {
					completionRatio: 0,
					end: latestDate,
					id: structuredClone(i),
					isCritical: i === 0,
					start: earliestDate,
					subtasks: ganttData,
					tf: null,
					title: '',
					name: (i === 0 ? 'First ' : i === 1 ? 'Second ' : 'Third ') + 'Path',
					isTopLevel: true,
				};
				data.push(updateData);
			}

			i++;
		}
		this.data = data;
		this.minDate = earliestOverallDate;
		this.maxDate = latestOverallDate;
		this.loading = false;
		if (!!this.existingData) {
			this.existingData[finMilestoneCode] = {
				data: this.data,
				minDate: this.minDate,
				maxDate: this.maxDate,
				dataDate: this.dataDate,
				code: finMilestoneCode,
			};
		}
		let j: number = 0;
		const hasDomLoaded = setInterval(() => {
			const drivingPathGantt: HTMLElement = document.getElementById('drivingPathGantt') as HTMLElement;
			if ((drivingPathGantt !== undefined && this.minDate !== undefined) || j > 500) {
				this.updateSlotWidth(this.dataDate);
				clearInterval(hasDomLoaded);
			}
			j++;
		}, 200);
	}

	generateGanttData(
		path: { float: number; path: ActivityPredecessor[] },
		dataDate: Date,
		parentId: number,
		finMile: Activity
	): Array<Task & { parentId: number }> {
		const pathWithDates: Activity[] = [];
		if (finMile && (finMile.raw_entry.status_code !== 'TK_Complete' || path.path.length === 1)) {
			finMile.info.start = finMile.raw_entry.act_start_date || finMile.raw_entry.early_start_date;
			finMile.info.finish = finMile.raw_entry.act_end_date || finMile.raw_entry.early_end_date;
			pathWithDates.push(finMile);
		}
		path.path.forEach((el) => {
			if (el.prevActivity.raw_entry.status_code !== 'TK_Complete' || path.path.length === 1) {
				el.prevActivity.info.start =
					el.prevActivity.raw_entry.act_start_date || el.prevActivity.raw_entry.early_start_date;
				el.prevActivity.info.finish =
					el.prevActivity.raw_entry.act_end_date || el.prevActivity.raw_entry.early_end_date;
				pathWithDates.push(el.prevActivity);
			}
		});
		const orderedPath: Activity[] = orderBy(pathWithDates, this.sort);
		const ganttData: Array<Task & { parentId: number }> = [];
		orderedPath.forEach((act: Activity) => {
			const actAsTask: Task & { parentId: number } = this.getGanttData(act, dataDate, parentId);
			ganttData.push(actAsTask);
		});
		return ganttData;
	}

	getGanttData(
		act: Activity,
		dataDate: Date,
		parentId: number
	): Task & {
		parentId: number;
		od: number;
		rd: number;
		activityStatus: string;
		taskName: string;
		taskCode: string;
		activityType: string;
	} {
		const actv: XerActivity = act.raw_entry;
		const start: Date = startOfDay(new Date(actv?.act_start_date || actv?.early_start_date));
		const end: Date = startOfDay(new Date(actv?.act_end_date || actv?.early_end_date));
		let completionRatio: number = 0;
		const od: number = (actv?.target_drtn_hr_cnt || 0) / 8;
		const rd: number = (actv?.remain_drtn_hr_cnt || 0) / 8;
		if (isAfter(dataDate, start)) {
			const durationUntilDataDate: number = differenceInCalendarDays(dataDate, start);
			const taskDuration: number = differenceInCalendarDays(end, start);
			completionRatio = durationUntilDataDate / (taskDuration || 1);
			completionRatio = completionRatio > 1 ? 1 : completionRatio;
		}
		return {
			completionRatio,
			end: startOfDay(new Date(actv.act_end_date || actv.early_end_date)),
			// @ts-expect-error for some reason this Date only field now has empty string as its value when the field doesn't exist?????
			endIsAct: actv?.act_end_date !== undefined && actv?.act_end_date !== '',
			id: actv.task_id,
			isCritical: isCritical(actv),
			start: startOfDay(new Date(actv.act_start_date || actv.early_start_date)),
			// @ts-expect-error for some reason this Date only field now has empty string as its value when the field doesn't exist?????
			startIsAct: actv?.act_start_date !== undefined && actv?.act_start_date !== '',
			subtasks: [],
			tf: actv.total_float_hr_cnt ? actv.total_float_hr_cnt / 8 : 0,
			title: '',
			name: actv.task_code ? actv.task_code + ' - ' + actv.task_name : actv.task_name,
			taskCode: actv.task_code,
			taskName: actv.task_name,
			activityType: actv.task_type,
			activityStatus: actv.status_code,
			od,
			rd,
			isTopLevel: false,
			parentId,
		};
	}

	updateSlotWidth(lastFinishRecalc: Date): void {
		setTimeout(() => {
			const drivingPathGantt: HTMLElement = document.getElementById('drivingPathGantt') as HTMLElement;
			if (drivingPathGantt) {
				const containerEl: HTMLElement = drivingPathGantt.querySelector('.k-gantt-timeline') as HTMLElement;
				const headerEl = containerEl?.children[0].children[0].children[0].children[0].children[1];
				const numberOfSubdivisions: number = headerEl?.children.length ?? 0;
				const containerWidth: number = containerEl?.getBoundingClientRect().width;
				if (numberOfSubdivisions) {
					this.slotWidth = containerWidth / numberOfSubdivisions;
				}
				setTimeout(() => {
					this.loading = true;
					setTimeout(() => {
						this.loading = false;
						setTimeout(() => {
							//show gantt vertical line borders on a yearly basis once zoom is small enough
							if (this.slotWidth <= 20 && this.minDate !== undefined) {
								const columns = document.getElementsByClassName('k-gantt-columns');
								const row = columns.item(0)?.children[1]?.children[0];
								const startNumber = 13 - getMonth(this.minDate);
								if (startNumber < row.children.length - 1) {
									for (let i = startNumber; i < row.children.length; i += 12) {
										row.children[i].setAttribute('style', 'border-left: 1px solid rgba(0, 0, 0, 0.08)');
									}
								}
							}
							this.drawDataDateLine(lastFinishRecalc);
						}, 100);
					}, 500);
				}, 200);
			}
		}, 500);
	}

	handleFilterMilestone(value: string) {
		const groupedActivities: GroupResult[] = groupBy(this.availableActivities, [
			{ field: 'taskType' },
		]).reverse() as GroupResult[];
		const finMileOptions: XerActivity[] = groupedActivities.find((i) => i.value === 'Finish Milestones')
			.items as XerActivity[];
		const allOptions: XerActivity[] = groupedActivities.find((i) => i.value === 'All Activities')
			.items as XerActivity[];
		const finMileMatches: XerActivity[] = finMileOptions.filter(
			(s: XerActivity) =>
				s.task_code.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
				s.task_name.toLowerCase().indexOf(value.toLowerCase()) !== -1
		);
		const allMatches: XerActivity[] = allOptions.filter(
			(s: XerActivity) =>
				s.task_code.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
				s.task_name.toLowerCase().indexOf(value.toLowerCase()) !== -1
		);
		const newGroupedActivities: MilestoneTreeItem[] = [];
		if (finMileMatches?.length) {
			newGroupedActivities.push({
				entry: {
					id: 0,
					name: 'Finish Milestones',
				},
				children: finMileMatches,
			});
		}
		if (allMatches?.length) {
			newGroupedActivities.push({
				entry: {
					id: 1,
					name: 'All Activities',
				},
				children: allMatches,
			});
		}
		this.groupedActivities = newGroupedActivities;
	}

	drawDataDateLine(dataDate: Date): void {
		if (!this.gantt) {
			return;
		}
		let i: number = 0;
		const waitForDOMInterval = setInterval(() => {
			const drivingPathGantt: HTMLElement = document.getElementById('drivingPathGantt') as HTMLElement;
			const timeline = drivingPathGantt?.querySelector('.k-gantt-timeline') as HTMLElement;
			if (timeline || i > 500) {
				clearInterval(waitForDOMInterval);
				const header = timeline.querySelector('.k-grid-header');
				const minStart: Date = this.gantt.timelineSlots[0].start;
				const maxEnd: Date = this.gantt.timelineSlots[this.gantt.timelineSlots.length - 1].end;
				const totalTimespan: number = differenceInCalendarDays(maxEnd, minStart);
				const daysElapsed: number = differenceInCalendarDays(dataDate, minStart);
				const rightPercent: number = 1 - daysElapsed / (totalTimespan || 1);
				const pxShift: number = this.criticalActivities?.length > 12 ? 2 : 0; // not exact science. 2 is for a shift that kendo's scrollbar brings.
				const rightValue: number = header.clientWidth * rightPercent + pxShift;
				const dataDateLine = document.getElementsByClassName('data-date-gantt-line-2');
				if (dataDateLine?.length) {
					dataDateLine.item(0).setAttribute('style', 'right:' + rightValue + 'px');
				}
				//hides data date line if its past the gantt timeline
				const ganttTimeline = timeline.getBoundingClientRect();
				const dateLine = dataDateLine?.item(0).getBoundingClientRect();
				if (dateLine.left < ganttTimeline.left || dateLine.left > ganttTimeline.right) {
					dataDateLine.item(0).setAttribute('style', 'display: none');
				}
			}
			i++;
		}, 200);
	}

	/**
	 * applies style class to timeline task bars
	 * @param dataItem
	 */
	public taskCallback(
		dataItem: Task & {
			parentId?: number;
		}
	): string {
		const colors: string[] = ['red3', 'red', 'red2'];
		const index: number =
			dataItem?.name === 'First Path'
				? 0
				: dataItem?.name === 'Second Path'
					? 1
					: dataItem?.name === 'Third Path'
						? 2
						: dataItem?.parentId;
		return colors[index];
	}

	/**
	 * A function that checks whether a given node index exists in the expanded keys collection.
	 * If the item ID can be found, the node is marked as expanded.
	 */
	public isNodeExpanded = (node): boolean => this.expandedNodes.indexOf(node.entry.id) !== -1;

	/**
	 * A `nodeCollapse` event handler that will remove the node data item ID
	 * from the collection, collapsing its children.
	 */
	public handleCollapse(args: TreeItem): void {
		this.expandedNodes = this.expandedNodes.filter((id) => id !== args.dataItem.entry.id);
	}

	/**
	 * An `nodeExpand` event handler that will add the node data item ID
	 * to the collection, expanding its children.
	 */
	public handleExpand(args: TreeItem): void {
		this.expandedNodes = this.expandedNodes.concat(args.dataItem.entry.id);
	}

	/**
	 * dropdowntree open event handler
	 */
	open(): void {
		// sets finish milestones as only open dropdown
		this.expandedNodes = [0];
		this.dropdownIsOpen = true;
	}

	treeValChange(ev: XerActivity & { entry?: { name: string; id: number } }, dropDownTree: DropDownTreeComponent): void {
		if (ev.entry.id === 0 || ev.entry.id === 1) {
			this.selectedFinishMilestone = structuredClone(this.prevSelectedFinishMilestone);
		} else {
			this.selectedFinishMilestone = ev;
			if (ev?.entry?.id !== this.prevSelectedFinishMilestone?.entry?.id) {
				this.prevSelectedFinishMilestone = structuredClone(this.selectedFinishMilestone);
				this.prevSelectedFinishMilestone.entry = {
					name: this.prevSelectedFinishMilestone.task_code + ' - ' + this.prevSelectedFinishMilestone.task_name,
					id: this.prevSelectedFinishMilestone.task_id,
				};
				this.loadDrivingPath();
				dropDownTree.toggle(false);
				this.dropdownIsOpen = false;
			}
		}
	}

	disableClose(event): void {
		event.preventDefault();
	}

	@HostListener('window:mousedown', ['$event'])
	mouseDown(event) {
		//closes milestone dropdown popup if clicked outside the popup
		if (this.dropdownIsOpen) {
			const milestonesDropdown: DOMRect = document
				.getElementsByClassName('drivingPathDropdownTree')
				.item(0)
				?.getBoundingClientRect();
			if (
				milestonesDropdown &&
				event.x >= milestonesDropdown.left &&
				event.x <= milestonesDropdown.right &&
				event.y >= milestonesDropdown.top &&
				event.y <= milestonesDropdown.bottom
			) {
				return;
			} else {
				const dropdownBar: DOMRect = document
					.getElementsByClassName('drivingMileTree')
					.item(0)
					?.getBoundingClientRect();
				if (
					dropdownBar &&
					event.x >= dropdownBar.left &&
					event.x <= dropdownBar.right &&
					event.y >= dropdownBar.top &&
					event.y <= dropdownBar.bottom
				) {
					//delayed close bc clicking the bar opens it first
					setTimeout(() => {
						this.closeMilestoneDropdown();
					}, 500);
				} else {
					this.closeMilestoneDropdown();
				}
			}
		}
	}

	closeMilestoneDropdown(): void {
		this.dropdownIsOpen = false;
		this.dropDownTree.toggle(false);
	}

	updateSort(sort): void {
		const conversion = [
			{
				field: 'StartGantt',
				valueField: 'start',
			},
			{
				field: 'FinishGantt',
				valueField: 'end',
			},
		];
		this.sort = sort;
		this.criticalActivities = orderBy(this.criticalActivities, this.sort);
	}

	/**
	 * used by gantt to know if a row is expanded
	 * @param dataItem
	 */
	public isExpanded = (dataItem: Task): boolean => this.expandedKeys.includes(dataItem.id);

	/**
	 * handles arrow click
	 * @param dataItem
	 */
	arrowToggle({ dataItem }: ExpandEvent): void {
		this.toggleNode(dataItem.id);
	}

	/**
	 * toggle expansion of individual node
	 * @param id
	 */
	toggleNode(id): void {
		const indexOfKey = this.expandedKeys.findIndex((key) => key === id);
		if (indexOfKey !== -1) {
			this.expandedKeys.splice(indexOfKey, 1);
		} else {
			this.expandedKeys.push(id);
		}
	}

	/**
	 * update selected list based on user click
	 * @param dataItem
	 * @param sender
	 * @param originalEvent
	 */
	public toggleSelection({ dataItem, sender, originalEvent }: CellClickEvent | TaskClickEvent): void {
		// prevents context menu opening
		originalEvent.preventDefault();
		if (dataItem.isTopLevel) {
			this.toggleNode(dataItem.id);
		}
		setTimeout(() => {
			this.drawDataDateLine(this.dataDate);
		}, 500);
	}

	/**
	 * toggle opens/closes all nodes in gantt chart
	 * @param expand
	 */
	toggleAllNodes(expand: boolean): void {
		this.allNodesExpanded = expand;
		this.expandedKeys = [];
		if (expand) {
			this.data.forEach((update) => {
				this.expandedKeys.push(update.id);
			});
		}
	}

	doExport() {
		this.isExportRunning = true;
		this.rest.postToExporter('driving/', this.data).subscribe({
			next: (res: any) => {
				saveAs(res, 'Driving path to ' + this.selectedFinishMilestone?.task_code + '.xlsx');
				this.isExportRunning = false;
			},
			error: (err: any) => {
				this.isExportRunning = false;
				console.log(err);
			},
		});
	}
}
