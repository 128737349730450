import { AnalyticsDashboardService, PortfolioProject } from 'services/analytics/analytics.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Inject,
	OnDestroy,
	OnInit,
	Renderer2,
	TemplateRef,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { UserService } from 'services/common/user.service';
import { RestService } from '../../services/common/rest.service';
import {
	addMonths,
	differenceInCalendarDays,
	endOfDay,
	format,
	getMonth,
	isAfter,
	isBefore,
	isSameDay,
	startOfDay,
	startOfMonth,
} from 'date-fns';
import { ProjectDashboardService } from '../../services/project/project.service';
import { LegendLabelsContentArgs } from '@progress/kendo-angular-charts';
import { IntlService } from '@progress/kendo-angular-intl';
import { DOCUMENT } from '@angular/common';
import { MapComponent } from './map/map/map.component';
import {
	IButton,
	isQuickViewOption,
	PROJECT_TYPES,
	ProjectInterface,
	QuickFilterWidgets,
	QuickViewFilters,
	QuickViewOption,
} from '../../models/Project';
import { NavigationBarStorageService } from '../../services/common/navigation-bar-storage.service';
import { ChartScoreSelection, SeriesData } from '../../models/ChartSettings';
import { compareVarianceArrayLength, hasObjChanged, scheduleType } from '../../util/projects';
import {
	FilterItem,
	ProjectListColumn,
	ProjectListItem,
	scheduleTypeList,
} from './project-list/project-list/project-list.component';
import { filterBy, groupBy, GroupResult, SortDescriptor } from '@progress/kendo-data-query';
import { FilterOperator } from '@progress/kendo-data-query/dist/npm/filtering/operators.enum';
import { ProjectFieldFilter, ProjectsFilter } from '../../util/kendo';
import { UiStorageService } from '../../services/common/ui-storage.service';
import { RiskRegister } from '../../models/risk';
import { Client, HierarchyResponse, Member } from 'models/company';
import { AppWindowService } from '../../services/common/window.service';
import {
	buildingBlocksIcon,
	caretAltDownIcon,
	columnsIcon,
	fileExcelIcon,
	filterIcon,
	pencilIcon,
	searchIcon,
	starIcon,
	SVGIcon,
	toggleFullScreenModeIcon,
	trashIcon,
	zoomInIcon,
	zoomOutIcon,
} from '@progress/kendo-svg-icons';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { Button } from '@progress/kendo-angular-buttons';
import { ClickEvent, TimelineViewType } from '@progress/kendo-angular-gantt';
import { fullscreenIcon, fullscreenExitIcon } from '@progress/kendo-svg-icons';
import {
	CashFlowChartData,
	CostService,
	HistoricPerformanceChartData,
	SPIChartData,
} from '../../services/common/cost.service';
import { Router } from '@angular/router';
import { PopupRef, PopupService } from '@progress/kendo-angular-popup';
import { PortfolioPreset } from '../../models/auth/account-user';
export interface DropdownMenuItemData {
	value: string;
	viewValue: string;
}

declare global {
	interface Window {
		initMap: () => void;
	}
}

const defaultFilters: ProjectsFilter = new ProjectsFilter();

export enum LOCAL_STORAGE {
	LIST_VIEW = 'currentPortfolioView',
	LIST_FILTERS = 'projectListFilterMap',
	PREV_BASELINE_LIST_FILTERS = 'prevBaselineProjectListFilterMap',
}

interface ProjectListGanttItem {
	id: string;
	start: Date;
	end: Date;
	name: string;
	currentCompletion: Date;
	contractCompletion: Date;
	projectStart: Date;
	deltaVsCcd: number;
	prevVariance: number;
	aegisScore: number;
	progressScore: number;
	qcScore: number;
	predictabilityScore: number;
	updatesPerProject: number;
	lastUpdatedDate: Date;
	projectCode: string;
	clientName: string;
	scheduleType: string;
	companyName: string;
	market: string;
	pocName: string;
	highDuration?: number;
	highFloat?: number;
	actualsPastDD?: number;
	ssFFWithLags?: number;
	softConstraints?: number;
	missingLogic?: number;
	negativeLags?: number;
	fsWithLags?: number;
	hardConstraints?: number;
	outOfSequence?: number;
	missingUpdate?: boolean;
	calendarDaysRemaining?: number;
	spi?: number;
	spiCumulative?: number;
	actualCost?: number;
	plannedCost?: number;
	actualCostCumulative?: number;
	remainingCost?: number;
	nextPeriodPlanned?: number;
	costBaseline?: string;
	currentBudget?: number;
	currentRemainingCost?: number;
	openStartFinish?: number;
	sfRelationships?: number;
}

export type GanttToProjectListColumn = {
	plField: string;
	ganttField: string;
};

@Component({
	selector: 'analytics-dashboard',
	templateUrl: './portfolio.component.html',
	styleUrls: ['./portfolio.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class PortfolioComponent implements OnInit, AfterViewInit, OnDestroy {
	public svgFullscreen: SVGIcon = fullscreenIcon;
	public svgFullscreenExit: SVGIcon = fullscreenExitIcon;
	public svgFilter: SVGIcon = filterIcon;
	public svgExcel: SVGIcon = fileExcelIcon;
	public svgColumns: SVGIcon = columnsIcon;
	public svgSearch: SVGIcon = searchIcon;
	@ViewChild('appMap', { static: false }) appMap: MapComponent;
	public isProjectsGridMaximized = false;
	$allProjects = new BehaviorSubject<Array<ProjectListItem>>(undefined);
	$filteredProjects = new BehaviorSubject<Array<ProjectListItem>>(undefined);
	$filters: ProjectsFilter = defaultFilters;
	$riskRegisterProjects = new BehaviorSubject<Array<ProjectListItem>>([]);
	$projectCompletionVariance = new BehaviorSubject<Array<any>>([]);
	$totalSlipped = new BehaviorSubject<number>(null);
	$totalGained = new BehaviorSubject<number>(null);
	$totalMaintained = new BehaviorSubject<number>(null);
	$maintainedPercentage = new BehaviorSubject<number>(null);
	$slippedPercentage = new BehaviorSubject<number>(null);
	$gainedPercentage = new BehaviorSubject<number>(null);
	$totalMissedLastUpload = new BehaviorSubject<number>(null);
	$allRiskProjects = new BehaviorSubject<number>(null);
	$averageRiskScore = new BehaviorSubject<number>(null);
	$averageProjectScore = new BehaviorSubject<number>(null);
	$averageProgressScore = new BehaviorSubject<number>(null);
	$averagePredictabilityScore = new BehaviorSubject<number>(null);
	$totalShowingProjects = new BehaviorSubject<number>(null);
	$averageQCScore = new BehaviorSubject<number>(null);
	$totalRiskMissedLastUpload = new BehaviorSubject<number>(null);
	$totalRiskSlipped = new BehaviorSubject<number>(null);
	$activeProjects = new BehaviorSubject<number>(null);
	riskSummaryTrendCategories = [];
	riskSummaryTrendData = [];
	allSummaryTrendData = {
		projectScore: [],
		progressScore: [],
		predictabilityScore: [],
		qcScore: [],
	};
	portfolioSummaryTrendData = [];
	portfolioSummaryTrendTitle = 'Project Score';
	monthMapProjectScore;
	monthMapProgressScore;
	monthMapPredictabilityScore;
	monthMapQcScore;
	titleMapping = {
		projectScore: 'Project Score',
		progressScore: 'Progress Score',
		predictabilityScore: 'Risk Score',
		qcScore: 'QC Score',
	};
	pieData: Array<{
		category: string;
		color: string;
		valueAsPercentage: number;
		exploded: boolean;
		valueAsNumber: number;
	}> = [
		{ category: 'Slipped', valueAsPercentage: 0, color: 'rgba(223, 83, 83, .9)', exploded: false, valueAsNumber: 0 },
		{ category: 'On-Track', valueAsPercentage: 0, color: 'rgba(79, 201, 49, .9)', exploded: false, valueAsNumber: 0 },
		{ category: 'Improved', valueAsPercentage: 0, color: 'rgba(0, 89, 255, .9)', exploded: false, valueAsNumber: 0 },
	];
	prevBaselineFiltersReady: boolean = false;
	goingBackToBaseline: boolean = false;
	doneBackToBaseline: boolean = false;
	updatesExistInPastSixMonths = false;
	isProjectExpanded = false;
	public icons = {
		caretAltDown: caretAltDownIcon,
		zoomIn: zoomInIcon,
		zoomOut: zoomOutIcon,
		toggleFullscreen: toggleFullScreenModeIcon,
		blocks: buildingBlocksIcon,
		pencilIcon,
		trashIcon,
		star: starIcon,
	};
	private presetsPopupRef: PopupRef;
	@ViewChild('presetsPopupTemplate') presetsTemplateRef: TemplateRef<any>;
	zoomValue: number = 25;
	columnSelectorPopupIsOpen = false;
	presetsPopupIsOpen = false;
	searchTerm = '';
	resetClickedPortfolio = new BehaviorSubject<boolean>(false);
	projectsListView: 'grid' | 'gantt' = 'grid';
	start: number;
	end: number;
	public allItems: ProjectListGanttItem[] = [];
	public items: ProjectListGanttItem[] = [];
	showLines = false;
	@ViewChild('portfolioSearch') portfolioSearchbar: TextBoxComponent;
	public viewButtons: IButton[] = [
		{
			text: 'Table',
			value: 0,
			selected: true,
			disabled: false,
		},
		{
			text: 'Gantt',
			value: 1,
			disabled: false,
		},
	];
	@ViewChild('columnSelectorPopupAnchor') columnSelectorBtn: Button;
	visibleColumns: ProjectListColumn[] = [];
	visibleColumnsSet = new Set<string>();
	allColumns: ProjectListColumn[] = [];
	public hiddenColumns: string[] = [];
	_userSelectedSort: SortDescriptor[] = [];
	public sort: SortDescriptor[] = [
		{
			field: 'name',
			dir: 'asc',
		},
	];
	public activeView: TimelineViewType = 'year';
	public slotWidth: number = 25;
	minStart: Date = null;
	maxEnd: Date = null;
	viewOptions = [
		{
			display: 'Auto-Fit',
			zoomVal: null,
		},
		{
			display: 'Year',
			zoomVal: 25,
		},
		{
			display: 'Quarter',
			zoomVal: 35,
		},
		{
			display: 'Month',
			zoomVal: 55,
		},
	];
	selectedGanttView: number = null;
	isPanningSetup = false;
	isDragging = false;
	playingAnimation = null;
	userPresets: PortfolioPreset[] = [];
	quickSaveName: string = '';
	quickSaveIsFavorite: boolean = false;

	/**
	 * applies style class to timeline task bars
	 * @param dataItem
	 */
	public taskCallback(dataItem: any): any {
		const latestDate = isBefore(dataItem.currentCompletion, dataItem.contractCompletion)
			? 'contractCompletion'
			: 'currentCompletion';
		return dataItem?.isCritical ? 'red-portfolio' : 'green-portfolio';
	}

	public pieLabelContent(args: LegendLabelsContentArgs): string {
		return `${this.intl.formatNumber(args.dataItem.valueAsPercentage, '0%')}`;
	}

	public isNotZero(args: LegendLabelsContentArgs): boolean {
		return args.value !== 0;
	}

	currentBanner = 'default';
	$bannerToggledCount = new BehaviorSubject<number>(0);
	loading = true;
	numUnarchived = 0;
	tooltipOpened = false;
	riskTooltipOpened = false;
	tooltipType: string = null;
	tooltipTitle: string = null;
	currentFilters: Record<string, FilterItem[]> = {};
	currentFilterLength = 0;
	quickFilterWidgets = QuickFilterWidgets;
	clickedOnMapMarker = new EventEmitter<void>();
	selectedQuickView: QuickViewOption;

	projectTypeList: Array<FilterItem> = PROJECT_TYPES.map((projectType) => ({
		text: projectType,
		value: {
			text: projectType,
			field: 'projectType',
			operator: FilterOperator.EqualTo,
			value: projectType,
		},
	}));
	filteredProjectTypeList: Array<FilterItem> = Array.from(this.projectTypeList);

	completionStatus: Array<FilterItem> = [
		{
			text: 'Behind Schedule',
			value: { text: 'Behind Schedule', operator: FilterOperator.LessThan, value: 0, field: 'deltaVsCcd' },
		},
		{
			text: 'On Time',
			value: { text: 'On Time', operator: FilterOperator.EqualTo, value: 0, field: 'deltaVsCcd' },
		},
		{
			text: 'Ahead of Schedule',
			value: { text: 'Ahead of Schedule', operator: FilterOperator.GreaterThan, value: 0, field: 'deltaVsCcd' },
		},
	];

	pastPeriodPerformanceList: Array<FilterItem> = [
		{
			text: 'Slipped Last Period (prev var < 0)',
			value: {
				text: 'Slipped Last Period (prev var < 0)',
				operator: FilterOperator.LessThan,
				value: 0,
				field: 'prevVariance',
			},
		},
		{
			text: 'Progress On-Track (prev var = 0)',
			value: {
				text: 'Progress On-Track (prev var = 0)',
				operator: FilterOperator.EqualTo,
				value: 0,
				field: 'prevVariance',
			},
		},
		{
			text: 'Improved Last Period (prev var > 0)',
			value: {
				text: 'Improved Last Period (prev var > 0)',
				operator: FilterOperator.GreaterThan,
				value: 0,
				field: 'prevVariance',
			},
		},
	];

	uploadList: Array<FilterItem> = [
		{
			text: 'Current Update',
			value: {
				operator: FilterOperator.LessThan,
				value: 45,
				field: 'dUpload',
			},
		},
		{
			text: 'Missing Update (45+ cd)',
			value: {
				operator: FilterOperator.GreaterThanOrEqual,
				value: 45,
				field: 'dUpload',
			},
		},
	];

	clientList: Array<FilterItem> = [];
	filteredClientList: Array<FilterItem> = [];

	companyList: Array<FilterItem> = [];
	nestedCompanyList: Array<FilterItem> = [];
	filteredCompanyList: Array<FilterItem> = [];
	riskList: Array<FilterItem> = [
		{
			text: 'Default KPIs',
			value: {
				text: 'Default KPIs',
				value: 'default',
				operator: FilterOperator.EqualTo,
				field: 'riskMetricsType',
			},
		},
		{
			text: 'Performance Factor',
			value: {
				text: 'Performance Factor',
				operator: FilterOperator.EqualTo,
				value: 'performanceFactor',
				field: 'riskMetricsType',
			},
		},
		{
			text: 'Risk Register',
			value: {
				text: 'Risk Register',
				operator: FilterOperator.EqualTo,
				value: 'riskRegister',
				field: 'riskMetricsType',
			},
		},
		{
			text: 'Core Risk',
			value: {
				text: 'Core Risk',
				operator: FilterOperator.EqualTo,
				value: 'coreRisk',
				field: 'riskMetricsType',
			},
		},
	];

	pocList: Array<FilterItem> = [];
	nestedPocList: GroupResult[] = [];
	nestedClientList: GroupResult[] = [];
	filteredPocList: Array<FilterItem> = [];
	scheduleTypeList = scheduleTypeList;

	public clickedCoords = new BehaviorSubject<google.maps.LatLngLiteral>({
		lat: 36.1167256667,
		lng: -94.0130476667,
	} as google.maps.LatLngLiteral);
	public selectedProject = new BehaviorSubject<ProjectInterface>(undefined);

	user: any = {
		userType: 'aegis',
	};

	listUpdatedFromPageLoad = true;
	dialogOpened = false;
	addProjectOpened = false;
	public wrapper: DOMRect;
	public top = 5;
	public left = 600;
	public height = 820;
	public width = 650;

	private _unsubscribeAll = new Subject<void>();

	public googleScriptLoaded = false;
	selectedSummaryChart: ChartScoreSelection = 'projectScore';

	exportGrid: Subject<void> = new Subject<void>();

	resetFiltersSubject: Subject<void> = new Subject<void>();
	$checkForResetBtnDisabled = new BehaviorSubject<boolean>(false);
	$filtersOpened = new BehaviorSubject<ElementRef | HTMLElement>(undefined);
	$columnSelectorOpened = new BehaviorSubject<ElementRef | HTMLElement>(undefined);
	headerSearch = new BehaviorSubject<string>('');
	isFromSearch = false;
	resetDisabled = true;
	mapPaneSize = '24%';
	showingTooltip = false;
	tooltipVals = {
		x: 0,
		y: 0,
		name: '',
		projectStart: null,
		lastUpdatedDate: null,
		currentCompletion: null,
		contractCompletion: null,
		deltaVsCcd: null,
		calendarDaysRemaining: null,
	};
	rangeSliders: string[] = ['projectScore', 'progressScore', 'qcScore', 'predictabilityScore', 'completionVar'];
	deletingPreset: PortfolioPreset = null;
	$presetApplied = new BehaviorSubject<PortfolioPreset>(null);
	lastProjectsPocsUpdates: string = null;

	constructor(
		public _analyticsDashboardService: AnalyticsDashboardService,
		public userService: UserService,
		private restService: RestService,
		private _projectDashboardService: ProjectDashboardService,
		private intl: IntlService,
		private _renderer2: Renderer2,
		public navBarStorage: NavigationBarStorageService,
		public ui: UiStorageService,
		public windowService: AppWindowService,
		private cdr: ChangeDetectorRef,
		public costService: CostService,
		public router: Router,
		private popupService: PopupService,
		@Inject(DOCUMENT) private _document: Document
	) {
		this.pieLabelContent = this.pieLabelContent.bind(this);
		this.isNotZero = this.isNotZero.bind(this);
	}

	ngOnInit(): void {
		this._projectDashboardService.$currentProjectData.next(undefined);
		this.currentBanner = localStorage.getItem('currentBanner') || 'default';
		if (!this.selectedProject) {
			document.getElementById('projectsMapSection').classList.remove('gridlayout-container');
			document.getElementById('projectsMapSection').classList.add('nothing-expanded');
			document.getElementById('dashboard-analytics').classList.add('none-expanded');
		}

		this.$filters.pipe(takeUntil(this._unsubscribeAll)).subscribe((filters: ProjectFieldFilter[]) => {
			if (this.loading || !this.$allProjects.value.length) {
				return;
			}
			if (!filters.length) {
				setTimeout(() => {
					this.resetFiltersSubject.next();
				}, 1000);
			}
			this.$checkForResetBtnDisabled.next(true);
		});

		this.restService
			.fetchAccount('api/terms/analytics/')
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((res) => {
				if (!res.agree) {
					this.navBarStorage.eulaOpened = true;
					this.navBarStorage.$triggerNewProjectTooltip.next(false);
					// this.windowService.setViewport('eula');
				}
			});
		this._registerCustomChartJSPlugin();

		this.userService.$updateLayoutList.pipe(takeUntil(this._unsubscribeAll)).subscribe((val: PortfolioPreset[]) => {
			if (val !== null && val !== undefined) {
				this.userPresets = val.sort(this.layoutSortFunction);
			}
		});

		this.userService.user.pipe(takeUntil(this._unsubscribeAll)).subscribe((data) => {
			if (data) {
				this.user = data;
				if (data?.portfolioPresets) {
					const savedPresets: PortfolioPreset[] = data.portfolioPresets.sort(this.layoutSortFunction);
					this.userPresets = savedPresets;
				} else {
					this.userPresets = [];
				}
				this.userService._userLayouts = data?.portfolioPresets || [];
			}
		});
		this._analyticsDashboardService.$addProjectOpen.pipe(takeUntil(this._unsubscribeAll)).subscribe((result) => {
			if (result) {
				this.addProjectOpened = true;
			}
		});

		this._analyticsDashboardService.$globalTooltipType.pipe(takeUntil(this._unsubscribeAll)).subscribe((result) => {
			if (result !== '') {
				if (this._projectDashboardService.$currentProjectData.value === undefined) {
					this.openTooltipWindow(result);
				}
			} else {
				this.tooltipOpened = false;
			}
		});

		const projectsPocsUpdates = (
			projects: PortfolioProject[],
			pocMembers: Map<number, Member & { displayName: string }>,
			clients: Map<string, Client>,
			companies: Map<string, HierarchyResponse>
		): void => {
			const allProjects: Array<ProjectListItem> = [];
			if (!projects) {
				this.loading = true;
				return;
			}
			const args = JSON.stringify({
				projects,
				pocMembers: Array.from(pocMembers.values()),
				clients: Array.from(clients.values()),
				companies: Array.from(companies.values()),
			});
			if (args === this.lastProjectsPocsUpdates) {
				return;
			} else {
				this.lastProjectsPocsUpdates = args;
			}

			const allClients = new Map<string, FilterItem>([]);
			const flattenedCompanies = new Map<number, FilterItem>([]);
			const allItems: ProjectListGanttItem[] = [];
			const getNestedFilterItem = (company: HierarchyResponse): FilterItem => {
				const entry = company.entry;
				const filter = {
					text: entry.name,
					id: entry.id,
					value: {
						text: entry.name,
						field: 'company',
						operator: FilterOperator.EqualTo,
						value: entry.id,
					},
					children: company.children?.map((subCompany) => getNestedFilterItem(subCompany)),
				};
				flattenedCompanies.set(entry.id, filter);
				return filter;
			};
			this.nestedCompanyList = Array.from(companies.values()).map((company) => getNestedFilterItem(company));

			for (const project of projects) {
				const projectInfo: ProjectListItem = {
					...project,
				};
				const projectClient = clients.get(project.clientId);
				const projectCompany = flattenedCompanies.get(project.company);
				projectInfo.name = project.name.toString().trim();
				projectInfo.contractCompletion = project.rawReport?.projectTable?.contractCompletion
					? new Date(project.rawReport?.projectTable?.contractCompletion)
					: undefined;
				projectInfo.currentCompletion = project.rawReport?.projectTable?.currentCompletion
					? new Date(project.rawReport?.projectTable?.currentCompletion)
					: undefined;
				const projectStart = project?.rawReport?.projectOverview?.projectStart
					? startOfDay(new Date(project?.rawReport?.projectOverview?.projectStart))
					: undefined;
				projectInfo.projectStart = projectStart;
				projectInfo.deltaVsCcd = project.rawReport?.projectTable?.contractVariance || 0;
				projectInfo.projectScore = project.rawReport?.projectTable?.aegisScore || project?.rawReport?.projectScore || 0;
				projectInfo.aegisScore = project.rawReport?.projectTable?.aegisScore || project?.rawReport?.projectScore || 0;
				projectInfo.predictabilityScore = project?.rawReport?.riskScore || 0;
				projectInfo.progressScore =
					project.rawReport?.updateIds?.length > 1 ? project?.rawReport?.progressScore || 0 : null;
				projectInfo.qcScore = project.rawReport?.qualityControl?.qcScore || 0;
				projectInfo.updatesPerProject = (project.rawReport?.updateIds?.length || 1) - 1;
				projectInfo.lastUpdatedDate =
					!!project.rawReport?.projectOverview?.dataDate && new Date(project.rawReport?.projectOverview?.dataDate);
				projectInfo.dUpload = 0;
				projectInfo.missingUpdate = false;
				if (
					!!project.rawReport?.projectOverview?.dataDate &&
					typeof project.rawReport?.projectOverview?.dataDate === 'string'
				) {
					const dateSplit = project.rawReport?.projectOverview?.dataDate.split(' ');
					const dateString = dateSplit[1] + ' ' + dateSplit[2] + ' ' + dateSplit[3];
					const newDate = new Date(dateString);
					projectInfo.lastUpdatedDate = newDate;
					const now = new Date();
					const dUpload = differenceInCalendarDays(now, newDate);
					projectInfo.dUpload = dUpload || 0;
					projectInfo.missingUpdate = dUpload >= 45 && scheduleType(project) === 'Active';
				}
				projectInfo.scheduleType = scheduleType(project);
				projectInfo.companyName = projectCompany?.text;
				projectInfo.clientName = projectClient?.name;
				projectInfo.prevVariance = project.rawReport?.projectTable?.previousVariance || 0;
				if (project.rawReport?.projectCompletionTrend?.projectCompletionTrendArray.length > 0) {
					const baselineCompletionDate = this.getDateIgnoreTimezone(
						project.rawReport?.projectCompletionTrend?.projectCompletionTrendArray[0].currentCompletion
					);
					const latestCompletionDate = this.getDateIgnoreTimezone(
						project.rawReport?.projectCompletionTrend?.projectCompletionTrendArray[
							project.rawReport?.projectCompletionTrend?.projectCompletionTrendArray.length - 1
						].currentCompletion
					);
					const cumulativeVariance = differenceInCalendarDays(baselineCompletionDate, latestCompletionDate);
					projectInfo.cumulativeVariance = isNaN(cumulativeVariance) ? null : cumulativeVariance;
				} else {
					projectInfo.cumulativeVariance = 0;
				}
				projectInfo.totalActivities = project.rawReport?.qualityControl?.totalActivities;
				projectInfo.riskMetricsType = projectInfo.riskMetricsType || 'default';
				projectInfo.pocName = projectInfo.pocId ? pocMembers.get(projectInfo.pocId)?.displayName : '';
				projectInfo.market = project.projectType;
				const preMitigationRiskScores: number[] = [];
				const postMitigationRiskScores: number[] = [];
				const likelihoodImpacts: number[] = [];
				const activeRisks: RiskRegister[] = [];
				if (project?.riskMetricsType === 'riskRegister') {
					const riskMitigations = project?.riskMitigation;
					if (!!riskMitigations && riskMitigations?.length > 0) {
						for (const riskMitigation of riskMitigations) {
							if (!riskMitigation.isDraft && !riskMitigation.disabled) {
								activeRisks.push(riskMitigation);
								if (riskMitigation.preMitigation) {
									const preMitigationRiskScore =
										(riskMitigation.preMitigation.probability || 0) *
										Math.max(
											riskMitigation.preMitigation.scheduleImpact || 0,
											riskMitigation.preMitigation.costImpact || 0,
											riskMitigation.preMitigation.qualityImpact || 0
										);
									const postMitigationRiskScore =
										(riskMitigation.preMitigation.probability || 0) *
										Math.max(
											riskMitigation.postMitigation.scheduleImpact || 0,
											riskMitigation.postMitigation.costImpact || 0,
											riskMitigation.postMitigation.qualityImpact || 0
										);
									preMitigationRiskScores.push(preMitigationRiskScore);
									postMitigationRiskScores.push(postMitigationRiskScore);
									const impact =
										Math.max(
											riskMitigation.preMitigation.qualityImpact,
											riskMitigation.preMitigation.performanceImpact,
											riskMitigation.preMitigation.costImpact,
											riskMitigation.preMitigation.scheduleImpact
										) || 0;
									const likelihood = riskMitigation.preMitigation.probability || 0;
									const likelihoodImpact = likelihood * impact;
									likelihoodImpacts.push(likelihoodImpact);
								}
							}
						}
					}
				}
				projectInfo.avgPreMitigationRiskScore =
					preMitigationRiskScores.length > 0
						? Math.round(preMitigationRiskScores.reduce((a, b) => a + b) / (preMitigationRiskScores.length || 1))
						: null;
				projectInfo.avgPostMitigationRiskScore =
					postMitigationRiskScores.length > 0
						? Math.round(postMitigationRiskScores.reduce((a, b) => a + b) / (postMitigationRiskScores.length || 1))
						: null;
				projectInfo.avgMitigationRiskScoreDelta =
					!!projectInfo.avgPreMitigationRiskScore && !!projectInfo.avgPostMitigationRiskScore
						? projectInfo.avgPreMitigationRiskScore - projectInfo.avgPostMitigationRiskScore
						: null;
				projectInfo.likelihoodImpact =
					likelihoodImpacts.length > 0
						? Math.round(likelihoodImpacts.reduce((a, b) => a + b) / (likelihoodImpacts.length || 1))
						: null;
				projectInfo.activeRisks = activeRisks?.length;
				projectInfo.monteCarloShowing =
					(!!project?.preferences?.monteCarlo?.pfVariance[0] ||
						!!project?.preferences?.monteCarlo?.pfVariance[1] ||
						!!project?.riskMitigation?.filter(
							(register) => register.preMitigation.activityImpact && register.impactedTaskCodes.length
						)?.length) &&
					project?.showMonteCarlo;
				if (projectClient) {
					allClients.set(projectClient.id, {
						text: projectClient.name,
						value: {
							text: projectClient.name,
							field: 'clientId',
							operator: FilterOperator.EqualTo,
							value: projectClient.id,
						},
						companyName: projectClient.parent.name,
					});
				}
				projectInfo.highDuration = project.rawReport?.qualityControl?.highDurationActivities;
				projectInfo.highFloat =
					project.rawReport?.qualityControl?.highFloatActivities +
					project.rawReport?.qualityControl?.problematicFloatActivities;
				projectInfo.actualsPastDD = project.rawReport?.qualityControl?.actualsPastDataDate;
				projectInfo.ssFFWithLags = project.rawReport?.qualityControl?.ssFfProblematic;
				projectInfo.missingLogic = project.rawReport?.qualityControl?.missingPredecessorsSuccessors;
				projectInfo.negativeLags = project.rawReport?.qualityControl?.negativeLags;
				projectInfo.fsWithLags = project.rawReport?.qualityControl?.fsLags;
				projectInfo.hardConstraints = project.rawReport?.qualityControl?.hardConstraints;
				projectInfo.outOfSequence = project.rawReport?.qualityControl?.totalOutOfSequenceActivities;
				projectInfo.cashFlow = project.rawReport?.cashFlowHistorical || null;
				projectInfo.costLoaded = this.costService.isValidCostProject(projectInfo.cashFlow);
				projectInfo.openStartFinish = project.rawReport?.qualityControl?.openStartFinish;
				projectInfo.sfRelationships = Math.floor(
					project.rawReport?.qualityControl?.percentSF * 0.01 * project.rawReport?.qualityControl?.totalRelationships
				);
				projectInfo.costBaseline = project?.costBaseline || undefined;
				allProjects.push(projectInfo);
				if (projectInfo?.currentCompletion !== undefined) {
					const projectItem: ProjectListGanttItem = {
						name: project.name,
						id: project._id,
						start: projectStart ? projectStart : undefined,
						end: projectInfo?.currentCompletion,
						currentCompletion: projectInfo?.currentCompletion,
						contractCompletion: projectInfo?.contractCompletion,
						projectStart,
						deltaVsCcd: projectInfo.deltaVsCcd,
						prevVariance: projectInfo.prevVariance,
						aegisScore: projectInfo.projectScore,
						progressScore: projectInfo.progressScore,
						qcScore: projectInfo.qcScore,
						predictabilityScore: projectInfo.predictabilityScore,
						updatesPerProject: projectInfo.updatesPerProject,
						lastUpdatedDate: projectInfo.lastUpdatedDate,
						projectCode: projectInfo.projectCode,
						clientName: projectInfo.clientName,
						scheduleType: projectInfo.scheduleType,
						companyName: projectInfo.companyName,
						market: projectInfo.market,
						pocName: projectInfo.pocName,
						highDuration: projectInfo.highDuration,
						highFloat: projectInfo.highFloat,
						actualsPastDD: projectInfo.actualsPastDD,
						ssFFWithLags: projectInfo.ssFFWithLags,
						softConstraints: projectInfo.softConstraints,
						missingLogic: projectInfo.missingLogic,
						negativeLags: projectInfo.negativeLags,
						fsWithLags: projectInfo.fsWithLags,
						hardConstraints: projectInfo.hardConstraints,
						outOfSequence: projectInfo.outOfSequence,
						missingUpdate: projectInfo.missingUpdate,
						calendarDaysRemaining: differenceInCalendarDays(projectInfo.currentCompletion, projectInfo.lastUpdatedDate),
						spi: projectInfo.spi,
						spiCumulative: projectInfo.spiCumulative,
						actualCost: projectInfo.actualCost,
						plannedCost: projectInfo.plannedCost,
						actualCostCumulative: projectInfo.actualCostCumulative,
						remainingCost: projectInfo.remainingCost,
						nextPeriodPlanned: projectInfo.nextPeriodPlanned,
						openStartFinish: projectInfo.openStartFinish,
						sfRelationships: projectInfo.sfRelationships,
						costBaseline: projectInfo.costBaseline,
						currentBudget: projectInfo.currentBudget,
						currentRemainingCost: projectInfo.currentRemainingCost,
					};
					if (projectItem?.projectStart !== null && projectItem?.projectStart !== undefined) {
						allItems.push(projectItem);
					}
				}
			}
			this.allItems = allItems;
			const savedZoom = localStorage.getItem('ganttZoom');
			if (savedZoom === 'null' || savedZoom === null) {
				this.selectedGanttView = null;
				this.autoFitToContainer();
			} else if (Number(savedZoom) !== this.slotWidth) {
				this.selectedGanttView = Number(savedZoom);
				this.updateView(Number(savedZoom), true);
			}
			this.updateGanttItems();
			const pocs: FilterItem[] = Array.from(pocMembers.values())
				.map((member) => ({
					text: member.displayName,
					companyName: member.company.name,
					value: {
						text: member.displayName,
						field: 'pocId',
						operator: FilterOperator.EqualTo,
						value: member.account.id,
					},
				}))
				.sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));
			this.clientList = [...allClients.values()];
			this.filteredClientList = [...allClients.values()];
			this.pocList = [...pocs];
			this.filteredPocList = [...pocs];
			this.companyList = Array.from(flattenedCompanies.values());
			this.filteredCompanyList = Array.from(this.companyList);
			this.$allProjects.next(allProjects);
			this.costService.updatePortfolioData(allProjects);
			if (allProjects?.length === 0) {
				this.navBarStorage.$triggerNewProjectTooltip.next(true);
			}
			this.nestedPocList = groupBy(pocs, [{ field: 'companyName' }]) as GroupResult[];
			this.nestedClientList = groupBy(this.clientList, [{ field: 'companyName' }]) as GroupResult[];
			this.filterProjects(allProjects, localStorage.getItem(LOCAL_STORAGE.LIST_VIEW) as QuickViewOption);
			this.loading = false;
			// const totalProj = allProjects.filter((proj) => proj.scheduleType !== 'Archived');
			// console.log(
			// 	1,
			// 	this.$allProjects.value,
			// 	this.$filteredProjects.value,
			// 	totalProj,
			// 	totalProj.filter((proj) => !this.$filteredProjects.value.includes(proj)).map((proj) => proj._id)
			// );
		};

		this._analyticsDashboardService.$allProjects.pipe(takeUntil(this._unsubscribeAll)).subscribe((projects) => {
			const lastSavedProjectsListView = localStorage.getItem('projectsListView');
			if (
				lastSavedProjectsListView !== this.projectsListView &&
				(lastSavedProjectsListView === 'grid' || lastSavedProjectsListView === 'gantt')
			) {
				this.projectsListView = lastSavedProjectsListView;
				const conversion = { Table: 'grid', Gantt: 'gantt' };
				this.viewButtons.forEach((btn) => {
					btn.selected = conversion[btn.text] === lastSavedProjectsListView;
				});
			}
			const members = this.userService.members.value;
			const allClients = this.userService.clients.value;
			const allCompanies = this.userService.companies.value;

			let timestampFilters = localStorage.getItem('rw-project-portfolio-ts');
			if (timestampFilters === null) {
				localStorage.setItem('rw-project-portfolio-ts', Date.now().toString());
				timestampFilters = localStorage.getItem('rw-project-portfolio-ts');
			}

			console.info('Checking timestamp filters: ', timestampFilters);

			//if timestamp is older than 24 hours, clear the filters
			if (Date.now() - Number(timestampFilters) > 24 * 60 * 60 * 1000) {
				localStorage.setItem('rw-project-portfolio-ts', Date.now().toString());
				localStorage.removeItem(LOCAL_STORAGE.LIST_FILTERS);
				localStorage.removeItem(LOCAL_STORAGE.LIST_VIEW);
				localStorage.removeItem(LOCAL_STORAGE.PREV_BASELINE_LIST_FILTERS);
				localStorage.removeItem('projectsListView');
				localStorage.removeItem('previousBanner');
				this.resetFiltersClicked();
			}

			if (members && projects && allClients && allCompanies) {
				let timestampFilters = localStorage.getItem('rw-project-portfolio-ts');
				if (timestampFilters === null) {
					localStorage.setItem('rw-project-portfolio-ts', Date.now().toString());
					timestampFilters = localStorage.getItem('rw-project-portfolio-ts');
				}

				//if timestamp is older than 24 hours, clear the filters
				if (Date.now() - Number(timestampFilters) > 24 * 60 * 60 * 1000) {
					localStorage.setItem('rw-project-portfolio-ts', Date.now().toString());
					localStorage.removeItem(LOCAL_STORAGE.LIST_FILTERS);
					localStorage.removeItem(LOCAL_STORAGE.LIST_VIEW);
					localStorage.removeItem('previousBanner');
					this.resetFiltersClicked();
				}

				//Should be working good: manages filters/quick view card values on refresh when quick view is selected. Goes back to baselines card values then keeps those cards while changing back to quick view table filter
				if (localStorage.getItem(LOCAL_STORAGE.PREV_BASELINE_LIST_FILTERS) !== null) {
					// this.goingBackToBaseline = true;
					const filters = new ProjectsFilter();
					filters.filters = this.defaultFilters(this.currentBanner === 'risk', this.currentBanner === 'cost');
					const storedFilters: Record<string, FilterItem[]> =
						JSON.parse(localStorage.getItem(LOCAL_STORAGE.PREV_BASELINE_LIST_FILTERS)) || {};
					delete storedFilters.aegisPocName;
					if (Object.keys(storedFilters).length > 0) {
						for (const [text, value] of Object.entries(storedFilters)) {
							filters.setFilterDescriptor(text, value, this.rangeSliders.includes(text) ? 'and' : 'or');
						}
					}
					this.$filters.filters = filters.filters;
				} else if (localStorage.getItem(LOCAL_STORAGE.LIST_VIEW) !== null) {
					const filters = new ProjectsFilter();
					filters.filters = this.defaultFilters(this.currentBanner === 'risk', this.currentBanner === 'cost');
					const storedFilters: Record<string, FilterItem[]> =
						JSON.parse(localStorage.getItem(LOCAL_STORAGE.LIST_FILTERS)) || {};
					delete storedFilters.aegisPocName;
					if (Object.keys(storedFilters).length > 0) {
						for (const [text, value] of Object.entries(storedFilters)) {
							filters.setFilterDescriptor(text, value, this.rangeSliders.includes(text) ? 'and' : 'or');
						}
					}
					this.$filters.filters = filters.filters;
					// this.doneBackToBaseline = true;
				}
				projectsPocsUpdates(projects, members, allClients, allCompanies);
			}
		});

		this.userService.members.pipe(takeUntil(this._unsubscribeAll)).subscribe((members) => {
			const allProjects = this._analyticsDashboardService.$allProjects.value;
			const allClients = this.userService.clients.value;
			const allCompanies = this.userService.companies.value;

			if (allProjects && members && allClients && allCompanies) {
				projectsPocsUpdates(allProjects, members, allClients, allCompanies);
			}
		});

		this.userService.clients.pipe(takeUntil(this._unsubscribeAll)).subscribe((clients) => {
			const allMembers = this.userService.members.value;
			const allProjects = this._analyticsDashboardService.$allProjects.value;
			const allCompanies = this.userService.companies.value;

			if (allMembers && allProjects && clients && allCompanies) {
				projectsPocsUpdates(allProjects, allMembers, clients, allCompanies);
			}
		});
		this.userService.companies.pipe(takeUntil(this._unsubscribeAll)).subscribe((companies) => {
			const allMembers = this.userService.members.value;
			const allProjects = this._analyticsDashboardService.$allProjects.value;
			const allClients = this.userService.clients.value;

			if (allMembers && allProjects && allClients && companies) {
				projectsPocsUpdates(allProjects, allMembers, allClients, companies);
			}
		});
		this.costService.$updatePortfolioProjectData.pipe(takeUntil(this._unsubscribeAll)).subscribe((val: boolean) => {
			if (val) {
				const newProjectData: ProjectListItem[] = [];
				this.$allProjects.value?.forEach((project: ProjectListItem) => {
					let category: string = '';
					const cashFlowData: CashFlowChartData = this.costService.projectCashFlowData.get(project._id);
					const historicData: HistoricPerformanceChartData = this.costService.projectHistoricalPerformanceData.get(
						project._id
					);
					const spiData: SPIChartData = this.costService.projectSPIData.get(project._id);
					const projectData: ProjectListItem = project;
					if (cashFlowData !== undefined) {
						cashFlowData.actCumulative.forEach((point: SeriesData) => {
							if (point.value !== null) {
								category = point.category;
							}
						});
						projectData.remainingCost = cashFlowData.remainingCost;
						projectData.nextPeriodPlanned = cashFlowData.nextPeriodPlanned;
						projectData.plannedCost = cashFlowData.budgetedCost;
						projectData.actualCostCumulative = cashFlowData.actCumulative.find((x) => x.category === category)?.value;
						projectData.currentBudget = cashFlowData.currentBudget;
						projectData.currentRemainingCost = cashFlowData.currentRemaining;
					}
					if (historicData !== undefined) {
						projectData.actualCost = historicData.actualPeriodic.find((x) => x.category === category)?.value;
					}
					if (spiData !== undefined) {
						projectData.spi = spiData.spiPeriodic[spiData.spiPeriodic?.length - 1].value;
						projectData.spiCumulative = spiData.spiCumulative[spiData.spiCumulative?.length - 1].value;
					}
					newProjectData.push(projectData);
				});
				this.$allProjects.next(newProjectData);
				const newGanttProjectData: ProjectListGanttItem[] = [];
				this.allItems.forEach((ganttProject: ProjectListGanttItem) => {
					const cashFlowData: CashFlowChartData = this.costService.projectCashFlowData.get(ganttProject.id);
					const historicData: HistoricPerformanceChartData = this.costService.projectHistoricalPerformanceData.get(
						ganttProject.id
					);
					const spiData: SPIChartData = this.costService.projectSPIData.get(ganttProject.id);
					const ganttProjectData: ProjectListGanttItem = ganttProject;
					if (cashFlowData !== undefined) {
						ganttProjectData.remainingCost = cashFlowData.remainingCost;
						ganttProjectData.nextPeriodPlanned = cashFlowData.nextPeriodPlanned;
						ganttProjectData.plannedCost = cashFlowData.budgetedCost;
						ganttProjectData.actualCostCumulative =
							cashFlowData.actCumulative[cashFlowData.actCumulative?.length - 1]?.value;
						ganttProjectData.currentBudget = cashFlowData.currentBudget;
						ganttProjectData.currentRemainingCost = cashFlowData.currentRemaining;
					}
					if (historicData !== undefined) {
						ganttProjectData.actualCost = historicData.actualPeriodic[historicData.actualPeriodic?.length - 1]?.value;
					}
					if (spiData !== undefined) {
						ganttProjectData.spi = spiData.spiPeriodic[spiData.spiPeriodic?.length - 1].value;
						ganttProjectData.spiCumulative = spiData.spiCumulative[spiData.spiCumulative?.length - 1].value;
					}
					newGanttProjectData.push(ganttProjectData);
				});
				this.allItems = newGanttProjectData;
			}
		});
	}

	@HostListener('window:mousedown', ['$event'])
	mouseDown(event) {
		//closes presets/column selector popups if clicked outside the popup
		if (this.presetsPopupIsOpen || this.columnSelectorPopupIsOpen) {
			const presetsBtn: DOMRect = document.getElementById('presetsOpenBtn')?.getBoundingClientRect();
			const columnsBtn: DOMRect = document.getElementById('columnsOpenBtn')?.getBoundingClientRect();
			if (
				(presetsBtn &&
					event.x >= presetsBtn.left &&
					event.x <= presetsBtn.right &&
					event.y >= presetsBtn.top &&
					event.y <= presetsBtn.bottom) ||
				(columnsBtn &&
					event.x >= columnsBtn.left &&
					event.x <= columnsBtn.right &&
					event.y >= columnsBtn.top &&
					event.y <= columnsBtn.bottom)
			) {
				return;
			}
			if (this.presetsPopupIsOpen) {
				const presetsContainer: HTMLElement = document.getElementById('presetsContainer');
				const presetPopupBounds: DOMRect = presetsContainer?.parentElement?.parentElement?.getBoundingClientRect();
				const quickSaveDialog: HTMLElement = document.getElementById('presetQuickSaveDialog');
				const quickSaveBounds: DOMRect = quickSaveDialog?.children[0]?.getBoundingClientRect();
				const confirmDeleteDialog: HTMLElement = document.getElementById('presetDeleteDialog');
				const confirmDeleteBounds: DOMRect = confirmDeleteDialog?.children[0]?.getBoundingClientRect();
				if (
					presetPopupBounds &&
					!(
						event.x >= presetPopupBounds.left &&
						event.x <= presetPopupBounds.right &&
						event.y >= presetPopupBounds.top &&
						event.y <= presetPopupBounds.bottom
					) &&
					(quickSaveBounds === undefined ||
						!(
							event.x >= quickSaveBounds.left &&
							event.x <= quickSaveBounds.right &&
							event.y >= quickSaveBounds.top &&
							event.y <= quickSaveBounds.bottom
						)) &&
					(confirmDeleteBounds === undefined ||
						!(
							event.x >= confirmDeleteBounds.left &&
							event.x <= confirmDeleteBounds.right &&
							event.y >= confirmDeleteBounds.top &&
							event.y <= confirmDeleteBounds.bottom
						))
				) {
					if (presetsContainer !== null) {
						this.presetsPopupRef.close();
					}
					this.presetsPopupIsOpen = false;
				}
			} else {
				const columnSelectorContainer: HTMLElement = document.getElementById('columnSelectorContainer');
				const columnSelectorPopupBounds: DOMRect =
					columnSelectorContainer?.parentElement?.parentElement?.getBoundingClientRect();
				if (
					columnSelectorPopupBounds &&
					!(
						event.x >= columnSelectorPopupBounds.left &&
						event.x <= columnSelectorPopupBounds.right &&
						event.y >= columnSelectorPopupBounds.top &&
						event.y <= columnSelectorPopupBounds.bottom
					)
				) {
					this.$columnSelectorOpened.next(null);
					this.columnSelectorPopupIsOpen = false;
				}
			}
		}
	}

	ngAfterViewInit(): void {
		if (!AnalyticsDashboardService.isGoogleMapsScriptLoaded) {
			const script = this._renderer2.createElement('script');
			script.type = `text/javascript`;
			script.src =
				'https://maps.googleapis.com/maps/api/js?key=AIzaSyDLqs4B7aCg4mZv8SAO4bLr6Oy8zD1qKTw&v=beta&libraries=marker,places&callback=initMap';
			this._renderer2.appendChild(this._document.body, script);
			window.initMap = () => {
				this.googleScriptLoaded = true;
				AnalyticsDashboardService.isGoogleMapsScriptLoaded = true;
				console.log('Google Maps script loaded');
				this.appMap?.initMap(this.appMap);
			};
		} else {
			this.appMap.initMap(this.appMap);
		}
	}

	/**
	 * match gantt sort with project list sort
	 * @param sort
	 */
	updateSortProjectList(sort: SortDescriptor[]): void {
		this.sort = structuredClone(sort);
	}

	/**
	 * gantt column sort handler
	 * @param sort
	 */
	public sortChange(sort: SortDescriptor[]): void {
		this.sort = structuredClone(sort);
		if (this._analyticsDashboardService.canEditPortfolioLocalStorage) {
			localStorage.setItem('projectListSort', JSON.stringify(this.sort));
		}
		this._userSelectedSort = sort;
		this._analyticsDashboardService.ganttSort.next(sort);
	}

	/**
	 * panning to scroll gantt pane setup
	 */
	setupScrolling() {
		let mouseDown = false;
		let startX: any;
		let scrollLeft: any;
		let startY: any;
		let scrollTop: any;
		const slider: any = document.querySelector('.k-gantt-timeline')?.children[1];

		if (slider !== null && slider !== undefined) {
			const startDragging = (e: any) => {
				this.isDragging = true;
				mouseDown = true;
				startX = e.pageX - slider.offsetLeft;
				startY = e.pageY - slider.offsetTop;
				scrollLeft = slider.scrollLeft;
				scrollTop = slider.scrollTop;
			};

			const stopDragging = (e: any) => {
				mouseDown = false;
				this.isDragging = false;
			};

			const move = (e: any) => {
				e.preventDefault();
				if (!mouseDown) {
					return;
				}
				const x = e.pageX - slider.offsetLeft;
				const y = e.pageY - slider.offsetTop;
				const scroll = x - startX;
				const top = y - startY;
				slider.scrollLeft = scrollLeft - scroll;
				slider.scrollTop = scrollTop - top;
			};

			// Add the event listeners
			slider.addEventListener('mousemove', move, false);
			slider.addEventListener('mousedown', startDragging, false);
			slider.addEventListener('mouseup', stopDragging, false);
			slider.addEventListener('mouseleave', stopDragging, false);
		}
	}

	/**
	 * update gantt chart and left pane to have newest dataset
	 */
	updateGanttItems(): void {
		const filteredProjects = this.$filteredProjects.value || [];
		this.items = this.allItems.filter(
			(item) => filteredProjects.find((project) => project._id === item.id) !== undefined
		);
		if (this.items.length) {
			let minStart = this.items[0].projectStart;
			let maxEnd = isBefore(this.items[0].contractCompletion, this.items[0].currentCompletion)
				? this.items[0].currentCompletion
				: this.items[0].contractCompletion;
			this.items.forEach((item) => {
				if (isBefore(item.projectStart, minStart)) {
					minStart = item.projectStart;
				}
				const latestDate = isBefore(item.currentCompletion, item.contractCompletion)
					? 'contractCompletion'
					: 'currentCompletion';
				if (isAfter(item[latestDate], maxEnd)) {
					maxEnd = item[latestDate];
				}
			});
			this.minStart = minStart;
			this.maxEnd = maxEnd;
			this.loading = true;
			setTimeout(() => {
				this.loading = false;
				setTimeout(() => {
					if (this.isFromSearch) {
						if (this.searchTerm !== '') {
							this.portfolioSearchbar.focus();
						}
						this.isFromSearch = false;
					}
					if (this.projectsListView === 'gantt') {
						this.scrollGanttToToday();
					}
					if (this.selectedGanttView === null) {
						this.autoFitToContainer();
					}
				}, 500);
			}, 200);
		}
	}

	/**
	 * update portfolio showing banner
	 * @param view
	 */
	updatePortfolioView(view: string): void {
		const oldBanner = structuredClone(this.currentBanner);
		localStorage.setItem('previousBanner', oldBanner);
		const order = ['default', 'cost', 'risk'];
		this.playingAnimation =
			order.findIndex((item) => item === oldBanner) < order.findIndex((item) => item === view)
				? 'slideUp'
				: 'slideDown';
		this.currentBanner = view;
		if (this._analyticsDashboardService.canEditPortfolioLocalStorage) {
			localStorage.setItem('currentBanner', this.currentBanner);
			localStorage.removeItem(LOCAL_STORAGE.LIST_VIEW);
		}
		this.update();
		this.bannerToggleFinished();
	}

	scrollGanttToToday(): void {
		if (this.minStart !== null && this.maxEnd !== null) {
			const timelineScrollPane = document.getElementsByClassName('k-grid-content');
			for (let i = 0; i < timelineScrollPane.length; i++) {
				const currentPane = timelineScrollPane.item(i);
				if (currentPane.classList.length === 1) {
					const maxWidth = currentPane.scrollWidth;
					const today = new Date();
					if (isAfter(today, this.minStart) && isBefore(today, this.maxEnd)) {
						const d =
							differenceInCalendarDays(today, this.minStart) /
							(differenceInCalendarDays(this.maxEnd, this.minStart) || 1);
						currentPane.scroll(d * maxWidth - 300, 0);
					}
				}
			}
		}

		window.onmousemove = (e) => {
			const newX = e.clientX - 118;
			if (this.showingTooltip) {
				this.tooltipVals.x = newX < 0 ? 0 : newX;
			}
		};

		if (!this.isPanningSetup) {
			this.setupScrolling();
		}
		setTimeout(() => {
			//show gantt vertical line borders on a quarterly basis and replace months with quarters
			if (this.slotWidth > 25 && this.slotWidth <= 35) {
				const columns = document.getElementsByClassName('k-gantt-columns');
				const row = columns.item(0)?.children[1]?.children[0];
				const startNumber = 13 - getMonth(this.minStart);
				if (startNumber < row.children.length - 1) {
					for (let i = startNumber; i < row.children.length; i += 3) {
						row.children[i].setAttribute('style', 'border-left: 1px solid rgba(0, 0, 0, 0.08)');
					}
				}
				const timeline = document.getElementsByClassName('k-gantt-timeline');
				const monthsRow = timeline.item(0)?.children[0]?.children[0]?.children[0]?.children[0]?.children[1];
				const offset = (getMonth(this.minStart) - 1) % 3;
				const shortMonths = ['Feb', 'May', 'Aug', 'Nov'];
				if (monthsRow.children.length > 0) {
					for (let i = 0; i < row.children.length; i++) {
						const val = (i + offset) % 3;
						switch (val) {
							case 0: {
								monthsRow.children[i].setAttribute('style', 'color: transparent');
								break;
							}
							case 1: {
								const oldTextContent = structuredClone(monthsRow.children[i].textContent);
								const quarter = shortMonths.findIndex((month: string) => month === oldTextContent) + 1;
								if (quarter !== 0) {
									monthsRow.children[i].textContent = 'Q' + quarter;
								}
								monthsRow.children[i].setAttribute('style', 'border-left: none;');
								break;
							}
							case 2: {
								monthsRow.children[i].setAttribute('style', 'color: transparent; border-left: none;');
								break;
							}
						}
					}
				}
			}
			//show gantt vertical line borders on a yearly basis once zoom is small enough
			if (this.slotWidth <= 25) {
				const columns = document.getElementsByClassName('k-gantt-columns');
				const row = columns.item(0)?.children[1]?.children[0];
				const startNumber = 13 - getMonth(this.minStart);
				if (startNumber < row?.children.length - 1) {
					for (let i = startNumber; i < row.children.length; i += 12) {
						row.children[i].setAttribute('style', 'border-left: 1px solid rgba(0, 0, 0, 0.08)');
					}
				}
			}
		});
	}

	/**
	 * updates slot width based on zoom value and re-renders gantt
	 */
	setZoom(skipLoadToggle = false): void {
		if (this.slotWidth !== this.zoomValue) {
			this.slotWidth = this.zoomValue;
			setTimeout(() => {
				if (!skipLoadToggle) {
					this.loading = true;
				}
				setTimeout(() => {
					if (!skipLoadToggle) {
						this.loading = false;
					}
					setTimeout(() => {
						if (this.projectsListView === 'gantt') {
							this.scrollGanttToToday();
						}
					}, 500);
				}, 500);
			}, 200);
		}
	}

	/**
	 * update gantt 'view'
	 * @param ev
	 * @param skipLoadToggle
	 */
	updateView(ev: number, skipLoadToggle = false): void {
		localStorage.setItem('ganttZoom', ev === null ? 'null' : ev.toString());
		if (ev === null) {
			this.autoFitToContainer();
		} else {
			this.zoomValue = ev;
			this.setZoom(skipLoadToggle);
		}
	}

	/**
	 * sync gantt showing columns with project list showing columns
	 * @param columns
	 */
	columnSelectorUpdated(columns: { visible: string[]; hidden: string[]; all: ProjectListColumn[] }): void {
		this.allColumns = columns.all;
		this.hiddenColumns = columns.hidden;
	}

	/**
	 * auto-fit gantt timeline pane width to container
	 */
	autoFitToContainer(skipLoadToggle = false): void {
		//timeout so the dom can finish updating to the expanded/collapsed width
		setTimeout(() => {
			const containerEl = document.getElementsByClassName('k-gantt-timeline')[0] as HTMLElement;
			const headerEl = containerEl?.children[0].children[0].children[0].children[0].children[1];
			const numberOfSubdivisions = headerEl?.children.length ?? 0;
			//-12 for vertical scroll bar
			const containerWidth = containerEl?.getBoundingClientRect().width - 12;
			if (numberOfSubdivisions) {
				this.zoomValue = containerWidth / numberOfSubdivisions;
			}
			this.setZoom(skipLoadToggle);
		}, 500);
	}

	calculateBarWidth(task: any, type: string, isMilestone = false, isProgress = false): number {
		if (isProgress && isBefore(task.lastUpdatedDate, task.projectStart)) {
			return 0;
		}
		const latestDate = isBefore(task.currentCompletion, task.contractCompletion)
			? 'contractCompletion'
			: 'currentCompletion';
		const reverseType = type === 'currentCompletion' ? 'contractCompletion' : 'currentCompletion';
		if (
			latestDate === 'contractCompletion' &&
			type === 'contractCompletion' &&
			!isSameDay(task.currentCompletion, task.contractCompletion) &&
			!isMilestone &&
			!isProgress
		) {
			return 0;
		}
		const fullBar = task.currentCompletion.getTime() - task.projectStart.getTime();
		const startToTypeEnd = task[type].getTime() - task.projectStart.getTime();
		if (
			latestDate === 'currentCompletion' &&
			!isSameDay(task.currentCompletion, task.contractCompletion) &&
			!isMilestone &&
			!isProgress
		) {
			const val = (100 * (task[reverseType].getTime() - task.projectStart.getTime())) / fullBar;
			return val > 100 ? 100 : val;
		}

		const d = (100 * startToTypeEnd) / fullBar;
		return d > 100 ? 100 : d;
	}

	/**
	 * buttongroup selection change handler
	 * @param selected
	 * @param btn
	 */
	public selectionChange(selected: boolean, btn: IButton): void {
		btn.selected = selected;
		if (btn.selected === true) {
			this.loading = true;
			this.projectsListView = btn.text === 'Table' ? 'grid' : 'gantt';
			localStorage.setItem('projectsListView', this.projectsListView);
			this.loading = false;
			if (this.projectsListView === 'gantt') {
				this.updateGanttItems();
				if (this.selectedGanttView === null) {
					this.autoFitToContainer();
				}
			} else {
				setTimeout(() => {
					this.sort = structuredClone(this.sort);
				}, 200);
			}
		}
	}

	/**
	 * disable localstorage filter updates when nav event happens
	 */
	navToProjectEvent() {
		this._analyticsDashboardService.closeAll();
		this._analyticsDashboardService.canEditPortfolioLocalStorage = false;
	}

	filterProjects(allProjects: ProjectListItem[] = this.$allProjects.value, view: QuickViewOption) {
		if (allProjects.length === 0) {
			this.ui.portfolioEmpty = true;
		}
		if (!allProjects) {
			return;
		}
		const currentView: string = localStorage.getItem(LOCAL_STORAGE.LIST_VIEW) as QuickViewOption;
		const filters = new ProjectsFilter();
		filters.filters = this.defaultFilters(this.currentBanner === 'risk', this.currentBanner === 'cost');
		const storedFilters: Record<string, FilterItem[]> =
			JSON.parse(localStorage.getItem(LOCAL_STORAGE.PREV_BASELINE_LIST_FILTERS)) || {};
		if (Object.keys(storedFilters).length > 0) {
			for (const [text, value] of Object.entries(storedFilters)) {
				filters.setFilterDescriptor(text, value, this.rangeSliders.includes(text) ? 'and' : 'or');
			}
		}
		const filteredProjectsWithoutView = filterBy(allProjects, filters.filter);
		this.updateProjectStats(filteredProjectsWithoutView, false);
		if (!isQuickViewOption(currentView)) {
			this.selectedQuickView = undefined;
			localStorage.removeItem(LOCAL_STORAGE.LIST_VIEW);
			delete storedFilters.aegisPocName;
		} else {
			this.selectedQuickView = currentView;
			const viewFilters = this.softReset(this.selectedQuickView, this.currentBanner);
			if (viewFilters) {
				filters.mergeFilters(viewFilters);
			}
			filters.mergeFilters(QuickViewFilters[currentView]);
		}
		if (view !== currentView && view !== null) {
			const viewFilters = this.softReset(view, this.currentBanner);
			if (viewFilters) {
				filters.mergeFilters(viewFilters);
			}
		}
		this.$filters.filters = filters.filters;
		const filteredProjects = filterBy(allProjects, filters.filter);
		this.$filteredProjects.next(filteredProjects);
		if (this.projectsListView === 'gantt') {
			this.updateGanttItems();
		}
		this.updateProjectStats(filteredProjects, true);
	}

	update(val?: boolean) {
		this.listUpdatedFromPageLoad = val ? val : false;
	}

	resetFilters() {
		return;
		this.resetFiltersSubject.next();
		if (this.projectsListView === 'gantt') {
			this.updateGanttItems();
		}
	}

	/**
	 * reset filters button clicked in project list
	 */
	resetFiltersClicked(): void {
		this.selectedQuickView = undefined;
		localStorage.removeItem(LOCAL_STORAGE.LIST_VIEW);
		const blProjectListAfterReset = filterBy(this.$allProjects.value, this.$filters.filter);
		this.$filteredProjects.next(blProjectListAfterReset);
		if (this.projectsListView === 'gantt') {
			this.updateGanttItems();
		}
	}

	public defaultFilters(isRisk?: boolean, isCost = false): ProjectFieldFilter[] {
		const filters: Array<ProjectFieldFilter> = [];
		filters.push({
			logic: 'or',
			text: 'companyName',
			filters: this.clientList.reduce((accumulator, f) => accumulator.concat(f.value), []),
		});
		filters.push({
			logic: 'or',
			text: 'scheduleType',
			filters: this.scheduleTypeList
				.filter((schedType) => schedType.text !== 'Archived')
				.reduce((accumulator, f) => accumulator.concat(f.value), []),
		});
		filters.push({
			logic: 'or',
			text: 'company',
			filters: this.companyList.reduce((accumulator, f) => accumulator.concat(f.value), []),
		});
		if (isRisk) {
			filters.push({
				logic: 'or',
				text: 'riskMetricsType',
				filters: this.riskList
					.filter((risk) => risk.value.value === 'performanceFactor' || risk.value.value === 'riskRegister')
					.reduce((accumulator, f) => accumulator.concat(f.value), []),
			});
		} else {
			filters.push({
				logic: 'or',
				text: 'riskMetricsType',
				filters: this.riskList.reduce((accumulator, f) => accumulator.concat(f.value), []),
			});
		}
		filters.push({
			logic: 'or',
			text: 'pocId',
			filters: this.pocList.reduce((accumulator, f) => accumulator.concat(f.value), []),
		});
		filters.push({
			logic: 'or',
			text: 'clientId',
			filters: this.clientList.reduce((accumulator, f) => accumulator.concat(f.value), []),
		});
		filters.push({
			logic: 'or',
			text: 'projectType',
			filters: this.projectTypeList.reduce((accumulator, f) => accumulator.concat(f.value), []),
		});
		filters.push({
			logic: 'or',
			text: 'deltaVsCcd',
			filters: this.completionStatus.reduce((accumulator, f) => accumulator.concat(f.value), []),
		});
		filters.push({
			logic: 'or',
			text: 'prevVariance',
			filters: this.pastPeriodPerformanceList.reduce((accumulator, f) => accumulator.concat(f.value), []),
		});
		filters.push({
			logic: 'or',
			text: 'update-status',
			filters: [{ text: 'update-status', field: 'dUpload', operator: FilterOperator.IsNotNull, value: 0 }],
		});
		if (isCost) {
			filters.push({
				logic: 'or',
				text: 'cost-loaded',
				filters: [{ text: 'cost-loaded', field: 'costLoaded', operator: FilterOperator.EqualTo, value: true }],
			});
		} else {
			filters.push({
				logic: 'or',
				text: 'cost-loaded',
				filters: [{ text: 'cost-loaded', field: 'costLoaded', operator: FilterOperator.IsNotNull }],
			});
		}
		filters.push({
			logic: 'and',
			text: 'progressScore',
			filters: [
				{ text: 'progressScore', field: 'progressScore', operator: FilterOperator.GreaterThanOrEqual, value: 0 },
				{ text: 'progressScore', field: 'progressScore', operator: FilterOperator.LessThanOrEqual, value: 100 },
			],
		});
		filters.push({
			logic: 'and',
			text: 'projectScore',
			filters: [
				{ text: 'projectScore', field: 'projectScore', operator: FilterOperator.GreaterThanOrEqual, value: 0 },
				{ text: 'projectScore', field: 'projectScore', operator: FilterOperator.LessThanOrEqual, value: 100 },
			],
		});
		filters.push({
			logic: 'and',
			text: 'qcScore',
			filters: [
				{ text: 'qcScore', field: 'qcScore', operator: FilterOperator.GreaterThanOrEqual, value: 0 },
				{ text: 'qcScore', field: 'qcScore', operator: FilterOperator.LessThanOrEqual, value: 100 },
			],
		});
		filters.push({
			logic: 'and',
			text: 'predictabilityScore',
			filters: [
				{
					text: 'predictabilityScore',
					field: 'predictabilityScore',
					operator: FilterOperator.GreaterThanOrEqual,
					value: 0,
				},
				{
					text: 'predictabilityScore',
					field: 'predictabilityScore',
					operator: FilterOperator.LessThanOrEqual,
					value: 100,
				},
			],
		});
		filters.push({
			text: 'completionVar',
			filters: [
				{
					text: 'completionVar',
					field: 'cumulativeVariance',
					operator: FilterOperator.GreaterThanOrEqual,
					value: -Number.MAX_SAFE_INTEGER,
				},
				{
					text: 'completionVar',
					field: 'cumulativeVariance',
					operator: FilterOperator.LessThanOrEqual,
					value: Number.MAX_SAFE_INTEGER,
				},
			],
			logic: 'and',
		});
		return filters;
	}

	clickedOnMarker(ev) {
		this.clickedOnMapMarker.next();
	}

	/**
	 * gathers risk scores from last 6 months for all risk enabled projects
	 * @param projects
	 */
	updateRiskTrendingChartData(projects): Map<Date, Map<string, number>> {
		//make dateMap with end of month dates for datapoint months
		const dateIterator = new Date();
		dateIterator.setMonth(dateIterator.getMonth() - 5);
		const monthMap = new Map<Date, Map<string, number>>([]);
		for (let i = 0; i < 6; i++) {
			const endOfMonthDate = new Date(dateIterator.getUTCFullYear(), dateIterator.getUTCMonth() + i + 1, 0);
			monthMap.set(endOfMonthDate, new Map<string, number>([]));
		}

		//get risk score for each month for each project
		for (const project of projects) {
			const rawReport = project.rawReport;
			if (!rawReport) {
				break;
			}
			if (project.riskMetricsType === 'riskRegister' || project.riskMetricsType === 'performanceFactor') {
				const dataDateArray = rawReport.completionVarianceGraph.dataDateArray;
				if (!dataDateArray) {
					break;
				}
				for (const [key, value] of monthMap.entries()) {
					let indexOfUpdate = -1;
					for (const [index, update] of dataDateArray.entries()) {
						//skip updates that have missing dataDates
						if (update === '') {
							continue;
						}
						const newDate = new Date(update);
						const startOfKeyMonth: Date = startOfMonth(key);
						if (
							(isBefore(newDate, key) || isSameDay(newDate, key)) &&
							(isAfter(newDate, startOfKeyMonth) || isSameDay(newDate, startOfKeyMonth))
						) {
							indexOfUpdate = index;
						}
					}
					if (indexOfUpdate !== -1) {
						if (rawReport.calculationFieldsHistorical) {
							const riskScore = rawReport.calculationFieldsHistorical[indexOfUpdate].riskScore;
							if (riskScore !== null) {
								value.set(project._id, riskScore);
							}
						}
					}
				}
			}
		}
		return monthMap;
	}

	/**
	 * toggles visibility of gantt timeline bar tooltip after it updates the data
	 * @param e
	 * @param bar
	 * @param task
	 * @param show
	 */
	toggleTooltip(e: MouseEvent, bar, task: ProjectListGanttItem, show: boolean): void {
		if (show) {
			const newX = e.clientX - 118;
			const newY = bar.getBoundingClientRect().top - 165;
			this.tooltipVals = {
				x: newX < 0 ? 0 : newX,
				y: newY < 0 ? 0 : newY,
				name: task.name,
				projectStart: task.projectStart,
				lastUpdatedDate: task.lastUpdatedDate,
				currentCompletion: task.currentCompletion,
				contractCompletion: task.contractCompletion,
				deltaVsCcd: task.deltaVsCcd,
				calendarDaysRemaining: task.calendarDaysRemaining,
			};
		}
		this.showingTooltip = show;
	}

	/**
	 * gathers scores from last 6 months for all projects
	 * @param projects
	 */
	updateScoreTrendingChartData(projects): void {
		//make dateMap with end of month dates for datapoint months
		const dateIterator = new Date();
		dateIterator.setMonth(dateIterator.getMonth() - 5);
		const monthMapProjectScore = new Map<Date, Map<string, number>>([]);
		const monthMapProgressScore = new Map<Date, Map<string, number>>([]);
		const monthMapPredictabilityScore = new Map<Date, Map<string, number>>([]);
		const monthMapQcScore = new Map<Date, Map<string, number>>([]);
		for (let i = 0; i < 6; i++) {
			const endOfMonthDate = new Date(dateIterator.getUTCFullYear(), dateIterator.getUTCMonth() + i + 1, 0);
			monthMapProjectScore.set(endOfMonthDate, new Map<string, number>([]));
			monthMapProgressScore.set(endOfMonthDate, new Map<string, number>([]));
			monthMapPredictabilityScore.set(endOfMonthDate, new Map<string, number>([]));
			monthMapQcScore.set(endOfMonthDate, new Map<string, number>([]));
		}

		//get project score for each month for each project
		for (const project of projects) {
			const projectScheduleType = scheduleType(project);
			if (projectScheduleType === 'Archived') {
				continue;
			}
			const rawReport = project.rawReport;
			if (!rawReport) {
				break;
			}
			const dataDateArray = rawReport.completionVarianceGraph.dataDateArray;
			if (!dataDateArray) {
				break;
			}
			if (monthMapProjectScore.size) {
				for (const [key, value] of monthMapProjectScore.entries()) {
					let indexOfUpdate = -1;
					//determines index of the latest update before the cutoff date for the monthMap
					for (const [index, update] of dataDateArray.entries()) {
						//skip updates that have missing dataDates
						if (update === '') {
							continue;
						}
						const dateSplit = update.split(' ');
						const dateString = dateSplit[1] + ' ' + dateSplit[2] + ' ' + dateSplit[3];
						const newDate = new Date(dateString);
						const monthStart = startOfMonth(key);
						if (isBefore(newDate, key) || isSameDay(newDate, key)) {
							if (isAfter(newDate, monthStart) || isSameDay(newDate, monthStart)) {
								indexOfUpdate = index;
							}
						} else {
							//early return when rest of updates are after comparison date to save compute time
							break;
						}
					}
					if (indexOfUpdate !== -1) {
						if (rawReport.calculationFieldsHistorical) {
							const projectScore = rawReport.calculationFieldsHistorical[indexOfUpdate].projectScore;
							const progressScore = rawReport.calculationFieldsHistorical[indexOfUpdate].progressScore;
							const predictabilityScore = rawReport.calculationFieldsHistorical[indexOfUpdate].predictabilityScore;
							const qcScore = rawReport.calculationFieldsHistorical[indexOfUpdate].qcScore;
							if (projectScore) {
								value.set(project._id, projectScore);
							}
							if (!!progressScore && projectScheduleType !== 'Baseline') {
								const progressMap = monthMapProgressScore.get(key).set(project._id, progressScore);
								monthMapProgressScore.set(key, progressMap);
							}
							if (predictabilityScore) {
								const predictabilityMap = monthMapPredictabilityScore.get(key).set(project._id, predictabilityScore);
								monthMapPredictabilityScore.set(key, predictabilityMap);
							}
							if (qcScore) {
								const qcMap = monthMapQcScore.get(key).set(project._id, qcScore);
								monthMapQcScore.set(key, qcMap);
							}
						}
					}
				}
			}
		}
		this.monthMapProjectScore = monthMapProjectScore;
		this.monthMapProgressScore = monthMapProgressScore;
		this.monthMapPredictabilityScore = monthMapPredictabilityScore;
		this.monthMapQcScore = monthMapQcScore;
	}

	/**
	 * updates project view stats based on latest baseline project list and all other stats based on the current view project list
	 * @param projects
	 * @param viewChange
	 */
	updateProjectStats(projects?: Array<ProjectListItem>, viewChange: boolean = false) {
		if (!projects) {
			return;
		}
		this.$riskRegisterProjects.next(projects.filter((proj) => proj.riskMetricsType === 'riskRegister'));
		projects = projects || this.$filteredProjects.value;
		if (!projects) {
			return;
		}

		let slippedCount = 0;
		let gainedCount = 0;
		let maintainedCount = 0;
		let missedLastUploadCount = 0;
		let riskMissedLastUploadCount = 0;
		let riskSlippedCount = 0;

		const monthlyRisks = this.updateRiskTrendingChartData(projects);
		this.updateScoreTrendingChartData(projects);
		let totalRiskProjects = 0;

		const globalProjectReportLength = this.$projectCompletionVariance.value?.length;
		const completionVars = [];
		let numUnarchived = 0;
		let activeProjectsCount = 0;
		let areThereNonBaselineProjects = false;

		const latestUpdateProjectScores = new Map<string, number>([]);
		const latestUpdateProgressScores = new Map<string, number>([]);
		const latestUpdateQcScores = new Map<string, number>([]);
		const latestUpdatePredictabilityScores = new Map<string, number>([]);

		for (const project of projects) {
			if (!areThereNonBaselineProjects && project.rawReport?.updateIds?.length > 1) {
				areThereNonBaselineProjects = true;
			}
			const isActive = scheduleType(project) === 'Active';
			if (isActive) {
				activeProjectsCount++;
				numUnarchived++;
				if (!globalProjectReportLength) {
					completionVars.push(project.rawReport?.completionVarianceGraph);
				}

				const prevVariance = project.rawReport?.projectTable?.previousVariance;
				if (prevVariance < 0) {
					slippedCount++;
					if (project.riskMetricsType === 'riskRegister' || project.riskMetricsType === 'performanceFactor') {
						riskSlippedCount++;
					}
				}
				if (prevVariance > 0) {
					gainedCount++;
				}
				if (!prevVariance) {
					maintainedCount++;
				}
				const lastUploaded = new Date(project?.rawReport?.projectOverview?.dataDate);
				const now = new Date();
				const dUpload = differenceInCalendarDays(now, lastUploaded);
				if (dUpload >= 45 && scheduleType(project) === 'Active') {
					missedLastUploadCount++;
					if (project.riskMetricsType === 'riskRegister' || project.riskMetricsType === 'performanceFactor') {
						riskMissedLastUploadCount++;
					}
				}

				if (project.riskMetricsType === 'riskRegister' || project.riskMetricsType === 'performanceFactor') {
					totalRiskProjects++;
				}
			}
			if (project?.projectScore) {
				latestUpdateProjectScores.set(project._id, project?.projectScore);
			}
			if (project?.progressScore) {
				latestUpdateProgressScores.set(project._id, project?.progressScore);
			}
			if (project?.qcScore) {
				latestUpdateQcScores.set(project._id, project?.qcScore);
			}
			if (project?.predictabilityScore) {
				latestUpdatePredictabilityScores.set(project._id, project?.predictabilityScore);
			}
		}
		if (!globalProjectReportLength) {
			completionVars.sort(compareVarianceArrayLength);
			const top5Var = completionVars.splice(0, 5);
			this.$projectCompletionVariance.next(top5Var);
		}
		// these should only change on baseline change
		if (!viewChange) {
			this.$activeProjects.next(activeProjectsCount);
			this.$totalMissedLastUpload.next(missedLastUploadCount);
			this.$totalSlipped.next(slippedCount);
			this.$slippedPercentage.next(+((slippedCount / (numUnarchived || 1)) * 100).toFixed(0));
			this.$totalMaintained.next(maintainedCount);
			this.$maintainedPercentage.next(+((maintainedCount / (numUnarchived || 1)) * 100).toFixed(0));
			this.$totalGained.next(gainedCount);
			this.$gainedPercentage.next(+((gainedCount / (numUnarchived || 1)) * 100).toFixed(0));
			this.$totalRiskMissedLastUpload.next(riskMissedLastUploadCount);
			this.$totalRiskSlipped.next(riskSlippedCount);
			if (
				(this.$totalGained.value == null || this.$totalMaintained.value == null) &&
				this.$allProjects.value.length &&
				this.goingBackToBaseline
			) {
				if (this.$totalGained.value == null) {
					this.$totalGained.next(gainedCount);
					this.$gainedPercentage.next(+((gainedCount / (numUnarchived || 1)) * 100).toFixed(0));
				}
				if (this.$totalMaintained.value == null) {
					this.$totalMaintained.next(maintainedCount);
				}
				if (this.$totalMissedLastUpload.value == null) {
					this.$totalMissedLastUpload.next(missedLastUploadCount);
				}
			}
			const denominator = numUnarchived || 1;
			const slippedPercentage = this.$totalSlipped.value / denominator;
			const onTrackPercentage = this.$totalMaintained.value / denominator;
			const improvedPercentage = this.$totalGained.value / denominator;
			const currentView = localStorage.getItem(LOCAL_STORAGE.LIST_VIEW);
			this.pieData = [
				{
					category: 'Slipped',
					valueAsPercentage: slippedPercentage,
					color: '#df5353',
					exploded: currentView === 'slipped',
					valueAsNumber: this.$totalSlipped.value,
				},
				{
					category: 'On-Track',
					valueAsPercentage: onTrackPercentage,
					color: '#4fc931',
					exploded: currentView === 'onTrack',
					valueAsNumber: this.$totalMaintained.value,
				},
				{
					category: 'Improved',
					valueAsPercentage: improvedPercentage,
					color: '#0059FF',
					exploded: currentView === 'improved',
					valueAsNumber: this.$totalGained.value,
				},
			];
			const latestUpdateTotalRiskProjects = totalRiskProjects;
			this.$allRiskProjects.next(latestUpdateTotalRiskProjects);
		}
		const newRiskTrendingCategories = [];
		const newRiskTrendingData = [];
		const newProjectScoreTrendingData = [];
		const newProgressScoreTrendingData = [];
		const newPredictabilityScoreTrendingData = [];
		const newQcScoreTrendingData = [];
		for (const month of monthlyRisks.entries()) {
			let sumOfRisks = 0;
			month[1].forEach((riskScore) => (sumOfRisks += riskScore));
			const averageRisk = sumOfRisks / (month[1].size || 1);
			newRiskTrendingCategories.push(format(month[0], 'LLL'));
			newRiskTrendingData.push(Math.round(averageRisk));
		}
		let updatesExistInPastSixMonths = false;
		for (const month of this.monthMapProjectScore.entries()) {
			let sumOfProjectScores = 0;
			month[1].forEach((projectScore) => (sumOfProjectScores += projectScore));
			const averageProjectScore = sumOfProjectScores / (month[1].size || 1);
			newProjectScoreTrendingData.push(month[1].size > 0 ? Math.round(averageProjectScore) : null);
			if (month[1].size > 0) {
				updatesExistInPastSixMonths = true;
			}
		}
		this.updatesExistInPastSixMonths = updatesExistInPastSixMonths;
		for (const month of this.monthMapProgressScore.entries()) {
			let sumOfProgressScores = 0;
			month[1].forEach((progressScore) => (sumOfProgressScores += progressScore));
			const averageProgressScore = sumOfProgressScores / (month[1].size || 1);
			newProgressScoreTrendingData.push(month[1].size > 0 ? Math.round(averageProgressScore) : null);
		}
		for (const month of this.monthMapPredictabilityScore.entries()) {
			let sumOfPredictabilityScores = 0;
			month[1].forEach((predictabilityScore) => (sumOfPredictabilityScores += predictabilityScore));
			const averagePredictabilityScore = sumOfPredictabilityScores / (month[1].size || 1);
			newPredictabilityScoreTrendingData.push(month[1].size > 0 ? Math.round(averagePredictabilityScore) : null);
		}
		for (const month of this.monthMapQcScore.entries()) {
			let sumOfQcScores = 0;
			month[1].forEach((qcScore) => (sumOfQcScores += qcScore));
			const averageQcScore = sumOfQcScores / (month[1].size || 1);
			newQcScoreTrendingData.push(month[1].size > 0 ? Math.round(averageQcScore) : null);
		}
		this.allSummaryTrendData.projectScore = newProjectScoreTrendingData;
		this.allSummaryTrendData.progressScore = newProgressScoreTrendingData;
		this.allSummaryTrendData.predictabilityScore = newPredictabilityScoreTrendingData;
		this.allSummaryTrendData.qcScore = newQcScoreTrendingData;
		//Makes Progress Score have '-' instead of 0 when baseline is the only filter active for schedule type since baseline does not have progress score
		for (const filter of this.$filters.filters) {
			if (filter.text === 'scheduleType' && filter.filters.length === 1 && filter.filters[0].text === 'Baseline') {
				this.$averageProgressScore.next(null);
			}
		}
		if (!areThereNonBaselineProjects) {
			this.$averageProgressScore.next(null);
		}
		const updateTrend = hasObjChanged(this.riskSummaryTrendData, newRiskTrendingData);
		if (updateTrend) {
			this.riskSummaryTrendCategories = newRiskTrendingCategories;
			this.riskSummaryTrendData = newRiskTrendingData;
		}

		this.portfolioSummaryTrendData = this.allSummaryTrendData[this.selectedSummaryChart];
		const latestUpdateAverageRisk = newRiskTrendingData[newRiskTrendingData.length - 1] || 0;
		const latestUpdateAverageProjectScore = newProjectScoreTrendingData[newProjectScoreTrendingData.length - 1] || 0;
		this.$averageRiskScore.next(latestUpdateAverageRisk);
		let sumOfLatestProjectScores = 0;
		let sumOfLatestProgressScores = 0;
		let sumOfLatestQcScores = 0;
		let sumOfLatestPredictabilityScores = 0;
		latestUpdateProjectScores.forEach((score: number) => {
			sumOfLatestProjectScores += score;
		});
		latestUpdateProgressScores.forEach((score: number) => {
			sumOfLatestProgressScores += score;
		});
		latestUpdateQcScores.forEach((score: number) => {
			sumOfLatestQcScores += score;
		});
		latestUpdatePredictabilityScores.forEach((score: number) => {
			sumOfLatestPredictabilityScores += score;
		});
		this.$averageProjectScore.next(sumOfLatestProjectScores / (latestUpdateProjectScores?.size || 1));
		this.$averageProgressScore.next(
			latestUpdateProgressScores?.size === 0
				? null
				: sumOfLatestProgressScores / (latestUpdateProgressScores?.size || 1)
		);
		this.$averageQCScore.next(sumOfLatestQcScores / (latestUpdateQcScores?.size || 1));
		this.$averagePredictabilityScore.next(
			sumOfLatestPredictabilityScores / (latestUpdatePredictabilityScores?.size || 1)
		);
		this.numUnarchived = numUnarchived;
		this.$totalShowingProjects.next(latestUpdateProjectScores?.size || 1);
	}

	selectProject(project: ProjectInterface) {
		this.isProjectExpanded = true;
		this.selectedProject.next(project);
		document.getElementById('projectsMapSection').classList.add('gridlayout-container');
		document.getElementById('projectsMapSection').classList.remove('nothing-expanded');
		document.getElementById('dashboard-analytics').classList.remove('none-expanded');
		this.clickedCoords.next({ lat: project?.lat, lng: project?.lng });
	}

	deselectProject() {
		this.isProjectExpanded = false;
		document.getElementById('projectsMapSection').classList.remove('gridlayout-container');
		document.getElementById('projectsMapSection').classList.add('nothing-expanded');
		document.getElementById('dashboard-analytics').classList.add('none-expanded');
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
		this._analyticsDashboardService.$allProjects.next(undefined);
	}

	private _registerCustomChartJSPlugin(): void {
		(window as any).Chart?.plugins.register({
			afterDatasetsDraw: (chart, easing): any => {
				if (
					!chart.options.plugins.xLabelsOnTop ||
					(chart.options.plugins.xLabelsOnTop && chart.options.plugins.xLabelsOnTop.active === false)
				) {
					return;
				}

				// To only draw at the end of animation, check for easing === 1
				const ctx = chart.ctx;

				chart.data.datasets?.forEach((dataset, i): any => {
					const meta = chart.getDatasetMeta(i);
					if (!meta.hidden) {
						meta.data?.forEach((element, index): any => {
							// Draw the text in black, with the specified font
							ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
							const fontSize = 13;
							const fontStyle = 'normal';
							const fontFamily = 'Roboto, Helvetica Neue, Arial';
							ctx.font = (window as any).Chart.helpers.fontString(fontSize, fontStyle, fontFamily);

							// Just naively convert to string for now
							const dataString = dataset.data[index].toString() + 'k';
							// Make sure alignment settings are correct
							ctx.textAlign = 'center';
							ctx.textBaseline = 'middle';
							const padding = 15;
							const startY = 24;
							const position = element.tooltipPosition();
							ctx.fillText(dataString, position.x, startY);

							ctx.save();

							ctx.beginPath();
							ctx.setLineDash([5, 3]);
							ctx.moveTo(position.x, startY + padding);
							ctx.lineTo(position.x, position.y - padding);
							ctx.strokeStyle = 'rgba(255,255,255,0.12)';
							ctx.stroke();

							ctx.restore();
						});
					}
				});
			},
		});
	}

	/**
	 * Resets filter list and applies simple filter
	 * @param filterSelector
	 * @param filterValue
	 */
	applySimpleFilter(filterSelector, filterValue, prevFilterVal?, alsoApplyRiskFilter?: boolean): void {
		this.resetFilters();
		if (alsoApplyRiskFilter === true) {
			this._analyticsDashboardService.setFilter('riskMetricsType', 'performanceFactor', [
				'performanceFactor',
				'riskRegister',
			]);
		}
		this._analyticsDashboardService.setFilter(filterSelector, filterValue, prevFilterVal);
	}

	/**
	 * toggle explode on pie slice on click
	 * @param ev
	 */
	clickedPieSlice(ev) {
		if (ev.dataItem.exploded) {
			ev.dataItem.exploded = false;
		} else {
			this.pieData.forEach((slice) => (slice.exploded = false));
			ev.dataItem.exploded = true;
		}
		this.pieData = this.pieData.slice();
		//Allows user to control pie chart explosion and quick view change at the same time from either component
		switch (ev.category) {
			case 'Slipped': {
				this.quickViewChange('slipped');
				break;
			}
			case 'Improved': {
				this.quickViewChange('improved');
				break;
			}
			case 'On-Track': {
				this.quickViewChange('onTrack');
				break;
			}
		}
	}

	expandPieSlice(category) {
		const newPieData = structuredClone(this.pieData);
		for (const breakdown of newPieData) {
			if (breakdown.category === category) {
				breakdown.exploded = !breakdown.exploded;
				continue;
			}
			breakdown.exploded = false;
		}
		this.pieData = newPieData;
	}

	/**
	 * visual updates once banner toggle finished
	 */
	bannerToggleFinished(): void {
		if (this.listUpdatedFromPageLoad !== true) {
			this._analyticsDashboardService.resetFilters.next(true);
			this.selectedQuickView = undefined;
		}
	}

	agreeTerms() {
		this.restService
			.fetchAccount('api/terms/analytics/agree/')
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((res) => {
				this.navBarStorage.eulaOpened = false;
				this.navBarStorage.$triggerNewProjectTooltip.next(true);
			});
	}

	/**
	 * grab current filter list from project list and update relevant values/quick filters
	 * @param newFilters
	 */
	currentFiltersChanged(newFilters: typeof this.currentFilters) {
		this.currentFilters = newFilters;
		this.currentFilterLength = Object.keys(this.currentFilters).length;
		for (const [key, value] of Object.entries(this.quickFilterWidgets)) {
			const matchingFilter = this.currentFilters[value.projectListModelName];
			let isActive = true;
			if (
				matchingFilter !== undefined &&
				matchingFilter.length === value.filterValue.length &&
				(value.totalCurrentFilters === this.currentFilterLength ||
					(this.currentBanner !== 'default' && value.totalCurrentFilters + 1 === this.currentFilterLength))
			) {
				value.filterValue.forEach((val) => {
					if (!matchingFilter.some((entry) => entry.value === val)) {
						isActive = false;
					}
				});
				//rmt widgets shouldn't be active when another widget on the risk banner is active
				if (value.totalCurrentFilters < this.currentFilterLength && value.projectListModelName === 'riskMetricsType') {
					isActive = false;
				}
			} else {
				isActive = false;
			}
			value.active = isActive;
		}
	}

	/**
	 * updates which score is showing in the trend chart based on user selection
	 * @param newVal
	 */
	updateChartSelection(newVal: ChartScoreSelection): void {
		this.selectedSummaryChart = newVal;
		this.portfolioSummaryTrendData = this.allSummaryTrendData[this.selectedSummaryChart];
		this.portfolioSummaryTrendTitle = this.titleMapping[this.selectedSummaryChart];
	}

	toggleProjectsGridWidth(): void {
		this.isProjectsGridMaximized = !this.isProjectsGridMaximized;
		this.mapPaneSize = this.isProjectsGridMaximized ? '0%' : '24%';
		this.navBarStorage.selectedLayout = null;
	}

	openFilterMenu(anchor: ElementRef | HTMLElement, menu: 'filters' | 'columns' | 'presets'): void {
		switch (menu) {
			case 'filters': {
				this.$filtersOpened.next(anchor);
				break;
			}
			case 'columns': {
				this.$columnSelectorOpened.next(anchor);
				this.closePresetsPopup();
				break;
			}
			case 'presets': {
				const popupPresetsContainer = document.getElementById('presetsContainer');
				if (popupPresetsContainer !== null) {
					this.presetsPopupRef.close();
					this.presetsPopupIsOpen = false;
				} else {
					this.$columnSelectorOpened.next(null);
					this.presetsPopupRef = this.popupService.open({
						anchor,
						content: this.presetsTemplateRef,
						popupClass: ['presets-popup-class'],
						margin: { vertical: 10, horizontal: 0 },
					});
					this.presetsPopupIsOpen = true;
				}
				break;
			}
		}
	}

	/**
	 * apply preset
	 * @param ev
	 * @param preset
	 */
	presetClicked(ev: Event, preset: PortfolioPreset): void {
		this.navBarStorage.selectedLayout = preset.id;
		const filters: ProjectFieldFilter[] = JSON.parse(preset.filters);
		this.currentBanner = preset.banner;
		this.mapPaneSize = preset.showMap ? '24%' : '0%';
		this.projectsListView = preset.tableView;
		this.selectedGanttView = preset.ganttView;
		const convertedTableViewName: 'Table' | 'Gantt' = preset.tableView === 'grid' ? 'Table' : 'Gantt';
		localStorage.setItem('projectsListView', preset.tableView);
		localStorage.setItem('currentBanner', preset.banner);
		this.viewButtons.forEach((btn) => {
			btn.selected = btn.text === convertedTableViewName;
		});
		if (this.projectsListView === 'gantt') {
			this.updateGanttItems();
			if (this.selectedGanttView === null) {
				this.autoFitToContainer();
			}
		} else {
			setTimeout(() => {
				this.sort = structuredClone(this.sort);
			}, 200);
		}
		this.$presetApplied.next(preset);
		//this is the part that applies the filter in this file and the project list then filters the projects
		this.$filters.filters = filters;
		const newFilters: any = {};
		this.$filters.filters.forEach((filter) => {
			const arrayOfFilters = [];
			filter.filters.forEach((f2) => {
				const newF2 = {
					text: filter.text === 'search' ? f2.field : f2.text,
					value: f2,
				};
				arrayOfFilters.push(newF2);
			});
			newFilters[filter.text] = arrayOfFilters.sort((c, d) => c?.text.localeCompare(d?.text));
		});
		localStorage.setItem('prevBaselineProjectListFilterMap', JSON.stringify(newFilters));
		this.filterProjects(this.$allProjects.value, null);
	}

	editPreset(ev: Event, preset: PortfolioPreset): void {
		this.navBarStorage.$portfolioPresetWindowOpen.next({
			preset,
			clientList: this.clientList,
			filteredClientList: this.filteredClientList,
			nestedClientList: this.nestedClientList,
			companyList: this.companyList,
			nestedCompanyList: this.nestedCompanyList,
			riskList: this.riskList,
			projectTypeList: this.projectTypeList,
			filteredProjectTypeList: this.filteredProjectTypeList,
			pocList: this.pocList,
			filteredPocList: this.filteredPocList,
			nestedPocList: this.nestedPocList,
			currentFilters: JSON.parse(preset.filters),
			completionStatus: this.completionStatus,
			pastPeriodPerformanceList: this.pastPeriodPerformanceList,
		});
		// necessary to avoid bubbling the click event to the presetClicked function -RS
		ev.cancelBubble = true;
		ev.stopPropagation();
	}

	/**
	 * trigger confirm delete dialog
	 * @param ev
	 * @param preset
	 */
	deletePreset(ev: Event, preset: PortfolioPreset): void {
		this.deletingPreset = preset;
		this.userService.presetDeleteConfirmOpen = true;
		// necessary to avoid bubbling the click event to the presetClicked function -RS
		ev.cancelBubble = true;
		ev.stopPropagation();
	}

	/**
	 * only delete preset once confirmed
	 * @param preset
	 */
	confirmDelete(preset: PortfolioPreset): void {
		this.userService.deletePortfolioPreset(preset);
	}

	closeConfirmDialog(): void {
		this.deletingPreset = null;
		this.userService.presetDeleteConfirmOpen = false;
	}

	toggleQuickSaveDialog(val: boolean): void {
		this.userService.presetQuickSaveOpen = val;
		this.quickSaveIsFavorite = false;
		this.quickSaveName = '';
	}

	confirmQuickSave(): void {
		const visibleColumns: string[] = this.allColumns.map((c) => c.field).filter((c) => !this.hiddenColumns.includes(c));
		const newPreset: PortfolioPreset = {
			banner: this.currentBanner,
			filters: JSON.stringify(this.$filters.filters),
			ganttView: this.selectedGanttView,
			quickView: null,
			tableView: this.projectsListView,
			visibleColumns,
			name: this.quickSaveName,
			isFavorite: this.quickSaveIsFavorite,
			id: crypto.randomUUID(),
			showMap: this.mapPaneSize !== '0%',
		};
		this.userService.addNewPortfolioPreset(newPreset);
		this.toggleQuickSaveDialog(false);
	}

	openAddNewPresetPopup(): void {
		const stringifiedFilters: string = JSON.stringify(this.$filters.filters);
		const visibleColumns: string[] = this.allColumns.map((c) => c.field).filter((c) => !this.hiddenColumns.includes(c));
		const newPreset: PortfolioPreset = {
			banner: this.currentBanner,
			filters: stringifiedFilters,
			ganttView: this.selectedGanttView,
			quickView: this.selectedQuickView,
			tableView: this.projectsListView,
			visibleColumns,
			name: '',
			isFavorite: false,
			id: crypto.randomUUID(),
			showMap: true,
		};
		this.navBarStorage.$portfolioPresetWindowOpen.next({
			preset: newPreset,
			clientList: this.clientList,
			filteredClientList: this.filteredClientList,
			nestedClientList: this.nestedClientList,
			companyList: this.companyList,
			nestedCompanyList: this.nestedCompanyList,
			riskList: this.riskList,
			projectTypeList: this.projectTypeList,
			filteredProjectTypeList: this.filteredProjectTypeList,
			pocList: this.pocList,
			filteredPocList: this.filteredPocList,
			nestedPocList: this.nestedPocList,
			currentFilters: this.$filters.filters,
			completionStatus: this.completionStatus,
			pastPeriodPerformanceList: this.pastPeriodPerformanceList,
		});
		// this.userService.addNewPortfolioPreset(newPreset);
	}

	closePresetsPopup(): void {
		if (this.presetsPopupRef) {
			this.presetsPopupRef.close();
		}
		this.presetsPopupIsOpen = false;
	}

	/**
	 * keeps track of current popup state for callout arrow toggling purposes
	 * @param isOpen
	 * @param popup
	 */
	updatePopupState(isOpen: boolean, popup: 'filters' | 'columns'): void {
		switch (popup) {
			case 'columns': {
				this.columnSelectorPopupIsOpen = isOpen;
				break;
			}
		}
	}

	tellProjectListToSearchProjects(term: string): void {
		if (this.projectsListView === 'gantt') {
			this.isFromSearch = true;
		}
		this.headerSearch.next(term);
	}

	updateResetBtnState(newState: boolean): void {
		this.resetDisabled = newState;
	}

	updateSearch(term: string): void {
		this.searchTerm = term;
	}

	resetFiltersButtonClicked(): void {
		this.resetClickedPortfolio.next(true);
	}

	/**
	 * toggles the quick-view for the current baseline of projects/filters
	 * @param view
	 */
	quickViewChange(view: QuickViewOption): void {
		const isView = isQuickViewOption(view);
		if (view !== this.selectedQuickView && isView) {
			localStorage.setItem(LOCAL_STORAGE.LIST_VIEW, view);
		} else {
			if (localStorage.getItem(LOCAL_STORAGE.LIST_VIEW) !== null) {
				this.prevBaselineFiltersReady = true;
			}
			localStorage.removeItem(LOCAL_STORAGE.LIST_VIEW);
		}
		this.filterProjects(this.$allProjects.value, view);
		this.$checkForResetBtnDisabled.next(true);
	}

	/**
	 * facilitates filter application. called from project list after a banner is changed
	 * @param ev
	 */
	doFilter(ev: boolean): void {
		if (ev) {
			this.filterProjects(this.$allProjects.value, null);
		}
	}

	/**
	 * resets the filter values correlated with the old selected view without touching any other filters
	 * @param quickViewToReset
	 * @param currentBanner
	 */
	softReset(quickViewToReset: QuickViewOption, currentBanner: string): ProjectFieldFilter[] {
		const changedFilters: ProjectFieldFilter[] = [];
		const isRisk = currentBanner === 'risk';
		const isCost = currentBanner === 'cost';
		const defaultFilterValues = this.defaultFilters(isRisk, isCost);
		const scheduleTypeDefault = defaultFilterValues.find((filter) => filter.text === 'scheduleType');
		const riskMetricsTypeDefault = defaultFilterValues.find((filter) => filter.text === 'riskMetricsType');
		const updateStatusDefault = defaultFilterValues.find((filter) => filter.text === 'update-status');
		const prevVarianceDefault = defaultFilterValues.find((filter) => filter.text === 'prevVariance');
		const costDefault = defaultFilterValues.find((filter) => filter.text === 'cost-loaded');
		switch (quickViewToReset) {
			case 'missingUpdate': {
				changedFilters.push(updateStatusDefault);
				changedFilters.push(scheduleTypeDefault);
				break;
			}
			case 'slipped':
			case 'onTrack':
			case 'improved': {
				changedFilters.push(prevVarianceDefault);
				changedFilters.push(scheduleTypeDefault);
				break;
			}
			case 'active': {
				changedFilters.push(scheduleTypeDefault);
				break;
			}
			case 'riskActive': {
				changedFilters.push(scheduleTypeDefault);
				changedFilters.push(riskMetricsTypeDefault);
				break;
			}
			case 'riskMissingUpdate': {
				changedFilters.push(updateStatusDefault);
				changedFilters.push(scheduleTypeDefault);
				changedFilters.push(riskMetricsTypeDefault);
				break;
			}
			case 'riskSlipped': {
				changedFilters.push(prevVarianceDefault);
				changedFilters.push(scheduleTypeDefault);
				changedFilters.push(riskMetricsTypeDefault);
				break;
			}
			case 'costActive': {
				changedFilters.push(scheduleTypeDefault);
				changedFilters.push(costDefault);
				break;
			}
			default: {
				console.log('this should never happen. something went wrong', quickViewToReset);
			}
		}
		return changedFilters.length ? changedFilters : null;
	}

	/**
	 * updates dialog open state value
	 * @param newVal
	 */
	dialogStateChanged(newVal) {
		this.dialogOpened = newVal;
	}

	openTooltipWindow(type) {
		if (type === '') {
			return;
		}
		this.tooltipOpened = true;
		this.windowService.setViewport('globalTooltip');
		this.tooltipType = type;
	}

	openRiskTooltipWindow(type) {
		this.riskTooltipOpened = true;
	}

	fillTooltipName(tooltipName) {
		this.tooltipTitle = tooltipName;
		this.windowService.tooltipTitle = tooltipName;
	}

	public closeTooltip(isOpened: boolean): void {
		this.tooltipOpened = isOpened;
		this._analyticsDashboardService.globalTooltipType.next('');
		this.navBarStorage.showingNewProject = false;
	}

	public closeNewProject(isOpened: boolean) {
		this.addProjectOpened = isOpened;
		this._analyticsDashboardService.addProjectOpen.next(false);
	}

	/**
	 * called when a filter finishes going through the project list. This updates the baseline projects if it wasn't a
	 * view change and makes sure the stats update too
	 * @param newValues
	 */
	onProjectsFiltered(newValues: boolean): void {
		const newFilter = new ProjectsFilter();
		newFilter.filters = this.defaultFilters(this.currentBanner === 'risk', this.currentBanner === 'cost');
		const storedFilters: Record<string, FilterItem[]> =
			JSON.parse(localStorage.getItem(LOCAL_STORAGE.PREV_BASELINE_LIST_FILTERS)) || {};
		if (Object.keys(storedFilters).length > 0) {
			for (const [text, value] of Object.entries(storedFilters)) {
				newFilter.setFilterDescriptor(text, value, this.rangeSliders.includes(text) ? 'and' : 'or');
			}
		}
		const baselineProjectList = filterBy(this.$allProjects.value, newFilter.filter);
		this.updateProjectStats(baselineProjectList, newValues);
		if (isQuickViewOption(localStorage.getItem(LOCAL_STORAGE.LIST_VIEW))) {
			newFilter.mergeFilters(QuickViewFilters[this.selectedQuickView]);
		}
		const blProjectListAfterViewApplied = filterBy(this.$allProjects.value, newFilter.filter);
		localStorage.setItem('rw-project-portfolio-ts', Date.now().toString());
		this.$filteredProjects.next(blProjectListAfterViewApplied);
		if (this.projectsListView === 'gantt') {
			this.updateGanttItems();
		}
	}

	/**
	 * converts dateString to date ignoring timezone
	 * @param dateString
	 */
	getDateIgnoreTimezone(dateString: string): Date {
		const date = new Date(dateString);
		const userTimezoneOffset = date.getTimezoneOffset() * 60000;
		//the Math.sign is to account for both + and - GMT timezones
		return new Date(date.getTime() + userTimezoneOffset * Math.sign(userTimezoneOffset));
	}

	/**
	 * forces window to stay within bounds of viewport
	 * @param window
	 */
	restrictMovement(window: string): void {
		this.windowService.restrictMovement(window, this.cdr);
	}

	layoutSortFunction = (a: PortfolioPreset, b: PortfolioPreset): number => {
		// if (a?.isFavorite && !b?.isFavorite) {
		// 	return -1;
		// }
		// if (b?.isFavorite && !a?.isFavorite) {
		// 	return 1;
		// }
		if (a?.name?.toLowerCase() < b?.name?.toLowerCase()) {
			return -1;
		}
		if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
			return 1;
		}
		return 0;
	};

	protected readonly searchIcon = searchIcon;
}
