<div class='tabContainer'>
	<kendo-tabstrip [tabIndex]='currentTab' (tabSelect)="onSelect($event)">
		<kendo-tabstrip-tab title="Details" [selected]="currentTab === 0" [disabled]='projectData?.isArchived'>
				<ng-template kendoTabContent>
					<div class='tabContents' [style.maxHeight.px]='appWindowService.windowPosition.adminSettings.height - 158' style='margin-top: -16px'>
						<app-new-project #newProject [isFromProjectSettings]='true' [projectInfo]='projectData' [showMilestoneButtons]='true' [isEdit]='true' [hideSubmit]='true'
														 [afterSave]='afterSave'
														 [viewerDisabledMode]='(projectService.$currentProjectReport | async)?.permissionData.role === "viewer"'
						(canSaveEmit)='setCanSave($event)'
						></app-new-project>
					</div>
				</ng-template>
		</kendo-tabstrip-tab>
		<kendo-tabstrip-tab title="Layout" [selected]="currentTab === 1" [disabled]='projectData?.isArchived'>
			<ng-template kendoTabContent>
				<div class='tabContents' [style.maxHeight.px]='appWindowService.windowPosition.adminSettings.height - 158'>
					<app-layout-settings (canSave)="updateCanSaveLayoutTab($event)"></app-layout-settings>
				</div>
			</ng-template>
		</kendo-tabstrip-tab>
		<kendo-tabstrip-tab title="Overview" [selected]="currentTab === 2" [disabled]='projectData?.isArchived'>
			<ng-template kendoTabContent>
				<div class='tabContents' [style.maxHeight.px]='appWindowService.windowPosition.adminSettings.height - 158'>
					<app-overview-tab-settings (canSave)="updateCanSaveOverviewTab($event)"></app-overview-tab-settings>
				</div>
			</ng-template>
		</kendo-tabstrip-tab>
		<kendo-tabstrip-tab title="Milestones" [selected]="currentTab === 3" [disabled]='projectData?.isArchived'>
			<ng-template kendoTabContent>
				<div class='tabContents' [style.maxHeight.px]='appWindowService.windowPosition.adminSettings.height - 158' style='margin-top: -16px'>
					<app-milestones-tab (canSave)="updateCanSaveMilestones($event)"></app-milestones-tab>
				</div>
			</ng-template>
		</kendo-tabstrip-tab>
		<kendo-tabstrip-tab title="Scores" [selected]="currentTab === 4" *ngIf='user?.userType !== "saasRisk"  && (projectService.$currentProjectReport | async)?.permissionData.role !== "viewer"' [disabled]='projectData?.isArchived'>
			<ng-template kendoTabTitle>
				<div style='position: relative;'>
					<kendo-circularprogressbar
						[indeterminate]="true"
						[value]="30"
						[progressColor]='"#ffb500"'
						id='project-settings-scores-tab-spin'
						*ngIf='projectService.loadingScoresTab'
					></kendo-circularprogressbar>
					<span [class.showing-loading-spinner]='projectService.loadingScoresTab'>Scores</span>
				</div>
			</ng-template>
			<ng-template kendoTabContent>
				<div style='overflow: hidden'>
					<app-score-playground
						#scorePlayground
						*ngIf='currentTab === 4'
						[projectReport]='projectReport'
						[projectData]='projectData'
						(canSave)='hasUnsavedScoreSettings = $event'
						[resetValues]='resetValues'
						(closeWindow)='closeAdminWindow()'
						[afterSave]='afterSave'
					></app-score-playground>
				</div>
			</ng-template>
		</kendo-tabstrip-tab>
		<kendo-tabstrip-tab title="Quality" [selected]="currentTab === 5" *ngIf='user?.userType !== "saasRisk"  && (projectService.$currentProjectReport | async)?.permissionData.role !== "viewer"' [disabled]='projectData?.isArchived'>
			<ng-template kendoTabContent>
				<div style='overflow: hidden'>
					<app-quality-settings [projectData]="projectData" (closeWindow)='closeAdminWindow(true)' (changes)="qualityChanges = $event"/>
				</div>
			</ng-template>
		</kendo-tabstrip-tab>
		<kendo-tabstrip-tab title="Risk" [selected]="user?.userType === 'saasRisk' ? currentTab === 5 : currentTab === 6" *ngIf='(projectService.$currentProjectReport | async)?.permissionData.role !== "viewer"' [disabled]='projectData?.isArchived'>
			<ng-template kendoTabContent>
				<div class='tabContents' [style.maxHeight.px]='appWindowService.windowPosition.adminSettings.height - 185'>
					<fieldset *ngIf='user?.userType !== "saasRisk"'>
						<legend>Risk Calculation Method</legend>
						<form [formGroup]='riskFormGroup'>
							<kendo-formfield showHints="initial" >
								<ul class="k-radio-list">
									<li class="k-radio-item">
										<input
											type="radio"
											#defaultRiskCalc
											value="default"
											kendoRadioButton
											formControlName="riskMetricsType"
										/>
										<kendo-label
											class="k-radio-label"
											[for]="defaultRiskCalc"
											text="Default - KPIs"
										></kendo-label>
									</li>
									<li class="k-radio-item">
										<input
											type="radio"
											#PFRiskCalc
											value="performanceFactor"
											kendoRadioButton
											formControlName="riskMetricsType"
										/>
										<kendo-label
											class="k-radio-label"
											[for]="PFRiskCalc"
											text="Monte Carlo - Performance Trending"
										></kendo-label>
									</li>
									<li class="k-radio-item">
										<input
											type="radio"
											#riskRegisterRiskCalc
											value="riskRegister"
											kendoRadioButton
											formControlName="riskMetricsType"
										/>
										<kendo-label
											class="k-radio-label"
											[for]="riskRegisterRiskCalc"
											text="Monte Carlo - Risk Register"
										></kendo-label>
									</li>
									<li class="k-radio-item">
										<input
											type="radio"
											#coreRiskRiskCalc
											value="coreRisk"
											kendoRadioButton
											formControlName="riskMetricsType"
										/>
										<kendo-label
											class="k-radio-label"
											[for]="coreRiskRiskCalc"
											text="Monte Carlo - Core Risk "
										></kendo-label>
										<span style="color: #8e8e8e; font-size: 12px;">(Simplified Risk Register)</span>
									</li>
								</ul>
								<kendo-formerror [align]="'end'">Error: This field is required</kendo-formerror>
							</kendo-formfield>
						</form>
					</fieldset>
					<fieldset>
						<legend>Global Impacts</legend>
						<div style='display: flex;'>
							<kendo-label class="k-form" style='padding-right: 10px; display: flex; flex-direction: column;' text="Minimum (-Δ%)">
								<kendo-numerictextbox
									format="'-'# '%'"
									[style.width.px]="105"
									[style.marginBottom.px]="10"
									(valueChange)="saveGlobalVariance([$event, globalMCVariance[1]])"
									[value]="globalMCVariance[0]"
									[min]="0"
									[step]='1'
									(keydown)='testNumericKeypress($event)'
									(paste)='testPaste($event)'
									[disabled]='!riskFormGroup.value["riskMetricsType"] || riskFormGroup.value["riskMetricsType"] === "default"'
								>
								</kendo-numerictextbox>
							</kendo-label>
							<kendo-label class="k-form" style='display: flex; flex-direction: column;' text="Maximum (+Δ%)">
								<kendo-numerictextbox
									format="'+'# '%'"
									[style.width.px]="105"
									[style.marginBottom.px]="10"
									(valueChange)="saveGlobalVariance([globalMCVariance[0], $event])"
									[value]="globalMCVariance[1]"
									[min]="0"
									[step]='1'
									(keydown)='testNumericKeypress($event)'
									(paste)='testPaste($event)'
									[disabled]='!riskFormGroup.value["riskMetricsType"] || riskFormGroup.value["riskMetricsType"] === "default"'
								>
								</kendo-numerictextbox>
							</kendo-label>
						</div>

					</fieldset>
					<fieldset *ngIf='projectData?.riskMetricsType === "riskRegister"'>
						<legend>Risk Fields</legend>
						<app-risk-data-fields
							[disabled]='riskFormGroup.value["riskMetricsType"] !== "riskRegister"'
							(canSave)='updateCanSaveRisk($event)'
						></app-risk-data-fields>
					</fieldset>
				</div>
			</ng-template>
		</kendo-tabstrip-tab>
		<kendo-tabstrip-tab title="Sync" [selected]="user?.userType === 'saasRisk' ? currentTab === 6 : currentTab === 7" [disabled]='projectData?.isArchived'>
			<ng-template kendoTabContent>
				<div class='tabContents' [style.maxHeight.px]='appWindowService.windowPosition.adminSettings.height - 158'>
					<app-db-sync-tab [hasExistingSync]="projectData.hasExistingSyncSettings" [selectedDatabase]="projectData.projectSelectedDatabase"
					></app-db-sync-tab>
				</div>
			</ng-template>
		</kendo-tabstrip-tab>
		<kendo-tabstrip-tab title="Admin" [selected]="user?.userType === 'saasRisk' ? currentTab === 7 : currentTab === 8" *ngIf='(projectService.$currentProjectReport | async)?.permissionData.role !== "viewer"'>
			<ng-template kendoTabContent>
				<fieldset>

					<legend>Project Files</legend>

					<form [formGroup]='sharepointForm'>

						<kendo-formfield showHints="always">
							<kendo-label text="Current Location">
								<div style='display: flex; align-items: flex-end' [class.disabled-cursor]='projectData?.isArchived'>
									<kendo-textbox  style='width: 75%' placeholder="https://www.sharepoint.com/my-project" [clearButton]="true"
																	formControlName='sharepoint'
																	[readonly]='projectData?.isArchived'
																	[class.readonly-textbox]='projectData?.isArchived'
									></kendo-textbox>
									<a target='_blank'
										 [href]='sharepointForm.value["sharepoint"]'
										 [class.k-disabled]='!sharepointForm.valid || projectData?.isArchived'
										 class='k-button k-button-md k-rounded-md k-button-solid-base k-button-solid'
										 style='margin-left: 10px; text-decoration: none; margin-right: 10px'>View <kendo-icon name="hyperlink-open-sm"></kendo-icon></a>
									<button kendoButton themeColor="success" [disabled]='!sharepointForm.valid || (sharepointForm.value["sharepoint"] === projectData.sharePoint) || projectData?.isArchived' (click)='saveSharepoint()'>Save</button>

								</div>
							</kendo-label>
							<kendo-formerror [align]="'end'">Invalid URL</kendo-formerror>
						</kendo-formfield>
					</form>


				</fieldset>
				<fieldset style='display: grid;'>
					<legend>Archive</legend>
					<div *ngIf='(projectService.$projectArchived | async) === false' kendoTypography textAlign="center" themeColor="error">Warning: Archiving a project will remove the project from the database. Administrator account is needed to unarchive the project.</div>
					<button kendoButton [themeColor]="(projectService.$projectArchived | async) ? 'info' : 'error'" style='justify-self: center; margin-top: 5px' (click)='toggleArchived()'>{{((projectService.$projectArchived | async) ? 'Un-' : '') + 'Archive'}} Project</button>

				</fieldset>
				@if((projectService.$currentProjectReport | async).permissionData?.role !== "viewer" && user?.userType !== "saasRisk"  && !projectService.$currentProjectData.value?.isArchived) {
					<fieldset>
						<legend>Recalculate</legend>
						@if (doingRecalc) {
							<kendo-circularprogressbar
								style="left: 160px; top: 147px"
								[indeterminate]="true"
								[value]="30"
								[progressColor]='"#ffb500"'
								class='indeterminate-progressbar'
							></kendo-circularprogressbar>
						}
						<div style="display: flex; flex-direction: row; justify-content: center;">
							<button kendoButton (click)="refreshCalculations()" style="margin-top: -5px;">
								Recalculate Project
							</button>
						</div>

					</fieldset>
				}
			</ng-template>
		</kendo-tabstrip-tab>

	</kendo-tabstrip>
	@switch (currentTab) {
		@case (0) {
			<button
				kendoButton
				class="k-button k-primary"
				[themeColor]='(projectService.sqlProjects | async)?.size ? "success" : "base"'
				style='float: right; margin-top: 10px;'
				(click)="saveAll()"
				[disabled]='!hasUnsavedDetailChanges'
			>
				<kendo-loader *ngIf="!(projectService.sqlProjects | async)?.size && (userService.user | async)?.userType === 'aegis'" size="small"> </kendo-loader>
				Save
			</button>
		}
		@case (1) {
			<button kendoButton themeColor='success' style='float: right; margin-top: 10px' (click)='saveAll()'
							[disabled]='!hasChanges'>Save</button>
		}
		@case (2) {
			<button kendoButton themeColor='success' style='float: right; margin-top: 10px' (click)='saveAll()'
							[disabled]='!hasChanges'>Save</button>
		}
		@case (3) {
			<button kendoButton themeColor='success' style='float: right; margin-top: 10px' (click)='saveAddMileChanges()'
							[disabled]='!hasUnsavedMilestoneSettings'>Save</button>
		}
		@case (4) {
			<div class='score-playground-action-btn-container'>
				<button kendoButton [disabled]='projectService.resetDisabledScorePlayground' (click)="resetScorePlayground()" themeColor="info" style='float: right'>
					Reset
				</button>
				<button kendoButton [disabled]='projectService.saveDisabledScorePlayground' (click)="saveAll()" themeColor="success" style='float: right'>
					Save & Recalculate
				</button>
			</div>
		}
		@case (6) {
			<button kendoButton themeColor='success' style='float: right; margin-top: 10px' (click)='saveAll()'
							[disabled]='!hasChanges'>Save</button>
		}
	}
</div>
<kendo-dialog
	title="Please confirm"
	*ngIf="unsavedChangesOpen"
	(close)="closeUnsavedWindow()"
	[minWidth]="250"
	[width]="450"
	[height]='250'
>
	<p style="margin: 30px 30px 30px 30px; text-align: center;">
		You have unsaved changes. Would you like to save them?
	</p>
	<kendo-dialog-actions style='margin: 0'>
		<button kendoButton (click)="saveAll(true)">Discard</button>
		<button kendoButton (click)="saveAll()" themeColor="success">
			Save
		</button>
	</kendo-dialog-actions>
</kendo-dialog>
