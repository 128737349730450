import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisualizeReportComponent } from './visualize-report.component';
import { VisualizerHeaderComponent } from './visualizer-header/visualizer-header.component';
import { IonicModule } from '@ionic/angular';
import { ProjectOverviewModule } from '../overview/project-overview.module';
import { AddBaselineModule } from '../top-row/add-baseline/add-baseline.module';
import { TopRowModule } from '../top-row/top-row.module';
import { MatIconModule } from '@angular/material/icon';
import { QcPageModule } from '../qc/qc-page.module';
import { ScheduleUpdatesListModule } from '../schedule-updates-list/schedule-updates-list.module';
import { GridLayoutModule } from '@progress/kendo-angular-layout';

@NgModule({
	declarations: [VisualizeReportComponent, VisualizerHeaderComponent],
	imports: [
		CommonModule,
		IonicModule,
		ProjectOverviewModule,
		AddBaselineModule,
		TopRowModule,
		MatIconModule,
		QcPageModule,
		ScheduleUpdatesListModule,
		GridLayoutModule,
	],
	exports: [VisualizerHeaderComponent],
})
export class ReportVisualizerModule {}
