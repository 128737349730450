<div id="layout-tab-settings">
	<div class="content-container">
		<div class="desc" style="text-align: center;">
			The Layout Tab has options to customize the components/tabs shown on your project.
		</div>
		<div class="selection-content">
			<div style="height: 145px;">
				@for (tab of tabs; track tab; let i = $index) {
					<div class="tab-item" [class.right-item]="i % 2 === 1" [class.selected-item]="tab.id === filteredTab" [style.margin-bottom]="i >= tabs?.length - 1 ? '7px' : '2px'" [style.cursor]="(['overview', 'reports', 'compare', 'focus'].includes(tab.id) || tab.required) ? 'not-allowed' : 'pointer'" (click)='editItem(tab)'>
						<span style="width: calc(100% - 66px); display: flex; align-items: center;" [style.color]="tab?.disabled ? '#a9a9a9' : 'black'">
							{{tab.name}}
							@if (tab.disabled) {
								<span style="font-size: 9px; position: relative; align-self: end; margin-bottom: 2px; margin-left: 2px;">(Hidden)</span>
							}
							@if (!tab.disabled && allComponentsOnTabHidden(tab.id)) {
								<span kendoTooltip style="padding-left: 2px;">
									<kendo-svg-icon [icon]="icons.warning" title="Tab is hidden due to no visible components." style="margin-top: -2px; color: #001489;"></kendo-svg-icon>
								</span>
							}
						</span>
						<span class="btn-row">
							<span style='display: flex; gap: 2px;' kendoTooltip>
								@if (tab.required) {
									<button kendoButton themeColor="base" title="Cannot edit required tab" [svgIcon]="icons.pencil" class="itemBtn disabled-item"></button>
									<button kendoButton themeColor="base" title="Cannot hide required tab" [svgIcon]="tab?.disabled ? icons.eye : icons.hideEye" class="itemBtn disabled-item"></button>
								} @else {
									@if (['overview', 'reports', 'compare', 'focus'].includes(tab.id)) {
										<button kendoButton themeColor="base" [title]="tab.id==='overview' ? 'Overview Tab contents editable in Project Settings -> Overview' : tab.id === 'reports' ? 'Cannot edit Reports Tab content' : tab.id === 'compare' ? 'Cannot edit Compare Tab content' : 'Focus Tab coming soon'" [svgIcon]="icons.pencil" class="itemBtn disabled-item"></button>
									} @else {
										<button kendoButton themeColor="base" title="Edit" [svgIcon]="icons.pencil" class="itemBtn"></button>
									}
									<button kendoButton themeColor="base" [title]="tab?.disabled ? 'Show Tab' : 'Hide Tab'" [svgIcon]="tab?.disabled ? icons.hideEye : icons.eye" (click)='toggleTabVis($event, tab)' class="itemBtn"></button>
								}
							</span>
						</span>
					</div>
				}
				<!--<kendo-chiplist selection="multiple">
					<kendo-chip
						*ngFor="let chip of chips"
						[label]="chip.name"
						[removable]="!chip.required && chip.selected"
						(remove)="onRemove($event)"
						(contentClick)="selectionChange($event)"
						[selected]="chip.selected"
						class="tab-chip"
						[class.selected-tab-chip]="chip.selected"
					>
					</kendo-chip>
				</kendo-chiplist>-->
			</div>
			@if (filteredTab !== '') {
				<div class="desc" style="font-size: 12px;">
					<!--				Click the arrow buttons to add components to the selection. Drag to reorder or click the X button to deselect them.-->
					Click the arrow buttons to make components visible and the X button to hide them. Select a tab above to filter the list.
				</div>
				<div class="adjustment-row">
					<kendo-gridlayout
						[rows]="['100%']"
						[cols]="['calc(50% - 5px)', 'calc(50% - 5px)']"
						[gap]="{ rows: 0, cols: 10 }"
						class='grid'
						style="height: 100%; width: 100%;"
						id="layoutTabOptionContainer"
						#layoutTabOptionContainer
					>
						<kendo-gridlayout-item [row]='1' [col]='1' class="gridlayout-container" style="justify-content: flex-start;">
							<div class="panel-title">
								<kendo-textbox
									[placeholder]="'Search...'"
									[clearButton]="true"
									[size]='"small"'
									[(value)]='searchTerm'
									(afterValueChangedStr)='search($event)'
									class="search"
								>
									<ng-template kendoTextBoxPrefixTemplate>
										<kendo-svgicon [icon]='icons.search'></kendo-svgicon>
										<kendo-textbox-separator></kendo-textbox-separator>
									</ng-template>
								</kendo-textbox>
								Hidden
								<button kendoButton themeColor="base" title="Move All" [svgIcon]="icons.arrowRight" [disabled]="!availableComponentOptions?.length" (click)='moveAll()' class="allBtn">All</button>
							</div>
							<div class="options-list">
								@for (component of availableComponentOptions; track component) {
									@if(!component.disabled) {
										<div class="option">
											<div class="label" style="width: calc(100% - 22px)">
												{{component.name}}
											</div>
											<span class="btn-row">
											<button kendoButton themeColor="base" title="Move To Selected List" [svgIcon]="icons.arrowRight" (click)='moveItem(component)' class="itemBtn"></button>
										</span>
										</div>
									} @else {
										<span style='display: inline-flex; align-items: center;float: right' kendoTooltip>
										<div class="option disabled-type" title="Option unavailable for this project">
											<div class="label" style="width: calc(100% - 22px)">
												{{component.name}}
											</div>
											<span class="btn-row">
												<button kendoButton themeColor="base" title="" [svgIcon]="icons.arrowRight" [disabled]="true" class="itemBtn"></button>
											</span>
										</div>
									</span>
									}
								}
							</div>
						</kendo-gridlayout-item>
						<kendo-gridlayout-item [row]='1' [col]='2' class="gridlayout-container" style="justify-content: flex-start;">
							<div class="panel-title">
								Visible
								<button kendoButton themeColor="base" title="Remove All" [svgIcon]="icons.close" [disabled]="!selectedComponentOptions?.length" (click)='removeAll()' class="allBtn">All</button>
							</div>
							<!--						<div class="options-list" dragula="SELECTEDLAYOUTCOMPONENTS" [(dragulaModel)]="selectedComponentOptions">-->
							<div class="options-list">
								@for (item of selectedComponentOptions; track item) {
									<div class="rowItem">
										<div class="option" style="width: calc(100% - 10px);">
										<span class="moveContainer">
											<kendo-svgicon [icon]='icons.move' title="Move" class="dragHandle"></kendo-svgicon>
										</span>
											<span
												class="option-name"
												style="width: calc(100% - 66px); align-items: center; overflow: hidden; position: relative; display: inline-block; text-overflow: ellipsis;"
											>
											{{item.name}}
										</span>
											<span class="btn-row">
											<button kendoButton themeColor="base" title="Deselect" [svgIcon]="icons.close" (click)='deselectItem(item)' class="itemBtn"></button>
										</span>
										</div>
									</div>
								}
							</div>
						</kendo-gridlayout-item>
					</kendo-gridlayout>
				</div>
			}
		</div>
	</div>
</div>
