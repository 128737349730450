<kendo-expansionpanel
	subtitle="Notes"
	[disabled]="currentUpdateNotes === ''"
	class="overview-notes-expansion-panel"
	[expanded]="isScreenshotMode"
	[class.overview-br-bottom]="squareBrTop"
>
	<kendo-label [for]="notesTextArea" [text]="notes?.length === 0 ? 'Baseline' :'Update ' + (notes?.length - 1) + ' Notes'"></kendo-label>
	<kendo-textarea
		#notesTextArea
		maxlength='3000'
		[(ngModel)]='currentUpdateNotes'
		resizable="none"
		style='max-height: 160px;'
		[readonly]='true'
		[class.readonly-textbox]='true'
		class="overview-textarea"
	>
	</kendo-textarea>
	<div style="margin-top: 10px; display: flex; justify-content: space-between; position: relative;">
		@if (notes?.length && notes[notes?.length - 1]?.lastEditTimestamp !== null && notes[notes?.length]?.lastEditUser !== '') {
			<span style="margin-top: -10px; font-size: 12px; color: #8e8e8e;">
				Last Updated: {{notes[notes?.length - 1]?.lastEditTimestamp | date: 'MMM d, yyyy'}} by {{notes[notes?.length - 1]?.lastEditUser}}
			</span>
		}
	</div>
</kendo-expansionpanel>
