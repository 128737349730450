<kendo-chart class="chart stacked-chart" *ngIf="chartType === 'bar'" (seriesClick)="clickedBar($event)">
  <kendo-chart-legend [visible]="false">
  </kendo-chart-legend>
  <kendo-chart-category-axis>
    <kendo-chart-category-axis-item [visible]='false'>
    </kendo-chart-category-axis-item>
  </kendo-chart-category-axis>
	<kendo-chart-value-axis>
		<kendo-chart-value-axis-item [visible]="false">
		</kendo-chart-value-axis-item>
	</kendo-chart-value-axis>
  <kendo-chart-series>
    <kendo-chart-series-item
      *ngFor="let item of data"
      [type]="chartType"
      [stack]="{ type: '100%' }"
      [data]="[item.value]"
      [name]="item.category"
			[labels]="{ visible: this.isFromProjectPage, padding: 3, font: '10px Muli, Helvetica Neue, Arial, sans-serif', format: '{0:p}', background: 'none', position: 'center'}"
		>
      <kendo-chart-series-item-tooltip>
        <ng-template>
          {{item.category}} - {{item.value | percent}}
        </ng-template>
      </kendo-chart-series-item-tooltip>
    </kendo-chart-series-item>
  </kendo-chart-series>
</kendo-chart>
<kendo-chart class="chart" *ngIf="chartType === 'donut'" [class.donut-chart]="chartType === 'donut'">
  <kendo-chart-legend position="bottom" [visible]="false"></kendo-chart-legend>
  <kendo-chart-tooltip>
    <ng-template kendoChartSeriesTooltipTemplate let-value="value" let-name="name" let-dataItem="dataItem">
      {{dataItem.category}} - {{value | percent}}
    </ng-template>
  </kendo-chart-tooltip>
  <kendo-chart-series>
    <kendo-chart-series-item
      [type]="chartType"
      [data]="data"
      categoryField="category"
      field="value"
			[labels]="{ visible: this.isFromProjectPage, padding: 3, font: '10px Muli, Helvetica Neue, Arial, sans-serif', format: '{0:p}', background: 'none'}"
		>
    </kendo-chart-series-item>
  </kendo-chart-series>
</kendo-chart>
