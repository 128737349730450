import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class StatusCheckService {
	maintenanceMessage: string = '';
	isMaintenance: boolean = false;
	maintenanceAlreadyRead: boolean = true;
	currentStatus: any;
	isThisBleeding: boolean = environment.bleeding;
	timeSinceStart: number = 0;
	tSSFunc: any;

	constructor(private http: HttpClient) {
		this.tSSFunc = setInterval(() => {
			this.timeSinceStart += 1;
		}, 1000);
		this.currentStatus = JSON.parse(localStorage.getItem('analytics_status')) || { last_update: 'new' };
		this.http.get('https://controller.analytics.rhinoworks.dev/app/status/').subscribe(
			(res: any) => {
				if (this.currentStatus.last_update !== res.last_update) {
					this.currentStatus = res;
					localStorage.setItem('analytics_status', JSON.stringify(this.currentStatus));
					localStorage.setItem('analytics_status_shown', 'false');
				}

				this.checkMaintenanceDisplay();
			},
			(err) => {
				console.log('There was an issue reaching the controller to check status. ', err);
			}
		);
	}

	checkMaintenanceDisplay() {
		if (
			this.currentStatus.frontend === 'up' &&
			this.currentStatus.backend === 'up' &&
			this.currentStatus.workers === 'up' &&
			!this.currentStatus.maintenance
		) {
			this.maintenanceAlreadyRead = true;
			return true;
		}
		this.maintenanceAlreadyRead = localStorage.getItem('analytics_status_shown') === 'true' || false;

		if (!this.maintenanceAlreadyRead) {
			if (this.currentStatus.maintenance) {
				this.maintenanceMessage =
					"We are currently performing maintenance on our systems. Some services may be intermittent. <br>For more information, please visit our status page at: <a href='https://status.rhino.works' target='_blank'>https://status.rhino.works</a>";
			} else {
				this.maintenanceMessage =
					"We are currently experiencing an issue with our Analytics Platform. As a result, some services may be unavailable as we work to resolve these issues. <br>For more information, please visit our status page at: <a href='https://status.rhino.works' target='_blank'>https://status.rhino.works</a>";
			}
		}
	}

	setDismiss() {
		localStorage.setItem('analytics_status_shown', 'true');
		this.maintenanceAlreadyRead = true;
	}
}
