<ng-container [formGroup]="redirectDetails">
	<div class='same-row-grouping'>
		<kendo-formfield>
			<kendo-label [for]="projectCode" text="Project Code"></kendo-label>
			<kendo-autocomplete
				#projectCode
				[data]="projectKeys"
				formControlName="projectCode"
				[clearButton]='true'
			>
			</kendo-autocomplete>
			<kendo-formerror [align]="'end'">Project Code is required</kendo-formerror>
		</kendo-formfield>
		<kendo-formfield>
			<kendo-label [for]="projectName" text="Project Name"> </kendo-label>
			<kendo-textbox
				formControlName="name"
				#projectName
				required
				[clearButton]="true"
			>
			</kendo-textbox>
			<kendo-formerror [align]="'end'">Project Name is required</kendo-formerror>
		</kendo-formfield>
	</div>

	<div class='same-row-grouping'>
		<kendo-formfield style='width: calc(50% - 4px)'>
			<kendo-label [for]="projectType" text="Market"> </kendo-label>
			<kendo-combobox
				#projectType
				[data]="availableProjectTypes"
				[suggest]="true"
				[kendoDropDownFilter]="{ operator: 'contains' }"
				(focus)="focus('projectTypeDropdown')"
				formControlName="projectType"
				required
			></kendo-combobox>
			<kendo-formerror [align]="'end'">Market is required</kendo-formerror>
		</kendo-formfield>
	</div>
	<kendo-formfield>
		<kendo-label [for]="siteLocation" text="Site Location"> </kendo-label>
		<kendo-textbox
			formControlName="siteAddressReference"
			#siteLocation
			required
			[clearButton]="true"
		>
		</kendo-textbox>
		<kendo-formerror [align]="'end'">Site Location is required</kendo-formerror>
	</kendo-formfield>
	<div style='text-align: right; margin-top: 10px;'>
		<kendo-button
		themeColor='success'
		(click)='patchClientData()'
		[disabled]='doingPatchClientData'
		>
			<kendo-circularprogressbar
				[indeterminate]="true"
				[value]="30"
				[progressColor]='"#ffb500"'
				class='indeterminate-progressbar'
				*ngIf='doingPatchClientData'
			></kendo-circularprogressbar>

			Save</kendo-button>
	</div>

</ng-container>
