import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RiskRegister } from '../../../../../models/risk';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query/dist/npm/sort-descriptor';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

interface TopRisks extends RiskRegister {
	preMitigationRiskScore: number;
}

@Component({
	selector: 'app-saas-risk-top-active-risks',
	templateUrl: './saas-risk-top-active-risks-grid.component.html',
	styleUrls: ['./saas-risk-top-active-risks-grid.component.scss'],
})
export class SaasRiskTopActiveRisksGridComponent implements OnInit {
	@Input() riskMitigations = new BehaviorSubject<RiskRegister[]>([]);
	public gridView: GridDataResult;
	gridData: Array<any> = [];
	public sort: SortDescriptor[] = [
		{
			field: 'preMitigation.activityImpact',
			dir: 'desc',
		},
	];

	constructor(private sanitizer: DomSanitizer) {}

	ngOnInit(): void {
		this.riskMitigations.subscribe((riskMitigations: TopRisks[]) => {
			riskMitigations.forEach((risk: TopRisks) => {
				const preImpact = Math.max(
					risk.preMitigation.qualityImpact,
					risk.preMitigation.performanceImpact,
					risk.preMitigation.costImpact,
					risk.preMitigation.scheduleImpact
				);
				const likelihood = risk.preMitigation.probability;
				const preMitigationRiskScore = likelihood * preImpact;
				risk.preMitigationRiskScore = preMitigationRiskScore;
			});
			this.loadProjects(riskMitigations);
		});
	}

	/**
	 * updates top active risks grid with the latest mitigation data
	 * @param riskMitigationsInput
	 */
	public loadProjects(riskMitigationsInput: TopRisks[]): void {
		let riskMitigations = riskMitigationsInput.filter((risk) => !risk.isDraft);
		if (riskMitigations.length) {
			//sort and remove risk mitigations that don't fit (4 max)
			riskMitigations = riskMitigations.sort(this.compareRiskMitigations).slice(0, 4);
		}
		this.gridView = {
			data: riskMitigations,
			total: riskMitigations?.length,
		};
		this.gridData = riskMitigations;
	}

	/**
	 * compare function to order risk mitigations by activity impacts desc
	 * @param a
	 * @param b
	 */
	compareRiskMitigations(a, b) {
		if (a.preMitigation.activityImpact > b.preMitigation.activityImpact) {
			return -1;
		}
		if (a.preMitigation.activityImpact < b.preMitigation.activityImpact) {
			return 1;
		}
		return 0;
	}

	/**
	 * get color for risk score cell background
	 * @param score
	 */
	public colorCodeRiskScore(score: number): SafeStyle {
		let result;

		if (score <= 5) {
			result = '#a6e398';
		} else if (score < 11) {
			result = '#f5f2ae';
		} else if (score < 18) {
			result = '#ffbe96';
		} else {
			result = '#f77d79';
		}

		return this.sanitizer.bypassSecurityTrustStyle(result);
	}
}
