import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { RegisterComponent } from 'components/authentication/register/register.component';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
	declarations: [RegisterComponent],
	imports: [
		CommonModule,
		MatIconModule,
		RouterModule,
		MatCheckboxModule,
		MatButtonModule,
		MatInputModule,
		ReactiveFormsModule,
	],
})
export class RegisterModule {}
