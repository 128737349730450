<kendo-window
	style="z-index: 1000000000000000"
	class="risk-settings-window"
	*ngIf='isOpened'
	(close)="closeCustomFieldsDialog()"
	[resizable]='false'
	[width]='1000'
	[top]="200"
>
	<kendo-window-titlebar>
		<div class="k-window-title">Risk Settings</div>
		<button kendoWindowMinimizeAction></button>
		<button kendoWindowMaximizeAction></button>
		<button kendoWindowRestoreAction></button>
		<button kendoWindowCloseAction></button>
	</kendo-window-titlebar>

	<div class='form-container'>
		<div class='form-body'>
			<div class="wrapper">
				<kendo-tabstrip (tabSelect)='onTabSelect($event)'>
					<kendo-tabstrip-tab title="Global Settings" [selected]="tabIndex === 0" >
						<ng-template kendoTabContent>
							<div>
								<div style='display: flex;'>
									<div style='margin-right: 10px'>
										<h4><b>Global Impact:</b></h4>
									</div>
									<kendo-label class="k-form" style='padding-right: 10px; display: flex; flex-direction: column;' text="Minimum (-Δ%)">
										<kendo-numerictextbox
											format="'-'# '%'"
											[style.width.px]="105"
											[style.marginBottom.px]="10"
											(valueChange)="saveGlobalVariance([$event, globalMCVariance[1]])"
											[value]="globalMCVariance[0]"
											[min]="0"
											[step]='1'
											(keydown)='testNumericKeypress($event)'
											(paste)='testPaste($event)'
										>
										</kendo-numerictextbox>
									</kendo-label>
									<kendo-label class="k-form" style='display: flex; flex-direction: column;' text="Maximum (+Δ%)">
										<kendo-numerictextbox
											format="'+'# '%'"
											[style.width.px]="105"
											[style.marginBottom.px]="10"
											(valueChange)="saveGlobalVariance([globalMCVariance[0], $event])"
											[value]="globalMCVariance[1]"
											[min]="0"
											[step]='1'
											(keydown)='testNumericKeypress($event)'
											(paste)='testPaste($event)'
										>
										</kendo-numerictextbox>
									</kendo-label>
								</div>
								<div style='display: flex;'>

								</div>

							</div>
						</ng-template>
					</kendo-tabstrip-tab>

					<kendo-tabstrip-tab title="Data Fields" [selected]="tabIndex === 1" *ngIf='(_projectData | async)?.riskMetricsType === "riskRegister"'>

						<ng-template kendoTabContent>
							<app-risk-data-fields></app-risk-data-fields>
						</ng-template>
					</kendo-tabstrip-tab>
				</kendo-tabstrip>
			</div>
		</div>
	</div>
</kendo-window>
<kendo-window
	*ngIf="editService.confirmDialogOpen"
	(close)="closeConfirmDialog()"
	[resizable]='false'
	[minWidth]="250"
	[width]="450"
	class='unsave-warning-container'
>
	<kendo-window-titlebar style='background-color: #001489'>
		<div class="k-window-title" style="text-align: left;font-size: 16px; color: white">Cannot Remove</div>
		<button style="color: white" kendoWindowMinimizeAction></button>
		<button style="color: white" kendoWindowMaximizeAction></button>
		<button style="color: white" kendoWindowRestoreAction></button>
		<button style="color: white" kendoWindowCloseAction></button>
	</kendo-window-titlebar>
	<p style="margin: 30px; text-align: center;">
		This is a required field for these risk registers.<br>Reassign them to delete this field.
	</p>
	<p style='text-align: center;'>Risk Ids:</p>
	<ul style="display: flex; flex-direction: column; width: min-content; margin: auto;">
		<li *ngFor='let id of editService.conflictingRiskIds'>{{id}}</li>
	</ul>
	<kendo-dialog-actions>
		<button kendoButton (click)="closeConfirmDialog()" fillMode="solid" themeColor="error">Ok</button>
	</kendo-dialog-actions>
</kendo-window>
