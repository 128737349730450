import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerformanceFactorComponent } from './risk-performance-factor/performance-factor.component';
import { TooltipMenuModule } from '../../../portfolio/tooltip-menu/tooltip-menu.module';
import { HighchartsChartModule } from 'highcharts-angular';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { SharedMatModule } from '../../../shared/shared-mat.module';
import { SharedIonModule } from '../../../shared/shared-ion.module';
import { PfTableDropdownComponent } from './risk-performance-factor/pf-table-dropdown/pf-table-dropdown.component';
import { McDatagridComponent } from './risk-performance-factor/mc-datagrid/mc-datagrid.component';
import { TypeofPipe } from './risk-performance-factor/mc-datagrid/typeof.pipe';
import { PipeModule } from '../../../../util/pipes/pipe.module';
import { McRegisterSelectionComponent } from './risk-performance-factor/mc-register-selection/mc-register-selection.component';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { MonteCarloComponent } from './monte-carlo/monte-carlo.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { GridModule } from '@progress/kendo-angular-grid';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { GaugeModule } from '../../../shared/gauge/gauge.module';
import { RiskSettingsModule } from '../risk-settings/risk-settings.module';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LabelModule } from '@progress/kendo-angular-label';
import { DropDownListModule, DropDownTreesModule } from '@progress/kendo-angular-dropdowns';
import { OverviewNotesModule } from '../../../shared/overview-notes/overview-notes.module';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

@NgModule({
	declarations: [
		PerformanceFactorComponent,
		PfTableDropdownComponent,
		McDatagridComponent,
		TypeofPipe,
		McRegisterSelectionComponent,
		MonteCarloComponent,
	],
	imports: [
		CommonModule,
		SharedIonModule,
		TooltipMenuModule,
		HighchartsChartModule,
		MatExpansionModule,
		FormsModule,
		MatTableModule,
		MatButtonModule,
		SharedMatModule,
		PipeModule,
		ProgressBarModule,
		IndicatorsModule,
		LayoutModule,
		GridModule,
		ChartsModule,
		GaugeModule,
		RiskSettingsModule,
		ButtonModule,
		TreeListModule,
		InputsModule,
		MatCheckboxModule,
		LabelModule,
		DropDownListModule,
		OverviewNotesModule,
		TooltipModule,
		DropDownTreesModule,
	],
	exports: [PerformanceFactorComponent, MonteCarloComponent, PfTableDropdownComponent],
})
export class RiskPerformanceFactorModule {}
