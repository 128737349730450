import { CurrentProjectReport, ProjectDashboardService } from 'services/project/project.service';
import { BehaviorSubject, Subject } from 'rxjs';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { RestService } from 'services/common/rest.service';
import { UserService } from 'services/common/user.service';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '../../services/common/notification.service';
import { ScheduleStorageService } from '../../services/project/schedule-storage.service';
import { CurrentUpdateGanttPreset, ProjectInterface } from '../../models/Project';
import { NavigationBarStorageService } from '../../services/common/navigation-bar-storage.service';
import { format, isBefore } from 'date-fns';
import { hasObjChanged } from '../../util/projects';
import { AnalyticsDashboardService } from '../../services/analytics/analytics.service';
import { RiskRegister } from '../../models/risk';
import { UpdateInterface } from '../../models/Update/Task';
import { EventService } from '../../services/infra/event.service';
import { AccountUser, ProfileCompanyPermission } from '../../models/auth/account-user';
import { io, Socket } from 'socket.io-client';
import { environment } from '../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GanttService } from '../../services/project/gantt.service';
import { ProjectReportInterface } from '@rhinoworks/analytics-calculations';
import { NotesPreferences } from '../../models/company';

@Component({
	selector: 'project-dashboard',
	templateUrl: './project.component.html',
	styleUrls: ['./project.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ProjectDashboardComponent implements OnInit, OnDestroy {
	@ViewChild('windowWrapper') public winWrapper: ElementRef;
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	readonly $projectData = new BehaviorSubject<ProjectInterface>(undefined);
	readonly $allUpdates = new BehaviorSubject<UpdateInterface[]>([]);
	readonly $linkToCompanyLogo = new BehaviorSubject<string>('/assets/images/logos/AEGIS-ANALYTICS-COLOR.png');
	readonly $sharepointURL = new BehaviorSubject<string>('');
	user: any = {
		userType: 'aegis',
	};
	riskMetricsOptions = [
		{
			value: 'default',
			viewValue: 'Default',
			defaultChecked: false,
		},
		{
			value: 'performanceFactor',
			viewValue: 'Monte Carlo - Performance Trending',
			defaultChecked: false,
		},
		{
			value: 'riskRegister',
			viewValue: 'Monte Carlo - Risk Register',
			defaultChecked: false,
		},
	];
	dialogOpened = false;
	blur: boolean = false;
	riskSummaryTrendCategories = [];
	riskSummaryTrendData = [];
	$riskRegisterProjects = new BehaviorSubject<Array<ProjectInterface>>([]);
	userType: string;
	projectInfo: ProjectInterface;
	public wrapper: DOMRect;
	public top = 5;
	public left = 600;
	public height = 820;
	public width = 650;
	private updatesEventSource: EventSource;
	private projectEventSource: EventSource;
	private reportEventSource: EventSource;
	currentProjectCompanyPermissions: ProfileCompanyPermission = null;
	public socket: Socket;
	notesRequirement: BehaviorSubject<NotesPreferences> = new BehaviorSubject<NotesPreferences>({
		critPathRequired: false,
		tiaRequired: false,
	});

	constructor(
		public _projectDashboardService: ProjectDashboardService,
		private restService: RestService,
		public userService: UserService,
		private route: ActivatedRoute,
		public notificationService: NotificationService,
		public _scheduleService: ScheduleStorageService,
		public navBarStorage: NavigationBarStorageService,
		public analyticsService: AnalyticsDashboardService,
		public cdr: ChangeDetectorRef,
		private eventService: EventService,
		private snackBar: MatSnackBar,
		public ganttService: GanttService
	) {}

	ngOnInit(): void {
		this.socket = io(environment.baseURL, {
			transports: ['websocket'],
		});
		const user = JSON.parse(localStorage.getItem('currentUserCredentials')) as AccountUser;
		if (this.socket.connected) {
			this.socket.emit('watchProject', {
				projectId: this._projectDashboardService.$currentProjectPageId.value,
				authorization: user.access_token,
			});
		} else {
			this.socket.on('connect', () => {
				this.socket.emit('watchProject', {
					projectId: this._projectDashboardService.$currentProjectPageId.value,
					authorization: user.access_token,
				});
			});
		}
		let projectReport: ProjectReportInterface;
		let latestProject: ProjectInterface = this._projectDashboardService.$currentProjectData.value;
		console.log('initing, setting to', this._projectDashboardService.$currentProjectData.value);
		const updatesById = new Map<string, UpdateInterface>([]);
		const sortUpdates = (projectData: ProjectInterface) => {
			if (!projectData) {
				return;
			}
			const updates = Array.from(updatesById.values())
				.filter((update) => projectData.updateIds.includes(update._id))
				.sort((a, b) => projectData.updateIds.indexOf(a._id) - projectData.updateIds.indexOf(b._id));
			this._scheduleService.numUpdatedLoaded.next(updates.length);
			this._scheduleService.isLoading = updates.length !== projectData.updateIds.length;
			if (
				updates.length === projectData.updateIds.length &&
				this._scheduleService.$allUpdates.value.length !== updates.length
			) {
				this._scheduleService.$allUpdates.next(updates);
			}
		};

		this.socket.on('projectUpdated', (project: ProjectInterface) => {
			latestProject =
				latestProject ||
				(this._projectDashboardService.$currentProjectData.value?._id === project._id
					? this._projectDashboardService.$currentProjectData.value
					: latestProject);
			console.log(
				'got upd',
				project,
				project._id,
				this._projectDashboardService.$currentProjectPageId.value,
				project._id === this._projectDashboardService.$currentProjectPageId.value,
				this._projectDashboardService.$currentProjectData.value
			);
			if (project._id === this._projectDashboardService.$currentProjectPageId.value) {
				console.log(
					'got updated project data',
					project,
					project?.updateLock,
					latestProject?.updateLock,
					project?.updatedAt,
					latestProject?.updatedAt
				);
				this.$allUpdates.value.forEach((update) => {
					updatesById.set(update._id, update);
				});
				sortUpdates(project);
				project?.riskMitigation?.forEach((risk: RiskRegister) => {
					const preImpact = Math.max(
						risk.preMitigation.qualityImpact,
						risk.preMitigation.performanceImpact,
						risk.preMitigation.costImpact,
						risk.preMitigation.scheduleImpact
					);
					const postImpact = Math.max(
						risk.postMitigation.qualityImpact,
						risk.postMitigation.performanceImpact,
						risk.postMitigation.costImpact,
						risk.postMitigation.scheduleImpact
					);
					const likelihood = risk.preMitigation.probability;
					const preMitigationRiskScore = likelihood * preImpact;
					const postMitigationRiskScore = likelihood * postImpact;
					risk.riskAssessmentScore = preMitigationRiskScore * 4;
					risk.preMitigationRiskScore = preMitigationRiskScore;
					risk.postMitigationRiskScore = postMitigationRiskScore;
					risk.value = 1;
				});
				const wasLocked = !!latestProject?.updateLock || (latestProject && latestProject.updateLock !== false);
				console.log(
					latestProject,
					project,
					wasLocked,
					latestProject?.currentProjectReport,
					project?.currentProjectReport,
					latestProject?.updatedAt,
					project?.updatedAt
				);
				if (
					latestProject &&
					((wasLocked && !project?.updateLock) || latestProject?.currentProjectReport !== project?.currentProjectReport)
				) {
					const projectStatCompleted = this._projectDashboardService.$calculationStatus.value;
					projectStatCompleted.jobProgress = {
						data: {
							stage: 'Complete!',
							percent: 100,
						},
					};
					//console.log('REFRESH HERE');
					window.location.reload();
					return;
				}
				if (latestProject?.updatedAt && project?.updatedAt) {
					if (new Date(latestProject.updatedAt).getTime() > new Date(project.updatedAt).getTime()) {
						latestProject = project;
					}
				} else {
					latestProject = project;
				}
				this._projectDashboardService.$currentProjectData.next(project);
				sortUpdates(project);
				const report: CurrentProjectReport = {
					...projectReport,
					project: undefined,
				};
				if (projectReport) {
					report.project = project;
					this._projectDashboardService.$currentProjectReport.next(report);
				}
			}
		});
		console.log('watching report');
		this.socket.on('reportUpdated', (report: ProjectReportInterface) => {
			console.log('got updated report data', report);
			if (this._projectDashboardService.$currentProjectPageId.value === report?.project) {
				projectReport = report;
				if (latestProject) {
					const combinedReportProject = {
						...report,
						project: latestProject,
					};
					this._projectDashboardService.$currentProjectReport.next(combinedReportProject);
				}
			}
		});
		//set a new timestamp for caching.
		const timestamp = new Date().getTime().toString();
		localStorage.setItem('raz-timestamp', timestamp);
		this.analyticsService.editProfileOpen.next(false);
		this._projectDashboardService.$currentProjectData.pipe(takeUntil(this._unsubscribeAll)).subscribe((projectData) => {
			if (projectData) {
				this._projectDashboardService.currentProjectImg = projectData?.imageBase64;
			}
			this.blur = false;
			const currentProjectCompanyPermissions =
				projectData === undefined ? null : this.navBarStorage.companyPermissionMap.get(projectData?.company);
			this.blur =
				(this.currentProjectCompanyPermissions === null || this.currentProjectCompanyPermissions === undefined) &&
				(currentProjectCompanyPermissions === undefined || currentProjectCompanyPermissions === null);

			if (projectData && projectData?.hiddenTabs === undefined) {
				projectData.hiddenTabs = [];
			}
			this.$projectData.next(projectData);
		});

		this.analyticsService.$user.pipe(takeUntil(this._unsubscribeAll)).subscribe((result) => {
			if (result != null) {
				this.userType = result;
			}
		});
		this.navBarStorage.riskMetricsOptions = this.riskMetricsOptions;
		this.$projectData.pipe(takeUntil(this._unsubscribeAll)).subscribe((project) => {
			if (project?._id) {
				this._projectDashboardService.fullyLoaded = true;
			}
			this.$sharepointURL.next(project?.sharePoint);
			console.log({ project });
			if (project?.warnings?.length > 0) {
				this.showProjectWarning(project.warnings[0]);
			}
			this.$riskRegisterProjects.next(project ? [project] : []);
			const monthlyRisks = this.updateRiskTrendingChartData(project ? [project] : []);
			const newRiskTrendingCategories = [];
			const newRiskTrendingData = [];
			for (const month of monthlyRisks.entries()) {
				let sumOfRisks = 0;
				month[1].forEach((riskScore) => (sumOfRisks += riskScore));
				const averageRisk = sumOfRisks / (month[1].size || 1);
				newRiskTrendingCategories.push(format(month[0], 'LLL'));
				newRiskTrendingData.push(Math.round(averageRisk));
			}
			const updateTrend = hasObjChanged(this.riskSummaryTrendData, newRiskTrendingData);
			if (updateTrend) {
				this.riskSummaryTrendCategories = newRiskTrendingCategories;
				this.riskSummaryTrendData = newRiskTrendingData;
			}
		});

		this._scheduleService.$allUpdates.pipe(takeUntil(this._unsubscribeAll)).subscribe((newUpdates) => {
			this.$allUpdates.next(newUpdates);
		});

		//

		this.userService.user.pipe(takeUntil(this._unsubscribeAll)).subscribe((data) => {
			if (data) {
				this.user = data;
			}
		});

		if (this.user.userType === 'client') {
			const thisProject = this._projectDashboardService.$currentProjectData.value;
			if (thisProject?.companyId != null) {
				const url = `company/${thisProject.companyId}`;
				this.restService.fetch(url).subscribe((data) => {
					const companyData = data.Companies[0];
					this.$linkToCompanyLogo.next(companyData.logo);
				});
			}
		}

		this.navBarStorage.$tabPointer.next(this.user.userType === 'saasRisk' ? 'risk' : 'overview');

		this.route.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe((params) => {
			if (params.tab) {
				this.navBarStorage.$tabPointer.next(params.tab);
			}

			if (params.reportId) {
				console.warn(params.reportId);
				// change tab to schedules/reports
				// force this report ID to be shown
				let i: number = 0;
				const waitingForPresetsToLoad = setInterval(() => {
					if (this.ganttService.userPresets?.length > 0) {
						clearInterval(waitingForPresetsToLoad);
						this.navBarStorage.$tabPointer.next('gantt');
						const matchingPreset: CurrentUpdateGanttPreset = this.ganttService.userPresets.find(
							(preset: CurrentUpdateGanttPreset) => preset.id === params.reportId
						);
						if (matchingPreset !== null && matchingPreset !== undefined) {
							this.ganttService.$applyReportPreset.next(matchingPreset);
						}
					}
					if (i > 50) {
						clearInterval(waitingForPresetsToLoad);
						console.log('sad. no userPresets in 30 seconds');
					}
					i++;
				}, 600);
			}
		});
		this._projectDashboardService.$currentProjectData.subscribe((data) => {
			this.currentProjectCompanyPermissions =
				data === undefined ? null : this.navBarStorage.companyPermissionMap.get(data?.company);
			this._projectDashboardService.overviewTabDefault = data?.overviewTabDefault !== false;
		});
		this._projectDashboardService.companyDataLoaded.subscribe((val: boolean) => {
			if (val) {
				this.notesRequirement.next(this._projectDashboardService.companyDefaults.notes);
				this._projectDashboardService.notesRequirement.next(this._projectDashboardService.companyDefaults.notes);
			}
		});
	}

	ngOnDestroy() {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
		this._scheduleService.clearAllData();
		this.$allUpdates.next([]);
		this.updatesEventSource?.close();
		this.projectEventSource?.close();
		this.reportEventSource?.close();
		this.socket.disconnect();
	}

	reloadWindow() {
		window.location.reload();
	}

	showProjectWarning(msg: string, n: number = 0) {
		const isScreenshotMode: boolean = localStorage.getItem('isScreenshotMode') === 'true';
		if (!isScreenshotMode) {
			const warningBar = this.snackBar.open(msg, 'Dismiss', {
				horizontalPosition: 'end',
				verticalPosition: 'top',
			});

			warningBar.afterDismissed().subscribe(() => {
				if (this.$projectData.value?.warnings?.[n + 1]) {
					this.showProjectWarning(this.$projectData.value.warnings[n + 1], n + 1);
				}
			});
		}
	}

	/**
	 * updates dialog open state value
	 * @param newVal
	 */
	dialogStateChanged(newVal) {
		this.dialogOpened = newVal;
	}

	/**
	 * gathers risk scores from last 6 months for all risk enabled projects
	 * @param projects
	 */
	updateRiskTrendingChartData(projects): Map<Date, Map<string, number>> {
		//make dateMap with end of month dates for datapoint months
		const dateIterator = new Date();
		dateIterator.setMonth(dateIterator.getMonth() - 6);
		const monthMap = new Map<Date, Map<string, number>>([]);
		for (let i = 0; i < 6; i++) {
			dateIterator.setMonth(dateIterator.getMonth() + 1);
			const endOfMonthDate = new Date(dateIterator.getFullYear(), dateIterator.getMonth() + 1, 0);
			monthMap.set(endOfMonthDate, new Map<string, number>([]));
		}

		//get risk score for each month for each project
		for (const project of projects) {
			const rawReport = this._projectDashboardService.$currentProjectReport.value;
			if (!rawReport) {
				break;
			}
			if (project.riskMetricsType === 'riskRegister' || project.riskMetricsType === 'performanceFactor') {
				const dataDateArray = rawReport.completionVarianceGraph.dataDateArray;
				if (!dataDateArray) {
					break;
				}
				for (const [key, value] of monthMap.entries()) {
					let indexOfUpdate = -1;
					for (const [index, update] of dataDateArray.entries()) {
						//skip updates that have missing dataDates
						if (update === '') {
							continue;
						}
						const newDate = new Date(update);
						newDate.setDate(newDate.getDate() + 1);
						if (isBefore(newDate, key)) {
							indexOfUpdate = index;
						} else {
							//early return when rest of updates are after comparison date to save compute time
							break;
						}
					}
					if (indexOfUpdate !== -1) {
						if (rawReport.calculationFieldsHistorical) {
							const riskScore = rawReport.calculationFieldsHistorical[indexOfUpdate].riskScore;
							if (riskScore !== null) {
								value.set(project._id, riskScore);
							}
						}
					}
				}
			}
		}
		return monthMap;
	}

	public closeAddUpdate(isOpened: boolean) {
		this.analyticsService.addUpdateOpen.next(false);
	}

	public closeRiskCalcMethod(isOpened: boolean) {
		this.analyticsService.riskCalcMethodOpen.next(false);
	}

	public closeProjectDetails(isOpened: boolean) {
		this.analyticsService.projectDetailsOpen.next(false);
	}

	public closeRiskSettings(isOpened: boolean) {
		this.analyticsService.riskSettingsOpen.next(false);
	}

	public restrictMovement() {
		this.cdr.detectChanges();

		const minTop = 5;
		const minLeft = 5;
		const maxTop = this.wrapper.top + this.wrapper.height - this.height;
		const maxLeft = this.wrapper.left + this.wrapper.width - this.width;

		if (this.top < minTop) {
			this.top = minTop;
		}

		if (this.left < minLeft) {
			this.left = minLeft;
		}

		if (this.top > maxTop) {
			this.top = maxTop;
		}

		if (this.left > maxLeft) {
			this.left = maxLeft;
		}
	}

	/**
	 * opens marketing license pricing page in a new tab
	 */
	openMarketingPage(): void {
		window.open('https://rhino.works/analytics/pricing', '_blank');
	}
}
